import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { HttpService } from '../../../../shared/services/http.service';
import allowedTypes from '../../../../../../../shared/types/allowed-types';
import * as _ from 'lodash';
import { InterestService } from '../../../../../../../shared/services/interest.service';
import { REQUEST_TYPE } from 'projects/app-ipid/src/app/shared/enums/request-type';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-info',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})

export class InformationComponent implements OnInit, OnDestroy {

  @Input() requestType: REQUEST_TYPE; // тип заявки
  @Input() selectedType; // выбранный тип на первом шаге
  @Input() idApplication; // id заявки
  @Input() startData; // информация для зполнения при возврате
  @Output() nextStepEvent = new EventEmitter();
  @Output() backStepEvent = new EventEmitter();
  modalAddDocs: boolean;
  filesSaved = []; // сохраненные файлы
  files = []; // временные файлы в модальном окне
  link; // временная ссылка в модальном окне
  linkSaved; // сохраненная ссылка
  fileError = ''; // ошибка загрузки файла
  designation; // описание заявки
  rates = {min: null, opty: null, max: null}; // тарифы
  step2ButtonClicked = false; // нажата ли кнопка перехода с 2 на 3 шаг
  loadindErrorStep2Send = false; // ошибка при переходе на 3 шаг
  countRates; // количество тарифов
  activeTariff: string; // id выбранного тарифа
  // duty: any; // пошлины
  sumBeforeDiscount = 0; // общая сумма (тариф + пошлины) до скидки
  paymentSum; // сумма к оплате с учетом скидки
  loading = false;

  constructor(
    private httpService: HttpService,
    private interestService: InterestService,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.startData) {
        this.filesSaved = this.startData.filesSaved;
        this.designation = this.startData.designation;
        this.linkSaved = this.startData.linkSaved;
      }
    }, 250);
  }

  nextStep() {
    // TODO: вернуть назад поэтапную оплату
    this.nextStepEvent.emit({
      idApplication: this.idApplication,
      rates: this.rates,
      countRates: this.countRates,
      activeTariff: this.activeTariff,
      // duty: null,
      sumBeforeDiscount: this.sumBeforeDiscount,
      paymentSum: this.paymentSum,
      files: {
        filesSaved: this.filesSaved,
        linkSaved: this.linkSaved,
        designation: this.designation
      }
    });
  }

  backStep() {
    this.backStepEvent.emit();
  }

  removeLink() {
    this.linkSaved = null;
  }

  getTitle(): string {
    let title = '';
    if (this.requestType === 'patent') {
      switch (this.selectedType) {
        case 'rupat':
          title = 'Информация об изобретении';
          break;
        case 'ruum':
          title = 'Информация о полезной модели';
          break;
        case 'rude':
          title = 'Информация о промышленном образце';
          break;
        case 'unknown':
          title = 'Информация для определения объекта';
          break;
      }
    } else if (this.requestType === 'soft') {
      switch (this.selectedType) {
        case 'rusw':
          title = 'Сведения о программе для ЭВМ или базе данных';
          break;
        case 'rudb':
          title = 'Сведения о программе для ЭВМ или базе данных';
          break;
        case 'unknown':
          title = 'Информация для определения объекта';
          break;
      }
    }
    return title;
  }

  /** Событие загрузки файла
   */
  fileChangeEvent(fileInput: any) {
    if (this.fileError?.length) {
      this.fileError = '';
    }
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      if (file) {
        const maxSize = 2000000;
        if (fileInput.target.files[0].size > maxSize) {
          this.fileError = 'Максимальный размер файла ' + maxSize / 1000000 + 'Mb';
          return false;
        }
        if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
          this.fileError = 'Недопустимый тип файла';
          return false;
        }
        if (!this.fileError?.length) {
          const formData: FormData = new FormData();
          formData.append('file', file, file.name);
          this.httpService.post({path: 'request/file/add', body: formData, isFile: true})
            .subscribe((data: any) => {
              this.files.push({
                fileName: fileInput.target.files[0].name,
                file: fileInput.target.files[0],
                path: data.path,
                size: fileInput.target.files[0].size
              });
              fileInput.target.value = '';
            });
        }
      }
    }
  }

  /** Удаление файла
   */
  removeFile(index, array) {
    array.splice(index, 1);
  }

  ngOnDestroy(): void {
  }


  /** Закрывать модальное окно при нажатии на esc
   */
  @HostListener('document:keyup', ['$event'])
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.cancelModal();
    }
  }

  closeModal() {
    this.modalAddDocs = false;
  }

  /** Открытие модального окна
   */
  openModal() {
    this.files = this.filesSaved.slice();
    this.link = this.linkSaved;
    this.modalAddDocs = true;
  }

  /** Сброс изменений и закрытие модального окна
   */
  cancelModal() {
    this.files = [];
    this.link = null;
    this.closeModal();
  }

  /** Сохранение данных из модального окна только при нажатии на кнопку "Сохранить"
   */
  safeModal() {
    this.filesSaved = this.files.slice();
    this.linkSaved = this.link;
    this.closeModal();
  }

  sendSecondJson(clarify: boolean) {
    this.loading = true;
    const fileLinks = [];
    this.filesSaved.forEach(item => {
      fileLinks.push(item.path);
    });
    if (this.requestType === 'rumcc') {
      this.httpService.post({path: 'request/load',
        body: {
          type: this.requestType,
          patenttype: 'rumcc',
          responseID: this.idApplication,
          stage: 2,
          designation: this.designation,
          clarify,
          files: fileLinks,
          links: this.linkSaved ? [this.linkSaved] : undefined,
          interestData: this.interestService.addInterestData()
        }}).subscribe((data: any) => {
        if (data && data.responseID) {
          this.idApplication = data.responseID;
          const refresh = window.location.protocol + '//' + window.location.host + window.location.pathname + `?type=${this.requestType}&id=${this.idApplication}`;
          window.history.pushState({path: refresh}, '', refresh);
          this.getRates();
        }
        // this.nextStep();
      });
    } else {
      this.httpService.post({path: 'request/load',
        body: {
          responseID: this.idApplication,
          stage: 2,
          designation: this.designation,
          clarify,
          files: fileLinks,
          links: this.linkSaved ? [this.linkSaved] : undefined,
        }}).subscribe((data: any) => {
        this.getRates();
        // this.nextStep();
      });
    }

  }

  getRates(): void {
    this.httpService.get({
      path: 'request/tariff',
      params: {responseID: this.idApplication}
    }).subscribe((data: any) => {
      if (data?.tariff) {
        this.countRates = data.tariff.length;
        data.tariff.forEach((item, index) => {
          if (index === 0) {
            this.rates.min = item;
          }
          if (index === 1) {
            this.rates.opty = item;
          }
          if (index === 2) {
            this.rates.max = item;
          }
          if (item.default) {
            this.activeTariff = item.id;
            this.paymentSum = +item.sumDiscount;
            this.sumBeforeDiscount = +item.sum;
          }
        });
      }
    });
  }


  /**
   * переход со 2 на 3 шаг возможен только когда есть номер заявки и тарифы
   * иначе лоадер
   */
  step2to3Click(clarify: boolean): void {
    this.rates.min = null;
    this.rates.opty = null;
    this.rates.max = null;
    let secondJSONSended = false;
    this.step2ButtonClicked = true;
    this.loadindErrorStep2Send = true;
    const intervalId = setInterval(() => {
      if ((this.idApplication || this.requestType === 'rumcc') && !secondJSONSended) {
        secondJSONSended = true;
        this.sendSecondJson(clarify);
      }
      if (this.rates.min || this.rates.opty || this.rates.max) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        this.step2ButtonClicked = false;
        this.loadindErrorStep2Send = false;
        this.nextStep();
        const map = new Map()
          .set('ruum', 'Полезная модель')
          .set('rupat', 'Изобретение')
          .set('rude', 'Промышленный образец')
          .set('unknown', 'Не знаю');

        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_SVEDENIYA_SENT, itemType: this.requestType });
      }
    }, 1000);
    const timeoutId = setTimeout(() => {
      this.loadindErrorStep2Send = false;
    }, 10000);
  }

  getShortName(str: string) {
    return str.substring(0, 45) + '...';
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
