<app-filters
  [def]="def"
  [currentLayout]="currentLayout$ | async"
  [layout]="layout"
  [value]="value"
  [clear$]="clear$"
  [smButtonTitle]="smButtonTitle"
  [filterValue]="filterValue"
  (apply)="onApply($event)"
  [currentTab]="currentTab"
></app-filters>
