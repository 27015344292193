<section class="section-position section-position--bg section-position--main" [class.empty]="!items?.length">
  <div class="container container--bg">

<!--    <div *ngIf="!items?.length && !owners" class="empty-results">-->
<!--      <div class="empty-results__wrapper">-->
<!--        <p class="empty-results__title">Здорово!</p>-->
<!--        <p class="empty-results__desc">-->
<!--          Поздравляем, вы придумали уникальное название и можете его зарегистрировать в качестве товарного знака, с-->
<!--          помощью кнопки выше!-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->

    <div *ngIf="!items?.length" class="empty-results-owners">
      К сожалению, ничего не найдено. Попробуйте изменить запрос.
    </div>

    <app-list-view
      *ngIf="items?.length"
      [sourceData]="items"
      [currentPage]='currentPage'
      [totalCount]="totalCount"
      [itemsPerPage]="itemsPerPage"
      [filterType]="'товарным знакам'"
      (changePage)="setPage($event)"
      (applyFilters)="onApplyFilters($event)"
    >
      <ng-container *ngIf="showFilters">
        <lvFilter lvFilterMultiselect
                  className="size--md"
                  name="type"
                  title="Статус и Вид"
                  [options]="filters.type.options"
                  [value]="filters.type.value"
                  [filterFn]="filters.type.fn"
        ></lvFilter>

        <lvFilter lvFilterMultiselect
                  name="classes"
                  title="Классы МКТУ"
                  [options]="filters.classes.options"
                  [value]="filters.classes.value"
                  [filterFn]="filters.classes.fn"
        ></lvFilter>

        <lvFilter lvFilterMultiselect
                  *ngIf="showRiskLevelFilter && type !== 'image'"
                  name="riskLevel"
                  title="Степень сходства"
                  [options]="filters.riskLevel.options"
                  [value]="filters.riskLevel.value"
                  [filterFn]="filters.riskLevel.fn"
        ></lvFilter>

        <lvFilter lvFilterDaterange
                  name="priority_date"
                  placeholder="Дата приоритета"
                  [value]="filters.priority_date.value"
                  filterField="_source.priorityDate"
        ></lvFilter>
      </ng-container>

      <ng-template let-rows lvData>
        <app-corp-search-trademark-list
          [showColumns]="showColumns"
          [items]="rows"
          [countriesDropdown]="countriesDropdown"
          [disableRisk]="disableRisk"
          (select)="onSelectItem($event)"
        ></app-corp-search-trademark-list>
      </ng-template>
    </app-list-view>

  </div>
</section>
