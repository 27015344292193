import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-add-organization-confirm',
  templateUrl: './add-organization-confirm.component.html',
  styleUrls: ['./add-organization-confirm.component.scss']
})
export class AddOrganizationConfirmComponent implements OnInit {

  @Input() name;
  @Input() success;
  brand;

  constructor(private router: Router) {
    this.brand = environment.BRAND;
  }

  ngOnInit(): void {
  }

  toMainPage() {
    this.router.navigate(['/index']);
  }
}
