import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { EventsService } from '../../../../services/events.service';


@Component({
  selector: 'app-domain-names',
  templateUrl: './domain-names.component.html',
  styleUrls: ['./domain-names.component.scss']
})
export class DomainNamesComponent implements OnInit {

  @Output() hideSearchPopup = new EventEmitter();
  @Output() clickDownload = new EventEmitter();

  @Input() isShowSearchPopup;

  @Input() domainNamesData;
  @Input() domainNames;
  @Input() allDomainNames;
  @Input() isLoadingDomainNames;
  @Input() brandsearch;
  @Input() legalsupport;
  @Input() designation;

  domainNamesOffset = 0;

  showModal = false;
  selectedDomain;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeDomainNames();
  }

  constructor(
    readonly eventsService: EventsService,
  ) {
  }

  ngOnInit(): void {
    if (this.brandsearch || this.legalsupport) {
      this.countDomainNamesBrandSearch();
    }
  }

  countDomainNamesBrandSearch() {
    if (document.documentElement.clientWidth > 991) {
      if (this.allDomainNames.length >= 6) {
        this.allDomainNames = this.allDomainNames.slice(0, 6);
      } else {
        if (this.allDomainNames.length <= 4) {
          this.allDomainNames = this.allDomainNames.slice(0, 2);
          this.domainNames = this.allDomainNames.slice(0, 2);
        }
      }
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      if (this.allDomainNames.length >= 4) {
        this.allDomainNames = this.allDomainNames.slice(0, 4);
      } else {
        if (this.allDomainNames.length <= 3) {
          this.allDomainNames = this.allDomainNames.slice(0, 1);
          this.domainNames = this.allDomainNames.slice(0, 1);
        }
      }
    }
  }

  changeDomainNameSlider(direction) {
    switch (direction) {
      case 'next':
        if (document.documentElement.clientWidth > 991) {
          this.domainNamesOffset = this.domainNamesOffset + 4;
          this.domainNames = this.allDomainNames.slice(this.domainNamesOffset, this.domainNamesOffset + 4);
        } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
          this.domainNamesOffset = this.domainNamesOffset + 3;
          this.domainNames = this.allDomainNames.slice(this.domainNamesOffset, this.domainNamesOffset + 3);
        }
        break;
      case 'back':
        if (document.documentElement.clientWidth > 991) {
          this.domainNamesOffset = this.domainNamesOffset - 4;
          this.domainNames = this.allDomainNames.slice(this.domainNamesOffset, this.domainNamesOffset + 4);
        } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
          this.domainNamesOffset = this.domainNamesOffset - 3;
          this.domainNames = this.allDomainNames.slice(this.domainNamesOffset, this.domainNamesOffset + 3);
        }
        break;
    }
  }

  getShortNameByWords(name) {
    return name.length > 18 ? name.substr(0, 16) + '...' : name;
  }

  getDomain(domainName) {
    return domainName._source.fullDomain.includes('XN--') ? `${domainName._source.domain}.${domainName._source.zone}`.toUpperCase() : domainName._source.fullDomain.toUpperCase();
  }

  openModal(data) {
    if (this.isShowSearchPopup) {
      // TODO: quick fix for close search popup
      this.hideSearchPopup.emit();
    }
    this.showModal = true;
    this.selectedDomain = data;
    const bodyStyles = document.body.style;
    document.getElementsByTagName('header')[0].style.zIndex = '1';
    bodyStyles.setProperty('overflow', 'hidden');
  }

  closeModal() {
    this.showModal = false;
    const bodyStyles = document.body.style;
    document.getElementsByTagName('header')[0].style.zIndex = '999';
    bodyStyles.setProperty('overflow', 'visible');
  }

  isViewLoadCard(): boolean {
    if (document.documentElement.clientWidth > 991) {
      return (this.brandsearch || this.legalsupport) && this.domainNamesOffset === 4 * ((this.allDomainNames.length - this.allDomainNames.length % 4) / 4) && this.allDomainNames.length;
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      return (this.brandsearch || this.legalsupport) && this.domainNamesOffset === 3 * ((this.allDomainNames.length - this.allDomainNames.length % 3) / 3) && this.allDomainNames.length;
    } else if (document.documentElement.clientWidth <= 767) {
      return (this.brandsearch || this.legalsupport) && this.allDomainNames.length;
    }
  }

  emitClickDownload() {
    return this.clickDownload.emit();
  }

  resizeDomainNames(): void {
    if (document.documentElement.clientWidth > 991) {
      this.domainNames = this.allDomainNames.slice(0, 4);
      this.domainNamesOffset = 0;
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      this.domainNames = this.allDomainNames.slice(0, 3);
      this.domainNamesOffset = 0;
    } else if (document.documentElement.clientWidth <= 767 && !this.brandsearch && !this.legalsupport) {
      this.domainNames = this.allDomainNames;
      this.domainNamesOffset = 0;
    } else if (document.documentElement.clientWidth <= 767 && (!this.brandsearch || !this.legalsupport)) {
      this.domainNames = this.allDomainNames.slice(0, 3);
      this.domainNamesOffset = 0;
    }
  }
}
