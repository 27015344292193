import { PayloadHistoryInterface } from '@web/features/corp/corp-history/models/payload-history.interface';
import { FiltersValueInterface } from '@web/features/corp/corp-history/models/filters-value.interface';
import {SearchesInterface} from '@web/features/corp/corp-history/models/searches.interface';
import {HistoryResponseInterface} from '@web/features/corp/corp-history/models/history-response.interface';

export namespace HistoryActions {

  export class Init {
    constructor(
      public payload?: PayloadHistoryInterface,
      public onlyReport: boolean = false
    ) {
    }
    static readonly type = '[history] Init';
  }

  export class InitReportsSuccess {
    constructor(
      public response: HistoryResponseInterface
    ) {
    }
    static readonly type = '[history] InitReportsSuccess';
  }

  export class InitHistorySuccess {
    constructor(
     public response: HistoryResponseInterface
    ) {
    }
    static readonly type = '[history] InitHistorySuccess';
  }

  export class InitFail {
    constructor(
     public error: Error
    ) {
    }
    static readonly type = '[history] InitFail';
  }

  export class GetHistory {
    constructor(
      public onlyReport: boolean = false
    ) {
    }
    static readonly type = '[history] GetHistory';
  }

  export class ChangeFilters {
    constructor(
      public filters: FiltersValueInterface,
      public onlyReport: boolean = false
    ) {
    }
    static readonly type = '[history] ChangeFilters';
  }

  export class ChangePage {
    constructor(
      public page: number,
      public onlyReport: boolean = false
    ) {
    }
    static readonly type = '[history] ChangePage';
  }

  export class DownloadReport {
    constructor(
      public search: SearchesInterface
    ) {
    }
    static readonly type = '[history] DownloadReport';
  }

  export class OpenReport {
    constructor(
      public searchId: string
    ) {
    }
    static readonly type = '[history] OpenReport';
  }

  export class ChangeTab {
    constructor(
      public tab: 'searches' | 'reports' = 'searches'
    ) {
    }
    static readonly type = '[history] ChangeTab';
  }

  export class GetFormFiltersValue {
    constructor(
      public filters: FiltersValueInterface | Record<string, any>,
    ) {
    }
    static readonly type = '[history] GetFormFiltersValue';
  }
}
