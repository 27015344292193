<div class="duty">
  <div>
    <div class="duty__text">Пошлины Роспатента</div>
    <div class="duty__description">Все пошлины за {{MKTUCodes.length}} {{MKTUCodes.length | declOfNum}}
      МКТУ, с учетом 30% скидки {{brand | brandName}} за электронную подачу заявки
    </div>
  </div>
  <div class="duty__price-wrap" *ngIf="duty">
    <p class="duty__price">
      <span class="duty__old-price">{{duty.sum | toLocaleString}}</span>
      <span class="duty__discount">-{{duty.discount}}%</span>
    </p>
    <span class="duty__full">{{duty.sumDiscount | toLocaleString}}</span>
  </div>
</div>