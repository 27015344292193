import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '../../../shared/models/main.interfaces';
import { SharedService } from '../../../shared/services/shared.service';
import {document} from "ngx-bootstrap/utils";

@Component({
  selector: 'app-tab-organizations-ip',
  templateUrl: './tab-organizations-ip.component.html',
  styleUrls: ['./tab-organizations-ip.component.scss']
})

export class TabOrganizationsIpComponent implements OnInit, OnChanges {

  // Сколько показывать дел
  showCases: boolean[] = [];

  // Временный флаг Новая организация
  newOrganization = false;

  hasData = false;
  cases;
  showChild = false;
  orgData;
  expAttorneys;
  user: IUser;
  showModalSupport = false;

  @Input() organizations;
  @Input() expiringAttorneys;
  @Input() administrator;
  @Input() waitingAccount;
  @Input() tasks;
  @Input() monoAccount;
  @Output() openAttorney = new EventEmitter();
  @Output() editRights = new EventEmitter();


  multiaccount: boolean;

  constructor(
    private router: Router,
    private sharedApi: SharedService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.organizations) {
      this.orgData = this.organizations.filter(org => org.id !== 'ХО-242776');
      this.expAttorneys = this.expiringAttorneys;
      this.cases = this.organizations.cases;
    }
  }

  openAttorneys() {
    this.openAttorney.emit('attorney');
  }

  addOrg() {
    this.router.navigate(['user/add-organization']);
  }

  toChild(id) {
    this.showChild = true;
    this.router.navigate(['user/organization'], {queryParams: {id}});
  }

  getRepresentativesCount(representatives: []) {
    return representatives.filter((user: any) => user.status !== 'Нет доступа').length;
  }

  openModal() {
    window.scrollTo({top: 0});
    // TODO: подумать над этим
    this.sharedApi.openModal.next(null);
  }

  getTitleByPSRNCount(PSRN) {
    if (PSRN.toString().replace(/\s+/g, '').length === 15) {
      return 'ОГРНИП';
    } else {
      return 'ОГРН';
    }
  }

  getStringWithoutSpace(str) {
    return str.replace(/\s+/g, '');
  }

  getCasesByLength(index) {
    return this.showCases[index] ? this.orgData[index].cases : this.orgData[index].cases.slice(0, 2);
  }

  editUserRights(companyID, userID) {
    if (this.multiaccount) {
      const rightsData = [];
      rightsData.push(companyID, userID);
      this.editRights.emit(rightsData);
    }
  }

  openModalSupport() {
    this.showModalSupport = true;
    if (this.showModalSupport) {
      document.getElementsByTagName('header')[0].style.zIndex = 'initial';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
  }

  closeModalSupport() {
    document.body.style.overflow = 'visible';
    document.body.style.position = '';
    document.body.style.height = 'auto';
    this.showModalSupport = false;
  }
}
