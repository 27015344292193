import { IApiError } from './Error/api-error.interface';

export interface IApiResponse<TData> {
  data: TData;
  error: IApiError;
}

export class ApiResponse<TData> implements IApiResponse<TData> {
  data: TData;
  error: IApiError;

  constructor(init?: Partial<ApiResponse<TData>>) {
    Object.assign(this, init);
  }
}
