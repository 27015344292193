<div class="tabs">
  <ul class="tabs__list tabs__list--half-cols">
    <li class="tabs__item tabs-act" *ngFor="let tab of tabs">
      <a
        [id]="tab.id"
        (click)="switchPaymentTab(tab.type)"
        class="tabs__item-link js-tabs__link"
        [className]="isOnlineTab(tab.type) ? 'disabled' : ''"
        [ngClass]="{'is-active': tab.type === currentTab}"
      >{{tab.title}}</a>
    </li>
  </ul>
</div>
