import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { HttpParams } from '@angular/common/http';
import { IPromoCode } from '../../models/main.interfaces';

const path = 'request/promocode';


@Injectable({
  providedIn: 'root'
})
export class PromocodesService {

  constructor(private http: HttpService) {
  }

  getPromoCode(params) {
    return this.http.get<IPromoCode>({path: `${path}`, params});
  }
}
