<div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
  <div class="filter__search-input-wrap">
    <span (click)="showMobileControl = true;makeBodyHidden()">Поиск по делам</span>
    <button class="filter__search-close" *ngIf="showMobileControl === true"
            (click)="showMobileControl = false;makeBodyHidden()"></button>
  </div>
</div>
<div class="container container--search" [ngClass]="showMobileControl === true? 'mobile': ''">
  <form class="search" [ngClass]="showMobileControl === true? 'mobile': ''">
    <div class="search__input-wrap">
      <svg width="21" height="21" fill="none" viewBox="0 0 21 21">
        <circle cx="9" cy="9" r="8" stroke-width="2"/>
        <path d="M15 15L20 20" stroke-width="2" stroke-linecap="round"/>
      </svg>
      <input
        [disabled]="countAllCases === 0"
        [(ngModel)]="searchText"
        (ngModelChange)="applyFiltersEmit()"
        [ngModelOptions]="{standalone: true}"
        class="search__input"
        placeholder="Поиск по тексту"
      />
    </div>

    <div class="search__wrapper">
      <div class="dropdown" [class.disabled]="!caseStatuses.length">
        <button class="dropdown__button" (click)="showStatusType = !showStatusType" style="outline: none;">
          <span *ngIf="getCountCheckedObjects(caseStatuses) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(caseStatuses) === 0 ? 'Статус' : 'Статус:'}}</span>
          <span *ngIf="getCountCheckedObjects(caseStatuses) > 0" class="dropdown__button-text dropdown__button-text--check">
              Статус: {{getCountCheckedObjects(caseStatuses)}}</span>
          <img
            class="dropdown__button-img"
            [ngClass]="showStatusType ? 'rotate': ''"
            src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
        </button>
        <div *ngIf="showStatusType" class="dropdown__menu-wrap">
          <div class="dropdown__menu">
            <span class="dropdown__menu-title">Статус</span>
            <ng-container *ngFor="let option1 of caseStatuses">
              <input class="visual-hidden dropdown__input" type="checkbox" name="{{option1.value}}" id="{{option1.id}}-status"
                     [disabled]="!caseStatuses.length"
                     [(ngModel)]="option1.checked" (ngModelChange)="applyFiltersEmit()">
              <label class="dropdown__label"
                     for="{{option1.id}}-status">
                <span>{{option1.value}}</span>
              </label>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="showStatusType || showRequestType" (click)="showStatusType = false; showRequestType = false;"
           class="overlay overlay--searchList"
           style="background: none;
                  z-index: 4;">
      </div>
      <div class="dropdown" [class.disabled]="!caseRequestTypes.length">
        <button class="dropdown__button" (click)="showRequestType = !showRequestType" style="outline: none;">
          <span *ngIf="getCountCheckedObjects(caseRequestTypes) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(caseRequestTypes) === 0 ? 'Тип работ' : 'Тип работ:'}}</span>
          <span *ngIf="getCountCheckedObjects(caseRequestTypes) > 0" class="dropdown__button-text dropdown__button-text--check">
              Тип работ: {{getCountCheckedObjects(caseRequestTypes)}}</span>
          <img
            class="dropdown__button-img"
            [ngClass]="showRequestType ? 'rotate': ''"
            src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
        </button>
        <div *ngIf="showRequestType" class="dropdown__menu-wrap">
          <div class="dropdown__menu">
            <span class="dropdown__menu-title">Тип работ</span>
            <ng-container *ngFor="let option2 of caseRequestTypes">
              <input class="visual-hidden dropdown__input" type="checkbox" name="{{option2.value}}" id="{{option2.id}}"
                     [disabled]="!caseRequestTypes.length"
                     [(ngModel)]="option2.checked" (ngModelChange)="applyFiltersEmit()">
              <label class="dropdown__label"
                     for="{{option2.id}}">
                <span>{{option2.value}}</span>
              </label>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="search__btns-wrapper">
      <button class="btn-blue btn-search" (click)="applyFiltersEmit();showMobileControl=false;makeBodyHidden()">Найти
      </button>
      <button class="btn-blue-attr btn-clean" (click)="clearFiltersEmit()">Очистить</button>
    </div>
  </form>

  <div *ngIf="countAllCases">
    <div
      *ngIf="checkAppliedFilters() && getCasesCount('filtered')"
      class="filter-text-description">
      <p class="unconnected-table__link-wrap"><b class="unconnected-table__filter">Применен фильтр:</b> показано
        <b>{{getCasesCount('filtered')}}</b>
        дел
        из
        <b>{{getCasesCount('all')}}</b>
        <a class="unconnected-table__link" (click)="clearFiltersEmit()">Показать все</a></p>
    </div>

    <div class="filter-text-result filter-text-result--nothing" *ngIf="!getCasesCount('filtered')">
      Не найдены дела, соответствующие запросу.<br>
      Попробуйте изменить поиск<br>
      <a class="show-all-link" (click)="clearFiltersEmit()">Показать все</a>
    </div>
  </div>

</div>
