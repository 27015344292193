import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  private readonly TOKEN_KEY = 'currentUserIPID';

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  setToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  deleteToken() {
    localStorage.removeItem(this.TOKEN_KEY);
  }
}
