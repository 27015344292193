<section *ngIf="firstStep" class="section-position section-position--treaty">
  <div *ngIf="formIdentity.length && !isLoading">
    <div class="container" *ngIf="formIdentity.length">
      <h1 *ngIf="formIdentity.length">Создать заявку по услуге «{{ formIdentity }}»</h1>
      <div class="attention attention--in-card">
        <h3>Важно</h3>
        <p>После формирования заявки с вами свяжется эксперт для уточнения всех подробностей и подготовит счет на оплату
          услуг. Если вы хотите оставить дополнительную информацию для эксперта, вы можете оставить ее в комментарии.</p>
      </div>

      <app-user-phone *ngIf="showPersonalData"
                      [isDebit]="false"
                      [header]="false"
                      (personalDataStatus)="getPersonalDataStatus($event)"
      ></app-user-phone>

      <textarea
        [(ngModel)]="comment"
        [class.with-error]="requestError"
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="{{rows}}"
        cdkAutosizeMaxRows="{{rows*8}}"
        placeholder="Комментарий"></textarea>

      <div *ngIf="requestError" class="lead-error">Ошибка отправки, повторите запрос позже.</div>
    </div>
    <div class="add-file__btns-block">
      <button (click)="toBackLocation()" [disabled]="!formIdentity.length" type="button" class="btn-back">
        <svg width="21" height="16" viewBox="0 0 21 16">
          <path
            d="M0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z"/>
        </svg>
        Назад
      </button>
<!--      <div *ngIf="isLoading" class="loader_treaty"></div>-->
      <button (click)="goNext()" [disabled]="!formIdentity.length || isLoading" class="btn-blue">Отправить заявку</button>
    </div>
  </div>
  <div *ngIf="!formIdentity.length || isLoading" class="loader"></div>
</section>

<section *ngIf="secondStep" class="section-position section-position--treaty">
  <div *ngIf="formIdentity.length" class="container ">
    <h1 class="heading-h1">Заявка успешно создана</h1>
    <div class="info-plate__img"><img src="/assets/images/logo_gardium.svg"></div>
    <p class="info-plate__text">Ваша заявка по услуге «{{ formIdentity }}» создана.
      В ближайшее время с вами свяжется эксперт для уточнения всех деталей по заявке и подготовке счета.
    </p><a class="bordered-blue" routerLink="/index">Перейти на главную</a>
  </div>
  <div *ngIf="!formIdentity.length" class="loader"></div>
</section>
