<div class="trademark-search__wrap">
  <div class="trademark-search" data-intercom-target="check trademark">
    <input
      [(ngModel)]="designation"
      (focusout)="sendEvent($event)"
      class="trademark-search__input" type="text"
      placeholder="Обозначение"
      (keyup.enter)="corpSearchService.searchTypeOptions[0].accept ? sendSearchParam() : ''"
      [disabled]="isDisabledSearch || X5"
      [class.trademark-search__input_disabled]="isDisabledSearch || X5"
    >
    <button
      [disabled]="isDisabledSearch || X5"
      (click)="sendSearchParam()"
      [class.disabled]="isDisabledSearch || X5"
      class="trademark-search__btn"
      type="submit"
    >
      Проверить
    </button>
  </div>

  <div class="history-search">
    <h3 class="history-search__title">История поиска</h3>
    <ng-container *ngIf="searches?.length; else noSearches">
      <table class="history-search__table">
        <tr *ngFor="let item of searches">
          <td class="history-search__first">
            {{ item.date | date: 'dd.MM.yyyy, H:mm' }}
          </td>
          <td class="history-search__second">
            {{ item.searchParameters.text }}
          </td>
          <td class="history-search__third">
            {{ item.user }}
          </td>
          <td class="history-search__fourth">
            <span class="history-search__counter">{{ item.count }}</span>
          </td>
        </tr>
      </table>
      <a href=""
         class="trademark-search-item__btn btn"
         [routerLink]="'/history'"
         [class.disabled]="X5">
        Перейти в историю и отчеты
      </a>
    </ng-container>
    <ng-template #noSearches>
      <div class="trademark-search__nothing">
        <svg width="18" height="18" viewBox="0 0 18 18">
          <path
            d="M6.51562 0C7.67188 0 8.75 0.296875 9.75 0.890625C10.75 1.45312 11.5312 2.23438 12.0938 3.23438C12.6875 4.23437 12.9844 5.32812 12.9844 6.51562C12.9844 8.10938 12.4688 9.51562 11.4375 10.7344L11.7188 11.0156H12.5156L17.4844 15.9844L15.9844 17.4844L11.0156 12.5156V11.7188L10.7344 11.4375C9.51562 12.4688 8.10938 12.9844 6.51562 12.9844C5.32812 12.9844 4.23438 12.7031 3.23438 12.1406C2.23438 11.5469 1.4375 10.75 0.84375 9.75C0.28125 8.75 0 7.67188 0 6.51562C0 5.32812 0.28125 4.23437 0.84375 3.23438C1.4375 2.23438 2.23438 1.45312 3.23438 0.890625C4.23438 0.296875 5.32812 0 6.51562 0ZM6.51562 2.01562C5.26562 2.01562 4.20312 2.45312 3.32812 3.32812C2.45312 4.20313 2.01562 5.26562 2.01562 6.51562C2.01562 7.76562 2.45312 8.82812 3.32812 9.70312C4.20312 10.5781 5.26562 11.0156 6.51562 11.0156C7.76562 11.0156 8.82812 10.5781 9.70312 9.70312C10.5781 8.82812 11.0156 7.76562 11.0156 6.51562C11.0156 5.26562 10.5781 4.20313 9.70312 3.32812C8.82812 2.45312 7.76562 2.01562 6.51562 2.01562Z"/>
        </svg>
        Вы еще ничего не искали
      </div>
    </ng-template>
  </div>
</div>

