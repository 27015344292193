<div (click)="toTask(case.ID, case.caseType)" class="table__big-block">
  <div class="table__row row-white" [class.row-white--red]="case?.toAgreement">
    <div class="row-white__title-wrap">
      <div *ngIf="case?.imgSrc" class="row-white__img">
        <img class="tm_image" src="{{case?.imgSrc | proxyImage}}">
      </div>

      <div *ngIf="!case?.imgSrc && case?.name" class="row-white__img-text">
        <p class="image_text">
          {{ getShortName(case?.name) }}
        </p>
      </div>

      <div class="row-white__title-text row-white__title-text--first">
        <p class="row-white__text row-white__text--bold">
          {{case?.name?.length > 85 ? case?.name?.slice(0, 85) + '...' : case?.name}}
        </p>
      </div>
    </div>
    <div class="row-white__title-text row-white__title-text--second">
      <p
        class="row-white__text row-white__text--gray">
        {{case?.description?.length > 35 ? case?.description?.slice(0, 35) + '...' : case?.description}}
      </p>
    </div>

    <div class="row-white__title-text row-white__title-text--third">
      <span class="row-white__text row-white__text--desc">Номер дела</span>
      <p class="row-white__text row-white__text--small row-white__text--bold">{{ case.number }}</p>
    </div>

    <div class="row-white__title-text row-white__title-text--fourth">
      <div class="row-white__reg-progress">
        <div
          *ngFor="let track of case?.tracks; index as i"
          [ngClass]="track.reach ? 'row-white__point--' + trackColors[i] : ''"
          class="row-white__point">
        </div>
      </div>
      <p class="row-white__reg-title">{{ case.status }}</p>
      <!-- не убирать div ниже, только span добавлять или удалять по услвию-->
      <div class="row-white__attention--wrapper">
        <span *ngIf="case?.toAgreement" class="row-white__attention">Требуется согласование</span>
      </div>
    </div>
  </div>
</div>
