import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationComponent } from './organization/organization.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangeBalanceComponent } from './change-balance/change-balance.component';
import { ChangeProfileComponent } from './change-profile/change-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserCardSettingsComponent } from './user-card-settings/user-card-settings.component';
import { AddOrganizationComponent } from './add-organization/add-organization.component';
import { ChangeOrganizationComponent } from './change-organization/change-organization.component';
import { OrganizationDeleteComponent } from './organization-delete/organization-delete.component';
import { InviteUserComponent } from './invite-user/invite-user.component';

const systemRoutes: Routes = [
  {
    path: 'change-profile',
    component: ChangeProfileComponent
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'change-balance',
    component: ChangeBalanceComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'organization',
    component: OrganizationComponent
  },
  {
    path: 'change-organization',
    component: ChangeOrganizationComponent
  },
  {
    path: 'organization-delete',
    component: OrganizationDeleteComponent
  },
  {
    path: 'settings',
    component: UserCardSettingsComponent
  },
  {
    path: 'invite',
    component: InviteUserComponent
  },
  {
    path: 'add-organization',
    component: AddOrganizationComponent
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(systemRoutes)
  ], exports: [
    RouterModule
  ]
})
export class UserRoutingModule {
}
