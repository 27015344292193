import { Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  template: ''
})
export abstract class AbstractValueAccessorComponent implements ControlValueAccessor {
  disabled = false;

  onChange: any = (value: any) => {};

  onTouch: any = () => {};

  protected abstract set value(val: any);

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
