import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchService } from '../../../../../services/search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';

@Component({
  selector: 'app-right-nmpt-modal',
  templateUrl: './right-nmpt-modal.component.html',
  styleUrls: ['./right-nmpt-modal.component.scss']
})

export class RightNmptModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() modalEvent = new EventEmitter();
  private readonly onDestroy = new Subject<void>();
  sourceData: any = {};
  isLoading = false;
  overTradeMarks;
  countOverTradeMarks;

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  constructor(
    private searchService: SearchService,
    private corp: CorpSearchService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '0';
    }
    this.getDocById();
  }

  getOverTradeMarks() {
    const query = {
      id: this.sourceData._source.PSRN ? this.sourceData._source.PSRN.toString() : this.sourceData._source.TIN.toString(),
      searchTradeMarks: true
    };
    this.searchService.getOverTradeMarksOrDomains(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.hits && data.hits.hits) {
        data.hits.hits.forEach((item) => {
          let id = item._source.registrationString;
          if (item._index === 'rutmap' && item._source.applicationString) {
            id = item._source.applicationString;
          }
          item._source.imageUrl = this.searchService.getImgUrl(
            item._index,
            parseInt(id, 10),
            item._source.markImageFileName,
            id
          );
          item._source.indexType = this.searchService.getIndexType(item);
        });

        this.overTradeMarks = data.hits.hits;
        this.countOverTradeMarks = data.hits.count?.tradeMarks;

        let deleteIndex;
        this.overTradeMarks.forEach((item, index) => {
          if (item._source.indexType === this.sourceData.indexType) {
            deleteIndex = index;
          }
        });
        if (deleteIndex || (deleteIndex === 0)) {
          this.overTradeMarks.splice(deleteIndex, 1);
        }
        this.ref.detectChanges();
      }
    });
  }


  getDocById() {
    const query = {
      _id: this.data._id,
      _index: this.data._index
    };
    this.isLoading = true;
    this.searchService.getDocumentById(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits?.length) {
        this.sourceData = data.hits.hits[0];
        this.sourceData.indexType = this.searchService.getIndexType(this.sourceData);
        this.isLoading = false;
        this.ref.detectChanges();
        if (this.sourceData._source.PSRN || this.sourceData._source.TIN) {
          this.getOverTradeMarks();
        }
      }
    });
  }

  getExternalLink(index) {
    return this.corp.getExternalUrl(index);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  closeModal() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '1';
    }
    this.modalEvent.emit();
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
