import {
  TariffPayload,
  TariffResponse,
  UploadImagePayload,
  SearchLegalResponse,
  UploadImageResponse,
  TrademarkDraftTasks,
  LastRequestTrademark,
  UpdateTrademarkDraft,
  LoadMktuByOkvedResponse,
  LoadDocumentByIdResponse,
  LoadTrademarkDraftResponse,
  LoadMktuByOkvedResponseHits,
  LoadDocumentByIdResponseHits,
} from '../types/trademark.types';
import { Observable, pluck } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from '../../../../../app-ipid/src/environments/environment';
import { BASE_REQUEST_CONTEXT, ELASTIC_SEARCH_CONTEXT } from '@web/utils/auth.interceptor';

@Injectable()
export class TrademarkService {
  constructor(private readonly httpClient: HttpClient) { }
  
  public loadDraftRequest(id: string): Observable<TrademarkDraftTasks> {
    return this.httpClient.get<LoadTrademarkDraftResponse>(`${environment.BASE_URL_API}/cases/${id}`, {
      params: { isDraft: true },
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    }).pipe(pluck('case'), pluck('tasks'));
  }

  public uploadImage(payload: UploadImagePayload): Observable<UploadImageResponse> {
    return this.httpClient.post<UploadImageResponse>(`${environment.BASE_URL_API}/request/image/add`, payload, {
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    });
  }

  public searchLegal(payload: any): Observable<SearchLegalResponse> {
    return this.httpClient.post<SearchLegalResponse>(`${environment.BASE_ELASTIC_URL_API}/_legal`, payload, {
      context: new HttpContext().set(ELASTIC_SEARCH_CONTEXT, true),
    });
  }

  public checkOrganization(payload: { PSRN: number }): Observable<{ passed: boolean }> {
    return this.httpClient.get<{ passed: boolean }>(`${environment.BASE_URL_API}/organizations/check`, {
      params: payload, context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    });
  }

  public createDraftRequest(payload: UpdateTrademarkDraft): Observable<{ responseID: string }> {
    return this.httpClient.post<{ responseID: string }>(`${environment.BASE_URL_API}/request/load`, payload, {
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    });
  }

  public loadLastRequestTrademark(payload: { TIN: string, PSRN: string }): Observable<LastRequestTrademark> {
    return this.httpClient.post<LastRequestTrademark>(`${environment.BASE_ELASTIC_URL_API}/_searchlastmktu`, payload, {
      context: new HttpContext().set(ELASTIC_SEARCH_CONTEXT, true),
    });
  }

  public loadDocumentById(payload: { _id: string; _index: string }): Observable<LoadDocumentByIdResponseHits> {
    return this.httpClient.post<LoadDocumentByIdResponse>(`${environment.BASE_ELASTIC_URL_API}/_id`, payload, {
      context: new HttpContext().set(ELASTIC_SEARCH_CONTEXT, true),
    }).pipe(pluck('hits'));
  }

  public loadMktuByOkved(payload: { eaNumber: string[] }): Observable<LoadMktuByOkvedResponseHits> {
    return this.httpClient.post<LoadMktuByOkvedResponse>(`${environment.BASE_ELASTIC_URL_API}/_getmktubyokved`, payload, {
      context: new HttpContext().set(ELASTIC_SEARCH_CONTEXT, true),
    }).pipe(pluck('hits'));
  }

  public loadTariffes(payload: TariffPayload): Observable<TariffResponse> {
    return this.httpClient.get<TariffResponse>(`${environment.BASE_URL_API}/request/tariff`, {
      params: { ...payload },
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    });
  }
}
