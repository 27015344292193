import { Component, OnInit } from '@angular/core';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { RequestContractService } from '../shared/request-contract.service';

@Component({
  selector: 'app-sides',
  templateUrl: './sides.component.html',
  styleUrls: ['./sides.component.scss']
})
export class SidesComponent implements OnInit {
  dropdown = false;
  counter = 1;
  counter2 = 0;
  fieldsWho = [
    {
      id: 0,
      notRes: this.requestContractService.owners?.length ? this.requestContractService.owners[0].notResident : false
    }
  ];

  fieldsWhom = [
    {
      id: '0n',
      notRes: this.requestContractService.recipients?.length ? this.requestContractService.recipients[0].notResident : false
    }
  ];

  constructor(
    private requestContractService: RequestContractService,
    private readonly intercomEventsService: IntercomEventsService
  ) {
  }

  ngOnInit() {
    if (this.requestContractService.owners.length) {
      this.setDataSides();
    }
  }

  setDataSides() {
    this.requestContractService.owners.forEach((item, index) => {
      if (index) {
        ++this.counter;
        this.fieldsWho.push(
          {
            id: this.counter,
            notRes: item.notResident
          });
      }
    });
    this.requestContractService.recipients.forEach((item, index) => {
      if (index) {
        ++this.counter2;
        this.fieldsWhom.push(
          {
            id: this.counter2 + 'n',
            notRes: item.notResident
          });
      }
    });
  }

  addWho() {
    ++this.counter;
    this.fieldsWho.push(
      {
        id: this.counter,
        notRes: false
      });
  }

  addWhom() {
    ++this.counter2;
    this.fieldsWhom.push(
      {
        id: this.counter2 + 'n',
        notRes: false
      });
  }

  selectNonResident(data) {
    this.removeOwner(data.side, data.index);

    if (data.side === 'owner') {
      this.fieldsWho[data.index].notRes = true;
    } else {
      this.fieldsWhom[data.index].notRes = true;
    }

    this.requestContractService[`${data.side}s`][data.index] = {
      name: data.organization.name,
      address: data.organization.legalAdress,
      notResident: true
    };
  }

  removeOwner(side: 'owner' | 'recipient', index: number, removeField = false) {
    if (side === 'owner') {
      this.requestContractService.owners.splice(index, 1);
      if (removeField) {
        this.fieldsWho.splice(index, 1);
      }
    } else {
      this.requestContractService.recipients.splice(index, 1);
      if (removeField) {
        this.fieldsWhom.splice(index, 1);
      }
    }
    this.requestContractService.validation = false;
  }

  get isLoading() {
    return this.requestContractService.isLoading;
  }

}
