import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { SearchActions } from '@web/features/corp/corp-search/states/search.actions';

const defaultShowColumns = ['owner', 'date', 'classes', 'state', 'countries'];

@Component({
  selector: 'app-corp-search-trademark-list',
  templateUrl: './corp-search-trademark-list.component.html',
  styleUrls: ['./corp-search-trademark-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorpSearchTrademarkListComponent implements OnInit, OnChanges {

  constructor(
    private store: Store
  ) {
  }

  mobile = false;
  mobileGrid = true;
  countries;
  trademarks;
  trademarksIndex;

  @Input() items: any[];
  @Input() disableRisk: boolean;
  @Input() countriesDropdown?: boolean;

  @Output() select = new EventEmitter<any[]>();

  public _showColumns: string[];
  @Input()
  set showColumns(val: string[]) {
    this._showColumns = val && val.length ? val : [...defaultShowColumns];
  }

  get showColumns() {
    return this._showColumns;
  }

  selectedItem = null;

  ngOnChanges() {
    if (this.items?.length) {
      this.getTrademarks();
      this.select.emit(this.items.filter(i => i.checked));
    }
  }

  ngOnInit(): void {
    this.mobile = window.innerWidth <= 768;
    this.mobileGrid = window.innerWidth > 1100;

    window.addEventListener('resize', () => {
      this.mobile = window.innerWidth <= 768;

      this.mobileGrid = window.innerWidth > 1100;
    });
  }

  isShowColumn(name: string) {
    return this.showColumns.includes(name);
  }

  selectTrademark(item) {
    this.selectedItem = {...item};
    document.body.style.overflow = 'hidden';
  }

  closeModal() {
    this.selectedItem = null;
    document.body.style.overflow = 'visible';
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  toggleCheck(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.store.dispatch(new SearchActions.CheckObject(item));
  }

  get headerColumnsFlexGroup() {
    return `180px 0.75fr ${this.columnSizes.join(' ')} 70px`;
  }

  get rowColumnsFlexGroup() {
    return `170px 0.75fr ${this.columnSizes.join(' ')} 60px`;
  }

  private get columnSizes() {
    const sizes = [
      ['owner', '1.15fr'],
      ['date', '0.9fr'],
      ['classes', '0.55fr'],
      ['state', '0.7fr']
    ];

    return sizes
      .filter(([name]) => this.showColumns.includes(name))
      .map(([name, size]) => size);
  }

  getMatchingCodes(sourceData: any) {
    const codes = [];
    for (const c of (sourceData?.class_matching?.right || [])) {
      if (c.type > 0) {
        codes.push(c.code);
      }
    }

    return codes;
  }

  getTrademarks(): void {
    this.trademarks = [];
    this.items.forEach(sourceData => {
      if (sourceData?._index === 'wotm' && !sourceData?._source?.registrationCountryCode?.length) {
        return;
      } else {
        this.trademarks.push(sourceData);
      }
    });
  }

  getCountries(sourceData: any): string {
    if (sourceData?._source?.registrationCountryCode?.length) {
      return sourceData?._source?.registrationCountryCode;
    } else {
      return sourceData?._index.slice(0, 2).toUpperCase().split(',');
    }
  }

  getTrademarksIndex(trademark: any) {
    if (trademark._index !== 'rutm' && trademark._index !== 'rugp' && trademark._index !== 'rugpap'
      && trademark._index !== 'rutmap' && trademark._index !== 'wktm' && trademark._index !== 'wotm') {
      return trademark?._index.slice(0, 2).toUpperCase();
    }
  }
}
