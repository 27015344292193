<h2 class="heading">Пошлины Роспатента</h2>

<section class="duties">
  <div class="duties__column">
    <h3 class="duties__title">
      Когда вы хотите оплатить пошлины?
    </h3>

    <ul class="duties__list">
      <li (click)="switchDuties('later')"
          [class.selected]="isDuties === 'later'"
      >
        <span>После проверки</span>
      </li>
      <li (click)="switchDuties('request_only')"
          [class.selected]="isDuties === 'request_only'"
      >
        <span>Оплатить пошлину за подачу заявки</span>
        <span>+ {{(+duties?.request_only?.sumDiscount).toLocaleString('ru')}} ₽</span>
      </li>
      <li (click)="switchDuties('all')"
          [class.selected]="isDuties === 'all'"
      >
        <span>Оплатить все пошлины сразу</span>
        <span>+ {{(+duties?.all?.sumDiscount).toLocaleString('ru')}} ₽</span>
      </li>
    </ul>

  </div>
  <div class="duties__column">

    <div class="duties__decryption">
      <p>
        <span>Итоговая сумма к оплате:</span>
        <span>{{(sum).toLocaleString('ru')}} ₽</span>
      </p>

      <p>
        <span>из них услуги по тарифу {{tariffTitle}}:</span>
        <span>+ {{(+paymentSum).toLocaleString('ru')}} ₽</span>
      </p>

      <p>
        <span>пошлины Роспатента:</span>
        <span>{{dutySum ? (+dutySum).toLocaleString('ru') : '0.00'}} ₽</span>
      </p>
    </div>

    <p class="duties__desc">
      Нужен ли тут поясняющий текст? Если да то какой?
    </p>
  </div>
</section>
