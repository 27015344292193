<div class="service-wrapper">
  <div class="service-wrapper__title">
    <h2>Популярные услуги</h2>
    <button href="" class="" [disabled]="disabled">Все</button>
  </div>
  <div class="info-card__wrapper">
    <div (click)="sendEvent('clickCheckProbabilityHit')" class="info-card info-card__small" routerLink="/search">
      <h3>Проверка товарного знака</h3>
      <p class="info-card__text">Оцените вероятность регистрации вашего обозначения</p>
      <div class="btn main-nav__btn btn__blue" style="cursor: auto">Бесплатно</div>
    </div>
    <div (click)="sendEvent('clickRTZHit')" class="info-card info-card__small" [routerLink]="['/request']" [queryParams]="{type: 'trademark'}">
      <h3>Регистрация товарного знака</h3>
      <p class="info-card__text">Электронная подача заявки в Роспатент</p>
      <span class="info-card__price">От <span>17 000</span> &#8381;</span>
    </div>
  </div>
</div>
