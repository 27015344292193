import { Injectable } from '@angular/core';
import { IMonitoringAddObjectsDetail, IMonitoringAddObjectsStage3, IMonitoringObject } from './monitoring.model';
import { MonitoringService } from '../monitoring.service';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import {HttpService} from '../../../shared/services/http.service';
import { InterestService } from '../../../../../../shared/services/interest.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import {APP_ROUTES_NAMES} from '@web/core/models/app-routes-names';

@Injectable({
  providedIn: 'root'
})
export class MonitoringAddService {

  rates = {
    1: null,
    3: null,
    6: null,
    12: null,
  };
  countRates;

  isLoading = false;
  isLoadingOnPay = false;
  data: IMonitoringAddObjectsDetail;
  payer;
  paymentType: 'online' | 'cash' | 'act' | '' = '';
  organizationId: string;

  constructor(
    private readonly monitoringService: MonitoringService,
    private readonly router: Router,
    private httpService: HttpService,
    private interestService: InterestService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  // подгружаем responseId только если у нас нет закешированного варианта
  // например, если страницу обновили после начала добавления
  loadRequestIfNeeded(responseID: string) {
    if (!this.data) {
      this.loadRequest(responseID);
    }
  }

  loadRequest(responseID: string) {
    const params = {
      isDraft: true
    };
    this.isLoading = true;
    this.monitoringService.addObjectsToMonitoringGetDetail(params, responseID)
      .subscribe(data => {
        this.data = data;
        if (this.data.stage === 3) {
          this.router.navigate([APP_ROUTES_NAMES.CASES]);
        } else {
          this.organizationId = data.organizationId;
          this.getRates(this.data.responseID);
        }
      });
  }

  // начинаем stage1
  // это может происходить с другой страницы чтобы уменьшить время ожидания пользователя. Сейчас именно так и происходит
  createRequest(params: { objects: IMonitoringObject[], organization: string, interestData: object }) {
    this.isLoading = true;
    this.organizationId = params.organization;
    return this.monitoringService.addObjectsToMonitoringStage1(params)
      .subscribe(data => {
        this.data = {
          stage: 1,
          responseID: data.responseID,
          objects: params.objects,
          excludedObjects: [],
          interestData: this.interestService.addInterestData()
        };
        this.getRates(this.data.responseID);
        this.router.navigateByUrl(`/monitoring/add/${data.responseID}`);
      });
  }

  goPayStage() {
    this.isLoadingOnPay = true;
    return this.monitoringService.addObjectsToMonitoringStage2(this.data)
      .pipe(
        finalize(() => this.isLoadingOnPay = false)
      )
      .subscribe(() => {
        for (const rate in this.rates) {
          if (this.rates[rate]?.id === this.data.tariffID) {
            this.intercomEventsService.push({
              event: INTERCOM_EVENT_NAME.FORM_TM_RATE_SENT,
              typeIP: 'monitoring',
              tariff: this.rates[rate],
              totalPrice: this.totalPrice
            });
          }
        }
        this.data.stage = 2;
      });
  }

  goSuccessStage() {
    this.isLoading = true;

    const query: IMonitoringAddObjectsStage3 = {
      responseID: this.data.responseID,
      type: this.paymentType,
      inn: this.payer?.TIN,
      ogrn: this.payer?.PSRN,
      address: this.payer?.address,
      name: this.payer?.name || this.payer?.fullName,
      sum: this.calcPriceWithDiscount(this.data.tariffID).toString(),
    };
    return this.monitoringService.addObjectsToMonitoringStage3(query)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(() => {
        const monitoringObjects = [];
        this.data.objects.forEach(item => {
          const date = new Date();
          if (this.data.excludedObjects.findIndex(el => el.ID === item.ID) === -1) {
            monitoringObjects.push({
              id: item.ID,
              index: item.index[0],
              paid: this.paymentType === 'online',
              monitoringDate: new Date(date.setMonth(date.getMonth() + this.getPeriod(this.data.tariffID)))
            });
          }
        });
        if (this.paymentType === 'online') {
          this.monitoringService.setObjectMonitoring(monitoringObjects, this.organizationId).subscribe(() => {
          });
        }

        this.data.stage = 3;
      });
  }

  selectTariff(id) {
    for (const rate in this.rates) {
      if (this.rates[rate].id === id) {
        this.intercomEventsService.push({
          event: INTERCOM_EVENT_NAME.SELECT_TARIF,
          typeIP: 'monitoring',
          tariff: this.rates[rate]
        });
      }
    }
    this.data.tariffID = id;
  }

  toggleObject(obj) {
    if (this.isObjectExcluded(obj)) {
      this.data.excludedObjects = this.data.excludedObjects.filter(o => o !== obj);
      this.data.objects.push(obj);
    } else {
      this.data.objects = this.data.objects.filter(o => o !== obj);
      this.data.excludedObjects.push(obj);
    }
  }

  isObjectExcluded(obj) {
    return this.data.excludedObjects.includes(obj);
  }

  get allObjects() {
    return [...this.data.objects, ...this.data.excludedObjects]
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get currentDiscount() {
    return this.rates[this.getPeriod(this.data.tariffID)]?.discount;
  }

  get totalPrice() {
    return this.calcPriceWithDiscount(this.data.tariffID);
  }

  calcPriceWithDiscount(id: string) {
    // const price = this.calcPrice(months);
    // return Math.floor(price / 100 * (100 - this.Tariffs[months].discount));
    return Math.floor(this.totalSelectedObjects * this.rates[this.getPeriod(id)]?.sumDiscount);
  }

  // calcPrice(months: number) {
  //   return Math.floor(this.totalSelectedObjects * this.BaseTariff * months);
  // }

  get isAvailablePersonalTariff() {
    return this.totalSelectedObjects >= 20;
  }

  get totalSelectedObjects() {
    return this.data.objects.length;
  }

  reset() {
    this.data = null;
    this.isLoading = false;
  }


  setPayer(payerData) {
    this.payer = payerData;
  }

  setPaymentType(type: 'online' | 'cash' | 'act') {
    this.paymentType = type;
  }

  get logo() {
    return environment.LOGO2_PATH;
  }

  getRates(id) {
    const params = {
      responseID: id,
    };
    this.httpService.get({path: 'request/tariff', params}).subscribe((data: any) => {
      if (data && data.tariff) {
        this.countRates = data.tariff?.length;
        data.tariff.forEach((item, index) => {
          if (index === 0) {
            this.rates[1] = item;
          }
          if (index === 1) {
            this.rates[3] = item;
          }
          if (index === 2) {
            this.rates[6] = item;
          }
          if (index === 3) {
            this.rates[12] = item;
          }
          if (item.default && !this.data.tariffID) {
            this.data.tariffID = item.id;
          }
        });
        this.isLoading = false;
      }
    });
  }

  getPeriod(id) {
    for (const key in this.rates) {
      if (this.rates[key].id === id) {
        return +key;
      }
    }
  }

}
