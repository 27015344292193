export default [
  {name: 'Австралия',  code: 'AU'},
  {name: 'Австрия', code: 'AT', organizations: ['Евросоюз']},
  {name: 'Азербайджан', code: 'AZ', organizations: ['СНГ']},
  {name: 'Албания', code: 'AL'},
  {name: 'Алжир', code: 'DZ'},
  {name: 'Американское Самоа', code: 'AS'},
  {name: 'Ангилья', code: 'AI'},
  {name: 'Ангола', code: 'AO'},
  {name: 'Андорра', code: 'AD'},
  {name: 'Антарктида', code: 'AQ'},
  {name: 'Антигуа и Барбуда', code: 'AG'},
  {name: 'Аргентина', code: 'AR'},
  {name: 'Армения', code: 'AM', organizations: ['СНГ']},
  {name: 'Аруба', code: 'AW'},
  {name: 'Афганистан', code: 'AF'},

  {name: 'Багамские острова', code: 'BS'},
  {name: 'Бангладеш', code: 'BD'},
  {name: 'Барбадос', code: 'BB'},
  {name: 'Бахрейн', code: 'BH'},
  {name: 'Белоруссия (Беларусь)', code: 'BY', organizations: ['СНГ']},
  {name: 'Белиз', code: 'BZ'},
  {name: 'Бельгия', code: 'BE', organizations: ['Евросоюз']},
  {name: 'Бенин', code: 'BJ'},
  {name: 'Бермудские Острова', code: 'BM'},
  {name: 'Болгария', code: 'BG', organizations: ['Евросоюз']},
  {name: 'Боливия (Многонациональное государство)', code: 'BO'},
  {name: 'Бонайре, Синт – Эстатиус и Саба', code: 'BQ'},
  {name: 'Босния и Герцеговина', code: 'BA'},
  {name: 'Ботсвана', code: 'BW'},
  {name: 'Бразилия', code: 'BR', organizations: ['БРИКС']},
  {name: 'Британские Виргинские Острова', code: 'VG'},
  {name: 'Британская территория в Индийском океане', code: 'IO'},
  {name: 'Бруней Даруссалам', code: 'BN'},
  {name: 'Буве, остров', code: 'BV'},
  {name: 'Буркина Фасо', code: 'BF'},
  {name: 'Бурунди', code: 'BI'},
  {name: 'Бутан', code: 'BT'},

  {name: 'Вануату', code: 'VU'},
  {name: 'Ватикан', code: 'VA'},
  {name: 'Великобритания', code: 'GB', organizations: ['Евросоюз']},
  {name: 'Венгрия', code: 'HU', organizations: ['Евросоюз']},
  {name: 'Венесуэла (Боливарианская республика)', code: 'VE'},
  {name: 'Виргинские Острова (США)', code: 'VI'},
  {name: 'Вьетнам', code: 'VN'},

  {name: 'Габон', code: 'GA'},
  {name: 'Гаити', code: 'HT'},
  {name: 'Гайна', code: 'GY'},
  {name: 'Гамбия', code: 'GM'},
  {name: 'Гана', code: 'GH'},
  {name: 'Гваделупа', code: 'GP'},
  {name: 'Гватемала', code: 'GT'},
  {name: 'Гвинея', code: 'GN'},
  {name: 'Гвинея-Бисау', code: 'GW'},
  {name: 'Германия', code: 'DE', organizations: ['Евросоюз']},
  {name: 'Гернси', code: 'GG'},
  {name: 'Гибралтар', code: 'GI'},
  {name: 'Гондурас', code: 'HN'},
  {name: 'Гонконг, Китай', code: 'HK'},
  {name: 'Гренада', code: 'GD'},
  {name: 'Гренландия', code: 'GL'},
  {name: 'Греция', code: 'GR', organizations: ['Евросоюз']},
  {name: 'Грузия', code: 'GE'},
  {name: 'Гуам', code: 'GU'},

  {name: 'Дания', code: 'DK', organizations: ['Евросоюз']},
  {name: 'Демократическая республика Конго', code: 'CD'},
  {name: 'Джерси', code: 'JE'},
  {name: 'Джибути', code: 'DJ'},
  {name: 'Доминикана', code: 'DM'},
  {name: 'Доминиканская республика', code: 'DO'},

  {name: 'Египет', code: 'EG'},

  {name: 'Замбия', code: 'ZM'},
  {name: 'Западная Сахара', code: 'EH'},
  {name: 'Зимбабве', code: 'ZW'},

  {name: 'Израиль', code: 'IL'},
  {name: 'Индия', code: 'IN', organizations: ['БРИКС']},
  {name: 'Индонезия', code: 'ID'},
  {name: 'Иордания', code: 'JO'},
  {name: 'Ирак', code: 'IQ'},
  {name: 'Иран (Исламская республика)', code: 'IR'},
  {name: 'Ирландия', code: 'IE', organizations: ['Евросоюз']},
  {name: 'Исландия', code: 'IS'},
  {name: 'Испания', code: 'ES', organizations: ['Евросоюз']},
  {name: 'Италия', code: 'IT', organizations: ['Евросоюз']},
  {name: 'Йемен', code: 'YE'},

  {name: 'Кабо-Верде', code: 'CV'},
  {name: 'Казахстан', code: 'KZ', organizations: ['СНГ']},
  {name: 'Кайман, Острова', code: 'KY'},
  {name: 'Камбоджа', code: 'KH'},
  {name: 'Камерун', code: 'CM'},
  {name: 'Канада', code: 'CA'},
  {name: 'Катар', code: 'QA'},
  {name: 'Кения', code: 'KE'},
  {name: 'Кипр', code: 'CY', organizations: ['Евросоюз']},
  {name: 'Киргизия (Кыргызстан)', code: 'KG', organizations: ['СНГ']},
  {name: 'Кирибати', code: 'KI'},
  {name: 'Китай', code: 'CN', organizations: ['БРИКС']},
  {name: 'Колумбия', code: 'CO'},
  {name: 'Коморские острова', code: 'KM'},
  {name: 'Конго', code: 'CG'},
  {name: 'Корейская Народно-Демократическая', code: 'KP'},
  {name: 'Корея, Республика', code: 'KR'},
  {name: 'Кокосовые (Килинг) Острова', code: 'CC'},
  {name: 'Коста-Рика', code: 'CR'},
  {name: 'Кот-д\'Ивуар', code: 'CI'},
  {name: 'Куба', code: 'CU'},
  {name: 'Кувейт', code: 'KW'},
  {name: 'Кука, Острова', code: 'CK'},
  {name: 'Кюрасао', code: 'CW'},

  {name: 'Лаос', code: 'LA'},
  {name: 'Латвия', code: 'LV', organizations: ['Евросоюз']},
  {name: 'Лесото', code: 'LS'},
  {name: 'Либерия', code: 'LR'},
  {name: 'Ливан', code: 'LB'},
  {name: 'Ливия', code: 'LY'},
  {name: 'Литва', code: 'LT', organizations: ['Евросоюз']},
  {name: 'Лихтенштейн', code: 'LI'},
  {name: 'Люксембург', code: 'LU', organizations: ['Евросоюз']},

  {name: 'Маврикий', code: 'MU'},
  {name: 'Мавритания', code: 'MR'},
  {name: 'Мадагаскар', code: 'MG'},
  {name: 'Майотта', code: 'YT'},
  {name: 'Макао, Китай', code: 'MO'},
  {name: 'Малави', code: 'MW'},
  {name: 'Малайзия', code: 'MY'},
  {name: 'Мали', code: 'ML'},
  {name: 'Малые Тихоокеанские отдаленные острова США', code: 'UM'},
  {name: 'Мальдивы', code: 'MV'},
  {name: 'Мальта', code: 'MT', organizations: ['Евросоюз']},
  {name: 'Марокко', code: 'MA'},
  {name: 'Мартиника', code: 'MQ'},
  {name: 'Маршалловы Острова', code: 'MH'},
  {name: 'Мексика', code: 'MX'},
  {name: 'Микронезия (Федеративные Штаты)', code: 'FM'},
  {name: 'Мозамбик', code: 'MZ'},
  {name: 'Молдавия (Молдова)', code: 'MD', organizations: ['СНГ']},
  {name: 'Монако', code: 'MC'},
  {name: 'Монголия', code: 'MN'},
  {name: 'Монтсеррат', code: 'MS'},
  {name: 'Мьянма', code: 'MM'},

  {name: 'Намибия', code: 'NA'},
  {name: 'Науру', code: 'NR'},
  {name: 'Непал', code: 'NP'},
  {name: 'Нигер', code: 'NE'},
  {name: 'Нигерия', code: 'NG'},
  {name: 'Нидерланды', code: 'NL', organizations: ['Евросоюз']},
  {name: 'Никарагуа', code: 'NI'},
  {name: 'Ниуэ', code: 'NU'},
  {name: 'Новая Зеландия', code: 'NZ'},
  {name: 'Новая Каледония', code: 'NC'},
  {name: 'Норвегия', code: 'NO'},

  {name: 'Объединенные Арабские Эмираты', code: 'AE'},
  {name: 'Оман', code: 'OM'},
  {name: 'Остров Мэн', code: 'IM'},
  {name: 'Остров Норфолк', code: 'NF'},
  {name: 'Остров Рождества', code: 'CX'},
  {name: 'Остров Херд и острова Макдональд', code: 'HM'},

  {name: 'Пакистан', code: 'PK'},
  {name: 'Палау', code: 'PW'},
  {name: 'Палестина, Государство', code: 'PS'},
  {name: 'Панама', code: 'PA'},
  {name: 'Папуа Новая Гвинея', code: 'PG'},
  {name: 'Парагвай', code: 'PY'},
  {name: 'Перу', code: 'PE'},
  {name: 'Польша', code: 'PL', organizations: ['Евросоюз']},
  {name: 'Португалия', code: 'PT', organizations: ['Евросоюз']},
  {name: 'Пуэрто-Рико', code: 'PR'},

  {name: 'Реюньон', code: 'RE'},
  {name: 'Российская Федерация', code: 'RU', organizations: ['СНГ', 'БРИКС']},
  {name: 'Руанда', code: 'RW'},
  {name: 'Румыния', code: 'RO', organizations: ['Евросоюз']},

  {name: 'Сальвадор', code: 'SV'},
  {name: 'Самоа', code: 'WS'},
  {name: 'Сан-Марино', code: 'SM'},
  {name: 'Сан-Томе и Принсипи', code: 'ST'},
  {name: 'Саудовская Аравия', code: 'SA'},
  {name: 'Святой Елены, Вознесения и Тристана-да-Кунья, Острова', code: 'SH'},
  {name: 'Северная Македония', code: 'MK'},
  {name: 'Северные Марианские Острова', code: 'MP'},
  {name: 'Сейшельские Острова', code: 'SC'},
  {name: 'Сенегал', code: 'SN'},
  {name: 'Сент-Винсент и Гренадины', code: 'VC'},
  {name: 'Сент-Киттс и Невис', code: 'KN'},
  {name: 'Сент-Люсия', code: 'LC'},
  {name: 'Сербия', code: 'RS'},
  {name: 'Сингапур', code: 'SG'},
  {name: 'Синт Маартен (Голландская часть)', code: 'SX'},
  {name: 'Сирия', code: 'SY'},
  {name: 'Словакия', code: 'SK', organizations: ['Евросоюз']},
  {name: 'Словения', code: 'SI', organizations: ['Евросоюз']},
  {name: 'Соединенные штаты Америки (США)', code: 'US'},
  {name: 'Соломоновы Острова', code: 'SB'},
  {name: 'Сомали', code: 'SO'},
  {name: 'Судан', code: 'SD'},
  {name: 'Суринам', code: 'SR'},
  {name: 'Сьерра-Леоне', code: 'SL'},

  {name: 'Таджикистан', code: 'TJ', organizations: ['СНГ']},
  {name: 'Тайвань, провинция Китая', code: 'TW'},
  {name: 'Таиланд', code: 'TH'},
  {name: 'Танзания', code: 'TZ'},
  {name: 'Теркс и Кайкос', code: 'TC'},
  {name: 'Тимор-Лесте', code: 'TL'},
  {name: 'Того', code: 'TG'},
  {name: 'Токелау', code: 'TK'},
  {name: 'Тонга', code: 'TO'},
  {name: 'Тринидад и Тобаго', code: 'TT'},
  {name: 'Тувалу', code: 'TV'},
  {name: 'Тунис', code: 'TN'},
  {name: 'Туркменистан', code: 'TM'},
  {name: 'Турция', code: 'TR'},

  {name: 'Уганда', code: 'UG'},
  {name: 'Узбекистан', code: 'UZ', organizations: ['СНГ']},
  {name: 'Украина', code: 'UA', organizations: ['СНГ']},
  {name: 'Уоллис и Футуна', code: 'WF'},
  {name: 'Уругвай', code: 'UY'},

  {name: 'Фарерские острова', code: 'FO'},
  {name: 'Фиджи', code: 'FJ'},
  {name: 'Филиппины', code: 'PH'},
  {name: 'Финляндия', code: 'FI', organizations: ['Евросоюз']},
  {name: 'Фолклендские (Мальвинские) Острова', code: 'FK'},
  {name: 'Франция', code: 'FR', organizations: ['Евросоюз']},
  {name: 'Французская Гвиана', code: 'GF'},
  {name: 'Французская Полинезия', code: 'PF'},
  {name: 'Французские Южные территории', code: 'TF'},

  {name: 'Хорватия', code: 'HR', organizations: ['Евросоюз']},

  {name: 'Центральноафриканская республика', code: 'CF'},

  {name: 'Чад', code: 'TD'},
  {name: 'Черногория', code: 'ME'},
  {name: 'Чехия', code: 'CZ', organizations: ['Евросоюз']},
  {name: 'Чили', code: 'CL'},

  {name: 'Швейцария', code: 'CH'},
  {name: 'Швеция', code: 'SE', organizations: ['Евросоюз']},
  {name: 'Шпицберген и Ян Майен', code: 'SJ'},
  {name: 'Шри-Ланка', code: 'LK'},

  {name: 'Эквадор', code: 'EC'},
  {name: 'Экваториальная Гвинея', code: 'GQ'},
  {name: 'Эль Сальвадор', code: 'SV'},
  {name: 'Эритрея', code: 'ER'},
  {name: 'Эсватини', code: 'SZ'},
  {name: 'Эстония', code: 'EE', organizations: ['Евросоюз']},
  {name: 'Эфиопия', code: 'ET'},
  {name: 'Евросоюз', code: 'EM'},

  {name: 'Южно-Африканская Республика', code: 'ZA', organizations: ['БРИКС']},
  {name: 'Южная Георгия и Южные Сандвичевы острова', code: 'GS'},
  {name: 'Южный Судан', code: 'SS'},

  {name: 'Ямайка', code: 'JM'},
  {name: 'Япония', code: 'JP'},
];
