<div *ngIf="sliderCards" class="slider">
  <div class="container container--slider">
    <h1>Популярные услуги</h1>
    <section class="slider__wrap">
      <carousel [cellWidth]="160" [margin]="15" [transitionDuration]="500" [arrows]="false" #slider>
        <div (click)="toServiceDetails(card.id)" class="carousel-cell slider__item" *ngFor="let card of sliderCards">
          <p>{{card.name}}</p>
        </div>
      </carousel>
      <button class="slider__btn slider__btn--prev" (click)="moveLeft()" [class.disabled]="disableArrow">
        <!--      <button class="slider__btn slider__btn&#45;&#45;prev" (click)="moveLeft()" [class.disabled]="isPrevArrowDisabled()">-->
        <svg width="19" height="16" viewBox="0 0 19 16">
          <path
            d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM19 7L1 7V9L19 9V7Z"/>
        </svg>
      </button>
      <button *ngIf="sliderCards.length > 5" class="slider__btn slider__btn--next" (click)="moveRight()">
        <!--      <button class="slider__btn slider__btn&#45;&#45;next" (click)="moveRight()" [class.disabled]="isNextArrowDisabled()">-->
        <svg width="19" height="16" viewBox="0 0 19 16">
          <path
            d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9H18V7H0V9Z"/>
        </svg>
      </button>
    </section>
  </div>
</div>

<section class="section-position search-style">
  <div class="container container--search">
    <form *ngIf="showMobileControl===true" class="search">
      <div class="search__input-wrap">
        <svg width="21" height="21" fill="none" viewBox="0 0 21 21">
          <circle cx="9" cy="9" r="8" stroke-width="2"/>
          <path d="M15 15L20 20" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <input class="search__input"
               [(ngModel)]="searchTerm"
               (ngModelChange)="filterByText()"
               [ngModelOptions]="{standalone: true}"
               placeholder="Поиск по услугам"/>
      </div>
      <div class="search__wrapper">
        <div class="dropdown" style="max-width: initial">
          <button class="dropdown__button" (click)="showTypeObject = !showTypeObject" style="outline: none;">
            <span *ngIf="getCountCheckedObjects(typeOfObjects) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(typeOfObjects) === 0 ? 'Вид объекта:' : 'Вид объекта:'}}</span>
            <span *ngIf="getCountCheckedObjects(typeOfObjects) > 0" class="dropdown__button-text dropdown__button-text--check">
              Вид объекта: {{getCountCheckedObjects(typeOfObjects)}}</span>
            <img
              class="dropdown__button-img"
              [ngClass]="showTypeObject ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showTypeObject" class="dropdown__menu-wrap" style="z-index: 1000">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Вид объекта:</span>
              <ng-container *ngFor="let option of typeOfObjects">
                <input class="visual-hidden dropdown__input" type="checkbox" name="{{option.value}}" id="{{option.id}}"
                       [(ngModel)]="option.checked"
                       (ngModelChange)="filterByType()"
                >
                <label class="dropdown__label"
                       for="{{option.id}}">
                  <span>{{option.value}}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="showGovernmentAgency || showTypeObject"
             (click)="showGovernmentAgency = false; showTypeObject = false;"
             class="overlay overlay--searchList"
             style="background: none">
        </div>
        <div class="dropdown" style="max-width: initial">
          <button class="dropdown__button" (click)="showGovernmentAgency = !showGovernmentAgency" style="outline: none;">
            <span *ngIf="getCountCheckedObjects(objectsSelect) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(objectsSelect) === 0 ? 'Государственный орган:' : 'Государственный орган:'}}</span>
            <span *ngIf="getCountCheckedObjects(objectsSelect) > 0" class="dropdown__button-text dropdown__button-text--check">
              Государственный орган: {{getCountCheckedObjects(objectsSelect)}}</span>
            <img
              class="dropdown__button-img"
              [ngClass]="showGovernmentAgency ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showGovernmentAgency" class="dropdown__menu-wrap" style="z-index: 1000">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Государственный орган:</span>
              <ng-container *ngFor="let option of objectsSelect">
                <input class="visual-hidden dropdown__input" type="checkbox" name="{{option.value}}" id="{{option.id}}"
                       [(ngModel)]="option.checked"
                       (ngModelChange)="filterByStateEntity()"
                >
                <label class="dropdown__label"
                       for="{{option.id}}">
                  <span>{{option.value}}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="search__btns-wrapper">
        <button class="btn-blue btn-search" (click)="applyFilters();showMobileControl=false;makeBodyHidden()">Найти
        </button>
        <button class="btn-blue-attr btn-clean" (click)="clearAll()">Очистить</button>
      </div>
    </form>
    <span class="control__mobile"
          [class.control__mobile--active]="showMobileControl===true"
          (click)="showMobileControl = true; showFilters = true; makeBodyHidden()">
      <p>Фильтр по услугам</p>

    </span>

    <button class="control__mobile-close" *ngIf="showMobileControl === true && showFilters === true"
            (click)="showMobileControl=false; showFilters = false; makeBodyHidden()"></button>
    <div class="services">
      <div *ngFor="let block of $services" class="services__card-wrapper">
        <h2>{{block.title}}</h2>
        <div class="services__cards">
          <div *ngFor="let card of block.cards" class="services__card">
            <div class="services__card-top">
              <p>{{trimString(card.title, 30)}}
                <span>{{card.title}}</span>
              </p>
              <span *ngIf="card.prompt" class="prompt prompt__question">?
                <span class="prompt__hidden">
                  <p>{{card.prompt}}</p>
                </span>
              </span>
            </div>
            <div class="services__card-bottom">
              <a (click)="toServiceDetails(link.id)" *ngFor="let link of card.links">{{link.name}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="showNoResult">
      <div class="filter-text-result filter-text-result--nothing">
        Не найдены объекты, соответствующие запросу.<br>
        Попробуйте изменить поиск<br>
        <a class="show-all-link" (click)="clearAll()">Показать все</a>
      </div>
    </ng-container>

  </div>
</section>
<app-expert>
</app-expert>
