import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { environment } from '../../../../app-ipid/src/environments/environment';
import { UserProfileService } from '../../../services/user-profile.service';
import { EnvService } from '../../../../app-ipid/src/app/shared/services/env.service';
import { Store } from '@ngxs/store';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';


@Component({
  selector: 'app-payment-tabs',
  templateUrl: './payment-tabs.component.html',
  styleUrls: ['./payment-tabs.component.scss']
})
export class PaymentTabsComponent implements OnInit {
  @Input() requestType?: string;
  @Output() switchPaymentType = new EventEmitter(); // событие переключения способа оплаты
  logo2path = '';
  paymentTab = 'cash';
  showActTab = false;


  constructor(
    private profileApi: UserProfileService,
    private envService: EnvService,
    private store: Store
  ) {
    this.logo2path = environment.LOGO2_PATH;
  }

  ngOnInit(): void {
    if (this.store.selectSnapshot(AUTH_STATE_TOKEN).user?.certificate_of_completion && this.envService.isX5) {
      this.showActTab = true;
      this.paymentTab = 'act';
      this.switchPaymentType.emit('act');
    } else {
      this.switchPaymentType.emit('cash');
    }
  }

  /**
   * переключение панели способа оплаты
   * @param type: выбранный тип оплаты
   */
  switchPaymentTab(type: string): void {
    if (type === this.paymentTab) {
      return;
    } else {
      this.paymentTab = type;
      this.switchPaymentType.emit(type);
    }
  }
}
