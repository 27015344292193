import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ILvFilterDetail, LvFilterComponent } from '../lv-filter/lv-filter.component';
import { LvDataDirective } from '../directives/lv-data.directive';
import { LvFilterMultiselectDirective } from '../directives/lv-filter-multiselect.directive';
import { LvFilterDaterangeDirective } from '../directives/lv-filter-daterange.directive';
import { document } from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListViewComponent implements OnInit, AfterContentInit {
  @ContentChildren(LvFilterComponent) filters!: QueryList<LvFilterComponent>;
  @ContentChild('rows') rowsComponent: ComponentRef<any>;

  @ViewChild('dataWrapper') dataWrapper: ElementRef;

  @ContentChild(LvDataDirective, {read: TemplateRef}) lvDataDirective;

  private _sourceData = [];

  @Input() set sourceData(items: any[]) {
    this._sourceData = items;
    this.applyFiltersToData();
  }

  @Input() page: number = 1;
  @Input() limit: number = 50;

  @Input() filterType;

  @Output() applyFilters = new EventEmitter<Array<{ filter: ILvFilterDetail<any>, value: any }>>();
  @Output() changePage = new EventEmitter<any>();
  @Output() hideTopPage = new EventEmitter<boolean>();
  @Input() totalCount: number;
  @Input() itemsPerPage;
  @Input() currentPage;

  showMobileControl = false;
  isMobile = false;
  pagesCount;
  maxPage = 4;

  makeBodyHidden() {
    if (window.innerWidth <= 793) {
      if (this.showMobileControl === true) {
        document.getElementsByTagName('header')[0].style.zIndex = 'initial';
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.height = '100%';
      } else {
        document.body.style.overflow = 'visible';
        document.body.style.position = '';
        document.body.style.height = 'auto';
      }
    }
  }

  makeBodyVisible() {
    if (window.innerWidth < 1100) {
      document.body.style.overflow = 'visible';
    }
  }

  constructor() {
  }

  ngOnInit(): void {
    this.onWindowSizeChange();
    window.addEventListener('resize', this.onWindowSizeChange);
  }

  onWindowSizeChange = () => {
    if (window.innerWidth <= 1100) {
      this.isMobile = true;
      this.showMobileControl = false;
    } else {
      document.body.style.overflow = 'visible';
      this.isMobile = false;
      // this.showMobileControl = true;
    }
  };

  allFilters: ILvFilterDetail<any>[] = [];
  filterValues = new Map<object, any>();

  ngAfterContentInit(): void {
    this.allFilters = this.filters.map(filter => {
      return filter.getFilterDetail();
    });

    this.applyFiltersToData();
    this.getPagesCount();
  }

  getSearchType(filterType) {
    if (filterType === 'searches') {
      return 'истории';
    } else if (filterType === 'reports') {
      return 'отчетам';
    } else {
      return filterType;
    }
  }

  getPagesCount() {
    if (this.pages.length > 5) {
      this.pagesCount = this.pages.slice(0, this.maxPage);
    } else {
      this.pagesCount = this.pages.length - 1;
    }
  }

  morePages(current) {
    this.pagesCount = this.pages.slice(current[this.maxPage - 1], current[this.maxPage - 1] + this.maxPage);
    this.page = this.pagesCount[0];
  }

  lessPages(current) {
    this.pagesCount = this.pages.slice(current[0] - this.maxPage - 1, current[0] - 1);
    this.page = this.pagesCount[this.pagesCount.length - 1];
  }

  onFilterChangeMultiselect(filter: ILvFilterDetail<LvFilterMultiselectDirective>, value: any[]) {
    this.filterValues.set(filter, value);
    this.onApply();
  }

  onFilterChangeInput(filter: ILvFilterDetail<LvFilterMultiselectDirective>, value: string) {
    this.filterValues.set(filter, value);
    this.onApply();
  }

  onFilterChangeDaterange(filter: ILvFilterDetail<LvFilterDaterangeDirective>, value: [Date, Date]) {
    this.filterValues.set(filter, value);
    this.onApply();
  }

  filteredItems = [];


  get paginatedItems() {
    const offset = this.page * this.limit - this.limit;
    return this.filteredItems.slice(offset, offset + this.limit);
  }

  onChangePage(page: number) {
    this.changePage.emit({page, size: this.itemsPerPage});
    this.dataWrapper.nativeElement.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'});
  }

  get pages() {
    const pages = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }

  get totalPages() {
    return Math.ceil(this.filteredItems.length / this.limit);
  }

  onClean() {
    this.filterValues.clear();
    this.applyFilters.emit(this.allFilters.map(filter => {
      return {filter, value: filter.type === 'multiselect' ? [] : ''};
    }));

    this.afterEmitFilters();
  }

  onApply() {
    this.applyFilters.emit(this.allFilters.map(filter => {
      return {
        filter,
        value: this.filterValues.has(filter) ? this.filterValues.get(filter) : filter.props.value
      };
    }));

    this.afterEmitFilters();
  }

  private afterEmitFilters() {
    // выглядит как костыль, но других "простых" вариантов нет
    setTimeout(() => this.applyFiltersToData(), 100);
  }

  private applyFiltersToData() {
    this.filteredItems = this._sourceData.filter(row => this.allFilters.every(filter => filter.filterFn(row)));
  }

  get lvDataContext() {
    return {$implicit: this.paginatedItems};
  }

  backPage() {
    if (this.page !== 1) {
      if (this.page - 1 < this.pagesCount[0]) {
        this.lessPages(this.pagesCount);
      } else {
        this.page--;
      }
    } else {
      this.page = 1;
    }

  }

  nextPage() {
    if (this.page !== this.pages.length) {
      if (this.page + 1 > this.pagesCount[this.pagesCount.length - 1]) {
        this.morePages(this.pagesCount);
      } else {
        this.page++;
      }
    } else {
      this.page = this.pages.length;
    }
  }

  closeMobileForm() {
    this.makeBodyVisible();
    this.showMobileControl = false;
    this.hideTopPage.emit(false);

  }

  openMobileForm() {
    this.makeBodyHidden();
    this.showMobileControl = true;
    this.hideTopPage.emit(true);
  }

  get allCount() {
    return this._sourceData.length;
  }

}
