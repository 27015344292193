<div class="main_footer">
  <div class="f_box">
    <div class="ip-id_logo_f">

    </div>
    <div class="f_title">
      <h5>
        +7 495 134-57-32
      </h5>
      <h5>
        ООО «Сколковское юрлицо»
      </h5>
    </div>
    <div class="f_login">
      <button class="submit_red_f">
        Войти в личный кабинет
      </button>
    </div>
    <div class="f_trademark">
      Корпоративная политика в отношении персональных данных
      © 2005-2019 ООО «Сколковское юрлицо»
    </div>
  </div>
  <div class="f_box">
    <div class="trade_list f_bottom_list">
      <span class="title_f">Товарные знаки</span>
      <ul>
        <li>
          Поиск товарного знака
        </li>
        <li>
          Поиск нарушений
        </li>
        <li>
          Поиск по изображениям
        </li>
        <li>
          Подборки по категориям
        </li>
        <li>
          Популярные товарные знаки
        </li>
        <li>
          База товарных знаков
        </li>
      </ul>
      <span class="title_f">Фирменные наименования</span>
      <ul>
        <li>
          База юридических лиц
        </li>
        <li>
          Поиск нарушений
        </li>
      </ul>
    </div>

  </div>
  <div class="f_box">
    <div class="trade_list f_bottom_list">
      <span class="title_f">Доменные имена</span>
      <ul>
        <li>
          Проверка занятости
        </li>
        <li>
          Как зарегистировать занятый домен
        </li>
      </ul>
      <span class="title_f">О сервисе</span>
      <ul>
        <li>
          Алогоритм поиска
        </li>
        <li>
          Источники данных
        </li>
        <li>
          Конфиденциальность
        </li>
        <li>
          Конфиденциальность
        </li>
        <li>
          Новости
        </li>
        <li>
          Контакты
        </li>
      </ul>
    </div>
  </div>
</div>
