import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../app-ipid/src/app/shared/shared.module';
import { LayoutModule } from '../../../../app-ipid/src/app/pages/layout/layout.module';
import { CorpSearchComponent } from './components/corp-search/corp-search.component';
import { CorpSearchByNameComponent } from './components/corp-search-by-name/corp-search-by-name.component';
import { CorpSearchByNumberComponent } from './components/corp-search-by-number/corp-search-by-number.component';
import { CorpSearchByOwnerComponent } from './components/corp-search-by-owner/corp-search-by-owner.component';
import { CorpSearchService } from './services/corp-search.service';
import { CorpSearchApiService } from './services/corp-search-api.service';
import { CorpSearchByNameService } from './services/corp-search-by-name.service';
import { CorpSearchByNumberService } from './services/corp-search-by-number.service';
import { CorpSearchByOwnerService } from './services/corp-search-by-owner.service';
import { CorpSearchDomainsComponent } from './components/corp-search-domains/corp-search-domains.component';
import { CorpSearchLegalnamesComponent } from './components/corp-search-legalnames/corp-search-legalnames.component';
import { CorpSearchLoaderComponent } from './components/corp-search-loader/corp-search-loader.component';
import { CorpSearchTrademarkListComponent } from './components/corp-search-trademark-list/corp-search-trademark-list.component';
import { CorpSearchTrademarksComponent } from './components/corp-search-trademarks/corp-search-trademarks.component';
import { CorpSearchInputComponent } from './components/corp-search-input/corp-search-input.component';
import { FormsModule } from '@angular/forms';
import { IpIdSearchModule } from '../../../../app-search/src/app/app.module';
import { CorpSearchRepeatComponent } from './components/corp-search-repeat/corp-search-repeat.component';
import { CorpSearchRepeatService } from './services/corp-search-repeat.service';
import { CorpSearchCreateReportComponent } from './components/corp-search-create-report/corp-search-create-report.component';
import { CorpSearchSaveReportService } from './services/corp-search-save-report.service';
import { RouterModule } from '@angular/router';
import { CorpSearchByImageComponent } from './components/corp-search-by-image/corp-search-by-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import {CorpSearchByImageService} from './services/corp-search-by-image.service';
import { CorpSearchTmComponent } from './components/corp-search-tm/corp-search-tm.component';
import { NgxsModule } from '@ngxs/store';
import { SearchState } from '@web/features/corp/corp-search/states/search.state';
import { CorpSearchTmService } from '@web/features/corp/corp-search/services/corp-search-tm.service';
import {ExpertModule} from '../../../../app-ipid/src/app/pages/layout/expert/expert.mdoule';


@NgModule({
  declarations: [
    CorpSearchComponent,
    CorpSearchByNameComponent,
    CorpSearchByNumberComponent,
    CorpSearchByOwnerComponent,
    CorpSearchDomainsComponent,
    CorpSearchLegalnamesComponent,
    CorpSearchLoaderComponent,
    CorpSearchTrademarkListComponent,
    CorpSearchTrademarksComponent,
    CorpSearchInputComponent,
    CorpSearchRepeatComponent,
    CorpSearchCreateReportComponent,
    CorpSearchByImageComponent,
    CorpSearchTmComponent,
  ],
  providers: [
    CorpSearchService,
    CorpSearchApiService,
    CorpSearchByNameService,
    CorpSearchByNumberService,
    CorpSearchByOwnerService,
    CorpSearchRepeatService,
    CorpSearchSaveReportService,
    CorpSearchByImageService,
    CorpSearchTmService
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    FormsModule,
    IpIdSearchModule,
    RouterModule,
    ImageCropperModule,
    ExpertModule,
    NgxsModule.forFeature([SearchState])
  ],
  exports: [],
})
export class CorpSearchModule {
}
