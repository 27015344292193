import { Component, EventEmitter, Output } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { PromocodesService } from './promocodes.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent {
  public showPromoInput: boolean = false;
  public promoCode: string = '';
  public path: string = 'request/promocode';
  public showSuccess: boolean = false;
  @Output() promoEvent: EventEmitter<string> = new EventEmitter();

  constructor(
    private promoService: PromocodesService,
    private toaster: ToastrService
  ) {
  }

  public getPromoCode(): void {
    if (this.promoCode.length) {
      const params = new HttpParams()
        .set('PromocodeName', this.promoCode);
      this.promoService.getPromoCode(params).subscribe(data => {
        console.log(data, 'code');
        if (data.PromocodeID.length > 1) {
          this.promoEvent.emit(data.PromocodeID);
          this.showSuccess = true;
        } else {
          this.showSuccess = false;
          this.showPromoInput = false;
          this.toaster.info(' Промокод не найден');
        }
      });
    }
  }

}
