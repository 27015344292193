import {
  AfterContentChecked,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { REQUEST_TYPE } from 'projects/app-ipid/src/app/shared/enums/request-type';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

declare var window: any;

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent implements OnInit, OnDestroy, AfterContentChecked {

  @Input() rates;
  @Input() activeTariff;
  @Input() countRates;
  @Input() requestType;
  @Output() selectingTariff = new EventEmitter();
  max2min: boolean;
  max2opty: boolean;
  max2max: boolean;

  max3min: boolean;
  max3opty: boolean;
  max3max: boolean;
  constructor(
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  ngOnInit(): void {
    if (window.innerWidth < 768) {
      this.max2min = false;
      this.max2opty = false;
      this.max2max = false;
      this.max3min = false;
      this.max3opty = false;
      this.max3max = false;
    } else {
      this.max2min = true;
      this.max2opty = true;
      this.max2max = true;
      this.max3min = true;
      this.max3opty = true;
      this.max3max = true;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
      this.max2min = false;
      this.max2opty = false;
      this.max2max = false;
      this.max3min = false;
      this.max3opty = false;
      this.max3max = false;
    } else {
      this.max2min = true;
      this.max2opty = true;
      this.max2max = true;
      this.max3min = true;
      this.max3opty = true;
      this.max3max = true;
    }
  });
  }
  ngAfterContentChecked() {
    if (this.countRates && this.countRates !== 1 && window.innerWidth < 768) {
      this.setTariff();
    }
  }

  selectTariff(event) {
    if (this.requestType === REQUEST_TYPE.CONTRACT) {
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_TARIF, typeIP: this.requestType, tariff: event.title, });
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_ITEM, itemType: event.title });
    }
    if (event.id) {
      switch (event.id) {
        case '000000001':
          this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_MIN });
          break;
        case '000000002':
          this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_OPT });
          break;
        case '000000003':
          this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_MAX });
          break;
        default:
          console.warn(`Для тарифа id: ${event.id} отсутствует dataLayer событие`);
      }
    }
    this.selectingTariff.emit(event);
  }

  ngOnDestroy(): void {
  }

  toCheckMax2Min() {
    if (this.max2min) {
      this.max2opty = false;
      this.max2max = false;
    }
  }

  toCheckMax2Opty() {
    if (this.max2opty) {
      this.max2min = false;
      this.max2max = false;
    }
  }

  toCheckMax2Max() {
    if (this.max2max) {
      this.max2min = false;
      this.max2opty = false;
    }
  }

  toCheckMax3Min() {
    if (this.max3min) {
      this.max3opty = false;
      this.max3max = false;
    }
  }

  toCheckMax3Opty() {
    if (this.max3opty) {
      this.max3min = false;
      this.max3max = false;
    }
  }

  toCheckMax3Max() {
    if (this.max3max) {
      this.max3min = false;
      this.max3opty = false;
    }
  }

  setTariff() {
    if (this.activeTariff?.length) {
      for (const item in this.rates) {
        if (this.rates.hasOwnProperty(item)) {
          if (this.rates[item]?.id === this.activeTariff) {
            switch (this.countRates) {
              case 3:
                switch (item) {
                  case 'min':
                    this.max3min = true;
                    this.max3opty = false;
                    this.max3max = false;
                    break;
                  case 'max':
                    this.max3min = false;
                    this.max3opty = false;
                    this.max3max = true;
                    break;
                  case 'opty':
                    this.max3min = false;
                    this.max3opty = true;
                    this.max3max = false;
                    break;
                }
                break;
              case 2:
                switch (item) {
                  case 'min':
                    this.max2min = true;
                    this.max2opty = false;
                    this.max2max = false;
                    break;
                  case 'max':
                    this.max2min = false;
                    this.max2opty = false;
                    this.max2max = true;
                    break;
                  case 'opty':
                    this.max2min = false;
                    this.max2opty = true;
                    this.max2max = false;
                    break;
                }
                break;
            }
          }
        }
      }
    } else {
      switch (this.countRates) {
        case 3:
          this.max3min = true;
          this.max3opty = false;
          this.max3max = false;
          break;
        case 2:
          this.max2min = true;
          this.max2opty = false;
          this.max2max = false;
          break;
      }
    }
  }
}
