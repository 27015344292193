import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { TrademarkState } from '../../store/trademark.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { REQUEST_TYPES } from '@web/core/models/user.interface';
import { TrademarkActions } from '../../store/trademark.actions';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Tariff } from '@ui/features/tariffes/models/rates.interfaces';
import { TrademarkDraftPromocode, TariffResult } from '../../types/trademark.types';

@Component({
  selector: 'app-tarrif-selection',
  templateUrl: './tarrif-selection.component.html',
  styleUrls: ['./tarrif-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TarrifSelectionComponent {
  @ViewSelectSnapshot(TrademarkState.type) type: REQUEST_TYPES;
  @ViewSelectSnapshot(TrademarkState.tariffes) tariffes: Tariff[];
  @ViewSelectSnapshot(TrademarkState.proocode) proocode: TrademarkDraftPromocode | null;
  @ViewSelectSnapshot(TrademarkState.tariffResult) tariffResult: TariffResult;
  @ViewSelectSnapshot(TrademarkState.activeTariffId) activeTariffId: string | null;
  @Dispatch() backStep = () => new TrademarkActions.BackStep();
  @Dispatch() toPaymentStage = () => new TrademarkActions.ToPaymentStage();
  @Dispatch() selectTariff = (tariff: Tariff['id']) => new TrademarkActions.SelectTariff(tariff);
  @Dispatch() applyPromoCode = (promocode: TrademarkDraftPromocode) => new TrademarkActions.ApplyPromoCode(promocode);
}
