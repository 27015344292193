import {RequestContractService} from '../shared/request-contract.service';
import {HttpService} from '../../../shared/services/http.service';
import {Injectable} from '@angular/core';
import allowedTypes from '../../../../../../shared/types/allowed-types';
import * as _ from 'lodash';

@Injectable()

export class ConditionsService {
  fileError = ''; // ошибка загрузки файла
  constructor(
    private requestContractService: RequestContractService,
    private httpService: HttpService
  ) {
  }

  /**
   * Устанавливаем значение НДС
   */
  setNDS(selectedNDS: string): string {
    if (selectedNDS === 'Не включая НДС') {
      return '0';
    } else if (selectedNDS === 'С НДС 20%') {
      return '20';
    } else {
      return '10';
    }
  }

  /** Событие загрузки файла
   */
  fileChangeEvent(fileInput: any, files: any[], fileLinks: string[]) {
    if (this.fileError?.length) {
      this.fileError = '';
    }
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      if (file) {
        const maxSize = 2000000;
        if (fileInput.target.files[0].size > maxSize) {
          this.fileError = 'Максимальный размер файла ' + maxSize / 1000000 + 'Mb';
          return false;
        }
        if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
          this.fileError = 'Недопустимый тип файла';
          return false;
        }
        if (!this.fileError?.length) {
          const formData: FormData = new FormData();
          formData.append('file', file, file.name);
          this.httpService.post({path: 'request/file/add', body: formData, isFile: true})
            .subscribe((data: any) => {
              files.push({
                fileName: fileInput.target.files[0].name,
                file: fileInput.target.files[0],
                path: data.path,
                size: fileInput.target.files[0].size
              });
              fileInput.target.value = '';
              this.requestContractService.files = files;
              files.forEach(item => fileLinks.push(item.path));
            });
        }
      }
    }
  }

  /** Удаление файла
   */
  removeFile(index, array) {
    array.splice(index, 1);
  }
}
