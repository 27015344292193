import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeclOfNumPipe } from './decl-of-num.pipe';



@NgModule({
  declarations: [DeclOfNumPipe],
  imports: [CommonModule],
  exports: [DeclOfNumPipe],
})
export class DeclOfNumModule { }
