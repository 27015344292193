export const enum PAYMENT_TYPE {
  ACT = 'act',
  ONLINE = 'online',
  OFFLINE = 'cash',
}

export const enum PAYMENT_STATUS {
  SUCCESSED = 'succeeded',
  CANCELED = 'canceled',
}

export interface PaymentTab {
  id: string;
  type: PAYMENT_TYPE;
  title: string;
}

export interface YandexResponseToken {
  id: string;
  confirmation: YandexResponseTokenConfirmation;
}

export interface YandexResponseTokenConfirmation {
  confirmation_token: string;
}

export interface CheckpaymentResponse {
  status: PAYMENT_STATUS;
  cancellation_details: {
    reason: string;
  };
}

export interface YandexPaymentData {
  id: string;
  token: string;
}

export interface PayerForm {
  INN: string;
  sum: string;
  OGRN: string;
  name: string;
  address: string;
}
