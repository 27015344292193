<div *ngIf="showModalUnconnected">
  <app-monitoring-modal
    [data]="dataUnconnectedObject"
    (modalEvent)="closeModal()"
    (selectObjectEvent)="selectUnconnectedObject($event)"
  >
  </app-monitoring-modal>
</div>

<div *ngIf="showModalRisk">
  <app-risk-trademark-modal
    (modalEvent)="closeModal()"
    (hideRiskEvent)="makeRiskHidden($event)"
    [data]="dataRisk"
  >
  </app-risk-trademark-modal>
</div>

<section class="section-position section-position--gradient-monitoring-column">
  <div class="container chart">
    <!--    <h1 class="chart__header">Мониторинг</h1>-->
    <div class="chart__header" [class.chart__header--mobile-row]="kontragents?.length === 1" *ngIf="selectedCompany">

      <button *ngIf="kontragents?.length" class="chart__mini-wrapper chart__mini-wrapper--several"
              [class.chart__mini-wrapper--several-2]="showCompanyList===true">
        <span style="color:#fff">Мониторинг
          <span *ngIf="corporate || selectedCompany.id">по</span>
        </span>
        <a class="company-list__link"
           *ngIf="corporate || selectedCompany.id"
           (click)="hideShowCompanyList()">
          <span>{{ selectedCompany.id ? selectedCompany.name : 'всем организациям'}}</span>
        </a>

        <div *ngIf="showCompanyList===true" (click)="showCompanyList=false"
             class="overlay overlay--transparent">
        </div>

        <div class="chart__companies-wrapper company-list" [class.hide]="showCompanyList===false">
          <ul class="company-list__list">
            <li class="company-list__item"
                [class.company-list__item--selected]="company.name === selectedCompany.name"
                *ngFor="let company of kontragents" (click)="onSelect(company)">
              <p>{{company.name}}</p>
            </li>
          </ul>

        </div>
      </button>
    </div>

    <div class="chart__wrapper">

      <div class="chart__block-wrapper"
           [ngClass]="!(risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length) || !objectsWithMonitoring.length === true ? 'is-active-wrap': ''">
        <div>
          <div class="chart__block"
               *ngIf="!(risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length) || !objectsWithMonitoring.length">
            <p class="chart__name">Состав рисков</p>
            <span class="chart__status">{{loadingRisks ? 'Загрузка...' : 'Нет данных'}}</span>
          </div>
        </div>
        <div class="chart__block chart__block--main">
          <p class="chart__name chart-date chart__name--main" (click)="mobileOpenFirstCard()"
             [ngClass]="compositionRisks === true ? 'is-active': ''">
            <span class="chart__name-title">Состав рисков</span>
            <span
              class="chart__name-counter">{{risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length + hiddenRisksCount}}</span>
          </p>
          <div class="chart-date__wrapper" *ngIf="compositionRisks">
            <div class="chart-date__numbers">
              <p
                class="chart-date__big-count">{{risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length + hiddenRisksCount}}</p>
              <p class="chart-date__big-count-text">Рисков</p>
            </div>
            <div class="chart-date__charts">

              <div class="chart-date__row row">
                <span class="row__color">
                  <span class="row__color--chart row__color--chart--red"
                        [style.width.%]="risksAll.critical.length/(risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length)*200">
                  </span>
                </span>
                <p class="row__number row__number--red">{{risksAll.critical.length + hiddenRisks.critical.length}}</p>
                <p class="row__text">Критичные</p>
              </div>

              <div class="chart-date__row row">
                <span class="row__color">
                  <span class="row__color--chart row__color--chart--orange"
                        [style.width.%]="risksAll.high.length/(risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length)*200">
                  </span>
                </span>
                <p class="row__number row__number--orange">{{risksAll.high.length + hiddenRisks.high.length}}</p>
                <p class="row__text">Высокие</p>
              </div>

              <div class="chart-date__row row">
                <span class="row__color">
                  <span class="row__color--chart row__color--chart--yellow"
                        [style.width.%]="risksAll.possible.length/(risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length)*200">
                  </span>
                </span>
                <p
                  class="row__number row__number--yellow">{{risksAll.possible.length + hiddenRisks.possible.length}}</p>
                <p class="row__text">Средние</p>
              </div>

              <div class="chart-date__row row">
                <span class="row__color">
                  <span class="row__color--chart "
                        [style.width.%]="risksAll.recommendation.length/(risksAll.critical.length + risksAll.high.length + risksAll.possible.length + risksAll.recommendation.length)*200">
                  </span>
                </span>
                <p class="row__number row__number--green ">{{risksAll.recommendation.length}}</p>
                <p class="row__text">Рекомендации</p>
              </div>

              <div class="chart-date__row row">
                <span class="row__color"><span class="row__color--chart row__color--chart--gray"
                                               style="width:200%"></span></span>
                <p class="row__number row__number--gray">{{getCountNoMonitoringRisk()}}</p>
                <p class="row__text">Неподключенных объектов</p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="chart__block-wrapper"
           [ngClass]="!objectsWithMonitoring.length || !(getCountTypeObjects('trademark') + getCountTypeObjects('domains')) ? 'is-active-wrap': ''">
        <div class="chart__block"
             *ngIf="!objectsWithMonitoring.length || !(getCountTypeObjects('trademark') + getCountTypeObjects('domains'))">
          <p class="chart__name">
            Объекты рисков
          </p>
          <span class="chart__status">{{loadingRisks ? 'Загрузка...' : 'Нет данных'}}</span>
        </div>

        <div class="chart__block chart__block--main">
          <p class="chart__name chart-date  chart__name--main" (click)="mobileOpenSecondCard()"
             [ngClass]="objectsRisks === true ? 'is-active': ''">
            <span class="chart__name-title">Объекты рисков</span>
            <!--            <span class="chart__name-counter">{{risksObjAmount}}</span>-->
          </p>
          <div class="chart-date__wrapper" *ngIf="objectsRisks">
            <div class="chart-date__numbers">
              <p class="chart-date__big-count">{{getCountTypeObjects('trademark') + getCountTypeObjects('domains')}}</p>
              <p class="chart-date__big-count-text">Объектов</p>
            </div>
            <div class="chart-date__charts">

              <div class="chart-date__row row">
                <span class="row__color">
                  <span class="row__color--chart row__color--chart--blue"
                        [style.width.%]="getCountTypeObjects('trademark')/(getCountTypeObjects('trademark') + getCountTypeObjects('domains'))*200">
                  </span>
                </span>
                <p class="row__number row__number--blue">{{getCountTypeObjects('trademark')}}</p>
                <p class="row__text">Товарные знаки</p>
              </div>

              <div class="chart-date__row row">
                <span class="row__color">
                  <span class="row__color--chart row__color--chart--lemon"
                        [style.width.%]="getCountTypeObjects('domains')/(getCountTypeObjects('trademark') + getCountTypeObjects('domains'))*200">
                  </span>
                </span>
                <p class="row__number row__number--lemon">{{getCountTypeObjects('domains')}}</p>
                <p class="row__text">Домены</p>
              </div>

            </div>

          </div>
        </div>

      </div>

      <div class="chart__block-wrapper" [ngClass]="dynamicsRisks === true ? 'is-active-wrap': ''">
        <div class="chart__block">
          <p class="chart__name">Динамика рисков</p>
          <span class="chart__status">Скоро</span>
        </div>


        <div class="chart__block chart__block--main">
          <p class="chart__name chart-date chart__name--main" (click)="mobileOpenThirdCard()"
             [ngClass]="dynamicsRisks === true ? 'is-active': ''">
            <span class="chart__name-title">Динамика рисков</span>
            <span class="chart__name-counter chart__name-counter--red">+23</span>
          </p>
          <div class="chart-date__wrapper" *ngIf="dynamicsRisks">
            <div class="chart-date__numbers chart-date__numbers--special">
              <div class="chart-date__big-count-wrap">
                <p class="chart-date__big-count chart-date__big-count--red">+23</p>
                <p class="chart-date__big-count-text chart-date__big-count-text--align">риска <br>за 3 квартал</p>
              </div>
              <div class="chart-date__addition">
                <p class="chart-date__addition--yellow"><span class="chart-date__addition-span">+12 </span>в работе</p>
                <p class="chart-date__addition--green"><span class="chart-date__addition-span">+17 </span>решено</p>
              </div>
            </div>

            <div class="quarter">
              <ul class="quarter__count">
                <li>40</li>
                <li>20</li>
                <li>0</li>
              </ul>
              <div class="quarter__item">
                <ul class="quarter__chart-list">
                  <li class="quarter__chart-item quarter__chart-item--yellow" style="height: 18%"></li>
                  <li class="quarter__chart-item quarter__chart-item--green" style="height: 48%"></li>
                  <li class="quarter__chart-item quarter__chart-item--red" style="height: 78%"></li>
                </ul>
                <span>1 кв.</span>
              </div>
              <div class="quarter__item">
                <ul class="quarter__chart-list">
                  <li class="quarter__chart-item quarter__chart-item--yellow" style="height: 35%"></li>
                  <li class="quarter__chart-item quarter__chart-item--green" style="height: 30%"></li>
                  <li class="quarter__chart-item quarter__chart-item--red" style="height: 45%"></li>
                </ul>
                <span>2 кв.</span>
              </div>
              <div class="quarter__item">
                <ul class="quarter__chart-list">
                  <li class="quarter__chart-item quarter__chart-item--yellow" style="height: 13%"></li>
                  <li class="quarter__chart-item quarter__chart-item--green" style="height: 58%"></li>
                  <li class="quarter__chart-item quarter__chart-item--red" style="height: 21%"></li>
                </ul>
                <span>3 кв.</span>
              </div>
              <div class="quarter__item">
                <ul class="quarter__chart-list">
                  <li class="quarter__chart-item" style="height: 6%"></li>
                  <li class="quarter__chart-item" style="height: 6%"></li>
                  <li class="quarter__chart-item" style="height: 6%"></li>
                </ul>
                <span>4 кв.</span>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<section class="section-position section-position--controls section-position--gradient-monitoring-column"
         style="overflow-x: auto;">
  <div class="container control">
    <button class="control__btn " (click)="switchTypeTab('all'); noObjects=false"
            [class.control__btn--has]="noObjects===false"
            [class.control__btn--active]="typeTab.all">
      <span>Все</span> <span
      class="control__count">{{allCount}}</span>
    </button>
    <button class="control__btn " (click)="switchTypeTab('critical'); noObjects=false"
            [class.control__btn--has]="noObjects===false"
            [class.disabled-tab-btn]="!criticalCount"
            [class.control__btn--active]="typeTab.critical"><span>Критичные</span><span class="control__count"
                                                                                        [class.control__count--critical]="typeTab.critical">
      {{criticalCount}}</span>
    </button>
    <button class="control__btn " (click)="switchTypeTab('high')"
            [class.control__btn--has]="noObjects===false"
            [class.disabled-tab-btn]="!highCount"
            [class.control__btn--active]="typeTab.high"><span>Высокие</span><span class="control__count"
                                                                                  [class.control__count--high]="typeTab.high">{{highCount}}</span>
    </button>
    <button class="control__btn " (click)="switchTypeTab('possible')"
            [class.control__btn--has]="noObjects===false"
            [class.disabled-tab-btn]="!middleCount"
            [class.control__btn--active]="typeTab.possible"><span>Средние</span><span class="control__count"
                                                                                      [class.control__count--possible]="typeTab.possible">
      {{middleCount}}</span>
    </button>
    <button class="control__btn" (click)="switchTypeTab('recommendation')"
            [class.control__btn--has]="noObjects===false"
            [class.disabled-tab-btn]="!recommendedCount"
            [class.control__btn--active]="typeTab.recommendation"><span>Рекомендации</span><span
      class="control__count"
      [class.control__count--recommendation]="typeTab.recommendation">
      {{recommendedCount}}</span>
    </button>
  </div>
</section>
<section class="section-position container">
  <div *ngIf="showMobileControl===true" (click)="showMobileControl=false;makeBodyHidden()"
       class="overlay overlay--search"></div>
  <div class="search-style scroll" *ngIf="checkRisksByTab('risksAll') || loadingRisks">
    <span class="control__mobile"
               [class.control__mobile--active]="showMobileControl===true"
               [class.control__mobile--not-active]="showMobileControl===false"
               (click)="showMobileControl=true; makeBodyHidden()">
      <p>Поиск по мониторингу</p>
    </span>

    <button class="control__mobile-close" *ngIf="showMobileControl === true"
            (click)="showMobileControl=false;makeBodyHidden()"></button>

    <form class="search"
          [ngClass]="showMobileControl === true? 'mobile': ''">
      <div class="search__input-wrap">
        <svg width="21" height="21" fill="none" viewBox="0 0 21 21">
          <circle cx="9" cy="9" r="8" stroke-width="2"/>
          <path d="M15 15L20 20" stroke-width="2" stroke-linecap="round"/>
        </svg>
        <input class="search__input" placeholder="Поиск по объектам" [(ngModel)]="searchText" [disabled]="loadingRisks"
               (ngModelChange)="applyFilters()" name="searchText"/>
      </div>
      <div class="search__wrapper" style="margin-right: 0">
        <div class="dropdown" style="max-width: initial">
          <button class="dropdown__button" (click)="showObjectType = !showObjectType" [disabled]="loadingRisks" style="outline: none;">
            <span *ngIf="getCountCheckedObjects(typeOfObjects) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(typeOfObjects) === 0 ? 'Вид объекта:' : 'Вид объекта:'}}</span>
            <span *ngIf="getCountCheckedObjects(typeOfObjects) > 0" class="dropdown__button-text dropdown__button-text--check">
              Вид объекта: {{getCountCheckedObjects(typeOfObjects)}}</span>
            <img
              class="dropdown__button-img"
              [ngClass]="showObjectType ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showObjectType" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Вид объекта:</span>
              <ng-container *ngFor="let option of typeOfObjects">
                <input class="visual-hidden dropdown__input" type="checkbox" name="{{option.value}}" id="{{option.id}}-type"
                       [(ngModel)]="option.checked"
                       [disabled]="loadingRisks"
                       (ngModelChange)="applyFilters()"
                >
                <label class="dropdown__label"
                       for="{{option.id}}-type">
                  <span>{{option.value}}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="showObjectType || showObject" (click)="showObjectType = false; showObject = false;"
             class="overlay overlay--searchList"
             style="background: none;
                    z-index: 4;">
        </div>
        <div class="dropdown" style="max-width: initial">
          <button class="dropdown__button" (click)="showObject = !showObject" [attr.disabled]="noObjects || loadingRisks ? true : null" style="outline: none;">
            <span *ngIf="getCountCheckedObjects(objectsSelect) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(objectsSelect) === 0 ? 'Объект:' : 'Объект:'}}</span>
            <span *ngIf="getCountCheckedObjects(objectsSelect) > 0" class="dropdown__button-text dropdown__button-text--check">
              Объект: {{getCountCheckedObjects(objectsSelect)}}</span>
            <img
              class="dropdown__button-img"
              [ngClass]="showObject ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showObject" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Объект:</span>
              <ng-container *ngFor="let option of objectsSelect">
                <input class="visual-hidden dropdown__input" type="checkbox" name="{{option.value}}" id="{{option.id}}"
                       [(ngModel)]="option.checked"
                       (ngModelChange)="applyFilters()"
                >
                <label class="dropdown__label"
                       for="{{option.id}}">
                  <span>{{option.value}}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="search__btns-wrapper">
        <button class="btn-blue btn-search" (click)="applyFilters();showMobileControl=false;makeBodyHidden()">Найти
        </button>
        <button class="btn-blue-attr btn-clean" (click)="clearFilters()">Очистить</button>
      </div>
    </form>

    <div
      *ngIf="checkAppliedFilters() && checkRisksByTab('risks')"
      class="filter-text-description">
      <p class="unconnected-table__link-wrap"><b class="unconnected-table__filter">Применен фильтр:</b> показано
        <b>{{checkRisksByTab('risks')}}</b>
        риска
        из
        <b>{{checkRisksByTab('risksAll')}}</b>
        <a class="unconnected-table__link" (click)="clearFilters()">Показать все</a></p>
    </div>
    <div class="filter-text-result filter-text-result--nothing"
         *ngIf="!checkRisksByTab('risks') && checkRisksByTab('risksAll') && !loadingRisks">
      Не найдены риски, соответствующие запросу.<br>
      Попробуйте изменить поиск<br>
      <a class="show-all-link" (click)="clearFilters()">Показать все</a>
    </div>

    <div *ngIf="loadingRisks" class="filter-text-description" style="display: flex; flex-direction: row">
      <span class="loaderAuth"></span>
      <div style="margin-left: 15px">Идет поиск рисков</div>
    </div>

    <div *ngIf="loadingRisks" style="width: 100%; font-size: 20px; max-width: 1230px; margin: auto auto 50px;">
      <div>
        <div class="monitoring__head-table head-table">
          <p class="head-table__first">Объект мониторинга</p>
          <p class="head-table__second">Событие</p>
          <p class="head-table__third">Чем грозит</p>
          <p class="head-table__fourth">Действия</p>
        </div>
      </div>
      <div>
        <div class="monitoring__card-wrapper">
          <div class="monitoring-card" *ngFor="let item of [1, 2, 3, 4]">

            <div class="monitoring-card__img-wrapper"></div>
            <div class="monitoring-card__block">
              <div
                style="background: #929FAB; width: 176px; height: 8px; opacity: 0.1; border-radius: 2px; margin-bottom: 17px; margin-top: 5px;"></div>
              <div
                style="background: #152438; width: 213px; height: 8px; opacity: 0.1; border-radius: 2px; margin-bottom: 12px"></div>
              <div style="background: #929FAB; width: 251px; height: 8px; opacity: 0.1; border-radius: 2px;"></div>
            </div>
            <div class="monitoring-card__notice-wrapper">
              <span class="monitoring-card__lamp" style="background: #818B95; opacity: 0.32;"></span>
              <div
                style="background: #152438; max-width: 294px; width: 100%; height: 8px; opacity: 0.1; border-radius: 2px; margin-bottom: 12px; margin-top: 8px;"></div>
              <div
                style="background: #929FAB;  max-width: 76px;  width: 100%; height: 8px; opacity: 0.1; border-radius: 2px; margin-bottom: 12px; margin-top: 8px;"></div>
            </div>
            <div class="monitoring-card__description-wrapper">
              <div
                style="background: #152438; max-width: 294px; width: 100%; height: 8px; opacity: 0.1; border-radius: 2px; margin-bottom: 12px; margin-top: 8px;"></div>
              <div
                style="background: #152438; width: 241px; height: 8px; opacity: 0.1; border-radius: 2px; margin-bottom: 12px; margin-top: 8px;"></div>
              <div
                style="background: #152438; width: 100px; height: 8px; opacity: 0.1; border-radius: 2px; margin-bottom: 12px; margin-top: 8px;"></div>
            </div>
            <div class="monitoring-card__btn-wrapper">
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="monitoring monitoring--critical"
         *ngIf="!loadingRisks && ((typeTab.all && risksAllForTab.length) || (typeTab.critical && risks.critical.length) || (typeTab.high && risks.high.length) || (typeTab.possible && risks.possible.length) || (typeTab.recommendation && risks.recommendation.length))">
      <div class="monitoring__wrapper">
        <div>
          <div class="monitoring__head-table head-table">
            <p class="head-table__first">Объект мониторинга</p>
            <p class="head-table__second">Событие</p>
            <p class="head-table__third">Чем грозит</p>
            <p class="head-table__fourth">Действия</p>
          </div>
        </div>

        <div class="monitoring__wrapper-double" *ngIf="typeTab.all">
          <div class="monitoring__card-wrapper"
               *ngFor="let risk of risksAllForTab.slice(10 * (pages.all - 1), 10 * pages.all); index as riskIndex">
            <div class="monitoring-card" *ngIf="checkHiddenRisk(risk.risk)"
                 [style.margin-bottom]="risksAllForTab.slice(10 * (pages.all - 1), 10 * pages.all)[riskIndex].risk.level !== risksAllForTab.slice(10 * (pages.all - 1), 10 * pages.all)[riskIndex + 1]?.risk?.level ? '20px': '5px'">

              <div *ngIf="risk.object" (click)="openModalRisk(risk)" class="monitoring-card__img-wrapper">
                <img [src]="getSrcByIndex(risk.object.index[0].id, true, risk.object.imageURL) | proxyImage" alt="item">
              </div>
              <div (click)="openModalRisk(risk)" class="monitoring-card__block">
                <p class="monitoring-card__item">{{risk.type}} {{risk.showId || risk.id}}</p>
                <p *ngIf="risk.object" class="monitoring-card__text">{{risk.object.name}}</p>
              </div>
              <div (click)="openModalRisk(risk)" class="monitoring-card__notice-wrapper">
                <span class="monitoring-card__lamp"
                      [ngClass]="'monitoring-card__lamp' + getColorByLevel(risk.risk.level)"></span>
                <p class="monitoring-card__notice">{{risk.risk.description}}</p>
                <time class="monitoring-card__date">{{risk.risk.date | date:'dd.MM.yyyy' }}</time>
              </div>
              <div (click)="openModalRisk(risk)" class="monitoring-card__description-wrapper">
                <p class="monitoring-card__text monitoring-card__description">{{risk.riskData.threat}}</p>
              </div>
              <div class="monitoring-card__btn-wrapper">
                <button (click)="toLeadForm(risk)" class="monitoring-card__btn">{{risk.riskData.button}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="risksAllForTab.length > 10">
              <!--todo-->
            <app-pagination
              [monitoring]="true"
              [page]="pages.all"
              [currentPageNumber]="pages.all"
              [totalCount]="risksAllForTab.length"
              [itemPerPage]="10"
              (eventChangePage)="changePage($event, 'all')"
            ></app-pagination>
          </div>
        </div>

        <div class="monitoring__wrapper-double" *ngIf="typeTab.critical">
          <div class="monitoring__card-wrapper"
               *ngFor="let risk of risks.critical.slice(10 * (pages.critical - 1), 10 * pages.critical)">
            <div class="monitoring-card" (click)="openModalRisk(risk)" *ngIf="checkHiddenRisk(risk.risk)">

              <div *ngIf="risk.object" class="monitoring-card__img-wrapper">
                <img [src]="getSrcByIndex(risk.object.index[0].id, true, risk.object.imageURL) | proxyImage" alt="item">
              </div>
              <div class="monitoring-card__block">
                <p class="monitoring-card__item">{{risk.type}} {{risk.showId || risk.id}}</p>
                <p *ngIf="risk.object" class="monitoring-card__text">{{risk.object.name}}</p>
              </div>
              <div class="monitoring-card__notice-wrapper">
                <span class="monitoring-card__lamp"></span>
                <p class="monitoring-card__notice">{{risk.risk.description}}</p>
                <time class="monitoring-card__date">{{risk.risk.date | date:'dd.MM.yyyy' }}</time>
              </div>
              <div class="monitoring-card__description-wrapper">
                <p class="monitoring-card__text monitoring-card__description">{{risk.riskData.threat}}</p>
              </div>
              <div class="monitoring-card__btn-wrapper">
                <button (click)="toLeadForm(risk)" class="monitoring-card__btn">{{risk.riskData.button}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="risks.critical.length > 10">
            <app-pagination
              [monitoring]="true"
              [page]="pages.critical"
              [currentPageNumber]="pages.critical"
              [totalCount]="risks.critical.length"
              [itemPerPage]="10"
              (eventChangePage)="changePage($event, 'critical')"
            ></app-pagination>
          </div>
        </div>

        <div class="monitoring__wrapper-double" *ngIf="typeTab.high">
          <div class="monitoring__card-wrapper"
               *ngFor="let risk of risks.high.slice(10 * (pages.high - 1), 10 * pages.high)">
            <div class="monitoring-card" (click)="openModalRisk(risk)">
              <div *ngIf="risk.object" class="monitoring-card__img-wrapper">
                <img [src]="getSrcByIndex(risk.object.index[0].id, true, risk.object.imageURL) | proxyImage" alt="item">
              </div>
              <div class="monitoring-card__block">
                <p class="monitoring-card__item">{{risk.type}} {{risk.showId || risk.id}}</p>
                <p *ngIf="risk.object" class="monitoring-card__text">{{risk.object.name}}</p>
              </div>
              <div class="monitoring-card__notice-wrapper">
                <span class="monitoring-card__lamp monitoring-card__lamp--orange"></span>
                <p class="monitoring-card__notice">{{risk.risk.description}}</p>
                <time class="monitoring-card__date">{{risk.risk.date | date:'dd.MM.yyyy' }}</time>
              </div>
              <div class="monitoring-card__description-wrapper">
                <p class="monitoring-card__text monitoring-card__description">{{risk.riskData.threat}}</p>
              </div>
              <div class="monitoring-card__btn-wrapper">
                <button (click)="toLeadForm(risk)" class="monitoring-card__btn">{{risk.riskData.button}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="risks.high.length > 10">
            <app-pagination
              [monitoring]="true"
              [page]="pages.high"
              [currentPageNumber]="pages.high"
              [totalCount]="risks.high.length"
              [itemPerPage]="10"
              (eventChangePage)="changePage($event, 'high')"
            ></app-pagination>
          </div>
        </div>

        <div class="monitoring__wrapper-double" *ngIf="typeTab.possible">
          <div class="monitoring__card-wrapper"
               *ngFor="let risk of risks.possible.slice(10 * (pages.possible - 1), 10 * pages.possible)">
            <div class="monitoring-card" (click)="openModalRisk(risk)">
              <div *ngIf="risk.object" class="monitoring-card__img-wrapper">
                <img [src]="getSrcByIndex(risk.object.index[0].id, true, risk.object.imageURL) | proxyImage" alt="item">
              </div>
              <div class="monitoring-card__block">
                <p class="monitoring-card__item">{{risk.type}} {{risk.showId || risk.id}}</p>
                <p *ngIf="risk.object" class="monitoring-card__text">{{risk.object.name}}</p>
              </div>
              <div class="monitoring-card__notice-wrapper">
                <span class="monitoring-card__lamp monitoring-card__lamp--yellow"></span>
                <p class="monitoring-card__notice">{{risk.risk.description}}</p>
                <time class="monitoring-card__date">{{risk.risk.date | date:'dd.MM.yyyy' }}</time>
              </div>
              <div class="monitoring-card__description-wrapper">
                <p class="monitoring-card__text monitoring-card__description">{{risk.riskData.threat}}</p>
              </div>
              <div class="monitoring-card__btn-wrapper">
                <button (click)="toLeadForm(risk)" class="monitoring-card__btn">{{risk.riskData.button}}</button>
              </div>
            </div>
          </div>
          <div *ngIf="risks.possible.length > 10">
            <app-pagination
              [monitoring]="true"
              [page]="pages.possible"
              [currentPageNumber]="pages.possible"
              [totalCount]="risks.possible.length"
              [itemPerPage]="10"
              (eventChangePage)="changePage($event, 'possible')"
            ></app-pagination>
          </div>
        </div>

        <div class="monitoring__wrapper-double" *ngIf="typeTab.recommendation">
          <div class="monitoring__card-wrapper"
               *ngFor="let risk of risks.recommendation.slice(10 * (pages.recommendation - 1), 10 * pages.recommendation)">
            <div class="monitoring-card" (click)="openModalRisk(risk)">
              <div *ngIf="risk.object" class="monitoring-card__img-wrapper">
                <img [src]="getSrcByIndex(risk.object.index[0].id, true, risk.object.imageURL) | proxyImage" alt="item">
              </div>
              <div class="monitoring-card__block">
                <p class="monitoring-card__item">{{risk.type}} {{risk.showId || risk.id}}</p>
                <p *ngIf="risk.object" class="monitoring-card__text">{{risk.object.name}}</p>
              </div>
              <div class="monitoring-card__notice-wrapper">
                <span class="monitoring-card__lamp monitoring-card__lamp--green"></span>
                <p class="monitoring-card__notice">{{risk.risk.description}}</p>
                <time class="monitoring-card__date">{{risk.risk.date | date:'dd.MM.yyyy' }}</time>
              </div>
              <div class="monitoring-card__description-wrapper">
                <p class="monitoring-card__text monitoring-card__description">{{risk.riskData.threat}}</p>
              </div>
              <div class="monitoring-card__btn-wrapper">
              </div>
            </div>
          </div>
          <div *ngIf="risks.recommendation.length > 10">
            <app-pagination
              [monitoring]="true"
              [page]="pages.recommendation"
              [currentPageNumber]="pages.recommendation"
              [totalCount]="risks.recommendation.length"
              [itemPerPage]="10"
              (eventChangePage)="changePage($event, 'recommendation')"
            ></app-pagination>
          </div>
        </div>

      </div>
      <div class="monitoring__risks" (click)="viewHiddenRisks()" *ngIf="hiddenRisksCount && !showHiddenRisks">
        <a>Показать принятые риски</a>
        <span>{{hiddenRisksCount}}</span>
      </div>
    </div>

    <div *ngIf="attention" class="monitoring-attention">
      <p>Скрытый риск не будет отображаться в мониторинге. Вы всегда можете найти его в скрытых рисках.<span
        (click)="viewHiddenRisks()"> Посмотреть скрытые риски.</span>
      </p>
      <span class="monitoring-attention__close" (click)="attention = false">
        <span class="monitoring-attention__close-text">Закрыть</span>
      </span>
    </div>


  </div>
  <ng-container *ngIf="!checkRisksByTab('risksAll') && !loadingRisks">
    <ng-container *ngIf="objectsWithoutMonitoring.length; else notUnconnected">
      <ng-container *ngIf="objectsWithMonitoring.length; else notMonitoring">
        <div class="monitoring" style="max-width: none">
          <img class="monitoring__img" src="/assets/images/nothing.svg" alt="nothing"/>
          <p class="monitoring__head">Риски не найдены</p>
          <p class="monitoring__desc">Вы можете подключить объекты для мониторинга ниже.</p>
          <button class="btn-blue btn-blue--add" (click)="scrollTo('unconnected-table--opened')">Добавить объект
          </button>
        </div>
      </ng-container>

      <ng-template #notMonitoring>
        <div class="monitoring" style="max-width: none">
          <img class="monitoring__img" src="/assets/images/nothing.svg" alt="nothing"/>
          <p class="monitoring__head">Нет объектов для мониторинга</p>
          <p class="monitoring__desc">Вы можете подключить объекты для мониторинга ниже.</p>
          <button class="btn-blue btn-blue--add" (click)="scrollTo('unconnected-table--opened')">Добавить объект
          </button>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #notUnconnected>
      <ng-container *ngIf="objectsWithMonitoring.length; else notMonitoring">
        <div class="monitoring" style="max-width: none">
          <img class="monitoring__img" src="/assets/images/nothing.svg" alt="nothing"/>
          <p class="monitoring__head">Риски не найдены</p>
        </div>
      </ng-container>

      <ng-template #notMonitoring>
        <div class="monitoring" style="max-width: none">
          <img class="monitoring__img" src="/assets/images/nothing.svg" alt="nothing"/>
          <p class="monitoring__head">Нет объектов для мониторинга</p>
          <p class="monitoring__desc">Для подключения мониторинга необходимо добавить объекты в портфель</p>
          <button class="btn-blue btn-blue--add" routerLink="/portfolio">Добавить объект</button>
        </div>
      </ng-template>
    </ng-template>

  </ng-container>


  <div class="search-style" *ngIf="loadingUnconnectedObjects || objectsWithoutMonitoring.length">
    <div class="unconnected-table unconnected-table--opened">
      <!--      таблица на дивах потому что межстроковый разрыв в table сделать нельзя-->
      <h2 class="unconnected-table__head">Неподключенные объекты <span
        *ngIf="objectsWithoutMonitoring.length"
        class="unconnected-table__head-count">{{objectsWithoutMonitoring.length}}</span></h2>
      <div class="unconnected-table__main-row" *ngIf="objectsWithoutMonitoring.length && !loadingUnconnectedObjects">
        <div class="unconnected-table__my-obj">Мой объект</div>
        <div class="unconnected-table__name">Наименование объекта</div>
        <div class="unconnected-table__category">Критичные</div>
        <div class="unconnected-table__category">Высокие</div>
        <div class="unconnected-table__category">Средние</div>
        <div class="unconnected-table__category">Рекомендации</div>
        <div class="unconnected-table__choose">Выбрать</div>
      </div>
      <div *ngIf="loadingUnconnectedObjects">
        <app-spinner class="spinner-full"></app-spinner>
      </div>

      <!--      <ng-container *ngIf="objectsWithoutMonitoring.length && objectsWithoutMonitoring[0]?.risks">-->
      <ng-container *ngIf="!loadingUnconnectedObjects">
        <div *ngFor=" let obj of objectsWithoutMonitoring">
          <div class="unconnected-table__row" [class.unconnected-table__row--active]="obj.checked"
               (click)="openModalRisk(obj, true)">
            <div class="unconnected-table__icon" style="height: 68px;">
              <img *ngIf="obj.imageURL" alt="" width="27" height="36" [src]="obj.imageURL | proxyImage"
                   style="width: auto"/>
              <img *ngIf="!obj.imageURL" alt="" [src]="getSrcByIndex(obj.index[0].id) | proxyImage"/>
              <p *ngIf="!obj.imageURL && obj.index[0].id !== 'domains'">ЕГРЮЛ</p>
            </div>
            <div class="unconnected-table__text">{{obj.name}}</div>
            <div class="unconnected-table__count unconnected-table__count--red"
                 [class.unconnected-table__count--bigNumber]="getCountRisksByLevel(4, obj) > 99">
              <small>Критичные</small>
              <span class="unconnected-table__count-number">{{getCountRisksByLevel(4, obj)}}</span>
            </div>
            <div class="unconnected-table__count unconnected-table__count--orange"
                 [class.unconnected-table__count--bigNumber]="getCountRisksByLevel(3, obj) > 99">
              <small>Высокие</small>
              <span class="unconnected-table__count-number">{{getCountRisksByLevel(3, obj)}}</span>
            </div>
            <div class="unconnected-table__count unconnected-table__count--yellow"
                 [class.unconnected-table__count--bigNumber]="getCountRisksByLevel(2, obj) > 99">
              <small>Средние</small>
              <span class="unconnected-table__count-number">{{getCountRisksByLevel(2, obj)}}</span>
            </div>
            <div class="unconnected-table__count unconnected-table__count--green"
                 [class.unconnected-table__count--bigNumber]="getCountRisksByLevel(1, obj) > 99">
              <small>Рекоменд.</small>
              <span class="unconnected-table__count-number">{{getCountRisksByLevel(1, obj)}}</span>
            </div>
            <div class="unconnected-table__checkbox">
              <input class="unconnected-table__checkbox--input" id="{{obj.id}}" type="checkbox"
                     (change)="addObject($event, obj)"
                     [checked]="isChecked(obj)"
                     (click)="$event.stopPropagation()">
              <label class="unconnected-table__checkbox--label" for="{{obj.id}}">
                <span>Выбрать для подключения</span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>


      <div class="unconnected-table__btn-wrap"
           [ngStyle]="{'position': showMore?'sticky':'absolute','width':showMore?'100%':'fit-content',
           'padding-top':showMore?'20px':'0'}">
        <button class="unconnected-table__btn"
                [class.unconnected-table__btn--active]="checkedObjects.length && selectedCompany.id"
                [class.unconnected-table__btn--loading]="monitoringAddService.isLoading"
                (click)="onAddObjects()"
        >
          <ng-container *ngIf="!monitoringAddService.isLoading">
            Подключить выбранные объекты <span
            *ngIf="checkedObjects.length">({{checkedObjects.length}})</span>
          </ng-container>
          <app-spinner class="spinner" *ngIf="monitoringAddService.isLoading"></app-spinner>
          <div *ngIf="checkedObjects.length && !selectedCompany.id" class="tooltiptext">Необходимо выбрать организацию
          </div>
        </button>
      </div>

    </div>
  </div>
</section>

<app-expert>
</app-expert>


