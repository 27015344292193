import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TariffesComponent } from './components/tariffes/tariffes.component';
import { TariffCardComponent } from './components/tariff-card/tariff-card.component';
import { ToLacaleStringModule } from '@ui/pipes/to-lacale-string/to-lacale-string.module';
import { SwitchMultiCaseModule } from '@ui/pipes/switch-multi-case/switch-multi-case.module';

@NgModule({
  declarations: [
    TariffesComponent,
    TariffCardComponent,
  ],
  imports: [
    CommonModule,
    ToLacaleStringModule,
    SwitchMultiCaseModule,
  ],
  exports: [TariffesComponent],
})
export class TariffesModule { }
