import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { IEvents, IResponse, ITasks, ITracks } from '../../shared/models/main.interfaces';


const path = 'tasks';
const eventsPath = 'events';

const newCasesPath = 'cases';

@Injectable({
  providedIn: 'root'
})
export class CasesService {

  constructor(private http: HttpService) {
  }

  getCases() {
    return this.http.get({path: `${newCasesPath}`, subjectType: 'cases'});
  }

  getCase(params, id) {
    return this.http.get<ITasks>({path: `${newCasesPath}/${id}`, subjectType: 'cases', params});
  }

  getCountCases() {
    return this.http.get({path: `${newCasesPath}/count`, subjectType: 'cases'});
  }

  fetchTasks(params?) {
    return this.http.get<ITasks>({path: `${path}`, subjectType: 'tasks', params});
  }

  getTasks(params, permissions) {
    console.log(permissions, 'from service');
    return this.http.get<ITasks>({path: `${path}`, subjectType: 'tasks', params});
  }

  fetchTasksById(params) {
    return this.http.get<IResponse>({path: `${path}`, subjectType: 'tasks', params});
  }

  fetchTracks(params) {
    return this.http.get<ITracks>({path: `${path}/tracks`, subjectType: 'tracks', params});
  }

  fetchEvents(params) {
    return this.http.get<IEvents>({path: `${eventsPath}/events`, subjectType: 'events', params});
  }

  addQuestion(params, body) {
    return this.http.post({path: `${path}/question`, subjectType: 'question', params, body});
  }

  addComment(params, body) {
    return this.http.post({path: `${eventsPath}/comment`, subjectType: 'comment', params, body});
  }

  closeAgreement(params) {
    return this.http.get({path: `${eventsPath}/read`, subjectType: 'events', params});
  }

  eventAgree(params) {
    return this.http.get({path: `${eventsPath}/agree`, subjectType: 'events', params});
  }

  deleteTask(params) {
    return this.http.get<IResponse>({path: `${path}/delete`, subjectType: 'delete task', params});
  }

}
