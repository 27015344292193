<ng-container *ngIf="isLoadingContracts; else loader">
  <section *ngIf="docsPage && contracts" class="section-position section-position__color-bg ">
    <div class="container header-court">
      <div class="header-court__block header-court__block--head">
        <a [routerLink]="contract.url"
           [queryParams]="contract.tab ? {tab: contract.tab} : null" class="back-link">
              <span>
                <svg width="12" height="8" viewBox="0 0 21 16">
                  <g clip-path="url(#clip0)">
                   <path
                     d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                    <rect width="21" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
          Назад
        </a>
        <p *ngIf="contracts.valid" class="header-court__state">Действует</p>
        <p *ngIf="!contracts.valid" class="header-court__state header-court__state--invalid">Не действует</p>
        <h1 *ngIf="!editHeader"> {{realName}}
          <span
            (click)="editHeader = true"></span></h1>
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          *ngIf="editHeader"
          [(ngModel)]="realName"
          class="edit_header"
          placeholder="Редактировать наименование"
          value="Договор № {{contracts.contractNumber}} от {{contracts.contractDate | date:'dd.MM.yyyy'}}"
          type="text"
          (focusout)="editHeaders()"
          (keydown.enter)="editHeaders()"
        ></textarea>
        <span *ngIf="paramError" class="error_label_text">{{errorText}}</span>
      </div>
      <div class="header-court__block header-court__block--row">
        <div class="header-court__card header-court__card--docs-height">
          <div class="header-court__card-top">
            <p class="header-court__sub">Номер и дата регистрации в Роспатенте</p>
            <h2 class="header-court__card-bottom">{{contracts.contractNumber}}
              ОТ {{contracts.contractDate | date:'dd.MM.yyyy'}}
            </h2>
          </div>
          <a *ngIf="contractChanges" class="header-court__card--gray header-court__card--link"
             (click)="history=true; toCheckHistory();">История изменений</a>
        </div>
        <div class="header-court__card header-court__card--gray header-court__card--docs-height">
          <div class="header-court__card-top">
            <p class="header-court__sub">Право предоставляет</p>
            <h2 class="header-court__card-bottom">{{contracts.assignor.name}}</h2>
          </div>
          <p class="header-court__card--gray ">ОГРН {{contracts.assignor.PSRN}} / ИНН {{contracts.assignor.TIN}}</p>
        </div>
        <div class="header-court__card header-court__card--gray header-court__card--docs-height">
          <div class="header-court__card-top">
            <p class="header-court__sub">Право получает</p>
            <h2 class="header-court__card-bottom">{{contracts.assignee.name}}</h2>
          </div>
          <p class="header-court__card--gray">ОГРН {{contracts.assignee.PSRN}} / ИНН {{contracts.assignee.TIN}}</p>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="courtPage && contracts" class="section-position section-position__color-bg ">
    <div class="container header-court">
      <div class="header-court__block header-court__block--head">
        <button *ngIf="contracts.valid" class="state__btn state__btn--valid">Действует</button>
        <button *ngIf="!contracts.valid" class="state__btn state__btn--invalid">Не действует</button>
        <h1>Договор № {{contracts.contractNumber}} от {{contracts.contractDate | date:'dd.MM.yyyy'}}</h1>
      </div>
      <div class="header-court__block header-court__block--row">
        <div class="header-court__card header-court__card--court-height">
          <div class="header-court__card-top">
            <p class="header-court__sub">Номер и дата регистрации в Роспатенте</p>
            <h2 class="header-court__card-bottom"> {{contracts.contractNumber}}
              ОТ {{contracts.contractDate | date:'dd.MM.yyyy'}}</h2>
          </div>
          <a *ngIf="contractChanges" (click)="overviewCourt=false; historyCourt= true;" class="header-court__card--gray">История
            изменений</a>
        </div>
        <div class="header-court__card header-court__card--gray header-court__card--court-height">
          <div class="header-court__card-top">
            <p class="header-court__sub">Право предоставляет</p>
            <h2 class="header-court__card-bottom">{{contracts.assignor.name}}</h2>
          </div>
          <p class="header-court__card--gray ">ОГРН {{contracts.assignor.PSRN}} / ИНН {{contracts.assignor.TIN}}</p>

        </div>
        <div class="header-court__card header-court__card--gray header-court__card--court-height">
          <div class="header-court__card-top">
            <p class="header-court__sub">Право получает</p>
            <h2 class="header-court__card-bottom">{{contracts.assignee.name}}</h2>
          </div>
          <p class="header-court__card--gray">ОГРН {{contracts.assignee.PSRN}} / ИНН {{contracts.assignee.TIN}}</p>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="docsPage" class="section-position section-position__color-bg section-position--padding">
    <div class="container control">
      <button class="control__btn contr__button" (click)="overview=true; toCheckOverview(); clearAll()"
              [class.control__btn--active]="overview">
        <span>Обзор</span>
        <span class="control__count"></span>
      </button>
      <button [disabled]="!subContracts" class="control__btn contr__button"
              (click)="license=true; toCheckLicense(); clearAll()"
              [class.control__btn--active]="license">
        <span>Сублицензии</span>
        <span *ngIf="subContracts" class="control__count">{{subContracts.length}}</span>
        <span *ngIf="!subContracts" class="control__count">0</span>
      </button>
      <button [disabled]="!contractChanges" class="control__btn contr__button"
              (click)="history=true; toCheckHistory(); clearAll()"
              [class.control__btn--active]="history">
        <span>История изменений</span>
        <span *ngIf="contractChanges" class="control__count">{{contractChanges.length}}</span>
        <span *ngIf="!contractChanges" class="control__count">0</span>
      </button>
    </div>
  </section>
  <section *ngIf="courtPage" class="section-position section-position__color-bg section-position--padding">
    <div class="container control">
      <button class="control__btn control__btn--court contr__button" (click)="overviewCourt=true; historyCourt= false;"
              [class.control__btn--active]="overviewCourt"
      >
        <span>Обзор</span>
        <span class="control__count"></span>
      </button>
      <button [disabled]="!contractChanges" class="control__btn control__btn--court contr__button"
              (click)="overviewCourt=false; historyCourt= true;"
              [class.control__btn--active]="historyCourt"
      >
        <span>История рассмотрения</span>
        <span *ngIf="contractChanges" class="control__count">{{contractChanges.length}}</span>
      </button>
    </div>
  </section>

  <section *ngIf="docsPage && contracts" class="section-position section-position--white">
    <div *ngIf="overview" class="container inset contracts-ins">

      <div *ngIf="contracts" class="to-do contracts-ins__block contracts-ins__block--options ribbon-box"
           style="margin-bottom: 0">
        <!--      <div class="ribbon ribbon-top-right"><span>СКОРО</span></div>-->
        <button (click)="toLeadForm('Продление договора')" class="to-do__btn ">Продлить договор
          <!--        <span class="prompt__hidden">-->
          <!--             <p>Скоро появится опция "Продлить договор"</p>-->
          <!--          </span>-->
        </button>
        <button (click)="toLeadForm('Внесение изменений в договор')" class="to-do__btn ">Внести изменения
          <!--        <span class="prompt__hidden">-->
          <!--             <p>Скоро появится опция "Внести изменения"</p>-->
          <!--          </span>-->
        </button>
        <button (click)="toLeadForm('Расторжение договора')" class="to-do__btn ">Расторгнуть договор
          <!--        <span class="prompt__hidden">-->
          <!--             <p>Скоро появится опция "Расторгнуть договор"</p>-->
          <!--          </span>-->
        </button>
      </div>

      <div class="contracts-ins__block contracts-ins__block--comment">
        <div class="comment__wrap comment--court">
          <h3>Комментарий</h3>
          <textarea *ngIf="commentParam" class="comment  comment--court comment_input"
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="7"
                    #textArea
                    placeholder="Вы можете добавить свой комментарий"
                    (focusout)="postComment()"
                    value="{{commentParam}}"></textarea>

          <textarea *ngIf="!commentParam" (focusout)="postComment()"
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="7"
                    placeholder="Вы можете добавить свой комментарий"
                    class="comment  comment--court comment_input"
                    type="text" [(ngModel)]="contractComment">
          </textarea>

          <span></span>
        </div>
        <div>
          <div class="text">
            <h4>Условия договора</h4>
            <p>{{getFullTerms()}} <span *ngIf="getFullTerms().length >= 200 && fullTerms === false"
                                        (click)="fullTerms = true;getFullTerms()">Читать далее</span>
            </p>
          </div>
          <hr>

          <div *ngIf="contracts">
            <div class="objects">
              <h2>Объекты договора {{contracts.tradeMarks.length}}</h2>
              <div class="objects__wrapper">
                <div *ngFor="let object of objects" class="objects__block">
                  <div *ngIf="!object.markImageFileName" class="objects__img">
                    <p class="image_text">
                      {{object.imageText}}
                    </p>
                  </div>
                  <div *ngIf="object.markImageFileName" class="objects__img">
                    <img class="tm_image"
                         src="{{getImage(object.index, object.registrationString, object.markImageFileName, object)}}">
                  </div>
                  <div class="objects__sub-text">
                    <p>Товарный знак <span>RU {{object.registrationString}}</span> от
                      <span>{{object.registrationDate | date:'dd.MM.yyyy'}}</span></p>
                  </div>
                  <br>
                  <ul class="classes_list__parent">
                    <li *ngFor="let service of object.goodsServices" class="classes_list">
                      {{service.classNumber}}
                    </li>
                  </ul>
                  <!--                <p *ngFor="let service of object.goodsServices" class="objects__text">-->
                  <!--                  {{service.classNumber}}-->
                  <!--                </p>-->
                  <!--                <button class="objects__btn">3 события в мониторинге</button>-->
                </div>
              </div>
            </div>
            <button *ngIf="objectsInitial.length > 3 && showObjectsButton" (click)="getAllObjects()" class="docs__show">
              Посмотреть еще
            </button>
          </div>
        </div>
      </div>

      <div class="docs contracts-ins__block contracts-ins__block--file top_block">
        <div class="docs__main-wrapper">
          <div class="files_header">
            <h4 (click)="uploadFile()">Файлы</h4>
            <button class="docs__add not-mobile">
              <span class="docs__add-text">Прикрепить файл</span>
              <span class="docs__plus-icon" (click)="triggerClick()">
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12" fill="#00AFEC"/>
                  <rect x="11" y="7" width="2" height="10" fill="white"/>
                  <rect x="17" y="11" width="2" height="10" transform="rotate(90 17 11)"/>
                </svg>
              <input
                type="file"
                class="dn"
                (change)="onFileUpload($event)"
                #fileInput
              >
            </span>
            </button>
          </div>
          <p class="file-error" *ngIf="fileError?.length">{{fileError}}</p>

          <div class="files_parent">
            <div *ngFor="let file of files" class="docs__block">
              <div class="docs__text-wrapper">
                <span>{{file.name}}</span>
                <div class="files-btn">
                  <a class="files-btn__download" (click)="downloadFiles.downloadFile(file)">Скачать</a>
                </div>
              </div>
              <div class="docs__icon-wrapper">
                <span (click)="deleteFile(file.publicPath)" class="docs__icon">
                 <img src="/assets/images/delete-small.png" class="docs__icon--mini">
                 <img src="/assets/icons/gray-empty-contracts.svg" style="cursor: pointer;">
                </span>
              </div>
            </div>
          </div>
          <div class="btns-wrapper-mobile">
            <button class="docs__add mobile" (click)="triggerClick()">
              <span class="docs__add-text">Прикрепить файл</span>
            </button>
            <button *ngIf="showAllFiles" (click)="getAllFiles()" class="docs__show">Посмотреть еще</button>
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="license" class="container inset">
      <div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="filter__search-input-wrap">
          <span (click)="openModal()">Поиск по документам</span>
          <button class="filter__search-close" *ngIf="showMobileControl === true"
                  (click)="closeModal()"></button>
        </div>
      </div>

      <form class="contracts-form" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="contracts-form__inputs">
          <input
            type="text"
            class="contracts-form__universal"
            placeholder="Лицо, получающее право"
            [(ngModel)]="searchTerm"
            (ngModelChange)="searchAssignee()"
            [ngModelOptions]="{standalone: true}"
          >
          <div tabindex="0" (focusout)="statesShow=false"
               class="contracts-form__universal contracts-form__universal--select"
               [class.contracts-form__universal--select--rotate]="statesShow===true">
            <p (click)="statesShow=true"
               [class.contracts-form__state-placeholder]="selectedState==='Статус'">{{selectedState}}</p>
            <div *ngIf="statesShow===true" class="contracts-form__hidden">
              <p *ngFor="let option of states" class="contracts-form__option-label"
                 (click)="onSelect(option)">{{option}}</p>
            </div>
          </div>
          <app-filter-daterange
            [placeholder]="placeholder"
            [value]="value"
            (apply)="receiveDate($event); searchDateSubcontracts()"
          >
          </app-filter-daterange>
        </div>
        <div class="search__btns">
          <button class="search__btn btn-search" (click)="searchDateSubcontracts(); closeModal()">Найти</button>
          <button class="search__btn btn-clean" (click)="clearAll()">Очистить</button>
        </div>
      </form>
      <div class="filter-text-result"
           *ngIf="(value.length || searchTerm || selectedState !== 'Статус') && subContractsCopy.length">
        <b>Применен фильтр:</b>
        Показано
        <b>{{subContractsCopy.length}}</b>
        договоров из
        <b>{{subContracts.length}}</b>
        <a class="show-all-link" (click)="clearAll()">Показать все</a>
      </div>

      <ng-container *ngIf="!subContractsCopy.length && subContracts.length; else tableLicense">
        <div class="filter-text-result filter-text-result--nothing"
             *ngIf="!subContractsCopy.length && subContracts.length">
          Не найдены объекты, соответствующие запросу.<br>
          Попробуйте изменить поиск<br>
          <a class="show-all-link" (click)="clearAll()">Показать все</a>
        </div>
      </ng-container>
      <ng-template #tableLicense>
        <table class="table-license">
          <tr>
            <th class="table-license__registration">Номер и дата регистрации</th>
            <th class="table-license__circumstances">Условия договора</th>
            <th class="table-license__owner">Лицо, получающее право</th>
            <th class="table-license__state-head">Статус</th>
          </tr>
          <tr *ngFor="let sub of subContractsCopy">
            <td class="table-license__date">{{sub.contractNumber}} ОТ {{sub.contractDate | date: 'dd.MM.yyyy'}}</td>
            <td class="table-license__text" data-label="Условия договора">
              <p *ngIf="subContracts.changesContract">{{sub.changesContract[0]?.contractTerms}}</p>
              <p *ngIf="!subContracts.changesContract">{{sub.contractTerms}}</p>
            </td>
            <td class="table-license__company-wrap" data-label="Лицо, получающее право">
              <!--          <p class="table-license__company">{{date.company}}</p>-->
              <p class="table-license__company-info">{{sub.assignee.shortLegalName}}</p>
            </td>
            <td class="table-license__state-wrap">
              <button *ngIf="sub.valid" class="state__btn state__btn--valid">Действует</button>
              <button *ngIf="!sub.valid" class="state__btn state__btn--invalid">Не действует</button>
            </td>
          </tr>
        </table>
      </ng-template>
    </div>
    <!--  todo isrtoriya-->

    <div *ngIf="history" class="container inset">
      <div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="filter__search-input-wrap">
          <span (click)="openModal()">Поиск по истории</span>
          <button class="filter__search-close" *ngIf="showMobileControl === true"
                  (click)="closeModal()"></button>
        </div>
      </div>
      <form class="contracts-form" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="contracts-form__inputs">
          <input type="text" class="contracts-form__universal contracts-form__universal--history"
                 placeholder="Поиск по тексту"
                 [(ngModel)]="searchTermHistory"
                 (ngModelChange)="searchHistory()"
                 [ngModelOptions]="{standalone: true}"
          >
          <app-filter-daterange
            [placeholder]="placeholder"
            [value]="value"
            (apply)="receiveDate($event); searchDateHistory()"
          >
          </app-filter-daterange>
        </div>
        <div class="search__btns">
          <button class="search__btn btn-search" (click)="searchDateHistory(); closeModal()">Найти</button>
          <button class="search__btn btn-clean" (click)="clearAll()">Очистить</button>
        </div>
      </form>

      <div class="filter-text-result" *ngIf="(value.length || searchTermHistory) && contractChangesCopy.length">
        <b>Применен фильтр:</b>
        Показано
        <b>{{contractChangesCopy.length}}</b>
        договоров из
        <b>{{contractChanges.length}}</b>
        <a class="show-all-link" (click)="clearAll()">Показать все</a>
      </div>

      <ng-container *ngIf="!contractChangesCopy.length && contractChanges.length; else tableHistory">
        <div class="filter-text-result filter-text-result--nothing"
             *ngIf="!contractChangesCopy.length && contractChanges.length">
          Не найдены объекты, соответствующие запросу.<br>
          Попробуйте изменить поиск<br>
          <a class="show-all-link" (click)="clearAll()">Показать все</a>
        </div>
      </ng-container>

      <ng-template #tableHistory>
        <table class="table-history">
          <tr>
            <th class="table-history__type">Тип</th>
            <th class="table-history__registration">Номер и дата регистрации</th>
            <th class="table-history__description">Описание</th>
          </tr>
          <tr *ngFor="let changed of contractChangesCopy">
            <td data-label="Тип">Внесение изменений</td>
            <td data-label="Номер и дата регистрации изменений">{{contracts.contractNumber}}
              ОТ {{changed.changeDate | date: 'dd.MM.yyyy' }}</td>
            <!--        <td data-label="Описание" class="table-history__text" [class.table-history__text&#45;&#45;open]="item.isShow === true">-->
            <td data-label="Описание" class="table-history__text">
              <p>{{changed.contractTerms}}</p>
              <!--          <span (click)="item.isShow = true;" *ngIf="item.text.length>128 && item.isShow===false" class="table-history__link-->
              <!--          table-history__link&#45;&#45;next">далее...</span>-->
            </td>
          </tr>
        </table>
      </ng-template>
    </div>

    <!--  todo history end-->
  </section>

  <section *ngIf="courtPage" class="section-position section-position--white">
    <div *ngIf="overviewCourt && overviewVar1" class="container inset contracts-ins">
      <!--    <button style="position: absolute; top: 0;" (click)="overviewVar1=true;overviewVar2=false">Вариант1</button>-->
      <!--    <button style="position: absolute; left: 189px; top: 0;" (click)="overviewVar2=true;overviewVar1=false">Вариант2-->
      <!--    </button>-->

      <!-- Задать вопрос-->
      <div class="to-do contracts-ins__block to-do--court contracts-ins__block--options">
        <button class="to-do__btn ">Задать вопрос по делу
          <span class="prompt__hidden">
                 <p>Скоро появится опция "Задать вопрос по делу"</p>
              </span>
        </button>
        <button class="to-do__btn ">Заключить мировое соглашение
          <span class="prompt__hidden">
                 <p>Скоро появится опция "Заключить мировое соглашение"</p>
              </span>
        </button>
      </div>

      <!-- Комментарий -->
      <div class="contracts-ins__block contracts-ins__block--comment">
        <div class="comment comment--court">
          <h3>Комментарий</h3>
          <textarea *ngIf="commentParam" class="comment  comment--court comment_input"
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="7"
                    #textArea
                    placeholder="Вы можете добавить свой комментарий"
                    (focusout)="postComment()"
                    value="{{commentParam}}"></textarea>
          <textarea *ngIf="!commentParam" (focusout)="postComment()"
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="7"
                    placeholder="Вы можете добавить свой комментарий"
                    class="comment  comment--court comment_input"
                    type="text" [(ngModel)]="contractComment">
        </textarea>
          <span></span>
        </div>

        <div class="" *ngIf="contracts">
          <!-- Условия договора-->
          <div class="text text--court">
            <h4>Условия договора</h4>
            <p>{{getFullTerms()}} <span *ngIf="getFullTerms().length >= 200 && fullTerms === false"
                                        (click)="fullTerms = true;getFullTerms()">Читать далее</span>
          </div>
          <hr>
          <!-- Объекты-->
          <div class="objects">
            <h2>Объекты</h2>
            <div class="objects__wrapper">
              <div *ngFor="let object of contracts.tradeMarks" class="objects__block">
                <div *ngIf="!object.markImageFileName" class="objects__img">
                  <p class="image_text">
                    {{object.imageText}}
                  </p>
                </div>
                <div *ngIf="object.markImageFileName" class="objects__img">
                  <img class="tm_image"
                       src="{{getImage(object.index, object.registrationString, object.markImageFileName, object)}}">
                </div>
                <div class="objects__sub-text">
                  <p>Товарный знак <span>RU {{object.registrationString}}</span> от
                    <span>{{object.registrationDate | date:'dd.MM.yyyy'}}</span></p>
                </div>
                <br>
                <ul class="classes_list__parent">
                  <li *ngFor="let service of object.goodsServices" class="classes_list">
                    {{service.classNumber}}
                  </li>
                </ul>
                <!--              <button class="objects__btn">3 события в мониторинге</button>-->
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- Файлы-->
      <div class="docs contracts-ins__block contracts-ins__block--file top_block">
        <div class="docs__main-wrapper">
          <div class="files_header">
            <h4 (click)="uploadFile()">Файлы</h4>
            <button class="docs__add">
              <span class="docs__add-text">Прикрепить файл</span>
              <span class="docs__plus-icon" (click)="triggerClick()">
                <input
                  type="file"
                  class="dn"
                  (change)="onFileUpload($event)"
                  #fileInput
                >
              </span>
            </button>
          </div>

          <div class="files_parent">
            <div *ngFor="let file of files" class="docs__block">
              <div class="docs__text-wrapper">
                <span>{{file.name}}</span>
                <div class="files-btn">
                  <a class="files-btn__download" (click)="downloadFiles.downloadFile(file)">Скачать</a>
                </div>
              </div>
              <div class="docs__icon-wrapper">
                  <span (click)="deleteFile(file.publicPath)" class="docs__icon">
                   <img src="/assets/images/delete-small.png" class="docs__icon--mini">
                   <img src="/assets/icons/gray-empty-contracts.svg" style="cursor: pointer;">
                  </span>
              </div>
            </div>
          </div>

          <button *ngIf="showAllFiles" (click)="getAllFiles()" class="docs__show">Посмотреть еще</button>
        </div>
      </div>
      <div>
        <!--  <div *ngIf="overviewCourt && overviewVar2" class="container inset contracts-ins contracts-ins&#45;&#45;column">-->
        <!--    <button style="position: absolute; top: 0;" (click)="overviewVar1=true;overviewVar2=false">Вариант1</button>-->
        <!--    <button style="position: absolute; left: 189px; top: 0;" (click)="overviewVar2=true;overviewVar1=false">Вариант2-->
        <!--    </button>-->

        <!--    <div class="contracts-ins__block&#45;&#45;special contracts-ins__block&#45;&#45;column">-->
        <!--      <div class="text">-->
        <!--        <h4>Предмет иска</h4>-->
        <!--        <p>Оспаривание предоставления товарного знака для использования в маркетинговых материалах и рекламной-->
        <!--          продукции,-->
        <!--          на печатных носителях, на телевидении и в интернете.</p>-->
        <!--      </div>-->

        <!--      <div class="card-info">-->
        <!--        <h3>К вам предъявлен иск о признании недействительным регистрации товарного знака</h3>-->
        <!--        <div class="attention attention&#45;&#45;in-card">-->
        <!--          <h3>Рекомендация</h3>-->
        <!--          <p>К вам предъявлен иск о признании недействительным регистрации товарного знака. Для оценки перспектив-->
        <!--            рассмотрения спора, необходимо ознакомиться с материалами дела, изучить доказательства и доводы истца.-->
        <!--            По результатам анализа мы подготовим правовое заключение на основании которого вы сможете принять решение-->
        <!--            о выборе стратегии по делу.</p>-->
        <!--        </div>-->
        <!--        <div class="card-info__blocks-wrapper">-->
        <!--          <div class="card-info__block">-->
        <!--            <h4 class="card-info__subtitle">Документы</h4>-->
        <!--            <p class="card-info__text">Ознакомиться с документами</p>-->
        <!--            <div class="docs__block">-->
        <!--              <div class="docs__text-wrapper">-->
        <!--                <span>Определение СИП</span>-->
        <!--                <a href="" download>nazvanie_dogovora.pdf</a>-->
        <!--              </div>-->
        <!--              <div class="docs__icon-wrapper">-->
        <!--                  <span class="docs__icon">-->
        <!--                    <img src="/assets/icons/blue-empty.svg">-->
        <!--                  </span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="card-info__block">-->
        <!--            <h4 class="card-info__subtitle">Ваши действия</h4>-->
        <!--            <form>-->
        <!--              <fieldset>-->
        <!--                <div class="input-checkbox">-->
        <!--                  <input type="radio" name="agreement" id="5">-->
        <!--                  <label class="input-checkbox__label" for="5">Оценить перспективы-->
        <!--                  </label>-->
        <!--                </div>-->
        <!--                <div class="input-checkbox">-->
        <!--                  <input type="radio" name="agreement" id="6">-->
        <!--                  <label class="input-checkbox__label" for="6">Отказаться от представительства-->
        <!--                  </label>-->
        <!--                </div>-->
        <!--              </fieldset>-->
        <!--              <div class="card-info__btn-wrapper">-->
        <!--                <button type="submit" class='btn-blue'>Продолжить</button>-->
        <!--              </div>-->
        <!--            </form>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->

        <!--    </div>-->
        <!--    <div class="contracts-ins__block&#45;&#45;special">-->
        <!--      <div class="objects">-->
        <!--        <h2>Объект спора</h2>-->
        <!--        <div class="objects__wrapper">-->
        <!--          <div class="objects__block objects__block&#45;&#45;empty">-->
        <!--            <p>Уточняется</p>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--      <div class="docs">-->
        <!--        <div class="docs__main-wrapper">-->
        <!--          <h4>Файлы</h4>-->
        <!--          <div class="docs__blocks">-->
        <!--            <div class="docs__block">-->
        <!--              <div class="docs__text-wrapper">-->
        <!--                <span>Определение СИП</span>-->
        <!--                <div class="files-btn">-->
        <!--                  <a class="files-btn__see" href="">Посмотреть</a>-->
        <!--                  <p>или</p>-->
        <!--                  <a class="files-btn__download" href="" download>Скачать</a>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="docs__icon-wrapper">-->
        <!--                <span class="docs__icon">-->
        <!--                 <img src="/assets/images/delete-small.png" class="docs__icon&#45;&#45;mini">-->
        <!--                 <img src="/assets/icons/gray-empty-contracts.svg">-->
        <!--                </span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--  </div>-->
      </div>
    </div>
    <div *ngIf="historyCourt" class="container inset">
      <form class="contracts-form">
        <input type="text"
               class="contracts-form__universal contracts-form__universal--history"
               placeholder="Поиск по тексту"
        >
        <div tabindex="0" (focusout)="dateFromShow=false"
             class="contracts-form__universal contracts-form__universal--select contracts-form__universal--date"
             [class.contracts-form__universal--select--rotate]="dateFromShow===true">
          <p (click)="dateFromShow=true"
             [class.contracts-form__state-placeholder]="selectedDateFrom==='Дата события от'">{{selectedDateFrom}}</p>
          <div *ngIf="dateFromShow===true" class="contracts-form__hidden">
            <p *ngFor="let option of dateFrom" class="contracts-form__option-label"
               (click)="onSelectDateFrom(option)">{{option}}</p>
          </div>
        </div>
        <div tabindex="0" (focusout)="dateToShow=false"
             class="contracts-form__universal contracts-form__universal--select contracts-form__universal--date"
             [class.contracts-form__universal--select--rotate]="dateToShow===true">
          <p (click)="dateToShow=true"
             [class.contracts-form__state-placeholder]="selectedDateTo==='Дата события до'">{{selectedDateTo}}</p>
          <div *ngIf="dateToShow===true" class="contracts-form__hidden">
            <p *ngFor="let option of dateTo" class="contracts-form__option-label"
               (click)="onSelectDateTo(option)">{{option}}</p>
          </div>
        </div>
        <div class="search__btns">
          <button class="search__btn btn-search">Найти</button>
          <button class="search__btn btn-clean" (click)="clearAll()">Очистить</button>
        </div>
      </form>
      <table class="table-history">
        <tr>
          <th class="table-history__date">Дата события</th>
          <th class="table-history__description--court">Описание</th>
        </tr>
        <tr *ngFor="let changed of contractChanges">
          <td data-label="Дата события">{{changed.changeDate | date: 'dd.MM.yyyy'}}</td>
          <td class="table-history__text" data-label="Описание">
            <p>{{changed.contractTerms}}
            </p>
          </td>
        </tr>
      </table>
    </div>
  </section>
</ng-container>

<ng-template #loader>
  <app-spinner class="spinner-full"></app-spinner>
</ng-template>

<app-expert></app-expert>



