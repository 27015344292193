import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {RequestContractService} from '../shared/request-contract.service';
import {ActivatedRoute} from '@angular/router';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { REQUEST_TYPE } from '../../../shared/enums/request-type';
import { PAYMENT_TYPE } from '../../../shared/enums/payment-type';


@Component({
  selector: 'app-payment-contract',
  templateUrl: './payment-contract.component.html',
  styleUrls: ['./payment-contract.component.scss']
})
export class PaymentContractComponent implements OnInit {

  returnAfterPayment = false;
  yaLastPaymentId;
  error;

  @Output() nextStepEvent = new EventEmitter(); // событие перехода на следующий шаг
  @Output() backStepEvent = new EventEmitter(); // событие перехода на предыдущий шаг

  constructor(
    private activatedRoute: ActivatedRoute,
    readonly requestContractService: RequestContractService,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.returnAfterPayment = params.returnAfterPayment === 'true';
      this.yaLastPaymentId = params.t;
    });
    const payer = JSON.parse(localStorage.getItem('payer'));
    if (payer) {
      this.selectPayer(payer);
    }
  }


  nextStep() {
    this.nextStepEvent.emit();
  }

  backStep() {
    this.backStepEvent.emit();
  }

  switchPaymentType(type: PAYMENT_TYPE) {
    this.intercomEventsService.push({
      event: type === PAYMENT_TYPE.ONLINE
        ? INTERCOM_EVENT_NAME.CLICK_PAYMENT_ONLINE
        : type === PAYMENT_TYPE.OFFLINE && INTERCOM_EVENT_NAME.CLICK_PAYMENT_BILL,
      itemType: REQUEST_TYPE.CONTRACT,
    });
    this.requestContractService.setPaymentType(type);
  }

  get paymentType() {
    console.log(333, this.requestContractService.paymentType);
    return this.requestContractService.paymentType;
  }

  get idApplication() {
    return this.requestContractService.responseID;
  }

  get paymentSum() {
    return this.requestContractService.paymentSum;
  }

  onlineError(error) {
    this.error = error;
  }

  paymentOnlineSuccessed(data) {
    if (data.status === 'succeeded') {
      this.toSuccessPage(data);
    }
  }

  toSuccessPage(data?) {
    this.requestContractService.goSuccessStage(data);
    this.intercomEventsService.push({
      event: Boolean(data)
        ? INTERCOM_EVENT_NAME.FORM_PAYMENT_ONLINE_SUCCESS
        : INTERCOM_EVENT_NAME.FORM_PAYMENT_BILL_SUCCESS,
      itemType: REQUEST_TYPE.CONTRACT,
    });
  }

  selectPayer(data) {
    if (this.paymentType === 'online') {
      localStorage.setItem('payer', JSON.stringify(data));
    }
    this.requestContractService.setPayer(data);
  }

  get payer() {
    return this.requestContractService.payer;
  }
}
