<div class="search-wrap" *ngIf="showSearch">
  <form class="search">
    <div class="search__header-mobile">
      <p>Прикрепить документы к делу</p>
      <span class="search__mobile-close" (click)="closeModal()"></span>
    </div>
    <input class="search__input" placeholder="Поиск по объектам"/>
    <div class="search__wrapper">
      <div class="search__wrapper-btns-blocks">
        <div class="search__btn-block">
          <p class="search__label">Должник:</p>
          <div class="search__select">
            <button class="search__select-btn"
                    [class.search__select-btn--bold]="getCountCheckedObjects(typeOfObjects)>0">
              {{ getCountCheckedObjects(typeOfObjects) === 0 ? 'Все' : getCountCheckedObjects(typeOfObjects)}}
              <span class="search__select-btn-span"></span>

              <div class="search__hidden-options-wrapper">
                <div class="search__options-wrapper">

                  <div *ngFor="let option of typeOfObjects" class="search__option">
                    <input class="search__option-input" type="checkbox"
                           name="{{option.title}}"
                           id="{{option.id}}"
                           [(ngModel)]="option.isChecked">
                    <label class="search__option-label" for="{{option.id}}">{{option.title}}</label>
                  </div>

                </div>

                <div class="search__btns-wrapper">
                  <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(typeOfObjects)">Отмена
                  </button>
                  <button type="submit" class="search__btn-submit" onclick="this.blur()">Выбрать</button>
                </div>
              </div>
            </button>

          </div>
        </div>

        <div class="search__btn-block">
          <p class="search__label">Регион:</p>
          <div class="search__select">
            <button class="search__select-btn"
                    [class.search__select-btn--bold]="getCountCheckedObjects(typeOfObjects)>0">
              {{ getCountCheckedObjects(typeOfObjects) === 0 ? 'Все' : getCountCheckedObjects(typeOfObjects)}}
              <span class="search__select-btn-span"></span>


              <div class="search__hidden-options-wrapper">
                <div class="search__options-wrapper">

                  <div *ngFor="let option of typeOfObjects" class="search__option">
                    <input class="search__option-input" type="checkbox" name="{{option.title}}" id="{{option.id}}"
                           [(ngModel)]="option.isChecked">
                    <label class="search__option-label" for="{{option.id}}">{{option.title}}</label>
                  </div>

                </div>

                <div class="search__btns-wrapper">
                  <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(typeOfObjects)">Отмена
                  </button>
                  <button type="submit" class="search__btn-submit" onclick="this.blur()">Выбрать</button>
                </div>
              </div>
            </button>

          </div>
        </div>

        <div class="search__btn-block">
          <p class="search__label">Стадия:</p>
          <div class="search__select">
            <button class="search__select-btn"
                    [class.search__select-btn--bold]="getCountCheckedObjects(objectsSelect)>0">
              {{ getCountCheckedObjects(objectsSelect) === 0 ? 'Все' : getCountCheckedObjects(objectsSelect)}}
              <span class="search__select-btn-span"></span>


              <div class="search__hidden-options-wrapper">
                <div class="search__options-wrapper">

                  <div *ngFor="let option of objectsSelect" class="search__option">
                    <input class="search__option-input" type="checkbox" name="{{option.title}}" id="{{option.id}}"
                           [(ngModel)]="option.isChecked">
                    <label class="search__option-label" for="{{option.id}}">{{option.title}}</label>
                  </div>

                </div>

                <div class="search__btns-wrapper">
                  <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(objectsSelect)">Отмена
                  </button>
                  <button type="submit" class="search__btn-submit" onclick="this.blur()">Выбрать</button>
                </div>
              </div>
            </button>

          </div>
        </div>
      </div>

      <div class="search__btns">
        <button class="search__btn btn-search">Найти</button>
        <button class="search__btn btn-clean">Очистить</button>
      </div>

    </div>
  </form>
</div>

<table class="table-gr">
  <tr class="table-gr__head">
    <th>Тип</th>
    <th>Правообладатель и объект</th>
    <th>Нарушитель</th>
    <th>Требования</th>
    <th>Где нарушает</th>
    <th>Срок ответа</th>
    <th>Статус</th>
  </tr>
  <tr class="table-gr__row" *ngFor="let claim of claimsMassive">
    <td class="table-gr__first">
      <p class="table-gr__type table-gr__type--in" *ngIf="claim.type==='in'">Входящая</p>
      <p class="table-gr__type table-gr__type--out" *ngIf="claim.type==='out'">Исходящая</p>
    </td>
    <td class="table-gr__second">
      <span class="table-gr__mobile-title">Правообладатель и объект</span>
      <p class="table-gr__name">{{claim.obj}}</p>
      <span class="table-gr__name-span">{{claim.objItem}}</span>
    </td>
    <td class="table-gr__name table-gr__third">
      <span class="table-gr__mobile-title">Нарушитель</span>
      {{claim.badSide}}
    </td>
    <td class="table-gr__fourth">
      <span class="table-gr__mobile-title">Требования</span>
      <p class="table-gr__name" *ngIf="claim.reqSum.length > 1"><b>{{claim.reqSum}}</b></p>
      <span class="table-gr__name-span" *ngIf="claim.reqText.length > 1">{{claim.reqText}}</span>
    </td>
    <td class="table-gr__place table-gr__fifth">
      <span class="table-gr__mobile-title">Где нарушает</span>
      {{claim.place}}
    </td>
    <td class="table-gr__date table-gr__sixth">
      <span class="table-gr__mobile-title">Срок ответа</span>
      {{claim.date}}
    </td>
    <td class="table-gr__seventh">
      <a class="table-gr__state"
         [class.table-gr__state--green]="claim.state==='Получен ответ'"
         [class.table-gr__state--yellow]="claim.state==='Отправлен ответ'"
         [class.table-gr__state--red]="claim.state==='Требует ответ'"
         routerLink="/index/item">{{claim.state}}</a>
    </td>
  </tr>
</table>
<app-pagination
  [totalCount]="10"
  [itemPerPage]="10"
></app-pagination>

