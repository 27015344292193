import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { EventsService } from '../../../../services/events.service';

const SPACE = 15;
const SIZE = 300;
const LINE_SIZE = 18;

@Component({
  selector: 'app-search-risks',
  templateUrl: './search-risks.component.html',
  styleUrls: ['./search-risks.component.scss']
})
export class SearchRisksComponent implements OnInit {

  probability = 'low';

  @Input() risks: IRisksParam;

  @Input() totalTradeMarks: number;
  @Input() totalLegalEntities: number;
  @Input() totalDomains: number;
  @Input() designation: any;
  @Input() mktu: any;

  @Input() options: {
    style?: string;
    dynamicStylesForBrand?: any;
    showProgressPercent?: boolean
  } = {};
  @Input() legalsupport;

  @ViewChild('low') low: ElementRef;

  @Input() helpEnabled = false;

  isScale = false;

  private paths = {
    '99': [174, 119, -196],
    '98': [174, 115, -196],
    '97': [174, 112, -194],
    '96': [175, 110, -192],
    '95': [176, 107, -190],
    '94': [176, 104, -188],
    '93': [176, 101, -186],
    '92': [176, 98, -184],
    '91': [176, 94.5, -182],
    '90': [175, 92, -180],
    '89': [175, 89, -177],
    '88': [174, 86, -175],
    '87': [173, 83, -172],
    '86': [173, 80, -170],
    '85': [172, 77, -168],
    '84': [171, 74, -166],
    '83': [170, 71.5, -164],
    '82': [169, 69, -162],
    '81': [168, 66, -160],
    '80': [166.5, 64, -157],
    '79': [165, 61, -154],
    '78': [163, 59, -151],
    '77': [161, 56.5, -148],
    '76': [159, 54.5, -145],
    '75': [158, 52, -142],
    '74': [157, 50, -139],
    '73': [154, 47, -136],
    '72': [152, 45, -133],
    '71': [150, 43, -130],
    '70': [148, 41, -127],
    '69': [146, 39.5, -124],
    '68': [144, 37.5, -122],
    '67': [141, 36, -120],
    '66': [138.5, 34, -118],
    '65': [136, 33, -116],
    '64': [133, 32, -114],
    '63': [131, 31, -112],
    '62': [127, 30, -110],
    '61': [125, 28, -108],
    '60': [122, 27, -106],
    '59': [120, 27, -104],
    '58': [117, 26, -102],
    '57': [114, 25, -100],
    '56': [111, 24, -98],
    '55': [108, 24, -96],
    '54': [104.5, 24, -94],
    '53': [101, 24, -93],
    '52': [98.5, 24, -92],
    '51': [95.5, 24, -92],
    '50': [93, 24, -90],
    '49': [90, 25, -88],
    '48': [87, 25, -87],
    '47': [84, 25, -85],
    '46': [80.5, 27, -82],
    '45': [78, 27, -79],
    '44': [75, 28, -76],
    '43': [73, 30, -73],
    '42': [69.5, 31, -71],
    '41': [66.5, 32, -69],
    '40': [64, 33, -68],
    '39': [62, 34, -66],
    '38': [60, 36, -64],
    '37': [56.5, 38.5, -62],
    '36': [54, 40, -59],
    '35': [52.5, 41, -56],
    '34': [50, 44, -53],
    '33': [48, 46, -50],
    '32': [46, 48, -47],
    '31': [44, 49, -44],
    '30': [42, 51, -41],
    '29': [40, 54, -38],
    '28': [38, 56, -35],
    '27': [37, 59, -32],
    '26': [35, 61, -30],
    '25': [34, 62, -28],
    '24': [33, 65, -26],
    '23': [32, 67, -24],
    '22': [30, 70, -22],
    '21': [29, 73, -20],
    '20': [28, 75, -18],
    '19': [27, 78, -16],
    '18': [27, 81, -14],
    '17': [26, 84, -12],
    '16': [26, 87, -10],
    '15': [25, 90, -8],
    '14': [25, 94.5, -6],
    '13': [25, 97, -4],
    '12': [25, 98, -2],
    '11': [25, 103, 0],
    '10': [25, 106, 2],
    '9': [25, 109, 4],
    '8': [26, 112, 6],
    '7': [26, 115, 8],
    '6': [27, 118, 10],
    '5': [28, 120, 12],
    '4': [29, 124, 14],
    '3': [30, 126, 16],
    '2': [31, 129, 18],
    '1': [32, 132, 20],
  };

  private circleGradient = {
    99: 0,
    98: 0,
    97: 0,
    96: 10,
    95: 10,
    94: 12,
    93: 14,
    92: 14,
    91: 14,
    90: 14,
    89: 14,
    88: 14,
    87: 14,
    86: 14,
    85: 10,
    84: 10,
    83: 10,
    82: 10,
    81: 10,
    80: 10,
    79: 11,
    78: 10,
    77: 10,
    76: 10,
    75: 10,
    74: 10,
    73: 10,
    72: 10,
    71: 10,
    70: 10,
    69: 10,
    68: 10,
    67: 10,
    66: 10,
    65: 8,
    64: 8,
    63: 8,
    62: 8,
    61: 8,
    60: 8,
    59: 8,
    58: 8,
    57: 8,
    56: 8,
    55: 8,
    54: 8,
    53: 8,
    52: 8,
    51: 8,
    50: 8,
    49: 8,
    48: 8,
    47: 8,
    46: 8,
    45: 9,
    44: 0,
    43: 2,
    42: 2,
    41: 2,
    40: -4,
    39: 1,
    38: 0,
    37: -3,
    36: -2,
    35: -2,
    34: -4,
    33: -6,
    32: -5,
    31: -5,
    30: -5,
    29: -7,
    28: -7,
    27: -7,
    26: -7,
    25: -9,
    24: -9,
    23: -9,
    22: -9,
    21: -9,
    20: -9,
    19: -11,
    18: -11,
    17: -11,
    16: -11,
    15: -11,
    14: -11,
    13: -12,
    12: -12,
    11: -12,
    10: -14,
    9: -14,
    8: -15,
    7: -15,
    6: -16,
    5: -15,
    4: -17,
    3: -17,
    2: -18,
    1: -18,
  };

  private mapRiskNames = {
    low: 'Низкая',
    middle: 'Средняя',
    high: 'Высокая'
  };

  constructor(
    public intercomEventsService: IntercomEventsService,
    private router: Router,
    private eventsService: EventsService,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.showHelpEnabledMobile();
  }

  ngOnInit(): void {
    if (this.designation.length) {
      console.log(this.designation, 'log des');
    }
    if (document.documentElement.clientWidth > 767) {
      this.probability = 'both';
    } else {
      this.probability = 'low';
    }

    if (window.innerWidth < 768) {
      this.isScale = true;
    } else {
      this.isScale = false;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        this.isScale = true;
      } else {
        this.isScale = false;
      }
    });
  }

  sendDesignation() {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.APP_REGISTER });

    if (this.legalsupport) {
      this.eventsService.emitAction('zaregistrirovat');
    } else {
      if (this.designation.length) {
        if (this.mktu) {
          const mktuCodes = [];
          this.mktu.forEach(item => {
            mktuCodes.push(item.number);
          });
          this.router.navigate([`/request/${this.designation}`], {
            queryParams: {
              type: 'trademark',
              mktu: JSON.stringify(mktuCodes)
            }
          });
        } else {
          this.router.navigate([`/request/${this.designation}`], {queryParams: {type: 'trademark'}});
        }
      }
    }
  }

  sendDesignationLow() {
    this.helpEnabled = false;
    this.eventsService.emitAction('clickScoreLow');
  }

  sendDesignationHigh() {
    this.helpEnabled = true;
    this.eventsService.emitAction('clickScoreHigh');
  }

  get risk() {
    return this.helpEnabled ? this.helpEnabledRisk : this.helpDisabledRisk;
  }

  get helpDisabledColor() {
    return this.colorByPercent(this.helpDisabledRisk);
  }

  get helpEnabledColor() {
    return this.colorByPercent(this.helpEnabledRisk);
  }

  get helpEnabledRiskName() {
    return this.mapRiskNames[this.riskLevelByPercent(this.helpEnabledRisk)];
  }

  get helpDisabledRiskName() {
    return this.mapRiskNames[this.riskLevelByPercent(this.helpDisabledRisk)];
  }


  get helpEnabledRisk() {
    return this.risks.legal_strategies + this.risks.custom + this.risks.mktu_completion + this.risks.sign_reworking;
  }

  get helpDisabledRisk() {
    return this.risks.custom;
  }

  colorByPercent(percent) {
    const map = {
      low: 'red',
      middle: 'orange',
      high: 'green',
    };
    return map[this.riskLevelByPercent(percent)];
  }

  private riskLevelByPercent(percent) {
    if (percent < 20) {
      return 'low';
    } else if (percent < 50) {
      return 'middle';
    } else {
      return 'high';
    }
  }

  get helpEnabledParams() {
    if (!this.helpEnabled) {
      return {array: SIZE, offset: SIZE};
    }

    const [x2, y2, r] = this.paths[this.helpEnabledRisk];
    const [x1, y1] = this.rotate(x2, y2, x2 - LINE_SIZE, y2, r);

    const [textLeft, textTop] = this.calcTextPosition(this.helpEnabledRisk, x1, y1, r);


    const gradient = {
      '0%': 'rgb(255,16,55)',
      '50%': 'rgb(255,174,16)',
      '60%': 'rgb(237,229,32)',
      '100%': 'rgb(120,214,77)',
    };

    const offset = this.calcDashoffset(this.helpDisabledRisk) + SPACE;
    return {
      array: this.calcDashoffset(this.helpEnabledRisk) - offset - SPACE,
      offset: -offset,

      textLeft, textTop,
      x2, y2,
      x1, y1,

      gradient: Object.keys(gradient).map(k => ({per: k, color: gradient[k]})),
    };
  }

  get helpDisabledParams() {
    let [x2, y2, r] = this.paths[this.helpDisabledRisk];

    const [x1, y1] = this.rotate(x2, y2, x2 - LINE_SIZE, y2, r);
    const [textLeft, textTop] = this.calcTextPosition(this.helpDisabledRisk, x1, y1, r);

    return {
      array: SIZE,
      offset: SIZE - this.calcDashoffset(this.helpDisabledRisk) + SPACE,

      textLeft, textTop,
      x2, y2,
      x1, y1
    };
  }

  private calcTextPosition(per, x1, y1, r) {
    let x, y;

    if (per < 37) {
      [x, y] = this.rotate(x1 - 20, y1, x1 - 40, y1 + 5, r);
    } else if (per >= 37 && per <= 50) {
      x = x1 - 40;
      y = y1 + 5;
    } else if (per > 50 && per < 80) {
      x = x1 + 20;
      y = y1 + 5;
    } else if (per > 80 && per < 90) {
      x = x1 + 15;
      y = y1;
    } else {
      x = x1 + 15;
      y = y1 + 5;
    }

    return [x, y];
  }

  private rotate(cx, cy, x, y, angle) {
    const radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
      ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;

    return [nx, ny];
  }

  get unusedProgressParams() {
    const offset = this.calcDashoffset(this.risk) + SPACE;
    return {
      array: this.calcDashoffset(100 - this.risk) + offset,
      offset: -offset,
    };
  }

  private calcDashoffset(percent: number) {
    return 30 + SIZE / 100 * percent;
  }

  toggleHelpEnabled() {
    this.helpEnabled = !this.helpEnabled;
  }

  toggleHelpEnabledMobile(type: string): void {
    if (document.documentElement.clientWidth <= 767) {
      if (type === this.probability) {
        return;
      }
      this.probability = type;
      this.helpEnabled = !this.helpEnabled;
    }
  }

  showHelpEnabledMobile(): void {
    if (document.documentElement.clientWidth > 767) {
      this.probability = 'both';
    } else if (document.documentElement.clientWidth <= 767 && this.low.nativeElement.classList.contains('active')) {
      this.probability = 'low';
    } else if (document.documentElement.clientWidth <= 767) {
      this.probability = 'high';
    }
  }

  get baseHref() {
    return window.location.href;
  }

  get isProgressPercentShow() {
    return this.options?.showProgressPercent !== false;
  }

  getRoundGradient(percent) {
    const color = this.legalsupport ? 'white' : this.options?.dynamicStylesForBrand?.SEARCH_SPEEDOMETER_BACKGROUND;

    if (percent === 1) {
      return {background: `conic-gradient(transparent 114deg , ${color} 0deg)`};
    }
    if (percent !== 1 && 10 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 2}deg , ${color} 0deg)`};
    }
    if (percent >= 10 && 20 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 4}deg , ${color} 0deg)`};
    }
    if (percent >= 20 && 25 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 6}deg , ${color} 0deg)`};
    }
    if (percent >= 25 && 30 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 10}deg , ${color} 0deg)`};
    }
    if (percent >= 30 && 45 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 8}deg , ${color} 0deg)`};
    }
    if (percent >= 45 && 50 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 10}deg , ${color} 0deg)`};
    }
    if (percent >= 50 && 55 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 10}deg , ${color} 0deg)`};
    }
    if (percent >= 55 && 66 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 12}deg , ${color} 0deg)`};
    }
    if (percent >= 66 && 78 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 14}deg , ${color} 0deg)`};
    }
    if (percent >= 78 && 86 > percent) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 16}deg , ${color} 0deg)`};
    }
    if (percent >= 86) {
      return {background: `conic-gradient(transparent ${(percent - 1) * 2 + 114 + 18}deg , ${color} 0deg)`};
    }

  }

  getY1(percent) {
    return this.circleGradient[percent];
  }

  getStrokeWidth(percent) {
    if (percent < 94) {
      return 13;
    } else if (percent === 94) {
      return 5;
    } else if (percent >= 95 && percent < 97) {
      return 2;
    } else if (percent >= 97) {
      return 0;
    }
  }

}


export interface IRisksParam {
  custom: number; // вероятность при самостоятельной регистрации
  sign_reworking: number; // вероятность регистрации при заказе доработки знака
  mktu_completion: number; // вероятность регистрации при заказе доработки знака и работе с МКТУ
  // tslint:disable-next-line:max-line-length
  legal_strategies: number; // вероятность с использованием юридических стратегий (доработка знака, работа с МКТУ и дополнительные юридические действия)
}


