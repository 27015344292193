import { Injectable } from '@angular/core';
import { UraitApiService } from '@web/core/services/urait-api.service';
import { SearchImageResponseInterface } from '@web/features/corp/corp-search/states/models/search-image-response.interface';
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import { SearchApiService } from '@web/core/services/search-api.service';
import { ISearchHistoryDetail } from '../../../../../app-ipid/src/app/shared/models/search-history.interfaces';

@Injectable()
export class CorpSearchTmService {
  private user = 'aa';
  private password = 'Aa123456';

  constructor(
    private searchApiService: SearchApiService,
    private apiService: UraitApiService
  ) {
  }

  getHash() {
    return Base64.stringify(sha1(this.user.toLowerCase() + this.password));
  }

  searchByImage(params): Promise<SearchImageResponseInterface> {
    const SHA1hash = this.getHash();
    return this.searchApiService.post<SearchImageResponseInterface>(
      `_searchbyimage`,
      { ...params, SHA1hash }
    );
  }

  uploadImage(params: FormData): Promise<{ path: string }> {
    return this.apiService.post<{path: string }>(
      'request/file/add',
      params,
      { isFile: true }
    );
  }

  saveSearch(params: ISearchHistoryDetail): Promise<{ searchID: string }> {
    return this.apiService.post<{ searchID: string }>(
      'searches/load',
      params
    );
  }

}
