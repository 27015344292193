import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import {
  ICompanySearchParam,
  IContractsParrent,
  IFileArray,
  IResponse,
  ISearchINN,
  ISearchObjects
} from '../models/main.interfaces';
import { Observable } from 'rxjs';

const path = 'objects';
export interface ITopRisk {
  date: string;
  description: string;
  level: number;
  type: string;
  id: string;
}
export interface ITopRisksResult {
  countRisks: number;
  risks: ITopRisk[];
}

@Injectable({
  providedIn: 'root'
})

export class PortfolioService {

  private user = 'aa';
  private password = 'Aa123456';

  private bodyForElasticRequest = {
    SHA1hash: this.getHash()
  };

  constructor(
    private httpService: HttpService
  ) {
  }

  getHash() {
    return Base64.stringify(sha1(this.user.toLowerCase() + this.password));
  }

  searchObjects(body: ISearchObjects) {
    return this.httpService.post({path: '_searchobjects', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  searchDomainByInn(body: ISearchINN) {
    return this.httpService.post({path: '_getObjectsByLegalId', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  getLegal(body: any = {}) {
    return this.httpService.post({path: '_legal', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  getRisks(body: any) {
    return this.httpService.post({path: '_qualifyrisksbyid', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  getSecurity(body: any = {}) {
    return this.httpService.post({path: '_qualifyrisksbypsrn', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  getContracts(body: ISearchObjects) {
    return this.httpService.post({path: '_contractinfo', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  loadPortfolioCost(body: ICompanySearchParam) {
    return this.httpService.post({path: '_annual_reports', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  addPortfolioObjects(body) {
    return this.httpService.post({path: `${path}/load`, subjectType: 'portfolio', body});
  }

  getContractsNew(params) {
    return this.httpService.get<IContractsParrent>({path: `${path}/contracts`, subjectType: 'Get contracts', params});
  }

  getFiles(params) {
    return this.httpService.get<IFileArray>({path: `${path}/contracts/file`, subjectType: 'files', params});
  }

  deleteFile(params, body) {
    return this.httpService.post({path: `${path}/contracts/file/delete`, subjectType: 'delete file', params, body});
  }

  addFile(params, body) {
    const fd: FormData = new FormData();
    if (body) {
      fd.append('file', body, body.name);
    }
    return this.httpService.post({
      path: `${path}/contracts/file/add`,
      subjectType: 'add file',
      params,
      body: fd,
      isFile: true
    });
  }

  editTitle(params, body) {
    return this.httpService.post<IResponse>({path: `${path}/contracts/rename`, subjectType: 'edit header', body, params});
  }


  postComment(body, params) {
    return this.httpService.post({path: `${path}/contracts/comment`, subjectType: 'add comment', body, params});
  }

  getFilesByObject(params: { objectID: string; index: string }) {
    return this.httpService.get({path: `${path}/file`, subjectType: 'portfolio', params});
  }

  getTopRisksByObject(params: {id: string; index: string}): Observable<ITopRisksResult> {
    return this.httpService.get({path: `${path}/risks/top`, subjectType: 'top risks', params});
  }

  getStatusTradeMarks(index: string, typeNum: number) {
    if (index === 'wotm') {
      return 'нет статуса';
    }
    if ([index === 'rutm' || index === 'rugp' || index === 'wktm'] && typeNum === 1) {
      return 'действует';
    }
    if ([index === 'rutm' || index === 'rugp' || index === 'wktm'] && typeNum === 2) {
      return 'срок действия истек';
    }
    if ([index === 'rutm' || index === 'rugp' || index === 'wktm'] && typeNum === 3) {
      return 'прекратил действие';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 10) {
      return 'пошлина за регистрацию не оплачена';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 14) {
      return 'формальная экспертиза';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 15) {
      return 'экспертиза заявленного обозначения';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 16) {
      return 'заявка отозвана';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 17) {
      return 'принято решение о регистрации товарного знака';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 18) {
      return 'принято решение об отказе в регистрации товарного знака';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 19) {
      return 'регистрация товарного знака';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 20) {
      return 'Принято решение о признании заявки отозванной';
    }
    if ([index === 'rutmap' || index === 'rugpap'] && typeNum === 21) {
      return 'Заявка отозвана по просьбе заявителя';
    }
  }

  getStatusDomains(index: string, typeNum: number) {
    if (index === 'domains' && typeNum === 0) {
      return 'занят';
    }
    if (index === 'domains' && typeNum === 1) {
      return 'свободен';
    }
  }

  getStatusPatents(index: string, typeNum: number) {
    if ([index === 'patent' || index === 'rupat' || index === 'ruum' || index === 'rude'] && typeNum === 1) {
      return 'действует';
    }
    if ([index === 'patent' || index === 'rupat' || index === 'ruum' || index === 'rude'] && typeNum !== 1) {
      return 'не действует';
    }
  }
}

