import { Component, Input, OnInit } from '@angular/core';
import { IApiCountersResponse } from 'projects/app-ipid/src/app/shared/models/api/api.model';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';
import {AuthState} from '@web/core/states/auth.state';

@Component({
  selector: 'app-risks',
  templateUrl: './risks-chart.component.html',
  styleUrls: ['./risks-chart.component.scss']
})

export class RisksChartComponent implements OnInit {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  @ViewSelectSnapshot(AuthState.getRightByName('monitoring-viewing')) monitoring: boolean;
  // Риски
  risks = [];
  objectsWithMonitoring: number;

  @Input() set counters(counters: IApiCountersResponse) {
    this.risks = [
      {
        name: 'Критичные',
        value: counters?.risks?.critical ?? 0,
      },
      {
        name: 'Высокие',
        value: counters?.risks?.high ?? 0,
      },
      {
        name: 'Средние',
        value: counters?.risks?.medium ?? 0,
      },
      {
        name: 'Рекомендации',
        value: counters?.risks?.recommendation ?? 0
      },
      {
        name: 'Неподключенные объекты',
        value: counters?.risks?.unconnected ?? 0,
      }
    ];

    this.objectsWithMonitoring = counters?.monitoring;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  // Получить сколько всего рисков
  getTotalRisks(): number {
    return this.risks.reduce((x, y) => x + y.value, 0);
  }

  // Получить максимальное значение одного из рисков
  getMaxRisk(): number {
    return Math.max.apply(Math, this.risks.map(o => o.value));
  }
}
