import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnvService } from '../../services/env.service';

@Component({
  selector: 'app-title-dropdown',
  templateUrl: './title-dropdown.component.html',
  styleUrls: ['./title-dropdown.component.scss']
})
export class TitleDropdownComponent implements OnInit {
  @Input() title: string;
  @Input() value: string;
  @Input() options: Array<{ id: string, label: string, accept: boolean }>;

  @Output() changeEvent = new EventEmitter<string>();

  isShowDropdown = false;

  constructor(private env: EnvService) {
  }

  ngOnInit(): void {
  }

  showDropdown() {
    this.isShowDropdown = true;
  }

  hideDropdown() {
    this.isShowDropdown = false;
  }

  onSelect(id: string) {
    this.isShowDropdown = false;
    this.changeEvent.emit(id);
  }

  get selectedOption() {
    return this.options.find(i => i.id === this.value);
  }

}
