import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-payment-offline-individual',
  templateUrl: './payment-offline-individual.component.html',
  styleUrls: ['./payment-offline-individual.component.scss']
})
export class PaymentOfflineIndividualComponent implements OnInit {
  @Input() paymentSum; // сумма
  formPay: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this._initFormPay();
  }

  private _initFormPay(): void {
    this.formPay = this.fb.group({
      sum: [(+this.paymentSum).toLocaleString('ru') + ' ₽', [Validators.required]],
    });
  }

}
