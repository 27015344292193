<div [ngClass]="options?.style === 'appIpIdSearchPage' ? 'ipid-app-search' : ''"
     [class.z-index]="zIndex">
  <div [class.legalsupport]="isLegalSupport">
    <div class="ip-id_search__wrapper" [class.legalsupport-brandsearch]="isLegalSupport || isBrandSearch">
      <!--TODO: новый компонент адаптивной поисковой строки-->
      <!--TODO: oldSearch это старая поисковая строка, решили пока оставить так, так как она юзается в подаче заявки и у нее своя мобильная версия-->
      <div class="search-container search-container--search">
        <div class="search-wrapper">
          <app-ip-id-search-input
            [designation]="designation"
            *ngIf="!options.onlyClassSelect; else oldSearch"
            (search)="checkTradeMarks($event)"
            (openPopup)="changeZ($event)"
            class="app-ip-id-search-input"
          ></app-ip-id-search-input>
        </div>
      </div>
    </div>

    <app-search-risks-brand
      [designation]="designation"
      [risks]="tradeMarksData.risks"
      [totalTradeMarks]="tradeMarksData?.hits?.total?.tradeMarks"
      [totalLegalEntities]="legalNamesData?.hits?.total?.legalEntities"
      [totalDomains]="domainNamesData?.hits?.total?.domains"
      [options]="options"
      (reportCheckClick)="emitReportRisksClick()"
      *ngIf="(!isLoadingTradeMarks && tradeMarksData && legalNamesData && domainNamesData && tradeMarksData.risks) && !options.onlyClassSelect && isBrandSearch">
    </app-search-risks-brand>

    <app-search-risks
      [designation]="designation"
      [risks]="tradeMarksData.risks"
      [totalTradeMarks]="tradeMarksData?.hits?.total?.tradeMarks"
      [totalLegalEntities]="legalNamesData?.hits?.total?.legalEntities"
      [totalDomains]="domainNamesData?.hits?.total?.domains"
      [options]="options"
      [mktu]="mktuSearchArray"
      [legalsupport]="isLegalSupport"
      *ngIf="(!isLoadingTradeMarks && tradeMarksData && legalNamesData && domainNamesData && tradeMarksData.risks) && !options.onlyClassSelect && !isBrandSearch">
    </app-search-risks>
    <div
      *ngIf="!options.onlyClassSelect"
      class="main_result"
      [ngStyle]="getStyleForMainResult()"
    >

      <div class="stub_img" *ngIf="isLoadingDomainNames || isLoadingLegalNames || isLoadingTradeMarks; else result">
        <app-loading-modal
          [brandsearch]="isBrandSearch"
          [legalsupport]="isLegalSupport"
        >
        </app-loading-modal>
        <!--    <div *ngTemplateOutlet="loader"></div>-->
      </div>
    </div>


    <ng-template #result>
      <div class="ip-id-result"
           data-intercom-target="results"
           *ngIf="tradeMarks?.length || legalNames?.length || domainNames?.length; else stub">

        <!--  Trademarks carousel  -->
        <app-trade-marks
          (click)="sendEvent('openIPcard', 'ТоварныйЗнак')"
          [tradeMarksData]="tradeMarksData"
          [tradeMarks]="tradeMarks"
          [allTradeMarks]="allTradeMarks"
          [isShowSearchPopup]="isShowSearchPopup"
          [brandsearch]="isBrandSearch"
          [legalsupport]="isLegalSupport"
          [designation]="designation"
          (clickDownload)="emitReportClick('trademark')"
          (hideSearchPopup)="clickOnDesignation()"
        >
        </app-trade-marks>

<!--        <app-legal-names-->
<!--          (click)="sendEvent('openIPcard', 'юрлиц')"-->
<!--          [legalNamesData]="legalNamesData"-->
<!--          [legalNames]="legalNames"-->
<!--          [allLegalNames]="allLegalNames"-->
<!--          [isShowSearchPopup]="isShowSearchPopup"-->
<!--          [brandsearch]="isBrandSearch"-->
<!--          [legalsupport]="isLegalSupport"-->
<!--          [designation]="designation"-->
<!--          (hideSearchPopup)="clickOnDesignation()"-->
<!--          (clickDownload)="emitReportClick('legal')"-->
<!--          [isLoadingLegalNames]="isLoadingLegalNames"-->
<!--        >-->
<!--        </app-legal-names>-->

<!--        <app-domain-names-->
<!--          (click)="sendEvent('openIPcard', 'домен')"-->
<!--          [domainNamesData]="domainNamesData"-->
<!--          [domainNames]="domainNames"-->
<!--          [allDomainNames]="allDomainNames"-->
<!--          [isShowSearchPopup]="isShowSearchPopup"-->
<!--          [brandsearch]="isBrandSearch"-->
<!--          [legalsupport]="isLegalSupport"-->
<!--          [designation]="designation"-->
<!--          (hideSearchPopup)="clickOnDesignation()"-->
<!--          (clickDownload)="emitReportClick('domain')"-->
<!--          [isLoadingDomainNames]="isLoadingDomainNames"-->
<!--        >-->
<!--        </app-domain-names>-->

      </div>

    </ng-template>

    <ng-template #stub>
      <div class="stub_img" *ngIf="!(options?.style === 'appIpIdSearchPage') && (!isBrandSearch && !isLegalSupport)">
        <div class="loop">
        </div>
        <div class="cover_text">
          <h2>
            Здесь будут показаны результаты поиска<br>
            и вероятность регистрации по вашему запросу
            <!--        Здесь будут показаны товарные знаки-->
            <!--        по вашему запросу-->
          </h2>
        </div>
      </div>

      <div class="stub_img stub_img--elements" *ngIf="isBrandSearch">
        <div class="cover_text cover_text--elements">
          <div class="cover_text--elements-text">
            <div *ngIf="!searchRequest200; else stubNoResult">
              Здесь будут показаны результаты поиска и вероятность регистрации по вашему запросу
            </div>
          </div>
          <ng-template #stubNoResult>
            К сожалению, ничего не найдено. Попробуйте изменить запрос
          </ng-template>
        </div>
      </div>

      <div *ngIf="!isBrandSearch">
        <ng-container *ngIf="!searchRequest200; else stubNoResult">
        </ng-container>
        <ng-template #stubNoResult>
          <div class="unique-name" [class.legalsupport]="isLegalSupport">
            <div class="unique-name__wrap">
              <div class="unique-name__wrapper">
                <p class="unique-name__title">Здорово!</p>
                <p class="unique-name__desc">
                  Поздравляем, вы придумали уникальное название и можете его зарегистрировать в качестве товарного
                  знака, с помощью кнопки выше!
                </p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>

    <ng-template #loader>
      <div style="display: block; height: 265px">
        <div class="loader"></div>
      </div>
    </ng-template>

    <ng-template #oldSearch>
      <div class="ip-id_search_container"
           [style.background]="options?.style === 'appIpIdSearchPage' ? '#F2F4F8' : options.onlyClassSelect ? '#ffffff00' : '#EBEDF2'">

        <div>
          <!--  <div class="three-buttons-nav">-->
          <!--    <div *ngIf="searchDefault" class="button-one" [ngClass]=" !searchDefault ? 'disabled-tab': 'button-active'" (click)="showDefault()">-->
          <!--      <svg width="329" height="37" viewBox="0 0 329 37" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L311.344 1.24312e-06C315.862 1.26116e-06 319.819 3.02939 320.998 7.39091L329 37L-1.47732e-07 37L8.00246 7.39091Z" fill="#EBEDF2"/>-->
          <!--      </svg>-->
          <!--      <div class="tabs-text">Проверить возможность регистрации</div>-->
          <!--    </div>-->
          <!--    <div *ngIf="!searchDefault" class="button-one" [ngClass]=" !searchDefault ? 'disabled-tab': 'button-active'" (click)="showDefault()">-->
          <!--      <svg width="329" height="37" viewBox="0 0 329 37" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L311.344 1.24312e-06C315.862 1.26116e-06 319.819 3.02939 320.998 7.39091L329 37L-1.47732e-07 37L8.00246 7.39091Z" fill="#EBEDF2"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L311.344 1.24312e-06C315.862 1.26116e-06 319.819 3.02939 320.998 7.39091L329 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint0_linear)"/>-->
          <!--        <defs>-->
          <!--          <linearGradient id="paint0_linear" x1="164.5" y1="6.56808e-07" x2="164.5" y2="37" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-color="#D6DAE2"/>-->
          <!--            <stop offset="1" stop-color="#CDD1DA"/>-->
          <!--          </linearGradient>-->
          <!--        </defs>-->
          <!--      </svg>-->
          <!--      <div class="tabs-text">Проверить возможность регистрации</div>-->
          <!--    </div>-->
          <!--    <div *ngIf="searchStrategy" class="button-two" [ngClass]=" !searchStrategy ? 'disabled-tab': 'button-active'" (click)="showStrategy()">-->
          <!--      <svg width="181" height="37" viewBox="0 0 181 37" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L163.344 6.52192e-07C167.862 6.70231e-07 171.819 3.02939 172.998 7.39091L181 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint0_linear)"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L163.344 6.52192e-07C167.862 6.70231e-07 171.819 3.02939 172.998 7.39091L181 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint1_linear)"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L163.344 6.52192e-07C167.862 6.70231e-07 171.819 3.02939 172.998 7.39091L181 37L-1.47732e-07 37L8.00246 7.39091Z" fill="#EBEDF2"/>-->
          <!--        <defs>-->
          <!--          <linearGradient id="paint0_linear" x1="80.5" y1="4.01272e-07" x2="80.5" y2="37" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-color="#D6DAE2"/>-->
          <!--            <stop offset="1" stop-color="#CDD1DA"/>-->
          <!--          </linearGradient>-->
          <!--          <linearGradient id="paint1_linear" x1="-20" y1="18" x2="44.5" y2="18" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-opacity="0.08"/>-->
          <!--            <stop offset="1" stop-opacity="0"/>-->
          <!--          </linearGradient>-->
          <!--        </defs>-->
          <!--      </svg>-->

          <!--      <div class="tabs-text">Поиск нарушений</div>-->
          <!--    </div>-->
          <!--    <div *ngIf="!searchStrategy" class="button-two" [ngClass]=" !searchStrategy ? 'disabled-tab': 'button-active'" (click)="showStrategy()">-->
          <!--      <svg width="181" height="37" viewBox="0 0 181 37" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L163.344 6.52192e-07C167.862 6.70231e-07 171.819 3.02939 172.998 7.39091L181 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint0_linear)"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L163.344 6.52192e-07C167.862 6.70231e-07 171.819 3.02939 172.998 7.39091L181 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint1_linear)"/>-->
          <!--        <defs>-->
          <!--          <linearGradient id="paint0_linear" x1="80.5" y1="4.01272e-07" x2="80.5" y2="37" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-color="#D6DAE2"/>-->
          <!--            <stop offset="1" stop-color="#CDD1DA"/>-->
          <!--          </linearGradient>-->
          <!--          <linearGradient id="paint1_linear" x1="-20" y1="18" x2="44.5" y2="18" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-opacity="0.08"/>-->
          <!--            <stop offset="1" stop-opacity="0"/>-->
          <!--          </linearGradient>-->
          <!--        </defs>-->
          <!--      </svg>-->
          <!--      <div class="tabs-text">Поиск нарушений</div>-->
          <!--    </div>-->
          <!--    <div *ngIf="searchCompetition" class="button-three" [ngClass]=" !searchCompetition ? 'disabled-tab': 'button-active'" (click)="showCompetition()">-->
          <!--      <svg width="261" height="37" viewBox="0 0 261 37" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L243.344 9.71612e-07C247.862 9.89651e-07 251.819 3.02939 252.998 7.39091L261 37L-1.47732e-07 37L8.00246 7.39091Z" fill="#D6DAE2"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L243.344 9.71612e-07C247.862 9.89651e-07 251.819 3.02939 252.998 7.39091L261 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint0_linear)"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L243.344 9.71612e-07C247.862 9.89651e-07 251.819 3.02939 252.998 7.39091L261 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint1_linear)"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L243.344 9.71612e-07C247.862 9.89651e-07 251.819 3.02939 252.998 7.39091L261 37L-1.47732e-07 37L8.00246 7.39091Z" fill="#EBEDF2"/>-->
          <!--        <defs>-->
          <!--          <linearGradient id="paint0_linear" x1="120.5" y1="5.60982e-07" x2="120.5" y2="37" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-color="#D6DAE2"/>-->
          <!--            <stop offset="1" stop-color="#CDD1DA"/>-->
          <!--          </linearGradient>-->
          <!--          <linearGradient id="paint1_linear" x1="-20" y1="18" x2="70.1716" y2="18" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-opacity="0.08"/>-->
          <!--            <stop offset="1" stop-opacity="0"/>-->
          <!--          </linearGradient>-->
          <!--        </defs>-->
          <!--      </svg>-->
          <!--      <div class="tabs-text">Анализировать конкурентов</div>-->
          <!--    </div>-->
          <!--    <div *ngIf="!searchCompetition" class="button-three" [ngClass]=" !searchCompetition ? 'disabled-tab': 'button-active'" (click)="showCompetition()">-->
          <!--      <svg width="261" height="37" viewBox="0 0 261 37" fill="none" xmlns="http://www.w3.org/2000/svg">-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L243.344 9.71612e-07C247.862 9.89651e-07 251.819 3.02939 252.998 7.39091L261 37L-1.47732e-07 37L8.00246 7.39091Z" fill="#D6DAE2"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L243.344 9.71612e-07C247.862 9.89651e-07 251.819 3.02939 252.998 7.39091L261 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint0_linear)"/>-->
          <!--        <path d="M8.00246 7.39091C9.18125 3.02939 13.1381 5.24571e-08 17.6561 7.04964e-08L243.344 9.71612e-07C247.862 9.89651e-07 251.819 3.02939 252.998 7.39091L261 37L-1.47732e-07 37L8.00246 7.39091Z" fill="url(#paint1_linear)"/>-->
          <!--        <defs>-->
          <!--          <linearGradient id="paint0_linear" x1="120.5" y1="5.60982e-07" x2="120.5" y2="37" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-color="#D6DAE2"/>-->
          <!--            <stop offset="1" stop-color="#CDD1DA"/>-->
          <!--          </linearGradient>-->
          <!--          <linearGradient id="paint1_linear" x1="-20" y1="18" x2="70.1716" y2="18" gradientUnits="userSpaceOnUse">-->
          <!--            <stop stop-opacity="0.08"/>-->
          <!--            <stop offset="1" stop-opacity="0"/>-->
          <!--          </linearGradient>-->
          <!--        </defs>-->
          <!--      </svg>-->
          <!--      <div class="tabs-text">Анализировать конкурентов</div>-->
          <!--    </div>-->
          <!--  </div>-->
        </div>
        <span *ngIf="!options.onlyClassSelect" #invisibleText id="invisibleTextID"
              class="search-invisible-designation">{{ designation }}</span>
        <div class="main__form" [style.width.px]="options.onlyClassSelect ? 814 : 1070" *ngIf="searchDefault">
          <div data-intercom-target="search field" #searchContainerEl [ngStyle]="getSearchBorderStyle()" style="
                  display: flex;
                  flex-wrap: wrap;
                  width: 890px;
                  min-height: 60px;
                  box-shadow: 0 3px 6px rgba(21, 36, 56, 0.15);
                  background: #fff;
                  padding-left: 20px;">
            <form *ngIf="!options.onlyClassSelect">
              <div class="designation">
                <input type="text" class="designation__input"
                       #designationEl
                       (click)="clickOnDesignation()"
                       [(ngModel)]="designation"
                       [ngModelOptions]="{standalone: true}"
                       [style.width.px]='this.widthDesignation'
                       [maxLength]="this.widthDesignation > 850 ? designation.length : 100"
                       (paste)="resizeInput()"
                       (cut)="resizeInput()"
                       (input)="resizeInput()"
                       (keyup.enter)="checkTradeMarks()"
                       placeholder="Обозначение">
              </div>
            </form>
            <!--      <div *ngIf="isShowMktuSearchBlock || mktuSearchArray.length || okvedSearchArray.length" class="dynamic_value">-->

            <!--        <div style="position: relative; display: flex;" *ngIf="okvedSearchArray?.length">-->
            <!--        <span class="okved-label">ОКВЭД</span>-->
            <div class="added-number added-number_okved" *ngFor="let okvedItem of okvedSearchArray">
              <span class="added-number__delete" (click)="deleteNumberFromSearch('okved', okvedItem)"></span>
              <div class="added-number__title">ОКВЭД</div>
              <div class="added-number__number">{{ okvedItem.code }}</div>
              <div *ngIf="okvedItem.description" class="added-number__popover">
                <div [innerHTML]="okvedItem.description"></div>
              </div>
            </div>
            <!--        </div>-->

            <!--        <div style="position: relative; display: flex;" *ngIf="mktuSearchArray?.length">-->
            <!--        <span class="mktu-label">МКТУ</span>-->
            <div class="added-number" *ngFor="let mktuItem of mktuSearchArray">
              <span class="added-number__delete" (click)="deleteNumberFromSearch('mktu', mktuItem)"></span>
              <div class="added-number__title">МКТУ</div>
              <div class="added-number__number">{{ mktuItem.number }}</div>
              <div class="added-number__popover">
                <div>{{ mktuItem.desc }}</div>
                <div *ngIf="mktuItem.subClasses && mktuItem.subClasses.length">
                  <hr style="background: #0be281; margin: 4px 0;">
                  <div *ngFor="let subClass of mktuItem.subClasses" [innerHTML]="'- ' + subClass.desc"></div>
                </div>
              </div>
            </div>
            <!--        </div>-->

            <button *ngIf="isShowMktuPlusBtn"
                    (click)="searchByJSON()"
                    class="plus_button"
                    id="plusBtn"
                    data-intercom-target="activities"
            >+ Вид деятельности
            </button>
            <!--      </div>-->
            <form style="background: #fff; height: 60px; display: flex; flex: auto;border-radius: 5px;">
              <input
                *ngIf="isShowSearchInput"
                [(ngModel)]="query"
                #queryEl
                type="text"
                name="mktuQuery"
                placeholder="Например кафе или 63.01 или 42"
                autocomplete="off"
                class="ip-id_Input_wide"
                id="inputSearch"
                (keyup.enter)="fetchMktu();fetchOkved()"
                (ngModelChange)=fetchMktuAndOkvedByQuery()>
            </form>
          </div>
          <button
            data-intercom-target="check button"
            *ngIf="!options.onlyClassSelect"
            class="submit_red"
            [ngClass]="!designation ? 'disabled' : ''"
            [ngStyle]="options?.style === 'appIpIdSearchPage' ? {background: options?.dynamicStylesForBrand?.SEARCH_SUBMIT_BUTTON_BACKGROUND, color: options?.dynamicStylesForBrand?.SEARCH_SUBMIT_BUTTON_COLOR} : {}"
            (click)="checkTradeMarks()"
          >
            <!--            matTooltip="{{!designation ? 'Введите обозначение' : ''}}"-->
            Проверить
          </button>

          <div
            data-intercom-target="activities classifier"
            *ngIf="isShowSearchPopup"
            (clickOutside)="hideSearchPopupFromOutsideClick($event)"
            [clickOutsideEnabled]="true"
            [clickOutsideEvents]="'click,touchstart'"
            #searchModalEl
            class="main_modal"
            [ngStyle]="{top: searchContainerHeight + 1 + 'px'}"
          >
            <div *ngIf="!isLoadingSearchClases; else loader">
              <div *ngIf="!options.onlyClassSelect" class="main_modal__btn-group">
                <button
                  class="all-btn"
                  *ngIf="searchPopupViewType !== 'inn-ogrn' && !options.onlyClassSelect"
                  [ngClass]="searchPopupViewType === 'all' ? '__selected' : ''"
                  (click)="changeSearchPopupView('all')"
                >
                  Все
                </button>
                <button
                  class="okved-btn"
                  *ngIf="searchPopupViewType !== 'inn-ogrn' && !options.search || (searchPopupViewType !== 'inn-ogrn' && options.search === 'okved')"
                  [ngClass]="searchPopupViewType === 'okved' ? '__selected' : ''"
                  (click)="changeSearchPopupView('okved')"
                >
                  ОКВЭД
                </button>
                <button
                  class="mktu-btn"
                  *ngIf="searchPopupViewType !== 'inn-ogrn' && !options.search || (searchPopupViewType !== 'inn-ogrn' && options.search === 'mktu')"
                  [ngClass]="searchPopupViewType === 'mktu' ? '__selected' : ''"
                  (click)="changeSearchPopupView('mktu')"
                >
                  Классы МКТУ
                </button>

                <img
                  *ngIf="searchPopupViewType === 'inn-ogrn' && !options.onlyClassSelect"
                  (click)="backFromInn()"
                  class="back-button"
                  src="/assets/icons/back.svg"
                  alt=""
                >
                <div class="inn-ogrn-search-block" *ngIf="!options.onlyClassSelect">
                  <div
                    *ngIf="legalName"
                    [ngStyle]="getDynamicMarginForLegalName()"
                    class="legal-name"
                  >
                    {{ legalName }}
                  </div>
                  <input
                    type="text"
                    name="innOgrnQuery"
                    #innOgrn="ngModel"
                    [(ngModel)]="innOgrnQuery"
                    maxlength="15"
                    pattern='^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$'
                    placeholder="Подбор ОКВЭД по ИНН\ОГРН"
                    autocomplete="off"
                    class="inn-ogrn-search-input validate-border-color"
                    (click)="changeSearchPopupView('inn-ogrn')"
                    (ngModelChange)=legalChanged()
                  >
                  <div *ngIf="innOgrn.invalid && (innOgrn.dirty || innOgrn.touched)" class="error-inn-ogrn">
                    Поле не может быть пустым и должно содержать 10, 12, 13 или 15 цифр.
                  </div>
                </div>
              </div>
              <div class="response-result-list" [ngSwitch]="searchPopupViewType">

                <div *ngSwitchCase="'all'">
                  <div *ngIf="mktuMappedResponse?.length || okvedMappedResponse; else classesNotFound">
                    <div
                      class="response-result-list__item"
                      *ngFor="let item of okvedMappedResponse"
                      (click)="addOkvedToSearch(item);"
                      id="allAdd"
                      [ngClass]="item.selected ? 'response-result-list__item__selected' : ''"
                    >
                      <div class="response-result-list__item__name okved-class-code">ОКВЭД {{item.code}}</div>
                      <div class="response-result-list__item__desc" [title]="item.description"
                           [innerHTML]="item.description"></div>
                    </div>
                    <div
                      class="response-result-list__item"
                      *ngFor="let item of mktuMappedResponse"
                      id="allAdd2"
                      (click)="addMktuToSearch(item, false)"
                      [ngClass]="item.selected ? 'response-result-list__item__selected' : ''"
                    >
                      <div class="response-result-list__item__name mktu-class-code">{{item.name}}</div>
                      <div class="response-result-list__item__desc" [title]="item.desc" [innerHTML]="item.desc"></div>
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'mktu'">
                  <div *ngIf="mktuMappedResponse?.length; else classesNotFound">
                    <div
                      class="response-result-list__item"
                      *ngFor="let item of mktuMappedResponse"
                      id="addMktu"
                      (click)="addMktuToSearch(item, false)"
                      [ngClass]="item.selected ? 'response-result-list__item__selected' : ''"
                    >
                      <div class="response-result-list__item__name mktu-class-code">{{item.name}}</div>
                      <div class="response-result-list__item__desc" [title]="item.desc" [innerHTML]="item.desc"></div>
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'okved'">
                  <div *ngIf="okvedMappedResponse?.length; else classesNotFound">
                    <div
                      class="response-result-list__item"
                      id="addOkved"
                      *ngFor="let item of okvedMappedResponse"
                      id="addOkved"
                      (click)="addOkvedToSearch(item)"
                      [ngClass]="item.selected ? 'response-result-list__item__selected' : ''"
                    >
                      <div class="response-result-list__item__name okved-class-code">ОКВЭД {{item.code}}</div>
                      <div class="response-result-list__item__desc" [title]="item.description"
                           [innerHTML]="item.description"></div>
                    </div>
                  </div>
                </div>

                <div *ngSwitchCase="'inn-ogrn'">
                  <div *ngIf="legalMappedResponse?.length; else innOgrnStub">
                    <div
                      class="response-result-list__item"
                      id="addInnOgrn"
                      *ngFor="let item of legalMappedResponse"
                      id="addInnOgrn"
                      (click)="addOkvedToSearch(item)"
                      [ngClass]="item.selected ? 'response-result-list__item__selected' : ''"
                    >
                      <div class="response-result-list__item__name okved-class-code">ОКВЭД {{item.code}}</div>
                      <div class="response-result-list__item__desc" [title]="item.description"
                           [innerHTML]="item.description"></div>
                    </div>
                  </div>
                </div>

                <ng-template #classesNotFound>
                  <div class="classesNotFound">
                    Классы не найдены
                  </div>
                </ng-template>

                <ng-template #innOgrnStub>
                  <div *ngIf="!isLoadingLegal; else loader" class="innOgrnStub">
                    После ввода ИНН или ОГРН организации<br>
                    здесь отобразятся ее виды деятельности
                  </div>
                </ng-template>

              </div>
            </div>
          </div>

        </div>
      </div>
    </ng-template>

    <div [innerHTML]="customStyles"></div>

  </div>
</div>
