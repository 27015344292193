import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ISearchHistorySearchListItem} from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import {SearchesInterface} from '@web/features/corp/corp-history/models/searches.interface';
import {CorpHistoryStateModel} from '@web/features/corp/corp-history/states/history.state';
import {FilterDataUsers} from '@web/features/corp/corp-history/models/filters-data.interface';

@Component({
  selector: 'app-corp-history-searches',
  templateUrl: './corp-history-searches.component.html',
  styleUrls: ['./corp-history-searches.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorpHistorySearchesComponent implements OnInit {

  private searches: SearchesInterface[];

  @Input() state: CorpHistoryStateModel;

  readonly listLayout = '0.85fr 1.15fr 1fr 0.75fr 1.35fr';

  @Input() viewMode: 'searches' | 'reports' = 'searches';

  @Input() filterValue: Record<string, any>;

  @Input() layout: 'sm' | 'md' | 'lg' = 'md';

  @Input() currentPage: number;

  @Input() users: FilterDataUsers[];

  @Input() set items(val: SearchesInterface[]) {
    this.searches = val;
  }

  get items() {
    return this.searches;
  }

  @Input() downloadingIdList: string[] = [];

  @Output() changePage = new EventEmitter<number>();

  @Output() hideTop = new EventEmitter<boolean>();

  @Output() changeFilters = new EventEmitter<any>();

  @Output() downloadReport = new EventEmitter<SearchesInterface>();

  @Output() openReport = new EventEmitter<string>();

  constructor(
  ) {
  }

  onChangePage(page: number) {
    this.changePage.emit(page);
  }

  applyFilters(event: Record<string, any>) {
    this.changeFilters.emit(event);
  }

  ngOnInit(): void {
  }

  onDownloadReport(search: SearchesInterface) {
    this.downloadReport.emit(search);
  }

  onOpenReport(searchId: string) {
    this.openReport.emit(searchId);
  }

  isDownloading(search: ISearchHistorySearchListItem) {
    return this.downloadingIdList.includes(search.searchID);
  }


  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
