import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-tabs',
  templateUrl: './accordion-tabs.component.html',
  styleUrls: ['./accordion-tabs.component.scss']
})
export class AccordionTabsComponent implements OnInit {
  cardShown: any;
  // сколько событий показывать без кнопки Показть еще
  eventsLength;

  testData = [
    {
      name: 'Смирнов Егор',
      title: 'Добавление документов',
      date: '01.07.2020',
      time: '12:32',
      type: 1,
      id: 1,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Документы',
      type: 2,
      date: '01.09.2020',
      time: '15:42',
      id: 2,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Получено решение арбитражного суда. Иск удовлетворен частично.',
      type: 3,
      date: '01.09.2020',
      time: '15:42',
      id: 3,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Создана и оплачена заявка на претензионную работу',
      type: 4,
      date: '01.09.2020',
      time: '15:42',
      id: 4,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Создана заявка на претензионную работу',
      type: 5,
      date: '01.09.2020',
      time: '15:42',
      id: 5,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Оплачен тариф «Все включено» для взыскания долга в судебном порядке',
      type: 6,
      date: '01.09.2020',
      time: '15:42',
      id: 6,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Проведено основное судебное заседание. Иск удовлетворен полностью.',
      type: 7,
      date: '01.09.2020',
      time: '15:42',
      id: 7,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Истек срок ожидания ответа на претензию',
      type: 8,
      date: '01.09.2020',
      time: '15:42',
      id: 8,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Добавлена доверенность для подписания',
      type: 9,
      date: '01.09.2020',
      time: '15:42',
      id: 9,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Мы подготовили претензию',
      type: 10,
      date: '01.09.2020',
      time: '15:42',
      id: 10,
    },
    {
      name: 'Авоськина Регина',
      title: 'Претензия удволетворена с условием',
      type: 11,
      date: '01.09.2020',
      time: '15:42',
      id: 11,
    },
  ];

  showMore = false;

  showModalPerson = false;
  showModalPost = false;

  showOptions = false;
  attention = true;
  attentionStep1 = false;
  showServicesList = false;
  toHideForm = false;
  toHideForm1 = true;
  toHideForm2 = true;
  toHideForm3 = true;

  // для мобилки
  mobile = false;

  constructor() {
  }

  ngOnInit(): void {
    if (window.innerWidth < 500) {
      this.eventsLength = 4;
      this.mobile = true;
    } else {
      this.eventsLength = 7;
      this.mobile = false;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 500) {
        this.eventsLength = 4;
        this.mobile = true;
      } else {
        this.eventsLength = 7;
        this.mobile = false;
      }
    });

  }

  showCard(id) {
    this.cardShown = id;
  }

  hideCard() {
    this.cardShown = '';
  }
}
