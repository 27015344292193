<div class="table-corp">
  <div class="table-corp__header" *ngIf="items && trademarks.length">
    <p class="head-table__first">Обозначение и статус</p>
    <p class="head-table__second" *ngIf="isShowColumn('owner')">Правообладатель</p>
    <p class="head-table__third" *ngIf="isShowColumn('date')">Дата</p>
    <p class="head-table__fourth" *ngIf="isShowColumn('classes')">Классы МКТУ</p>
    <p class="head-table__fifth" *ngIf="isShowColumn('countries')">Страны действия</p>
    <p class="head-table__six">Выбрать</p>
  </div>
  <div *ngFor="let sourceData of trademarks"
       class="table-corp__row"
       [class.table-corp__row--active]="sourceData.checked"
  >
    <div
      *ngIf="!sourceData._source.imageUrl"
      class="table-corp__img table-corp__img-text" (click)="selectTrademark(sourceData)">
      {{ sourceData._source.imageText }}
    </div>

    <div class="table-corp__img" *ngIf="sourceData._source.imageUrl" (click)="selectTrademark(sourceData)">
      <img [src]="sourceData._source.imageUrl | proxyImage" alt="">
    </div>
    <div class="table-corp__type" (click)="selectTrademark(sourceData)">
      <span>{{ sourceData | trademarkTitle: 'title' }} {{ getTrademarksIndex(sourceData) }}</span>
      <p><b>№ {{ sourceData | trademarkTitle: 'number' }}</b></p>
      <span *ngIf="_showColumns.length > 3">
              <app-risk-badge *ngIf="!disableRisk" [risk]="sourceData._risk"></app-risk-badge>
      </span>
    </div>
    <div class="table-corp__owner" *ngIf="isShowColumn('owner')" (click)="selectTrademark(sourceData)">
      <span class="table-corp__mobile-txt table-corp__mobile-txt--none">Правообладатель</span>
      <p *ngIf="sourceData._source.applicantName">{{trimString(sourceData._source.applicantName, 90)}}</p>
      <span class="comment-new__tooltip">{{sourceData._source.applicantName}}</span>
    </div>
    <div class="table-corp__date" *ngIf="isShowColumn('date')" (click)="selectTrademark(sourceData)">
      <span class="table-corp__mobile-txt table-corp__mobile-txt--none">Дата</span>
      <p *ngIf="sourceData._source.priorityDate">
        <span>{{mobile ? 'Дата приоритета' : 'Приоритета'}}</span>{{sourceData._source.priorityDate | date:'dd.MM.yyyy'}}
      </p>
      <p *ngIf="sourceData._source.registrationDate">
        <span>{{mobile ? 'Дата регистрации' : 'Регистрации'}}</span>{{sourceData._source.registrationDate | date:'dd.MM.yyyy'}}
      </p>
      <p *ngIf="sourceData._source.expiryDate">
        <span>Действует до</span>{{sourceData._source.expiryDate | date:'dd.MM.yyyy'}}</p>
    </div>
    <div class="table-corp__class-wrapper" *ngIf="isShowColumn('classes')">
      <span class="table-corp__mobile-txt">Классы МКТУ</span>
      <app-mktu-list-view
        [countriesDropdown]="countriesDropdown"
        [codes]="sourceData._source.goodsServices"
        [matching]="getMatchingCodes(sourceData)"
      ></app-mktu-list-view>
      <!--
      <div class="table-corp__class" *ngFor="let i of sourceData._source.goodsServices.slice(0, 9)">{{i.classNumber}}</div>-->
      <!--          <div class="table-corp__class table-corp__class&#45;&#45;critical">3</div>-->
    </div>
    <div class="table-corp__state" *ngIf="isShowColumn('countries')">
      <span class="table-corp__mobile-txt table-corp__mobile-txt--state">Страны действия</span>
      <app-countries-list-view
        [countriesDropdown]="countriesDropdown"
        [countries]="getCountries(sourceData)"
      ></app-countries-list-view>
    </div>
    <div class="table-corp__checkbox">
      <input class="table-corp__checkbox--input" id="{{sourceData.id}}" type="checkbox"
             [(ngModel)]="sourceData.checked">
      <label class="table-corp__checkbox--label" for="{{sourceData.id}}" (click)="toggleCheck($event, sourceData)"><span
        class="table-corp__mobile-blue" [class.table-corp__mobile-blue--delete]="sourceData.checked">
        {{sourceData.checked ? 'Убрать выбор' : 'Выбрать для отчета'}}</span></label>
    </div>
  </div>
</div>


<app-trademark-modal
  class="app-trademark-modal-new"
  [show]="!!selectedItem"
  [trademark]="selectedItem"
  (close)="closeModal()"
></app-trademark-modal>
