import { Injectable } from '@angular/core';
import { SearchService } from '../../../../../app-search/src/app/services/search.service';
import {
  ICountry,
  IGetOverByLegalId,
  IMktu,
  IOkved, ISearchOptions,
  ISearchRequestData
} from '../../../../../app-search/src/app/models/search.interfaces';
import { map } from 'rxjs/operators';
import { DataService } from '../../../../../app-ipid/src/app/shared/services/api.service';
import { forkJoin, Observable, } from 'rxjs';
import {
  ISearchHistoryDetail,
  ISearchHistoryParams,
  ISearchHistoryPayload
} from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { SearchNameResult } from '@web/features/corp/corp-search/states/models/search-name-response.interface';
import { SearchRequestInterface } from '@web/features/corp/corp-search/states/models/search-request.interface';
import { CORP_SEARCH_TOKEN } from '@web/features/corp/corp-search/states/search.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class CorpSearchApiService {
  objectsLoading = true;

  constructor(
    private searchService: SearchService,
    private apiService: DataService,
    private store: Store,
  ) {
  }


  public getSearchDetailById(searchID: string): Observable<ISearchHistoryDetail> {
    const params: ISearchHistoryPayload = {
      searchID
    };
    return this.apiService.getSearchDetailById(params);
  }

  public searchByName(params: SearchRequestInterface): Observable<SearchNameResult> {
    const domains$ = this.searchDomains(params);
    const legalNames$ = this.searchLegalEntities(params);
    const tradeMarks$ = this.searchTrademarks(params);

    return forkJoin([domains$, legalNames$, tradeMarks$])
      .pipe(
        map(([domains, legalNames, tradeMarks]) => ({domains, legalNames, tradeMarks})),
      );
  }

  public saveSearchByName(params: ISearchOptions, data: SearchNameResult) {
    const searchParameters: ISearchHistoryParams = {
      searchType: 'name',
      text: params.designation,
      mktuList: params.mktuList,
      okvedList: params.okvedList,
      countriesList: params.countriesList,
      registrationDate: params.registrationDate,
      applicationDate: params.applicationDate,
      priorityDate: params.priorityDate,
      searchNumber: params.searchNumber,
      searchLegalId: params.searchLegalId,
    };

    return this._saveSearch({
      searchParameters,
      results: [
        {entity: 'domain', result: data.domains},
        {entity: 'legalname', result: data.legalNames},
        {entity: 'trademark', result: data.tradeMarks},
      ],
    });
  }

  public searchByImage(params: ICorpSearchApiByNameParams): Observable<ICorpSearchApiByNameResult> {
    const domains$ = this.searchDomains(params);
    const legalNames$ = this.searchLegalEntities(params);
    const tradeMarks$ = this.searchTrademarks(params);

    return forkJoin([domains$, legalNames$, tradeMarks$])
      .pipe(
        map(([domains, legalNames, tradeMarks]) => ({domains, legalNames, tradeMarks})),
      );
  }

  public saveSearchByImage(params: ICorpSearchApiByImageParams, data: ICorpSearchApiByImageResult, image: string) {
    const searchParameters: ISearchHistoryParams = {
      searchType: 'image',
      mktuList: params.gsNumber,
      okvedList: params.eaNumber,
      countriesList: params.countryCodes,
      image: {
        filename: params.image.filename,
        url: image
      }
    };

    return this._saveSearch({
      searchParameters,
      results: [
        {entity: 'trademark', result: data},
      ],
    });
  }

  public searchTrademarksByOwner(params: ICorpSearchApiByOwnerParams): Observable<ICorpSearchApiByOwnerResult> {
    const domains$ = this.getOverDomains(params);
    const tradeMarks$ = this.getOverTradeMarks(params);

    return forkJoin([domains$, tradeMarks$])
      .pipe(
        map(([domains, tradeMarks]) => ({domains, tradeMarks}))
      );
  }

  public saveSearchTrademarksByOwner(params: ICorpSearchApiByOwnerParams, result: ICorpSearchApiByOwnerResult) {
    const searchParameters: ISearchHistoryParams = {
      searchType: 'owner',
      text: params.owner,
      okvedList: [],
      mktuList: [],
      countriesList: [],
    };

    return this._saveSearch({
      searchParameters,
      results: [
        {entity: 'domain', result: result.domains},
        {entity: 'trademark', result: result.tradeMarks},
      ],
    });
  }


  public searchTrademarksByNumber(params: ICorpSearchApiByNumberParams): Observable<ICorpSearchApiByNumberResult> {
    return this.searchService
      .searchTrademarksByNumber(params)
      .pipe(this._addIndexPipe());
  }

  public saveSearchTrademarksByNumber(params: ICorpSearchApiByNumberParams, result: ICorpSearchApiByNumberResult) {
    const searchParameters: ISearchHistoryParams = {
      searchType: 'number',
      text: params.number,
      mktuList: [],
      okvedList: [],
      countriesList: [],
    };

    return this._saveSearch({
      searchParameters,
      results: [{entity: 'trademark', result}]
    });
  }


  public getOverTradeMarks(params: ICorpSearchApiByOwnerParams) {
    return this.getOverTradeMarksOrDomains(params, {searchTradeMarks: true});
  }

  public getOverDomains(params: ICorpSearchApiByOwnerParams) {
    return this.getOverTradeMarksOrDomains(params, {searchDomains: true});
  }

  private getOverTradeMarksOrDomains(params: ICorpSearchApiByOwnerParams, additional: Partial<IGetOverByLegalId> = {}) {
    return this.searchService
      .getOverTradeMarksOrDomains({
        id: params.owner,
        ...additional,
      })
      .pipe(this._addIndexPipe());
  }

  public searchTrademarks(params: SearchRequestInterface) {
    return this._getTradeMarks(params, {...this.store.selectSnapshot(CORP_SEARCH_TOKEN).tradeMarksPagination});
  }

  public searchLegalEntities(params: SearchRequestInterface) {
    return this._getTradeMarks(params, {searchLegalEntities: true, ...this.store.selectSnapshot(CORP_SEARCH_TOKEN).legalNamesPagination});
  }

  public searchDomains(params: SearchRequestInterface) {
    return this._getTradeMarks(params, {searchDomains: true, ...this.store.selectSnapshot(CORP_SEARCH_TOKEN).domainsPagination});
  }


  private _getTradeMarks(params: SearchRequestInterface, customParams: Partial<ISearchRequestData> = {}) {
    return this.searchService
      .getTradeMarks({
        text: params.text,
        registrationDate: params.registrationDate,
        applicationDate: params.applicationDate,
        priorityDate: params.priorityDate,
        searchNumber: params.searchNumber,
        searchLegalId: params.searchLegalId,
        gsNumber: params.gsNumber,
        eaNumber: params.eaNumber,
        countryCodes: params.countryCodes,
        index: params.index,
        ...customParams
      })
      .pipe(this._addIndexPipe());
  }

  // добавляем порядковый номер к каждому объекту потому что на стороне юрайта так закостылили
  private _addIndexPipe() {
    return map<any, any>((obj) => {
      const all = obj?.hits?.hits ?? [];
      if (all.length) {
        obj.hits.hits = all.map((obj, index) => ({...obj, __indexNumber: index, checked: false}));
      }
      return obj;
    });
  }

  private _saveSearch(params: ISearchHistoryDetail) {
    return this.apiService.saveSearch(params);
  }


  public addSearchItemsToReport(searchID: string, ids: { tradeMarks: string[], legalNames: string[], domains: string[] }) {
    const entities = [];
    if (ids.tradeMarks.length) {
      entities.push({entity: 'trademark', strings: ids.tradeMarks.map(i => ({stringID: i, mark: true}))});
    }
    if (ids.legalNames.length) {
      entities.push({entity: 'legalname', strings: ids.legalNames.map(i => ({stringID: i, mark: true}))});
    }
    if (ids.domains.length) {
      entities.push({entity: 'domain', strings: ids.domains.map(i => ({stringID: i, mark: true}))});
    }

    return this.apiService.addSearchItemsToReport({searchID, entities})
      .pipe(
        map(result => {
          if (result?.answer !== 'Успешно') {
            throw new Error(result.answer);
          }
          return {ok: true};
        })
      );
  }
}


export interface ICorpSearchApiByOwnerParams {
  owner: string;
}

export interface ICorpSearchApiByOwnerResult {
  tradeMarks: any;
  domains: any;
}


export interface ICorpSearchApiByNameParams {
  text: string;
  mktuList: IMktu[];
  okvedList: IOkved[];
  countriesList: ICountry[];
  registrationDate?: string[];
  applicationDate?: string[];
  priorityDate?: string;
  searchNumber?: string;
  searchLegalId?: string;
}

export interface ICorpSearchApiByImageParams {
  gsNumber: IMktu[];
  eaNumber: IOkved[];
  countryCodes: ICountry[];
  image: IImage;
}

export interface IImage {
  filename?: string;
  url?: string;
  binary?: string;
}

export interface ICorpSearchApiByNameResult {
  tradeMarks: any;
  domains: any;
  legalNames: any;
}

export type ICorpSearchApiByImageResult = any;

export interface ICorpSearchApiByNumberParams {
  number: string;
}

export type ICorpSearchApiByNumberResult = any;
