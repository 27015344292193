import { environment } from '../../../environments/environment';

const statics = [
  {baseUrl: 'https://static.9958258.ru', alias: '9958258'},
  {baseUrl: 'http://static.intelis.ru', alias: 'signs'},
];


// проксируем статичные ресурсы
export function proxyStaticUrl(url: string) {
  if (!url) {
    return url;
  }

  // если /tm то это картирка в эластике
  if (url.startsWith('/tm')) {
    return `${environment.BASE_ELASTIC_URL_API}/${url}`;
  }


  // проверяем "заменяемый" ли домен
  for (const st of statics) {
    if (url.startsWith(st.baseUrl)) {
      const urlPath = url.slice(st.baseUrl.length);
      return `${environment.BASE_URL_API}/static/${st.alias}${urlPath}`;
    }
  }

  return url;
}
