<div class="trademarks">
  <div class="carousel-title">
    Товарные знаки
    <div class="carousel-counter">
      {{ tradeMarksData?.hits?.total?.tradeMarks ? (tradeMarksData?.hits?.total?.tradeMarks > 100 ? '100+' :
      tradeMarksData?.hits?.total?.tradeMarks) : 0 }}
    </div>
  </div>
  <div *ngIf="allTradeMarks && allTradeMarks.length === 0" class="empty-result">
    <img src="/assets/icons/empty.svg">
    <div class="empty-result__text">Товарные знаки по данному запросу не найдены</div>
  </div>
  <div class="trademarks-list__wrap">
    <div class="trademarks-list" [ngStyle]="allTradeMarks.length < 4 ? {'justify-content': 'center'} : {}"
         [class.trademarks-list__notIpiD]="brandsearch || legalsupport">
      <div class="trade-mark-item" [class.trade-mark-item__notIpID]="brandsearch || legalsupport"
           *ngFor="let tradeMark of tradeMarks">
        <div (click)="openModalNew(tradeMark)" class="trade-mark-card">
          <div class="buttons_risk">
            <div *ngIf="tradeMark._risk">
              <button *ngIf="tradeMark._risk.toLowerCase() ===  'high'" class="btn-risk box_button__warning">
                Высокий риск
              </button>
              <button *ngIf="tradeMark._risk.toLowerCase() ===  'medium'" class="btn-risk box_button__middle">
                Средний риск
              </button>
              <button *ngIf="tradeMark._risk.toLowerCase() === 'low'" class="btn-risk box_button__low">
                Низкий риск
              </button>
              <button *ngIf="tradeMark._risk.toLowerCase() ===  'none'" class="btn-risk box_button__none">
                Нет риска
              </button>
            </div>
          </div>

          <div *ngIf="!tradeMark._source.imageUrl || !tradeMark._source.markImageFileName"
               class="trade-mark-card__header_cover">
            {{ tradeMark._source.imageText }}
          </div>

          <div class="card__logo" [class.margin__notIpiD]="brandsearch || legalsupport"
               *ngIf="tradeMark._source.imageUrl && tradeMark._source.markImageFileName">
            <img style='height: 95%; width: 100%; margin-top: 5px; object-fit: contain' class="trade-mark-card__image"
                 [src]="tradeMark._source.imageUrl" alt="">
          </div>

          <div class="trade-mark-card__footer">
            <div class="type_mark">
              Классы МКТУ
            </div>
            <div class="class-numbers" *ngIf="tradeMark._index !== 'rugp' && tradeMark._index !== 'rugpap'">
              <div *ngFor="let classes of tradeMark._source.goodsServices| slice:0:2;"
                   class="class-number class-number--desktop">
                {{classes.classNumber}}
              </div>
              <div *ngFor="let classes of tradeMark._source.goodsServices| slice:0:2;"
                   class="class-number class-number--mobile">
                {{classes.classNumber}}
              </div>

              <div *ngIf="tradeMark._source.goodsServices && tradeMark._source.goodsServices.length > 2"
                   class="added_classes added_classes--desktop">
                +{{ tradeMark._source.goodsServices.length - 2 }}
              </div>
              <div *ngIf="tradeMark._source.goodsServices && tradeMark._source.goodsServices.length > 2"
                   class="added_classes added_classes--mobile">
                +{{ tradeMark._source.goodsServices.length - 2 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isViewLoadCard()" class="trade-mark-item brand-search-card"
           [class]="legalsupport? 'legal-support-card': ''"
           [class.trade-mark-item__notIpiD]="brandsearch || legalsupport"
           [class.img__notIpID]="brandsearch || legalsupport">
        <img *ngIf="!legalsupport" src="/assets/icons/free-label.svg">
        <div class="title">{{legalsupport ? 'Сходные знаки можно обойти' : 'Скачайте полный отчет'}}</div>
        <div class="text" *ngIf="brandsearch && !legalsupport" [class.padding__notIpID]="brandsearch || legalsupport">
          Индивидуальные рекомендации по регистрации<br>
          5 способов доработки товарного знака от ведущих компаний<br>
          Полный отчет с результатами поиска по всем базам и реестрам
        </div>
        <div class="text" *ngIf="!brandsearch && legalsupport" [class.padding__notIpID]="brandsearch || legalsupport">
          Оставьте заявку и мы расскажем как избежать противопоставлений<br> при подаче заявки в Роспатент. Это
          бесплатно.
        </div>

        <button class="btn-download" *ngIf="!brandsearch && legalsupport"
                (click)="eventsService.emitAction('uznat-kak')">
          Узнать как
        </button>

        <button class="btn-download" *ngIf="brandsearch && !legalsupport" (click)="emitClickDownload()">
          Скачать отчет
        </button>
      </div>

      <div class="carousel-arrow carousel-arrow__back"
           *ngIf="trademarkOffset && tradeMarks.length"
           (click)="changeTrademarkSlider('back')"
           [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div *ngIf="((trademarkOffset + 4) < allTradeMarks.length) && tradeMarks.length"
           class="carousel-arrow carousel-arrow__next" (click)="changeTrademarkSlider('next')"
           [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div *ngIf="((trademarkOffset +3) < allTradeMarks.length) && tradeMarks.length"
           class="carousel-arrow carousel-arrow__next carousel-arrow__next--tablet"
           (click)="changeTrademarkSlider('next')"
           [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>

  <app-trademark-modal
    [show]="showModalNew"
    [trademark]="selectedTrademark"
    (close)="closeModal()">
  </app-trademark-modal>
</div>
