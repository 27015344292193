<div class="page-wrapper">
  <main [ngClass]="userBlock ? 'user-block': ''">
    <div class="user-card">
      <section class="user-card__wrap container">
        <div class="user">
          <div class="user__wrap">
            <h1 class="visual-hidden">Карточка пользователя с настройками прав доступа</h1>
            <img src="/assets/images/avatar.png" alt="" class="user__avatar" width="120" height="120">
            <div>
              <p class="user__name">Ирина Иванова</p>
              <ul class="user__info-list">
                <li *ngIf="userBlock" class="user__info-item user__info-item--block">Пользователь заблокирован</li>
                <li class="user__info-item">Менеджер</li>
                <li class="user__info-item">
                  <a href="mailto:ivanova.i@companyname.ru" class="user__links">ivanova.i@companyname.ru</a>
                </li>
              </ul>
            </div>
          </div>
          <button *ngIf="!userBlock" class="user__button-block">Заблокировать пользователя</button>
          <button *ngIf="userBlock" class="user__button-block user__button-block--unblock">
            Разблокировать пользователя
          </button>
        </div>

        <div class="user__settings-wrap">
          <p class="user__settings-desc">
            <span>Настройки прав для</span>
            <ng-container *ngIf="organizations.length == 1">
              <span *ngFor="let organization of organizations">&nbsp;{{ organization?.name }}</span>
            </ng-container>
          </p>

          <div *ngIf="organizations.length > 1" class="organization-dropdown">
            <button class="organization-dropdown__button" (click)="showDropdown = !showDropdown">
              <span class="organization-dropdown__text">
                {{ selected }}
              </span>
              <img
                *ngIf="!userBlock"
                class="dropdown__button-img"
                [ngClass]="showDropdown ? 'rotate': ''"
                src="/assets/icons/icon-dropdown-blue.svg" alt="" width="12" height="6"/>
              <img
                *ngIf="userBlock"
                class="dropdown__button-img"
                src="/assets/icons/icon-dropdown-gray.svg" alt="" width="12" height="6"/>
            </button>
            <div *ngIf="showDropdown" class="organization-dropdown__menu-wrap">
              <div class="dropdown__menu">
                <span class="dropdown__menu-title">Организация</span>
                <ng-container *ngFor="let item of organizations;">
                  <input type="radio"
                         class="visual-hidden dropdown__input dropdown__input--radio"
                         [value]='item?.name'
                         [(ngModel)]="selected"
                         id="{{ item?.id }}">
                  <label class="dropdown__label" for="{{ item?.id }}">
                    <span>{{ item?.name }}</span>
                  </label>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="user-card__container">
      <div *ngIf="!userBlock" class="container">
        <section class="confirm">
          <h2 class="confirm__title">Пожалуйста, подтвердите полномочия</h2>
          <div class="confirm__steps">
            <div class="confirm__step confirm__step--1">
              <h3 class="confirm__step-title">Шаг 1</h3>
              <p class="confirm__desc">
                Информационная безопасность и доступ к чувствительной информации по объектам
                интеллектуальной собственности – наши главные приоритеты. Для  доступа и возможности
                совершать критичные действия от имени «Ирина Иванова», вам необходимо загрузить доверенность.
              </p>
              <div class="confirm__attorney">
                <p class="confirm__attorney-desc">
                  <span class="confirm__attorney-title">Доверенность</span>
                  <span class="confirm__attorney-doc">nazvanie_dogovora.pdf</span>
                </p>
                <img src="/assets/images/icon-add-attorney.png" alt="" class="" width="55" height="55">
              </div>
            </div>
            <div class="confirm__step confirm__step--2">
              <h3 class="confirm__step-title">Шаг 2</h3>
              <p class="confirm__desc">
                Прикрепите подписанный скан/фото доверенности
              </p>
              <div class="confirm__file">
                <span>Doverennost.pdf</span>
                <button class="confirm__file-del" aria-label="Удалить"></button>
              </div>
              <div class="confirm__add">
                <svg width="32" height="33" viewBox="0 0 32 33" class="confirm__add-img" >
                  <path d="M17.5586 14.6914H22.3086V17.8086H17.5586V22.5586H14.4414V17.8086H9.69141V14.6914H14.4414V9.94141H17.5586V14.6914ZM31.8086 5.93359V22.5586L22.3086 32.0586H5.68359C4.19922 32.0586 2.91276 31.5143 1.82422 30.4258C0.735677 29.3372 0.191406 28.0508 0.191406 26.5664V5.93359C0.191406 4.44922 0.735677 3.16276 1.82422 2.07422C2.91276 0.985677 4.19922 0.441406 5.68359 0.441406H26.3164C27.8008 0.441406 29.0872 0.985677 30.1758 2.07422C31.2643 3.16276 31.8086 4.44922 31.8086 5.93359ZM28.6914 6.45312C28.6914 5.66146 28.3945 4.99349 27.8008 4.44922C27.2565 3.85547 26.5885 3.55859 25.7969 3.55859H6.20312C5.41146 3.55859 4.71875 3.85547 4.125 4.44922C3.58073 4.99349 3.30859 5.66146 3.30859 6.45312V26.1953C3.30859 26.987 3.58073 27.6797 4.125 28.2734C4.71875 28.8177 5.41146 29.0898 6.20312 29.0898H20.75V26.7148C20.75 25.181 21.2943 23.8698 22.3828 22.7812C23.4714 21.6927 24.7826 21.1484 26.3164 21.1484H28.6914V6.45312Z"/>
                </svg>
                <div>
                  <label>
                    <input type="file" class="visual-hidden">
                    <span class="confirm__add-label">Прикрепить скан/фото доверенности</span>
                  </label>
                  <span class="confirm__add-desc">Изображение, не более 2мб</span>
                </div>
                <div class="confirm-tooltip">
                  <span class="confirm-tooltip__block">?</span>
                  <div class="confirm-tooltip__wrap">
                    <div class="confirm-tooltip__desc-wrap">
                      <p class="confirm-tooltip__desc">Тут могла бы быть ваша реклама</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="confirm__button-send-wrap">
                <button class="user-card__button user-card__button--send">Отправить доверенность</button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <app-confidants></app-confidants>

      <app-intellectual-property [userBlock]="userBlock"></app-intellectual-property>

      <div class="user-card__button-wrap container">
        <button class="user-card__button user-card__button--revoke">Отозвать доступ</button>
        <button class="user-card__button">Сохранить изменения</button>
      </div>
    </div>

    <section class="section-position section-position__exp section-position__exp--user-profile">
      <div class="container section-position__expert">
        <div class="section-position__expert-img">
          <img src="/assets/images/expert.png" alt="" width="314"/>
        </div>
        <div class="expert">
          <h2>Задать вопрос патентному поверенному</h2>
          <div class="expert__btn-wrapper">
            <button type="button" class="expert__btn" *ngFor=" let btn  of btns">{{ btn }}</button>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
