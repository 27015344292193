import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  readonly events = new EventEmitter<{ name: string, payload: any }>();

  emitAction(name: string) {
    this.emit('action', {name});
  }

  emit(name: string, payload: any) {
    this.events.emit({name, payload});
  }

}
