import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trademarkTitle'
})
export class TrademarkTitlePipe implements PipeTransform {

  transform(trademark: any, returnType: 'inline' | 'title' | 'number' = 'inline'): string {
    const [title, number] = this.getTradermarkData(trademark);
    if (returnType === 'title') {
      return title;
    } else if (returnType === 'number') {
      return number;
    } else {
      return `${title} № ${number}`;
    }
  }

  getTradermarkData(trademark: any) {
    if (trademark._index === 'rutm') {
      return ['Товарный знак', trademark._source.registrationString];
    } else if (trademark._index === 'rugp') {
      return [
        'Наименование мест происхождения товара',
        trademark._source.registrationString
      ];
    } else if (trademark._index === 'rugpap') {
      return [
        'Заявка на наименование мест происхождения товаров',
        trademark._source.applicationString
      ];
    } else if (trademark._index === 'rutmap') {
      return [`Заявка на товарный знак`, trademark._source.applicationString];
    } else if (trademark._index === 'wktm') {
      return [`Общеизвестный товарный знак`, trademark._source.registrationString];
    } else if (trademark._index === 'wotm') {
      return [`Международный товарный знак`, trademark._source.registrationString];
    } else {
      return ['Товарный знак', trademark._source.registrationString || trademark._source.applicationString];
    }

    return ['', ''];
  }
}
