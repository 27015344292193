import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpContext } from '@angular/common/http';
import { BASE_REQUEST_CONTEXT } from '@web/utils/auth.interceptor';
import { environment } from 'projects/app-ipid/src/environments/environment';
import { TrademarkDraftPromocode } from '@web/features/requests/trademark/types/trademark.types';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

export interface IPromoCode {
  PromocodeID?: string;
}

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoCodeComponent {
  public promoCode: string = '';
  public showSuccess: boolean = false;
  public isShowPromoInput: boolean = false;
  @Input() set promocode(value: TrademarkDraftPromocode | null) {
    this.promoCode = value ? value.code : '';
    this.showSuccess = value ? true : false;
  }
  @Output() promoEvent = new EventEmitter<TrademarkDraftPromocode>();

  constructor(
    private readonly toaster: ToastrService,
    private readonly httpClient: HttpClient,
  ) {}

  public showPromoInput(): void {
    this.isShowPromoInput = true;
  }

  public getPromoCode(): void {
    if (this.promoCode.length) {
      this.httpClient.get<IPromoCode>(`${environment.BASE_URL_API}/request/promocode`, {
        params: { 'PromocodeName': this.promoCode },
        context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
      }).subscribe(data => {
        data.PromocodeID.length > 1
          ? this.getPromoCodeSuccess(data.PromocodeID)
          : this.getPromoCodeFail();
      })
    }
  }

  private getPromoCodeSuccess(promocode: string): void {
    this.showSuccess = true;
    this.promoEvent.emit({ id: promocode, code: this.promoCode });
  }

  private getPromoCodeFail(): void {
    this.showSuccess = false;
    this.isShowPromoInput = false;
    this.toaster.info('Промокод не найден');
  }
}
