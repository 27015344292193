<div class="support-modal">
  <div (click)="closeModalSupport()" class="modal__top">
                  <span class="modal__close js-show-modal__close">
                    <svg width="26" height="26" viewBox="0 0 26 26">
                      <rect x="1.41431" width="34" height="2" transform="rotate(45 1.41431 0)"/>
                      <rect y="24.0416" width="34" height="2" transform="rotate(-45 0 24.0416)"/>
                    </svg>
                  </span>
    <h2 class="modal__title">Обращение в техническую поддержку</h2>
  </div>
  <form [formGroup]="supportForm" class="support-form form">
    <div class="form__group">
      <div class="form__input-wrap">
        <input
          class="form__input field"
          type="text"
          required="required"
          [class.error]="supportForm.get('fio').invalid && supportForm.get('fio').touched"
          (ngModelChange)="setValue()"
          formControlName="fio">
        <span class="form__input-label">Ваше имя</span>
        <span
          *ngIf="supportForm.controls.fio.touched && supportForm.controls.fio.invalid"
          class="invalid-field">
          Поле не может быть пустым
        </span>
      </div>
      <div class="form__input-wrap">
        <input
          class="form__input field"
          required
          email
          [class.error]="supportForm.get('mail').invalid && supportForm.get('mail').touched"
          (ngModelChange)="setValue()"
          formControlName="mail">
        <span class="form__input-label">Email</span>
        <span
          *ngIf="supportForm.controls.mail.touched && supportForm.controls.mail.invalid"
          class="invalid-field">
          Введите корректный email
        </span>
      </div>
      <div class="form__input-wrap">
        <input
          class="form__input field"
          required="required"
          (ngModelChange)="setValue()"
          (click)='showOrganizations()'
          [class.error]="!organizations?.length && supportForm.get('organization').invalid && supportForm.get('organization').touched"
          formControlName="organization">
        <span class="form__input-label">Название организации</span>
        <span
          *ngIf="!organizations?.length && supportForm.controls.organization.touched && supportForm.controls.organization.invalid"
          class="invalid-field">
          Поле не может быть пустым
        </span>
        <div class="form__dropdown form-card" *ngIf="showOrganizationsList">
          <ng-container>
            <table class="form-card__table">
              <thead class="dropdown-hover">
              <tr>
                <th>Наименование компании или ИП</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let organization of organizations" (click)="supportForm.get('organization').setValue(organization); showOrganizationsList = false">
                <td class="black">{{organization}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </div>
      <div class="form__input-wrap">
        <textarea
          class="field"
          required="required"
          formControlName="request"
          [class.error]="supportForm.get('request').invalid && supportForm.get('request').touched"
          (ngModelChange)="setValue()"
          cdkTextareaAutosize
          cdkAutosizeMinRows="{{7}}"
          cdkAutosizeMaxRows="{{7}}">
        </textarea>
        <span class="form__input-label textarea-label">Опишите проблему</span>
        <span
          *ngIf="supportForm.controls.request.touched && supportForm.controls.request.invalid"
          class="invalid-field">
          Поле не может быть пустым
        </span>
      </div>
      <span class="files-info">Вы можете прикрепить файлы с проблемой</span>
      <div appDragAndDrop (fileDropped)="onFileUpload($event)" class="files-block">
        <input
          class="support-add-file"
          type="file"
          (change)="onFileUpload($event)">
        <span class="files-info upload-file-info">
          <svg class="upload-icon" width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM14 9V13H10V9H7L12 4L17 9H14Z" fill="#929FAB"/>
          </svg>
          Загрузить файлы
        </span>
        <div class="support-text">Перетащите один или несколько файлов или кликните по полю для выбора файлов</div>
      </div>
      <div class="add-file-form__wrap" *ngIf="this.files.length">
        <div class="add-file-form__file" *ngFor="let file of this.files, index as i">
          <p>{{trimString(file?.name, 30)}}</p>
          <button class="add-file__del"
                  (click)="deleteFile(i)"
          >
          </button>
        </div>
      </div>
      <button
        [disabled]="!supportForm.get('fio').value || !supportForm.get('organization').value || !supportForm.get('mail').value || !supportForm.get('request').value"
        type="button"
        class="btn-blue btn-submit"
        (click)="submitForm()">Отправить</button>
    </div>
  </form>
  <div *ngIf="showOrganizationsList" (click)="showOrganizationsList = false;"
       class="overlay overlay--searchList"
       style="background: none;
                z-index: 4;">
  </div>
</div>
<div *ngIf="showModal" class="support-modal-wrapper" (click)="closeModalSupport()"></div>

