import { NgModule } from '@angular/core';
import { TitleDropdownComponent } from './components/title-dropdown/title-dropdown.component';
import { CommonModule } from '@angular/common';
import { TabsComponent } from './components/tabs/tabs.component';
import { RiskBadgeComponent } from './components/risk-badge/risk-badge.component';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ListViewComponent } from './components/list-view/list-view/list-view.component';
import { LvFilterMultiselectDirective } from './components/list-view/directives/lv-filter-multiselect.directive';
import { LvDataDirective } from './components/list-view/directives/lv-data.directive';
import { LvFilterComponent } from './components/list-view/lv-filter/lv-filter.component';
import { FilterInputComponent } from './components/filter-input/filter-input.component';
import { OkvedListViewComponent } from './components/okved-list-view/okved-list-view.component';
import { LvFilterInputDirective } from './components/list-view/directives/lv-filter-input.directive';
import { MktuListViewComponent } from './components/mktu-list-view/mktu-list-view.component';
import { FilterDaterangeComponent } from './components/filter-daterange/filter-daterange.component';
import { LvFilterDaterangeDirective } from './components/list-view/directives/lv-filter-daterange.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ImageProxyPipe } from './pipes/image-proxy.pipe';
import { TrademarkTitlePipe } from './pipes/tradermark-title.pipe';
import { PricePipe } from './pipes/price.pipe';
import { Payment2Component } from './components/payment2/payment2.component';
import { PaymentInvoicingComponent } from './components/payment2/payment-invoicing/payment-invoicing.component';
import { PaymentYandexComponent } from './components/payment2/payment-yandex/payment-yandex.component';
import { RouterModule } from '@angular/router';
import { ModalErrorComponent } from './components/modal-error/modal-error.component';
import { EmbLoaderComponent } from '../../../../shared/components/emb-loader/emb-loader.component';
import { PaymentModule } from '../../../../shared/components/payment/payment.module';
import { ButtonModule } from '../../../../shared/components/button/button.module';
import { NumberValidatorDirective } from './directives/number-validator.directive';
import { SharedAuthModule } from '../../../../shared/components/auth/shared-auth.module';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { CountriesListViewComponent } from './components/countries-list-view/countries-list-view.component';

const components = [
  TitleDropdownComponent,
  TabsComponent,
  RiskBadgeComponent,
  FilterSelectComponent,
  ListViewComponent,
  LvFilterMultiselectDirective,
  LvDataDirective,
  LvFilterComponent,
  FilterInputComponent,
  OkvedListViewComponent,
  LvFilterInputDirective,
  MktuListViewComponent,
  FilterDaterangeComponent,
  LvFilterDaterangeDirective,
  SpinnerComponent,
  PaginationComponent,
  ImageProxyPipe,
  PricePipe,
  TrademarkTitlePipe,
  PromoCodeComponent,
];

@NgModule({
  declarations: [
    ...components,
    Payment2Component,
    PaymentInvoicingComponent,
    PaymentYandexComponent,
    ModalErrorComponent,
    EmbLoaderComponent,
    NumberValidatorDirective,
    PromoCodeComponent,
    CountriesListViewComponent,
  ],
  exports: [
    ...components,
    ModalErrorComponent,
    EmbLoaderComponent,
    CountriesListViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    RouterModule,
    PaymentModule,
    ButtonModule,
    SharedAuthModule
  ]
})
export class SharedModule {
}
