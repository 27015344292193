import { StateContext, StateToken, State, Selector, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CorpSearchByImageService } from '@web/features/corp/corp-search/services/corp-search-by-image.service';
import { SearchService } from '../../../../../app-search/src/app/services/search.service';
import { SearchActions } from '@web/features/corp/corp-search/states/search.actions';
import {
  TrademarksImageInterface
} from '@web/features/corp/corp-search/states/models/search-image-response.interface';
import { SearchRequestInterface } from '@web/features/corp/corp-search/states/models/search-request.interface';
import {
  HitSearchNameInterface,
  SearchNameResponseInterface
} from '@web/features/corp/corp-search/states/models/search-name-response.interface';
import { tap } from 'rxjs/operators';
import { CorpSearchApiService } from '@web/features/corp/corp-search/services/corp-search-api.service';
import { PaginationInterface } from '@web/features/corp/corp-search/states/models/search-name-pagination.interface';
import { CorpSearchByNameService } from '@web/features/corp/corp-search/services/corp-search-by-name.service';
import {append, patch, updateItem} from '@ngxs/store/operators';
import { CorpSearchTmService } from '@web/features/corp/corp-search/services/corp-search-tm.service';
import { DataService } from '../../../../../app-ipid/src/app/shared/services/api.service';

export const CORP_SEARCH_TOKEN = new StateToken<SearchStateModel>('search');

@State({
  name: CORP_SEARCH_TOKEN,
  defaults: {
    isLoadingPage: false,
    isLoading: null,
    error: null,
    trademarksByImage: [],
    totalCount: 0,
    payload: {
      from: 0,
      size: 1000
    },
    trademarksByName: {
      hits: {
        hits: []
      }
    },
    domainsByName: {
      hits: {
        hits: []
      }
    },
    legalNamesByName: {
      hits: {
        hits: []
      }
    },
    tradeMarksPagination: {
      from: 0,
      size: 1000
    },
    domainsPagination: {
      from: 0,
      size: 1000
    },
    legalNamesPagination: {
      from: 0,
      size: 1000
    },
    isLoadingHistory: false,
    countriesList: []
  }
})
@Injectable()
export class SearchState {
  constructor(
    private corpSearchByImageService: CorpSearchByImageService,
    private searchApi: SearchService,
    private corpSearchApiService: CorpSearchApiService,
    private corpSearchByNameService: CorpSearchByNameService,
    private corpSearchTmService: CorpSearchTmService,
    private apiService: DataService
  ) {
  }

  @Selector([SearchState])
  static currentPage(state: SearchStateModel): number {
    return Math.floor(state.payload.from / state.payload.size) + 1;
  }

  @Selector([SearchState])
  static currentPageTrademarks(state: SearchStateModel): number {
    return Math.floor(state.tradeMarksPagination.from / state.tradeMarksPagination.size) + 1;
  }

  @Selector([SearchState])
  static currentPageDomains(state: SearchStateModel): number {
    return Math.floor(state.domainsPagination.from / state.domainsPagination.size) + 1;
  }

  @Selector([SearchState])
  static currentPageLegalNames(state: SearchStateModel): number {
    return Math.floor(state.legalNamesPagination.from / state.legalNamesPagination.size) + 1;
  }

  @Action(SearchActions.Search)
  async search(ctx: Context, { payload, saveSearch, imageData, searchOptions, imageFile }: SearchActions.Search) {
    ctx.setState(patch({countriesList: null}));
    if (searchOptions?.countriesList?.length) {
      searchOptions.countriesList
        .filter(country => country.code !== 'RU')
        .forEach(country => {
          ctx.setState(
            patch({
              countriesList: append<string>([country.code])
            })
          );
        });
    }
    ctx.patchState({isLoading: true, payload});
    if (ctx.getState().isLoadingHistory) {
      ctx.patchState({
        tradeMarksPagination: {
          from: 0,
          size: 1000
        },
        domainsPagination: {
          from: 0,
          size: 1000
        },
        legalNamesPagination: {
          from: 0,
          size: 1000
        }
      });
    }
    try {
      if (payload.image) {
        const searchByImageResponse = await this.corpSearchTmService.searchByImage(payload);
        this.corpSearchByImageService.pipeTrademarks(searchByImageResponse);
        if (saveSearch) {
          await this.corpSearchByImageService.uploadImage(payload, searchByImageResponse, imageFile);
        }
        ctx.patchState({
          trademarksByImage: searchByImageResponse.hits.hits.map((obj, index) => ({...obj, __indexNumber: index, checked: false})),
          totalCount: 1000,
          isLoading: false,
          isLoadingHistory: false
        });
        if (ctx.getState().payload.size === 1000) {
          ctx.patchState({
            payload: {
              ... payload,
              from: 0,
              size: 50
            }
          });
        }
        sessionStorage.setItem('imageBinary', imageData.imageBinary);
        sessionStorage.setItem('eventBase64', imageData.eventBase64);
      } else {
        const state = ctx.getState();
        this.corpSearchApiService.searchByName(payload)
          .pipe(
            tap((data) => saveSearch && this.corpSearchByNameService.saveSearch(searchOptions, data))
          )
          .subscribe((data) => {
            ctx.patchState({
              trademarksByName: data.tradeMarks,
              domainsByName: data.domains,
              legalNamesByName: data.legalNames,
              isLoading: false,
              isLoadingHistory: false
            });
            if (state.tradeMarksPagination.size === 1000 && state.domainsPagination.size === 1000 && state.legalNamesPagination.size === 1000) {
              ctx.patchState({
                tradeMarksPagination: {
                  from: 0,
                  size: 50
                },
                domainsPagination: {
                  from: 0,
                  size: 50
                },
                legalNamesPagination: {
                  from: 0,
                  size: 50
                }
              });
            }
            if (!saveSearch) {
              this.corpSearchApiService.objectsLoading = false;
            }
          });
      }
    } catch (error) {
      ctx.patchState({isLoading: false, error});
    }
  }

  @Action(SearchActions.ChangePage)
  changePage(ctx: Context, { changePage }: SearchActions.ChangePage) {
    if (ctx.getState().payload.image?.binary) {
      ctx.patchState({
        payload: {
          ...ctx.getState().payload,
          from: (changePage.page - 1) * changePage.size
        }
      });
    } else {
      ctx.patchState({
        [changePage.type] : {
          from: (changePage.page - 1) * changePage.size,
          size: changePage.size
        }
      });
    }
    ctx.dispatch(new SearchActions.Search(ctx.getState().payload));
  }

  @Action(SearchActions.CheckObject)
  checkObject(ctx: Context, { item }: SearchActions.CheckObject) {
    if (ctx.getState().trademarksByName?.hits?.hits?.length) {
      ctx.setState(
        patch({
          trademarksByName: patch({
            hits: patch({
              hits: updateItem<HitSearchNameInterface>(el => el && el._id === item._id, patch({ checked: !item.checked }))
            })
          })
        })
      );
    }
    if (ctx.getState().domainsByName?.hits?.hits?.length) {
      ctx.setState(
        patch({
          domainsByName: patch({
            hits: patch({
              hits: updateItem<HitSearchNameInterface>(el => el && el._id === item._id, patch({ checked: !item.checked }))
            })
          })
        })
      );
    }
    if (ctx.getState().legalNamesByName?.hits?.hits?.length) {
      ctx.setState(
        patch({
          legalNamesByName: patch({
            hits: patch({
              hits: updateItem<HitSearchNameInterface>(el => el && el._id === item._id, patch({ checked: !item.checked }))
            })
          })
        })
      );
    }

    if (ctx.getState().trademarksByImage?.length) {
      ctx.setState(
        patch({
          trademarksByImage: updateItem<TrademarksImageInterface>(el => el && el._id === item._id, patch({ checked: !item.checked }))
        })
      );
    }
  }

  @Action(SearchActions.LoadByHistory)
  loadByHistory(ctx: Context, { search, changePage }: SearchActions.LoadByHistory) {
    ctx.patchState({
      payload: {
        registrationDate: search.searchParameters.registrationDate,
        applicationDate: search.searchParameters.applicationDate,
        priorityDate: search.searchParameters.priorityDate,
        searchNumber: search.searchParameters.searchNumber,
        searchLegalId: search.searchParameters.searchLegalId,
        gsNumber: search.searchParameters.mktuList?.map(i => i.number),
        eaNumber: search.searchParameters.okvedList?.map(i => i.code),
        countryCodes: search.searchParameters.countriesList?.map(i => i.code),
        text: search.searchParameters.text,
        size: changePage?.limit ? changePage?.limit : 50,
        from: changePage?.offset ? changePage?.offset : 0,
        image: {
          filename: search.searchParameters.image?.filename,
          binary: search.searchParameters.image?.binary
        }
      },
      totalCount: search.searchParameters.searchType === 'image' ? 1000 : 0,
      isLoading: false,
      isLoadingHistory: true
    });
    if (search.searchParameters.searchType === 'image') {
      ctx.patchState({
        trademarksByImage: search
          .results
          .find(i => i.entity === 'trademark')
          .result.hits.hits.map((obj, index) => ({...obj, __indexNumber: index, checked: false}))
      });
    } else {
      ctx.patchState({
        trademarksByName: search.results.find(i => i.entity === 'trademark') ?
          search.results.find(i => i.entity === 'trademark').result : [],
        domainsByName: search.results.find(i => i.entity === 'domain') ?
          search.results.find(i => i.entity === 'domain').result : [],
        legalNamesByName: search.results.find(i => i.entity === 'legalname') ?
          search.results.find(i => i.entity === 'legalname').result : [],
        tradeMarksPagination: {
          from: changePage?.offset ? changePage?.offset : 0,
          size: changePage?.limit ? changePage?.limit : 50
        },
        domainsPagination: {
          from: changePage?.offset ? changePage?.offset : 0,
          size: changePage?.limit ? changePage?.limit : 50
        },
        legalNamesPagination: {
          from: changePage?.offset ? changePage?.offset : 0,
          size: changePage?.limit ? changePage?.limit : 50
        }
      });
    }
    if (ctx.getState().trademarksByName?.hits?.total) {
      const total = ctx.getState().trademarksByName.hits.total;
      ctx.setState(
        patch({
          trademarksByName: patch({
            hits: patch({
              total: patch({
                tradeMarks: total.tradeMarks > total.size ? total.size : total.tradeMarks
              })
            })
          })
        })
      );
    }
    if (ctx.getState().domainsByName?.hits?.total) {
      const total = ctx.getState().domainsByName.hits.total;
      ctx.setState(
        patch({
          domainsByName: patch({
            hits: patch({
              total: patch({
                domains: total.domains > total.size ? total.size : total.domains
              })
            })
          })
        })
      );
    }
    if (ctx.getState().legalNamesByName?.hits?.total) {
      const total = ctx.getState().legalNamesByName.hits.total;
      ctx.setState(
        patch({
          legalNamesByName: patch({
            hits: patch({
              total: patch({
                legalEntities: total.legalEntities > total.size ? total.size : total.legalEntities
              })
            })
          })
        })
      );
    }
    this.corpSearchApiService.objectsLoading = false;
  }

  @Action(SearchActions.ChangePageByHistory)
  changePageByHistory(ctx: Context, { payload }: SearchActions.ChangePageByHistory) {
    ctx.patchState({isLoading: true});
    this.apiService.getSearchDetailById(payload).subscribe(data => {
      ctx.dispatch(new SearchActions.LoadByHistory(data, payload));
    });
  }

  @Action(SearchActions.ClearSearch)
  clearSearch(ctx: Context) {
    ctx.patchState({
      isLoading: null,
      isLoadingHistory: false,
      trademarksByImage: [],
      totalCount: 0,
      trademarksByName: {
        hits: {
          hits: []
        }
      },
      domainsByName: {
        hits: {
          hits: []
        }
      },
      legalNamesByName: {
        hits: {
          hits: []
        }
      },
      payload: {
        size: 1000,
        from: 0
      },
      domainsPagination: {
        from: 0,
        size: 1000
      },
      tradeMarksPagination: {
        from: 0,
        size: 1000
      },
      legalNamesPagination: {
        from: 0,
        size: 1000
      },
    });
  }
}

type Context = StateContext<SearchStateModel>;

export interface SearchStateModel {
  isLoadingPage: boolean;
  isLoading: boolean | null;
  error: string | null;
  isLoadingHistory: boolean;
  trademarksByImage: TrademarksImageInterface[];
  trademarksByName: SearchNameResponseInterface | null;
  domainsByName: SearchNameResponseInterface | null;
  legalNamesByName: SearchNameResponseInterface | null;
  totalCount: number;
  payload: SearchRequestInterface;
  tradeMarksPagination: PaginationInterface;
  domainsPagination: PaginationInterface;
  legalNamesPagination: PaginationInterface;
  countriesList: string[];
}
