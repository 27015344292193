export default [
  {
    type: 6,
    threat: 'Данная организация может в судебном порядке заблокировать использование домена и перевести его на себя',
    recommendation: 'Зарегистрируйте товарный знак со словесным элементом домена',
    button: 'Оспорить'
  },
  {
    type: 14,
    threat: 'Владелец товарного знака может в судебном порядке заблокировать использование домена и перевести его на себя',
    recommendation: 'Получите согласие от правообладателя сходного товарного знака и зарегистрируйте собственный знак со словесным элементом домена',
    button: 'Оспорить'
  },
  {
    type: 18,
    threat: 'В случае успешной регистрации товарного знака его владелец сможет в судебном порядке блокировать использование вашего домена и перевести его на себя',
    recommendation: 'Подайте оппозицию против регистрации сходного товарного знака и зарегистрируйте собственный знак со словесным элементом домена',
    button: 'Оспорить'
  },
  {
    type: 22,
    threat: 'Данная организация может в судебном порядке заблокировать использование домена и перевести его на себя',
    recommendation: 'Получите согласие от организации и зарегистрируйте товарный знак со словесным элементом домена',
    button: 'Оспорить'
  },
  {
    type: 24,
    threat: 'Владелец товарного знака может в судебном порядке заблокировать использование домена и перевести его на себя',
    recommendation: 'Получите согласие от правообладателя сходного товарного знака и зарегистрируйте собственный знак со словесным элементом домена',
    button: 'Оспорить'
  },
  {
    type: 25,
    threat: 'В случае успешной регистрации товарного знака его владелец сможет в судебном порядке блокировать использование вашего домена и перевести его на себя',
    recommendation: 'Получите согласие от правообладателя сходного товарного знака и зарегистрируйте собственный знак со словесным элементом домена',
    button: 'Оспорить'
  },
  {
    type: 7,
    threat: 'Организация может оспорить ваш товарный знак и запретить его использование',
    recommendation: 'Если вы планируете увеличивать объем инвестиций в развитие бренда, то необходимо защитить уникальность фирменного наименования, товарного знака и домена ',
    button: 'Оспорить'
  },
  {
    type: 5,
    threat: 'Организация вправе в судебном порядке потребовать переименования вашей компании',
    recommendation: 'Если вы планируете увеличивать объем инвестиций в развитие бренда, то необходимо защитить уникальность фирменного наименования, товарного знака и домена ',
    button: 'Оспорить'
  },
  {
    type: 13,
    threat: 'Владелец товарного знака может в судебном порядке потребовать переименования вашей компании',
    recommendation: 'Если вы планируете увеличивать объем инвестиций в развитие бренда, то необходимо защитить уникальность фирменного наименования, товарного знака и домена ',
    button: 'Оспорить'
  },
  {
    type: 17,
    threat: 'В случае успешной регистрации товарного знака его владелец сможет в судебном порядке потребовать переименования компании',
    recommendation: 'Если вы планируете увеличивать объем инвестиций в развитие бренда, то необходимо защитить уникальность фирменного наименования, товарного знака и домена ',
    button: 'Оспорить'
  },
  {
    type: 3,
    threat: 'Использование похожего знака третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Направьте претензию нарушителю с требованием исключить из охраны товары и услуги, аналогичные вашим',
    button: 'Оспорить'
  },
  {
    type: 4,
    threat: 'Использование похожего знака третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Подайте оппозицию против регистрации сходного товарного знака',
    button: 'Оспорить'
  },
  {
    type: 11,
    threat: 'Такое сходство может ввести ваших клиентов в заблуждение ',
    recommendation: 'Заблокируйте указанный домен и переведите его на себя',
    button: 'Оспорить'
  },
  {
    type: 15,
    threat: 'Владелец указанного товарного знака может в судебном порядке оспорить ваш товарный знак и запретить его использование',
    recommendation: 'Если вы планируете увеличивать объем инвестиций в развитие бренда, то необходимо защитить уникальность фирменного наименования, товарного знака и домена ',
    button: 'Оспорить'
  },
  {
    type: 26,
    threat: 'Использование похожего названия третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Направьте претензию с требованием переименовать компанию или получить возмездную лицензию на использование вашего товарного знака',
    button: 'Оспорить'
  },
  {
    type: 27,
    threat: 'Использование похожего домена третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Заблокируйте указанный домен и переведите его  на себя',
    button: 'Оспорить'
  },
  {
    type: 1,
    threat: 'Использование похожего знака третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Направьте претензию с требованием исключить из охраны товары и услуги, аналогичные вашим',
    button: 'Оспорить'
  },
  {
    type: 2,
    threat: 'Такое сходство может ввести ваших клиентов в заблуждение',
    recommendation: 'Заблокируйте указанный домен и переведите его  на себя',
    button: 'Оспорить'
  },
  {
    type: 9,
    threat: 'Такое сходство может ввести ваших клиентов в заблуждение ',
    recommendation: 'Заблокируйте указанный домен и переведите его  на себя',
    button: 'Оспорить'
  },
  {
    type: 21,
    threat: 'Использование похожего знака третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Подайте оппозицию против регистрации сходного товарного знака',
    button: 'Оспорить'
  },
  {
    type: 28,
    threat: 'Использование похожего наименования третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Направьте претензию с требованием прекратить выпуск аналогичных товаров и оказание услуг',
    button: 'Оспорить'
  },
  {
    type: 8,
    threat: 'Данная организация может подать оппозицию против регистрации вашего товарного знака ',
    recommendation: 'Получите согласие от организации на регистрацию',
    button: 'Оспорить'
  },
  {
    type: 12,
    threat: 'Использование похожего домена третьими лицами приводит к размытию идентичности вашего бренда и снижению узнаваемости',
    recommendation: 'Заблокируйте указанный домен и переведите его  на себя после получения свидетельства о регистрации знака',
    button: 'Оспорить'
  },
  {
    type: 16,
    threat: 'Данная организация вправе подать оппозицию против регистрации вашего товарного знака',
    recommendation: 'Получите согласие от организации на регистрацию',
    button: 'Оспорить'
  },
  {
    type: 20,
    threat: 'Данная организация вправе подать оппозицию против регистрации вашего товарного знака',
    recommendation: 'Получите согласие от организации на регистрацию',
    button: 'Оспорить'
  },
  {
    type: 2001,
    threat: 'Если заявление подано не вами, то третьи лица могут получить возможность использовать ваш товарный знак',
    recommendation: 'Заблокируйте возможность регистрации договора в Роспатенте',
    button: 'Оспорить'
  },
  {
    type: 2002,
    threat: 'Третьи лица могут получить возможность использовать ваш товарный знак',
    recommendation: 'Защитите свой товарный знак в Роспатенте',
    button: 'Оспорить'
  },
  {
    type: 3001,
    threat: 'После истечения срока действия третьи лица получат возможность использовать ваш товарный знак или зарегистрировать его на себя',
    recommendation: 'Продлите срок действия вашего товарного знака',
    button: 'Продлить'
  },
  {
    type: 3002,
    threat: 'После истечения срока действия третьи лица получат возможность ввозить товары с вашим товарным знаком на территорию РФ',
    recommendation: 'Продлите срок внесения товарного знака в ТРОИС',
    button: 'Продлить'
  },
  {
    type: 3003,
    threat: 'После истечения срока действия договора будет невозможно использовать товарный знак',
    recommendation: 'Продлите срок действия договора',
    button: 'Продлить'
  },
  {
    type: 3004,
    threat: 'После истечения срока действия договора будет невозможно использовать товарный знак',
    recommendation: 'Продлите срок действия договора',
    button: 'Продлить'
  },
  {
    type: 3005,
    threat: 'После истечения срока действия третьи лица получат возможность зарегистрировать ваш домен на себя',
    recommendation: 'Продлите срок действия домена',
    button: 'Продлить'
  }
];
