import {Component, Input, OnInit} from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { IMainData } from '../../../shared/models/main.interfaces';
import { PortfolioService } from '../../../shared/services/portfolio.service';
import { SearchService } from '../../../../../../app-search/src/app/services/search.service';
import { RequestContractService } from '../shared/request-contract.service';
import { IRequestContractObject } from '../shared/contract.model';
import typeOfTradeMarks from './json-data/types-trademarks';
import typeOfPatents from './json-data/types-patents';
import typeOfSofts from './json-data/types-softs';
import {EnvService} from '../../../shared/services/env.service';

@Component({
  selector: 'app-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.scss']
})

export class ObjectsComponent implements OnInit {
  @Input() objectsImmutable;

  showMobileFilter = false;

  tab: 'trademarks' | 'patents' | 'softs' | 'search' = 'trademarks';
  showOptions = false;
  searchTypes = ['Товарные знаки', 'Патенты', 'Программы и базы данных'];
  selectedSearchType = this.searchTypes[0];
  countPage = 10;
  page = 1;
  trademarks = [];
  patents = [];
  softs = [];
  searches = [];
  loading = true;
  querySearchText = '';
  checkedElasticId = [];
  typeOfTrademarks = typeOfTradeMarks;
  typeOfPatents = typeOfPatents;
  typeOfSofts = typeOfSofts;
  filteredObjects = [];
  showNotFound = false;
  searchTextTrademarks;
  searchTextPatents;
  searchTextSofts;
  copyTrademarks;
  copyPatents;
  copySofts;
  showObjectType = false;

  constructor(
    private httpService: HttpService,
    private http: PortfolioService,
    private searchService: SearchService,
    private requestContractService: RequestContractService,
    public envService: EnvService
  ) { }

  ngOnInit(): void {
    this.changeMobileFilter();

    if (this.objectsImmutable) {
      this.trademarks = this.objectsImmutable.trademarks;
      this.patents = this.objectsImmutable.patents;
      this.softs = this.objectsImmutable.softs;
      this.copyTrademarks = JSON.parse(JSON.stringify(this.trademarks));
      this.copyPatents = JSON.parse(JSON.stringify(this.patents));
      this.copySofts = JSON.parse(JSON.stringify(this.softs));
    }

    window.addEventListener('resize', () => {
      this.changeMobileFilter();
    });

    this.parseObjects();
  }

  changeMobileFilter(): void {
    this.showMobileFilter = window.innerWidth >= 793;
  }

  makeBodyHidden(): void {
    if (window.innerWidth <= 793) {
      if (this.showMobileFilter === true) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.height = '100%';
      } else {
        document.body.style.overflow = 'visible';
        document.body.style.position = 'static';
        document.body.style.height = 'auto';
      }
    }
  }

  switchTab(type: 'trademarks' | 'patents' | 'softs' | 'search'): void {
    this.tab = type;
    this.page = 1;
  }

  trimString(str: string, count: number): string {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  onSelect(type: string): void {
    this.selectedSearchType = type;
    this.showOptions = false;
  }

  getCountChecked(massive: any[]): number {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });
    return counter;
  }

  getTypeByIndex(index: string): string {
    switch (index) {
      case 'rutm':
        return 'Товарный знак';
      case 'rutmap':
        return 'Заявка на товарный знак';
      case 'wotm':
        return 'Международный товарный знак';
      case 'wktm':
        return 'Общеизвестный товарный знак';
      case 'rugp':
        return 'Наименование мест происхождения';
      case 'rugpap':
        return 'Заявка на наименование мест происхождения';
      case 'rupat':
        return 'Патент на изобретение';
      case 'ruum':
        return 'Патент на полезную модель';
      case 'rude':
        return 'Патент на промышленный образец';
      case 'rusw':
        return 'Программа для ЭВМ';
      case 'rudb':
        return 'База данных';
    }
  }

  isRequest(index: string): boolean {
    return index === 'rutmap' || index === 'rugpap';
  }

  changePage(page: number): void {
    this.page = page;
  }

  searchObjects(): void {
    const query = {
      index: 'trademarks',
      name: this.querySearchText
    };
    if (this.selectedSearchType === 'Патенты') {
      query.index = 'patents';
    } else if (this.selectedSearchType === 'Программы и базы данных') {
      query.index = 'swdb';
    }

    this.http.searchObjects(query).subscribe((data: IMainData) => {
      if (data.hits.hits.length) {
        this.showNotFound = false;
        data.hits.hits.forEach(item => {
          item.checked = this.checkedElasticId.find(el => el.id === item._id);
          if (item._source.markImageFileName) {
            const id = item._source.registrationString || item._source.applicationString;
            item.imageUrl = this.searchService.getImgUrl(
              item._index,
              parseInt(id, 10),
              item._source.markImageFileName,
              id);
          }
        });
        this.searches = data.hits.hits;
      } else {
        this.searches = [];
        this.showNotFound = true;
      }
    });
  }

  getImgStubByIndex(index: string): string {
    switch (index) {
      case 'rudb':
        return 'assets/portfolio/databases.svg';
      case 'rusw':
        return 'assets/portfolio/programs.svg';
      case 'rupat':
      case 'ruum':
      case 'rude':
        return 'assets/portfolio/patents.svg';
    }
  }

  checkObject(fromElastic: boolean, object: any, isCheck: boolean): void {
    if (isCheck) {
      if (fromElastic) {
        const checkedObjectElastic: IRequestContractObject = {
          id: object._id,
          index: object._index,
          name: object._source.imageText || object._source.inventionName || object._source.programName || object._source.dbName,
          rightholder: object._source.applicantName || object._source.patentHolders || object._source.rightHolders,
          date: object._source.registrationDate || object._source.applicationDate,
          elasticObject: true
        };
        if (object._source.markImageFileName) {
          const id = object._index === 'rutmap' ? object._source.applicationString : object._source.registrationString;
          checkedObjectElastic.imageURL = this.searchService.getImgUrl(
            object._index,
            parseInt(id, 10),
            object._source.markImageFileName,
            id);
        }
        this.requestContractService.objects.push(checkedObjectElastic);
        this.checkedElasticId.push(object._id);
      } else {
        const checkedObject: IRequestContractObject = {
          id: object.id,
          index: object.index[0].id,
          elasticObject: false
        };
        this.requestContractService.objects.push(checkedObject);
      }
    } else {
      let id;
      if (fromElastic) {
        id = object._id;

        const ind = this.checkedElasticId.indexOf(id);
        if (ind > -1) {
          this.checkedElasticId.splice(ind, 1);
        }
      } else {
        id = object.id;
      }
      const index = this.requestContractService.objects.findIndex(el => el.id === id);
      if (index > -1) {
        this.requestContractService.objects.splice(index, 1);
      }
    }
  }

  get isLoading() {
    return this.requestContractService.isLoading;
  }

  filterObjectByType() {
    const indexes = [];
    console.log(this.tab);
    switch (this.tab) {
      case 'trademarks':
        if (this.typeOfTrademarks[0].checked) {
          indexes.push('rutm');
        }
        if (this.typeOfTrademarks[1].checked) {
          indexes.push('rutmap');
        }
        if (this.typeOfTrademarks[2].checked) {
          indexes.push('wotm');
        }
        if (this.typeOfTrademarks[3].checked) {
          indexes.push('wktm');
        }
        if (this.typeOfTrademarks[4].checked) {
          indexes.push('rugp');
        }
        if (this.typeOfTrademarks[5].checked) {
          indexes.push('rugpap');
        }
        console.log(indexes);
        if (indexes.length) {
          this.trademarks = this.trademarks.filter(item => indexes.includes(item.index[0].id));
          console.log(this.trademarks);
        }
        break;
      case 'patents':
        if (this.typeOfPatents[0].checked) {
          indexes.push('rupat');
        }
        if (this.typeOfPatents[1].checked) {
          indexes.push('ruum');
        }
        if (this.typeOfPatents[2].checked) {
          indexes.push('rude');
        }
        if (indexes.length) {
          this.patents = this.patents.filter(item => indexes.includes(item.index[0].id));
        }
        break;
      case 'softs':
        if (this.typeOfSofts[0].checked) {
          indexes.push('rusw');
        }
        if (this.typeOfSofts[1].checked) {
          indexes.push('rudb');
        }
        if (indexes.length) {
          this.softs = this.softs.filter(item => indexes.includes(item.index[0].id));
        }
        break;
    }
  }

  filterObjectsByText() {
    switch (this.tab) {
      case 'trademarks':
        if (this.searchTextTrademarks && this.searchTextTrademarks.length >= 1) {
          this.trademarks = this.trademarks.filter(i => i?.name?.toLowerCase().includes(this.searchTextTrademarks?.toLowerCase()) || i?.type?.toLowerCase().includes(this.searchTextTrademarks?.toLowerCase()) ||
            i?.validUntil?.toLowerCase().includes(this.searchTextTrademarks?.toLowerCase()) || (i?.rightHolder?.toLowerCase().includes(this.searchTextTrademarks?.toLowerCase()) || i?.client?.toLowerCase().includes(this.searchTextTrademarks?.toLowerCase())) ||
            i?.showId?.toLowerCase().includes(this.searchTextTrademarks?.toLowerCase())
          );
        }
        break;
      case 'patents':
        if (this.searchTextPatents && this.searchTextPatents.length >= 1) {
          this.patents = this.patents.filter(i => i?.name?.toLowerCase().includes(this.searchTextPatents?.toLowerCase()) || i?.type?.toLowerCase().includes(this.searchTextPatents?.toLowerCase()) ||
            i?.validUntil?.toLowerCase().includes(this.searchTextPatents?.toLowerCase()) || (i?.rightHolder?.toLowerCase().includes(this.searchTextPatents?.toLowerCase()) || i?.client?.toLowerCase().includes(this.searchTextPatents?.toLowerCase())) ||
            i?.showId?.toLowerCase().includes(this.searchTextPatents?.toLowerCase())
          );
        }
        break;
      case 'softs':
        if (this.searchTextSofts && this.searchTextSofts.length >= 1) {
          this.softs = this.softs.filter(i => i?.name?.toLowerCase().includes(this.searchTextSofts?.toLowerCase()) || i?.type?.toLowerCase().includes(this.searchTextSofts?.toLowerCase()) ||
            i?.validUntil?.toLowerCase().includes(this.searchTextSofts?.toLowerCase()) || (i?.rightHolder?.toLowerCase().includes(this.searchTextSofts?.toLowerCase()) || i?.client?.toLowerCase().includes(this.searchTextSofts?.toLowerCase())) ||
            i?.showId?.toLowerCase().includes(this.searchTextSofts?.toLowerCase())
          );
        }
        break;

    }
  }

  applyFilter() {
    this.page = 1;
    this.clearAll();
    this.filterObjectByType();
    this.filterObjectsByText();
  }

  clearAll() {
    switch (this.tab) {
      case 'trademarks':
        this.trademarks = JSON.parse(JSON.stringify(this.copyTrademarks));
        break;
      case 'patents':
        this.patents = JSON.parse(JSON.stringify(this.copyPatents));
        break;
      case 'softs':
        this.softs = JSON.parse(JSON.stringify(this.copySofts));
        break;

    }
  }

  clearFilter() {
    this.searchTextPatents = this.searchTextPatents = this.searchTextTrademarks = this.querySearchText = '';
    this.typeOfTrademarks.forEach(item => {
      item.checked = false;
    });
    this.typeOfPatents.forEach(item => {
      item.checked = false;
    });
    this.typeOfSofts.forEach(item => {
      item.checked = false;
    });
  }

  parseObjects() {
    if (this.requestContractService.objects?.length) {
      this.requestContractService.objects.forEach(el => {
        if (el.elasticObject === true) {
          el.checked = true;
          this.checkedElasticId.push(el);
          this.searches.push(el);
        } else {
          for (const objects in this.objectsImmutable) {
            if (this.objectsImmutable.hasOwnProperty(objects)) {
              this.objectsImmutable[objects].forEach(object => {
                if (el.id === object.id) {
                  object.checked = true;
                }
              });
            }
          }
        }
      });
    }
  }

  getCountCheckedObjects(massive): number {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });
    return counter;
  }
}
