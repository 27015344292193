export default [
  {
    id: 1,
    value: 'Патент на изобретение',
    checked: false,
    index: 'rupat'
  },
  {
    id: 2,
    value: 'Патент на полезную модель',
    checked: false,
    index: 'ruum'
  },
  {
    id: 3,
    value: 'Патент на промышленный образец',
    checked: false,
    index: 'rude'
  }
];
