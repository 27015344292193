import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-info',
  templateUrl: './item-info.component.html',
  styleUrls: ['./item-info.component.scss']
})
export class ItemInfoComponent implements OnInit {
  isDraft = false;
  type;
  monitoredObject;

  constructor() {
  }

  @Input() infoItem;


  ngOnInit(): void {
    console.log(this.infoItem, 'child info');
    if (!this.infoItem.status) {
      this.isDraft = true;
    }
    this.type = this.infoItem.type;
    if (this.type === 'monitoring') {
      const length = this.infoItem.details.objects.filter(i => i.include === true);
      this.monitoredObject = length.length;
    }
    console.log(this.infoItem);
  }

  getContractAction(action) {
    switch (action) {
      case 'registration':
        return `Регистрация нового договора`;
      case 'change':
        return `Изменения текущего договора`;
      case 'termination':
        return `Расторжение текущего договора`;
      default:
    }
  }

  getContractType(type) {
    switch (type) {
      case 'license':
        return `Лицензионный`;
      case 'concession':
        return `Коммерческая концессия`;
      case 'completeAlienation':
        return `Полное отчуждение `;
      case 'pledge':
        return `Залог`;
      case 'sublicense':
        return `Сублицензионный`;
      case 'subconcession':
        return `Коммерческая субконцессия`;
      case 'partialAlienation':
        return `Частичное отчуждение`;
      case 'repledge':
        return `Последующий залог`;
      case 'unknown':
        return `Неизвестно`;
      default:
    }
  }

  getPatenteeName(name) {
    if (typeof name === 'string') {
      return name;
    } else {
      return name.name;
    }
  }
}
