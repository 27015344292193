<div class="monitoring-modal risk-modal">
  <div class="monitoring-modal__wrap">
    <div class="risk-modal__content">
      <p class="risk-modal__criticality"
         [class.risk-modal__criticality--critical]= "data.risk.level === 4"
         [class.risk-modal__criticality--high]= "data.risk.level === 3"
         [class.risk-modal__criticality--middle]="data.risk.level === 2"
         [class.risk-modal__criticality--recommendation]="data.risk.level === 1"
      >{{getRiskByLevel(data.risk.level)}}</p>
      <h1 class="risk-modal__title">{{data.risk.description}}</h1>
      <div class="risk-modal__desc">
        <p>
          {{data.riskData.threat}}
        </p>
      </div>

      <div class="about-risk" *ngIf="data.risk.opposedObject; else aloneObject">
        <div class="about-risk__source-wrap">
          <h3 class="about-risk__title">Источник риска</h3>
          <div class="about-risk__source">
            <div class="about-risk__source-img" *ngIf="imgUrl">
              <img [src]="imgUrl" alt="" class="" width="71" height="30">
            </div>
            <dl>
              <dt class="about-risk__source-title">{{getObjNameByIndex(data.risk.opposedObject._index) + (data.risk.opposedObject._index === 'domains' ? '' : ' ' + getShowIdById(data.risk.opposedObject._id))}}</dt>
              <dd class="about-risk__source-name">{{data.risk.opposedObject.representation}}</dd>
            </dl>
          </div>
        </div>
        <div class="about-risk__mktu" *ngIf="data.risk.object_crossing?.left?.length || data.risk.object_crossing?.right?.length">
          <h3 class="about-risk__caption">{{data.risk.object_crossing.right_source === 'mktu' ? 'Классы МКТУ' : 'ОКВЭД'}}</h3>
          <div class="risk-modal__table-classes classes__list" >
            <div class="classes__item-wrapper" *ngFor="let classes of data.risk.object_crossing.right">
              <label class="classes__item" [class.classes__item--active]="!!classes.type">{{classes.code}}</label>
            </div>
          </div>
        </div>
        <div class="about-risk__date" *ngIf="data.risk.opposedObject.registrationDate">
          <h3 class="about-risk__caption">Дата регистрации</h3>
          <p>{{data.risk.opposedObject.registrationDate | date:'dd.MM.yyyy'}}</p>
        </div>
        <div class="about-risk__applicant" *ngIf="data.risk.opposedObjectOwner?.representation">
          <h3 class="about-risk__caption">Заявитель</h3>
          <p>{{data.risk.opposedObjectOwner.representation}}</p>
<!--          <p class="about-risk__applicant-name">-->
<!--            <a href="" class="risk-modal__table-site">www.site.com</a>-->
<!--            Иванов Г. Х.-->
<!--          </p>-->
<!--          <p class="about-risk__applicant-spec">Консультирование по вопросам коммерческой деятельности и управления; Деятельность в области права</p>-->
        </div>

        <div class="about-risk__source-wrap about-risk__source-wrap--your">
          <h3 class="about-risk__title">Ваш объект</h3>
          <div class="about-risk__source">
            <div class="about-risk__source-img">
              <img [src]="data.object.imageURL | proxyImage" alt="" class="" width="71" height="30">
            </div>
            <dl>
              <dt class="about-risk__source-title">{{getObjNameByIndex(data.object.index[0].id) + ' ' + data.object.showId}}</dt>
              <dd class="about-risk__source-name">{{data.object.name}}</dd>
            </dl>
          </div>
        </div>
        <div class="about-risk__mktu about-risk__mktu--your" *ngIf="data.risk.object_crossing?.left?.length && data.risk.object_crossing?.right?.length">
          <h3 class="about-risk__caption">{{data.risk.object_crossing.left_source === 'mktu' ? 'Классы МКТУ' : 'ОКВЭД'}}</h3>
          <div class="risk-modal__table-classes classes__list">
            <div class="classes__item-wrapper" *ngFor="let ok of data.risk.object_crossing.left">
              <label class="classes__item" [class.classes__item--active]="!!ok.type">{{ok.code}}</label>
            </div>
          </div>
        </div>
        <div class="about-risk__date about-risk__date--your" *ngIf="data.object.index[0].id === 'rutmap' && data.object.applicationDate">
          <h3 class="about-risk__caption">Дата подачи зявки</h3>
          <p>{{data.object.applicationDate | date:'dd.MM.yyyy'}}</p>
        </div>
        <div class="about-risk__date about-risk__date--your" *ngIf="data.object.index[0].id !== 'rutmap' && data.object.registrationDate">
          <h3 class="about-risk__caption">Дата регистрации</h3>
          <p>{{data.object.registrationDate | date:'dd.MM.yyyy'}}</p>
        </div>
      </div>
      <ng-template #aloneObject>
        <div class="risk-modal__about-wrap">
          <div class="img-container-object" *ngIf="data.object.imageURL">
            <img [src]="data.object.imageURL | proxyImage" alt="" class="risk-modal__about-img">
          </div>
          <dl class="risk-modal__about">
            <div>
              <dt>{{getObjNameByIndex(data.object.index[0].id) + ' ' + data.object.showId}}</dt>
              <dd class="risk-modal__about-name">{{data.object.name}}</dd>
            </div>
            <div *ngIf="data.object.registrationDate">
              <dt>Дата регистрации</dt>
              <dd>{{data.object.registrationDate | date:'dd.MM.yyyy'}}</dd>
            </div>
            <div *ngIf="data.object.rightHolder">
              <dt>Правообладатель</dt>
              <dd>{{data.object.rightHolder}}</dd>
            </div>
            <div *ngIf="data.object.validUntil">
              <dt>Действует до</dt>
              <dd class="risk-modal__about-expire">{{data.object.validUntil | date:'dd.MM.yyyy'}}</dd>
            </div>
          </dl>
        </div>
      </ng-template>
    </div>
    <div class="risk-modal__footer">
      <p class="risk-modal__recommendation">Рекомендации</p>
      <p class="risk-modal__recommendation-desc">{{data.riskData.recommendation}}</p>
      <div class="risk-modal__btn-wrap">
        <div class="risk-modal__btn-wrapper">
          <button (click)="toLeadForm(data)" class="risk-modal__btn risk-modal__btn--decide">{{data.riskData.button}}</button>
          <button class="risk-modal__btn risk-modal__btn--ask" (click)="onClickBtn('спросить поверенного')">Спросить поверенного</button>
        </div>
        <div class="risk-modal__take-risk-wrap">
          <button class="risk-modal__take-risk" (click)="hideRisk()">Принять риск</button>
          <p>Проблема известна с {{data.risk.date | date:'dd.MM.yyyy'}}</p>
        </div>
      </div>
    </div>
    <button (click)="closeModal()" class="risk-modal__close"></button>
  </div>
  <div (click)="closeModal()" class="monitoring-modal__overlay"></div>
</div>
