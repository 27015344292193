<div class="modal-wrapper"
     [ngStyle]="{'display': showModal? 'block':'none'}"
>
  <div class="modal modal--order">
    <div class="modal__container">
      <div (click)="showModal = false" class="modal__top"><span
        class="modal__close js-show-modal__close">
                  <svg width="26" height="26" viewBox="0 0 26 26">
                    <rect x="1.41431" width="34" height="2" transform="rotate(45 1.41431 0)"/>
                    <rect y="24.0416" width="34" height="2" transform="rotate(-45 0 24.0416)"/>
                  </svg>
                </span>
        <h2 class="modal__title">Подать заявку</h2>
      </div>
      <div class="service-cards" data-intercom-target="object type">
        <div class="service-cards__element js-show-modal__close">
          <a class="service-card"
             [class.disabled-card]="isDisabled('trademark')"
             (click)="toRequestPage('trademark')">
            <div class="service-card__icon">
              <svg width="33" height="18" viewBox="0 0 33 18" fill="none">
                <path
                  d="M13.1797 2.16797H7.33203V18H5.89062V2.16797H0.0546875V0.9375H13.1797V2.16797ZM17.8555 0.9375L24.1484 15.9961L30.4648 0.9375H32.3867V18H30.9453V10.5703L31.0625 2.95312L24.7109 18H23.5977L17.2695 3.01172L17.3867 10.5234V18H15.9453V0.9375H17.8555Z"/>
              </svg>
            </div>
            <h3 class="service-card__title">Товарный знак</h3>
            <p>Словесный, изобразительный или комбинированный</p>
          </a>
        </div>
        <div class="service-cards__element">
          <a class="service-card"
             (click)="toRequestPage('patent')"
             [class.disabled-card]="isDisabled('patent')">
            <div class="service-card__icon">
              <svg width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                  <path
                    d="M23.625 42h-5.25c-1.225 0-2.625-1.05-2.625-3.15v-3.675c0-1.75-.875-3.325-2.275-4.55-3.15-2.45-4.725-5.95-4.725-9.8.175-6.65 5.6-11.9 12.075-12.075 3.325 0 6.475 1.225 8.75 3.5 2.275 2.275 3.675 5.425 3.675 8.75 0 3.675-1.575 7.175-4.55 9.45-1.575 1.225-2.45 3.15-2.45 4.9v4.025c0 1.4-1.225 2.625-2.625 2.625zM21 10.5c-5.6 0-10.325 4.725-10.5 10.325 0 3.325 1.4 6.475 4.025 8.4C16.45 30.8 17.5 32.9 17.5 35.175v3.675c0 .35 0 1.4.875 1.4h5.25c.525 0 .875-.35.875-.875V35.35c0-2.275 1.225-4.725 3.15-6.3 2.45-1.925 3.85-4.9 3.85-8.05 0-2.8-1.05-5.425-3.15-7.525C26.425 11.55 23.8 10.5 21 10.5z"/>
                  <path
                    d="M25.375 36.75h-8.75c-.525 0-.875-.35-.875-.875s.35-.875.875-.875h8.75c.525 0 .875.35.875.875s-.35.875-.875.875zM21 5.25c-.525 0-.875-.35-.875-.875v-3.5c0-.525.35-.875.875-.875s.875.35.875.875v3.5c0 .525-.35.875-.875.875zM32.725 10.15c-.175 0-.525 0-.7-.175a.846.846 0 010-1.225l2.45-2.45a.846.846 0 011.225 0c.35.35.35.875 0 1.225l-2.45 2.45s-.35.175-.525.175zM41.125 21.875h-3.5c-.525 0-.875-.35-.875-.875s.35-.875.875-.875h3.5c.525 0 .875.35.875.875s-.35.875-.875.875zM35.175 36.05c-.175 0-.525 0-.7-.175l-2.45-2.45a.846.846 0 010-1.225.846.846 0 011.225 0l2.45 2.45c.35.35.35.875 0 1.225 0 .175-.175.175-.525.175zM6.825 36.05c-.175 0-.525 0-.7-.175a.846.846 0 010-1.225l2.45-2.45a.846.846 0 011.225 0c.35.35.35.875 0 1.225l-2.45 2.45c-.175.175-.35.175-.525.175zM4.375 21.875h-3.5C.35 21.875 0 21.525 0 21s.35-.875.875-.875h3.5c.525 0 .875.35.875.875s-.35.875-.875.875zM9.275 10.15c-.175 0-.525 0-.7-.175L6.125 7.35a.846.846 0 010-1.225.846.846 0 011.225 0l2.45 2.45c.35.35.35.875 0 1.225-.175.175-.35.35-.525.35zM28 21.875c-.525 0-.875-.35-.875-.875 0-3.325-2.8-6.125-6.125-6.125-.525 0-.875-.35-.875-.875s.35-.875.875-.875c4.375 0 7.875 3.5 7.875 7.875 0 .525-.35.875-.875.875z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path fill="#fff" d="M0 0h42v42H0z"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h3 class="service-card__title">Патент</h3>
            <p>Изобретение, полезная модель или промышленный образец</p>
          </a>
        </div>
        <div class="service-cards__element">
          <a
            class="service-card"
            (click)="toRequestPage('soft')"
            [class.disabled-card]="isDisabled('soft')">
            <div class="service-card__icon">
              <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M38.9238 5.86523H36.7696C36.4298 5.86523 36.1544 6.14061 36.1544 6.48047C36.1544 6.82032 36.4298 7.0957 36.7696 7.0957H38.9238C39.9415 7.0957 40.7695 7.92373 40.7695 8.94141V11.7715H1.23047V8.94141C1.23047 7.92373 2.05841 7.0957 3.07617 7.0957H34.0683C34.4082 7.0957 34.6836 6.82032 34.6836 6.48047C34.6836 6.14061 34.4082 5.86523 34.0683 5.86523H3.07617C1.37993 5.86523 0 7.24516 0 8.94141V15.7238C0 16.0637 0.275461 16.3391 0.615234 16.3391C0.955008 16.3391 1.23047 16.0637 1.23047 15.7238V13.002H40.7695V28.864C40.7695 29.2039 41.0449 29.4792 41.3848 29.4792C41.7246 29.4792 42 29.2039 42 28.864V8.94141C42 7.24516 40.6201 5.86523 38.9238 5.86523Z"
                  fill="#00AFEC"/>
                <path
                  d="M41.3848 30.8433C41.0449 30.8433 40.7695 31.1187 40.7695 31.4585V33.0586C40.7695 34.0763 39.9415 34.9043 38.9238 34.9043H3.07617C2.05841 34.9043 1.23047 34.0763 1.23047 33.0586V18.3641C1.23047 18.0243 0.955008 17.7489 0.615234 17.7489C0.275461 17.7489 0 18.0243 0 18.3641V33.0586C0 34.7549 1.37993 36.1348 3.07617 36.1348H38.9238C40.6201 36.1348 42 34.7549 42 33.0586V31.4585C42 31.1188 41.7246 30.8433 41.3848 30.8433Z"
                  fill="#00AFEC"/>
                <path
                  d="M8.89913 19.2328C8.65886 18.9926 8.2693 18.9926 8.02911 19.2328L4.33196 22.9299C4.21654 23.0453 4.15173 23.2017 4.15173 23.365C4.15173 23.5282 4.21654 23.6846 4.33196 23.8001L7.98022 27.4483C8.10031 27.5684 8.25781 27.6285 8.41523 27.6285C8.57265 27.6285 8.73015 27.5684 8.85024 27.4483C9.09051 27.208 9.09051 26.8185 8.85024 26.5782L5.63699 23.3651L8.89913 20.1029C9.13949 19.8626 9.13949 19.4731 8.89913 19.2328Z"
                  fill="#00AFEC"/>
                <path
                  d="M17.1728 27.4483C17.2929 27.5684 17.4504 27.6285 17.6078 27.6285C17.7652 27.6285 17.9227 27.5684 18.0428 27.4483L21.6912 23.8001C21.8065 23.6847 21.8713 23.5282 21.8713 23.365C21.8713 23.2017 21.8065 23.0454 21.6912 22.9299L17.9939 19.2328C17.7537 18.9926 17.3641 18.9926 17.1239 19.2328C16.8837 19.4731 16.8837 19.8626 17.1239 20.1029L20.3861 23.365L17.1729 26.5781C16.9325 26.8186 16.9325 27.2081 17.1728 27.4483Z"
                  fill="#00AFEC"/>
                <path
                  d="M10.8274 29.7147C11.0756 29.7147 11.3094 29.5635 11.4027 29.3176L15.7745 17.8006C15.8951 17.4829 15.7353 17.1276 15.4176 17.007C15.1 16.8864 14.7447 17.0463 14.6241 17.3639L10.2524 28.8811C10.1318 29.1987 10.2916 29.554 10.6092 29.6746C10.6811 29.7019 10.7548 29.7147 10.8274 29.7147Z"
                  fill="#00AFEC"/>
                <path
                  d="M2.7074 9.46387C2.7074 10.4815 3.53534 11.3096 4.5531 11.3096C5.57086 11.3096 6.3988 10.4815 6.3988 9.46387C6.3988 8.44619 5.57086 7.61816 4.5531 7.61816C3.53534 7.61816 2.7074 8.44619 2.7074 9.46387ZM5.16833 9.46387C5.16833 9.80315 4.89238 10.0791 4.5531 10.0791C4.21382 10.0791 3.93786 9.80315 3.93786 9.46387C3.93786 9.12459 4.21382 8.84863 4.5531 8.84863C4.89238 8.84863 5.16833 9.12459 5.16833 9.46387Z"
                  fill="#00AFEC"/>
                <path
                  d="M7.47852 9.46387C7.47852 10.4815 8.30646 11.3096 9.32422 11.3096C10.342 11.3096 11.1699 10.4815 11.1699 9.46387C11.1699 8.44619 10.342 7.61816 9.32422 7.61816C8.30646 7.61816 7.47852 8.44619 7.47852 9.46387ZM9.93945 9.46387C9.93945 9.80315 9.6635 10.0791 9.32422 10.0791C8.98494 10.0791 8.70898 9.80315 8.70898 9.46387C8.70898 9.12459 8.98494 8.84863 9.32422 8.84863C9.6635 8.84863 9.93945 9.12459 9.93945 9.46387Z"
                  fill="#00AFEC"/>
                <path
                  d="M12.2494 9.46387C12.2494 10.4815 13.0773 11.3096 14.0951 11.3096C15.1129 11.3096 15.9408 10.4815 15.9408 9.46387C15.9408 8.44619 15.1129 7.61816 14.0951 7.61816C13.0773 7.61816 12.2494 8.44619 12.2494 9.46387ZM14.7103 9.46387C14.7103 9.80315 14.4344 10.0791 14.0951 10.0791C13.7558 10.0791 13.4799 9.80315 13.4799 9.46387C13.4799 9.12459 13.7558 8.84863 14.0951 8.84863C14.4344 8.84863 14.7103 9.12459 14.7103 9.46387Z"
                  fill="#00AFEC"/>
                <path
                  d="M27.5215 19.8818H31.377C32.3946 19.8818 33.2227 19.0538 33.2227 18.0361C33.2227 17.0185 32.3946 16.1904 31.377 16.1904H27.5215C26.5038 16.1904 25.6758 17.0185 25.6758 18.0361C25.6758 19.0538 26.5038 19.8818 27.5215 19.8818ZM27.5215 17.4209H31.377C31.7162 17.4209 31.9922 17.6969 31.9922 18.0361C31.9922 18.3754 31.7162 18.6514 31.377 18.6514H27.5215C27.1822 18.6514 26.9062 18.3754 26.9062 18.0361C26.9062 17.6969 27.1822 17.4209 27.5215 17.4209Z"
                  fill="#00AFEC"/>
                <path
                  d="M27.5215 24.8038H37.6934C38.711 24.8038 39.5391 23.9758 39.5391 22.9581C39.5391 21.9405 38.711 21.1124 37.6934 21.1124H27.5215C26.5038 21.1124 25.6758 21.9405 25.6758 22.9581C25.6758 23.9758 26.5038 24.8038 27.5215 24.8038ZM27.5215 22.3429H37.6934C38.0326 22.3429 38.3086 22.6188 38.3086 22.9581C38.3086 23.2974 38.0326 23.5734 37.6934 23.5734H27.5215C27.1822 23.5734 26.9062 23.2974 26.9062 22.9581C26.9062 22.6188 27.1822 22.3429 27.5215 22.3429Z"
                  fill="#00AFEC"/>
                <path
                  d="M27.5215 29.7257H34.6717C35.6894 29.7257 36.5174 28.8977 36.5174 27.88C36.5174 26.8623 35.6894 26.0343 34.6717 26.0343H27.5215C26.5038 26.0343 25.6758 26.8623 25.6758 27.88C25.6758 28.8977 26.5038 29.7257 27.5215 29.7257ZM27.5215 27.2648H34.6717C35.011 27.2648 35.287 27.5407 35.287 27.88C35.287 28.2193 35.011 28.4952 34.6717 28.4952H27.5215C27.1822 28.4952 26.9062 28.2193 26.9062 27.88C26.9062 27.5407 27.1822 27.2648 27.5215 27.2648Z"
                  fill="#00AFEC"/>
              </svg>

            </div>
            <h3 class="service-card__title">Программа / база данных </h3>
            <p>Программа для ЭВМ или база данных</p>
          </a>
        </div>
        <div class="service-cards__element">
          <a
            class="service-card"
            (click)="toRequestPage('rumcc')"
            [class.disabled-card]="isDisabled('rumcc')">
            <div class="service-card__icon service-card__icon--rus">
              <svg width="42" height="42" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M38.9238 5.86523H36.7696C36.4298 5.86523 36.1544 6.14061 36.1544 6.48047C36.1544 6.82032 36.4298 7.0957 36.7696 7.0957H38.9238C39.9415 7.0957 40.7695 7.92373 40.7695 8.94141V11.7715H1.23047V8.94141C1.23047 7.92373 2.05841 7.0957 3.07617 7.0957H34.0683C34.4082 7.0957 34.6836 6.82032 34.6836 6.48047C34.6836 6.14061 34.4082 5.86523 34.0683 5.86523H3.07617C1.37993 5.86523 0 7.24516 0 8.94141V15.7238C0 16.0637 0.275461 16.3391 0.615234 16.3391C0.955008 16.3391 1.23047 16.0637 1.23047 15.7238V13.002H40.7695V28.864C40.7695 29.2039 41.0449 29.4792 41.3848 29.4792C41.7246 29.4792 42 29.2039 42 28.864V8.94141C42 7.24516 40.6201 5.86523 38.9238 5.86523Z"
                  fill="#00AFEC"/>
                <path
                  d="M41.3848 30.8433C41.0449 30.8433 40.7695 31.1187 40.7695 31.4585V33.0586C40.7695 34.0763 39.9415 34.9043 38.9238 34.9043H3.07617C2.05841 34.9043 1.23047 34.0763 1.23047 33.0586V18.3641C1.23047 18.0243 0.955008 17.7489 0.615234 17.7489C0.275461 17.7489 0 18.0243 0 18.3641V33.0586C0 34.7549 1.37993 36.1348 3.07617 36.1348H38.9238C40.6201 36.1348 42 34.7549 42 33.0586V31.4585C42 31.1188 41.7246 30.8433 41.3848 30.8433Z"
                  fill="#00AFEC"/>
                <path
                  d="M8.89913 19.2328C8.65886 18.9926 8.2693 18.9926 8.02911 19.2328L4.33196 22.9299C4.21654 23.0453 4.15173 23.2017 4.15173 23.365C4.15173 23.5282 4.21654 23.6846 4.33196 23.8001L7.98022 27.4483C8.10031 27.5684 8.25781 27.6285 8.41523 27.6285C8.57265 27.6285 8.73015 27.5684 8.85024 27.4483C9.09051 27.208 9.09051 26.8185 8.85024 26.5782L5.63699 23.3651L8.89913 20.1029C9.13949 19.8626 9.13949 19.4731 8.89913 19.2328Z"
                  fill="#00AFEC"/>
                <path
                  d="M17.1728 27.4483C17.2929 27.5684 17.4504 27.6285 17.6078 27.6285C17.7652 27.6285 17.9227 27.5684 18.0428 27.4483L21.6912 23.8001C21.8065 23.6847 21.8713 23.5282 21.8713 23.365C21.8713 23.2017 21.8065 23.0454 21.6912 22.9299L17.9939 19.2328C17.7537 18.9926 17.3641 18.9926 17.1239 19.2328C16.8837 19.4731 16.8837 19.8626 17.1239 20.1029L20.3861 23.365L17.1729 26.5781C16.9325 26.8186 16.9325 27.2081 17.1728 27.4483Z"
                  fill="#00AFEC"/>
                <path
                  d="M10.8274 29.7147C11.0756 29.7147 11.3094 29.5635 11.4027 29.3176L15.7745 17.8006C15.8951 17.4829 15.7353 17.1276 15.4176 17.007C15.1 16.8864 14.7447 17.0463 14.6241 17.3639L10.2524 28.8811C10.1318 29.1987 10.2916 29.554 10.6092 29.6746C10.6811 29.7019 10.7548 29.7147 10.8274 29.7147Z"
                  fill="#00AFEC"/>
                <path
                  d="M2.7074 9.46387C2.7074 10.4815 3.53534 11.3096 4.5531 11.3096C5.57086 11.3096 6.3988 10.4815 6.3988 9.46387C6.3988 8.44619 5.57086 7.61816 4.5531 7.61816C3.53534 7.61816 2.7074 8.44619 2.7074 9.46387ZM5.16833 9.46387C5.16833 9.80315 4.89238 10.0791 4.5531 10.0791C4.21382 10.0791 3.93786 9.80315 3.93786 9.46387C3.93786 9.12459 4.21382 8.84863 4.5531 8.84863C4.89238 8.84863 5.16833 9.12459 5.16833 9.46387Z"
                  fill="#00AFEC"/>
                <path
                  d="M7.47852 9.46387C7.47852 10.4815 8.30646 11.3096 9.32422 11.3096C10.342 11.3096 11.1699 10.4815 11.1699 9.46387C11.1699 8.44619 10.342 7.61816 9.32422 7.61816C8.30646 7.61816 7.47852 8.44619 7.47852 9.46387ZM9.93945 9.46387C9.93945 9.80315 9.6635 10.0791 9.32422 10.0791C8.98494 10.0791 8.70898 9.80315 8.70898 9.46387C8.70898 9.12459 8.98494 8.84863 9.32422 8.84863C9.6635 8.84863 9.93945 9.12459 9.93945 9.46387Z"
                  fill="#00AFEC"/>
                <path
                  d="M12.2494 9.46387C12.2494 10.4815 13.0773 11.3096 14.0951 11.3096C15.1129 11.3096 15.9408 10.4815 15.9408 9.46387C15.9408 8.44619 15.1129 7.61816 14.0951 7.61816C13.0773 7.61816 12.2494 8.44619 12.2494 9.46387ZM14.7103 9.46387C14.7103 9.80315 14.4344 10.0791 14.0951 10.0791C13.7558 10.0791 13.4799 9.80315 13.4799 9.46387C13.4799 9.12459 13.7558 8.84863 14.0951 8.84863C14.4344 8.84863 14.7103 9.12459 14.7103 9.46387Z"
                  fill="#00AFEC"/>
                <path
                  d="M27.5215 19.8818H31.377C32.3946 19.8818 33.2227 19.0538 33.2227 18.0361C33.2227 17.0185 32.3946 16.1904 31.377 16.1904H27.5215C26.5038 16.1904 25.6758 17.0185 25.6758 18.0361C25.6758 19.0538 26.5038 19.8818 27.5215 19.8818ZM27.5215 17.4209H31.377C31.7162 17.4209 31.9922 17.6969 31.9922 18.0361C31.9922 18.3754 31.7162 18.6514 31.377 18.6514H27.5215C27.1822 18.6514 26.9062 18.3754 26.9062 18.0361C26.9062 17.6969 27.1822 17.4209 27.5215 17.4209Z"
                  fill="#00AFEC"/>
                <path
                  d="M27.5215 24.8038H37.6934C38.711 24.8038 39.5391 23.9758 39.5391 22.9581C39.5391 21.9405 38.711 21.1124 37.6934 21.1124H27.5215C26.5038 21.1124 25.6758 21.9405 25.6758 22.9581C25.6758 23.9758 26.5038 24.8038 27.5215 24.8038ZM27.5215 22.3429H37.6934C38.0326 22.3429 38.3086 22.6188 38.3086 22.9581C38.3086 23.2974 38.0326 23.5734 37.6934 23.5734H27.5215C27.1822 23.5734 26.9062 23.2974 26.9062 22.9581C26.9062 22.6188 27.1822 22.3429 27.5215 22.3429Z"
                  fill="#00AFEC"/>
                <path
                  d="M27.5215 29.7257H34.6717C35.6894 29.7257 36.5174 28.8977 36.5174 27.88C36.5174 26.8623 35.6894 26.0343 34.6717 26.0343H27.5215C26.5038 26.0343 25.6758 26.8623 25.6758 27.88C25.6758 28.8977 26.5038 29.7257 27.5215 29.7257ZM27.5215 27.2648H34.6717C35.011 27.2648 35.287 27.5407 35.287 27.88C35.287 28.2193 35.011 28.4952 34.6717 28.4952H27.5215C27.1822 28.4952 26.9062 28.2193 26.9062 27.88C26.9062 27.5407 27.1822 27.2648 27.5215 27.2648Z"
                  fill="#00AFEC"/>
              </svg>

            </div>
            <h3 class="service-card__title">Внесение ПО</h3>
            <p>В реестр Россииского ПО в Минкомсвязи</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="showModal " class="modal-modal" (click)="closeModal()"></div>
