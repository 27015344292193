import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../app-ipid/src/environments/environment';
import { HttpService } from '../../../../app-ipid/src/app/shared/services/http.service';

declare var window: any;

export interface IYandexResponseToken {
  id: string;
  confirmation: {
    confirmation_token: string
  };
}

const currentHost = `${window.location.protocol}//${window.location.host}`;

@Component({
  selector: 'app-payment-online',
  templateUrl: './payment-online.component.html',
  styleUrls: ['./payment-online.component.scss']
})
export class PaymentOnlineComponent implements OnInit, OnDestroy {

  private readonly onDestroyTimer = new Subject<void>();

  @Input() paymentSum; // сумма к оплате
  @Input() returnPage;
  @Input() returnParams;
  @Input() returnAfterPayment: boolean;
  @Input() yaLastPaymentId;
  @Input() idApplication;
  @Input() isDebit;
  @Input() payer;
  @Output() errorEvent = new EventEmitter();
  @Output() paymentSuccessEvent = new EventEmitter();

  returnUrl;
  payError = {
    status: false,
    text: '',
  };
  production: boolean;
  yaPaymentStatus: string;
  payWidjetIsRender = false;
  payResponse: any;
  timerSubscription: Subscription;

  constructor(
    public httpService: HttpService,
  ) {
    this.production = environment.production;
  }

  ngOnInit(): void {
    setTimeout(() => {
      console.log(1230000, this.returnAfterPayment);
      console.log(1230000, this.yaLastPaymentId);
      this.getReturnUrl();
      this.getUrlParam();
    }, 1000);
  }

  getReturnUrl() {
    this.returnUrl = currentHost + '/' + this.returnPage + `?returnAfterPayment=true` + '&idApplication=' + this.idApplication?.toString();
    if (this.returnParams) {
      for (const key in this.returnParams) {
        if (this.returnParams.hasOwnProperty(key)) {
          if (this.returnParams[key]) {
            this.returnUrl += `&${key}=${this.returnParams[key]}`;
          }
        }
      }
    }
  }

  getUrlParam() {
    if (this.returnAfterPayment) {
      const source = interval(1000 * 30);
      this.testPayStatus();
      this.timerSubscription = source.pipe(takeUntil(this.onDestroyTimer)).subscribe(val => {
        this.testPayStatus();
      });
    } else {
      this.preInitYandexPay();
    }
  }

  preInitYandexPay() {
    this.payError.status = false;
    this.payError.text = '';
    this.initYandexCheckout();
  }

  initYandexCheckout() {
    this.httpService.get(
      {
        path: `yandex_payment/create?responseID=${this.idApplication}`,
      }
    ).subscribe((data: IYandexResponseToken) => {
      if (data?.confirmation?.confirmation_token && data?.id) {
        this.yaLastPaymentId = data.id;
        this.returnUrl = this.returnUrl + '&t=' + this.yaLastPaymentId.toString();

        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: data.confirmation.confirmation_token,
          return_url: this.returnUrl,
          error_callback(error) {
            console.error('ya pay err', error);
            // Обработка ошибок инициализации
          }
        });

        // Отображение платежной формы в контейнере
        checkout.render('payment-form');
        this.checkPayRender();

      } else {
        console.error('В ответе нет confirmation_token и/или payment_id');
      }
    });
  }

  testPayStatus() {
    this.yaPaymentStatus = null;

    if (this.yaLastPaymentId) {
      // this.http.get(`http://10.1.33.225:9999/api/yandex_payment/check?payment_id=${this.yaLastPaymentId}`).subscribe((data: any) => {
      this.httpService.get(
        {
          path: `yandex_payment/check?payment_id=${this.yaLastPaymentId}&responseID=${this.idApplication}`,
          subjectType: 'yandex_payment/check'
        }
      ).subscribe((data: any) => {
        console.log('getstatus ya pay', data);
        this.payResponse = data;
        if (this.payResponse?.status === 'succeeded') {
          this.onDestroyTimer.next();
          this.paymentSuccessEvent.emit(data);
        }
        if (this.payResponse?.status === 'canceled') {
          this.payError.status = true;
          switch (this.payResponse?.cancellation_details?.reason) {
            case '3d_secure_failed':
              this.payError.text = `Не пройдена аутентификация по 3-D Secure. <br class="hide-up-to-md">
            Вам следует повторить платеж, обратиться в банк за уточнениями или использовать другое платежное средство.`;
              break;
            case 'card_expired':
              this.payError.text = `Истек срок действия банковской карты. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'fraud_suspected':
              this.payError.text = `Платеж заблокирован из-за подозрения в мошенничестве. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'insufficient_funds':
              this.payError.text = `Не хватает денег для оплаты. <br class="hide-up-to-md">
            Вам следует пополнить баланс или использовать другое платежное средство.`;
              break;
            case 'invalid_card_number':
              this.payError.text = `Неправильно указан номер карты. <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'invalid_csc':
              this.payError.text = `Неправильно указан код CVV2 (CVC2, CID). <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'payment_method_restricted':
              this.payError.text = `Операции данным платежным средством заблокированы. <br class="hide-up-to-md">
            Вам следует обратиться в организацию, выпустившую платежное средство.`;
              break;
            default:
              this.payError.text = `Произошла ошибка при оплате. <br class="hide-up-to-md">
            Проверьте правильность введенных данных или срок действия карты.`;
          }
          this.errorEvent.emit(this.payError);
          this.onDestroyTimer.next();
        }

        this.yaPaymentStatus = data;
      });
    }
  }

  checkPayRender() {
    setTimeout(() => {
      const el = document.getElementsByTagName('iframe');
      console.log(el[0]);
      if (el[0]) {
        this.payWidjetIsRender = true;
      } else {
        this.payWidjetIsRender = false;
        this.checkPayRender();
      }
    }, 300);
  }

  ngOnDestroy(): void {
  }


}
