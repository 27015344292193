import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CorpSearchByOwnerService } from '../../services/corp-search-by-owner.service';
import { ICorpDataSourceType } from '../../interaces/corp-data-source.interface';
import { CorpSearchSaveReportService } from '../../services/corp-search-save-report.service';
import { ISearchHistoryDetail } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { ActivatedRoute } from '@angular/router';
import {CorpSearchApiService} from '../../services/corp-search-api.service';

@Component({
  selector: 'app-corp-search-by-owner',
  templateUrl: './corp-search-by-owner.component.html',
  styleUrls: ['./corp-search-by-owner.component.scss']
})
export class CorpSearchByOwnerComponent implements OnInit, OnDestroy {

  @Input() initialSearchHistory: ISearchHistoryDetail = null;
  @Input() initialSearchHistoryAction: 'show' | 'repeat' = null;

  owners = true;

  currentTab: 'tradeMarks' | 'domains' = 'tradeMarks';

  changeTab(tab) {
    this.currentTab = tab;
  }

  constructor(
    readonly corpSearchByOwnerService: CorpSearchByOwnerService,
    readonly corpSearchSaveService: CorpSearchSaveReportService,
    readonly activatedRoute: ActivatedRoute,
    readonly corpSearchApiService: CorpSearchApiService
  ) {
  }

  ngOnInit(): void {
    if (this.initialSearchHistory) {
      this.corpSearchByOwnerService.loadBySearchHistory(this.initialSearchHistory, this.initialSearchHistoryAction === 'show');
    } else {
      this.parseSearchParams();
    }
  }

  private parseSearchParams() {
    const query = this.activatedRoute.snapshot.queryParamMap;

    if (!query.has('q')) {
      return;
    } else {
      const owner = query.get('q');
      this.corpSearchByOwnerService.load({owner: owner || ''});
    }

  }

  ngOnDestroy(): void {
    this.corpSearchByOwnerService.reset();
  }

  onSearch(owner: string) {
    this.corpSearchByOwnerService.load({owner});
  }

  onSelect(type: ICorpDataSourceType, selected: any[]) {
    this.corpSearchSaveService.onSelect(type, selected);
  }

}
