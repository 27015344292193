import { Component, Input, OnInit } from '@angular/core';
import mktu from '../../../../../../app-search/src/app/pages/ip-id-search/json-data/mktu';

@Component({
  selector: 'app-mktu-list-view',
  templateUrl: './mktu-list-view.component.html',
  styleUrls: ['./mktu-list-view.component.scss']
})
export class MktuListViewComponent implements OnInit {
  @Input() codes: Array<{ classNumber: number | string, description: string }> = [];
  @Input() limit = 2;
  @Input() countriesDropdown?: boolean;
  @Input() matching: string[] = [];

  showAll = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  get showedItems() {
    if (!this.hasMoreThanLimit || this.showAll) {
      return this.codes;
    } else {
      return this.codes.slice(0, this.limit);
    }
  }

  onShowAll() {
    this.showAll = true;
  }

  get hasMoreThanLimit() {
    return this.codes?.length > this.limit;
  }

  getDescription(code: string) {
    const item = mktu.find(i => i.number.toString() === code.toString());
    return item?.desc;
  }

  isMatching(code: string) {
    // toString потому что иногда нам классы прилетают как числа, иногда строки)
    return this.matching?.includes(code.toString());
  }


}
