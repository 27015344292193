import { AfterContentInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '@web/core/states/app.state';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appOverflow]'
})
export class OverflowDirective implements AfterContentInit, OnDestroy {
  private hiddenCount = 0;

  private insertedEl;
  private emptyEl;

  private windowResize$ = this.store.select(AppState.getWindowWidth);
  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private elementRef: ElementRef,
    private store: Store
  ) { }

  ngAfterContentInit(): void {
    this.windowResize$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.insertedEl) {
        this.insertedEl.remove();
        this.emptyEl.remove();
      }
      // -1 и 1 т.к. последний видимый элемент мы тоже скрываем
      this.hiddenCount = 1;
      let lastShownIndex = -1;

      for (const child of Array.from(this.elementRef.nativeElement.children)) {
        if ((child as HTMLElement).offsetTop) {
          this.hiddenCount++;
        } else {
          lastShownIndex++;
        }
      }

      const cloneNode = this.elementRef.nativeElement.children[lastShownIndex]?.cloneNode();
      if (cloneNode) {
        this.emptyEl = document.createElement('div');
        this.emptyEl.style.width = '100%';
        cloneNode.innerText = `+${this.hiddenCount}`;
        this.elementRef.nativeElement.insertBefore(this.emptyEl, this.elementRef.nativeElement.children[lastShownIndex]);
        this.insertedEl = this.elementRef.nativeElement.insertBefore(cloneNode, this.elementRef.nativeElement.children[lastShownIndex]);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
