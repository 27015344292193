<div class="page-wrapper">
  <main>
    <section class="form-section">
      <div class="container">
        <a (click)="backClicked()" class="back-link">
            <span>
              <svg width="12" height="8" viewBox="0 0 21 16">
                <g clip-path="url(#clip0)">
                 <path d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                  <rect width="21" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          Назад
        </a>
        <div class="container-md">
          <h1 class="heading-h1 heading-h1--centered">Пригласить пользователя</h1>
          <form [formGroup]="form">
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="name"
                       class="form__input field"
                       type="text"
                       required>
                <span class="form__input-label">Имя</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="surname"
                       class="form__input field"
                       type="text"
                       required>
                <span class="form__input-label">Фамилия</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="position"
                       class="form__input form__input--linked field"
                       type="text"
                       required>
                <span class="form__input-label">Должность</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap" [ngClass]="showEmailError() ? 'invalid' : ''">
                <input formControlName="email"
                       class="form__input form__input--linked field"
                       type="text"
                       required>
                <span class="form__input-label">Email</span>
              </div>
              <p class="error-invalid-message" *ngIf="showEmailError()">Введите корректный email</p>
            </div>
            <div class="form__group">
              <div class="form__checkbox-wrap" [ngClass]="showSelectOrgError() ? 'invalid' : ''">
                <span class="form__checkbox-label">Пригласить в организации</span>
                <ng-container
                  *ngFor="let organization of organizations; let i = index"
                  formArrayName="organizations"
                >
                  <input type="checkbox"
                         class="visual-hidden dropdown__input"
                         [formControlName]="i"
                         [value]="organization?.id"
                         id="{{ organization?.id }}"/>
                  <label class="dropdown__label" for="{{ organization?.id }}">
                    <span>{{ organization?.name }}</span>
                  </label>
                </ng-container>
              </div>
              <p class="error-invalid-message" *ngIf="showSelectOrgError()">Выберите организации для приглашения</p>
            </div>
            <div class="btn-wrap">
              <button *ngIf="!loading" class="btn btn__auth" type="button" (click)="send()">Отправить приглашение</button>
              <button *ngIf="loading" class="btn btn__auth" type="button" style="width: 292px"><span class="loaderAuth"></span></button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</div>
