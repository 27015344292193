<button class="btn-settings" (click)="toEmitClick()">
  <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 3H22" stroke="#00AFEC" stroke-width="2" stroke-linecap="round"/>
    <path d="M2 10H22" stroke="#00AFEC" stroke-width="2" stroke-linecap="round"/>
    <path d="M2 17L22 17" stroke="#00AFEC" stroke-width="2" stroke-linecap="round"/>
    <circle cx="3" cy="3" r="2" fill="white" stroke="#00AFEC" stroke-width="2"/>
    <circle cx="12" cy="10" r="2" fill="white" stroke="#00AFEC" stroke-width="2"/>
    <circle cx="21" cy="17" r="2" fill="white" stroke="#00AFEC" stroke-width="2"/>
  </svg>
</button>
