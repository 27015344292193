import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CourtRoutingModule } from './court-routing.module';
import { TreatyExtensionRoutingModule } from '../treaty-extension/treaty-extension-routing.module';
import { ServicesRoutingModule } from '../services/services-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { DisputeAndCourtComponent } from './index/dispute-and-court.component';
import { ClaimsIndexTableComponent } from './index/claims-index-table/claims-index-table.component';
import { CourtsIndexTableComponent } from './index/courts-index-table/courts-index-table.component';
import { DisputsIndexTableComponent } from './index/disputs-index-table/disputs-index-table.component';
import { ClaimPageComponent } from './pages/claim-page/claim-page.component';
import { CourtPageComponent } from './pages/court-page/court-page.component';
import { DisputePageComponent } from './pages/dispute-page/dispute-page.component';
import { AccordionTabsComponent } from './tabs/accordion-tabs/accordion-tabs.component';
import { DocsTabComponent } from './tabs/docs-tab/docs-tab.component';
import { InfoTabComponent } from './tabs/info-tab/info-tab.component';
import { AddDocComponent } from './add-doc/add-doc.component';
import {ExpertModule} from '../layout/expert/expert.mdoule';


@NgModule({
  declarations: [
    DisputeAndCourtComponent,
    ClaimsIndexTableComponent,
    CourtsIndexTableComponent,
    DisputsIndexTableComponent,
    ClaimPageComponent,
    CourtPageComponent,
    DisputePageComponent,
    AccordionTabsComponent,
    DocsTabComponent,
    InfoTabComponent,
    AddDocComponent,

  ],
  imports: [
    CommonModule,
    CourtRoutingModule,
    CommonModule,
    TreatyExtensionRoutingModule,
    ServicesRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    ExpertModule
  ]
})
export class CourtModule {
}
