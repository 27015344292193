import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-tariff',
  templateUrl: './contract-tariff.component.html',
  styleUrls: ['./contract-tariff.component.scss']
})
export class ContractTariffComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
