<div class="title-dropdown">{{title}}

  <button *ngIf="options?.length === 1" class="dropdown-wrapper">
    <span class="title-dropdown-white"> по {{ options[0]?.label }}</span>
  </button>

  <button *ngIf="options?.length > 1" class="dropdown-wrapper dropdown-wrapper--several"
          [class.dropdown-wrapper--several-2]="isShowDropdown">

    <span tabindex="0" class="title-dropdown-blue" [class.title-dropdown-blue--open]="isShowDropdown">
      <a class="options-list__link" (click)="showDropdown()">по {{selectedOption.label}}</a>
    </span>

    <div *ngIf="isShowDropdown" (click)="hideDropdown()" class="overlay overlay--transparent"></div>

    <div class="options-list" [class.hide]="!isShowDropdown">
      <ul class="options-list__list">
        <li class="options-list__item"
            [class.options-list__item--selected]="option.id === selectedOption.id"
            [class.disabled]="option.accept === false"
            *ngFor="let option of options" (click)="onSelect(option.id)">
          <p> по {{option.label}}</p>
        </li>
      </ul>
    </div>
  </button>
</div>
