import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { RegistrationComponent } from './registration/registration.component';
import { GoogleAuthComponent } from './google-auth/google-auth.component';
import { ExternalAuthComponent } from './external-auth/external-auth.component';
import { OauthService } from './oauth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppPasswordDirective } from '../../directives/app-password.directive';
import { LoginService } from '../../services/login.service';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@web/core/states/auth.state';
import {LayoutModule} from '../../../app-ipid/src/app/pages/layout/layout.module';
import {SupportModalModule} from '../../../app-ipid/src/app/pages/layout/support-modal/support-modal.module';


@NgModule({
  declarations: [
    LoginComponent,
    RecoveryComponent,
    RegistrationComponent,
    ExternalAuthComponent,
    GoogleAuthComponent,
    AppPasswordDirective
  ],
  providers: [
    OauthService,
    LoginService
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    NgxsModule.forFeature([AuthState]),
    LayoutModule,
    SupportModalModule
  ]
})
export class SharedAuthModule {
}
