import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IRights } from '../models/main.interfaces';
import { HttpService } from './http.service';
import { AbstractControl } from '@angular/forms';

const path = 'rights';
const onlyNumbersPattern = /^-?(0|[1-9]\d*)?$/;


@Injectable({
  providedIn: 'root'
})

export class SharedService {
  public closeModals: Subject<any> = new Subject();
  public draft: Subject<any> = new Subject();
  public openModal: Subject<any> = new Subject();
  public hideCases: Subject<boolean> = new Subject();
  public rights: any = null;
  public currentRights = new BehaviorSubject<any>(this.rights);
  public sharedSubject;


  constructor(
    private _http: HttpService,
  ) {
  }

  getRights(params) {
    this._http.get<IRights>({path: `${path}`, subjectType: 'tasks', params}).subscribe(data => {
      this.rights = data.rights;
      console.log(this.rights, 1234);
      this.sharedSubject = this.currentRights.asObservable();
      this.currentRights.next(this.rights);
    });
  }

  getRightsAsync(params) {
    return this._http.get<IRights>({path: `${path}`, subjectType: 'tasks', params});
  }


}
