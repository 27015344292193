import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-docs-tab',
  templateUrl: './docs-tab.component.html',
  styleUrls: ['./docs-tab.component.scss']
})
export class DocsTabComponent implements OnInit {
  @Input() type;

  constructor() {
  }

  ngOnInit(): void {

  }


}
