<!--Сообщение о составе тарифа-->
<div class="attention attention--in-card" *ngIf="type==='tariff'" [class.attention--white]="backgroundWhite">
  <h3 *ngIf="!backgroundWhite">Важно!</h3>
  <p>Вы оплатили претензионную работу по тарифу <b>«Оптимальный»</b>. Сумма оплаты <b>13 890 ₽</b>. Дата оплаты 01.10.2020</p>
  <div tabindex="0" class="attention__show" (click)="showServicesList=!showServicesList" [class.attention__show--open]="showServicesList">
    <span class="attention__link">{{showServicesList ? 'Скрыть состав услуг' : 'Посмотреть состав услуг'}}</span>
    <ul *ngIf="showServicesList" class="attention__list" >
      <li>
        Формирование доказательной базы для суда
      </li>
      <li>
        Расчет государственной пошлины
      </li>
      <li>
        Подготовка искового заявления
      </li>
      <li>
        Оплата государственной пошлины за истца
      </li>
      <li>
        Подача иска и контроль возбуждения арбитражного дела
      </li>
      <li>
        Анализ доказательств ответчика и письменные пояснения на его отзыв
      </li>
      <li>
        Получение, проверка судебного решения и исполнительного листа на ошибки
      </li>
    </ul>
  </div>
</div>

<!--Просто перчисление пунктов-->
<div class="attention attention--in-card" *ngIf="type==='list'" >
  <h3>Важно!</h3>
  <p>Сумма долга и характер требований предусматривает упрощенный порядок рассмотрения спора арбитражным
    судом. Это означает, что дело будет рассмотрено:</p>
  <ul  class="attention__list" >
    <li>
      без вызова сторон
    </li>
    <li>
      без проведения судебного заседания
    </li>
    <li>
      основываясь на иске, отзыве на иск и письменных доказательствах
    </li>
  </ul>
  <p>Решение вступит в силу незамедлительно, а исполнительный лист будет выдан сразу после изготовления текста решения в полном объеме.</p>
</div>
