<div class="page-wrapper">
  <main>
    <section *ngIf="!userEdited" class="user">
      <div class="user__wrap container">
        <h1 class="visual-hidden">Личный кабинет</h1>
        <ng-container *ngIf="user?.image; else avatar">
          <img src="{{user?.image}}" alt="" class="user__avatar">
        </ng-container>
        <ng-template #avatar>
          <img src="/assets/images/avatar.png" class="user__avatar" width="120" height="120">
        </ng-template>
        <div>
          <p class="user__name">{{user?.name}}</p>
          <ul class="user__info-list">
            <li class="user__info-item" *ngIf="user?.phone">
              <a [href]="'tel:+' + user?.phone">+{{user?.phone}}</a>
            </li>
            <li class="user__info-item">
              <a [href]="'mailto:'+user?.email">{{user?.email}}</a>
            </li>
            <li class="user__info-item user__info-item--links">
              <a class="user__links" [routerLink]="'/user/change-profile'">Редактировать профиль</a>
              <span style="padding: 0 10px 0 10px;">или</span>
              <a class="user__links" [routerLink]="'/user/change-password'">Изменить пароль</a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section *ngIf="userEdited" class="user">
      <div class="user__wrap container">
        <h1 class="visual-hidden">Личный кабинет</h1>
        <div class="user__wrapper">
          <a (click)="backClicked()" class="back-link">
            <span>
              <svg width="12" height="8" viewBox="0 0 21 16">
                <g clip-path="url(#clip0)">
                 <path
                   d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                  <rect width="21" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
            Назад
          </a>
          <div class="user__wrap-info">
            <ng-container *ngIf="userEdited[0].image; else avatar">
              <img src="{{userEdited[0].image}}" alt="" class="user__avatar">
            </ng-container>
            <ng-template #avatar>
              <img src="/assets/images/avatar.png" class="user__avatar" width="120" height="120">
            </ng-template>
            <div>
              <p class="user__name">{{userEdited[0].name}}</p>
              <ul class="user__info-list">
                <li class="user__info-item" *ngIf="userEdited[0].position">
                  <p>{{userEdited[0].position}}</p>
                </li>
                <li class="user__info-item">
                  <a [href]="'mailto:'+userEdited[0].email">{{userEdited[0].email}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button *ngIf="user?.role === 'Администратор' && userEdited[0].role != 'Администратор'" (click)="blockUser()" class="block-user">Заблокировать пользователя</button>

<!--        <button *ngIf="user.role === 'Администратор' && disableBlockBtn" (click)="blockUser()" class="block-user">-->
<!--          Заблокировать пользователя-->
<!--        </button>-->

      </div>
    </section>

    <section class="user-tabs">

      <div *ngIf="!userEdited" class="user-tabs__list-wrap">
        <ul class="user-tabs__list container">
          <li class="user-tabs__item"
              [ngClass]="{
                        'is-active': userTab === 'organization',
                        'mono-account': monoAccount,
                        'waiting-account': waitingAccount,
                        'administrator': administrator}"
          >
            <button (click)="switchUserTab('organization')">
              <span>Организации и ИП</span>
              <span class="user-tabs__count">{{ organizations.length }}</span>
            </button>
          </li>
          <li class="user-tabs__item"
              *ngIf="!administrator && !monoAccount && waitingAccount === false"
              [ngClass]="userTab === 'access'? 'is-active': ''"
          >
            <button (click)="switchUserTab('access')">
              <span>Мои права доступа</span>
            </button>
          </li>
          <li class="user-tabs__item"
              *ngIf="administrator"
              [ngClass]="{
                        'is-active': userTab === 'users',
                        'administrator': administrator}"
          >
            <button (click)="switchUserTab('users')" *ngIf="organizations.length">
              <span>Пользователи и права</span>
              <span class="user-tabs__count">{{organizations[0].representatives.length}}</span>
            </button>
          </li>
          <li class="user-tabs__item"
              *ngIf="administrator || monoAccount || waitingAccount === true"
              [ngClass]="{
                        'is-active': userTab === 'payment',
                        'mono-account': monoAccount,
                        'waiting-account': waitingAccount,
                        'administrator': administrator,
                        'disabled__tab' : disableFinance
                        }"
          >
            <button (click)="switchUserTab('payment')" [disabled]="!paymentHistory?.length">
              <span>История платежей</span>
              <span class="user-tabs__count">{{ paymentHistory?.length }}</span>
            </button>
          </li>
          <li class="user-tabs__item"
              *ngIf="administrator || monoAccount || waitingAccount === true"
              [ngClass]="{
                        'is-active': userTab === 'attorney',
                        'mono-account': monoAccount,
                        'waiting-account': waitingAccount,
                        'administrator': administrator}"
          >
            <button *ngIf="proxies" (click)="switchUserTab('attorney')"  [disabled]="!proxies.length">
              <span>Доверенности</span>
              <span class="user-tabs__count">{{ proxies.length }}</span>
            </button>
          </li>
        </ul>
      </div>

      <div class="user-tabs__content">
        <div *ngIf="userTab === 'organization' && organizations">
          <ng-container *ngIf="isLoadingOrganizations; else loader">
            <app-tab-organizations-ip
              [organizations]="organizations"
              [expiringAttorneys]="expiringAttorneys"
              [administrator]="administrator"
              [waitingAccount]="waitingAccount"
              [monoAccount]="monoAccount"
              (openAttorney)="switchUserTab('attorney')"
              (editRights)="getRightsForEdit($event)"
            ></app-tab-organizations-ip>
          </ng-container>
          <ng-template #loader>
            <app-spinner class="spinner-full"></app-spinner>
          </ng-template>
        </div>

        <div *ngIf="userTab === 'access'">
          <ng-container *ngIf="isLoadingOrganizations; else loader">
            <app-tab-access
              [organizations]="organizations"
              [attorneys]="proxies"
              [rightsForEdit]="rightsDataInput"
            ></app-tab-access>
          </ng-container>
          <ng-template #loader>
            <app-spinner class="spinner-full"></app-spinner>
          </ng-template>
        </div>

        <div *ngIf="userTab === 'users'">
          <ng-container *ngIf="isLoadingOrganizations; else loader">
            <app-tab-users-rights
              (editRights)="getRightsForEdit($event)"
              [organizations]="organizations"
            ></app-tab-users-rights>
          </ng-container>
          <ng-template #loader>
            <app-spinner class="spinner-full"></app-spinner>
          </ng-template>
        </div>

        <div class="" *ngIf="userTab === 'payment' && paymentHistory">
          <ng-container *ngIf="isLoadingOrganizations; else loader">
            <app-tab-payment-history
              [paymentHistory]="paymentHistory"
            ></app-tab-payment-history>
          </ng-container>
          <ng-template #loader>
            <app-spinner class="spinner-full"></app-spinner>
          </ng-template>
        </div>

        <div *ngIf="userTab === 'attorney' && proxies">
          <ng-container *ngIf="isLoadingOrganizations; else loader">
            <app-tab-attorney
              [proxies]="proxies"
            ></app-tab-attorney>
          </ng-container>
          <ng-template #loader>
            <app-spinner class="spinner-full"></app-spinner>
          </ng-template>
        </div>
      </div>
    </section>

    <app-expert></app-expert>

  </main>
</div>
