<div class="mktu-modal">
  <div class="mktu-modal__main">
    <div class="mktu-modal__head">
      <p>Добавить классы МКТУ</p>
      <span (click)="close.emit()" class="mktu-modal__close"></span>
    </div>
    <div class="mktu-modal__block">
      <div *ngFor="let mktu of mktuData" tabindex="0" class="mktu-modal__item">
        <div class="mktu-modal__row">
          <div class="mktu-modal__mktu">
            <input
              type="checkbox"
              id="{{mktu.number}}"
              (click)="checkedClass(mktu.number)"
              [checked]="selected.includes(mktu.number)"
            />
            <label class="mktu-modal__label" for="{{mktu.number}}"></label>
            <p class="mktu-modal__text">{{mktu.name}}</p>
          </div>
          <span class="mktu-modal__show" [class.mktu-modal__show--open]="showMassive.includes(mktu.number)" (click)="showDescriptionClick(mktu.number)"></span>
        </div>
        <div *ngIf="showMassive.includes(mktu.number)" class="mktu-modal__hidden">
          <p>{{mktu.desc}}</p>
        </div>
      </div>
      <button class="btn-blue" type="button" (click)="closeSuccess()">Применить выбор</button>
    </div>
  </div>
  <div (click)="close.emit()" class="overlay"></div>
</div>
