import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpService } from '../../../../shared/services/http.service';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../../../../app-search/src/app/services/search.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {EnvService} from '../../../../shared/services/env.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

declare var window: any;

@Component({
  selector: 'app-tm-mktu',
  templateUrl: './tm-mktu.component.html',
  styleUrls: ['./tm-mktu.component.scss']
})
export class TmMktuComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @Output() backStepEvent = new EventEmitter();
  @Output() nextStepEvent = new EventEmitter();
  mktu = []; // классы мкту (массив объектов)
  MKTUCodesShort = []; // классы мкту (массив чисел)
  optionsMktu = {
    onlyClassSelect: true,
    search: 'mktu',
    selectedMktu: []
  };
  @Input() firstJSON;
  @Input() draftData;
  @Input() OKVEDCodes = []; // оквэд коды (массив объектов): [{code: 12, description: "Описание"}, ...]
  @Input() OKVEDCodesShort = []; // оквэд кодв (массив чисел): [12, 34, 56, ...]
  @Input() ownerShortName = ''; // короткое наименование организации
  @Input() mktuSearch;
  @Input() owner;
  @Input() requestType;
  rates = {min: null, opty: null, max: null}; // тарифы
  step2ButtonClicked = false; // нажата ли кнопка перехода с 2 на 3 шаг
  loadindErrorStep2Send = false; // ошибка при переходе на 3 шаг
  @Input() idApplication;
  countRates; // количество тарифов
  activeTariff: string; // id выбранного тарифа
  duty: any; // пошлины
  sumBeforeDiscount = 0; // общая сумма (тариф + пошлины) до скидки
  paymentSum; // сумма к оплате с учетом скидки
  lastTrademark;
  showSearchInput = false;
  constructor(
    private httpService: HttpService,
    private searchService: SearchService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  ngOnInit(): void {
    window.scrollBy(0, 0);
    setTimeout(() => {
      if (!this.draftData) {
        if (!this.mktuSearch && (this.firstJSON.owner.inn?.length || this.firstJSON.owner.ogrn?.length)) {
          this.getMKTUByLastRequest();
        } else if (!this.mktuSearch && (!this.firstJSON.owner.inn?.length && !this.firstJSON.owner.ogrn?.length)){
          // this.MKTUCodesShort = this.mktuSearch;
          // this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
          this.showSearchInput = true;
        } else if (this.mktuSearch) {
          this.MKTUCodesShort = this.mktuSearch;
          this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
          this.showSearchInput = true;
        }
        this.getFirstJSON();
      } else {
        this.MKTUCodesShort = this.draftData;
        this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
        this.showSearchInput = true;
      }

      this.changeDetector.detectChanges();

    }, 150);
  }

  /**
   * отправка данных с 1 шага
   */
  getFirstJSON(): void {
    this.firstJSON.responseID = this.idApplication;
    this.httpService.post({path: 'request/load', body: this.firstJSON}).subscribe((data: any) => {
      if (data?.responseID) {
        this.idApplication = data.responseID;

        // добавление id заявки в get параметры

        const refresh = window.location.protocol + '//' + window.location.host + window.location.pathname + `?type=${this.requestType}&id=${this.idApplication}`;
        window.history.pushState({ path: refresh }, '', refresh);
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_TM_APPLY_SENT, itemType: this.requestType });
      }
    });
  }
  /**
   * событие добавления класса МКТУ
   * @param selectedClass: объект добавляемого класса
   */
  eventSelectedClass(selectedClass): void {
    // TODO: Сохранять (перемапить) для дальнейшего использования в заявке
    if (this.mktu.length) {
      this.mktu.forEach((item, index) => {
        if (!item.selected) {
          this.mktu.splice(index, 1);
        }
      });
      let hasClass;
      this.mktu.forEach(item => {
        if (item.number === selectedClass.number) {
          hasClass = true;
        }
      });
      if (!hasClass) {
        this.mktu.push(selectedClass);
      }
    } else {
      this.mktu.push(selectedClass);
    }
    this.MKTUCodesShort = [];
    this.mktu.forEach(item => {
      this.MKTUCodesShort.push(item.number);
    });
  }

  /**
   * событие удаления класса МКТУ
   * @param deletedClass: объект удаляемого класса
   */
  eventDeletedClass(deletedClass): void {
    // TODO: логика удаления
    let deleteIndex;
    this.mktu.forEach((item, index) => {
      if (item.number === deletedClass.number) {
        deleteIndex = index;
      }
    });
    if (deleteIndex || deleteIndex === 0) {
      this.mktu.splice(deleteIndex, 1);
    }
    this.MKTUCodesShort = [];
    this.mktu.forEach(item => {
      this.MKTUCodesShort.push(item.number);
    });
  }

  eventApplyClasses(event) {
    this.mktu = event;
    this.MKTUCodesShort = [];
    this.mktu.forEach(item => {
      this.MKTUCodesShort.push(item.number);
    });
    this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
  }

  /**
   * получение короткого описание КВЭД
   * @param okved: описание ОКВЭД класса
   */
  getShortOkved(okved): string {
    if (okved.length > 40) {
      return okved.slice(0, 40) + '...';
    } else {
      return okved;
    }
  }

  /**
   * переход со 2 на 3 шаг возможен только когда есть номер заявки и тарифы
   * иначе лоадер
   */
  step2to3Click(): void {
    this.rates.min = null;
    this.rates.opty = null;
    this.rates.max = null;
    let secondJSONSended = false;
    this.step2ButtonClicked = true;
    this.loadindErrorStep2Send = true;
    const intervalId = setInterval(() => {
      if (this.idApplication && !secondJSONSended) {
        secondJSONSended = true;
        this.getSecondJSON();
      }
      if (this.rates.min || this.rates.opty || this.rates.max) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        this.step2ButtonClicked = false;
        this.loadindErrorStep2Send = false;
        this.nextStep();
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_SVEDENIYA_SENT, itemType: this.requestType });
      }
    }, 1000);
    const timeoutId = setTimeout(() => {
      this.loadindErrorStep2Send = false;
    }, 60000);
  }

  /**
   * отправка данных со 2 шага
   */
  getSecondJSON(): void {
    const secondJSON = {
      responseID: this.idApplication,
      stage: 2,
      okved: this.OKVEDCodes,
      mktu: this.mktu
    };
    this.httpService.post({path: 'request/load', body: secondJSON}).subscribe((data: any) => {
      if (data) {
        // this.nextStep();
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_TM_CLASSES_SENT, itemType: this.requestType });
        this.getRates();
      }
    });
  }

  /**
   * получение тарифов и пошлин
   */
  getRates(): void {
    this.httpService.get({
      path: 'request/tariff',
      params: {responseID: this.idApplication}
    }).subscribe((data: any) => {
      if (data?.tariff && data.duty) {
        this.countRates = data.tariff.length;
        data.tariff.forEach((item, index) => {
          if (index === 0) {
            this.rates.min = item;
          }
          if (index === 1) {
            this.rates.opty = item;
          }
          if (index === 2) {
            this.rates.max = item;
          }
          if (item.default) {
            this.activeTariff = item.id;
          }
        });
        this.duty = data.duty;
        if (this.countRates === 1) {
          this.activeTariff = this.rates.min.id;
          this.paymentSum = +this.rates.min.sumDiscount + +this.duty.sumDiscount;
          this.sumBeforeDiscount = +this.rates.min.sum + +this.duty.sum;
        } else {
          this.activeTariff = this.rates.opty.id;
          this.paymentSum = +this.rates.opty.sumDiscount + +this.duty.sumDiscount;
          this.sumBeforeDiscount = +this.rates.opty.sum + +this.duty.sum;
        }

        // TODO: вернуть назад поэтапную оплату
        // if (this.envService.isGardiumOnline || this.envService.isAlfa) {
        //   this.paymentSum = +this.rates.opty.sumDiscount;
        //   this.sumBeforeDiscount = +this.rates.opty.sum;
        // } else {
        //   this.paymentSum = +this.rates.opty.sumDiscount + +this.duty.sumDiscount;
        //   this.sumBeforeDiscount = +this.rates.opty.sum + +this.duty.sum;
        // }
      }
    });
  }

  getMKTUByLastRequest() {
    const query = {
      TIN: this.firstJSON.owner.inn,
      PSRN: this.firstJSON.owner.ogrn
    };
    this.searchService.getLastRequestTrademark(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data._source && data._source.goodsServices && data._source.goodsServices.length) {
        this.MKTUCodesShort = [];
        data._source.goodsServices.forEach(item => {
          this.MKTUCodesShort.push(item.classNumber);
        });
        this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
        this.showSearchInput = true;
        data._source.imageUrl = this.searchService.getImgUrl(data._index, parseInt(data._source.applicationString, 10), data._source.markImageFileName, data._source.applicationString);
        this.lastTrademark = data;
        if (this.lastTrademark._id) {
          const queryId = {
            _id: this.lastTrademark._id,
            _index: this.lastTrademark._index
          };
          this.searchService.getDocumentById(queryId).pipe(takeUntil(this.onDestroy)).subscribe((dataMktu: any) => {
            if (dataMktu?.hits?.hits?.length) {
              this.lastTrademark._source.goodsServices = dataMktu.hits.hits[0]._source.goodsServices;
            }
          });
        }
        this.changeDetector.detectChanges();
      } else {
        this.getMktuByOkved();
      }
    });
  }

  /**
   * получение классов МКТУ по ОКВЭД
   */
  getMktuByOkved(): void {
    const query = {
      eaNumber: this.OKVEDCodesShort,
    };
    this.mktu = [];
    this.MKTUCodesShort = [];
    this.searchService.getMktyByOkved(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits && data.hits.hits.length > 0) {
        data.hits.hits.forEach(item => {
          if (item._source && item._source.codeMKTU) {
            this.MKTUCodesShort.push(item._source.codeMKTU);
          }
        });
        this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
        this.showSearchInput = true;

        this.changeDetector.detectChanges();
      }
    });

    this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
  }

  /**
   * склонение слова 'классы'
   * @param n: количество классов
   */
  declOfNum(n: number): string {
    n = Math.abs(n) % 100; const n1 = n % 10;
    if (n > 10 && n < 20) { return 'классов'; }
    if (n1 > 1 && n1 < 5) { return 'класса'; }
    if (n1 === 1) { return 'класс'; }
    return 'классов';
  }

  getDutySum(): any {
    const discount = 30;
    let sumDiscount;
    let sum;
    let term1 = 3500;
    if (this.MKTUCodesShort.length > 5) {
      term1 = term1 + 1000 * (this.MKTUCodesShort.length - 5);
    }
    let term2 = 11500;
    if (this.MKTUCodesShort.length > 1) {
      term2 = term2 + 2500 * (this.MKTUCodesShort.length - 1);
    }
    let term3 = 18000;
    if (this.MKTUCodesShort.length > 5) {
      term3 = term3 + 1000 * (this.MKTUCodesShort.length - 5);
    }
    sum = term1 + term2 + term3;
    sumDiscount = (sum / 10) * 3;
    return {sum, discount, sumDiscount};
  }

  backStep() {
    this.backStepEvent.emit();
  }

  nextStep() {
    this.nextStepEvent.emit({
      idApplication: this.idApplication,
      rates: this.rates,
      countRates: this.countRates,
      activeTariff: this.activeTariff,
      duty: this.duty,
      sumBeforeDiscount: this.sumBeforeDiscount,
      paymentSum: this.paymentSum,
      MKTUCodesShort: this.MKTUCodesShort
    });
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  ngOnDestroy(): void {
  }

}
