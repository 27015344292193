import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenIndexComponent } from './open-index/open-index.component';
import { OpenDashboardComponent } from './open-dashboard/open-dashboard.component';
import { AuthGuard } from '../../shared/guardes/auth-guard.service';
import { IndexModule } from '../index/index.module';
import { LayoutModule } from '../layout/layout.module';
import { OpenCasesComponent } from './open-cases/open-cases.component';
import { OpenCaseItemComponent } from './open-case-item/open-case-item.component';
import { OpenMonitoringWidgetComponent } from './open-dashboard/open-monitoring-widget/open-monitoring-widget.component';
import { SharedModule } from '../../shared/shared.module';
import { OpenLayoutComponent } from './open-layout/open-layout.component';
import { OpenTopMenuComponent } from './open-top-menu/open-top-menu.component';
import { RouterModule } from '@angular/router';
import {ExpertModule} from '../layout/expert/expert.mdoule';

@NgModule({
  declarations: [
    OpenIndexComponent,
    OpenDashboardComponent,
    OpenCasesComponent,
    OpenCaseItemComponent,
    OpenMonitoringWidgetComponent,
    OpenLayoutComponent,
    OpenTopMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IndexModule,
    LayoutModule,
    SharedModule,
    ExpertModule
  ],
  exports: [
    OpenLayoutComponent,
    OpenIndexComponent,
    OpenDashboardComponent
  ],
  providers: [
    AuthGuard,
  ]
})
export class OpenModule {
}
