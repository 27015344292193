<h1 class="form-item__head" id="ratesHeader">Выберите тариф</h1>

<app-tariffes
  [requestType]="type"
  [tariffes]="tariffes"
  [activeTariff]="activeTariffId"
  (selectingTariff)="selectTariff($event)"
></app-tariffes>

<app-rospatent-duties *ngIf="type === 'trademark'"></app-rospatent-duties>

<div class="form-item__btn-block">
  <app-ui-button class="actions__cancel" uiType="link" label="Назад" icon="arrow-left" iconPos="left" (onClick)="backStep()"></app-ui-button>
  <div class="form-item__btn-block--mini sum-block" *ngIf="tariffResult.sumBeforeDiscount && tariffResult.paymentSum">
    <p *ngIf="tariffResult.sumBeforeDiscount !== tariffResult.paymentSum">Итого к оплате:<span>{{tariffResult.sumBeforeDiscount | toLocaleString}}</span> <b>{{tariffResult.paymentSum | toLocaleString}}</b></p>
    <p *ngIf="tariffResult.sumBeforeDiscount === tariffResult.paymentSum">Итого к оплате: <b>{{tariffResult.paymentSum | toLocaleString}}</b></p>
  </div>
  <div class="form-item__btn-block--mini">
    <app-promo-code [promocode]="proocode" (promoEvent)="applyPromoCode($event)"></app-promo-code>
    <app-ui-button class="actions__pay" label="Оплатить" (onClick)="toPaymentStage()" [id]="'payBtn'"></app-ui-button>
  </div>
</div>
