import { ChangeDetectionStrategy, Component, DoCheck, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ISearchHistoryDetail, ISearchHistoryPayload } from '../../../../../../app-ipid/src/app/shared/models/search-history.interfaces';
import { CorpSearchService } from '../../services/corp-search.service';
import { CorpSearchByNameService } from '../../services/corp-search-by-name.service';
import { CorpSearchSaveReportService } from '../../services/corp-search-save-report.service';
import { ActivatedRoute } from '@angular/router';
import { CorpSearchApiService } from '../../services/corp-search-api.service';
import { environment } from '../../../../../../app-ipid/src/environments/environment';
import { ICorpDataSourceType } from '../../interaces/corp-data-source.interface';
import { CorpSearchByImageService } from '../../services/corp-search-by-image.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SearchService } from '../../../../../../app-search/src/app/services/search.service';
import { HttpService } from '../../../../../../app-ipid/src/app/shared/services/http.service';
import { Store } from '@ngxs/store';
import { SearchActions } from '@web/features/corp/corp-search/states/search.actions';
import { CORP_SEARCH_TOKEN, SearchState } from '@web/features/corp/corp-search/states/search.state';
import { CorpSearchRepeatService } from '@web/features/corp/corp-search/services/corp-search-repeat.service';

@Component({
  selector: 'app-corp-search-tm',
  templateUrl: './corp-search-tm.component.html',
  styleUrls: ['./corp-search-tm.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorpSearchTmComponent implements OnInit, OnDestroy, DoCheck {
  @Input() initialSearchHistory: ISearchHistoryDetail = null;
  @Input() initialSearchHistoryAction: 'show' | 'repeat' = null;

  currentTab: 'trademark' | 'legalname' | 'domain' = 'trademark';
  showIndex = true;
  showIndexMobile = true;
  public innerWidth: any;
  countriesPopup = false;
  imageChangedEvent: any = '';
  croppedImageBinary;
  disabledButton = true;
  visibleBorder = true;
  imageBase64;
  imageFile;
  isLoading = false;
  tradeMarks;
  count;
  state$ = this.store.select(CORP_SEARCH_TOKEN);
  currentPage$ = this.store.select(SearchState.currentPage);
  currentPageTrademarks$ = this.store.select(SearchState.currentPageTrademarks);
  currentPageDomains$ = this.store.select(SearchState.currentPageDomains);
  currentPageLegalNames$ = this.store.select(SearchState.currentPageLegalNames);

  get searchRisksOptions() {
    return {showProgressPercent: false, ...this.searchOptions};
  }

  searchOptions = {
    style: 'appIpIdSearchPage',
    dynamicStylesForBrand: environment.SEARCH_STYLES,
  };

  constructor(
    readonly corpSearchService: CorpSearchService,
    readonly corpSearchByNameService: CorpSearchByNameService,
    readonly corpSearchSaveService: CorpSearchSaveReportService,
    readonly activatedRoute: ActivatedRoute,
    readonly corpSearchApiService: CorpSearchApiService,
    readonly corpSearchByImageService: CorpSearchByImageService,
    private searchApi: SearchService,
    private httpService: HttpService,
    private store: Store,
    public corpSearchRepeatService: CorpSearchRepeatService
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.initialSearchHistory) {
      if (this.initialSearchHistory.searchParameters.searchType !== 'image') {
        this.corpSearchByNameService.loadBySearchHistory(this.initialSearchHistory, this.initialSearchHistoryAction === 'show');
      } else {
        this.loadBySearchHistoryImage(this.initialSearchHistory, this.initialSearchHistoryAction === 'show');
      }
    } else {
      this.parseSearchParams();
    }
  }

  ngDoCheck() {
    if (document.body.style.position === 'fixed') {
      this.visibleBorder = true;
    }
  }


  private parseSearchParams() {
    const query = this.activatedRoute.snapshot.queryParamMap;

    if ((!query.has('q') && !query.has('searchLegalId') && !query.has('searchNumber')) && !sessionStorage.getItem('imageBinary')?.length) {
      return;
    } else if ((query.has('q') || query.has('searchLegalId') || query.has('searchNumber')) && !sessionStorage.getItem('imageBinary')?.length) {
      const designation = query.get('q');
      const searchLegalId = query.get('searchLegalId');
      const searchNumber = query.get('searchNumber');

      this.corpSearchByNameService.load({
        designation: designation || '',
        searchNumber: searchNumber || '',
        searchLegalId: searchLegalId || '',
        mktuList: [],
        okvedList: [],
        countriesList: [{name: 'Российская Федерация', code: 'RU', organizations: ['СНГ', 'БРИКС']}]
      }, !!query.get('saveSearch'));
    } else if (!query.has('q') && sessionStorage.getItem('imageBinary')?.length) {
      this.fileChangeEvent(JSON.parse(sessionStorage.getItem('imageBase64')));
      this.croppedImageBinary = sessionStorage.getItem('imageBinary');
      const searchPayload = {
        mktuList: [],
        okvedList: [],
        countriesList: [{name: 'Российская Федерация', code: 'RU', organizations: ['СНГ', 'БРИКС']}]
      };
      if (query.has('searchID')) {
        this.corpSearchSaveService.setSearchID(query.get('searchID'));
      }
      this.onSearchImage(searchPayload, false);
    }
  }

  changeTab(tab: 'domain' | 'trademark' | 'legalname') {
    this.currentTab = tab;
    if (this.initialSearchHistory) {
      this.changePage();
    }
  }

  onSelect(type: ICorpDataSourceType, selected: any[]) {
    this.corpSearchSaveService.onSelect(type, selected);
  }

  showPopup(show) {
    this.showIndex = show;
    this.visibleBorder = show;
  }

  showPopupMobile(showMobile) {
    if (innerWidth <= 1100) {
      this.showIndexMobile = !showMobile;
    }
  }

  getClassName() {
    switch (this.store.selectSnapshot(CORP_SEARCH_TOKEN).trademarksByName.risks.max_risk) {
      case 'High':
        return 'high';
      case 'Medium':
        return 'medium';
      case 'Low':
        return 'none';
      case 'None':
        return 'none';
    }
  }

  declOfNum(num, words): string {
    return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? Math.abs(num) % 10 : 5]];
  }

  countriesDropdown(event) {
    this.countriesPopup = event;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event.image || event;
    this.imageFile = event.file;

    if (event.deleteImage) {
      this.store.dispatch(new SearchActions.ClearSearch());
      sessionStorage.removeItem('imageBinary');
      sessionStorage.removeItem('imageBase64');
      sessionStorage.removeItem('eventBase64');
      this.corpSearchByImageService.fileSelected = false;
    } else {
      this.corpSearchByImageService.fileSelected = true;
      sessionStorage.setItem('imageBase64', JSON.stringify(event));
      this.disabledButton = false;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBinary = event.base64.split(',')[1];
    this.imageBase64 = event.base64;
    // example with coordinates
    // rectangle: [
    // this.croppedImage.imagePosition.x1,
    // this.croppedImage.imagePosition.y1,
    // this.croppedImage.imagePosition.x2,
    // this.croppedImage.imagePosition.y2
    // ],
  }

  onSearchImage(searchPayload?, saveSearch = true) {
    if (!saveSearch) {
      this.corpSearchApiService.objectsLoading = false;
    }
    const payload = {
      from: 0,
      size: 1000,
      gsNumber: searchPayload ? searchPayload?.mktuList?.map(i => i.number || i) : [],
      image: {
        filename: 'Test.jpeg',
        binary: this.croppedImageBinary,
      },
      // TODO: раскоментить когда доработают метод:
      // TODO: https://cf.9958258.ru/pages/viewpage.action?pageId=139430881
      // eaNumber: searchPayload.okvedList.map(i => i.code),
      // countryCodes: searchPayload.countriesList.map(i => i.code),
    };
    const imageData = {
      imageBinary: this.croppedImageBinary,
      eventBase64: this.imageBase64
    };
    this.store.dispatch(new SearchActions.Search(payload, imageData, saveSearch, null, this.imageFile));
  }

  loadBySearchHistoryImage(search: ISearchHistoryDetail, isShow: boolean) {
    this.httpService.post({path: `request/file`, body: {path: search.searchParameters.image.url, Base64: true}, responseType: 'json'})
      .subscribe((data: any) => {
        if (data?.binary?.length) {
          this.croppedImageBinary = data.binary;
          search.searchParameters.image = {
            filename: search.searchParameters.image.filename,
            binary: this.croppedImageBinary
          };
          this.fileChangeEvent('data:image/jpeg;base64,'.concat(data.binary));
          this.imageFile = search.searchParameters.image.url;
          const searchPayload = {
            mktuList: search.searchParameters.mktuList,
            okvedList: search.searchParameters.okvedList,
            countriesList: search.searchParameters.countriesList,
          };
          this.corpSearchByImageService.isLoadImage = true;
          if (isShow) {
            this.store.dispatch(new SearchActions.LoadByHistory(search));
          } else {
            this.onSearchImage(searchPayload, false);
          }
        }
      });
  }

  changePage($event?) {
    if (this.initialSearchHistory) {
      const query = this.activatedRoute.snapshot.queryParamMap;
      if (query.has('searchID')) {
        const searchID = query.get('searchID');
        const params: ISearchHistoryPayload = {
          searchID,
          limit: 50,
          offset: $event ? ($event.page - 1) * $event.size : 0,
          entity: this.currentTab
        };
        this.store.dispatch(new SearchActions.ChangePageByHistory(params));
      }
    } else {
      this.store.dispatch(new SearchActions.ChangePage($event));
    }
  }

  ngOnDestroy(): void {
    this.corpSearchByNameService.reset();
    this.corpSearchByImageService.fileSelected = false;
    sessionStorage.removeItem('imageBinary');
    sessionStorage.removeItem('imageBase64');
    sessionStorage.removeItem('eventBase64');
    this.corpSearchByImageService.isLoadImage = false;
    this.store.dispatch(new SearchActions.ClearSearch());
  }
}
