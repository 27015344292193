import { LoadOrganizationsResponse } from "./organization.state";

export namespace OrganizationActions {
  export class Init {
    public static readonly type = '[organization] Init';
  }

  export class InitSuccess {
    public static readonly type = '[organization] Init Success';
    constructor(public readonly payload: LoadOrganizationsResponse) { }
  }

  export class InitFail {
    public static readonly type = '[organization] Init Fail';
    constructor(public readonly error: string) { }
  }

  export class SetIsLoading {
    public static readonly type = '[organization] Set Is Loading';
  }

  export class ClearOrganizations {
    static readonly type = '[organization] ClearOrganizations';
  }
}
