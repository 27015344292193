<table>
  <tr>
    <th *ngFor="let head of title">{{head.text}}</th>
  </tr>
  <tr *ngFor="let row of dataView" (mouseenter)="openModal($event, row)" (mouseleave)="closeModal()"
      (click)="openModalMobile($event, row)">
    <td *ngFor="let head of title" [attr.data-label]="head.text" [ngClass]="{
                      'date-doc': head.text === 'Дата договора',
                      'number': head.text === 'Номер договора',
                      'type-doc': head.text === 'Тип договора',
                      'status': head.text === 'Статус',
                      'date-reg': head.text === 'Дата регистрации',
                      'date-pub': head.text === 'Дата публикации',
                      'type-change': head.text === 'Тип изменения'}">
      <div class="contract-tooltip" *ngIf="head.text === 'Номер договора'"
           [ngClass]="row === selectedRow ? 'open' : ''">
        <span class="contract-tooltip__close" (click)="closeModal(); $event.stopPropagation()"></span>
        <dl>
          <dt>Вид договора:</dt>
          <dd>{{row.contractType}}</dd>
        </dl>
        <dl>
          <dt>Лицо, предоставляющее право использования:</dt>
          <dd>{{row.assignor?.name}}</dd>
        </dl>
        <dl>
          <dt>Лицо, которому предоставлено право использования:</dt>
          <dd>{{row.assignee?.name}}</dd>
        </dl>
        <dl>
          <dt>Дата и номер государственной регистрации договора:</dt>
          <dd>{{row.contractDate | date: 'dd.MM.yyyy'}} {{row.contractNumber}}</dd>
        </dl>
        <dl *ngIf="getChanges(row.changesContracts)">
          <dt>Указание об изменениях:</dt>
          <dd>{{getChanges(row.changesContracts)}}</dd>
        </dl>
      </div>
      <div *ngIf="getCellText(row[head.name], head.text)">{{getCellText(row[head.name], head.text)}}</div>
      <div *ngIf="!getCellText(row[head.name], head.text)">{{row[head.name] | date: 'dd.MM.yyyy'}}</div>
    </td>
  </tr>
</table>
<div class="pagination" *ngIf="countPages.length > 1 && !isMobile">
  <button type="button" *ngFor="let page of countPages" [class.active]="pageNumber === page"
          (click)="changePage(page)">{{page}}</button>
</div>
<button *ngIf="dataView.length !== data.length && isMobile" (click)="showMore()" class="show-all-btn">Показать еще
</button>
