import { Component, Input, OnInit, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Claims, ItemForSelection } from '../../../../shared/models/mock.interfaces';

@Component({
  selector: 'app-claims-index-table',
  templateUrl: './claims-index-table.component.html',
  styleUrls: ['./claims-index-table.component.scss']
})
export class ClaimsIndexTableComponent implements OnInit, AfterViewChecked {
  claimsMassive: Claims[] = [];
  typeOfObjects: ItemForSelection[] = [];
  objectsSelect: ItemForSelection[] = [];

  showSearch = false;

  @Input() showFilter;
  @Output() modalEvent = new EventEmitter();

  constructor(private http: HttpClient) {
    this.http.get('/assets/mockDataCourts/mockClaims.json').subscribe((data: any) => {
      this.claimsMassive = this.flat(Object.entries(data))[1];
    });
    this.http.get('/assets/mockDataCourts/mockTypesOfObjects.json').subscribe((data: any) => {
      this.typeOfObjects = this.flat(Object.entries(data))[1];
    });
    this.http.get('/assets/mockDataCourts/mockObjectsSelect.json').subscribe((data: any) => {
      this.objectsSelect = this.flat(Object.entries(data))[1];
    });
  }

  flat(obj): object {
    return obj.flat();
  }

  ngOnInit(): void {
    if (window.innerWidth < 1090) {
      this.showSearch = false;
    } else {
      this.showSearch = true;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 1090) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    });
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      if (this.showFilter) {
        this.showSearch = this.showFilter;
      }
    });
  }

  getCountCheckedObjects(massive) {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });

    return counter;
  }

  cleanCheckedObj(massive) {
    massive.forEach((obj) => {
      obj.checked = false;
    });
  }

  closeModal() {
    this.showSearch = false;
    this.modalEvent.emit();
  }
}
