import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() styleButton: string;
  @Output() search = new EventEmitter();

  onClick(e: Event) {
    e.stopPropagation();
    this.search.emit();
  }

}
