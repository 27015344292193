import { Injectable } from '@angular/core';
import { UraitApiService } from '@web/core/services/urait-api.service';
import { OpenedServiceResponse, ServicesResponseInterface } from '../models/services-response.interface';
import { ServiceInterface } from '../models/service.interface';
import { SectionInterface } from '../models/cards.interface';

@Injectable()
export class ServicesApiService {
  private readonly url = 'services';

  constructor(private apiService: UraitApiService) {}

  getPopularServices() {
    return this.apiService.get<ServicesResponseInterface<ServiceInterface>>(
      `${this.url}/popular`
    );
  }

  getServices() {
    return this.apiService.get<ServicesResponseInterface<SectionInterface>>(
      `${this.url}`
    );
  }

  getServiceDetails(id: string) {
    return this.apiService.get<OpenedServiceResponse>(
      `${this.url}/item`,
      {
        params: {
          serviceID: id,
        },
      },
    );
  }

  getStateFilter() {
    return this.apiService.get<ServicesResponseInterface<string>>(
      `${this.url}/filterstatebody`
    );
  }
}
