import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  lastUrl: string = null;


  constructor(
    private readonly router: Router
  ) {
  }

  listen() {
    // слушаем все события роутера и при изменении эмитим previousUrl$
    this.lastUrl = this.router.url;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const previousUrl = this.lastUrl;
      this.lastUrl = event.url;
      this.setPreviousUrl(previousUrl);
    });
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }


}
