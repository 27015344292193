import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { MatButtonModule } from '@angular/material/button';
import { ButtonIconModule } from '../button-icon/button-icon.module';



@NgModule({
  declarations: [
    ButtonComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    ButtonIconModule,
  ],
  exports: [ButtonComponent],
})
export class ButtonModule { }
