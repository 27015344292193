import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PaymentState } from './store/payment.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { PaymentService } from './services/payment.service';
import { InputModule } from '@ui/components/input/input.module';
import { ButtonModule } from '@ui/components/button/button.module';
import { PayerComponent } from './components/payer/payer.component';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentTabsComponent } from './components/payment-tabs/payment-tabs.component';
import { PaymentCashComponent } from './components/payment-cash/payment-cash.component';
import { SwitchMultiCaseModule } from '@ui/pipes/switch-multi-case/switch-multi-case.module';
import { PaymentOnlineComponent } from './components/payment-online/payment-online.component';

@NgModule({
  declarations: [
    PayerComponent,
    PaymentComponent,
    PaymentTabsComponent,
    PaymentCashComponent,
    PaymentOnlineComponent,
  ],
  imports: [
    InputModule,
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    SwitchMultiCaseModule,
    NgxsSelectSnapshotModule,
    NgxsModule.forFeature([PaymentState]),
  ],
  providers: [PaymentService],
  exports: [PaymentComponent],
})
export class PaymentModule { }
