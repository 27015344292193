<div class="monitoring-modal risk-modal">
  <div class="monitoring-modal__wrap">
    <div class="risk-modal__content">
      <p class="risk-modal__criticality"
         [class.risk-modal__criticality--critical]= "status === 'Критичный риск'"
         [class.risk-modal__criticality--high]= "status === 'Высокий риск'"
         [class.risk-modal__criticality--middle]="status === 'Средний риск'"
         [class.risk-modal__criticality--recommendation]="status === 'Рекомендация'"
      >{{status}}</p>
      <h1 class="risk-modal__title">Зарегистрирован сходный домен</h1>
      <div class="risk-modal__desc">
        <p>
          Владелец домена сможет заработать на вашей узнаваемости и репутации
        </p>
      </div>

      <div class="about-risk">
        <div class="about-risk__source-wrap">
          <h3 class="about-risk__title">Источник риска</h3>
          <div class="about-risk__source">
            <dl>
              <dt class="about-risk__source-title">Домен</dt>
              <dd class="about-risk__source-name">demidemi.ru</dd>
            </dl>
          </div>
        </div>
        <div class="about-risk__date">
          <h3 class="about-risk__caption">Регистрация</h3>
          <p>24.05.2020</p>
        </div>
        <div class="about-risk__applicant">
          <h3 class="about-risk__caption">Администратор домена</h3>
          <p><span>Физическое лицо Private Person</span></p>
        </div>

        <div class="about-risk__source-wrap about-risk__source-wrap--your">
          <h3 class="about-risk__title">Ваш объект</h3>
          <div class="about-risk__source">
            <dl>
              <dt class="about-risk__source-title">Фирменное наименование</dt>
              <dd class="about-risk__source-name">ДемиРус, ООО</dd>
            </dl>
          </div>
        </div>
        <div class="about-risk__date about-risk__date--your">
          <h3 class="about-risk__caption">Регистрация</h3>
          <p>18.03.2015</p>
        </div>
      </div>

    </div>
    <div class="risk-modal__footer">
      <p class="risk-modal__recommendation">Рекомендации</p>
      <p class="risk-modal__recommendation-desc">Оспорьте заявку в Роспатенте чтобы устранить проблему</p>
      <div class="risk-modal__btn-wrap">
        <div class="risk-modal__btn-wrapper">
          <button class="risk-modal__btn risk-modal__btn--decide" routerLink="/monitoring/tariff-for-contestation">Оспорить</button>
          <button class="risk-modal__btn risk-modal__btn--ask" (click)="onClickBtn('спросить поверенного')">Спросить поверенного</button>
        </div>
        <div class="risk-modal__take-risk-wrap">
          <button class="risk-modal__take-risk">Принять риск</button>
          <p>Проблема известна с 24.05.2020</p>
        </div>
      </div>
    </div>
    <button (click)="closeModal()" class="risk-modal__close"></button>
  </div>
  <div (click)="closeModal()" class="monitoring-modal__overlay"></div>
</div>
