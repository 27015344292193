import { Component, OnDestroy, OnInit } from '@angular/core';
import { CorpIndexService } from '../../services/corp-index.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@web/core/states/auth.state';
import { ORGANIZATIONS_STATE_TOKEN } from '@web/core/states/organization.state';
import { AuthActions } from '@web/core/states/auth.actions';
import { ServicesActions } from '@web/features/services/states/services.actions';
import { ServicesState } from '@web/features/services/states/services.state';
import { nullableFilter } from '@web/utils/nullable-filter.util';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';

declare var window: any;

@Component({
  selector: 'app-index-corp',
  templateUrl: './corp-index.component.html',
  styleUrls: ['./corp-index.component.scss']
})
export class CorpIndexComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  rights$ = this.store.select(AuthState.getRights);
  sliderCards$ = this.store.select(ServicesState.getPopular);

  constructor(
    readonly corpIndexService: CorpIndexService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  private readonly onDestroy = new Subject<void>();
  organizations;
  disableSearchWidget = false;
  rights;

  showDropdownOrg = false;
  selectedOrg;
  params;

  ngOnInit(): void {
    this.store.dispatch(new ServicesActions.LoadPopular());
    this.organizations = this.store.selectSnapshot(ORGANIZATIONS_STATE_TOKEN).organizations?.length
      ? [...this.store.selectSnapshot(ORGANIZATIONS_STATE_TOKEN).organizations] : [];
    if (this.organizations?.length) {
      this.organizations.unshift({name: 'Все организации'});
    }

    this.selectedOrg = this.organizations.length === 2
      ? this.organizations[1]
      : this.organizations[0];

    this.corpIndexService.load(this.selectedOrg?.id);

    // TODO: после создания матрицы прав подумать над этим
    // this.route.queryParams.pipe(takeUntil(this.onDestroy)).subscribe(params => {
    //   if (params?.selectedOrganizationId) {
    //     this.selectedOrg = this.organizations.find(o => o.id === params?.selectedOrganizationId);
    //   } else {
    //     this.selectedOrg = this.organizations.length === 2
    //       ? this.organizations[1]
    //       : this.organizations[0];
    //   }
    //   this.corpIndexService.load(this.selectedOrg?.id);
    //   this.store.dispatch(new AuthActions.GetRights(this.selectedOrg));
    // });

    this.rights$.pipe(
      nullableFilter(),
      takeUntil(this.onDestroy)
    ).subscribe(rights => {
      this.rights = rights.rights;
      this.getRightsAlgorithm();
    });

  }

  selectOrg(org) {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_INN });
    this.selectedOrg = org;
    // TODO: после создания матрицы прав подумать над этим
    // if (org.id) {
    //   this.location.go(`/?selectedOrganizationId=${org.id}`);
    // }
    // this.selectedOrg = org;
    //
    // if (this.organizations[0]?.id) {
    //   this.store.dispatch(new AuthActions.GetRights(this.selectedOrg));
    // }
    // this.reloadComponent();
  }

   reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/'], {queryParams: {selectedOrganizationId: this.selectedOrg.id}});
  }

  getRightsAlgorithm() {
    const tradeMarks = this.rights?.filter(i => i?.title === 'Права на поиски');
    this.checkSearchRights(tradeMarks);
  }

  checkSearchRights(tradeMarks) {
    const synonym = 'searches_name';
    tradeMarks[0].data.forEach(item => {
      if (item.nameEng === synonym) {
        this.disableSearchWidget = item.value === 'false' || !item.value || item.value === 'pending';
      }
    });
  }

  trimString(str, count) {
    if (window.innerWidth < 400) {
      return str?.length > count ? str.slice(0, count) + '...' : str;
    }
    return str;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }
}
