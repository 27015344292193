import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BASE_REQUEST_CONTEXT } from '@web/utils/auth.interceptor';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { environment } from '../../../app-ipid/src/environments/environment';

@Injectable()
export class UserPhoneService {
  constructor(private readonly httpClient: HttpClient) { }

  public sendUserPhoneCode(phone: string, brand: string): Observable<{ answer: string }> {
    return this.httpClient.get<{ answer: string }>(`${environment.BASE_URL_API}/user/validation-code/send`, {
      params: new HttpParams().set('phone', phone).set('brand', brand),
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    })
  }

  public checkUserPhoneCode(payload: { phone: string, validationCode: string }, brand: string): Observable<{ answer: string }> {
    return this.httpClient.post<{ answer: string }>(`${environment.BASE_URL_API}/user/validation-code/check`, { body: payload }, {
      params: new HttpParams().set('brand', brand),
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    });
  }
}
