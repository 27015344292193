import { ButtonIconType } from '../button-icon/button-icon.component';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

type ButtonIconPosition = 'left' | 'right';
type DefaultButtonType = 'submit' | 'button' | 'reset';
type UIButtonType = 'primary' | 'secondary' | 'link';

@Component({
  selector: 'app-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() id: string = '';
  @Input() label: string = '';
  @Input() icon: ButtonIconType;
  @Input() disabled: boolean = false;
  @Input() uiType: UIButtonType = 'primary';
  @Input() type: DefaultButtonType = 'button';
  @Input() iconPos: ButtonIconPosition = 'right';
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
}
