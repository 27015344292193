<div class="risks-item__title" *ngIf="this.risks">
  <p class="risks-item__total-wrap">
    <span class="risks-item__total">{{ getTotalRisks() }}</span>
    <span class="risks-item__total-desc">Рисков</span>
  </p>
</div>
<table class="chart-corp" *ngIf="this.risks">
  <tr class="chart-corp__item fatal">
    <td class="chart-corp__line-wrap">
          <span class="chart-corp__line"
                style="width:{{(risks.critical / getMaxRisk())*100}}%">
          </span>
    </td>
    <td class="chart-corp__value">{{ risks.critical }}</td>
    <td class="chart-corp__name">Критичные</td>
  </tr>
  <tr class="chart-corp__item high">
    <td class="chart-corp__line-wrap">
          <span class="chart-corp__line"
                style="width:{{(risks.high / getMaxRisk())*100}}%">
          </span>
    </td>
    <td class="chart-corp__value">{{ risks.high }}</td>
    <td class="chart-corp__name">Высокие</td>
  </tr>
  <tr class="chart-corp__item possible">
    <td class="chart-corp__line-wrap">
          <span class="chart-corp__line"
                style="width:{{(risks.medium / getMaxRisk())*100}}%">
          </span>
    </td>
    <td class="chart-corp__value">{{ risks.medium }}</td>
    <td class="chart-corp__name">Средние</td>
  </tr>
  <tr class="chart-corp__item recommended">
    <td class="chart-corp__line-wrap">
          <span class="chart-corp__line"
                style="width:{{(risks.recommendation / getMaxRisk())*100}}%">
          </span>
    </td>
    <td class="chart-corp__value">{{ risks.recommendation }}</td>
    <td class="chart-corp__name">Рекомендации</td>
  </tr>
  <tr class="chart-corp__item disconnected">
    <td class="chart-corp__line-wrap">
          <span class="chart-corp__line"
                style="width:{{(risks.unconnected / getMaxRisk())*100}}%">
          </span>
    </td>
    <td class="chart-corp__value">{{ risks.unconnected }}</td>
    <td class="chart-corp__name">Неподключенные объекты</td>
  </tr>
</table>
