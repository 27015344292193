import { TrademarkState } from '../../store/trademark.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import {Component, ChangeDetectionStrategy, OnInit} from '@angular/core';
import {INTERCOM_EVENT_NAME, IntercomEventsService} from '../../../../../../shared/services/intercom-events.service';

@Component({
  selector: 'app-trademark',
  templateUrl: './trademark.component.html',
  styleUrls: ['./trademark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkComponent implements OnInit {
  @ViewSelectSnapshot(TrademarkState.step) step: number;
  @ViewSelectSnapshot(TrademarkState.steps) steps: string[];

  constructor(
    private intercomEventsService: IntercomEventsService
  ) {
  }

  public ngOnInit(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.APP_REGISTER });
  }
}
