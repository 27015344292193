import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TrademarkState } from '../../../store/trademark.state';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkActions } from '../../../store/trademark.actions';
import { OrganizationState } from '@web/core/states/organization.state';
import { OrganizationInterface } from '@web/core/models/organization.interface';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationListComponent {
  @ViewSelectSnapshot(TrademarkState.viewOrganizationsList) viewOrganizationsList: boolean;
  @ViewSelectSnapshot(OrganizationState.organizations) organizations: OrganizationInterface[];
  @Dispatch() hideOrganizationsList = () => new TrademarkActions.ShowOrganizationsList(false);
  @Dispatch() setSelectedOwner = (owner: OrganizationInterface) => new TrademarkActions.SetOrganizationFromList(owner);
}
