<div class="filter-container"
     [class.filter-container__mobile]="isControlsShown">
  <form [formGroup]="form"
        class="filter-container__form"
        [class.filter-container__form_mobile]="isControlsShown"
        [style.grid-template-columns]="gridTemplates">
    <div *ngFor="let item of def">
      <app-ui-input
        *ngIf="item.type === 'input'"

        [placeholder]="item.placeholder"
        [formControlName]="item.name"
      ></app-ui-input>

      <app-ui-selector
        *ngIf="item.type === 'select'"

        [placeholder]="item.placeholder"
        [formControlName]="item.name"
        [options]="item.options"
      ></app-ui-selector>

      <app-ui-daterange
        *ngIf="item.type === 'date'"

        [placeholder]="item.placeholder"
        [formControlName]='item.name'
      ></app-ui-daterange>

      <app-ui-autocomplete
        *ngIf="item.type === 'autocomplete'"
        
        [options]="item.options"
        [formControlName]="item.name"
        [placeholder]="item.placeholder"
      ></app-ui-autocomplete>
    </div>

    <div class="filter-container__buttons" *ngIf="isControlsShown">
      <button class="btn-blue-attr btn-clean" (click)="clearAll()">
        Очистить
      </button>
      <button
        class="btn-blue btn-search"
        (click)="isControlsShown = false; makeBodyHidden()"
      >
        Найти
      </button>
    </div>
  </form>

  <span
    class="filter-container__controls-open"
    [class.filter-container__controls-open_active]="isControlsShown"
  >
    <p (click)="isControlsShown = true; makeBodyHidden()">
      <img src="/assets/images/icon-search-blue.svg" alt="search" class="search-image">
      {{buttonTittle}}</p>
  </span>

  <button class="filter-container__controls-close" *ngIf="isControlsShown"
          (click)="isControlsShown = false; makeBodyHidden()"
  ></button>
</div>
