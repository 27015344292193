import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentOnlineComponent } from './payment-online/payment-online.component';
import { PaymentOfflineComponent } from './payment-offline/payment-offline.component';
import { PayerComponent } from './payer/payer.component';
import { PaymentTabsComponent } from './payment-tabs/payment-tabs.component';
import { PaymentOfflineIndividualComponent } from './payment-offline-individual/payment-offline-individual.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    PaymentOnlineComponent,
    PaymentOfflineComponent,
    PayerComponent,
    PaymentTabsComponent,
    PaymentOfflineIndividualComponent,
  ],
  exports: [
    PaymentOnlineComponent,
    PaymentOfflineComponent,
    PayerComponent,
    PaymentTabsComponent,
    PaymentOfflineIndividualComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ]
})
export class PaymentModule {
}
