import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ui-objects-found',
  templateUrl: './objects-found.component.html',
  styleUrls: ['./objects-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectsFoundComponent {

  _found: number;
  @Input() set found(value: number) {
    this._found = value;

    this.setFoundEnding(value);
    this.setObjectsEnding(value);
  }
  get found() {
    return this._found;
  }

  @Input() total: number;

  @Output() clearFilter = new EventEmitter<void>();

  objectWord = 'объектов';

  foundWord = 'Найдено';

  setFoundEnding(value: number) {
    const lastChar = value?.toString().split('').pop();
    if (value !== 11 && lastChar === '1') {
      this.foundWord = 'Найден';
    } else {
      this.foundWord = 'Найдено';
    }
  }

  setObjectsEnding(value: number) {
    const valuesEndingsForA = ['2', '3', '4'];
    const lastChar = value?.toString().split('').pop();
    if (value !== 11 && lastChar === '1' ) {
      this.objectWord = 'объект';
    } else if (valuesEndingsForA.includes(lastChar) && (value < 10 || value > 20)) {
      this.objectWord = 'объекта';
    } else {
      this.objectWord = 'объектов';
    }
  }

  showAll() {
    this.clearFilter.emit();
  }
}
