<section class="confidants container" *ngIf="attorneys.length > 0">
  <h3 class="confidants__caption" [class.empty]="!proxiesByUser.length">Доверенности</h3>
  <div class="confidants__empty" *ngIf="!proxiesByUser.length" >
    Нет доступных доверенностей!
  </div>
  <table *ngIf="proxiesByUser.length" class="confidants__table">
      <tr>
        <th>Название файла</th>
        <th>Статус</th>
        <th>Дата загрузки</th>
        <th>Действует от</th>
        <th>Действительна до</th>
      <tr *ngFor="let confidant of proxiesByUser">
        <td class="confidants__name-wrap">
          <a [href]="confidant?.file?.path" target="_blank" class="confidants__name">{{ confidant?.file?.name }}</a>
        </td>
        <td class="confidants__status-wrap">
          <p [ngClass]="{
                      'check': confidant?.attorney?.status === 'На проверке',
                      'valid': confidant?.attorney?.status === 'Действует',
                      'invalid': confidant?.attorney?.status === 'Не действует'}"
             class="confidants__status">
            {{ confidant?.attorney?.status }}
          </p>
        </td>
        <td class="confidants__date-wrap" data-label="Дата загрузки">{{ confidant?.attorney.dateLoad | date:'dd.MM.yyyy' }}</td>
        <td class="confidants__from-wrap" data-label="Действует от">{{ confidant?.attorney.dateStart | date:'dd.MM.yyyy' }}</td>
        <td class="confidants__to-wrap" data-label="Действительна до">{{ confidant?.attorney.dateEnd | date:'dd.MM.yyyy' }}</td>
      </tr>
    </table>
</section>
