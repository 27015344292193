<div class='table-corp__row'>
  <div class="table-corp__date-name">
    <small class="table-corp__mobile">Дата создания, пользователь</small>
    <p>{{search.date | date:'dd.MM.yyyy'}}, <span>{{search.date | date:'H:mm:ss'}}</span></p>
    <h3>{{search.user}}</h3>
  </div>
  <div class="table-corp__company">
    <p *ngIf="search.searchParameters.searchType !== 'image'"><b>{{trimString(search.searchParameters.text || search.searchParameters.searchLegalId || search.searchParameters.searchNumber, 20)}}</b></p>
    <img *ngIf="search.searchParameters.searchType === 'image'" src="{{search.searchParameters.image?.url}}" alt="image" style="width: 70px">
    <div *ngIf="search.searchParameters.mktuList?.length || search.searchParameters.okvedList?.length"
         class="table-corp__classes">
      <div class="table-corp__class-card" *ngFor="let item of search.searchParameters.okvedList">
        <span class="table-corp__card-title table-corp__card-title--blue">Оквэд</span>
        <p><b>{{item.code}}</b></p>
      </div>
      <div class="table-corp__class-card" *ngFor="let item of search.searchParameters.mktuList">
        <span class="table-corp__card-title table-corp__card-title--red">МКТУ</span>
        <p><b>{{item.number || item}}</b></p>
      </div>
    </div>
  </div>
  <div class="table-corp__search-type">
    <small class="table-corp__mobile">Вид поиска</small>
    <p>{{formatSearchType(search.searchParameters.searchType)}}</p>
  </div>
  <div class="table-corp__results">
    <small class="table-corp__mobile">Результатов</small>
    <p>{{search.count}}</p>
  </div>
  <div class="table-corp__reports">
    <small class="table-corp__mobile">Документов в отчете</small>

    <p class="table-corp__report"
       *ngIf="isSearchView"
       (click)="onChangeTab('reports')"
       [class.table-corp__report--active]="search.countReport > 0"
       [class.table-corp__report--empty]="search.countReport === 0">
      <span>{{search.countReport}}</span>
    </p>


    <p class="table-corp__report"
       *ngIf="isReportView"
       [class.table-corp__report--empty]="search.countReport === 0">{{search.countReport}}</p>
  </div>
  <div class="table-corp__choose">
    <div *ngIf="isSearchView">
      <a
        [routerLink]="'/search/trademarks'"
        [queryParams]="{ searchID: search.searchID, show: 1, right: search.right,
                               searchText: search.searchParameters.text ? search.searchParameters.text : '',
                               searchLegalId: search.searchParameters.searchLegalId ? search.searchParameters.searchLegalId : '',
                               searchNumber: search.searchParameters.searchNumber ? search.searchParameters.searchNumber : ''}">
        Посмотреть
      </a>
      <p>или</p>
      <a
        [class.disabled]="search.right === false"
        style="color: #929fab"
        [routerLink]="'/search/trademarks'"
        [queryParams]="{ searchID: search.searchID,
                               searchText: search.searchParameters.text ? search.searchParameters.text : '',
                               searchLegalId: search.searchParameters.searchLegalId ? search.searchParameters.searchLegalId : '',
                               searchNumber: search.searchParameters.searchNumber ? search.searchParameters.searchNumber : ''}">
        повторить</a>
    </div>
    <div *ngIf="isReportView">
      <ng-container *ngIf="!isDownloading(search)">
<!--        <a [routerLink]="'/search/trademarks'"-->
<!--           [queryParams]="{ searchID: search.searchID, show: 1, right: search.right,-->
<!--                               searchText: search.searchParameters.text ? search.searchParameters.text : '',-->
<!--                               searchLegalId: search.searchParameters.searchLegalId ? search.searchParameters.searchLegalId : '',-->
<!--                               searchNumber: search.searchParameters.searchNumber ? search.searchParameters.searchNumber : ''}">Посмотреть</a>-->
        <a (click)="onOpenReport(search)">Посмотреть</a>
        <p>или</p> <a (click)="onDownloadReport(search)" style="color: #929fab">скачать</a>
      </ng-container>
      <app-spinner class="sm" *ngIf="isDownloading(search)"></app-spinner>
    </div>
  </div>
</div>
