<div class="events-steps__hidden">
  <div class="events-steps__hidden-text-wrapper hidden">
    <h3>Получено решение арбитражного суда. Иск удовлетворен частично.</h3>
    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
      <div class="hidden__blocks-table-wrapper">
        <h4>Рекомендация</h4>
        <p>Иск удволетворен частично</p>
        <table class="hidden-table">
          <tr>
            <th>Состав требований</th>
            <th>Сумма требований</th>
            <th>Присуждено судом</th>
            <th>Разница</th>
          </tr>
          <tr>
            <td>Основной долг</td>
            <td><p>Сумма требований</p><span>80 000 ₽</span></td>
            <td><p>Присуждено судом</p><span>80 000 ₽</span></td>
            <td><p>Разница</p><span>0 ₽</span></td>
          </tr>
          <tr>
            <td>Неустойка</td>
            <td><p>Сумма требований</p><span>20 000 ₽</span></td>
            <td><p>Присуждено судом</p><span>20 000 ₽</span></td>
            <td class="hidden-table__minus">
              <p>Разница</p>
              <span class="hidden-table__sum">
                            -15 000 ₽
                              <span class="hidden-table__question">?
                                <span class="hidden-table__prompt">
                                   <p>Мы заявили сумму неустойки, рассчитанную по условиям договора. Суд посчитал неустойку не
                                    соразмерной факту нарушения. И расчитал сумму неустойки в соответвии с ГК РФ.
                                   </p>
                                  </span>
                               </span>
                              </span>
            </td>
          </tr>
          <tr>
            <td>Судебные расходы</td>
            <td><p>Сумма требований</p><span>19 890 ₽</span></td>
            <td><p>Присуждено судом</p><span>14 240 ₽</span></td>
            <td class="hidden-table__minus">
              <p>Разница</p>
              <span class="hidden-table__sum">
                            -5 240 ₽
                              <span class="hidden-table__question">?
                                <span class="hidden-table__prompt">
                                   <p>Мы заявили сумму неустойки, рассчитанную по условиям договора. Суд посчитал неустойку не
                                    соразмерной факту нарушения. И расчитал сумму неустойки в соответвии с ГК РФ.
                                   </p>
                                  </span>
                               </span>
                              </span>
            </td>
          </tr>
          <tr class="hidden-table__bold">
            <td>Итого</td>
            <td>119 890 ₽</td>
            <td>85 100 ₽</td>
            <td>-20 240 ₽</td>
          </tr>
        </table>
        <p>
          Мы считаем, что данное решение выгодное и рекомендуем согласиться с ним. Однако, окончательное
          решение всегда остается за вами, мы готовы его поддержать и обжаловать в вышестоящей инстанции.
        </p>
      </div>
      <div class="hidden__blocks-after-table">
        <div class="hidden__block">
          <h4 class="hidden__subtitle">Решение арбитражного суда</h4>
          <p>Скачать решение арбитражного суда</p>
          <div class="docs__block">
            <div class="docs__text-wrapper">
              <span>Решение суда</span>
              <a href="" download>nazvanie_dogovora.pdf</a>
            </div>
            <div class="docs__icon-wrapper">
                             <span class="docs__icon">
<!--                               <img src="/assets/images/green-key.png" class ="docs__icon--mini">-->
                               <img src="/assets/icons/blue-empty.svg">
                             </span>
            </div>
          </div>
        </div>
        <div class="hidden__block">
          <h4 class="hidden__subtitle">Ваше решение</h4>
          <p>Если вы соглашаетесь с решением суда, то мы можем приступить к взысканию сразу после получения
            исполнительного листа.
          </p>
          <form>
            <fieldset>
              <div class="hidden__input-wrapper input-checkbox">
                <input type="radio" name="agreement" id="225">
                <label class="input-checkbox__label" for="225">
                  Согласиться с решением суда
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="12"/>
                    <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                  </svg>
                </label>
              </div>
              <div class="hidden__input-wrapper input-checkbox">
                <input type="radio" name="agreement" id="23">
                <label class="input-checkbox__label" for="23">
                  Обжаловать решение суда
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="12"/>
                    <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                  </svg>
                </label>
              </div>
            </fieldset>
            <div class="hidden__btn-wrapper">
              <button type="submit" class='btn-blue'>Продолжить</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>
