import { Injectable } from '@angular/core';
import { IResponse } from '../../../shared/models/main.interfaces';
import { HttpService } from '../../../shared/services/http.service';

const path = 'user';

@Injectable({
  providedIn: 'root'
})

export class MainMenuService {


  constructor(private http: HttpService) {
  }

  logOut(params) {
    return this.http.get<IResponse>({path: `${path}/logout`, subjectType: 'logout', params});
  }
}
