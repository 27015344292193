import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { BASE_REQUEST_CONTEXT } from '@web/utils/auth.interceptor';
import { environment } from 'projects/app-ipid/src/environments/environment';
import { CheckpaymentResponse, YandexResponseToken } from '../types/payment.types';

@Injectable()
export class PaymentService {
  constructor(private readonly httpClient: HttpClient) { }

  public createYandexPayment(idApplication: string): Observable<YandexResponseToken> {
    return this.httpClient.get<YandexResponseToken>(`${environment.BASE_URL_API}/yandex_payment/create`, {
      params: { responseID: idApplication },
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    })
  }

  public checkPaymentStatus(idApplication: string, yaLastPaymentId: string): Observable<CheckpaymentResponse> {
    return this.httpClient.get<CheckpaymentResponse>(`${environment.BASE_URL_API}/yandex_payment/check`, {
      params: { payment_id: yaLastPaymentId, responseID: idApplication },
      context: new HttpContext().set(BASE_REQUEST_CONTEXT, true),
    })
  }
}
