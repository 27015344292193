<section class="section">
  <div class="container">
    <div class="steps">
      <div class="steps__element steps__element--line" [ngClass]="getStepClass(1)"><span class="steps__element-text">
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Вид договора</span></div>
      <div
        *ngIf="requestContractService.changeContract && contractAction === 'termination' || requestContractService.changeContract && contractAction === 'change' || requestContractService.contractId || requestContractService.contractDate && requestContractService.contractNumber"
        class="steps__element steps__element--line" [ngClass]="getChooseContractClass()"><span
        class="steps__element-text">
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Выбор договора</span></div>
      <div
        *ngIf="!requestContractService.changeContract && contractAction !== 'termination' || requestContractService.changeContract && contractAction === 'change'"
        class="steps__element steps__element--line" [ngClass]="getStepClass(2)"><span class="steps__element-text">
         <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Стороны договора</span></div>
      <div
        *ngIf="!requestContractService.changeContract && contractAction !== 'termination' || requestContractService.changeContract && contractAction === 'change'"
        class="steps__element steps__element--line" [ngClass]="getStepClass(3)"><span class="steps__element-text">
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Объект договора</span></div>
      <div
        *ngIf="!requestContractService.changeContract && contractAction !== 'termination' || requestContractService.changeContract && contractAction === 'change'"
        class="steps__element steps__element--line" [ngClass]="getStepClass(4)"><span class="steps__element-text">
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Предмет договора</span></div>
      <div class="steps__element steps__element--line" [ngClass]="getStepClass(5)"><span class="steps__element-text">
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Тариф</span></div>
      <div class="steps__element steps__element--line" [ngClass]="getStepClass(6)"><span class="steps__element-text">
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Оплата</span></div>
      <div class="steps__element" [ngClass]="getStepClass(7)"><span class="steps__element-text">
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
            <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>Заявка сформирована</span>
      </div>
    </div>
    <div [ngSwitch]="step"
         class="step-box"
         [class.step-box__special]="step === 3 || step === 1 && requestContractService.changeContract">
      <div *ngSwitchCase="0">
        <div class="loader"></div>
      </div>
      <div *ngSwitchCase="1">
        <div *ngIf="!requestContractService.changeContract">
          <h1 class="heading-h1">Регистрация договора</h1>
          <app-type (type)="getContractType($event)"></app-type>
          <div class="container container__tabs container__tabs--footer">
            <div class="form-item__btn-block">
              <button type="button" class="btn-blue-attr" (click)="backStep()">Отмена</button>
              <button type="button" class="btn-blue" [disabled]="!contractType" (click)="nextStep()">Далее</button>
            </div>
          </div>
        </div>
        <div *ngIf="requestContractService.changeContract">
          <h1
            class="heading-h1 heading-h1__special heading-h1__special--obj">{{requestContractService.contractAction === 'change' ? 'Выберите договор для внесения изменений' : 'Выберите договор для расторжения'}}</h1>
          <app-choice-of-contract
            [contractsImmutable]="immutableContracts">
          </app-choice-of-contract>
          <div class="container container__tabs container__tabs--footer container__tabs--paddings">
            <div class="form-item__btn-block">
              <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
              <button type="button" class="btn-blue" [disabled]="validationChoiceContract()"
                      (click)="sendContractInfo()">Далее
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="2">
        <h1 class="heading-h1">Стороны договора</h1>
        <app-sides></app-sides>
        <div class="form-item__btn-block">
          <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
          <button type="button" class="btn-blue" (click)="nextStep()">Далее</button>
        </div>
      </div>
      <div *ngSwitchCase="3" class="step-box__special">
        <div *ngIf="!hasRisk">
          <h1 class="heading-h1 heading-h1__special heading-h1__special--obj">Объект договора</h1>
          <app-objects
            [objectsImmutable]="immutableObjects">
          </app-objects>
          <div class="form-item__btn-block form-item__btn-block--special">
            <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
            <button type="button" class="btn-blue" [disabled]="objects === 0" (click)="nextStep()">Далее</button>
          </div>
        </div>
        <div *ngIf="hasRisk">
          <h1 class="heading-h1 heading-h1__special">Выявлен риск отказа в регистрации</h1>
          <app-risk></app-risk>
          <div class="form-item__btn-block form-item__btn-block--special">
            <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
            <button type="button" class="btn-blue" (click)="nextstepRisk()">Далее</button>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="4">
        <h1 class="heading-h1">Предмет договора и существенные условия</h1>
        <app-conditions [contractType]="contractType"></app-conditions>
        <div class="container container__tabs container__tabs--footer">
          <div class="form-item__btn-block">
            <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
            <button type="button" class="btn-blue" (click)="nextStep()">Далее</button>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="5">
        <h1 class="heading-h1" style="margin-bottom: 40px">Выберите тариф</h1>
        <app-rates [rates]="requestContractService.rates"
                   [activeTariff]="requestContractService.activeTariff"
                   [countRates]="requestContractService.countRates"
                   [requestType]="requestType"
                   (selectingTariff)="requestContractService.selectTariff($event)">
        </app-rates>
        <div class="container container__tabs container__tabs--footer">
          <div class="form-item__btn-block">
            <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
            <div class="form-item__payment mobile-pay">

              <div class="form-item__btn-block--mini">
                <app-promo-code
                  style="width: 100%"
                  (promoEvent)="applyPromoCode($event)"
                >
                </app-promo-code>
              </div>
              <div class="form-item__btn-block--mini">
                <p>Итого к оплате: <b>{{(+requestContractService.paymentSum).toLocaleString('ru')}} ₽</b></p>
              </div>
              <button type="button" class="btn-blue btn-contract" (click)="nextStep()">Оплатить</button>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="6">
        <app-payment-contract></app-payment-contract>
        <div class="form-item__btn-block payment-buttons">
          <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
        </div>
      </div>
      <div *ngSwitchCase="7">
        <app-finished *ngIf="requestContractService.paymentType === 'online'; else offline"></app-finished>
        <ng-template #offline>
          <div class="info-plate">
            <div class="info-plate__img"><img [src]="logo2path"></div>

            <p *ngIf="requestContractService.paymentType === 'cash'" class="info-plate__text">Счет сформирован и отправлен вам на почту {{userEmail}}.<br> Рекомендуем оплатить
              счет в течение 5 дней.</p>
            <p *ngIf="requestContractService.paymentType === 'act'" class="info-plate__text">
              Стоимость услуг и пошлины будут включены в следующий акт выполненных работ.
            </p>

            <a class="btn" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к заявке</a>
          </div>

        </ng-template>
      </div>
    </div>

  </div>
</section>

<app-expert></app-expert>
