import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-intellectual-property',
  templateUrl: './intellectual-property.component.html',
  styleUrls: ['./intellectual-property.component.scss']
})
export class IntellectualPropertyComponent implements OnInit, OnChanges {

  @Input() userBlock;
  @Input() rights;
  @Output() deleteRight = new EventEmitter();
  @Output() addRight = new EventEmitter();
  @Output() deleteAll = new EventEmitter();
  @Output() saveChange = new EventEmitter();
  @Input() rightParams;
  rightsInput;
  userRole;
  bodyDel = {
    rights: []
  };
  bodyAdd = {
    rights: []
  };
  id;
  contId;
  showAdminPanel = false;
  showButtons = true;

  constructor(
  ) {
  }

  ngOnInit(): void {
    const role = JSON.parse(localStorage.getItem('currentUserData'));
    this.userRole = role.role;
    this.id = role.userID;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.rights) {
      const refresh = window.location.protocol + '//' + window.location.host + window.location.pathname;
      window.history.pushState({path: refresh}, '', refresh);
      if (this.rightParams && this.rightParams[1] === this.id) {
        this.showAdminPanel = true;
      }


      this.rights.forEach(item => {
        item.data.forEach(i => {
          i.checked = false;
        });
      });
      this.rights.forEach(data => {
        data.data.forEach(d => {
          d.checked = d.value === 'true';
        });
      });
      this.rightsInput = [...this.rights];
    }
  }

  changeRights(id, value, checked) {
    if (checked) {
      this.bodyAdd.rights.push({id, value, checked});
    } else {
      this.bodyDel.rights.push({id, value, checked});
    }
  }

  confirmRightsChange() {
    if (this.bodyAdd.rights.length) {
      this.addRight.emit(this.bodyAdd);
    }
    if (this.bodyDel.rights.length) {
      this.deleteRight.emit(this.bodyDel);
    }
    this.saveChange.emit();
  }

  deleteAllRights() {
    this.deleteAll.emit();
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
