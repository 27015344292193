<!--<div class="pagination-block" *ngIf="countPages > 0">-->
<!--  <div class="pagination-button" [class.disabled-page]="page === 1" (click)="backPage()">-->
<!--    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <path d="M8 1L2 7L8 13" stroke="#00AFEC" stroke-width="1.5"/>-->
<!--    </svg>-->
<!--  </div>-->
<!--  <div class="pagination-button" *ngFor="let item of subPages" [class.active-page]="page === item" (click)="changePage(item)"> {{item}} </div>-->
<!--  <div class="pagination-button" style="margin-right: 0; transform: scale(-1, 1)" [class.disabled-page]="page === countPages" (click)="nextPage()">-->
<!--    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <path d="M8 1L2 7L8 13" stroke="#00AFEC" stroke-width="1.5"/>-->
<!--    </svg>-->
<!--  </div>-->
<!--</div>-->


<!--&lt;!&ndash;<div *ngIf="countPages > 0 && mobile" class="show">Показать еще из {{allItems}}</div>&ndash;&gt;-->
<div class="pagination-block">
  <div *ngIf="pageNumbersList?.length > 1" class="o-pagination">
    <div class="o-pagination__container">

      <button *ngIf="isPrevSet" class="o-pagination__btn"
              [ngStyle]="getWidthBtn()"
              (click)="onChangePageNumber(1)">
        1
      </button>

      <button *ngIf="isPrevSet" class="o-pagination__btn"
              [ngStyle]="getWidthBtn()"
              (click)="onChangePageNumber(pageNumbersList[0] - 1)">
        ...
      </button>

      <button *ngFor="let pageNumber of pageNumbersList"
              class="o-pagination__btn"
              [ngStyle]="getWidthBtn()"
              [class.active]="pageNumber === currentPageNumber"
              (click)="onChangePageNumber(pageNumber)">
        {{pageNumber}}
      </button>

      <button *ngIf="isNextSet" class="o-pagination__btn"
              [ngStyle]="getWidthBtn()"
              (click)="onChangePageNumber(pageNumbersList[pageNumbersList.length - 1] + 1)">
        ...
      </button>

      <button *ngIf="isNextSet" class="o-pagination__btn"
              [ngStyle]="getWidthBtn()"
              (click)="onChangePageNumber(pageCount)">
        {{pageCount}}
      </button>

    </div>
  </div>
</div>
