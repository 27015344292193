<div class="pagination-block">
  <div *ngIf="pageNumbersList?.length > 1" class="o-pagination">
    <div class="o-pagination__container">

      <button
        *ngIf="isPrevSet" class="o-pagination__btn"
        [ngStyle]="getWidthBtn()"
        (click)="onChangePageNumber(1)"
      >1</button>

      <button
        *ngIf="isPrevSet" class="o-pagination__btn"
        [ngStyle]="getWidthBtn()"
        (click)="onChangePageNumber(pageNumbersList[0] - 1)"
      >...</button>

      <button
        *ngFor="let pageNumber of pageNumbersList"
        class="o-pagination__btn"
        [ngStyle]="getWidthBtn()"
        [class.active]="pageNumber === currentPageNumber"
        (click)="onChangePageNumber(pageNumber)"
      >{{pageNumber}}</button>

      <button
        *ngIf="isNextSet" class="o-pagination__btn"
        [ngStyle]="getWidthBtn()"
        (click)="onChangePageNumber(pageNumbersList[pageNumbersList.length - 1] + 1)"
      >...</button>

      <button
        *ngIf="isNextSet" class="o-pagination__btn"
        [ngStyle]="getWidthBtn()"
        (click)="onChangePageNumber(pageCount)"
      >{{pageCount}}</button>

    </div>
  </div>
</div>
