<section class="section">
  <div class="container">
    <div class="steps">
      <div class="steps__element steps__element--line steps__element-check-label" [ngClass]="getStepClass(1)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Загрузка изображений
            </span>
      </div>
      <div class="steps__element steps__element--line steps__element-check-label" [ngClass]="getStepClass(2)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Оплата
            </span>
      </div>
      <div class="steps__element steps__element--line steps__element--line-check-label steps__element-check-label" [ngClass]="getStepClass(3)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Заявка сформирована
            </span>
      </div>
      <div class="steps__mobile">Шаг {{requestCheckLabelService.step}} из 3</div>
    </div>
    <div [ngSwitch]="requestCheckLabelService.step"
         class="step-box">
      <div *ngSwitchCase="0">
        <div class="loader"></div>
      </div>
      <div *ngSwitchCase="1">
        <h1 class="heading-h1">Обозначение и правообладатель</h1>
        <app-label-owner
          [organizations]="requestCheckLabelService.organizations">
        </app-label-owner>
        <div class="container container__tabs container__tabs--footer">
          <div class="form-item__btn-block">
            <button
              type="button"
              (click)="backStep()"
              class="btn-blue-attr">
              Отмена
            </button>
            <button
              type="button"
              class="btn-blue"
              (click)="nextStep()"
            >
              Далее
            </button>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="2">
        <app-payment-check-label
          [organizations]="requestCheckLabelService.organizations"
        >
        </app-payment-check-label>
        <div class="form-item__btn-block payment-buttons">
          <button
            type="button"
            class="btn-blue-attr"
            (click)="backStep()">
            Назад
          </button>
        </div>
      </div>
      <div *ngSwitchCase="3">
        <app-finished-check-label *ngIf="requestCheckLabelService.paymentType === 'online'; else offline"></app-finished-check-label>
        <ng-template #offline>
          <div class="info-plate">
            <div class="info-plate__img"><img [src]="logo2path" alt="alt"></div>

            <p *ngIf="requestCheckLabelService.paymentType === 'cash'" class="info-plate__text">Счет сформирован и отправлен вам на почту {{userEmail}}.<br> Рекомендуем оплатить
              счет в течение 5 дней.</p>
            <p *ngIf="requestCheckLabelService.paymentType === 'act'" class="info-plate__text">
              Стоимость услуг и пошлины будут включены в следующий акт выполненных работ.
            </p>

            <a class="bordered-blue" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к заявке</a>
          </div>

        </ng-template>
      </div>
    </div>
  </div>
</section>
