import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy, OnInit,
  Output
} from '@angular/core';
import { FiltersLayout, Filters } from '../../models/filters.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, SubscriptionLike } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent<T extends Filters<any>> implements OnDestroy, AfterViewInit, OnInit {
  @Input() set def(value: T) {
    this._def = value;

    this.form = this.fb.group(value.reduce((acc, input) => {
      acc[input.name] = this.fb.control(input.options ? [] : '');
      return acc;
    }, {}));

    if (!this.sub) {
      this.sub = this.form.valueChanges
        .pipe(debounceTime(1000))
        .subscribe(filterValue => {
          this.apply.emit(filterValue);
        });
    }
  }

  get def(): T {
    return this._def;
  }

  private _def: T;

  @Input() smButtonTitle;
  @Input() value: Record<string, any> = {};
  @Input() layout: FiltersLayout;
  @Output() apply = new EventEmitter<Record<string, any>>();

  @Input() clear$: Observable<void>;
  @Input() filterValue: Record<string, any>;
  @Input() currentTab: string;

  private _currentLayout: 'sm' | 'md' | 'lg' = 'md';
  @Input() set currentLayout(value: 'sm' | 'md' | 'lg') {
    this._currentLayout = value;
    if (value !== 'sm' && this.isControlsShown) {
      document.body.style.overflow = 'visible';
      document.body.style.position = '';
      document.body.style.height = 'auto';
      this.isControlsShown = false;
    }
  }
  get currentLayout() {
    return this._currentLayout;
  }

  get buttonTittle() {
    if (this.currentTab === 'reports' && this.currentLayout === 'sm') {
      this.smButtonTitle = 'Фильтр по отчетам';
    } else if (this.currentTab === 'searches' && this.currentLayout === 'sm') {
      this.smButtonTitle = 'Фильтр по истории';
    }
    return this.smButtonTitle;
  }

  form: FormGroup;

  sub: SubscriptionLike;
  clearSub: SubscriptionLike;

  isControlsShown = false;

  constructor(private fb: FormBuilder) {}

  get gridTemplates() {
    const layout = this.layout ?? this.autogeneratedFilterLayouts;
    const sizes = layout[this.currentLayout] ?? layout.md;

    return sizes.join(' ');
  }

  get autogeneratedFilterLayouts(): FiltersLayout {
    const items = this.def.map(() => '1fr');
    return { md: items, sm: ['1fr'], lg: items };
  }

  ngOnInit() {
    if (this.filterValue && Object.values(this.filterValue).some(el => el?.length)) {
      for (const key of Object.keys(this.form.controls)) {
        this.form.get(key).setValue(this.filterValue[key]);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.clearSub) {
      this.clearSub.unsubscribe();
    }
  }

  clearAll() {
    for (const key of Object.keys(this.form.controls)) {
      this.form.get(key).setValue('');
    }
  }

  makeBodyHidden() {
    if (this._currentLayout === 'sm') {
      if (this.isControlsShown) {
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
        document.getElementsByTagName('header')[0].style.zIndex = '0';
      } else {
        document.body.style.overflow = 'visible';
        document.getElementsByTagName('header')[0].style.zIndex = '';
        document.body.style.height = 'auto';
      }
    }
  }

  ngAfterViewInit(): void {
    this.clearSub = this.clear$?.subscribe(() => {
      this.clearAll();
    });
  }
}
