export default [
  {
    id: 0,
    value: 'Алтайский край',
    checked: false,
    special: true
  },
  {
    id: 1,
    value: 'Амурская область',
    checked: false,
    special: true },
  {
    id: 2,
    value: 'Архангельская область',
    checked: false,
    special: true
  },
  {
    id: 3,
    value: 'Астраханская область',
    checked: false,
    special: true
  },
  {
    id: 4,
    value: 'Белгородская область',
    checked: false,
    special: true
  },
  { id: 5, value: 'Брянская область', checked: false, special: true },
  {
    id: 6,
    value: 'Владимирская область',
    checked: false,
    special: true
  },
  {
    id: 7,
    value: 'Волгоградская область',
    checked: false,
    special: true
  },
  {
    id: 8,
    value: 'Вологодская область',
    checked: false,
    special: true
  },
  {
    id: 9,
    value: 'Воронежская область',
    checked: false,
    special: true
  },
  {
    id: 10,
    value: 'Еврейская автономная область',
    checked: false,
    special: true
  },
  {
    id: 11,
    value: 'Забайкальский край',
    checked: false,
    special: true
  },
  {
    id: 12,
    value: 'Ивановская область',
    checked: false,
    special: true
  },
  { id: 13, value: 'Иркутская область', checked: false, special: true },
  {
    id: 14,
    value: 'Кабардино-Балкарская Республика',
    checked: false,
    special: true
  },
  {
    id: 15,
    value: 'Калининградская область',
    checked: false,
    special: true
  },
  { id: 16, value: 'Калужская область', checked: false, special: true },
  { id: 17, value: 'Камчатский край', checked: false, special: true },
  {
    id: 18,
    value: 'Карачаево-Черкесская Республика',
    checked: false,
    special: true
  },
  {
    id: 19,
    value: 'Кемеровская область',
    checked: false,
    special: true
  },
  { id: 20, value: 'Кировская область', checked: false, special: true },
  {
    id: 21,
    value: 'Корякский автономный округ',
    checked: false,
    special: true
  },
  {
    id: 22,
    value: 'Костромская область',
    checked: false,
    special: true
  },
  {
    id: 23,
    value: 'Краснодарский край',
    checked: false,
    special: true
  },
  { id: 24, value: 'Красноярский край', checked: false, special: true },
  {
    id: 25,
    value: 'Курганская область',
    checked: false,
    special: true
  },
  { id: 26, value: 'Курская область', checked: false, special: true },
  {
    id: 27,
    value: 'Ленинградская область',
    checked: false,
    special: true
  },
  { id: 28, value: 'Липецкая область', checked: false, special: true },
  {
    id: 29,
    value: 'Магаданская область',
    checked: false,
    special: true
  },
  { id: 30, value: 'Москва', checked: false, special: true },
  {
    id: 31,
    value: 'Московская область',
    checked: false,
    special: true
  },
  {
    id: 32,
    value: 'Мурманская область',
    checked: false,
    special: true
  },
  {
    id: 33,
    value: 'Ненецкий автономный округ',
    checked: false,
    special: true
  },
  {
    id: 34,
    value: 'Нижегородская область',
    checked: false,
    special: true
  },
  {
    id: 35,
    value: 'Новгородская область',
    checked: false,
    special: true
  },
  {
    id: 36,
    value: 'Новосибирская область',
    checked: false,
    special: true
  },
  { id: 37, value: 'Омская область', checked: false, special: true },
  {
    id: 38,
    value: 'Оренбургская область',
    checked: false,
    special: true
  },
  { id: 39, value: 'Орловская область', checked: false, special: true },
  {
    id: 40,
    value: 'Пензенская область',
    checked: false,
    special: true
  },
  { id: 41, value: 'Пермский край', checked: false, special: true },
  { id: 42, value: 'Приморский край', checked: false, special: true },
  { id: 43, value: 'Псковская область', checked: false, special: true },
  {
    id: 44,
    value: 'Республика Адыгея (Адыгея)',
    checked: false,
    special: true
  },
  { id: 45, value: 'Республика Алтай', checked: false, special: true },
  {
    id: 46,
    value: 'Республика Башкортостан',
    checked: false,
    special: true
  },
  {
    id: 47,
    value: 'Республика Бурятия',
    checked: false,
    special: true
  },
  {
    id: 48,
    value: 'Республика Дагестан',
    checked: false,
    special: true
  },
  {
    id: 49,
    value: 'Республика Ингушетия',
    checked: false,
    special: true
  },
  {
    id: 50,
    value: 'Республика Калмыкия',
    checked: false,
    special: true
  },
  {
    id: 51,
    value: 'Республика Карелия',
    checked: false,
    special: true
  },
  { id: 52, value: 'Республика Коми', checked: false, special: true },
  { id: 53, value: 'Республика Крым', checked: false, special: true },
  {
    id: 54,
    value: 'Республика Марий Эл',
    checked: false,
    special: true
  },
  {
    id: 55,
    value: 'Республика Мордовия',
    checked: false,
    special: true
  },
  {
    id: 56,
    value: 'Республика Саха (Якутия)',
    checked: false,
    special: true
  },
  {
    id: 57,
    value: 'Республика Северная Осетия - Алания',
    checked: false,
    special: true
  },
  {
    id: 58,
    value: 'Республика Татарстан (Татарстан)',
    checked: false,
    special: true
  },
  { id: 59, value: 'Республика Тыва', checked: false, special: true },
  {
    id: 60,
    value: 'Республика Хакасия',
    checked: false,
    special: true
  },
  {
    id: 61,
    value: 'Ростовская область',
    checked: false,
    special: true
  },
  { id: 62, value: 'Рязанская область', checked: false, special: true },
  { id: 63, value: 'Самарская область', checked: false, special: true },
  { id: 64, value: 'Санкт-Петербург', checked: false, special: true },
  {
    id: 65,
    value: 'Саратовская область',
    checked: false,
    special: true
  },
  {
    id: 66,
    value: 'Сахалинская область',
    checked: false,
    special: true
  },
  {
    id: 67,
    value: 'Свердловская область',
    checked: false,
    special: true
  },
  { id: 68, value: 'Севастополь', checked: false, special: true },
  {
    id: 69,
    value: 'Смоленская область',
    checked: false,
    special: true
  },
  {
    id: 70,
    value: 'Ставропольский край',
    checked: false,
    special: true
  },
  {
    id: 71,
    value: 'Тамбовская область',
    checked: false,
    special: true
  },
  { id: 72, value: 'Тверская область', checked: false, special: true },
  { id: 73, value: 'Томская область', checked: false, special: true },
  { id: 74, value: 'Тульская область', checked: false, special: true },
  { id: 75, value: 'Тюменская область', checked: false, special: true },
  {
    id: 76,
    value: 'Удмуртская Республика',
    checked: false,
    special: true
  },
  {
    id: 77,
    value: 'Ульяновская область',
    checked: false,
    special: true
  },
  {
    id: 78,
    value: 'Усть-Ордынский Бурятский автономный округ',
    checked: false,
    special: true
  },
  { id: 79, value: 'Хабаровский край', checked: false, special: true },
  {
    id: 80,
    value: 'Ханты-Мансийский автономный округ - Югра',
    checked: false,
    special: true
  },
  {
    id: 81,
    value: 'Челябинская область',
    checked: false,
    special: true
  },
  {
    id: 82,
    value: 'Чеченская Республика',
    checked: false,
    special: true
  },
  {
    id: 83,
    value: 'Чувашская Республика - Чувашия',
    checked: false,
    special: true
  },
  {
    id: 84,
    value: 'Чукотский автономный округ',
    checked: false,
    special: true
  },
  {
    id: 85,
    value: 'Ямало-Ненецкий автономный округ',
    checked: false,
    special: true
  },
  {
    id: 86,
    value: 'Ярославская область',
    checked: false,
    special: true
  }
];
