import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SsoAuthService } from '../../../../app-ipid/src/app/shared/services/sso-auth.service';
import { environment } from '../../../../app-ipid/src/environments/environment';
import { OauthService } from '../oauth.service';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss']
})
export class GoogleAuthComponent implements OnInit {
  brand;
  code;

  constructor(
    private route: ActivatedRoute,
    private sso: SsoAuthService,
    private oauth: OauthService,
    private router: Router
  ) {
    this.brand = environment.BRAND;

  }

  ngOnInit(): void {
    this.googleAuth();
  }

  googleAuth() {
    this.route.queryParams.subscribe(param => {
      if (param.code) {
        this.code = param.code;
        const body = {
          code: this.code,
          provider: 'google'
        };
        const params = new HttpParams()
          .set('brand', this.brand);
        this.oauth.googleLogin(body, params).subscribe(data => {
          console.log(data);
          if (data.answer) {
            this.router.navigate(['/index']);
          }
        });
      }
    });
  }
}
