import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { RequestType } from '../../types/trademark.types';
import { TrademarkActions } from '../../store/trademark.actions';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';

type CanActivateReturn = boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>;

@Injectable()
export class LoadTrademarkRequestGuard implements CanActivate {
  @Dispatch() clearTrademarkState = () => new TrademarkActions.ClearTrademarkState();
  @Dispatch() loadTrademarkDraft = (id: string) => new TrademarkActions.LoadTrademarkDraft(id);
  @Dispatch() setRequestType = (type: RequestType) => new TrademarkActions.SetRequestType(type);

  public canActivate(route: ActivatedRouteSnapshot): CanActivateReturn {
    route.params.requestId 
      ? this.loadTrademarkDraft(route.params.requestId)
      : this.clearTrademarkState();
      
    this.setRequestType(route.data.requestType);

    return true;
  }
}
