import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';

import mktuData from '../ip-id-search/json-data/mktu';
import okvedData from '../ip-id-search/json-data/okved';
import countries from '../ip-id-search/json-data/countries';
import { SearchService } from '../../services/search.service';
import { ISearchOptions } from '../../models/search.interfaces';
import { environment } from '../../../../../app-ipid/src/environments/environment';
import { IntercomEventsService } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-ip-id-search-input',
  templateUrl: './ip-id-search-input.component.html',
  styleUrls: ['./ip-id-search-input.component.scss']
})

export class IpIdSearchInputComponent implements OnInit {

  constructor(
    private searchService: SearchService,
    private intercomEventsService: IntercomEventsService,
  ) {
  }

  searchDefault = true;
  searchStrategy = false;
  searchCompetition = false;
  widthDesignation = 140;

  query;

  searchContainerHeight = 60;

  isShowSearchPopup = false;
  isShowMktuPlusBtn = true;
  isShowSearchInput = true;
  isShowCountriesBtn = true;
  isShowCountriesPopup = false;
  isShowCountriesPopupMobile = false;

  searchPopupViewType = 'mktu';

  // Loading variables
  isLoadingSearchClases = false;
  isLoadingTradeMarks = false;
  searchRequest200 = false;
  isLoadingLegalNames = false;
  isLoadingDomainNames = false;

  // Mapped mktu data array for first show after click by plus button
  mktuData = JSON.parse(JSON.stringify(mktuData));
  // Array with selected MKTU classes
  mktuSearchArray = [];
  // Array for MKTU response after mapping for a single structure
  mktuMappedResponse = [];
  mktuMobileTempArray = [];
  mktuBySearchMobileTempArray = [];
  // Массив выдачи мкту из последнего API поиска
  mktuSelectedDataFromPreviosApiRequest = [];
  mktuSelectedDataFromPreviosApiRequestCopy = [];

  // Mapped okved data array for first show after click by plus button
  okvedData = okvedData;
  // Array with selected OKVED classes
  okvedSearchArray = [];
  // Array for OKVED response after mapping for a single structure
  okvedMappedResponse = JSON.parse(JSON.stringify(this.okvedData));
  okvedMobileTempArray = [];
  okvedBySearchMobileTempArray = [];
  // Массив выдачи оквед из последнего API поиска
  okvedSelectedDataFromPreviosApiRequest = [];
  okvedSelectedDataFromPreviosApiRequestCopy = [];
  isLoadingOkvedSearch = false;

  // Debouncing Value Changes timeout for search classes
  timeoutID;

  innOgrnQuery;

  // Array for OKVED response after search by inn/ogrn
  legalMappedResponse = [];
  legalMobileTempArray = [];
  legalName = '';
  isLoadingLegal = false;

  searchError = false;

  isMobile = true;

  countries = JSON.parse(JSON.stringify(countries));
  // Array with selected countries classes
  countriesSearchArray = [];
  filterCountryList;

  // Отображение территории действия только для корпоратов
  corporate = environment.corporate;
  placeholder;

  @Input() designation = '';
  @Input() mktuList = [];
  @Input() okvedList = [];
  @Input() countriesList = [];
  @Input() disableCountry;
  @Input() disableButton;

  @Input() options: any = {};

  @Output() search = new EventEmitter<ISearchOptions>();
  @Output() searchImage = new EventEmitter();
  @Output() openPopup = new EventEmitter();
  @Output() openCountriesPopup = new EventEmitter();
  @Output() eventSelected = new EventEmitter<any>();
  @Output() eventDeleted = new EventEmitter<any>();
  @Output() searchHappened = new EventEmitter<any>();
  @Output() eventsearch = new EventEmitter<any>();
  @Output() reportdownload = new EventEmitter<any>();
  @Output() riskscheck = new EventEmitter<any>();
  @Output() reportrisksclick = new EventEmitter<any>();
  @Output() action = new EventEmitter();

  @ViewChild('invisibleText') invTextER: ElementRef;
  @ViewChild('searchContainerEl') searchContainerElement: ElementRef;
  @ViewChild('queryEl') queryElement: ElementRef;
  @ViewChild('searchModalEl') searchModalElement: ElementRef;
  @ViewChild('searchModalCountries') searchModalCountries: ElementRef;
  @ViewChild('searchModalCountriesMobile') searchModalCountriesMobile: ElementRef;
  @ViewChild('innOgrn') innOgrnElement: ElementRef;

  @HostListener('document:mousedown', ['$event'])
  clickOutside(event): void {
    if (this.searchModalCountries && this.searchModalCountries.nativeElement.contains(event.target)) {
      this.isShowCountriesPopup = true;
    } else {
      this.isShowCountriesPopup = false;
      this.isShowCountriesBtn = true;
    }
    this.isShowCountriesPopupMobile = !!(this.searchModalCountriesMobile && this.searchModalCountriesMobile.nativeElement.contains(event.target));
  }

  ngOnInit(): void {
    this.init();

    if (this.okvedList?.length) {
      this.okvedSearchArray = [...this.okvedList.map(i => ({...i}))];
    }
    if (this.mktuList?.length) {
      this.mktuSearchArray = [...this.mktuList.map(i => ({...i}))];
    }
    if (this.countriesList?.length) {
      this.countriesSearchArray = [...this.countriesList.map(i => ({...i}))];
    }

    this.onMobile();

    window.addEventListener('resize', () => {
      this.onMobile();
    });
  }

  onMobile() {
    this.isMobile = window.innerWidth <= 1000;
  }

  init() {
    if (this.designation) {
      console.log('Init designation: ', this.designation);
    }

    if (this.options.search === 'okved') {
      this.searchPopupViewType = 'okved';
    }
    if (this.options.search === 'mktu') {
      this.searchPopupViewType = 'mktu';
    }

    this.addSearchStrAtrCountryCode();

    this.initialCountiesSearch();

    if (this.corporate) {
      this.placeholder = 'Укажите обозначение';
    }
    if (this.disableCountry) {
      this.placeholder = '';
    }
    else {
      this.placeholder = 'Укажите товарный знак или название';
    }
  }

  initialCountiesSearch(): void {
    // По умолчанию поиск по России
    this.countriesSearchArray = this.countries.filter(i => i.code === 'RU');

    this.countries.forEach(i => {
      i.selected = this.countriesSearchArray.includes(i);
      i.checked = this.countriesSearchArray.includes(i);
    });
  }

  checkTradeMarks() {
    if (!this.designation) {
      return;
    }

    if (!this.countriesSearchArray.length) {
      this.initialCountiesSearch();
    }

    const data = {
      designation: this.designation,
      mktuList: [...this.mktuSearchArray],
      okvedList: [...this.okvedSearchArray],
      countriesList: [...this.countriesSearchArray]
    };

    this.sendIntercomData({
      event: 'printSearch',
      query: data.designation,
      mktu: data.mktuList,
      okved: data.okvedList,
      countries: data.countriesList,
    });

    this.search.emit(data);
  }

  searchImages() {
    const mktuForSearch = [];
    this.mktuSearchArray.forEach(i => {
      mktuForSearch.push(i.number);
    });
    const okvedForSearch = [];
    console.log(this.okvedSearchArray);
    this.okvedSearchArray.forEach(i => {
      okvedForSearch.push(parseInt(i.code, 10));
    });
    const joinedData = mktuForSearch.concat(okvedForSearch);
    let classData = [];
    if (joinedData.length) {
      classData = [...joinedData];
    }
    this.searchImage.emit(classData);
  }

  checkTradeMarksForAppSearch() {
    if (!this.designation) {
      return;
    }

    const data = {
      designation: this.designation,
      mktuList: [...this.mktuSearchArray],
      okvedList: [...this.okvedSearchArray],
      countriesList: [...this.countriesSearchArray]
    };

    this.sendIntercomData({
      event: 'printSearch',
      query: data.designation,
      mktu: data.mktuList,
      okved: data.okvedList,
      countries: data.countriesList,
    });

    this.search.emit(data);
  }

  // Debouncing Value Changes
  fetchMktuAndOkvedByQuery() {
    if (!this.query) {
      // Только если не мобилка
      if (innerWidth > 1100) {
        this.searchModalElement.nativeElement.scrollTop = 0;
        this.searchByJSON();
      } else {
        this.mktuBySearchMobileTempArray = [];
        this.okvedBySearchMobileTempArray = [];
      }
      return;
    }

    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID);
    }
    this.timeoutID = window.setTimeout(() => {
      if (this.query && this.query.length >= 2) {
        this.fetchMktu();
        this.fetchOkved();
      }
    }, 400);
  }

  fetchMktu() {
    if (this.query) {
      this.isLoadingSearchClases = true;

      const query = {
        gsDescription: this.query
      };
      const queryNum = {
        gsNumber: +this.query
      };
      if (isNaN(this.query)) {
        this.searchService.searchByTermMKTU(query).subscribe((response: any) => {
          if (innerWidth > 1100) {
            this.mktuMappedResponse = this.mappingResponse('mktu', {...response, type: 'term'});
          } else {
            this.mktuBySearchMobileTempArray = this.mappingResponse('mktu', {...response, type: 'term'});
          }
          this.isLoadingSearchClases = false;
        });
      } else {
        this.searchService.searchByNumberMKTU(queryNum).subscribe((response: any) => {
          if (innerWidth > 1100) {
            this.mktuMappedResponse = this.mappingResponse('mktu', {...response, type: 'number'});
          } else {
            this.mktuBySearchMobileTempArray = this.mappingResponse('mktu', {...response, type: 'number'});
          }
          this.isLoadingSearchClases = false;
        });
      }
    }
  }

  fetchOkved() {
    if (this.query) {
      this.isLoadingSearchClases = true;

      const queryOkved = {
        eaDescription: this.query
      };
      const queryNumOkved = {
        eaNumber: this.query
      };
      if (isNaN(this.query)) {
        this.searchService.searchByTermOKVED(queryOkved).subscribe((response: any) => {
          if (innerWidth > 1100) {
            this.okvedMappedResponse = this.mappingResponse('okved', {...response, type: 'term'});
          } else {
            this.okvedBySearchMobileTempArray = this.mappingResponse('okved', {
              ...response,
              type: 'term'
            });
          }
          this.isLoadingSearchClases = false;
        });
      } else {
        this.searchService.searchByNumberOKVED(queryNumOkved).subscribe((response: any) => {
          if (innerWidth > 1100) {
            this.okvedMappedResponse = this.mappingResponse('okved', {...response, type: 'number'});
          } else {
            this.okvedBySearchMobileTempArray = this.mappingResponse('okved', {
              ...response,
              type: 'number'
            });
          }
          this.isLoadingSearchClases = false;
        });
      }
    }
  }

  fetchLegal() {
    // @ts-ignore
    if (this.innOgrnQuery && this.innOgrnElement.valid) {
      this.isLoadingLegal = true;

      this.searchService.getLegal({id: this.innOgrnQuery}).subscribe((response: any) => {

        this.sendIntercomData({event: 'printSearchInn', query: this.innOgrnQuery});

        this.legalName = response?.hits?.hits[0]?._source?.name ?
          `/ ${response?.hits?.hits[0]?._source?.name}` :
          response?.hits?.hits[0]?._source?.fullName ? `/ ${response?.hits?.hits[0]?._source?.fullName}` : '';
        this.legalMappedResponse = response?.hits?.hits[0]?._source?.OKVEDCodes?.map((OKVEDCode) => {
          let classIsSelected = false;
          this.okvedSelectedDataFromPreviosApiRequest.forEach((okvedSearchItem: any) => {
            if (okvedSearchItem.checkedMobile || okvedSearchItem.selected) {
              if (OKVEDCode.code === okvedSearchItem.code) {
                classIsSelected = true;
              }
            }
          });
          this.okvedSearchArray.forEach((okvedSearchItem: any) => {
            if (okvedSearchItem.checkedMobile || okvedSearchItem.selected) {
              if (OKVEDCode.code === okvedSearchItem.code) {
                classIsSelected = true;
              }
            }
          });
          return {
            ...OKVEDCode,
            selected: classIsSelected,
            checkedMobile: classIsSelected,
          };
        });
        if (this.legalMappedResponse) {
          this.legalMobileTempArray = JSON.parse(JSON.stringify(this.legalMappedResponse));
        }
        this.isLoadingLegal = false;
      });
    }
  }

  mappingResponse(type, data) {
    if (data?.hits?.hits?.length) {
      return data.hits.hits.map((item: any) => {

        if (type === 'mktu') {
          // Добавляем в подкласс флаг для того чтобы если юзер вернется к добалению подклассов мкту из поиска, мы сделали класс selected
          // если какой то из подклассов уже был добавлен в поиск
          let subClassIsSelected = false;
          this.mktuSearchArray.forEach((mktuItem: any) => {
            if (mktuItem.number === parseInt(item._source.classNumber, 10) && mktuItem?.subClasses?.length) {
              const foundSubClass: any = mktuItem.subClasses.find((mktuSubClass) => mktuSubClass._id === item._id);
              if (foundSubClass) {
                subClassIsSelected = true;
              }
            }
          });

          const cheked = this.mktuSelectedDataFromPreviosApiRequest.findIndex(el => el._id === item._id) !== -1;
          switch (data.type) {
            case 'number':
              return {
                _id: item._id,
                number: parseInt(item._source.classNumber, 10),
                name: `Класс ${item._source.classNumber}`,
                desc: item._source.description,
                subclass: true,
                selected: subClassIsSelected || cheked,
                checkedMobile: subClassIsSelected || cheked
              };
            case 'term':
              return {
                _id: item._id,
                number: parseInt(item._source.classNumber, 10),
                name: `Класс ${item._source.classNumber}`,
                desc: item.highlight['description.parts'][0],
                subclass: true,
                selected: subClassIsSelected || cheked,
                checkedMobile: subClassIsSelected || cheked
              };
          }

        } else if (type === 'okved') {

          let classIsSelected = false;
          this.okvedSearchArray.forEach((okvedSearchItem: any) => {
            if (okvedSearchItem.selected) {
              if (item._source.code === okvedSearchItem.code) {
                classIsSelected = true;
              }
            }
          });
          const cheked = this.okvedSelectedDataFromPreviosApiRequest.findIndex(el => el.code === item._source.code) !== -1;
          switch (data.type) {
            case 'number':
              return {
                _id: item._id,
                number: parseInt(item._source.code, 10),
                code: item._source.code,
                description: item._source.description,
                selected: classIsSelected || cheked,
                checkedMobile: classIsSelected || cheked
              };
            case 'term':
              return {
                _id: item._id,
                number: parseInt(item._source.code, 10),
                code: item._source.code,
                description: item.highlight['description.parts'][0],
                selected: classIsSelected || cheked,
                checkedMobile: classIsSelected || cheked
              };
          }
        }
      });
    } else {
      console.warn('mappingMktuResponse: NOT DATA');
      return [];
    }
  }

  searchByJSON() {
    this.sendIntercomData({event: 'clickActivity'});

    const mktuDataJSONcopy = JSON.parse(JSON.stringify(this.mktuData));
    const okvedDataJSONcopy = JSON.parse(JSON.stringify(this.okvedData));

    // Проверяем если есть мкту классы добавленные через подкласс или они были выбраны до этого, делаем их selected
    this.mktuSearchArray.forEach((mktuSearchItem: any) => {
      if (mktuSearchItem.addedWithSubclass || mktuSearchItem.selected) {
        const foundClass: any = mktuDataJSONcopy.find((mktuClass) => mktuClass.number === mktuSearchItem.number);
        if (foundClass) {
          foundClass.selected = foundClass.checkedMobile = true;
          foundClass.addedWithSubclass = mktuSearchItem.addedWithSubclass;
        }
        if (mktuSearchItem.subClasses && mktuSearchItem.subClasses.length) {
          mktuSearchItem.subClasses.forEach(item => {
            this.mktuSelectedDataFromPreviosApiRequest.push(item);
          });
        }
      }
    });

    // Проверяем если есть ли добавленные оквед классы, делаем их selected
    this.okvedSearchArray.forEach((okvedSearchItem: any) => {
      if (okvedSearchItem.selected) {
        const foundClass: any = okvedDataJSONcopy.find((okvedClass) => okvedClass.code === okvedSearchItem.code);
        if (foundClass) {
          foundClass.selected = foundClass.checkedMobile = true;
        } else {
          this.okvedSelectedDataFromPreviosApiRequest.push(okvedSearchItem);
        }
      }
    });

    this.mktuMappedResponse = mktuDataJSONcopy;
    this.mktuMobileTempArray = JSON.parse(JSON.stringify(this.mktuMappedResponse));
    this.mktuSelectedDataFromPreviosApiRequestCopy = JSON.parse(JSON.stringify(this.mktuSelectedDataFromPreviosApiRequest));

    this.okvedMappedResponse = okvedDataJSONcopy;
    this.okvedMobileTempArray = JSON.parse(JSON.stringify(this.okvedMappedResponse));
    this.okvedSelectedDataFromPreviosApiRequestCopy = JSON.parse(JSON.stringify(this.okvedSelectedDataFromPreviosApiRequest));

    this.isShowMktuPlusBtn = false;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.isShowSearchPopup = true;
      this.openPopup.emit(this.isShowSearchPopup);

      if (innerWidth < 1100) {
        this.okvedSearchArray = [];
        this.mktuSearchArray = [];
      }

      if (innerWidth > 1100) {
        this.queryElement?.nativeElement?.focus();
        this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
      }
    }, 0);
  }

  //
  // searchTerm;
  //
  // searchCountryJson(): void {
  //   const term = this.searchTerm;
  //   const itemsCopy = this.countries;
  //   this.countries = itemsCopy.filter(tag => {
  //     return tag.name.indexOf(term) >= 0;
  //   });
  // }

  searchByCountries(): void {
    this.isShowCountriesBtn = false;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.isShowCountriesPopup = true;
      this.openCountriesPopup.emit(this.isShowCountriesPopup);
    }, 0);
  }

  searchByCountriesMobile(): void {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.isShowCountriesPopupMobile = true;
      this.openCountriesPopup.emit(this.isShowCountriesPopupMobile);
    }, 0);
  }

  addMktuToSearch(item, dontDelete, isMobile = false) {

    if (item.selected && !dontDelete && !isMobile) {
      this.deleteNumberFromSearch('mktu', item);
      return;
    }

    item.selected = item.checkedMobile = true;

    // Если это МКТУ подкласс, мы добавляем его в родительский МКТУ класс
    if (item.subclass) {

      if (!isMobile) {
        this.sendIntercomData({event: 'selectMktu', mktu: item.number, subclass: true});
      }

      const foundAddedClass: any = this.mktuSearchArray.find((mktuSearchItem) => mktuSearchItem.number === item.number);

      if (foundAddedClass) {
        if (!foundAddedClass.subClasses) {
          foundAddedClass.subClasses = [];
        }
        foundAddedClass.subClasses.push(item);
      } else {
        // Если добавленный класс не найден, добавляем класс в поиск вместе с подклассом
        const mainClass: any = JSON.parse(JSON.stringify(
          this.mktuData.find((mktuDataItem) => mktuDataItem.number === item.number)
        ));
        if (mainClass) {
          // Добавляем в класс флаг для того чтобы если юзер вернется к добалению классов мкту из json, мы сделали класс selected
          mainClass.selected = mainClass.checkedMobile = true;
          mainClass.addedWithSubclass = true;
          // Добавляем подкласс
          mainClass.subClasses = [item];
          // Пушим класс с подклассом в массив МКТУ классов для поиска
          this.mktuSearchArray.push(mainClass);
          this.eventSelected.emit(mainClass);
        }
      }
    } else {
      // Если нет подкласса просто добавляем мкту класс в массив поиска

      if (!isMobile) {
        this.sendIntercomData({event: 'selectMktu', mktu: item.number, subclass: false});
      }

      this.mktuSearchArray.push(item);
      this.eventSelected.emit(item);
    }

    if (!isMobile) {
      setTimeout(() => {
        this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
      }, 0);
    }
  }

  addOkvedToSearch(item, isMobile = false) {
    if (item.selected && !isMobile) {
      this.deleteNumberFromSearch('okved', item);
      return;
    }

    item.selected = true;

    if (!isMobile) {
      this.sendIntercomData({event: 'selectOkvd', okved: item.code || item.number});
    }

    this.okvedSearchArray.push(item);
    this.eventSelected.emit(item);

    if (!isMobile) {
      setTimeout(() => {
        this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
      }, 0);
    }
  }

  deleteNumberFromSearch(type, item) {

    switch (type) {
      case 'mktu':
        // delete from search string
        this.mktuSearchArray.splice(this.mktuSearchArray.findIndex((i) => i.number === item.number), 1);

        // selected false for subclasses list
        if (item.subClasses && this.isShowSearchPopup) {
          item.subClasses.forEach((subClass) => {
            const foundSelectedSubClass: any = this.mktuMappedResponse.find((mktuSubClassItem) => {
              return mktuSubClassItem._id === subClass._id && mktuSubClassItem.selected;
            });
            if (foundSelectedSubClass) {
              foundSelectedSubClass.selected = false;
            }
          });
        }

        this.eventDeleted.emit(item);

        // selected false for main array
        this.mktuMappedResponse.forEach((mktuItem: any) => {
          if (mktuItem.number === item.number) {
            mktuItem.selected = false;
          }
        });

        break;
      case 'okved':
        // delete from search string
        this.okvedSearchArray.splice(this.okvedSearchArray.findIndex((i) => i.code === item.code), 1);

        // selected false for main array
        this.okvedMappedResponse.forEach((okvedItem: any) => {
          if (okvedItem.code === item.code) {
            okvedItem.selected = false;
          }
        });
        // selected false for main array
        this.legalMappedResponse.forEach((okvedItem: any) => {
          if (okvedItem.code === item.code) {
            okvedItem.selected = false;
          }
        });
        break;
      case 'countries':
        if (this.countriesSearchArray.length > 1) {
          this.countriesSearchArray.splice(this.countriesSearchArray.findIndex((i) => i.code === item.code), 1);

          this.countries.forEach((i: any) => {
            if (i.code === item.code) {
              i.selected = false;
              i.checked = false;
            }
          });
        }

        break;
    }

    if (!this.mktuSearchArray.length && !this.okvedSearchArray.length) {
      // this.hideSearchPopup();
      // this.query = '';
      if (!this.isMobile) {
        setTimeout(() => {
          this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
        }, 0);
      }
    }
  }

  legalChanged() {
    this.legalMappedResponse = [];
    this.legalMobileTempArray = [];
    this.legalName = '';
    this.fetchLegal();
  }

  backFromInn() {
    this.innOgrnQuery = '';
    this.legalName = '';
    this.changeSearchPopupView('all');
  }

  getDynamicMarginForLegalName() {
    let leftMargin = 0;
    if (this.isMobile) {
      leftMargin = 50;
    }
    switch (this.innOgrnQuery.length) {
      case 10:
        return {left: `${leftMargin + 112}px`};
      case 12:
        return {left: `${leftMargin + 130}px`};
      case 13:
        return {left: `${leftMargin + 138}px`};
      case 15:
        return {left: `${leftMargin + 154}px`};
    }
  }

  hideSearchPopup() {
    this.isShowSearchPopup = false;
    this.openPopup.emit(this.isShowSearchPopup);

    // app.ip-id
    this.searchPopupViewType = 'mktu';

    this.isShowMktuPlusBtn = true;
  }

  hideSearchPopupFromOutsideClick(e) {

    // Для safari, генерация $event.path массива, так как safari нам его не дает в $event в отличие от хрома
    if (!e?.path) {
      if (!('path' in Event.prototype)) {
        Object.defineProperty(Event.prototype, 'path', {
          get() {
            const path = [];
            let currentElem = this.target;
            while (currentElem) {
              path.push(currentElem);
              currentElem = currentElem.parentElement;
            }
            if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
              path.push(document);
            }
            if (path.indexOf(window) === -1) {
              path.push(window);
            }
            return path;
          }
        });
      }
    }

    if (e?.path) {
      const isSearchPopup = e.path.find((p) => {
        if (p.className) {
          return p.className.includes('main_modal') || p.className.includes('ip-id_Input_wide');
        }
      });
      if (isSearchPopup) {
        return;
      } else {
        this.clickOnDesignation();
        setTimeout(() => {
          this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
        }, 0);
      }
    }
  }

  clickOnDesignation() {
    this.hideSearchPopup();
    this.query = '';
  }

  clickByMobileQueryInput() {
    if (!this.query) {
      this.mktuBySearchMobileTempArray = [];
      this.okvedBySearchMobileTempArray = [];
      this.changeSearchPopupView('okvedBySearch');
    }
  }

  changeSearchPopupView(type: string, mobile?: boolean) {
    switch (type) {
      case 'okved':
        this.sendIntercomData({event: 'clickСhoiceOkvd'});
        break;
      case 'mktu':
        this.sendIntercomData({event: 'clickСhoiceMktu'});
        break;
      case 'inn-ogrn':
        this.sendIntercomData({event: 'clickСhoiceInn'});
        break;
      case 'all':
        this.sendIntercomData({event: 'clickChoiceMktuOkvdMob'});
        break;
      default:
        console.warn('Эвент не отправлен. searchPopupViewType: ', type);
    }

    if (!mobile) {
      this.searchPopupViewType = type;
    } else if (this.query) {
      switch (type) {
        case 'okved':
          this.searchPopupViewType = 'okvedBySearch';
          break;
        case 'mktu':
          this.searchPopupViewType = 'mktuBySearch';
          break;
        default:
          console.warn('Что то пошло не так. searchPopupViewType: ', type);
      }
    } else {
      this.searchPopupViewType = type;
    }

    // this.query = '';
    this.innOgrnQuery = this.legalName = '';
    this.legalMobileTempArray = [];
    this.legalMappedResponse = [];
    // this.okvedBySearchMobileTempArray = [];
  }

  showDefault() {
    this.searchDefault = true;
    this.searchCompetition = false;
    this.searchStrategy = false;
  }

  showMoreDescription(classIndex, classType) {
    switch (classType) {
      case 'okved':
        this.okvedMobileTempArray[classIndex].opened = !this.okvedMobileTempArray[classIndex].opened;
        break;
      case 'okvedBySearch':
        this.okvedBySearchMobileTempArray[classIndex].opened = !this.okvedMobileTempArray[classIndex].opened;
        break;
      case 'mktu':
        this.mktuMobileTempArray[classIndex].opened = !this.mktuMobileTempArray[classIndex].opened;
        break;
      case 'mktuBySearch':
        this.mktuBySearchMobileTempArray[classIndex].opened = !this.mktuMobileTempArray[classIndex].opened;
        break;
      case 'legal':
        this.legalMobileTempArray[classIndex].opened = !this.legalMobileTempArray[classIndex].opened;
        break;
      default:
        console.warn('Не передан тип класса!');
    }
  }

  checkoutClassMobile($event, classItem, classIndex, classType, classCode?) {
    switch (classType) {
      case 'okved':
        this.sendIntercomData({event: 'selectOkvd', okved: classItem.code || classItem.number});
        this.okvedMobileTempArray[classIndex].checkedMobile = !!$event.target.checked;
        break;
      case 'okvedBySearch':
        this.sendIntercomData({event: 'selectOkvd', okved: classItem.code || classItem.number});
        this.okvedBySearchMobileTempArray[classIndex].checkedMobile = !!$event.target.checked;
        if (!$event.target.checked && classCode) {
          const index = this.okvedSelectedDataFromPreviosApiRequest.findIndex(el => el.code === classCode);
          this.okvedSelectedDataFromPreviosApiRequest.splice(index, 1);
        } else {
          this.okvedSelectedDataFromPreviosApiRequest.push(this.okvedBySearchMobileTempArray[classIndex]);
        }
        break;
      case 'mktu':
        this.sendIntercomData({event: 'selectMktu', mktu: classItem.number, subclass: !!classItem.subclass});
        this.mktuMobileTempArray[classIndex].checkedMobile = !!$event.target.checked;
        break;
      case 'mktuBySearch':
        this.sendIntercomData({event: 'selectMktu', mktu: classItem.number, subclass: !!classItem.subclass});
        this.mktuBySearchMobileTempArray[classIndex].checkedMobile = !!$event.target.checked;
        if (!$event.target.checked && classCode) {
          const index = this.mktuSelectedDataFromPreviosApiRequest.findIndex(el => el.code === classCode);
          this.mktuSelectedDataFromPreviosApiRequest.splice(index, 1);
        } else {
          this.mktuSelectedDataFromPreviosApiRequest.push(this.mktuBySearchMobileTempArray[classIndex]);
        }
        break;
      case 'legal':
        this.legalMobileTempArray[classIndex].checkedMobile = !!$event.target.checked;
        this.sendIntercomData({event: 'selectOkvd', okved: classItem.code || classItem.number});
        if (!$event.target.checked && classCode) {
          const index = this.okvedSelectedDataFromPreviosApiRequest.findIndex(el => el.code === classCode);
          this.okvedSelectedDataFromPreviosApiRequest.splice(index, 1);
        } else {
          this.okvedSelectedDataFromPreviosApiRequest.push(this.legalMobileTempArray[classIndex]);
        }
        break;
      default:
        console.warn('Не передан тип класса!');
    }
  }

  applyMobileFilterByClasses(withoutSave?) {

    if (!withoutSave) {
      this.okvedMappedResponse = JSON.parse(JSON.stringify(this.okvedMobileTempArray));
      this.mktuMappedResponse = JSON.parse(JSON.stringify(this.mktuMobileTempArray));
      this.legalMappedResponse = JSON.parse(JSON.stringify(this.legalMobileTempArray));
    } else {
      this.okvedSelectedDataFromPreviosApiRequest = JSON.parse(JSON.stringify(this.okvedSelectedDataFromPreviosApiRequestCopy));
      this.mktuSelectedDataFromPreviosApiRequest = JSON.parse(JSON.stringify(this.mktuSelectedDataFromPreviosApiRequestCopy));
    }

    this.okvedMappedResponse.forEach((oItem) => {
      if (oItem.checkedMobile) {
        this.addOkvedToSearch(oItem, true);
      }
    });

    this.okvedSelectedDataFromPreviosApiRequest.forEach((oItem) => {
      if (oItem.checkedMobile) {
        this.addOkvedToSearch(oItem, true);
      }
    });

    this.mktuMappedResponse.forEach((mItem) => {
      if (mItem.checkedMobile) {
        this.addMktuToSearch(mItem, false, true);
      }
    });

    this.mktuSelectedDataFromPreviosApiRequest.forEach((mItem) => {
      if (mItem.checkedMobile) {
        this.addMktuToSearch(mItem, false, true);
      }
    });

    this.isShowSearchPopup = false;
    this.openPopup.emit(this.isShowSearchPopup);
    this.query = this.innOgrnQuery = this.legalName = '';
    this.mktuBySearchMobileTempArray = [];
    this.okvedBySearchMobileTempArray = [];
    this.okvedSelectedDataFromPreviosApiRequest = [];
    this.okvedSelectedDataFromPreviosApiRequestCopy = [];
    this.mktuSelectedDataFromPreviosApiRequest = [];
    this.mktuSelectedDataFromPreviosApiRequestCopy = [];
    this.legalMobileTempArray = [];

    this.changeSearchPopupView('all');
  }

  clearAddedClasses() {
    for (let i = this.okvedSearchArray.length - 1; i >= 0; i--) {
      this.deleteNumberFromSearch('okved', this.okvedSearchArray[i]);
    }
    for (let i = this.mktuSearchArray.length - 1; i >= 0; i--) {
      this.deleteNumberFromSearch('mktu', this.mktuSearchArray[i]);
    }
  }

  allMobileSelecting(selectedAll, data, mktuAndOkved?: boolean) {
    if (mktuAndOkved) {
      this.mktuMobileTempArray.map(i => {
        i.checkedMobile = selectedAll;
        return i;
      });
      this.okvedMobileTempArray.map(i => {
        i.checkedMobile = selectedAll;
        return i;
      });
    } else {
      data.map(i => {
        i.checkedMobile = selectedAll;
        return i;
      });
    }
  }

  // Убрать скрол под модалкой с фильтром
  // makeBodyHidden() {
  //   if (this.isShowSearchPopup === true) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'visible';
  //   }
  // }

  closeModal() {
    this.isShowSearchPopup = false;
    this.openPopup.emit(this.isShowSearchPopup);
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  closePopupMobile() {
    this.applyMobileFilterByClasses(true);
    this.isShowSearchPopup = false;
    this.openPopup.emit(this.isShowSearchPopup);
  }

  isSearchAllowed(text) {
    if (text !== '') {
      this.checkTradeMarks();
      this.searchError = false;
    } else {
      this.searchError = true;
    }
  }

  isSearchAllowedMobile(text) {
    if (text !== '') {
      this.checkTradeMarksForAppSearch();
      this.searchError = false;
    } else {
      this.searchError = true;
    }
  }

  public sendIntercomData(params): void {
    this.intercomEventsService.push(params);
  }

  addCountryToSearch(item) {
    if (!this.countriesSearchArray.includes(item)) {
      item.selected = true;
      item.checked = true;
      this.countriesSearchArray.push(item);
    } else {
      item.selected = false;
      item.checked = false;
      this.deleteNumberFromSearch('countries', item);
    }
  }

  clearAllCountriesSelected() {
    if (this.countriesSearchArray.length > 1) {
      for (let i = this.countriesSearchArray.length - 1; i >= 0; i--) {
        this.deleteNumberFromSearch('countries', this.countriesSearchArray[i]);
      }
    }

    this.initialCountiesSearch();
  }

  addCountriesSelected(organization): void {
    this.countries.forEach(country => {
      if (country.organizations?.length) {
        if (country.organizations.includes(organization)) {
          if (!this.countriesSearchArray.includes(country) && !country.checked) {
            country.selected = true;
            this.countriesSearchArray.push(country);
          } else if (this.countriesSearchArray.includes(country) && !country.checked) {
            country.selected = false;
            this.deleteNumberFromSearch('countries', country);
          }
        }
      }
    });
  }

  addSearchStrAtrCountryCode(): void {
    for (const country of this.countries) {
      country.searchStr = `${country.code} ${country.name}`;
    }
  }

  selectFilter(event): void {
    this.filterCountryList = [];
    for (const country of this.countries) {
      if (
        country.searchStr
          .toLowerCase()
          .indexOf(event.target.value.toLowerCase()) > -1
      ) {
        this.filterCountryList.push(country);
      }
    }
  }
}
