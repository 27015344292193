<div class="corp-history" *ngIf="state$ | async as state">
  <section *ngIf="!hidePageMobile" class="section-position">
    <div class="container container--header">
      <h1>История и отчеты</h1>
    </div>

    <div class="data-loader" *ngIf='state.isLoadingPage'>
      <app-spinner></app-spinner>
    </div>

    <div class="load-error" *ngIf="state.error">
      Ошибка при загрузке истории поиска
    </div>
  </section>
  <ng-container *ngIf='!state.isLoadingPage'>
    <section *ngIf="!hidePageMobile" class="section-position">
      <div class="container control">
        <button class="control__btn " (click)="changeTab('searches')"
                [class.control__btn--active]="state.currentTab === 'searches'">История поисков<span
          class="control__count">{{ state.totalCountSearchHistory }}</span>
        </button>
        <button class="control__btn " (click)="changeTab('reports')"
                [class.control__btn--active]="state.currentTab === 'reports'">Сохраненные отчеты<span
          class="control__count">{{ state.totalCountReports }}</span>
        </button>
      </div>
    </section>

    <section class="section-position section-position--bg section-position--main">
      <div class="container container--bg">
        <div class="data">
          <app-corp-history-searches
            [downloadingIdList]="state.downloadingIDList"
            [state]='state'
            [filterValue]="state.currentTab === 'searches' ? state.filtersValueHistory : state.filtersValueReports"
            [layout]='layout$ | async'
            [viewMode]="state.currentTab"
            [items]="state.currentTab === 'searches' ? state.searches : state.reports"
            [users]="state.currentTab === 'searches' ? state.usersSearchHistory : state.usersReports"
            [currentPage]="+currentPage"
            (downloadReport)="onDownloadReport($event)"
            (openReport)="onOpenReport($event)"
            (changePage)="changePage($event)"
            (hideTop)="hideMobileWindow($event)"
            (changeFilters)='applyFilters($event)'
          ></app-corp-history-searches>
        </div>
      </div>
    </section>
  </ng-container>

  <app-expert></app-expert>

</div>
