import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-add-doc',
  templateUrl: './add-doc.component.html',
  styleUrls: ['./add-doc.component.scss']
})
export class AddDocComponent implements OnInit {

  @Output() modalEvent = new EventEmitter();

  selects = [
    {
      name: 'Документы по претензии',
      level: 1,
      isSelectable: true,
    },
    {
      name: 'Доказательства для судебного дела',
      level: 1,
      isSelectable: true,
    },
    {
      name: 'Процессуальные документы истца',
      level: 2,
      isSelectable: false,
    },
    {
      name: 'Иск',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Ходатайство об ознакомлении',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Ходатайство о назначении экспертизы',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Письменные пояснения на отзыв',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Процессуальные документы ответчика',
      level: 2,
      isSelectable: false,
    },
    {
      name: 'Отзыв на иск',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Апелляционная жалоба',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Судебные акты',
      level: 2,
      isSelectable: false,
    },
    {
      name: 'Определение о назначении экспертизы',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Определение о принятии иска',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Определение о принятии иска',
      level: 3,
      isSelectable: true,
    },
    {
      name: 'Копия материалов дела',
      level: 1,
      isSelectable: true,
    },
  ];
  selected = 'Выберите принадлежность документа';
  showSelectList = false;
  error = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalEvent.emit();
  }

  onSelect(sel): void {
    this.selected = sel.name;
    this.showSelectList = false;
  }

  trimString(str, count) {
    if (window.innerWidth < 400) {
      return str?.length > count ? str.slice(0, count) + '...' : str;
    }
    return str;
  }
}
