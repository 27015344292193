import { DictionaryItem } from './dictionary-item-model';

/**
 * Словарь ключей значений
 */
export class Dictionary<TKey, TValue> {

  private items: DictionaryItem<TKey, TValue>[];

  constructor() {
    this.items = [];
  }

  /**
   * Добавляет элемент в словарь
   * @param item DictionaryItem<TKey, TValue>
   * @returns void
   */
  public add(item: DictionaryItem<TKey, TValue>): void {
    if (item == null) {
      return;
    }

    // Проверка уникальности ключей
    const isContainsItem = this.items.find(x => x.key === item.key);
    if (isContainsItem != null && isContainsItem.key === item.key) {
      console.error(`item with key - ${item.key} has been added to dictionary before -
            it has value ${isContainsItem.value}. Change key and try again`);
      return;
    }

    this.items.push(item);
  }

  /**
   * Получает элемент из словаря
   * @param key TKey
   * @returns TValue
   */
  public getItem(key: TKey): TValue {
    if (key == null) {
      return;
    }
    return this.items.find(x => x.key === key).value;
  }

  /**
   * Удаляет элемент из словаря по ключу
   * @param key TKey
   * @returns void
   */
  public remove(key: TKey): void {
    if (key == null) {
      return;
    }
    const find = this.items.findIndex(x => x.key === key);
    this.items.slice(find, 1);
  }

  /**
   * Возвращает все занные словаря
   * @returns DictionaryItem<TKey, TValue>[]
   */
  public getAll(): DictionaryItem<TKey, TValue>[] {
    return this.items;
  }
}
