import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {RequestCheckLabelApiService} from './request-check-label.api.service';
import {IOrg} from '../../shared/models/main.interfaces';
import {UserProfileService} from '../../../../../shared/services/user-profile.service';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import { InterestService } from '../../../../../shared/services/interest.service';
import {APP_ROUTES_NAMES} from '@web/core/models/app-routes-names';

@Injectable({
  providedIn: 'root'
})

export class RequestCheckLabelService {
  isLoading = false;
  step = 0; // Шаг заявки
  organizations = []; // Список организаций пользователя
  rate; // Тариф
  labels = []; // Загруженные этикетки
  tariffID; // id тарифа
  mktu = []; // Классы мкту (массив объектов)
  owner = {
    name: '',
    inn: '',
    ogrn: '',
    shortName: '',
    address: '',
    director: '',
  }; // Правообладатель
  product = ''; // Товары или продукты
  comment = ''; // Комментарий
  tags = []; // Массив тегов
  responseID; // id заявки
  paymentType: 'online' | 'cash' | 'act'; // тип оплаты
  paymentSum; // Сумма к оплате с учетом скидки
  payer; // Плательщик
  draft; // Черновик
  optionsMKTU = {
    onlyClassSelect: true,
    search: 'mktu',
    selectedMktu: []
  }; // Параметры для добавления МКТУ
  validation = false; // Валидация форм
  tagsCodes = []; // Коды тегов


  constructor(
    private httpService: HttpService,
    private checkLabelApiService: RequestCheckLabelApiService,
    private userProfileService: UserProfileService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private interestService: InterestService
  ) {
  }

  createRequest() {
    this.isLoading = true;
    this.checkLabelApiService.requestCheckLabelStage1().subscribe(data => {
      if (data) {
        this.responseID = data.responseID;
        this.getRates();
      }
    });
  }

  /**
   * Запрос на восстановление заявки по id
   * @param responseID: id заявки
   */
  loadRequestIfNeeded(responseID: string): void {
    if (!this.responseID) {
      this.loadRequest(responseID);
    }
  }

  /**
   * Запрос на восстановление заявки по id
   * @param responseID: id заявки
   */
  loadRequest(responseID: string) {
    const params = {
      isDraft: true
    };
    this.isLoading = true;
    this.checkLabelApiService.requestContractGetDetail(params, responseID)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(data => {
        this.draft = data.task;
        this.responseID = data.responseID;
        this.getRates();
        if (this.draft) {
          this.parseDraft();
        }
      });
  }

  /**
   * Восстановление из черновика
   */
  parseDraft() {
    this.labels = this.draft.labels;
    this.tags = this.draft.tags;
    this.owner = this.draft.owner;
    this.product = this.draft.product;
    this.comment = this.draft.comment;
    this.optionsMKTU.selectedMktu = [...new Set(this.draft.info.find(item => item.title === 'Классы МКТУ').text.split(', ').map(item => +item))];
    this.tags = this.draft.tags;
    switch (this.draft.lastStage) {
      case 3:
        this.step = 2;
        break;
      case 4:
        this.router.navigate([APP_ROUTES_NAMES.CASES]);
        break;
    }
  }

  getOrganizations() {
    this.userProfileService.getOrganizations().subscribe((data: IOrg) => {
      if (data && data.organizations?.length) {
        this.isLoading = false;
        this.organizations = data.organizations;
        if (!this.draft) {
          this.step = 1;
        }
      }
    });
  }

  getRates() {
    this.httpService.get({path: 'request/tariff', params: {responseID: this.responseID}}).subscribe((rates: any) => {
      if (rates.tariff) {
        this.rate = rates.tariff[0];
        this.tariffID = this.rate.id;
        if (this.draft) {
          this.paymentSum = this.rate.sumDiscount * this.draft.labels.length;
        }
        this.getOrganizations();
      }
    });
  }

  /**
   * Отправка данных с первого шага
   */
  sendFirstStepData() {
    const params = {
      responseID: this.responseID,
      tariffID: this.tariffID,
      labels: this.labels,
      owner: this.owner,
      tags: this.tagsCodes,
      product: this.product,
      mktu: this.mktu,
      comment: this.comment,
      interestData: this.interestService.addInterestData()
    };
    this.step = 2;
    this.isLoading = true;
    return this.checkLabelApiService.requestCheckLabelStage3(params)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(data => {
        this.responseID = data.responseID;
        if (!this.activatedRoute.snapshot.queryParams.id) {
          this.router.navigate(['/request-check-label'], {queryParams: {id: this.responseID}});
        }
      });
  }

  /**
   * Выбор типа оплаты
   * @param type: тип оплаты
   */
  setPaymentType(type: 'online' | 'cash' | 'act') {
    this.paymentType = type;
  }

  /**
   * Отправка данных об оплате
   * @param dataYa: данные от Яндекс
   */
  goSuccessStage(dataYa) {
    this.isLoading = true;

    const query = {
      responseID: this.responseID,
      inn: this.payer?.TIN || this.payer?.inn,
      ogrn: this.payer?.PSRN || this.payer?.ogrn,
      name: this.payer?.name || this.payer?.fullName || this.payer,
      address: this.payer?.address || this.payer?.legalAdress,
      sum: this.paymentSum,
      type: this.paymentType,
      yandexResponse: dataYa
    };
    return this.checkLabelApiService.requestCheckLabelStage4(query)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe((data) => {
        this.responseID = data.responseID;
        this.step = 3;
      });
  }

  /**
   * Выбор плательщика
   * @param payerData: плательщик
   */
  setPayer(payerData) {
    this.payer = payerData;
    if (this.payer?.ogrn?.length && typeof this.payer?.ogrn === 'number') {
      this.payer.ogrn.toString();
    } else if (this.payer?.ogrn?.length && typeof this.payer?.ogrn === 'string') {
      this.payer.ogrn = this.payer.ogrn.replace(/\s/g, '');
    }
  }
}
