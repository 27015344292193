import { Component, Input, OnInit } from '@angular/core';
import {DownloadFilesService} from '../../../../../../../shared/services/download-files.service';

@Component({
  selector: 'app-item-files',
  templateUrl: './item-files.component.html',
  styleUrls: ['../cases-item.component.scss']
})
export class ItemFilesComponent implements OnInit {

  pattern = '([^\\/]*$)';

  constructor(
    readonly downloadFiles: DownloadFilesService
  ) {
  }

  @Input() files;

  ngOnInit(): void {
    console.log(this.files, 'files child');
  }
}
