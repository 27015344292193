import { Injectable } from '@angular/core';
import { UraitApiService } from '@web/core/services/urait-api.service';
import { HistoryResponseInterface } from '@web/features/corp/corp-history/models/history-response.interface';

@Injectable()
export class CorpHistoryApiService {
  private readonly url = 'searches';

  constructor(
    private apiService: UraitApiService
  ) {
  }

  getSearchHistory(params): Promise<HistoryResponseInterface> {
    return this.apiService.get(this.url, { params: {...params} });
  }
}
