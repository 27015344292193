import {
  Input,
  Output,
  Component,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Tariff } from '../../models/rates.interfaces';
import { REQUEST_TYPES } from '@web/core/models/user.interface';

@Component({
  selector: 'app-tariff-card',
  templateUrl: './tariff-card.component.html',
  styleUrls: ['./tariff-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TariffCardComponent {
  @Input() tariff: Tariff;
  @Input() id: string = '';
  @Input() active: boolean;
  @Input() mobile: boolean;
  @Input() request: REQUEST_TYPES;
  @Output() select = new EventEmitter<Tariff['id']>();
}