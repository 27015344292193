import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-tracks',
  templateUrl: './item-tracks.component.html',
  styleUrls: ['../cases-item.component.scss']
})
export class ItemTracksComponent implements OnInit {

  constructor() {
  }

  @Input() $tracks;
  @Input() infoItem;

  trackColors = ['yellow', 'blue', 'purple', 'dark-green', 'green', 'green', 'green'];

  ngOnInit(): void {
  }

}
