export enum Brand {
  '1C-START' = '1C-START',
  'MTS-KASSA' = 'MTS-KASSA',
  'IP-ID' = 'IP-ID',
  'GARDIUM-ONLINE' = 'GARDIUM-ONLINE',
  'BRAND-SEARCH' = 'BRAND-SEARCH',
  'ALFA' = 'ALFA',
  'IPID-PRO' = 'IPID-PRO',
  'X5' = 'X5',
  'OPEN' = 'OPEN',
  'SBER' = 'SBER',
  'YOOKASSA' = 'YOOKASSA',
  'TOCHKA' = 'TOCHKA'
}
