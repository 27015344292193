import { patch } from '@ngxs/store/operators';
import { PaymentActions } from './payment.actions';
import { Injectable } from '@angular/core';
import { AuthState } from '@web/core/states/auth.state';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { catchError, filter, map, repeat, take } from 'rxjs';
import { PaymentService } from '../services/payment.service';
import { OrganizationState } from '@web/core/states/organization.state';
import { NgxsForm, UserInterface } from '@web/core/models/user.interface';
import { OrganizationInterface } from '@web/core/models/organization.interface';
import { DEFAULT_PAYMENT_TABS, PAYMENT_TABS_WITH_ACT } from '../types/payment.defaults';
import { PayerForm, PaymentTab, PAYMENT_STATUS, PAYMENT_TYPE } from '../types/payment.types';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { environment } from '../../../../app-ipid/src/environments/environment';

interface PaymentStateModel {
  tabs: PaymentTab[];
  showOptions: boolean;
  payer: string | null;
  currentTab: PAYMENT_TYPE;
  searchedPayer: any | null;
  form: NgxsForm<PayerForm>;
  idApplication: string | null;
  yaLastPaymentId: string | null;
  confirmation_token: string | null;
  paymentStatus: PAYMENT_STATUS | null;
}
const PAYMENT_STATE_TOKEN = new StateToken<PaymentStateModel>('payment');
const PAYMENT_INITIAL_STATE: PaymentStateModel = {
  payer: null,
  showOptions: false,
  idApplication: null,
  paymentStatus: null,
  searchedPayer: null,
  yaLastPaymentId: null,
  confirmation_token: null,
  tabs: DEFAULT_PAYMENT_TABS,
  currentTab: PAYMENT_TYPE.OFFLINE,
  form: { model: {
    INN: '', sum: '0 ₽', OGRN: '',  name: '',  address: '',
  } },
};

@State({
  name: PAYMENT_STATE_TOKEN,
  defaults: PAYMENT_INITIAL_STATE,
})
@Injectable()
export class PaymentState {
  @SelectSnapshot(AuthState.getUser) private static user: UserInterface;

  constructor(
    private readonly store: Store,
    private readonly paymentService: PaymentService,
  ) { }

  public static currentPayer() {
    return createSelector(
      [PaymentState.payer, OrganizationState.organizations, PaymentState.searchedPayer],
      (payer: string | null, organizations: OrganizationInterface[], searchedPayer: any) => {
        if (payer === 'another') {
          return searchedPayer;
        }
        return  payer ? organizations.find(item => item.id === payer) : null;
      }
    );
  }

  @Selector()
  public static paymentStatus(state: PaymentStateModel) {
    return state.paymentStatus;
  }

  @Selector()
  public static idApplication(state: PaymentStateModel) {
    return state.idApplication;
  }

  @Selector()
  public static yaLastPaymentId(state: PaymentStateModel) {
    return state.yaLastPaymentId;
  }

  @Selector()
  public static confirmation_token(state: PaymentStateModel) {
    return state.confirmation_token;
  }

  @Selector()
  public static payer(state: PaymentStateModel) {
    return state.payer;
  }

  @Selector()
  public static currentTab(state: PaymentStateModel) {
    return state.currentTab;
  }

  @Selector()
  public static showOptions(state: PaymentStateModel) {
    return state.showOptions;
  }

  @Selector()
  public static searchedPayer(state: PaymentStateModel) {
    return state.searchedPayer;
  }

  @Selector()
  public static tabs() {
    return environment.BRAND === 'X5'
      ? PAYMENT_TABS_WITH_ACT
      : DEFAULT_PAYMENT_TABS;
  }

  @Action(PaymentActions.SetApplicationId)
  public setApplicationId(
    { setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.SetApplicationId,
  ) {
    setState(patch<PaymentStateModel>({ idApplication: payload }));
  }

  @Action(PaymentActions.SetCurrentTab)
  public setCurrentTab(
    { setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.SetCurrentTab,
  ) {
    setState(patch<PaymentStateModel>({ currentTab: payload }));
  }

  @Action(PaymentActions.SetCurrentPayer)
  public setCurrentPayer(
    { getState, setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.SetCurrentPayer,
  ) {
    setState(patch<PaymentStateModel>({ payer: payload, showOptions: false }));
    const currentPayer = this.store.selectSnapshot(PaymentState.currentPayer());
    window.localStorage.setItem('payer', JSON.stringify(currentPayer));
    setState(patch<PaymentStateModel>({ form: { model: {
      INN: currentPayer?.TIN ? currentPayer?.TIN : '',
      OGRN: currentPayer?.PSRN ? currentPayer?.PSRN : '',
      name: currentPayer?.name ? currentPayer?.name : '',
      address: currentPayer?.legalAdress ? currentPayer?.legalAdress : '',
      sum: getState().form.model.sum,
    } } }));
  }

  @Action(PaymentActions.SetShowOptions)
  public setShowOptions(
    { getState, setState }: StateContext<PaymentStateModel>,
  ) {
    setState(patch<PaymentStateModel>({ showOptions: !getState().showOptions }));
  }

  @Action(PaymentActions.CreateYandexPayment)
  public createYandexPayment(
    { getState, dispatch }: StateContext<PaymentStateModel>,
  ) {
    if (!getState().yaLastPaymentId) {
      return this.paymentService.createYandexPayment(getState().idApplication).pipe(
        map(response => dispatch(new PaymentActions.CreateYandexPaymentSuccess(response))),
        catchError(() => dispatch(new PaymentActions.CreateYandexPaymentFail())),
      );
    }
  }

  @Action(PaymentActions.CreateYandexPaymentSuccess)
  public createYandexPaymentSuccess(
    { setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.CreateYandexPaymentSuccess,
  ) {
    (payload?.confirmation?.confirmation_token && payload?.id)
      ? setState(patch<PaymentStateModel>({ yaLastPaymentId: payload.id, confirmation_token: payload.confirmation.confirmation_token }))
      : console.error('В ответе нет confirmation_token и/или payment_id');
  }

  @Action(PaymentActions.CheckPaymentStatus)
  public checkPaymentStatus(
    { getState, dispatch }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.CheckPaymentStatus,
  ) {
    return this.paymentService.checkPaymentStatus(getState().idApplication, payload).pipe(
      repeat({ delay: 30 * 1000 }),
      filter((res) => res.status === PAYMENT_STATUS.SUCCESSED || res.status === PAYMENT_STATUS.CANCELED),
      take(1),
      map(response => dispatch(new PaymentActions.CheckPaymentStatusSuccess(response))),
      catchError(() => dispatch(new PaymentActions.CheckPaymentStatusFail())),
    );
  }

  @Action(PaymentActions.CheckPaymentStatusSuccess)
  public checkPaymentStatusSuccess(
    { setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.CheckPaymentStatusSuccess,
  ) {
    setState(patch<PaymentStateModel>({ paymentStatus: payload.status }));
  }

  @Action(PaymentActions.SetPaymentAmount)
  public setPaymentAmount(
    { getState, setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.SetPaymentAmount,
  ) {
    setState(patch<PaymentStateModel>({ form: { model: { ...getState().form.model, sum: `${payload} ₽` }} }));
  }

  @Action(PaymentActions.SetSearchedPayer)
  public setSearchedPayer(
    { setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.SetSearchedPayer,
  ) {
    setState(patch<PaymentStateModel>({ searchedPayer: payload }));
  }

  @Action(PaymentActions.ClearPaymentState)
  public clearPaymentState(
    { setState }: StateContext<PaymentStateModel>,
    { payload }: PaymentActions.SetSearchedPayer,
  ) {
    setState(patch<PaymentStateModel>(PAYMENT_INITIAL_STATE));
  }
}
