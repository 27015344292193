import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IOwner } from '../request-patent/request-patent.component';
import { HttpService } from '../../../../shared/services/http.service';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../../../../app-search/src/app/services/search.service';
import { Subject } from 'rxjs';
import { InterestService } from '../../../../../../../shared/services/interest.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';


@Component({
  selector: 'app-owner',
  templateUrl: './owner-form.component.html',
  styleUrls: ['./owner-form.component.scss']
})
export class OwnerFormComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @Input() startData: IOwner;
  @Input() requestType;
  @Input() isDisabled;
  @Output() nextStepEvent = new EventEmitter();
  formOwner: FormGroup;
  idApplication;
  typeNotSelected;
  fullPatentee = [];
  tempPatentee = {patentee: undefined, index: undefined};
  viewSelectContainer = true; // отображать выпадающий блок после ввода ИНН/ОГРН
  loadingOwner = false; // лоадер при получении владельца
  loading = false;
  patenteeError = [];
  patenteeCheckLoading = [];
  isMobile: boolean;
  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private searchService: SearchService,
    private interestService: InterestService,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.getIsMobile();
    window.addEventListener('resize', () => {
      this.getIsMobile();
    });
  }

  getIsMobile() {
    this.isMobile = window.innerWidth <= 768;

    console.log(this.isMobile);
  }

  /** Инициализируем форму оплаты и если заявка не новая, то заполняем данными
   */
  initForm(): void {
    this.formOwner = this.fb.group({
      type: [null, Validators.required],
      author: new FormArray([
        new FormControl(null, Validators.required),
      ]),
      patentee: new FormArray([
        new FormControl(null, Validators.required),
      ]),
    });
    if (this.startData?.type || this.startData?.author || this.startData?.patentee) {
      this.formOwner.get('type').setValue(this.startData.type);
      if (this.startData.author.length > 1) {
        this.startData.author.forEach((item, index) => {
          if (index !== 1) {
            this.addAuthor(true);
          }
        });
      }
      this.formOwner.get('author').setValue(this.startData.author);
      if (this.startData.patentee.length > 1) {
        this.startData.patentee.forEach((item, index) => {
          if (index !== 1) {
            this.addPatentee(true);
          }
        });
      }
      this.startData.patentee.forEach((item, index) => {
        if (typeof item === 'string') {
          this.patentee.at(index).patchValue(item);
        } else {
          this.fullPatentee[index] = item;
          this.patentee.at(index).patchValue(this.fullPatentee[index].TIN);
        }
      });
      // this.formOwner.get('patentee').setValue(this.startData.patentee);
      // this.patentee.at(index).patchValue(this.fullPatentee[index].TIN);
    }
    if (this.startData?.idApplication) {
      this.idApplication = this.startData.idApplication;
    }
  }

  get author(): FormArray {
    return this.formOwner.get('author') as FormArray;
  }

  get patentee(): FormArray {
    return this.formOwner.get('patentee') as FormArray;
  }

  /** Добавление поля автора
   */
  addAuthor(withoutValid?: boolean): void {
    let valid = true;
    this.author.controls.forEach((item, index) => {
      if (item.invalid) {
        valid = false;
        item.markAsTouched();
      }
    });
    if (valid || withoutValid) {
      this.author.push(new FormControl(null, Validators.required));
    }
  }

  /** Добавление поля патентообладателя
   */
  addPatentee(withoutValid?: boolean): void {
    let valid = true;
    this.patentee.controls.forEach((item, index) => {
      if (item.invalid) {
        valid = false;
        item.markAsTouched();
      }
    });
    if (valid || withoutValid) {
      this.patentee.push(new FormControl(null, Validators.required));
    }
  }

  removeAuthor(index): void {
    this.author.removeAt(index);
  }

  removePatentee(index): void {
    this.patentee.removeAt(index);
    this.fullPatentee.splice(index, 1);
  }

  nextStep(): void {
    if (this.checkDisableNextStep()) {
      if (!this.formOwner.get('author').value[0]) {
        this.author.controls[0].markAllAsTouched();
      }
      if (!this.formOwner.get('patentee').value[0]) {
        this.patentee.controls[0].markAllAsTouched();
      }
      if (!this.formOwner.get('type').value) {
        this.typeNotSelected = true;
      }
    } else {
      this.loading = true;
      if (this.requestType !== 'rumcc') {
        const patenteeResult = this.formOwner.get('patentee').value.slice();
        this.fullPatentee.forEach((item, index) => {
          if (item) {
            patenteeResult[index] = item;
          }
        });
        this.httpService.post({path: 'request/load',
          body: {
            responseID: this.idApplication,
            stage: 1,
            type: this.requestType,
            patenttype: this.formOwner.get('type').value,
            author: this.formOwner.get('author').value,
            patentee: patenteeResult,
            interestData: this.interestService.addInterestData()
          }}).subscribe((data: any) => {
          if (data?.responseID) {
            this.idApplication = data.responseID;

            // добавление id заявки в get параметры

            const refresh = window.location.protocol + '//' + window.location.host + window.location.pathname + `?type=${this.requestType}&id=${this.idApplication}`;
            window.history.pushState({path: refresh}, '', refresh);

            /** Создаем массив плательщиков для 4 шага(оплата онлайн)
             */
            // const company = [
            //   {
            //     id: 0,
            //     name: 'Выберите плательщика'
            //   }
            // ];
            // patenteeResult.forEach((item, index) => {
            //   company.push({
            //     id: index + 1,
            //     name: item});
            // });
            // company.push({
            //   id: company.length,
            //   name: 'Указать другого плательщика'});

            // const company = [
            //   {
            //     id: 0,
            //     name: 'Выберите плательщика'
            //   },
            //   {
            //     id: 1,
            //     name: 'Указать другого плательщика'
            //   }
            // ];
            const map = new Map()
              .set('ruum', 'Полезная модель')
              .set('rupat', 'Изобретение')
              .set('rude', 'Промышленный образец')
              .set('rusw', 'Программа для ЭВМ')
              .set('rudb', 'База данных')
              .set('unknown', 'Не знаю')
              .set('unknown', 'Не знаю');

            this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_TM_APPLY_SENT, itemType: map.get(this.formOwner.get('type').value), typeIP: this.requestType });
            this.nextStepEvent.emit({
              type: this.formOwner.get('type').value,
              author: this.formOwner.get('author').value,
              patentee: patenteeResult,
              // company,
              idApplication: this.idApplication,
              interestData: this.interestService.addInterestData()
            });
          }
        });
      }
    }
  }

  showPatenteeError(index): boolean {
    return (this.patentee.controls[index].invalid &&
      this.patentee.controls[index].touched);
  }

  showAuthorError(index): boolean {
    return (this.author.controls[index].invalid &&
      this.author.controls[index].touched);
  }

  /** Проверка невалидности формы
   */
  checkDisableNextStep(): boolean {
    return !this.formOwner.get('type').value
      || !this.formOwner.get('patentee').value[0]
      || !this.formOwner.get('author').value[0]
      || !(this.patenteeError.findIndex(el => el === true) === -1);
  }

  addFullPatentee(index: number, patentee: any): void {
    this.fullPatentee[index] = patentee;
  }

  /** Получение полного обьекта патентообладателя (юр. лицо)
   */
  getFullPatentee(id, index): void {
    const query = {
      id,
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits[0]?._source && id === this.patentee.controls[index].value) {
        this.tempPatentee.patentee = data.hits.hits[0]._source;
        this.tempPatentee.index = index;
        this.viewSelectContainer = true;
      }
    });
  }

  checkValidInnOgrn(value): boolean {
    return value && !isNaN(+value) && (value.length === 10 || value.length === 12 || value.length === 13 || value.length === 15);
  }

  patenteeChanged(value, index): void {
    this.patenteeError[index] = false;
    if (this.viewSelectContainer) {
      this.tempPatentee = {patentee: undefined, index: undefined};
      if (this.fullPatentee[index]) {
        this.fullPatentee[index] = undefined;
      }
      if (value.length > 2) {
        this.getFullPatentee(value, index);
      }
    } else {
      this.viewSelectContainer = true;
    }
  }

  patenteeSelected(index): void {
    this.fullPatentee[index] = this.tempPatentee.patentee;
    this.tempPatentee.patentee = undefined;
    this.tempPatentee.index = undefined;
    if (!this.checkValidInnOgrn(this.formOwner.get('patentee').value[index])) {
      this.viewSelectContainer = false;
      this.patentee.at(index).patchValue(this.fullPatentee[index].TIN);
    }
  }

  getDynamicMarginForLegalName(index: number): any {
    switch (this.formOwner.get('patentee').value[index].length) {
      case 10:
        return {left: '112px'};
      case 12:
        return {left: '130px'};
      case 13:
        return {left: '138px'};
      case 15:
        return {left: '154px'};
    }
  }

  cancelRatentee() {
    this.tempPatentee = {patentee: undefined, index: undefined};
  }

  checkCurrentOrganization(ogrn, index) {
    this.patenteeError[index] = false;
    this.patenteeCheckLoading[index] = true;
    const reqData = {
      PSRN: ogrn
    };
    this.httpService.get({path: 'organizations/check', params: reqData}).subscribe((data: any) => {
      this.patenteeCheckLoading[index] = false;
      this.patenteeError[index] = !data.passed;
      if (data.passed) {
        this.patenteeSelected(index);
      } else {
        this.tempPatentee = {patentee: undefined, index: undefined};
      }
    });
  }

  public sendPatentEvent(type: string): void {
    console.log(type);
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_ITEM, itemType: type });
  }

  ngOnDestroy(): void {
  }

}
