import { Injectable } from '@angular/core';
import { UraitApiService } from '@web/core/services/urait-api.service';
import { CredentialsInterface, UserInterface, UserLoginInterface } from '@web/core/models/user.interface';

@Injectable()
export class AuthApiService {
  private readonly url = 'user';

  constructor(private apiService: UraitApiService) {}

  getUser(): Promise<{userInfo: UserInterface}> {
    return this.apiService.get(`${this.url}/info`);
  }

  login(credentials: CredentialsInterface, brand: string): Promise<UserLoginInterface> {
    return this.apiService.post(
      `${this.url}/${credentials.code ? 'ssoLogin' : 'login'}`,
      { body: credentials },
      { params: { brand } }
    );
  }

  loginInfo(brand: string): Promise<UserLoginInterface> {
    return this.apiService.get(
      `${this.url}/login-info`, { params: { brand }}
    );
  }
}
