<div class="card">
  <div class="card__header card-header">
    <p class='card-header__title'>
      {{trimmedTitle}}
      <span class='card-header__full-title'>{{fullTitle}}</span>
    </p>
    <span *ngIf="prompt" class="prompt prompt__question">?
      <span class="prompt__hidden">
        <p>{{prompt}}</p>
      </span>
    </span>
  </div>
  <div class="card__body">
    <ng-content></ng-content>
  </div>
</div>
