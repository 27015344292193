import {
  Component,
  Input,
  ElementRef,
  HostListener,
  DoCheck,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { SearchService } from '../../../../../../app-search/src/app/services/search.service';
import { ICorpDataSourceType } from '../../interaces/corp-data-source.interface';
import { CorpSearchSaveReportService } from '../../services/corp-search-save-report.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CorpSearchByNameService } from '../../services/corp-search-by-name.service';
import { environment } from '../../../../../../app-ipid/src/environments/environment';
import {ISearchHistoryDetail} from '../../../../../../app-ipid/src/app/shared/models/search-history.interfaces';
import {CorpSearchByImageService} from '../../services/corp-search-by-image.service';
import {tap} from 'rxjs/operators';
import {CorpSearchApiService} from '../../services/corp-search-api.service';
import {HttpService} from '../../../../../../app-ipid/src/app/shared/services/http.service';

@Component({
  selector: 'app-corp-search-by-image',
  templateUrl: './corp-search-by-image.component.html',
  styleUrls: ['./corp-search-by-image.component.scss']
})
export class CorpSearchByImageComponent implements OnInit, DoCheck, OnDestroy {
  @Input() initialSearchHistory: ISearchHistoryDetail = null;
  @Input() initialSearchHistoryAction: 'show' | 'repeat' = null;

  @ViewChild('input') inputRef: ElementRef;
  image: File;
  fileName;
  tradeMarks;
  count;
  disabledButton = true;
  imageChangedEvent: any = '';
  croppedImageBinary;
  imageBody;
  isLoading = false;
  disableRisks = true;
  disableCountry = true;
  classListArray;
  public innerWidth: any;
  searchOptions = {
    style: 'appIpIdSearchPage',
    dynamicStylesForBrand: environment.SEARCH_STYLES,
    isSearchByImage: true,
  };
  visibleBorder = true;
  imageBase64;
  imageFile;

  constructor(
    private searchApi: SearchService,
    readonly corpSearchSaveService: CorpSearchSaveReportService,
    readonly corpSearchByNameService: CorpSearchByNameService,
    readonly corpSearchByImageService: CorpSearchByImageService,
    readonly corpSearchApiService: CorpSearchApiService,
    private httpService: HttpService,
    private http: HttpService
  ) {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.initialSearchHistory) {
      this.loadBySearchHistory(this.initialSearchHistory, this.initialSearchHistoryAction === 'show');
    } else {
      this.parseSearchParams();
    }
  }

  loadBySearchHistory(search: ISearchHistoryDetail, isShow: boolean) {
    this.http.post({path: `request/file`, body: {path: search.searchParameters.image.url, Base64: true}, responseType: 'json'})
      .subscribe((data: any) => {
        if (data?.binary?.length) {
          this.croppedImageBinary = data.binary;
          this.fileChangeEvent('data:image/jpeg;base64,'.concat(data.binary));
          const searchPayload = {
            mktuList: search.searchParameters.mktuList,
            okvedList: search.searchParameters.okvedList,
            countriesList: search.searchParameters.countriesList,
          };
          if (isShow) {
            this.tradeMarks = search.results.find(i => i.entity === 'trademark').result.hits.hits.map((obj, index) => ({...obj, __indexNumber: index}));
          } else {
            this.onSearch(searchPayload, false);
          }
        }
      });
  }

  private parseSearchParams() {
    if (localStorage.getItem('imagePath')?.length) {
      this.http.post({path: `request/file`, body: {path: localStorage.getItem('imagePath'), Base64: true}, responseType: 'json'})
        .subscribe((data: any) => {
          if (data?.binary?.length) {
            this.croppedImageBinary = data.binary;
            this.fileChangeEvent('data:image/jpeg;base64,'.concat(data.binary));
            const searchPayload = {
              mktuList: [],
              okvedList: [],
              countriesList: [{name: 'Российская Федерация', code: 'RU', organizations: ['СНГ', 'БРИКС']}]
            };
            this.onSearch(searchPayload, false);
          }
        });
    }
  }

  ngDoCheck() {
    if (document.body.style.position === 'fixed') {
      this.visibleBorder = true;
    }
  }

  onSelect(type: ICorpDataSourceType, selected: any[]) {
    this.corpSearchSaveService.onSelect(type, selected);
  }

  fileChangeEvent(event: any): void {
    this.corpSearchByImageService.fileSelected = true;
    this.imageChangedEvent = event.image || event;
    this.imageFile = event.file;
    localStorage.setItem('imageBase64', JSON.stringify(event));
    this.disabledButton = false;
  }

  onSearch(searchPayload, saveSearch = true) {
    if (searchPayload) {
      this.isLoading = true;

      const payload = {
        from: 0,
        size: 1000,
        gsNumber: searchPayload.mktuList.map(i => i.number || i),
        image: {
          filename: 'Test.jpeg',
          binary: this.croppedImageBinary,
        },
        // TODO: раскоментить когда доработают метод:
        // TODO: https://cf.9958258.ru/pages/viewpage.action?pageId=139430881
        // eaNumber: searchPayload.okvedList.map(i => i.code),
        // countryCodes: searchPayload.countriesList.map(i => i.code),
      };

      this.searchByImage(payload, saveSearch);
    }
  }

  searchByImage(payload?, saveSearch?) {
    if (payload) {
      this.searchApi.searchByImage(payload)
        .pipe(
          tap(data => saveSearch && this.uploadImage(payload, data, this.imageFile))
        ).subscribe(data => {
        if (data) {
          this.tradeMarks = data.hits.hits.map((obj, index) => ({...obj, __indexNumber: index}));
          this.count = data.took;
          this.isLoading = false;
          if (!saveSearch) {
            this.corpSearchApiService.objectsLoading = false;
          }
        }
      });
    }
  }

  uploadImage(payload, data, image) {
    const formData: FormData = new FormData();
    formData.append('file', image, image.name);
    this.httpService.post({path: 'request/file/add', body: formData, isFile: true})
      .subscribe((value: any) => {
        if (value) {
          localStorage.setItem('imagePath', value.path);
          this.corpSearchByImageService.saveSearch(payload, data, value.path);
        }
      });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBinary = event.base64.split(',')[1];
    this.imageBase64 = event.base64;
      // example with coordinates
      // rectangle: [this.croppedImage.imagePosition.x1, this.croppedImage.imagePosition.y1, this.croppedImage.imagePosition.x2, this.croppedImage.imagePosition.y2],
  }

  showPopup(event) {
    this.visibleBorder = event;
  }

  ngOnDestroy() {
    localStorage.removeItem('imageBase64');
    localStorage.removeItem('imagePath');
  }
}
