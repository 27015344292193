<div class="mktu-block" *ngIf="lastTrademark">
  <div class="mktu-block__block">
    <h3>МКТУ из
      <span *ngIf="lastTrademark._index === 'rutm'">товарного знака</span>
      <span *ngIf="lastTrademark._index === 'rutmap'">заявки</span>
      №{{lastTrademark._source.applicationString}} от
      <span *ngIf="lastTrademark._index === 'rutmap'; else tm">{{lastTrademark._source.applicationDate | date:'dd.MM.yyyy'}}</span>
      <ng-template #tm>{{lastTrademark._source.registrationDate | date:'dd.MM.yyyy'}}</ng-template>
    </h3>
  </div>
  <div class="mktu-block__block">
    <div class="mktu-block__img">
      <img  [src]="lastTrademark._source.imageUrl" alt="">
    </div>
    <div class="mktu-block__text">
      <div class="mktu-block__row">
        <p class="mktu-block__text--gray">Классы МКТУ</p>
        <div class="mktu-block__classes">
          <div class="mktu-block__class-item" *ngFor="let item of lastTrademark._source.goodsServices">
            <div>
              {{item.classNumber}} <span class="prompt__hidden"><p>{{item.gsDescription | trimString: 250}}</p></span>
            </div>
          </div>
        </div>
      </div>
      <div class="mktu-block__row">
        <p class="mktu-block__text--gray">Правообладатель</p>
        <div>
          <p id="ownerName">{{lastTrademark._source.applicantName}}</p>
        </div>
      </div>
    </div>
  </div>
</div>