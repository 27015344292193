import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { PopularServicesService } from '../popular-services.service';
import { Subject } from 'rxjs';
import { IServiceDetails, IServiceRates } from '../../../shared/models/main.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent implements OnInit, OnDestroy {

  packages = ['Дешевле не бывает', 'Все включено', 'Форсаж'];

  selectedPackage = this.packages[1];
  four = false;
  three = true;
  $details: IServiceRates;
  $rates;
  $initial: IServiceDetails;
  noPackages = false;
  fastRegistration = false;
  showLoader = true;
  serviceId: string;
  rateButton;
  private readonly onDestroy = new Subject<void>();

  constructor(
    private popularApi: PopularServicesService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params) {
        this.serviceId = params.id;
        this.getDetails(params.id);
      }
    });
  }

  toSelectPackage(btn, name?) {
    if (name) {
      this.rateButton = name;
    }
    this.$details = this.$initial.rates[btn - 1];
  }

  getDetails(id) {
    this.popularApi.getServiceDetails(id).pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        if (data) {
          this.$rates = data.rates;
          this.fastRegistration = data.fastRegistration;
          this.$initial = data;
          this.$details = data.rates[0];
          console.log(this.$details.boards);
          if (this.$rates && this.$rates.length > 0 && !this.$rates[0]?.id && !this.$rates[0]?.name){
            this.noPackages = true;
          }
          this.showLoader = false;
        }
      });
  }

  toLeadForm(identity) {
    if (!this.rateButton) {
      this.rateButton = this.$initial.rates[0].name;
    }
    this.intercomEventsService
      .push({
        event: INTERCOM_EVENT_NAME.CLICK_SERVICE_CALL2ACTION,
        button_name: identity,
        service_id: this.serviceId,
        service_name: this.$initial.title,
        tariff: this.rateButton,
        price: this.$details.boards[0].title
      });
    const data = {
      formIdentity: identity,
      serviceId: this.serviceId
    };
    this.router.navigate(['/treaty-extension'], {queryParams: {data: JSON.stringify(data)}});
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

}
