<div class="info-plate" *ngIf="paymentType === 'online'">
  <div class="info-plate__img"><img [src]="logo2path" alt="alt"></div>
  <p class="info-plate__text">
    Как только ваша оплата будет обработана,<br class="hide-up-to-md">
    мы переместим вашу заявку из черновиков в активные дела.
  </p>
  <div class="action">
    <app-ui-button label="Перейти к заявке" routerLink="/cases" (click)="sendIntercomEvent()"></app-ui-button>
  </div>
</div>

<div class="info-plate" *ngIf="paymentType === 'cash'">
  <div class="info-plate__img"><img [src]="logo2path" alt="alt"></div>
  <p class="info-plate__text" id="infoPlate">
    Счет сформирован и отправлен вам на почту {{userEmail}}<br>
    Рекомендуем оплатить счет в течение 5 дней.
  </p>
  <div class="action">
    <app-ui-button label="Перейти к заявке" routerLink="/cases" (click)="sendIntercomEvent()"></app-ui-button>
  </div>
</div>

<div class="info-plate" *ngIf="paymentType === 'act'">
  <div class="info-plate__img"><img [src]="logo2path" alt="alt"></div>
  <p class="info-plate__text">
    Стоимость услуг и пошлины будут включены в следующий акт выполненных работ.
  </p>
  <div class="action">
    <app-ui-button label="Перейти к заявке" routerLink="/cases" (click)="sendIntercomEvent()"></app-ui-button>
  </div>
</div>
