import { Store } from '@ngxs/store';
import { forkJoin } from 'rxjs';
import { AppActions } from '@web/core/states/app.actions';
import { AuthActions } from '@web/core/states/auth.actions';
import { OrganizationActions } from '@web/core/states/organization.actions';

export function initializeAppFactory(store: Store): () => Promise<void> {
  return () => {
    return new Promise((resolve, _) => {
      forkJoin([
        store.dispatch(new AppActions.Init()),
        store.dispatch(new AuthActions.Init()),
        store.dispatch(new OrganizationActions.Init()),
      ]).subscribe(() => {
        resolve();
      });
    });
  };
}
