import { SearchRequestInterface } from '@web/features/corp/corp-search/states/models/search-request.interface';
import { ImageInterface } from '@web/features/corp/corp-search/states/models/image.interface';
import { ISearchOptions } from '../../../../../app-search/src/app/models/search.interfaces';
import { HitSearchNameInterface } from '@web/features/corp/corp-search/states/models/search-name-response.interface';
import { TrademarksImageInterface } from '@web/features/corp/corp-search/states/models/search-image-response.interface';
import { ISearchHistoryDetail, ISearchHistoryPayload } from '../../../../../app-ipid/src/app/shared/models/search-history.interfaces';
import { ChangePageRequestInterface } from '@web/features/corp/corp-search/states/models/change-page-request.interface';

export namespace SearchActions {

  export class Search {
    constructor(
      public payload: SearchRequestInterface,
      public imageData?: ImageInterface,
      public saveSearch?: boolean,
      public searchOptions?: ISearchOptions,
      public imageFile?: any
    ) {
    }
    static readonly type = '[search] GetSearchByImage';
  }

  export class ChangePage {
    constructor(
      public changePage: ChangePageRequestInterface,
      public repeat: boolean = false
    ) {
    }
    static readonly type = '[search] ChangePage';
  }

  export class ClearSearch {
    static readonly type = '[search] ClearSearch';
  }

  export class CheckObject {
    constructor(
      public item: HitSearchNameInterface | TrademarksImageInterface,
    ) {
    }
    static readonly type = '[search] CheckObject';
  }

  export class LoadByHistory {
    constructor(
      public search: ISearchHistoryDetail,
      public changePage?: ISearchHistoryPayload
    ) {
    }
    static readonly type = '[search] LoadByHistory';
  }

  export class ChangePageByHistory {
    constructor(
      public payload: ISearchHistoryPayload
    ) {
    }
    static readonly type = '[search] ChangePageByHistory';
  }
}
