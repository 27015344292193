import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserRightsService } from '../tab-users-rights/user-rights.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-tab-access',
  templateUrl: './tab-access.component.html',
  styleUrls: ['./tab-access.component.scss']
})
export class TabAccessComponent implements OnInit, OnDestroy {
  showDropdown = false;
  selected;
  orgInput;
  attorneysByOrganization;
  rights;
  @Input() organizations;
  @Input() attorneys;
  @Input() rightsForEdit;
  rightParams;
  private readonly onDestroy = new Subject<void>();
  contragentID;
  params = {
    contragentID: '',
    token: '',
    userID: ''
  };
  success = false;
  actualIdOrg;

  constructor(
    private userApi: UserRightsService
  ) {
  }

  ngOnInit(): void {
    this.orgInput = this.organizations;
    this.selected = this.organizations[0].name;
    if (this.rightsForEdit) {
      this.rightParams = this.rightsForEdit;
      const org = this.organizations.filter(i => i.id === this.rightParams[0]);
      if (org) {
        this.selected = org[0].name;
        this.actualIdOrg = org[0].id;
      }
    }
    this.attorneysByOrganization = this.attorneys.filter(att => att.attorney.organization === this.selected);
    // if (!this.actualIdOrg) {
    this.getUserRights();
    // }
  }

  filterAttorney(id) {
    this.actualIdOrg = id;
    this.attorneysByOrganization = this.attorneys.filter(att => att.attorney.organization === this.selected);
    this.params.contragentID = id;
    if (this.rightsForEdit) {
      this.params.userID = this.rightParams[1];
    }
    this.userApi.getRights(this.params).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
      this.rights = answer.rights;
    });
    this.showDropdown = false;
  }

  getUserRights() {
    if (this.rightParams) {
      this.params.contragentID = this.actualIdOrg;
      this.params.userID = this.rightParams[1];
    } else {
      delete this.params.userID;
      this.params.contragentID = this.organizations[0].id;
    }
    this.userApi.getRights(this.params).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
      this.rights = answer.rights;
    });
  }

  addUserRights(data) {
    data.contragentID = this.actualIdOrg;
    data.userID = this.rightParams[1];
    this.userApi.addRights(data).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
      if (answer.success.length) {
        this.getUserRights();
      }
    });
  }

  deleteUserRights(data) {
    data.contragentID = this.actualIdOrg;
    data.userID = this.rightParams[1];
    this.userApi.deleteRights(data).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
      if (answer.success.length) {
        this.getUserRights();
      }
    });
  }

  deleteAllUserRights() {
    const data = {
      contragentID: this.actualIdOrg,
      userID: this.rightsForEdit[1]
    };
    this.userApi.deleteAllRights(data).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
      if (answer) {
        this.getUserRights();
      }
    });
    this.saveRightsChange();
  }

  saveRightsChange() {
    this.success = true;
    setTimeout(() => {
      this.success = false;
    }, 3000);
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
