import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-open-layout',
  templateUrl: './open-layout.component.html',
  styleUrls: ['./open-layout.component.scss']
})
export class OpenLayoutComponent implements OnInit {

  constructor(
    private shared: SharedService,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
  }

  closeDropdowns() {
    // TODO: подумать над этим
    this.shared.closeModals.next(null);
  }

  get isIndexPage() {
    return this.router.url === '/';
  }

}
