<ng-container *ngIf="!monitoringAddService.isLoading">

  <div class="add-object add-object__content add-object__content--tariff" >
    <div class="add-object__block">
      <h2  class="add-object__header ">Выберите тариф</h2>
      <div class="add-object__btns-wrapper">

        <div class="flex">
          <div *ngFor=" let obj of shownObjects"
               class="obj-btn"
               [class.obj-btn--refresh]="monitoringAddService.isObjectExcluded(obj)">
            <p class="obj-btn__name">{{obj.type}}</p>
            <button class="obj-btn__btn"
                    [class.obj-btn__btn--refresh]="monitoringAddService.isObjectExcluded(obj)"
                    (click)="monitoringAddService.toggleObject(obj)"
            >
              <ng-container *ngIf="monitoringAddService.isObjectExcluded(obj)">
                      <span class="obj-btn__btn-refresh">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none">
                          <path d="M9 5.00007C9 7.20921 7.20914 9.00007 5 9.00007C2.79086 9.00007 1 7.20921 1 5.00007C1 3.35774 1.98978 1.94658 3.40537 1.33057"/>
                          <path d="M0 1H4V4"/>
                        </svg>
                      </span>
                <span class="obj-btn__hidden">Восстановить объект для добавления в мониторинг</span>
              </ng-container>

            </button>
            <p class="obj-btn__text">{{obj.name}}</p>
          </div>

          <div  class="obj-btn__show-more" *ngIf="!showAll && hasHiddenObjects">
            <button  class="obj-btn__show-more--btn" (click)="onShowAll()">
                    <span class="obj-btn__show-more--desktop">
                      Показать все {{monitoringAddService.allObjects.length}} объекта для подключения
                    </span>
              <span class="obj-btn__show-more--mobile">
                      Показать все <span class="obj-btn__show-more--number">{{monitoringAddService.allObjects.length}}</span>
                    </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="add-object__individual individual" *ngIf="monitoringAddService.isAvailablePersonalTariff">
      <div class="individual__wrap">
        <p class="individual__header">Вы хотите подключить {{monitoringAddService.totalSelectedObjects}} объектов для мониторинга</p>
        <p class="individual__text">Получите индивидуальное предложение по стоимости.</p>
      </div>
      <div class="individual__btns-wrap">
        <button class="individual__btn">Получить предложение</button>
        <p><span class="individual__or">или</span> <span class="individual__btn-blue">Запросить счет</span></p>
      </div>
    </div>

    <ng-container *ngIf="!monitoringAddService.isAvailablePersonalTariff">
      <div class="add-object__block">
        <div class="add-object__prices prices" >
          <div class="prices__block" (click)="monitoringAddService.selectTariff(monitoringAddService.rates[1].id)">
            <p *ngIf="monitoringAddService.data?.tariffID === monitoringAddService.rates[1].id" class="prices__choose">Выбран</p>
            <p class="prices__period">1 месяц</p>
            <div class="prices__wrapper">
              <p  class="prices__price">{{ monitoringAddService.calcPriceWithDiscount(monitoringAddService.rates[1].id) | price }} ₽</p>
            </div>
          </div>

          <div class="prices__block" (click)="monitoringAddService.selectTariff(monitoringAddService.rates[3].id)">
            <p *ngIf="monitoringAddService.data.tariffID === monitoringAddService.rates[3].id" class="prices__choose">Выбран</p>
            <p class="prices__period">3 месяцa</p>
            <div class="prices__wrapper">
              <p class="prices__old-price">{{ monitoringAddService.totalSelectedObjects * monitoringAddService.rates[3].sum | price }} ₽</p>
              <p class="prices__price">{{ monitoringAddService.calcPriceWithDiscount(monitoringAddService.rates[3].id) | price}} ₽</p>
            </div>
            <p *ngIf="monitoringAddService.rates[3].discount"  class="prices__discount" [class.prices__discount--top]="monitoringAddService.data.tariffID === monitoringAddService.rates[3].id">
              <span>-{{monitoringAddService.rates[3].discount}}%</span></p>
          </div>

          <div class="prices__block"  (click)="monitoringAddService.selectTariff(monitoringAddService.rates[6].id)">
            <p *ngIf="monitoringAddService.data.tariffID === monitoringAddService.rates[6].id" class="prices__choose">Выбран</p>
            <p class="prices__period">6 месяцев</p>
            <div class="prices__wrapper">
              <p class="prices__old-price">{{ monitoringAddService.totalSelectedObjects * monitoringAddService.rates[6].sum | price }} ₽</p>
              <p class="prices__price">{{ monitoringAddService.calcPriceWithDiscount(monitoringAddService.rates[6].id) | price}} ₽</p>
            </div>
            <p *ngIf="monitoringAddService.rates[6].discount"  class="prices__discount" [class.prices__discount--top]="monitoringAddService.data.tariffID === monitoringAddService.rates[6].id">
              <span>-{{monitoringAddService.rates[6].discount}}%</span></p>
          </div>

          <div class="prices__block" (click)="monitoringAddService.selectTariff(monitoringAddService.rates[12].id)">
            <p *ngIf="monitoringAddService.data.tariffID === monitoringAddService.rates[12].id" class="prices__choose">Выбран</p>
            <p class="prices__period">12 месяцев</p>
            <div class="prices__wrapper">
              <p class="prices__old-price">{{ monitoringAddService.totalSelectedObjects * monitoringAddService.rates[12].sum | price }} ₽</p>
              <p class="prices__price">{{ monitoringAddService.calcPriceWithDiscount(monitoringAddService.rates[12].id) | price}} ₽</p>
            </div>
            <p *ngIf="monitoringAddService.rates[12].discount"  class="prices__discount" [class.prices__discount--top]="monitoringAddService.data.tariffID === monitoringAddService.rates[12].id">
              <span>-{{ monitoringAddService.rates[12].discount}}%</span></p>
          </div>
        </div>
      </div>

      <div class="add-object__pay pay">
        <div class="pay__text-wrapper">
          <p class="pay__text">Количество подключаемых объектов: {{ monitoringAddService.totalSelectedObjects }}</p>
          <p class="pay__text">Стоимость одного объекта для подключения {{ monitoringAddService.rates[12].sum | price }} руб. в год, итого к оплате {{ monitoringAddService.totalPrice | price }} руб. с учетом скидки {{ monitoringAddService.currentDiscount}}%</p>
        </div>
        <div class="pay__sum-wrap">
          <p class="pay__sum">Итого к оплате: <b>{{ monitoringAddService.totalPrice | price }} ₽</b></p>
          <app-button
            [loading]="monitoringAddService.isLoadingOnPay"
            [styleButton]="'btn-blue__offline pr'"
            (click)="monitoringAddService.goPayStage()"
          >Оплатить</app-button>
        </div>
      </div>
    </ng-container>
  </div>

</ng-container>
