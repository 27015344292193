<div class="page-wrapper">
  <main>
    <section class="form-section profile">
      <div class="container">
        <a (click)="backClicked()" class="back-link">
            <span>
              <svg width="12" height="8" viewBox="0 0 21 16">
                <g clip-path="url(#clip0)">
                 <path d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                  <rect width="21" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          Назад
        </a>
        <div class="container-sm">
          <h1 class="heading-h1 heading-h1--centered">Изменить пароль</h1>
          <form [formGroup]="userPassForm">
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="password"
                       class="form__input field"
                       type="password"
                       [ngClass]="{ 'error': userPassForm.get('password').invalid && userPassForm.get('password').touched }"
                       required>
                <span class="form__input-label">Старый пароль</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="newPassword"
                       class="form__input field"
                       type="password"
                       [ngClass]="{ 'error': userPassForm.get('newPassword').invalid && userPassForm.get('newPassword').touched }"
                       required>
                <span class="form__input-label">Новый пароль</span>
              </div>
            </div>
            <div *ngIf="userPassForm.get('newPassword').invalid && userPassForm.get('newPassword').touched"
                 class="error_container_pass">
              <span class="error_label">Пароль слишком простой. Используйте минимум 6 символов, большие и маленькие буквы, добавьте цифры и спецсимволы</span>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="confirmPassword"
                       class="form__input field"
                       type="password"
                       [ngClass]="{ 'error': userPassForm.get('confirmPassword').invalid && userPassForm.get('confirmPassword').touched }"
                       required>
                <span class="form__input-label">Подтвердите новый пароль</span>
              </div>
              <p *ngIf="error" class="error_label">{{errorText}}</p>
            </div>

            <div class="form__footer">
              <button
                (click)="changePassword()"
                class="btn btn__auth"
                [ngClass]="{'disable_btn' : userPassForm.get('confirmPassword').invalid || userPassForm.get('newPassword').invalid || userPassForm.get('password').invalid}"
                type="button">Сохранить
              </button>
            </div>

          </form>
        </div>
      </div>
    </section>
  </main>
</div>
