<div *ngIf="def">
  <app-filters-container
    [def]="def"
    [layout]="{ lg: lg,
                  md: ['1fr'],
                  sm: ['1fr']
                 }"
    [clear$]="clearFilter$"
    (apply)='applyFilter($event)'
    [filterValue]="state.currentTab === 'searches' ? state.formFiltersValueHistory : state.formFiltersValueReports"
    [currentTab]="state.currentTab"
    [smButtonTitle]="'Поисковый запрос'"
  ></app-filters-container>

  <div class="sections container found-objects">
    <app-ui-objects-found
      *ngIf="layout !== 'sm' && isFiltersApplied"
      [total]="viewMode === 'searches' ? state.totalCountSearchHistory : state.totalCountReports"
      [found]="viewMode === 'searches' ? state.filteredCountSearchHistory : state.filteredCountReports"
      (clearFilter)="clearFilter()"
    ></app-ui-objects-found>
  </div>
</div>
