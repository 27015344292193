<ng-container *ngIf="getTotalCases() != 0; else notObjects">
  <div class="portfolio-item__title">
    <div class="portfolio-item__total-wrapper">
      <p class="portfolio-item__total-wrap">
        <span class="portfolio-item__total">{{ getTotalCases() }}</span>
        <span class="portfolio-item__total-desc">Объектов</span>
      </p>
      <p class="portfolio-item__total-wrap portfolio-item__total-wrap--active">
        <span class="portfolio-item__total">{{activeRequests}}</span>
        <span class="portfolio-item__total-desc">Активных дел</span>
      </p>
    </div>
  </div>
  <table class="chart-corp">
    <tr *ngFor="let item of cases" class="chart-corp__item"
        [ngClass]="{
                          'trademark': item.name === 'Товарные знаки',
                          'patent': item.name === 'Патенты',
                          'db': item.name === 'Программы и БД',
                          'domain': item.name === 'Доменов',
                          'copyright': item.name === 'Авторских прав'}">
      <td class="chart-corp__line-wrap">
            <span class="chart-corp__line"
                  style="width:{{(item.value / getMaxCases())*100}}%">
            </span>
      </td>
      <td class="chart-corp__value">{{ item.value }}</td>
      <td class="chart-corp__name">{{ item.name }}</td>
    </tr>
  </table>
  <div class="portfolio__btn-wrap">
    <a href="" class="portfolio-item__btn btn" [routerLink]="'/portfolio'" [class.disabled]="X5">Перейти в портфель</a>
    <a href="" class="portfolio-item__btn portfolio-item__btn--cases btn" [routerLink]="'/cases'">Перейти к делам</a>
  </div>
</ng-container>

<ng-template #notObjects>
  <div class="no-objects">
    <img src="/assets/images/nothing.svg" alt="nothing"/>
    <p>Нет объектов в портфеле</p>
    <a routerLink="/portfolio" class="portfolio-item__btn btn" [class.disabled]="X5">Добавить объект</a>
  </div>
</ng-template>
