import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, ChangeDetectionStrategy, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AbstractValueAccessorComponent } from '@ui/shared/components/abstract-value-accessor/abstract-value-accessor.component';

type CustomInputType = 'text' | 'number';

@Component({
  selector: 'app-ui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    }
  ]
})
export class InputComponent extends AbstractValueAccessorComponent {
  @Input() loading: boolean = false;
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: CustomInputType = 'text';
  @Input() set value(val: string) {
    if (val !== undefined && this.textValue !== val) {
      this.textValue = val;
      this.onChange(val);
      this.onTouch();
      this.ref.detectChanges();
    }
  }
  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeValue: EventEmitter<string> = new EventEmitter<string>();
  public textValue: string = '';

  constructor(
    private readonly ref: ChangeDetectorRef,
  ) { super() }

  public update(target: HTMLInputElement): void {
    this.textValue = target.value;
    this.onChange(this.textValue);
    this.changeValue.emit(this.textValue);
  }

  public onClickHandler(): void {
    if (!this.disabled) {
      this.onClick.emit();
    }
  }
}
