<div *ngIf="!isLoading; else load">
  <form *ngIf="contractType==='subconcession' || contractType==='concession' || contractType==='license' || contractType==='sublicense'"
        class="conditions" [formGroup]="conditionsForm" (ngSubmit)="submit()">
    <div class="conditions__row">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Вид договора
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio">
          <div class="conditions__radio-option">
            <input type="radio" id="{{contractType ==='concession' || contractType === 'subconcession' ? 'concession' : 'license'}}"
                   value="{{contractType === 'concession' ||contractType ==='subconcession' ? 'concession' : 'license'}}"
                   formControlName="kind"
                   class="conditions"
                   (ngModelChange)="setValue()">
            <label for="{{contractType ==='concession' || contractType === 'subconcession' ? 'concession' : 'license'}}">
              {{contractType ==='concession'||contractType ==='subconcession' ?'Коммерческой концессии' : 'Лицензионный'}}</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio" id="{{contractType ==='subconcession' || contractType === 'concession' ? 'subconcession' : 'sublicense'}}"
                   value="{{contractType==='subconcession' || contractType ==='concession' ? 'subconcession' : 'sublicense'}}"
                   formControlName="kind"
                   (ngModelChange)="setValue()">
            <label for="{{contractType ==='subconcession' || contractType === 'concession' ? 'subconcession' : 'sublicense'}}">
              {{contractType==='concession'||contractType==='subconcession' ?'Коммерческой субконцессии' : 'Сублицензионный'}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('kind').value !== '' || this.conditionsForm.get('kind').value !== null ||  contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Тип {{contractType==='concession'||contractType==='subconcession' ?'концессии' : 'лицензии'}}
          <!--        <span class="prompt prompt__question">?-->
          <!--         <span class="prompt__hidden">-->
          <!--           <p>Исключительная дает право распоряжаться ОИС только указанному лицу;</p>-->
          <!--         </span>-->
          <!--      </span>-->
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.exclusivity.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="nonexclusive"
                   value="nonexclusive"
                   formControlName="exclusivity"
                   (ngModelChange)="setValue()">
            <label for="nonexclusive">Не исключительная</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="exclusive"
                   value="exclusive"
                   formControlName="exclusivity"
                   (ngModelChange)="setValue()">
            <label for="exclusive">Исключительная</label>
          </div>
        </div>
      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('exclusivity').value !== '' && this.conditionsForm.get('exclusivity').value !== null || contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Территория действия
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Выберите территориальную единицу</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.territorialLimits.value">
          <div class="conditions__radio-option">
            <input
              type="radio"
              id="russia"
              formControlName="territorialLimits"
              value="russia"
              (ngModelChange)="setValue()">
            <label for="russia">Россия</label>
          </div>

          <div class="conditions__radio-option">
            <input
              type="radio"
              id="subject"
              formControlName="territorialLimits"
              value="subject"
              (ngModelChange)="setValue()">
            <label  for="subject">Субъект</label>
          </div>

          <div class="conditions__radio-option">
            <input
              type="radio"
              id="other"
              formControlName="territorialLimits"
              value="other"
              (ngModelChange)="setValue()">
            <label for="other" (click)="this.conditionsForm.value.territorialLimitsString = ''">Другое</label>
          </div>

          <div class="conditions__radio-option">
            <input
              type="radio"
              id="noLimits"
              formControlName="territorialLimits"
              value="noLimits"
              (ngModelChange)="setValue()">
            <label for="noLimits">Нет ограничений</label>
          </div>
        </div>
        <div *ngIf="this.conditionsForm.get('territorialLimits').value==='subject'"
             tabindex="0"
             class="search__btn-block search__btn-block--top"
             [class.invalid]="validation && conditionsForm.controls.territorialLimits.value === 'subject' && setAreaObjects().length === 0">
          <p class="search__label" [class.search__label--black]="getCountChecked(territorialSubject) > 0">{{ subjectText()}}</p>
          <div class="search__select" id="object-type-debates">
            <button class="search__select-btn"><span class="search__select-btn-span"></span>

              <div class="search__hidden-options-wrapper">
                <div class="search__options-wrapper">
                  <p class="search__title">Субъект</p>

                  <div *ngFor="let option of territorialSubject"
                       class="search__option">
                    <input class="search__option-input"
                           type="checkbox"
                           id="{{option.id}}"
                           [(ngModel)]="option.checked"
                           value="{{option.value}}"
                           [ngModelOptions]="{standalone: true}"
                           (ngModelChange)="setValue()">
                    <label class="search__option-label" for="{{option.id}}">{{option.value}}</label>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="this.conditionsForm.get('territorialLimits').value==='other'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.territorialLimits.value === 'other' && !conditionsForm.controls.territorialLimitsString.value">
          <input
            type="text"
            placeholder="Другое"
            formControlName="territorialLimitsString"
            (ngModelChange)="setValue()">
        </div>
      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('territorialLimits').value === 'russia' || this.conditionsForm.get('territorialLimits').value === 'noLimits'
  || this.setAreaObjects().length > 0 && this.conditionsForm.get('territorialLimits').value === 'subject'
  || this.conditionsForm.get('territorialLimitsString').value !== '' && this.conditionsForm.get('territorialLimits').value === 'other' || contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Срок действия
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Выберите временную единицу</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.periodType.value">
          <div class="conditions__radio-option">
            <input
              type="radio"
              id="months"
              formControlName="periodType"
              value="months"
              (ngModelChange)="setValue()">
            <label for="months">Количество месяцев</label>
          </div>

          <div class="conditions__radio-option">
            <input
              type="radio"
              id="years"
              formControlName="periodType"
              value="years"
              (ngModelChange)="setValue()">
            <label for="years">Количество лет</label>
          </div>

          <div class="conditions__radio-option">
            <input
              type="radio"
              id="date"
              formControlName="periodType"
              value="date"
              (ngModelChange)="setValue()">
            <label for="date">Дата до</label>
          </div>

          <div class="conditions__radio-option">
            <input
              type="radio"
              id="fullTerm"
              formControlName="periodType"
              value="noLimits"
              (ngModelChange)="setValue()">
            <label for="fullTerm">На срок действия исключительного права</label>
          </div>
        </div>

        <div *ngIf="this.conditionsForm.get('periodType').value==='years'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.periodType.value === 'years'
           && !conditionsForm.controls.periodYears.value
           || (conditionsForm.controls.periodYears.touched || conditionsForm.controls.periodYears.dirty) && conditionsForm.controls.periodYears.invalid">
          <input
            type="text"
            placeholder="Укажите количество, например 1 или 5"
            formControlName="periodYears"
            (ngModelChange)="setValue()">
          <span>лет</span>
        </div>
        <span *ngIf="(conditionsForm.controls.periodYears.touched || conditionsForm.controls.periodYears.dirty) && conditionsForm.controls.periodYears.invalid"
              class="invalid-field">
        Поле не может быть пустым и должно содержать цифры
        </span>

        <div *ngIf="this.conditionsForm.get('periodType').value==='months'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.periodType.value === 'months'
           && !conditionsForm.controls.periodMonths.value
           || (conditionsForm.controls.periodMonths.touched || conditionsForm.controls.periodMonths.dirty) && conditionsForm.controls.periodMonths.invalid">
          <input
            type="text"
            placeholder="Укажите количество, например 1 или 5"
            formControlName="periodMonths"
            (ngModelChange)="setValue()">
          <span>месяцев</span>
        </div>
        <span *ngIf="(conditionsForm.controls.periodMonths.touched || conditionsForm.controls.periodMonths.dirty) && conditionsForm.controls.periodMonths.invalid"
              class="invalid-field">
        Поле не может быть пустым и должно содержать цифры
      </span>

        <div *ngIf="this.conditionsForm.get('periodType').value==='date'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.periodType.value === 'date'
           && !conditionsForm.controls.periodDate.value
           || (conditionsForm.controls.periodDate.touched || conditionsForm.controls.periodDate.dirty) && conditionsForm.controls.periodDate.invalid">
          <input
            bsDatepicker
            placeholder="Введите дату"
            formControlName="periodDate"
            (ngModelChange)="setValue()">
        </div>
        <span *ngIf="(conditionsForm.controls.periodDate.touched || conditionsForm.controls.periodDate.dirty) && conditionsForm.controls.periodDate.invalid"
              class="invalid-field">
        Поле не может быть пустым и должно содержать цифры
      </span>

      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('periodType').value === 'noLimits'
  || this.conditionsForm.get('periodType').value ==='years' && this.conditionsForm.get('periodYears').value !== '' && this.conditionsForm.get('periodYears').value !== null && this.conditionsForm.get('periodYears').valid
  || this.conditionsForm.get('periodType').value ==='months' && this.conditionsForm.get('periodMonths').value !== '' && this.conditionsForm.get('periodMonths').value !== null && this.conditionsForm.get('periodMonths').valid
  || this.conditionsForm.get('periodType').value ==='date' && this.conditionsForm.get('periodDate').value !== '' && this.conditionsForm.get('periodDate').value !== null && this.conditionsForm.get('periodDate').valid
  || contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Сумма вознаграждения
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Полная сумма договора</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__fields-wrapper">
          <div class="conditions__complex"
               formGroupName="reward"
               [class.invalid]="validation && !this.conditionsForm.get('reward').value.amountOfCompensation || (conditionsForm.controls.reward.get('amountOfCompensation').touched
                              || conditionsForm.controls.reward.get('amountOfCompensation').dirty) && conditionsForm.controls.reward.get('amountOfCompensation').invalid">
            <input
              type="text"
              formControlName="amountOfCompensation"
              placeholder="0"
              (ngModelChange)="setValue()">
            <div tabindex="0" class="form__input-wrap form__input-wrap--select money"
                 (focusout)="showOptionsCurrency =false"
                 [class.form__input-wrap--select--after]="showOptionsCurrency ===true">
              <a class="form__link " (click)="showOptionsCurrency =!showOptionsCurrency " >{{selectedCurrency}}</a>
              <ul *ngIf="showOptionsCurrency " class="form__select-list">
                <li *ngFor = "let cur of currency" class="form__li company-item"
                    (click)="onSelectCurrency(cur)">
                  <p>{{cur}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div tabindex="0" class="form__input-wrap form__input-wrap--select nds"
               (focusout)="showOptionsNDS=false"
               [class.form__input-wrap--select--after]="showOptionsNDS===true">
            <a class="form__link " (click)="showOptionsNDS=!showOptionsNDS" >{{selectedNDS}}</a>
            <ul *ngIf="showOptionsNDS" class="form__select-list">
              <li *ngFor = "let item of NDS" class="form__li company-item"
                  (click)="onSelectNDS(item.name)">
                <p>{{item.name}}</p>
              </li>
            </ul>
          </div>
        </div>
        <span *ngIf="(conditionsForm.controls.reward.get('amountOfCompensation').touched
      || conditionsForm.controls.reward.get('amountOfCompensation').dirty) && conditionsForm.controls.reward.get('amountOfCompensation').invalid"
              class="invalid-field">
        Поле не может быть пустым и должно содержать цифры
      </span>
      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('reward').value.amountOfCompensation !== '' && this.conditionsForm.get('reward').value.amountOfCompensation !== null && conditionsForm.controls.reward.get('amountOfCompensation').valid || contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Порядок выплаты
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Периодичность выплат</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div
          class="conditions__radio"
          [class.invalid]="validation && !conditionsForm.controls.mannerOfPayment.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="month"
                   formControlName="mannerOfPayment"
                   value="month"
                   (ngModelChange)="setValue()">
            <label for="month">Ежемесячно</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="quarter"
                   formControlName="mannerOfPayment"
                   value="quarter"
                   (ngModelChange)="setValue()">
            <label for="quarter">Ежеквартально</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="year"
                   formControlName="mannerOfPayment"
                   value="year"
                   (ngModelChange)="setValue()">
            <label for="year">1 раз в год</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="freeForm"
                   formControlName="mannerOfPayment"
                   value="other"
                   (ngModelChange)="setValue()">
            <label for="freeForm">Указать в свободной форме</label>
          </div>
        </div>

        <div *ngIf="this.conditionsForm.get('mannerOfPayment').value==='other'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.mannerOfPayment.value === 'other' && !conditionsForm.controls.mannerOfPaymentString.value">
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMaxRows="20"
          cdkAutosizeMinRows="{{mobileTextarea1100 ? 1.4 : 1}}"
          placeholder="Укажите в свободной форме порядок выплаты вознаграждения"
          formControlName="mannerOfPaymentString"
          (ngModelChange)="setValue()">
        </textarea>
        </div>

      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('mannerOfPayment').value === 'month'|| this.conditionsForm.get('mannerOfPayment').value === 'year'
  || this.conditionsForm.get('mannerOfPayment').value === 'quarter'
  || this.conditionsForm.get('mannerOfPayment').value === 'other' && this.conditionsForm.get('mannerOfPaymentString').value !== '' || contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Начало платежей
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Дата первого платежа</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.paymentStart.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="registration"
                   value="registration"
                   formControlName="paymentStart"
                   (ngModelChange)="setValue()">
            <label for="registration">С даты регистрации договора</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="signing"
                   value="signing"
                   formControlName="paymentStart"
                   (ngModelChange)="setValue()">
            <label for="signing">С даты подписания договора</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="otherDate"
                   value="other"
                   formControlName="paymentStart"
                   (ngModelChange)="setValue()">
            <label for="otherDate">Иное</label>
          </div>
        </div>
        <div *ngIf="this.conditionsForm.get('paymentStart').value==='other'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.paymentStart.value === 'other' && !conditionsForm.controls.paymentStartString.value">
          <input type="text"
                 formControlName="paymentStartString"
                 placeholder="Иное"
                 (ngModelChange)="setValue()">
        </div>
      </div>
    </div>

    <div class="conditions__row" *ngIf="contractType !== 'license' && contractType !== 'sublicense' && (this.conditionsForm.get('paymentStart').value === 'signing' ||
         this.conditionsForm.get('paymentStart').value === 'registration' ||
         this.conditionsForm.get('paymentStart').value === 'other' && this.conditionsForm.get('paymentStartString').value !== '' || contractAction === 'change')">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Право на предоставление субконцессии
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Может ли обладатель исключительного права, полученного в рамках первичного договора, передать права на ОИС другому лицу по договору субконцесии</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.subconcessionRight.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="limits"
                   value="limits"
                   formControlName="subconcessionRight"
                   (ngModelChange)="setValue()">
            <label for="limits">Да, с ограничениями</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="yes"
                   value="yes"
                   formControlName="subconcessionRight"
                   (ngModelChange)="setValue()">
            <label for="yes">Да, без ограничений</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="no"
                   value="no"
                   formControlName="subconcessionRight"
                   (ngModelChange)="setValue()">
            <label for="no">Нет</label>
          </div>
        </div>
        <div *ngIf="this.conditionsForm.get('subconcessionRight').value==='limits'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.subconcessionRight.value === 'limits' && !conditionsForm.controls.subconcessionRightString.value">
          <input type="text"
                 placeholder="Укажите ограничения"
                 formControlName="subconcessionRightString"
                 (ngModelChange)="setValue()">
        </div>
      </div>
    </div>
    <div class="conditions__row" *ngIf="(contractType === 'license' || contractType === 'sublicense')
    && (this.conditionsForm.get('paymentStart').value === 'signing' ||
         this.conditionsForm.get('paymentStart').value === 'registration' ||
         this.conditionsForm.get('paymentStart').value === 'other' &&
         this.conditionsForm.get('paymentStartString').value !== '' || contractAction === 'change') || this.conditionsForm.get('subconcessionRight').value === 'no' ||
         this.conditionsForm.get('subconcessionRight').value === 'yes' ||
         this.conditionsForm.get('subconcessionRight').value === 'limits'
         && this.conditionsForm.get('subconcessionRightString').value !== '' ||contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Товары и услуги
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>На какие товары и услуги передаются права на ОИС</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.goodsAndServices.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="withRestrictionsGoods"
                   value="limits"
                   formControlName="goodsAndServices"
                   (ngModelChange)="setValue()">
            <label for="withRestrictionsGoods">С ограничениями</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="withoutRestrictionsGoods"
                   value="yes"
                   formControlName="goodsAndServices"
                   (ngModelChange)="setValue()">
            <label for="withoutRestrictionsGoods">Без ограничений</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="noGoods"
                   value="no"
                   formControlName="goodsAndServices"
                   (ngModelChange)="setValue()">
            <label for="noGoods">Нет</label>
          </div>
        </div>
        <div *ngIf="this.conditionsForm.get('goodsAndServices').value==='limits'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.goodsAndServices.value === 'limits' && !conditionsForm.controls.goodsAndServicesString.value">
          <input type="text"
                 placeholder="Укажите ограничения"
                 formControlName="goodsAndServicesString"
                 (ngModelChange)="setValue()">
        </div>
      </div>
    </div>

    <div *ngIf="contractType==='subconcession' || contractType==='concession'">
      <div class="conditions__row" *ngIf="this.conditionsForm.get('goodsAndServices').value === 'no' ||
   this.conditionsForm.get('goodsAndServices').value === 'yes' ||
   this.conditionsForm.get('goodsAndServices').value === 'limits' && this.conditionsForm.get('goodsAndServicesString').value !== '' || contractAction === 'change'">
        <div class="conditions__title-wrapper">
          <div class="conditions__title">Комплекс передаваемых прав
            <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Какие права и на какие объекты вы хотите передать</p>
         </span>
      </span>
          </div>
        </div>
        <div class="conditions__radio-wrapper">
          <div class="conditions__complex">
            <input type="text"
                   placeholder="Описание передаваемых прав"
                   formControlName="complex"
                   (ngModelChange)="setValue()">
          </div>
        </div>

      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('complex').value !== '' && this.conditionsForm.get('complex').value !== null || this.conditionsForm.get('goodsAndServices').value === 'no' ||
   this.conditionsForm.get('goodsAndServices').value === 'yes' ||
   this.conditionsForm.get('goodsAndServices').value === 'limits' && this.conditionsForm.get('goodsAndServicesString').value !== '' || contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Область использования
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Выберите области, для которых передаются права на ОИС</p>
         </span>
        </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper" formGroupName="fieldsOfApplication">
        <div class="conditions__checkbox"
             [class.invalid]="validation
           && !this.conditionsForm.get('fieldsOfApplication').value.goods
           && !this.conditionsForm.get('fieldsOfApplication').value.services
           && !this.conditionsForm.get('fieldsOfApplication').value.documentation
           && !this.conditionsForm.get('fieldsOfApplication').value.advertising
           && !this.conditionsForm.get('fieldsOfApplication').value.internet
           && !this.conditionsForm.get('fieldsOfApplication').value.textOfApplication">
          <div class="conditions__checkbox-option">
            <input type="checkbox"
                   id="goods"
                   formControlName="goods"
                   (ngModelChange)="setValue()">
            <label for="goods">Товары
              <!--            <span class="prompt prompt__question">?-->
              <!--              <span class="prompt__hidden">-->
              <!--                <p>На товарах, в том числе на этикетках, упаковках товаров, которые производятся, предлагаются к продаже, продаются</p>-->
              <!--              </span>-->
              <!--             </span>-->
            </label>
          </div>

          <div class="conditions__checkbox-option">
            <input type="checkbox"
                   id="services"
                   formControlName="services"
                   (ngModelChange)="setValue()">
            <label for="services">Услуги
              <!--            <span class="prompt prompt__question">?-->
              <!--              <span class="prompt__hidden">-->
              <!--                <p>При выполнении работ, оказании услуг</p>-->
              <!--              </span>-->
              <!--             </span>-->
            </label>
          </div>

          <div class="conditions__checkbox-option">
            <input type="checkbox"
                   id="documentation"
                   formControlName="documentation"
                   (ngModelChange)="setValue()">
            <label for="documentation">Документация
              <!--            <span class="prompt prompt__question">?-->
              <!--              <span class="prompt__hidden">-->
              <!--                <p>На документации, связанной с введением товаров в гражданский оборот</p>-->
              <!--              </span>-->
              <!--             </span>-->
            </label>
          </div>

          <div class="conditions__checkbox-option">
            <input type="checkbox"
                   id="advertising"
                   formControlName="advertising"
                   (ngModelChange)="setValue()">
            <label for="advertising">Реклама
              <!--            <span class="prompt prompt__question">?-->
              <!--              <span class="prompt__hidden">-->
              <!--                <p>В предложениях о продаже товаров, о выполнении работ, об оказании услуг, а также в объявлениях, на вывесках и в рекламе</p>-->
              <!--              </span>-->
              <!--             </span>-->
            </label>
          </div>

          <div class="conditions__checkbox-option">
            <input type="checkbox"
                   id="internet"
                   formControlName="internet"
                   (ngModelChange)="setValue()">
            <label for="internet">Интернет
              <!--            <span class="prompt prompt__question">?-->
              <!--              <span class="prompt__hidden">-->
              <!--                <p>В сети "Интернет", в том числе в доменном имени и при других способах адресации</p>-->
              <!--              </span>-->
              <!--             </span>-->
            </label>
          </div>
        </div>
        <div class="conditions__complex conditions__complex--top">
        <textarea matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="{{mobileTextarea400 ? 1.4 : 1}}"
                  cdkAutosizeMaxRows="20"
                  type="text"
                  placeholder="Опишите иные условия области применения"
                  formControlName="textOfApplication"
                  (ngModelChange)="setValue()"></textarea>
        </div>
      </div>

    </div>
    <div class="conditions__row" *ngIf="
  this.conditionsForm.get('fieldsOfApplication').value.goods !== false && this.conditionsForm.get('fieldsOfApplication').value.goods !== null ||
  this.conditionsForm.get('fieldsOfApplication').value.services !== false && this.conditionsForm.get('fieldsOfApplication').value.services !== null ||
  this.conditionsForm.get('fieldsOfApplication').value.documentation !== false && this.conditionsForm.get('fieldsOfApplication').value.documentation !== null ||
  this.conditionsForm.get('fieldsOfApplication').value.advertising !== false && this.conditionsForm.get('fieldsOfApplication').value.advertising !== null ||
  this.conditionsForm.get('fieldsOfApplication').value.internet !== false  && this.conditionsForm.get('fieldsOfApplication').value.internet !== null ||
  this.conditionsForm.get('fieldsOfApplication').value.textOfApplication !== '' && this.conditionsForm.get('fieldsOfApplication').value.textOfApplication !== null || contractAction === 'change'">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Иные условия
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Укажите иные условия, которые необходимо внести в договор</p>
         </span>
        </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__complex">
        <textarea matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="{{mobileTextarea1100 ? 2 : 1}}"
                  cdkAutosizeMaxRows="20"
                  type="text"
                  placeholder="Опишите иные условия для внесения в договор или прикрепите ваш проект договора"
                  formControlName="misc"
                  (ngModelChange)="setValue()"></textarea>
        </div>
        <div class="conditions__complex--top conditions__add-doc conditions__add-doc--flex">
          <input type="file"
                 id="file"
                 formControlName="file"
                 (change)="this.conditionsService.fileChangeEvent($event, files, fileLinks)"
                 (ngModelChange)="setValue()">
          <label for="file">
            <span class="conditions__text-blue">Прикрепить ваш проект договора в формате PDF или Word</span>
            <span class="conditions__text-gray">(Не обязательно)</span>
          </label>
          <p class="file-error" *ngIf="conditionsService.fileError?.length">{{conditionsService.fileError}}</p>
          <div style="display: flex" *ngFor="let file of files, index as i">
            <span>{{trimString(file.fileName, 30)}}</span>
            <span class="conditions__delete" (click)="this.conditionsService.removeFile(i, files)"></span>
          </div>
        </div>
      </div>

    </div>
    <!--  <button type="submit">console</button>-->
  </form>

  <div *ngIf="contractType ==='completeAlienation'|| contractType ==='partialAlienation'">
    <app-short-form [contractType]="contractType"></app-short-form>
  </div>

  <div *ngIf="contractType ===''"><p style="margin-bottom: 20px">Тут могла бы быть ваша реклама</p></div>
</div>

<ng-template #load>
  <div class="loader"></div>
</ng-template>

