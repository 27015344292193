import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkState } from '@web/features/requests/trademark/store/trademark.state';
import { LastRequestTrademark } from '@web/features/requests/trademark/types/trademark.types';

@Component({
  selector: 'app-last-trademark',
  templateUrl: './last-trademark.component.html',
  styleUrls: ['./last-trademark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastTrademarkComponent {
  @ViewSelectSnapshot(TrademarkState.lastTrademark) lastTrademark: LastRequestTrademark | null;
}
