import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SearchService } from '../../../../../services/search.service';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-foreign-trademark-modal',
  templateUrl: './foreign-trademark-modal.component.html',
  styleUrls: ['./foreign-trademark-modal.component.scss']
})
export class ForeignTrademarkModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() modalEvent = new EventEmitter();
  private readonly onDestroy = new Subject<void>();
  sourceData: any = {};
  isLoading = false;
  viewClassesCount = 'short';
  contractsData = [];
  overTradeMarks;
  countOverTradeMarks;
  isOpenLogo = false;
  isShowLogoModal = false;

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  constructor(
    private searchService: SearchService,
    private corp: CorpSearchService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '0';
    }
    this.getDocById();
  }

  getOverTradeMarks() {
    const query = {
      id: this.sourceData._source.PSRN ? this.sourceData._source.PSRN.toString() : this.sourceData._source.TIN.toString(),
      searchTradeMarks: true
    };
    this.searchService.getOverTradeMarksOrDomains(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.hits && data.hits.hits) {
        data.hits.hits.forEach((item) => {
          let id = item._source.registrationString;
          if (item._index === 'rutmap' && item._source.applicationString) {
            id = item._source.applicationString;
          }
          item._source.imageUrl = this.searchService.getImgUrl(
            item._index,
            parseInt(id, 10),
            item._source.markImageFileName,
            id
          );
          item._source.indexType = this.searchService.getIndexType(item);
        });

        this.overTradeMarks = data.hits.hits;
        this.countOverTradeMarks = data.hits.total?.tradeMarks;

        let deleteIndex;
        this.overTradeMarks.forEach((item, index) => {
          if (item._source.indexType === this.sourceData.indexType) {
            deleteIndex = index;
          }
        });
        if (deleteIndex || (deleteIndex === 0)) {
          this.overTradeMarks.splice(deleteIndex, 1);
        }
        this.ref.detectChanges();
      }
    });
  }


  getDocById() {
    const query = {
      _id: this.data._id,
      _index: this.data._index
    };
    this.isLoading = true;
    this.searchService.getDocumentById(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits?.length) {
        this.sourceData = data.hits.hits[0];
        this.sourceData._source.goodsServices.forEach((item, index) => {
          if (item.classNumber === 0) {
            this.sourceData._source.goodsServices.splice(index, 1);
          }
        });
        this.sourceData.indexType = this.searchService.getIndexType(this.sourceData);
        this.isLoading = false;
        this.ref.detectChanges();
        this.createContractsData();
        if (this.sourceData._source.PSRN || this.sourceData._source.TIN) {
          this.getOverTradeMarks();
        }
      }
      console.log(this.sourceData, 'test');
    });
  }

  getExternalLink(index) {
    return this.corp.getExternalUrl(index);
  }

  createContractsData() {
    if (this.sourceData._source.contracts) {
      this.sourceData._source.contracts.forEach(item => {
        if (item.contractNumber !== 'Нет договора') {
          this.contractsData.push(item);
        }
        if (item.subContracts && item.subContracts.length > 0) {
          this.contractsData = this.contractsData.concat(item.subContracts);
        }
      });
      this.ref.detectChanges();
    }
  }

  getMktuClasses() {
    if (this.viewClassesCount === 'long') {
      return this.sourceData._source.goodsServices;
    } else {
      return this.sourceData._source.goodsServices.slice(0, 3);
    }
  }

  showAllClasses() {
    this.viewClassesCount = 'long';
  }

  getStatusTrademark(sourceData): string {
    let status = '';
    switch (sourceData._source.statusFIPS) {
      case 1:
        status = 'Действует';
        break;
      case 2:
        status = 'Может прекратить действие';
        break;
      case 3:
        status = 'Не действует';
        break;
      case 0:
      default:
        break;
    }
    return status;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  closeModal() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '1';
    }
    this.modalEvent.emit();
  }

  showLogoModal(): void {
    this.isShowLogoModal = !this.isShowLogoModal;
  }
}
