<div class="container inset">
  <div class="events-steps">
    <div *ngIf="testData.length > eventsLength" class="events-steps__showMore-wrapper">
      <span *ngIf="!showMore" class="events-steps__showMore-line"></span>
      <div *ngIf="!showMore" (click)="showMore=true" class="events-steps__showMore">Показать
        предыдущие {{testData.length - eventsLength}} событий
      </div>
      <div *ngIf="showMore" class="events-steps__rows-wrapper events-steps__rows-wrapper--show">
        <!--Пример того как будут открываться вкладки, когда будут данные-->
        <div *ngFor="let point of testData.slice(0, testData.length)" class="events-steps__row">
          <div class="events-steps__info">
            <div class="events-steps__user">
              <div class="events-steps__img">
                <img src="/assets/images/user.svg">
              </div>
              <div *ngIf="point.name" class="events-steps__name events-steps__name--human">
                <p>{{point.name}}</p>
              </div>
              <div *ngIf="point.company" class="events-steps__name">
                <p>{{point.company}}</p>
              </div>
            </div>
            <div class="events-steps__state" [class.events-steps__state--show]="cardShown === point.id">
              <div (click)="showCard(point.id)" [class.show]="cardShown === point.id" class="events-steps__btn-show">
                <span class="events-steps__link">Посмотреть</span>
                <div *ngIf="point.type ===1" class="events-steps__hidden">
                  <div class="docs__main-wrapper docs__main-wrapper--card">
                    <h4>Документы по претензии</h4>
                    <div class="docs__blocks">
                      <div class="docs__block">
                        <div class="docs__text-wrapper">
                          <span>Претензия</span>
                          <a href="" download>nazvanie_dogovora.pdf</a>
                        </div>
                        <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                           <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                           <img src="/assets/icons/gray-empty.svg">
                        </span>
                        </div>
                      </div>
                      <div class="docs__block">
                        <div class="docs__text-wrapper">
                          <span>Претензия</span>
                          <a href="" download>nazvanie_dogovora.pdf</a>
                        </div>
                        <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                        </span>
                        </div>
                      </div>
                      <div class="docs__block">
                        <div class="docs__text-wrapper">
                          <span>Претензия</span>
                          <a href="" download>nazvanie_dogovora.pdf</a>
                        </div>
                        <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                        </span>
                        </div>
                      </div>
                      <div class="docs__block">
                        <div class="docs__text-wrapper">
                          <span>Претензия</span>
                          <a href="" download>nazvanie_dogovora.pdf</a>
                        </div>
                        <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                        </span>
                        </div>
                      </div>
                      <div class="docs__block">
                        <div class="docs__text-wrapper">
                          <span>Претензия</span>
                          <a href="" download>nazvanie_dogovora.pdf</a>
                        </div>
                        <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                         </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="point.type ===2" class="events-steps__hidden">
                  <div class="events-steps__hidden-text-wrapper hidden">
                    <h3 class="hidden__short">Вам необходимо добавить документы с доказательствами</h3>
                    <p>Текст с описанием того, что такое доказательства.</p>
                  </div>
                  <div class="events-steps__hidden-btn-wrapper">
                    <button class="btn-blue-small">Добавить документы</button>
                  </div>
                </div>
                <div *ngIf="point.type ===3" class="events-steps__hidden">
                  <div class="events-steps__hidden-text-wrapper hidden">
                    <h3>Получено решение арбитражного суда. Иск удовлетворен частично.</h3>
                    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                      <div class="hidden__blocks-table-wrapper">
                        <h4>Рекомендация</h4>
                        <p>Иск удволетворен частично</p>
                        <table class="hidden-table">
                          <tr>
                            <th>Состав требований</th>
                            <th>Сумма требований</th>
                            <th>Присуждено судом</th>
                            <th>Разница</th>
                          </tr>
                          <tr>
                            <td>Основной долг</td>
                            <td><p>Сумма требований</p>80 000 ₽</td>
                            <td><p>Присуждено судом</p>80 000 ₽</td>
                            <td><p>Разница</p> 0 ₽</td>
                          </tr>
                          <tr>
                            <td>Неустойка</td>
                            <td><p>Сумма требований</p>20 000 ₽</td>
                            <td><p>Присуждено судом</p>20 000 ₽</td>
                            <td class="hidden-table__minus">
                              <p>Разница</p>
                              <span class="hidden-table__sum">
                            -15 000 ₽
                              <span class="hidden-table__question">?
                                <span class="hidden-table__prompt">
                                   <p>Мы заявили сумму неустойки, рассчитанную по условиям договора. Суд посчитал неустойку не
                                    соразмерной факту нарушения. И расчитал сумму неустойки в соответвии с ГК РФ.
                                   </p>
                                  </span>
                               </span>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Судебные расходы</td>
                            <td><p>Сумма требований</p>19 890 ₽</td>
                            <td><p>Присуждено судом</p>14 240 ₽</td>
                            <td class="hidden-table__minus">
                              <p>Разница</p>
                              <span class="hidden-table__sum">
                            -5 240 ₽
                              <span class="hidden-table__question">?
                                <span class="hidden-table__prompt">
                                   <p>Мы заявили сумму неустойки, рассчитанную по условиям договора. Суд посчитал неустойку не
                                    соразмерной факту нарушения. И расчитал сумму неустойки в соответвии с ГК РФ.
                                   </p>
                                  </span>
                               </span>
                              </span>
                            </td>
                          </tr>
                          <tr class="hidden-table__bold">
                            <td>Итого</td>
                            <td>119 890 ₽</td>
                            <td>85 100 ₽</td>
                            <td>-20 240 ₽</td>
                          </tr>
                        </table>
                        <p>
                          Мы считаем, что данное решение выгодное и рекомендуем согласиться с ним. Однако, окончательное
                          решение всегда остается за вами, мы готовы его поддержать и обжаловать в вышестоящей
                          инстанции.
                        </p>
                      </div>
                      <div class="hidden__blocks-after-table">
                        <div class="hidden__block">
                          <h4 class="hidden__subtitle">Решение арбитражного суда</h4>
                          <p>Скачать решение арбитражного суда</p>
                          <div class="docs__block">
                            <div class="docs__text-wrapper">
                              <span>Решение суда</span>
                              <a href="" download>nazvanie_dogovora.pdf</a>
                            </div>
                            <div class="docs__icon-wrapper">
                             <span class="docs__icon">
<!--                               <img src="/assets/images/green-key.png" class ="docs__icon--mini">-->
                               <img src="/assets/icons/blue-empty.svg">
                             </span>
                            </div>
                          </div>
                        </div>
                        <div class="hidden__block">
                          <h4 class="hidden__subtitle">Ваше решение</h4>
                          <p>Если вы соглашаетесь с решением суда, то мы можем приступить к взысканию сразу после
                            получения
                            исполнительного листа.
                          </p>
                          <form>
                            <fieldset>
                              <div class="hidden__input-wrapper input-checkbox">
                                <input type="radio" name="agreement" id="225">
                                <label class="input-checkbox__label" for="225">Согласиться с решением суда
                                </label>
                              </div>
                              <div class="hidden__input-wrapper input-checkbox">
                                <input type="radio" name="agreement" id="23">
                                <label class="input-checkbox__label" for="23">Обжаловать решение суда
                                </label>
                              </div>
                            </fieldset>
                            <div class="hidden__btn-wrapper">
                              <button type="submit" class='btn-blue'>Продолжить</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf="point.type ===4" class="events-steps__hidden">
                  <div class="attention attention--in-card">
                    <h3>Важно!</h3>
                    <p>Вы оплатили претензионную работу по тарифу <b>«Оптимальный»</b>. Сумма оплаты <b>13 890 ₽</b>.
                      Дата оплаты 01.10.2020</p>
                    <div tabindex="0" class="attention__show" (click)="showServicesList=!showServicesList"
                         [class.attention__show--open]="showServicesList">
                      <span
                        class="attention__link">{{showServicesList ? 'Скрыть состав услуг' : 'Посмотреть состав услуг'}}</span>
                      <ul *ngIf="showServicesList" class="attention__list">
                        <li>
                          Формирование доказательной базы для суда
                        </li>
                        <li>
                          Расчет государственной пошлины
                        </li>
                        <li>
                          Подготовка искового заявления
                        </li>
                        <li>
                          Оплата государственной пошлины за истца
                        </li>
                        <li>
                          Подача иска и контроль возбуждения арбитражного дела
                        </li>
                        <li>
                          Анализ доказательств ответчика и письменные пояснения на его отзыв
                        </li>
                        <li>
                          Получение, проверка судебного решения и исполнительного листа на ошибки
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="events-steps__hidden--mini-block">
                    <h4>Кто должник</h4>
                    <p>Не знаю</p>
                  </div>
                  <div class="events-steps__hidden--mini-block">
                    <h4>Что случилось</h4>
                    <p>Поставили товары, оказали услуги не качественно</p>
                  </div>
                  <div class="events-steps__hidden--mini-block">
                    <h4>Что вы хотите от должника</h4>
                    <p>Иное: Меня обманули при поставке товаров, из 10 вскрытых коробок только в 2х оказался товар
                      надлежащего качества.</p>
                  </div>
                </div>
                <div *ngIf="point.type ===5" class="events-steps__hidden">
                  <div class="events-steps__hidden--mini-block">
                    <h4>Кто должник</h4>
                    <p>Не знаю</p>
                  </div>
                  <div class="events-steps__hidden--mini-block">
                    <h4>Что случилось</h4>
                    <p>Поставили товары, оказали услуги не качественно</p>
                  </div>
                  <div class="events-steps__hidden--mini-block">
                    <h4>Что вы хотите от должника</h4>
                    <p>Иное: Меня обманули при поставке товаров, из 10 вскрытых коробок только в 2х оказался товар
                      надлежащего качества.</p>
                  </div>
                </div>
                <div *ngIf="point.type ===6" class="events-steps__hidden">
                  <div class="attention attention--white attention--in-card ">
                    <p>Вы оплатили тариф <b>«Все включено»</b>. Сумма оплаты <b>16 890 ₽</b>. Дата оплаты 01.10.2020</p>
                    <div tabindex="0" class="attention__show" (click)="showServicesList=!showServicesList"
                         [class.attention__show--open]="showServicesList">
                      <span
                        class="attention__link">{{showServicesList ? 'Скрыть состав услуг' : 'Посмотреть состав услуг'}}</span>
                      <ul *ngIf="showServicesList" class="attention__list">
                        <li>
                          Формирование доказательной базы для суда
                        </li>
                        <li>
                          Расчет государственной пошлины
                        </li>
                        <li>
                          Подготовка искового заявления
                        </li>
                        <li>
                          Оплата государственной пошлины за истца
                        </li>
                        <li>
                          Подача иска и контроль возбуждения арбитражного дела
                        </li>
                        <li>
                          Анализ доказательств ответчика и письменные пояснения на его отзыв
                        </li>
                        <li>
                          Получение, проверка судебного решения и исполнительного листа на ошибки
                        </li>
                        <li>
                          Розыск открытых счетов должника во всех банках
                        </li>
                        <li>
                          Подготовка заявления на взыскание и подача в банк
                        </li>
                        <li>
                          Предъявление исполнительного листа по очередно в каждый банк
                        </li>
                        <li>
                          Переговоры с должником по фактическому исполнению судебного решения
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="point.type ===7" class="events-steps__hidden">
                  <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
                    <h3>Проведено основное судебное заседание. Иск удовлетворен полностью.</h3>
                    <div class="hidden__blocks-wrapper">
                      <div class="hidden__block hidden__block--no-line">
                        <h4 class="hidden__subtitle">Содержание решения</h4>
                        <ul class="hidden__list">
                          <li>
                            Иск удволетворен полностью
                          </li>
                          <li>
                            Присуждена сумма основного долга в размере <b>80 000 ₽</b>
                          </li>
                          <li>
                            Присуждена сумма неустойки в размере <b>20 000 ₽</b>
                          </li>
                          <li>
                            Присуждена сумма судебных расходов в размере <b>19 890 ₽</b>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf="point.type ===8" class="events-steps__hidden">
                  <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
                    <h3>Истек срок ожидания ответа на претензию</h3>
                    <div class="attention attention--in-card">
                      <h3>Рекомендация</h3>
                      <p>Должник не ответил на претензию в течении установленного законом срока. Возможно он произвел
                        оплату
                        или ответил вам напрямую, о чем мы не были уведомлены. Если вы не получили оплату напрямую от
                        должника,
                        либо должник не ответил вам, рекомендуем обратиться в суд.</p>
                    </div>
                    <div class="hidden__blocks-wrapper">
                      <div class="hidden__block">
                        <h4 class="hidden__subtitle">направленная претензия должнику</h4>
                        <p class="hidden__text">Вы можете посмотреть претензию которую мы отправили должнику
                          10.08.2020.</p>
                        <div class="docs__block">
                          <div class="docs__text-wrapper">
                            <span>Претензия</span>
                            <a href="" download>nazvanie_dogovora.pdf</a>
                          </div>
                          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/green-key.png" class="docs__icon--mini">
                             <img src="/assets/icons/orange-empty.svg">
                          </span>
                          </div>
                        </div>
                      </div>
                      <div class="hidden__block">
                        <h4 class="hidden__subtitle">Ваше решение</h4>
                        <div class="hidden__choice">
                          <p>Получил оплату напрямую</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf="point.type ===9" class="events-steps__hidden">
                  <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
                    <h3>Скачайте, подпишите и отправьте доверенность</h3>
                    <div class="hidden__blocks-wrapper">
                      <div class="hidden__block">
                        <h4 class="hidden__subtitle">Шаг 1</h4>
                        <p class="hidden__text">Мы подготовили доваренность, которая необходима для взаимодействия с
                          должником.
                          Скачайте, распечатайте и подпишите доверенность. Прикрепите скан или фото доверенности с вашей
                          подписью и печатью на следующем шаге.</p>
                        <div class="docs__block">
                          <div class="docs__text-wrapper">
                            <span>Доверенность</span>
                            <a href="" download>nazvanie_dogovora.pdf</a>
                          </div>
                          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/red-attention.png" class="docs__icon--mini">
                            <img src="/assets/icons/orange-empty.svg">
                          </span>
                          </div>
                        </div>
                      </div>
                      <div class="hidden__block">
                        <h4 class="hidden__subtitle">Шаг 2</h4>
                        <p>Прикрепите подписанный скан/фото доверенности или отправьте файл через
                          <a class="hidden__chat">Chat_Gardium</a></p>
                        <form class="add-file-form">
                          <fieldset>
                            <div class="add-file-form__file">
                              <p>Doverennost.JPG</p>
                              <span></span>
                            </div>
                            <div class="add-file-form__block add-file">
                            <span class="prompt prompt__question">?
                              <span class="prompt__hidden">
                                   <p>???</p>
                                </span>
                             </span>
                              <input type="file" id="doc">
                              <label class="add-file__label" for="doc">Прикрепить скан/фото доверенности</label>
                              <span
                                class="add-file__subtitle add-file__subtitle--no-hover">Изображение, не более 2мб</span>
                            </div>
                          </fieldset>
                          <button type="submit" class="btn-blue">Отправить доверенность</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="point.type ===10" class="events-steps__hidden">
                  <div class="events-steps__hidden-text-wrapper hidden">
                    <h3>Мы подготовили претензию</h3>
                    <div class="hidden__blocks-wrapper">
                      <div class="hidden__block ">
                        <h4 class="hidden__subtitle">Что сделано</h4>
                        <ul class="hidden__list">
                          <li>
                            Проанализирован пакет документов по долгу
                          </li>
                          <li>
                            Определены основания возникновения долга
                          </li>
                          <li>
                            Установлены статьи ГК РФ для предъявления требований
                          </li>
                          <li>
                            Определена дата возникновения долга
                          </li>
                          <li>
                            Рассчитана сумма основного долга
                          </li>
                          <li>
                            Рассчитана сумма пени
                          </li>
                        </ul>
                      </div>
                      <div class="hidden__block">
                        <h4 class="hidden__subtitle">претензия</h4>
                        <p>Скачать претензию для ознакомления</p>
                        <div class="docs__block">
                          <div class="docs__text-wrapper">
                            <span>претензия</span>
                            <a href="" download>nazvanie_dogovora.pdf</a>
                          </div>
                          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
<!--                            <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                            <img src="/assets/icons/blue-empty.svg">
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div *ngIf="point.type ===11" class="events-steps__hidden events-steps__hidden--special">
                  <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm===true"
                       class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                    <h3>Претензия удволетворена с условием <span></span></h3>
                    <div class="attention attention--in-card">
                      <h3>Рекомендация</h3>
                      <p>Должник согласился удволетворить главное требование по претензии и заплатить основной долг в
                        сумме
                        80 000 ₽, но отказался платить пени и неустойку в размере 20 000 ₽. Мы считаем, что данное
                        решение
                        выгодное и рекомендуем его принять. Однако, окончательное решение всегда остается за вами, мы
                        готовы
                        его поддержать и продолжить работу по взысканию.</p>
                    </div>
                    <div class="hidden__blocks-wrapper">
                      <div class="hidden__block">
                        <h4 class="hidden__subtitle">Ответ от должника</h4>
                        <p class="hidden__text">Пожалуйста, скачайте официальный ответ от должника для изучения. Нужен
                          текст.</p>
                        <div class="docs__block">
                          <div class="docs__text-wrapper">
                            <span>Ответ #1</span>
                            <a href="" download>nazvanie_dogovora.pdf</a>
                          </div>
                          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/green-key.png" class="docs__icon--mini">
                             <img src="/assets/icons/orange-empty.svg">
                          </span>
                          </div>
                        </div>
                      </div>
                      <div class="hidden__block">
                        <h4 class="hidden__subtitle">Ваше решение</h4>
                        <p>Возможно тут лучше разместить текст с информацией о возможном выборе и дискуссии с
                          должником.</p>
                        <form>
                          <fieldset>
                            <div class="hidden__input-wrapper input-checkbox">
                              <input type="radio" name="agreement" id="5">
                              <label class="input-checkbox__label" for="5">Полностью согласен с условиями должника
                              </label>
                            </div>
                            <div class="hidden__input-wrapper input-checkbox">
                              <input type="radio" name="agreement" id="6">
                              <label class="input-checkbox__label" for="6">Подготовить альтернативное предложение
                              </label>
                            </div>
                            <div class="hidden__input-wrapper input-checkbox">
                              <input type="radio" name="agreement" id="7">
                              <label class="input-checkbox__label" for="7">Не согласен, подать в суд
                              </label>
                            </div>
                          </fieldset>
                          <div class="hidden__btn-wrapper">
                            <button type="submit" class='btn-blue'
                                    (click)="toHideForm=true; toHideForm1=false; toHideForm2=true; toHideForm3=true;">
                              Продолжить
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="attention attention--gray" *ngIf="attention && toHideForm===false">
                    <p><b>Важно.</b> Банк может перечислить денежные средства на ваш счет, не уведомляя нас об этом.
                      Если вы
                      получили деньги, пожалуйста сообщите нам для завершения делопроизводства. Если деньги не будут
                      получены до 27.10.2020, то мы направим заявление в следующий банк.</p>
                    <a class="btn-blue-attr" (click)="attention=false; attentionStep1=true">
                      Получил оплату
                    </a>
                  </div>
                  <div *ngIf="attentionStep1 && toHideForm===false">
                    <div class="hidden__block hidden__block--separating">
                      <h4 class="hidden__subtitle">Получил оплату напрямую</h4>
                      <form>
                        <fieldset>
                          <div class="hidden__input-wrapper input-checkbox">
                            <input type="radio" name="agreement" id="3" (change)="showOptions=false">
                            <label class="input-checkbox__label" for="3">Согласиться с решением суда
                            </label>
                          </div>
                          <div class="hidden__input-wrapper input-checkbox">
                            <input type="radio" name="agreement" id="4" (change)="showOptions=true">
                            <label class="input-checkbox__label" for="4">Обжаловать решение суда
                            </label>
                          </div>
                          <div class="hidden__input-text-wrapper" *ngIf="showOptions">
                            <div class="input-text">
                              <input type="number" placeholder="Пожалуйста уточните сумму">
                            </div>
                            <div class="input-text">
                              <input type="number" placeholder="Пожалуйста укажите дату оплаты">
                            </div>
                          </div>
                        </fieldset>
                        <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                          <a type="submit" class='btn-blue-attr'
                             (click)="attention=true; attentionStep1=false">Отменить</a>
                          <button type="submit" class='btn-blue'>Отправить</button>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm1===true"
                       class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                    <h3>Полностью согласен с условиями должника</h3>
                    <div class="attention attention--in-card">
                      <h3>Рекомендация</h3>
                      <p>Не смотря на согласие должника оплатить долг, рекомендуется зафиксировать данные обязательства
                        в
                        дополнительном соглашении об изменении порядка расчетов. Однако, можно подождать добровольное
                        исполнение
                        должником выплаты долга в течении разумного времени. Итоговое решение остается за вами.</p>
                    </div>
                    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                      <h4 class="hidden__subtitle">Ваше решение</h4>
                      <form>
                        <fieldset>
                          <div class="hidden__input-wrapper input-checkbox">
                            <input type="radio" name="agreement" id="8">
                            <label class="input-checkbox__label" for="8">Подготовить и согласовать дополнительное
                              соглашение с должником за 1 490 ₽
                            </label>
                          </div>
                          <div class="hidden__input-wrapper input-checkbox">
                            <input type="radio" name="agreement" id="9">
                            <label class="input-checkbox__label" for="9">Не хочу дополнительное соглашение, подожду
                              оплату
                            </label>
                          </div>
                          <div class="hidden__input-wrapper input-checkbox">
                            <input type="radio" name="agreement" id="10">
                            <label class="input-checkbox__label" for="10">Составлю сам дополнительное соглашение
                            </label>
                          </div>
                        </fieldset>
                        <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                          <a type="submit" class='btn-blue-attr'
                             (click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;">Отменить</a>
                          <button type="submit" class='btn-blue'
                                  (click)="toHideForm=true; toHideForm1=true; toHideForm2=false; toHideForm3=true;">
                            Отправить
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm2===true"
                       class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                    <h3>Подготовить альтернативное предложение</h3>
                    <div class="attention attention--in-card">
                      <h3>Рекомендация</h3>
                      <p>Предоставьте информацию в свободной форме как вы хотели бы урегулировать долг, а мы подготовим
                        и
                        отправим должнику официальный ответ с альтернативным предложением.</p>
                    </div>
                    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                      <h4 class="hidden__subtitle">Пожалуйста, уточните ваши условия</h4>
                      <form>
                        <fieldset>
                          <div class="hidden__input-wrapper input-textarea">
                            <textarea placeholder="Ваши условия" rows="6"></textarea>
                          </div>
                        </fieldset>
                        <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                          <a type="submit" class='btn-blue-attr'
                             (click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;">Отменить</a>
                          <button type="submit" class='btn-blue'
                                  (click)="toHideForm=true; toHideForm1=true; toHideForm2=true; toHideForm3=false;">
                            Отправить
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm3===true"
                       class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                    <h3>Не согласен, подать в суд</h3>
                    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                      <form>
                        <div class="attention attention--in-card">
                          <h3>Важно!</h3>
                          <p>Сумма долга и характер требований предусматривает упрощенный порядок рассмотрения спора
                            арбитражным
                            судом. Это означает, что дело будет рассмотрено:</p>
                          <ul class="attention__list">
                            <li>
                              без вызова сторон
                            </li>
                            <li>
                              без проведения судебного заседания
                            </li>
                            <li>
                              основываясь на иске, отзыве на иск и письменных доказательствах
                            </li>
                          </ul>
                          <p>Решение вступит в силу незамедлительно, а исполнительный лист будет выдан сразу после
                            изготовления текста решения в полном объеме.</p>
                        </div>
                        <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                          <a type="submit" class='btn-blue-attr'
                             (click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;">Отменить</a>
                          <button type="submit" class='btn-blue'>Отправить</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <input type="text" class="to-hide" value="Скрыть" readonly (click)="hideCard()">
              <p>{{point.title}}</p>
            </div>
            <div class="events-steps__date">
              <p>{{point.date}},{{point.time}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngFor="let point of testData.length> eventsLength ? testData.slice(testData.length-eventsLength,testData.length) : testData"
      class="events-steps__row">
      <div class="events-steps__info">
        <div class="events-steps__user">
          <div class="events-steps__img">
            <img src="/assets/images/woman2.jpg">
          </div>
          <div *ngIf="point.name" class="events-steps__name events-steps__name--human">
            <p>{{point.name}}</p>
          </div>
          <div *ngIf="point.company" class="events-steps__name">
            <p>{{point.company}}</p>
          </div>
        </div>
        <div class="events-steps__state" [class.events-steps__state--show]="cardShown === point.id">
          <div (click)="showCard(point.id)" [class.show]="cardShown === point.id" class="events-steps__btn-show">
            <span class="events-steps__link">Посмотреть</span>
            <div *ngIf="point.type ===1" class="events-steps__hidden">
              <div class="docs__main-wrapper docs__main-wrapper--card">
                <h4>Документы по претензии</h4>
                <div class="docs__blocks">
                  <div class="docs__block">
                    <div class="docs__text-wrapper">
                      <span>Претензия</span>
                      <a href="" download>nazvanie_dogovora.pdf</a>
                    </div>
                    <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                           <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                           <img src="/assets/icons/gray-empty.svg">
                        </span>
                    </div>
                  </div>
                  <div class="docs__block">
                    <div class="docs__text-wrapper">
                      <span>Претензия</span>
                      <a href="" download>nazvanie_dogovora.pdf</a>
                    </div>
                    <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                        </span>
                    </div>
                  </div>
                  <div class="docs__block">
                    <div class="docs__text-wrapper">
                      <span>Претензия</span>
                      <a href="" download>nazvanie_dogovora.pdf</a>
                    </div>
                    <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                        </span>
                    </div>
                  </div>
                  <div class="docs__block">
                    <div class="docs__text-wrapper">
                      <span>Претензия</span>
                      <a href="" download>nazvanie_dogovora.pdf</a>
                    </div>
                    <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                        </span>
                    </div>
                  </div>
                  <div class="docs__block">
                    <div class="docs__text-wrapper">
                      <span>Претензия</span>
                      <a href="" download>nazvanie_dogovora.pdf</a>
                    </div>
                    <div class="docs__icon-wrapper">
                        <span class="docs__icon">
<!--                          <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                          <img src="/assets/icons/gray-empty.svg">
                         </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="point.type ===2" class="events-steps__hidden">
              <div class="events-steps__hidden-text-wrapper hidden">
                <h3 class="hidden__short">Вам необходимо добавить документы с доказательствами</h3>
                <p>Текст с описанием того, что такое доказательства.</p>
              </div>
              <div class="events-steps__hidden-btn-wrapper">
                <button class="btn-blue-small">Добавить документы</button>
              </div>
            </div>
            <div *ngIf="point.type ===3" class="events-steps__hidden">
              <div class="events-steps__hidden-text-wrapper hidden">
                <h3>Получено решение арбитражного суда. Иск удовлетворен частично.</h3>
                <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                  <div class="hidden__blocks-table-wrapper">
                    <h4>Рекомендация</h4>
                    <p>Иск удволетворен частично</p>
                    <table class="hidden-table">
                      <tr>
                        <th>Состав требований</th>
                        <th>Сумма требований</th>
                        <th>Присуждено судом</th>
                        <th>Разница</th>
                      </tr>
                      <tr>
                        <td>Основной долг</td>
                        <td><p>Сумма требований</p>80 000 ₽</td>
                        <td><p>Присуждено судом</p>80 000 ₽</td>
                        <td><p>Разница</p> 0 ₽</td>
                      </tr>
                      <tr>
                        <td>Неустойка</td>
                        <td><p>Сумма требований</p>20 000 ₽</td>
                        <td><p>Присуждено судом</p>20 000 ₽</td>
                        <td class="hidden-table__minus">
                          <p>Разница</p>
                          <span class="hidden-table__sum">
                            -15 000 ₽
                              <span class="hidden-table__question">?
                                <span class="hidden-table__prompt">
                                   <p>Мы заявили сумму неустойки, рассчитанную по условиям договора. Суд посчитал неустойку не
                                    соразмерной факту нарушения. И расчитал сумму неустойки в соответвии с ГК РФ.
                                   </p>
                                  </span>
                               </span>
                              </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Судебные расходы</td>
                        <td><p>Сумма требований</p>19 890 ₽</td>
                        <td><p>Присуждено судом</p>14 240 ₽</td>
                        <td class="hidden-table__minus">
                          <p>Разница</p>
                          <span class="hidden-table__sum">
                            -5 240 ₽
                              <span class="hidden-table__question">?
                                <span class="hidden-table__prompt">
                                   <p>Мы заявили сумму неустойки, рассчитанную по условиям договора. Суд посчитал неустойку не
                                    соразмерной факту нарушения. И расчитал сумму неустойки в соответвии с ГК РФ.
                                   </p>
                                  </span>
                               </span>
                              </span>
                        </td>
                      </tr>
                      <tr class="hidden-table__bold">
                        <td>Итого</td>
                        <td>119 890 ₽</td>
                        <td>85 100 ₽</td>
                        <td>-20 240 ₽</td>
                      </tr>
                    </table>
                    <p>
                      Мы считаем, что данное решение выгодное и рекомендуем согласиться с ним. Однако, окончательное
                      решение всегда остается за вами, мы готовы его поддержать и обжаловать в вышестоящей инстанции.
                    </p>
                  </div>
                  <div class="hidden__blocks-after-table">
                    <div class="hidden__block">
                      <h4 class="hidden__subtitle">Решение арбитражного суда</h4>
                      <p>Скачать решение арбитражного суда</p>
                      <div class="docs__block">
                        <div class="docs__text-wrapper">
                          <span>Решение суда</span>
                          <a href="" download>nazvanie_dogovora.pdf</a>
                        </div>
                        <div class="docs__icon-wrapper">
                             <span class="docs__icon">
<!--                               <img src="/assets/images/green-key.png" class ="docs__icon--mini">-->
                               <img src="/assets/icons/blue-empty.svg">
                             </span>
                        </div>
                      </div>
                    </div>
                    <div class="hidden__block">
                      <h4 class="hidden__subtitle">Ваше решение</h4>
                      <p>Если вы соглашаетесь с решением суда, то мы можем приступить к взысканию сразу после получения
                        исполнительного листа.
                      </p>
                      <form>
                        <fieldset>
                          <div class="hidden__input-wrapper input-checkbox">
                            <input type="radio" name="agreement" id="225">
                            <label class="input-checkbox__label" for="225">Согласиться с решением суда
                            </label>
                          </div>
                          <div class="hidden__input-wrapper input-checkbox">
                            <input type="radio" name="agreement" id="23">
                            <label class="input-checkbox__label" for="23">Обжаловать решение суда
                            </label>
                          </div>
                        </fieldset>
                        <div class="hidden__btn-wrapper">
                          <button type="submit" class='btn-blue'>Продолжить</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div *ngIf="point.type ===4" class="events-steps__hidden">
              <div class="attention attention--in-card">
                <h3>Важно!</h3>
                <p>Вы оплатили претензионную работу по тарифу <b>«Оптимальный»</b>. Сумма оплаты <b>13 890 ₽</b>. Дата
                  оплаты 01.10.2020</p>
                <div tabindex="0" class="attention__show" (click)="showServicesList=!showServicesList"
                     [class.attention__show--open]="showServicesList">
                  <span
                    class="attention__link">{{showServicesList ? 'Скрыть состав услуг' : 'Посмотреть состав услуг'}}</span>
                  <ul *ngIf="showServicesList" class="attention__list">
                    <li>
                      Формирование доказательной базы для суда
                    </li>
                    <li>
                      Расчет государственной пошлины
                    </li>
                    <li>
                      Подготовка искового заявления
                    </li>
                    <li>
                      Оплата государственной пошлины за истца
                    </li>
                    <li>
                      Подача иска и контроль возбуждения арбитражного дела
                    </li>
                    <li>
                      Анализ доказательств ответчика и письменные пояснения на его отзыв
                    </li>
                    <li>
                      Получение, проверка судебного решения и исполнительного листа на ошибки
                    </li>
                  </ul>
                </div>
              </div>
              <div class="events-steps__hidden--mini-block">
                <h4>Кто должник</h4>
                <p>Не знаю</p>
              </div>
              <div class="events-steps__hidden--mini-block">
                <h4>Что случилось</h4>
                <p>Поставили товары, оказали услуги не качественно</p>
              </div>
              <div class="events-steps__hidden--mini-block">
                <h4>Что вы хотите от должника</h4>
                <p>Иное: Меня обманули при поставке товаров, из 10 вскрытых коробок только в 2х оказался товар
                  надлежащего качества.</p>
              </div>
            </div>
            <div *ngIf="point.type ===5" class="events-steps__hidden">
              <div class="events-steps__hidden--mini-block">
                <h4>Кто должник</h4>
                <p>Не знаю</p>
              </div>
              <div class="events-steps__hidden--mini-block">
                <h4>Что случилось</h4>
                <p>Поставили товары, оказали услуги не качественно</p>
              </div>
              <div class="events-steps__hidden--mini-block">
                <h4>Что вы хотите от должника</h4>
                <p>Иное: Меня обманули при поставке товаров, из 10 вскрытых коробок только в 2х оказался товар
                  надлежащего качества.</p>
              </div>
            </div>
            <div *ngIf="point.type ===6" class="events-steps__hidden">
              <div class="attention attention--white attention--in-card ">
                <p>Вы оплатили тариф <b>«Все включено»</b>. Сумма оплаты <b>16 890 ₽</b>. Дата оплаты 01.10.2020</p>
                <div tabindex="0" class="attention__show" (click)="showServicesList=!showServicesList"
                     [class.attention__show--open]="showServicesList">
                  <span
                    class="attention__link">{{showServicesList ? 'Скрыть состав услуг' : 'Посмотреть состав услуг'}}</span>
                  <ul *ngIf="showServicesList" class="attention__list">
                    <li>
                      Формирование доказательной базы для суда
                    </li>
                    <li>
                      Расчет государственной пошлины
                    </li>
                    <li>
                      Подготовка искового заявления
                    </li>
                    <li>
                      Оплата государственной пошлины за истца
                    </li>
                    <li>
                      Подача иска и контроль возбуждения арбитражного дела
                    </li>
                    <li>
                      Анализ доказательств ответчика и письменные пояснения на его отзыв
                    </li>
                    <li>
                      Получение, проверка судебного решения и исполнительного листа на ошибки
                    </li>
                    <li>
                      Розыск открытых счетов должника во всех банках
                    </li>
                    <li>
                      Подготовка заявления на взыскание и подача в банк
                    </li>
                    <li>
                      Предъявление исполнительного листа по очередно в каждый банк
                    </li>
                    <li>
                      Переговоры с должником по фактическому исполнению судебного решения
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div *ngIf="point.type ===7" class="events-steps__hidden">
              <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
                <h3>Проведено основное судебное заседание. Иск удовлетворен полностью.</h3>
                <div class="hidden__blocks-wrapper">
                  <div class="hidden__block hidden__block--no-line">
                    <h4 class="hidden__subtitle">Содержание решения</h4>
                    <ul class="hidden__list">
                      <li>
                        Иск удволетворен полностью
                      </li>
                      <li>
                        Присуждена сумма основного долга в размере <b>80 000 ₽</b>
                      </li>
                      <li>
                        Присуждена сумма неустойки в размере <b>20 000 ₽</b>
                      </li>
                      <li>
                        Присуждена сумма судебных расходов в размере <b>19 890 ₽</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
            <div *ngIf="point.type ===8" class="events-steps__hidden">
              <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
                <h3>Истек срок ожидания ответа на претензию</h3>
                <div class="attention attention--in-card">
                  <h3>Рекомендация</h3>
                  <p>Должник не ответил на претензию в течении установленного законом срока. Возможно он произвел оплату
                    или ответил вам напрямую, о чем мы не были уведомлены. Если вы не получили оплату напрямую от
                    должника,
                    либо должник не ответил вам, рекомендуем обратиться в суд.</p>
                </div>
                <div class="hidden__blocks-wrapper">
                  <div class="hidden__block">
                    <h4 class="hidden__subtitle">направленная претензия должнику</h4>
                    <p class="hidden__text">Вы можете посмотреть претензию которую мы отправили должнику 10.08.2020.</p>
                    <div class="docs__block">
                      <div class="docs__text-wrapper">
                        <span>Претензия</span>
                        <a href="" download>nazvanie_dogovora.pdf</a>
                      </div>
                      <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/green-key.png" class="docs__icon--mini">
                             <img src="/assets/icons/orange-empty.svg">
                          </span>
                      </div>
                    </div>
                  </div>
                  <div class="hidden__block">
                    <h4 class="hidden__subtitle">Ваше решение</h4>
                    <div class="hidden__choice">
                      <p>Получил оплату напрямую</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div *ngIf="point.type ===9" class="events-steps__hidden">
              <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
                <h3>Скачайте, подпишите и отправьте доверенность</h3>
                <div class="hidden__blocks-wrapper">
                  <div class="hidden__block">
                    <h4 class="hidden__subtitle">Шаг 1</h4>
                    <p class="hidden__text">Мы подготовили доваренность, которая необходима для взаимодействия с
                      должником.
                      Скачайте, распечатайте и подпишите доверенность. Прикрепите скан или фото доверенности с вашей
                      подписью и печатью на следующем шаге.</p>
                    <div class="docs__block">
                      <div class="docs__text-wrapper">
                        <span>Доверенность</span>
                        <a href="" download>nazvanie_dogovora.pdf</a>
                      </div>
                      <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/red-attention.png" class="docs__icon--mini">
                            <img src="/assets/icons/orange-empty.svg">
                          </span>
                      </div>
                    </div>
                  </div>
                  <div class="hidden__block">
                    <h4 class="hidden__subtitle">Шаг 2</h4>
                    <p>Прикрепите подписанный скан/фото доверенности или отправьте файл через
                      <a class="hidden__chat">Chat_Gardium</a></p>
                    <form class="add-file-form">
                      <fieldset>
                        <div class="add-file-form__file">
                          <p>Doverennost.JPG</p>
                          <span></span>
                        </div>
                        <div class="add-file-form__block add-file">
                            <span class="prompt prompt__question">?
                              <span class="prompt__hidden">
                                   <p>???</p>
                                </span>
                             </span>
                          <input type="file" id="doc">
                          <label class="add-file__label" for="doc">Прикрепить скан/фото доверенности</label>
                          <span class="add-file__subtitle add-file__subtitle--no-hover">Изображение, не более 2мб</span>
                        </div>
                      </fieldset>
                      <button type="submit" class="btn-blue">Отправить доверенность</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="point.type ===10" class="events-steps__hidden">
              <div class="events-steps__hidden-text-wrapper hidden">
                <h3>Мы подготовили претензию</h3>
                <div class="hidden__blocks-wrapper">
                  <div class="hidden__block ">
                    <h4 class="hidden__subtitle">Что сделано</h4>
                    <ul class="hidden__list">
                      <li>
                        Проанализирован пакет документов по долгу
                      </li>
                      <li>
                        Определены основания возникновения долга
                      </li>
                      <li>
                        Установлены статьи ГК РФ для предъявления требований
                      </li>
                      <li>
                        Определена дата возникновения долга
                      </li>
                      <li>
                        Рассчитана сумма основного долга
                      </li>
                      <li>
                        Рассчитана сумма пени
                      </li>
                    </ul>
                  </div>
                  <div class="hidden__block">
                    <h4 class="hidden__subtitle">претензия</h4>
                    <p>Скачать претензию для ознакомления</p>
                    <div class="docs__block">
                      <div class="docs__text-wrapper">
                        <span>претензия</span>
                        <a href="" download>nazvanie_dogovora.pdf</a>
                      </div>
                      <div class="docs__icon-wrapper">
                          <span class="docs__icon">
<!--                            <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                            <img src="/assets/icons/blue-empty.svg">
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div *ngIf="point.type ===11" class="events-steps__hidden events-steps__hidden--special">
              <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm===true"
                   class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                <h3>Претензия удволетворена с условием <span></span></h3>
                <div class="attention attention--in-card">
                  <h3>Рекомендация</h3>
                  <p>Должник согласился удволетворить главное требование по претензии и заплатить основной долг в сумме
                    80 000 ₽, но отказался платить пени и неустойку в размере 20 000 ₽. Мы считаем, что данное решение
                    выгодное и рекомендуем его принять. Однако, окончательное решение всегда остается за вами, мы готовы
                    его поддержать и продолжить работу по взысканию.</p>
                </div>
                <div class="hidden__blocks-wrapper">
                  <div class="hidden__block">
                    <h4 class="hidden__subtitle">Ответ от должника</h4>
                    <p class="hidden__text">Пожалуйста, скачайте официальный ответ от должника для изучения. Нужен
                      текст.</p>
                    <div class="docs__block">
                      <div class="docs__text-wrapper">
                        <span>Ответ #1</span>
                        <a href="" download>nazvanie_dogovora.pdf</a>
                      </div>
                      <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/green-key.png" class="docs__icon--mini">
                             <img src="/assets/icons/orange-empty.svg">
                          </span>
                      </div>
                    </div>
                  </div>
                  <div class="hidden__block">
                    <h4 class="hidden__subtitle">Ваше решение</h4>
                    <p>Возможно тут лучше разместить текст с информацией о возможном выборе и дискуссии с должником.</p>
                    <form>
                      <fieldset>
                        <div class="hidden__input-wrapper input-checkbox">
                          <input type="radio" name="agreement" id="5">
                          <label class="input-checkbox__label" for="5">Полностью согласен с условиями должника
                          </label>
                        </div>
                        <div class="hidden__input-wrapper input-checkbox">
                          <input type="radio" name="agreement" id="6">
                          <label class="input-checkbox__label" for="6">Подготовить альтернативное предложение
                          </label>
                        </div>
                        <div class="hidden__input-wrapper input-checkbox">
                          <input type="radio" name="agreement" id="7">
                          <label class="input-checkbox__label" for="7">Не согласен, подать в суд
                          </label>
                        </div>
                      </fieldset>
                      <div class="hidden__btn-wrapper">
                        <button type="submit" class='btn-blue'
                                (click)="toHideForm=true; toHideForm1=false; toHideForm2=true; toHideForm3=true;">
                          Продолжить
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="attention attention--gray" *ngIf="attention && toHideForm===false">
                <p><b>Важно.</b> Банк может перечислить денежные средства на ваш счет, не уведомляя нас об этом. Если вы
                  получили деньги, пожалуйста сообщите нам для завершения делопроизводства. Если деньги не будут
                  получены до 27.10.2020, то мы направим заявление в следующий банк.</p>
                <a class="btn-blue-attr" (click)="attention=false; attentionStep1=true">
                  Получил оплату
                </a>
              </div>
              <div *ngIf="attentionStep1 && toHideForm===false">
                <div class="hidden__block hidden__block--separating">
                  <h4 class="hidden__subtitle">Получил оплату напрямую</h4>
                  <form>
                    <fieldset>
                      <div class="hidden__input-wrapper input-checkbox">
                        <input type="radio" name="agreement" id="3" (change)="showOptions=false">
                        <label class="input-checkbox__label" for="3">Согласиться с решением суда
                        </label>
                      </div>
                      <div class="hidden__input-wrapper input-checkbox">
                        <input type="radio" name="agreement" id="4" (change)="showOptions=true">
                        <label class="input-checkbox__label" for="4">Обжаловать решение суда
                        </label>
                      </div>
                      <div class="hidden__input-text-wrapper" *ngIf="showOptions">
                        <div class="input-text">
                          <input type="number" placeholder="Пожалуйста уточните сумму">
                        </div>
                        <div class="input-text">
                          <input type="number" placeholder="Пожалуйста укажите дату оплаты">
                        </div>
                      </div>
                    </fieldset>
                    <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                      <a type="submit" class='btn-blue-attr' (click)="attention=true; attentionStep1=false">Отменить</a>
                      <button type="submit" class='btn-blue'>Отправить</button>
                    </div>
                  </form>
                </div>
              </div>

              <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm1===true"
                   class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                <h3>Полностью согласен с условиями должника</h3>
                <div class="attention attention--in-card">
                  <h3>Рекомендация</h3>
                  <p>Не смотря на согласие должника оплатить долг, рекомендуется зафиксировать данные обязательства в
                    дополнительном соглашении об изменении порядка расчетов. Однако, можно подождать добровольное
                    исполнение
                    должником выплаты долга в течении разумного времени. Итоговое решение остается за вами.</p>
                </div>
                <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                  <h4 class="hidden__subtitle">Ваше решение</h4>
                  <form>
                    <fieldset>
                      <div class="hidden__input-wrapper input-checkbox">
                        <input type="radio" name="agreement" id="8">
                        <label class="input-checkbox__label" for="8">Подготовить и согласовать дополнительное соглашение
                          с должником за 1 490 ₽
                        </label>
                      </div>
                      <div class="hidden__input-wrapper input-checkbox">
                        <input type="radio" name="agreement" id="9">
                        <label class="input-checkbox__label" for="9">Не хочу дополнительное соглашение, подожду оплату
                        </label>
                      </div>
                      <div class="hidden__input-wrapper input-checkbox">
                        <input type="radio" name="agreement" id="10">
                        <label class="input-checkbox__label" for="10">Составлю сам дополнительное соглашение
                        </label>
                      </div>
                    </fieldset>
                    <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                      <a type="submit" class='btn-blue-attr'
                         (click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;">Отменить</a>
                      <button type="submit" class='btn-blue'
                              (click)="toHideForm=true; toHideForm1=true; toHideForm2=false; toHideForm3=true;">
                        Отправить
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm2===true"
                   class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                <h3>Подготовить альтернативное предложение</h3>
                <div class="attention attention--in-card">
                  <h3>Рекомендация</h3>
                  <p>Предоставьте информацию в свободной форме как вы хотели бы урегулировать долг, а мы подготовим и
                    отправим должнику официальный ответ с альтернативным предложением.</p>
                </div>
                <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                  <h4 class="hidden__subtitle">Пожалуйста, уточните ваши условия</h4>
                  <form>
                    <fieldset>
                      <div class="hidden__input-wrapper input-textarea">
                        <textarea placeholder="Ваши условия" rows="6"></textarea>
                      </div>
                    </fieldset>
                    <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                      <a type="submit" class='btn-blue-attr'
                         (click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;">Отменить</a>
                      <button type="submit" class='btn-blue'
                              (click)="toHideForm=true; toHideForm1=true; toHideForm2=true; toHideForm3=false;">
                        Отправить
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm3===true"
                   class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
                <h3>Не согласен, подать в суд</h3>
                <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
                  <form>
                    <div class="attention attention--in-card">
                      <h3>Важно!</h3>
                      <p>Сумма долга и характер требований предусматривает упрощенный порядок рассмотрения спора
                        арбитражным
                        судом. Это означает, что дело будет рассмотрено:</p>
                      <ul class="attention__list">
                        <li>
                          без вызова сторон
                        </li>
                        <li>
                          без проведения судебного заседания
                        </li>
                        <li>
                          основываясь на иске, отзыве на иск и письменных доказательствах
                        </li>
                      </ul>
                      <p>Решение вступит в силу незамедлительно, а исполнительный лист будет выдан сразу после
                        изготовления текста решения в полном объеме.</p>
                    </div>
                    <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
                      <a type="submit" class='btn-blue-attr'
                         (click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;">Отменить</a>
                      <button type="submit" class='btn-blue'>Отправить</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <input type="text" class="to-hide" value="Скрыть" readonly (click)="hideCard()">
          <p>{{point.title}}</p>
        </div>
        <div class="events-steps__date">
          <p>{{point.date}},{{point.time}}</p>
        </div>
      </div>
    </div>
    <div class="events-steps__row">
      <div class="events-steps__info">
        <div class="events-steps__user">
          <div class="events-steps__img">
            <img src="/assets/images/woman2.jpg">
          </div>
          <div class="events-steps__name events-steps__name--human">
            <p>Авоськина Регина</p>
          </div>
        </div>
        <div class="events-steps__state">
          <p class="events-steps__state--gray">Розыск счетов должника... Мы уведомим вас о результате</p>
        </div>
        <div class="events-steps__date">
          <p>в процессе</p>
        </div>
      </div>
    </div>
  </div>
</div>
