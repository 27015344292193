<div class="legal-names">
  <div class="carousel-title">
    Названия юрлиц
    <div class="carousel-counter">
      {{ legalNamesData?.hits?.total?.legalEntities ? (legalNamesData?.hits?.total?.legalEntities > 100 ? '100+' : legalNamesData?.hits?.total?.legalEntities) : 0 }}
    </div>
  </div>
  <div *ngIf="!isLoadingLegalNames && allLegalNames.length === 0" class="empty-result">
    <img src="/assets/icons/empty.svg">
    <div class="empty-result__text">Названия юрлиц по данному запросу не найдены</div>
  </div>
  <div class="legal-names-list__wrap">
    <div *ngIf="allLegalNames.length || !isLoadingLegalNames; else loader"
         [class.legal-names-list__notIpiD]="brandsearch || legalsupport"
         class="legal-names-list" [ngStyle]="allLegalNames.length < 4 ? {'justify-content': 'center'} : {}">
      <div class="legal-name-item" [class.legal-name-item__notIpID]="brandsearch || legalsupport"
           *ngFor="let legalName of legalNames">
        <div class="legal-name-item__popover"
             *ngIf="isOverlongName(legalName)"
        >{{legalName._source.name ? legalName._source.name : legalName._source.fullName}}</div>
        <div
          class="legal-name-card"
          (click)="openModalNew(legalName)"
        >
          <div class="buttons_risk">
            <div *ngIf="legalName._risk">
              <button *ngIf="legalName._risk.toLowerCase() === 'high'" class="box_button_warning">
                Высокий риск
              </button>
              <button *ngIf="legalName._risk.toLowerCase() === 'medium'" class="box_button_middle">
                средний риск
              </button>
              <button *ngIf="legalName._risk.toLowerCase() === 'low' || legalName._risk.toLowerCase() === 'none'"
                      class="box_button_low">
                Нет риска
              </button>
            </div>
          </div>

          <div
            *ngIf="legalName._source?.name"
            class="legal-name-card__header_cover"
          >
            <p>{{ isOverlongName(legalName) ? getShortNameByWords(getShortNameByRows(legalName._source.name)).name + '...' : legalName._source.name}}</p>
          </div>

          <div
            *ngIf="!legalName._source?.name && legalName._source?.fullName"
            class="legal-name-card__header_cover"
          >
            {{ isOverlongName(legalName) ? getShortNameByWords(getShortNameByRows(legalName._source.fullName)).name + '...' : legalName._source.fullName}}
          </div>

          <div class="legal-name-card__footer">
            <div class="type_mark">
              ОКВЭД
            </div>
            <div class="class-numbers">
              <div *ngFor="let classes of legalName._source.OKVEDCodes| slice:0:2;"
                   class="class-number class-number--desktop">
                {{classes.code}}
              </div>
              <div *ngFor="let classes of legalName._source.OKVEDCodes| slice:0:1;"
                   class="class-number class-number--mobile">
                {{classes.code}}
              </div>

              <div *ngIf="legalName._source?.OKVEDCodes?.length > 2" class="added_classes added_classes--desktop">
                +{{ legalName._source.OKVEDCodes.length - 2 }}
              </div>
              <div *ngIf="legalName._source?.OKVEDCodes?.length > 1" class="added_classes added_classes--mobile">
                +{{ legalName._source.OKVEDCodes.length - 1 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="isViewLoadCard()"
        class="legal-name-item brand-search-card" [class]="legalsupport? 'legal-support-card': ''"
        [class.legal-name-item__notIpiD]="brandsearch || legalsupport"
        [class.img__notIpID]="brandsearch || legalsupport">
        <img *ngIf="!legalsupport" src="/assets/icons/free-label.svg">
        <div class="title">{{legalsupport ? 'Важно действовать быстро' : 'Скачайте полный отчет'}}</div>
        <div class="text" *ngIf="brandsearch && !legalsupport" [class.padding__notIpID]="brandsearch || legalsupport">
          Индивидуальные рекомендации по регистрации<br>
          5 способов доработки товарного знака от ведущих компаний<br>
          Полный отчет с результатами поиска по всем базам и реестрам
        </div>
        <div class="text" *ngIf="!brandsearch && legalsupport">
          Ежедневно в Роспатент подаётся 300 заявок на регистрацию товарного<br>
          знака — одна из этих компаний может подать заявку сегодня. Будьте<br>
          первыми и получите преимущество.
        </div>

        <button class="btn-download" *ngIf="!brandsearch && legalsupport"
                (click)="eventsService.emitAction('podat-zayavku')">
          Подать заявку
        </button>

        <button class="btn-download" *ngIf="brandsearch && !legalsupport" (click)="emitClickDownload()">
          Скачать отчет
        </button>


      </div>

      <div *ngIf="legalNamesOffset && legalNames.length" class="carousel-arrow carousel-arrow__back"
           (click)="changeLegalNameSlider('back')" [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div *ngIf="((legalNamesOffset + 4) < allLegalNames.length) && legalNames.length"
           class="carousel-arrow carousel-arrow__next" (click)="changeLegalNameSlider('next')"
           [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div *ngIf="((legalNamesOffset +3) < allLegalNames.length) && legalNames.length"
           class="carousel-arrow carousel-arrow__next carousel-arrow__next--tablet"
           (click)="changeLegalNameSlider('next')" [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div style="display: block; height: 265px">
    <div class="loader"></div>
  </div>
</ng-template>

<div class="zzmodal" *ngIf="showModal && selectedLegalName">
  <app-legal-name-modal
    [data]="selectedLegalName"
    (modalEvent)="closeModal()"
  >
  </app-legal-name-modal>
</div>
