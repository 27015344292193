import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('rootEl') rootEl: ElementRef;

  // по умолчанию имя элемента рандомное
  private arr = new Uint32Array(10);
  @Input() name: string = window.crypto.getRandomValues(this.arr)[1].toString();
  @Input() icon: string;
  @Input() options: Array<{ id: any, label: string }>;
  @Input() title: string;

  private _value: any[];

  // array of option.id
  @Input() set value(value: any[]) {
    this._value = value;

    for (const opt of this.options) {
      this.checked[opt.id] = value.includes(opt.id);
    }
  }

  get value() {
    return this._value;
  }

  @Output() apply = new EventEmitter<any[]>();

  checked = {};

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // ждем фокуса элемента и сбрасываем стейт к value
    this.rootEl.nativeElement.addEventListener('focus', this.onFocus);
  }

  ngOnDestroy(): void {
    if (this.rootEl?.nativeElement) {
      this.rootEl.nativeElement.removeEventListener('focus', this.onFocus);
    }
  }


  get countSelected() {
    return this.value.length;
  }

  onApply() {
    const allCheckedIds = Object.keys(this.checked).filter(k => this.checked[k]);
    const allOptionIds = this.options.map(i => i.id);
    // id.toString() потому что ключи объектов не могут быть числами
    this.apply.emit(allOptionIds.filter(i => allCheckedIds.includes(i.toString())));
  }

  clean() {
    for (const opt of this.options) {
      this.checked[opt.id] = false;
    }
  }

  onFocus = () => {
// todo close drop on click!
    for (const opt of this.options) {
      this.checked[opt.id] = this.value.includes(opt.id);
    }
  }

  uniqueOptionName(option) {
    return 'filter-select__' + this.name + '__' + option.id;
  }
}
