import { Injectable } from '@angular/core';
import { OpenedServiceInterface, ServiceInterface } from '../models/service.interface';
import { SectionInterface } from '../models/cards.interface';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ServicesActions } from './services.actions';
import { ServicesApiService } from '../services/services-api.service';

export const SERVICES_STATE_TOKEN = new StateToken<ServicesStateModel>('services');

@State({
  name: SERVICES_STATE_TOKEN,
  defaults: {
    isLoading: false,
    error: null,
    popular: null,
    sections: null,
    openedService: null
  }
})
@Injectable()
export class ServicesState {
  constructor(private apiService: ServicesApiService) {}

  @Selector()
  static getOpenedState(state: ServicesStateModel) {
    return state.openedService;
  }

  @Selector()
  static getPopular(state: ServicesStateModel) {
    return state.popular || [];
  }

  @Action(ServicesActions.LoadPopular)
  async loadPopular(ctx: Context) {
    ctx.patchState({ isLoading: true, error: null });

    try {
      const popularRes = await this.apiService.getPopularServices();

      ctx.patchState({
        isLoading: false,
        error: null,
        popular: popularRes.data.items,
      });
    } catch (error) {
      ctx.patchState({ isLoading: false, error });
    }
  }

  @Action(ServicesActions.Init)
  async init(ctx: Context) {
    ctx.patchState({ isLoading: true, error: null, popular: null });

    try {
      const popularRes = await this.apiService.getPopularServices();
      const sectionsRes = await this.apiService.getServices();

      ctx.patchState({
        isLoading: false,
        error: null,
        popular: popularRes.data.items,
        sections: sectionsRes.data.items,
        openedService: null
      });
    } catch (error) {
      ctx.patchState({ isLoading: false, error });
    }
  }

  @Action(ServicesActions.LoadDetails)
  async loadDetails(ctx: Context, { id }: ServicesActions.LoadDetails) {
    ctx.patchState({ isLoading: true, error: null });

    try {
      const openedService = await this.apiService.getServiceDetails(id);

      ctx.patchState({
        isLoading: false,
        error: openedService.error ? openedService.error.messages[0] : null,
        openedService: openedService.data,
        popular: null,
      });
    } catch (error) {
      ctx.patchState({ isLoading: false, error, openedService: null, popular: null });
    }
  }
}

type Context = StateContext<ServicesStateModel>;

export interface ServicesStateModel {
  isLoading: boolean;
  error: string | null;
  popular: ServiceInterface[] | null;
  sections: SectionInterface[] | null;
  openedService: null | OpenedServiceInterface;
}
