<form class="form form--margin" [formGroup]="form">
  <div class="form__group">
    <div 
      class="form__input-wrap form__input-wrap--select"
      [class.form__input-wrap--select--after]="showOptions"
    >
      <a
        class="form__link"
        id="choosePayerBtn"
        (click)="setShowOptions()"
        [class.form__link--gray]="!selectedPayer"
      >
        {{selectedPayer?.name || (selectedPayer ? 'Указать другого плательщика' : null) || 'Выберите плательщика'}}
      </a>

      <ul *ngIf="showOptions" class="form__select-list">
        <li
          class="form__li company-item"
          [class.form__li--selected]="!selectedPayer"
          (click)="setCurrentPayer(null)"
        >
          <p>Выберите плательщика</p>
        </li>
        
        <li
          *ngFor="let comp of organizations; let i = index"
          [id]="'company-item' + i"
          class="form__li company-item"
          [class.form__li--selected]="comp.id === selectedPayer"
          (click)="setCurrentPayer(comp.id)"
        >
          <p>{{comp.TIN || comp.inn}}<span> {{(comp.TIN || comp.inn) ? '/' : ''}} {{comp.name || comp.fullName}}</span></p>
        </li>
        <li
          *ngIf="!envService.isCorp && !organizations.length"
          class="form__li company-item"
          id="anotherId"
          [class.form__li--selected]="selectedPayerId === 'another'"
          (click)="setCurrentPayer('another')"
        >
          <p>Указать другого плательщика</p>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="selectedPayerId === 'another'" class="form__group">
    <div class="form__input-wrap">
      <div
        *ngIf="selectedPayerFromSearch?.name"
        [ngStyle]="getDynamicMarginForLegalName()"
        class="legal-name-patentee"
      ></div>

      <input
        required
        type="text"
        formControlName="payer"
        class="form__input field"
        [style.pointerEvents]="isDebit && organizations?.length ? 'none' : 'auto'"
        (ngModelChange)="payerChanged($event)"
      />
      <span class="form__input-label">Название, ОГРН/ОГРНИП, ИНН или ФИО</span>
    </div>

    <div
      class="form__dropdown form-card"
      *ngIf="tempPayer"
      (click)="payerSelected()"
      style="position: relative"
    >
      <span class="modal__close" style="right: 6px" (click)="cancelPatentee()">
        <svg width="26" height="20" viewBox="0 0 26 26">
          <rect x="1.41431" width="34" height="2" transform="rotate(45 1.41431 0)"/>
          <rect y="24.0416" width="34" height="2" transform="rotate(-45 0 24.0416)"/>
        </svg>
      </span>
      <table class="form-card__table">
        <thead class="dropdown-hover">
          <tr>
            <th>ОГРН</th>
            <th>ИНН</th>
            <th>Наименование компании или ИП</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{tempPayer.PSRN}}</td>
            <td>{{tempPayer.TIN}}</td>
            <td class="black">{{tempPayer.name || tempPayer.fullName}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
