import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval, Subject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../services/http.service';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../../../../../../app-search/src/app/services/search.service';
import { environment } from '../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

declare var window: any;

export interface IYandexResponseToken {
  id: string;
  confirmation: {
    confirmation_token: string
  };
}

const currentHost = `${window.location.protocol}//${window.location.host}`;

@Component({
  selector: 'app-payment2',
  templateUrl: './payment2.component.html',
  styleUrls: ['./payment2.component.scss']
})
export class Payment2Component implements OnInit {


  private readonly onDestroyTimer = new Subject<void>();
  private readonly onDestroy = new Subject<void>();

  @Input() paymentSum; // сумма к оплате
  @Input() requestType; // тип заявки
  @Input() idApplication; // id заявки
  @Input() activeTariff; // id выбранного тарифа
  @Input() owner; // плательщик (только для заявок на ТЗ)
  @Input() company = []; // список плательщиков
  @Output() switchPaymentType = new EventEmitter(); // событие переключения способа оплаты
  @Output() nextStepEvent = new EventEmitter(); // событие перехода на следующий шаг
  @Output() backStepEvent = new EventEmitter(); // событие перехода на предыдущий шаг
  showOptions = false; // показать/скрыть выпадающий список плательщиков
  selectedCompany; // выбранный плательщик из списка правообладателей

  payError = {
    status: false,
    text: '',
  };
  production: boolean;
  logo2path = '';
  yaLastPaymentId: string;
  returnAfterPayment;
  yaPaymentStatus: string;
  payWidjetIsRender = false;
  payResponse: any;
  formPay: FormGroup; // форма плательщика с полными данными (инн, огрн, адрес, ...)
  formPayer: FormGroup; // форма плательщик по инн
  paymentTab = 'cash'; // тип оплаты
  timerSubscription: Subscription;
  tempPayer; // найденный плательщик по инн
  selectedPayer; // выбранный плательщик по инн
  selectedPayerString; // выбранный плательщик физ лицо
  viewSelectContainer = true; // отображать выпадающий блок после ввода ИНН/ОГРН
  thirdStepSended = false;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
    this.logo2path = environment.LOGO2_PATH;
    this.production = environment.production;
  }

  ngOnInit(): void {
    if (this.requestType !== 'trademark') {
      this._initFormPayer();
    }
    this.getUrlParam();
    setTimeout(() => {
      if (this.company) {
        this.selectedCompany = this.company[0];
      } else {
        this.company = [
          {
            id: 0,
            name: 'Выберите плательщика'
          },
          {
            id: 2,
            name: 'Указать другого плательщика'
          }
        ];
      }
    }, 1000);
    const payerTempString = localStorage.getItem('selectedPayerString');
    const payerTempObj = localStorage.getItem('selectedPayer');
    if (payerTempString && payerTempString !== 'undefined') {
      this.selectedPayer = payerTempString;
      localStorage.removeItem('selectedPayerString');
    } else if (payerTempObj && payerTempObj !== 'undefined') {
      this.selectedPayer = JSON.parse(payerTempObj);
      localStorage.removeItem('selectedPayer');
    }
  }

  getUrlParam() {
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.idApplication = params.id;
      }
      this.returnAfterPayment = params.returnAfterPayment;
      const lastPaymentId = params.t;
      if (lastPaymentId) {
        this.yaLastPaymentId = params.t;
      }

      if (this.returnAfterPayment) {
        const source = interval(1000 * 30);
        this.testPayStatus();
        this.timerSubscription = source.pipe(takeUntil(this.onDestroyTimer)).subscribe(() => {
          this.testPayStatus();
        });
      } else {
        setTimeout(() => {
          this.sendTariff();
          this.preInitYandexPay();
          this._initFormPay();
        }, 1700);
      }
    });
  }

  repeatPayment() {
    this.yaLastPaymentId = null;
    this.payError = {
      status: false,
      text: '',
    };

    // TODO: восстановить url, очистить get params

    this.initYandexCheckout();
  }

  preInitYandexPay(options?) {
    if (this.returnAfterPayment && this.returnAfterPayment === 'true') {
      if (options.reInit) {
        this.initYandexCheckout();
      } else {
        this.testPayStatus();
      }
    } else {
      this.payError.status = false;
      this.payError.text = '';
      this.initYandexCheckout();
    }
  }

  initYandexCheckout() {
    // this.http.get(`http://10.1.33.225:9999/api/yandex_payment/create?sum=${sum}&responseID=${this.idApplication}`).subscribe((data: IYandexResponseToken) => {
    this.httpService.get(
      {
        path: `yandex_payment/create?responseID=${this.idApplication}`,
        subjectType: 'recover password'
      }
    ).subscribe((data: IYandexResponseToken) => {
      if (data?.confirmation?.confirmation_token && data?.id) {
        console.log('payment_id and confirmation_token', data);
        this.yaLastPaymentId = data.id;

        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: data.confirmation.confirmation_token,
          return_url: `${currentHost}/request?type=${this.requestType}&id=${this.idApplication}&returnAfterPayment=true&t=${this.yaLastPaymentId}`,
          error_callback(error) {
            console.error('ya pay err', error);
            // Обработка ошибок инициализации
          }
        });

        // Отображение платежной формы в контейнере
        checkout.render('payment-form');
        this.checkPayRender();

      } else {
        console.error('В ответе нет confirmation_token и/или payment_id');
      }
    });
  }

  testPayStatus() {
    this.yaPaymentStatus = null;

    if (this.yaLastPaymentId) {
      // this.http.get(`http://10.1.33.225:9999/api/yandex_payment/check?payment_id=${this.yaLastPaymentId}`).subscribe((data: any) => {
      this.httpService.get(
        {
          path: `yandex_payment/check?payment_id=${this.yaLastPaymentId}&responseID=${this.idApplication}`,
          subjectType: 'yandex_payment/check'
        }
      ).subscribe((data: any) => {
        console.log('getstatus ya pay', data);
        this.payResponse = data;
        if (this.payResponse?.status === 'succeeded') {
          this.sendPayJSON('online', data);
          this.onDestroyTimer.next();
        }
        if (this.payResponse?.status === 'canceled') {
          this.payError.status = true;
          switch (this.payResponse?.cancellation_details?.reason) {
            case '3d_secure_failed':
              this.payError.text = `Не пройдена аутентификация по 3-D Secure. <br class="hide-up-to-md">
            Вам следует повторить платеж, обратиться в банк за уточнениями или использовать другое платежное средство.`;
              break;
            case 'card_expired':
              this.payError.text = `Истек срок действия банковской карты. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'fraud_suspected':
              this.payError.text = `Платеж заблокирован из-за подозрения в мошенничестве. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'insufficient_funds':
              this.payError.text = `Не хватает денег для оплаты. <br class="hide-up-to-md">
            Вам следует пополнить баланс или использовать другое платежное средство.`;
              break;
            case 'invalid_card_number':
              this.payError.text = `Неправильно указан номер карты. <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'invalid_csc':
              this.payError.text = `Неправильно указан код CVV2 (CVC2, CID). <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'payment_method_restricted':
              this.payError.text = `Операции данным платежным средством заблокированы. <br class="hide-up-to-md">
            Вам следует обратиться в организацию, выпустившую платежное средство.`;
              break;
            default:
              this.payError.text = `Произошла ошибка при оплате. <br class="hide-up-to-md">
            Проверьте правильность введенных данных или срок действия карты.`;
          }
          this.onDestroyTimer.next();
        }

        this.yaPaymentStatus = data;
      });
    }
  }

  checkPayRender() {
    setTimeout(() => {
      const el = document.getElementsByTagName('iframe');
      console.log(el[0]);
      if (el[0]) {
        this.payWidjetIsRender = true;
      } else {
        this.payWidjetIsRender = false;
        this.checkPayRender();
      }
    }, 300);
  }

  /**
   * отправка данных для оффайн оплаты
   */
  sendPayJSON(type: string, yandexResponse?: any): void {
    // if (this.requestType === 'trademark') {
    let userID;
    if (localStorage.getItem('currentUserData')) {
      userID = JSON.parse(localStorage.getItem('currentUserData')).userID;
    }

    let reqData;
    if (type === 'cash') {
      reqData = {
        stage: 4,
        type: 'cash',
        userId: userID,
        responseId: this.idApplication,
        inn: this.formPay.controls.INN.value.toString(),
        ogrn: this.formPay.controls.OGRN.value.toString(),
        name: this.formPay.controls.name.value.toString(),
        address: this.formPay.controls.address.value.toString(),
        sum: this.formPay.controls.sum.value.toString(),
      };
    } else if (type === 'online') {
      if (this.selectedPayer) {
        reqData = {
          stage: 4,
          type: 'online',
          userId: userID,
          responseId: this.idApplication,
          inn: this.selectedPayer.TIN,
          ogrn: this.selectedPayer.PSRN,
          name: this.selectedPayer.name || this.selectedPayer.fullName || this.selectedPayer,
          address: this.selectedPayer.address,
          yandexResponse,
        };
      } else {
        reqData = {
          stage: 4,
          type: 'online',
          userId: userID,
          responseId: this.idApplication,
          yandexResponse,
        };
      }

    }

    this.httpService.post({path: 'request/load', body: reqData}).subscribe((data: any) => {

      this.nextStep();
      localStorage.setItem('selectCaseUnfinished', this.idApplication);

      this.intercomEventsService.push({
        event: type === 'online'
          ? INTERCOM_EVENT_NAME.FORM_PAYMENT_ONLINE_SUCCESS
          : INTERCOM_EVENT_NAME.FORM_PAYMENT_BILL_SUCCESS,
        itemType: this.requestType,
        applyRate: this.activeRateName(this.activeTariff),
        totalPrice: +this.paymentSum
      });
    });
    // } else {
    //   this.nextStep();
    // }
  }

  activeRateName(rateId) {
    switch (rateId) {
      case '000000001':
        return 'Минимальный';
      case '000000002':
        return 'Оптимальный';
      case '000000003':
        return 'Максимальный';
      default:
        console.warn(`Для тарифа id: ${rateId} необходимо указать его наименование.`);
    }
  }

  /**
   * переключение панели способа оплаты
   * @param type: выбранный тип оплаты
   */
  switchPaymentTab(type: string): void {
    if (type === this.paymentTab) {
      return;
    }
    if (type === 'cash') {
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_PAYMENT_BILL, itemType: this.requestType });
      this.switchPaymentType.emit('cash');
      this._initFormPay();
    }
    // else if (type === 'online') {
    //   this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_PAYMENT_ONLINE, itemType: this.requestType });
    //   this.switchPaymentType.emit('online');
    //   this.preInitYandexPay({reInit: true});
    // }
    if (this.requestType !== 'trademark') {
      this.showOptions = false;
      this.tempPayer = undefined;
      this.selectedPayer = undefined;
      this.formPayer.get('payer').setValue('');
      this.selectedCompany = this.company[0];
    }
    this.paymentTab = type;
  }

  /**
   * инициализация формы оффлайн оплаты
   */
  private _initFormPay(): void {
    if (this.requestType === 'trademark') {
      this.formPay = this.fb.group({
        INN: [this.owner.inn, [Validators.required]],
        OGRN: [this.owner.ogrn, [Validators.required]],
        name: [this.owner.name, [Validators.required]],
        address: [this.owner.address, [Validators.required]],
        sum: [(+this.paymentSum).toLocaleString('ru') + ' ₽', [Validators.required]],
      });
    } else {
      this.formPay = this.fb.group({
        INN: [null, [Validators.required]],
        OGRN: [null, [Validators.required]],
        name: [null, [Validators.required]],
        address: [null, [Validators.required]],
        sum: [(+this.paymentSum).toLocaleString('ru') + ' ₽', [Validators.required]],
      });
    }
  }

  /**
   * инициализация формы плательщика
   */
  private _initFormPayer(): void {
    this.formPayer = this.fb.group({
      payer: [null, [Validators.required]],
    });
  }

  /**
   * отправка выбранного тарифа в юрайт
   */
  sendTariff(): void {
    const tariffBody = {
      responseID: this.idApplication,
      tariffID: this.activeTariff,
      stage: 3,
    };
    this.httpService.post({path: 'request/load', body: tariffBody}).subscribe((data: any) => {
      this.thirdStepSended = true;
    });
  }

  nextStep() {
    this.nextStepEvent.emit();
  }

  backStep() {
    this.backStepEvent.emit();
  }

  onSelect(comp): void {
    this.selectedCompany = comp;
    this.showOptions = false;
    this.tempPayer = undefined;
    this.selectedPayer = undefined;
    this.selectedPayerString = undefined;
    if (this.formPay.get('payer')) {
      this.formPay.get('payer').setValue('');
    }
    if (comp.name !== 'Указать другого плательщика' && comp.name !== 'Выберите плательщика') {
      if (!(typeof comp.name === 'string')) {
        this.selectedPayer = comp.name;
        localStorage.setItem('selectedPayer', JSON.stringify(this.selectedPayer));
        localStorage.removeItem('selectedPayerString');
        this.setSelectedPayer();
      } else {
        this.selectedPayerString = comp.name;
        localStorage.setItem('selectedPayerString', this.selectedPayerString);
        localStorage.removeItem('selectedPayer');
      }
    } else {
      localStorage.removeItem('selectedPayer');
      localStorage.removeItem('selectedPayerString');
    }
  }

  getCompanyName(comp) {
    if (typeof comp === 'string') {
      return comp;
    } else {
      return `${comp.TIN}<span> / ${comp.name}</span>`;
    }
  }

  payerChanged(innOgrn: string) {
    if (this.viewSelectContainer) {
      this.tempPayer = undefined;
      this.selectedPayer = undefined;
      if (innOgrn.length > 2) {
        this.getFullPayer(innOgrn);
      }
    } else {
      this.viewSelectContainer = true;
    }
  }

  getFullPayer(id) {
    const query = {
      id,
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits[0]?._source) {
        this.tempPayer = data.hits.hits[0]._source;
      }
    });
  }

  checkValidInnOgrn(value) {
    return value && !isNaN(+value) && (value.length === 10 || value.length === 12 || value.length === 13 || value.length === 15);
  }

  peyerSelected() {
    this.selectedPayer = this.tempPayer;
    this.tempPayer = undefined;
    this.setSelectedPayer();
    if (!this.checkValidInnOgrn(this.formPayer.get('payer').value)) {
      this.viewSelectContainer = false;
      this.formPayer.get('payer').setValue(this.selectedPayer.TIN);
    }
    localStorage.setItem('selectedPayer', JSON.stringify(this.selectedPayer));
    localStorage.removeItem('selectedPayerString');
  }

  setSelectedPayer() {
    if (this.paymentTab === 'cash') {
      this.formPay.get('INN').setValue(this.selectedPayer.TIN);
      this.formPay.get('OGRN').setValue(this.selectedPayer.PSRN);
      this.formPay.get('name').setValue(this.selectedPayer.fullName);
      this.formPay.get('address').setValue(this.selectedPayer.address);
    }
  }

  ngOnDestroy(): void {
  }

  getDynamicMarginForLegalName() {
    switch (this.formPayer.get('payer').value.length) {
      case 10:
        return {left: '132px'};
      case 12:
        return {left: '150px'};
      case 13:
        return {left: '158px'};
      case 15:
        return {left: '174px'};
    }
  }

  cancelRatentee() {
    this.tempPayer = undefined;
    this.selectedPayer = this.formPayer.get('payer').value;
    localStorage.setItem('selectedPayer', this.selectedPayer);
  }

}
