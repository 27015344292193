import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { EventsService } from '../../../../services/events.service';


@Component({
  selector: 'app-trade-marks',
  templateUrl: './trade-marks.component.html',
  styleUrls: ['./trade-marks.component.scss']
})
export class TradeMarksComponent implements OnInit {

  @Output() hideSearchPopup = new EventEmitter();
  @Output() clickDownload = new EventEmitter();

  @Input() tradeMarksData;
  @Input() tradeMarks;
  @Input() allTradeMarks;

  @Input() isShowSearchPopup;
  @Input() brandsearch;
  @Input() legalsupport;
  @Input() designation;

  trademarkOffset = 0;

  selectedTrademark: any;
  showModal = false;
  showModalNew = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeTradeMarks();
  }

  constructor(readonly eventsService: EventsService) {
  }

  ngOnInit(): void {
    console.log(this.tradeMarks);
    if (this.brandsearch || this.legalsupport) {
      this.countTradeMarksBrandSearch();
    }
  }

  countTradeMarksBrandSearch() {
    if (document.documentElement.clientWidth > 991) {
      if (this.allTradeMarks.length >= 6) {
        this.allTradeMarks = this.allTradeMarks.slice(0, 6);
      } else {
        if (this.allTradeMarks.length <= 4) {
          this.allTradeMarks = this.allTradeMarks.slice(0, 2);
          this.tradeMarks = this.allTradeMarks.slice(0, 2);
        }
      }
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      if (this.allTradeMarks.length >= 4) {
        this.allTradeMarks = this.allTradeMarks.slice(0, 4);
      } else if (this.allTradeMarks.length <= 3) {
        this.allTradeMarks = this.allTradeMarks.slice(0, 1);
        this.tradeMarks = this.allTradeMarks.slice(0, 1);
      }
    }
  }

  changeTrademarkSlider(direction) {
    switch (direction) {
      case 'next':
        if (document.documentElement.clientWidth > 991) {
          this.trademarkOffset = this.trademarkOffset + 4;
          this.tradeMarks = this.allTradeMarks.slice(this.trademarkOffset, this.trademarkOffset + 4);
        } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
          this.trademarkOffset = this.trademarkOffset + 3;
          this.tradeMarks = this.allTradeMarks.slice(this.trademarkOffset, this.trademarkOffset + 3);
        }
        break;
      case 'back':
        if (document.documentElement.clientWidth > 991) {
          this.trademarkOffset = this.trademarkOffset - 4;
          this.tradeMarks = this.allTradeMarks.slice(this.trademarkOffset, this.trademarkOffset + 4);
        } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
          this.trademarkOffset = this.trademarkOffset - 3;
          this.tradeMarks = this.allTradeMarks.slice(this.trademarkOffset, this.trademarkOffset + 3);
        }
        break;
    }
  }

  // openModal(tradeMark) {
  //   if (this.isShowSearchPopup) {
  //     // TODO: quick fix for close search popup
  //     this.hideSearchPopup.emit();
  //   }
  //
  //   this.showModal = !this.showModal;
  //   this.selectedTrademark = tradeMark;
  // }

  openModalNew(tradeMark) {
    if (this.isShowSearchPopup) {
      // TODO: quick fix for close search popup
      this.hideSearchPopup.emit();
    }
    this.selectedTrademark = tradeMark;
    this.showModalNew = true;
    let bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'hidden');
  }

  closeModal() {
    this.showModal = false;
    this.showModalNew = false;
    let bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  isViewLoadCard(): boolean {
    if (document.documentElement.clientWidth > 991) {
      return (this.brandsearch || this.legalsupport) && this.trademarkOffset === 4 * ((this.allTradeMarks.length - this.allTradeMarks.length % 4) / 4) && this.allTradeMarks.length;
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      return (this.brandsearch || this.legalsupport) && this.trademarkOffset === 3 * ((this.allTradeMarks.length - this.allTradeMarks.length % 3) / 3) && this.allTradeMarks.length;
    } else if (document.documentElement.clientWidth <= 767) {
      return (this.brandsearch || this.legalsupport) && this.allTradeMarks.length;
    }
  }

  emitClickDownload() {
    return this.clickDownload.emit();
  }

  resizeTradeMarks(): void {
    if (document.documentElement.clientWidth > 991) {
      this.tradeMarks = this.allTradeMarks.slice(0, 4);
      this.trademarkOffset = 0;
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      this.tradeMarks = this.allTradeMarks.slice(0, 3);
      this.trademarkOffset = 0;
    } else if (document.documentElement.clientWidth <= 767 && !this.brandsearch && !this.legalsupport) {
      this.tradeMarks = this.allTradeMarks;
      this.trademarkOffset = 0;
    } else if (document.documentElement.clientWidth <= 767 && (this.brandsearch || this.legalsupport)) {
      this.tradeMarks = this.allTradeMarks.slice(0, 3);
      this.trademarkOffset = 0;
    }
  }
}
