<div class="parrent_modal">

  <div class="modal">
    <ng-container *ngIf="!isLoading && sourceData?._source; else loader">
      <div class="top-hat">
        <div class="top-hat__info">
          {{ sourceData?._index.slice(0, 2).toUpperCase() }}
          {{ sourceData?._source.registrationString || sourceData?._source.applicationString }}
        </div>
        <button class="top-hat__btn-close" (click)="closeModal()"></button>
      </div>

      <div class="trademark-body">
        <div class="trademark-body__buttons_risk" *ngIf="data._risk">
          <button *ngIf="data._risk.toLowerCase() ===  'high'" class="btn-risk box_button__warning">
            Высокий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'medium'" class="btn-risk box_button__middle">
            средний риск
          </button>
          <button *ngIf="data._risk.toLowerCase() === 'low'" class="btn-risk box_button__low">
            Низкий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'none'" class="btn-risk box_button__none">
            Нет риска
          </button>
          <div class="risk-hint">
            <div class="risk-tooltip"
                 *ngIf="(data._risk.toLowerCase() ===  'high') || (data._risk.toLowerCase() ===  'medium')">Этот объект
              создает значительные риски для вашего товарного знака
            </div>
            <div class="risk-tooltip" *ngIf="data._risk.toLowerCase() === 'low' || data._risk.toLowerCase() === 'none'">
              Этот объект создает незначительные риски для вашего товарного знака
            </div>
          </div>
        </div>

        <div class="trademark-body__header">
          Товарный знак
          № {{sourceData._source.registrationString || sourceData?._source.applicationString}}
        </div>

        <div class="trademark-body__data">
          <div *ngIf="sourceData._source.markImageFileName && data._source.imageUrl; else text" class="image-container">
            <a (click)="showLogoModal()">
              <img class="image-container__image" [src]="data._source.imageUrl">
            </a>
          </div>
          <div [class.open]="isShowLogoModal" class="image-container__modal" (click)="showLogoModal()">
            <img [src]="data._source.imageUrl">
          </div>
          <ng-template #text>
            <div class="text-container">
              <div class="text-container__name">
                <span>
                  {{data._source.imageText}}
                </span>
              </div>
            </div>
          </ng-template>
          <div class="info-container">
            <div class="info-container__top">
              <div class="feature" *ngIf="getStatusTrademark(sourceData)">
                <div class="title">Статус</div>
                <div class="text status-trademark" [innerText]="getStatusTrademark(sourceData)"></div>
              </div>
              <div class="feature" *ngIf="sourceData._source.applicantName">
                <div class="title">
                  Заявитель
                </div>
                <div class="text">
                  <div class="link-applicantName">{{sourceData._source.applicantName}}</div>
                </div>
              </div>
            </div>
            <div class="info-container__bottom">
              <div class="first-column">
                <div class="feature" *ngIf="sourceData._source.applicationDate">
                  <div class="title">Дата подачи заявки</div>
                  <div class="text">{{sourceData._source.applicationDate | date:'dd.MM.yyyy'}}</div>
                </div>
                <div class="feature" *ngIf="sourceData._source.registrationDate">
                  <div class="title">Дата регистрации</div>
                  <div class="text">{{sourceData._source.registrationDate | date:'dd.MM.yyyy'}}</div>
                </div>
              </div>
              <div class="second-column">
                <div class="feature" *ngIf="sourceData._source.expiryDate">
                  <div class="title">Дата истечения</div>
                  <div class="text">{{sourceData._source.expiryDate | date:'dd.MM.yyyy'}}</div>
                </div>
              </div>
            </div>
            <div class="info-container__top">
              <div class="feature" *ngIf="sourceData._source.disclaimer" style="margin-bottom: 0">
                <div class="title">Неохраняемые элементы</div>
                <div class="text">{{sourceData._source.disclaimer}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <app-accordion [title]="'Классы МКТУ'"
                       [count]="sourceData && sourceData._source && sourceData._source.goodsServices && sourceData._source.goodsServices.length">
          <ul>
            <li *ngFor="let classes of getMktuClasses()">
              <div class="mktu-class">
                <div class="class-number">{{classes.classNumber}} <span>класс</span></div>
                <div class="class-description"
                     onclick="this.className = (this.className === 'class-description' ? 'class-description-long' : 'class-description')">
                  {{classes.gsDescription}}</div>
              </div>
            </li>
          </ul>
          <button *ngIf="sourceData._source.goodsServices.length > 3 && viewClassesCount === 'short'"
                  (click)="showAllClasses()" class="show-all-btn">Показать
            еще {{sourceData._source.goodsServices.length - 3}}</button>
        </app-accordion>

        <div class="divider"></div>
        <app-accordion [title]="'Договоры по знаку'" [count]="contractsData.length">
          <div *ngIf="contractsData.length">
            <app-table
              [title]="[{text: 'Дата договора', name:'contractDate'}, {text: 'Номер договора', name:'contractNumber'}, {text: 'Тип договора', name:'contractType'}, {text: 'Статус', name:'valid'}]"
              [data]="contractsData"></app-table>
          </div>
        </app-accordion>

        <div class="divider"></div>
        <app-accordion [title]="'История изменений'"
                       [count]="sourceData._source && sourceData._source.changesHistory && sourceData._source.changesHistory.length">
          <div *ngIf="sourceData._source.changesHistory">
            <app-table
              [title]="[{text: 'Дата регистрации', name:'registrationDate'}, {text: 'Дата публикации', name:'publicationDate'}, {text: 'Тип изменения', name:'changeType'}]"
              [data]="sourceData._source.changesHistory"></app-table>
          </div>
        </app-accordion>

        <div class="divider"></div>
        <app-accordion [title]="'Другие товарные знаки этого правообладателя'"
                       [count]="countOverTradeMarks">
          <div class="trademarks-list__wrap">
            <div class="trademarks-list" *ngIf="overTradeMarks">
              <div class="trade-mark-card" *ngFor="let tradeMark of overTradeMarks; let i = index">
                <div class="image-body" *ngIf="tradeMark && tradeMark._source">
                  <img *ngIf="tradeMark._source.imageUrl && tradeMark._source.markImageFileName"
                       [src]="tradeMark._source.imageUrl" alt="">
                  <div *ngIf="!tradeMark._source.imageUrl || !tradeMark._source.markImageFileName"
                       class="text-instead-image">{{ tradeMark._source.imageText }}</div>
                </div>
                <div class="image-text" *ngIf="tradeMark._source && tradeMark._source.indexType">
                  {{tradeMark._source.indexType}}</div>
                <div *ngIf="tradeMark.typeOfOwner === 'owner'" class="label" [innerText]="'владелец'"></div>
                <div *ngIf="tradeMark.typeOfOwner === 'user'" class="label" [innerText]="'по договору'"></div>
              </div>
            </div>
          </div>
        </app-accordion>

        <!--        <div class="divider"></div>-->
        <!--        <app-accordion-->
        <!--          [title]="'Споры и суды'"-->
        <!--          [count]="0"-->
        <!--        >-->
        <!--        </app-accordion>-->
      </div>

    </ng-container>
  </div>

  <div (click)="closeModal()" class="overlay-space"></div>
</div>

<div class="page-overlay" id="modal-overlay"></div>

<ng-template #loader>
  <div class="loader-wrapper">
    <app-ls-emb-loader></app-ls-emb-loader>
  </div>
</ng-template>

