import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-monitoring-modal',
  templateUrl: './monitoring-modal.component.html',
  styleUrls: ['./monitoring-modal.component.scss']
})
export class MonitoringModalComponent implements OnInit {

  constructor(
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  @Input() data;
  @Output() modalEvent = new EventEmitter();
  @Output() selectObjectEvent = new EventEmitter();

  ngOnInit(): void {
    this.intercomEventsService.push({
      event: INTERCOM_EVENT_NAME.OPEN_ITEM,
      item_type: this.getObjNameByIndex(this.data.index[0].id) +
        (this.data.index[0].id === 'domains' ? '' : ' № ' + this.data.showId)
    });
  }

  closeModal() {
    this.modalEvent.emit();
  }

  getObjNameByIndex(index) {
    switch (index) {
      case 'rutm':
        return 'Товарный знак';
      case 'wotm':
        return 'Международный товарный знак';
      case 'wktm':
        return 'Общеизвестный товарный знак';
      case 'rugp':
        return 'Наименование мест происхождения товара';
      case 'rugpap':
        return 'Заявка на наименование мест происхождения товара';
      case 'rutmap':
        return 'Заявка на товарный знак';
      case 'domains':
        return 'Домен';
      case 'organizations':
        return 'Организация';
      case 'individual_entrepreneurs':
        return 'Индивидуальный предприниматель';
    }
  }

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  getCountRisksByLevel(level) {
    const res = this.data.risks.find(item => item.risk === level);
    if (res && res.values) {
      return res.values;
    } else {
      return 0;
    }
  }

  selectObject() {
    this.selectObjectEvent.emit(this.data);
  }
}
