<div class="zzmodal" *ngIf="show && trademark">
  <app-trademark-modal-new
    *ngIf="trademark._index && trademark._index === 'rutm'"
    [data]="trademark"
    (modalEvent)="closeModal()">
  </app-trademark-modal-new>

  <app-nmpt-modal
    *ngIf="trademark._index === 'rugp' && !trademark._source.registrationString.includes('/')"
    [data]="trademark"
    (modalEvent)="closeModal()">
  </app-nmpt-modal>

  <app-right-nmpt-modal
    *ngIf="trademark._index === 'rugp' && trademark._source.registrationString.includes('/')"
    [data]="trademark"
    (modalEvent)="closeModal()">
  </app-right-nmpt-modal>

  <app-application-nmpt-modal
    *ngIf="trademark._index === 'rugpap'"
    [data]="trademark"
    (modalEvent)="closeModal()">
  </app-application-nmpt-modal>

  <app-trademark-application
    *ngIf="trademark._index === 'rutmap'"
    [data]="trademark"
    (modalEvent)="closeModal()">
  </app-trademark-application>

  <app-well-known-trademark-modal
    *ngIf="trademark._index === 'wktm'"
    [data]="trademark"
    (modalEvent)="closeModal()">
  </app-well-known-trademark-modal>

  <app-international-trademark-modal
    *ngIf="trademark._index === 'wotm'"
    [data]="trademark"
    (modalEvent)="closeModal()">
  </app-international-trademark-modal>

  <app-foreign-trademark-modal
    *ngIf="getTrademarksIndex(trademark)"
    [data]="trademark"
    (modalEvent)="closeModal()"
  >
  </app-foreign-trademark-modal>
</div>
