<div class="progress-view-simple progress-view-simple-{{size}} fill-{{color}}">
  <div>
    <svg width="300" height="232" viewBox="0 0 300 232" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.3135 221C17.0407 200.183 10 175.914 10 150C10 72.6801 72.6801 10 150 10C227.32 10 290 72.6801 290 150C290 175.914 282.959 200.183 270.686 221"
        stroke="#F6F6F6" stroke-width="20" stroke-linecap="round"/>

      <path
        d="M29.3135 221C17.0407 200.183 10 175.914 10 150C10 72.6801 72.6801 10 150 10C227.32 10 290 72.6801 290 150C290 175.914 282.959 200.183 270.686 221"
        stroke-width="20" stroke-linecap="round" stroke-dasharray="590 1000" [attr.stroke-dashoffset]="dashoffset"
        class="stroke"/>
    </svg>

    <div class="percent-text">{{progress}}%</div>
  </div>
</div>
