<section class="section-top-search"
         [class.is-loading]="corpSearchByOwnerService.isLoading"
         [class.not-loaded]="!corpSearchByOwnerService.isLoaded"
>
  <div class="search_header">
    <div class="container">
      <div class="search-wrapper">
        <app-search-input
          [type]="'owner'"
          [value]="corpSearchByOwnerService.searchOptions.owner"
          placeholder="Поиск по правообладателю"
          (search)="onSearch($event)"
        ></app-search-input>
      </div>

      <app-corp-search-loader *ngIf="corpSearchByOwnerService.isLoading"></app-corp-search-loader>
    </div>
  </div>
</section>

<ng-container *ngIf="!corpSearchByOwnerService.isLoading && corpSearchByOwnerService.data">

  <section class="section-position section-position__tabs">
    <div class="container control">
      <button class="control__btn " (click)="changeTab('tradeMarks')"
              [class.control__btn--active]="currentTab === 'tradeMarks'">Товарные знаки<span
        class="control__count">{{corpSearchByOwnerService.total.tradeMarks}}</span>
      </button>
      <button class="control__btn " (click)="changeTab('domains')"
              [class.control__btn--active]="currentTab === 'domains'">Домены<span
        class="control__count">{{corpSearchByOwnerService.total.domains}}</span>
      </button>
    </div>
  </section>

  <app-corp-search-trademarks
    [owners]="owners"
    *ngIf="currentTab === 'tradeMarks'"
    [showFilters]="true"
    [showColumns]="['owner', 'date', 'classes']"
    [items]="corpSearchByOwnerService.items.tradeMarks"
    (select)="onSelect('tradeMarks', $event)"
  ></app-corp-search-trademarks>

  <app-corp-search-domains
    *ngIf="currentTab === 'domains'"
    [items]="corpSearchByOwnerService.items.domains"
    (select)="onSelect('domains', $event)"
  ></app-corp-search-domains>

  <app-corp-search-create-report *ngIf="!corpSearchApiService.objectsLoading"></app-corp-search-create-report>

</ng-container>
