import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimStringPipe } from './pipes/trim-string.pipe';
import { GetDutySumPipe } from './pipes/get-duty-sum.pipe';
import { GetShortOkvedPipe } from './pipes/get-short-okved.pipe';
import { ButtonModule } from '@ui/components/button/button.module';
import { IpIdSearchModule } from 'projects/app-search/src/app/app.module';
import { DeclOfNumModule } from '@ui/pipes/decl-of-num/decl-of-num.module';
import { MktuModalComponent } from './components/mktu-modal/mktu-modal.component';
import { MktuMobileComponent } from './components/mktu-mobile/mktu-mobile.component';
import { TrademarkMktuComponent } from './components/trademark-mktu/trademark-mktu.component';
import { LastTrademarkComponent } from './components/last-trademark/last-trademark.component';



@NgModule({
  declarations: [
    TrimStringPipe,
    GetDutySumPipe,
    GetShortOkvedPipe,
    MktuModalComponent,
    MktuMobileComponent,
    TrademarkMktuComponent,
    LastTrademarkComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    DeclOfNumModule,
    IpIdSearchModule,
  ],
  exports: [TrademarkMktuComponent],
})
export class TrademarkMktuModule { }
