import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  boot() {
    this.Intercom('boot', {
      app_id: environment.INTERCOM_APP_ID,
    });
  }

  private get Intercom() {
    return (window as any).Intercom;
  }

}
