import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab-payment-history',
  templateUrl: './tab-payment-history.component.html',
  styleUrls: ['./tab-payment-history.component.scss']
})
export class TabPaymentHistoryComponent implements OnInit {
  // Выпадающее меню Организация
  showOrganization = false;

  // Выпадающее меню Тип платежа - radio
  showPaymentType = false;
  radioSelected: any;

  // Выпадающее меню Тип услуги
  showServiceType = false;

  // Выбранные чекбоксы в селекте Организация
  filtersCount = {
    orgFilterData: 0,
    productNameFilterData: 0,
  }

  dateFilter = {
    startDate: undefined,
    endDate: undefined
  }

  date;

  // Модальное окно с чеком оплаты
  showModalPay = false;

  // Выбранный платеж в таблице для вызова модалки
  selectedPay;

  // Форма поиска на мобилке
  showMobileControl = false;

  // Тип платежа
  payments = [
    {
      id: '1',
      name: 'Пополнение',
      checked: false,
    },
    {
      id: '2',
      name: 'Списание',
      checked: false,
    }
  ];

  // Сколько показывать в истории платежей
  showPayment = 10;

  @Input() paymentHistory;
  paymentHistoryCopy;

  filters = {
    orgFilterData: [],
    productNameFilterData: [],
  };

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.paymentHistoryCopy = JSON.parse(JSON.stringify(this.paymentHistory));

    this.paymentHistory.forEach(item => {
      this.setItemToFiltersArray('orgFilterData', 'client', item);
      this.setItemToFiltersArray('productNameFilterData', 'productName', item);
    });
  }

  setItemToFiltersArray(filterName: string, fieldName: string, item) {
    if (this.filters[filterName].findIndex(el => el.name === item[fieldName]) === -1) {
      const mappedItem = {
        id: item[fieldName],
        checked: false,
        name: item[fieldName]
      };
      this.filters[filterName].push(mappedItem);
    }
  }

  toSelectedCasePage(caseId) {
    localStorage.setItem('selectCase', caseId);
    this.router.navigate(['/cases']);
  }



  // // Выпадлающее меню селекта Организация
  // getSelectedValueOrganization(status: boolean, value: string) {
  //   if (status) {
  //     this.checkedListOrganization.push(value);
  //   } else {
  //     const index = this.checkedListOrganization.indexOf(value);
  //     this.checkedListOrganization.splice(index, 1);
  //   }
  //   this.currentSelectedOrganization = {checked: status, name: value};
  // }

  // // Выпадлающее меню селекта Тип услуги
  // getSelectedValueService(status: boolean, value: string) {
  //   if (status) {
  //     this.checkedListService.push(value);
  //   } else {
  //     const index = this.checkedListService.indexOf(value);
  //     this.checkedListService.splice(index, 1);
  //   }
  //   this.currentSelectedService = {checked: status, name: value};
  // }

  // Показ всей истории платежей
  paymentShow(): void {
    this.showPayment += 10;
  }

  // Открыть модальное окно добавления представителя
  openModalPay(pay): void {
    this.showModalPay = true;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'hidden');
    this.selectedPay = pay;
  }

  // Закрыть модальное окно добавления представителя
  closeModal(): void {
    this.showModalPay = false;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  // Убрать скрол под модалкой с фильтром
  makeBodyHidden() {
    if (this.showMobileControl === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  applyFilters() {
    this.filtersCount = {
      orgFilterData: 0,
      productNameFilterData: 0
    }
    this.paymentHistory = JSON.parse(JSON.stringify(this.paymentHistoryCopy));
    this.applyFilterByName('orgFilterData', 'client');
    this.applyFilterByName('productNameFilterData', 'productName');
    this.applyFilterPaymentType();
    this.applyDateFilter();
  }


  applyDateFilter() {
    if (this.dateFilter.startDate) {
      this.paymentHistory = this.paymentHistory.filter(el => {
        if (new Date(el.date).getTime() >= this.dateFilter.startDate && new Date(el.date).getTime() <= this.dateFilter.endDate) {
          return true;
        }
      });
    }
  }


  applyFilterByName(filterName: string, fieldName: string) {
    const searchNames = [];
    this.filters[filterName].forEach(item => {
      if (item.checked) {
        this.filtersCount[filterName] = this.filtersCount[filterName] + 1;
        searchNames.push(item.name);
      }
    });
    if (searchNames.length) {
      this.paymentHistory = this.paymentHistory.filter(el => searchNames.includes(el[fieldName]));
    }
  }

  applyFilterPaymentType() {
    if (this.radioSelected) {
      if (this.radioSelected === 'Пополнение') {
        this.paymentHistory = this.paymentHistory.filter(el => el.deposit === true);
      } else {
        this.paymentHistory = this.paymentHistory.filter(el => el.deposit === false);
      }
    }
  }

  dateFilterChange(event) {
    if (event?.length) {
      this.dateFilter.startDate = new Date(event[0].setHours(0,0,0,0)).getTime();
      this.dateFilter.endDate = new Date(event[1].setHours(23,59,59,0)).getTime();
      this.applyFilters();
    }
  }

  clearFilters() {
    this.paymentHistory = JSON.parse(JSON.stringify(this.paymentHistoryCopy));
    this.radioSelected = undefined;
    this.date = [];
    this.filters.orgFilterData.forEach(item => {
      item.checked = false
    });
    this.filters.productNameFilterData.forEach(item => {
      item.checked = false
    });
    this.payments.forEach(item => {
      item.checked = false
    });
    this.dateFilter.endDate = undefined;
    this.dateFilter.startDate = undefined;
    this.filtersCount = {
      orgFilterData: 0,
      productNameFilterData: 0
    }
  }

  checkAppliedFilters(): boolean {
    return this.filtersCount.orgFilterData || this.filtersCount.productNameFilterData || this.radioSelected || this.dateFilter.startDate;
  }
}
