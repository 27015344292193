import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { map, take, takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { OauthService } from '../oauth.service';
import { EnvService } from '../../../../app-ipid/src/app/shared/services/env.service';
import { SocialAuthService } from '../../../services/social-auth.service';
import { LoginService } from '../../../services/login.service';

import { environment as ipidEnvironment } from '../../../../app-ipid/src/environments/environment';
import { environment as debtEnvironment } from '../../../../app-debit/src/environments/environment';
import { RegistrationService } from '../../../services/registration.service';
import { UserProfileService } from '../../../services/user-profile.service';
import { Store } from '@ngxs/store';
import { AuthActions } from '@web/core/states/auth.actions';
import {document} from 'ngx-bootstrap/utils';
import { InterestService } from '../../../services/interest.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

let environment;

declare var window: any;

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  newReg = true;
  brand = '';
  footerText: string;
  logoPath: string;
  staticFolder = '';

  registrationForm: FormGroup;
  passwordMatch: boolean;
  private readonly onDestroy = new Subject<void>();
  errorText;
  errorRegister = false;
  counter$: Observable<number>;
  count = 45;
  showTimer = false;
  passwordPattern =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s\n\t])[A-Za-z\d^\W\s\n\t].{5,}$/;
  emailPattern =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  showLoader: boolean;
  phone = '';
  mobile = '';
  utmData: any;
  invitedUser;
  code;
  showPage = false;
  subRoute: Subscription;
  isDebit;
  showModalSupport = false;

  constructor(
    private registerApi: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private userProfileService: UserProfileService,
    private oauth: OauthService,
    readonly envService: EnvService,
    private socialApi: SocialAuthService,
    private readonly intercomEventsService: IntercomEventsService,
    readonly store: Store,
    private interestService: InterestService
  ) {
    this.subRoute = this.route.data.subscribe((data) => {
      this.isDebit = data?.isDebit;

      if (this.isDebit) {
        environment = debtEnvironment;
      } else {
        environment = ipidEnvironment;
      }

      this.brand = environment.BRAND;
      this.footerText = environment.FOOTER_TEXT;
      this.logoPath = environment.LOGO_PATH;
      this.staticFolder = environment.STATIC_FILES;

      console.log('brand: ', this.brand);
      console.log('prod: ', environment.production);
      console.log('isDebit: ', this.isDebit);
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.getInvitedUser(params.id);
      } else {
        this.utmData = params;
      }
    });

    this.showPage = true;
    this.showLoader = false;
    this.passwordMatch = true;
    this.initForm();
    if (window.innerWidth > 768) {
      this.mobile = 'Номер мобильного телефона';
    } else {
      this.mobile = 'Номер моб.телефона';
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        this.mobile = 'Номер мобильного телефона';
      } else {
        this.mobile = 'Номер моб.телефона';
      }
    });
  }

  getInvitedUser(id) {
    const params = { id };
    this.registerApi
      .getInviteUser(params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data: any) => {
        if (data?.invitation) {
          this.invitedUser = data.invitation;
          this.setInvitedUserData();
        }
      });
  }

  setInvitedUserData() {
    if (this.invitedUser.email) {
      this.registrationForm.controls.email.setValue(this.invitedUser.email);
    }
  }

  initForm() {
    const PassPattern = this.passwordPattern;
    const emailPattern = this.emailPattern;
    this.registrationForm = new FormGroup({
      email: new FormControl(null, Validators.pattern(emailPattern)),
      newPassword: new FormControl(null, Validators.pattern(PassPattern)),
    });
  }

  registerUser() {
    this.showLoader = true;
    const data = {
      login: this.registrationForm.value.email,
      password: this.registrationForm.value.newPassword,
      interestData: this.interestService.addInterestData()
    };
    const params = new HttpParams().set('brand', this.brand);
    this.registerApi
      .registerUser(data, params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((regData) => {
        if (regData.answer === 'Успешно') {
          if (!this.isDebit) {
            this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_USER_REGISTERED });
          }
          this.login();
        } else {
          this.showLoader = false;
          this.errorRegister = true;
          this.errorText = regData.answer;
        }
      });
  }

  login() {
    const authData = {
      login: this.registrationForm.value.email,
      pass: this.registrationForm.value.newPassword,
    };

    this.store.dispatch([new AuthActions.Login(authData)]).subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  comparePasswords() {
    if (
      this.registrationForm.value.newPassword ===
      this.registrationForm.value.password
    ) {
      console.log('no match');
      this.passwordMatch = true;
    } else {
      this.passwordMatch = false;
    }
  }

  googleRegister() {
    const interestData = this.interestService.addInterestData();
    const urlParams = new HttpParams()
      .set('client_id', this.socialApi.getGoogleId(environment.BRAND))
      .set(
        'scope',
        'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
      )
      .set('response_type', 'code')
      .set('state', 'brand.' + environment.BRAND + '/' + 'provider.' + 'google' + '/' + 'interestData.' + JSON.stringify(interestData))
      .set('redirect_uri', `${environment.BASE_URL_API}/user/oauth`);
    document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${urlParams.toString()}`;
  }

  facebookLogin() {
    const urlParams = new HttpParams()
      .set('client_id', this.socialApi.getFacebookId(environment.BRAND))
      .set('scope', 'email')
      .set(
        'state',
        'brand.' + environment.BRAND + '/' + 'provider.' + 'facebook'
      )
      .set('redirect_uri', `${environment.BASE_URL_API}/user/oauth`);
    document.location.href = `https://www.facebook.com/dialog/oauth?${urlParams.toString()}`;
  }

  yandexCodeGet() {
    const interestData = this.interestService.addInterestData();
    const urlParams = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', this.socialApi.getYandexId(environment.BRAND))
      .set('state', 'brand.' + environment.BRAND + '/' + 'provider.' + 'yandex' + '/' + 'interestData.' + JSON.stringify(interestData))
      .set('redirect_uri', `${environment.BASE_URL_API}/user/oauth`);
    document.location.href = `https://oauth.yandex.ru/authorize?${urlParams.toString()}`;
  }

  openModalSupport() {
    this.showModalSupport = true;
    if (this.showModalSupport) {
      document.getElementsByTagName('header')[0].style.zIndex = 'initial';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
  }

  closeModalSupport() {
    this.showModalSupport = false;
    document.body.style.overflow = 'visible';
    document.body.style.position = '';
    document.body.style.height = 'auto';
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.showLoader = false;
  }
}
