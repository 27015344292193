import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Router } from '@angular/router';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';

@Component({
  selector: 'app-service-slider',
  templateUrl: './service-slider.component.html',
  styleUrls: ['./service-slider.component.scss']
})
export class ServiceSliderComponent implements OnInit {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  @Input() sliderCards;

  // Изначально кнопка "назад" скрыта
  btnPrevShow = false;
  btnNextShow = true;

  // Чтобы работал слайдер
  @ViewChild('slider', {read: DragScrollComponent}) ds: DragScrollComponent;

  constructor(private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  toServiceDetails(id) {
    this.router.navigate(['services/' + id]);
  }

  // Клик влево
  moveLeft(): void {
    this.ds.moveLeft();
  }

  // Клик вправо
  moveRight(): void {
    this.ds.moveRight();
  }

  // При достижении левого края скрывается кнопка "назад"
  leftBoundStat(reachesLeftBound: boolean) {
    this.btnNextShow = reachesLeftBound;
  }

  // При достижении правого края скрывается кнопка "вперед"
  rightBoundStat(reachesRightBound: boolean) {
    this.btnPrevShow = reachesRightBound;
  }
}
