import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonIconComponent } from './button-icon.component';



@NgModule({
  declarations: [ButtonIconComponent],
  imports: [CommonModule],
  exports: [ButtonIconComponent],
})
export class ButtonIconModule { }
