import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() color: 'default' | 'blue' | 'orange' | 'green' | 'red' = 'default';
  @Input() color2: 'default' | 'blue' | 'orange' | 'green' | 'red' = 'default';
  @Input() checked: boolean;
  @Output() toggle = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

}
