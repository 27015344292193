import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TrademarkState } from '../../../../store/trademark.state';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkActions } from '../../../../store/trademark.actions';
import { LastRequestTrademark, MKTU, OKVEDCode, OptionsMKTU, SearchLegalResponseSourse } from '../../../../types/trademark.types';

@Component({
  selector: 'app-trademark-mktu',
  templateUrl: './trademark-mktu.component.html',
  styleUrls: ['./trademark-mktu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkMktuComponent {
  @ViewSelectSnapshot(TrademarkState.OKVEDCodes) OKVEDCodes: OKVEDCode[];
  @ViewSelectSnapshot(TrademarkState.optionsMktu) optionsMktu: OptionsMKTU;
  @ViewSelectSnapshot(TrademarkState.ownerShortName) ownerShortName: string;
  @ViewSelectSnapshot(TrademarkState.showSearchInput) showSearchInput: boolean;
  @ViewSelectSnapshot(TrademarkState.owner) owner: SearchLegalResponseSourse | null;
  @ViewSelectSnapshot(TrademarkState.lastTrademark) lastTrademark: LastRequestTrademark | null;
  @Dispatch() backStep = () => new TrademarkActions.BackStep();
  @Dispatch() nextStep = () => new TrademarkActions.SaveMKTUForRequest();
  @Dispatch() updateMKTUClasses = (mktu: MKTU[]) => new TrademarkActions.UpdateMKTUClasses(mktu);
}
