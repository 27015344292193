<div class="monitoring-modal risk-modal">
  <div class="monitoring-modal__wrap">
    <div class="risk-modal__content">
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
      1 <br>
    </div>
    <!--    <div class="risk-modal__footer">-->

    <!--    </div>-->
    <button (click)="closeModal()" class="risk-modal__close"></button>
  </div>
  <div (click)="closeModal()" class="monitoring-modal__overlay"></div>
</div>

