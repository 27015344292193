<div class="page-wrapper">
  <main>
    <section class="form-section">
      <div class="container">
        <a (click)="backClicked()" class="back-link">
            <span>
              <svg width="12" height="8" viewBox="0 0 21 16">
                <g clip-path="url(#clip0)">
                 <path d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                  <rect width="21" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          Назад
        </a>
        <div class="container-md">
          <h1 class="heading-h1 heading-h1--centered">Пополнить баланс</h1>
          <form [formGroup]="form">
            <div class="form__group">
              <div class="form__input-wrap">
                <input
                  formControlName="sum"
                  class="form__input field"
                  type="text"
                  required>
                <span class="form__input-label">Укажите сумму пополнения в рублях</span>
              </div>
            </div>
            <div class="pay-modal__tabs">
              <button class="pay-modal__tab" (click)="openOnline()" [ngClass]="{'pay-modal__tab--checked': online}">Оплата
                онлайн
              </button>
              <button class="pay-modal__tab" (click)="openCash()" [ngClass]="{'pay-modal__tab--checked': cash}">Выставить
                счет для безналичной оплаты
              </button>
            </div>
            <div class="" *ngIf="online">
              Компонент яндекс денег
            </div>
            <div class="" *ngIf="cash">
              <div class="form__group">
                <div class="form__input-wrap">
                  <input class="form__input field" formControlName="INN" type="number"
                         style="pointer-events: none"><span class="form__input-label">ИНН</span>
                </div>
              </div>
              <div class="form__group">
                <div class="form__input-wrap">
                  <input class="form__input field" formControlName="OGRN" type="number"
                         style="pointer-events: none"><span class="form__input-label">ОГРН</span>
                </div>
              </div>
              <div class="form__group">
                <div class="form__input-wrap">
                  <input class="form__input field" formControlName="name" type="text"
                         style="pointer-events: none"><span
                  class="form__input-label">Наименование юрлица или ИП</span>
                </div>
              </div>
              <div class="form__group">
                <div class="form__input-wrap">
                  <input class="form__input field" formControlName="address" type="text"
                         style="pointer-events: none"><span
                  class="form__input-label">Юридический адрес</span>
                </div>
              </div>
            </div>
            <div class="btn-wrap" disabled>
              <button class="btn">
                <span>Продолжить</span>
                <svg width="25" height="16" viewBox="0 0 25 16">
                  <path
                    d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"/>
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</div>
