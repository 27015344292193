<div *ngIf="$tracks?.length" class="container-top__state-wrapper">
  <div *ngFor="let track of $tracks; index as i" class="container-top__state"
       [ngClass]="track.reach ? 'container-top__state--' + trackColors[i] : 'container-top__state--current'">
    <div class="container-top__state-svg">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path [ngClass]="track.reach ? 'container-top__state-svg--' + trackColors[i] : 'container-top__state-svg'"
              d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"/>
        <path *ngIf="track.reach" fill-rule="evenodd" clip-rule="evenodd"
              d="M18.7072 8.70712L10.0001 17.4142L5.29297 12.7071L6.70718 11.2929L10.0001 14.5858L17.293 7.29291L18.7072 8.70712Z"
              fill="white"/>
      </svg>
    </div>
    <p>{{track.name}}</p>
    <span *ngIf="track.date">{{track.date}}</span>
  </div>
</div>
