import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components/card/card.component';
import { InputComponent } from './components/input/input.component';
import { SelectorComponent } from './components/selector/selector.component';
import { FormsModule } from '@angular/forms';
import { AskExpertComponent } from './components/ask-expert/ask-expert.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SpinnerComponent } from '@ui/shared/components/spinner/spinner.component';
import { ObjectsFoundComponent } from './components/objects-found/objects-found.component';
import { TrimPipe } from '@ui/shared/pipes/trim.pipe';
import { OverflowDirective } from './directives/hide-overflowed.directive';
import { DaterangeComponent } from './components/daterange/daterange.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonComponent } from './components/button/button.component';
import { PaginatorComponent } from './components/pagination/pagination.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AccordionComponent } from './components/accordion/accordion.component';

@NgModule({
  imports: [CommonModule, FormsModule, IvyCarouselModule, BsDatepickerModule],
  declarations: [
    CardComponent,
    InputComponent,
    SelectorComponent,
    AskExpertComponent,
    CarouselComponent,
    SpinnerComponent,
    ObjectsFoundComponent,
    TrimPipe,
    OverflowDirective,
    DaterangeComponent,
    ButtonComponent,
    PaginatorComponent,
    AutocompleteComponent,
    AccordionComponent,
  ],
  exports: [
    CardComponent,
    InputComponent,
    SelectorComponent,
    AskExpertComponent,
    CarouselComponent,
    SpinnerComponent,
    ObjectsFoundComponent,
    TrimPipe,
    OverflowDirective,
    DaterangeComponent,
    PaginatorComponent,
    AutocompleteComponent,
    AccordionComponent,
    ButtonComponent,
    DaterangeComponent,
  ]
})
export class UiSharedModule {}
