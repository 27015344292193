import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {
  footerText = '';

  constructor() {
    this.footerText = environment.FOOTER_TEXT;
  }

  ngOnInit() {
  }

}
