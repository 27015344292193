<section class="section-position ">
  <div class="container container--small ">
    <div *ngIf="first" class="payment">
      <div class="add-object" >
        <h2 class="add-object__header" >Редактирование профиля</h2>
        <div class="add-object__btns-pay-wrapper">
          <button class="add-object__btns-pay " (click)="personal=!personal; password=!password" [class.add-object__btns-pay--blue]="personal">Персональная информация</button>
          <button class="add-object__btns-pay" (click)="personal=!personal; password=!password" [class.add-object__btns-pay--blue]="password">Сменить пароль</button>
        </div>

        <form *ngIf="personal==true && password===false" class="form" [formGroup]="PersonalForm" >
          <div class="form__group" >
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="name">
              <span class="form__input-label">Ваше имя</span>
            </div>
          </div>
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="secondName">
              <span class="form__input-label">Фамилия</span>
            </div>
          </div>
          <div class="form__group--wrapper">
            <div class="form__group" >
              <div class="form__input-wrap">
                <input class="form__input field" type="number" required formControlName="phone">
                <span class="form__input-label">Телефон</span>
              </div>
            </div>
            <div *ngIf="phoneConfirmed" class="form__btn">
              <button type="button">
                Подтвержден
              </button>
            </div>
            <div *ngIf="phoneConfirmed===false" class="form__btn">
              <button type="button">
                Получить код
              </button>
            </div>
          </div>
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="email">
              <span class="form__input-label">Email</span>
            </div>
          </div>
          <button class="btn-blue" [attr.disabled]="checkValidForm()? '' : null">Сохранить данные</button>
        </form>

        <form *ngIf="password==true && personal===false" class="form" [formGroup]="PasswordForm" >
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="oldPassword">
              <span class="form__input-label">Текущий пароль</span>
            </div>
          </div>
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="newPassword">
              <span class="form__input-label">Новый пароль</span>
            </div>
          </div>
          <div  class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="newPasswordRepeat">
              <span class="form__input-label">Повторите новый пароль</span>
            </div>
          </div>
          <button class="btn-blue" [attr.disabled]="checkValidForm()? '' : null">Сохранить новый пароль</button>
        </form>
      </div>
    </div>
  </div>
</section>
