import { Component } from '@angular/core';
import {RequestContractService} from '../shared/request-contract.service';
import {environment} from '../../../../environments/environment';
import {INTERCOM_EVENT_NAME, IntercomEventsService} from '../../../../../../shared/services/intercom-events.service';

@Component({
  selector: 'app-finished',
  templateUrl: './finished.component.html',
  styleUrls: ['./finished.component.scss']
})
export class FinishedComponent {
  public logo2path: string = '';

  constructor(
    readonly requestContractService: RequestContractService,
    private intercomEventsService: IntercomEventsService
  ) {
    this.logo2path = environment.LOGO2_PATH;
  }

  get isLoading(): boolean {
    return this.requestContractService.isLoading;
  }

  public sendIntercomEvent(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.GOTO_ORDER });
  }

}
