import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, forwardRef, Input } from "@angular/core";
import { AbstractValueAccessorComponent } from "../abstract-value-accessor/abstract-value-accessor.component";

@Component({
  selector: 'app-ui-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    }
  ]
})
export class AutocompleteComponent extends AbstractValueAccessorComponent {
  @Input() icon = 'assets/icons/icon-search.svg';
  @Input() placeholder: string = '';
  @Input() options: any[] = [];

  public inputValue: string = '';
  public isShowDopdown: boolean = false;
  
  set value(value: string) {
    if (value !== undefined && this.inputValue !== value) {
      this.inputValue = value;
      this.onChange(value);
      this.onTouch();
    } 
  }

  get filteredOptions(): any[] {
    return this.options.filter(item => item.label.toLowerCase().includes(this.inputValue.toLowerCase()));
  }

  public update(value: string): void {
    this.value = value;
    this.isShowDopdown = true;
  }

  public complete(value: string): void {
    this.value = value;
    this.isShowDopdown = false;
  }
}
