import { Component, OnInit } from '@angular/core';
import { RequestContractService } from '../../shared/request-contract.service';

@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.scss']
})
export class RiskComponent implements OnInit {

  result = 'Изменить';

  constructor(
    private requestContractService: RequestContractService,
  ) { }

  ngOnInit(): void {
    this.result = this.requestContractService.riskData.result;
  }

  change(data) {
    this.requestContractService.riskData.result = data;
    this.result = data;
  }
}
