import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  readonly createRequestModal = {
    open: new Subject(),
    close: new Subject(),
  };

  openCreateRequestModal() {
    // TODO: подумать над этим
    this.createRequestModal.open.next(null);
  }

  closeCreateRequestModal() {
    // TODO: подумать над этим
    this.createRequestModal.close.next(null);
  }

}
