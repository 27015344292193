import { Routes } from '@angular/router';
import { REQUEST_TYPES } from '@web/core/models/user.interface';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import { TrademarkComponent } from '../components/trademark/trademark.component';
import { LoadTrademarkRequestGuard } from './guards/load-trademark-request.guard';
import { TrademarkStepComponent } from '../components/trademark-step/trademark-step.component';

export const TRADEMARK_ROUTES: Routes = [
  {
    path: `${APP_ROUTES_NAMES.REQUESTS}/${APP_ROUTES_NAMES.TRADEMARK}`,
    component: TrademarkComponent,
    data: { requestType: REQUEST_TYPES.TRADEMARK },
    children: [
      {
        path: '',
        data: { requestType: REQUEST_TYPES.TRADEMARK },
        canActivate: [LoadTrademarkRequestGuard],
        component: TrademarkStepComponent,
      },
      {
        path: APP_ROUTES_NAMES.REQUEST_ID,
        data: { requestType: REQUEST_TYPES.TRADEMARK },
        canActivate: [LoadTrademarkRequestGuard],
        component: TrademarkStepComponent,
      }
    ],
  },
  {
    path: `${APP_ROUTES_NAMES.REQUESTS}/${APP_ROUTES_NAMES.TRADEMARK_SEARCH}`,
    component: TrademarkComponent,
    data: { requestType: REQUEST_TYPES.TRADEMARK_SEARCH },
    children: [
      {
        path: '',
        data: { requestType: REQUEST_TYPES.TRADEMARK_SEARCH },
        canActivate: [LoadTrademarkRequestGuard],
        component: TrademarkStepComponent,
      },
      {
        path: APP_ROUTES_NAMES.REQUEST_ID,
        data: { requestType: REQUEST_TYPES.TRADEMARK_SEARCH },
        canActivate: [LoadTrademarkRequestGuard],
        component: TrademarkStepComponent,
      }
    ],
  },
];

