<div *ngIf="userData" class="page-wrapper">
  <main>
    <section class="form-section profile">
      <div class="container">
        <a (click)="backClicked()" class="back-link">
            <span>
              <svg width="12" height="8" viewBox="0 0 21 16">
                <g clip-path="url(#clip0)">
                 <path
                   d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                  <rect width="21" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          Назад
        </a>
        <div class="container-sm">
          <h1 class="heading-h1 heading-h1--centered">Изменение персональных данных</h1>
          <form [formGroup]="editUser">
            <div class="profile__avatar-wrap">
              <div *ngIf="!isImageLoading; else loader"  class="profile__avatar-img">
                <img *ngIf="userData?.image" src="{{userData.image}}" alt="" class="image-avatar-change">
                <img *ngIf="!userData?.image" src="/assets/images/avatar.png" alt="">
              </div>

              <ng-template #loader>
                <app-spinner style="height: 100px" class="spinner-full"></app-spinner>
              </ng-template>

              <input id="profile-photo" (change)="fileChangeEvent($event)" type="file" accept="image/*"
                     class="profile__avatar-field">
              <div class="profile__avatar-label-wrap">
                <label for="profile-photo" class="profile__avatar-label">
                  <span *ngIf="!userData?.image">Загрузить фото</span>
                  <span *ngIf="userData?.image">Заменить фото</span>
                </label>
                <span class="profile__avatar-delete" (click)="deleteImage()" *ngIf="userData?.image">Удалить фото</span>
              </div>

            </div>
            <p class="file-error" *ngIf="imageError?.length">{{imageError}}</p>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="name"
                       class="form__input field"
                       type="text"
                       [ngClass]="{ 'error': editUser.get('name').invalid && editUser.get('name').touched }"
                       required>
                <span class="form__input-label">Ваше имя</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="surname"
                       class="form__input field"
                       type="text"
                       [ngClass]="{ 'error': editUser.get('surname').invalid && editUser.get('surname').touched }"
                       required>
                <span class="form__input-label">Фамилия</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="position"
                       class="form__input form__input--linked field"
                       type="text"
                       [ngClass]="{ 'error': editUser.get('position').invalid && editUser.get('position').touched }"
                       required>
                <span class="form__input-label">Должность</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="email"
                       class="form__input form__input--linked field disabled"
                       type="text"
                       (click)="checkEmail()"
                       (keyup)="checkEmail()"
                       [ngClass]="{ 'error': editUser.get('email').invalid && editUser.get('email').touched }"
                       required>
                <span class="form__input-label">Email</span>
              </div>
            </div>
            <p *ngIf="errorText" class="error_label">{{errorText}}</p>
            <div class="form__footer">
              <button *ngIf="!loading"
                      class="btn" (click)="editUsers()"
                      type="button"
                      [ngClass]="disableButton ? 'update-button-disabled':''">Сохранить данные</button>
              <button *ngIf="loading" class="btn" type="button" style="width: 236px"><span class="loaderAuth"></span></button>
            </div>
          </form>
        </div>

        <div class="container-sm personal-data">

          <h1 class="heading-h1">Номер телефона</h1>

          <p *ngIf="!phoneVerified" class="personal-data__title">
            Для изменения номера, пожалуйста, укажите свой новый контактный номер телефона
          </p>

          <div *ngIf="isShowphoneVerifiedMessage" class="personal-data__success success">
            <div class="success__title-wrap">
              <h3>Телефон успешно добавлен</h3>
              <button (click)="removeSuccess()" aria-label="Закрыть"></button>
            </div>
          </div>

<!--          <app-user-phone-->
<!--            [header]="false"-->
<!--            [isEditPhone]="true"-->
<!--            (personalDataStatus)="getPersonalDataStatus($event)"-->
<!--          ></app-user-phone>-->

          <form [formGroup]="phoneForm">
            <div class="form__group form__group--fields" style="position: relative; margin-right: 0;">
            <div class="form__col form__col--2-3-md">
              <div class="form__input-wrap">
                <input formControlName="phone"
                       class="form__input form__input--linked field"
                       type="tel"
                       (ngModelChange)="initiatePhoneForm()"
                       (input)="onInputPhone()"
                       required>
                <span class="form__input-label">Телефон</span>
              </div>
            </div>

            <div (click)="codeGetter()" *ngIf="!phoneVerified" class="form__col form__col--1-3-md" [class.update-button-disabled]="!validatePhone()">
              <p class="tel-confirm ">
                Подтвердить
              </p>
            </div>

            <div *ngIf="phoneVerified && !showCodeInput" class="form__col form__col--1-3-md">
              <p class="tel-confirm verified">
                Подтвержден
              </p>
            </div>

            <input
              *ngIf="showCodeInput"
              (keyup)="checkCode($event, 4)"
              formControlName="validationCode"
              class="form__col form__col--1-3-md"
              style="border: 0; border-radius: 5px;box-shadow: 0 3px 6px rgba(111, 132, 152, 0.1); color: #162941; font-size: 18px; padding: 27px 10px 14px 20px">
            <span *ngIf="showCodeInput" class="form__input-label" style="position: absolute; top: 22px; left: 413px; font-size: 0.8125rem;">Код подтверждения</span>
          </div>
          </form>

          <p style="margin-top: 10px" *ngIf="phoneErrorText" class="error_label">{{phoneErrorText}}</p>

        </div>

      </div>
    </section>
  </main>
</div>
