import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-duties',
  templateUrl: './duties.component.html',
  styleUrls: ['./duties.component.scss']
})
export class DutiesComponent implements OnInit {
  @Input() duties;
  @Input() activeDuty;
  @Input() paymentSum;
  @Input() tariffTitle;
  @Output() selectingDuty = new EventEmitter();
  isDuties;
  sum; // Итоговая сумма к оплате
  dutySum;

  constructor() { }

  ngOnInit(): void {
    if (this.activeDuty) {
      this.switchDuties(this.activeDuty);
    } else {
      this.switchDuties('later');
    }
  }

  switchDuties(type): void {
    switch (type) {
      case 'later':
        this.isDuties = 'later';
        this.sum = this.paymentSum;
        this.dutySum = +this.duties?.later?.sumDiscount;
        break;
      case 'request_only':
        this.isDuties = 'request_only';
        this.sum = +this.paymentSum + +this.duties?.request_only?.sumDiscount;
        this.dutySum = +this.duties?.request_only?.sumDiscount;
        break;
      case 'all':
        this.isDuties = 'all';
        this.sum = +this.paymentSum + +this.duties?.all?.sumDiscount;
        this.dutySum = +this.duties?.all?.sumDiscount;
        break;
    }
    this.selectingDuty.emit({type, paymentSumWithDuty: this.sum});
  }

}
