<div *ngIf="!isLoading; else load" xmlns="http://www.w3.org/1999/html">
  <div class="info-plate">
    <div class="info-plate__img">
      <img alt="logo" [src]="logo2path">
    </div>
    <p class="info-plate__text">
      Как только ваша оплата будет обработана,
      <br class="hide-up-to-md">
      мы переместим вашу заявку из черновиков в активные дела.
    </p>
    <a class="btn" routerLink="/cases" (click)="sendIntercomEvent()">
      Перейти к заявке
    </a>
  </div>
</div>

<ng-template #load>
  <div class="loader"></div>
</ng-template>

