<app-add-doc *ngIf="addDoc" (modalEvent)="closeModal()"></app-add-doc>

<section class="section-position section-position__color-bg section-position__color-bg--court">
  <div class="container header-court">
    <div class="header-court__block header-court__block--head">
      <div>
        <p class="header-court__link-back" routerLink="/court">
          <span>СИП-51/2021</span>Суд по интеллектуальным правам, Вероятность удовлетворения ― средняя
        </p>
        <button (click)="openModal()">Прикрепить документы к делу</button>
      </div>
      <div>
        <div class="resizable"
             #titleDiv
             contenteditable
             (input)="getChangedTitle(titleDiv)">{{title}}
        </div>
        <span class="edit" (click)="setResizableWidth(title,titleDiv)"></span>
      </div>
      <p>Судья: Лапшина И. В.</p>
    </div>
    <div class="header-court__block header-court__block--row">
      <div class="header-court__card  header-court__card--court">
        <p class="header-court__card-bottom ">Этап</p>
        <h2 class="header-court__card-header-main header-court__card-header-main--court">Подготовка искового
          заявления</h2>
        <p class="header-court__card-bottom-txt">Рассматривается в первой инстанции</p>
      </div>
      <div class="header-court__card--wrapper">
        <div class="header-court__on-card">наш процессуальный статус</div>
        <div class="header-court__card ">
          <p class="header-court__card-bottom ">Истец</p>
          <h2 class="header-court__card-header-main header-court__card-header-main--court">
            {{trimString('Интериор Инжиниринг, ООО', 38)}}
            <span class="prompt__hidden">
            <p>Интериор Инжиниринг, ООО</p>
          </span>
          </h2>
          <small>{{trimString('ОГРН 234246457', 32)}}</small>
        </div>
      </div>
      <div class="header-court__card ">
        <p class="header-court__card-bottom ">Ответчики</p>
        <h2 class="header-court__card-header-main header-court__card-header-main--court">
          {{trimString('Лиснянский Алексей Григорьевич', 38)}}
          <span class="prompt__hidden">
            <p>Лиснянский Алексей Григорьевич</p>
          </span>
        </h2>
        <small>
          +2 ответчика
          <span class="prompt__hidden">
              <p>Окнасервис, ООО</p>
              <span>ОГРН 5147746214391</span>
              <p>Окнасервис, ООО</p>
              <span>ОГРН 5147746214391</span>
            </span>
        </small>
      </div>
      <div class="header-court__card ">
        <div class="header-court__card-bottom">Третьи лица
        </div>
        <h2 class="header-court__card-header-main header-court__card-header-main--court">―</h2>

      </div>

    </div>
  </div>
</section>

<section
  class="section-position section-position__color-bg section-position__color-bg--court section-position--padding">
  <div class="container control">
    <button class="control__btn" (click)="events=true; docs=false; claimsInfo=false"
            [class.control__btn--active]="events">События<span class="control__count">8</span>
    </button>
    <button class="control__btn" (click)="docs=true; events=false; claimsInfo=false"
            [class.control__btn--active]="docs"
    >Документы<span class="control__count">8</span>
    </button>
    <button class="control__btn" (click)="docs=false; events=false; claimsInfo=true"
            [class.control__btn--active]="claimsInfo"
    >Информация о претензии<span class="control__count">8</span>
    </button>
  </div>
</section>

<section class="section-position section-position--white">

  <app-accordion-tabs *ngIf="events"></app-accordion-tabs>

  <app-docs-tab *ngIf="docs" [type]="'court'"></app-docs-tab>

  <app-info-tab *ngIf="claimsInfo" [type]="'court'"></app-info-tab>

</section>

<app-expert></app-expert>
