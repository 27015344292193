<section class="tariff" (click)="select.emit(tariff.id)">
  <div
    [id]="id"
    class="tariff-card--mobile"
    [class.show-flex]="mobile && !active"
    [class.hide]="!mobile || (mobile && active)"
  >{{tariff.title}}</div>

  <div
    class="tariff-card"
    [class.show]="!mobile"
    [class.hide]="mobile && !active"
    [class.tariff-card--highlight]="active"
  >
    <div class="tariff-card__title">
      {{tariff.title}} <span *ngIf="tariff.hit" class="tariff-card__title--hit"></span>
    </div>

    <div class="progress-risk" *ngIf="['trademark', 'trademarkSearch'] | switchMultiCase:request"></div>
    <hr color="#DCDEE1" noshade size="1">

    <div class="tariff-card__price">
      <div *ngIf="tariff.discount" class="prices-wrapper">
        <div class="old-price">{{tariff.sum | toLocaleString}}</div>
        <div class="discount">-{{tariff.discount}}%</div>
      </div>
      <div class="full-price">{{tariff.sumDiscount | toLocaleString}}</div>
    </div>

    <div class="tariff-card__description">
      <div class="title">В тариф включено</div>
      <div *ngFor="let desc of tariff.descriptions">
        <hr color="#EBEDF2" noshade size="1">
        <div class="item">
          {{desc.description}}
          <div class="info-icon">
            <div class="info-tooltip">{{desc.hint}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
