import { MKTU } from '@web/features/requests/trademark/types/trademark.types';
import mktuData from 'projects/app-search/src/app/pages/ip-id-search/json-data/mktu';
import { Component, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-mktu-modal',
  templateUrl: './mktu-modal.component.html',
  styleUrls: ['./mktu-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MktuModalComponent {
  @Input() set selectedMKTU(value: number[]) {
    this.selected = [...value];
  }
  @Output() applyCodes = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  
  public selected: number[] = [];
  public showMassive: number[] = [];
  public mktuData: MKTU[] = JSON.parse(JSON.stringify(mktuData));

  public checkedClass(code: number): void {
    this.selected.includes(code)
      ? this.selected.filter(item => item !== code)
      : this.selected.push(code);
  }

  public showDescriptionClick(code: number): void {
    this.showMassive.includes(code)
      ? this.showMassive.filter(item => item !== code)
      : this.showMassive.push(code);
  }

  public closeSuccess(): void {
    this.applyCodes.emit(this.selected);
  }
}
