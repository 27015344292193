<div [ngSwitch]="countRates">
  <div *ngSwitchCase="3" class="rates rates__main-wrapper">
    <div class="rates__rate-card-wrapper" *ngIf="rates.min" (click)="selectTariff(rates.min);">
      <div tabindex="0" class="rate-card__mobile-name" (click)="max3min=!max3min;toCheckMax3Min()"
           [ngStyle]="{'display': max3min ? 'none' : 'flex' }">{{rates.min.title}}</div>
      <div class="rate-card" *ngIf="max3min===true" [ngClass]="{'rate-card--highlight': rates.min.id == activeTariff}">
        <div class="rate-card__name" id="rateMinCardName">{{rates.min.title}}
          <span class="rate-card__name--hit" *ngIf="rates.min.hit"></span>
        </div>
        <div class="progress-risk" *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'">
        </div>
        <hr color="#DCDEE1" noshade size="1">
        <div class="price">
          <div *ngIf="rates.min.discount !== 0 || rates.min.discount !== 0" class="prices-wrapper">
            <div class="old-price" *ngIf="rates.min.discount !== 0">{{(+rates.min.sum).toLocaleString('ru')}}₽
            </div>
            <div class="discount" *ngIf="rates.min.discount !== 0">-{{rates.min.discount}}%</div>
          </div>
          <div class="price__full">{{(+rates.min.sumDiscount).toLocaleString('ru')}} ₽</div>
        </div>
        <div class="rate-description">
          <div class="rate-description__title">В тариф включено</div>
          <div *ngFor="let descr of rates.min.descriptions">
            <hr color="#EBEDF2" noshade size="1">
            <div class="rate-description__item">
              {{descr.description}}
              <div class="info-icon">
                <div class="info-tooltip">{{descr['hint']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rates__rate-card-wrapper" *ngIf="rates.opty" (click)="selectTariff(rates.opty);"  >
      <div tabindex="0" class="rate-card__mobile-name"(click)="max3opty=!max3opty;toCheckMax3Opty()"
           [ngStyle]="{'display': max3opty ? 'none' : 'flex' }">{{rates.opty.title}}</div>
      <div class="rate-card" *ngIf="max3opty===true" [ngClass]="{'rate-card--highlight': rates.opty.id == activeTariff}">
        <div class="rate-card__name" id="rateOptyCardName">{{rates.opty.title}}
          <span class="rate-card__name--hit" *ngIf="rates.opty.hit"></span>
        </div>
        <div class="progress-risk" *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'">
        </div>
        <hr color="#DCDEE1" noshade size="1">
        <div class="price">
          <div *ngIf="rates.opty.discount !== 0 || rates.opty.discount !== 0" class="prices-wrapper">
            <div class="old-price" *ngIf="rates.opty.discount !== 0">{{(+rates.opty.sum).toLocaleString('ru')}}₽
            </div>
            <div class="discount" *ngIf="rates.opty.discount !== 0">-{{rates.opty.discount}}%</div>
          </div>
          <div class="price__full">{{(+rates.opty.sumDiscount).toLocaleString('ru')}} ₽</div>
        </div>
        <div class="rate-description">
          <div class="rate-description__title">В тариф включено</div>
          <div *ngFor="let descr of rates.opty.descriptions">
            <hr color="#EBEDF2" noshade size="1">
            <div class="rate-description__item">
              {{descr.description}}
              <div class="info-icon">
                <div class="info-tooltip">{{descr['hint']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rates__rate-card-wrapper" *ngIf="rates.max" (click)="selectTariff(rates.max);">
      <div tabindex="0" class="rate-card__mobile-name" (click)="max3max=!max3max;toCheckMax3Max()"
           [ngStyle]="{'display': max3max ? 'none' : 'flex' }">{{rates.max.title}}</div>
      <div class="rate-card" *ngIf="max3max===true" [ngClass]="{'rate-card--highlight': rates.max.id == activeTariff}">
        <div class="rate-card__name" id="rateMaxCardName">{{rates.max.title}}
          <span class="rate-card__name--hit" *ngIf="rates.max.hit"></span>
        </div>
        <div class="progress-risk" *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'">
        </div>
        <hr color="#DCDEE1" noshade size="1">
        <div class="price">
          <div *ngIf="rates.max.discount !== 0 || rates.max.discount !== 0" class="prices-wrapper">
            <div class="old-price" *ngIf="rates.max.discount !== 0">{{(+rates.max.sum).toLocaleString('ru')}}₽
            </div>
            <div class="discount" *ngIf="rates.max.discount !== 0">-{{rates.max.discount}}%</div>
          </div>
          <div class="price__full">{{(+rates.max.sumDiscount).toLocaleString('ru')}} ₽</div>
        </div>
        <div class="rate-description">
          <div class="rate-description__title">В тариф включено</div>
          <div *ngFor="let descr of rates.max.descriptions">
            <hr color="#EBEDF2" noshade size="1">
            <div class="rate-description__item">
              {{descr.description}}
              <div class="info-icon">
                <div class="info-tooltip">{{descr['hint']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="2" class="rates rates__main-wrapper" >
    <div class="rates__rate-card-wrapper rates__rate-card-wrapper--width" *ngIf="rates.min" (click)="selectTariff(rates.min);">
      <div tabindex="0" class="rate-card__mobile-name" (click)="max2min=!max2min;toCheckMax2Min()"
           [ngStyle]="{'display': max2min ? 'none' : 'flex' }">{{rates.min.title}}</div>
      <div class="rate-card rate-card--width" *ngIf="max2min===true" [ngClass]="{'rate-card--highlight': rates.min.id == activeTariff}">
        <div class="rate-card__name" id="rateMin2CardName">{{rates.min.title}}
          <span class="rate-card__name--hit" *ngIf="rates.min.hit"></span>
        </div>
        <div class="progress-risk" *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'">
        </div>
        <hr color="#DCDEE1" noshade size="1">
        <div class="price">
          <div *ngIf="rates.min.discount !== 0 || rates.min.discount !== 0" class="prices-wrapper">
            <div class="old-price" *ngIf="rates.min.discount !== 0">{{(+rates.min.sum).toLocaleString('ru')}}₽
            </div>
            <div class="discount" *ngIf="rates.min.discount !== 0">-{{rates.min.discount}}%</div>
          </div>
          <div class="price__full">{{(+rates.min.sumDiscount).toLocaleString('ru')}} ₽</div>
        </div>
        <div class="rate-description">
          <div class="rate-description__title">В тариф включено</div>
          <div *ngFor="let descr of rates.min.descriptions">
            <hr color="#EBEDF2" noshade size="1">
            <div class="rate-description__item">
              {{descr.description}}
              <div class="info-icon">
                <div class="info-tooltip">{{descr['hint']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rates__rate-card-wrapper rates__rate-card-wrapper--width" *ngIf="rates.opty" (click)="selectTariff(rates.opty);">
      <div tabindex="0" class="rate-card__mobile-name" (click)="max2opty=!max2opty;toCheckMax2Opty()"
           [ngStyle]="{'display': max2opty ? 'none' : 'flex' }">{{rates.opty.title}}</div>
      <div class="rate-card rate-card--width" *ngIf="max2opty===true" [ngClass]="{'rate-card--highlight': rates.opty.id == activeTariff}">
        <div class="rate-card__name" id="rateOpty2CardName">{{rates.opty.title}}
          <span class="rate-card__name--hit" *ngIf="rates.opty.hit"></span>
        </div>
        <div class="progress-risk" *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'">
        </div>
        <hr color="#DCDEE1" noshade size="1">
        <div class="price">
          <div *ngIf="rates.opty.discount !== 0 || rates.opty.discount !== 0" class="prices-wrapper">
            <div class="old-price" *ngIf="rates.opty.discount !== 0">{{(+rates.opty.sum).toLocaleString('ru')}}₽
            </div>
            <div class="discount" *ngIf="rates.opty.discount !== 0">-{{rates.opty.discount}}%</div>
          </div>
          <div class="price__full">{{(+rates.opty.sumDiscount).toLocaleString('ru')}} ₽</div>
        </div>
        <div class="rate-description">
          <div class="rate-description__title">В тариф включено</div>
          <div *ngFor="let descr of rates.opty.descriptions">
            <hr color="#EBEDF2" noshade size="1">
            <div class="rate-description__item">
              {{descr.description}}
              <div class="info-icon">
                <div class="info-tooltip">{{descr['hint']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rates__rate-card-wrapper rates__rate-card-wrapper--width" (click)="selectTariff(rates.max);" *ngIf="rates.max" >
      <div tabindex="0" class="rate-card__mobile-name" (click)="max2max=!max2max;toCheckMax2Max()"
           [ngStyle]="{'display': max2max ? 'none' : 'flex' }">{{rates.max.title}}</div>
      <div class="rate-card rate-card--width" *ngIf="max2max===true" [ngClass]="{'rate-card--highlight': rates.max.id == activeTariff}">
        <div class="rate-card__name" id="rateMax2CardName">{{rates.max.title}}
          <span class="rate-card__name--hit" *ngIf="rates.max.hit"></span>
        </div>
        <div class="progress-risk" *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'">
        </div>
        <hr color="#DCDEE1" noshade size="1">
        <div class="price">
          <div *ngIf="rates.max.discount !== 0 || rates.max.discount !== 0" class="prices-wrapper">
            <div class="old-price" *ngIf="rates.max.discount !== 0">{{(+rates.max.sum).toLocaleString('ru')}}₽
            </div>
            <div class="discount" *ngIf="rates.max.discount !== 0">-{{rates.max.discount}}%</div>
          </div>
          <div class="price__full">{{(+rates.max.sumDiscount).toLocaleString('ru')}} ₽</div>
        </div>
        <div class="rate-description">
          <div class="rate-description__title">В тариф включено</div>
          <div *ngFor="let descr of rates.max.descriptions">
            <hr color="#EBEDF2" noshade size="1">
            <div class="rate-description__item">
              {{descr.description}}
              <div class="info-icon">
                <div class="info-tooltip">{{descr['hint']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="1" class="rates rates__main-wrapper" >
    <div *ngIf="rates.min" class="attention attention--white attention--in-card" >
      <p>Тариф «{{rates.min.title}}»</p>
      <div tabindex="0" class="attention__show" >
        <ul class="attention__list">
          <li *ngFor="let descr of rates.min.descriptions">
            {{descr.description}}
          </li>
        </ul>
        <div class="attention__price-block">
          <p class="attention__sum-sum">Стоимость</p>
          <p *ngIf="rates.min.discount !== 0" class="attention__price-block--old">{{(+rates.min.sum).toLocaleString('ru')}} ₽</p>
          <p *ngIf="rates.min.discount !== 0" class="attention__price-block--sale">-{{rates.min.discount}}%</p>
          <p>{{(+rates.min.sumDiscount).toLocaleString('ru')}} ₽</p>
        </div>
      </div>
    </div>
    <div *ngIf="rates.opty" class="attention attention--white attention--in-card ">
      <p *ngIf="requestType == 'software'">Тариф «Регистрация программы для ЭВМ или базы данных»</p>
      <p *ngIf="requestType === 'rumcc'">Тариф «Регистрация в Минкомсвязи»</p>
      <p *ngIf="['trademark', 'patent', 'trademarkSearch', 'contract'].indexOf(requestType) > -1">Тариф «{{rates.opty.title}}»</p>
      <div tabindex="0" class="attention__show" >
        <ul class="attention__list">
          <li *ngFor="let descr of rates.opty.descriptions">
            {{descr.description}}
          </li>
        </ul>
        <div class="attention__price-block">
          <p class="attention__sum-sum">Стоимость</p>
          <p *ngIf="rates.opty.discount !== 0" class="attention__price-block--old">{{(+rates.opty.sum).toLocaleString('ru')}} ₽</p>
          <p *ngIf="rates.opty.discount !== 0" class="attention__price-block--sale">-{{rates.opty.discount}}%</p>
          <p>{{(+rates.opty.sumDiscount).toLocaleString('ru')}} ₽</p>
        </div>
      </div>
    </div>
    <div *ngIf="rates.max" class="attention attention--white attention--in-card">
      <p>Тариф «{{rates.max.title}}»</p>
      <div tabindex="0" class="attention__show" >
        <ul class="attention__list">
          <li *ngFor="let descr of rates.max.descriptions">
            {{descr.description}}
          </li>
        </ul>
        <div class="attention__price-block">
          <p class="attention__sum-sum">Стоимость</p>
          <p *ngIf="rates.max.discount !== 0" class="attention__price-block--old">{{(+rates.max.sum).toLocaleString('ru')}} ₽</p>
          <p *ngIf="rates.max.discount !== 0" class="attention__price-block--sale">-{{rates.max.discount}}%</p>
          <p>{{(+rates.max.sumDiscount).toLocaleString('ru')}} ₽</p>
        </div>
      </div>
    </div>
  </div>
</div>
