<div class="form__dropdown form-card" *ngIf="viewOrganizationsList">
  <ng-container>
    <table class="form-card__table">
      <thead class="dropdown-hover">
      <tr>
        <th>ОГРН</th>
        <th>ИНН</th>
        <th>Наименование компании или ИП</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let organization of organizations; let i = index" [id]="'ownerDropdown' + i" (click)="setSelectedOwner(organization)">
        <td>{{organization.PSRN}}</td>
        <td>{{organization.TIN}}</td>
        <td class="black">{{organization.shortName || organization.name}}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>
</div>

<div
  *ngIf="viewOrganizationsList"
  class="overlay overlay--searchList"
  style="background: none; z-index: 4;"
  (click)="hideOrganizationsList()"
></div>