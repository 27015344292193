<div class="events-steps__hidden">
  <div class="events-steps__hidden-text-wrapper hidden">
    <h3>Мы подготовили претензию</h3>
    <div class="hidden__blocks-wrapper">
      <div class="hidden__block ">
        <h4 class="hidden__subtitle">Что сделано</h4>
        <ul class="hidden__list">
          <li>
            Проанализирован пакет документов по долгу
          </li>
          <li>
            Определены основания возникновения долга
          </li>
          <li>
            Установлены статьи ГК РФ для предъявления требований
          </li>
          <li>
            Определена дата возникновения долга
          </li>
          <li>
            Рассчитана сумма основного долга
          </li>
          <li>
            Рассчитана сумма пени
          </li>
        </ul>
      </div>
      <div class="hidden__block">
        <h4 class="hidden__subtitle">Претензия</h4>
        <p>Скачать претензию для ознакомления</p>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>претензия</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
<!--                            <img src="/assets/images/green-key.png" class ="docs__icon&#45;&#45;mini">-->
                            <img src="/assets/icons/blue-empty.svg">
                          </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
