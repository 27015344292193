<section [class.disabled-section]="isDisabled" class="owner-form" *ngIf="!loading; else load">
  <h1>Информация о правообладателе</h1>
  <div>
    <form class="form-item" [formGroup]="formOwner">
      <div class="form-item__block">
        <p class="form-item__subtitle">Выберите тип <span *ngIf="requestType === 'patent'">патента</span><span *ngIf="requestType === 'soft'">объекта</span></p>
        <div class="form-item__wrapper" *ngIf="requestType === 'patent'; else soft">
          <div (click)="sendPatentEvent('Изобретение')" class="form-item__input" [ngClass]="typeNotSelected && formOwner.get('type').invalid ? 'not-valid-type' : ''">
            <input type="radio" value="rupat"  formControlName="type">
            <p>Изобретение</p>
            <span class="form-item__placeholder-wrapper">
              <p class="form-item__placeholder">Устройство или способ, срок действия 20 лет</p>
              <span class="prompt prompt__question">?
                 <div class="prompt__hidden" *ngIf="!isMobile">
                  <div><b>Изобретение</b> - техническое решение в любой области, относящееся к продукту или способу
                    (технологии), в том числе к применению продукта или способа по определенному назначению.<br>
                    К изобретениям <b>относятся:</b>
                    <ul class="prompt__list">
                      <li>методы, способы с использованием материальных объектов;</li>
                      <li>установки, системы, механизмы;</li>
                      <li>устройства;</li>
                      <li>алгоритмы взаимодействия программного кода и технических средств;</li>
                      <li>новое неочевидное использование существующего решения.</li>
                    </ul>
                  </div>
                </div>
                <div class="prompt__hidden" *ngIf="isMobile">
                  <p>Например: методы, рецепты, составы, установки, системы, механизмы, алгоритмы
                    взаимодействия программного кода и технических средств и т.д.
                  </p>
                </div>
              </span>
            </span>
            <h3 class="check">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="12"/>
                <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
              </svg>
            </h3>
          </div>
          <div (click)="sendPatentEvent('Полезная модель')" class="form-item__input" [ngClass]="typeNotSelected && formOwner.get('type').invalid ? 'not-valid-type' : ''">
            <input type="radio" value="ruum" formControlName="type">
            <p>Полезная модель</p>
            <span class="form-item__placeholder-wrapper">
              <p class="form-item__placeholder">Устройство, срок действия 10 лет</p>
              <span class="prompt prompt__question">?
                <span class="prompt__hidden" *ngIf="!isMobile">
                  <div><b>Полезная модель</b> - техническое решение, относящееся к устройству.<br>
                    К полезным моделям <b>относятся:</b>
                    <ul class="prompt__list">
                      <li>конструкции, узлы;</li>
                      <li>изделия (устройства в едином корпусе). </li>
                    </ul>
                  </div>
                 </span>
                <span class="prompt__hidden" *ngIf="isMobile">
                  <p>Например: конструкции, узлы, изделия (устройства в едином корпусе)</p>
                 </span>
               </span>
            </span>
            <h3 class="check">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="12"/>
                <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
              </svg>
            </h3>
          </div>
          <div (click)="sendPatentEvent('Промышленный образец')" class="form-item__input" [ngClass]="typeNotSelected && formOwner.get('type').invalid ? 'not-valid-type' : ''">
            <input type="radio" value="rude" formControlName="type">
            <p>Промышленный образец</p>
            <span class="form-item__placeholder-wrapper">
              <p class="form-item__placeholder">Действует 5 лет (можно продлять еще 4 раза по 5 лет).</p>
              <span class="prompt prompt__question">?
                <span class="prompt__hidden" *ngIf="!isMobile">
                  <div><b>Промышленный образец</b> - это дизайн изделия.
                    Срок действия патента - 5 лет с даты подачи заявки с возможностью продления каждые 5 лет (суммарно до 25 лет).<br>
                    К промышленным образцам <b>относятся:</b>
                    <ul class="prompt__list">
                      <li>дизайны материальных объектов, приложений, сайтов; </li>
                      <li>орнамент, сочетание цветов, линий;</li>
                      <li>контуры изделия;</li>
                      <li>объекты архитектуры;</li>
                      <li>авторские шрифты.</li>
                    </ul>
                  </div>
                </span>
                <span class="prompt__hidden" *ngIf="isMobile">
                  <p>Например: дизайны материальных объектов, приложений, сайтов, орнамент, объекты архитектуры, авторские шрифты</p>
                 </span>
              </span>
            </span>
            <h3 class="check">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="12"/>
                <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
              </svg>
            </h3>
          </div>
          <div (click)="sendPatentEvent('Не знаю')" class="form-item__input" [ngClass]="typeNotSelected && formOwner.get('type').invalid ? 'not-valid-type' : ''">
            <input type="radio"  value="unknown" formControlName="type">
            <p>Не знаю</p>
            <span class="form-item__placeholder-wrapper form-item__placeholder-wrapper--no-padding">
             <p class="form-item__placeholder">Вид объекта будет определен патентным поверенным</p>
             <span *ngIf="isMobile" class="prompt prompt__question">?
                <span class="prompt__hidden" >
                  <p>Если вы не уверены, к какому типу отнести объект интеллектуальной собственности, наш патентный поверенный поможет его определить</p>
                 </span>
              </span>
            </span>
            <h3 class="check">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="12"/>
                <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
              </svg>
            </h3>
          </div>
        </div>
        <ng-template #soft>
          <div class="form-item__wrapper">
            <div (click)="sendPatentEvent('Программа для ЭВМ')" class="form-item__input" [ngClass]="typeNotSelected && formOwner.get('type').invalid ? 'not-valid-type' : ''">
              <input type="radio" value="rusw" formControlName="type">
              <p>Программа для ЭВМ</p>
              <span class="form-item__placeholder-wrapper">
                <p class="form-item__placeholder"></p>
                <!--                <span class="prompt prompt__question">?-->
                <!--                  <span class="prompt__hidden">-->
                <!--                    <p>???</p>-->
                <!--                  </span>-->
                <!--                </span>-->
              </span>
              <h3 class="check">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </h3>
            </div>
            <div (click)="sendPatentEvent('База данных')" class="form-item__input" [ngClass]="typeNotSelected && formOwner.get('type').invalid ? 'not-valid-type' : ''">
              <input type="radio" value="rudb" formControlName="type">
              <p>База данных</p>
              <span class="form-item__placeholder-wrapper">
                <p class="form-item__placeholder"></p>
                <!--                <span class="prompt prompt__question">?-->
                <!--                  <span class="prompt__hidden">-->
                <!--                    <p>???</p>-->
                <!--                  </span>-->
                <!--                </span>-->
              </span>
              <h3 class="check">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </h3>
            </div>
            <div (click)="sendPatentEvent('Не знаю')" class="form-item__input" [ngClass]="typeNotSelected && formOwner.get('type').invalid ? 'not-valid-type' : ''">
              <input type="radio" value="unknown" formControlName="type">
              <p>Не знаю</p>
              <span class="form-item__placeholder-wrapper form-item__placeholder-wrapper--no-padding">
             <p class="form-item__placeholder">Вид объекта будет определен патентным поверенным</p>
            </span>
              <h3 class="check">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </h3>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="form-item__block">
        <div class="form-item__subtitle">Автор<span class="prompt prompt__question">?
           <span class="prompt__hidden">
              <p *ngIf="requestType === 'patent'">Автор не имеет исключительных прав на запатентованный объект и не может распоряжаться патентом.
                Вы можете указать всех, кто внес творческий вклад в патентуемый объект.</p>
             <p *ngIf="requestType !== 'patent'">Авторы - физические лица, создавшие программу ЭВМ.
               Если иное не оговорено трудовым договором, имущественные права на программу принадлежат работодателю.</p>
            </span>
          </span>

        </div>
        <div class="form-item__wrapper" formArrayName="author">
          <div *ngFor="let auth of author.controls; index as i">
            <div class="form-item__input form-item__text form-item__text--padding" [ngClass]="showAuthorError(i) ? 'invalid' : ''">
              <textarea
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"
                type="text" [formControlName]="i" placeholder="Например: Дроздов Юрий Петрович"></textarea>
              <span class="form-item__text--delete" *ngIf="i !== 0" (click)="removeAuthor(i)"></span>
            </div>
            <p class="form-item__text--invalid" *ngIf="showAuthorError(i)">Поле не может быть пустым</p>
          </div>
        </div>
        <p class="form-item__add" (click)="addAuthor()">Добавить автора</p>
      </div>
      <div class="form-item__block">
        <div class="form-item__subtitle">{{requestType === 'patent' ? 'Патентообладатель' : 'Правообладатель'}}
          <span class="prompt prompt__question" *ngIf="requestType === 'patent'">?
           <span class="prompt__hidden">
              <p>Патентообладатель получает все исключительные права на охраняемый объект.
                Несколько патентообладателей могут распоряжаться правом только совместно.</p>
           </span>
          </span>
        </div>
        <div class="form-item__wrapper" formArrayName="patentee">
          <div *ngFor="let patent of patentee.controls; index as i">
            <div class="form-item__input form-item__text" [ngClass]="showPatenteeError(i) ? 'invalid' : ''">
              <div
                *ngIf="fullPatentee[i]"
                [ngStyle]="getDynamicMarginForLegalName(i)"
                class="legal-name-patentee">
                / {{ fullPatentee[i].name || fullPatentee[i].fullName }}
              </div>
              <textarea
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                type="text" [formControlName]="i" placeholder="Название, ОГРН/ОГРНИП, ИНН или ФИО" (ngModelChange)="patenteeChanged($event, i)"></textarea>
              <span class="form-item__text--delete" *ngIf="i !== 0" (click)="removePatentee(i)"></span>
            </div>
            <p class="form-item__text--invalid " *ngIf="showPatenteeError(i)">Поле не может быть пустым</p>
            <div class="form__dropdown form-card" *ngIf="tempPatentee.patentee && tempPatentee.index === i" style="position: relative" [class.non-border]="patenteeCheckLoading[i]">
              <ng-container *ngIf="!patenteeCheckLoading[i]; else loading">
              <span class="modal__close" style="right: 6px" (click)="cancelRatentee()">
                <svg width="26" height="20" viewBox="0 0 26 26">
                  <rect x="1.41431" width="34" height="2" transform="rotate(45 1.41431 0)"/>
                  <rect y="24.0416" width="34" height="2" transform="rotate(-45 0 24.0416)"/>
                </svg>
              </span>
                <table class="form-card__table" (click)="checkCurrentOrganization(tempPatentee.patentee.PSRN, i)">
                  <thead class="dropdown-hover">
                  <tr>
                    <th>ОГРН</th>
                    <th>ИНН</th>
                    <th>Наименование компании или ИП</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{tempPatentee.patentee.PSRN}}</td>
                    <td>{{tempPatentee.patentee.TIN}}</td>
                    <td class="black">{{tempPatentee.patentee.name || tempPatentee.patentee.fullName}}</td>
                  </tr>
                  </tbody>
                </table>
              </ng-container>
              <ng-template #loading>
                <div class="loader payer-check-loader"></div>
              </ng-template>
            </div>
            <div *ngIf="patenteeError[i]" class="error-text">Выбранная организация уже зарегистрирована, укажите другого патентообладателя</div>
          </div>
        </div>
        <p class="form-item__add" (click)="addPatentee()">Добавить {{requestType === 'patent' ? 'патентообладателя' : 'правообладателя'}}</p>
      </div>

      <div class="form-item__btn-block">
        <button type="button" class="btn-blue-attr" routerLink="/">Отмена</button>
        <button type="button" class="btn-blue" (click)="nextStep()">Далее</button>
      </div>
    </form>
  </div>
</section>
<ng-template #load>
  <div class="loader"></div>
</ng-template>
