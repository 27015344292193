import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index-search',
  templateUrl: './index-search.component.html',
  styleUrls: ['./index-search.component.scss']
})
export class IndexSearchComponent implements OnInit {
  designation;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  @HostListener('document:keyup', ['$event']) // 13=ENTER
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.sendSearchParam();
    }
  }

  sendSearchParam() {
    if (this.designation) {
      this.router.navigate(['/search'], {queryParams: {q: this.designation}});
    }
  }
}
