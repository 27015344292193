import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Filters } from '@ui/filters/models/filters.interface';
import { CorpHistoryStateModel } from '@web/features/corp/corp-history/states/history.state';
import { FiltersValueInterface } from '@web/features/corp/corp-history/models/filters-value.interface';
import {FilterDataUsers} from '@web/features/corp/corp-history/models/filters-data.interface';
import {Store} from '@ngxs/store';
import {HistoryActions} from '@web/features/corp/corp-history/states/history.actions';

@Component({
  selector: 'app-corp-search-history-filters',
  templateUrl: './corp-search-history-filters.component.html',
  styleUrls: ['./corp-search-history-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorpSearchHistoryFiltersComponent implements OnInit {

  @Input() state: CorpHistoryStateModel;

  @Input() layout: 'sm' | 'md' | 'lg' = 'md';

  @Input() filterValue: Record<string, any>;

  @Input() viewMode: 'searches' | 'reports' = 'searches';

  @Input() users: FilterDataUsers[];

  @Output() changeFilter = new EventEmitter();

  private _clearFilter = new ReplaySubject<void>();
  clearFilter$ = this._clearFilter.asObservable();

  readonly listLayout = 'minmax(auto, 2fr) 1fr 1fr 1.5fr 28px';

  def: Filters<any[]> | null = null;

  lg: Array<string>;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.setDef();
  }

  setDef() {
    this.def = [
      {
        type: 'input',
        name: 'searchQuery',
        placeholder: 'Поисковый запрос',
      },
      {
        type: 'select',
        name: 'searchType',
        placeholder: 'Вид поиска:',
        options: this.filterTypes,
      },
      {
        type: 'select',
        name: 'users',
        placeholder: 'Пользователь:',
        options: this.filtersUsers,
      },
      {
        type: 'date',
        name: 'date',
        placeholder: 'Дата поиска:',
      },
    ];
    switch (this.def.length) {
      case 4:
        this.lg = ['1.5fr', '1fr', '1fr', '1fr'];
        break;
      case 5:
        this.lg = ['1.5fr', '1fr', '1fr', '1fr', '1fr'];
        break;
    }
  }

  get filtersUsers(): {id: number, label: string}[] {
    return this.users.map(item => {
      return { id: +item.code, label: item.name };
    });
  }

  get filterTypes(): {id: number, label: string}[] {
    return [
      {
        id: 0,
        label: 'По обозначению'
      },
      {
        id: 1,
        label: 'По изображению'
      }
    ];
  }

  applyFilter(event: Record<string, any> | FiltersValueInterface) {
    this.store.dispatch(new HistoryActions.GetFormFiltersValue(event));
    const currentFiltersValue = {...event};
    if (currentFiltersValue.searchType?.length) {
      currentFiltersValue.searchType = currentFiltersValue.searchType.map(typeName => {
        switch (typeName) {
          case 'По обозначению':
            typeName = 'name';
            break;
          case 'По изображению':
            typeName = 'image';
            break;
        }
        return typeName;
      });
    }
    if (currentFiltersValue.users?.length) {
      currentFiltersValue.users = currentFiltersValue.users.map((userName => {
        const index = this.users.findIndex((user) => user.name === userName);
        return this.users[index].code;
      }));
    }
    if (currentFiltersValue.date?.length) {
      currentFiltersValue.startDate = currentFiltersValue.date[0].toISOString();
      currentFiltersValue.finishDate = currentFiltersValue.date[1].toISOString();
      delete currentFiltersValue.date;
    }
    this.changeFilter.emit(currentFiltersValue);
  }

  get isFiltersApplied(): boolean {
    return this.filterValue?.users?.length || this.filterValue?.searchType?.length
      || this.filterValue?.startDate?.length || this.filterValue?.finishDate?.length || this.filterValue?.searchQuery?.length;
  }

  clearFilter() {
    this._clearFilter.next();
  }

}
