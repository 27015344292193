<mat-card class="step-box">
  <mat-card-content [ngSwitch]="step">
    <ng-container *ngIf="!loading; else loader">
      <app-trademark-owner *ngSwitchCase="0"></app-trademark-owner>
      <app-trademark-mktu *ngSwitchCase="1"></app-trademark-mktu>
      <app-tarrif-selection *ngSwitchCase="2"></app-tarrif-selection>
      <app-trademark-payment *ngSwitchCase="3"></app-trademark-payment>
      <app-trademark-result *ngSwitchCase="4"></app-trademark-result>
    </ng-container>
  </mat-card-content>
</mat-card>

<ng-template #loader>
  <div class="loader"></div>
</ng-template>
