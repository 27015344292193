import { Component, HostListener, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() title = [];
  @Input() data = [];
  dataView = [];
  countPages;
  pageNumber;

  isMobile;
  showItem;
  counter = 0;

  selectedRow;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onMobileShow();
  }

  constructor() {
  }

  ngOnInit(): void {
    this.changePage(1);
    this.countPages = Array(Math.ceil(this.data.length / 10)).fill(0).map((x, i) => i + 1);
    this.onMobileShow();
  }

  changePage(number) {
    this.pageNumber = number;
    this.dataView = this.data.slice((number - 1) * 10, number * 10);
  }

  getCellText(data, text) {
    if (data === true) {
      return 'Действует';
    } else if (data === false) {
      return 'Не действует';
    }
    if (text.toLowerCase().includes('дата')) {
      return false;
    }
    return data;
  }

  getChanges(data): string {
    let changesString = '';
    if (data && data.length) {
      data.forEach(item => {
        changesString += item.contractTerms;
      });
    }
    return changesString;
  }

  onMobileShow(): void {
    if (document.documentElement.clientWidth > 767) {
      this.showItem = 10;
      this.isMobile = false;
    } else {
      this.showItem = 3;
      this.isMobile = true;
    }
    this.dataView = this.data.slice(0, this.showItem);
  }

  showMore(): void {
    this.pageNumber = 1;
    for (let i = this.counter + 1; i < this.data.length; i++) {
      this.dataView.push(this.data[i]);
      if (i % 10 === 0) {
        break;
      }
    }
    this.counter += 10;
    this.dataView = this.data.slice(0, this.counter);
  }

  // Открыть модальное окно
  openModal(event: any, i: any): void {
    if (window.innerWidth >= 768) {
      this.selectedRow = i;
    }
  }

  // Открыть модальное окно на мобильном
  openModalMobile(event: any, i: any) {
    if (window.innerWidth < 768) {
      this.selectedRow = i;
    }
  }

  // Закрыть модальное окно
  closeModal(): void {
    this.selectedRow = undefined;
  }
}
