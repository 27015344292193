<div class="container">
  <h2 class="visual-hidden">История платежей</h2>

  <ng-container *ngIf="paymentHistory.length != 0; else notPayment">
    <div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
      <div class="filter__search-input-wrap">
        <span (click)="showMobileControl = true;makeBodyHidden()">Поиск по истории платежей</span>
        <button class="filter__search-close" *ngIf="showMobileControl === true" (click)="showMobileControl = false;makeBodyHidden()"></button>
      </div>
    </div>
    <div class="filter" [ngClass]="showMobileControl === true? 'mobile': ''">
      <div class="dropdown__wrap" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="dropdown">
          <button class="dropdown__button" (click)="showOrganization = !showOrganization">
            <span *ngIf="!filtersCount.orgFilterData" class="dropdown__button-text">Организация</span>
            <span *ngIf="filtersCount.orgFilterData" class="dropdown__button-text dropdown__button-text--check">
              Выбрано: {{ filtersCount.orgFilterData }}
            </span>
            <img
              class="dropdown__button-img"
              [ngClass]="showOrganization ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showOrganization" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Организация</span>
              <ng-container *ngFor="let organization of filters.orgFilterData">
                <input type="checkbox" [(ngModel)]="organization.checked"
                       class="visual-hidden dropdown__input"
                       id="{{ organization.id }}"
                       (change)="applyFilters()"/>
                <label class="dropdown__label" for="{{ organization.id }}">
                  <span>{{ organization?.name }}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="dropdown">
          <button class="dropdown__button" (click)="showPaymentType = !showPaymentType">
            <span *ngIf="radioSelected == null" class="dropdown__button-text">Тип платежа</span>
            <span *ngIf="radioSelected" class="dropdown__button-text dropdown__button-text--check">
              {{ radioSelected }}
            </span>
            <img
              class="dropdown__button-img"
              [ngClass]="showPaymentType ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showPaymentType" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Тип платежа</span>
              <ng-container *ngFor="let payment of payments">
                <input type="radio"
                       class="visual-hidden dropdown__input dropdown__input--radio"
                       [value]='payment?.name'
                       [(ngModel)]="radioSelected"
                       id="{{ payment?.id }}"
                       (change)="applyFilters()">
                <label class="dropdown__label" for="{{ payment?.id }}">
                  <span>{{ payment?.name }}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="dropdown">
          <button class="dropdown__button" (click)="showServiceType = !showServiceType">
            <span *ngIf="!filtersCount.productNameFilterData" class="dropdown__button-text">Тип услуги</span>
            <span *ngIf="filtersCount.productNameFilterData" class="dropdown__button-text dropdown__button-text--check">
              Выбрано: {{ filtersCount.productNameFilterData }}
            </span>
            <img
              class="dropdown__button-img"
              [ngClass]="showServiceType ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showServiceType" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Тип услуги</span>
              <ng-container *ngFor="let service of filters.productNameFilterData">
                <input type="checkbox" [(ngModel)]="service.checked"
                       class="visual-hidden dropdown__input"
                       id="{{ service?.id }}"
                       (change)="applyFilters()"/>
                <label class="dropdown__label" for="{{ service?.id }}">
                  <span>{{ service?.name }}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="dropdown">
          <app-filter-daterange
            [value]="date"
            [placeholder]="'Дата платежа'"
            (apply)="dateFilterChange($event)"
          >
          </app-filter-daterange>
        </div>

      </div>

      <div class="filter__buttons">
        <button class="filter__button filter__button--find" (click)="showMobileControl = false;makeBodyHidden()">Найти</button>
        <button class="filter__button filter__button--clear" (click)="clearFilters()">Очистить</button>
      </div>
    </div>
    <div *ngIf="checkAppliedFilters() && paymentHistory.length" class="filter-text-result">
      <b>Применен фильтр:</b>
      Показано
      <b>{{paymentHistory.length}}</b>
      объектов из
      <b>{{paymentHistoryCopy.length}}</b>
      <a class="show-all-link" (click)="clearFilters()">Показать все</a>
    </div>
    <ng-container *ngIf="!paymentHistory.length && paymentHistoryCopy.length; else tablePayment">
      <div style="text-align: center; margin-top: 50px">
        Не найдены объекты, соответствующие запросу.<br>
        Попробуйте изменить поиск<br>
        <a class="show-all-link" (click)="clearFilters()">Показать все</a>
      </div>
    </ng-container>
    <ng-template #tablePayment>
      <div class="payment-table">
      <table class="payment-table__table user-profile-table">
        <tr>
          <th>Наименование платежа</th>
          <th>Дата платежа</th>
          <th>Организация</th>
          <th>Сумма платежа</th>
        </tr>
        <tr *ngFor="let payment of paymentHistory | slice:0:showPayment">
          <td class="payment-table__name">
            <span class="payment-table__text">{{ payment?.name }}</span>
          </td>
          <td class="payment-table__date-wrap">
            {{ payment?.date | date:'dd.MM.yyyy' }}
          </td>
          <td class="payment-table__organization-wrap">
            <p class="payment-table__organization">
              <span>{{ payment?.client }}</span>
              <a (click)="toSelectedCasePage(payment?.caseId)" class="payment-table__organization-case">Дело #{{ payment?.number }}</a>
            </p>
  <!--          <a class="payment-table__organization-doc">Подтверждающие документы</a>-->
          </td>
          <td class="payment-table__sum-wrap">
            <div class="payment-table__sum">
              <div class="payment-table__total-wrap">
                <span class="payment-table__total"
                      (click)="openModalPay(payment)"
                      [class.plus]="payment?.deposit"
                >
                  <span *ngIf="payment?.sum  !== 0; else zero">
                    {{ payment?.deposit ? '+' : '-' }}
                    {{ payment?.sum?.toLocaleString('ru') }} ₽
                  </span>
                  <ng-template #zero>
                    0 ₽
                  </ng-template>
                </span>
                <span *ngIf="payment?.bonus != null" class="payment-table__bonus">
                  Из них бонусных: <span class="payment-table__bonus-sum">{{ payment?.bonus }} ₽</span>
                </span>
              </div>
              <div class="payment-table__payment-type-wrap">
  <!--              <p class="payment-table__payment-type" (click)="openModalPay(payment)">-->
  <!--                {{ payment?.method }}-->
  <!--              </p>-->
              </div>
            </div>
          </td>
        </tr>
      </table>
      <button class="user-profile-table__show-all" *ngIf="showPayment < paymentHistory.length" (click)="paymentShow()">
        Показать еще
      </button>
    </div>
    </ng-template>
  </ng-container>

  <ng-template #notPayment>
    <section class="no-payment">
      <img src="/assets/icons/icon-no-org.svg" alt="" class="no-payment__img" width="120" height="32">
      <p class="no-payment__title">
        У вас еще нет истории платежей
      </p>
<!--      <p class="no-payment__desc">-->
<!--        У вас еще нет добавленной организации в сервисе. Вы можете добавить организацию самостоятельно, или она будет добавлена автоматически-->
<!--        после первой оплаты в сервисе. После добавления организации изменения возможны только через службу поддержки.-->
<!--      </p>-->
<!--      <button class="no-payment__btn btn">Добавить организацию</button>-->
    </section>
  </ng-template>
</div>

<div *ngIf="showModalPay">
  <app-modal-payment
    (modalEvent)="closeModal()"
    [pay]="selectedPay"
  >
  </app-modal-payment>
</div>
