import {Component, OnDestroy, OnInit} from '@angular/core';
import { RequestContractService } from './shared/request-contract.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ContractService} from './contract.service';
import {HttpService} from '../../shared/services/http.service';
import {environment} from '../../../environments/environment';
import {finalize} from 'rxjs/operators';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { REQUEST_TYPE } from '../../shared/enums/request-type';

@Component({
  selector: 'app-request-contract',
  templateUrl: './request-contract.component.html',
  styleUrls: ['./request-contract.component.scss'],
  providers: [RequestContractService]
})
export class RequestContractComponent implements OnInit, OnDestroy {
  /** Тип контракта */
  contractType;
  /** Наличие риска */
  hasRisk = false;
  headerChoiceOfContactComponent;
  immutableContracts;
  immutableObjects = {
    trademarks: [],
    patents: [],
    softs: [],
  };
  requestType = REQUEST_TYPE.CONTRACT;
  logo2path = '';
  promoCode;

  public userEmail: string = JSON.parse(localStorage.getItem('currentUserData')).userInfo
    ? JSON.parse(localStorage.getItem('currentUserData')).userInfo.email
    : JSON.parse(localStorage.getItem('currentUserData')).email;

  constructor(
    readonly requestContractService: RequestContractService,
    private router: Router,
    readonly activatedRoute: ActivatedRoute,
    private contractService: ContractService,
    private httpService: HttpService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
    this.logo2path = environment.LOGO2_PATH;
  }

  ngOnInit(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.APP_REGISTER });
    const id = this.activatedRoute.snapshot.queryParams.id;
    if (id) {
      this.getContractsNew(id);
    } else {
      this.getContractsNew();
      this.requestContractService.stage = 1;
    }
  }

  getStepClass(step): string {
    if (this.step === step && !this.requestContractService.changeContract) {
      return 'steps__element--active';
    } else if (this.step > step || step === 1 && this.requestContractService.changeContract) {
      return 'steps__element--finished';
    } else {
      return '';
    }
  }

  getChooseContractClass() {
    if (this.requestContractService.changeContract && this.step < 2) {
      return 'steps__element--active';
    } else if (this.step >= 2) {
      return 'steps__element--finished';
    } else if (!this.requestContractService.changeContract && this.step === 1) {
      return '';
    }
  }

  applyPromoCode(code) {
    this.requestContractService.getRates(code);
  }


  get step() {
    return this.requestContractService.stage;
  }

  get isLoading() {
    return this.requestContractService.isLoading;
  }

  get contractAction() {
    return this.requestContractService.contractAction;
  }

  getContractType(event) {
    this.contractType = event;
    if (event === 'change' || event === 'termination') {
      switch (event) {
        case 'change':
          this.requestContractService.contractAction = 'change';
          break;
        case 'termination':
          this.requestContractService.contractAction = 'termination';
          break;
      }
      this.requestContractService.contractType = null;
    } else {
      this.requestContractService.contractType = event;
      this.requestContractService.contractAction = 'registration';
    }
  }

  logValue() {
    this.requestContractService.validation = true;
  }

  checkValidForm(): boolean {
    let result = false;
    if (this.requestContractService.recipients.length > 0 && this.requestContractService.owners.length > 0) {
      const sidesArr = this.requestContractService.recipients?.concat(this.requestContractService?.owners).filter(el => el !== null);
      if (sidesArr.length > 1 ) {
        result =  sidesArr.every(el => {
          if ((!el?.notResident)
            && el?.inn
            && el?.account
            && el?.bik
            && el?.director
            && el?.address) {
            return true;
          } else if (
            el?.notResident
            && el?.account
            && el?.bik
            && el?.director
            && el?.address) {
            return true;
          }
        });
      }
    }
    return result;
  }

  nextstepRisk() {
  }

  /**
   * Вернуться на шаг назад
   */
  backStep() {
    switch (this.requestContractService.stage) {
      case 1:
        if (this.requestContractService.changeContract) {
          this.requestContractService.changeContract = false;
        } else {
          this.router.navigate(['index']);
        }
        break;
      case 2:
        if (this.requestContractService.contractAction === 'termination' || this.requestContractService.contractAction === 'change') {
          this.requestContractService.stage -= 1;
          this.requestContractService.changeContract = true;
        } else {
          this.requestContractService.stage -= 1;
        }
        break;
      case 3:
      case 4:
      case 5:
        if (this.contractAction === 'termination') {
          this.requestContractService.stage = 1;
          this.requestContractService.changeContract = true;
        } else {
          this.requestContractService.stage -= 1;
        }
        break;
      case 6:
        this.requestContractService.stage -= 1;
        break;
    }
    this.contractType = this.requestContractService.contractType !== null ? this.requestContractService.contractType : this.requestContractService.contractAction;
  }

  /**
   * Получение массива объектов договора
   */
  get objects() {
    return this.requestContractService.objects.length;
  }

  /**
   * Получение выбранного договора
   */
  validationChoiceContract() {
    return !(this.requestContractService.contractId?.length || this.requestContractService.contractDate && this.requestContractService.contractDate);
  }



  /**
   * Переход к следующим шагам
   */
  nextStep(promoCode?) {
    if (promoCode) {
      this.promoCode = promoCode;
    }
    switch (this.requestContractService.stage) {
      case 0:
      case 1:
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_CONTRACT_TYPE_APPLY_SENT });
        if (this.requestContractService.contractType === 'unknown' || this.requestContractService.contractType === 'pledge'
        || this.requestContractService.contractType === 'repledge') {
          if (this.requestContractService.responseID) {
            this.requestContractService.deleteTask(this.requestContractService.responseID);
          }
          this.requestContractService.toLeadForm();
        } else if (this.requestContractService.contractAction === 'change' || this.requestContractService.contractAction === 'termination') {
          this.resetCheckedObjects();
          this.requestContractService.sendTypeContract();
          this.requestContractService.changeContract = true;
        } else {
          this.requestContractService.sendTypeContract();
        }
        this.requestContractService.subject = undefined;
        break;
      case 2:
        if (this.checkValidForm()) {
          this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_CONTRACT_SIDE_APPLY_SENT });
          this.requestContractService.sendPartiesContract();
        } else {
          this.requestContractService.validation = true;
        }
        break;
      case 3:
        this.requestContractService.validation = false;
        this.requestContractService.sendObjectsContract();
        if (this.requestContractService.riskData.risk) {
          this.hasRisk = true;
        }
        // this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_CONTRACT_TOPIC_APPLY_SENT });
        break;
      case 4:
        if (this.checkValidConditions()) {
          this.requestContractService.sendSubjectContract();
        } else {
          this.requestContractService.validation = true;
        }
        break;
        case 5:
        // this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_TM_RATE_SENT, itemType: this.requestType });
        this.requestContractService.sendTariff(this.promoCode);
        break;
    }
  }
  // TODO: переписать этот говнокод
  checkValidConditions() {
    const contractSubject = this.requestContractService.subject;
    let result = false;
    switch (this.requestContractService.contractType) {
      case 'license':
      case 'sublicense':
      case 'concession':
      case 'subconcession':
        if (contractSubject.exclusivity
          && (contractSubject.territorialLimits === 'russia'
            || contractSubject.territorialLimits === 'subject' && contractSubject.territorialSubject.length > 0
            || contractSubject.territorialLimits === 'other' && contractSubject.territorialLimitsString || contractSubject.territorialLimits === 'noLimits')
          && (contractSubject.periodType === 'months' && contractSubject.periodMonths
            || contractSubject.periodType === 'years' && contractSubject.periodYears
            || contractSubject.periodType === 'date' && contractSubject.periodDate
            || contractSubject.periodType === 'noLimits')
          && contractSubject.amountOfCompensation && contractSubject.currencyType && contractSubject.VAT
          && (contractSubject.mannerOfPayment === 'month' || contractSubject.mannerOfPayment === 'quarter'
            || contractSubject.mannerOfPayment === 'year' || (contractSubject.mannerOfPayment === 'other' && contractSubject.mannerOfPaymentString))
          && (contractSubject.paymentStart === 'registration' || contractSubject.paymentStart === 'signing'
            || (contractSubject.paymentStart === 'other' && contractSubject.paymentStartString))
          && ((contractSubject.subconcessionRight === 'yes' || contractSubject.subconcessionRight === 'no'
            || (contractSubject.subconcessionRight === 'limits' && contractSubject.subconcessionRightString))
            || (this.requestContractService.contractType === 'license' || this.requestContractService.contractType === 'sublicense'))
          && (contractSubject.goodsAndServices === 'yes' || contractSubject.goodsAndServices === 'no'
            || (contractSubject.goodsAndServices === 'limits' && contractSubject.goodsAndServicesString))
          && (contractSubject.fieldsOfApplication.advertising || contractSubject.fieldsOfApplication.documentation || contractSubject.fieldsOfApplication.goods
            || contractSubject.fieldsOfApplication.internet || contractSubject.fieldsOfApplication.services)) {
          result = true;
        }
        break;
      case 'completeAlienation':
      case 'partialAlienation':
        if (contractSubject.amountOfCompensation && contractSubject.currencyType && contractSubject.VAT
          && (contractSubject.mannerOfPayment === 'month' || contractSubject.mannerOfPayment === 'quarter'
            || contractSubject.mannerOfPayment === 'year' || (contractSubject.mannerOfPayment === 'other' && contractSubject.mannerOfPaymentString))
          && (contractSubject.paymentStart === 'registration' || contractSubject.paymentStart === 'signing'
            || (contractSubject.paymentStart === 'other' && contractSubject.paymentStartString))
          && (contractSubject.goodsAndServices === 'yes' || contractSubject.goodsAndServices === 'no'
            || (contractSubject.goodsAndServices === 'limits' && contractSubject.goodsAndServicesString))) {
          result = true;
        }
    }
    return result;
  }

  /**
   * Получение договоров
   */
  getContractsNew(id?) {
    const params: any = {};
    this.requestContractService.isLoading = true;
    this.contractService.getContractsNew(params)
      .subscribe(data => {
        if (data) {
          this.immutableContracts = data.contracts;
          this.getObjectsPortfolio(id);
        }
      });
  }

  /**
   * Получение объектов договора
   */
  getObjectsPortfolio(id?): void {
    this.httpService.get({
      path: 'objects',
    }).pipe(
      finalize( () => {
          if (id) {
            this.requestContractService.loadRequestIfNeeded(id);
          }
      }
      )
    ).subscribe((data: any) => {
      this.requestContractService.isLoading = false;
      if (data?.objects?.length) {
        data.objects.forEach(item => {
          const checkedObj = this.requestContractService.objects.findIndex(el => el.id === item.id);
          item.checked = checkedObj !== -1;
          item.show = true;
          item.checkedId = item.id + ' ' + item.index[0].id;
          switch (item.index[0].id) {
            case 'rutm':
            case 'rutmap':
            case 'wotm':
            case 'wktm':
            case 'rugp':
            case 'rugpap':
              this.immutableObjects.trademarks.push(item);
              break;
            case 'rupat':
            case 'ruum':
            case 'rude':
              this.immutableObjects.patents.push(item);
              break;
            case 'rudb':
            case 'rusw':
              this.immutableObjects.softs.push(item);
          }
        });
      }
    });
  }

  resetCheckedObjects() {
    for (const objects in this.immutableObjects) {
      if (this.immutableObjects.hasOwnProperty(objects)) {
        this.immutableObjects[objects].forEach(el => el.checked = false);
      }
    }
  }

  sendContractInfo() {
    if (this.requestContractService.contractId?.length) {
      this.requestContractService.getContracts();
    } else {
      this.requestContractService.sendTypeContract();
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('payer');
  }

  public sendIntercomEvent(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.GOTO_ORDER });
  }
}
