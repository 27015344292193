<section class="autocomplete">
  <img *ngIf="icon" class="autocomplete__icon" [src]="icon" />
  <input
    id="autocomplete"
    class="search__input"
    [ngClass]="{'search__input_without-icon': !icon}"
    [ngModel]="inputValue"
    (ngModelChange)="update($event)"
    [placeholder]="placeholder"
  />

  <div class="autocomplete-dropdown" *ngIf="isShowDopdown && filteredOptions.length" style="z-index: 1000">
    <div
      *ngFor="let option of filteredOptions"
      class="autocomplete-dropdown__option"
      (click)="complete(option.label)"
    >
      {{option.label}}
    </div>
  </div>
</section>

<div *ngIf="isShowDopdown"
     (click)="isShowDopdown = false;"
     class="overlay overlay--searchList"
     style="background: none">
</div>