export class WebModuleConfig {
  production!: boolean;

  BASE_URL_API!: string;

  BASE_URL_OPEN?: string;

  FRONT_URL?: string;

  BASE_ELASTIC_URL_API!: string;

  isDebit!: boolean;

  constructor(data: Partial<WebModuleConfig>) {
    Object.assign(this, data);
  }
}
