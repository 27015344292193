<section class="possibility" *ngIf="risk === 'low'">
  <h2 class="possibility__title">Низкая вероятность регистрации</h2>
  <div class="possibility__wrap">
    <app-simple-progress-view [progress]="percent"></app-simple-progress-view>
    <div class="possibility__desc-wrapper">
      <div class="possibility__desc-wrap">
        <h3 class="possibility__caption">Причины</h3>
        <p class="possibility__desc">Обнаружены тождественные или сходные до степени смешения обозначения</p>
      </div>
      <div class="possibility__desc-wrap">
        <h3 class="possibility__caption">Как повысить вероятность регистрации?</h3>
        <p class="possibility__desc">Уточните вид деятельности, чтобы устранить пересечения с конкурентами</p>
      </div>
    </div>
    <div class="possibility__increase">
      <p class="possibility__increase-desc">Узнайте как повысить вероятность регистрации товарного знака до 96%</p>
      <a class="possibility__increase-link" (click)="emitReportCheckClick()">Узнать</a>
    </div>
  </div>
</section>

<section class="possibility" *ngIf="risk === 'middle'">
  <h2 class="possibility__title">Средняя вероятность регистрации</h2>
  <div class="possibility__wrap">
    <app-simple-progress-view [progress]="percent"></app-simple-progress-view>
    <div class="possibility__desc-wrap">
      <div class="possibility__desc-wrap">
        <h3 class="possibility__caption">Причины</h3>
        <p class="possibility__desc">Обнаружены тождественные или сходные до степени смешения обозначения</p>
      </div>
      <div class="possibility__desc-wrap">
        <h3 class="possibility__caption">Как повысить вероятность регистрации?</h3>
        <p class="possibility__desc">Уточните вид деятельности, чтобы устранить пересечения с конкурентами</p>
      </div>
    </div>
    <div class="possibility__increase">
      <p class="possibility__increase-desc">Узнайте как повысить вероятность регистрации товарного знака до 96%</p>
      <a class="possibility__increase-link" (click)="emitReportCheckClick()">Узнать</a>
    </div>
  </div>
</section>

<section class="possibility" *ngIf="risk === 'high'">
  <h2 class="possibility__title">Высокая вероятность регистрации</h2>
  <div class="possibility__wrap">
    <app-simple-progress-view [progress]="percent"></app-simple-progress-view>
    <div class="possibility__desc-wrap">
      <div class="possibility__desc-wrap">
        <h3 class="possibility__caption">Причины</h3>
        <p class="possibility__desc">Не обнаружены тождественные или сходные до степени смешения обозначения</p>
      </div>
      <div class="possibility__desc-wrap">
        <h3 class="possibility__caption">Что делать?</h3>
        <p class="possibility__desc">Вы придумали уникальное название — защитите его товарным знаком</p>
      </div>
    </div>
    <div class="possibility__increase">
      <p class="possibility__increase-desc">Отличный выбор! Подайте заявку на регистрацию товарного знака</p>
      <a class="possibility__increase-link" (click)="emitReportCheckClick()">Узнать</a>
    </div>
  </div>
</section>

