import { Injectable } from '@angular/core';
import { IResponse } from '../models/main.interfaces';
import { HttpService } from './http.service';

const path = 'user';


@Injectable({
  providedIn: 'root'
})
export class RecoveryService {

  constructor(private http: HttpService) {
  }

  recoverAccount(params) {
    return this.http.get<IResponse>({path: `${path}/recovery-pass`, subjectType: 'recover password', params});
  }

  recoverAccountMail(params) {
    return this.http.get<IResponse>({path: `${path}/recovery-pass/letter`, subjectType: 'recover password', params});
  }

  resetPassword(body, params) {
    return this.http.post<IResponse>({path: `${path}/recovery-pass/confirm`, body: {body}, subjectType: 'reset password', params});
  }
}
