<div class="data-hidden" #dataWrapper></div>

<form class="search corp" *ngIf="allFilters.length && (!isMobile || showMobileControl)"
      [class.search_mobile_shown]="isMobile && showMobileControl">

  <div class="search__wrapper">
    <ng-container *ngFor="let filter of allFilters" [ngSwitch]="filter.type">

      <app-filter-select
        *ngSwitchCase="'multiselect'"
        class="filter-item filter-select {{filter.className || ''}}"

        [name]="filter.name"
        [title]="filter.props.title"
        [icon]="filter.props.icon"
        [options]="filter.props.options"
        [value]="filterValues.has(filter) ? filterValues.get(filter) : filter.props.value"
        (apply)="onFilterChangeMultiselect(filter, $event)"
      ></app-filter-select>

      <app-filter-input
        *ngSwitchCase="'input'"
        class="filter-item filter-input {{filter.className || ''}}"

        [name]="filter.name"
        [placeholder]="filter.props.placeholder"
        [value]="filterValues.has(filter) ? filterValues.get(filter) : filter.props.value"
        (apply)="onFilterChangeInput(filter, $event)"
      ></app-filter-input>


      <app-filter-daterange
        *ngSwitchCase="'daterange'"
        class="filter-item filter-daterange {{filter.className || ''}}"

        [name]="filter.name"
        [placeholder]="filter.props.placeholder"
        [value]="filterValues.has(filter) ? filterValues.get(filter) : filter.props.value"
        (apply)="onFilterChangeDaterange(filter, $event)"
      ></app-filter-daterange>

    </ng-container>
    <div class="search__btns-wrapper">
      <button class="btn-blue btn-search" (click)="onApply();showMobileControl=false;makeBodyHidden()">Найти
      </button>
      <button class="btn-blue-attr btn-clean" (click)="onClean()">Очистить</button>
    </div>
  </div>

</form>
<div *ngIf="filteredItems.length !== allCount" class="filter-text-result">
  <b>Применен фильтр:</b>
  Показано
  <b>{{filteredItems.length}}</b>
  объектов из
  <b>{{allCount}}</b>
  <a class="show-all-link" (click)="onClean()">Показать все</a>
</div>

<div class="data">
  <div>
    <span class="control__mobile"
          *ngIf="allFilters.length"
          [class.control__mobile--active]="showMobileControl && isMobile"
          [class.control__mobile--not-active]="!showMobileControl && filteredItems.length === allCount && isMobile"
          [class.control__mobile--filtered]="!showMobileControl && filteredItems.length !== allCount && isMobile"
          (click)="this.showMobileControl = true; makeBodyHidden()">
      <p>
        Фильтр по {{getSearchType(filterType)}}
      </p>
  </span>
    <button class="control__mobile-close" *ngIf="showMobileControl === true"
            (click)="showMobileControl=false;makeBodyHidden()"></button>
  </div>
  <ng-container *ngTemplateOutlet="lvDataDirective; context: lvDataContext"></ng-container>
</div>

<app-pagination
  class="lv-pagination"
  [page]='page'
  [currentPageNumber]="currentPage ? currentPage : page"
  [totalCount]="totalCount"
  [itemPerPage]="itemsPerPage ? itemsPerPage : limit"
  (eventChangePage)="onChangePage($event)"
></app-pagination>
