import { FormControl, FormGroup } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TrademarkState } from '../../../store/trademark.state';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkActions } from '../../../store/trademark.actions';

@Component({
  selector: 'app-trademark-info',
  templateUrl: './trademark-info.component.html',
  styleUrls: ['./trademark-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkInfoComponent {
  @ViewSelectSnapshot(TrademarkState.imageUrl) imageUrl: string;
  @ViewSelectSnapshot(TrademarkState.imageError) imageError: string;
  @Dispatch() removeTrademarkImage = () => new TrademarkActions.RemoveTrademarkImage();
  @Dispatch() uploadTrademarkImage = (fileInput: HTMLInputElement) => new TrademarkActions.UploadTrademarkImage(fileInput.files[0]);
  public form: FormGroup = new FormGroup({ imageText: new FormControl() });
}
