<!--<svg display="none">-->
<!--  <symbol id="tm"><g><path xmlns="http://www.w3.org/2000/svg" d="M13.1797 2.16797H7.33203V18H5.89062V2.16797H0.0546875V0.9375H13.1797V2.16797ZM17.8555 0.9375L24.1484 15.9961L30.4648 0.9375H32.3867V18H30.9453V10.5703L31.0625 2.95312L24.7109 18H23.5977L17.2695 3.01172L17.3867 10.5234V18H15.9453V0.9375H17.8555Z" /></g></symbol>-->
<!--  <symbol id="light"><g><path d="M23.625 42h-5.25c-1.225 0-2.625-1.05-2.625-3.15v-3.675c0-1.75-.875-3.325-2.275-4.55-3.15-2.45-4.725-5.95-4.725-9.8.175-6.65 5.6-11.9 12.075-12.075 3.325 0 6.475 1.225 8.75 3.5 2.275 2.275 3.675 5.425 3.675 8.75 0 3.675-1.575 7.175-4.55 9.45-1.575 1.225-2.45 3.15-2.45 4.9v4.025c0 1.4-1.225 2.625-2.625 2.625zM21 10.5c-5.6 0-10.325 4.725-10.5 10.325 0 3.325 1.4 6.475 4.025 8.4C16.45 30.8 17.5 32.9 17.5 35.175v3.675c0 .35 0 1.4.875 1.4h5.25c.525 0 .875-.35.875-.875V35.35c0-2.275 1.225-4.725 3.15-6.3 2.45-1.925 3.85-4.9 3.85-8.05 0-2.8-1.05-5.425-3.15-7.525C26.425 11.55 23.8 10.5 21 10.5z"/><path d="M25.375 36.75h-8.75c-.525 0-.875-.35-.875-.875s.35-.875.875-.875h8.75c.525 0 .875.35.875.875s-.35.875-.875.875zM21 5.25c-.525 0-.875-.35-.875-.875v-3.5c0-.525.35-.875.875-.875s.875.35.875.875v3.5c0 .525-.35.875-.875.875zM32.725 10.15c-.175 0-.525 0-.7-.175a.846.846 0 010-1.225l2.45-2.45a.846.846 0 011.225 0c.35.35.35.875 0 1.225l-2.45 2.45s-.35.175-.525.175zM41.125 21.875h-3.5c-.525 0-.875-.35-.875-.875s.35-.875.875-.875h3.5c.525 0 .875.35.875.875s-.35.875-.875.875zM35.175 36.05c-.175 0-.525 0-.7-.175l-2.45-2.45a.846.846 0 010-1.225.846.846 0 011.225 0l2.45 2.45c.35.35.35.875 0 1.225 0 .175-.175.175-.525.175zM6.825 36.05c-.175 0-.525 0-.7-.175a.846.846 0 010-1.225l2.45-2.45a.846.846 0 011.225 0c.35.35.35.875 0 1.225l-2.45 2.45c-.175.175-.35.175-.525.175zM4.375 21.875h-3.5C.35 21.875 0 21.525 0 21s.35-.875.875-.875h3.5c.525 0 .875.35.875.875s-.35.875-.875.875zM9.275 10.15c-.175 0-.525 0-.7-.175L6.125 7.35a.846.846 0 010-1.225.846.846 0 011.225 0l2.45 2.45c.35.35.35.875 0 1.225-.175.175-.35.35-.525.35zM28 21.875c-.525 0-.875-.35-.875-.875 0-3.325-2.8-6.125-6.125-6.125-.525 0-.875-.35-.875-.875s.35-.875.875-.875c4.375 0 7.875 3.5 7.875 7.875 0 .525-.35.875-.875.875z"/></g></symbol>-->
<!--  <symbol id="tag">-->
<!--    <g><path d="M38.9238 5.86523H36.7696C36.4298 5.86523 36.1544 6.14061 36.1544 6.48047C36.1544 6.82032 36.4298 7.0957 36.7696 7.0957H38.9238C39.9415 7.0957 40.7695 7.92373 40.7695 8.94141V11.7715H1.23047V8.94141C1.23047 7.92373 2.05841 7.0957 3.07617 7.0957H34.0683C34.4082 7.0957 34.6836 6.82032 34.6836 6.48047C34.6836 6.14061 34.4082 5.86523 34.0683 5.86523H3.07617C1.37993 5.86523 0 7.24516 0 8.94141V15.7238C0 16.0637 0.275461 16.3391 0.615234 16.3391C0.955008 16.3391 1.23047 16.0637 1.23047 15.7238V13.002H40.7695V28.864C40.7695 29.2039 41.0449 29.4792 41.3848 29.4792C41.7246 29.4792 42 29.2039 42 28.864V8.94141C42 7.24516 40.6201 5.86523 38.9238 5.86523Z" /><path d="M41.3848 30.8433C41.0449 30.8433 40.7695 31.1187 40.7695 31.4585V33.0586C40.7695 34.0763 39.9415 34.9043 38.9238 34.9043H3.07617C2.05841 34.9043 1.23047 34.0763 1.23047 33.0586V18.3641C1.23047 18.0243 0.955008 17.7489 0.615234 17.7489C0.275461 17.7489 0 18.0243 0 18.3641V33.0586C0 34.7549 1.37993 36.1348 3.07617 36.1348H38.9238C40.6201 36.1348 42 34.7549 42 33.0586V31.4585C42 31.1188 41.7246 30.8433 41.3848 30.8433Z" />-->
<!--      <path d="M8.89913 19.2328C8.65886 18.9926 8.2693 18.9926 8.02911 19.2328L4.33196 22.9299C4.21654 23.0453 4.15173 23.2017 4.15173 23.365C4.15173 23.5282 4.21654 23.6846 4.33196 23.8001L7.98022 27.4483C8.10031 27.5684 8.25781 27.6285 8.41523 27.6285C8.57265 27.6285 8.73015 27.5684 8.85024 27.4483C9.09051 27.208 9.09051 26.8185 8.85024 26.5782L5.63699 23.3651L8.89913 20.1029C9.13949 19.8626 9.13949 19.4731 8.89913 19.2328Z" />-->
<!--      <path d="M17.1728 27.4483C17.2929 27.5684 17.4504 27.6285 17.6078 27.6285C17.7652 27.6285 17.9227 27.5684 18.0428 27.4483L21.6912 23.8001C21.8065 23.6847 21.8713 23.5282 21.8713 23.365C21.8713 23.2017 21.8065 23.0454 21.6912 22.9299L17.9939 19.2328C17.7537 18.9926 17.3641 18.9926 17.1239 19.2328C16.8837 19.4731 16.8837 19.8626 17.1239 20.1029L20.3861 23.365L17.1729 26.5781C16.9325 26.8186 16.9325 27.2081 17.1728 27.4483Z" />-->
<!--      <path d="M10.8274 29.7147C11.0756 29.7147 11.3094 29.5635 11.4027 29.3176L15.7745 17.8006C15.8951 17.4829 15.7353 17.1276 15.4176 17.007C15.1 16.8864 14.7447 17.0463 14.6241 17.3639L10.2524 28.8811C10.1318 29.1987 10.2916 29.554 10.6092 29.6746C10.6811 29.7019 10.7548 29.7147 10.8274 29.7147Z" />-->
<!--      <path d="M2.7074 9.46387C2.7074 10.4815 3.53534 11.3096 4.5531 11.3096C5.57086 11.3096 6.3988 10.4815 6.3988 9.46387C6.3988 8.44619 5.57086 7.61816 4.5531 7.61816C3.53534 7.61816 2.7074 8.44619 2.7074 9.46387ZM5.16833 9.46387C5.16833 9.80315 4.89238 10.0791 4.5531 10.0791C4.21382 10.0791 3.93786 9.80315 3.93786 9.46387C3.93786 9.12459 4.21382 8.84863 4.5531 8.84863C4.89238 8.84863 5.16833 9.12459 5.16833 9.46387Z" />-->
<!--      <path d="M7.47852 9.46387C7.47852 10.4815 8.30646 11.3096 9.32422 11.3096C10.342 11.3096 11.1699 10.4815 11.1699 9.46387C11.1699 8.44619 10.342 7.61816 9.32422 7.61816C8.30646 7.61816 7.47852 8.44619 7.47852 9.46387ZM9.93945 9.46387C9.93945 9.80315 9.6635 10.0791 9.32422 10.0791C8.98494 10.0791 8.70898 9.80315 8.70898 9.46387C8.70898 9.12459 8.98494 8.84863 9.32422 8.84863C9.6635 8.84863 9.93945 9.12459 9.93945 9.46387Z" />-->
<!--      <path d="M12.2494 9.46387C12.2494 10.4815 13.0773 11.3096 14.0951 11.3096C15.1129 11.3096 15.9408 10.4815 15.9408 9.46387C15.9408 8.44619 15.1129 7.61816 14.0951 7.61816C13.0773 7.61816 12.2494 8.44619 12.2494 9.46387ZM14.7103 9.46387C14.7103 9.80315 14.4344 10.0791 14.0951 10.0791C13.7558 10.0791 13.4799 9.80315 13.4799 9.46387C13.4799 9.12459 13.7558 8.84863 14.0951 8.84863C14.4344 8.84863 14.7103 9.12459 14.7103 9.46387Z" />-->
<!--      <path d="M27.5215 19.8818H31.377C32.3946 19.8818 33.2227 19.0538 33.2227 18.0361C33.2227 17.0185 32.3946 16.1904 31.377 16.1904H27.5215C26.5038 16.1904 25.6758 17.0185 25.6758 18.0361C25.6758 19.0538 26.5038 19.8818 27.5215 19.8818ZM27.5215 17.4209H31.377C31.7162 17.4209 31.9922 17.6969 31.9922 18.0361C31.9922 18.3754 31.7162 18.6514 31.377 18.6514H27.5215C27.1822 18.6514 26.9062 18.3754 26.9062 18.0361C26.9062 17.6969 27.1822 17.4209 27.5215 17.4209Z" />-->
<!--      <path d="M27.5215 24.8038H37.6934C38.711 24.8038 39.5391 23.9758 39.5391 22.9581C39.5391 21.9405 38.711 21.1124 37.6934 21.1124H27.5215C26.5038 21.1124 25.6758 21.9405 25.6758 22.9581C25.6758 23.9758 26.5038 24.8038 27.5215 24.8038ZM27.5215 22.3429H37.6934C38.0326 22.3429 38.3086 22.6188 38.3086 22.9581C38.3086 23.2974 38.0326 23.5734 37.6934 23.5734H27.5215C27.1822 23.5734 26.9062 23.2974 26.9062 22.9581C26.9062 22.6188 27.1822 22.3429 27.5215 22.3429Z" />-->
<!--      <path d="M27.5215 29.7257H34.6717C35.6894 29.7257 36.5174 28.8977 36.5174 27.88C36.5174 26.8623 35.6894 26.0343 34.6717 26.0343H27.5215C26.5038 26.0343 25.6758 26.8623 25.6758 27.88C25.6758 28.8977 26.5038 29.7257 27.5215 29.7257ZM27.5215 27.2648H34.6717C35.011 27.2648 35.287 27.5407 35.287 27.88C35.287 28.2193 35.011 28.4952 34.6717 28.4952H27.5215C27.1822 28.4952 26.9062 28.2193 26.9062 27.88C26.9062 27.5407 27.1822 27.2648 27.5215 27.2648Z" /><g>-->
<!--  </symbol>-->
<!--</svg>-->
<section class="section">
  <div class="container-md">
    <div class="steps">
      <div class="steps__element steps__element--line" [ngClass]="getStepClass(1)"><span class="steps__element-text">Обозначение</span></div>
      <div class="steps__element steps__element--line" [ngClass]="getStepClass(2)"><span class="steps__element-text">Классы МКТУ</span></div>
      <div class="steps__element steps__element--line" [ngClass]="getStepClass(3)"><span class="steps__element-text">Тариф</span></div>
      <div class="steps__element steps__element--line" [ngClass]="getStepClass(4)"><span class="steps__element-text">Оплата</span></div>
      <div class="steps__element" [ngClass]="getStepClass(5)"><span class="steps__element-text">Заявка готова</span>
      </div>
    </div>
    <div [ngSwitch]="step">
      <div *ngSwitchCase="0">
        <div class="loader" style="margin-top: 210px"></div>
      </div>
      <div *ngSwitchCase="1">
        <h1 class="heading-h1">Обозначение и правообладатель</h1>
        <form class="form form--cards js-form">
          <div class="form__cards">
            <div class="form__card">
              <div class="form__input-wrap">
                <input class="form__card-input image-text" type="text" required [(ngModel)]="imageText"
                       name="imageText"><span class="form__card-input-label">Введите текстовое обозначение,<br
                class="hide-up-to-md">которое хотите зарегистрировать</span>
              </div>
            </div>
            <div class="form__card">
              <div class="form__file js-form__file">
                <div class="form__file-wrap" style="text-align: center">
                  <img src="assets/images/download-icon.svg" style="max-height: 53px" *ngIf="!imageUrl">
                  <label class="form__file-label" for="file" *ngIf="!imageUrl">Загрузите логотип <span
                    *ngIf="!imageError">Размером не более 2 МБ</span></label>
                  <div class="image-error" *ngIf="imageError">{{imageError}}</div>
                  <ng-container *ngIf="!isImageLoading && imageUrl">
                    <div class="image-application">
                      <img [src]="imageUrl">
                    </div>
                  </ng-container>
                  <ng-template *ngIf="isImageLoading">
                    loading...
                  </ng-template>
                  <div class="form__file-labels img-downloaded" *ngIf="imageUrl">
                    <label class="form__file-download" for="file">Заменить</label>
                    <label class="form__file-delete" for="file" (click)="removeImage()">Удалить</label>
                  </div>
                  <input class="form__file-input" type="file" id="file" name="file" accept=".jpg, .jpeg, .png"
                         (change)="fileChangeEvent($event)">
                </div>
              </div>
            </div>
          </div>
        </form>
        <h2 class="heading-h2">Укажите правообладателя</h2>
        <form class="form" [formGroup]="ownerForm">
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="number"
                     required
                     formControlName="TIN"
                     pattern='^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$'
                     maxlength="15"
                     (keyup)="checkValidTIN()">
              <span class="form__input-label">ОГРН или ИНН</span>
              <div class="loading" *ngIf="loadingOwner">
                <div class="loading__inner"></div>
              </div>
              <div class="form__dropdown form-card" *ngIf="viewSelectContainer && owner" (click)="setValueOwner()">
                <table class="form-card__table">
                  <thead class="dropdown-hover">
                  <tr>
                    <th>ОГРН</th>
                    <th>ИНН</th>
                    <th>Наименование компании или ИП</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{owner.PSRN}}</td>
                    <td>{{owner.TIN}}</td>
                    <td class="black">{{owner.name || owner.fullName}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="name"
                     [attr.disabled]="disabledInput? '' : null">
              <span class="form__input-label">Наименование юрлица</span>
            </div>
          </div>
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="head"
                     [attr.disabled]="disabledInput? '' : null"><span
              class="form__input-label">Руководитель</span>
            </div>
          </div>
          <div class="form__group">
            <div class="form__input-wrap">
              <input class="form__input field" type="text" required formControlName="address"
                     [attr.disabled]="disabledInput? '' : null">
              <span class="form__input-label">Юридический адрес</span>
            </div>
          </div>
        </form>
        <div class="btn-wrap" disabled>
          <button class="btn rate__btn" (click)="nextStep()" [attr.disabled]="!checkValidForm()? '' : null">
            Продолжить
            <div>
              <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"
                  fill="white"/>
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div *ngSwitchCase="2">
        <h1 class="heading-h1">Выбор классов МКТУ</h1>
        <p class="subtitle">Классы подобраны исходя из видов деятельности правообладателя в ЕГРЮЛ</p>
        <!--        <div class="company-info">-->
        <!--          <div class="company-info__wrap">-->
        <!--            <div class="company-info__logo"><img src="/assets/images/logo-cafe.png"></div>-->
        <!--            <div class="company-info__text">-->
        <!--              <table class="company-info__table">-->
        <!--                <tbody>-->
        <!--                <tr>-->
        <!--                  <td>Свидетельство</td>-->
        <!--                  <td>№ 14851231</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Правообладатель</td>-->
        <!--                  <td>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ГАРДИУМ"</td>-->
        <!--                </tr>-->
        <!--                <tr>-->
        <!--                  <td>Классы МКТУ</td>-->
        <!--                  <td>-->
        <!--                    <div class="tooltip">43<span class="tooltip-text">Пример всплывающего тултипа с текстом описанием данного блока</span></div>,-->
        <!--                    <div class="tooltip">44<span class="tooltip-text">Пример всплывающего тултипа с текстом описанием данного блока</span></div>,-->
        <!--                    <div class="tooltip">45<span class="tooltip-text">Пример всплывающего тултипа с текстом описанием данного блока</span></div>,-->
        <!--                    <div class="tooltip">46<span class="tooltip-text">Пример всплывающего тултипа с текстом описанием данного блока</span></div>,-->
        <!--                    <div class="tooltip">47<span class="tooltip-text">Пример всплывающего тултипа с текстом описанием данного блока</span></div>,-->
        <!--                    <div class="tooltip">48<span class="tooltip-text">Пример всплывающего тултипа с текстом описанием данного блока</span></div>,-->
        <!--                  </td>-->
        <!--                </tr>-->
        <!--                </tbody>-->
        <!--              </table>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="section__wrap">-->
        <!--          <h2 class="heading-h2">Уточните ОКВЭД</h2><a class="dashed-blue">Восстановить подобранные</a>-->
        <!--        </div>-->
        <!--        <form class="js-form form">-->
        <!--          <div class="form__group">-->
        <!--            <div class="form__input-wrap">-->
        <!--              <input class="form__input field field&#45;&#45;md" type="text" name="name" required>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <app-ip-id-search-->
        <!--            (eventSelected)="eventSelectedClass($event)"-->
        <!--            [options]="optionsOkved"-->
        <!--          >-->
        <!--          </app-ip-id-search>-->

        <!--          <p class="form__text-remark">Уточните виды деятельности, начав ввод номера или описания ОКВЭД. Классы МКТУ будут подобраны автоматически.</p>-->
        <!--        </form>-->
        <!--        <h2 class="heading-h2">Подобранные классы МКТУ</h2>-->
        <form class="js-form form">
          <div class="request__form" style="margin-left: -10px">
            <app-ip-id-search
              (eventSelected)="eventSelectedClass($event)"
              (eventDeleted)="eventDeletedClass($event)"
              [options]="optionsMktu"
            >
            </app-ip-id-search>
          </div>

          <!--          <pre>{{optionsMktu|json}}</pre>-->
          <!--          <pre>{{mktu|json}}</pre>-->

          <!--          <div class="form__group">-->
          <!--            <div class="form__input-wrap">-->
          <!--              <input class="form__input field field&#45;&#45;md" type="text" name="name" required>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <p class="form__text-remark">Товарный знак будет действовать только в выбранных классах</p>-->
<!--          <div class="form__group">-->
<!--            <input class="form__checkbox" type="checkbox" name="checkbox" id="checkbox"-->
<!--                   [(ngModel)]="employeeSelectClasses">-->
<!--            <label class="form__label-checkbox form__label-checkbox&#45;&#45;has-remark" for="checkbox">Подбор и проверка-->
<!--              классов патентным поверенным-->
<!--              &lt;!&ndash;              <span class="highlighted-remark">+ 2 000 ₽ </span>&ndash;&gt;-->
<!--            </label>-->
<!--          </div>-->
          <div class="okved" *ngIf="OKVEDCodes && OKVEDCodes.length">
            <div class="okved-title">ОКВЭД из ЕГРЮЛ для {{ownerShortName}}</div>
            <div class="okved-codes">
              <div class="okved-code" *ngFor="let okved of OKVEDCodes">
                {{getShortOkved(okved.code + ' ' + okved.description)}}
                <div class="okved-tooltip" *ngIf="(okved.code + ' ' + okved.description).length > 40">
                  {{okved.description}}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="btn-wrap" style="position: relative">
          <a class="back-button" (click)="backStep()">
            <div class="back-icon"></div>
            <div class="text">Назад</div>
          </a>
          <a class="btn step2to3-btn" (click)="step2to3Click()">
            <div class="btn-body">
              <span
                (click)="nextStep()"
                *ngIf="!step2ButtonClicked || loadindErrorStep2Send else repeatText">
                Продолжить
                <div>
            </div>
              </span>
              <ng-template #repeatText>
                <span>Повторить</span>
              </ng-template>
              <div *ngIf="!step2ButtonClicked else error" class="arrow-request-icon">
                <!--              <div *ngIf="false else error" class="arrow-request-icon">-->
                <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"
                    fill="white"/>
                </svg>
              </div>
              <ng-template #error>
                <span class="loaderAuth" *ngIf="loadindErrorStep2Send else repeat"></span>
                <!--                <span class="loaderAuth" *ngIf="true else repeat"></span>-->
                <ng-template #repeat>
                  <div class="repeat">
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.0673162 9.85268C0.606606 5.03829 4.45517 1.17992 9.26955 0.630825C15.5645 -0.089861 20.9231 4.80297 20.9917 10.9362L23.8156 10.9362C23.9725 10.9362 24.0559 11.1323 23.9578 11.2646L19.6876 17.0792C19.6141 17.1772 19.4719 17.1772 19.3984 17.0792L15.1282 11.2646C15.0301 11.1323 15.1184 10.9362 15.2704 10.9362L18.0894 10.9362C18.0207 6.62674 14.3536 3.17529 9.97062 3.46945C6.2005 3.71948 3.14126 6.79343 2.90104 10.5636C2.6461 14.5592 5.49943 17.9567 9.27445 18.5647C9.97552 18.6774 10.4854 19.2902 10.4854 19.9962C10.4854 20.8738 9.70588 21.5602 8.83811 21.4229C3.45993 20.5698 -0.574928 15.6035 0.0673162 9.85268Z"
                        fill="white"/>
                    </svg>
                  </div>
                </ng-template>
              </ng-template>
            </div>
          </a>
        </div>
        <div *ngIf="step2ButtonClicked && !loadindErrorStep2Send" class="error-message">
          Кажется, что-то пошло не так. Если ошибка повторяется, попробуйте повторить позже.
        </div>
      </div>
      <div *ngSwitchCase="3">
        <h1 class="heading-h1">Выберите тариф</h1>
        <app-rates
          [rates]="rates"
          [activeTariff]="activeTariff"
          (selectingTariff)="selectTariff($event)"
          [countRates]="countRates"
        ></app-rates>
<!--        <div class="rates">-->
<!--          <div class="rate-card" *ngIf="rates.min" [ngClass]="{'rate-card&#45;&#45;highlight': rates.min.id == activeTariff}"-->
<!--               (click)="selectTariff(rates.min)">-->
<!--            <div class="rate-card__name">Минимальный</div>-->
<!--            <div class="progress-risk">-->
<!--              <img src="/assets/images/min-13.svg" alt="">-->
<!--              <div class="text">-->
<!--                вероятность регистрации-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Здесь будет текст</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <hr color="#DCDEE1" noshade size="1">-->
<!--            <div class="price">-->
<!--              <div class="old-price" *ngIf="rates.min.discount !== '0'">{{(+rates.min.sum).toLocaleString('ru')}}₽-->
<!--              </div>-->
<!--              <div class="discount" *ngIf="rates.min.discount !== '0'">-{{rates.min.discount}}%</div>-->
<!--              <div class="price__full">{{(+rates.min.sumDiscount).toLocaleString('ru')}} ₽</div>-->
<!--            </div>-->
<!--            <div class="rate-description">-->
<!--              <div class="rate-description__title">В тариф включено</div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Подача заявки-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Электронное делопроизводство-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Получение свидетельства-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="rate-card" *ngIf="rates.opty" [ngClass]="{'rate-card&#45;&#45;highlight': rates.opty.id == activeTariff}"-->
<!--               (click)="selectTariff(rates.opty)">-->
<!--            <div class="rate-card__name">Оптимальный</div>-->
<!--            <div class="progress-risk">-->
<!--              <img src="/assets/images/opty-54.svg" alt="">-->
<!--              <div class="text">-->
<!--                вероятность регистрации-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Здесь будет текст</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <hr color="#DCDEE1" noshade size="1">-->
<!--            <div class="price">-->
<!--              <div class="old-price" *ngIf="rates.opty.discount !== '0'">{{(+rates.opty.sum).toLocaleString('ru')}}₽-->
<!--              </div>-->
<!--              <div class="discount" *ngIf="rates.opty.discount !== '0'">-{{rates.opty.discount}}%</div>-->
<!--              <div class="price__full">{{(+rates.opty.sumDiscount).toLocaleString('ru')}} ₽</div>-->
<!--            </div>-->
<!--            <div class="rate-description">-->
<!--              <div class="rate-description__title">Плюс к тарифу «Минимальный»</div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Подбор и проверка классов МКТУ-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Полная проверка слова или словосочетания-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Доработка обозначения до уникальности-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="rate-card" *ngIf="rates.max" [ngClass]="{'rate-card&#45;&#45;highlight': rates.max.id == activeTariff}"-->
<!--               (click)="selectTariff(rates.max)">-->
<!--            <div class="rate-card__name">Максимальный</div>-->
<!--            <div class="progress-risk">-->
<!--              <img src="/assets/images/max-97.svg" alt="">-->
<!--              <div class="text">-->
<!--                вероятность регистрации-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Здесь будет текст</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <hr color="#DCDEE1" noshade size="1">-->
<!--            <div class="price">-->
<!--              <div class="old-price" *ngIf="rates.max.discount !== '0'">{{(+rates.max.sum).toLocaleString('ru')}}₽-->
<!--              </div>-->
<!--              <div class="discount" *ngIf="rates.max.discount !== '0'">-{{rates.max.discount}}%</div>-->
<!--              <div class="price__full">{{(+rates.max.sumDiscount).toLocaleString('ru')}} ₽</div>-->
<!--            </div>-->
<!--            <div class="rate-description">-->
<!--              <div class="rate-description__title">Плюс к тарифу «Оптимальный»</div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Ответы на запросы и уведомления Роспатента-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <hr color="#EBEDF2" noshade size="1">-->
<!--              <div class="rate-description__item">-->
<!--                Юридические стратегии-->
<!--                <div class="info-icon">-->
<!--                  <div class="info-tooltip">Пример всплывающего тултипа с текстом описанием данного блока</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="duty" *ngIf="duty">
          <div>
            <div class="duty__text">Пошлины Роспатента</div>
            <div class="duty__description">Все пошлины за {{MKTUCodesShort.length}} {{declOfNum(MKTUCodesShort.length)}}
              МКТУ, с учетом 30% скидки IP-ID за электронную подачу заявки
            </div>
          </div>
          <div class="duty__price-wrap">
            <p class="duty__price">
              <span class="duty__old-price">{{(+duty.sum).toLocaleString('ru')}} ₽</span>
              <span class="duty__discount">-{{duty.discount}}%</span>
            </p>
            <span class="duty__full">{{(+duty.sumDiscount).toLocaleString('ru')}} ₽</span>
          </div>
        </div>
        <div class="btn-wrap rate__btn-wrap" style="position: relative; margin-top: 30px;">
          <a class="back-button" (click)="backStep()" style="transform: unset">
            <div class="back-icon"></div>
            <div class="text">Назад</div>
          </a>
          <div class="rate__amount">
            <p class="rate__amount-price">
              Итого к оплате:
              <span class="rate__amount-price rate__amount-price--old">{{(+sumBeforeDiscount).toLocaleString('ru')}}
                ₽</span>&nbsp;
              <span class="rate__amount-price rate__amount-price--new">{{(+paymentSum).toLocaleString('ru')}} ₽</span>
            </p>
            <button class="btn rate__btn" (click)="nextStep()">Оплатить
              <div>
                <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"
                    fill="white"/>
                </svg>
              </div>
            </button>
          </div>
        </div>
        <!--        <div class="price-section__row">-->
        <!--          <div class="price-section__left tabs js-tabs">-->
        <!--            <ul class="tabs__list">-->
        <!--              <li class="tabs__item"><a class="tabs__item-link js-tabs__link is-active" data-id="min">Минимальный</a></li>-->
        <!--              <li class="tabs__item"><a class="tabs__item-link js-tabs__link" data-id="opt">Оптимальный</a></li>-->
        <!--              <li class="tabs__item"><a class="tabs__item-link js-tabs__link" data-id="max">Максимальный</a></li>-->
        <!--            </ul>-->
        <!--            <div class="tabs__body">-->
        <!--              <div class="tabs__remark">Повышение вероятности</div>-->
        <!--              <div class="tabs__content js-tabs__content is-active" id="min">-->
        <!--                <div class="price-cards js-price-cards">-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Ответы на запросы и уведомления Роспатента</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">19 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch1" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch1">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Полная проверка изображения</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">24 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+17%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch2" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch2">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Ответы на запросы и уведомления Роспатента</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch3" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch3">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Юридические стратегии</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch4" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch4">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Получение свидетельства</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">1 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch5" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch5">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Подача заявки</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card"><span class="price-card__sale price-card__sale&#45;&#45;red">-15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch6" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch6">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Доработка обозначения до <br class="hide-up-to-md"> уникальности</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch7" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch7">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Электронное делопроизводство</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card"><span class="price-card__sale">+10%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch8" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch8">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Электронное делопроизводство</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="tabs__content js-tabs__content" id="opt">-->
        <!--                <div class="price-cards js-price-cards">-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch9" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch9">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Ответы на запросы и уведомления Роспатента</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch10" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch10">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Полная проверка изображения</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">24 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+17%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch12" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch12">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Ответы на запросы и уведомления Роспатента</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch13" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch13">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Юридические стратегии</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch14" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch14">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Получение свидетельства</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">1 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch15" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch5">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Подача заявки</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card"><span class="price-card__sale price-card__sale&#45;&#45;red">-15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch16" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch6">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Доработка обозначения до <br class="hide-up-to-md"> уникальности</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch17" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch17">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Электронное делопроизводство</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card"><span class="price-card__sale">+10%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch18" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch18">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Электронное делопроизводство</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="tabs__content js-tabs__content" id="max">-->
        <!--                <div class="price-cards js-price-cards">-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch19" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch19">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Ответы на запросы и уведомления Роспатента</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch20" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch20">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Полная проверка изображения</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">24 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+17%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch21" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch21">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Ответы на запросы и уведомления Роспатента</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card"><span class="price-card__sale">+15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch22" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch22">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Юридические стратегии</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card price-card&#45;&#45;active js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch24" type="checkbox" checked>-->
        <!--                          <label class="price-card__label" for="switch24">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Получение свидетельства</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">1 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch25" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch25">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Подача заявки</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">13 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card"><span class="price-card__sale price-card__sale&#45;&#45;red">-15%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch26" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch26">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Доработка обозначения до <br class="hide-up-to-md"> уникальности</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card">-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch27" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch27">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Электронное делопроизводство</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                  <div class="price-cards__element">-->
        <!--                    <div class="price-card js-price-card"><span class="price-card__sale">+10%</span>-->
        <!--                      <div class="price-card__inner">-->
        <!--                        <div class="price-card__toggle">-->
        <!--                          <input class="price-card__checkbox js-price-card__checkbox" id="switch28" type="checkbox">-->
        <!--                          <label class="price-card__label" for="switch28">Toggle</label>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__text">-->
        <!--                          <div class="price-card__name">Электронное делопроизводство</div><a class="price-card__link blue">Подробнее</a>-->
        <!--                        </div>-->
        <!--                        <div class="price-card__price">8 900 ₽</div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="price-section__right">-->
        <!--            <div class="progress-card">-->
        <!--              <div class="progress-card__top">-->
        <!--                <h2 class="heading-h2">Вероятность регистрации</h2>-->
        <!--                <div class="progress-card__progress-wrap"><img src="/assets/images/icon-progress_87.svg"><span class="progress-card__progress-num">87%</span></div><span class="progress-card__top-remark"> На основании поиска от 10.05.2020</span>-->
        <!--              </div>-->
        <!--              <div class="progress-card__bottom">-->
        <!--                <div class="progress-card__bottom-inner">-->
        <!--                  <div class="progress-card__bottom-col"><span class="progress-card__remark">Итого</span><span class="progress-card__old-price">32 500 ₽</span></div>-->
        <!--                  <div class="progress-card__bottom-col"><span class="progress-card__sale">Скидка 15%</span><span class="progress-card__new-price">23 100 ₽</span></div>-->
        <!--                </div><a class="btn btn&#45;&#45;arrowed" (click)="nextStep()">Оплатить</a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="info-card">-->
        <!--              <div class="info-card__top">+ Пошлины Роспатента</div>-->
        <!--              <p class="info-card__text">Итоговая сумма пошлин будет рассчитана после оформления заявки</p>-->
        <!--              <div class="info-card__price">от 10 300 ₽</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div *ngSwitchCase="4">
        <h1 class="heading-h1">{{!payError?.status ? 'Выбор способа оплаты' : 'Ошибка'}}</h1>
        <div *ngIf="payError?.status" class="info-plate">
          <div class="info-plate__img"><img [src]="logo2path"></div>
          <p class="info-plate__text" [innerHTML]="payError?.text"></p>
          <a class="btn" (click)="repeatPayment()">ПОПРОБОВАТЬ ЕЩЕ РАЗ</a>
        </div>
        <div *ngIf="!payError?.status" class="tabs js-tabs">
          <ul class="tabs__list tabs__list--half-cols">
            <li class="tabs__item">
              <a [ngClass]="paymentTab === 'online'? 'is-active': ''"
                 class="tabs__item-link js-tabs__link" data-id="online">оплата онлайн</a>
            </li>
            <li class="tabs__item">
              <a (click)="switchPaymentTab('cash')" [ngClass]="paymentTab === 'cash'? 'is-active': '' "
                 class="tabs__item-link js-tabs__link" data-id="cash">Выставить счет для безналичной оплаты</a>
            </li>
          </ul>
          <div class="tabs__body">
<!--            <div *ngIf="paymentTab === 'online'" class="js-tabs__content tabs__content is-active" id="online">-->
<!--              <div class="screenshots">-->
<!--                <div id="payment-form" style="min-height: 480px;">-->
<!--                  <div *ngIf="!payWidjetIsRender" style="padding-top: 190px;">-->
<!--                    <div class="loader"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="btn-wrap" style="position: relative; margin-top: 20px;">-->
<!--                <a class="back-button" (click)="backStep()" style="transform: unset">-->
<!--                  <div class="back-icon"></div>-->
<!--                  <div class="text">Назад</div>-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->
            <div *ngIf="paymentTab === 'cash'" class="js-tabs__content tabs__content" id="cash">
              <form class="form" [formGroup]="formPay">
                <div class="form__group">
                  <div class="form__input-wrap">
                    <input class="form__input field" formControlName="INN" type="number"
                           style="pointer-events: none"><span class="form__input-label"> ИНН</span>
                  </div>
                </div>
                <div class="form__group">
                  <div class="form__input-wrap">
                    <input class="form__input field" formControlName="OGRN" type="number"
                           style="pointer-events: none"><span class="form__input-label"> ОГРН</span>
                  </div>
                </div>
                <div class="form__group">
                  <div class="form__input-wrap">
                    <input class="form__input field" formControlName="name" type="text"
                           style="pointer-events: none"><span
                    class="form__input-label">Наименование юрлица или ИП</span>
                  </div>
                </div>
                <div class="form__group">
                  <div class="form__input-wrap">
                    <input class="form__input field" formControlName="address" type="text" style="pointer-events: none"><span
                    class="form__input-label">Юридический адрес</span>
                  </div>
                </div>
                <div class="form__group">
                  <div class="form__input-wrap">
                    <input class="form__input field" formControlName="sum" type="text"
                           style="pointer-events: none"><span class="form__input-label">Сумма</span>
                  </div>
                </div>
              </form>
              <div class="btn-wrap" style="position: relative">
                <a class="back-button" (click)="backStep()" style="transform: unset">
                  <div class="back-icon"></div>
                  <div class="text">Назад</div>
                </a>
                <a class="btn btn__pay" (click)="sendPayJSON('cash')">Выставить счет</a></div>
            </div>
          </div>
        </div>
      </div>
      <!--        Разметка ошибки-->
<!--      <div *ngSwitchCase="5">-->
<!--        <h1 class="heading-h1 heading-h1&#45;&#45;red">Ошибка</h1>-->
<!--        <div class="info-plate" >-->
<!--          <div class="info-plate__img"><img [src]="logo2path"></div>-->
<!--          <p class="info-plate__text">Неправильно указан код CVV2 (CVC2, CID). <br>-->
<!--            Вам следует повторить платеж и ввести корректные данные.</p>-->
<!--          <button class="btn-blue">Попробовать еще раз</button>-->
<!--        </div>-->
<!--      </div>-->
      <div *ngSwitchCase="5">
        <h1 class="heading-h1">Заявка успешно создана</h1>
<!--        <div class="info-plate" *ngIf="paymentTab === 'online'">-->
<!--          <div class="info-plate__img"><img [src]="logo2path"></div>-->
<!--          <p class="info-plate__text">Как только ваша оплата будет обработана,<br class="hide-up-to-md">-->
<!--            мы переместим вашу заявку из черновиков в активные дела.</p><a class="btn" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к-->
<!--          заявке</a>-->
<!--          &lt;!&ndash;          <p class="info-plate__text">Как только ваша оплата будет обработана,<br class="hide-up-to-md">&ndash;&gt;-->
<!--          &lt;!&ndash;            мы переместим вашу заявку из черновиков в активные <a class="bordered-blue" routerLink="/cases">дела</a>.</p><a class="btn" routerLink="/cases">Перейти к&ndash;&gt;-->
<!--          &lt;!&ndash;          заявке</a>&ndash;&gt;-->
<!--        </div>-->
        <div class="info-plate" *ngIf="paymentTab === 'cash'">
          <div class="info-plate__img"><img [src]="logo2path"></div>
          <p class="info-plate__text">Счет сформирован и отправлен вам на почту {{userEmail}}.<br> Рекомендуем оплатить
            счет в течение 5 дней.</p><a class="bordered-blue" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к заявке</a>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- TODO: for test -->
<!--<div style="position: absolute; bottom: 20px; right: 20px">-->
<!--  <button (click)="backStep()"><-</button>-->
<!--  <button (click)="nextStep()">-></button>-->
<!--  <br>-->
<!--  Кнопки временные (для разработчиков)-->
<!--</div>-->

