import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkState } from '@web/features/requests/trademark/store/trademark.state';
import { TrademarkActions } from '@web/features/requests/trademark/store/trademark.actions';
import { SearchLegalResponseSourse } from '@web/features/requests/trademark/types/trademark.types';

@Component({
  selector: 'app-selection-container',
  templateUrl: './selection-container.component.html',
  styleUrls: ['./selection-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionContainerComponent {
  @ViewSelectSnapshot(TrademarkState.isCheckingCandidate) isCheckingCandidate: boolean;
  @ViewSelectSnapshot(TrademarkState.viewSelectContainer) viewSelectContainer: boolean;
  @ViewSelectSnapshot(TrademarkState.candidate) candidate: SearchLegalResponseSourse | null;
  @Dispatch() checkCurrentOrganization = (ogrn: number) => new TrademarkActions.CheckOrganization({ PSRN: ogrn });
}
