import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-tab',
  templateUrl: './info-tab.component.html',
  styleUrls: ['./info-tab.component.scss']
})
export class InfoTabComponent implements OnInit {
  @Input() type;
  @Input() typeOfClaim;
  hasExpertise = false;

  accordion = [
    {
      name: 'Дизайнстройремонт, ООО',
      isOpen: false,
    },
    {
      name: 'Окнасервис, ООО',
      isOpen: false,
    },
    {
      name: 'Докамонтаж, ООО',
      isOpen: false,
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
