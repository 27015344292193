import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ls-emb-loader',
  templateUrl: './emb-loader.component.html',
  styleUrls: ['./emb-loader.component.scss']
})
export class EmbLoaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
