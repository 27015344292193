import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { Location } from '@angular/common';
import {Store} from '@ngxs/store';
import {OrganizationActions} from '@web/core/states/organization.actions';

@Component({
  selector: 'app-change-organization',
  templateUrl: './change-organization.component.html',
  styleUrls: ['./change-organization.component.scss']
})

export class ChangeOrganizationComponent implements OnInit {
  editCompany: FormGroup;
  phoneVerified = false;
  errorText;
  showCodeInput = false;
  codeError = false;
  loading = false;
  organization;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userProfileService: UserProfileService,
    private router: Router,
    private store: Store,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    console.log(2);
    this.getUrlParam();
  }

  getUrlParam() {
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.getOrganizationById(params.id);
      }
    });
  }

  getOrganizationById(id) {
   this.store.dispatch(new OrganizationActions.Init()).subscribe(data => {
     this.organization = data.organizations.organizations.find(el => el.id === id);
     this.editCompany.controls.name.setValue(this.organization.name);
     if (this.organization.phone) {
       this.editCompany.controls.phone.setValue(this.organization.phone);
     }
     if (this.organization.email) {
       this.editCompany.controls.email.setValue(this.organization.email);
     }
   });
  }

  initForm(): void {
    this.editCompany = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]]
    });
  }


  initiatePhoneForm() {
    this.phoneVerified = false;
  }

  checkChanges(): boolean {
    return this.organization?.phone !== this.editCompany?.controls.phone.value || this.organization?.email !== this.editCompany?.controls.email.value;
  }

  toPrevPage() {
    this.router.navigate(['user/organization'], {queryParams: {id: this.organization.id}});
  }

  updateOrganization() {
    const query = {
      organization: this.organization.id,
      phone: this.editCompany.controls.phone.value,
      email: this.editCompany.controls.email.value
    };
    this.userProfileService.updateOrganization(query).subscribe((data: any) => {
      if (data?.answer) {
        this.userProfileService.loadOrganizationIfNeeded();
        this.router.navigate(['user/profile']);
      }
      console.log(data);
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  showEmailError(): boolean {
    return (!this.validateEmail(this.editCompany.value.email) &&
      this.editCompany.controls.email.touched && this.editCompany.value.email && this.organization.email !== this.editCompany.controls.email.value);
  }

  backClicked() {
    this.location.back();
  }

  public numericOnly(event): boolean {
    const onlyNumbersPattern = /^-?(0|[1-9]\d*)?$/;
    return onlyNumbersPattern.test(event.key);
  }
}
