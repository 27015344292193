<section class="payment">
  <h2 class="payment__title" id="paymentHeader">Выбор способа оплаты</h2>
  <div class="payment__tabs">
    <app-payment-tabs></app-payment-tabs>
  </div>
  <app-payer></app-payer>

  <ng-container [ngSwitch]="currentTab">
    <app-payment-online *ngSwitchCase="'online'"></app-payment-online>
    <app-payment-cash *ngSwitchCase="['cash', 'act'] | switchMultiCase:currentTab"></app-payment-cash>
  </ng-container>

  <div class="payment-actions">
    <app-ui-button class="payment-actions__cancel" uiType="link" label="Назад" icon="arrow-left" iconPos="left" (onClick)="cancel.emit()"></app-ui-button>
    <app-ui-button class="payment-actions__pay" *ngIf="currentTab === 'cash'" label="Выставить счет" [disabled]="!payer" (onClick)="success()"></app-ui-button>
    <app-ui-button class="payment-actions__pay" *ngIf="currentTab === 'act'" label="Включить в акт" [disabled]="!payer" (onClick)="success()"></app-ui-button>
  </div>
</section>
