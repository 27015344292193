import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestContractService} from '../../shared/request-contract.service';
import areaSubject from '../json-data-territorial-subjects';
import {HttpService} from '../../../../shared/services/http.service';
import {FormValidationService} from '../../../../shared/services/form-validation.service';
import {ConditionsService} from '../conditions.service';

@Component({
  selector: 'app-short-form',
  templateUrl: './short-form.component.html',
  styleUrls: ['./short-form.component.scss'],
  providers: [ConditionsService]
})
export class ShortFormComponent implements OnInit {

  @Input() contractType;
  @Output() type = new EventEmitter();

  subject = false; // выбор кнопки Субъект (Территория действия)
  areaSubjects = areaSubject; // Субъекты

  showOptionsNDS = false; // выбор из списка НДС (Сумма вознаграждения)
  NDS = [
    {
      name: 'Не включая НДС',
      value: '0',
    },
    {
      name: 'С НДС 20%',
      value: '20',
    },
    {
      name: 'С НДС 10%',
      value: '10',
    }]; // НДС

  selectedNDS = this.NDS[0].name; // выбранный НДС

  showOptionsCurrency = false; // выбор из списка валют (Сумма вознаграждения)
  currency = ['РУБ', 'USD', 'EUR', 'AUD', 'KRW', 'KZT', 'CAD', 'KGS', 'SGD']; // Валюты
  selectedCurrency = this.currency[0]; // выбранная валюта

  mobileTextarea1100 = true;
  mobileTextarea400 = true;

  files = [];
  fileName;
  fileError = '';
  docBase64;
  fileLinks = [];
  onlyNumbersPattern = /^-?(0|[1-9]\d*)?$/;

  conditionsForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private requestContractService: RequestContractService,
    private httpService: HttpService,
    private validationService: FormValidationService,
    readonly conditionsService: ConditionsService
    ) {
  }

  ngOnInit(): void {
    this.type.emit(this.contractType);
    this.contractType = this.requestContractService.contractType;
    this.initForm();
    if (!!this.requestContractService.subject) {
      this.setForm();
    }
    this.mobileTextarea1100 = window.innerWidth < 1100;
    this.mobileTextarea400 = window.innerWidth < 400;
    window.addEventListener('resize', () => {
      this.mobileTextarea1100 = window.innerWidth < 1100;
    });
    this.setValue();
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  onSelectNDS(comp): void {
    this.selectedNDS = comp;
    this.showOptionsNDS = false;
    this.setValue();
  }

  onSelectCurrency(comp): void {
    this.selectedCurrency = comp;
    this.showOptionsCurrency = false;
    this.setValue();
  }

  initForm(): void {
    this.conditionsForm = this.fb.group({
      kind: new FormControl(this.contractType),
      reward: new FormGroup({
        amountOfCompensation: new FormControl(null, Validators.pattern(this.validationService.onlyNumbersPattern)),
        currencyType: new FormControl(this.selectedCurrency),
        VAT: new FormControl(''),
      }),
      mannerOfPayment: new FormControl(''),
      mannerOfPaymentString: new FormControl(''),
      paymentStart: new FormControl(''),
      paymentStartString: new FormControl(''),
      goodsAndServices: new FormControl(this.contractType === 'completeAlienation' ? 'yes' : 'limits'),
      goodsAndServicesString: new FormControl(''),
      misc: new FormControl(''),
      file: new FormControl(''),
    });
  }

  setForm(): void {
    this.conditionsForm = this.fb.group({
      kind: new FormControl(this.contractType),
      reward: new FormGroup({
        amountOfCompensation: new FormControl(this.requestContractService.subject.amountOfCompensation),
        currencyType: new FormControl(this.selectedCurrency),
        VAT: new FormControl(this.conditionsService.setNDS(this.selectedNDS)),
      }),
      mannerOfPayment: new FormControl(this.requestContractService.subject.mannerOfPayment),
      mannerOfPaymentString: new FormControl(this.requestContractService.subject.mannerOfPaymentString),
      paymentStart: new FormControl(this.requestContractService.subject.paymentStart),
      paymentStartString: new FormControl(this.requestContractService.subject.paymentStartString),
      goodsAndServices: new FormControl(this.contractType === 'completeAlienation' ? 'yes' : 'limits'),
      goodsAndServicesString: new FormControl(this.requestContractService.subject.goodsAndServicesString),
      misc: new FormControl(this.requestContractService.subject.misc),
      file: new FormControl(this.requestContractService.files),
    });
  }

  submit() {
    this.conditionsForm.value.doc = this.docBase64;
  }

  setValue() {
    this.requestContractService.contractType = this.conditionsForm.value.kind;
    this.requestContractService.subject = {
      amountOfCompensation: this.conditionsForm.value.reward.amountOfCompensation,
      currencyType: this.selectedCurrency,
      VAT: this.conditionsService.setNDS(this.selectedNDS),
      mannerOfPayment: this.conditionsForm.value.mannerOfPayment,
      mannerOfPaymentString: this.conditionsForm.value.mannerOfPaymentString,
      paymentStart: this.conditionsForm.value.paymentStart,
      paymentStartString: this.conditionsForm.value.paymentStartString,
      goodsAndServices: this.conditionsForm.value.goodsAndServices,
      goodsAndServicesString: this.conditionsForm.value.goodsAndServicesString,
      misc: this.conditionsForm.value.misc,
      files: this.fileLinks,
    };
    this.requestContractService.validation = false;
    this.conditionsForm.get('kind').valueChanges.subscribe(type => this.setGoodsAndServices(type));
    console.log(this.requestContractService.subject);
  }

  setGoodsAndServices(type) {
    if (type === 'completeAlienation') {
      this.conditionsForm.get('goodsAndServices').setValue('yes');
    } else {
      this.conditionsForm.get('goodsAndServices').setValue('limits');
    }
  }

  get validation(): boolean {
    return this.requestContractService.validation;
  }

  get isLoading() {
    return this.requestContractService.isLoading;
  }


  /**
   * Сохранение загруженных файлов при возвращении со следующего шага
   */
  setFiles() {
    if (this.requestContractService.files.length > 0) {
      this.files = this.requestContractService.files;
      return this.files;
    } else {
      return this.files;
    }
  }
}
