import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Component, ChangeDetectionStrategy, Renderer2 } from '@angular/core';
import mktuData from 'projects/app-search/src/app/pages/ip-id-search/json-data/mktu';
import { TrademarkState } from '@web/features/requests/trademark/store/trademark.state';
import { MKTU, OptionsMKTU } from '@web/features/requests/trademark/types/trademark.types';
import { TrademarkActions } from '@web/features/requests/trademark/store/trademark.actions';


@Component({
  selector: 'app-mktu-mobile',
  templateUrl: './mktu-mobile.component.html',
  styleUrls: ['./mktu-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MktuMobileComponent {
  @ViewSelectSnapshot(TrademarkState.optionsMktu) optionsMktu: OptionsMKTU;
  @Dispatch() selectedClass = (selectedClass: MKTU) => new TrademarkActions.AddMKTUClass(selectedClass);
  @Dispatch() removeMobileMKTUClass = (deletedClass: number) => new TrademarkActions.RemoveMKTUClass(deletedClass);

  public showModalMktu: boolean = false;
  public mktuData: MKTU[] = JSON.parse(JSON.stringify(mktuData));

  constructor(private readonly renderer: Renderer2) { }

  public openModal(): void {
    this.showModalMktu = true;
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  public closeModal(): void {
    this.showModalMktu = false;
    this.renderer.setStyle(document.body, 'overflow', 'visible');
  }

  public closeModalSuccess(event: number[]): void {
    this.closeModal();
    event.forEach(code => {
      if (!this.optionsMktu.selectedMktu.includes(code)) {
        const mktu = this.mktuData.find(item => item.number === code);
        this.selectedClass(mktu);
      }
    });
  }
}
