import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';
import { ClickOutsideModule } from 'ng-click-outside';

import { SystemPageRoutingModule } from './system-page-routing.module';
import { AuthGuard } from '../shared/guardes/auth-guard.service';

import { RequestComponent } from './request/request.component';
import { RatesComponent } from './request/components/rates/rates.component';
import { MktuMobileComponent } from './request/components/mktu-mobile/mktu-mobile.component';
import { PaymentComponent } from './request/components/payment/payment.component';
import { TmOwnerComponent } from './request/components/tm-owner/tm-owner.component';
import { TmMktuComponent } from './request/components/tm-mktu/tm-mktu.component';
import { OwnerFormComponent } from './request/components/owner-form/owner-form.component';
import { RequestPatentComponent } from './request/components/request-patent/request-patent.component';
import { InformationComponent } from './request/components/information/information.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FileComponent } from './file/file.component';
import { IndexComponent } from './index/index/index.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoComponent } from './layout/logo/logo.component';
import { IpIdSearchModule } from '../../../../app-search/src/app/app.module';
import { MonitoringWidgetComponent } from './index/widgets/monitoring-widget/monitoring-widget.component';
import { ContractTariffComponent } from './contracts/contract-tariff/contract-tariff.component';
import { UserModule } from './user/user.module';
import { MktuModalComponent } from './request/components/mktu-mobile/mktu-modal/mktu-modal.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { DragScrollModule } from 'ngx-drag-scroll';
import { SharedModule } from '../shared/shared.module';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { CorpHistoryModule } from '@web/features/corp/corp-history/corp-history.module';
import { LayoutModule } from './layout/layout.module';
import { CorpIndexModule } from './corp/corp-index/corp-index.module';
import { CorpSearchModule } from '@web/features/corp/corp-search/corp-search.module';
import { UrlService } from '../shared/services/url.service';
import { RisksWidgetComponent } from './index/widgets/risks-widget/risks-widget.component';
import { RequestContractComponent } from './request-contract/request-contract.component';
import { SidesComponent } from './request-contract/sides/sides.component';
import { ObjectsComponent } from './request-contract/objects/objects.component';
import { RiskComponent } from './request-contract/objects/risk/risk.component';
import { TypeComponent } from './request-contract/type/type.component';
import { ConditionsComponent } from './request-contract/conditions/conditions.component';
import { PaymentContractComponent } from './request-contract/payment-contract/payment-contract.component';
import { FinishedComponent } from './request-contract/finished/finished.component';
import { FormResidentComponent } from './request-contract/sides/form-resident/form-resident.component';
import { FormNotResidentComponent } from './request-contract/sides/form-not-resident/form-not-resident.component';
import { ShortFormComponent } from './request-contract/conditions/short-form/short-form.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CasesModule } from './cases/cases.module';
import { ServicesModule } from './services/services.module';
import { CourtModule } from './dispute-and-court/court.module';
import { SearchModule } from './search/search.module';
import { ContractsModule } from './contracts/contracts.module';
import { MonitoringModule } from './monitoring/monitoring.module';
import { MonitoringLandingAlfaComponent } from './monitoring/monitoring-landing-alfa/monitoring-landing-alfa.component';
import { OpenModule } from './open/open.module';
import { IndexModule } from './index/index.module';
import { LoginService } from '../shared/services/login.service';
import { ChoiceOfContractComponent } from './request-contract/choice-of-contract/choice-of-contract.component';
import { PaymentModule } from '../../../../shared/components/payment/payment.module';
import { ButtonModule } from '../../../../shared/components/button/button.module';
import { DutiesComponent } from './request/components/duties/duties.component';
import {UserPhoneModule} from '../../../../shared/components/user-phone/user-phone.module';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {DownloadFilesService} from '../../../../shared/services/download-files.service';
import { RequestCheckLabelComponent } from './request-check-label/request-check-label.component';
import { LabelOwnerComponent } from './request-check-label/label-owner/label-owner.component';
import {TagInputModule} from 'ngx-chips';
import { PaymentCheckLabelComponent } from './request-check-label/payment-check-label/payment-check-label.component';
import { FinishedCheckLabelComponent } from './request-check-label/finished-check-label/finished-check-label.component';
import { MatIconModule } from '@angular/material/icon';
import { InterestService } from '../../../../shared/services/interest.service';
import { TrademarkModule } from '@web/features/requests/trademark/trademark.module';
import {ExpertModule} from './layout/expert/expert.mdoule';


@NgModule({
  imports: [
    CommonModule,
    TrademarkModule,
    SystemPageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatTooltipModule,
    MatChipsModule,
    ClickOutsideModule,
    PerfectScrollbarModule,
    FontAwesomeModule,
    IpIdSearchModule,
    UserModule,
    TextFieldModule,
    MatInputModule,
    DragScrollModule,
    TextareaAutosizeModule,
    SharedModule,
    CorpHistoryModule,
    LayoutModule,
    CorpIndexModule,
    CorpSearchModule,
    IvyCarouselModule,
    CasesModule,
    ServicesModule,
    CourtModule,
    SearchModule,
    ContractsModule,
    MonitoringModule,
    OpenModule,
    IndexModule,
    PaymentModule,
    ButtonModule,
    UserPhoneModule,
    BsDatepickerModule,
    TagInputModule,
    MatIconModule,
    MatAutocompleteModule,
    ExpertModule
  ],
  declarations: [
    RequestComponent,
    RatesComponent,
    MktuMobileComponent,
    PaymentComponent,
    TmOwnerComponent,
    TmMktuComponent,
    OwnerFormComponent,
    InformationComponent,
    RequestPatentComponent,
    IndexComponent,
    FileComponent,
    MonitoringWidgetComponent,
    ContractTariffComponent,
    MktuModalComponent,
    RisksWidgetComponent,
    RequestContractComponent,
    SidesComponent,
    ObjectsComponent,
    RiskComponent,
    TypeComponent,
    ConditionsComponent,
    PaymentContractComponent,
    FinishedComponent,
    FormResidentComponent,
    FormNotResidentComponent,
    ShortFormComponent,
    MonitoringLandingAlfaComponent,
    ChoiceOfContractComponent,
    DutiesComponent,
    RequestCheckLabelComponent,
    LabelOwnerComponent,
    PaymentCheckLabelComponent,
    FinishedCheckLabelComponent
  ],
    exports: [
        // MonitoringModalComponent,
        LogoComponent,
    ], providers: [
    AuthGuard,
    LoginService,
    UrlService,
    DownloadFilesService,
    InterestService
  ]
})
export class SystemPageModule { }
