import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchesInterface} from '@web/features/corp/corp-history/models/searches.interface';
import { HistoryActions } from '@web/features/corp/corp-history/states/history.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-history-search-item',
  templateUrl: './history-search-item.component.html',
  styleUrls: ['./history-search-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistorySearchItemComponent implements OnInit {
  @Input() search: SearchesInterface;
  @Input() currentLayout: 'sm' | 'md' | 'lg';
  @Input() listLayout: string;
  @Input() viewMode: 'searches' | 'reports' = 'searches';
  @Input() downloadingIdList: string[] = [];
  @Output() downloadReport = new EventEmitter<SearchesInterface>();
  @Output() openReport = new EventEmitter<string>();


  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
  }

  get isReportView() {
    return this.viewMode === 'reports';
  }

  get isSearchView() {
    return this.viewMode === 'searches';
  }

  onChangeTab(tab: 'searches' | 'reports') {
    this.store.dispatch(new HistoryActions.ChangeTab(tab));
  }

  onDownloadReport(search: SearchesInterface) {
    this.downloadReport.emit(search);
  }

  onOpenReport(search: SearchesInterface) {
    this.openReport.emit(search.searchID);
  }

  isDownloading(search: SearchesInterface) {
    return this.downloadingIdList.includes(search.searchID);
  }

  formatSearchType(searchType: string) {
    return {
      name: 'По обозначению',
      image: 'По изображению'
    }[searchType];
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

}
