import { Component, Input, OnInit } from '@angular/core';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';

@Component({
  selector: 'app-confidants',
  templateUrl: './confidants.component.html',
  styleUrls: ['./confidants.component.scss']
})
export class ConfidantsComponent implements OnInit {

  @Input() attorneys;
  @Input() attorney;
  proxiesByUser;
  user;
  constructor(    private userProfileService: UserProfileService,
  ) { }

  ngOnInit(): void {
    this.user = this.userProfileService.getUserData();
    if (this.user.role === 'Администратор') {
      this.proxiesByUser = this.attorneys.filter( att => att.attorney.userID === this.attorney[1]);
    } else {
      this.proxiesByUser = this.attorneys.filter( att => att.attorney.userID === this.user.userID);
    }
  }

}
