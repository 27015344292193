<div class="filter-daterange">
  <input type="text"
         id="dateRange"
         [ngModel]="val"
         (ngModelChange)="update($event)"
         [placeholder]="placeholder || ''"
         class="form-control"
         bsDaterangepicker
         [bsConfig]="{ isAnimated: true }">
</div>
