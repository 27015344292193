import { Component, OnInit } from '@angular/core';
import { MonitoringLandingService } from './monitoring-landing.service';
import risksDataJson from '../json-data-risks/json-data-risks';
import { Router } from '@angular/router';
import { environment } from '../../../../../../app-search/src/environments/environment';
import { SupportChatService } from '../../../../../../shared/services/support-chat.service';

const hostUrl = `${window.location.protocol}//${window.location.host}`;
const HOST = (hostUrl.includes('localhost') || hostUrl.includes('127.0.0.1') || hostUrl.includes('10.1.33.225'))
  ? `${environment.BASE_URL_IMG}/tm`
  : `${hostUrl}/storage/tm`;

const RUTM_PATH = `${HOST}/RUTM`;
const WKTM_PATH = `${HOST}/WKTM`;
const WOTM_PATH = `${HOST}/WOTM`;
const RUTMAP_PATH = `${HOST}/RUTMAP`;

@Component({
  selector: 'app-monitoring-landing',
  templateUrl: './monitoring-landing.component.html',
  styleUrls: ['./monitoring-landing.component.scss'],
  providers: [MonitoringLandingService],
})

export class MonitoringLandingComponent implements OnInit {
  risksData = risksDataJson;
  riskDescription;
  imgUrl: string;
  opposedImgUrl: string;

  constructor(
    readonly monitoringLandingService: MonitoringLandingService,
    private router: Router,
    private readonly supportChatService: SupportChatService,
  ) {
    this.monitoringLandingService.load();
  }

  ngOnInit(): void {
  }

  get isLoading(): boolean {
    if (!this.monitoringLandingService.isLoading && !this.riskDescription) {
      this.riskDescription = this.risksData.find(el => el.type === this.data.risk.type);
      if (this.data.markImageFileName) {
        const id = this.data.registrationString || this.data.applicationString;
        this.imgUrl = this.getImgUrl(this.data.index, parseInt(this.data.id, 10), this.data.markImageFileName, id);
      }
      if (this.data.risk.opposedObject?.markImageFileName) {
        const id = this.data.risk.opposedObject.registrationString || this.data.risk.opposedObject.applicationString;
        this.opposedImgUrl = this.getImgUrl(this.data.risk.opposedObject._index, parseInt(this.data.risk.opposedObject._id, 10), this.data.risk.opposedObject.markImageFileName, id);
      }
    }
    return this.monitoringLandingService.isLoading;
  }

  get data() {
    return this.monitoringLandingService.data;
  }

  get loadingError() {
    return this.monitoringLandingService.loadingError;
  }

  public getRiskNameByLevel(): string {
    switch (this.data.risk.level) {
      case 1:
        return 'Рекомендация';
      case 2:
        return 'Средний риск';
      case 3:
        return 'Высокий риск';
      case 4:
        return 'Критичный риск';
    }
  }

  public getImgUrl(
    type: string,
    id: number,
    markImageFileName: string,
    fullId?: string
  ): string {
    if (type === 'rutm') {
      return `${RUTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wktm') {
      return `${WKTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wotm') {
      return `${WOTM_PATH}/${this.generatePath(id)}/${markImageFileName}`;
    } else if (type === 'rutmap') {
      const year = id.toString().slice(0, 4);
      const realId = parseInt(id.toString().slice(4), 10);
      return `${RUTMAP_PATH}/NEW${year}/${this.generatePath(
        realId,
      )}/${id}/${markImageFileName}`;
    }
  }

  public generatePath(num): string {
    if (num < 1000) {
      return `0`;
    }
    const paths: string[] = [];
    const n = num.toString();
    for (let i = 1; n.length - i >= 3; i++) {
      paths.push(n.slice(0, i) + '0'.repeat(n.length - i));
    }
    return paths.join('/');
  }

  public getObjNameByIndex(index): string {
    switch (index) {
      case 'rutm':
        return 'Товарный знак';
      case 'rutmap':
        return 'Заявка на товарный знак';
      case 'domains':
        return 'Домен';
      case 'organizations':
        return 'Организация';
      case 'individual_entrepreneurs':
        return 'Индивидуальный предприниматель';
    }
  }

  public toLeadForm(): void {
    const data = {
      formIdentity: 'Оспорить риск',
      objectId: this.data.id,
      objectIndex: this.data.index,
      riskId: this.data.risk.id
    };
    this.router.navigate(['/treaty-extension'], {queryParams: {data: JSON.stringify(data)}});
  }

  onClickBtn() {
    this.supportChatService.open();
  }

}
