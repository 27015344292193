import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesRoutingModule } from './services-routing.module';
import { ServicesComponent } from './services.component';
import { ServiceItemComponent } from './service-item/service-item.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import {ExpertModule} from '../layout/expert/expert.mdoule';


@NgModule({
  declarations: [
    ServicesComponent,
    ServiceItemComponent,
  ],
  imports: [
    CommonModule,
    ServicesRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    IvyCarouselModule,
    ExpertModule
  ]
})
export class ServicesModule { }
