<section class="mktu">
  <div class="mktu__search-wrapper">
    
    <div class="mktu__search">
      <p class="mktu__empty" *ngIf="!optionsMktu.selectedMktu.length">Классы МКТУ</p>
      <div *ngFor="let mktuClass of optionsMktu.selectedMktu" class="mktu__card">
        <p class="mktu__subtitle">МКТУ</p>
        <div class="mktu__inner-card">
          <p class="mktu__number">{{mktuClass}}</p>
          <span class="mktu__close-card" (click)="removeMobileMKTUClass(mktuClass)"></span>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="mktu__btn"
      (click)="openModal()"
    >Добавить вид деятельности</button>
  </div>
</section>

<app-mktu-modal
  *ngIf="showModalMktu"
  (close)="closeModal()"
  [selectedMKTU]="optionsMktu.selectedMktu"
  (applyCodes)="closeModalSuccess($event)"
></app-mktu-modal>