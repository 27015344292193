import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tab-attorney',
  templateUrl: './tab-attorney.component.html',
  styleUrls: ['./tab-attorney.component.scss']
})
export class TabAttorneyComponent implements OnInit, OnChanges {
  // Выпадающее меню От кого
  showFromWhom = false;

  // Выпадающее меню Кому выдана
  showToWhom = false;

  // Выпадающее меню Статус
  showStatus = false;

  // Сколько показывать доверенностей
  showAttorney = 10;

  // Форма поиска на мобилке
  showMobileControl = false;

  @Input() proxies;
  proxyCopy;
  filters = {
    orgFilterData: [],
    userFilterData: [],
    checkedListStatus: []
  };


  constructor() {
  }

  ngOnInit(): void {
    this.getInitialProxies();
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  getInitialProxies() {
    this.proxyCopy = JSON.parse(JSON.stringify(this.proxies));

    this.proxies.forEach(item => {
      this.setItemToFiltersArray('orgFilterData', 'organization', item);
      this.setItemToFiltersArray('userFilterData', 'user', item);
      this.setItemToFiltersArray('checkedListStatus', 'status', item);
    });
  }

  setItemToFiltersArray(filterName: string, fieldName: string, item) {
    if (this.filters[filterName].findIndex(el => el.name === item.attorney[fieldName]) === -1) {
      const mappedItem = {
        id: item.attorney?.id,
        checked: false,
        name: item.attorney[fieldName]
      };
      this.filters[filterName].push(mappedItem);
    }
  }


  itemChecking(item) {
    item.checked = !item.checked;
    this.applyFilters();
  }


  applyFilters() {
    this.proxies = JSON.parse(JSON.stringify(this.proxyCopy));
    this.applyFilterByName('orgFilterData', 'organization');
    this.applyFilterByName('userFilterData', 'user');
    this.applyFilterByName('checkedListStatus', 'status');

  }

  applyFilterByName(filterName: string, fieldName: string) {
    const searchNames = [];
    this.filters[filterName].forEach(item => {
      if (item.checked) {
        searchNames.push(item.name);
      }
    });
    if (searchNames.length) {
      this.proxies = this.proxies.filter(el => searchNames.includes(el.attorney[fieldName]));
    }
  }

  clearFilterByName(filterName) {
    this.filters[filterName].forEach(item => {
      item.checked = false;
    });
  }


  getCountChecked(filterName: string) {
    let count = 0;
    this.filters[filterName].forEach(i => {
      if (i.checked) {
        count += 1;
      }
    });
    return count;
  }

  // Показ всех доверенностей
  attorneyShow(): void {
    this.showAttorney += 10;
  }

  // Убрать скрол под модалкой с фильтром
  makeBodyHidden() {
    if (this.showMobileControl === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  clearFilters() {
    this.proxies = JSON.parse(JSON.stringify(this.proxyCopy));
    this.clearFilterByName('orgFilterData');
    this.clearFilterByName('userFilterData');
    this.clearFilterByName('checkedListStatus');
  }

  checkAppliedFilter() {
    return !!this.getCountChecked('orgFilterData') || !!this.getCountChecked('userFilterData') || !!this.getCountChecked('checkedListStatus');
  }
}
