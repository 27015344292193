import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from './error-service';
import { StorateType } from '../enums/storate-type';
import { IDeleteParams, IGetParams, IPostParams, IPutParams } from '../models/rest.interfaces';
import {StrorageService} from '../../../../../shared/services/storage-service';

const baseUrl = environment.BASE_URL_API && environment.BASE_URL_API !== ''
  ? environment.BASE_URL_API
  : `${window.location.protocol}//${window.location.host}`;

const baseElasticUrl = environment.BASE_ELASTIC_URL_API;

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private errorService: ErrorService,
    private storageService: StrorageService
  ) {
  }

  public getHeaders(isFile = false, isRequestToElastic = false): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', this.getToken() ? this.getToken() : '');

    if (isFile) {
      headers = headers.append('Content-Type', 'multipart/form-data');
    } else if (isRequestToElastic) {
      headers = headers.delete('Authorization');
      headers = headers.append('Content-Type', 'application/json; charset=utf-8');
    } else {
      headers = headers.append('Content-Type', 'text/plain');
    }
    return headers;
  }

  private getToken(): string {
    return this.storageService.getStorageItem(StorateType.Token);
  }

  get<T>(getParams: IGetParams): Observable<T> {
    const {path, isFile = false, params = {}} = getParams;

    return this.http.get<T>(`${baseUrl}/${path}`, {headers: this.getHeaders(isFile), params})
      .pipe(catchError(this._handleError(`get ${path}`, null)));
  }

  post<T>(postParams: IPostParams): Observable<T> {
    const {path, body, isRequestToElastic = false, isFile = false, subjectType = '', params = {}, responseType = 'json'} = postParams;

    let url = path;

    if (!isRequestToElastic) {
      url = `${baseUrl}/${path}`;
    } else {
      url = `${baseElasticUrl}/${url}`;
    }

    // @ts-ignore
    return this.http.post<T>(url, body, {headers: this.getHeaders(isFile, isRequestToElastic), params, responseType})
      .pipe(
        tap(() => {
          console.log('ok');
          // this.success(`Successfully created ${subjectType}`)
        }),
        catchError(this._handleError(`create ${path}`, null)));
  }

  postWithErr<T>(postParams: IPostParams): Observable<T> {
    const {path, body, isFile = false, subjectType = ''} = postParams;
    return this.http.post<T>(`${baseUrl}/${path}`, body, {headers: this.getHeaders(isFile)});

  }

  put<T>(putParams: IPutParams): Observable<T> {
    const {path, body, isFile = false, subjectType = ''} = putParams;

    return this.http.put<T>(`${baseUrl}/${path}`, body, {headers: this.getHeaders(isFile)})
      .pipe(
        tap(() => {
          // this.success(`Successfully updated ${subjectType}`)
        }),
        catchError(this._handleError(`update ${path}`, null)),
      );
  }

  delete<T>(deleteParams: IDeleteParams) {
    const {path, id, subjectType = ''} = deleteParams;

    return this.http
      .delete(`${baseUrl}/${path}/${id}`, {headers: this.getHeaders()})
      .pipe(
        tap(() => {
          // this.success(`Successfully deleted ${subjectType}`)
        }),
        catchError(this._handleError(`delete ${path}`, null)),
      );
  }

  private _handleError<TResult>(operation = 'operation', result: TResult = null): (error) => Observable<TResult> {
    return (error) => {

      // TODO: send the error to remote logging infrastructure
      this.errorService.httpRequestError(error, operation);

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  success(message) {
    this.toastr.success(message);
  }

}
