import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebModuleConfig } from './config.service';
import { ApiService } from './api.service';
import { Store } from '@ngxs/store';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';

@Injectable()
export class UraitApiService extends ApiService {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly config: WebModuleConfig,
    protected readonly store: Store,
  ) {
    super(httpClient, config.BASE_URL_API, store);
  }

  protected getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = this.store.selectSnapshot(AUTH_STATE_TOKEN).token;
    if (token) {
      headers = headers.append('Authorization', token);
    }
    headers = headers.append('Content-Type', 'text/plain');
    return headers;
  }
}
