import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RssAnswerInterface} from './rss.interface';
import {NewsInterface} from './news.interface';

declare const require;
const xml2js = require('xml2js');

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {
  public news: NewsInterface[] = [];

  constructor(
    private http: HttpClient
  ) {
  }

  private getRSS(): void {
    this.http.get('https://legal-support.ru/bitrix/rss.php?ID=5&LANG=s1&TYPE=articles', {
      responseType: 'text'
    }).subscribe((data) => {
      const parser = new xml2js.Parser({ explicitArray: false });
      parser.parseString(data, (err, result) => {
        const newsArray: RssAnswerInterface = JSON.parse(JSON.stringify(result));
        newsArray.rss.channel.item.forEach((newsItem, index) => {
          if (index <= 3) {
            const date = new Date(newsItem.pubDate);
            date.setHours(25);
            this.news.push({
              title: newsItem.title,
              date: date.toLocaleDateString(),
              link: newsItem.link
            });
          }
        });
      });
    });
  }
  public ngOnInit(): void {
    this.getRSS();
  }
}
