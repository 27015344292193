<div class="users-rights container">
  <h2 class="visual-hidden">Пользователи и права</h2>

  <div class="users-rights__top">

    <div class="dropdown">
      <button class="dropdown__button" (click)="showOrganization =! showOrganization">
        <span *ngIf="checkedList.length <= 0" class="dropdown__button-text">Организация</span>
        <span *ngIf="checkedList.length == 1" class="dropdown__button-text dropdown__button-text--check">
          {{ checkedList[0].name }}
        </span>
        <span *ngIf="checkedList.length > 1" class="dropdown__button-text dropdown__button-text--check">
          Выбрано: {{ checkedList.length }}
        </span>
        <img
          class="dropdown__button-img"
          [ngClass]="showOrganization ? 'rotate': ''"
          src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
      </button>
      <div *ngIf="showOrganization" class="dropdown__menu-wrap">
        <div class="dropdown__menu">
          <span class="dropdown__menu-title">Организация</span>
          <ng-container *ngFor="let organization of organizations">
            <input type="checkbox" [(ngModel)]="organization.checked"
                   class="visual-hidden dropdown__input"
                   id="{{ organization.id }}"
                   (change)="getSelectedValue(organization.checked, organization.name, organization.id)"/>
            <label class="dropdown__label" for="{{ organization.id }}">
              <span>{{ organization.name }}</span>
            </label>
          </ng-container>
        </div>
      </div>
    </div>

    <!--    <ul class="users-rights__status-list">-->
    <!--      <li class="users-rights__status-item users-rights__status-item&#45;&#45;active">-->
    <!--        Активен-->
    <!--      </li>-->
    <!--      <li class="users-rights__status-item users-rights__status-item&#45;&#45;no-attorney">-->
    <!--        Нет доверенности-->
    <!--      </li>-->
    <!--      <li class="users-rights__status-item users-rights__status-item&#45;&#45;no-rights">-->
    <!--        Нет доступа-->
    <!--      </li>-->
    <!--    </ul>-->
  </div>
  <div class="users__wrap">
    <aside class="users__item"
           *ngFor="let user of users"
           [ngClass]="{'invitation': user?.active === false}"
    >
      <div class="users__info">
        <img *ngIf="user.image" src="{{ user.image }}" alt="" class="users__photo" width="100"
             height="100">
        <img *ngIf="!user.image" src="/assets/images/avatar.png" alt="" class="users__photo" width="100"
             height="100">
        <div class="users__desc-wrap">
          <p class="users__name">{{ user.name }}</p>
          <p class="users__position">{{ user.position }}</p>
          <a href="mailto:{{ user.email }}" class="users__email">{{ user.email }}</a>
        </div>
      </div>
      <div class="users__organization">
        <p class="users__organization-title">Пользователь организаций: {{ getActiveOrgCount(user) }}</p>
        <dl class="users__organization-list">
          <div class="users__organization-item" *ngFor="let item of user?.companys">
            <dt (click)="editUserRights(item.companyId, user.id)"
                class="users__organization-name">
              <span class="desktop">{{trimString(getCompanyNameById(item.companyId), 15)}}</span>
              <span class="mobile">{{trimString(getCompanyNameById(item.companyId), 12)}}</span>
              <span class="prompt__hidden">
               <p>{{getCompanyNameById(item.companyId)}}</p>
              </span>
            </dt>
            <dd class="users__organization-access"
                [ngClass]="{
                        'active': item?.status === 'Активен',
                        'no-attorney': item?.status === 'Нет доверенности',
                        'no-rights': item?.status === 'Нет доступа'}"
                [ngStyle]="{'color': item?.status === 'Нет доступа' ? '#929FAB;' : '#152438;'}"
            >
              {{ item.status }}
            </dd>
          </div>
        </dl>
      </div>
    </aside>
    <ng-container *ngIf="!checkedList.length">
      <aside class="users__item" *ngFor="let user of waitingUsers">
        <div class="users__info">
          <img src="/assets/images/avatar.png" alt="" class="users__photo" width="100"
               height="100">
          <div class="users__desc-wrap">
            <p class="users__name">{{ user.name }}</p>
            <p class="users__position">{{ user.position }}</p>
            <a href="mailto:{{ user.email }}" class="users__email">{{ user.email }}</a>
          </div>
        </div>
        <div class="users__organization invitation">
          <img src="/assets/icons/icon-invitation.svg" alt="" width="120" height="32">
          <p class="invitation__send">Отправлено приглашение</p>
          <p class="invitation__wait">Ожидаем авторизации</p>
        </div>
      </aside>
    </ng-container>
    <aside class="users__item users__item--button-add">
      <button class="users__button-add" (click)="toInvitePage()">
        <svg viewBox="0 0 60 60" class="user-organization__add-img">
          <circle cx="30" cy="30" r="30"/>
          <rect x="29" y="20" width="2" height="20" fill="white"/>
          <rect x="40" y="29" width="2" height="20" transform="rotate(90 40 29)" fill="white"/>
        </svg>
        <span class="user-organization__add-text">Пригласить пользователя</span>
      </button>
    </aside>
  </div>
</div>
