export default [
  {number: 1, name: 'МКТУ 1', desc: 'Продукты химические'},
  {number: 2, name: 'МКТУ 2', desc: 'Краски, олифы, лаки'},
  {number: 3, name: 'МКТУ 3', desc: 'Продукты косметические и туалетные нелечебные'},
  {number: 4, name: 'МКТУ 4', desc: 'Масла, смазки и воски промышленные'},
  {number: 5, name: 'МКТУ 5', desc: 'Изделия фармацевтические, препараты медицинские и ветеринарные'},
  {number: 6, name: 'МКТУ 6', desc: 'Металлы обычные и их сплавы, руды'},
  {number: 7, name: 'МКТУ 7', desc: 'Машины, станки, инструмент с механическим приводом'},
  {number: 8, name: 'МКТУ 8', desc: 'Орудия и инструменты ручные'},
  {number: 9, name: 'МКТУ 9', desc: 'Приборы и инструменты научные'},
  {number: 10, name: 'МКТУ 10', desc: 'Приборы и инструменты хирургические, медицинские, стоматологические и ветеринарные'},
  {
    number: 11,
    name: 'МКТУ 11',
    desc: 'Устройства и установки для освещения, отопления, охлаждения, получения пара, приготовления пищи, сушки, вентиляции, водоснабжения и санитарно-технические'
  },
  {number: 12, name: 'МКТУ 12', desc: 'Средства транспортные'},
  {number: 13, name: 'МКТУ 13', desc: 'Оружие огнестрельное'},
  {number: 14, name: 'МКТУ 14', desc: 'Металлы благородные и их сплавы'},
  {number: 15, name: 'МКТУ 15', desc: 'Инструменты музыкальные'},
  {number: 16, name: 'МКТУ 16', desc: 'Бумага, картон'},
  {
    number: 17,
    name: 'МКТУ 17',
    desc: 'Каучук, резина, гуттаперча, асбест, слюда необработанные и частично обработанные и заменители этих материалов'
  },
  {number: 18, name: 'МКТУ 18', desc: 'Кожа и имитация кожи'},
  {number: 19, name: 'МКТУ 19', desc: 'Материалы строительные неметаллические'},
  {number: 20, name: 'МКТУ 20', desc: 'Мебель, зеркала, обрамления для картин'},
  {number: 21, name: 'МКТУ 21', desc: 'Утварь и посуда домашняя и кухонная'},
  {number: 22, name: 'МКТУ 22', desc: 'Канаты, веревки, бечевки'},
  {number: 23, name: 'МКТУ 23', desc: 'Нити текстильные и пряжа'},
  {number: 24, name: 'МКТУ 24', desc: 'Текстиль и его заменители'},
  {number: 25, name: 'МКТУ 25', desc: 'Одежда, обувь, головные уборы'},
  {number: 26, name: 'МКТУ 26', desc: 'Кружева, шнурки и изделия вышитые, тесьма и ленты'},
  {number: 27, name: 'МКТУ 27', desc: 'Ковры, циновки, маты, линолеум и прочие покрытия для полов'},
  {number: 28, name: 'МКТУ 28', desc: 'Игры, игрушки'},
  {number: 29, name: 'МКТУ 29', desc: 'Мясо, рыба, птица и дичь'},
  {number: 30, name: 'МКТУ 30', desc: 'Продукты растительные пищевые, кроме овощей и фруктов'},
  {
    number: 31,
    name: 'МКТУ 31',
    desc: 'Продукты сельскохозяйственные, аквакультуры, садово-огородные и лесные, в сыром виде и необработанные'
  },
  {number: 32, name: 'МКТУ 32', desc: 'Напитки безалкогольные, а также пиво'},
  {number: 33, name: 'МКТУ 33', desc: 'Алкогольные напитки (за исключением пива)'},
  {number: 34, name: 'МКТУ 34', desc: 'Табак и предметы, используемые для курения'},
  {number: 35, name: 'МКТУ 35', desc: 'Реклама, менеджмент в сфере бизнеса'},
  {number: 36, name: 'МКТУ 36', desc: 'Услуги по денежно-финансовым операциям и услуги, связанные со всеми видами страховых контрактов'},
  {number: 37, name: 'МКТУ 37', desc: 'Услуги строительные'},
  {number: 38, name: 'МКТУ 38', desc: 'Услуги телекоммуникационные'},
  {number: 39, name: 'МКТУ 39', desc: 'Услуги, относящиеся к перевозке людей, животных или товаров'},
  {number: 40, name: 'МКТУ 40', desc: 'Обработка материалов, переработка мусора и отходов'},
  {
    number: 41,
    name: 'МКТУ 41',
    desc: 'Воспитание, образование, развлечения, организация спортивных и культурно-просветительных мероприятий.'
  },
  {number: 42, name: 'МКТУ 42', desc: 'Научные и технологические услуги'},
  {number: 43, name: 'МКТУ 43', desc: 'Услуги по обеспечению пищевыми продуктами и напитками, обеспечение временного проживания'},
  {number: 44, name: 'МКТУ 44', desc: 'Медицинский уход, альтернативная медицина, услуги в области гигиены и косметики'},
  {number: 45, name: 'МКТУ 45', desc: 'Услуги юридические, службы безопасности, услуги персональные и социальные'},
];
