import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();
  searchTerm: string;
  options = {
    style: 'appIpIdSearchPage',
    dynamicStylesForBrand: environment.SEARCH_STYLES,
  };
  withResults = false;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe((params: Params) => {
        if (params?.q) {
          this.searchTerm = params.q;
          console.warn('searchTerm: ', this.searchTerm);
        }
      });
  }

  ngOnInit(): void {
    this.withResults = false;

  }

  searchHappened(designation) {
    setTimeout(() => {this.withResults = true; }, 0);
    // Подмена адреса без перезагрузки, что-бы после поиска при перезагрузки страницы не сбрасывалось введенное значение
    this.location.go(`search?q=${designation}`);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

}
