export default [
  {
    id: 1,
    value: 'Программа',
    checked: false,
    index: 'rusw'
  },
  {
    id: 2,
    value: 'База данных',
    checked: false,
    index: 'rudb'
  },
];
