<div class="steps">
  <div *ngFor="let label of steps; let i = index" [ngClass]="{
    'steps__element': true,
    'steps__element--active': step === i,
    'steps__element--finished': step > i,
    'steps__element--line': i !== steps.length - 1
  }">
    <span class="steps__element-text">
      <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
        <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      {{label}}
    </span>
  </div>
  <div class="steps__mobile">Шаг {{step + 1}} из {{steps.length}}</div>
</div>
