import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../../app-search/src/app/services/search.service';
import { interval, Subject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../shared/services/http.service';
import { SharedService } from '../../shared/services/shared.service';
import { CasesService } from '../cases/cases.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

declare var window: any;

export interface IYandexResponseToken {
  id: string;
  confirmation: {
    confirmation_token: string
  };
}

const currentHost = `${window.location.protocol}//${window.location.host}`;

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  providers: [],
})
export class RequestComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();
  private readonly onDestroyTimer = new Subject<void>();

  // TODO: Пропускать шаг 1
  // nextStepForTest = true;
  nextStepForTest = false;

  brand = '';
  logo2path = '';
  optionsMktu = {
    onlyClassSelect: true,
    search: 'mktu',
    selectedMktu: []
  };
  idApplication;

  step = 5;
  imageText: string; // текстовое обозначение товарного знака
  ownerForm: FormGroup;
  formPay: FormGroup;
  owner; // владелец по ИНН/ОГРН
  ownerShortName = ''; // короткое наименование организации
  viewSelectContainer = false; // отображать выпадающий блок после ввода ИНН/ОГРН
  loadingOwner = false; // лоадер при получении владельца
  disabledInput = true; // заблокировать поля ввода владельца
  imageError: string; // ошибка загрузки картинки
  isImageLoading: boolean; // лоадер загрузки картинки
  cardImageBase64: string; // картинка в формате base64
  imageUrl; // url картинки
  ogrn; // ОГРН владельца
  OKVEDCodes = []; // оквэд коды (массив объектов): [{code: 12, description: "Описание"}, ...]
  OKVEDCodesShort = []; // оквэд кодв (массив чисел): [12, 34, 56, ...]
  MKTUCodesShort = []; // классы мкту (массив чисел)
  mktu = []; // классы мкту (массив объектов)
  employeeSelectClasses = false; // флаг - Подбор и проверка классов патентным поверенным
  rates = {min: null, opty: null, max: null}; // тарифы
  countRates; // количество тарифов
  duty: any; // пошлины
  sumBeforeDiscount = 0; // общая сумма (тариф + пошлины) до скидки
  paymentTab = 'cash'; // тип оплаты
  paymentSum; // сумма к оплате с учетом скидки
  draftID; // номер заявки (переход из черновика)
  draft; // данные из черновика
  step2ButtonClicked = false; // нажата ли кнопка перехода с 2 на 3 шаг
  loadindErrorStep2Send = false; // ошибка при переходе на 3 шаг
  tasks;
  userEmail; // email пользователя
  activeTariff: string; // id выбранного тарифа
  searchTerm;

  yaLastPaymentId: string;
  yaPaymentStatus: string;
  timerSubscription: Subscription;
  returnAfterPayment;
  payResponse: any;
  payError = {
    status: false,
    text: '',
  };
  payWidjetIsRender = false;

  constructor(
    private fb: FormBuilder,
    private searchService: SearchService,
    private router: Router,
    private httpService: HttpService,
    private http: HttpClient,
    private sharedApi: SharedService,
    private route: ActivatedRoute,
    private casesApi: CasesService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
    this.logo2path = environment.LOGO2_PATH;
    this.brand = environment.BRAND;
  }

  ngOnInit() {

    // TODO: for move on pay step
    // this.step = 4;
    // this.initYandexCheckout(100);

    this._initForm();
    if (!localStorage.getItem('currentUserIPID')) {
      this.router.navigate(['/login']);
    }

    if (this.nextStepForTest) {
      this.imitationFirstStep();
    }

    this.getUrlParam();
    this.userEmail = JSON.parse(localStorage.getItem('currentUserData')).email;

    this.route.paramMap
      .pipe(takeUntil(this.onDestroy))
      .subscribe((paramMap) => {
        this.imageText = paramMap.get('designation');
      });
  }

  // TODO: Имитация заполнения и прохода на второй шаг
  imitationFirstStep() {
    this.imageText = 'test';
    this.ownerForm.controls.TIN.setValue(7736207543);
    this.checkValidTIN();
    setTimeout(() => {
      this.setValueOwner();
      this.nextStep();
    }, 1000);
  }

  getUrlParam() {
    this.route.queryParams.subscribe((params) => {
      this.draftID = params.id;
      this.returnAfterPayment = params.returnAfterPayment;
      const lastPaymentId = params.t;
      if (lastPaymentId) {
        this.yaLastPaymentId = params.t;
      }
      if (this.returnAfterPayment) {
        const source = interval(1000 * 30);
        this.timerSubscription = source.pipe(takeUntil(this.onDestroyTimer)).subscribe(val => {
          this.testPayStatus();
        });
      }
      console.log(typeof this.returnAfterPayment);
    });
    if (this.draftID) {
      this.step = 0;
      this.selectCaseUnfinished(this.draftID);
    }
  }

  /**
   * получение данных из черновика
   * @param id: id заявки из черновика
   */
  selectCaseUnfinished(id: string): void {
    const params = {
      responseID: id
    };
    this.casesApi.fetchTasks(params).pipe(takeUntil(this.onDestroy))
      .subscribe(tasksData => {
        this.tasks = tasksData;
        this.draft = this.tasks.tasks.items.filter(i => i.active === true)[0];
        if (this.draft) {
          this.parseDraft();
        }
      });
  }

  /**
   * заполнение данных из черновика в заявку
   */
  parseDraft(): void {
    this.idApplication = this.draft.ID;
    this.imageText = this.draft.designationValue;
    this.imageUrl = this.draft.details.imgSrc;
    this.ownerForm.controls.head.setValue(this.draft.director);
    this.ownerForm.controls.TIN.setValue(this.draft.inn);
    this.ownerForm.controls.name.setValue(this.draft.info.find(item => item.title === 'Заявитель').text);
    this.ownerForm.controls.address.setValue(this.draft.legalAdress);
    this.ownerShortName = this.draft.info.find(item => item.title === 'Заявитель сокращенно').text;
    this.ogrn = this.draft.info.find(item => item.title === 'ОГРН').text;
    this.disabledInput = false;
    if (this.draft.lastStage === 1) {
      const query = {
        id: this.ownerForm.controls.TIN.value.toString(),
      };
      this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        if (data?.hits?.hits[0]?._source.OKVEDCodes) {
          this.OKVEDCodes = data.hits.hits[0]._source.OKVEDCodes;
          this.OKVEDCodesShort = [];
          this.OKVEDCodes.forEach(item => {
            this.OKVEDCodesShort.push(item.code);
          });
          this.getMktuByOkved('draft');
        }
        this.loadingOwner = false;
      });
    }
    if (this.draft.lastStage >= 2) {
      this.MKTUCodesShort = this.draft.info.find(item => item.title === 'Классы МКТУ').text.split(', ').map(item => +item);
      this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
      this.OKVEDCodes = this.draft.OKVED;
      this.employeeSelectClasses = this.draft.employeeSelectClasses;
      this.getRates();
    }
    if (this.draft.lastStage !== 1) {
      this.step = this.draft.lastStage + 1;
    }

    // if (this.step === 4) {
    //   this.initYandexCheckout(100);
    // }
  }

  /**
   * инициализация формы оплыты оффлайн
   */
  private _initForm(): void {
    this.ownerForm = this.fb.group({
      TIN: [null, [Validators.required]],
      name: [null, [Validators.required]],
      head: [null, [Validators.required]],
      address: [null, [Validators.required]]
    });
  }

  /**
   * загрузка картинки
   * @param fileInput: файл
   */
  fileChangeEvent(fileInput: any): any {
    this.isImageLoading = true;
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const maxSize = 2000000;
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (fileInput.target.files[0].size > maxSize) {
        this.imageError = 'Максимальный размер файла ' + maxSize / 1000000 + 'Mb';

        return false;
      }

      if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
        this.imageError = 'Недопустимый тип файла';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          this.cardImageBase64 = e.target.result;
          const imageBody = {
            body: {
              name: fileInput.target.files[0].name,
              image: this.cardImageBase64
            }
          };
          this.httpService.post({path: 'request/image/add', body: imageBody}).subscribe((data: any) => {
            if (data && data.path) {
              this.imageUrl = data.path;
            }
            this.isImageLoading = false;
          });

        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  /**
   * уделание картинки
   */
  removeImage(): void {
    (document.getElementById('file') as HTMLInputElement).value = '';
    this.imageError = null;
    this.cardImageBase64 = null;
    this.imageUrl = null;
  }

  /**
   * отметки о пройденных шагах
   * @param step: номер шага
   */
  getStepClass(step): string {
    if (this.step === step) {
      return 'steps__element--active';
    } else if (this.step > step) {
      return 'steps__element--finished';
    } else {
      return '';
    }
  }

  /**
   * если поле ИНН/ОГРН валидно - получать данные о правообладателе
   */
  checkValidTIN(): void {
    this.viewSelectContainer = false;
    const patternTIN = /^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$/g;
    if (patternTIN.test(this.ownerForm.controls.TIN.value)) {
      if (this.validateInn() || this.validateOgrn()) {
        this.loadingOwner = true;
        this.getOwnerData();
        this.disabledInput = false;
      }
    }
  }

  /**
   * получение данных о правообладателе по ИНН/ОГРН
   */
  getOwnerData(): void {
    if (!this.nextStepForTest) {
      const query = {
        id: this.ownerForm.controls.TIN.value.toString(),
      };
      this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        if (data?.hits?.hits[0]?._source) {
          this.owner = data.hits.hits[0]._source;
          if (this.owner.name) {
            this.ownerShortName = this.owner.name;
          }
          this.viewSelectContainer = true;
          this.ogrn = this.owner.PSRN.toString();
          if (this.owner.OKVEDCodes) {
            this.OKVEDCodes = this.owner.OKVEDCodes;
            this.OKVEDCodesShort = [];
            this.owner.OKVEDCodes.forEach(item => {
              this.OKVEDCodesShort.push(item.code);
            });
            this.getMktuByOkved();
          }
        }
        this.loadingOwner = false;
      });

    } else {
      // tslint:disable-next-line:max-line-length
      const data = {
        took: 1, timed_out: false, _shards: {total: 2, successful: 2, skipped: 0, failed: 0}, hits: {
          total: {value: 1, relation: 'eq'}, max_score: 15.90332, hits: [{
            _index: 'organizations', _type: '_doc', _id: '1027700229193', _score: 15.90332, _source: {
              address: '119021, МОСКВА ГОРОД, УЛИЦА ЛЬВА ТОЛСТОГО, 16',
              directors: [{
                recordID: 7177749824036,
                patronymic: 'ИГОРЕВНА',
                surname: 'БУНИНА',
                TIN: '773006366201',
                name: 'ЕЛЕНА',
                recordDate: '2017-12-28T00:00:00',
                postTitle: 'ГЕНЕРАЛЬНЫЙ ДИРЕКТОР'
              }],
              name: 'ООО "ЯНДЕКС"',
              TIN: '7736207543',
              fullName: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ЯНДЕКС"',
              PSRN: 1027700229193,
              OKVEDCodes: [{
                code: '62.01',
                description: 'Разработка компьютерного программного обеспечения'
              }, {
                code: '61.10',
                description: 'Деятельность в области связи на базе проводных технологий'
              }, {
                code: '61.10.9',
                description: 'Деятельность в области связи на базе проводных технологий прочая'
              }, {
                code: '62.02',
                description: 'Деятельность консультативная и работы в области компьютерных технологий'
              }, {
                code: '62.09',
                description: 'Деятельность, связанная с использованием вычислительной техники и информационных технологий, прочая'
              }, {
                code: '63.11',
                description: 'Деятельность по обработке данных, предоставление услуг по размещению информации и связанная с этим деятельность'
              }, {
                code: '63.11.1',
                description: 'Деятельность по созданию и использованию баз данных и информационных ресурсов'
              }, {
                code: '70.22',
                description: 'Консультирование по вопросам коммерческой деятельности и управления'
              }, {
                code: '71.20',
                description: 'Технические испытания, исследования, анализ и сертификация'
              }, {code: '71.20.8', description: 'Сертификация продукции, услуг и организаций'}, {
                code: '72.19',
                description: 'Научные исследования и разработки в области естественных и технических наук прочие'
              }, {
                code: '72.20',
                description: 'Научные исследования и разработки в области общественных и гуманитарных наук'
              }, {code: '73.11', description: 'Деятельность рекламных агентств'}, {
                code: '73.20',
                description: 'Исследование конъюнктуры рынка и изучение общественного мнения'
              }, {code: '73.20.1', description: 'Исследование конъюнктуры рынка'}, {
                code: '73.20.2',
                description: 'Деятельность по изучению общественного мнения'
              }, {
                code: '82.99',
                description: 'Деятельность по предоставлению прочих вспомогательных услуг для бизнеса, не включенная в другие группировки'
              }, {code: '94.11', description: 'Деятельность коммерческих и предпринимательских членских организаций'}]
            }
          }]
        }
      };
      // TODO: for quik testing
      if (data?.hits?.hits[0]?._source) {
        this.owner = data.hits.hits[0]._source;
        this.viewSelectContainer = true;
        if (this.owner.name) {
          this.ownerShortName = this.owner.name;
        }
        if (this.owner.OKVEDCodes) {
          this.OKVEDCodes = this.owner.OKVEDCodes;
          this.OKVEDCodesShort = [];
          this.owner.OKVEDCodes.forEach(item => {
            this.OKVEDCodesShort.push(item.code);
          });
          this.getMktuByOkved();
        }
      }
      this.loadingOwner = false;
    }
  }

  /**
   * проверка - введен ли в поле ИНН/ОГРН валидный ИНН
   */
  validateInn(): boolean {
    const inn = this.ownerForm.controls.TIN.value?.toString();
    const check = (num) => {
      const n = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0].slice(-num.length);
      let summ = 0;
      for (let i = 0; i < num.length; i++) {
        summ += num[i] * n[i];
      }

      let control = summ % 11;
      if (control > 9) {
        control = control % 10;
      }

      return control;
    };
    if (inn?.length === 10) {
      // tslint:disable-next-line:triple-equals
      return check(inn) == inn.slice(-1);
    } else if (inn?.length === 12) {
      return (
        // tslint:disable-next-line:triple-equals
        check(inn.slice(0, 11)) == inn.slice(10, -1) &&
        // tslint:disable-next-line:triple-equals
        check(inn) == inn.slice(-1)
      );
    } else {
      return false;
    }
  }

  /**
   * проверка - введен ли в поле ИНН/ОГРН валидный ОГРН
   */
  validateOgrn(): boolean {
    const ogrn = this.ownerForm.controls.TIN.value?.toString();
    if (ogrn) {
      const n13 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 11).toString().slice(-1), 10);
      const n15 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 13).toString().slice(-1), 10);
      return n13 === parseInt(ogrn[12], 10) || n15 === parseInt(ogrn[14], 10);
    }
  }

  /**
   * заполнение данных о правообладателе
   */
  setValueOwner(): void {
    this.ownerForm.controls.name.setValue(this.owner.fullName);
    this.ownerForm.controls.address.setValue(this.owner.address);
    if (this.owner.directors && this.owner.directors[0]) {
      const director = this.owner.directors[0];
      this.ownerForm.controls.head.setValue(director.surname + ' ' + director.name + ' ' + director.patronymic);
    } else {
      this.ownerForm.controls.head.setValue(this.owner.fullName);
    }
    this.viewSelectContainer = false;
  }

  /**
   * проверка валидности формы на 1 шаге
   */
  checkValidForm(): boolean {
    return (this.validateInn() || this.validateOgrn()) && this.ownerForm.status === 'VALID' && (!!this.imageText || !!this.cardImageBase64);
  }

  /**
   * получение классов МКТУ по ОКВЭД
   * @param draft: черновик (если переход из черновика)
   */
  getMktuByOkved(draft?): void {
    const query = {
      eaNumber: this.OKVEDCodesShort,
    };

    if (!this.nextStepForTest) {
      this.mktu = [];
      this.MKTUCodesShort = [];
      this.searchService.getMktyByOkved(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        if (data?.hits?.hits && data.hits.hits.length > 0) {
          data.hits.hits.forEach(item => {
            if (item._source && item._source.codeMKTU) {
              this.MKTUCodesShort.push(item._source.codeMKTU);
            }
          });
          this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
          if (draft) {
            this.step = 2;
          }
        }
      });

    } else {
      // tslint:disable-next-line:max-line-length
      const data = {
        took: 0, timed_out: false, _shards: {total: 1, successful: 1, skipped: 0, failed: 0}, hits: {
          total: {value: 30, relation: 'eq'},
          max_score: 8.368539,
          hits: [{
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '70.22_35',
            _score: 8.368539,
            _source: {codeMKTU: 35}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '71.20_42',
            _score: 8.368539,
            _source: {codeMKTU: 42}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '71.20.8_42',
            _score: 8.368539,
            _source: {codeMKTU: 42}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '73.20.2_35',
            _score: 8.368539,
            _source: {codeMKTU: 35}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '73.11_35',
            _score: 8.368539,
            _source: {codeMKTU: 35}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '72.19_42',
            _score: 8.368539,
            _source: {codeMKTU: 42}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '72.20_42',
            _score: 8.368539,
            _source: {codeMKTU: 42}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '73.20_35',
            _score: 8.368539,
            _source: {codeMKTU: 35}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '73.20.1_35',
            _score: 8.368539,
            _source: {codeMKTU: 35}
          }, {
            _index: 'connection_okved_mktu',
            _type: '_doc',
            _id: '61.10_38',
            _score: 8.368539,
            _source: {codeMKTU: 38}
          }]
        }
      };
      if (data?.hits?.hits && data.hits.hits.length > 0) {
        data.hits.hits.forEach(item => {
          if (item._source && item._source.codeMKTU) {
            this.MKTUCodesShort.push(item._source.codeMKTU);
          }
        });
      }


    }
  }

  /**
   * событие добавления класса МКТУ
   * @param selectedClass: объект добавляемого класса
   */
  eventSelectedClass(selectedClass): void {
    // TODO: Сохранять (перемапить) для дальнейшего использования в заявке
    if (this.mktu.length) {
      this.mktu.forEach((item, index) => {
        if (!item.selected) {
          this.mktu.splice(index, 1);
        }
      });
      let hasClass;
      this.mktu.forEach(item => {
        if (item.number === selectedClass.number) {
          hasClass = true;
        }
      });
      if (!hasClass) {
        this.mktu.push(selectedClass);
      }
    } else {
      this.mktu.push(selectedClass);
    }
    this.MKTUCodesShort = [];
    this.mktu.forEach(item => {
      this.MKTUCodesShort.push(item.number);
    });
  }

  /**
   * событие удаления класса МКТУ
   * @param deletedClass: объект удаляемого класса
   */
  eventDeletedClass(deletedClass): void {
    // TODO: логика удаления
    let deleteIndex;
    this.mktu.forEach((item, index) => {
      if (item.number === deletedClass.number) {
        deleteIndex = index;
      }
    });
    if (deleteIndex || deleteIndex === 0) {
      this.mktu.splice(deleteIndex, 1);
    }
    this.MKTUCodesShort = [];
    this.mktu.forEach(item => {
      this.MKTUCodesShort.push(item.number);
    });
  }

  /**
   * получение короткого описание КВЭД
   * @param okved: описание ОКВЭД класса
   */
  getShortOkved(okved): string {
    if (okved.length > 40) {
      return okved.slice(0, 40) + '...';
    } else {
      return okved;
    }
  }

  /**
   * отправка данных с 1 шага
   */
  getFirstJSON(): void {
    const firstJSON = {
      responseID: this.idApplication ? this.idApplication.toString() : '',
      stage: 1,
      type: 'trademark',
      designation: this.imageText,
      imgURL: this.imageUrl,
      owner: {
        inn: this.validateInn() ? this.ownerForm.controls.TIN.value?.toString() : (this.owner ? this.owner.TIN.toString() : ''),
        ogrn: this.validateOgrn() ? this.ownerForm.controls.TIN.value?.toString() : this.ogrn,
        name: this.ownerForm.controls.name.value,
        shortName: this.ownerShortName,
        director: this.ownerForm.controls.head.value,
        address: this.ownerForm.controls.address.value
      }
    };

    if (!this.nextStepForTest) {
      this.httpService.post({path: 'request/load', body: firstJSON}).subscribe((data: any) => {
        if (data?.responseID) {
          this.idApplication = data.responseID;

          // добавление id заявки в get параметры
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              id: this.idApplication
            },
            queryParamsHandling: 'merge',
          });
          this.getRates();
        }
      });
    } else {
      const data = {responseID: '000000020'};
      this.idApplication = data.responseID;
    }

  }

  /**
   * отправка данных со 2 шага
   */
  getSecondJSON(): void {
    const secondJSON = {
      responseID: this.idApplication,
      stage: 2,
      okved: this.OKVEDCodes,
      mktu: this.mktu,
      // employeeSelectClasses: this.employeeSelectClasses,
    };
    this.httpService.post({path: 'request/load', body: secondJSON}).subscribe((data: any) => {
      if (data) {
        this.getRates();
      }
    });
  }

  /**
   * получение тарифов и пошлин
   */
  getRates(): void {
    this.httpService.get({
      path: 'request/tariff',
      params: {responseID: this.idApplication}
    }).subscribe((data: any) => {
      if (data?.tariff && data.duty) {
        this.countRates = data.tariff.length;
        data.tariff.forEach((item, index) => {
          if (index === 0) {
            this.rates.min = item;
          }
          if (index === 1) {
            this.rates.opty = item;
          }
          if (index === 2) {
            this.rates.max = item;
          }
          if (item.default) {
            this.activeTariff = item.id;
          }
        });
        this.duty = data.duty;
        if (this.draft) {
          switch (this.draft.tariff) {
            case 'Максимальный':
              this.paymentSum = +this.rates.max.sumDiscount + +this.duty.sumDiscount;
              this.activeTariff = this.rates.max.id;
              this.sumBeforeDiscount = +this.rates.max.sum + +this.duty.sum;
              break;
            case 'Оптимальный':
              this.paymentSum = +this.rates.opty.sumDiscount + +this.duty.sumDiscount;
              this.activeTariff = this.rates.opty.id;
              this.sumBeforeDiscount = +this.rates.opty.sum + +this.duty.sum;
              break;
            case 'Минимальный':
              this.paymentSum = +this.rates.min.sumDiscount + +this.duty.sumDiscount;
              this.activeTariff = this.rates.min.id;
              this.sumBeforeDiscount = +this.rates.min.sum + +this.duty.sum;
              break;
            default :
              this.activeTariff = this.rates.opty.id;
              this.paymentSum = +this.rates.opty.sumDiscount + +this.duty.sumDiscount;
              this.sumBeforeDiscount = +this.rates.opty.sum + +this.duty.sum;
          }
          if (this.draft.lastStage === 3) {
            this.preInitYandexPay();
          }
        } else {
          this.activeTariff = this.rates.opty.id;
          this.paymentSum = +this.rates.opty.sumDiscount + +this.duty.sumDiscount;
          this.sumBeforeDiscount = +this.rates.opty.sum + +this.duty.sum;
        }
      }
    });
  }

  disableCases(show) {
    this.sharedApi.hideCases.next(show);
    this.router.navigate(['/request']);
    console.log(show);
    console.log('cases disable');
  }

  /**
   * событие перехода на следующий шаг
   */
  nextStep(): void {
    this.optionsMktu.selectedMktu = this.mktu.map(i => i.number);
    ++this.step;
    switch (this.step) {
      case 1:
        break;
      case 2:
        this.getFirstJSON();
        // only unique codes in array [35, 42, 38, 35, 42, 38, 38] => [35, 42, 38]
        this.optionsMktu.selectedMktu = [...new Set(this.MKTUCodesShort)];
        break;
      case 3:
        break;
      case 4:
        this.sendTariff();
        this.preInitYandexPay();
        this._initFormPay();
        break;
      case 5:
        break;
    }
  }

  /**
   * событие перехода на предыдущий шаг
   */
  backStep(): void {
    --this.step;
  }

  /**
   * переход со 2 на 3 шаг возможен только когда есть номер заявки и тарифы
   * иначе лоадер
   */
  step2to3Click(): void {
    this.rates.min = null;
    this.rates.opty = null;
    this.rates.max = null;
    let secondJSONSended = false;
    this.step2ButtonClicked = true;
    this.loadindErrorStep2Send = true;
    const intervalId = setInterval(() => {
      if (this.idApplication && !secondJSONSended) {
        secondJSONSended = true;
        this.getSecondJSON();
      }
      if (this.rates.min || this.rates.opty || this.rates.max) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        this.step2ButtonClicked = false;
        this.loadindErrorStep2Send = false;
        this.nextStep();
      }
    }, 1000);
    const timeoutId = setTimeout(() => {
      this.loadindErrorStep2Send = false;
    }, 10000);
  }

  /**
   * склоние слова 'классы'
   * @param n: количество классов
   */
  declOfNum(n: number): string {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
      return 'классов';
    }
    if (n1 > 1 && n1 < 5) {
      return 'класса';
    }
    if (n1 === 1) {
      return 'класс';
    }
    return 'классов';
  }

  /**
   * событие выбора тарифа
   * @param tariff: объект выбранного тарифа
   */
  selectTariff(tariff): void {
    this.paymentSum = +tariff.sumDiscount + +this.duty.sumDiscount;
    this.activeTariff = tariff.id;
    this.sumBeforeDiscount = +tariff.sum + +this.duty.sumDiscount;
  }

  /**
   * отправка выбранного тарифа в юрайт
   */
  sendTariff(): void {
    const tariffBody = {
      responseID: this.idApplication,
      tariffID: this.activeTariff,
      stage: 3,
    };
    this.httpService.post({path: 'request/load', body: tariffBody}).subscribe((data: any) => {
    });
  }

  /**
   * переключение панели способа оплаты
   * @param type: выбранный тип оплаты
   */
  switchPaymentTab(type: string): void {
    if (type === this.paymentTab) {
      return;
    }
    if (type === 'cash') {
      this._initFormPay();
    } else if (type === 'online') {
      this.preInitYandexPay();
    }
    this.paymentTab = type;
  }

  /**
   * инициализация формы оффлайн оплаты
   */
  private _initFormPay(): void {
    this.formPay = this.fb.group({
      INN: [
        this.validateInn() ? this.ownerForm.controls.TIN.value?.toString() : (this.owner ? this.owner.TIN.toString() : ''),
        [Validators.required]],
      OGRN: [
        this.validateOgrn() ? this.ownerForm.controls.TIN.value?.toString() : this.ogrn,
        [Validators.required]],
      name: [this.ownerForm.controls.name.value, [Validators.required]],
      address: [this.ownerForm.controls.address.value, [Validators.required]],
      sum: [(+this.paymentSum).toLocaleString('ru') + ' ₽', [Validators.required]],
    });
  }

  /**
   * отправка данных для оффайн оплаты
   */
  sendPayJSON(type: string, yandexResponse?: any): void {
    let userID;
    if (localStorage.getItem('currentUserData')) {
      userID = JSON.parse(localStorage.getItem('currentUserData')).userID;
    }

    let reqData;
    if (type === 'cash') {
      reqData = {
        stage: 4,
        type: 'cash',
        userId: userID,
        responseId: this.idApplication,
        inn: this.formPay.controls.INN.value.toString(),
        ogrn: this.formPay.controls.OGRN.value.toString(),
        name: this.formPay.controls.name.value.toString(),
        address: this.formPay.controls.address.value.toString(),
        sum: this.formPay.controls.sum.value.toString(),
      };
    } else if (type === 'online') {
      reqData = {
        stage: 4,
        type: 'online',
        userId: userID,
        responseId: this.idApplication,
        yandexResponse,
      };
    }

    this.httpService.post({path: 'request/load', body: reqData}).subscribe((data: any) => {
      console.log(data);
      this.nextStep();
      localStorage.setItem('selectCaseUnfinished', this.idApplication);
    });
  }

  checkPayRender() {
    setTimeout(() => {
      const el = document.getElementsByTagName('iframe');
      console.log(el[0]);
      if (el[0]) {
        this.payWidjetIsRender = true;
      } else {
        this.payWidjetIsRender = false;
        this.checkPayRender();
      }
    }, 300);
  }

  preInitYandexPay() {
    if (this.returnAfterPayment && this.returnAfterPayment === 'true') {
      this.testPayStatus();
    } else {
      this.payError.status = false;
      this.payError.text = '';
      this.initYandexCheckout();
    }
  }

  initYandexCheckout() {
    // this.http.get(`http://10.1.33.225:9999/api/yandex_payment/create?sum=${sum}&responseID=${this.idApplication}`).subscribe((data: IYandexResponseToken) => {
    this.httpService.get(
      {
        path: `yandex_payment/create?responseID=${this.idApplication}`,
        subjectType: 'recover password'
      }
    ).subscribe((data: IYandexResponseToken) => {
      if (data?.confirmation?.confirmation_token && data?.id) {
        console.log('payment_id and confirmation_token', data);
        this.yaLastPaymentId = data.id;

        const checkout = new window.YandexCheckout({
          confirmation_token: data.confirmation.confirmation_token,
          return_url: `${currentHost}/request?id=${this.idApplication}&returnAfterPayment=true&t=${this.yaLastPaymentId}`,
          error_callback(error) {
            console.error('ya pay err', error);
            // Обработка ошибок инициализации
          }
        });

        // Отображение платежной формы в контейнере
        checkout.render('payment-form');
        this.checkPayRender();

      } else {
        console.error('В ответе нет confirmation_token и/или payment_id');
      }
    });
  }

  testPayStatus() {
    this.yaPaymentStatus = null;

    if (this.yaLastPaymentId) {
      // this.http.get(`http://10.1.33.225:9999/api/yandex_payment/check?payment_id=${this.yaLastPaymentId}`).subscribe((data: any) => {
      this.httpService.get(
        {
          path: `yandex_payment/check?payment_id=${this.yaLastPaymentId}&responseID=${this.idApplication}`,
          subjectType: 'recover password'
        }
      ).subscribe((data: any) => {
        console.log('getstatus ya pay', data);
        this.payResponse = data;
        if (this.payResponse?.status === 'succeeded') {
          this.sendPayJSON('online', data);
          this.onDestroyTimer.next();
        }
        if (this.payResponse?.status === 'canceled') {
          this.payError.status = true;
          switch (this.payResponse?.cancellation_details?.reason) {
            case '3d_secure_failed':
              this.payError.text = `Не пройдена аутентификация по 3-D Secure. <br class="hide-up-to-md">
            Вам следует повторить платеж, обратиться в банк за уточнениями или использовать другое платежное средство.`;
              break;
            case 'card_expired':
              this.payError.text = `Истек срок действия банковской карты. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'fraud_suspected':
              this.payError.text = `Платеж заблокирован из-за подозрения в мошенничестве. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'insufficient_funds':
              this.payError.text = `Не хватает денег для оплаты. <br class="hide-up-to-md">
            Вам следует пополнить баланс или использовать другое платежное средство.`;
              break;
            case 'invalid_card_number':
              this.payError.text = `Неправильно указан номер карты. <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'invalid_csc':
              this.payError.text = `Неправильно указан код CVV2 (CVC2, CID). <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'payment_method_restricted':
              this.payError.text = `Операции данным платежным средством заблокированы. <br class="hide-up-to-md">
            Вам следует обратиться в организацию, выпустившую платежное средство.`;
              break;
            default:
              this.payError.text = `Произошла ошибка при оплате. <br class="hide-up-to-md">
            Проверьте правильность введенных данных или срок действия карты.`;
          }
          this.onDestroyTimer.next();
        }

        this.yaPaymentStatus = data;
      });
    }
  }

  repeatPayment() {
    this.yaLastPaymentId = null;
    this.payError = {
      status: false,
      text: '',
    };

    // TODO: восстановить url, очистить get params

    this.initYandexCheckout();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  public sendIntercomEvent(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.GOTO_ORDER });
  }
}
