import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import { CasesItemComponent } from './cases-item/cases-item.component';

const systemRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('@web/features/cases/cases.module').then(m => m.CasesModule),
  },
  {
    path: `${APP_ROUTES_NAMES.CASES_ITEM}/${APP_ROUTES_NAMES.ID}`,
    component: CasesItemComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(systemRoutes)],
  exports: [RouterModule]
})
export class CasesRoutingModule {
}

