import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SsoAuthService } from '../../../shared/services/sso-auth.service';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ILogin } from '../../../shared/models/main.interfaces';
import { Store } from '@ngxs/store';
import { AuthActions } from '@web/core/states/auth.actions';
import { UserLoginInterface } from '@web/core/models/user.interface';

@Component({
  selector: 'app-sso-auth',
  templateUrl: './sso-auth.component.html',
  styleUrls: ['./sso-auth.component.scss'],
})
export class SsoAuthComponent implements OnInit, OnDestroy {
  brand;
  code;
  testBoolean = true;
  private readonly onDestroy = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private sso: SsoAuthService,
    private router: Router,
    private store: Store
  ) {
    this.brand = environment.BRAND;
  }

  ngOnInit(): void {
    if (this.testBoolean) {
      this.route.queryParams.subscribe((param) => {
        if (param.code) {
          this.code = param.code;
        }
      });
    }
    const params = new HttpParams().set('brand', this.brand);
    const body = {
      code: this.code,
    };
    this.sso
      .ssoLogin(body, params)
      .pipe(
        takeUntil(this.onDestroy),
        catchError((e) => {
          if (e.error) {
            console.log(e.error);
          }
          return e;
        })
      )
      .subscribe((data: UserLoginInterface) => {
        this.store
          .dispatch(new AuthActions.SetAuthorized(data))
          .subscribe(() => {
            this.router.navigate(['/index']);
          });
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
