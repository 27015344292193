import { Injectable } from '@angular/core';
import { HttpService } from '../../app-ipid/src/app/shared/services/http.service';
import { IResponse } from '../../app-ipid/src/app/shared/models/main.interfaces';

const path = 'user';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {


  constructor(private http: HttpService) { }

  getCode(params) {
    return this.http.get<IResponse>({path: `${path}/validation-code/send`, subjectType: 'Get code', params});
  }

  checkCode(body, params) {
    return this.http.post<IResponse>({path: `${path}/validation-code/check`, body: {body}, subjectType: 'check code', params});
  }

  registerUser(body, params) {
    return this.http.post<IResponse>({path: `${path}/registration`, body: {body}, subjectType: 'register request', params});
  }

  getInviteUser(params) {
    return this.http.get<IResponse>({path: `invitations/detail`, subjectType: 'Get invite user', params});
  }
}
