import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-item-events',
  templateUrl: './item-events.component.html',
  styleUrls: ['../cases-item.component.scss']
})
export class ItemEventsComponent implements OnInit, OnDestroy {
  // вкладки
  events = true;
  docs = false;
  info = false;

  showMore = false;
  showServicesList = false;
  open = false;
  eventsLength = 3;
  cardShown = '';

  testData = [
    {
      expertName: 'Смирнов Егор',
      title: 'Добавление документов',
      date: '01.07.2020',
      time: '12:32',
      type: 1,
      eventID: 1,

    },
    {
      expertName: 'ИП Константинопольский К.А',
      title: 'Документы',
      type: 2,
      date: '01.09.2020',
      time: '15:42',
      eventID: 2,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Получено решение арбитражного суда. Иск удовлетворен частично.',
      type: 3,
      date: '01.09.2020',
      time: '15:42',
      eventID: 3,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Создана и оплачена заявка на претензионную работу',
      type: 4,
      date: '01.09.2020',
      time: '15:42',
      eventID: 4,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Создана заявка на претензионную работу',
      type: 5,
      date: '01.09.2020',
      time: '15:42',
      eventID: 5,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Оплачен тариф «Все включено» для взыскания долга в судебном порядке',
      type: 6,
      date: '01.09.2020',
      time: '15:42',
      eventID: 6,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Проведено основное судебное заседание. Иск удовлетворен полностью.',
      type: 7,
      date: '01.09.2020',
      time: '15:42',
      eventID: 7,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Истек срок ожидания ответа на претензию',
      type: 8,
      date: '01.09.2020',
      time: '15:42',
      eventID: 8,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Добавлена доверенность для подписания',
      type: 9,
      date: '01.09.2020',
      time: '15:42',
      eventID: 9,
    },
    {
      company: 'ИП Константинопольский К.А',
      title: 'Мы подготовили претензию',
      type: 10,
      date: '01.09.2020',
      time: '15:42',
      eventID: 10,
    },
    {
      expertName: 'Авоськина Регина',
      title: 'Претензия удволетворена с условием',
      type: 11,
      date: '01.09.2020',
      time: '15:42',
      eventID: 11,
    },
  ];
  showLast = true;

  @Input() $events;

  constructor() {
  }

  ngOnInit(): void {
    this.$events.map(i => i.last = false);
    this.testData = this.$events.reverse();
    console.log(this.testData, 'events from child');
  }

  showCard(id) {
    this.cardShown = id;
  }

  hideCard() {
    this.showLast = false;
    this.cardShown = null;
  }

  ngOnDestroy() {
    this.testData = this.$events.reverse();
  }

}
