import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ILvFilterDetail } from '../../../../../../app-ipid/src/app/shared/components/list-view/lv-filter/lv-filter.component';
import { Store } from '@ngxs/store';
import { SearchActions } from '@web/features/corp/corp-search/states/search.actions';

@Component({
  selector: 'app-corp-search-legalnames',
  templateUrl: './corp-search-legalnames.component.html',
  styleUrls: ['./corp-search-legalnames.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorpSearchLegalnamesComponent implements OnInit, OnChanges {

  private _items: any[];

  @Input() set items(items: any[]) {
    this._items = items;
    this.filters.classes.options = this.filterClassesOptions;
  }
  @Input() countriesDropdown?: boolean;
  @Input() totalCount: number;
  @Input() itemsPerPage = 10;
  @Input() currentPage: number;
  @Output() changePage = new EventEmitter<any>();

  get items() {
    return this._items;
  }

  @Output() select = new EventEmitter<any[]>();

  page = 1;
  selectedLegal = null;

  constructor(
    private store: Store
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.items?.length) {
      this.select.emit(this.items.filter(i => i.checked));
    }
  }

  filters = {

    name: {
      value: '',
      fn: row => {
        const {value} = this.filters.name;
        if (!value) {
          return true;
        }

        return this.getRowName(row).toLowerCase().includes(value.toLowerCase());
      }
    },


    classes: {
      options: [],
      value: [],
      fn: (row: any) => {
        const {value} = this.filters.classes;
        if (!value.length) {
          return true;
        }

        if (!row._source.OKVEDCodes) {
          return false;
        }
        return row._source.OKVEDCodes.some(i => value.includes(i.code));
      }
    },

    riskLevel: {
      options: [
        {id: 'High', label: 'Высокая'},
        {id: 'Medium', label: 'Средняя'},
        {id: 'None', label: 'Низкая'},
      ],
      value: [],
      fn: (row) => {
        const {value} = this.filters.riskLevel;
        if (!value.length) {
          return true;
        }

        let risk = row._risk ?? 'None';
        return value.includes(risk);
      },
    },

    registration_date: {
      options: [], // dynamic
      value: []
    },

  };


  private get filterClassesOptions() {
    const okvedCache = new Map<string, any>();

    for (const row of this.items) {
      if (row._source.OKVEDCodes) {
        row._source.OKVEDCodes.forEach(i => okvedCache.set(i.code, i));
      }

      if (okvedCache.size >= 20) {
        break;
      }
    }

    // показываем только первые 20 оквэдов
    return Array.from(okvedCache)
      .slice(0, 20)
      .map(([code, {description}]) => ({id: code, label: `${code} - ${description}`}));
  }

  onApplyFilters(allFilters: Array<{ filter: ILvFilterDetail<any>, value: any }>) {
    for (const item of allFilters) {
      this.filters[item.filter.name].value = item.value;
    }

    this.page = 1;
  }

  setPage($event) {
    $event.type = 'legalNamesPagination';
    this.changePage.emit($event);
  }

  selectLegal(item) {
    this.selectedLegal = item;
    document.body.style.overflow = 'hidden';
  }

  onCloseLegalModal() {
    this.selectedLegal = null;
    document.body.style.overflow = 'visible';
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }


  toggleCheck(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.store.dispatch(new SearchActions.CheckObject(item));

    this.select.emit(this.items.filter(i => i.checked));
  }

  getRegionName(row: any) {
    const {addressHistory} = row._source;
    if (!addressHistory) {
      return;
    }

    const item = addressHistory[addressHistory.length - 1];

    return item.regionName + ' ' + item.regionType;
  }

  getRowName(row) {
    return row._index === 'organizations' ? row._source.name : row._source.fullName;
  }

}
