import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SearchService } from '../../../../../../app-search/src/app/services/search.service';
import { nextTick } from '../../../shared/utils/next-tick';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.scss']
})
export class AddOrganizationComponent implements OnInit, OnDestroy {

  addOrg: FormGroup;

  // Отображение страницы с подтверждением
  addOrganization = false;
  organizationsFromSearch = [];
  selectedOrganization;
  success = true;
  isLoading = false;
  phone = '';
  private readonly onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private profileApi: UserProfileService,
    private router: Router,
    private searchService: SearchService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.addOrg = this.fb.group({
      query: ['', [Validators.required]],
      INN: ['', [Validators.required]],
      OGRN: ['', [Validators.required]],
      name: ['', [Validators.required]],
      director: ['', [Validators.required]],
      date: ['', [Validators.required]],
      region: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
  }

  addingOrganization() {
    this.isLoading = true;
    const body = {
      organizations: [
        {
          name: this.addOrg.value.name,
          TIN: this.addOrg.value.INN,
          PSRN: this.addOrg.value.OGRN,
          phone: this.phone,
          email: this.addOrg.value.email,
          shortName: this.selectedOrganization._source.name
        },
      ]
    };
    this.profileApi.createOrganizations(body).pipe(takeUntil(this.onDestroy)).subscribe(data => {
      this.isLoading = false;
      if (data.error.length) {
        this.success = false;
        this.addOrganization = true;
        console.log(data.error);
      } else {
        this.success = true;
        this.addOrganization = true;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  inputChange(text) {
    this.selectedOrganization = undefined;
    this.organizationsFromSearch = [];
    if (text.length > 2) {
      this.getFullOrganization(text);
    }
  }

  onInputPhone(e) {
    nextTick(() => {
      this.validPhoneNumber(this.phone);
      let phone = this.phone.slice(2).replace(/\D+/, '');
      if (phone.length !== 10) {
        phone = phone.slice(0, 10);
      }
      phone = '+7' + phone;
      console.log('ohine', phone, this.phone);
      if (phone !== this.phone) {
        this.phone = phone;
      }

      this.validPhoneNumber(this.phone);
    });
  }


  getFullOrganization(id) {
    const query = {
      id,
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits.length && id === this.addOrg.value.query) {
        this.organizationsFromSearch = data.hits.hits.slice(0, 3);
      }
    });
  }

  selectingOrg(organization) {
    console.log(123777, organization);
    this.selectedOrganization = organization;
    this.organizationsFromSearch = [];
    this.addOrg.controls.name.setValue(organization._source.fullName);
    let director;
    if (organization._source.directors && organization._source.directors.length) {
      director = organization._source.directors[0].surname + ' ' + organization._source.directors[0].name;
      if (organization._source.directors[0].patronymic) {
        director = director + ' ' + organization._source.directors[0].patronymic;
      }
    } else {
      director = organization._source.fullName;
    }
    this.addOrg.controls.director.setValue(director);
    this.addOrg.controls.OGRN.setValue(organization._source.PSRN);
    this.addOrg.controls.INN.setValue(organization._source.TIN);
    const date = new Date(organization._source.dateOfPSRN);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateResult = (day) + '.' + (month) + '.' + date.getFullYear();
    this.addOrg.controls.date.setValue(dateResult);
    const region = organization._source.addressHistory[0].regionName + ' ' + organization._source.addressHistory[0].regionType;
    this.addOrg.controls.region.setValue(region);
  }

  checkInvalidForm() {
    return !this.selectedOrganization || !this.addOrg.value.phone || !this.addOrg.value.email || !this.validateEmail(this.addOrg.value.email) || !this.validPhoneNumber(this.addOrg.value.phone);
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validPhoneNumber(phone) {
    const re = new RegExp('^((\\+7|7|8)+([0-9]){10})$');
    // const re = new RegExp('^(\\+7|7|8)?[\\s\\-]?\\(?[3489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$');
    if (re.test(phone)) {
      return phone.toString().length === 10 || phone.toString().length === 11 || phone.toString().length === 12;
    } else {
      return false;
    }
  }

  showPhoneError(): boolean {
    return (!this.validPhoneNumber(this.addOrg.value.phone) &&
      this.addOrg.controls.phone.touched);
  }

  showEmailError(): boolean {
    return (!this.validateEmail(this.addOrg.value.email) &&
      this.addOrg.controls.email.touched);
  }

  backClicked() {
    this.location.back();
  }
}
