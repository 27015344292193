import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ISearchHistoryDetail, ISearchHistoryPayload } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { DataService } from '../../../../../app-ipid/src/app/shared/services/api.service';
import { CorpSearchSaveReportService } from './corp-search-save-report.service';

@Injectable({
  providedIn: 'root'
})
export class CorpSearchService {

  public searchTypeOptions = [
    {id: 'name', label: 'обозначению', accept: false},
    {id: 'image', label: 'изображению', accept: false},
  ];

  public searchType = 'trademarks';
  public placeholder;

  constructor(
    private corpSearchSaveService: CorpSearchSaveReportService,
    private apiService: DataService, private router: Router) {
  }

  public isLoadingSearchHistory = false;
  public searchHistoryItem: ISearchHistoryDetail;
  public searchHistoryAction: 'repeat' | 'show';

  public loadBySearchID(params: ISearchHistoryPayload, action: 'repeat' | 'show'): void {
    this.isLoadingSearchHistory = true;
    this.searchHistoryAction = action;
    this.corpSearchSaveService.setSearchID(params.searchID);
    this.apiService.getSearchDetailById(params).subscribe(data => {
      this.isLoadingSearchHistory = false;
      this.searchHistoryItem = data;
    });
  }

  public changeType(type: string): void {
    if (type !== this.searchType) {
      this.reset();
    }

    if (type && this.searchTypeOptions.find(i => i.id === type)) {
      this.searchType = type;
    }
  }

  public changeSearchType(id: string): void {
    this.router.navigate(['search', id]);
  }

  public reset() {
    // this.searchType = 'name';
    this.resetSearchHistory();
  }

  public resetSearchHistory(): void {
    this.isLoadingSearchHistory = false;
    this.searchHistoryItem = null;
    this.searchHistoryAction = null;
    this.corpSearchSaveService.reset();
  }

  getExternalUrl(index): string {
    switch (index._index) {
      case 'rutm':
      case 'wktm':
      case 'rugp':
        return `https://new.fips.ru/registers-doc-view/fips_servlet?DB=${index._index}&DocNumber=${index._source.registrationString}`;
      case 'rutmap':
      case 'rugpap':
        return `https://new.fips.ru/registers-doc-view/fips_servlet?DB=${index._index}&DocNumber=${index._source.applicationString}`;
      case 'wotm':
        return `https://www3.wipo.int/madrid/monitor/en/showData.jsp?ID=ROM.${index._source.registrationString}`;
      case 'rupat':
        return `https://new.fips.ru/registers-doc-view/fips_servlet?DB=RUPAT&DocNumber=${index._id}&TypeFile=html`;
      case 'ruum':
        return `https://new.fips.ru/registers-doc-view/fips_servlet?DB=RUPM&DocNumber=${index._id}&TypeFile=html`;
      case 'rude':
        return `https://new.fips.ru/registers-doc-view/fips_servlet?DB=RUDE&DocNumber=${index._id}&TypeFile=html`;
      case 'rusw':
        return `https://new.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=${index._id}&TypeFile=html`;
      case 'rudb':
        return `https://new.fips.ru/registers-doc-view/fips_servlet?DB=DB&DocNumber=${index._id}&TypeFile=html`;
    }
  }

}
