<section class="section-position">
  <div class="risk">
    <div class="attention attention--in-card" >
      <h3>Важно</h3>
      <p>Сведения о правообладателе ООО Ромашка товарного знака Звездочка не совпадают с актуальными данными в ЕГРЮЛ.
        Во избежание отказа в регистрации договора необходимо внести изменения в реестр Роспатента.</p>
    </div>
    <div class="hidden__blocks-wrapper">
      <div class="hidden__block">
        <div class="hidden__text-mini">
          <h4 class="hidden__subtitle">Данные правообладателя в егрюл</h4>
          <p class="hidden__text">Общество с ограниченной ответственностью «Ромашка»
            город Москва, Рязанский проспект, дом 75 корпус 4, офис 22.</p>
        </div>
        <div class="hidden__text-mini">
          <h4 class="hidden__subtitle">Данные правообладателя в Роспатенте</h4>
          <p class="hidden__text">Общество с ограниченной ответственностью «Ромашка»
            город Москва, Тверская, дом 17, офис 3.</p>
        </div>
      </div>
      <div class="hidden__block">
        <h4 class="hidden__subtitle">Ваше решение</h4>
        <form>
          <fieldset>
            <div class="hidden__input-wrapper input-checkbox" >
              <input type="radio" name="agreement" id="change" (click)="change('Изменить')" [checked]="result === 'Изменить'">
              <label class="input-checkbox__label" for="change">Внести изменения в реестр Роспатента
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </label>
            </div>
            <div class="hidden__input-wrapper input-checkbox">
              <input type="radio" name="agreement" id="refusal"(click)="change('Принять')" [checked]="result === 'Принять'">
              <label  class="input-checkbox__label" for="refusal" >Подать на регистрацию с риском отказа
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </label>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>

</section>
