<div class="dropdown" style="max-width: initial">
  <button id="{{'dropdown' + options.length}}" class="dropdown__button" (click)="isOptionsShown = !isOptionsShown" style="outline: none;">
    <span *ngIf="counter === 0" class="dropdown__button-text">
      {{placeholder}}
    </span>
    <span *ngIf="counter > 0" class="dropdown__button-text dropdown__button-text--check">
            {{placeholder}} {{counter}}</span>
    <img
      class="dropdown__button-img"
      [ngClass]="isOptionsShown ? 'rotate': ''"
      src="assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
  </button>
  <div class="dropdown__menu-wrap" *ngIf='isOptionsShown' style="z-index: 1000">
    <div class="dropdown__menu">
      <span class="dropdown__menu-title">{{placeholder}}</span>
      <ng-container *ngFor="let option of options; let i = index">
        <input class="visual-hidden dropdown__input"
               id="{{'checkbox' + i}}"
               type="checkbox"
               [name]="uniqueOptionName(option)"
               [ngModel]="isChecked(option.label)"
               (ngModelChange)="update(option.label)"
        >
        <label class="dropdown__label"
               [for]="'checkbox' + i">
          <span>{{option.label}}</span>
        </label>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="isOptionsShown"
     (click)="isOptionsShown = false;"
     class="overlay overlay--searchList"
     style="background: none">
</div>
