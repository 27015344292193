import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IntercomEventsService } from 'projects/shared/services/intercom-events.service';


@Component({
  selector: 'app-index-slider',
  templateUrl: './index-slider.component.html',
  styleUrls: ['./index-slider.component.scss']
})
export class IndexSliderComponent implements OnInit, AfterViewInit {
  slideIndex = 1;
  slides = document.getElementsByClassName('slide');
  dots = document.getElementsByClassName('slider-dots_item');

  constructor(
    private router: Router,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }


  ngAfterViewInit(): void {
    this.showSlides(this.slideIndex);
    setInterval(() => this.plusSlide(), 10000);
  }

  ngOnInit(): void {
  }

  toRequestPage(synonym) {
    if (synonym !== 'clickCheckProbabilityCarousel') {
      this.router.navigate(['/request'], {queryParams: {type: 'trademark'}});
    }
    this.intercomEventsService.push({event: synonym});
  }

  /* Основная функция слайдера */
  showSlides(n) {
    if (this.slides.length !== 0) {
      let i;
      if (n > this.slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = this.slides.length;
      }

      for (i = 0; i < this.slides.length; i++) {
        this.slides[i].classList.add('hide');
      }
      for (i = 0; i < this.dots.length; i++) {
        this.dots[i].className = this.dots[i].className.replace('active', '');
      }

      this.slides[this.slideIndex - 1].classList.remove('hide', 'show');
      this.dots[this.slideIndex - 1].classList.toggle('active');
    }
  }

  /* Функция уменьшяет индекс на 1, показывает предыдущий слайд*/
  minusSlide() {
    this.showSlides(this.slideIndex -= 1);
  }

  /* Устанавливает текущий слайд */
  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  plusSlide() {
    this.showSlides(this.slideIndex += 1);
  }

}
