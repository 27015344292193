<h1 class="heading-h1" id="mktuHeader">Выбор классов МКТУ
  <span class="prompt prompt__question">?
     <span class="prompt__hidden">
        <p>Международная классификация товаров и услуг, используемая для регистрации товарных знаков</p>
     </span>
   </span>
</h1>

<div *ngIf="owner?.TIN">
  <p class="subtitle" *ngIf="lastTrademark?._index === 'rutmap'">Классы подобраны на основании вашей последней заявки на регистрацию товарного знака</p>
  <p class="subtitle" *ngIf="lastTrademark?._index === 'rutm'">Классы подобраны из последнего зарегистрированного товарного знака</p>
  <p class="subtitle" *ngIf="!lastTrademark">Классы подобраны исходя из видов деятельности правообладателя в ЕГРЮЛ</p>
</div>

<form class="js-form form">
  <div class="request__form">
    <div class="not-mobile-mktu">
      <app-ip-id-search
        *ngIf="showSearchInput"
        style="width: 100%;"
        [options]="optionsMktu"
        (updateMKTUClasses)="updateMKTUClasses($event)"
      ></app-ip-id-search>
    </div>
    <div class="mobile-mktu">
      <app-mktu-mobile></app-mktu-mobile>
    </div>
  </div>

  <div class="duty-info"*ngIf="optionsMktu.selectedMktu.length">
    Вы добавили {{optionsMktu.selectedMktu.length}} {{optionsMktu.selectedMktu.length | declOfNum}} МКТУ, размер пошлин Роспатента
    <span class="sum">{{optionsMktu.selectedMktu.length | getDutySum}}</span>
  </div>
  <hr class="separator-line">

  <app-last-trademark *ngIf="lastTrademark"></app-last-trademark>

  <div class="okved" *ngIf="OKVEDCodes && OKVEDCodes.length && !lastTrademark">
    <div class="okved-title">ОКВЭД из ЕГРЮЛ для {{ownerShortName}}</div>
    <div class="okved-codes">
      <div class="okved-code" *ngFor="let okved of OKVEDCodes">
        {{(okved.code + ' ' + okved.description) | getShortOkved}}
        <div class="okved-tooltip" *ngIf="(okved.code + ' ' + okved.description).length > 40">
          {{okved.description}}
        </div>
      </div>
    </div>
  </div>
</form>

<div class="actions">
  <app-ui-button uiType="link" label="Назад" icon="arrow-left" iconPos="left" (onClick)="backStep()"></app-ui-button>
  <app-ui-button label="Далее" icon="arrow-right"(onClick)="nextStep()"  [id]="'nextStep'"></app-ui-button>
</div>
