import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkActions } from '../../../store/trademark.actions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganizationInterface } from '@web/core/models/organization.interface';
import { RequestType } from '@web/features/requests/trademark/types/trademark.types';
import { TrademarkState } from '@web/features/requests/trademark/store/trademark.state';

@Component({
  selector: 'app-trademark-form',
  templateUrl: './trademark-form.component.html',
  styleUrls: ['./trademark-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkFormComponent {
  @ViewSelectSnapshot(TrademarkState.type) type: RequestType;
  @ViewSelectSnapshot(TrademarkState.payerError) payerError: string;
  @ViewSelectSnapshot(TrademarkState.individual) individual: boolean;
  @ViewSelectSnapshot(TrademarkState.showFullForm) showFullForm: boolean;
  @ViewSelectSnapshot(TrademarkState.isLoadingOwner) isLoadingOwner: boolean;
  @ViewSelectSnapshot(TrademarkState.organization) organization: OrganizationInterface;
  public tinForm: FormGroup = new FormGroup({
    TIN: new FormControl('', [
      Validators.required, Validators.maxLength(15), Validators.pattern('^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$'),
    ])
  });
  public ownerForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    head: new FormControl(''),
    address: new FormControl(''),
  });
  @Dispatch() checkValidTIN = (value: string) => new TrademarkActions.CheckValidTIN(value);
  @Dispatch() showOrganizationsList = () => new TrademarkActions.ShowOrganizationsList(true);
}
