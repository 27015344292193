<section>


    <section class="section-position section-position__color-bg section-position--padding">
      <div class="container container-top">
        <h1>Дела</h1>
      </div>
    </section>

    <section class="section-position  section-position__color-bg section-position--padding control__wrap">
      <div class="container control">

        <button class="control__btn" (click)="changeTab('active')"
                [class.control__btn--active]="activeTab === 'active'"
        >Активные<span class="control__count">{{ immutableCases?.active?.length }} <span *ngIf="false"
                                                                                         class="control__division">/ <span
          class="control__division--red">2</span></span></span>
        </button>
        <button class="control__btn" (click)="changeTab('draft')"
                [class.control__btn--active]="activeTab === 'draft'"
        >Черновики<span class="control__count">{{ immutableCases?.draft?.length }}</span>
        </button>
        <button class="control__btn" (click)="changeTab('finished')"
                [class.control__btn--active]="activeTab === 'finished'"
        >Завершенные<span class="control__count">{{ immutableCases?.finished?.length }}</span>
        </button>

      </div>
    </section>

    <div *ngIf="!isLoadingCases; else loader">

      <div *ngIf="activeTab === 'active'" class="search-style search-style--filter">

        <app-cases-list-filters
          [showMobileControl]="showMobileControl"
          [caseStatuses]="caseStatuses"
          [caseRequestTypes]="caseRequestTypes"
          [allCases]="immutableCases"
          [filteredCases]="cases"
          [activeTab]="activeTab"
          (applyFilters)="applyFilters($event)"
          (clearFilters)="clearFilters()"
        >
        </app-cases-list-filters>

        <div class="container container--table">
          <div class="table">
            <div class="table__wrapper">
              <div *ngIf="countFilteredCases; else noData" class="table__head-block">
                <div class="table__head-object">Объект</div>
                <div class="table__head-type">Тип работ</div>
                <div class="table__head-number">Номер дела</div>
                <div class="table__head-state">Статус</div>
              </div>
              <div *ngFor="let caseItem of cases.active">
                <app-cases-list-item [case]="caseItem"></app-cases-list-item>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="activeTab === 'draft'" class="search-style search-style--filter">

        <app-cases-list-filters
          [showMobileControl]="showMobileControl"
          [caseStatuses]="caseStatuses"
          [caseRequestTypes]="caseRequestTypes"
          [allCases]="immutableCases"
          [filteredCases]="cases"
          [activeTab]="activeTab"
          (applyFilters)="applyFilters($event)"
          (clearFilters)="clearFilters()"
        >
        </app-cases-list-filters>

        <div class="container container--table">
          <div class="table">
            <div class="table__wrapper">
              <div *ngIf="countFilteredCases; else noData" class="table__head-block">
                <div class="table__head-object">Объект</div>
                <div class="table__head-type">Тип работ</div>
                <div class="table__head-number">Номер дела</div>
                <div class="table__head-state">Статус</div>
              </div>
              <div *ngFor="let caseItem of cases.draft">
                <app-cases-list-item [case]="caseItem" [draft]="true"></app-cases-list-item>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="activeTab === 'finished'" class="search-style search-style--filter">

        <app-cases-list-filters
          [showMobileControl]="showMobileControl"
          [caseStatuses]="caseStatuses"
          [caseRequestTypes]="caseRequestTypes"
          [allCases]="immutableCases"
          [filteredCases]="cases"
          [activeTab]="activeTab"
          (applyFilters)="applyFilters($event)"
          (clearFilters)="clearFilters()"
        >
        </app-cases-list-filters>

        <div class="container container--table">
          <div class="table">
            <div class="table__wrapper">
              <div *ngIf="countFilteredCases; else noData" class="table__head-block">
                <div class="table__head-object">Объект</div>
                <div class="table__head-type">Тип работ</div>
                <div class="table__head-number">Номер дела</div>
                <div class="table__head-state">Статус</div>
              </div>
              <div *ngFor="let caseItem of cases.finished">
                <app-cases-list-item [case]="caseItem"></app-cases-list-item>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


  <!--  <pre>{{this.cases.draft|json}}</pre>-->
  <app-expert></app-expert>
</section>

<ng-template #noData>
  <div *ngIf="!countAllCases" class="table__no-data">Нет данных</div>
</ng-template>

<ng-template #loader>
  <div class="search-style search-style--filter">
    <app-spinner style="height: 300px" class="spinner-full"></app-spinner>
  </div>
</ng-template>

