import { LastRequestTrademark } from '../types/trademark.types';
import { environment } from 'projects/app-ipid/src/environments/environment';

/**
 * @class
 * @name ImgHelper
 * @classdesc - Класс помощник для получение корректных путей для картинок товарных знаков.
 */
export class ImgHelper {
  /**
   * 
   * @typedef {LastRequestTrademark} - Объект с опциями для генерации пути.
   * @property {string} _index - Индекс товарного знака в БД.
   * @typedef {LastRequestTrademarkSource} - Объект с опциями по самому изобращени.
   * @property {string} applicationString - Уникальный id картинки в хранилище.
   * @property {string} markImageFileName - Наименование файла.
   * @returns 
   */
  public static getImgUrl({ _index, _source: { applicationString, markImageFileName } }: LastRequestTrademark): string {
    const hostUrl = `${window.location.protocol}//${window.location.host}`;
    const HOST = (hostUrl.includes('localhost') || hostUrl.includes('127.0.0.1') || hostUrl.includes('10.1.33.225'))
      ? `${environment.BASE_URL_IMG}/tm`
      : `${hostUrl}/storage/tm`;
    switch(_index) {
      case 'rutm':
        return `${HOST}/RUTM/${ImgHelper.generatePath(parseInt(applicationString, 10))}/${(applicationString || applicationString).replace('/', 'A')}/${markImageFileName}`;
      case 'wktm':
        return `${HOST}/WKTM/${ImgHelper.generatePath(parseInt(applicationString, 10))}/${(applicationString || applicationString).replace('/', 'A')}/${markImageFileName}`;
      case 'wotm':
        return `${HOST}/WOTM/${ImgHelper.generatePath(parseInt(applicationString, 10))}/${markImageFileName}`;
      case 'rutmap':
        return `${HOST}/RUTMAP/NEW${applicationString.slice(0, 4)}/${ImgHelper.generatePath(parseInt(applicationString.slice(4), 10))}/${applicationString}/${markImageFileName}`;
    }
  }

  private static generatePath(num: number): string {
    if (num < 1000) return '0';
    const paths: string[] = [];
    const n = num.toString();
    for (let i = 1; n.length - i >= 3; i++) {
      paths.push(n.slice(0, i) + '0'.repeat(n.length - i));
    }
    return paths.join('/');
  }
}
