import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthState} from '@web/core/states/auth.state';
import {Select} from '@ngxs/store';

type CanActivateReturn = boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>;

@Injectable()
export class MonitoringGuard implements CanActivate {
  @Select(AuthState.getRightByName('monitoring-viewing')) monitoring: Observable<boolean>;

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): CanActivateReturn {
    let monitoringRight: boolean = false;
    this.monitoring.subscribe(right => {
     monitoringRight = right;
    });

    return monitoringRight;
  }

}
