export namespace AppActions {
  export class Init {
    static readonly type = '[app] Init';
  }

  export class ListenWindowWidth {
    static readonly type = '[app] ListenWindowWidth';
  }

  export class SetBrand {
    static readonly type = '[app] SetBrand';
  }
}
