import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { TrademarkState } from '../../store/trademark.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TrademarkActions } from '../../store/trademark.actions';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { PaymentState } from '@ui/features/payment/store/payment.state';
import { PAYMENT_TYPE } from '@ui/features/payment/types/payment.types';
import { environment } from 'projects/app-ipid/src/environments/environment';
import { INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-trademark-result',
  templateUrl: './trademark-result.component.html',
  styleUrls: ['./trademark-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkResultComponent {
  @ViewSelectSnapshot(PaymentState.currentTab) paymentType: PAYMENT_TYPE;
  public logo2path: string = environment.LOGO2_PATH;
  public userEmail: string = JSON.parse(localStorage.getItem('currentUserData')).userInfo
    ? JSON.parse(localStorage.getItem('currentUserData')).userInfo.email
    : JSON.parse(localStorage.getItem('currentUserData')).email;
  @Dispatch() sendIntercomEvent = () => new TrademarkActions.SendIntercomEvent(INTERCOM_EVENT_NAME.GOTO_ORDER);
}
