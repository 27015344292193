import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import { GenericHttpService } from '../../shared/services/generic-http.service';
import { ApiParams } from '../../shared/models/api/api-params';
import {
  IServiceCards,
  IServiceDetails,
  IServices,
  IServicesSlider,
  IState
} from '../../shared/models/main.interfaces';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PopularServicesService {


  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private genericApi: GenericHttpService
  ) {
  }

  getSlider(): Observable<IServicesSlider> {
    return this.genericApi.getGeneric<void, IServicesSlider>('services/popular',
      null, new ApiParams({isMoqPostman: false}));
  }

  getServices(): Observable<IServices> {
    return this.genericApi.getGeneric<void, IServices>('services',
      null, new  ApiParams({isMoqPostman: false}));
  }

  getServiceDetails(id): Observable<IServiceDetails> {
    return this.genericApi.getGeneric<void, IServiceDetails>('services/item/?serviceID=' + id,
      null, new ApiParams({isMoqPostman: false}));
  }

  getStateFilter(): Observable<IState> {
    return this.genericApi.getGeneric<void, IState>('services/filterstatebody',
      null, new ApiParams({isMoqPostman: false}));
  }
}

