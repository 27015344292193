<div *ngIf='state$ | async as state'>
  <section class="section-top-search"
           [class.is-loading]="corpSearchByNameService.isLoading"
           [class.not-loaded]="!corpSearchByNameService.isLoaded">
    <div class="search_header">
      <div class="container container--search">
        <div [ngClass]="innerWidth > 1100 ? 'search-wrapper' : ''">

          <app-ip-id-search-input-corporate
            class="app-ip-id-search-input search--no-border"
            [options]="searchOptions"
            [placeholder]="corpSearchService.placeholder"
            [designation]="corpSearchByNameService.searchOptions.designation"
            [okvedList]="corpSearchByNameService.searchOptions.okvedList"
            [mktuList]="corpSearchByNameService.searchOptions.mktuList"
            [countriesList]="corpSearchByNameService.searchOptions.countriesList"
            (search)="corpSearchByNameService.load($event)"
            (searchImage)="onSearchImage($event)"
            (showPopup)="showPopup($event)"
            (fileChangeEvent)="fileChangeEvent($event)"
            (openCountriesPopup)="showPopupMobile($event)"
            (countriesDropdown)="countriesDropdown($event)"
          ></app-ip-id-search-input-corporate>
        </div>

        <div class="cropper_container" [style.display]="imageChangedEvent ? 'block' : 'none'" [class.visible-border]="visibleBorder">
          <image-cropper
            [imageBase64]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [aspectRatio]="4 / 3"
            [onlyScaleDown]="visibleBorder"
            [hideResizeSquares]="visibleBorder"
            format="png"
            (imageCropped)="imageCropped($event)"
          ></image-cropper>
        </div>
        <app-corp-search-loader *ngIf="corpSearchByNameService.isLoading || state.isLoading === true"></app-corp-search-loader>
        <span *ngIf="state.totalCount && state.isLoading === false && innerWidth > 1200" class="trademarks-btn">
        Найдено
          {{state.totalCount}}
          товарных знаков
      </span>
      </div>
      <div class="zz-index" *ngIf="state.trademarksByImage?.length && state.isLoading === false">
        <app-corp-search-trademarks
          [type]="'image'"
          [showFilters]="false"
          [items]="state.trademarksByImage"
          [totalCount]="state.totalCount"
          [itemsPerPage]="state.payload.size"
          [currentPage]='currentPage$ | async'
          [disableRisk]="true"
          (select)="onSelect('tradeMarks', $event)"
          (changePage)='changePage($event)'
        ></app-corp-search-trademarks>
        <app-corp-search-create-report *ngIf="!corpSearchApiService.objectsLoading"></app-corp-search-create-report>
      </div>
    </div>
  </section>

  <ng-container *ngIf="state.isLoading === false && !corpSearchByImageService.isLoadImage">
    <div class="corp-search-tabs">
      <div class="trademarks-btn__wrap">

        <p class="trademarks-btn">
          <span *ngIf="currentTab === 'trademark'">
            Найдено
            {{state.trademarksByName.hits?.total?.tradeMarks ? state.trademarksByName.hits?.total?.tradeMarks : 0}}
            товарных
            {{ declOfNum(state.trademarksByName.hits?.total?.tradeMarks, ['знак','знака','знаков']) }}
          </span>
          <span *ngIf="currentTab === 'legalname'">
            Найдено
            {{state.legalNamesByName.hits?.total?.legalEntities ? state.legalNamesByName.hits?.total?.legalEntities : 0}}
            фирменных
            {{ declOfNum(state.legalNamesByName.hits?.total?.legalEntities, ['наименование','наименования','наименований']) }}
            РФ
          </span>
          <span *ngIf="currentTab === 'domain'">
            Найдено
            {{state.domainsByName.hits?.total?.domains ? state.domainsByName.hits?.total?.domains : 0}}
            {{ declOfNum(state.domainsByName.hits?.total?.domains, ['домен','домена','доменов']) }}
          </span>
        </p>

        <div *ngIf='state.trademarksByName.hits?.hits?.length && state.trademarksByName.risks' class="trademarks-btn__risk {{getClassName()}}">
          <span *ngIf="state.trademarksByName.risks.max_risk === 'High'">Высокий риск отказа в регистрации</span>
          <span *ngIf="state.trademarksByName.risks.max_risk === 'Medium'">Есть риск отказа в регистрации</span>
          <span *ngIf="state.trademarksByName.risks.max_risk === 'None' || state.trademarksByName.risks.max_risk === 'Low'">Поздравляем, ваше название уникально</span>
        </div>
      </div>

      <div>
        <button class="corp-search-tabs__btn" (click)="changeTab('trademark')"
                *ngIf="currentTab === 'legalname' || currentTab === 'domain'">
          <span>Товарные знаки</span>
          <sup *ngIf="(!showIndex && currentTab === 'domain' && showIndexMobile) || (currentTab === 'legalname' && showIndexMobile) || (showIndexMobile && !showIndex)">
            {{state.trademarksByName.hits?.total?.tradeMarks}}
          </sup>
        </button>
        <button class="corp-search-tabs__btn" (click)="changeTab('legalname')"
                *ngIf="currentTab === 'trademark' || currentTab === 'domain'">
          <span>Фирменные наименования РФ</span>
          <sup *ngIf="showIndexMobile">
            {{state.legalNamesByName.hits?.total?.legalEntities}}
          </sup>
        </button>
        <button class="corp-search-tabs__btn" (click)="changeTab('domain')"
                *ngIf="currentTab === 'trademark' || currentTab === 'legalname'">
          <span>Домены</span>
          <sup *ngIf="showIndexMobile">
            {{state.domainsByName.hits?.total?.domains}}
          </sup>
        </button>
      </div>
    </div>

    <app-corp-search-trademarks
      *ngIf="currentTab === 'trademark' && state.trademarksByName.hits"
      [showFilters]="false"
      [items]="state.trademarksByName.hits?.hits"
      [totalCount]='state.trademarksByName.hits?.total?.tradeMarks'
      [itemsPerPage]="state.tradeMarksPagination.size"
      [currentPage]='currentPageTrademarks$ | async'
      (select)="onSelect('tradeMarks', $event)"
      (changePage)='changePage($event)'
      [countriesDropdown]="countriesPopup"
    ></app-corp-search-trademarks>

    <app-corp-search-domains
      *ngIf="currentTab === 'domain' && state.domainsByName.hits"
      [items]="state.domainsByName.hits?.hits"
      [totalCount]='state.domainsByName.hits?.total?.domains'
      [itemsPerPage]="state.domainsPagination.size"
      [currentPage]='currentPageDomains$ | async'
      (changePage)='changePage($event)'
      (select)="onSelect('domains', $event)"
    ></app-corp-search-domains>

    <app-corp-search-legalnames
      *ngIf="currentTab === 'legalname' && state.legalNamesByName.hits"
      [countriesDropdown]="countriesPopup"
      [items]="state.legalNamesByName.hits?.hits"
      [totalCount]='state.legalNamesByName.hits?.total?.legalEntities'
      [itemsPerPage]="state.legalNamesPagination.size"
      [currentPage]='currentPageLegalNames$ | async'
      (changePage)='changePage($event)'
      (select)="onSelect('legalNames', $event)"
    ></app-corp-search-legalnames>

    <app-corp-search-create-report *ngIf="!corpSearchApiService.objectsLoading"></app-corp-search-create-report>

  </ng-container>
</div>
