import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tariff-for-contestation',
  templateUrl: './tariff-for-contestation.component.html',
  styleUrls: ['./tariff-for-contestation.component.scss']
})
export class TariffForContestationComponent implements OnInit {
  openPayment: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
