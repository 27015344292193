import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { Location } from '@angular/common';
import { TabAccessComponent } from '../tab-access/tab-access.component';
import {IRepresentatives, IOrg} from '../../../shared/models/main.interfaces';
import {Store} from '@ngxs/store';
import {AuthState} from '@web/core/states/auth.state';
import {UserInterface} from '@web/core/models/user.interface';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit, OnDestroy {
  // Начальный таб
  userTab = 'organization';
  user: UserInterface;
  organizations = [];
  expiringAttorneys;
  cases;
  actualDate;
  userEdited: IRepresentatives;
  private readonly onDestroy = new Subject<void>();


  // Кнопки в секции с экспертом
  btns = ['Консультация по претензии или иску', 'Вопрос по текущему делопроизводству',
    'Консультация по услугам', 'Запрос первичных документов', 'Другое'];


  // Временный флаг для переключения администратор/юзер без прав
  administrator = false;

  // Временный флаг для моно-аккаунта - у моно-аккаунта может быть только 1 организация и
  // как я поняла 1 пользователь, но это не точно
  monoAccount = false;

  // Временный флаг для переключения на личный аккаунт с ожиданием подверждения прав от админа
  waitingAccount = false;

  // Не временные данные
  proxies;
  paymentHistory;
  rightsDataInput;
  disableFinance = false;
  contragentID;
  rights;
  activeOrg;
  repID;
  userID;
  disableBlockBtn = false;
  isLoadingOrganizations = false;

  @ViewChild(TabAccessComponent)
  tabAccessComponent: TabAccessComponent;

  constructor(
    private userProfileService: UserProfileService,
    private route: ActivatedRoute,
    private shared: SharedService,
    private router: Router,
    private location: Location,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.administrator = this.store.selectSnapshot(AuthState.getUser).role === 'Администратор'
      || this.store.selectSnapshot(AuthState.getUser).role === 'Администратор';
    this.monoAccount = !this.store.selectSnapshot(AuthState.getUser).multiaccount;
    this.getOrganizations();
    this.user = this.store.selectSnapshot(AuthState.getUser);
    this.contragentID = JSON.parse(localStorage.getItem('organizations'));

    if (this.administrator || this.monoAccount) {
      this.getAllProxies();
    } else {
      this.getProxiesByUser();
    }
    this.getPayments();

    this.route.queryParams.subscribe((params) => {
      if (params?.tab) {
        this.userTab = params.tab;
      } else {
        this.userTab = 'organization';
      }
    });
  }

  getRoleForCheckingRights() {
    const user = JSON.parse(localStorage.getItem('currentUserData'));
    if (user?.role === 'Пользователь' && user?.multiaccount) {
      const params = {
        contragentID: this.contragentID[0].id
      };
      if (params) {
        this.shared.getRightsAsync(params).subscribe(data => {
          this.rights = data.rights;
          if (this.rights) {
            this.getRightsAlgorithm();
          }
        });
      }
    }
  }

  getInitialRightData() {
    this.getRoleForCheckingRights();
    this.route.queryParams.subscribe(params => {
      this.activeOrg = params.contID;
      this.repID = params.userID;
      const data = [];
      data.push(params.contID, params.userID);
      if (data[0] !== undefined && data[1] !== undefined) {
        this.getRightsForEdit(data);
      }
      this.userID =  this.userProfileService.getUserData().userID;
      this.blockUserBtn();
    });
  }
  blockUserBtn() {
    this.disableBlockBtn = this.repID !== this.userID;
  }
  getRightsAlgorithm() {
    const finance = this.rights.filter(i => i.title === 'Финансовая информация');
    this.checkFinanceRights(finance);
  }

  checkFinanceRights(finance) {
    const synonym = 'finance_widget-payments';
    finance[0].data.forEach(item => {
      if (item.nameEng === synonym) {
        if (item.value === 'false' || item.value === 'pending') {
          this.disableFinance = true;
        }
      }
    });
  }

  // все доверенности пользователя
  getProxiesByUser() {
    const params = {
      mode: 'user'
    };
    this.userProfileService.getAllProxies(params).pipe(takeUntil(this.onDestroy)).subscribe((proxies: any) => {
      if (proxies.attorneys) {
        this.proxies = proxies.attorneys;
      }
    });
  }

  // все доверенности всех организаций мультиаккаунта пользователя
  getAllProxies() {
    const params = {
      mode: 'organizations'
    };
    this.userProfileService.getAllProxies(params).pipe(takeUntil(this.onDestroy)).subscribe((proxies: any) => {
      if (proxies.attorneys) {
        this.proxies = proxies.attorneys;
      }
    });
  }

  getPayments() {
    const params = {
      mode: 'organizations'
    };
    this.userProfileService.getPaymentsByOrg(params).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      this.paymentHistory = data?.payments;
      // TODO: For testing
      // this.paymentHistory = data?.payments.concat(data?.payments).concat(data?.payments).concat(data?.payments);
    });
  }

  // все доверенности одной или нескольких организаций мультиаккаунта пользователя
  getProxiesByOrg() {
    const params = {
      mode: 'organization'
      // id: 'id'
    };
    this.userProfileService.getAllProxies(params).pipe(takeUntil(this.onDestroy)).subscribe((proxies: any) => {
      if (proxies.attorneys) {
        this.proxies = proxies.attorneys;
      }
    });
  }

  // Выбор табов
  switchUserTab(type: string): void {
    if (type === this.userTab) {
      return;
    }
    this.userTab = type;
    this.location.go('user/profile');
  }

  getRightsForEdit(data) {
    this.rightsDataInput = data;
    if (this.rightsDataInput.length) {
      this.switchUserTab('access');
      // this.changeUserDetails(data[1]);
    }
  }

  changeUserDetails(id) {
    this.organizations.forEach(org => {
      this.userEdited = org.representatives.filter(i => i.id === id);
    });
  }

  getOrganizations() {
    this.userProfileService.getOrganizations().subscribe((data: IOrg) => {
      if (data) {
        this.organizations = data.organizations;
        this.expiringAttorneys = data.expiringAttorneys;
        localStorage.setItem('organizations', JSON.stringify(data.organizations));
        this.getInitialRightData();
      }
      this.getRoleForCheckingRights();
      this.isLoadingOrganizations = true;
    });
  }

  backClicked() {
    if (this.router.url.includes('contID')) {
      // нужна страница перед предыдущей
      this.location.back();
      this.location.back();
    } else {
      this.userTab = 'users';
      this.userEdited = undefined;
    }
  }

  deleteOrganization() {
    const query = {
      userID: this.repID,
      organizations: [{
        id: this.activeOrg
      }]
    };
    this.userProfileService.deleteOrganizations(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.error?.length) {
        console.log('ERROR', data.error);
      } else {
        this.getOrganizations();
        this.switchUserTab('organization');
        this.router.navigate(['user/profile']);
        localStorage.setItem('organizations', JSON.stringify(this.organizations));
      }
    });
    this.switchUserTab('organization');
  }


  blockUser() {
    this.deleteOrganization();
    // this.tabAccessComponent.deleteAllUserRights();
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
