import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})

export class LoadingModalComponent implements OnInit {

  loadingBases = false;
  loadingInternational = false;
  loadingReestr = false;

  constructor() {
  }

  @Input() brandsearch;
  @Input() legalsupport;

  ngOnInit() {
    setTimeout(() => {
      this.loadingBases = true;
    }, 300);

    setTimeout(() => {
      this.loadingInternational = true;
    }, 700);

    setTimeout(() => {
      this.loadingReestr = true;
    }, 1000);
  }

  closeModal() {

  }
}
