import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILvFilterDetail } from '../../../../../../app-ipid/src/app/shared/components/list-view/lv-filter/lv-filter.component';
import mktu from '../../../../../../app-search/src/app/pages/ip-id-search/json-data/mktu';
import { getAllIndexTypes, getSourceIndexInfo } from 'projects/app-ipid/src/app/shared/models/source.model';

@Component({
  selector: 'app-corp-search-trademarks',
  templateUrl: './corp-search-trademarks.component.html',
  styleUrls: ['./corp-search-trademarks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorpSearchTrademarksComponent implements OnInit {
  @Input() showColumns: string[];
  @Input() showFilters?: boolean;
  @Input() type?: string;
  @Input() countriesDropdown?: boolean;
  @Input() owners: boolean;
  @Input() disableRisk: boolean;
  @Input() totalCount: number;
  @Input() itemsPerPage = 10;
  @Input() currentPage: number;
  @Output() changePage = new EventEmitter<any>();

  private _items: any[];

  @Input() set items(items: any[]) {
    this._items = items;
    this.filters.type.options = this.filterTypeOptions;
    this.filters.classes.options = this.filterClassesOptions;
  }

  get items() {
    return this._items;
  }

  @Output() select = new EventEmitter<any[]>();

  constructor() {
  }

  page = 1;

  filters = {

    type: {
      options: [],
      value: [],
      fn: (row: any) => {
        const {value} = this.filters.type;
        if (!value.length) {
          return true;
        }

        return value.includes(row._index);
      }
    },

    classes: {
      options: [],
      value: [],
      fn: (row: any) => {
        const {value} = this.filters.classes;
        if (!value.length) {
          return true;
        }

        if (!row._source.goodsServices) {
          return false;
        }
        return row._source.goodsServices.some(i => value.includes(i.classNumber));
      }
    },

    riskLevel: {
      options: [
        {id: 'High', label: 'Высокая'},
        {id: 'Medium', label: 'Средняя'},
        {id: 'None', label: 'Низкая'},
      ],
      value: [],
      fn: (row) => {
        const {value} = this.filters.riskLevel;
        if (!value.length) {
          return true;
        }
        if (value.includes('None')) {
          value.push('Low');
        }
        const risk = row._risk;
        return value.includes(risk);
      },
    },

    priority_date: {
      options: [], // dynamic
      value: [],

      fn: (row: any) => {
        return true;
      }
    },


  };

  get showRiskLevelFilter() {
    // показываем фильтр по риску только если такое поле отображено
    return !this.showColumns?.length || this.showColumns.includes('state');
  }

  private get filterTypeOptions() {
    const allOptions = getAllIndexTypes().map(index => ({id: index, label: getSourceIndexInfo(index).title}));
    const allUsedTypes = new Set(this.items.map(i => i._index));
    return allOptions.filter(i => allUsedTypes.has(i.id));
  }


  private get filterClassesOptions() {
    const allUsedClasses = new Set([]);
    for (const row of this.items) {
      if (row._source.goodsServices) {
        row._source.goodsServices.forEach(i => allUsedClasses.add(i.classNumber));
      }
    }

    return mktu
      .filter(i => allUsedClasses.has(i.number))
      .map(i => ({id: i.number, label: i.desc}));
  }

  onApplyFilters(allFilters: Array<{ filter: ILvFilterDetail<any>, value: any }>) {
    for (const item of allFilters) {
      this.filters[item.filter.name].value = item.value;
    }

    this.page = 1;
  }

  setPage($event) {
    $event.type = 'tradeMarksPagination';
    this.changePage.emit($event);
  }

  onSelectItem(items: any[]) {
    this.select.emit(this.items.filter(i => i.checked));
  }


  ngOnInit(): void {
  }

}
