<div class="sections container" [ngStyle]='paddingProps'>
  <div>
    <ng-template *ngTemplateOutlet="headerRef"></ng-template>
  </div>

  <div
    class="items-list"
    [class.items-list-loading]="isLoading"
    [style.grid-template-columns]="gridTemplates"
  >
    <ng-container *ngFor="let item of dataSource">
      <ng-template *ngTemplateOutlet="itemRef; context: {$implicit: item}"></ng-template>
    </ng-container>
    <div *ngIf="isLoading" class="items-list__spinner">
      <app-ui-spinner [color]="'default'"></app-ui-spinner>
    </div>
  </div>

  <app-pagination
    *ngIf="totalCount / itemsPerPage > 1"
    [currentPageNumber]="currentPage"
    [totalCount]="totalCount"
    [itemPerPage]="itemsPerPage"
    (eventChangePage)="onChangePage($event)"
  ></app-pagination>
</div>
