import { Injectable } from '@angular/core';
import { ILogin } from '../models/main.interfaces';
import { HttpService } from './http.service';

const path = 'user';


@Injectable()
export class LoginService {

  constructor(private http: HttpService) {
  }

  login(body, params) {
    return this.http.post<ILogin>({path: `${path}/login`, body: {body}, subjectType: 'login', params});
  }

}
