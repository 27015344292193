import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpertComponent } from './expert/expert.component';
import { SystemLayoutComponent } from './system-layout/system-layout.component';
import { LogoComponent } from './logo/logo.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CreateRequestModalComponent } from './create-request-modal/create-request-modal.component';
import {UserPhoneModule} from '../../../../../shared/components/user-phone/user-phone.module';
import {TextFieldModule} from '@angular/cdk/text-field';
import {ExpertModule} from './expert/expert.mdoule';
import {SupportModalModule} from './support-modal/support-modal.module';

@NgModule({
  declarations: [
    SystemLayoutComponent,
    LogoComponent,
    MainMenuComponent,
    MainFooterComponent,
    NotificationsComponent,
    CreateRequestModalComponent
  ],
  exports: [
    SystemLayoutComponent,
    LogoComponent,
    MainMenuComponent,
    MainFooterComponent,
    NotificationsComponent,
    CreateRequestModalComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        UserPhoneModule,
        TextFieldModule,
        ReactiveFormsModule,
        ExpertModule,
        SupportModalModule
    ]
})
export class LayoutModule {
}
