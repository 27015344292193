import { Component, Input, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { RequestContractService } from '../../shared/request-contract.service';
import { ContractService } from '../../contract.service';
import { FormValidationService } from '../../../../shared/services/form-validation.service';
import {IOrganization} from '../../../../shared/models/main.interfaces';
import {UserProfileService} from '../../../../../../../shared/services/user-profile.service';

@Component({
  selector: 'app-form-not-resident',
  templateUrl: './form-not-resident.component.html',
  styleUrls: ['./form-not-resident.component.scss']
})
export class FormNotResidentComponent implements OnInit {

  @Input() index;
  @Input() side: 'owner' | 'recipient';
  dropdown = false;
  dropdownBik;
  lawFromNotResident: FormGroup;
  data;
  loadingOwner = false;
  organizations = [];
  organization: IOrganization;
  viewOrganizationsList = false;

  constructor(
    private fb: FormBuilder,
    private requestContractService: RequestContractService,
    private contractService: ContractService,
    private validationService: FormValidationService,
    private userProfileService: UserProfileService

  ) { }

  ngOnInit(): void {
    if (this.requestContractService[this.side + 's'][this.index]) {
      this.initFullForm();
    } else {
      this.initEmptyForm();
    }
    if (this.userProfileService.getOrganizationsData()) {
      this.organizations = this.userProfileService.getOrganizationsData().slice(0).filter(org => !org.pending && org.nonResident);
    }
  }

  showOrganizationsList() {
    if (this.organizations.length) {
      this.viewOrganizationsList = true;
    }
  }

  get validation() {
    return this.requestContractService.validation;
  }

  get contractAction() {
    return this.requestContractService.contractAction;
  }

  initEmptyForm(): void {
    this.lawFromNotResident = this.fb.group({
      search: new FormControl(null),
      INN: new FormControl( null),
      KIO: new FormControl(null),
      director: new FormControl(null),
      address: new FormControl(null),
      BIK: new FormControl(null, Validators.pattern(this.validationService.onlyNumbersPattern)),
      account: new FormControl(null, Validators.pattern(this.validationService.onlyNumbersPattern)),
    });
    this.setValue();
  }

  initFullForm(): void {
    this.lawFromNotResident = this.fb.group({
      search: new FormControl(this.requestContractService[this.side + 's'][this.index].name),
      INN: new FormControl(this.requestContractService[this.side + 's'][this.index].inn),
      KIO: new FormControl(this.requestContractService[this.side + 's'][this.index].kio),
      director: new FormControl(this.requestContractService[this.side + 's'][this.index].director),
      address: new FormControl(this.requestContractService[this.side + 's'][this.index].address),
      BIK: new FormControl(this.requestContractService[this.side + 's'][this.index].bik),
      account: new FormControl(this.requestContractService[this.side + 's'][this.index].account),
    });
  }

  getLegal(text?: string) {
    if (text?.length > 0) {
      this.dropdown = false;
      this.viewOrganizationsList = false;
      this.loadingOwner = true;
      this.contractService.getLegalByName(text).subscribe(data => {
        if (data.hits.hits.length && text === this.lawFromNotResident.value.search) {
          this.data = data.hits.hits.shift();
          this.dropdown = true;
          this.loadingOwner = false;
        } else {
          this.loadingOwner = false;
        }
      });
    } else {
      this.loadingOwner = false;
    }
  }

  setValue() {
    if (this.side === 'owner') {
      if (this.requestContractService.owners[this.index] === undefined) {
        this.requestContractService.owners[this.index] = {
          notResident: true,
          inn: '',
          kio: '',
          name: '',
          shortName: null,
          bik: '',
          account: '',
          director: '',
          address: '',
        };
      }
      this.requestContractService.owners[this.index] = {
        notResident: true,
        inn: this.data?._source?.TIN || null || this.organization?.TIN,
        kio: this.lawFromNotResident.value.KIO || this.organization?.TIN.slice(4, 9),
        director: this.lawFromNotResident.value.director,
        address: this.lawFromNotResident.value.address,
        bik: this.lawFromNotResident.value.BIK,
        account: this.lawFromNotResident.value.account,
        name: this.lawFromNotResident.value.search || this.data?._source?.name || null || this.organization?.name,
        shortName: this.data?._source?.shortName || null
      };
    } else {
      if (this.requestContractService.recipients[this.index] === undefined) {
        this.requestContractService.recipients[this.index] = {
          notResident: true,
          inn: '',
          kio: '',
          name: '',
          shortName: null,
          bik: '',
          account: '',
          director: '',
          address: '',
        };
      }
      this.requestContractService.recipients[this.index] = {
        notResident: true,
        inn: this.data?._source?.TIN || null || this.organization?.TIN,
        kio: this.lawFromNotResident.value.KIO || this.organization?.TIN.slice(4, 9),
        director: this.lawFromNotResident.value.director,
        address: this.lawFromNotResident.value.address,
        bik: this.lawFromNotResident.value.BIK,
        account: this.lawFromNotResident.value.account,
        name: this.lawFromNotResident.value.search || this.data?._source?.name || null || this.organization?.name,
        shortName: this.data?._source?.shortName || null
      };
    }
  }

  setValueOwner() {
    this.dropdown = false;
    this.lawFromNotResident.controls.INN.setValue(this.data._source.TIN);
    if (this.data._source.directors) {
      const director = this.data._source.directors.shift();
      this.lawFromNotResident.controls.director.setValue(`${director.surname} ${director.name} ${director.patronymic}`);
    }
    this.lawFromNotResident.controls.address.setValue(this.data._source.address);
    const kio = this.data._source.TIN.slice(4, 9);
    this.lawFromNotResident.controls.KIO.setValue(kio);
    this.lawFromNotResident.controls.search.setValue(this.data._source.name);
    this.getLegal();
    this.setValue();
  }

  setValueOrganization(organization) {
    this.organization = organization;
    this.lawFromNotResident.controls.search.setValue(null);
    this.viewOrganizationsList = false;
    this.lawFromNotResident.controls.INN.setValue(this.organization.TIN);
    const kio = this.organization.TIN.slice(4, 9);
    this.lawFromNotResident.controls.KIO.setValue(kio);
    this.lawFromNotResident.controls.director.setValue(this.organization.director);
    this.lawFromNotResident.controls.address.setValue(this.organization.legalAdress);
    this.lawFromNotResident.controls.search.setValue(this.organization.name);
    this.getLegal();
    this.setValue();
  }

}
