<div class="parrent_modal">

  <div class="modal">
    <ng-container *ngIf="!isLoading && sourceData?._source; else loader">

      <div class="top-hat">
        <div class="top-hat__logo-hat"></div>
        <div class="top-hat__info">
          {{ sourceData._source.PSRN }}
        </div>
        <button class="top-hat__btn-close" (click)="closeModal()"></button>
        <!--        <button type="button" class="top-hat__button">Задать вопрос онлайн</button>-->
      </div>

      <div class="legalname-body">
        <div class="legalname-body__buttons_risk" *ngIf="data._risk">
          <button *ngIf="data._risk.toLowerCase() ===  'high'" class="btn-risk box_button_warning">
            Высокий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'medium'" class="btn-risk box_button_middle">
            средний риск
          </button>
          <button *ngIf="data._risk.toLowerCase() === 'low' || data._risk.toLowerCase() === 'none'"
                  class="btn-risk box_button_low">
            Нет риска
          </button>
          <div class="risk-hint">
            <div class="risk-tooltip"
                 *ngIf="(data._risk.toLowerCase() ===  'high') || (data._risk.toLowerCase() ===  'medium')"
            >Этот объект создает значительные риски для вашего товарного знака
            </div>
            <div class="risk-tooltip" *ngIf="data._risk.toLowerCase() === 'low' || data._risk.toLowerCase() === 'none'"
            >Этот объект создает незначительные риски для вашего товарного знака
            </div>
          </div>
        </div>

        <div class="legalname-body__header">
          {{sourceData._index === 'organizations' ? sourceData._source.name : sourceData._source.fullName}}
        </div>

        <div class="legalname-body__data">
          <div class="image-container">
            <div class="image-container__name">
              {{trimString(sourceData._index === 'organizations' ? sourceData._source.name : sourceData._source.fullName, 42)}}
            </div>
          </div>
          <div class="info-container">
            <div class="info-container__top">
              <div class="feature">
                <div class="title">Статус</div>
                <div class="text status-legalname" [innerText]="getStatus(sourceData)"></div>
              </div>
            </div>
            <div class="info-container__bottom">
              <div class="first-column">
                <div class="feature" *ngIf="sourceData._source.PSRN">
                  <div class="title">ОГРН</div>
                  <div class="text">
                    {{sourceData._source.PSRN}}
                  </div>
                </div>
                <div class="feature" *ngIf="sourceData._source.registrationDate">
                  <div class="title">Дата регистрации</div>
                  <div class="text">
                    {{sourceData._source.registrationDate | date:'dd.MM.yyyy'}}
                  </div>
                </div>
              </div>
              <div class="second-column">
                <div class="feature" *ngIf="sourceData._source.TIN">
                  <div class="title">ИНН</div>
                  <div class="text">{{sourceData._source.TIN}}</div>
                </div>
                <!--                <div class="feature">-->
                <!--                  <div class="title">Руководитель</div>-->
                <!--                  <div class="text">какой-то руководитель</div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <app-accordion
          [title]="'ОКВЭД'"
          [count]="sourceData && sourceData._source && sourceData._source.OKVEDCodes && sourceData._source.OKVEDCodes.length">
          <ul *ngIf="sourceData && sourceData._source && sourceData._source.OKVEDCodes">
            <li *ngFor="let classes of getOKVEDClasses()">
              <div class="mktu-class">
                <div class="class-number">{{classes.code}}</div>
                <div class="class-description"
                     onclick="this.className = (this.className === 'class-description' ? 'class-description-long' : 'class-description')">{{classes.description}}</div>
              </div>
            </li>
          </ul>
          <button
            *ngIf="sourceData._source.OKVEDCodes && sourceData._source.OKVEDCodes.length && sourceData._source.OKVEDCodes.length > 3 && viewClassesCount === 'short'"
            (click)="showAllClasses()" class="show-all-btn">Показать
            еще {{sourceData._source.OKVEDCodes.length - 3}}</button>
        </app-accordion>


        <div class="divider"></div>
        <app-accordion
          [title]="'Товарные знаки'"
          [count]="overTradeMarks && overTradeMarks.length"
        >
          <div class="trademarks-list__wrap">
            <div class="trademarks-list" *ngIf="overTradeMarks">
              <div class="trade-mark-card" *ngFor="let tradeMark of overTradeMarks; let i = index"
                   [style.margin-right.px]="(i + 1) % 4 === 0 ? '0' : '40'">
                <div class="image-body" *ngIf="tradeMark && tradeMark._source">
                  <img *ngIf="tradeMark._source.imageUrl && tradeMark._source.markImageFileName"
                       [src]="tradeMark._source.imageUrl" alt="">
                  <div *ngIf="!tradeMark._source.imageUrl || !tradeMark._source.markImageFileName"
                       class="text-instead-image">{{ tradeMark._source.imageText }}</div>
                </div>
                <div class="image-text"
                     *ngIf="tradeMark._source && tradeMark._source.indexType">{{tradeMark._source.indexType}}</div>
                <div *ngIf="tradeMark.typeOfOwner === 'owner'" class="label" [innerText]="'владелец'"></div>
                <div *ngIf="tradeMark.typeOfOwner === 'user'" class="label" [innerText]="'по договору'"></div>
              </div>
            </div>
          </div>
        </app-accordion>

        <div class="divider"></div>
        <app-accordion
          [title]="'Другие домены этого владельца'"
          [count]="overDomains && overDomains.length"
        >
          <div *ngIf="overDomains && overDomains.length">
            <ul>
              <li *ngFor="let domain of overDomains">
                <span class="domain" [innerText]="getDomain(domain).toLowerCase()"></span>
              </li>
            </ul>
          </div>
        </app-accordion>

        <!--        <div class="divider"></div>-->
        <!--        <app-accordion-->
        <!--          [title]="'Споры'"-->
        <!--          [count]="0"-->
        <!--        >-->
        <!--        </app-accordion>-->
      </div>

    </ng-container>
  </div>

  <div (click)="closeModal()" class="overlay-space"></div>
</div>

<div class="page-overlay" id="modal-overlay"></div>

<ng-template #loader>
  <div class="loader-wrapper">
    <app-ls-emb-loader></app-ls-emb-loader>
  </div>
</ng-template>
