<section class="sections container container--head">
  <button
    *ngIf="organizations?.length"
    class="chart__mini-wrapper chart__mini-wrapper--several"
    [class.chart__mini-wrapper--several-2]="showDropdownOrg===true"
  >
    <span
      style="margin-right: 5px; color: #000"
      *ngIf="organizations.length > 1"
      (click)="showDropdownOrg=!showDropdownOrg"
    >
      Организация:
    </span>
    <span tabindex="0" *ngIf="organizations.length > 1">
            <a class="company-list__link" (click)="showDropdownOrg=!showDropdownOrg">
              {{ selectedOrg.id ? selectedOrg.name : ' все '}}
            </a>
          </span>

    <div *ngIf="showDropdownOrg" (click)="showDropdownOrg=false"
         class="overlay overlay--transparent"
         style="background: none;z-index: 2;">
    </div>
    <div *ngIf="showDropdownOrg" class="chart__companies-wrapper company-list">
      <ul class="company-list__list">
        <li class="company-list__item"
            *ngFor="let company of organizations" (click)="selectOrg(company);showDropdownOrg=false">
          <p>{{trimString(company.name, 30)}}</p>
        </li>
      </ul>
    </div>
  </button>
</section>

<section class="sections container">
  <article class="sections__item sections__item--trademarks">
    <app-spinner class="spinner-full" *ngIf="(corpIndexService.searchHistory.isLoading$ | async)"></app-spinner>
    <ng-container *ngIf="!(corpIndexService.searchHistory.isLoading$ | async)">
      <h3 class="sections__title">Поиск по товарным знакам</h3>
      <div class="sections__content">
        <app-trademarks-search
          [searches]="(corpIndexService.searchHistory.data$ | async)?.searches"
        ></app-trademarks-search>
      </div>
    </ng-container>

    <div class="no-access" *ngIf="disableSearchWidget">
      <p>Функционал недоступен</p>
      <span>Запросите права у администратора аккаунта</span>
    </div>
  </article>

  <article class="sections__item portfolio-item">
    <app-spinner class="spinner-full" *ngIf="(corpIndexService.counters.isLoading$ | async)"></app-spinner>

    <ng-container *ngIf="!(corpIndexService.counters.isLoading$ | async)">
      <h3 class="sections__title">Портфель и делопроизводство</h3>
      <div class="sections__content">
        <app-portfolio-chart
          [counters]="(corpIndexService.counters.data$ | async)"
        ></app-portfolio-chart>
      </div>
    </ng-container>
  </article>

  <article class="sections__item risks-item">
    <ng-container *ngIf="!(corpIndexService.counters.isLoading$ | async)">
      <h3 class="sections__title">Риски</h3>
      <div class="sections__content">
        <app-risks [counters]="(corpIndexService.counters.data$ | async)"></app-risks>
      </div>
    </ng-container>
    <app-spinner class="spinner-full" *ngIf="(corpIndexService.counters.isLoading$ | async)"></app-spinner>
  </article>

  <article class="sections__item service-item">
    <h3 class="sections__title">Популярные услуги</h3>
    <div class="sections__content">
      <app-service-slider
        [sliderCards]="sliderCards$ | async"
      >
      </app-service-slider>
    </div>
    <a routerLink="/services" class="sections__btn btn" [class.disabled]="X5">Перейти к услугам</a>

    <!--    <div *ngIf="soon" class="soon"></div>-->
  </article>
</section>

<app-news-feed></app-news-feed>

<app-expert></app-expert>
