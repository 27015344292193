<h1 class="heading-h1">{{!payError?.status ? 'Выбор способа оплаты' : 'Ошибка'}}</h1>
<div *ngIf="payError?.status" class="info-plate">
  <div class="info-plate__img"><img [src]="logo2path" alt="alt"></div>
  <p class="info-plate__text" [innerHTML]="payError?.text"></p>
  <a class="btn" (click)="repeatPayment()">ПОПРОБОВАТЬ ЕЩЕ РАЗ</a>
</div>
<div *ngIf="!payError?.status" class="tabs js-tabs">
  <ul class="tabs__list tabs__list--half-cols">
    <li class="tabs__item">
      <a [ngClass]="paymentTab === 'online'? 'is-active': ''"
         class="tabs__item-link js-tabs__link" data-id="online">оплата онлайн</a>
    </li>
    <li class="tabs__item">
      <a (click)="switchPaymentTab('cash')" [ngClass]="paymentTab === 'cash'? 'is-active': '' "
         class="tabs__item-link js-tabs__link" data-id="cash">Выставить счет для безналичной оплаты</a>
    </li>
  </ul>
  <div class="tabs__body">
<!--    <div *ngIf="paymentTab === 'online'" class="js-tabs__content tabs__content is-active" id="online">-->
<!--      <form class="form form&#45;&#45;margin" *ngIf="requestType !== 'trademark' && selectedCompany" [formGroup]="formPayer">-->
<!--        <div class="form__group">-->
<!--          <div tabindex="0" class="form__input-wrap form__input-wrap&#45;&#45;select"-->
<!--               [class.form__input-wrap&#45;&#45;select&#45;&#45;after]="showOptions===true">-->
<!--            <a class="form__link" (click)="showOptions=!showOptions"-->
<!--               [class.form__link&#45;&#45;gray]="selectedCompany.name==='Выберите плательщика'"-->
<!--               [innerHTML]="getCompanyName(selectedCompany.name)"></a>-->
<!--            <ul *ngIf="showOptions" class="form__select-list">-->
<!--              <li *ngFor="let comp of company" class="form__li company-item"-->
<!--                  [class.form__li&#45;&#45;selected]="comp === selectedCompany"-->
<!--                  (click)="onSelect(comp)">-->
<!--                <p [innerHTML]="getCompanyName(comp.name)"></p>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div *ngIf="selectedCompany.name==='Указать другого плательщика'" class="form__group">-->
<!--          <div class="form__input-wrap">-->
<!--            <div-->
<!--              *ngIf="selectedPayer"-->
<!--              [ngStyle]="getDynamicMarginForLegalName()"-->
<!--              class="legal-name-patentee"-->
<!--            >-->
<!--              / {{ selectedPayer.name }}-->
<!--            </div>-->
<!--            <input class="form__input field" type="text" required formControlName="payer"-->
<!--                   (ngModelChange)="payerChanged($event)">-->
<!--            <span class="form__input-label">Название, ОГРН/ОГРНИП, ИНН или ФИО</span>-->
<!--          </div>-->
<!--          <div class="form__dropdown form-card" *ngIf="tempPayer" (click)="peyerSelected()" style="position: relative">-->
<!--            <span class="modal__close" style="right: 6px" (click)="cancelRatentee()">-->
<!--                <svg width="26" height="20" viewBox="0 0 26 26">-->
<!--                  <rect x="1.41431" width="34" height="2" transform="rotate(45 1.41431 0)"/>-->
<!--                  <rect y="24.0416" width="34" height="2" transform="rotate(-45 0 24.0416)"/>-->
<!--                </svg>-->
<!--              </span>-->
<!--            <table class="form-card__table">-->
<!--              <thead class="dropdown-hover">-->
<!--              <tr>-->
<!--                <th>ОГРН</th>-->
<!--                <th>ИНН</th>-->
<!--                <th>Наименование компании или ИП</th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td>{{tempPayer.PSRN}}</td>-->
<!--                <td>{{tempPayer.TIN}}</td>-->
<!--                <td class="black">{{tempPayer.name || tempPayer.fullName}}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </div>-->
<!--        </div>-->
<!--      </form>-->
<!--      <div class="screenshots">-->
<!--        <div id="payment-form" style="min-height: 480px;">-->
<!--          <div *ngIf="!payWidjetIsRender" style="padding-top: 190px;">-->
<!--            <div class="loader"></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="btn-wrap" style="position: relative; margin-top: 20px;">-->
<!--        <a class="back-button" (click)="backStep()" style="transform: unset">-->
<!--          <div class="back-icon"></div>-->
<!--          <div class="text">Назад</div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
    <div *ngIf="paymentTab === 'cash'" class="js-tabs__content tabs__content" id="cash">
      <form class="form form--margin" *ngIf="requestType !== 'trademark' && selectedCompany" [formGroup]="formPayer">
        <div class="form__group">
          <div tabindex="0" class="form__input-wrap form__input-wrap--select"
               [class.form__input-wrap--select--after]="showOptions===true">
            <a class="form__link" (click)="showOptions=!showOptions"
               [class.form__link--gray]="selectedCompany.name==='Выберите плательщика'"
               [innerHTML]="getCompanyName(selectedCompany.name)"></a>
            <ul *ngIf="showOptions" class="form__select-list">
              <li *ngFor="let comp of company" class="form__li company-item"
                  [class.form__li--selected]="comp === selectedCompany"
                  (click)="onSelect(comp)">
                <p [innerHTML]="getCompanyName(comp.name)"></p>
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="selectedCompany.name==='Указать другого плательщика'" class="form__group"
             style="position: relative">
          <div class="form__input-wrap">
            <div
              *ngIf="selectedPayer"
              [ngStyle]="getDynamicMarginForLegalName()"
              class="legal-name-patentee"
            >
              / {{ selectedPayer.name }}
            </div>
            <input class="form__input field" type="text" required formControlName="payer"
                   (ngModelChange)="payerChanged($event)">
            <span class="form__input-label">Название, ОГРН/ОГРНИП, ИНН или ФИО</span>
          </div>
          <div class="form__dropdown form-card" *ngIf="tempPayer" (click)="peyerSelected()" style="position: relative">
            <span class="modal__close" style="right: 6px" (click)="cancelRatentee()">
                <svg width="26" height="20" viewBox="0 0 26 26">
                  <rect x="1.41431" width="34" height="2" transform="rotate(45 1.41431 0)"/>
                  <rect y="24.0416" width="34" height="2" transform="rotate(-45 0 24.0416)"/>
                </svg>
              </span>
            <table class="form-card__table">
              <thead class="dropdown-hover">
              <tr>
                <th>ОГРН</th>
                <th>ИНН</th>
                <th>Наименование компании или ИП</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{{tempPayer.PSRN}}</td>
                <td>{{tempPayer.TIN}}</td>
                <td class="black">{{tempPayer.name || tempPayer.fullName}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>

      <form class="form" [formGroup]="formPay">
        <div class="form__group" *ngIf="requestType === 'trademark' || selectedPayer">
          <div class="form__input-wrap">
            <input class="form__input field" formControlName="INN" type="number"
                   style="pointer-events: none"><span class="form__input-label"> ИНН</span>
          </div>
        </div>
        <div class="form__group" *ngIf="requestType === 'trademark' || selectedPayer">
          <div class="form__input-wrap">
            <input class="form__input field" formControlName="OGRN" type="number"
                   style="pointer-events: none"><span class="form__input-label"> ОГРН</span>
          </div>
        </div>
        <div class="form__group" *ngIf="requestType === 'trademark' || selectedPayer">
          <div class="form__input-wrap">
            <input class="form__input field" formControlName="name" type="text"
                   style="pointer-events: none"><span
            class="form__input-label">Наименование юрлица или ИП</span>
          </div>
        </div>
        <div class="form__group" *ngIf="requestType === 'trademark' || selectedPayer">
          <div class="form__input-wrap">
            <input class="form__input field" formControlName="address" type="text"
                   style="pointer-events: none"><span
            class="form__input-label">Юридический адрес</span>
          </div>
        </div>
        <div class="form__group">
          <div class="form__input-wrap">
            <input class="form__input field" formControlName="sum" type="text"
                   style="pointer-events: none"><span class="form__input-label">Сумма</span>
          </div>
        </div>
      </form>

      <div class="btn-wrap" style="position: relative">
        <a class="back-button" (click)="backStep()" style="transform: unset">
          <div class="back-icon"></div>
          <div class="text">Назад</div>
        </a>
        <a class="btn btn__pay" (click)="sendPayJSON('cash')"
           [class.invalid-form-btn]="!formPay.valid || !thirdStepSended">Выставить счет</a></div>
    </div>
  </div>
</div>
