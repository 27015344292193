import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import {RequestContractService} from '../shared/request-contract.service';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss']
})
export class TypeComponent implements OnInit {
  contractType;
  @Output() type = new EventEmitter<string>(); // событие переключения способа оплаты
  constructor(
    public requestContractService: RequestContractService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  ngOnInit(): void {
    if (this.requestContractService.contractAction === 'change' || this.requestContractService.contractAction === 'termination') {
      this.contractType = this.requestContractService.contractAction;
    } else {
      this.contractType = this.requestContractService.contractType;
    }
  }

  toSelectType(info) {
    this.type.emit(info);
    this.contractType = info;
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_ITEM, itemType: info });

  }

  get isLoading() {
    return this.requestContractService.isLoading;
  }
}
