import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-complex-form',
  templateUrl: './tab-complex-form.component.html',
  styleUrls: ['./tab-complex-form.component.scss']
})
export class TabComplexFormComponent implements OnInit {

  toHideForm = false;
  toHideForm1 = true;
  toHideForm3 = true;
  toHideForm2 = true;
  attention = true;
  constructor() { }

  ngOnInit(): void {
  }

}
