<section class="section-position section-position__top">
  <div class="container container__top">

    <div class="possibility">

      <h2>Возможность регистрации товарного знака</h2>

      <!--  Компонент поиска -->
      <app-index-search></app-index-search>

      <!--    Компонент слайдера -->
      <app-index-slider></app-index-slider>

    </div>


    <div class="cases_wrapper" data-intercom-target="my work">
      <div class="my-deal">
        <h2>Мои дела</h2>
        <a *ngIf="case?.length" routerLink="/cases" (click)="setActiveOther()" class="service-link">{{casesTotal}}
          <div style="position: absolute; top: 0; right: -30px;">
            <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="service-link__arrow"
                    d="M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.2929L14.3431 0.928933C13.9526 0.538409 13.3195 0.538409 12.9289 0.928933C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM-8.74228e-08 9L20 9L20 7L8.74228e-08 7L-8.74228e-08 9Z"
                    fill="#2E71F6"/>
            </svg>
          </div>
        </a>
      </div>

      <ng-container *ngIf="isLoadingCases; else loader">
        <div class="linkTo_case" *ngIf="tasks && case?.length" routerLink="/cases" (click)="setActiveCase()">

          <div class="info-card info-card--main info-card--hasdeal">
            <p class="info-card__text info-card__text--small">Товарный знак</p>
            <p class="info-card__main-text">{{case[0]?.details?.title}}</p>
            <div class="progress-bar">
              <div class="progress-bar__background">
                <span class="progress-bar__rate" [ngStyle]="{'left': (case[0]?.details?.progress+6)+'%'}">{{case[0]?.details?.progress}}%</span>
                <span class="progress-bar__indicator" [ngStyle]="{'width': case[0]?.details?.progress+'%'}"></span>
              </div>
            </div>
            <div *ngIf="active.status === 'К согласованию'" class="btn main-nav__btn btn__red"
                 style="cursor: auto">{{case[0].status}}</div>
            <span *ngIf="lastGoal" class="info-card__state">{{lastGoal.name}}</span>
          </div>

        </div>

        <div class="linkTo_case" *ngIf="tasks && !case?.length">
          <div class="info-card info-card--main">
            <p class="info-card__text info-card__text--big">Нет активных дел</p>
<!--            <button class="btn&#45;&#45;request main-nav__btn js-show-modal__trigger" (click)="openModal()">Подать заявку-->
<!--            </button>-->
          </div>
        </div>
      </ng-container>
      <ng-template #loader>
        <app-spinner class="spinner-full"></app-spinner>
      </ng-template>
    </div>

  </div>
</section>
<section class="container">
  <div class="section-position section-position--wrapper section-position--mobile"
       [ngStyle]="{'padding-bottom': demoService.indexPage.myDeal ? '26px' : '48px'} ">

    <app-service-list-widget class="service-wrapper"></app-service-list-widget>

    <app-monitoring-widget class="monitoring__wrap" *ngIf="envService.isMonitoringEnabled"></app-monitoring-widget>

  </div>
</section>

<app-expert>
</app-expert>


