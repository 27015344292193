import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchService } from '../../../../../services/search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-legal-name-modal',
  templateUrl: './legal-name-modal.component.html',
  styleUrls: ['./legal-name-modal.component.scss']
})

export class LegalNameModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() modalEvent = new EventEmitter();
  private readonly onDestroy = new Subject<void>();
  sourceData: any = {};
  isLoading = false;
  viewClassesCount = 'short';
  overTradeMarks;
  overDomains;

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  constructor(
    private searchService: SearchService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '0';
    }
    this.getDocById();
  }

  getOverTradeMarks() {
    const query = {
      id: this.sourceData._source.PSRN ? this.sourceData._source.PSRN.toString() : this.sourceData._source.TIN.toString(),
      searchTradeMarks: true
    };
    this.searchService.getOverTradeMarksOrDomains(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.hits && data.hits.hits) {
        data.hits.hits.forEach((item) => {
          let id = item._source.registrationString;
          if (item._index === 'rutmap' && item._source.applicationString) {
            id = item._source.applicationString;
          }
          item._source.imageUrl = this.searchService.getImgUrl(
            item._index,
            parseInt(id, 10),
            item._source.markImageFileName,
            id
          );
          item._source.indexType = this.searchService.getIndexType(item);
        });
        this.overTradeMarks = data.hits.hits;
      }
    });
  }

  getOverDomains() {
    const query = {
      id: this.sourceData._source.PSRN ? this.sourceData._source.PSRN.toString() : this.sourceData._source.TIN.toString(),
      searchDomains: true
    };
    this.searchService.getOverTradeMarksOrDomains(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.hits && data.hits.hits) {
        this.overDomains = data.hits.hits;
      }
    });
  }

  getDocById() {
    const query = {
      _id: this.data._id,
      _index: this.data._index
    };
    this.isLoading = true;
    this.searchService.getDocumentById(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits?.length) {
        this.sourceData = data.hits.hits[0];
        this.sourceData.indexType = this.searchService.getIndexType(this.sourceData);
        this.isLoading = false;
        this.ref.detectChanges();
        if (this.sourceData._source.PSRN || this.sourceData._source.TIN) {
          this.getOverTradeMarks();
          this.getOverDomains();
        }
      }
    });
  }


  getOKVEDClasses() {
    if (this.viewClassesCount === 'long') {
      return this.sourceData._source.OKVEDCodes;
    } else {
      return this.sourceData._source.OKVEDCodes.slice(0, 3);
    }
  }

  showAllClasses() {
    this.viewClassesCount = 'long';
  }

  getStatus(sourceData): string {
    let status = '';
    switch (sourceData._index) {
      case 'organizations':
        if (sourceData._source.status >= 100 && sourceData._source.status <= 199) {
          status = 'Действует';
        } else {
          status = 'Не действует';
        }
        break;
      case 'individual_interpreners':
        status = sourceData._source.status === 100 ? 'Действует' : 'Не действует';
        break;
      default:
        break;
    }
    return status;
  }

  getDomain(domainName) {
    return domainName._source.fullDomain.includes('XN--') ?
      `${domainName._source.domain}.${domainName._source.zone}`.toUpperCase() :
      domainName._source.fullDomain.toUpperCase();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  closeModal() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '1';
    }
    this.modalEvent.emit();
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
