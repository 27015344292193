import {
  MKTU,
  RequestType,
  TariffResponse,
  SearchLegalPayload,
  UploadImagePayload,
  TrademarkDraftTasks,
  SearchLegalResponse,
  UploadImageResponse,
  LastRequestTrademark,
  TrademarkDraftPromocode,
  LoadMktuByOkvedResponseHits,
  LoadDocumentByIdResponseHits,
} from '../types/trademark.types';
import { Tariff } from '@ui/features/tariffes/models/rates.interfaces';
import { OrganizationInterface } from '@web/core/models/organization.interface';
import { INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

export namespace TrademarkActions {
  export class SetLoading {
    public static readonly type = '[Trademark State] Set Loading';
    constructor(public readonly payload: boolean) { }
  }

  export class SetRequestType {
    public static readonly type = '[Trademark State] Set Request Type';
    constructor(public readonly payload: RequestType) { }
  }

  export class ClearTrademarkState {
    public static readonly type = '[Trademark State] Clear Trademark State';
  }

  export class LoadTrademarkDraft {
    public static readonly type = '[Trademark State] Load Trademark Draft';
    constructor(public readonly id: string) { }
  }

  export class LoadTrademarkDraftSuccess {
    public static readonly type = '[Trademark State] Load Trademark Draft Success';
    constructor(public readonly payload: TrademarkDraftTasks) { }
  }

  export class LoadTrademarkDraftFail {
    public static readonly type = '[Trademark State] Load Trademark Draft Fail';
  }

  export class UploadTrademarkImage {
    public static readonly type = '[Trademark State] Upload Trademark Image';
    constructor(public readonly payload: File) { }
  }

  export class UploadImage {
    public static readonly type = '[Trademark State] Upload Image';
    constructor(public readonly payload: UploadImagePayload) { }
  }

  export class UploadImageSuccess {
    public static readonly type = '[Trademark State] Upload Image Success';
    constructor(public readonly payload: UploadImageResponse) { }
  }

  export class UploadImageFail {
    public static readonly type = '[Trademark State] Upload Image Fail';
    constructor(public readonly error: string) { }
  }
  
  export class RemoveTrademarkImage {
    public static readonly type = '[Trademark State] Remove Trademark Image';
  }

  export class ShowOrganizationsList {
    public static readonly type = '[Trademark State] Show Organizations List';
    constructor(public readonly payload: boolean) { }
  }

  export class SetOrganizationFromList {
    public static readonly type = '[Trademark State] Set Organization From List';
    constructor(public readonly payload: OrganizationInterface) { }
  }

  export class LoadOwnerInfo {
    public static readonly type = '[TrademarkOwner State] Load Owner Info';
    constructor(public readonly payload: SearchLegalPayload) { }
  }

  export class LoadOwnerInfoSuccess {
    public static readonly type = '[Trademark State] Load Owner Info Success';
    constructor(public readonly payload: SearchLegalResponse) { }
  }

  export class LoadOwnerInfoFail {
    public static readonly type = '[Trademark State] Load Owner Info Fail';
  }

  export class CheckValidTIN {
    public static readonly type = '[Trademark State] Check Valid TIN';
    constructor(public readonly payload: string) { }
  }

  export class LoadOwnerCandidate {
    public static readonly type = '[Trademark State] Load Owner Candidate';
    constructor(public readonly payload: string) { }
  }

  export class LoadOwnerCandidateSuccess {
    public static readonly type = '[Trademark State] Load Owner Candidate Success';
    constructor(public readonly payload: SearchLegalResponse) { }
  }

  export class LoadOwnerCandidateFail {
    public static readonly type = '[Trademark State] Load Owner Candidate Fail';
  }

  export class CheckOrganization {
    public static readonly type = '[Trademark State] Check Organization';
    constructor(public readonly payload: { PSRN: number }) { }
  }

  export class CheckOrganizationSuccess {
    public static readonly type = '[Trademark State] Check Organization Success';
    constructor(public readonly payload: { passed: boolean }) { }
  }

  export class CheckOrganizationFail {
    public static readonly type = '[Trademark State] Check Organization Fail';
  }

  export class UpdateTrademarkDraft {
    public static readonly type = '[Trademark State] Update Trademark Draft';
  }

  export class UpdateTrademarkDraftSuccess {
    public static readonly type = '[Trademark State] Update Trademark Draft Success';
    constructor(public readonly responseID: string) { }
  }

  export class UpdateTrademarkDraftFail {
    public static readonly type = '[Trademark State] Update Trademark Draft Fail';
  }

  export class ActionByCurrentStep {
    public static readonly type = '[Trademark State] Action By Current Step';
  }

  export class BackStep {
    public static readonly type = '[Trademark State] Back Step';
  }

  export class FillFromDraft {
    public static readonly type = '[Trademark State] Fill From Draft';
  }

  export class LoadOwnerByInn {
    public static readonly type = '[Trademark State] Load Owner By Inn';
    constructor(public readonly payload: string | null) { }
  }

  export class LoadOwnerByInnSuccess {
    public static readonly type = '[Trademark State] Load Owner By Inn Success';
    constructor(public readonly payload: SearchLegalResponse | null) { }
  }

  export class LoadOwnerByInnFail {
    public static readonly type = '[Trademark State] Load Owner By Inn Fail';
  }

  export class SetMKTUListByDraft {
    public static readonly type = '[Trademark State] Set MKTU List By Draft';
    constructor(public readonly payload: number[]) { }
  }

  export class LoadMKTUByLastRequest {
    public static readonly type = '[Trademark State] Load MKTU By Last Request';
  }

  export class LoadMKTUByLastRequestSuccess {
    public static readonly type = '[Trademark State] Load MKTU By Last Request Success';
    constructor(public readonly payload: LastRequestTrademark) { }
  }

  export class LoadMKTUByLastRequestFail {
    public static readonly type = '[Trademark State] Load MKTU By Last Request Fail';
  }

  export class LoadDocumentById {
    public static readonly type = '[Trademark State] Load Document By Id';
  }

  export class LoadDocumentByIdSuccess {
    public static readonly type = '[Trademark State] Load Document By Id Success';
    constructor(public readonly payload: LoadDocumentByIdResponseHits) { }
  }

  export class LoadDocumentByIdFail {
    public static readonly type = '[Trademark State] Load Document By Id Fail';
  }

  export class LoadMKTUByOKVED {
    public static readonly type = '[Trademark State] Load MKTU By OKVED';
  }

  export class LoadMKTUByOKVEDSuccess {
    public static readonly type = '[Trademark State] Load MKTU By OKVED Success';
    constructor(public readonly payload: LoadMktuByOkvedResponseHits) { }
  }

  export class LoadMKTUByOKVEDFail {
    public static readonly type = '[Trademark State] Load MKTU By OKVED Fail';
  }

  export class AddMKTUClass {
    public static readonly type = '[Trademark State] Add MKTU Class';
    constructor(public readonly payload: MKTU) { }
  }

  export class RemoveMKTUClass {
    public static readonly type = '[Trademark State] Remove MKTU Class';
    constructor(public readonly payload: number) { }
  }

  export class SaveMKTUForRequest {
    public static readonly type = '[Trademark State] Save MKTU For Request';
  }

  export class SaveMKTUForRequestSuccess {
    public static readonly type = '[Trademark State] Save MKTU For Request Success';
  }

  export class SaveMKTUForRequestFail {
    public static readonly type = '[Trademark State] Save MKTU For Request Fail';
  }

  export class LoadTariffes {
    public static readonly type = '[Trademark State] Load Tariffes';
  }

  export class LoadTariffesSuccess {
    public static readonly type = '[Trademark State] Load Tariffes Success';
    constructor(public readonly payload: TariffResponse) { }
  }

  export class LoadTariffesFail {
    public static readonly type = '[Trademark State] Load Tariffes Fail';
  }

  export class SelectTariff {
    public static readonly type = '[Trademark State] Select Tariff';
    constructor(public readonly payload: Tariff['id']) { }
  }

  export class ApplyPromoCode {
    public static readonly type = '[Trademark State] Apply Promo Code';
    constructor(public readonly payload: TrademarkDraftPromocode) { }
  }

  export class ToPaymentStage {
    public static readonly type = '[Trademark State] To Payment Stage';
  }

  export class ToPaymentStageSuccess {
    public static readonly type = '[Trademark State] To Payment Stage Success';
  }

  export class ToPaymentStageFail {
    public static readonly type = '[Trademark State] To Payment Stage Fail';
  }

  export class PaymentCompletion {
    public static readonly type = '[Trademark State] Payment Completion';
    constructor(public readonly payload: OrganizationInterface) { }
  }

  export class PaymentCompletionSuccess {
    public static readonly type = '[Trademark State] Payment Completion Success';
  }

  export class PaymentCompletionFail {
    public static readonly type = '[Trademark State] Payment Completion Fail';
  }

  export class CancelRequest {
    public static readonly type = '[Trademark State] Cancel Request';
  }

  export class ReloadTrademarkDraft {
    public static readonly type = '[Trademark State] Reload Trademark Draft';
    constructor(public readonly id: string) { }
  }

  export class ReloadTrademarkDraftSuccess {
    public static readonly type = '[Trademark State] Reload Trademark Draft Success';
    constructor(public readonly payload: TrademarkDraftTasks) { }
  }

  export class ReloadTrademarkDraftFail {
    public static readonly type = '[Trademark State] Reload Trademark Draft Fail';
  }

  export class SendIntercomEvent {
    public static readonly type = '[Trademark State] Send Intercom Event';
    constructor(public readonly payload: INTERCOM_EVENT_NAME) { }
  }

  export class UpdateMKTUClasses {
    public static readonly type = '[Trademark State] Update MKTU Classes';
    constructor(public readonly payload: MKTU[]) { }
  }
}
