<section class="section-position section-position--tariff">
  <div class="container container--small">
    <h1>Продлить договор № РД0328224 от 13.03.2020</h1>
    <div class="attention attention--in-card" >
      <h3>Важно</h3>
      <p>Срок регистрации 2,5 - 3 месяца. Рекомендуем следить за обновлением статусов в разделе «дела» для своевременного
        продоставления данных, которые требуются для подачи заявления в Роспатент.</p>
    </div>
  </div>

  <div class="container container--small tariff">
    <div class="attention attention--white attention--in-card " >
      <p><b>Тариф «Продлить договор»</b></p>
      <div class="attention__show attention__show--open">
        <ul class="attention__list" >
          <li>
            Подготовка стандартного доп.соглашения к лицензионному договору с учетом интересов клиента или проверка подготовленного правообладателем договора
          </li>
          <li>
            Оплата государственной пошлины
          </li>
          <li>
            Подача пакета документов на регистрацию доп.соглашения к лицензионному договору в Федеральный институт промышленной собственности
          </li>
          <li>
            Ведение делопроизводства
          </li>
          <li>
            Получение документов о регистрации договора в Роспатенте
          </li>
        </ul>
      </div>
      <p><b class="attention--in-card__price">24 980 ₽</b></p>
    </div>
  </div>


  <div class="container container--small tariff">
    <div class="duty">
      <p>Пошлины Роспатента</p>
      <p>23 100 ₽</p>
    </div>
  </div>

  <div class="container container--small">
    <div class="tariff-footer tariff-footer__position">
      <a class="tariff-footer__back" routerLink="/index/item"><span>Назад</span></a>
      <div class="tariff-footer__total-wrapper">
        <div class="tariff-footer__total">
          <p>Итого к оплате: <b>58 600 ₽</b></p>
        </div>
        <button class="btn-blue" type="button">Оплатить</button>
      </div>
    </div>
  </div>
</section>
