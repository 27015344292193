import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestContractService } from '../../shared/request-contract.service';
import { ContractService } from '../../contract.service';
import { FormValidationService } from '../../../../shared/services/form-validation.service';
import {UserProfileService} from '../../../../../../../shared/services/user-profile.service';
import {IOrganization} from '../../../../shared/models/main.interfaces';

@Component({
  selector: 'app-form-resident',
  templateUrl: './form-resident.component.html',
  styleUrls: ['./form-resident.component.scss']
})
export class FormResidentComponent implements OnInit, OnDestroy {

  @Input() index;
  @Input() side: 'owner' | 'recipient';

  @Output() selectNonResident: EventEmitter<any> = new EventEmitter();

  dropdown = false;
  dropdownBik;
  lawFromResident: FormGroup;
  data;
  loadingOwner = false;
  organizations = [];
  organization: IOrganization;
  viewOrganizationsList = false;

  constructor(
    private fb: FormBuilder,
    private requestContractService: RequestContractService,
    private contractService: ContractService,
    private validationService: FormValidationService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit(): void {
    if (this.requestContractService[this.side + 's'][this.index]) {
      this.initFullForm();
    } else {
      this.initEmptyForm();
    }

    if (this.userProfileService.getOrganizationsData()) {
      this.organizations = this.userProfileService.getOrganizationsData().slice(0).filter(org => !org.pending);
    }
  }

  showOrganizationsList() {
    if (this.organizations.length) {
      this.viewOrganizationsList = true;
    }
  }

  get validation() {
    return this.requestContractService.validation;
  }

  get contractAction() {
    return this.requestContractService.contractAction;
  }

  initEmptyForm(): void {
    this.lawFromResident = this.fb.group({
      search: new FormControl(null),
      INN: new FormControl(null),
      director: new FormControl(null),
      address: new FormControl(null),
      BIK: new FormControl(null, Validators.pattern(this.validationService.onlyNumbersPattern)),
      account: new FormControl(null, Validators.pattern(this.validationService.onlyNumbersPattern)),
    });
    this.setValue();
  }

  initFullForm() {
    this.lawFromResident = this.fb.group({
      search: new FormControl(this.requestContractService[this.side + 's'][this.index].name),
      INN: new FormControl(this.requestContractService[this.side + 's'][this.index].inn),
      director: new FormControl(this.requestContractService[this.side + 's'][this.index].director),
      address: new FormControl(this.requestContractService[this.side + 's'][this.index].address),
      BIK: new FormControl(this.requestContractService[this.side + 's'][this.index].bik),
      account: new FormControl(this.requestContractService[this.side + 's'][this.index].account),
    });
  }

  setValue() {
    if (this.side === 'owner') {
      if (this.requestContractService.owners[this.index] === undefined) {
        this.requestContractService.owners[this.index] = {
          notResident: false,
          inn: '',
          ogrn: '',
          name: '',
          shortName: null,
          bik: '',
          account: '',
          director: '',
          address: '',
        };
      }
      this.requestContractService.owners[this.index] = {
        notResident: false,
        inn: this.data?._source?.TIN || null || this.organization?.TIN || this.requestContractService.owners[this.index].inn || this.lawFromResident.value.INN,
        ogrn: this.data?._source?.PSRN || null || this.organization?.PSRN || this.requestContractService.owners[this.index].ogrn,
        director: this.lawFromResident.value.director,
        address: this.lawFromResident.value.address || this.requestContractService.owners[this.index].address,
        bik: this.lawFromResident.value.BIK,
        account: this.lawFromResident.value.account,
        name: this.data?._source?.name || null || this.organization?.name || this.requestContractService.owners[this.index].name,
        shortName: this.data?._source?.shortName || null
      };
    } else {
      if (this.requestContractService.recipients[this.index] === undefined) {
        this.requestContractService.recipients[this.index] = {
          notResident: false,
          inn: '',
          ogrn: '',
          name: '',
          shortName: null,
          bik: '',
          account: '',
          director: '',
          address: '',
        };
      }
      this.requestContractService.recipients[this.index] = {
        notResident: false,
        inn: this.data?._source?.TIN || null || this.organization?.TIN || this.requestContractService.recipients[this.index].inn || this.lawFromResident.value.INN,
        ogrn: this.data?._source?.PSRN || null || this.organization?.PSRN || this.requestContractService.recipients[this.index].ogrn,
        director: this.lawFromResident.value.director,
        address: this.lawFromResident.value.address || this.requestContractService.recipients[this.index].address,
        bik: this.lawFromResident.value.BIK,
        account: this.lawFromResident.value.account,
        name: this.data?._source?.name || null || this.organization?.name || this.requestContractService.recipients[this.index].name,
        shortName: this.data?._source?.shortName || null
      };
    }
  }

  getLegal(text?: string) {
    if (text?.length > 0) {
      this.viewOrganizationsList = false;
      this.dropdown = false;
      this.loadingOwner = true;
      this.contractService.getLegalByName(text).subscribe(data => {
        if (data.hits.hits.length && text === this.lawFromResident.value.search) {
          this.data = data.hits.hits.shift();
          this.dropdown = true;
          this.loadingOwner = false;
        } else {
          this.loadingOwner = false;
        }
      });
    } else {
      this.loadingOwner = false;
    }
  }

  setValueOwner() {
    this.dropdown = false;
    const inn = this.data._source.PSRN ? this.data._source.TIN + ' / ' + this.data._source.PSRN : this.data._source.TIN;
    this.lawFromResident.controls.INN.setValue(inn);
    if (this.data._source.directors) {
      const director = this.data._source.directors.shift();
      this.lawFromResident.controls.director.setValue(`${director.surname} ${director.name} ${director.patronymic}`);
    }
    this.lawFromResident.controls.address.setValue(this.data._source.address);
    this.lawFromResident.controls.search.setValue(this.data._source.name);
    this.getLegal();
    this.setValue();
  }

  setValueOrganization(organization) {
    if (!organization.nonResident) {
      this.organization = organization;
      this.lawFromResident.controls.search.setValue(null);
      this.viewOrganizationsList = false;
      const inn = this.organization.PSRN ? this.organization.TIN + ' / ' + this.organization.PSRN : this.organization.TIN;
      this.lawFromResident.controls.INN.setValue(inn);
      this.lawFromResident.controls.director.setValue(this.organization.director);
      this.lawFromResident.controls.address.setValue(this.organization.legalAdress);
      this.lawFromResident.controls.search.setValue(this.organization.name);
      this.getLegal();
      this.setValue();
    } else {
      this.selectNonResident.emit({
        side: this.side,
        organization,
        index: this.index
      });
    }
  }

  ngOnDestroy() {
  }
}
