import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { InputModule } from '@ui/components/input/input.module';
import { ButtonModule } from '@ui/components/button/button.module';
import { UserPhoneModule } from '@ui/user-phone/user-phone.module';
import { TrademarkInfoComponent } from './trademark-info/trademark-info.component';
import { TrademarkFormComponent } from './trademark-form/trademark-form.component';
import { TrademarkOwnerComponent } from './trademark-owner/trademark-owner.component';
import { OrganizationListComponent } from './organization-list/organization-list.component';
import { SelectionContainerComponent } from './selection-container/selection-container.component';



@NgModule({
  declarations: [
    TrademarkInfoComponent,
    TrademarkFormComponent,
    TrademarkOwnerComponent,
    OrganizationListComponent,
    SelectionContainerComponent,
  ],
  imports: [
    InputModule,
    CommonModule,
    ButtonModule,
    UserPhoneModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
  ],
  exports: [TrademarkOwnerComponent],
})
export class TrademarkOwnerModule { }
