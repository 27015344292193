export interface UserInterface {
  name: string;
  surname: string;
  position: string;
  email: string;
  phone: string;
  multiaccount: boolean;
  certificate_of_completion: boolean;
  role: string;
  userID?: string;
  userId?: string;
  image: string;
}

export interface CredentialsInterface {
  login?: string;
  pass?: string;
  code?: string;
}

export interface UserLoginInterface {
  token: string;
  user: UserInterface;
  validation: boolean;
  kontragents: KontragentInterface[];
  answer?: string;
}

export interface KontragentInterface {
  name: string;
  PSRN: string;
  selected: boolean;
}

export interface NgxsForm<T> {
  model: T;
  status?: 'VALID' | 'INVALID';
  dirty?: boolean;
}

export const enum REQUEST_TYPES {
  TRADEMARK_SEARCH = 'trademarkSearch',
  TRADEMARK = 'trademark',
  PATENT = 'patent',
  SOFT = 'soft',
  RUMCC = 'rumcc',
  CONTRACT = 'contract',
  CHECK_LABEL = 'checkLabel',
};