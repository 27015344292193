import { Component, Input, OnInit } from '@angular/core';
import countries from '../../../../../../app-search/src/app/pages/ip-id-search/json-data/countries';
import {Store} from '@ngxs/store';
import {CORP_SEARCH_TOKEN} from '@web/features/corp/corp-search/states/search.state';

@Component({
  selector: 'app-countries-list-view',
  templateUrl: './countries-list-view.component.html',
  styleUrls: ['./countries-list-view.component.scss']
})
export class CountriesListViewComponent implements OnInit {
  @Input() countries;
  @Input() countriesDropdown?: boolean;
  @Input() limit = 2;

  showAll = false;

  constructor(
    private store: Store
  ) {
  }

  ngOnInit(): void {
  }

  get showedItems() {
    if (!this.hasMoreThanLimit || this.showAll) {
      return this.countries;
    } else {
      return this.countries.slice(0, this.limit);
    }
  }

  onShowAll() {
    this.showAll = true;
  }

  get hasMoreThanLimit() {
    return this.countries?.length > this.limit;
  }

  getDescription(i: { code: number | string; description: string }) {
    const item = countries.find(a => a.code.toString() === i.toString());
    return item?.name;
  }

  public isMatching(code: string): boolean {
    return this.store.selectSnapshot(CORP_SEARCH_TOKEN).countriesList?.includes(code);
  }
}
