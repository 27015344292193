import { Component, Input, OnInit } from '@angular/core';
import { IApiCountersResponse } from 'projects/app-ipid/src/app/shared/models/api/api.model';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';

@Component({
  selector: 'app-portfolio-chart',
  templateUrl: './portfolio-chart.component.html',
  styleUrls: ['./portfolio-chart.component.scss']
})
export class PortfolioChartComponent implements OnInit {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  // внутреннее состояние
  activeRequests = 0;
  cases = [];

  @Input() set counters(counters: IApiCountersResponse) {
    this.cases = [
      {
        name: 'Товарные знаки',
        value: counters?.portfolio?.tradermarks ?? 0,
      },
      {
        name: 'Патенты',
        value: counters?.portfolio?.patents ?? 0,
      },
      {
        name: 'Программы и БД',
        value: counters?.portfolio?.soft ?? 0,
      },
      {
        name: 'Доменов',
        value: counters?.portfolio?.domains ?? 0,
      },
      {
        name: 'Авторских прав',
        value: counters?.portfolio?.authorRights ?? 0,
      }
    ];
    this.activeRequests = counters?.activeRequests ?? 0;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  // Получить сколько всего дел
  getTotalCases(): number {
    return this.cases.reduce((x, y) => x + y.value, 0);
  }

  // Получить максимальное значение одного из дел
  getMaxCases(): number {
    return Math.max.apply(Math, this.cases.map(o => o.value));
  }
}
