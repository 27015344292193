<div class="mktu-list">
  <div class="mktu-list__item prompt prompt__active"
       [style.position]="countriesDropdown ? 'initial' : ''"
       [class.mktu-list__item--critical]="isMatching(i)"
       *ngFor="let i of showedItems">
    {{i}}
    <span class="prompt__hidden">
     <span *ngIf="countries.length">{{getDescription(i)}}</span>
    </span>
  </div>
  <div class="mktu-list__item mktu-list__item--red" *ngIf="hasMoreThanLimit && !showAll" (click)="onShowAll()">
    + {{countries.length - limit}}</div>
</div>
