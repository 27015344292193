import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexRoutingModule } from './index-routing.module';
import { IndexSearchComponent } from './index-search/index-search.component';
import { FormsModule } from '@angular/forms';
import { IndexSliderComponent } from './index-slider/index-slider.component';
import { ServiceListWidgetComponent } from './widgets/service-list-widget/service-list-widget.component';


@NgModule({
  declarations: [
    IndexSearchComponent,
    IndexSliderComponent,
    ServiceListWidgetComponent,
  ],
    exports: [
        IndexSearchComponent,
        IndexSliderComponent,
        ServiceListWidgetComponent
    ],
  imports: [
    CommonModule,
    IndexRoutingModule,
    FormsModule
  ]
})
export class IndexModule { }
