<div class="monitoring-modal">
  <div class="monitoring-modal__wrap">
    <div class="monitoring-modal__header">
      <img src="/assets/images/monitoring-modal-patent.png" alt="" class="monitoring-modal__header-img" width="80" height="54">
      <p class="monitoring-modal__header-info" *ngIf="data.showId">RU {{data.showId}}</p>
      <p class="monitoring-modal__header-info" *ngIf="!data.showId">{{data.name}}</p>
    </div>
    <div class="monitoring-modal__info">
      <h1 class="monitoring-modal__title">{{getObjNameByIndex(data.index[0].id) + (data.index[0].id === 'domains' ? '' : ' № ' + data.showId)}}</h1>
      <p class="monitoring-modal__desc">{{data.name}}</p>
      <div class="monitoring-modal__table-wrap">
        <h2 class="monitoring-modal__table-title">События мониторинга</h2>
        <table class="monitoring-modal__table">
          <tr>
<!--            <th>Дата</th>-->
            <th>Уровень риска</th>
            <th>Количество рисков</th>
          </tr>
          <tr>
<!--            <td class="monitoring-modal__table-date"><span>29.03.2013</span></td>-->
            <td class="monitoring-modal__table-risk">
              <span class="monitoring-modal__table-risk__level monitoring-modal__table-risk__level--fatal"></span>
              <span>Критичных</span>
            </td>
            <td class="monitoring-modal__table-desc"><span>{{getCountRisksByLevel(4)}}</span></td>
          </tr>
          <tr>
<!--            <td class="monitoring-modal__table-date"><span>29.03.2013</span></td>-->
            <td class="monitoring-modal__table-risk">
              <span class="monitoring-modal__table-risk__level monitoring-modal__table-risk__level--critical"></span>
              Высоких
            </td>
            <td class="monitoring-modal__table-desc"><span>{{getCountRisksByLevel(3)}}</span></td>
          </tr>
          <tr>
<!--            <td class="monitoring-modal__table-date"><span>29.03.2013</span></td>-->
            <td class="monitoring-modal__table-risk">
              <span class="monitoring-modal__table-risk__level monitoring-modal__table-risk__level--possible"></span>
              Средних
            </td>
            <td class="monitoring-modal__table-desc"><span>{{getCountRisksByLevel(2)}}</span></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="monitoring-modal__footer">
<!--      <p class="monitoring-modal__footer-events">И еще 3 события доступно в мониторинге</p>-->
      <p class="monitoring-modal__footer-object">Подключите объект для отслеживания рисков и пресечения нарушений.</p>
      <button class="monitoring-modal__btn" (click)="selectObject()">Выбрать для подключения</button>
    </div>
    <button (click)="closeModal()" class="risk-modal__close"></button>
  </div>
  <div (click)="closeModal()" class="monitoring-modal__overlay"></div>
</div>

