<div style="display: flex; position: relative" (click)="openAccordion()">
  <div class="title"
       [class.title--black]="isOpen"
       [class.monitoring-accordion]="!isOpen"
       [class.empty]="!count && !hideCounter"
  >
    <p>{{title}}</p>
    <span *ngIf="titleTooltip" class="prompt prompt__question">?
      <span class="prompt__hidden">
              <p>{{titleTooltip}}</p>
      </span>
    </span>
  </div>

  <div class="right-panel">
    <div class="motinoring-btn-link" routerLink="/monitoring" *ngIf="monitoring.btn">Перейти в мониторинг</div>
    <div class="count" *ngIf="!hideCounter" [innerText]="!count ? '0' : count"></div>
    <svg [ngClass]="isOpen ? 'arrow-icon-open' : 'arrow-icon-close'" style="margin-top: 10px"
         width="20" height="12" viewBox="0 0 20 12" fill="none">
      <path d="M1 1.5L10 10.5L19 1.5" stroke="#152438" stroke-width="2"/>
    </svg>
  </div>
</div>

<div *ngIf="isOpen">
  <ng-content></ng-content>
</div>
