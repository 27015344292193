import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'getDutySum', pure: true })
export class GetDutySumPipe implements PipeTransform {
  public transform(value: number): string {
    let term1: number = 3500, term2 = 11500, term3 = 18000;
    if (value > 5) term1 = term1 + 1000 * (value - 5);
    if (value > 1) term2 = term2 + 2500 * (value - 1);
    if (value > 5) term3 = term3 + 1000 * (value - 5);
    return  (term1 + term2 + term3).toLocaleString('ru');
  }
}
