import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TreatyExtensionComponent } from './treaty-extension.component';


const systemRoutes: Routes = [{
      path: '',
      component: TreatyExtensionComponent
}];

@NgModule({
  imports: [RouterModule.forChild(systemRoutes)],
  exports: [RouterModule]
})
export class TreatyExtensionRoutingModule { }
