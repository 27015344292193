import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './components/list/list.component';
import { FiltersModule } from '@ui/filters/filters.module';
import { SharedModule } from '../../app-ipid/src/app/shared/shared.module';
import { UiSharedModule } from '@ui/shared/ui-shared.module';



@NgModule({
  declarations: [
    ListComponent
  ],
  exports: [
    ListComponent
  ],
  imports: [
    CommonModule,
    FiltersModule,
    SharedModule,
    UiSharedModule
  ]
})
export class ListModule { }
