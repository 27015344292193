<div class="parrent_modal">

  <div class="search-elements-modal">
    <div class="loading">
      <div class="loading__header">Поиск</div>
      <div class="loading__body">
        <div class="item">
          <div class="item__image">
            <div *ngIf="loadingBases else loading">
              <svg *ngIf="brandsearch else search" width="28" height="28" viewBox="0 0 30 30"
                   xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                <use xlink:href="#img-brand-search-done"></use>
              </svg>
              <ng-template #search>
                <svg width="28" height="28" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" version="1.1"
                     preserveAspectRatio="xMinYMin">
                  <use xlink:href="#img-done"></use>
                </svg>
              </ng-template>
            </div>
            <ng-template #loading>
              <svg class="is-loading" *ngIf="brandsearch else search" width="28" height="28"
                   viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
                <use xlink:href="#img-brand-search-loader"></use>
              </svg>
              <ng-template #search>
                <svg class="is-loading" width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"
                     version="1.1" preserveAspectRatio="xMinYMin">
                  <use xlink:href="#img-loader"></use>
                </svg>
              </ng-template>
            </ng-template>
          </div>
          <div class="item__text">
            База региональных товарных знаков
          </div>
        </div>

<!--        <div class="item">-->
<!--          <div class="item__image">-->
<!--            <div *ngIf="loadingInternational else loading">-->
<!--              <svg *ngIf="brandsearch else search" width="28" height="28" viewBox="0 0 30 30"-->
<!--                   xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">-->
<!--                <use xlink:href="#img-brand-search-done"></use>-->
<!--              </svg>-->
<!--              <ng-template #search>-->
<!--                <svg width="28" height="28" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" version="1.1"-->
<!--                     preserveAspectRatio="xMinYMin">-->
<!--                  <use xlink:href="#img-done"></use>-->
<!--                </svg>-->
<!--              </ng-template>-->
<!--            </div>-->
<!--            <ng-template #loading>-->
<!--              <svg class="is-loading" *ngIf="brandsearch else search" width="28" height="28"-->
<!--                   viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">-->
<!--                <use xlink:href="#img-brand-search-loader"></use>-->
<!--              </svg>-->
<!--              <ng-template #search>-->
<!--                <svg class="is-loading" width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"-->
<!--                     version="1.1" preserveAspectRatio="xMinYMin">-->
<!--                  <use xlink:href="#img-loader"></use>-->
<!--                </svg>-->
<!--              </ng-template>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--          <div class="item__text">-->
<!--            База международных товарных знаков-->
<!--          </div>-->
<!--        </div>-->

        <div class="item">
          <div class="item__image">
            <svg class="is-loading" *ngIf="brandsearch else search" width="28" height="28"
                 viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
              <use xlink:href="#img-brand-search-loader"></use>
            </svg>
            <ng-template #search>
              <svg class="is-loading" width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"
                   version="1.1" preserveAspectRatio="xMinYMin">
                <use xlink:href="#img-loader"></use>
              </svg>
            </ng-template>
          </div>
          <div class="item__text">
            База международных товарных знаков
          </div>
        </div>

<!--        <div class="item">-->
<!--          <div class="item__image">-->
<!--            <div *ngIf="loadingReestr else loading">-->
<!--              <svg *ngIf="brandsearch else search" width="28" height="28" viewBox="0 0 30 30"-->
<!--                   xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">-->
<!--                <use xlink:href="#img-brand-search-done"></use>-->
<!--              </svg>-->
<!--              <ng-template #search>-->
<!--                <svg width="28" height="28" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" version="1.1"-->
<!--                     preserveAspectRatio="xMinYMin">-->
<!--                  <use xlink:href="#img-done"></use>-->
<!--                </svg>-->
<!--              </ng-template>-->
<!--            </div>-->
<!--            <ng-template #loading>-->
<!--              <svg class="is-loading" *ngIf="brandsearch else search" width="28" height="28"-->
<!--                   viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">-->
<!--                <use xlink:href="#img-brand-search-loader"></use>-->
<!--              </svg>-->
<!--              <ng-template #search>-->
<!--                <svg class="is-loading" width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"-->
<!--                     version="1.1" preserveAspectRatio="xMinYMin">-->
<!--                  <use xlink:href="#img-loader"></use>-->
<!--                </svg>-->
<!--              </ng-template>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--          <div class="item__text">-->
<!--            Реестр юридических лиц-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="item">-->
<!--          <div class="item__image">-->
<!--            <svg class="is-loading" *ngIf="brandsearch else search" width="28" height="28"-->
<!--                 viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">-->
<!--              <use xlink:href="#img-brand-search-loader"></use>-->
<!--            </svg>-->
<!--            <ng-template #search>-->
<!--              <svg class="is-loading" width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg"-->
<!--                   version="1.1" preserveAspectRatio="xMinYMin">-->
<!--                <use xlink:href="#img-loader"></use>-->
<!--              </svg>-->
<!--            </ng-template>-->
<!--          </div>-->
<!--          <div class="item__text">-->
<!--            Домены-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>

  <div (click)="closeModal()" class="overlay-space"></div>
</div>

<div class="page-overlay" id="modal-overlay"></div>

<svg display="none">
  <defs>
    <g id="img-brand-search-loader">
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="18" y="13" fill="#EC0039" rx="1"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="16.121" y="10.464" fill="#EC0039" rx="1"
            transform="rotate(-45 16.121 10.464)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="13" y="10" fill="#EC0039" rx="1"
            transform="rotate(-90 13 10)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="10.464" y="11.879" fill="#EC0039" rx="1"
            transform="rotate(-135 10.464 11.879)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" y="13" fill="#EC0039" rx="1"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="3.394" y="23.192" fill="#EC0039" rx="1"
            transform="rotate(-45 3.394 23.192)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="13" y="28" fill="#EC0039" rx="1"
            transform="rotate(-90 13 28)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="23.192" y="24.606" fill="#EC0039" rx="1"
            transform="rotate(-135 23.192 24.606)"/>
    </g>
    <g id="img-done">
      <circle xmlns="http://www.w3.org/2000/svg" cx="15" cy="15" r="15" fill="#00AFEC"/>
      <circle xmlns="http://www.w3.org/2000/svg" cx="17" cy="17" r="13" fill="#152438"/>
      <path xmlns="http://www.w3.org/2000/svg" stroke="white" stroke-width="3" d="M11 17L15 21L23 13"/>
    </g>
    <g id="img-loader">
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="18" y="13" fill="#4E79B2" rx="1"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="16.121" y="10.464" fill="#2794CF" rx="1"
            transform="rotate(-45 16.121 10.464)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="13" y="10" fill="#01AFEC" rx="1"
            transform="rotate(-90 13 10)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="10.464" y="11.879" fill="#E91C46" rx="1"
            transform="rotate(-135 10.464 11.879)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" y="13" fill="#CF2957" rx="1"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="3.393" y="23.192" fill="#B63667" rx="1"
            transform="rotate(-45 3.393 23.192)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="13" y="28" fill="#9D4378" rx="1"
            transform="rotate(-90 13 28)"/>
      <rect xmlns="http://www.w3.org/2000/svg" width="10" height="2" x="23.192" y="24.607" fill="#775D94" rx="1"
            transform="rotate(-135 23.192 24.607)"/>
    </g>
    <g id="img-brand-search-done">
      <circle xmlns="http://www.w3.org/2000/svg" cx="15" cy="15" r="15" fill="#454545"/>
      <path xmlns="http://www.w3.org/2000/svg" stroke="white" stroke-width="3" d="M9 15L13 19L21 11"/>
    </g>
  </defs>
</svg>
