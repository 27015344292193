import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { RegistrationComponent } from '../../../../../shared/components/auth/registration/registration.component';
import { LoginComponent } from '../../../../../shared/components/auth/login/login.component';
import { RecoveryComponent } from '../../../../../shared/components/auth/recovery/recovery.component';
import { AuthPageGuard } from './auth-page.guard';
import { SsoAuthComponent } from './sso-auth/sso-auth.component';
import { MonitoringLandingAlfaComponent } from '../monitoring/monitoring-landing-alfa/monitoring-landing-alfa.component';
import { GoogleAuthComponent } from '../../../../../shared/components/auth/google-auth/google-auth.component';
import { ExternalAuthComponent } from '../../../../../shared/components/auth/external-auth/external-auth.component';

const authRoutes: Routes = [
  {
    path: 'sso',
    component: SsoAuthComponent,
  },
  {
    path: 'external-auth',
    component: ExternalAuthComponent,
  },
  {
    path: '',
    component: AuthComponent,
    canActivate: [AuthPageGuard],
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent, data: {isDebit: false}},
      {path: 'registration', component: RegistrationComponent, data: {isDebit: false}},
      {path: 'recovery', component: RecoveryComponent, data: {isDebit: false}},
      {path: 'external', component: GoogleAuthComponent},
      {
        path: 'monitoring/landing-alfa',
        component: MonitoringLandingAlfaComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthPageRoutingModule {
}
