import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';

@Component({
  selector: 'app-modal-add-user',
  templateUrl: './modal-add-user.component.html',
  styleUrls: ['./modal-add-user.component.scss']
})

export class ModalAddUserComponent implements OnInit {

  @Input() users;
  @Input() organizationId;
  usersForm: FormGroup;
  selected = false;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userProfileService: UserProfileService) {
  }

  @Output() modalEvent = new EventEmitter();

  ngOnInit() {
    if (!this.users.length) {
      this.toInvitePage();
    }
    this.usersForm = this.fb.group({
      users: this.fb.array(this.users.map(x => false)),
    });
  }

  selectAll() {
    this.users.forEach((item: any) => {
      item.selected = true;
    });
  }

  closeModal() {
    this.users.forEach((item: any) => {
      item.selected = false;
    });
    this.selected = false;
    this.modalEvent.emit();
  }

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  onChanged() {
    this.selected = false;
    this.users.forEach((item: any) => {
      if (item.selected) {
        this.selected = true;
      }
    });
  }

  addSelected() {
    this.loading = true;
    const query = {
      organization: this.organizationId,
      users: []
    };
    this.users.forEach(item => {
      if (item.selected) {
        query.users.push(item.id);
      }
    });
    this.userProfileService.inviteInteriorUser(query).subscribe(data => {
      if (data) {
        this.loading = false;
        this.closeModal();
        this.router.navigate(['/user/profile']);
      }
    });
  }

  toInvitePage() {
    this.closeModal();
    this.router.navigate(['/user/invite']);
  }
}
