import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { IpIdSearchModule } from '../../../../../app-search/src/app/app.module';
import {ExpertModule} from '../layout/expert/expert.mdoule';



@NgModule({
  declarations: [
    SearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    IpIdSearchModule,
    LayoutModule,
    ExpertModule
  ]
})
export class SearchModule { }
