import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { SystemPageModule } from './pages/system-page.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthPageModule } from './pages/auth/auth-page.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BnNgIdleService } from 'bn-ng-idle';
import { DemoService } from './shared/services/demo.service';
import { EnvService } from './shared/services/env.service';
import { IntercomService } from './shared/services/intercom.service';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ruLocale } from 'ngx-bootstrap/locale';
import { DataService } from './shared/services/api.service';
import { ErrorService } from './shared/services/error-service';
import { ToastrModule } from 'ngx-toastr';
import { IntegrationModule } from './pages/integration/integration.module';
import { HttpIntercept } from './shared/interceptors/http-interceptor';
import { NavigationService } from './shared/services/navigation.service';
import { MoqService } from './shared/services/moq.service';
import { PaymentModule } from '../../../shared/components/payment/payment.module';
import { UserPhoneModule } from '../../../shared/components/user-phone/user-phone.module';
import { DictionaryService } from '../../../shared/services/dictionary-service';
import { StrorageService } from '../../../shared/services/storage-service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TagInputModule } from 'ngx-chips';
import { WebModule } from '@web/web.module';
import { environment } from '../environments/environment';
import { NgxsModule, Store } from '@ngxs/store';
import { initializeAppFactory } from '@web/utils/initialize-app-factory.util';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { TrademarkState } from '@web/features/requests/trademark/store/trademark.state';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';

defineLocale('ru', ruLocale);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SystemPageModule,
    IntegrationModule,
    AuthPageModule,
    HttpClientModule,
    BrowserAnimationsModule,
    Ng2TelInputModule,
    BsDatepickerModule.forRoot(),
    ToastrModule,
    PaymentModule,
    ImageCropperModule,
    PaymentModule,
    UserPhoneModule,
    TagInputModule,
    UserPhoneModule,
    WebModule.forRoot({
      production: environment.production,
      BASE_URL_API: environment.BASE_URL_API,
      BASE_URL_OPEN: environment.BASE_URL_OPEN,
      FRONT_URL: environment.FRONT_URL,
      BASE_ELASTIC_URL_API: environment.BASE_ELASTIC_URL_API,
      isDebit: false,
    }),
    NgxsModule.forRoot([], {
      developmentMode: true,
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsActionsExecutingModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  providers: [
    BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true },
    DemoService,
    EnvService,
    IntercomService,
    DataService,
    DictionaryService,
    StrorageService,
    NavigationService,
    ErrorService,
    MoqService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeAppFactory,
      deps: [Store],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('ru');
  }
}
