import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[lvData]'
})
export class LvDataDirective {

  context: CarouselContext | null = null;

  constructor(
    private tpl: TemplateRef<CarouselContext>,
    private vcr: ViewContainerRef
  ) {
  }

  @Input('carouselFrom') images: string[];

  ngOnInit(): void {
    this.context = {
      $implicit: null,
    };

    this.vcr.createEmbeddedView(this.tpl, this.context);
  }

  setData(items) {
    this.context = {
      $implicit: items
    };
  }

}

export interface CarouselContext {
  $implicit: any[];
}
