import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitoringLandingService } from '../monitoring-landing/monitoring-landing.service';
import risksDataJson from '../json-data-risks/json-data-risks';
import { filter, map, take } from 'rxjs/operators';
import { IntegrationService } from '../../../shared/services/integration.service';
import { environment } from '../../../../environments/environment';
import { AuthGuard } from "../../../shared/guardes/auth-guard.service";

const hostUrl = `${window.location.protocol}//${window.location.host}`;
const HOST = (hostUrl.includes('localhost') || hostUrl.includes('127.0.0.1') || hostUrl.includes('10.1.33.225'))
  ? `${environment.BASE_URL_IMG}/tm`
  : `${hostUrl}/storage/tm`;

const RUTM_PATH = `${HOST}/RUTM`;
const WKTM_PATH = `${HOST}/WKTM`;
const WOTM_PATH = `${HOST}/WOTM`;
const RUTMAP_PATH = `${HOST}/RUTMAP`;

@Component({
  selector: 'app-monitoring-landing-alfa',
  templateUrl: './monitoring-landing-alfa.component.html',
  styleUrls: ['./monitoring-landing-alfa.component.scss']
})
export class MonitoringLandingAlfaComponent implements OnInit {

  risksData = risksDataJson;
  riskDescription;

  riskId: string;
  // Временно
  risk;

  // Временно - есть объект риска
  objectRisk = true;
  opposedImgUrl: string;
  imgUrl: string;
  brand: string;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly monitoringLandingService: MonitoringLandingService,
    private readonly integrationService: IntegrationService,
    private readonly authGuard: AuthGuard,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(
        map(i => i.get('id')?.toString()),
        filter(i => !!i),
        take(1)
      )
      .subscribe((riskId) => {
        this.riskId = riskId;
        this.brand = environment.BRAND;
        if (!this.riskId || this.brand !== 'ALFA') {
          this.router.navigate(['/']);
          return;
        }
        if (localStorage.getItem('currentUserIPID')) {
          this.toFullRiskPage();
        } else {
          this.authGuard.setPreviousBlockedUrl('/monitoring/landing');
        }
        this.monitoringLandingService.fetchRiskById(this.riskId).subscribe(data => {
          this.risk = data;
          if (this.risk.markImageFileName) {
            const id = this.risk.registrationString || this.risk.applicationString;
            this.imgUrl = this.getImgUrl(this.risk.index, parseInt(this.risk.id, 10), this.risk.markImageFileName, id);
          }
          if (this.risk.risk.opposedObject?.markImageFileName) {
            const id = this.risk.risk.opposedObject.registrationString || this.risk.risk.opposedObject.applicationString;
            this.opposedImgUrl = this.getImgUrl(this.risk.risk.opposedObject._index, parseInt(this.risk.risk.opposedObject._id, 10), this.risk.risk.opposedObject.markImageFileName, id);
          }
          this.riskDescription = this.risksData.find(el => el.type === this.risk.risk.type);
        });
      });
  }

  public getRiskNameByLevel(): string {
    switch (this.risk.risk.level) {
      case 1:
        return 'Рекомендация';
      case 2:
        return 'Средний риск';
      case 3:
        return 'Высокий риск';
      case 4:
        return 'Критичный риск';
    }
  }

  public getImgUrl(
    type: string,
    id: number,
    markImageFileName: string,
    fullId?: string
  ): string {
    if (type === 'rutm') {
      return `${RUTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wktm') {
      return `${WKTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wotm') {
      return `${WOTM_PATH}/${this.generatePath(id)}/${markImageFileName}`;
    } else if (type === 'rutmap') {
      const year = id.toString().slice(0, 4);
      const realId = parseInt(id.toString().slice(4), 10);
      return `${RUTMAP_PATH}/NEW${year}/${this.generatePath(
        realId,
      )}/${id}/${markImageFileName}`;
    }
  }

  public generatePath(num): string {
    if (num < 1000) {
      return `0`;
    }
    const paths: string[] = [];
    const n = num.toString();
    for (let i = 1; n.length - i >= 3; i++) {
      paths.push(n.slice(0, i) + '0'.repeat(n.length - i));
    }
    return paths.join('/');
  }

  public getObjNameByIndex(index): string {
    switch (index) {
      case 'rutm':
        return 'Товарный знак';
      case 'rutmap':
        return 'Заявка на товарный знак';
      case 'domains':
        return 'Домен';
      case 'organizations':
        return 'Организация';
      case 'individual_entrepreneurs':
        return 'Индивидуальный предприниматель';
    }
  }

  toRegistrationPage() {
    this.integrationService.setRiskId(this.riskId);
    localStorage.removeItem('integration/alfa_monitoring_risk_id');
    localStorage.setItem('integration/alfa_is_registration_page', 'true');
    this.router.navigate(['/registration']);
  }

  toLoginPage() {
    this.integrationService.setRiskId(this.riskId);
    localStorage.removeItem('integration/alfa_monitoring_risk_id');
    this.router.navigate(['/login']);
  }

  toFullRiskPage() {
    this.integrationService.setRiskId(this.riskId);
    localStorage.removeItem('integration/alfa_monitoring_risk_id');
    this.router.navigate(['/monitoring/landing'], { queryParams: {id: this.riskId}});
  }
}
