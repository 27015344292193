import {
  KontragentInterface,
  UserInterface,
  UserLoginInterface,
} from '@web/core/models/user.interface';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthActions } from '@web/core/states/auth.actions';
import { StorageService } from '@web/core/services/storage.service';
import { AuthApiService } from '@web/core/services/auth-api.service';
import { AppState } from '@web/core/states/app.state';
import {
  RightsItemInterface,
  RightsResponse,
} from '@web/core/models/rights.interface';
import { RightsApiService } from '@web/core/services/rights-api.service';
import { ORGANIZATIONS_STATE_TOKEN } from '@web/core/states/organization.state';
import { promisify } from '@web/utils/promisify.util';
import { ActivatedRoute } from '@angular/router';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';
import { WebModuleConfig } from '@web/core/services/config.service';

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State({
  name: AUTH_STATE_TOKEN,
  defaults: {
    isLoading: false,
    isAuthorized: false,
    token: null,
    error: null,
    user: null,
    kontragents: null,
    validation: null,
    rights: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private storage: StorageService,
    private authApi: AuthApiService,
    private store: Store,
    private rightsService: RightsApiService,
    private route: ActivatedRoute,
    private corpSearchService: CorpSearchService,
    private config: WebModuleConfig
  ) {
  }

  public static getRightByName(right: string) {
    return createSelector([AuthState.getRights], ({ rights }: RightsResponse) => {
      return rights.some(item => item.data.some(el => el.nameEng === right && el.value));
    });
  }

  @Selector()
  static isLoading(state: AuthStateModel) {
    return state.isLoading;
  }

  @Selector()
  static isAuth(state: AuthStateModel) {
    return state.isAuthorized;
  }

  @Selector()
  static getRights(state: AuthStateModel) {
    return state.rights;
  }

  @Selector()
  static getSearchRights(state: AuthStateModel) {
    let rightsSearch = [];
    state.rights?.rights?.find(el => {
      if (el?.title?.toLowerCase() === 'права на поиски') {
        rightsSearch = el?.data;
      }
    });
    return rightsSearch;
  }

  @Selector()
  static getUser(state: AuthStateModel) {
    return state.user;
  }

  @Action(AuthActions.Init)
  async init(ctx: Context) {
    const token = this.storage.getToken();
    if (token) {
      ctx.patchState({token, isLoading: true, isAuthorized: false});
      try {
        const user = await this.authApi.getUser();
        localStorage.setItem('currentUserData', JSON.stringify(user));
        ctx.patchState({
          isLoading: false,
          isAuthorized: true,
          user: user.userInfo,
        });
      } catch (error) {
        ctx.patchState({isLoading: false, error, token: null});
      }
    }
  }

  @Action(AuthActions.SetAuthorized)
  async setAuthAction(ctx: Context, {data}: AuthActions.SetAuthorized) {
    this.setAuthorized(ctx, data);
  }

  @Action(AuthActions.AttemptSsoLogin)
  async attemptSsoLogin(ctx: Context) {
    const isDebit = this.store.selectSnapshot(AppState.isDebit);
    const brand = this.store.selectSnapshot(AppState.getBrand);

    if (!isDebit && AppState.isBrandOpen(brand)) {
      document.location.href =
        `${this.config.BASE_URL_OPEN}/sso/oauth2/authorize?service=external&redirect_uri=https%3A%2F%2F${this.config.FRONT_URL}%2Fsso&realm=%2Fcustomer&response_type=code&client_id=gardium`;
    }
  }

  @Action(AuthActions.AttemptOauthLogin)
  async attemptOauthInfo(ctx: Context) {
    const params = await promisify(this.route.queryParams);
    if (!params.token) {
      return;
    }

    ctx.patchState({
      isLoading: true,
      isAuthorized: false,
      error: null,
      token: params.token,
    });

    try {
      const brand = this.store.selectSnapshot(AppState.getBrand);
      const loginInfo = await this.authApi.loginInfo(brand);
      this.setAuthorized(ctx, loginInfo);
    } catch (error) {
      console.error('Error: ', error);
      ctx.patchState({isLoading: false, isAuthorized: false, error});
    }
  }

  @Action(AuthActions.Login)
  async login(ctx: Context, {credentials}: AuthActions.Login) {
    ctx.patchState({isLoading: true, isAuthorized: false, error: null});
    try {
      const brand = this.store.selectSnapshot(AppState.getBrand);
      const userLogin = await this.authApi.login(credentials, brand);
      if (userLogin.answer) {
        ctx.patchState({
          isLoading: false,
          isAuthorized: false,
          error: userLogin.answer,
        });
      } else {
        this.setAuthorized(ctx, userLogin);
      }
    } catch (error) {
      console.error('Error: ', error);
      ctx.patchState({isLoading: false, isAuthorized: false, error});
    }
  }

  @Action(AuthActions.Logout)
  async logout(ctx: Context) {
    localStorage.removeItem('currentUserIPID');
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('kontragents');

    ctx.patchState({
      isLoading: false,
      isAuthorized: false,
      token: null,
      error: null,
      user: null,
      kontragents: null,
      validation: null,
      rights: null,
    });
  }

  private setAuthorized(ctx: Context, loginInfo: UserLoginInterface) {
    // TODO remove after moving project to ngxs
    localStorage.setItem('currentUserIPID', loginInfo.token);
    localStorage.setItem('currentUserData', JSON.stringify(loginInfo.user));
    localStorage.setItem('kontragents', JSON.stringify(loginInfo.kontragents));

    ctx.patchState({
      isLoading: false,
      isAuthorized: true,
      ...loginInfo,
    });
  }

  @Action(AuthActions.GetRights)
  async getRights(ctx: Context, {org}: AuthActions.GetRights) {
    const orgState = this.store.selectSnapshot(ORGANIZATIONS_STATE_TOKEN);
    const {user} = ctx.getState();

    if (
      orgState.organizations?.length &&
      orgState.organizations[0]?.id
    ) {
      ctx.patchState({isLoading: true, error: null});

      try {
        const contragentID =
          org?.name === 'Все организации' || !org
            ? orgState?.organizations[0]?.id
            : org?.id;
        const rights = await this.rightsService.getRights({
          params: {contragentID},
        });
        let rightsSearch: RightsItemInterface[] = [];
        rights?.rights?.find((el) => {
          if (el?.title?.toLowerCase() === 'права на поиски') {
            rightsSearch = el.data;
          }
        });
        if (rightsSearch?.length) {
          rightsSearch.forEach((right) => {
            if (right.name.toLowerCase() === 'по обозначению') {
              this.corpSearchService.searchTypeOptions[0].accept = right.value;
            } else if (right.name.toLowerCase() === 'по изображению') {
              this.corpSearchService.searchTypeOptions[1].accept = right.value;
            }
          });
        }
        ctx.patchState({
          isLoading: false,
          rights,
        });
      } catch (error) {
        console.error('Error: ', error);
        ctx.patchState({isLoading: false, isAuthorized: false, error});
      }
    }
    ctx.patchState({
      isLoading: false,
    });
  }
}

type Context = StateContext<AuthStateModel>;

export interface AuthStateModel {
  isLoading: boolean;
  isAuthorized: boolean;
  token: string | null;
  error: string | null;
  user: UserInterface | null;
  kontragents: KontragentInterface[] | null;
  validation: boolean | null;
  rights: RightsResponse | null;
}
