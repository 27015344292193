import { Store } from '@ngxs/store';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthState } from '@web/core/states/auth.state';
import { FormControl, FormGroup } from '@angular/forms';
import { UserPhoneState } from '../store/user-phone.state';
import { UserPhoneActions } from '../store/user-phone.actions';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { UserInterface } from '@web/core/models/user.interface';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment as ipidEnvironment } from '../../../app-ipid/src/environments/environment';
import { environment as debtEnvironment } from '../../../app-debit/src/environments/environment';

@Component({
  selector: 'app-user-phone',
  templateUrl: './user-phone.component.html',
  styleUrls: ['./user-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPhoneComponent {
  @ViewSelectSnapshot(AuthState.getUser) user: UserInterface;
  @ViewSelectSnapshot(UserPhoneState.codeError) codeError: string;
  @ViewSelectSnapshot(UserPhoneState.phoneError) phoneError: string;
  @ViewSelectSnapshot(UserPhoneState.codeSended) codeSended: boolean;
  @ViewSelectSnapshot(UserPhoneState.showSuccess) showSuccess: boolean;

  @Input() header: boolean = true;
  @Input() successText: boolean = false;
  @Input() animationStatus: boolean = false;
  
  @Input()
  set isDebit(value: boolean) {
    this.store.dispatch(new UserPhoneActions.SetCurrentBrand(value ? debtEnvironment.BRAND : ipidEnvironment.BRAND));
  }

  public count: number = 60;
  public counter$: Observable<number>;
  public form: FormGroup = new FormGroup({
    phone: new FormControl(),
    code: new FormControl(),
  })
  
  constructor(private readonly store: Store) { }

  public removeSuccess(): void {
    this.store.dispatch(new UserPhoneActions.RemoveSuccess(false));
  }

  public sendUserPhoneCode(): void {
    this.setTimer();
    this.store.dispatch(new UserPhoneActions.SendUserPhoneCode());
  }

  public checkUserPhoneCode(): void {
    this.store.dispatch(new UserPhoneActions.CheckUserPhoneCode());
  }

  public repeatSendUserPhoneCode(): void {
    this.count = 60;
    this.sendUserPhoneCode();
  }

  public resetPhoneForm(): void {
    this.store.dispatch(new UserPhoneActions.ResetUserPhoneForm());
  }

  private setTimer(): void {
    this.counter$ = timer(0, 1000).pipe(take(this.count), map(() => --this.count));
  }
}
