import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISearchHistorySearchListItem } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';


@Component({
  selector: 'app-trademarks-search',
  templateUrl: './trademarks-search.component.html',
  styleUrls: ['./trademarks-search.component.scss']
})
export class TrademarksSearchComponent implements OnInit {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  designation;

  // История поиска
  @Input() searches: ISearchHistorySearchListItem[] = [];

  constructor(
    private router: Router,
    readonly corpSearchService: CorpSearchService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  ngOnInit(): void {
  }

  get isDisabledSearch() {
    return !this.corpSearchService.searchTypeOptions[0].accept;
  }

  // Взяла со страницы index
  sendSearchParam() {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.PRINT_SEARCH });

    if (this.designation) {
      this.router.navigate([`/search/trademarks`], {queryParams: {q: this.designation, saveSearch: true}});
    }
  }

  sendEvent(event: any) {
    console.log(event);
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.PRINT_SEARCH_FOCUS_OUT });
  }
}
