import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import {
  IGetDocumentByIdRequestData, ILegalData, IMktuByOkved,
  ISearchByNumberMKTURequestData,
  ISearchByNumberOKVEDRequestData,
  ISearchByTermMKTURequestData,
  ISearchByTermOKVEDRequestData,
  ISearchRequestData,
  IGetOverByLegalId
} from '../models/search.interfaces';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ITrademarks } from '../../../../app-ipid/src/app/shared/models/main.interfaces';

const hostUrl = `${window.location.protocol}//${window.location.host}`;

// todo: для чего так делать ?
const HOST = (hostUrl.includes('localhost') || hostUrl.includes('127.0.0.1') || hostUrl.includes('10.1.33.225'))
  ? `${environment.BASE_URL_IMG}/tm`
  : `${hostUrl}/storage/tm`;

const RUTM_PATH = `${HOST}/RUTM`;
const WKTM_PATH = `${HOST}/WKTM`;
const WOTM_PATH = `${HOST}/WOTM`;
const RUTMAP_PATH = `${HOST}/RUTMAP`;
const KZTM_PATH = `${HOST}/KZTM`;
const UZTM_PATH = `${HOST}/UZTM`;
const BUTM_PATH = `${HOST}/BUTM`;

@Injectable({
  providedIn: 'root'
})


export class SearchService {

  // TODO: test credentials
  private user = 'aa';
  private password = 'Aa123456';

  constructor(
    private httpService: HttpService
  ) {
  }

  getHash() {
    return Base64.stringify(sha1(this.user.toLowerCase() + this.password));
  }

  // https://cf.9958258.ru/pages/viewpage.action?pageId=68812852
  getTradeMarks(query: ISearchRequestData) {
    const SHA1hash = this.getHash();
    return this.httpService
      .post({path: '_search', body: {...query, SHA1hash}})
      .pipe(this.pipeTrademarkImages());
  }

  searchByImage(query) {
    const SHA1hash = this.getHash();
    return this.httpService
      .post<any>({ path: '_searchbyimage', body: { ...query, SHA1hash } })
      .pipe(this.pipeTrademarkImages());
  }

  // https://cf.9958258.ru/pages/viewpage.action?pageId=78032533
  getOverTradeMarksOrDomains(query: IGetOverByLegalId) {
    const SHA1hash = this.getHash();
    return this.httpService
      .post({path: '_getObjectsByLegalId', body: {...query, SHA1hash}})
      .pipe(this.pipeTrademarkImages());
  }

  // https://cf.9958258.ru/pages/viewpage.action?pageId=68812875
  getDocumentById(query: IGetDocumentByIdRequestData) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_id', body: {...query, SHA1hash}});
  }

  // https://cf.9958258.ru/pages/viewpage.action?pageId=68812888
  searchByNumberMKTU(query: ISearchByNumberMKTURequestData) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_mktu', body: {...query, SHA1hash}});
  }

  searchByTermMKTU(query: ISearchByTermMKTURequestData) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_mktu', body: {...query, SHA1hash}});
  }

  // https://cf.9958258.ru/pages/viewpage.action?pageId=68812955
  searchByNumberOKVED(query: ISearchByNumberOKVEDRequestData) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_okved', body: {...query, SHA1hash}});
  }

  searchByTermOKVED(query: ISearchByTermOKVEDRequestData) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_okved', body: {...query, SHA1hash}});
  }

  // https://cf.9958258.ru/pages/viewpage.action?pageId=68823266
  getLegal(query: ILegalData) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_legal', body: {...query, SHA1hash}});
  }

  getMktyByOkved(query: IMktuByOkved) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_getmktubyokved', body: {...query, SHA1hash}});
  }

  getImgUrl(
    type: string | 'rutm' | 'wktm' | 'wotm' | 'rutmap' | 'kztm' | 'uztm' | 'butm',
    id: number,
    markImageFileName: string,
    fullId?: string
  ): string {
    console.log(1111);
    if (type === 'rutm') {
      return `${RUTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wktm') {
      return `${WKTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wotm') {
      return `${WOTM_PATH}/${this.generatePath(id)}/${markImageFileName}`;
    } else if (type === 'rutmap') {
      const year = id.toString().slice(0, 4);
      const realId = parseInt(id.toString().slice(4), 10);
      return `${RUTMAP_PATH}/NEW${year}/${this.generatePath(
        realId,
      )}/${id}/${markImageFileName}`;
    } else if (type === 'kztm') {
      return `${KZTM_PATH}/${markImageFileName}`;
    } else if (type === 'uztm') {
      return `${UZTM_PATH}/${markImageFileName}`;
    } else if (type === 'butm') {
      return `${BUTM_PATH}/${markImageFileName}`;
    }
  }

  generatePath(num) {
    if (num < 1000) {
      return `0`;
    }
    const paths: string[] = [];
    const n = num.toString();
    for (let i = 1; n.length - i >= 3; i++) {
      paths.push(n.slice(0, i) + '0'.repeat(n.length - i));
    }
    return paths.join('/');
  }

  getIndexType(data) {
    let tempIndexType;
    if (data._index === 'rutmap' || data._index === 'rugpap') {
      tempIndexType = `${data._source.applicationString}`;
    } else if (data._index === 'wotm') {
      tempIndexType = `WO ${data._source.registrationString || data._source.registrationNumber}`;
    } else {
      tempIndexType = `${data._source.registrationString || data._source.registrationNumber}`;
    }

    switch (data._index) {
      case 'rutm':
      case 'rutmap':
      case 'rugp':
      case 'rugpap':
      case 'wktm':
        tempIndexType = `RU ${tempIndexType}`;
    }

    return tempIndexType;
  }

  getLastRequestTrademark(query) {
    const SHA1hash = this.getHash();
    return this.httpService.post({path: '_searchlastmktu', body: {...query, SHA1hash}});
  }

  searchTrademarksByNumber(opts: { number: string }) {
    return this.httpService
      .post({
        path: '_searchobjects',
        body: {index: 'trademarks', name: opts.number, SHA1hash: this.getHash()}
      })
      .pipe(this.pipeTrademarkImages());
  }

  pipeTrademarkImages() {
    return map((data: any) => {
      if (data && data.hits && data.hits.hits) {
        data.hits.hits.forEach((item) => {
          let id = item._source.registrationString;
          // todo: проверять есть ли картинка
          if (item._index === 'rutmap' && item._source.applicationString) {
            id = item._source.applicationString;
          }
          if (id && item._source.markImageFileName) {
            item._source.imageUrl = this.getImgUrl(
              item._index,
              parseInt(id, 10),
              item._source.markImageFileName,
              id
            );
          }
        });
      }

      return data;
    });
  }
}
