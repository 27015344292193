  <div class="page-wrapper" *ngIf="!addOrganization">
  <main>
    <section class="form-section profile">
      <div class="container">
        <a (click)="backClicked()" class="back-link">
            <span>
              <svg width="12" height="8" viewBox="0 0 21 16">
                <g clip-path="url(#clip0)">
                 <path d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                  <rect width="21" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          Назад
        </a>
        <div class="container-sm">
          <h1 class="heading-h1 heading-h1--centered">Добавить компанию</h1>
          <form [formGroup]="addOrg">
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="query"
                       class="form__input field"
                       type="text"
                       required
                       (ngModelChange)="inputChange($event)">
                <span class="form__input-label">Добавить по ОГРН или ИНН</span>
                <div class="form__dropdown form-card" *ngIf="organizationsFromSearch.length">
                  <table class="form-card__table">
                    <thead class="dropdown-hover">
                    <tr>
                      <th>ОГРН</th>
                      <th>ИНН</th>
                      <th>Наименование компании или ИП</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let org of organizationsFromSearch" class="applicant-organization" (click)="selectingOrg(org)">
                      <td>{{org._source.PSRN}}</td>
                      <td>{{org._source.TIN}}</td>
                      <td class="black">{{org._source.name || org._source.fullName}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="form__group-dropdown">
              <div class="form__group" style="margin-bottom: 5px;" *ngIf="selectedOrganization">
                <div class="form__input-wrap">
                  <input formControlName="name"
                         class="form__input field prompt-field"
                         type="text"
                         required
                         disabled>
                  <span class="form__input-label prompt-label">Наименование организации</span>
                </div>
              </div>
              <div class="form__group" style="margin-bottom: 5px;" *ngIf="selectedOrganization">
                <div class="form__input-wrap">
                  <input formControlName="director"
                         class="form__input field prompt-field"
                         type="text"
                         required
                         disabled>
                  <span class="form__input-label prompt-label">Руководитель</span>
                </div>
              </div>
              <div class="form__group" style="margin-bottom: 5px;" *ngIf="selectedOrganization">
                <div class="form__input-wrap">
                  <input formControlName="OGRN"
                         class="form__input field prompt-field"
                         type="text"
                         required
                         disabled>
                  <span class="form__input-label prompt-label">ОГРН</span>
                </div>
              </div>
              <div class="form__group" style="margin-bottom: 5px;" *ngIf="selectedOrganization">
                <div class="form__input-wrap">
                  <input formControlName="INN"
                         class="form__input field prompt-field"
                         type="text"
                         required
                         disabled>
                  <span class="form__input-label prompt-label">ИНН</span>
                </div>
              </div>
              <div class="form__group" style="margin-bottom: 5px;" *ngIf="selectedOrganization">
                <div class="form__input-wrap">
                  <input formControlName="date"
                         class="form__input field prompt-field"
                         type="text"
                         required
                         disabled>
                  <span class="form__input-label prompt-label">Дата регистрации</span>
                </div>
              </div>
              <div class="form__group" *ngIf="selectedOrganization">
                <div class="form__input-wrap">
                  <input formControlName="region"
                         class="form__input field prompt-field"
                         type="text"
                         required
                         disabled>
                  <span class="form__input-label prompt-label">Регион</span>
                </div>
              </div>
            </div>
            <div class="form__group" style="margin-top: 40px;">
              <div class="form__input-wrap">
                <input type="tel"
                       formControlName="phone"
                       class="form__input field"
                       [ngClass]="showPhoneError() ? 'invalid' : ''"
                       [(ngModel)]="phone"
                       (input)="onInputPhone($event)"
                       required
                />
                <span class="form__input-label">Номер телефона</span>
              </div>
              <p class="error-invalid-message" *ngIf="showPhoneError()">Введите корректный номер телефона</p>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="email"
                       [ngClass]="showEmailError() ? 'invalid' : ''"
                       class="form__input form__input--linked field"
                       type="text"
                       required>
                <span class="form__input-label">Email</span>
              </div>
              <p class="error-invalid-message" *ngIf="showEmailError()">Введите корректный email</p>
            </div>
            <div class="form__footer">
              <button (click)="addingOrganization()" [class.add-org-button-disabled]="checkInvalidForm() || isLoading" class="btn">
                <span *ngIf='!isLoading ; else loader'> Добавить компанию </span>
              </button>
<!--              <button (click)="addingOrganization()" class="btn" [class.add-org-button-disabled]="checkInvalidForm()">Добавить компанию</button>-->
            </div>
            <ng-template #loader>
              <div class="loader"></div>
            </ng-template>
          </form>
        </div>
      </div>
    </section>
  </main>
</div>

<app-add-organization-confirm *ngIf="addOrganization"
                              [name]="selectedOrganization?._source?.name || selectedOrganization?._source?.fullName"
                              [success]="success"
></app-add-organization-confirm>
