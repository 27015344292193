<!--  Вкладки  -->
<div *ngIf="!isLoading; else load">
  <section class="section-position section-position--gradient">
    <div class="container control">
      <button class="control__btn"
              (click)="switchTab('trademarks'); "
              [class.control__btn--active]="tab === 'trademarks'">Товарные знаки
        <span class="control__count">{{trademarks.length}}<span *ngIf="getCountChecked(trademarks)"
                                                                class="control__division">/<span class="control__division--green">
      {{getCountChecked(trademarks)}}</span></span></span>
      </button>
      <button class="control__btn"
              [disabled]="!patents.length"
              (click)="switchTab('patents')"
              [class.control__btn--active]="tab === 'patents'">Патенты
        <span class="control__count">{{patents.length}}<span *ngIf="getCountChecked(patents)"
                                                             class="control__division">/<span class="control__division--green">
      {{getCountChecked(patents)}}</span></span></span>
      </button>
      <button class="control__btn" (click)="switchTab('softs')"
              [class.control__btn--active]="tab === 'softs'"
              [disabled]="!softs.length">Программы и БД
        <span class="control__count">{{softs.length}}<span *ngIf="getCountChecked(softs)"
                                                           class="control__division">/<span class="control__division--green">
      {{getCountChecked(softs)}}</span></span></span>
      </button>
      <button  class="control__btn" (click)="switchTab('search')"
               [class.control__btn--active]="tab === 'search'">Поиск по реестру
        <span class="control__count">{{searches.length}}<span *ngIf="checkedElasticId.length"
                                                              class="control__division">/<span class="control__division--green">
      {{checkedElasticId.length}}</span></span></span>
      </button>
    </div>
  </section>


  <section class="section-position section-position--white">
          <span class="control__mobile"
                [class.control__mobile--active]="showMobileFilter"
                (click)="showMobileFilter = true; makeBodyHidden()">
              <p>Фильтр по объектам</p>
          </span>
    <span *ngIf="showMobileFilter" class="control__mobile--close" (click)="showMobileFilter=false;makeBodyHidden()"></span>
    <div *ngIf="showMobileFilter" (click)="showMobileFilter = false; makeBodyHidden()" class="overlay overlay--search"></div>

    <!--  Товарные знаки  -->
    <div *ngIf="tab === 'trademarks'">
      <div class="container container__tabs container__bottom">
        <form *ngIf="showMobileFilter"
              class="search">
          <input class="search__input"
                 placeholder="Поиск по объектам"
                 [(ngModel)]="searchTextTrademarks"
                 [ngModelOptions]="{standalone: true}"
                 (ngModelChange)="applyFilter()"
                 name="searchTextTrademarks"/>
          <div class="search__wrapper">
            <div class="dropdown" style="max-width: initial; margin-right: 0">
              <button class="dropdown__button" (click)="showObjectType = !showObjectType" style="outline: none;">
                <span *ngIf="getCountCheckedObjects(typeOfTrademarks) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(typeOfTrademarks) === 0 ? 'Тип объекта:' : 'Тип объекта:'}}</span>
                <span *ngIf="getCountCheckedObjects(typeOfTrademarks) > 0" class="dropdown__button-text dropdown__button-text--check">
              Тип объекта: {{getCountCheckedObjects(typeOfTrademarks)}}</span>
                <img
                  class="dropdown__button-img"
                  [ngClass]="showObjectType ? 'rotate': ''"
                  src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
              </button>
              <div *ngIf="showObjectType" class="dropdown__menu-wrap">
                <div class="dropdown__menu">
                  <span class="dropdown__menu-title">Тип объекта:</span>
                  <ng-container *ngFor="let option of typeOfTrademarks">
                    <input class="visual-hidden dropdown__input" type="checkbox" name="{{option.value}}" id="{{option.id}}-type-tm"
                           [(ngModel)]="option.checked"
                           (ngModelChange)="applyFilter()"
                    >
                    <label class="dropdown__label"
                           for="{{option.id}}-type-tm">
                      <span>{{option.value}}</span>
                    </label>
                  </ng-container>
                </div>
              </div>
            </div>
            <div *ngIf="showObjectType" (click)="showObjectType = false;"
                 class="overlay overlay--searchList"
                 style="background: none;
                    z-index: 4;">
            </div>
          </div>
          <div class="search__btns-wrapper">
            <button style="order: 2" class="btn-blue btn-search" (click)="applyFilter();showMobileFilter=false;makeBodyHidden()">Найти
            </button>
            <button style="order: 1" class="btn-blue-attr btn-clean btn-clean-blue" (click)="clearFilter()">Очистить</button>
          </div>
        </form>
        <div *ngFor="let one of trademarks.slice(countPage * (page - 1), countPage * page)">
          <div class="row-white"  [class.row-white--green]="one.checked">
            <div class="row-white__img-text">
              <div class="row-white__img">
                <img [src]="one.imageURL | proxyImage" style="max-width: 100%; height: auto; max-height: 100%;">
              </div>

              <div class="row-white__title-text row-white__title-text--text">
                <div class="row-white__subtitle-wrapper">
                  <p class="row-white__subtitle">{{getTypeByIndex(one.index[0].id) + ' ' + (one.showId || one.id)}}</p>
                </div>
                <p class="row-white__text row-white__text--bold">{{trimString(one.name, 42)}}</p>
              </div>
            </div>

            <div class="row-white__title-text row-white__title-text--date line">
              <div class="row-white__subtitle" *ngIf="one.registrationDate">Дата регистрации
                <span class="prompt prompt__question">?
            <span class="prompt__hidden">
               <p>Дата, с которой вступила в силу правовая защита объекта</p>
            </span>
          </span>
              </div>
              <p class="row-white__text">{{one.registrationDate | date:'dd.MM.yyyy'}}</p>
            </div>

            <div class="row-white__title-text row-white__title-text--btn">
              <input type="checkbox" [id]="one.checkedId" [(ngModel)]="one.checked" (ngModelChange)="checkObject(false, one, one.checked)">
              <label [for]="one.checkedId" *ngIf="!one.checked" class="row-white__btn-choose">Выбрать объект</label>
              <label [for]="one.checkedId" *ngIf="one.checked" class="row-white__btn-choose--done">
                <span class="row-white__choose">Выбран</span>
                <span  class="row-white__delete"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="container container__tabs container__tabs--pag" *ngIf="trademarks.length > countPage">
        <app-pagination
          [page]="page"
          [currentPageNumber]="page"
          [totalCount]="trademarks.length"
          [itemPerPage]="countPage"
          (eventChangePage)="changePage($event)"
        ></app-pagination>
      </div>
    </div>

    <!--  Патенты  -->
    <div *ngIf="tab === 'patents'">
      <div class="container container__tabs container__bottom">
        <form *ngIf="showMobileFilter"
              class="search">
          <input class="search__input"
                 placeholder="Поиск по объектам"
                 [(ngModel)]="searchTextPatents"
                 [ngModelOptions]="{standalone: true}"
                 (ngModelChange)="applyFilter()"
                 name="searchTextPatents"/>
          <div class="search__wrapper">
            <div class="dropdown" style="max-width: initial; margin-right: 0">
              <button class="dropdown__button" (click)="showObjectType = !showObjectType" style="outline: none;">
                <span *ngIf="getCountCheckedObjects(typeOfPatents) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(typeOfPatents) === 0 ? 'Тип объекта:' : 'Тип объекта:'}}</span>
                <span *ngIf="getCountCheckedObjects(typeOfPatents) > 0" class="dropdown__button-text dropdown__button-text--check">
              Тип объекта: {{getCountCheckedObjects(typeOfPatents)}}</span>
                <img
                  class="dropdown__button-img"
                  [ngClass]="showObjectType ? 'rotate': ''"
                  src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
              </button>
              <div *ngIf="showObjectType" class="dropdown__menu-wrap">
                <div class="dropdown__menu">
                  <span class="dropdown__menu-title">Тип объекта:</span>
                  <ng-container *ngFor="let option of typeOfPatents">
                    <input class="visual-hidden dropdown__input" type="checkbox" name="{{option.value}}" id="{{option.id}}-type"
                           [(ngModel)]="option.checked"
                           (ngModelChange)="applyFilter()"
                    >
                    <label class="dropdown__label"
                           for="{{option.id}}-type">
                      <span>{{option.value}}</span>
                    </label>
                  </ng-container>
                </div>
              </div>
            </div>
            <div *ngIf="showObjectType" (click)="showObjectType = false;"
                 class="overlay overlay--searchList"
                 style="background: none;
                    z-index: 4;">
            </div>
          </div>
          <div class="search__btns-wrapper">
            <button style="order: 2" class="btn-blue btn-search" (click)="applyFilter();showMobileFilter=false;makeBodyHidden()">Найти
            </button>
            <button style="order: 1" class="btn-blue-attr btn-clean btn-clean-blue" (click)="clearFilter()">Очистить</button>
          </div>
        </form>
        <div *ngFor="let one of patents.slice(countPage * (page - 1), countPage * page)">
          <div class="row-white" [class.row-white--green]="one.checked" *ngIf="one.show">
            <div class="row-white__img-text">
              <div class="row-white__img">
                <img src="assets/portfolio/patents.svg" style="min-width: 100%; min-height: 100%; vertical-align: middle; display: inline-block;">
              </div>

              <div class="row-white__title-text row-white__title-text--text">
                <div class="row-white__subtitle-wrapper">
                  <p class="row-white__subtitle">{{getTypeByIndex(one.index[0].id) + ' ' + (one.showId || one.id)}}</p>
                </div>
                <p class="row-white__text row-white__text--bold">{{trimString(one.name, 42)}}</p>
              </div>
            </div>

            <div class="row-white__title-text row-white__title-text--date line">
              <div class="row-white__subtitle" *ngIf="one.registrationDate">{{isRequest(one.index[0].id) ? 'Дата подачи заявки' : 'Дата регистрации'}}
                <span class="prompt prompt__question">?
            <span class="prompt__hidden">
               <p>Дата, с которой вступила в силу правовая защита объекта</p>
            </span>
          </span>
              </div>
              <p class="row-white__text">{{one.registrationDate | date:'dd.MM.yyyy'}}</p>
            </div>

            <div class="row-white__title-text row-white__title-text--btn">
              <input type="checkbox" [id]="one.checkedId" [(ngModel)]="one.checked" (ngModelChange)="checkObject(false, one, one.checked)">
              <label [for]="one.checkedId" *ngIf="!one.checked" class="row-white__btn-choose">Выбрать объект</label>
              <label [for]="one.checkedId" *ngIf="one.checked" class="row-white__btn-choose--done">
                <span class="row-white__choose">Выбран</span>
                <span  class="row-white__delete"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="container container__tabs container__tabs--pag" *ngIf="patents.length > countPage">
        <app-pagination
          [page]="page"
          [currentPageNumber]="page"
          [totalCount]="patents.length"
          [itemPerPage]="countPage"
          (eventChangePage)="changePage($event)"
        ></app-pagination>
      </div>
    </div>

    <!--  Программы и БД  -->
    <div *ngIf="tab === 'softs'">
      <div class="container container__tabs container__bottom">
        <form *ngIf="showMobileFilter"
              class="search">
          <input class="search__input"
                 placeholder="Поиск по объектам"
                 [(ngModel)]="searchTextSofts"
                 [ngModelOptions]="{standalone: true}"
                 (ngModelChange)="applyFilter()"
                 name="searchTextSofts"
          />
          <div class="search__wrapper">
            <div class="dropdown" style="max-width: initial; margin-right: 0">
              <button class="dropdown__button" (click)="showObjectType = !showObjectType" style="outline: none;">
                <span *ngIf="getCountCheckedObjects(typeOfSofts) === 0" class="dropdown__button-text">{{ getCountCheckedObjects(typeOfSofts) === 0 ? 'Тип объекта:' : 'Тип объекта:'}}</span>
                <span *ngIf="getCountCheckedObjects(typeOfSofts) > 0" class="dropdown__button-text dropdown__button-text--check">
              Тип объекта: {{getCountCheckedObjects(typeOfSofts)}}</span>
                <img
                  class="dropdown__button-img"
                  [ngClass]="showObjectType ? 'rotate': ''"
                  src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
              </button>
              <div *ngIf="showObjectType" class="dropdown__menu-wrap">
                <div class="dropdown__menu">
                  <span class="dropdown__menu-title">Тип объекта:</span>
                  <ng-container *ngFor="let option of typeOfSofts">
                    <input class="visual-hidden dropdown__input" type="checkbox" name="{{option.value}}" id="{{option.id}}-type"
                           [(ngModel)]="option.checked"
                           (ngModelChange)="applyFilter()"
                    >
                    <label class="dropdown__label"
                           for="{{option.id}}-type">
                      <span>{{option.value}}</span>
                    </label>
                  </ng-container>
                </div>
              </div>
            </div>
            <div *ngIf="showObjectType" (click)="showObjectType = false;"
                 class="overlay overlay--searchList"
                 style="background: none;
                    z-index: 4;">
            </div>
          </div>
          <div class="search__btns-wrapper">
            <button style="order: 2" class="btn-blue btn-search" (click)="applyFilter();showMobileFilter=false;makeBodyHidden()">Найти
            </button>
            <button style="order: 1" class="btn-blue-attr btn-clean btn-clean-blue" (click)="clearFilter()">Очистить</button>
          </div>
        </form>
        <div *ngFor="let one of softs.slice(countPage * (page - 1), countPage * page)">
          <div class="row-white" [class.row-white--green]="one.checked" *ngIf="one.show">
            <div class="row-white__img-text">
              <div class="row-white__img">
                <img *ngIf="one.index[0].id === 'rudb'" src="assets/portfolio/databases.svg" style="min-width: 100%; min-height: 100%; vertical-align: middle; display: inline-block;">
                <img *ngIf="one.index[0].id === 'rusw'" src="assets/portfolio/programs.svg" style="min-width: 100%; min-height: 100%; vertical-align: middle; display: inline-block;">
              </div>

              <div class="row-white__title-text row-white__title-text--text">
                <div class="row-white__subtitle-wrapper">
                  <p class="row-white__subtitle">{{getTypeByIndex(one.index[0].id) + ' ' + (one.showId || one.id)}}</p>
                </div>
                <p class="row-white__text row-white__text--bold">{{trimString(one.name, 42)}}</p>
              </div>
            </div>

            <div class="row-white__title-text row-white__title-text--date line">
              <div class="row-white__subtitle" *ngIf="one.registrationDate">{{isRequest(one.index[0].id) ? 'Дата подачи заявки' : 'Дата регистрации'}}
                <span class="prompt prompt__question">?
            <span class="prompt__hidden">
               <p>Дата, с которой вступила в силу правовая защита объекта</p>
            </span>
          </span>
              </div>
              <p class="row-white__text">{{one.registrationDate | date:'dd.MM.yyyy'}}</p>
            </div>

            <div class="row-white__title-text row-white__title-text--btn">
              <input type="checkbox" [id]="one.checkedId" [(ngModel)]="one.checked" (ngModelChange)="checkObject(false, one, one.checked)">
              <label [for]="one.checkedId" *ngIf="!one.checked" class="row-white__btn-choose">Выбрать объект</label>
              <label [for]="one.checkedId" *ngIf="one.checked" class="row-white__btn-choose--done">
                <span class="row-white__choose">Выбран</span>
                <span  class="row-white__delete"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="container container__tabs container__tabs--pag" *ngIf="softs.length > countPage">
        <app-pagination
          [page]="page"
          [currentPageNumber]="page"
          [totalCount]="softs.length"
          [itemPerPage]="countPage"
          (eventChangePage)="changePage($event)"
        ></app-pagination>
      </div>
    </div>

    <div  *ngIf="tab === 'search'">
      <div class="container container__tabs container__bottom">
        <form class="search search--noIcon" *ngIf="showMobileFilter===true">
          <div tabindex="0" class="form__input-wrap form__input-wrap--select" (focusout)="showOptions=false" [class.form__input-wrap--select--after]="showOptions===true">
            <a class="form__link " (click)="showOptions=!showOptions" >{{selectedSearchType}}</a>
            <ul *ngIf="showOptions" class="form__select-list">
              <li *ngFor = "let type of searchTypes" class="form__li company-item" [class.form__li--selected]="type === selectedSearchType"
                  (click)="onSelect(type)">
                <p>{{type}}</p>
              </li>
            </ul>
          </div>
          <div class="input_icon_wrapper">
            <img class="loop" src="/assets/images/icon-search-blue.svg">
            <input class="search__input search__input--special" placeholder="Поиск по реестру" type="text" [(ngModel)]="querySearchText" [ngModelOptions]="{standalone: true}"/>
          </div>
          <div class="search__btns" style="display: block">
            <button class="search__btn search__btn--single btn-search" (click)="searchObjects()" [ngClass]="querySearchText ? '' : 'disabled-button'">Найти</button>
          </div>
        </form>
        <div class="search-text-result search-text-result--nothing"
             *ngIf="showNotFound">
          Не найдены объекты, соответствующие запросу.<br>
          Попробуйте изменить поиск<br>
        </div>
        <div class="row-white" *ngFor="let one of searches.slice(countPage * (page - 1), countPage * page)" [class.row-white--green]="one.checked">
          <div class="row-white__img-text row-white__img-text--reestr">
            <div class="row-white__img">
              <img [src]="one.imageUrl ? one.imageUrl : getImgStubByIndex(one._index || one.index)">
            </div>

            <div class="row-white__title-text row-white__title-text--text">
              <div class="row-white__subtitle-wrapper">
                <p class="row-white__subtitle">
                  {{getTypeByIndex(one._index || one.index) + ' ' + (one._source?.registrationString || one._source?.applicationString || one._source?.registrationNumber || one._source?.applicationNumber || one.id)}}
                </p>
              </div>
              <p class="row-white__text row-white__text--bold">
                {{trimString((one._source?.imageText || one._source?.inventionName || one._source?.programName || one._source?.dbName || one.name), 42)}}</p>
            </div>
          </div>

          <div class="row-white__title-text row-white__title-text--owner line">
            <div class="row-white__subtitle">Правообладатель
              <span class="prompt prompt__question">?
            <span class="prompt__hidden">
               <p>На кого зарегистрирован объект</p>
            </span>
          </span>
            </div>
            <p class="row-white__text">{{one._source?.applicantName || one._source?.patentHolders || one._source?.rightHolders || one?.rightholder}}</p>
          </div>

          <div class="row-white__title-text row-white__title-text--date line">
            <div class="row-white__subtitle" style="margin-right: 0">{{isRequest(one._index || one.index) ? 'Дата подачи заявки' : 'Дата регистрации'}}
              <span class="prompt prompt__question">?
            <span class="prompt__hidden">
               <p>Дата, с которой вступила в силу правовая защита объекта</p>
            </span>
          </span>
            </div>
            <p class="row-white__text">{{(one._source?.registrationDate || one._source?.applicationDate || one.date) | date:'dd.MM.yyyy'}}</p>
          </div>

          <div class="row-white__title-text row-white__title-text--btn">
            <input type="checkbox" [id]="one._id || one.id" [(ngModel)]="one.checked" (ngModelChange)="checkObject(true, one, one.checked)">
            <label [for]="one._id || one.id" *ngIf="!one.checked" class="row-white__btn-choose">Выбрать объект</label>
            <label [for]="one._id || one.id" *ngIf="one.checked" class="row-white__btn-choose--done">
              <span class="row-white__choose">Выбран</span>
              <span  class="row-white__delete"></span>
            </label>
          </div>

        </div>
      </div>
      <div class="container container__tabs container__tabs--pag" *ngIf="searches.length > countPage">
        <app-pagination
          [page]="page"
          [currentPageNumber]="page"
          [totalCount]="searches.length"
          [itemPerPage]="countPage"
          (eventChangePage)="changePage($event)"
        ></app-pagination>
      </div>
    </div>
  </section>
</div>
<ng-template #load>
  <div class="loader"></div>
</ng-template>
