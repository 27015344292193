<div class="events-steps__hidden">
  <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
    <h3>Истек срок ожидания ответа на претензию</h3>
    <app-warning-text></app-warning-text>
    <div class="hidden__blocks-wrapper">
      <div class="hidden__block">
        <h4 class="hidden__subtitle">направленная претензия должнику</h4>
        <p class="hidden__text">Вы можете посмотреть претензию которую мы отправили должнику 10.08.2020.</p>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Претензия</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/green-key.png" class ="docs__icon--mini">
                             <img src="/assets/icons/orange-empty.svg">
                          </span>
          </div>
        </div>
      </div>
      <div class="hidden__block">
        <h4 class="hidden__subtitle">Ваше решение</h4>
        <div class="hidden__choice">
          <p>Получил оплату напрямую</p>
<!--          <svg width="24" height="24" viewBox="0 0 24 24">-->
<!--            <circle cx="12" cy="12" r="12"/>-->
<!--            <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>-->
<!--          </svg>-->
        </div>
      </div>
    </div>
  </div>

</div>
