import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MonitoringRoutingModule } from './monitoring-routing.module';
import { MonitoringModalComponent } from './monitoring-modal/monitoring-modal.component';
import { AddObjectCreateRequestComponent } from './add-object-create-request/add-object-create-request.component';
import { AddObjectPayComponent } from './add-object-pay/add-object-pay.component';
import { MonitoringLandingComponent } from './monitoring-landing/monitoring-landing.component';
import { TariffForContestationComponent } from './tariff-for-contestation/tariff-for-contestation.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { AddObjectComponent } from './add-object/add-object.component';
import { RiskTrademarkModalComponent } from './risk-trademark-modal/risk-trademark-modal.component';
import { RiskDomainModalComponent } from './risk-domain-modal/risk-domain-modal.component';
import { RiskContractModalComponent } from './risk-contract-modal/risk-contract-modal.component';
import { RiskPatentModalComponent } from './risk-patent-modal/risk-patent-modal.component';
import { MonitoringComponent } from './monitoring.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { TextFieldModule } from '@angular/cdk/text-field';
import { PaymentModule } from '../../../../../shared/components/payment/payment.module';
import { ButtonModule } from '../../../../../shared/components/button/button.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {ExpertModule} from '../layout/expert/expert.mdoule';
import {MonitoringGuard} from './monitoring.guard';


@NgModule({
  declarations: [
    MonitoringComponent,
    MonitoringModalComponent,
    RiskPatentModalComponent,
    RiskContractModalComponent,
    RiskDomainModalComponent,
    RiskTrademarkModalComponent,
    AddObjectComponent,
    UserSettingsComponent,
    TariffForContestationComponent,
    MonitoringLandingComponent,
    AddObjectPayComponent,
    AddObjectCreateRequestComponent,
  ],
  imports: [
    CommonModule,
    MonitoringRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    TextFieldModule,
    PaymentModule,
    ButtonModule,
    PerfectScrollbarModule,
    ExpertModule
  ],
  exports: [
    MonitoringModalComponent,
  ],
  providers: [
    MonitoringGuard
  ]
})
export class MonitoringModule { }
