<div class="okved-list">
  <div class="okved-list__item prompt__active"
       [style.position]="countriesDropdown ? 'initial' : ''"
       *ngFor="let i of showedItems">
    {{i.code}}
    <div class="prompt__hidden" *ngIf="i.description"><p>{{i.description}}</p></div>
  </div>
  <div class="okved-list__item okved-list__item--blue" *ngIf="hasMoreThanLimit && !showAll" (click)="onShowAll()">
    + {{codes.length - limit}}</div>
</div>
