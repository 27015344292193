<div class="js-tabs__content tabs__content" id="cash">
  <form class="form" *ngIf="payer" [formGroup]="formPay">
    <div *ngIf="payer?.TIN || payer?.inn" class="form__group">
      <div class="form__input-wrap">
        <input class="form__input field"
               formControlName="INN"
               type="number"
               style="pointer-events: none">
        <span class="form__input-label"> ИНН</span>
      </div>
    </div>
    <div *ngIf="payer?.PSRN || payer?.ogrn" class="form__group">
      <div class="form__input-wrap">
        <input class="form__input field"
               formControlName="OGRN"
               type="number"
               style="pointer-events: none">
        <span class="form__input-label"> ОГРН</span>
      </div>
    </div>
    <div *ngIf="payer?.name || payer?.fullName" class="form__group">
      <div class="form__input-wrap">
        <input class="form__input field"
               formControlName="name"
               type="text"
               style="pointer-events: none">
        <span class="form__input-label">Наименование юрлица или ИП</span>
      </div>
    </div>
    <div *ngIf="payer?.address || payer?.legalAdress" class="form__group">
      <div class="form__input-wrap">
        <input class="form__input field"
               formControlName="address"
               type="text"
               style="pointer-events: none">
        <span class="form__input-label">Юридический адрес</span>
      </div>
    </div>
    <div *ngIf='!isRequestCheckLabel' class="form__group">
      <div class="form__input-wrap">
        <input class="form__input field"
               formControlName="sum"
               type="text"
               style="pointer-events: none">
        <span class="form__input-label">Сумма</span>
      </div>
    </div>
    <div style='font-size: 18px' *ngIf='isRequestCheckLabel'>
      * Сумма будет определена после анализа
    </div>
  </form>
</div>
