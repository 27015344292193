<section class="step-block" *ngIf="!loading; else load">
  <h1>{{getTitle()}}</h1>
  <div class="attention" >
    <h3>Рекомендация</h3>
    <p>Для формирования заявки приложите документы, описывающих программу или базу данных.
      Описания составляются в свободной форме и должны включать пункты, приведенные ниже.
    </p>
  </div>
  <div class="hidden">
    <div class="hidden__blocks-wrapper">
      <div class="hidden__block">
        <ul class="hidden__list" *ngIf="requestType === 'patent'">
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Рабочее название</b>, например «Способ термообработки пищевых продуктов», «Устройство для термообработки молочной продукции».
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Область применения</b>, например «Сельское хозяйство».
            </p>
          </li>
          <li *ngIf="selectedType !== 'rude'">
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Последовательность действий</b> на каждой стадии или <b>описание работы</b> устройства/установки
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Эскиз или чертеж</b> с подписанными названиями составных частей или узлов
            </p>
          </li>
          <li *ngIf="selectedType !== 'rude'">
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Преимущества</b> перед аналогами и за счёт чего они достигаются
            </p>
          </li>
          <li *ngIf="selectedType === 'rude' || selectedType === 'unknown'">
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Фотографии объекта</b>
            </p>
          </li>
        </ul>
        <ul class="hidden__list" *ngIf="requestType === 'soft' && selectedType !== 'unknown'">
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Рабочее название</b>, например «Программное обеспечение управления логистикой», «Агрохимические свойства почв».
            </p>
          </li>
<!--          <li>-->
<!--            <div class="hidden__list-svg">-->
<!--              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">-->
<!--                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--            </div>-->
<!--            <p>-->
<!--              <b>Реферат</b> для публикации в официальном бюллетене Роспатента: назначение, область применения и функциональные возможности программы для ЭВМ или базы данных.-->
<!--            </p>-->
<!--          </li>-->
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Характеристики</b> языка программирования, системы управления регистрируемой базой данных, объема программы для ЭВМ или базы данных в машиночитаемой форме в единицах, кратных числу байт.
            </p>
          </li>
        </ul>
        <ul class="hidden__list" *ngIf="requestType === 'soft' && selectedType === 'unknown'">
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Рабочее название</b>, например «Способ термообработки пищевых продуктов», «Устройство для термообработки молочной продукции».
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Область применения</b>, например «Сельское хозяйство».
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Последовательность действий</b> на каждой стадии или <b>описание работы</b> устройства/установки
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Эскиз или чертеж</b> с подписанными названиями составных частей или узлов
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Преимущества</b> перед аналогами и за счёт чего они достигаются
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Фотографии объекта</b>
            </p>
          </li>
        </ul>
        <ul class="hidden__list" *ngIf="requestType === 'rumcc'">
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Название программного обеспечения</b>, например «Программное обеспечение управления логистикой», «Агрохимические свойства почв».
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Код (коды) продукции</b> в соответствии с Общероссийским классификатором продукции по видам экономической деятельности
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Сведения о правообладателях программного обеспечения,</b> информация заполняется в зависимости от статуса правообладателя
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Экземпляр программного обеспечения</b> с ссылкой на скачивание дистрибутива
            </p>
          </li>
          <li>
            <div class="hidden__list-svg">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p>
              <b>Иные документы</b> подтверждающие соответствие продукта критериям российского программного обеспечения и классу программного обеспечения
            </p>
          </li>
        </ul>
        <div class="add-file__btns-block add-file__btns-block--mobile">
          <button type="submit" class='btn-blue-attr' *ngIf="requestType === 'rumcc'" routerLink="/">Отменить</button>
          <button type="button" class="btn-back" *ngIf="requestType !== 'rumcc'" (click)="backStep()">Назад</button>
        </div>
      </div>
      <div class="hidden__block">
        <p>Прикрепите файл(ы) с описанием</p>
        <form class="add-file-form">
          <div class="add-file-form__file add-file-form__file--black" *ngFor="let file of filesSaved, index as i">
            <p>{{trimString(file.fileName, 30)}}</p>
            <span (click)="removeFile(i, filesSaved)"></span>
          </div>
          <div class="add-file-form__file add-file-form__file--black" *ngIf="linkSaved">
            <p style="word-break: break-all"><span class="add-file-form__file--gray">URL: </span>{{linkSaved}}</p>
            <span (click)="removeLink()"></span>
          </div>
          <div class="add-file-form__block add-file" >
            <div class="add-file__img" (click)="openModal()">
              <svg width="36" height="36" viewBox="0 0 32 33">
                <path d="M17.5586 14.6914H22.3086V17.8086H17.5586V22.5586H14.4414V17.8086H9.69141V14.6914H14.4414V9.94141H17.5586V14.6914ZM31.8086 5.93359V22.5586L22.3086 32.0586H5.68359C4.19922 32.0586 2.91276 31.5143 1.82422 30.4258C0.735677 29.3372 0.191406 28.0508 0.191406 26.5664V5.93359C0.191406 4.44922 0.735677 3.16276 1.82422 2.07422C2.91276 0.985677 4.19922 0.441406 5.68359 0.441406H26.3164C27.8008 0.441406 29.0872 0.985677 30.1758 2.07422C31.2643 3.16276 31.8086 4.44922 31.8086 5.93359ZM28.6914 6.45312C28.6914 5.66146 28.3945 4.99349 27.8008 4.44922C27.2565 3.85547 26.5885 3.55859 25.7969 3.55859H6.20312C5.41146 3.55859 4.71875 3.85547 4.125 4.44922C3.58073 4.99349 3.30859 5.66146 3.30859 6.45312V26.1953C3.30859 26.987 3.58073 27.6797 4.125 28.2734C4.71875 28.8177 5.41146 29.0898 6.20312 29.0898H20.75V26.7148C20.75 25.181 21.2943 23.8698 22.3828 22.7812C23.4714 21.6927 24.7826 21.1484 26.3164 21.1484H28.6914V6.45312Z"/>
              </svg>
            </div>
            <div class="add-file__label" (click)="openModal()">Прикрепить документы или указать ссылку</div>
            <span class="add-file__subtitle add-file__subtitle--no-hover" (click)="openModal()">DOC, PDF, изображение или URL</span>
            <span class="prompt prompt__question">?
              <span class="prompt__hidden" style="top: auto">
                <div *ngIf="requestType === 'patent'">
                 Добавьте любые данные, которые помогут защитить ваше изобретение:
                  <ul class="prompt__list">
                   <li>текстовый файл с описанием изобретения;</li>
                     <li>формула изобретения;</li>
                     <li>чертежи;</li>
                     <li>реферат.</li>
                  </ul>
                  Мы проведем поиск для определения патентоспособности изобретения. Переданная нам информация не разглашается.
                </div>

                <p *ngIf="requestType === 'soft'">Добавьте любые данные, которые помогут защитить вашу программу или базу данных.
                  На их основании мы подготовим реферат и заявление на регистрацию. Переданная нам информация не разглашается.</p>
               </span>
            </span>
          </div>
          <p>или опишите своими словами</p>
          <textarea class="add-file__textarea" placeholder="Ваше описание" rows="5" [(ngModel)]="designation" name="designation"></textarea>
          <div class="add-file__btns-block">
            <div tabindex="0" class="add-file__special-btn" (click)="step2to3Click(true)" style="cursor: pointer">Уточнить позже
              <span class="prompt prompt__question">?
                <span class="prompt__hidden">
                  <p>Эксперт свяжется с вами для уточнения деталей сразу после отправки заявки, обязательно задайте все уточняющие вопросы.</p>
                </span>
            </span>
            </div>
            <button type="submit" class="btn-blue" (click)="step2to3Click(false)">Далее</button>
          </div>

        </form>
      </div>
    </div>
  </div>

  <div class="modal-universal" *ngIf="modalAddDocs">
    <div class="modal-universal__wrapper">
      <div class="modal-universal__main-wrapper edit-doc">
        <h1>Прикрепить документы</h1>
        <span class="modal-universal__close" (click)="closeModal()"></span>
        <!--        <form>-->
        <div class="add-file-form__wrap" *ngIf="files.length">
          <!--            <div class="add-file-form__file">-->
          <!--              <p>nazvanie_dogovora.pdf</p>-->
          <!--              <span></span>-->
          <!--            </div>-->

          <div class="add-file-form__file" *ngFor="let file of files, index as i">
            <p>{{trimString(file.fileName, 30)}}</p>
            <span (click)="removeFile(i, files)"></span>
          </div>
        </div>
        <div class="edit-doc__form">
          <input type="file" id="100" (change)="fileChangeEvent($event)">
          <label for="100">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <circle cx="12" cy="12" r="12" fill="#00AFEC"/>
              <rect x="11" y="7" width="2" height="10" fill="white"/>
              <rect x="17" y="11" width="2" height="10" transform="rotate(90 17 11)" fill="white"/>
            </svg>
            Прикрепить документы
          </label>
          <p class="file-error" *ngIf="fileError?.length">{{fileError}}</p>
          <p>или указать URL</p>
          <input type="text" placeholder="Указать ссылку" [(ngModel)]="link">
        </div>
        <div class="modal-universal__btn-wrapper">
          <button type="submit" class='btn-blue-attr' (click)="cancelModal()">Отменить</button>
          <button type="submit" class='btn-blue' (click)="safeModal()">Сохранить</button>
        </div>
        <!--        </form>-->
      </div>
    </div>
    <div (click)="closeModal()" class="modal-universal__overlay"></div>
  </div>
</section>
<ng-template #load>
  <div class="loader"></div>
</ng-template>
