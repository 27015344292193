import { PaymentState } from '../../store/payment.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { PaymentActions } from '../../store/payment.actions';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { PaymentTab, PAYMENT_TYPE } from '../../types/payment.types';

@Component({
  selector: 'app-payment-tabs',
  templateUrl: './payment-tabs.component.html',
  styleUrls: ['./payment-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentTabsComponent {
  @ViewSelectSnapshot(PaymentState.tabs) tabs: PaymentTab[];
  @ViewSelectSnapshot(PaymentState.currentTab) currentTab: PAYMENT_TYPE;
  @Dispatch() switchPaymentTab = (type: PAYMENT_TYPE) => new PaymentActions.SetCurrentTab(type);

  isOnlineTab(type: PAYMENT_TYPE): boolean {
    return  PAYMENT_TYPE.ONLINE === type;
  }
}
