import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class FilterService {
  private filterFn = new Map<string, any>();
  private filterValue = new ReplaySubject<Record<string, any>>(1);

  setFn(name: string, fn: any): void {
    this.filterFn.set(name, fn);
  }

  getFn(name: string): any {
    return this.filterFn.get(name);
  }

  set value(value: Record<string, any>) {
    this.filterValue.next(value);
  }

  get value$() {
    return this.filterValue.asObservable();
  }
}
