import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessorComponent } from '@ui/shared/components/abstract-value-accessor/abstract-value-accessor.component';

@Component({
  selector: 'app-ui-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangeComponent),
      multi: true
    }
  ]
})
export class DaterangeComponent extends AbstractValueAccessorComponent{
  val = '';

  @Input() placeholder: string;

  set value(val: any) {
    if (val !== undefined && this.val !== val) {
      console.log(val);
      this.val = val;
      this.onChange(val);
      this.onTouch();
    }
  }

  update(value: any) {
    this.value = value;
  }

}
