<div class="parrent_modal">
  <div class="modal">
    <ng-container *ngIf="!isLoading && sourceData?._source; else loader">
      <div class="top-hat">
        <a class="ext_link" href="{{getExternalLink(sourceData)}}" target="_blank">
          <div class="top-hat__logo-hat"></div>
          <div class="top-hat__info">
            {{ sourceData.indexType }}
          </div>
        </a>
        <button class="top-hat__btn-close" (click)="closeModal()"></button>
        <!--        <button type="button" class="top-hat__button">Задать вопрос онлайн</button>-->
      </div>

      <div class="trademark-body">
        <div class="trademark-body__buttons_risk" *ngIf="data._risk">
          <button *ngIf="data._risk.toLowerCase() ===  'high'" class="btn-risk box_button__warning">
            Высокий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'medium'" class="btn-risk box_button__middle">
            Средний риск
          </button>
          <button *ngIf="data._risk.toLowerCase() === 'low'" class="btn-risk box_button__low">
            Низкий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'none'" class="btn-risk box_button__none">
            Нет риска
          </button>
          <div class="risk-hint">
            <div class="risk-tooltip"
                 *ngIf="(data._risk.toLowerCase() ===  'high') || (data._risk.toLowerCase() ===  'medium')"
            >Этот объект создает значительные риски для вашего товарного знака
            </div>
            <div class="risk-tooltip" *ngIf="data._risk.toLowerCase() === 'low' || data._risk.toLowerCase() === 'none'"
            >Этот объект создает незначительные риски для вашего товарного знака
            </div>
          </div>
        </div>

        <div class="trademark-body__header">
          Заявка на товарный знак
          № {{sourceData._index === 'rutmap' || sourceData._index === 'rugpap' ? sourceData._source.applicationString : sourceData._source.registrationNumber}}
        </div>

        <div class="trademark-body__data">
          <div *ngIf="sourceData._source.markImageFileName; else text" class="image-container">
            <a (click)="showLogoModal()">
              <img class="image-container__image" [src]="data._source.imageUrl">
            </a>
            <div [class.open]="isShowLogoModal" class="image-container__modal" (click)="showLogoModal()">
              <img [src]="data._source.imageUrl">
            </div>
          </div>
          <ng-template #text>
            <div class="text-container">
              <div class="text-container__name">
              <span>
                {{data._source.imageText}}
              </span>
              </div>
            </div>
          </ng-template>

          <div class="info-container">
            <div class="info-container__top">
              <div class="feature" *ngIf="getStatusTrademark(sourceData)">
                <div class="title">Статус рассмотрения в Роспатенте</div>
                <div class="text status-trademark" [innerText]="getStatusTrademark(sourceData)"></div>
              </div>
              <div class="feature" *ngIf="sourceData._source.applicantName">
                <div class="title">
                  {{ sourceData._index === 'rutmap' || sourceData._index === 'rugpap' ? 'Заявитель' : 'Правообладатель' }}
                </div>
                <div class="text">
                  <div class="link-applicantName">{{sourceData._source.applicantName}}</div>
                </div>
              </div>
            </div>
            <div class="info-container__bottom">
              <div class="first-column">
                <div class="feature" *ngIf="sourceData._source.PSRN">
                  <div class="title">ОГРН</div>
                  <div class="text">
                    {{sourceData._source.PSRN}}
                  </div>
                </div>
                <div class="feature" *ngIf="sourceData._source.registrationDate" style="margin-bottom: 0">
                  <div class="title">Дата регистрации</div>
                  <div class="text">{{sourceData._source.registrationDate | date:'dd.MM.yyyy'}}</div>
                </div>
              </div>
              <div class="second-column">
                <div class="feature" *ngIf="sourceData._source.applicationDate">
                  <div class="title">Дата подачи заявки</div>
                  <div class="text">{{sourceData._source.applicationDate | date:'dd.MM.yyyy'}}</div>
                </div>
                <div class="feature" *ngIf="sourceData._source.expiryDate">
                  <div class="title">Дата истечения</div>
                  <div class="text">{{sourceData._source.expiryDate | date:'dd.MM.yyyy'}}</div>
                </div>
              </div>
            </div>
            <div class="info-container__top">
              <div class="feature" *ngIf="sourceData._source.disclaimer" style="margin-bottom: 0">
                <div class="title">Неохраняемые элементы</div>
                <div class="text">{{sourceData._source.disclaimer}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <app-accordion
          [title]="'Классы МКТУ'"
          [count]="sourceData?._source?.goodsServices?.length">
          <div *ngIf="sourceData._source.goodsServices">
            <ul>
              <li *ngFor="let classes of getMktuClasses()">
                <div class="mktu-class">
                  <div class="class-number">{{classes.classNumber}} <span>класс</span></div>
                  <div class="class-description"
                       onclick="this.className = (this.className === 'class-description' ? 'class-description-long' : 'class-description')">{{classes.gsDescription}}</div>
                </div>
              </li>
            </ul>
            <button *ngIf="sourceData._source.goodsServices.length > 3 && viewClassesCount === 'short'"
                    (click)="showAllClasses()" class="show-all-btn">Показать
              еще {{sourceData._source.goodsServices.length - 3}}</button>
          </div>
        </app-accordion>


        <div class="divider"></div>
        <app-accordion
          [title]="'История рассмотрения заявок в Роспатенте'"
          [count]="sourceData && sourceData._source && sourceData._source.changesHistory && sourceData._source.changesHistory.length"
        >
          <div *ngIf="sourceData._source.changesHistory" class="change-date__wrapper">
            <ul class="change-date__wrap">
              <li *ngFor="let change of sourceData._source.changesHistory" style="display: flex; flex-direction: row">
                <div class="change-date">{{change.registrationDate | date: 'dd.MM.yyyy'}}</div>
                <div class="change-description">{{change.changeType}}</div>
                <div class="change-description" style="width: 450px" [innerText]="getChangeCode(change)"></div>
              </li>
            </ul>
          </div>
        </app-accordion>


        <div class="divider"></div>
        <app-accordion
          [title]="'Другие товарные знаки этого правообладателя'"
          [count]="countOverTradeMarks"
        >
          <div class="trademarks-list__wrap">
            <div class="trademarks-list" *ngIf="overTradeMarks">
              <div class="trade-mark-card" *ngFor="let tradeMark of overTradeMarks; let i = index"
                   [style.margin-right.px]="(i + 1) % 4 === 0 ? '0' : '40'">
                <div class="image-body" *ngIf="tradeMark && tradeMark._source">
                  <img *ngIf="tradeMark._source.imageUrl && tradeMark._source.markImageFileName"
                       [src]="tradeMark._source.imageUrl" alt="">
                  <div *ngIf="!tradeMark._source.imageUrl || !tradeMark._source.markImageFileName"
                       class="text-instead-image">{{ tradeMark._source.imageText }}</div>
                </div>
                <div class="image-text"
                     *ngIf="tradeMark._source && tradeMark._source.indexType">{{tradeMark._source.indexType}}</div>
                <div *ngIf="tradeMark.typeOfOwner === 'owner'" class="label" [innerText]="'владелец'"></div>
                <div *ngIf="tradeMark.typeOfOwner === 'user'" class="label" [innerText]="'по договору'"></div>
              </div>
            </div>
          </div>
        </app-accordion>

        <!--        <div class="divider"></div>-->
        <!--        <app-accordion-->
        <!--          [title]="'Споры'"-->
        <!--          [count]="0"-->
        <!--        >-->
        <!--        </app-accordion>-->
      </div>
    </ng-container>
  </div>

  <div (click)="closeModal()" class="overlay-space"></div>
</div>

<div class="page-overlay" id="modal-overlay"></div>

<ng-template #loader>
  <div class="loader-wrapper">
    <app-ls-emb-loader></app-ls-emb-loader>
  </div>
</ng-template>
