import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risks-widget',
  templateUrl: './risks-widget.component.html',
  styleUrls: ['./risks-widget.component.scss']
})

export class RisksWidgetComponent implements OnInit {

  @Input() risks;

  constructor() {
  }

  ngOnInit(): void {
  }

  getTotalRisks(): number {
    return this.risks.critical + this.risks.high + this.risks.medium + this.risks.recommendation;
  }

  getMaxRisk(): number {
    return Math.max(+this.risks.critical, +this.risks.high, +this.risks.medium, +this.risks.recommendation, +this.risks.unconnected);
  }
}
