<div class="events-steps__hidden events-steps__hidden--special">
  <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm===true"
        class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
    <h3>Претензия удовлетворена с условием <span></span></h3>

    <app-warning-text></app-warning-text>

    <div class="hidden__blocks-wrapper">
      <div class="hidden__block">
        <h4 class="hidden__subtitle">Ответ от должника</h4>
        <p class="hidden__text">Пожалуйста, скачайте официальный ответ от должника для изучения. Нужен текст.</p>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Ответ #1</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/green-key.png" class ="docs__icon--mini">
                             <img src="/assets/icons/orange-empty.svg">
                          </span>
          </div>
        </div>
      </div>
      <div class="hidden__block">
        <h4 class="hidden__subtitle">Ваше решение</h4>
        <p>Возможно тут лучше разместить текст с информацией о возможном выборе и дискуссии с должником.</p>
        <form>
          <fieldset>
            <div class="hidden__input-wrapper input-checkbox" >
              <input type="radio" name="agreement" id="5">
              <label class="input-checkbox__label" for="5" >
                Полностью согласен с условиями должника
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </label>
            </div>
            <div class="hidden__input-wrapper input-checkbox">
              <input type="radio" name="agreement" id="6" >
              <label  class="input-checkbox__label" for="6" >
                Подготовить альтернативное предложение
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </label>
            </div>
            <div class="hidden__input-wrapper input-checkbox">
              <input type="radio" name="agreement" id="7" >
              <label class="input-checkbox__label" for="7" >
                Не согласен, подать в суд
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="12"/>
                  <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
                </svg>
              </label>
            </div>
          </fieldset>
          <div class="hidden__btn-wrapper">
            <button type="submit" class='btn-blue' (click)="toHideForm=true; toHideForm1=false; toHideForm2=true; toHideForm3=true;">Продолжить</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-warning-payment *ngIf="attention && toHideForm===false"></app-warning-payment>

  <div  [class.events-steps__hidden-text-wrapper--toHide]="toHideForm1===true"
        class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
    <h3>Полностью согласен с условиями должника</h3>

    <app-warning-text></app-warning-text>

    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
      <h4 class="hidden__subtitle">Ваше решение</h4>
      <form>
        <fieldset>
          <div class="hidden__input-wrapper input-checkbox" >
            <input type="radio" name="agreement" id="8">
            <label class="input-checkbox__label" for="8" >
              Подготовить и согласовать дополнительное соглашение с должником за 1 490 ₽
              <svg width="24" height="24" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="12"/>
                <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
              </svg>
            </label>
          </div>
          <div class="hidden__input-wrapper input-checkbox">
            <input type="radio" name="agreement" id="9" >
            <label  class="input-checkbox__label" for="9" >
              Не хочу дополнительное соглашение, подожду оплату
              <svg width="24" height="24" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="12"/>
                <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
              </svg>
            </label>
          </div>
          <div class="hidden__input-wrapper input-checkbox">
            <input type="radio" name="agreement" id="10" >
            <label class="input-checkbox__label" for="10" >
              Составлю сам дополнительное соглашение
              <svg width="24" height="24" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="12"/>
                <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
              </svg>
            </label>
          </div>
        </fieldset>
        <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
          <a type="submit" class='btn-blue-attr'(click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;">Отменить</a>
          <button type="submit" class='btn-blue' (click)="toHideForm=true; toHideForm1=true; toHideForm2=false; toHideForm3=true;">Отправить</button>
        </div>
      </form>
    </div>
  </div>
  <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm2===true"
       class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
    <h3>Подготовить альтернативное предложение</h3>

    <app-warning-text></app-warning-text>

    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
      <h4 class="hidden__subtitle">Пожалуйста, уточните ваши условия</h4>
      <form>
        <fieldset>
          <div class="hidden__input-wrapper input-textarea" >
            <textarea  placeholder="Ваши условия" rows="6"></textarea>
          </div>
        </fieldset>
        <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
          <a type="submit" class='btn-blue-attr'(click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;" >Отменить</a>
          <button type="submit" class='btn-blue' (click)="toHideForm=true; toHideForm1=true; toHideForm2=true; toHideForm3=false;">Отправить</button>
        </div>
      </form>
    </div>
  </div>
  <div [class.events-steps__hidden-text-wrapper--toHide]="toHideForm3===true"
       class="events-steps__hidden-text-wrapper events-steps__hidden-text-wrapper--special hidden hidden--margin">
    <h3>Не согласен, подать в суд</h3>
    <div class="hidden__blocks-wrapper hidden__blocks-wrapper--column">
      <form>
        <app-warning-with-list [type]="'list'"></app-warning-with-list>
        <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
          <a type="submit" class='btn-blue-attr' (click)="toHideForm=false; toHideForm1=true; toHideForm2=true; toHideForm3=true;" >Отменить</a>
          <button type="submit" class='btn-blue'>Отправить</button>
        </div>
      </form>
    </div>
  </div>
</div>
