<div class="events-steps">
  <div *ngIf="testData.length > eventsLength" class="events-steps__showMore-wrapper">
    <span *ngIf="!showMore" class="events-steps__showMore-line"></span>
    <div *ngIf="!showMore" (click)="showMore=true" class="events-steps__showMore">Показать
      предыдущие {{testData.length - eventsLength}} события
    </div>
    <div *ngIf="showMore" class="events-steps__rows-wrapper events-steps__rows-wrapper--show">
      <div
        *ngFor="let point of testData.slice(0, testData.length-eventsLength); let i = index; let last = last"
        class="events-steps__row">
        <div class="events-steps__info">
          <div class="events-steps__user">
            <div class="events-steps__img">
              <img [src]="(point.imgSrc) ? (point.imgSrc | proxyImage) : '/assets/images/user.svg'">
            </div>
            <div *ngIf="point.expertName" class="events-steps__name events-steps__name--human">
              <p>{{point.expertName}}</p>
            </div>
            <div *ngIf="point.company" class="events-steps__name">
              <p>{{point.company}}</p>
            </div>
          </div>
          <div class="events-steps__state">
            <div style="display: flex; width: 100%">
              <div class="events-steps__state-wrap">{{point.title}}
                <div (click)="cardShown === point.eventID ? hideCard() : showCard(point.eventID)"
                     class="events-steps__btn-show"
                     [class.show]="cardShown === point.eventID">
                  <span *ngIf="point.popupText.length" class="events-steps__link">{{cardShown === point.popupText.length ? 'Свернуть' : 'Посмотреть' }}</span>
                </div>
                <input type="text" class="to-hide" value="Скрыть" readonly (click)="hideCard()">
              </div>
            </div>
          </div>
          <div class="events-steps__date">
            <p>{{point.date}}</p>
          </div>
        </div>
        <div [class.show]="cardShown===point.eventID">
          <app-tabs-types *ngIf="cardShown===point.eventID" [point]="point.type ? point.type : point"></app-tabs-types>
        </div>
      </div>
    </div>
  </div>
  <div [class.show]="cardShown === point.eventID || last && showLast"
       *ngFor="let point of testData.length> eventsLength ? testData.slice(testData.length-eventsLength,testData.length) : testData; let last = last"
       class="events-steps__row">
    <div class="events-steps__info">
      <div class="events-steps__user">
        <div class="events-steps__img">
          <img [src]="point.imgSrc ? (point.imgSrc | proxyImage) : '/assets/images/user.svg'">
        </div>
        <div *ngIf="point.expertName" class="events-steps__name events-steps__name--human">
          <p>{{point.expertName}}</p>
        </div>
        <div *ngIf="point.company" class="events-steps__name">
          <p>{{point.company}}</p>
        </div>
      </div>
      <div class="events-steps__state">
        <div style="display: flex; width: 100%">
          <div class="events-steps__state-wrap">{{point.title}}
            <div (click)="cardShown === point.eventID || last && showLast ? hideCard() : showCard(point.eventID)"
                 class="events-steps__btn-show"
                 [class.show]="cardShown === point.eventID">
              <span *ngIf="point.popupText.length > 0"
                    class="events-steps__link">{{cardShown === point.eventID || last && showLast ? 'Свернуть' : 'Посмотреть' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="events-steps__date">
        <p>{{point.date}}</p>
      </div>
    </div>
    <div [class.show]="cardShown===point.eventID" class="events-steps__info-wrap">
      <app-tabs-types
        *ngIf="cardShown===point.eventID || last && showLast && point.popupText.length > 0"
        [point]="point.type ? point.type : point"></app-tabs-types>
    </div>
  </div>
</div>
