import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { nextTick } from '../../../shared/utils/next-tick';
import { Location } from '@angular/common';
import { MainMenuService } from '../../layout/main-menu/main-menu.service';
import { RegistrationService } from '../../../../../../shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-change-profile',
  templateUrl: './change-profile.component.html',
  styleUrls: ['./change-profile.component.scss']
})

export class ChangeProfileComponent implements OnInit, OnDestroy {
  editUser: FormGroup;
  phoneForm: FormGroup;

  // Временный флаг - телефон подтвержден
  phoneVerified = false;
  isShowphoneVerifiedMessage = false;
  errorText;
  phoneErrorText;

  userData;
  file;
  image;
  disableButton = false;
  showCodeInput = false;
  emailPattern = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  base64textString = [];
  body = {
    name: '',
    image: ''
  };
  loading = false;
  email;

  imageError: string; // ошибка загрузки картинки
  isImageLoading: boolean; // лоадер загрузки картинки
  cardImageBase64: string; // картинка в формате base64

  brand = '';
  private readonly onDestroy = new Subject<void>();

  // showPersonalData = false;
  // personalData = false;

  constructor(
    private fb: FormBuilder,
    private profileApi: UserProfileService,
    private registerApi: RegistrationService,
    private router: Router,
    private location: Location,
    private menuApi: MainMenuService,
    private toastrService: ToastrService,
    private store: Store
  ) {
    this.brand = environment.BRAND;
  }

  ngOnInit(): void {
    this.initForm();
    if (this.store.selectSnapshot(AUTH_STATE_TOKEN).user) {
      this.userData = this.store.selectSnapshot(AUTH_STATE_TOKEN).user;
      this.prefillForm(this.userData);
    }
  }

  initForm(): void {
    const emailPattern = this.emailPattern;
    this.editUser = this.fb.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      position: ['', [Validators.required]],
      email: ['', [Validators.pattern(emailPattern)]]
    });
    this.email = this.editUser.value.email;

    this.phoneForm = this.fb.group({
      phone: ['+7', [Validators.required]],
      validationCode: ['', [Validators.required]],
    });
  }

  prefillForm(data) {
    this.editUser.get('name').setValue(data.name);
    this.editUser.get('email').setValue(data.email);
    this.editUser.get('surname').setValue(data.surname);
    this.editUser.get('position').setValue(data.position);

    this.phoneForm.get('phone').setValue('+7' + data.phone.slice(1));

    this.phoneVerified = this.phoneForm.value.phone.length > 2;
    this.showCodeInput = false;
  }

  checkEmail() {
    if (this.editUser.value.email.length) {
      this.disableButton = !this.emailPattern.test(this.editUser.value.email);
    } else {
      this.disableButton = true;
    }
  }

  /**
   * загрузка картинки
   * @param fileInput: файл
   */
  fileChangeEvent(fileInput: any): any {
    this.isImageLoading = true;
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const maxSize = 2000000;
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (fileInput.target.files[0].size > maxSize) {
        this.imageError = 'Максимальный размер файла ' + maxSize / 1000000 + 'Mb';

        return false;
      }

      if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
        this.imageError = 'Недопустимый тип файла';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          this.cardImageBase64 = e.target.result;
          const imageBody = {
            name: fileInput.target.files[0].name,
            image: this.cardImageBase64,
            delete: false
          };

          // Вынужденный говнокод, исправим после изменений методов на бэке
          // Чтобы загрузить новое изображение сначала нужно удалить предыдущее

          // Удаляем
          this.profileApi.addFile({
            name: null,
            image: null,
            delete: true,
          }).pipe(takeUntil(this.onDestroy))
            .subscribe(fakeData => {
              if (fakeData) {
                this.profileApi.addFile(imageBody).subscribe((data: any) => {
                  if (data && data.path) {
                    if (this.store.selectSnapshot(AUTH_STATE_TOKEN).user) {
                      this.userData = this.store.selectSnapshot(AUTH_STATE_TOKEN).user;
                      this.prefillForm(this.userData);
                    }
                  } else {
                    this.toastrService.error('Ошибка загрузки изображения');
                  }
                  this.isImageLoading = false;
                });
              } else {
                this.toastrService.error('Ошибка замены изображения');
              }
            });

        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  deleteImage() {
    this.profileApi.addFile({
      name: null,
      image: null,
      delete: true,
    }).pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data) {
          if (this.store.selectSnapshot(AUTH_STATE_TOKEN).user) {
            this.userData = this.store.selectSnapshot(AUTH_STATE_TOKEN).user;
            this.prefillForm(this.userData);
          }
        }
      });
  }

  editUsers(updateOnlyPhone?: boolean) {
    this.loading = true;
    const body = {
      name: this.editUser.value.name,
      surname: this.editUser.value.surname,
      position: this.editUser.value.position,
      email: this.editUser.value.email,
    };
    this.profileApi.updateUser(body).pipe(takeUntil(this.onDestroy)).subscribe(userData => {
      if (userData) {
        this.profileApi.getUser().pipe(takeUntil(this.onDestroy)).subscribe(data => {
          localStorage.setItem('currentUserData', (JSON.stringify(data.userInfo)));
          this.loading = false;
          if (!updateOnlyPhone) {
            // this.logout();
          }
        });
      }
    });
  }

  codeGetter() {
    this.phoneErrorText = '';
    const params = new HttpParams()
      .set('phone', this.phoneForm.value.phone.toString())
      .set('brand', this.brand);
    this.registerApi.getCode(params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data.answer === 'Успешно') {
          this.showCodeInput = true;
          this.phoneVerified = true;
        } else if (data.answer === 'Данный номер телефона уже зарегистрирован!') {
          this.phoneErrorText = data.answer;
          this.phoneVerified = false;
        } else {
          this.phoneVerified = false;
          this.phoneErrorText = data.answer;
        }
      });
  }

  initiatePhoneForm() {
    this.phoneErrorText = '';
    if (this.phoneForm.value.phone.length > 2) {
      this.phoneVerified = this.userData.phone === this.phoneForm.value.phone.slice(1);
    }
  }


  checkCode(event?, maxLength?) {
    this.phoneErrorText = '';
    if (maxLength === 4 && this.phoneForm.value.validationCode.length === 4) {
      const body = {
        phone: this.phoneForm.value.phone,
        validationCode: this.phoneForm.value.validationCode,
      };
      const params = new HttpParams()
        .set('brand', this.brand);
      this.registerApi.checkCode(body, params)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(data => {
          if (data.answer === 'Успешно') {
            this.phoneVerified = true;
            this.isShowphoneVerifiedMessage = true;
            this.showCodeInput = false;
          } else {
            this.phoneErrorText = data.answer;
          }
        });
    }

  }

  // getPersonalDataStatus(evt: boolean): void {
  //   this.personalData = evt;
  //   if (this.personalData === true) {
  //     this.showPersonalData = false;
  //   }
  // }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  onInputPhone() {
    nextTick(() => {
      let phone = this.phoneForm.controls.phone.value.slice(2).replace(/\D+/, '');
      if (phone.length > 10) {
        phone = phone.slice(0, 10);
      }
      phone = '+7' + phone;
      if (phone !== this.phoneForm.controls.phone.value) {
        this.phoneForm.controls.phone.setValue(phone);
      }
    });
  }

  validatePhone() {
    const re = new RegExp('^(\\+7|7|8)?[\\s\\-]?\\(?[489][0-9]{2}\\)?[\\s\\-]?[0-9]{3}[\\s\\-]?[0-9]{2}[\\s\\-]?[0-9]{2}$');
    return re.test(this.phoneForm.controls.phone.value);
  }

  public logout(): void {
    const token = localStorage.getItem('currentUserIPID');
    if (token) {
      const params = new HttpParams().set('logout', 'true');
      this.menuApi.logOut(params).pipe(takeUntil(this.onDestroy)).subscribe(data => {
        if (data) {
          localStorage.removeItem('currentUserIPID');
          localStorage.removeItem('kontragents');
          localStorage.removeItem('currentUserData');
          this.router.navigate(['/login']);
          localStorage.removeItem('currentUserIPID');
          this.router.navigate(['/login']);
        }
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  backClicked() {
    this.location.back();
  }

  removeSuccess() {
    this.phoneVerified = false;
    this.isShowphoneVerifiedMessage = false;
  }

}
