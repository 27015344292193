import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss']
})
export class FilterInputComponent implements OnInit {

  @Input() name: string;
  @Input() placeholder: string;

  @Input() value: string = '';

  @Output() apply = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onChange() {
    this.apply.emit(this.value);
  }

}
