export default [
  {
    id: 1,
    value: 'Товарный знак',
    checked: false,
    index: 'rutm'
  },
  {
    id: 2,
    value: 'Заявка на товарный знак',
    checked: false,
    index: 'rutmap'
  },
  {
    id: 3,
    value: 'Международный товарный знак',
    checked: false,
    index: 'wotm'
  },
  {
    id: 4,
    value: 'Общеизвестный товарный знак',
    checked: false,
    index: 'wktm'
  },
  {
    id: 5,
    value: 'Наименование мест происхождения',
    checked: false,
    index: 'rugp'
  },
  {
    id: 6,
    value: 'Заявка на наименование мест происхождения',
    checked: false,
    index: 'rugpap'
  }
];
