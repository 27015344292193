<div class="container container--slider">
  <h1>{{title}}</h1>
  <section class="slider__wrap">
    <carousel [cellWidth]="160" [margin]="15" [transitionDuration]="500" [arrows]="false" #slider>
      <div #cards (click)="click(card)" class="carousel-cell slider__item" *ngFor="let card of cards">
        <p>{{card.name}}</p>
      </div>
    </carousel>
    <button class="slider__btn slider__btn--prev" (click)="moveLeft()" [class.disabled]="disableArrow">
      <svg width="19" height="16" viewBox="0 0 19 16">
        <path
          d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM19 7L1 7V9L19 9V7Z"/>
      </svg>
    </button>
    <button *ngIf="cards.length > 5" class="slider__btn slider__btn--next" (click)="moveRight()">
      <svg width="19" height="16" viewBox="0 0 19 16">
        <path
          d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9H18V7H0V9Z"/>
      </svg>
    </button>
  </section>
</div>
