import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {
  transform(value: string, length: number, end: string = '...'): string {
    if (value.length > length) {
      return value.substr(0, length) + end;
    }
    return value;
  }
}
