<div tabindex="0" class="search__btn-block {{icon ? 'search__btn-block__icon-' + icon : ''}}" #rootEl>
  <p class="search__label">{{title}}:</p>
  <div class="search__select">
    <button class="search__select-btn" [class.search__select-btn--bold]="countSelected > 0">
      {{countSelected === 0 ? '' : countSelected}}
      <span class="search__select-btn-span"></span>

      <div class="search__hidden-options-wrapper">
        <div class="search__options-wrapper">

          <div *ngFor="let option of options" class="search__option">
            <input class="search__option-input"
                   type="checkbox"
                   [name]="uniqueOptionName(option)"
                   [id]="uniqueOptionName(option)"
                   [(ngModel)]="checked[option.id]"
                   (ngModelChange)="onApply()"/>
            <label class="search__option-label" [for]="uniqueOptionName(option)">{{option.label}}</label>
          </div>
        </div>

        <!--        <div class="search__btns-wrapper">-->
        <!--          <button type="button" class="search__btn-cancel" (click)="clean()">Очистить</button>-->
        <!--          <button type="submit" class="search__btn-submit" onclick="this.blur()" (click)="onApply()">Выбрать</button>-->
        <!--        </div>-->
      </div>
    </button>

  </div>
</div>
