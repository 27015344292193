<div class="js-tabs__content tabs__content" id="cash">
  <form class="form" [formGroup]="formPay">
    <div class="form__group">
      <div class="form__input-wrap">
        <input class="form__input field"
               formControlName="sum"
               type="text"
               style="pointer-events: none">
        <span class="form__input-label">Сумма</span>
      </div>
    </div>
  </form>
</div>
