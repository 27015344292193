import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-search-input',
  templateUrl: './corp-search-input.component.html',
  styleUrls: ['./corp-search-input.component.scss']
})
export class CorpSearchInputComponent implements OnInit {

  @Input() placeholder;
  @Input() value: string;
  @Input() type: string;
  @Input() disabledButton: boolean;


  @Output() search = new EventEmitter<string>();
  searchError = false;
  right;

  constructor(
    readonly activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const query = this.activatedRoute.snapshot.queryParamMap;

    if (!query.has('right')) {
      return;
    } else {
      this.right = query.get('right');
    }

  }

  apply() {
    this.search.emit(this.value);
  }

  isSearchAllowed(value) {
    if (value.length === 0) {
      this.searchError = true;
    } else {
      this.searchError = false;
      this.apply();
    }
  }

  getSearchErrorText() {
    if (this.type === 'number') {
      return 'Введите номер регистрации';
    } else {
      return 'Введите правообладателя';
    }
  }

}
