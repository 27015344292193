import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {HttpService} from '../../../shared/services/http.service';
import {document} from 'ngx-bootstrap/utils';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ORGANIZATIONS_STATE_TOKEN } from '@web/core/states/organization.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent implements OnInit {
  @Input() showModal;
  @Output() closeModalSupportPopup = new EventEmitter();
  rows = 5;
  fileError = ''; // ошибка загрузки файла
  isUploadingFile = false;
  files = [];
  data = {};
  emailPattern =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  organizations = [];
  showOrganizationsList = false;


  supportForm = new FormGroup({
    fio: new FormControl(),
    mail: new FormControl('', Validators.pattern(this.emailPattern)),
    organization: new FormControl(),
    request: new FormControl(),
  });

  constructor(
    private httpService: HttpService,
    private toastrService: ToastrService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    const orgState = this.store.selectSnapshot(ORGANIZATIONS_STATE_TOKEN);
    if (orgState?.organizations?.length) {
      orgState.organizations.forEach(el => this.organizations.push(el.name));
    }
  }

  closeModalSupport() {
    document.body.style.overflow = 'visible';
    document.body.style.position = '';
    document.body.style.height = 'auto';
    this.closeModalSupportPopup.emit(false);
  }

  /** Событие загрузки файла
   */
  onFileUpload(fileInput: any) {
    const file = fileInput?.target ? fileInput.target.files[0] : fileInput;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.files.push({
        name: file.name,
        base64: reader.result.toString().split(',')[1],
      });
    };
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  deleteFile(index): void {
    this.files.splice(index, 1);
  }

  setValue(): void {
    this.data = {
      fio: this.supportForm.value.fio,
      mail: this.supportForm.value.mail,
      organization: this.supportForm.value.organization,
      request: this.supportForm.value.request,
      files: this.files
    };
  }

  submitForm() {
    const body = {
      ...this.data,
      files: this.files,
      source: window.location.href,
      case: ''
    };
    this.httpService.post({ path: 'user/servicedesk', body: {...body} }).subscribe(data => {
      this.closeModalSupport();
      this.toastrService.success('Обращение успешно отправлено');
    });
  }

  showOrganizations() {
    if (this.organizations?.length) {
      this.showOrganizationsList = true;
    }

  }

}
