import { PaymentState } from '../../store/payment.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { PaymentActions } from '../../store/payment.actions';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganizationState } from '@web/core/states/organization.state';
import { OrganizationInterface } from '@web/core/models/organization.interface';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { EnvService } from 'projects/app-ipid/src/app/shared/services/env.service';
import { SearchService } from 'projects/app-search/src/app/services/search.service';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrls: ['./payer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayerComponent implements OnInit {
  @ViewSelectSnapshot(PaymentState.showOptions) showOptions: boolean;
  @ViewSelectSnapshot(PaymentState.payer) selectedPayerId: string | null;
  @ViewSelectSnapshot(OrganizationState.organizations) organizations: OrganizationInterface[];
  @ViewSelectSnapshot(PaymentState.currentPayer()) selectedPayer: OrganizationInterface | null;

  @Dispatch() setShowOptions = () => new PaymentActions.SetShowOptions();
  @Dispatch() setSearchedPayer = (payer: any) => new PaymentActions.SetSearchedPayer(payer);
  @Dispatch() setCurrentPayer = (comp: string | null) => new PaymentActions.SetCurrentPayer(comp);

  @Input() isDebit: boolean = false;

  public form: FormGroup = new FormGroup({
    payer: new FormControl('', Validators.required),
  });

  public tempPayer; // найденный плательщик по инн
  public selectedPayerFromSearch; // выбранный плательщик по инн

  constructor(
    public envService: EnvService,
    private readonly searchService: SearchService,
  ) { }

  public ngOnInit(): void {
    if (this.organizations.length > 0 && this.isDebit) {
      this.selectedPayerFromSearch = this.organizations[0];
      this.form.get('payer').setValue(this.selectedPayerFromSearch.TIN);
      this.setSearchedPayer(this.organizations[0]);
    }
  }

  public payerChanged(innOgrn: string): void {
    if (this.selectedPayerFromSearch?.TIN !== innOgrn) {
      this.tempPayer = undefined;
      if (this.envService.isCorp || (!this.envService.isCorp && !!+innOgrn === true)) {
        this.selectedPayerFromSearch = undefined;
      } else if (!this.envService.isCorp && !!+innOgrn === false) {
        this.selectedPayerFromSearch = innOgrn;
        this.setSearchedPayer(this.selectedPayerFromSearch);
      }
      if (innOgrn.length > 2) {
        this.getFullPayer(innOgrn);
      }
    }
  }

  public getFullPayer(id: string): void {
    this.searchService.getLegal({ id }).subscribe((data: any) => {
      if (data?.hits?.hits[0]?._source && id === this.form.controls.payer.value) {
        this.tempPayer = data.hits.hits[0]._source;
      }
    });
  }

  public checkValidInnOgrn(value): boolean {
    return value && !isNaN(+value) && (value.length === 10 || value.length === 12 || value.length === 13 || value.length === 15);
  }

  public payerSelected(): void {
    this.selectedPayerFromSearch = this.tempPayer;
    this.tempPayer = undefined;
    if (!this.checkValidInnOgrn(this.form.get('payer').value)) {
      this.form.get('payer').setValue(this.selectedPayerFromSearch.TIN);
    }
    this.setSearchedPayer(this.selectedPayerFromSearch);
  }

  public getDynamicMarginForLegalName(): { left: string } {
    switch (this.form.get('payer').value.length) {
      case 10: return { left: '132px' };
      case 12: return { left: '150px' };
      case 13: return { left: '158px' };
      case 15: return { left: '174px' };
    }
  }

  public cancelPatentee(): void {
    this.tempPayer = undefined;
    this.selectedPayerFromSearch = this.form.get('payer').value;
    this.setSearchedPayer(this.selectedPayerFromSearch);
  }

  public trimString(str, count): string {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  public getLegalName(): string {
    return window.innerWidth < 768 ?
      this.trimString(this.selectedPayerFromSearch?.name || this.selectedPayerFromSearch?.fullName, 17) :
      this.trimString(this.selectedPayerFromSearch?.name || this.selectedPayerFromSearch?.fullName, 65);
  }
}
