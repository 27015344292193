import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  second = false;
  first = true;

  password = false;
  personal = true;

  phoneConfirmed = false;

  PersonalForm: FormGroup;
  PasswordForm: FormGroup;
  constructor( private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this._initFormPersonal();
    this._initFormPassword();
  }

  private _initFormPersonal(): void {
    this.PersonalForm = this.fb.group({
      name: [null, [Validators.required]],
      secondName: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [null, [Validators.required]]
    });
  }

  private _initFormPassword(): void {
    this.PasswordForm = this.fb.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      newPasswordRepeat: [null, [Validators.required]],
    });
  }

  checkValidForm(): boolean {
    return true;
  }
}
