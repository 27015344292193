<div class="add-doc">
  <div class="add-doc__header">
    <p>Прикрепить документы к делу</p>
    <span class="add-doc__close" (click)="closeModal()"></span>
  </div>

  <div class="add-doc__main">
    <ul class="add-doc__file-list">
      <li class="add-doc__file">
        <div class="add-doc__file-name-block">
          <p>nazvanie_file_01_332445.pdf</p>
          <span class="add-doc__file-delete"></span>
        </div>
        <div class="add-doc__selected-wrap">
          <div class="add-doc__selected"
               [class.add-doc__selected--error]="error"
               [class.add-doc__selected--open]="showSelectList"
               (click)="showSelectList=!showSelectList"
          >
            {{trimString(selected, 30)}}
          </div>
          <p class="add-doc__error" *ngIf="error">Обязательно для заполнения</p>
          <div class="add-doc__select-list-wrap" [class.hide]="showSelectList===false">
            <ul class="add-doc__select-list">
              <li class="add-doc__select-list-item"
                  [class.add-doc__select-list-item--lev1]="select.level===1"
                  [class.add-doc__select-list-item--lev2]="select.level===2"
                  [class.add-doc__select-list-item--lev3]="select.level===3"
                  *ngFor="let select of selects" (click)="select.isSelectable ? onSelect(select) : ''"
              >
                {{select.name}}
              </li>
            </ul>
          </div>
        </div>
      </li>

    </ul>

    <div class="add-doc__input-add">
      <input id="add" type="file">
      <label for="add">Прикрепить документы</label>
    </div>
  </div>

  <div class="add-doc__btns-wrapper">
    <button class="btn-blue-attr">Отменить</button>
    <button class="btn-blue" (click)="error=true">Сохранить</button>
  </div>
</div>
<div (click)="closeModal()" class="overlay"></div>
