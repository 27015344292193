import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-case-item',
  templateUrl: './open-case-item.component.html',
  styleUrls: ['./open-case-item.component.scss']
})
export class OpenCaseItemComponent implements OnInit {
  progress;

  @Input() case;

  constructor() { }

  ngOnInit(): void {
    this.getProgress();
  }

  getProgress(): number {
    if (this.case?.progress <= 20) {
      return this.progress = 20;
    } else if (this.case?.progress <= 40) {
      return this.progress = 40;
    } else if (this.case?.progress <= 60) {
      return this.progress = 60;
    } else if (this.case?.progress <= 80) {
      return this.progress = 80;
    } else if (this.case?.progress <= 100) {
      return this.progress = 100;
    }
  }
}
