import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CasesService } from '../../../cases/cases.service';
import { SearchService } from '../../../../../../../app-search/src/app/services/search.service';
import { HttpService } from '../../../../shared/services/http.service';
import {UserProfileService} from '../../../../../../../shared/services/user-profile.service';
import {EnvService} from '../../../../shared/services/env.service';
import {RegistrationService} from '../../../../../../../shared/services/registration.service';
import {IOrg, IOrganization} from '../../../../shared/models/main.interfaces';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';
import { Store } from '@ngxs/store';
import {APP_ROUTES_NAMES} from '@web/core/models/app-routes-names';

export interface IOwner {
  type: string;
  author: any[];
  patentee: any[];
  company?: any[];
  idApplication?: string;
}

export interface IOwnerTM {
  imageText: string;
  imageUrl: string;
  director: string;
  inn: string;
  name: string;
  address: string;
  ownerShortName: string;
  ogrn: string;
  isNonResident?: boolean;
  id?: string;
}

@Component({
  selector: 'app-request-patent',
  templateUrl: './request-patent.component.html',
  styleUrls: ['./request-patent.component.scss']
})
export class RequestPatentComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  online = true;
  bill = false;
  showOptions = false;
  logo2path = '';
  brand; // бренд
  public userEmail: string = JSON.parse(localStorage.getItem('currentUserData')).userInfo
    ? JSON.parse(localStorage.getItem('currentUserData')).userInfo.email
    : JSON.parse(localStorage.getItem('currentUserData')).email;
  companies = [
    {
      id: 0,
      name: 'Выберите плательщика'
    },
    {
      id: 1,
      name: 'ООО Гардиум'
    },
    {
      id: 2,
      name: 'ООО Ромашка'
    },
    {
      id: 3,
      name: 'ООО Системные технологии'
    },
    {
      id: 4,
      name: 'Указать другого плательщика'
    }
  ];

  selectedCompany = this.companies[0];
  brandLabel; // Название бренда
  requestType;
  idApplication;
  step = 0;
  owner: IOwner;
  ownerTM: IOwnerTM;
  ownerShortName;
  OKVEDCodes = []; // оквэд коды (массив объектов): [{code: 12, description: "Описание"}, ...]
  OKVEDCodesShort = []; // оквэд кодв (массив чисел): [12, 34, 56, ...]
  imageText;
  draft; // данные из черновика
  activeTariff;
  paymentType = 'online';
  tariff;
  firstJSON;
  mktuSearch;
  filesData;
  personalData = true;
  userData;
  noPhone = true;
  isShowCompanyInfo: boolean;
  showPromoInput = false;
  promoCode;
  tariffTitle;
  duties = {};
  activeDuty;
  paymentSumWithDuty;
  company = [];
  organizationsData: IOrganization[] = [];

  @ViewChild('cards') cardsRef: ElementRef;

  ownerShortNameStub = 'Гардиум';
  OKVEDCodesShortStub = ['62.01', '62.01', '61.10.9', '62.02', '62.09', '63.11', '63.11.1', '70.22', '71.20', '71.20.8', '72.19', '72.20', '73.11', '73.20', '73.20.1', '73.20.2', '82.99', '94.11'];
  OKVEDCodesStub = [
    {code: '62.01', description: 'Разработка компьютерного программного обеспечения'},
    {code: '62.01', description: 'Деятельность в области связи на базе проводных технологий'},
    {code: '61.10.9', description: 'Деятельность в области связи на базе проводных технологий прочая'},
    {code: '62.02', description: 'Деятельность консультативная и работы в области компьютерных технологий'},
    {code: '62.09', description: 'Деятельность, связанная с использованием вычислительной техники и информационных технологий, прочая'},
    {code: '63.11', description: 'Деятельность по обработке данных, предоставление услуг по размещению информации и связанная с этим деятельность'},
    {code: '63.11.1', description: 'Деятельность по созданию и использованию баз данных и информационных ресурсов'},
    {code: '70.22', description: 'Консультирование по вопросам коммерческой деятельности и управления'},
    {code: '71.20', description: 'Технические испытания, исследования, анализ и сертификация'},
    {code: '71.20.8', description: 'Сертификация продукции, услуг и организаций'},
    {code: '72.19', description: 'Научные исследования и разработки в области естественных и технических наук прочие'},
    {code: '72.20', description: 'Научные исследования и разработки в области общественных и гуманитарных наук'},
    {code: '73.11', description: 'Деятельность рекламных агентств'},
    {code: '73.20', description: 'Исследование конъюнктуры рынка и изучение общественного мнения'},
    {code: '73.20.1', description: 'Исследование конъюнктуры рынка'},
    {code: '73.20.2', description: 'Деятельность по изучению общественного мнения'},
    {code: '82.99', description: 'Деятельность по предоставлению прочих вспомогательных услуг для бизнеса, не включенная в другие группировки'},
    {code: '94.11', description: 'Деятельность коммерческих и предпринимательских членских организаций'}];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private casesApi: CasesService,
    private searchService: SearchService,
    private httpService: HttpService,
    private registerApi: RegistrationService,
    private profileApi: UserProfileService,
    readonly envService: EnvService,
    readonly intercomEventsService: IntercomEventsService,
    private userProfileService: UserProfileService,
    private store: Store
  ) {
    this.brand = environment.BRAND;
    this.logo2path = environment.LOGO2_PATH;
  }

  selectTariff(event) {
    this.intercomEventsService.push({
      event: INTERCOM_EVENT_NAME.SELECT_TARIF,
      mktu: this.tariff.MKTUCodesShort,
      tariff: event.title,
      okved: this.OKVEDCodes,
      query: this.ownerTM?.inn,
      typeIP: this.requestType,
    });
    this.tariff.activeTariff = event.id;
    // TODO: вернуть назад поэтапную оплату
    // if (this.tariff.duty && this.requestType === 'trademark' && !this.envService.isAlfa && !this.envService.isGardiumOnline) {
    if (this.tariff.duty) {
      this.tariff.paymentSum = +event.sumDiscount + +this.tariff.duty.sumDiscount;
      this.tariff.sumBeforeDiscount = +event.sum + +this.tariff.duty.sum;
    } else {
      this.tariffTitle = event.title;
      this.tariff.sumBeforeDiscount = +event.sum;
      this.tariff.paymentSum = +event.sumDiscount;
    }
    this.structureEvent();
  }

  structureEvent() {
    if (this.tariff) {
      const testing = [];
      if (this.tariff.rates) {
        if (this.tariff?.rates?.max) {
          testing.push(this.tariff.rates.max);
        }
        if (this.tariff?.rates?.min) {
          testing.push(this.tariff.rates.min);
        }
        if (this.tariff?.rates?.opty) {
          testing.push(this.tariff.rates.opty);
        }
      }
      testing.forEach(item => {
        if (item.id === this.tariff.activeTariff) {
          this.tariffTitle = item.title;
        }
      });
      // this.intercomEventsService.push({
      //   event: INTERCOM_EVENT_NAME.SELECT_TARIF,
      //   mktu: this.tariff.MKTUCodesShort,
      //   tariff: this.tariffTitle,
      //   okved: this.OKVEDCodes,
      //   query: this.ownerTM?.inn,
      //   itemType: this.requestType,
      // });
    }
  }

  selectDuty(data) {
    this.activeDuty = data.type;
    this.paymentSumWithDuty = data.paymentSumWithDuty;
    // this.tariff.paymentSum = data.paymentSum;
  }

  ngOnInit(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.APP_REGISTER });
    if (!this.company?.length) {
      this.getOrganizations();
    }
    localStorage.removeItem('selectedPayer');
    localStorage.removeItem('selectedPayerString');
    if (!localStorage.getItem('currentUserIPID')) {
      this.router.navigate(['/login']);
    }
    this.getUrlParam();
    this.createBrandLabel();
    if (this.store.selectSnapshot(AUTH_STATE_TOKEN).user) {
      this.userData = this.store.selectSnapshot(AUTH_STATE_TOKEN).user;
      this.personalData = this.userData.phone;
    }
  }

  getUrlParam() {
    this.route.queryParams.subscribe((params) => {
      this.requestType = params.type;
      this.idApplication = params.id;
      if (this.idApplication) {
        // if (params.returnAfterPayment) {
        //   this.step = 4;
        // } else {
          this.step = 0;
          this.selectCaseUnfinished();
        // }
      } else {
        if (params.mktu) {
          this.mktuSearch = JSON.parse(params.mktu);
          if (!this.mktuSearch.length) {
            this.mktuSearch = undefined;
          }
        }
        if (this.requestType === 'rumcc') {
          this.step = 2;
        } else {
          this.step = 1;
          this.route.paramMap
            .pipe(takeUntil(this.onDestroy))
            .subscribe((paramMap) => {
              this.imageText = paramMap.get('designation');
            });
        }
      }
    });
    this.structureEvent();
  }

  /**
   * получение данных из черновика
   */
  selectCaseUnfinished(): void {
    const params = {
      isDraft: true,
      // раньше было: responseID: this.idApplication
    };
    this.casesApi.getCase(params, this.idApplication).pipe(takeUntil(this.onDestroy))
      .subscribe((tasksData: any) => {
        this.draft = tasksData?.case?.tasks?.items[0];
        if (this.draft) {
          if (this.draft.type === 'trademark' || this.draft.type === 'trademarkSearch') {
            this.parseDraftTrademark();
          } else {
            this.draft.lastStage === 4 ? this.router.navigate([APP_ROUTES_NAMES.CASES]) : this.parseDraftPatent();
          }
        }
      });
  }

  /**
   * заполнение данных из черновика в заявку
   */
  parseDraftTrademark(): void {
    this.firstJSON = {
      responseID: this.idApplication,
      stage: 1,
      type: this.draft.type,
      designation: this.draft.designationValue,
      imgURL: this.draft.details.imgSrc,
      owner: {
        inn: this.draft.inn,
        ogrn: this.draft.info.find(item => item.title === 'ОГРН').text,
        name: this.draft.info.find(item => item.title === 'Заявитель').text,
        shortName: this.draft.info.find(item => item.title === 'Заявитель сокращенно').text,
        director: this.draft.director,
        address:  this.draft.legalAdress
      }
    };
    this.ownerTM = {
      imageText: this.draft.designationValue,
      imageUrl: this.draft.details.imgSrc,
      director: this.draft.director,
      inn: this.draft.inn,
      name: this.draft.info.find(item => item.title === 'Заявитель').text,
      address: this.draft.legalAdress,
      ownerShortName: this.draft.info.find(item => item.title === 'Заявитель сокращенно').text,
      ogrn:  this.draft.info.find(item => item.title === 'ОГРН').text,
      isNonResident: this.draft.isNonResident,
    };
    if (Object.values(this.ownerTM).some(el => el !== null && el !== '' && el !== undefined)
      && this.company?.length && !this.company?.some(el => el.name !== 'Указать другого плательщика' && el.name !== 'Выберите плательщика')) {
      this.company.push({
        id: this.company.length,
        ...this.ownerTM
      });
    }
    if (this.draft.lastStage === 1) {
      const query = {
        id: this.ownerTM.inn.toString(),
      };
      this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        if (data?.hits?.hits[0]?._source.OKVEDCodes) {
          this.OKVEDCodes = data.hits.hits[0]._source.OKVEDCodes;
          this.OKVEDCodesShort = [];
          this.OKVEDCodes.forEach(item => {
            this.OKVEDCodesShort.push(item.code);
          });
        }
      });
      this.step = this.draft.lastStage + 1;
    }
    if (this.draft.lastStage >= 2) {
      this.OKVEDCodes = this.draft.OKVED;
      this.activeDuty = this.draft.duty;
      this.getRates();
    }
  }

  parseDraftPatent(): void {
    this.requestType = this.draft.type;
    this.owner = {
      type: this.draft.details.patenttype,
      author: this.draft.details.author,
      patentee: this.draft.details.patentee,
      idApplication: this.idApplication,
      company: []
    };
    for (let i = 0; i < this.draft.details.patentee.length + 2; i++) {
      if (i === 0) {
        this.owner.company.push({
          id: i,
          name: 'Выберите плательщика'});
      }
      if (i === this.draft.details.patentee.length + 1) {
        this.owner.company.push({
          id: i,
          name: 'Указать другого плательщика'});
      }
      if (i !== 0 && i !== this.draft.details.patentee.length + 1) {
        this.owner.company.push({
          id: i,
          name: this.draft.details.patentee[i - 1]});
      }
    }
    if (this.draft.lastStage >= 2) {
      const filesSaved = [];
      this.draft.details.files.forEach(item => {
        filesSaved.push({
          fileName: item.substring(item.lastIndexOf('/') + 1),
          file: undefined,
          path: item,
          size: 0
        });
      });
      this.filesData = {
        filesSaved,
        linkSaved: this.draft.details.links?.length > 0 ? this.draft.details.links[0] : null,
        designation: this.draft.details.designation
      };
    }
    if (this.draft.lastStage >= 2) {
      this.getRates();
    }
    this.step = this.draft.lastStage + 1;
  }

  /**
   * получение тарифов и пошлин
   */
  getRates(): void {
    const param = {
      responseID: this.idApplication,
      promocode: this.promoCode
    };
    if (!this.promoCode) {
      delete param.promocode;
    }
    this.httpService.get({path: 'request/tariff', params: param}).subscribe((data: any) => {
      if (data?.tariff && data.duty) {
        let min; let opty; let max; let activeTariff;
        data.tariff.forEach((item, index) => {
          if (index === 0) {
            min = item;
          }
          if (index === 1) {
            opty = item;
          }
          if (index === 2) {
            max = item;
          }
          if (item.default) {
            activeTariff = item.id;
          }
        });
        if (!activeTariff) {
          activeTariff = opty.id || min.id || max.id;
        }
        this.tariff = {
          idApplication: this.idApplication,
          rates: {min, opty, max},
          activeTariff,
          countRates: data.tariff.length,
          duty: data.duty,
          MKTUCodesShort: this.draft ? this.draft.info.find(item => item.title === 'Классы МКТУ').text.split(', ').map(item => +item) : null,
          // TODO: вернуть назад поэтапную оплату
          // sumBeforeDiscount: (this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline)) || this.requestType !== 'trademark' ? +this.getRateById(activeTariff, [min, max, opty]).sum : +this.getRateById(activeTariff, [min, max, opty]).sum + +data.duty.sum,
          // paymentSum: (this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline)) || this.requestType !== 'trademark' ? +this.getRateById(activeTariff, [min, max, opty]).sumDiscount : +this.getRateById(activeTariff, [min, max, opty]).sumDiscount + +data.duty.sumDiscount
          sumBeforeDiscount: +this.getRateById(activeTariff, [min, max, opty]).sum + +data.duty.sum,
          paymentSum: +this.getRateById(activeTariff, [min, max, opty]).sumDiscount + +data.duty.sumDiscount
        };
        this.duties = {later: data.duty.later, all: data.duty.all, request_only: data.duty.request_only};
        const selectedTariff = this.draft?.tariff ? this.getRateById([min, opty, max].find(item => item?.id === this.draft.tariff).id, [min, max, opty]) : this.getRateById(activeTariff, [min, max, opty]);
        this.tariff.activeTariff = selectedTariff.id;
        // if ((this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline)) || this.requestType !== 'trademark') {
        //   this.tariff.paymentSum = +selectedTariff.sumDiscount;
        //   this.tariff.sumBeforeDiscount = +selectedTariff.sum;
        //   this.tariffTitle = selectedTariff.title;
        //   if (this.activeDuty) {
        //     for (const item in this.duties) {
        //       if (item === this.activeDuty) {
        //         this.paymentSumWithDuty = +this.tariff?.paymentSum + +this.duties[item].sumDiscount;
        //       }
        //     }
        //   }
        // } else {
        //   this.tariff.paymentSum = +selectedTariff.sumDiscount + +data.duty.sumDiscount;
        //   this.tariff.sumBeforeDiscount = +selectedTariff.sum + +data.duty.sum;
        // }
        this.tariff.paymentSum = +selectedTariff.sumDiscount + +data.duty.sumDiscount;
        this.tariff.sumBeforeDiscount = +selectedTariff.sum + +data.duty.sum;
        if (!this.promoCode) {
          this.step = this.draft.lastStage + 1;
        }
        // this.tariff.paymentSum = +selectedTariff.sumDiscount;
        // this.tariffTitle = selectedTariff.title;
        // this.tariff.sumBeforeDiscount = +selectedTariff.sum;
      }
    });
    this.structureEvent();
  }

  applyPromoCode(code) {
    this.promoCode = code;
    this.getRates();
  }

  getRateById(activeRateId, rates) {
    return rates.find(el => el && el.id === activeRateId);
  }

  /**
   * отметки о пройденных шагах
   * @param step: номер шага
   */
  getStepClass(step): string {
    if (this.step === step) {
      return 'steps__element--active';
    } else if (this.step > step) {
      return 'steps__element--finished';
    } else {
      return '';
    }
  }

  step1to2(data) {
    this.idApplication = data.idApplication;
    this.owner = data;
    this.nextStep();
  }

  TMstep1to2(data) {
    this.ownerShortName = data.ownerShortName;
    if (data.idApplication) {
      this.idApplication = data.idApplication;
    }
    this.OKVEDCodesShort = data.OKVEDCodesShort;
    this.OKVEDCodes = data.OKVEDCodes;
    this.firstJSON = data.firstJSON;
    this.ownerTM = {
      imageText: data.firstJSON.designation,
      imageUrl: data.firstJSON.imgURL,
      director: data.firstJSON.owner.director,
      inn: data.firstJSON.owner.inn,
      name: data.firstJSON.owner.name,
      address: data.firstJSON.owner.address,
      ownerShortName: data.firstJSON.owner.shortName,
      ogrn:  data.firstJSON.owner.ogrn,
      isNonResident: data.firstJSON.owner.isNonResident,
      id: data.firstJSON.owner.id
    };
    if (Object.values(this.ownerTM).some(el => el !== null && el !== '' && el !== undefined)
      && !this.company.some(el => el.name !== 'Указать другого плательщика' && el.name !== 'Выберите плательщика')) {
      this.company.push({
        id: this.company.length,
        ...this.ownerTM
      });
    }
    this.draft = null;
    this.tariff = null;
    this.nextStep();
  }

  TMstep2to3(data) {
    this.mktuSearch = null;
    this.tariff = data;
    if (data?.duty) {
      this.duties = {
        later: data.duty.later,
        all: data.duty.all,
        request_only: data.duty.request_only
      };
    }
    this.idApplication = data.idApplication;
    this.nextStep();
  }

  step2to3(data) {
    this.tariff = data;
    this.idApplication = data.idApplication;
    this.filesData = data.files;
    this.nextStep();
  }

  nextStep() {
    this.structureEvent();
    this.step += 1;
  }

  backStep() {
    this.step -= 1;
    if (this.requestType === 'trademark' || (this.requestType === 'trademarkSearch' && this.ownerTM.inn)) {
      this.isShowCompanyInfo = true;
    }
    this.structureEvent();
  }

  onSelect(comp): void {
    this.selectedCompany = comp;
    this.showOptions = false;
  }

  /**
   * склоние слова 'классы'
   * @param n: количество классов
   */
  declOfNum(n: number): string {
    n = Math.abs(n) % 100; const n1 = n % 10;
    if (n > 10 && n < 20) { return 'классов'; }
    if (n1 > 1 && n1 < 5) { return 'класса'; }
    if (n1 === 1) { return 'класс'; }
    return 'классов';
  }

  switchPaymentTab(data) {
    this.paymentType = data;
  }

  createBrandLabel() {
    switch (this.brand) {
      case 'IPID-PRO':
      case 'X5':
        this.brandLabel = 'Гардиум.ПРО';
        break;
      case 'OPEN':
        this.brandLabel = 'Открытие';
        break;
      case '1C-START':
        this.brandLabel = '1С-Старт';
        break;
      default:
        this.brandLabel = 'Гардиум.ОНЛАЙН';
        break;
    }
  }

  getPersonalDataStatus(evt: boolean): boolean {
    return this.personalData = evt;
  }

  /**
   * отправка выбранного тарифа в юрайт
   */
  sendTariff(): void {
    const tariffBody = {
      responseID: this.idApplication,
      tariffID: this.tariff?.activeTariff,
      stage: 3,
      promocode: this.promoCode,
      duty: ''
    };
    this.httpService.post({path: 'request/load', body: tariffBody}).subscribe((data: any) => {
      this.step = 4;
    });
  }

  getOrganizations() {
    this.userProfileService.getOrganizations().subscribe((data: IOrg) => {
      if (this.envService.isCorp) {
        this.company.push(
          {
            id: 0,
            name: 'Выберите плательщика'
          },
        );
      } else {
        this.company.push(
          {
            id: 0,
            name: 'Выберите плательщика'
          },
          {
            id: 1,
            name: 'Указать другого плательщика'
          },
        );
      }
      if (data && data.organizations?.length) {
        if (this.company[1] && this.company[1].name === 'Указать другого плательщика') {
          this.company.pop();
        }
        this.organizationsData = data.organizations.slice(0).filter(org => !org.pending);
        data.organizations.forEach((el, index) => {
          this.company.push(
            {
              id: this.envService.isCorp ? index + 1 : index + 2,
              ...el
            });
        });
      }
    });
  }

  ngOnDestroy(): void {
  }

  public sendIntercomEvent(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.GOTO_ORDER });
  }
}
