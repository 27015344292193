<h2 class="visual-hidden">Мои права доступа</h2>
<div class="access">

  <div *ngIf="success" class="access__success">
    Данные успешно сохранены!
  </div>

  <div class="access__title-wrap container">
    <p class="access__title">
      <span>Доступные права для</span>
      <ng-container *ngIf="organizations.length == 1">
        <span *ngFor="let organization of organizations">&nbsp;{{ organization?.name }}</span>
      </ng-container>
    </p>

    <div *ngIf="organizations.length > 1" class="organization-dropdown">
      <button class="organization-dropdown__button" (click)="showDropdown = !showDropdown">
        <span class="organization-dropdown__text">
          {{ selected }}
        </span>
        <svg class="dropdown__button-img"
             [ngClass]="showDropdown ? 'rotate': ''"
             width="12" height="8" viewBox="0 0 12 8">
          <path d="M1 1L6 6L11 1" stroke-width="2"/>
        </svg>
      </button>
      <div *ngIf="showDropdown" class="organization-dropdown__menu-wrap">
        <div class="dropdown__menu">
          <span class="dropdown__menu-title">Организация</span>
          <ng-container *ngFor="let item of organizations">
            <input type="radio"
                   class="visual-hidden dropdown__input dropdown__input--radio"
                   [value]='item?.name'
                   [(ngModel)]="selected"
                   id="{{ item?.id }}"
                   (ngModelChange)="filterAttorney(item.id)">
            <label class="dropdown__label" for="{{ item?.id }}">
              <span>{{ item?.name }}</span>
            </label>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <app-confidants
    [attorneys]="attorneys"
    [attorney]="rightParams"
  ></app-confidants>

  <app-intellectual-property
    [rights]="rights"
    [rightParams]="rightParams"
    (deleteRight)="deleteUserRights($event)"
    (addRight)="addUserRights($event)"
    (deleteAll)="deleteAllUserRights()"
    (saveChange)="saveRightsChange()"
  >
  </app-intellectual-property>

</div>
