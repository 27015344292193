import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';

import { SearchService } from '../../services/search.service';
import { ISearchRequestData } from '../../models/search.interfaces';

import mktuData from './json-data/mktu';
import okvedData from './json-data/okved';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from '../../services/events.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

declare var window: any;

@Component({
  selector: 'app-ip-id-search',
  templateUrl: './ip-id-search.component.html',
  styleUrls: ['./ip-id-search.component.scss'],
})
export class IpIdSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(
    private location: Location,
    private searchService: SearchService,
    private sanitizer: DomSanitizer,
    private readonly ref: ChangeDetectorRef,
    private readonly eventsService: EventsService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  searchDefault = true;
  searchStrategy = false;
  searchCompetition = false;

  widthDesignation = 140;
  @Input() designation = '';
  @Input() countrycodes = '';
  @Input() brandsearch;
  @Input() legalsupport;
  isBrandSearch: boolean;
  isLegalSupport: boolean;
  @Input() options: any = {};

  @Output() eventSelected = new EventEmitter<any>();
  @Output() eventDeleted = new EventEmitter<any>();
  @Output() searchHappened = new EventEmitter<any>();
  @Output() eventsearch = new EventEmitter<any>();
  @Output() reportdownload = new EventEmitter<any>();
  @Output() riskscheck = new EventEmitter<any>();
  @Output() reportrisksclick = new EventEmitter<any>();

  @Output() action = new EventEmitter();

  @Output() updateMKTUClasses = new EventEmitter<any>();

  @ViewChild('invisibleText') invTextER: ElementRef;

  allTradeMarks: any = [];
  tradeMarksData: any;
  tradeMarks: any = [];

  allLegalNames: any = [];
  legalNamesData: any;
  legalNames: any = [];

  allDomainNames: any = [];
  domainNamesData: any;
  domainNames: any = [];

  query;

  searchContainerHeight = 60;
  @ViewChild('searchContainerEl') searchContainerElement: ElementRef;
  @ViewChild('queryEl') queryElement: ElementRef;
  @ViewChild('searchModalEl') searchModalElement: ElementRef;

  isShowMktuSearchBlock = true;
  isShowSearchPopup = false;
  isShowMktuPlusBtn = true;
  isShowSearchInput = false;

  searchPopupViewType = 'all';

  zIndex: boolean;

  // Loading variables
  isLoadingSearchClases = false;
  isLoadingTradeMarks = false;
  searchRequest200 = false;
  isLoadingLegalNames = false;
  isLoadingDomainNames = false;

  // Mapped mktu data array for first show after click by plus button
  mktuData = JSON.parse(JSON.stringify(mktuData));
  // Array with selected MKTU classes
  mktuSearchArray = [];
  // Array for MKTU response after mapping for a single structure
  mktuMappedResponse = [];

  // Mapped okved data array for first show after click by plus button
  okvedData = okvedData;
  // Array with selected OKVED classes
  okvedSearchArray = [];
  // Array for OKVED response after mapping for a single structure
  okvedMappedResponse = JSON.parse(JSON.stringify(this.okvedData));

  // Debouncing Value Changes timeout for search classes
  timeoutID;

  innOgrnQuery;
  @ViewChild('innOgrn') innOgrnElement: ElementRef;
  // Array for OKVED response after search by inn/ogrn
  legalMappedResponse = [];
  legalName = '';
  isLoadingLegal = false;
  innerWidth;


  ngOnInit(): void {

    // Так как на продах ls и bs, инпут флаг приходит как [brandsearch] [legalsupport]
    // делаю присвоение чтобы не поломать логику
    this.isBrandSearch = !!this.brandsearch;
    this.isLegalSupport = !!this.legalsupport;

    this.init();

    this.eventsService.events.subscribe(event => {
      if (event.name === 'action') {
        this.emitAction(event.payload.name);
      }
    });

    if (this.designation) {
      this.checkTradeMarks({
        designation: this.designation,
        mktuList: [...this.mktuSearchArray],
        okvedList: [...this.okvedSearchArray]
      });
    }
  }

  init() {
    // tslint:disable-next-line:max-line-length
    // console.log((!!(!this.isLoadingTradeMarks && this.tradeMarksData && this.legalNamesData && this.domainNamesData && this.tradeMarksData.risks)) && !!(!this.options.onlyClassSelect || this.possibility !== 'true'));
    // console.log(!this.options.onlyClassSelect || this.possibility !== 'true');
    // console.log(this.possibility !== 'true');
    console.log('%c%s', 'color: orange;', 'ngOnInit app-search');
    console.log(this.options.dynamicStylesForBrand);

    if (this.options?.onlyClassSelect) {
      // Only unique mktu numbers
      const uniqueselectedMktu = [...new Set(this.options.selectedMktu)];
      uniqueselectedMktu.forEach((mktu) => {
        const foundMktuClass = this.mktuData.find((item) => item.number === mktu);
        if (foundMktuClass) {
          this.addMktuToSearch(foundMktuClass, true);
        } else {
          console.log(`Переданный класс ${mktu} не найден в массиве классов МКТУ`);
        }
      });
    }

    if (this.designation) {
      console.log('designation: ', this.designation);
    }
    if (this.isBrandSearch) {
      console.log('is brand-search: ', this.isBrandSearch);
    }
    if (this.isLegalSupport) {
      console.log('is legal-support: ', this.isLegalSupport);
    }

    if (this.options.search === 'okved') {
      this.searchPopupViewType = 'okved';
    }
    if (this.options.search === 'mktu') {
      this.searchPopupViewType = 'mktu';
    }

    // this.designation = 'Прпаа';
    // this.designation = 'ghsdf'; // 46%
    // this.designation = 'yandex';
    // this.designation = 'bhvjh';
    // this.designation = 'православный';

    // Added data to search for test TODO: comment me after testing please
    // this.getTestDataToSearch();
  }

  // todo: testing Intercom for Elements build
  ngAfterViewInit(): void {
    (window as any).Intercom('boot', {
      app_id: 'fvwd0dw0',
    });
  }

  getTestDataToSearch() {
    // this.designation = 'Какое то длинное наименование которое сюда можно написать';

    // получаем данные из JSON файлов
    this.searchByJSON();

    // добавляем 5 мкту классов в поиск
    const mk = this.mktuMappedResponse.splice(0, 5);
    mk.forEach(m => this.addMktuToSearch(m, true));

    // добавляем 5 оквэд классов в поиск
    const ok = this.okvedMappedResponse.splice(0, 5);
    ok.forEach(o => this.addOkvedToSearch(o));

    // Скрываем всплывающий попап поиска
    setTimeout(() => {
      this.clickOnDesignation();
    }, 300);
  }

  checkTradeMarks(event?) {

    // Если билд для ls или bs, добавляем designation сразу в query params
    if (this.isLegalSupport || this.isBrandSearch) {
      this.location.go(`?q=${event.designation}`);
    }
    // для ipid, designation добавляется в query params в компоненте поиска
    // projects/app-ipid/src/app/pages/search/search-page.component.ts метод searchHappened

    this.clearData();

    console.log(event);
    if (event) {
      this.designation = event.designation;
      this.mktuSearchArray = event.mktuList;
      this.okvedSearchArray = event.okvedList;
    }

    if (!this.designation) {
      if (!event.designation) {
        return;
      }
    }

    // this.checkLegalNames();
    // this.checkDomainNames();

    this.hideSearchPopup();
    this.query = '';
    this.isShowSearchInput = false;
    this.searchHappened.emit(this.designation);

    // Если пользователь нажал на добавление класса но ничего не добавил и нажал Проверить, возвращаем МКТУ добавление классов в поиск
    if (!this.isShowMktuSearchBlock) {
      this.isShowMktuSearchBlock = true;
    }
    const query: ISearchRequestData = {
      text: this.designation,
      gsNumber: this.mktuSearchArray.map(i => i.number),
      eaNumber: this.okvedSearchArray.map(i => i.code)
    };
    if (this.countrycodes) {
      query.countryCodes = this.countrycodes.split(',');
    }
    this.isLoadingTradeMarks = true;
    this.searchService.getTradeMarks(query).subscribe((data: any) => {
      this.searchRequest200 = true;
      this.isLoadingTradeMarks = false;

      if (data?.hits?.hits?.length) {
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SEARCH_RESULT_NO_UNIQUE });
      } else {
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SEARCH_RESULT_UNIQUE });
      }

      if (data?.hits?.hits) {
        data.hits.hits.forEach((item) => {
          let id = item._source.registrationString;
          if (item._index === 'rutmap' && item._source.applicationString) {
            id = item._source.applicationString;
          }
          item._source.imageUrl = this.searchService.getImgUrl(
            item._index,
            parseInt(id, 10),
            item._source.markImageFileName,
            id
          );
        });
        this.tradeMarksData = data;
        this.allTradeMarks = data.hits.hits;
        // this.allTradeMarks = data.hits.hits.slice(0, 5);
        this.showTradeMarks();
      }
    });
    setTimeout(() => {
      this.eventsearch.emit({text: this.designation, mktu: this.mktuSearchArray});
    }, 10);
  }

  showTradeMarks(): void {
    if (document.documentElement.clientWidth > 991) {
      this.tradeMarks = this.allTradeMarks.slice(0, 4);
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      this.tradeMarks = this.allTradeMarks.slice(0, 3);
    } else if (document.documentElement.clientWidth <= 767 && !this.isBrandSearch && !this.isLegalSupport) {
      this.tradeMarks = this.allTradeMarks;
    } else if (document.documentElement.clientWidth <= 767 && (this.isBrandSearch || this.isLegalSupport)) {
      this.tradeMarks = this.allTradeMarks.slice(0, 3);
    }
  }

  checkLegalNames() {
    const query: ISearchRequestData = {
      text: this.designation,
      gsNumber: this.mktuSearchArray.map(i => i.number),
      eaNumber: this.okvedSearchArray.map(i => i.code),
      searchLegalEntities: true
    };
    this.isLoadingLegalNames = true;
    this.searchService.getTradeMarks(query).subscribe((data: any) => {
      // setTimeout(() => {
      this.isLoadingLegalNames = false;
      if (data && data.hits && data.hits.hits) {
        this.legalNamesData = data;
        this.allLegalNames = data.hits.hits;
        this.showLegalNames();
      }
      // }, 4000);
    });
  }

  showLegalNames(): void {
    if (document.documentElement.clientWidth > 991) {
      this.legalNames = this.allLegalNames.slice(0, 4);
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      this.legalNames = this.allLegalNames.slice(0, 3);
    } else if (document.documentElement.clientWidth <= 767 && !this.isBrandSearch && !this.isLegalSupport) {
      this.legalNames = this.allLegalNames;
    } else if (document.documentElement.clientWidth <= 767 && (!this.isBrandSearch || !this.isLegalSupport)) {
      this.legalNames = this.allLegalNames.slice(0, 3);
    }
  }

  checkDomainNames() {
    const query: ISearchRequestData = {
      text: this.designation,
      gsNumber: this.mktuSearchArray.map(i => i.number),
      eaNumber: this.okvedSearchArray.map(i => i.code),
      searchDomains: true
    };
    this.isLoadingDomainNames = true;
    this.searchService.getTradeMarks(query).subscribe((data: any) => {
      // setTimeout(() => {
      this.isLoadingDomainNames = false;
      if (data && data.hits && data.hits.hits) {
        this.domainNamesData = data;
        this.allDomainNames = data.hits.hits;
        this.showDomainNames();
      }
      // }, 2000);
    });
  }

  showDomainNames(): void {
    if (document.documentElement.clientWidth > 991) {
      this.domainNames = this.allDomainNames.slice(0, 4);
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      this.domainNames = this.allDomainNames.slice(0, 3);
    } else if (document.documentElement.clientWidth <= 767 && !this.isBrandSearch && !this.isLegalSupport) {
      this.domainNames = this.allDomainNames;
    } else if (document.documentElement.clientWidth <= 767 && (!this.isBrandSearch || !this.isLegalSupport)) {
      this.domainNames = this.allDomainNames.slice(0, 3);
    }
  }

  // Debouncing Value Changes
  fetchMktuAndOkvedByQuery() {
    if (!this.query) {
      this.searchModalElement.nativeElement.scrollTop = 0;
      this.searchByJSON();
      return;
    }

    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID);
    }
    this.timeoutID = window.setTimeout(() => {
      if (this.query && this.query.length >= 3) {
        this.fetchMktu();
        this.fetchOkved();
      }
    }, 800);
  }

  fetchMktu() {
    if (this.query) {
      this.isLoadingSearchClases = true;

      const query = {
        gsDescription: this.query
      };
      const queryNum = {
        gsNumber: +this.query
      };
      if (isNaN(this.query)) {
        this.searchService.searchByTermMKTU(query).subscribe((response: any) => {
          this.mktuMappedResponse = this.mappingResponse('mktu', {...response, type: 'term'});
          this.isLoadingSearchClases = false;
          this.ref.detectChanges();
        });
      } else {
        this.searchService.searchByNumberMKTU(queryNum).subscribe((response: any) => {
          this.mktuMappedResponse = this.mappingResponse('mktu', {...response, type: 'number'});
          this.isLoadingSearchClases = false;
          this.ref.detectChanges();
        });
      }
    }
  }

  mappingResponse(type, data) {
    if (data?.hits?.hits?.length) {
      return data.hits.hits.map((item: any) => {

        if (type === 'mktu') {
          // Добавляем в подкласс флаг для того чтобы если юзер вернется к добалению подклассов мкту из поиска, мы сделали класс selected
          // если какой то из подклассов уже был добавлен в поиск
          let subClassIsSelected = false;
          this.mktuSearchArray.forEach((mktuItem: any) => {
            if (mktuItem.number === parseInt(item._source.classNumber, 10) && mktuItem?.subClasses?.length) {
              const foundSubClass: any = mktuItem.subClasses.find((mktuSubClass) => mktuSubClass._id === item._id);
              if (foundSubClass) {
                subClassIsSelected = true;
              }
            }
          });

          switch (data.type) {
            case 'number':
              return {
                _id: item._id,
                number: parseInt(item._source.classNumber, 10),
                name: `Класс ${item._source.classNumber}`,
                desc: item._source.description,
                subclass: true,
                selected: subClassIsSelected
              };
            case 'term':
              return {
                _id: item._id,
                number: parseInt(item._source.classNumber, 10),
                name: `Класс ${item._source.classNumber}`,
                desc: item.highlight['description.parts'][0],
                subclass: true,
                selected: subClassIsSelected
              };
          }

        } else if (type === 'okved') {

          let classIsSelected = false;
          this.okvedSearchArray.forEach((okvedSearchItem: any) => {
            if (okvedSearchItem.selected) {
              if (item._source.code === okvedSearchItem.code) {
                classIsSelected = true;
              }
            }
          });
          switch (data.type) {
            case 'number':
              return {
                _id: item._id,
                number: parseInt(item._source.code, 10),
                code: item._source.code,
                // code: `ОКВЭД ${item.highlight['code.levels'][0]}`,
                description: item._source.description,
                selected: classIsSelected
              };
            case 'term':
              return {
                _id: item._id,
                number: parseInt(item._source.code, 10),
                code: item._source.code,
                description: item.highlight['description.parts'][0],
                selected: classIsSelected
              };
          }
        }
      });
    } else {
      console.log('mappingMktuResponse: NOT DATA');
      return [];
    }
  }

  searchByJSON() {
    const mktuDataJSONcopy = JSON.parse(JSON.stringify(this.mktuData));
    const okvedDataJSONcopy = JSON.parse(JSON.stringify(this.okvedData));

    // Проверяем если есть мкту классы добавленные через подкласс или они были выбраны до этого, делаем их selected
    this.mktuSearchArray.forEach((mktuSearchItem: any) => {
      if (mktuSearchItem.addedWithSubclass || mktuSearchItem.selected) {
        const foundClass: any = mktuDataJSONcopy.find((mktuClass) => mktuClass.number === mktuSearchItem.number);
        if (foundClass) {
          foundClass.selected = true;
        }
      }
    });

    // Проверяем если есть ли добавленные оквед классы, делаем их selected
    this.okvedSearchArray.forEach((okvedSearchItem: any) => {
      if (okvedSearchItem.selected) {
        const foundClass: any = okvedDataJSONcopy.find((okvedClass) => okvedClass.code === okvedSearchItem.code);
        if (foundClass) {
          foundClass.selected = true;
        }
      }
    });

    this.mktuMappedResponse = mktuDataJSONcopy;
    this.okvedMappedResponse = okvedDataJSONcopy;
    this.isShowMktuSearchBlock = false;
    this.isShowMktuPlusBtn = false;
    this.isShowSearchInput = true;
    setTimeout(() => { // this will make the execution after the above boolean has changed
      this.isShowSearchPopup = true;
      this.queryElement.nativeElement.focus();
      this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
      this.ref.detectChanges();
    }, 0);
    this.ref.detectChanges();
  }

  addMktuToSearch(item, dontDelete) {

    if (item.selected && !dontDelete) {
      this.deleteNumberFromSearch('mktu', { ...item });
      return;
    }

    item.selected = true;

    // Если это МКТУ подкласс, мы добавляем его в родительский МКТУ класс
    if (item.subclass) {
      const foundAddedClass: any = this.mktuSearchArray.find((mktuSearchItem) => mktuSearchItem.number === item.number);

      if (foundAddedClass) {
        if (!foundAddedClass.subClasses) {
          foundAddedClass.subClasses = [];
        }
        foundAddedClass.subClasses.push(item);
      } else {
        // Если добавленный класс не найден, добавляем класс в поиск вместе с подклассом
        const mainClass: any = JSON.parse(JSON.stringify(
          this.mktuData.find((mktuDataItem) => mktuDataItem.number === item.number)
        ));
        if (mainClass) {
          // Добавляем в класс флаг для того чтобы если юзер вернется к добалению классов мкту из json, мы сделали класс selected
          mainClass.addedWithSubclass = true;
          // Добавляем подкласс
          mainClass.subClasses = [item];
          // Пушим класс с подклассом в массив МКТУ классов для поиска
          this.mktuSearchArray.push(mainClass);
          this.eventSelected.emit(mainClass);
        }
      }
    } else {
      // Если нет подкласса просто добавляем мкту класс в массив поиска
      this.mktuSearchArray.push(item);
      this.eventSelected.emit(item);
    }

    if (!this.isShowMktuSearchBlock) {
      this.isShowMktuSearchBlock = true;
    }
    this.updateMKTUClasses.emit(this.mktuSearchArray);
    setTimeout(() => {
      this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
    }, 0);
  }

  deleteNumberFromSearch(type, item) {

    switch (type) {
      case 'mktu':
        // delete from search string
        this.mktuSearchArray.splice(this.mktuSearchArray.findIndex((i) => i.number === item.number), 1);

        // selected false for subclasses list
        if (item.subClasses && this.isShowSearchPopup) {
          item.subClasses.forEach((subClass) => {
            const foundSelectedSubClass: any = this.mktuMappedResponse.find((mktuSubClassItem) => {
              return mktuSubClassItem._id === subClass._id && mktuSubClassItem.selected;
            });
            if (foundSelectedSubClass) {
              foundSelectedSubClass.selected = false;
            }
          });
        }

        if (this.options.onlyClassSelect) {
          this.eventDeleted.emit(item);
          // this.searchByJSON();
        }

        // selected false for main array
        this.mktuMappedResponse = this.mktuMappedResponse.map((mktuItem: any) => {
          if (mktuItem.number === item.number) {
            return  { ...mktuItem, selected: false };
          }
          return mktuItem;
        });

        break;
      case 'okved':
        // delete from search string
        this.okvedSearchArray.splice(this.okvedSearchArray.findIndex((i) => i.code === item.code), 1);

        // selected false for main array
        this.okvedMappedResponse.forEach((okvedItem: any) => {
          if (okvedItem.code === item.code) {
            okvedItem.selected = false;
          }
        });
        // selected false for main array
        this.legalMappedResponse.forEach((okvedItem: any) => {
          if (okvedItem.code === item.code) {
            okvedItem.selected = false;
          }
        });
        break;
    }

    if (!this.mktuSearchArray.length && !this.okvedSearchArray.length) {
      this.hideSearchPopup();
      this.query = '';
      this.isShowMktuSearchBlock = true;
      this.isShowSearchInput = false;
    }

    this.updateMKTUClasses.emit(this.mktuSearchArray);
  }

  fetchOkved() {
    if (this.query) {
      this.isLoadingSearchClases = true;

      const queryOkved = {
        eaDescription: this.query
      };
      const queryNumOkved = {
        eaNumber: this.query
      };
      if (isNaN(this.query)) {
        this.searchService.searchByTermOKVED(queryOkved).subscribe((response: any) => {
          this.okvedMappedResponse = this.mappingResponse('okved', {...response, type: 'term'});
          this.isLoadingSearchClases = false;
          this.ref.detectChanges();
        });
      } else {
        this.searchService.searchByNumberOKVED(queryNumOkved).subscribe((response: any) => {
          this.okvedMappedResponse = this.mappingResponse('okved', {...response, type: 'number'});
          this.isLoadingSearchClases = false;
          this.ref.detectChanges();
        });
      }
    }
  }

  addOkvedToSearch(item) {
    if (item.selected) {
      this.deleteNumberFromSearch('okved', item);
      return;
    }

    item.selected = true;

    this.okvedSearchArray.push(item);
    this.eventSelected.emit(item);

    if (!this.isShowMktuSearchBlock) {
      this.isShowMktuSearchBlock = true;
    }

    setTimeout(() => {
      this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
    }, 0);
  }

  fetchLegal() {
    // @ts-ignore
    if (this.innOgrnQuery && this.innOgrnElement.valid) {
      this.isLoadingLegal = true;

      this.searchService.getLegal({id: this.innOgrnQuery}).subscribe((response: any) => {
        this.legalName = response?.hits?.hits[0]?._source?.name ?
          `/ ${response?.hits?.hits[0]?._source?.name}` :
          response?.hits?.hits[0]?._source?.fullName ? `/ ${response?.hits?.hits[0]?._source?.fullName}` : '';
        this.legalMappedResponse = response?.hits?.hits[0]?._source?.OKVEDCodes?.map((OKVEDCode) => {
          let classIsSelected = false;
          this.okvedSearchArray.forEach((okvedSearchItem: any) => {
            if (okvedSearchItem.selected) {
              if (OKVEDCode.code === okvedSearchItem.code) {
                classIsSelected = true;
              }
            }
          });
          return {
            ...OKVEDCode,
            selected: classIsSelected
          };
        });
        this.isLoadingLegal = false;
      });
    }
  }

  legalChanged() {
    this.legalMappedResponse = [];
    this.legalName = '';
    this.fetchLegal();
  }

  getDynamicMarginForLegalName() {
    switch (this.innOgrnQuery.length) {
      case 10:
        return {left: '102px'};
      case 12:
        return {left: '120px'};
      case 13:
        return {left: '128px'};
      case 15:
        return {left: '144px'};
    }
  }

  hideSearchPopup() {
    this.isShowSearchPopup = false;

    // app.ip-id
    if (!this.options?.onlyClassSelect) {
      this.searchPopupViewType = 'all';
    }

    this.isShowMktuPlusBtn = true;
  }

  hideSearchPopupFromOutsideClick(e) {

    // Для safari, генерация $event.path массива, так как safari нам его не дает в $event в отличие от хрома
    if (!e?.path) {
      if (!('path' in Event.prototype)) {
        Object.defineProperty(Event.prototype, 'path', {
          get() {
            const path = [];
            let currentElem = this.target;
            while (currentElem) {
              path.push(currentElem);
              currentElem = currentElem.parentElement;
            }
            if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
              path.push(document);
            }
            if (path.indexOf(window) === -1) {
              path.push(window);
            }
            return path;
          }
        });
      }
    }

    if (e?.path) {
      const isSearchPopup = e.path.find((p) => {
        if (p.className) {
          return p.className.includes('main_modal') || p.className.includes('ip-id_Input_wide');
        }
      });
      if (isSearchPopup) {
        return;
      } else {
        this.clickOnDesignation();
        setTimeout(() => {
          this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;
        }, 0);
      }
    }
  }

  clickOnDesignation() {
    this.hideSearchPopup();
    this.isShowSearchInput = false;
    this.isShowMktuSearchBlock = true;
    this.query = '';
  }

  backFromInn() {
    this.innOgrnQuery = '';
    this.legalName = '';
    this.changeSearchPopupView('all');
  }

  changeSearchPopupView(type) {
    this.searchPopupViewType = type;
  }

  showDefault() {
    this.searchDefault = true;
    this.searchCompetition = false;
    this.searchStrategy = false;
  }

  showStrategy() {
    // this.searchDefault = false;
    // this.searchCompetition = false;
    // this.searchStrategy = true;
  }

  showCompetition() {
    // this.searchDefault = false;
    // this.searchCompetition = true;
    // this.searchStrategy = false;
  }


  getSearchBorderStyle() {
    if (this.options.onlyClassSelect) {
      return {
        border: '1px solid rgba(21, 36, 56, 0.15)',
        'border-radius': '5px',
        'box-sizing': 'border-box',
        'box-shadow': '0px 3px 6px rgba(111, 132, 152, 0.1)',
        'padding-left': '10px'
      };
    } else if (this.searchContainerHeight > 60) {
      return {
        'border-bottom-right-radius': '5px'
      };
    }
  }

  getStyleForMainResult() {
    // if (this.options?.style === 'appIpIdSearchPage') {
    //   return {background: this.options?.dynamicStylesForBrand?.SEARCH_CARDS_BLOCK_BACKGROUND};
    // }
    if (!this.tradeMarks?.length && !this.legalNames?.length && !this.domainNames?.length) {
      return {background: '#FFF'};
    }
  }

  clearData() {
    this.allTradeMarks = [];
    this.tradeMarksData = {};
    this.tradeMarks = [];

    this.allLegalNames = [];
    this.legalNamesData = {};
    this.legalNames = [];

    this.allDomainNames = [];
    this.domainNamesData = {};
    this.domainNames = [];
  }

  // https://stackblitz.com/edit/angular-input-dynamic-width?file=app%2Finput-text-width-example.ts
  // we can either use inputText as parameter (from inputText.value) or use inString (from ngModel)
  resizeInput() {
    // this.textVis = this.inString; //this.inputTextER.nativeElement.value;
    // this.textInv = this.inString;
    // this.invTextER.nativeElement.value = this.inputTextER.nativeElement.value;
    // this.formFieldInputER.nativeElement.style.width = this.invTextER.nativeElement.offsetWidth + 2 + 'px';
    // without setTimeout the width gets updated to the previous length
    setTimeout(() => {

      // check searchContainerHeight
      this.searchContainerHeight = this.searchContainerElement.nativeElement.offsetHeight;

      const minWidth = 132;

      if (this.invTextER.nativeElement.offsetWidth > minWidth) {
        this.widthDesignation = this.invTextER.nativeElement.offsetWidth + 8;
      } else {
        this.widthDesignation = minWidth;
      }

    }, 0);
  }

  get customStyles() {
    return this.sanitizer.bypassSecurityTrustHtml(`<style>${this.options.customStyles || ''}</style>`);
  }

  ngOnDestroy() {
    console.log('Destroy');
  }

  // todo: нужно унифицировать эти события

  emitReportClick(type) {
    this.reportdownload.emit({type, text: this.designation, mktu: this.mktuSearchArray});
  }

  emitReportRisksClick() {
    this.reportrisksclick.emit({text: this.designation, mktu: this.mktuSearchArray});
  }

  emitAction(name: string, payload: any = {}) {
    console.log('action', name, payload);
    return this.action.emit({name, payload, state: {text: this.designation, mktu: this.mktuSearchArray}});
  }

  sendEvent(synonym, type) {
    this.intercomEventsService.push({event: synonym, typeIP: type});
  }

  changeZ(evt) {
    this.zIndex = evt;
  }
}
