import { Tariff } from '../../models/rates.interfaces';
import { REQUEST_TYPE } from 'projects/app-ipid/src/app/shared/enums/request-type';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { Input, Output, Component, EventEmitter, ChangeDetectionStrategy, AfterViewInit, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-tariffes',
  templateUrl: './tariffes.component.html',
  styleUrls: ['./tariffes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TariffesComponent implements AfterViewInit, OnDestroy {
  @Input() tariffes: Tariff[] = [];
  @Input() requestType: REQUEST_TYPE;
  @Input() activeTariff: Tariff['id'] | null = null;
  @Output() selectingTariff = new EventEmitter<Tariff['id']>();

  public mobile: boolean = false;
  private observer: ResizeObserver | null = null;
  private readonly body = this.document.querySelector("body");

  constructor(
    private readonly ref: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  public ngAfterViewInit(): void {
    this.observer = new ResizeObserver(entries =>
      entries.forEach(entry => this.changeModelByWidth(entry.contentRect.width)));
    this.observer.observe(this.body);
  }

  public ngOnDestroy(): void {
    this.observer.unobserve(this.body);
    this.observer.disconnect();
  }

  public selectTariff(tariff: Tariff['id']): void {
    this.activeTariff = tariff;
    this.sendIntercomEvent();
    this.selectingTariff.emit(tariff);
  }

  private sendIntercomEvent(): void {
    if (this.activeTariff) {
      switch(this.activeTariff) {
        case '000000001': return this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_MIN });
        case '000000002': return this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_OPT });
        case '000000003': return this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_MAX });
        default: console.warn(`Для тарифа id: ${this.activeTariff} отсутствует dataLayer событие`);
      }
    }
    // this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_TARIF, itemType: this.requestType });
  }

  private changeModelByWidth(width: number): void {
    this.mobile = width < 768;
    this.ref.detectChanges();
  }
}
