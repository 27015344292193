<!-- hidden file input for search by image -->
<input
  type="file"
  class="dn"
  (change)="fileChange($event)"
  #fileInput
>

<div class="full-size-search">
  <div class="ip-id_search_container">
    <div class="main__forms" *ngIf="searchDefault">

      <div class="search-field__wrap" #searchPopup>
        <div class="search-field" (click)="showSearchPopup()" data-intercom-target="search field">
          <div class="search-field__input-wrap">

            <div class="search-field__btn"
                 *ngIf="mktuSearchArray?.length"
                 (click)="clearAddedClasses('mktu', mktuSearchArray)">
              <p>
                {{ mktuSearchArray?.length }}
                {{ declOfNum(mktuSearchArray?.length, ['класс', 'класса', 'классов']) }} МКТУ
              </p>
              <button aria-label="clear"></button>
            </div>

            <div class="search-field__btn"
                 *ngIf="okvedSearchArray?.length"
                 (click)="clearAddedClasses('okved', okvedSearchArray)">
              <p>
                {{ okvedSearchArray?.length }}
                {{ declOfNum(okvedSearchArray?.length, ['класс', 'класса', 'классов']) }} ОКВЭД
              </p>
              <button aria-label="clear"></button>
            </div>

            <div class="search-field__btn"
                 (click)="clearAllCountriesSelected()">
              <p>
                <span *ngFor="let country of countriesSearchArray.slice(0, 1)">{{ country?.code }}</span>
                <span *ngIf="countriesSearchArray.length > 1">
                  и еще {{ countriesSearchArray.length - 1 }}
                </span>
              </p>
              <button aria-label="clear"></button>
            </div>

            <div class="search-field__btn"
                 *ngFor="let item of filterTrademarks"
                 (click)="onCheckboxSelect(item, $event)">
              <p>
                {{ item.name }}
              </p>
              <button aria-label="clear"></button>
            </div>

            <input *ngIf="!corpSearchByImageService.isLoadImage && (right === 'true' || !right)" type="text" class="search-field__input"
                   [style.width]="designation?.length ? '100%' : '226px'"
                   (click)="clickOnDesignation()"
                   [(ngModel)]="designation"
                   [ngModelOptions]="{standalone: true}"
                   (keyup.enter)="searchEnter();"
                   (focusout)="sendIntercomData({event: 'printSearchFocusOut', query: designation})"
                   [disabled]="disableButton"
                   [readOnly]="options?.isSearchByImage"
                   [placeholder]="placeholder?.length ? placeholder : ''">

            <div *ngIf="!designation?.length && !corpSearchByImageService.isLoadImage && corpSearchService.searchTypeOptions[1].accept === true && (right === 'true' || !right)"
                 class="upload-file_text" [class.disabled]="right === 'false'">
              <span (click)="triggerClick($event)">выберите файл</span>
            </div>

            <div class="image-btns" *ngIf="corpSearchByImageService.isLoadImage && (right === 'true' || !right)">
              <div class="upload-file_text">
                <span (click)="triggerClick($event)">Заменить файл</span>
              </div>
              <div class="input_text">
                или
              </div>
              <div class="delete-image_text">
                <span (click)="deleteImage($event)">удалить файл</span>
              </div>
            </div>

            <div class="image-btns" *ngIf="corpSearchByImageService.isLoadImage && right === 'false'">
              <div class="delete-image_text">
                <span (click)="deleteImage($event)">Удалить файл</span>
              </div>
            </div>

            <button *ngIf="designation?.length"
                    class="search-field__input-clear"
                    aria-label="clear"
                    (click)="clearSearchField()">
            </button>
          </div>
        </div>

        <div (keyup.enter)="searchEnter()" *ngIf="isShowSearchPopup" class="search-field-popup">
          <div class="search-field-popup__column-wrap">
            <div class="search-field-popup__column">
              <h3 class="search-field-popup__title">Классы МКТУ / ОКВЭД</h3>
              <div class="search-field-popup__select" #searchModalMKTU>
                <div class="search-field-popup__select-input"
                     [class.open]="isShowSearchMKTU"
                     (click)="isShowSearchMKTU = !isShowSearchMKTU; searchByJSON()"
                >
                  <p>
                    <span *ngFor="let item of mktuOkvedSearchArray">
                      {{ item?.name }}
                    </span>
                    <span *ngIf="!mktuOkvedSearchArray.length">
                      Все классы
                    </span>
                  </p>
                </div>
                <div data-intercom-target="activities classifier" class="mktu-modal search-field-popup__modal"
                     *ngIf="isShowSearchMKTU">
                  <p class="search-field-popup__modal-desc">
                    <span>Выбраны:</span>
                    <button (click)="clearAddedAll()">
                      Очистить все
                    </button>
                  </p>

                  <ul class="search-field-popup__selected-list">
                    <li class="search-field__btn search-field__btn--popup"
                        *ngFor="let item of mktuOkvedSearchArray"
                        [ngClass]="{
                          'search-field__btn--mktu': item?.mktu,
                          'search-field__btn--okved': item?.okved}"
                        (click)="deleteNumberFromSearchMKTUOKVED(item)">
                      <span>{{ item?.name }}</span>
                      <button aria-label="close"></button>
                    </li>
                  </ul>

                  <div class="search-field-popup__btn-wrap">
                    <button class="mktu-btn" [ngClass]="searchPopupViewType === 'mktu' ? 'selected' : ''"
                            (click)="changeSearchPopupView('mktu')">
                      Классы МКТУ
                    </button>
                    <button class="okved-btn" [ngClass]="searchPopupViewType === 'okved' ? 'selected' : ''"
                            (click)="changeSearchPopupView('okved')">
                      ОКВЭД
                    </button>
                  </div>

                  <form class="search-field-popup__form modal-input"
                        *ngIf="['okved', 'mktu'].indexOf(searchPopupViewType) > -1">
                    <input
                      *ngIf="isShowSearchInput"
                      [(ngModel)]="query"
                      #queryEl
                      type="text" name="mktuQuery"
                      placeholder="Например кафе или 63.01 или 42" autocomplete="off"
                      (keyup.enter)="fetchMktu();fetchOkved()"
                      (ngModelChange)="fetchMktuAndOkvedByQuery()"
                      (focusout)="sendIntercomData({event: 'printSearchMktuOkvdFocusOut', query: query})"
                    >
                  </form>

                  <div class="response-result-list" *ngIf="!isLoadingSearchClases; else loader"
                       [ngSwitch]="searchPopupViewType">

                    <ng-container *ngSwitchCase="'mktu'">
                      <ul *ngIf="mktuMappedResponse?.length; else classesNotFound"
                          class="search-field-popup__checkboxes search-field-popup__checkboxes--mktu">
                        <li class="search-field-popup__checkbox-item"
                            *ngFor="let item of mktuMappedResponse; let i = index"
                        >
                          <input type="checkbox" class="search-field-popup__checkbox-input" name="{{ item?.name }}"
                                 id="1-{{ i }}"
                                 [ngClass]="item?.selected ? 'search-field-popup__checkbox-input--checked' : ''"
                          >
                          <label
                            (click)="addMktuToSearch(item, false); $event.stopPropagation()"
                            class="search-field-popup__checkbox-label search-field-popup__checkbox-label--mktu"
                            for="1-{{ i }}">
                            <span>
                              <b>{{ item.name }}</b>&nbsp;
                              <span [title]="item.desc" [innerHTML]="item.desc"></span>
                            </span>
                          </label>
                        </li>
                      </ul>
                    </ng-container>

                    <ng-container *ngSwitchCase="'okved'">
                      <ul *ngIf="okvedMappedResponse?.length; else classesNotFound"
                          class="search-field-popup__checkboxes search-field-popup__checkboxes--mktu">
                        <li class="search-field-popup__checkbox-item"
                            *ngFor="let item of okvedMappedResponse; let i = index"
                        >
                          <input type="checkbox" class="search-field-popup__checkbox-input" name="{{ item?.name }}"
                                 id="2-{{ i }}"
                                 [ngClass]="item?.selected ? 'search-field-popup__checkbox-input--checked' : ''"
                          >
                          <label class="search-field-popup__checkbox-label search-field-popup__checkbox-label--mktu"
                                 for="2-{{ i }}"
                                 (click)="addOkvedToSearch(item)">
                            <span>
                              <b>{{ item.name || item.code }}</b>&nbsp;
                              <span [title]="item.description" [innerHTML]="item.description"></span>
                            </span>
                          </label>
                        </li>
                      </ul>
                    </ng-container>

                    <ng-template #classesNotFound>
                      <div class="classesNotFound">
                        Ничего не нашлось. Попробуйте изменить формулировку.
                      </div>
                    </ng-template>

                  </div>

                </div>
              </div>
            </div>

            <div class="search-field-popup__column">
              <h3 class="search-field-popup__title">Территория действия</h3>
              <div class="search-field-popup__select" (click)="searchByCountries()" #searchModalCountries>
                <div class="search-field-popup__select-input"
                     [class.open]="isShowCountriesPopup"
                     [class.disabled]="corpSearchByImageService.isLoadImage"
                     (click)="isShowCountriesPopup = !isShowCountriesPopup;"
                >
                  <p>
                    <span *ngFor="let country of countriesSearchArray">
                      {{ country.code }}
                    </span>
                  </p>
                </div>
                <div class="countries search-field-popup__modal" *ngIf="isShowCountriesPopup">
                  <p class="search-field-popup__modal-desc">
                    <span>Выбраны:</span>
                    <button (click)="clearAllCountriesSelected()">
                      Очистить все
                    </button>
                  </p>

                  <ul class="search-field-popup__selected-list">
                    <li class="search-field__btn search-field__btn--popup" *ngFor="let country of countriesSearchArray"
                        (click)="deleteNumberFromSearch('countries', country)">
                      <span>{{ country?.code }}</span>
                      <button aria-label="close"></button>
                    </li>
                  </ul>

                  <form class="search-field-popup__form modal-input">
                    <input type="text" placeholder="Поиск по странам" autocomplete="off"
                           #searchCountries
                           (input)="selectFilter($event)"
                           (change)="selectFilter($event)"
                           aria-describedby="search-text"
                    >
                  </form>

                  <p class="search-field-popup__modal-desc search-field-popup__modal-desc--all">
                    <span>Выбрать все:</span>
                    <button (click)="addCountriesSelected('СНГ')">
                      СНГ
                    </button>
                  </p>

                  <ul class="search-field-popup__checkboxes">
                    <li class="search-field-popup__checkbox-item"
                        *ngFor="let country of !searchCountries.value ? countries : filterCountryList">
                      <input type="checkbox" class="search-field-popup__checkbox-input" name="{{ country?.code }}"
                             id="1-{{ country?.code }}"
                             (change)="addCountryToSearch(country)"
                             [ngClass]="country?.selected ? 'search-field-popup__checkbox-input--checked' : ''"
                      >
                      <label class="search-field-popup__checkbox-label" for="1-{{ country?.code }}">
                        <span class="search-field-popup__checkbox-code">{{ country?.code }}</span>
                        <span class="search-field-popup__checkbox-name">{{ country?.name }}</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="search-field-popup__column-wrap">
            <div class="search-field-popup__column">
              <h3 class="search-field-popup__title">Правообладатель</h3>
              <div class="search-field-popup__select">
                <form class="search-field-popup__form">
                  <input
                    *ngIf="isShowSearchInput"
                    [class.disabled]="corpSearchByImageService.isLoadImage"
                    [(ngModel)]="searchLegalId"
                    [ngModelOptions]="{standalone: true}"
                    placeholder="Укажите правообладателя"
                    type="text"
                  >
                </form>
              </div>
            </div>

            <div class="search-field-popup__column">
              <h3 class="search-field-popup__title">Номер регистрации или заявки</h3>
              <div class="search-field-popup__select">
                <form class="search-field-popup__form">
                  <input
                    *ngIf="isShowSearchInput"
                    [class.disabled]="corpSearchByImageService.isLoadImage"
                    [(ngModel)]="searchNumber"
                    [ngModelOptions]="{standalone: true}"
                    placeholder="Укажите номер"
                    type="text"
                  >
                </form>
              </div>
            </div>
          </div>
          <div class="search-field-popup__column-wrap">
            <div class="search-field-popup__column">
              <h3 class="search-field-popup__title">Дата приоритета</h3>
              <div class="search-field-popup__select">
                <form class="search-field-popup__form">
                  <input
                    *ngIf="isShowSearchInput"
                    [class.disabled]="corpSearchByImageService.isLoadImage"
                    [(ngModel)]="priorityDate"
                    [ngModelOptions]="{standalone: true}"
                    placeholder="Укажите дату"
                    bsDatepicker
                  >
                </form>
              </div>
            </div>

            <div class="search-field-popup__column">
              <h3 class="search-field-popup__title">Дата регистрации</h3>
              <div class="search-field-popup__select">
                <form class="search-field-popup__form">
                  <input
                    *ngIf="isShowSearchInput"
                    [class.disabled]="corpSearchByImageService.isLoadImage"
                    [(ngModel)]="registrationDate"
                    [ngModelOptions]="{standalone: true}"
                    placeholder="Укажите дату"
                    bsDaterangepicker
                  >
                </form>
              </div>
            </div>
          </div>
          <div class="search-field-popup__column-wrap">
            <div class="search-field-popup__column">
              <h3 class="search-field-popup__title">Дата подачи заявки</h3>
              <div class="search-field-popup__select">
                <form class="search-field-popup__form">
                  <input
                    *ngIf="isShowSearchInput"
                    [class.disabled]="corpSearchByImageService.isLoadImage"
                    [(ngModel)]="applicationDate"
                    [ngModelOptions]="{standalone: true}"
                    placeholder="Укажите дату"
                    bsDaterangepicker
                  >
                </form>
              </div>
            </div>
          </div>
          <div>
            <h3 class="search-field-popup__title">Показать в результатах поиска</h3>
            <div
              class="search-field-popup__checkboxes-list"
              [class.disabled]="corpSearchByImageService.isLoadImage">
              <ng-container *ngFor="let checkbox of checkboxes; let i = index">
                <input type="checkbox" id="{{ i }}" name="result"
                       [(ngModel)]="checkbox.checked"
                       (change)="onCheckboxSelect(checkbox, $event)"
                >
                <label for="{{ i }}"
                       [class.grey]="filterTrademarks.length && !checkbox.checked"
                >{{ checkbox?.name }}</label>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="isShowSearchPopup" (click)="closeShowSearchPopup()"
             class="overlay overlay--searchList"
             style="background: none;
                    z-index: 4;">
        </div>

        <ng-template #loader>
          <div style="display: block; height: 265px">
            <div class="loader"></div>
          </div>
        </ng-template>

      </div>

      <button data-intercom-target="check button" class="search-field__submit"
              [class.disabled]="(!corpSearchByImageService.isLoadImage && !designation?.length && !searchLegalId && !searchNumber) || right === 'false'"
              (click)="isSearchAllowed(designation);searchImages()">
        Найти
      </button>

      <span *ngIf="searchError && searchDefault && !designation?.length && disableButton"
            class="main__form main__form--error"
            [style.width.px]="1070">
      {{ getSearchErrorText() }}
    </span>
    </div>
  </div>
</div>

<!-- Поиск для мобильной версии --------------------------------------------------->

<div class="mobile-search">
  <div class="mobile-search-container">
    <!--    <span #invisibleText id="invisibleTextID">{{ designation }}</span>-->
    <div class="" *ngIf="searchDefault">
      <div class="mobile-search-block" data-intercom-target="search field" #searchContainerEl>
        <div class="input-search__mobile">
          <textarea *ngIf="!corpSearchByImageService.isLoadImage && (right === 'true' || !right)"
                    type="text" class="mobile-search-block__textarea"
                    [class.invalid-red]="searchError && !designation?.length" #designationEl
                    (click)="clickOnDesignation()"
                    [(ngModel)]="designation" [ngModelOptions]="{standalone: true}"
                    [maxLength]="this.widthDesignation > 850 ? designation?.length : 100"
                    (keyup.enter)="checkTradeMarks()"
                    [placeholder]="placeholder?.length ? placeholder : ''">

        </textarea>

          <div *ngIf="!designation?.length && !corpSearchByImageService.isLoadImage && corpSearchService.searchTypeOptions[1].accept === true && (right === 'true' || !right)"
               class="upload-file_text"
               [class.disabled]="right === 'false'">
            <span (click)="triggerClick($event)">выберите файл</span>
          </div>

          <div class="image-btns" *ngIf="corpSearchByImageService.isLoadImage && (right === 'true' || !right)">
            <div class="upload-file_text">
              <span (click)="triggerClick($event)">Заменить файл</span>
            </div>
            <div class="input_text">
              или
            </div>
            <div class="delete-image_text" [class.disabled]="right === 'false'">
              <span (click)="deleteImage($event)">удалить файл</span>
            </div>
          </div>

          <div class="image-btns" *ngIf="corpSearchByImageService.isLoadImage && right === 'false'">
            <div class="delete-image_text">
              <span (click)="deleteImage($event)">Удалить файл</span>
            </div>
          </div>
        </div>

        <span style="display: block; height: 1px; background: rgba(21, 36, 56, 0.15);margin: 0 -5px"></span>
        <!--todo-->
        <a *ngIf="okvedSearchArray.length || mktuSearchArray.length" (click)="clearAddedAll()"
           class="clear-classes">
          Очистить вид деятельности
        </a>

        <div class="added-number__wrap">
          <div class="added-number added-number__mobile" *ngFor="let country of countriesSearchArray">
            <span class="added-number__delete" (click)="deleteNumberFromSearch('countries', country)"></span>
            <div class="added-number__title">Страна</div>
            <div class="added-number__number">{{ country.code }}</div>
          </div>
        </div>

        <button *ngIf="!corpSearchByImageService.isLoadImage"
                (click)="searchByCountriesMobile()"
                [disabled]="disableCountry"
                class="plus_button__mobile" data-intercom-target="activities">
          + Территория действия
        </button>

        <div class="added-number__wrap">
          <div class="added-number added-number__mobile added-number_okved"
               *ngFor="let okvedItem of okvedSearchArray">
            <span class="added-number__delete" (click)="deleteNumberFromSearch('okved', okvedItem)"></span>
            <div class="added-number__title added-number__title_okved">ОКВЭД</div>
            <div class="added-number__number">{{ okvedItem.code }}</div>
            <div *ngIf="okvedItem.description" class="added-number__popover">
              <div [innerHTML]="okvedItem.description"></div>
            </div>
          </div>

          <div class="added-number added-number__mobile added-number_mktu" *ngFor="let mktuItem of mktuSearchArray">
            <span class="added-number__delete" (click)="deleteNumberFromSearch('mktu', mktuItem)"></span>
            <div class="added-number__title added-number__title_mktu">МКТУ</div>
            <div class="added-number__number">{{ mktuItem.number }}</div>
            <div class="added-number__popover">
              <div>{{ mktuItem.desc }}</div>
              <div *ngIf="mktuItem.subClasses && mktuItem.subClasses.length">
                <hr style="background: #0be281; margin: 4px 0;" noshade size="1">
                <div *ngFor="let subClass of mktuItem.subClasses" [innerHTML]="'- ' + subClass.desc"></div>
              </div>
            </div>
          </div>
        </div>

        <button (click)="searchByJSON()"
                class="plus_button__mobile" data-intercom-target="activities">
          + Вид деятельности
        </button>

        <div class="search-field-popup__select">
          <form class="search-field-popup__form">
            <input
              class="input-mobile"
              *ngIf="!corpSearchByImageService.isLoadImage"
              [class.disabled]="corpSearchByImageService.isLoadImage"
              [(ngModel)]="searchLegalId"
              [ngModelOptions]="{standalone: true}"
              placeholder="Укажите правообладателя"
              type="text"
            >
          </form>
        </div>

        <div class="search-field-popup__select">
          <form class="search-field-popup__form">
            <input
              class="input-mobile"
              *ngIf="!corpSearchByImageService.isLoadImage"
              [class.disabled]="corpSearchByImageService.isLoadImage"
              [(ngModel)]="searchNumber"
              [ngModelOptions]="{standalone: true}"
              placeholder="Укажите номер регистрации"
              type="text"
            >
          </form>
        </div>

      <div class="search-field-popup__select">
        <form class="search-field-popup__form">
          <input
            class="input-mobile"
            *ngIf="!corpSearchByImageService.isLoadImage"
            [class.disabled]="corpSearchByImageService.isLoadImage"
            [(ngModel)]="priorityDate"
            [ngModelOptions]="{standalone: true}"
            placeholder="Укажите дату приоритета"
            bsDatepicker
          >
        </form>
      </div>

        <div class="search-field-popup__select">
          <form class="search-field-popup__form">
            <input
              class="input-mobile"
              *ngIf="!corpSearchByImageService.isLoadImage"
              [class.disabled]="corpSearchByImageService.isLoadImage"
              [(ngModel)]="registrationDate"
              [ngModelOptions]="{standalone: true}"
              placeholder="Укажите дату регистрации"
              bsDaterangepicker
            >
          </form>
        </div>

      <div class="search-field-popup__select">
        <form class="search-field-popup__form">
          <input
            class="input-mobile"
            *ngIf="!corpSearchByImageService.isLoadImage"
            [class.disabled]="corpSearchByImageService.isLoadImage"
            [(ngModel)]="applicationDate"
            [ngModelOptions]="{standalone: true}"
            placeholder="Укажите дату подачи заявки"
            bsDaterangepicker
          >
        </form>
      </div>

      </div>
      <div *ngIf="searchError && searchDefault && !corpSearchByImageService.fileSelected"
           class="main__form--error mobile-error">
        {{ getSearchErrorText() }}
      </div>

      <button data-intercom-target="check button" class="submit_red__mobile"
              [class.disabled]="(!corpSearchByImageService.isLoadImage && !designation?.length && !searchLegalId && !searchNumber) || right === 'false'"
              (click)="isSearchAllowed(designation);searchImages()">
        Проверить
      </button>

      <div class="countries search-field-popup__modal" *ngIf="isShowCountriesPopupMobile">
        <div class="choice-classes__header">
          <div class="choice-classes__header-wrap">
            <span>Страна действия</span>
            <button class="choice-classes__header-close" (click)="closePopupMobile()"></button>
          </div>
        </div>

        <div #searchModalCountriesMobile>

          <p class="search-field-popup__modal-desc">
            <span>Выбраны:</span>
            <button>
              Очистить все
            </button>
          </p>

          <ul class="search-field-popup__selected-list">
            <li class="search-field__btn search-field__btn--popup" *ngFor="let country of countriesSearchArray"
                (click)="deleteNumberFromSearch('countries', country)">
              <span>{{ country?.code }}</span>
              <button aria-label="close"></button>
            </li>
          </ul>

          <form class="search-field-popup__form">
            <img src="/assets/icons/search.svg" class="search-icon">
            <input type="text" placeholder="Поиск по странам" autocomplete="off"
                   #searchCountries
                   (input)="selectFilter($event)"
                   (change)="selectFilter($event)"
                   aria-describedby="search-text"
            >
          </form>

          <p class="search-field-popup__modal-desc search-field-popup__modal-desc--all">
            <span>Выбрать все:</span>
            <button (click)="addCountriesSelected('СНГ')">
              СНГ
            </button>
          </p>

          <ul class="search-field-popup__checkboxes">
            <li class="search-field-popup__checkbox-item"
                *ngFor="let country of !searchCountries.value ? countries : filterCountryList">
              <input type="checkbox" class="search-field-popup__checkbox-input" name="{{ country?.code }}"
                     id="{{ country?.code }}"
                     (change)="addCountryToSearch(country)"
                     [ngClass]="country?.selected ? 'search-field-popup__checkbox-input--checked' : ''"
              >
              <label class="search-field-popup__checkbox-label" for="{{ country?.code }}">
                <span class="search-field-popup__checkbox-code">{{ country?.code }}</span>
                <span class="search-field-popup__checkbox-name">{{ country?.name }}</span>
              </label>
            </li>
          </ul>
        </div>

        <div class="apply-filter__mobile-wrap">
          <button class="apply-filter__mobile" (click)="applyMobileFilterByClasses()">
            Применить выбор
          </button>
        </div>
      </div>

      <div *ngIf="isShowSearchMKTUMobile">
        <div class="choice-classes__header">
          <div class="choice-classes__header-wrap">
            <span>Вид деятельности</span>
            <button class="choice-classes__header-close" (click)="closePopupMobile()"></button>
          </div>
        </div>

        <div class="choice-classes" #searchModalMKTUMobile>

          <div class="choice-classes__filters-and-search">

            <div *ngIf="['all', 'okved', 'okvedBySearch', 'mktu', 'mktuBySearch'].indexOf(searchPopupViewType) > -1"
                 class="filters-or-search-item">
              <img class="search-icon" src="/assets/icons/search.svg">
              <input [(ngModel)]="query" #queryEl type="text" name="query" placeholder="Кафе, номер класса или ОКВЭД"
                     autocomplete="off" class="ip-id_Input_wide ip-id_Input_wide__mobile"
                     (click)="clickByMobileQueryInput()"
                     (keyup.enter)="fetchMktu();fetchOkved()" (ngModelChange)=fetchMktuAndOkvedByQuery()>
            </div>

            <div *ngIf="searchPopupViewType === 'inn-ogrn'" class="inn-ogrn-search-input">
              <div *ngIf="legalName" [ngStyle]="getDynamicMarginForLegalName()" class="legal-name legal-name--mobile">
                {{ legalName }}
              </div>
              <img class="search-icon" style="top: 15px;" src="/assets/icons/search.svg">
              <input type="text" name="innOgrnQuery" #innOgrn="ngModel" [(ngModel)]="innOgrnQuery" maxlength="15"
                     pattern='^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$' placeholder="Подбор ОКВЭД по ИНН\ОГРН"
                     autocomplete="off" class="ip-id_Input_wide ip-id_Input_wide__mobile validate-border-color"
                     (ngModelChange)=legalChanged()>
              <div *ngIf="innOgrn.invalid && (innOgrn.dirty || innOgrn.touched)" class="error-inn-ogrn">
                Поле не может быть пустым и должно содержать 10, 12, 13 или 15 цифр.
              </div>
            </div>

            <div *ngIf="['all', 'okved', 'okvedBySearch', 'mktu', 'mktuBySearch'].indexOf(searchPopupViewType) > -1"
                 class="filters-or-search-item">
              <button class="choice-classes__button choice-classes__button--okved"
                      *ngIf="searchPopupViewType !== 'inn-ogrn' && !options.search || (searchPopupViewType !== 'inn-ogrn' && options.search === 'okved')"
                      [ngClass]="['okved', 'okvedBySearch'].indexOf(searchPopupViewType) > -1 ? '__selected' : ''"
                      (click)="changeSearchPopupView('okved', true)">
                Показать только ОКВЭД
              </button>
              <button class="choice-classes__button choice-classes__button--mktu"
                      *ngIf="searchPopupViewType !== 'inn-ogrn' && !options.search || (searchPopupViewType !== 'inn-ogrn' && options.search === 'mktu')"
                      [ngClass]="['mktu', 'mktuBySearch'].indexOf(searchPopupViewType) > -1 ? '__selected' : ''"
                      (click)="changeSearchPopupView('mktu', true)">
                Показать только МКТУ
              </button>
            </div>

          </div>

          <div class="response-result-list response-result-list__mobile" [ngSwitch]="searchPopupViewType">

            <!--            all-->
            <div *ngSwitchCase="'all'">
              <div *ngIf="mktuMobileTempArray?.length || okvedMobileTempArray; else classesNotFound">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, okvedMobileTempArray, true)"
                     class="choose__btn choose__btn--all">Выбрать все</a>
                  <a (click)="allMobileSelecting(false, okvedMobileTempArray, true)"
                     class="choose__btn choose__btn--clean">Снять выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of okvedMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'okved')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'okved')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description.length > 39" (click)="showMoreDescription(i, 'okved')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
                <div class="response-result-list__item" *ngFor="let item of mktuMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name mktu-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'mktu')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>{{item.name}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'mktu')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.desc"
                       [innerHTML]="item.desc">
                  </div>
                  <img *ngIf="!item.opened && item.desc?.length > 39" (click)="showMoreDescription(i, 'mktu')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            mktu-->
            <div *ngSwitchCase="'mktu'">
              <div *ngIf="mktuMobileTempArray?.length; else classesNotFound">
                <div class="choose">
                  <a (click)="allMobileSelecting( true, mktuMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать
                    все</a>
                  <a (click)="allMobileSelecting(false, mktuMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять
                    выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of mktuMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name mktu-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'mktu')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>{{item.name}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'mktu')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.desc"
                       [innerHTML]="item.desc">
                  </div>
                  <img *ngIf="!item.opened && item.desc?.length > 39" (click)="showMoreDescription(i, 'mktu')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            mktu sub-->
            <div *ngSwitchCase="'mktuBySearch'">
              <div *ngIf="mktuBySearchMobileTempArray?.length; else mobileSearchByClassesStub">
                <div class="choose">
                  <a (click)="allMobileSelecting( true, mktuBySearchMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать все</a>
                  <a (click)="allMobileSelecting( false, mktuBySearchMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of mktuBySearchMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name mktu-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'mktuBySearch', item.number)"
                             [checked]="item.checkedMobile" class="response-result-list__item--checkbox"
                             type="checkbox">
                      <span>{{item.name}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'mktuBySearch')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.desc"
                       [innerHTML]="item.desc">
                  </div>
                  <img *ngIf="!item.opened && item.desc?.length > 39" (click)="showMoreDescription(i, 'mktuBySearch')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            okved-->
            <div *ngSwitchCase="'okved'">
              <div *ngIf="okvedMobileTempArray?.length; else classesNotFound">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, okvedMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать
                    все</a>
                  <a (click)="allMobileSelecting(false, okvedMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять
                    выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of okvedMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'okved')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'okved')" class="response-result-list__item__desc "
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description?.length > 39" (click)="showMoreDescription(i, 'okved')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            okved sub-->
            <div *ngSwitchCase="'okvedBySearch'">
              <div *ngIf="okvedBySearchMobileTempArray?.length; else mobileSearchByClassesStub">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, okvedBySearchMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать все</a>
                  <a (click)="allMobileSelecting( false, okvedBySearchMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of okvedBySearchMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'okvedBySearch', item.code)"
                             [checked]="item.checkedMobile" class="response-result-list__item--checkbox"
                             type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'okvedBySearch')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description?.length > 39"
                       (click)="showMoreDescription(i, 'okvedBySearch')" class="plus-icon"
                       src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            inn-ogrn-->
            <div *ngSwitchCase="'inn-ogrn'">
              <div *ngIf="legalMobileTempArray?.length; else innOgrnStub">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, legalMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать
                    все</a>
                  <a (click)="allMobileSelecting( false, legalMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять
                    выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of legalMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'legal', item.code)"
                             [checked]="item.checkedMobile" class="response-result-list__item--checkbox"
                             type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'legal')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description?.length > 39" (click)="showMoreDescription(i, 'legal')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <ng-template #classesNotFound>
              <div class="classesNotFound">
                Классы не найдены
              </div>
            </ng-template>

            <ng-template #mobileSearchByClassesStub>
              <div *ngIf="!isLoadingSearchClases; else loader" class="innOgrnStub__mobile">
                После ввода, здесь отобразятся<br>
                виды деятельности по вашему запросу
                <span *ngIf="['okvedBySearch', 'mktuBySearch'].indexOf(searchPopupViewType) > -1">
                  <br>или<br>
                  <a (click)="changeSearchPopupView('all')">Вернуться к классам первого уровня</a>
                </span>
              </div>
            </ng-template>

            <ng-template #innOgrnStub>
              <div *ngIf="!isLoadingLegal; else loader" class="innOgrnStub__mobile">
                После ввода ИНН или ОГРН<br>
                организации здесь отобразятся ее<br>
                виды деятельности
              </div>
            </ng-template>

          </div>

          <div class="apply-filter__mobile-wrap">
            <button class="apply-filter__mobile" (click)="applyMobileFilterByClasses()">
              Применить выбор
            </button>
          </div>

        </div>
      </div>

      <ng-template #loader>
        <div style="display: block; height: 265px">
          <div class="loader"></div>
        </div>
      </ng-template>

    </div>
  </div>
</div>

