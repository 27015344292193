import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ISearchHistoryDetail } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { CorpSearchByNumberService } from '../../services/corp-search-by-number.service';
import { CorpSearchSaveReportService } from '../../services/corp-search-save-report.service';
import { ActivatedRoute } from '@angular/router';
import {CorpSearchApiService} from '../../services/corp-search-api.service';

@Component({
  selector: 'app-corp-search-by-number',
  templateUrl: './corp-search-by-number.component.html',
  styleUrls: ['./corp-search-by-number.component.scss']
})
export class CorpSearchByNumberComponent implements OnInit, OnDestroy {

  @Input() initialSearchHistory: ISearchHistoryDetail = null;
  @Input() initialSearchHistoryAction: 'show' | 'repeat' = null;

  constructor(
    readonly corpSearchByNumberService: CorpSearchByNumberService,
    readonly corpSearchSaveService: CorpSearchSaveReportService,
    readonly activatedRoute: ActivatedRoute,
    readonly corpSearchApiService: CorpSearchApiService
  ) {
  }

  ngOnDestroy(): void {
    this.corpSearchByNumberService.reset();
  }

  ngOnInit(): void {
    if (this.initialSearchHistory) {
      this.corpSearchByNumberService.loadBySearchHistory(this.initialSearchHistory, this.initialSearchHistoryAction === 'show');
    } else {
      this.parseSearchParams();
    }
  }

  private parseSearchParams() {
    const query = this.activatedRoute.snapshot.queryParamMap;

    if (!query.has('q')) {
      return;
    } else {
      const searchNumber = query.get('q');
      this.corpSearchByNumberService.load({number: searchNumber || ''});
    }

  }

  onSearch(searchNumber: string) {
    this.corpSearchByNumberService.load({number: searchNumber});
  }

  onSelect() {
    this.corpSearchSaveService.onSelect(
      'tradeMarks',
      this.corpSearchByNumberService.items.tradeMarks.filter(i => i.checked)
    );
  }

}
