import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export type ButtonIconType = 'arrow-right' | 'arrow-left';

@Component({
  selector: 'app-button-icon',
  templateUrl: './button-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonIconComponent {
  @Input() icon: ButtonIconType;
}
