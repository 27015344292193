<div class="tabs__wrap">
  <ul class="tabs">
    <li class="tabs-item" [class.tabs-item--active]="active && active === option.value"
        [routerLinkActive]="'tabs-item--active'"
        [routerLink]="option.route ? option.route : null"
        *ngFor="let option of options">
      <button
        [disabled]="option.disabled === true"
      >
        {{option.title}}
      </button>
    </li>
  </ul>
</div>
