import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  constructor() {
  }

  get isMonitoringEnabledInMenu() {
    return this.isMonitoringEnabled;
  }

  get isMonitoringEnabled() {
    return this.isIpid || this.isGardiumOnline || this.isAlfa || this.isSber;
  }

  get isLoadCustomScripts() {
    return this.isAlfa || this.is1cStart || this.isMts || this.isGardiumOnline || this.isGardiumPro || this.isYouKassa || this.isTochka;
  }

  get menuType() {
    if (this.isGardiumPro || this.isX5) {
      return 2;
    } else if (this.isIpid || this.isGardiumOnline || this.isAlfa || this.isSber || this.isBrandSearch) {
      return 1;
    } else {
      return 3;
    }
  }

  get isShowSocialAuth() {
    return this.isGardiumOnline || this.isAlfa;
  }

  get is1cStart() {
    return this.brand === '1C-START';
  }

  get isMts() {
    return this.brand === 'MTS-KASSA';
  }

  get isIpid() {
    return this.brand === 'IP-ID';
  }

  get isGardiumOnline() {
    return this.brand === 'GARDIUM-ONLINE';
  }

  get isAlfa() {
    return this.brand === 'ALFA';
  }

  get isGardiumPro() {
    return this.brand === 'IPID-PRO';
  }

  get isX5() {
    return this.brand === 'X5';
  }

  get isOpen() {
    return this.brand === 'OPEN';
  }

  get isSber() {
    return this.brand === 'SBER';
  }

  get isYouKassa() {
    return this.brand === 'YOOKASSA';
  }

  get isTochka() {
    return this.brand === 'TOCHKA';
  }

  get isCorp() {
    return this.isX5 || this.isGardiumPro;
  }

  get brand() {
    return environment.BRAND;
  }

  get isBrandSearch() {
    return this.brand === 'BRAND-SEARCH';
  }

  // TODO: check! return all is false?.
  // get isProd() {
  //   return environment.production;
  // }

}
