<div class="page-wrapper">
  <main>
    <section  class="organization-added">
      <div class="organization-added__wrap">
        <div class="container" *ngIf="success">
          <svg width="32" height="32" viewBox="0 0 60 60" class="organization-added__img" >
            <circle cx="30" cy="30" r="30"/>
            <path d="M19 30L26 37L40 23" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <p *ngIf="name" class="organization-added__title">Организация {{name}} добавлена</p>
        </div>
        <div class="container" *ngIf="!success">
          <img src="/assets/icons/company-not-added.svg" alt="" class="organization-added__img" width="32" height="32">
          <p *ngIf="name" class="organization-added__title">Не удалось добавить организацию {{name}}</p>
        </div>
      </div>
      <div class="container">
        <p class="organization-added__desc" *ngIf="success">
          Вы можете от имени организации создавать заявки и управлять своей интеллектуальной собственностью.
          Эксперты {{brand}} помогут вам на всех этапах взаимодействия с сервисом
        </p>
        <p class="organization-added__desc" *ngIf="!success">
          Данная компания уже зарегистрирована в сервисе.
          Вы сможете использовать сервис для получения услуг от имени указанной организации,
          после подтверждения ваших прав администратором. Если вы считаете что должны являться
          администратором этой организации, то обратитесь в службу поддержки.
        </p>
        <button class="btn" (click)="toMainPage()">Перейти на главную</button>
      </div>
    </section>
  </main>
</div>
