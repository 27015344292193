import { PaymentState } from '../../store/payment.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-payment-cash',
  templateUrl: './payment-cash.component.html',
  styleUrls: ['./payment-cash.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCashComponent {
  @ViewSelectSnapshot(PaymentState.payer) payer: string | null;
  public form: FormGroup = new FormGroup({
    INN: new FormControl({ value: '', disabled: true }, Validators.required),
    OGRN: new FormControl({ value: '', disabled: true }, Validators.required),
    name: new FormControl({ value: '', disabled: true }, Validators.required),
    address: new FormControl({ value: '', disabled: true }, Validators.required),
    sum: new FormControl({ value: '', disabled: true }, Validators.required),
  });
}
