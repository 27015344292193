import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, HostListener, ChangeDetectorRef } from '@angular/core';
import { SearchService } from '../../../../../services/search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-domain-modal',
  templateUrl: './domain-modal.component.html',
  styleUrls: ['./domain-modal.component.scss']
})

export class DomainModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() modalEvent = new EventEmitter();
  private readonly onDestroy = new Subject<void>();
  sourceData: any = {};
  isLoading = false;
  overDomains;
  owner;

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  constructor(
    private searchService: SearchService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '0';
    }
    this.getDocById();
  }

  getOverDomains() {
    const query = {
      id: this.sourceData._source.PSRN ? this.sourceData._source.PSRN.toString() : this.sourceData._source.TIN.toString(),
      searchDomains: true
    };
    this.searchService.getOverTradeMarksOrDomains(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.hits && data.hits.hits) {
        this.overDomains = data.hits.hits;
      }
    });
  }

  getDocById() {
    const query = {
      _id: this.data._id,
      _index: this.data._index
    };
    this.isLoading = true;
    this.searchService.getDocumentById(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits?.length) {
        this.sourceData = data.hits.hits[0];
      }
      if (this.sourceData._source.PSRN || this.sourceData._source.TIN) {
        this.getOwner();
        this.getOverDomains();
      }
      this.isLoading = false;
      this.ref.detectChanges();
    });
  }

  getOwner() {
    const query = {
      id: this.data._source.TIN || this.data._source.PSRN,
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.hits && data.hits.hits) {
        this.owner = data.hits.hits[0]._source.name;
      }
    });
  }

  getShortNameByWords(name) {
    return name.length > 18 ? name.substr(0, 16) + '...' : name;
  }

  getDomain(domainName) {
    return domainName._source.fullDomain.includes('XN--')
      ? `${domainName._source.domain}.${domainName._source.zone}`.toUpperCase()
      : domainName._source.fullDomain.toUpperCase();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  closeModal() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '1';
    }
    this.modalEvent.emit();
  }
}
