import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-payment',
  templateUrl: './warning-payment.component.html',
  styleUrls: ['./warning-payment.component.scss']
})
export class WarningPaymentComponent implements OnInit {
  attentionStep1: boolean;
  showOptions: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
