import { Routes } from '@angular/router';
import { IndexComponent } from './index/index/index.component';
import { SearchComponent } from './search/search.component';
import { environment } from '../../environments/environment';
import { CorpIndexComponent } from './corp/corp-index/components/corp-index/corp-index.component';
import { CorpSearchRepeatComponent } from '@web/features/corp/corp-search/components/corp-search-repeat/corp-search-repeat.component';
import { CorpSearchComponent } from '@web/features/corp/corp-search/components/corp-search/corp-search.component';
import { CorpHistoryContainerComponent } from '@web/features/corp/corp-history/corp-history-container/corp-history-container.component';
import { SystemLayoutComponent } from './layout/system-layout/system-layout.component';
import { AuthGuard } from '../shared/guardes/auth-guard.service';
import { UserSettingsComponent } from './monitoring/user-settings/user-settings.component';
import { OpenIndexComponent } from './open/open-index/open-index.component';
import { OpenDashboardComponent } from './open/open-dashboard/open-dashboard.component';
import { OpenLayoutComponent } from './open/open-layout/open-layout.component';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import { TRADEMARK_ROUTES } from '@web/features/requests/trademark/routes/trademark.routes';
import { RequestPatentComponent } from './request/components/request-patent/request-patent.component';
import { RequestContractComponent } from './request-contract/request-contract.component';
import { RequestCheckLabelComponent } from './request-check-label/request-check-label.component';

const commonRoutes = [
  ...TRADEMARK_ROUTES,
  {
    path: 'request',
    component: RequestPatentComponent,
  },
  {
    path: 'request/:designation',
    component: RequestPatentComponent,
  },
  {
    path: 'request-contract',
    component: RequestContractComponent,
  },
  {
    path: 'request-check-label',
    component: RequestCheckLabelComponent
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
  },
  {
    path: 'contracts',
    loadChildren: () =>
      import('./contracts/contracts.module').then((m) => m.ContractsModule),
  },
  {
    path: 'treaty-extension',
    loadChildren: () =>
      import('./treaty-extension/treaty-extension.module').then(
        (m) => m.TreatyExtensionModule
      ),
  },
  {
    path: APP_ROUTES_NAMES.CASES,
    loadChildren: () => import('./cases/cases.module').then(m => m.CasesModule),
  },
  {
    path: 'monitoring',
    loadChildren: () =>
      import('./monitoring/monitoring.module').then((m) => m.MonitoringModule),
  },
  {
    path: APP_ROUTES_NAMES.PORTFOLIO,
    loadChildren: () => import('@web/features/portfolio/portfolio.module').then(m => m.PortfolioModule),
  },
  {
    path: 'services',
    loadChildren: () =>
      import('@web/features/services/services.module').then(
        (m) => m.ServicesModule
      ),
  },
  {
    path: 'court',
    loadChildren: () =>
      import('./dispute-and-court/court.module').then((m) => m.CourtModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
];

export class SystemPageRoutesService {
  static buildRoutes() {
    if (environment.corporate) {
      return this.corpRoutes();
    } else if (environment.BRAND === 'OPEN') {
      return this.openBrandRoutes();
    } else {
      return this.otherBrandsRoutes();
    }
  }

  private static corpRoutes() {
    return this.withLayout(SystemLayoutComponent, [
      {
        path: '',
        component: CorpIndexComponent,
      },
      {
        path: 'search',
        children: [
          {
            path: 'repeat',
            component: CorpSearchRepeatComponent,
          },
          {
            path: ':type',
            component: CorpSearchComponent,
          },
          {
            path: '',
            component: CorpSearchComponent,
          },
        ],
      },
      {
        path: 'history',
        component: CorpHistoryContainerComponent,
      },
    ]);
  }

  private static openBrandRoutes() {
    return this.withLayout(OpenLayoutComponent, [
      {
        path: '',
        component: OpenIndexComponent,
      },
      {
        path: 'dashboard',
        component: OpenDashboardComponent,
      },
      {
        path: 'search',
        component: SearchComponent,
      },
    ]);
  }

  private static otherBrandsRoutes() {
    return this.withLayout(SystemLayoutComponent, [
      {
        path: '',
        component: IndexComponent,
      },
      {
        path: 'search',
        component: SearchComponent,
      },
    ]);
  }

  private static withLayout(layoutComponent: any, routes: Routes) {
    return [
      {
        path: '',
        component: layoutComponent,
        canActivate: [AuthGuard],
        children: [
          {path: 'index', redirectTo: '', pathMatch: 'full'},

          ...routes,
          ...commonRoutes,
        ],
      },
    ];
  }
}
