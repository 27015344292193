<form class="form form--margin" [formGroup]="formPayer">
  <div class="form__group">
    <div *ngIf="!isDebit" tabindex="0" class="form__input-wrap form__input-wrap--select"
         [class.form__input-wrap--select--after]="showOptions">
      <a class="form__link" (click)="showOptions = !showOptions"
         [class.form__link--gray]="!selectedPayerFromList"
         [class.disabled-options]="isDebit"
      >{{selectedPayerFromList?.name || (selectedPayerFromList ? 'Указать другого плательщика' : null) || 'Выберите плательщика'}}</a>
      <ul *ngIf="showOptions" class="form__select-list">
        <li class="form__li company-item"
            [class.form__li--selected]="!selectedPayerFromList"
            (click)="emptyItemClick()">
          <p>Выберите плательщика</p>
        </li>
        <li *ngFor="let comp of company" class="form__li company-item"
            [class.form__li--selected]="comp === selectedPayerFromList"
            (click)="onSelect(comp)">
          <p [innerHTML]="getCompanyName(comp)"></p>
        </li>
        <li *ngIf="!envService.isCorp && !company.length"
            class="form__li company-item"
            [class.form__li--selected]="selectedPayerFromList === 'another'"
            (click)="anotherItemClick()">
          <p>Указать другого плательщика</p>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="selectedPayerFromList === 'another'" class="form__group">
    <div class="form__input-wrap">
      <div
        *ngIf="selectedPayerFromSearch?.name"
        [ngStyle]="getDynamicMarginForLegalName()"
        class="legal-name-patentee"
      >
<!--        / {{getLegalName()}}-->
      </div>
      <input class="form__input field"
             type="text"
             required
             formControlName="payer"
             [style.pointerEvents]="isDebit && company?.length ? 'none' : 'auto'"
             (ngModelChange)="payerChanged($event)">
      <span class="form__input-label">Название, ОГРН/ОГРНИП, ИНН или ФИО</span>
    </div>
    <div class="form__dropdown form-card" *ngIf="tempPayer" (click)="payerSelected()" style="position: relative">
            <span class="modal__close" style="right: 6px" (click)="cancelPatentee()">
                <svg width="26" height="20" viewBox="0 0 26 26">
                  <rect x="1.41431" width="34" height="2" transform="rotate(45 1.41431 0)"/>
                  <rect y="24.0416" width="34" height="2" transform="rotate(-45 0 24.0416)"/>
                </svg>
              </span>
      <table class="form-card__table">
        <thead class="dropdown-hover">
        <tr>
          <th>ОГРН</th>
          <th>ИНН</th>
          <th>Наименование компании или ИП</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{tempPayer.PSRN}}</td>
          <td>{{tempPayer.TIN}}</td>
          <td class="black">{{tempPayer.name || tempPayer.fullName}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
