import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { loadScriptByUrl } from '../utils/load-script';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private loaded = new Set<string>();

  loadScript(url: string): Observable<{ url: string; loaded: boolean }> {
    let promise = Promise.resolve({url, loaded: true});
    if (!this.loaded.has(url)) {
      promise = loadScriptByUrl(url);
    }

    return from(promise).pipe(tap(() => this.loaded.add(url)));
  }
}
