import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-invoicing',
  templateUrl: './payment-invoicing.component.html',
  styleUrls: ['./payment-invoicing.component.scss']
})
export class PaymentInvoicingComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
