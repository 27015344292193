import { Injectable } from '@angular/core';
import { environment } from '../../app-ipid/src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SocialAuthService {
  BRAND = environment.BRAND;

  constructor() {
  }

  getFacebookId(brand) {
    switch (brand) {
      case 'ALFA':
        return `848309906055551`;
      case 'IP-ID':
        return `359036012444798`;
      case 'GARDIUM-ONLINE':
        return `359036012444798`;
      case 'IPID-PRO':
        return `359036012444798`;
    }
  }

  getYandexId(brand) {
    switch (brand) {
      case 'ALFA':
        return `66180dc79adf4e8397fb630a934255c7`;
      case 'IP-ID':
        return `59ed34590fcf4b99b7e50e18cee7c5d1`;
      case 'GARDIUM-ONLINE':
        return `59ed34590fcf4b99b7e50e18cee7c5d1`;
      case 'IPID-PRO':
        return `59ed34590fcf4b99b7e50e18cee7c5d1`;
    }
  }

  getGoogleId(brand) {
    switch (brand) {
      case 'ALFA':
        return `111154830240-mprle39lo9rrukho5fec2m0mhtiheqst.apps.googleusercontent.com`;
      case 'IP-ID':
        return `239416622088-ikudvck4krb2uvur98nkie0cvs41bf89.apps.googleusercontent.com`;
      case 'GARDIUM-ONLINE':
        return `239416622088-ikudvck4krb2uvur98nkie0cvs41bf89.apps.googleusercontent.com`;
      case 'IPID-PRO':
        return `239416622088-ikudvck4krb2uvur98nkie0cvs41bf89.apps.googleusercontent.com`;
    }
  }
}
