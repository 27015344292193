import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input, OnDestroy, OnInit,
  Output, QueryList,
  ViewChild, ViewChildren
} from '@angular/core';
import { fromEvent, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-ui-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent<T> implements AfterViewInit, OnDestroy, OnInit {

  @Input() title = '';

  @Input() cards!: T[];

  @Output() clicked = new EventEmitter<T>();

  @ViewChild('slider') slider: any;
  @ViewChildren('cards') cardsRef: QueryList<ElementRef>;

  disableArrow = true;

  destroy$ = new ReplaySubject<void>(1);

  hasCardClick = new ReplaySubject<T | null>(1);

  ngOnInit(): void {
    this.hasCardClick.pipe(
      throttleTime(500),
      takeUntil(this.destroy$)
    ).subscribe(value => {
      if (value) {
        this.clicked.emit(value);
      }
    });
  }

  // It removes swipe-error from carousel package
  ngAfterViewInit(): void {
    this.slider.handleHorizontalSwipe = this.slider.carousel.handleHorizontalSwipe;
    this.slider.touches.handlers['horizontal-swipe'] = this.slider.carousel.handleHorizontalSwipe;

    this.cardsRef.forEach(el => {
      let downEvent: MouseEvent;
      let upEvent: MouseEvent;
      fromEvent(el.nativeElement, 'mousedown')
        .pipe(
          switchMap((event: MouseEvent) => {
            downEvent = event;
            return fromEvent(el.nativeElement, 'mouseup');
          }),
          takeUntil(this.destroy$)
        ).subscribe((event: MouseEvent) => {
          upEvent = event;
          if (Math.abs(downEvent.clientX - upEvent.clientX) > 10
            || Math.abs(downEvent.clientY - upEvent.clientY) > 10) {
            this.hasCardClick.next(null);
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  click(element: T) {
    this.hasCardClick.next(element);
  }

  moveLeft() {
    this.slider.prev();
  }

  moveRight() {
    this.slider.next();
    this.disableArrow = false;
  }
}
