import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  staticFolder = '';

  constructor(private activatedRoute: ActivatedRoute) {
    this.staticFolder = environment.STATIC_FILES;
  }

  public get fileType() {
    console.log('snapshot', this.activatedRoute.snapshot.data.file);
    return this.activatedRoute.snapshot.data.file;
  }

  public ngOnInit(): void {
    // @ts-ignore
    window.location = `${this.staticFolder}/${this.fileType}`;
  }

}
