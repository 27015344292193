<section class="section-position section-position__exp" data-intercom-target="get support">
  <div class="container section-position__expert">
    <div class="section-position__expert-img">
      <picture>
        <source
          media="(max-width: 768px)"
          [srcset]="footerImageMobile"
          width="314"/>
        <img [src]="footerImage" alt="" width="314"/>
      </picture>
    </div>
    <div class="expert">
      <h2>Задать вопрос патентному поверенному</h2>
      <div class="expert__btn-wrapper">
        <button type="button" class="expert__btn" *ngFor=" let btn  of btns" (click)="onClickBtn(btn)">{{ btn }}</button>
      </div>
    </div>
  </div>
</section>
<app-support-modal
  *ngIf="showModal"
  [showModal]="showModal"
  (closeModalSupportPopup)="closeModal()">
</app-support-modal>
