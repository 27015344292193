import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dispute-page',
  templateUrl: './dispute-page.component.html',
  styleUrls: ['./dispute-page.component.scss']
})
export class DisputePageComponent implements OnInit {
  docs = false;
  events = true;
  claimsInfo = false;

  title = '2021В13225 УФАС Краснодарского края';
  addDoc = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  setResizableWidth(text, titleDiv) {
    titleDiv.focus();
    titleDiv.style.width = text.offsetWidth + 'px';
  }

  getChangedTitle(titleDiv) {
    this.title = titleDiv.innerHTML;
  }

  closeModal() {
    this.addDoc = false;
  }

  openModal() {
    this.addDoc = true;
  }
}
