import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'brandName', pure: true })
export class BrandNamePipe implements PipeTransform {
  public transform(value: string): string {
    switch (value) {
      case 'IPID-PRO': return 'Гардиум.ПРО';
      case 'X5': return 'Гардиум.ПРО';
      case 'OPEN': return 'Открытие';
      case '1C-START': return '1С-Старт';
      case 'BRAND-SEARCH': return 'brand-search.ru';
      default:  return 'Гардиум.ОНЛАЙН';
    }
  }
}
