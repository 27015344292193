<ng-container>
  <!--    <div class="add-object add-object__content add-object__content&#45;&#45;pay">-->
  <!--    <h2 class="add-object__header" >Выбор способа оплаты</h2>-->
  <!--    <div class="add-object__header-main">-->
  <!--      <div class="add-object__btns-pay-wrapper">-->
  <!--        <app-payment-tabs-->
  <!--                           (switchPaymentType)="switchPaymentType($event)"></app-payment-tabs>-->
  <!--      </div>-->
  <!--    </div>-->
  <div class="first">
    <div class="act_header">
      <h2 class="add-object__header">Выбор способа оплаты</h2>
    </div>
    <div class="second">
      <app-payment-tabs
        (switchPaymentType)="switchPaymentType($event)"></app-payment-tabs>
    </div>
  </div>

  <app-payer
    [owners]="requestContractService.owners"
    [recipients]="requestContractService.recipients"
    (payerSelectEvent)="selectPayer($event)">
  </app-payer>

  <app-payment-offline
    *ngIf="paymentType === 'cash' || paymentType === 'act' && payer?.name"
    [paymentSum]="paymentSum"
    [payer]="payer"
  ></app-payment-offline>

<!--  <app-payment-offline-individual-->
<!--    *ngIf="paymentType === 'offline' && !payer?.name"-->
<!--    [paymentSum]="paymentSum">-->
<!--  </app-payment-offline-individual>-->


  <app-payment-online
    *ngIf="paymentType === 'online'"
    [paymentSum]="paymentSum"
    [returnPage]="'request-contract/'"
    [returnParams]="{id: idApplication}"
    [returnAfterPayment]="returnAfterPayment"
    [yaLastPaymentId]="yaLastPaymentId"
    [idApplication]="idApplication"
    (errorEvent)="onlineError($event)"
    [payer]="requestContractService.payer"
    (paymentSuccessEvent)="paymentOnlineSuccessed($event)"
  >
  </app-payment-online>


  <div *ngIf="paymentType === 'cash'" class="btn-offline" [class.disabled]="!!payer === false">
    <app-button
      [loading]="requestContractService.isLoading"
      (click)="toSuccessPage()"
      [styleButton]="'btn-blue__offline'"
    >Выставить счет
    </app-button>
  </div>
<!--  [ngClass]="requestContractService.payer ? 'disabled' : ''"-->

  <div *ngIf="paymentType === 'act'"
       [class.disabled]="!!payer === false"
       class="btn-offline">
    <app-button
      [loading]="requestContractService.isLoading"
      (click)="toSuccessPage()"
      [styleButton]="'btn-blue__offline'"
    >Включить в акт
    </app-button>
  </div>
  <!--  </div>-->
</ng-container>
