import { OrganizationInterface } from '@web/core/models/organization.interface';
import {
  CredentialsInterface,
  UserLoginInterface,
} from '@web/core/models/user.interface';

export namespace AuthActions {
  export class Init {
    static readonly type = '[auth] Init';
  }

  export class Login {
    static readonly type = '[auth] Login';

    constructor(public credentials: CredentialsInterface) {}
  }

  export class GetRights {
    static readonly type = '[auth] GetRights';

    constructor(public org?: OrganizationInterface) {}
  }

  export class AttemptSsoLogin {
    static readonly type = '[auth] AttemptSsoLogin';
  }

  export class SetAuthorized {
    static readonly type = '[auth] SetAuthorized';
    constructor(readonly data: UserLoginInterface) {}
  }

  export class Logout {
    static readonly type = '[auth] Logout';
  }

  export class AttemptOauthLogin {
    static readonly type = '[auth] AttemptOauthLogin';
  }
}
