import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-settings',
  templateUrl: './button-settings.component.html',
  styleUrls: ['./button-settings.component.scss']
})
export class ButtonSettingsComponent implements OnInit {
  @Output() settingsShow = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  toEmitClick() {
    this.settingsShow.emit();
  }

}
