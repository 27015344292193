<section class="section-position section-position--tariff">
  <div class="container container--small">
    <h1>Оспорить заявку в Роспатенте</h1>
    <div class="attention attention--in-card" >
      <h3>Рекомендуем</h3>
      <p>Подать возражение в Роспатент для отказа в регистрации конкурирующего товарного знака.</p>
    </div>
  </div>

  <div class="container container--small tariff">
    <div class="attention attention--white attention--in-card " >
      <p>Тариф «Оспорить заявку в Роспатенте»</p>
      <div class="attention__show attention__show--open">
        <ul class="attention__list" >
          <li>
            Разработаем правовую позицию для оспаривания
          </li>
          <li>
            Подготовим и отправим завявление Роспатент
          </li>
          <li>
            Проследим за его рассмотрением
          </li>

        </ul>
      </div>
      <p class="attention--in-card__price">9 800 ₽</p>
    </div>
  </div>

  <div class="container container--small">
    <div class="tariff-footer tariff-footer__position">
      <a class="tariff-footer__back" routerLink="/index/item">
        <svg width="21" height="16" viewBox="0 0 21 16">
          <path d="M0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z"/>
        </svg>
        Назад
      </a>
      <div class="tariff-footer__total-wrapper">
        <div class="tariff-footer__total">
          <p>Итого к оплате: <b>9 800 ₽</b></p>
        </div>
        <button class="btn-blue-arrow" type="button"  routerLink="/monitoring/tariff">
          Оплатить
          <svg width="25" height="16" viewBox="0 0 25 16" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>

</section>
