<app-add-doc *ngIf="addDoc" (modalEvent)="closeModal()"></app-add-doc>

<section class="section-position section-position__color-bg section-position__color-bg--dispute">
  <div class="container header-court">
    <div class="header-court__block header-court__block--head">
      <div>
        <p class="header-court__link-back" routerLink="/court">
          <span>2021В13225</span> УФАС Краснодарского края, Вероятность удовлетворения ― средняя
        </p>
        <button (click)="openModal()">Прикрепить документы к делу</button>
      </div>
      <div>
        <div class="resizable"
             #titleDiv
             contenteditable
             (input)="getChangedTitle(titleDiv)">{{title}}
        </div>
        <span class="edit" (click)="setResizableWidth(title,titleDiv)"></span>
      </div>
      <p></p>
    </div>
    <div class="header-court__block header-court__block--row">
      <div class="header-court__card  header-court__card--dispute">
        <p class="header-court__card-bottom ">Этап</p>
        <h2 class="header-court__card-header-main header-court__card-header-main--dispute">Подготовка заявления</h2>
        <p class="header-court__card-bottom-txt"></p>
      </div>
      <div class="header-court__card--wrapper">
        <div class="header-court__on-card">наш статус</div>
        <div class="header-court__card ">
          <p class="header-court__card-bottom ">Заявители</p>
          <h2 class="header-court__card-header-main header-court__card-header-main--dispute">
            {{trimString('Интериор Инжиниринг, ООО', 38)}}
            <span class="prompt__hidden">
            <p>Интериор Инжиниринг, ООО</p>
          </span>
          </h2>
          <small>{{trimString('ОГРН 234246457', 32)}}</small>
        </div>
      </div>
      <div class="header-court__card ">
        <p class="header-court__card-bottom ">Ответчики</p>
        <h2 class="header-court__card-header-main header-court__card-header-main--dispute">
          {{trimString('Лиснянский Алексей Григорьевич', 38)}}
          <span class="prompt__hidden">
            <p>Лиснянский Алексей Григорьевич</p>
          </span>
        </h2>

      </div>
      <div class="header-court__card ">
        <div class="header-court__card-bottom">Третьи лица
        </div>
        <h2 class="header-court__card-header-main header-court__card-header-maint--dispute">―</h2>

      </div>

    </div>
  </div>
</section>

<section
  class="section-position section-position__color-bg section-position__color-bg--dispute section-position--padding">
  <div class="container control">
    <button class="control__btn" (click)="events=true; docs=false; claimsInfo=false"
            [class.control__btn--active]="events">События<span class="control__count">8</span>
    </button>
    <button class="control__btn" (click)="docs=true; events=false; claimsInfo=false"
            [class.control__btn--active]="docs"
    >Документы<span class="control__count">8</span>
    </button>
    <button class="control__btn" (click)="docs=false; events=false; claimsInfo=true"
            [class.control__btn--active]="claimsInfo"
    >Информация о претензии<span class="control__count">8</span>
    </button>
  </div>
</section>

<section class="section-position section-position--white">

  <app-accordion-tabs *ngIf="events"></app-accordion-tabs>

  <app-docs-tab *ngIf="docs" [type]="'dispute'"></app-docs-tab>

  <app-info-tab *ngIf="claimsInfo" [type]="'dispute'"></app-info-tab>

</section>

<app-expert></app-expert>
