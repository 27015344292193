import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import { IntercomService } from './shared/services/intercom.service';
import { UrlService } from './shared/services/url.service';
import {fromEvent, pairwise, ReplaySubject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthActions } from '@web/core/states/auth.actions';
import {Router, RoutesRecognized} from '@angular/router';
import { ScriptLoaderService } from 'projects/shared/services/script-loader.service';
// import { IntercomEventsService } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'app-ipid';
  contragentID;
  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    // tslint:disable-next-line:variable-name
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title,
    private intercomService: IntercomService,
    // public sharedAPI: SharedService,
    private urlService: UrlService,
    private store: Store,
    private router: Router,
    private scriptLoaderService: ScriptLoaderService,
    // private readonly intercomEventsService: IntercomEventsService,
  ) {}

  ngOnInit(): void {
    this._document
      .getElementById('appFavicon')
      .setAttribute('href', environment.FAVICON);
    this.titleService.setTitle(environment.TITLE);

    this.store.dispatch(new AuthActions.GetRights());

    // this.contragentID = JSON.parse(localStorage.getItem('organizations'));
    // if (this.contragentID?.length && this.contragentID[0]?.id) {
    //   this.getRoleForCheckingRights();
    // }

    this.urlService.listen();

    this.scriptLoaderService
      .loadScript(`https://services.wiseadvice.ru/tracker/js/v1/wa.min.js`)
      // .loadScript(`/assets/js/wa.min.js`) // Для локальной отладки;
      .subscribe(() =>
        console.log(
          'console.log(\'%c Custom scripts is Loaded! \', \'background: #222; color: #bada55\');'
        ),
      );

    let defaultWindowHeight = window.innerHeight;
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (defaultWindowHeight !== window.innerHeight) {
          document.body.style.overflow = 'visible';
          document.body.style.position = '';
          document.body.style.height = 'auto';
          defaultWindowHeight = window.innerHeight;
        }
      });
  }

  // getRoleForCheckingRights() {
  //   const user = JSON.parse(localStorage.getItem('currentUserData'));
  //   if (user?.role === 'Пользователь' && user?.multiaccount) {
  //     const params = {
  //       contragentID: this.contragentID[0].id
  //     };
  //     if (params) {
  //       this.sharedAPI.getRights(params);
  //     }
  //   }
  // }

  // ngAfterContentInit() {
  //   this.router.events
  //     .pipe(filter((e: any) => e instanceof RoutesRecognized),
  //       pairwise()
  //     ).subscribe((e: any) => {
  //       this.intercomEventsService.push({ from: e[0].urlAfterRedirects, to: e[1].urlAfterRedirects });
  //   });
  // }

  ngAfterViewInit(): void {
    this.intercomService.boot();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
