import {Injectable} from '@angular/core';
import {HttpService} from '../../shared/services/http.service';
import {Observable} from 'rxjs';
import {
  IRequestCheckLabelResponse, IRequestCheckLabelStage3,
  IRequestCheckLabelStage4,
} from './request-check-label.model';
import {map} from 'rxjs/operators';
import {CasesService} from '../cases/cases.service';

@Injectable({
  providedIn: 'root'
})
export class RequestCheckLabelApiService {
  constructor(
    private httpService: HttpService,
    private casesService: CasesService,
  ) {
  }

  /**
   * Запрос на создание заявки
   */
  requestCheckLabelStage1(): Observable<IRequestCheckLabelResponse> {
    return this.httpService.post({path: 'request/load', body: {type: 'checkingLabels', stage: 1}});
  }

  /**
   * Поиск по тегам
   */
  getTags(params): Observable<any[]> {
    return this.httpService.get({path: `request/tag`, params});
  }

  /**
   * Создание нового тэга
   */
  createTag(tag): Observable<Response> {
    return this.httpService.post({path: 'request/tag', body: tag});
  }

  /**
   * Отправка данных с первого шага
   * @param data: Данные с первого шага
   */
  requestCheckLabelStage3(data: IRequestCheckLabelStage3): Observable<IRequestCheckLabelResponse> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 3 }  });
  }

  /**
   * Запрос на оплату
   * @param data: Данные об оплате
   */
  requestCheckLabelStage4(data: IRequestCheckLabelStage4): Observable<IRequestCheckLabelResponse> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 4 }  });
  }

  /**
   * Запрос на восстановление заявки из черновика
   * @param responseID: id заявка
   * @param params: дополнительные параметры
   */
  requestContractGetDetail(params, responseID: string) {
    return this.casesService.getCase(params, responseID)
      .pipe(
        map((data: any) => {
          const task = data?.case?.tasks?.items[0];
          return {
            responseID,
            task
          };
        })
      );
  }

}
