import { Injectable } from '@angular/core';
import { SearchService } from '../../../../../app-search/src/app/services/search.service';
import { ISearchOptions } from '../../../../../app-search/src/app/models/search.interfaces';
import {
  CorpSearchApiService,
  ICorpSearchApiByNameResult
} from './corp-search-api.service';
import { CorpSearchSaveReportService } from './corp-search-save-report.service';
import { ISearchHistoryDetail } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { Location } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import { SearchRequestInterface } from '@web/features/corp/corp-search/states/models/search-request.interface';
import { Store } from '@ngxs/store';
import { SearchActions } from '@web/features/corp/corp-search/states/search.actions';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';

@Injectable({
  providedIn: 'root'
})
export class CorpSearchByNameService {
  constructor(
    private location: Location,
    private searchService: SearchService,
    private corpSearchApiService: CorpSearchApiService,
    private corpSearchSaveService: CorpSearchSaveReportService,
    readonly activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private corpSearchService: CorpSearchService
  ) {
  }

  isLoading = false;

  data: ICorpSearchApiByNameResult;

  searchOptions: ISearchOptions = {
    designation: '',
    mktuList: [],
    okvedList: [],
    countriesList: [],
  };

  loadBySearchHistory(search: ISearchHistoryDetail, isShow: boolean) {
    this.searchOptions = {
      designation: search.searchParameters.text,
      mktuList: search.searchParameters.mktuList,
      okvedList: search.searchParameters.okvedList,
      countriesList: search.searchParameters.countriesList,
      registrationDate: search.searchParameters.registrationDate,
      applicationDate: search.searchParameters.applicationDate,
      priorityDate: search.searchParameters.priorityDate,
      searchNumber: search.searchParameters.searchNumber,
      searchLegalId: search.searchParameters.searchLegalId,
    };

    if (isShow) {
      this.store.dispatch(new SearchActions.LoadByHistory(search));
    } else {
      this.load(this.searchOptions, false);
    }
  }

  load(opts?: ISearchOptions, saveSearch = true) {
    this.store.dispatch(new SearchActions.ClearSearch());
    this.corpSearchService.reset();
    this.searchOptions = opts;
    const query = this.activatedRoute.snapshot.queryParamMap;

    if (opts.designation || opts.searchNumber || opts.searchLegalId) {
      this.router.navigate(['search/trademarks'], {
        queryParams: {
          q: opts.designation?.length ? opts.designation : '',
          searchNumber: opts.searchNumber?.length ? opts.searchNumber : '',
          searchLegalId: opts.searchLegalId?.length ? opts.searchLegalId : '',
          searchID: query.has('searchID') ? query.get('searchID') : ''
        }
      });
    }
    if (query.has('searchID')) {
      this.corpSearchSaveService.setSearchID(query.get('searchID'));
    }

    const searchParams: SearchRequestInterface = {
      registrationDate: opts.registrationDate,
      applicationDate: opts.applicationDate,
      priorityDate: opts.priorityDate,
      searchNumber: opts.searchNumber,
      searchLegalId: opts.searchLegalId,
      gsNumber: opts.mktuList.map(i => i.number),
      eaNumber: opts.okvedList.map(i => i.code),
      countryCodes: opts.countriesList.map(i => i.code),
      index: opts.index
    };

    if (opts.designation) {
      searchParams.text = opts.designation;
    }

    this.store.dispatch(new SearchActions.Search(searchParams, null, saveSearch, this.searchOptions));
  }

  public saveSearch(params?: ISearchOptions, result?: ICorpSearchApiByNameResult) {
    this.corpSearchApiService.objectsLoading = true;
    this.corpSearchApiService.saveSearchByName(params, result).subscribe((data) => {
      if (data) {
        this.corpSearchApiService.objectsLoading = false;
        this.corpSearchSaveService.setSearchID(data.searchID);
        const searchUrl = this.router.url.includes('search/trademarks');
        if (searchUrl) {
          this.router.navigate(['search/trademarks'], {
            queryParams: {searchID: data.searchID},
            queryParamsHandling: 'merge'
          });
        }
      }
    });
  }

  get total() {
    return {
      tradeMarks: this.data.tradeMarks?.hits?.total?.tradeMarks,
      legalNames: this.data.legalNames?.hits?.total?.legalEntities,
      domains: this.data.domains?.hits?.total?.domains,
    };
  }

  get items() {
    return {
      tradeMarks: this.data.tradeMarks?.hits?.hits,
      legalNames: this.data.legalNames?.hits?.hits,
      domains: this.data.domains?.hits?.hits,
    };
  }

  get maxRisk() {
    return this.data.tradeMarks?.risks?.max_risk;
  }

  get isLoaded() {
    return this.data !== undefined;
  }

  reset() {
    this.data = undefined;
    this.isLoading = false;
    this.searchOptions = {designation: '', mktuList: [], okvedList: [], countriesList: []};
  }
}
