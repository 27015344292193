<div class="tabs">
  <ul class="tabs__list tabs__list--half-cols">
    <li class="tabs__item tabs-act">
      <a [ngClass]="paymentTab === 'online'? 'is-active': ''"
         class="tabs__item-link js-tabs__link disabled" data-id="online">Оплата онлайн</a>
    </li>
    <li *ngIf="showActTab" class="tabs__item tabs-act">
      <a (click)="switchPaymentTab('act')" [ngClass]="paymentTab === 'act'? 'is-active': ''"
         class="tabs__item-link js-tabs__link" data-id="online">Включить в акт</a>
    </li>
    <li class="tabs__item tabs-act">
      <a (click)="switchPaymentTab('cash')" [ngClass]="paymentTab === 'cash'? 'is-active': '' "
         class="tabs__item-link js-tabs__link" data-id="cash">Выставить счет для безналичной оплаты</a>
    </li>
  </ul>
</div>
