import { Injectable } from '@angular/core';
import {
  IOrg,
  IUserInfo,
  IProxie,
  IPayments,
  IOrgDefault,
  ILogin,
} from '../../app-ipid/src/app/shared/models/main.interfaces';
import { HttpService } from '../../app-ipid/src/app/shared/services/http.service';

const path = 'organizations';
const userPath = 'user';
const proxyPath = 'attorneys';
const paymentsPath = 'payments';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  userData;
  organizations;

  constructor(private http: HttpService) {
    if (localStorage.getItem('currentUserData') !== 'undefined') {
      this.setUserData(JSON.parse(localStorage.getItem('currentUserData')));
    }
    if (localStorage.getItem('currentUserData')) {
      this.loadOrganizationIfNeeded();
    }
  }

  loadOrganizationIfNeeded() {
    this.getOrganizations().subscribe((data: any) => {
      if (data) {
        localStorage.setItem(
          'organizations',
          JSON.stringify(data.organizations)
        );
        this.setOrganizationsData(data.organizations);
      }
    });
  }

  setUserData(value: any) {
    this.userData = value;
  }

  getUserData(): any {
    if (!this.userData) {
      this.setUserData(JSON.parse(localStorage.getItem('currentUserData')));
    }
    return this.userData;
  }

  getOrganizationsData() {
    return this.organizations;
  }

  setOrganizationsData(value) {
    this.organizations = value;
  }

  getOrganizations() {
    return this.http.get<IOrg>({
      path: `${path}`,
      subjectType: 'organizations',
    });
  }

  createOrganizations(body, params?) {
    return this.http.post<IOrgDefault>({
      path: `${path}/add`,
      subjectType: 'organizations',
      params,
      body,
    });
  }

  deleteOrganizations(body) {
    return this.http.post({
      path: `${path}/delete`,
      subjectType: 'users',
      body,
    });
  }

  addFile(body) {
    return this.http.post({
      path: `${userPath}/image/add`,
      subjectType: 'file',
      body,
    });
  }

  getUser() {
    return this.http.get<IUserInfo>({
      path: `${userPath}/info`,
      subjectType: 'tasks',
    });
  }

  updateUser(body) {
    return this.http.post({
      path: `${userPath}/info/update`,
      subjectType: 'users',
      body,
    });
  }

  changePassword(body) {
    return this.http.post<ILogin>({
      path: `${userPath}/change-pass`,
      subjectType: 'users',
      body,
    });
  }

  getAllProxies(params) {
    return this.http.get<IProxie>({
      path: `${proxyPath}`,
      subjectType: 'proxies',
      params,
    });
  }

  getProxiesByOrg(params) {
    return this.http.get({
      path: `${proxyPath}`,
      subjectType: 'proxies',
      params,
    });
  }

  getAllProxiesByOrg(params) {
    return this.http.get({
      path: `${proxyPath}`,
      subjectType: 'proxies',
      params,
    });
  }

  addProxies(params, body) {
    return this.http.post({
      path: `${proxyPath}/add`,
      subjectType: 'proxies',
      params,
      body,
    });
  }

  removeProxies(params, body) {
    return this.http.post({
      path: `${proxyPath}/delete`,
      subjectType: 'proxies',
      params,
      body,
    });
  }

  getAllPaymentsByOrg(params) {
    return this.http.get({
      path: `${paymentsPath}`,
      subjectType: 'pay',
      params,
    });
  }

  getPaymentsByOrg(params) {
    return this.http.get<IPayments>({
      path: `${paymentsPath}`,
      subjectType: 'pay',
      params,
    });
  }

  inviteUser(body: any) {
    return this.http.post({
      path: 'invitations/add',
      subjectType: 'invitations',
      body,
    });
  }

  inviteInteriorUser(body) {
    return this.http.post({
      path: 'organizations/add-user',
      subjectType: 'invitations',
      body,
    });
  }

  updateOrganization(body) {
    return this.http.post({
      path: 'organizations/update',
      subjectType: 'organizations_update',
      body,
    });
  }

  sendLeadForm(body) {
    return this.http.post({
      path: `lead-form/send`,
      subjectType: 'send lead-form',
      body,
    });
  }
}
