import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeBalanceComponent } from './change-balance/change-balance.component';
import { ChangeProfileComponent } from './change-profile/change-profile.component';
import { ProfileComponent } from './profile/profile.component';
import { OrganizationComponent } from './organization/organization.component';
import { ModalAddUserComponent } from './modal-add-user/modal-add-user.component';
import { UserRoutingModule } from './user-routing.module';
import { TabUsersRightsComponent } from './tab-users-rights/tab-users-rights.component';
import { TabAccessComponent } from './tab-access/tab-access.component';
import { TabOrganizationsIpComponent } from './tab-organizations-ip/tab-organizations-ip.component';
import { AuthGuard } from '../../shared/guardes/auth-guard.service';
import { TabPaymentHistoryComponent } from './tab-payment-history/tab-payment-history.component';
import { TabAttorneyComponent } from './tab-attorney/tab-attorney.component';
import { ModalPaymentComponent } from './modal-payment/modal-payment.component';
import { UserCardSettingsComponent } from './user-card-settings/user-card-settings.component';
import { InviteUserComponent } from './invite-user/invite-user.component';
import { AddOrganizationComponent } from './add-organization/add-organization.component';
import { ChangeOrganizationComponent } from './change-organization/change-organization.component';
import { OrganizationDeleteComponent } from './organization-delete/organization-delete.component';
import { AddOrganizationConfirmComponent } from './add-organization-confirm/add-organization-confirm.component';
import { IntellectualPropertyComponent } from './intellectual-property/intellectual-property.component';
import { ConfidantsComponent } from './confidants/confidants.component';
import { SharedModule } from '../../shared/shared.module';
import { LayoutModule } from '../layout/layout.module';
import {ExpertModule} from '../layout/expert/expert.mdoule';
import {SupportModalModule} from '../layout/support-modal/support-modal.module';

@NgModule({
  declarations: [
    ChangeProfileComponent,
    ChangePasswordComponent,
    ChangeBalanceComponent,
    ProfileComponent,
    OrganizationComponent,
    ModalAddUserComponent,
    TabUsersRightsComponent,
    TabAccessComponent,
    TabOrganizationsIpComponent,
    TabPaymentHistoryComponent,
    TabAttorneyComponent,
    ModalPaymentComponent,
    UserCardSettingsComponent,
    InviteUserComponent,
    AddOrganizationComponent,
    ChangeOrganizationComponent,
    OrganizationDeleteComponent,
    AddOrganizationConfirmComponent,
    IntellectualPropertyComponent,
    ConfidantsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UserRoutingModule,
    FormsModule,
    SharedModule,
    LayoutModule,
    ExpertModule,
    SupportModalModule
  ],
  exports: [], providers: [
    AuthGuard,
  ]
})
export class UserModule {
}
