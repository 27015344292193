<div class="monitoring__wrap" data-intercom-target="monitoring">
  <h2>Мониторинг</h2>
  <ng-container *ngIf="isLoadingRisks; else loader">
    <ng-container *ngIf="hasRisks; else noMonitoring">
      <article class="sections__item risks-item">
        <h3 class="sections__title">Риски</h3>

        <div class="sections__content">
          <app-risks-widget
            [risks]="risks"></app-risks-widget>
        </div>

        <a routerLink="/monitoring" class="sections__btn btn" [class.disabled]="X5 || !monitoring">Перейти в мониторинг</a>
      </article>
      <!--    <div class="monitoring__wrapper">-->
      <!--      <div class="monitoring monitoring&#45;&#45;hasmonitoring">-->
      <!--        <div class="monitoring-card">-->
      <!--          <button class="monitoring-card__long">-->
      <!--            Продлить-->
      <!--          </button>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;img">-->
      <!--            <div class="monitoring-card__img-wrapper">-->
      <!--              <img src="/assets/images/item1.png" width="70" alt="item">-->
      <!--            </div>-->
      <!--            <div class="monitoring-card__block">-->
      <!--              <p class="monitoring-card__item">Товарный знак 48123111</p>-->
      <!--              <p class="monitoring-card__text">Спортивные, товары, ведущих производителей спортивных товаров.</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;mobile">-->
      <!--            <span class="monitoring-card__lamp"></span>-->
      <!--              <p class="monitoring-card__notice">Истекает срок действия товарного знака </p>-->
      <!--              <time class="monitoring-card__date">10.05.2020</time>-->
      <!--            <p class="monitoring-card__text-mobile">Заявитель сможет работать в том же бизнесе и зарабатывать на вашей узнаваемости</p>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div class="monitoring-card">-->
      <!--          <button class="monitoring-card__long">-->
      <!--            Продлить-->
      <!--          </button>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;img">-->
      <!--            <div class="monitoring-card__img-wrapper">-->
      <!--              <img src="/assets/images/lamp.png" width="42" alt="item">-->
      <!--            </div>-->
      <!--            <div class="monitoring-card__block">-->
      <!--              <p class="monitoring-card__item">Товарный знак 48123111</p>-->
      <!--              <p class="monitoring-card__text">Деформации и напряжения в монослойном композитном материале.</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;mobile">-->
      <!--            <span class="monitoring-card__lamp"></span>-->
      <!--            <p class="monitoring-card__notice">Истекает срок действия товарного знака</p>-->
      <!--            <time class="monitoring-card__date">10.05.2020</time>-->
      <!--            <p class="monitoring-card__text-mobile">Заявитель сможет работать в том же бизнесе и зарабатывать на вашей узнаваемости</p>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div class="monitoring-card">-->
      <!--          <button class="monitoring-card__long">-->
      <!--            Продлить-->
      <!--          </button>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;img">-->
      <!--            <div class="monitoring-card__img-wrapper">-->
      <!--              <img src="/assets/images/item1.png" width="70" alt="item">-->
      <!--            </div>-->
      <!--            <div class="monitoring-card__block">-->
      <!--              <p class="monitoring-card__item">Товарный знак 48123111</p>-->
      <!--              <p class="monitoring-card__text">Спортивные, товары, ведущих производителей спортивных товаров.</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;mobile">-->
      <!--            <span class="monitoring-card__lamp monitoring-card__lamp&#45;&#45;orange"></span>-->
      <!--            <p class="monitoring-card__notice">Вы нарушаете чужой товарный знак</p>-->
      <!--            <time class="monitoring-card__date">10.05.2020</time>-->
      <!--            <p class="monitoring-card__text-mobile">Заявитель сможет работать в том же бизнесе и зарабатывать на вашей узнаваемости</p>-->
      <!--          </div>-->
      <!--        </div>-->

      <!--        <div class="monitoring-card">-->
      <!--          <button class="monitoring-card__long">-->
      <!--            Продлить-->
      <!--          </button>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;img">-->
      <!--            <div class="monitoring-card__img-wrapper">-->
      <!--              <img src="/assets/images/lamp.png" width="42" alt="item">-->
      <!--            </div>-->
      <!--            <div class="monitoring-card__block">-->
      <!--              <p class="monitoring-card__item">Патент 2014619117</p>-->
      <!--              <p class="monitoring-card__text">Спортивные, товары, ведущих производителей спортивных товаров.</p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="monitoring-card__wrapper monitoring-card__wrapper&#45;&#45;mobile">-->
      <!--            <span class="monitoring-card__lamp monitoring-card__lamp&#45;&#45;orange"></span>-->
      <!--            <p class="monitoring-card__notice">На вас подана жалоба в ФАС </p>-->
      <!--            <time class="monitoring-card__date">10.05.2020</time>-->
      <!--            <p class="monitoring-card__text-mobile">Заявитель сможет работать в том же бизнесе и зарабатывать на вашей узнаваемости</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="gradient"></div>-->
      <!--      <div [ngStyle]="{-->
      <!--    height:myDeal ? '63px' : '20px',-->
      <!--    position: myDeal ? 'absolute' : 'relative'}" class="service-link__wrapper">-->
      <!--        <a class="service-link service-link&#45;&#45;background">Еще 125 событий-->
      <!--          <div style="position: absolute; top: 0; right:0;">-->
      <!--            <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--              <path class="service-link__arrow" d="M20.7071 8.70711C21.0976 8.31658 21.0976 7.68342 20.7071 7.2929L14.3431 0.928933C13.9526 0.538409 13.3195 0.538409 12.9289 0.928933C12.5384 1.31946 12.5384 1.95262 12.9289 2.34315L18.5858 8L12.9289 13.6569C12.5384 14.0474 12.5384 14.6805 12.9289 15.0711C13.3195 15.4616 13.9526 15.4616 14.3431 15.0711L20.7071 8.70711ZM-8.74228e-08 9L20 9L20 7L8.74228e-08 7L-8.74228e-08 9Z" fill="#2E71F6"/>-->
      <!--            </svg>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </div>-->
      <!--    </div>-->
    </ng-container>
    <ng-template #noMonitoring>
      <div class="monitoring monitoring--no">
        <img src="/assets/images/nothing.svg" alt="nothing">
        <p class="monitoring__head">Нет объектов для мониторинга</p>
        <p>Вы можете подключить объекты в разделе мониторинга.</p>
        <a routerLink="/monitoring" [class.disabled]="X5 || !monitoring">Перейти в мониторинг</a>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loader>
    <app-spinner class="spinner-full"></app-spinner>
  </ng-template>
</div>
