<section class="section-position section-position--bg section-position--main" [class.empty]="!items?.length">
  <div class="container container--bg">
    <!--          <span  class="control__mobile">-->
    <!--              <p>Фильтр по доменам</p>-->
    <!--          </span>-->

    <div style="padding-top: 1px">
      <div *ngIf="!items?.length" class="empty-results">
        К сожалению, ничего не найдено. Попробуйте изменить запрос.
      </div>
    </div>

    <app-list-view
      *ngIf="items?.length"
      [sourceData]="items"
      [currentPage]='currentPage'
      [totalCount]="totalCount"
      [itemsPerPage]="itemsPerPage"
      (changePage)="setPage($event)"
      (applyFilters)="onApplyFilters($event)"
      [filterType]="'доменам'"
    >
      <!--      <lvFilter lvFilterInput-->
      <!--                name="name"-->
      <!--                className="size&#45;&#45;lg"-->
      <!--                placeholder="Поиск по тексту"-->
      <!--                [value]="filters.name.value"-->
      <!--                [filterFn]="filters.name.fn"-->
      <!--      ></lvFilter>-->

      <!--      <lvFilter lvFilterMultiselect-->
      <!--                name="riskLevel"-->
      <!--                title="Статус"-->
      <!--                [options]="filters.riskLevel.options"-->
      <!--                [value]="filters.riskLevel.value"-->
      <!--                [filterFn]="filters.riskLevel.fn"-->
      <!--      ></lvFilter>-->

      <!--      <lvFilter lvFilterDaterange-->
      <!--                name="registration_date"-->
      <!--                placeholder="Дата регистрации"-->
      <!--                [value]="filters.registration_date.value"-->
      <!--                filterField="_source.registrationDate"-->
      <!--      ></lvFilter>-->

      <ng-template let-rows lvData>
        <div class="table-corp">
          <div class="table-corp__header">
            <p class="head-table__first"><span>Доменное имя</span></p>
            <p class="head-table__second">Дата регистрации</p>
            <p class="head-table__third">Дата освобождения</p>
            <p class="head-table__fourth">Оплачен до</p>
            <p class="head-table__fifth">Регистратор</p>
            <p class="head-table__fifth">Статус</p>
            <p class="head-table__six"><span>Выбрать</span></p>
          </div>
          <div *ngFor="let sourceData of rows"
               class="table-corp__row"
               [class.table-corp__row--active]="sourceData.checked"
               (click)="selectDomain(sourceData)">
            <div class="table-corp__domain">
              <img *ngIf="getIcon(sourceData)" [src]="getIcon(sourceData)"/>
              {{getDomain(sourceData)}}
            </div>
            <div class="table-corp__registerDate">
              <span class="table-corp__mobile-txt">Дата регистрации</span>
              <p
                *ngIf="sourceData._source.registrationDate">{{sourceData._source.registrationDate | date:'dd.MM.yyyy'}}</p>
            </div>
            <div class="table-corp__expireDate">
              <span class="table-corp__mobile-txt">Дата освобождения</span>
              <p *ngIf="sourceData._source.freeDate">{{sourceData._source.freeDate | date:'dd.MM.yyyy'}}</p>
            </div>
            <div class="table-corp__freeDate">
              <span class="table-corp__mobile-txt">Срок действия</span>
              <p *ngIf="sourceData._source.payDate">{{sourceData._source.payDate | date:'dd.MM.yyyy'}}</p>
            </div>
            <div class="table-corp__registrator">
              <span class="table-corp__mobile-txt">Регистратор</span>
              {{sourceData._source.domainRegistrator}}
            </div>
            <div class="table-corp__state">
              <span class="table-corp__mobile-txt">Статус</span>
              {{ sourceData._source.free ? 'Свободен' : 'Занят' }}
            </div>
            <div class="table-corp__checkbox">
              <input class="table-corp__checkbox--input" id="{{sourceData.id}}" type="checkbox"
                     [(ngModel)]="sourceData.checked">
              <label class="table-corp__checkbox--label" (click)="toggleCheck($event, sourceData)"
                     for="{{sourceData.id}}"><span class="table-corp__mobile-blue"
                                                   [class.table-corp__mobile-blue--delete]="sourceData.checked">
        {{sourceData.checked ? 'Убрать выбор' : 'Выбрать для отчета'}}</span></label>
            </div>
          </div>
        </div>
      </ng-template>

    </app-list-view>
  </div>

</section>
<div class="zzmodal" *ngIf="selectedDomain">
  <app-domain-modal
    class="app-domain-modal"
    [data]="selectedDomain"
    (modalEvent)="onCloseDomainInfoModal()"
  ></app-domain-modal>
</div>
