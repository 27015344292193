import {
  ChangeDetectionStrategy,
  Component,
  ContentChild, EventEmitter,
  Input,
  OnInit, Output,
  TemplateRef
} from '@angular/core';
import { FiltersLayout } from '@ui/filters/models/filters.interface';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent<T> implements OnInit {
  @Input() dataSource: T[] = [];

  @Input() layout: FiltersLayout;

  @Input() isLoading = false;

  @Input() totalCount: number;

  @Input() itemsPerPage = 10;

  @Input() currentPage = 1;

  @Input() currentLayout: 'sm' | 'md' | 'lg' = 'md';

  @Input() paddingProps: any;

  @ContentChild('item') itemRef: TemplateRef<any>;
  @ContentChild('header') headerRef: TemplateRef<any>;

  @Output() changePage = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  onChangePage(page: number) {
    this.changePage.emit(page);
  }

  get gridTemplates() {
    const layout = this.layout ?? this.autogeneratedFilterLayouts;
    const sizes = layout[this.currentLayout] ?? layout.md;

    return sizes.join(' ');
  }

  get autogeneratedFilterLayouts(): FiltersLayout {
    return { md: ['1fr'], sm: ['1fr'], lg: ['1fr'] };
  }
}
