<div class="events-steps__hidden">
  <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
    <h3>Проведено основное судебное заседание. Иск удовлетворен полностью.</h3>
    <div class="hidden__blocks-wrapper">
      <div class="hidden__block hidden__block--no-line">
        <h4 class="hidden__subtitle">Содержание решения</h4>
        <ul class="hidden__list">
          <li>
            Иск удволетворен полностью
          </li>
          <li>
            Присуждена сумма основного долга в размере <b>80 000 ₽</b>
          </li>
          <li>
            Присуждена сумма неустойки в размере <b>20 000 ₽</b>
          </li>
          <li>
            Присуждена сумма судебных расходов в размере <b>19 890 ₽</b>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
