import {Injectable} from '@angular/core';
import saveAs from 'file-saver';
import {HttpService} from '../../app-ipid/src/app/shared/services/http.service';
import { WebModuleConfig } from '@web/core/services/config.service';

@Injectable({ providedIn: 'root' })

export class DownloadFilesService {
  constructor(
    private http: HttpService,
    private config: WebModuleConfig
  ) {
  }

  downloadFile(file): any {
    let path;
    if (typeof file === 'string') {
      path = file;
    } else {
      path = file.path || file.publicPath || file.url || file.link;
    }

    let fileName;
    if (this.config.isDebit) {
      fileName = file.fileName;
    } else {
      fileName = path.slice(path.lastIndexOf('/') + 1, path.length);
    }

    this.http.post({path: `request/file`, body: {path}, responseType: 'blob'})
      .subscribe((data: any) => {
        const blob: any = new Blob([data]);
        saveAs(blob, fileName);
      });
  }
}
