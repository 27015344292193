import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../../shared/services/http.service';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AuthState} from '@web/core/states/auth.state';
import {AppState} from '@web/core/states/app.state';

@Component({
  selector: 'app-monitoring-widget',
  templateUrl: './monitoring-widget.component.html',
  styleUrls: ['./monitoring-widget.component.scss']
})
export class MonitoringWidgetComponent implements OnInit {
  @ViewSelectSnapshot(AuthState.getRightByName('monitoring-viewing')) monitoring: boolean;
  @ViewSelectSnapshot(AppState.X5) X5: boolean;

  constructor(
    readonly httpService: HttpService
  ) { }

  risks;
  isLoadingRisks = false;

  ngOnInit(): void {
    this.getRisks();
  }

  getRisks() {
    this.httpService.get({ path: 'objects/count' }).subscribe((data: any) => {
      this.risks = data.risks;
      this.isLoadingRisks = true;
    });
  }

  get hasRisks(): boolean {
    return this.risks && (this.risks.critical || this.risks.high || this.risks.medium || this.risks.recommendation);
  }

  // get myDeal() {
  //   return this.demoService.indexPage.myDeal;
  // }

}
