<div class="paragraph_header">
  <p *ngIf="!showPromoInput && !showSuccess"
     (click)="showPromoInput = true"
     class="promo_text">
    У меня есть промокод
  </p>
</div>
<input *ngIf="showPromoInput && !showSuccess"
       [(ngModel)]="promoCode"
       class="promo_input"
       placeholder="Промокод"
       type="text">
<button *ngIf="showPromoInput && !showSuccess"
        [class.disabled]="!promoCode.length"
        type="button"
        (click)="getPromoCode()"
        class="btn-blue_promo"
>Применить
</button>
<p class="success_paragraph" *ngIf="showSuccess">Применен промокод {{promoCode}}</p>
