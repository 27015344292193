<div *ngIf="orgData" class="page-wrapper">
  <main>
    <section class="organization">
      <div class="container">
        <a routerLink="/user/profile" class="back-link">
          <span>
            <svg width="12" height="8" viewBox="0 0 21 16">
              <g clip-path="url(#clip0)">
               <path
                 d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
              </g>
              <defs>
                <clipPath id="clip0">
                <rect width="21" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </span>
          Назад
        </a>
        <p class="organization__name">{{ orgData?.name }}</p>
        <ul class="user__info-list">
          <li
            class="user__info-item">{{getTitleByPSRNCount(orgData?.PSRN)}} {{ getStringWithoutSpace(orgData?.PSRN) }}</li>
          <li class="user__info-item">ИНН {{ orgData?.TIN }}</li>
          <li class="user__info-item" *ngIf="orgData?.phone">{{ orgData?.phone }}</li>
          <li class="user__info-item" *ngIf="orgData?.email">
            <a href="mailto:{{ orgData?.email }}">{{ orgData?.email }}</a>
          </li>
          <li class="user__info-item user__info-item--links" (click)="toChangePage()">
            <a class="user__links">Изменить контактную информацию</a>
          </li>
          <li class="user__info-item user__info-item--links" (click)="toDeletePage()" *ngIf="administrator">
            <a class="user__links">Удалить организацию</a>
          </li>
        </ul>
      </div>
    </section>
    <div class="organization__wrap container">
      <div class="organization__left-side organization__balance">

        <section *ngIf="!disableFinance" class="balance__wrap">
          <div class="balance">
            <div>
              <h3 class="organization__caption organization__caption--balance">Остаток на счете</h3>
              <p class="balance__total">
                <span *ngIf="!administrator">0</span>
                <span *ngIf="administrator && newOrganization === false">0,00</span>
                <span *ngIf="administrator && newOrganization === true">0,00</span>
                ₽
              </p>
              <!--              <p class="balance__bonus">-->
              <!--                Из них бонусных:-->
              <!--                <span *ngIf="administrator === false">* ***,00</span>-->
              <!--                <span *ngIf="administrator === true && newOrganization === false">3 900,00</span>-->
              <!--                <span *ngIf="administrator === true && newOrganization === true">0,00</span>-->
              <!--                ₽-->
              <!--              </p>-->
            </div>
            <div class="balance__buttons-wrap">
              <button [disabled]="financeReplenishment" (click)="toLeadForm('Пополнение депозита')"
                      class="balance__button">Пополнить
              </button>
              <button [disabled]="financeActs" (click)="toLeadForm('Получение акта сверки')"
                      class="balance__button balance__button--act">Получить Акт сверки
              </button>
            </div>
          </div>

          <div *ngIf="paymentHistory" class="payments">
            <h4 class="payments__title">История платежей</h4>
            <table class="payments__table" *ngIf="newOrganization === false && paymentHistory.length > 0">
              <tr *ngFor="let payment of paymentHistory.slice(0,  showPayment)">
                <td class="payments__name-wrap">
                  <p class="payments__name">{{ payment?.name }}</p>
                </td>
                <td class="payments__date">
                  <span *ngIf="!administrator">{{ payment?.date | date:'dd.MM.yyyy' }}</span>
                  <span *ngIf="administrator">{{ payment?.date | date:'dd.MM.yyyy' }}</span>
                </td>
                <td class="payments__total-wrap">
                  <span class="payments__total"
                        [ngStyle]="{'color': payment?.deposit ? '#72CA49' : '' }"
                  >
                    <span>{{ payment?.deposit ? '+' : '-' }}&nbsp;</span>
                    <span *ngIf="!administrator">{{ payment?.sum }}</span>
                    <span *ngIf="administrator">{{ payment?.sum }}</span>
                    ₽
                  </span>
                  <!--                  <span *ngIf="payment?.bonus" class="payments__bonus">-->
                  <!--                    Из них бонусных: <span class="payments__bonus-sum">{{ payment?.bonus }} ₽</span>-->
                  <!--                  </span>-->
                </td>
                <td class="payments__payment-type-wrap payments__payment-type-wrap--btn">
                  <p class="payments__payment-type">
                    {{ payment?.status }}
                  </p>
                </td>
              </tr>
            </table>
            <div class="payments__admin" *ngIf="administrator && newOrganization === false">
              <button *ngIf="showPayment < paymentHistory.length" (click)="paymentShow()"
                      class="show-all">Показать всю историю
              </button>
            </div>
            <div class="payments__no-payments" *ngIf="paymentHistory.length === 0 || newOrganization === true">
              <img src="/assets/icons/icon-no-payments.svg" alt=""
                   class="organization__empty-img" width="24" height="24">
              <span class="organization__empty-text">Нет истории платежей</span>
            </div>
            <div class="payments__no-rights" *ngIf="!administrator">
              <p class="">Нет доступа к информации</p>
            </div>
          </div>
        </section>

        <section *ngIf="disableFinance" class="balance__wrap">
          <div class="balance">
            <div>
              <h3 class="organization__caption organization__caption--balance">Остаток на счете</h3>
              <p class="balance__total">
                <span>0,00</span>
                ₽
              </p>
              <p class="balance__bonus">
                Из них бонусных:
                <span>* ***,00</span>
                ₽
              </p>
            </div>
            <div class="balance__buttons-wrap">
              <button [disabled]="financeReplenishment" (click)="toLeadForm('Пополнение депозита')"
                      class="balance__button">Пополнить
              </button>
              <button [disabled]="financeActs" (click)="toLeadForm('Получение акта сверки')"
                      class="balance__button balance__button--act">Получить Акт сверки
              </button>
            </div>
          </div>
          <div class="payments">
          </div>
          <div class="payments__no-rights" *ngIf="administrator === false">
            <p class="">Нет доступа к информации</p>
          </div>
        </section>

      </div>
      <div class="organization__right-side">
        <aside class="cases">
          <h3 class="organization__caption">Активные дела</h3>
          <ul class="cases__list" *ngIf="newOrganization === false && cases.length !== 0">
            <li class="cases__item" *ngFor="let case of cases">
              <div *ngIf="case.status" class="cases__response wait">
                <!--                <div class="cases__response wait"-->
                <!--                     [ngClass]="{-->
                <!--                        'wait': case?.status === 'Ждем ответ от роспатента',-->
                <!--                        'recovery': case?.status === 'Взыскание',-->
                <!--                        'claim': case?.status === 'Претензия, ожидание ответа'}">-->
                <span class="">{{ case?.status }}</span>
              </div>
              <a href="" class="cases__name">{{ case?.description }} #{{case.number}}</a>
            </li>
          </ul>
          <button *ngIf="orgData?.cases.length > 3 && newOrganization === false" (click)="toggleCases()"
                  class="show-all">
            Показать все
          </button>
          <div class="cases__no-cases" *ngIf="newOrganization === true || orgData?.cases.length === 0">
            <img src="/assets/icons/icon-no-cases.svg" alt=""
                 class="organization__empty-img" width="24" height="24">
            <span class="organization__empty-text">Нет активных дел</span>
            <!--            <button class="btn cases__no-cases-btn">Взыскать долг</button>-->
          </div>
        </aside>

        <aside *ngIf="archivedCases.length" class="cases cases--archive">
          <h3 class="organization__caption">Архив</h3>
          <ul class="cases__list" *ngIf="newOrganization === false && archivedCases.length !== 0">
            <li class="cases__item" *ngFor="let archived of archivedCases">
              <div class="cases__response complete">
                <span class="">{{ archived?.status }}</span>
              </div>
              <a href="" class="cases__name">{{ archived?.description }}</a>
              <a href="" class="cases__download">Скачать акт</a>
            </li>
          </ul>
          <button *ngIf="archivedCases.length > 3 && newOrganization === false" (click)="toggleArchive()"
                  class="show-all">
            Показать все
          </button>
          <div class="cases__no-cases" *ngIf="newOrganization === true || archivedCases.length === 0">
            <img src="/assets/icons/icon-no-archive.svg" alt=""
                 class="organization__empty-img" width="24" height="24">
            <span class="organization__empty-text">Нет архивных дел</span>
          </div>
        </aside>
      </div>
      <div class="organization__left-side organization__users">
        <section class="users">
          <div class="users__title-wrap">
            <h3 class="organization__caption organization__caption--users">Пользователи</h3>
            <button (click)="openModalAddUser()"
                    *ngIf="administrator === true && newOrganization === false"
                    class="users__title-btn">
              Добавить пользователя
            </button>
          </div>
          <form [formGroup]="form" class="users__form" *ngIf="newOrganization === false">
            <div class="users__form-input-wrap">
              <svg width="21" height="21" viewBox="0 0 21 21">
                <circle cx="9" cy="9" r="8" stroke-width="2"/>
                <path d="M15 15L20 20" stroke-width="2" stroke-linecap="round"/>
              </svg>
              <input formControlName="search"
                     [(ngModel)]="searchTerm"
                     (ngModelChange)="searchReps(searchTerm)"
                     class="users__form-input"
                     type="text"
                     placeholder="Укажите ФИО или должность">
            </div>
          </form>

          <!--          <div class="users__wrap" *ngIf="administrator === true && newOrganization === true">-->
          <!--            <aside class="users__item">-->
          <!--              <div class="users__info users__info&#45;&#45;admin"-->
          <!--                   [ngStyle]="{'border-radius': administrator ? '5px 5px 0 0' : '5px'}">-->
          <!--                <img *ngIf="userAdmin?.photo !== null" src="{{ userAdmin?.photo }}" alt="" class="users__photo"-->
          <!--                     width="100"-->
          <!--                     height="100">-->
          <!--                <img *ngIf="userAdmin?.photo === null" src="/assets/images/avatar.png" alt="" class="users__photo"-->
          <!--                     width="100"-->
          <!--                     height="100">-->
          <!--                <div class="users__desc-wrap">-->
          <!--                  <p class="users__name">{{ userAdmin?.name }}</p>-->
          <!--                  <p class="users__position">{{ userAdmin?.position }}</p>-->
          <!--                  <a href="mailto:{{ userAdmin?.email }}" class="users__email">{{ userAdmin?.email }}</a>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="users__admin">-->
          <!--                <img src="/assets/icons/icon-admin-account.svg" alt="" class="users__admin-img" width="120" height="32">-->
          <!--                <p class="users__admin-title">Администратор аккаунта</p>-->
          <!--                <span class="users__admin-desc">Доступны все права</span>-->
          <!--              </div>-->
          <!--            </aside>-->
          <!--          </div>-->

          <div class="users__wrap" *ngIf="representatives.length">
            <aside class="users__item" *ngFor="let user of representatives" (click)="editRights(user.id)"
                   [class.admin]="administrator">
              <div class="users__info" [ngStyle]="{'border-radius': administrator ? '5px 5px 0 0' : '5px'}"
                   style="min-height: 254px;">
                <div class="users__attorney" *ngIf="administrator">
                  <span>{{user?.status}}</span>
                </div>
                <img *ngIf="!user.image" src="/assets/images/avatar.png" alt="" class="users__photo" width="100">
                <img *ngIf="user.image" src="{{user.image}}" alt="" class="users__photo" width="100"
                     height="100">
                <div class="users__desc-wrap">
                  <p class="users__name">{{ user.name }} {{user.surname}}</p>
                  <p class="users__position">{{ user.position }}</p>
                  <a href="mailto:{{ user.email }}" class="users__email">{{ user.email }}</a>
                </div>
              </div>
              <!--              <div *ngIf="administrator === true" class="users__access">-->
              <!--&lt;!&ndash;                <h4 class="users__access-title">Доступные Права</h4>&ndash;&gt;-->

              <!--                <dl class="users__access-list" *ngFor="let right of user?.rights">-->
              <!--                  <div class="users__access-item">-->
              <!--                    <dt class="users__access-name"-->
              <!--                        [ngClass]="right?.trademarks?.length < 1 ? 'users__access-name&#45;&#45;rights__no': ''"-->
              <!--                    >-->
              <!--                      Товарные знаки-->
              <!--                    </dt>-->
              <!--                    <dd class="rights__list-wrap">-->
              <!--                      <ul class="rights__list" *ngIf="right?.trademarks?.length > 0">-->
              <!--                        <li *ngFor="let trademark of right?.trademarks">-->
              <!--                          <div class="rights__item">-->
              <!--                            <span>-->
              <!--                              {{ trademark?.name }}-->
              <!--                            </span>-->
              <!--                          </div>-->
              <!--                        </li>-->
              <!--                        <li *ngIf="right?.trademarks?.length < 1" class="rights__no">-->
              <!--                          Нет доступа-->
              <!--                        </li>-->
              <!--                      </ul>-->
              <!--                      <div class="rights__tooltip-wrap" *ngIf="right?.trademarks?.length > 0">-->
              <!--                        <ng-container *ngFor="let trademark of right?.trademarks">-->
              <!--                          <span class="rights__tooltip">-->
              <!--                            {{ trademark?.name}}-->
              <!--                          </span>-->
              <!--                        </ng-container>-->
              <!--                      </div>-->
              <!--                    </dd>-->
              <!--                  </div>-->
              <!--                  <div class="users__access-item">-->
              <!--                    <dt class="users__access-name"-->
              <!--                        [ngClass]="right?.patents?.length < 1 ? 'users__access-name&#45;&#45;rights__no': ''"-->
              <!--                    >-->
              <!--                      Патенты-->
              <!--                    </dt>-->
              <!--                    <dd class="rights__list-wrap">-->
              <!--                      <ul class="rights__list">-->
              <!--                        <li *ngIf="right?.patents?.length > 0">-->
              <!--                          <div *ngFor="let patent of right?.patents" class="rights__item">-->
              <!--                            <span>{{ patent?.name }}</span>-->
              <!--                          </div>-->
              <!--                        </li>-->
              <!--                        <li *ngIf="right?.patents?.length < 1" class="rights__no">-->
              <!--                          Нет доступа-->
              <!--                        </li>-->
              <!--                      </ul>-->
              <!--                      <div class="rights__tooltip-wrap" *ngIf="right?.patents?.length > 0">-->
              <!--                        <ng-container *ngFor="let patent of right?.patents">-->
              <!--                          <span class="rights__tooltip">-->
              <!--                            {{ patent?.name}}-->
              <!--                          </span>-->
              <!--                        </ng-container>-->
              <!--                      </div>-->
              <!--                    </dd>-->
              <!--                  </div>-->
              <!--                  <div class="users__access-item">-->
              <!--                    <dt class="users__access-name"-->
              <!--                        [ngClass]="right?.databases?.length < 1 ? 'users__access-name&#45;&#45;rights__no': ''"-->
              <!--                    >-->
              <!--                      Программы и БД-->
              <!--                    </dt>-->
              <!--                    <dd class="rights__list-wrap">-->
              <!--                      <ul class="rights__list">-->
              <!--                        <li *ngIf="right?.databases?.length > 0">-->
              <!--                          <div *ngFor="let database of right?.databases" class="rights__item">-->
              <!--                            <span>{{ database?.name }}</span>-->
              <!--                          </div>-->
              <!--                        </li>-->
              <!--                        <li *ngIf="right?.databases?.length < 1" class="rights__no">-->
              <!--                          Нет доступа-->
              <!--                        </li>-->
              <!--                      </ul>-->
              <!--                      <div class="rights__tooltip-wrap" *ngIf="right?.databases?.length > 0">-->
              <!--                        <ng-container *ngFor="let database of right?.databases">-->
              <!--                          <span class="rights__tooltip">-->
              <!--                            {{ database?.name}}-->
              <!--                          </span>-->
              <!--                        </ng-container>-->
              <!--                      </div>-->
              <!--                    </dd>-->
              <!--                  </div>-->
              <!--                  <div class="users__access-item">-->
              <!--                    <dt class="users__access-name"-->
              <!--                        [ngClass]="right?.disputes?.length < 1 ? 'users__access-name&#45;&#45;rights__no': ''"-->
              <!--                    >-->
              <!--                      Суды и споры-->
              <!--                    </dt>-->
              <!--                    <dd class="rights__list-wrap">-->
              <!--                      <ul class="rights__list">-->
              <!--                        <li *ngIf="right?.disputes?.length > 0">-->
              <!--                          <div *ngFor="let dispute of right?.disputes" class="rights__item">-->
              <!--                            <span>{{ dispute?.name }}</span>-->
              <!--                          </div>-->
              <!--                        </li>-->
              <!--                        <li *ngIf="right?.disputes?.length < 1" class="rights__no">-->
              <!--                          Нет доступа-->
              <!--                        </li>-->
              <!--                      </ul>-->
              <!--                      <div class="rights__tooltip-wrap" *ngIf="right?.disputes?.length > 0">-->
              <!--                        <ng-container *ngFor="let dispute of right?.disputes">-->
              <!--                          <span class="rights__tooltip">-->
              <!--                            {{ dispute?.name}}-->
              <!--                          </span>-->
              <!--                        </ng-container>-->
              <!--                      </div>-->
              <!--                    </dd>-->
              <!--                  </div>-->
              <!--                  <div class="users__access-item">-->
              <!--                    <dt class="users__access-name"-->
              <!--                        [ngClass]="right?.copyrights?.length < 1 ? 'users__access-name&#45;&#45;rights__no': ''"-->
              <!--                    >-->
              <!--                      Авторские права-->
              <!--                    </dt>-->
              <!--                    <dd class="rights__list-wrap">-->
              <!--                      <ul class="rights__list">-->
              <!--                        <li *ngIf="right?.copyrights?.length > 0">-->
              <!--                          <div *ngFor="let copyright of right?.copyrights" class="rights__item">-->
              <!--                            <span>{{ copyright?.name }}</span>-->
              <!--                          </div>-->
              <!--                        </li>-->
              <!--                        <li *ngIf="right?.copyrights?.length < 1" class="rights__no">-->
              <!--                          Нет доступа-->
              <!--                        </li>-->
              <!--                      </ul>-->
              <!--                      <div class="rights__tooltip-wrap" *ngIf="right?.copyrights?.length > 0">-->
              <!--                        <ng-container *ngFor="let copyright of right?.copyrights">-->
              <!--                          <span class="rights__tooltip">-->
              <!--                            {{ copyright?.name}}-->
              <!--                          </span>-->
              <!--                        </ng-container>-->
              <!--                      </div>-->
              <!--                    </dd>-->
              <!--                  </div>-->
              <!--                </dl>-->

              <!--              </div>-->
            </aside>
          </div>
        </section>
      </div>
    </div>
  </main>
</div>

<div *ngIf="showModalAddUser">
  <app-modal-add-user
    [organizationId]="organizationId"
    [users]="overRepresentatives"
    (modalEvent)="closeModal()"
  >
  </app-modal-add-user>
</div>
