import { Injectable } from '@angular/core';
import { HttpService } from '../../../app-ipid/src/app/shared/services/http.service';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '../../../app-debit/src/app/models/main.interfaces';
import { ILogin } from '../../../app-ipid/src/app/shared/models/main.interfaces';

const path = 'user';
const infoPath = 'login-info';


@Injectable({
  providedIn: 'root'
})
export class OauthService {

  constructor(
    private http: HttpService,
    private api: HttpClient
  ) {
  }

  googleLogin(body, params) {
    return this.http.post<IResponse>({path: `${path}/oauth`, body: {body}, params});
  }

  facebookLogin(body, params) {
    return this.http.post<IResponse>({path: `${path}/oauth`, body: {body}, params});
  }

  yandexLogin(body, params) {
    return this.http.post<IResponse>({path: `${path}/oauth`, body: {body}, params});
  }

  loginInfo(params) {
    return this.http.get<ILogin>({path: `${path}/login-info`, params});
  }

}
