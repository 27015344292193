export function loadScriptByUrl(
  url: string
): Promise<{ url; loaded: boolean }> {
  // load script
  const script: any = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;

  return new Promise((resolve, reject) => {
    if (script.readyState) {
      // IE
      script.onreadystatechange = () => {
        if (
          script.readyState === 'loaded' ||
          script.readyState === 'complete'
        ) {
          script.onreadystatechange = null;
          resolve({url, loaded: true});
        }
      };
    } else {
      // Others
      script.onload = () => {
        resolve({url, loaded: true});
      };
    }
    script.onerror = () => reject({url, loaded: false});
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}
