import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-payment',
  templateUrl: './modal-payment.component.html',
  styleUrls: ['./modal-payment.component.scss']
})
export class ModalPaymentComponent implements OnInit {

  @Input() pay;
  @Output() modalEvent = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalEvent.emit();
  }

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }
}
