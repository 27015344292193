import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corp-search-loader',
  templateUrl: './corp-search-loader.component.html',
  styleUrls: ['./corp-search-loader.component.scss']
})
export class CorpSearchLoaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
