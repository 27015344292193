<section class="container news">
  <h2 class="news__title">Лента новостей</h2>
  <a href="https://legal-support.ru/news/" class="news__link" target="_blank">Перейти в блог</a>
  <div class="news__feed">
    <article class="news__item" *ngFor="let item of news">
      <a href="{{ item.link }}" class="news__item-title" target="_blank">{{ item.title }}</a>
      <span class="news__item-date">{{ item.date }}</span>
    </article>
  </div>
</section>
