import { Select } from '@ngxs/store';
import { filter, Observable } from 'rxjs';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { PaymentState } from '../../store/payment.state';
import { PAYMENT_STATUS } from '../../types/payment.types';
import { PaymentActions } from '../../store/payment.actions';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from 'projects/app-ipid/src/environments/environment';
import { OrganizationInterface } from '@web/core/models/organization.interface';
import { PAYMENT_TYPE } from 'projects/app-ipid/src/app/shared/enums/payment-type';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@UntilDestroy()
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(PaymentState.currentTab) currentTab: PAYMENT_TYPE;
  @ViewSelectSnapshot(PaymentState.payer) payer: OrganizationInterface | null;
  @Select(PaymentState.paymentStatus) paymentStatus$: Observable<PAYMENT_STATUS>;
  @ViewSelectSnapshot(PaymentState.paymentStatus) paymentStatus: PAYMENT_STATUS | null;

  @Input() set requestId(value: string | null) {
    this.setApplicationId(value);
  }
  @Input() set paymentAmount(value: number) {
    this.setPaymentAmount(value);
  }
  @Output() cancel = new EventEmitter<void>();
  @Output() paymentError = new EventEmitter<void>();
  @Output() paymentSuccess = new EventEmitter<OrganizationInterface>();

  public logo2path: string = environment.LOGO2_PATH;

  @Dispatch() setPaymentAmount = (value: number) => new PaymentActions.SetPaymentAmount(value);
  @Dispatch() setApplicationId = (value: string | null) => new PaymentActions.SetApplicationId(value);

  public ngOnInit(): void {
    this.paymentStatus$
      .pipe(filter(status => Boolean(status)), untilDestroyed(this))
      .subscribe(status => status === 'succeeded' ? this.success() : this.paymentError.emit());
  }

  public ngOnDestroy(): void {
    // TODO: проверить!!
    // this.clearPaymentState();
  }

  public success(): void {
    const payer = JSON.parse(window.localStorage.getItem('payer'));
    this.paymentSuccess.emit(payer);
    window.localStorage.removeItem('payer');
  }
}
