import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tab-users-rights',
  templateUrl: './tab-users-rights.component.html',
  styleUrls: ['./tab-users-rights.component.scss']
})
export class TabUsersRightsComponent implements OnInit {

  // Массив для вабранных чекбоксов в селекте
  checkedList = [];

  // Изначально выпадающее меню селекта скрыто
  showOrganization = false;
  @Output() editRights = new EventEmitter();
  @Input() organizations;


  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
  ) {
  }

  users = [];
  waitingUsers = [];
  usersCopy;

  ngOnInit(): void {
    this.getUsersList();
  }

  getUsersList() {
    this.organizations.sort((a, b) => a.name.localeCompare(b.name));
    this.organizations.forEach(item => {
      item.representatives.forEach(el => {
        const userIndex = this.getIndexUser(el.email);
        if (userIndex === -1) {
          el.companys = [{companyId: item.id, status: el.status}];
          this.users.push(el);
        } else {
          this.users[userIndex].companys.push({companyId: item.id, status: el.status});
        }
      });
    });
    const indexes = [];
    this.users.forEach((user, index) => {
      let isWaiting = false;
      user.companys.forEach(comp => {
        if (comp.status === 'Приглашен') {
          isWaiting = true;
        }
      });
      if (isWaiting) {
        this.waitingUsers.push(user);
        indexes.push(index);
      }
    });
    indexes.forEach((item, index) => {
      this.users.splice(item - index, 1);
    });

    this.usersCopy = JSON.parse(JSON.stringify(this.users));
  }

  getIndexUser(userEmail) {
    return this.users.findIndex(el => el.email === userEmail);
  }

  // Выпадлающее меню селекта - Организация
  getSelectedValue(checked: boolean, name: string, id: string) {
    if (checked) {
      this.checkedList.push({name, id});
    } else {
      const index = this.checkedList.findIndex(el => el.id === id);
      this.checkedList.splice(index, 1);
    }
    this.applyFilters();
  }

  applyFilters() {
    const selectedOrgId = [];
    this.checkedList.forEach(item => {
      selectedOrgId.push(item.id);
    });
    if (selectedOrgId.length) {
      this.users = this.usersCopy.filter(user => {
        let res = false;
        user.companys.forEach(comp => {
          if (selectedOrgId.includes(comp.companyId) && comp.status !== 'Нет доступа') {
            res = true;
          }
        });
        return res;
      });
    } else {
      this.users = JSON.parse(JSON.stringify(this.usersCopy));
    }
  }

  getActiveOrgCount(user) {
    let count = 0;
    user.companys.forEach(item => {
      if (item.status === 'Активен' || item.status === 'Нет доверенности') {
        count++;
      }
    });
    return count;
  }

  editUserRights(companyID, userID) {
    const rightsData = [];
    rightsData.push(companyID, userID);
    this.editRights.emit(rightsData);
  }

  toInvitePage() {
    this.router.navigate(['/user/invite']);
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  getCompanyNameById(companyId) {
    return this.organizations.find(el => el.id === companyId).name;
  }
}
