<ng-container>
  <div class="add-object add-object__content add-object__content--pay">
<!--    <div class="add-object__header-main">-->
<!--      <h2 class="add-object__header" >Выбор способа оплаты</h2>-->
<!--      <div class="add-object__btns-pay-wrapper">-->
<!--        <app-payment-tabs  style="width: 100%"-->
<!--        (switchPaymentType)="switchPaymentType($event)"></app-payment-tabs>-->
<!--      </div>-->
<!--    </div>-->

    <div class="first">
      <div class="act_header">
        <h2 class="add-object__header">Выбор способа оплаты</h2>
      </div>
      <div class="second">
        <app-payment-tabs
          (switchPaymentType)="switchPaymentType($event)"></app-payment-tabs>
      </div>
    </div>

    <app-payer
    (payerSelectEvent)="selectPayer($event)"></app-payer>

    <app-payment-offline
      *ngIf="(paymentType === 'cash' || paymentType === 'act') && payer?.name"
      [paymentSum]="paymentSum"
      [payer]="payer"
    ></app-payment-offline>

    <app-payment-offline-individual
      *ngIf="(paymentType === 'cash' || paymentType === 'act') && !payer?.name"
      [paymentSum]="paymentSum">
    </app-payment-offline-individual>


    <app-payment-online
      *ngIf="paymentType === 'online'"
      [paymentSum]="paymentSum"
      [returnPage]="'monitoring/add/' + idApplication.toString()"
      [returnAfterPayment]="returnAfterPayment"
      [yaLastPaymentId]="yaLastPaymentId"
      [idApplication]="idApplication"
      (errorEvent)="onlineError($event)"
      [payer]="monitoringAddService.payer"
      (paymentSuccessEvent)="paymentOnlineSuccessed($event)"
    >
    </app-payment-online>

    <div *ngIf="paymentType === 'cash'"
         [class.disabled]="!!payer === false"
         class="offline-btn">
      <app-button
        [loading]="monitoringAddService.isLoading"
        [styleButton]="'btn-blue__offline'"
        (click)="toSuccessPage()"
      >Выставить счет
      </app-button>
    </div>

    <div *ngIf="paymentType === 'act'"
         [class.disabled]="!!payer === false"
         class="offline-btn">
      <app-button
        [loading]="monitoringAddService.isLoading"
        (click)="toSuccessPage()"
        [styleButton]="'btn-blue__offline'"
      >Включить в акт
      </app-button>
    </div>

    <div class="form-item__btn-block payment-buttons">
      <button type="button" class="btn-blue-attr" (click)="backStep()">Назад</button>
    </div>
  </div>
</ng-container>
