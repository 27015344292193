import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortfolioService } from '../../shared/services/portfolio.service';
import { SearchService } from '../../../../../app-search/src/app/services/search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IContracts, IFiles, ISearchObjects } from '../../shared/models/main.interfaces';
import allowedTypes from '../../../../../shared/types/allowed-types';
import * as _ from 'lodash';
import {DownloadFilesService} from '../../../../../shared/services/download-files.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {
  contracts: IContracts;
  contractChanges;
  contractChangesCopy;
  contractID;
  selectedCompanyId;
  assignee;
  assignor;
  contractComment;
  commentParam;
  contractName;
  customName;
  realName;
  subContracts;
  subContractsCopy;
  newTitle;
  searchTerm;
  searchTermHistory;
  placeholder = 'Дата регистрации';
  value = [];
  regDate;
  expDate;
  paramError = false;
  @ViewChild('fileInput') inputRef: ElementRef;
  @ViewChild('textArea') inputRefs: ElementRef;
  file;
  files: IFiles[];
  filesInitial: IFiles[];
  showAllFiles = false;
  fullTerms = false;
  editHeader = false;
  errorText;
  objectsInitial;
  objects;
  showAllObjects = false;
  showObjectsButton = true;
  private readonly onDestroy = new Subject<void>();

  // Вкладки разедла Договоры
  overview = true;
  license = false;
  history = false;

  // Вкладки разедла Суды
  overviewCourt = true;
  historyCourt = false;

  // Преключатель Договоры/Суды
  docsPage = true;
  courtPage = false;

  // Варианты отображнеия вкладки Обзор раздела Суды
  overviewVar1 = true;
  overviewVar2 = false;

  // Кнопки блока Эксперт
  btns = ['Консультация по результатам поиска', 'Вопрос по текущему делопроизводству',
    'Консультация по услугам', 'Запрос первичных документов', 'Другое'];

  // фильтр Статус
  states = ['Действует', 'Не действует'];
  selectedState = 'Статус';
  statesShow: boolean;

  // фильтр Даты регистрации 2
  dateFrom = ['Дата не выбрана', '01.01.2020', '02.01.2020'];
  selectedDateFrom = 'Дата регистрации от';
  dateFromShow: boolean;

  // фильтр Даты регистрации 2
  dateTo = ['Дата не выбрана', '01.01.2020', '02.01.2020'];
  selectedDateTo = 'Дата регистрации до';
  dateToShow: boolean;

  bigText: boolean;

  // Форма поиска на мобилке
  showMobileControl = false;

  // Загружены ли договоры
  isLoadingContracts = false;

  contract = {
    url: '/portfolio',
    tab: 'contracts',
  };
  fileError = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private portfolioService: PortfolioService,
    private searchApi: SearchService,
    readonly downloadFiles: DownloadFilesService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      this.contractID = param.contractID;
      this.selectedCompanyId = param.selectedCompanyId;
      this.commentParam = param.comment;
      this.contractName = param.name;
      this.customName = param.customName;
      if (this.contractID) {
        this.getContracts(this.contractID);
      }
      if (this.customName !== '') {
        this.realName = this.customName;
      }
      if (this.customName === '') {
        this.realName = this.contractName;
      }
    });

    this.toCheckOverview();
  }

  getContracts(contractID) {
    const body: ISearchObjects = {
      number: contractID
    };
    this.portfolioService.getContracts(body).subscribe(answer => {
      this.contracts = answer[0];
      this.objectsInitial = this.contracts.tradeMarks;
      this.getFiles();
      this.getSublicense(answer);
      this.getHistory(this.contracts);
      this.sortContracts(this.contracts);
      this.isLoadingContracts = true;
    });
  }

  sortContracts(contracts) {
    if (!this.showAllObjects) {
      this.objects = contracts.tradeMarks.slice(0, 3);
    } else {
      this.objects = contracts.tradeMarks;
    }
  }

  getAllObjects() {
    this.objects = this.objectsInitial;
    this.showObjectsButton = false;
  }

  getSublicense(answer) {
    if (this.contracts._options[0] === 'isParent') {
      this.subContracts = answer.filter(i => i._options[0] === 'isChild');
      this.subContractsCopy = [...this.subContracts];
    }
  }

  getHistory(contract) {
    if (contract.changesContract) {
      this.contractChanges = this.contracts.changesContract;
      this.contractChangesCopy = [...this.contractChanges];
    }
  }

  getFullTerms() {
    if (this.contracts.changesContract) {
      if (!this.fullTerms) {
        return this.contracts.changesContract[0].contractTerms.substring(0, 200);
      }
      if (this.fullTerms) {
        return this.contracts.changesContract[0].contractTerms;
      }
    }
    if (!this.contracts.changesContract) {
      if (!this.fullTerms) {
        return this.contracts.contractTerms?.substring(0, 200) ?? '';
      }
      if (this.fullTerms) {
        return this.contracts.contractTerms;
      }
    }
  }

  getImage(index, id, fileName, object) {
    if (index === 'rutmap' && object.applicationString) {
      return this.searchApi.getImgUrl(index, object.applicationString, fileName);
    } else {
      return this.searchApi.getImgUrl(index, id, fileName);
    }
  }

  onSelect(state): void {
    this.selectedState = state;
    this.statesShow = false;
    this.filterByStatus(state);
  }

  onSelectDateFrom(date): void {
    if (date === 'Дата не выбрана') {
      this.selectedDateFrom = 'Дата регистрации от';
    } else {
      this.selectedDateFrom = date;
    }
    this.dateFromShow = false;
  }

  onSelectDateTo(date): void {
    if (date === 'Дата не выбрана') {
      this.selectedDateTo = 'Дата регистрации до';
    } else {
      this.selectedDateTo = date;
    }
    this.dateToShow = false;
  }

  toCheckOverview() {
    if (this.overview) {
      this.license = false;
      this.history = false;
    }
  }

  toCheckLicense() {
    if (this.license) {
      this.history = false;
      this.overview = false;
    }
  }

  toCheckHistory() {
    if (this.history) {
      this.license = false;
      this.overview = false;
    }
  }

  getShortOkved(okved): string {
    if (okved.length > 40) {
      return okved.slice(0, 40) + '...';
    } else {
      return okved;
    }
  }

  postComment() {
    console.log(this.commentParam);
    console.log(this.contractComment);
    if (this.commentParam) {
      this.contractComment = this.inputRefs.nativeElement.value;
    }
    const params = {
      contractID: this.contracts.contractNumber
    };
    const body = {
      comment: this.contractComment
    };
    this.portfolioService.postComment(body, params).pipe(takeUntil(this.onDestroy))
      .subscribe(answer => {
        if (answer) {
          this.router.navigate(['contracts'], {
            queryParams: {
              contractID: this.contractID,
              comment: this.contractComment,
              customName: this.realName
            }
          });
        }
      });
  }

  onFileUpload(fileInput: any) {
    if (this.fileError?.length) {
      this.fileError = '';
    }
    this.file = fileInput.target.files[0];
    if (this.file) {
      const maxSize = 2000000;
      if (fileInput.target.files[0].size > maxSize) {
        this.fileError = 'Максимальный размер файла ' + maxSize / 1000000 + 'Mb';
        return false;
      }
      if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
        this.fileError = 'Недопустимый тип файла';
        return false;
      }
      if (!this.fileError?.length) {
        this.uploadFile();
      }
    }

  }

  uploadFile() {
    const params = {
      contractID: this.contracts.contractNumber
    };
    const body = this.file;
    this.portfolioService.addFile(params, body).pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.ngOnInit();
      });
  }

  getFiles() {
    const params = {
      contractID: this.contracts.contractNumber
    };
    this.portfolioService.getFiles(params).pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.filesInitial = data.files;
        this.files = data.files.slice(0, 3);
        if (this.filesInitial.length > 3) {
          this.showAllFiles = true;
        }
        if (this.filesInitial.length <= 3) {
          this.showAllFiles = false;
        }
      });
  }

  getAllFiles() {
    this.files = this.filesInitial;
  }

  deleteFile(path) {
    const params = {
      contractID: this.contracts.contractNumber
    };
    this.portfolioService.deleteFile(params, {path}).pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.ngOnInit();
      });
  }

  editHeaders() {
    if (this.realName) {
      const params = {
        contractID: this.contracts.contractNumber
      };
      const body = {
        newName: this.realName
      };
      this.portfolioService.editTitle(params, body).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
        if (answer.answer === 'Успешно') {
          this.editHeader = false;
          this.paramError = false;
          console.log(this.contractComment);
          console.log(this.commentParam);
          this.router.navigate(['contracts'], {
            queryParams: {
              contractID: this.contractID,
              comment: this.contractComment || this.commentParam,
              name: this.contractName,
              customName: this.realName
            }
          });
        } else {
          this.paramError = true;
          this.errorText = answer.answer;
        }
      });
    } else {
      this.paramError = true;
      this.errorText = 'Поле не может быть пустым';
    }
  }

  receiveDate($event) {
    if ($event) {
      $event.forEach(date => {
        date.setUTCHours(0, 0, 0, 0);
      });
      this.regDate = $event[0].toISOString().replace('Z', '').substring(0, 19);
      this.expDate = $event[1].toISOString().replace('Z', '').substring(0, 19);
    }
  }


  searchDateSubcontracts() {
    const from = new Date(this.regDate).getTime();
    const to = new Date(this.expDate).getTime();
    this.subContractsCopy = this.subContracts.filter(i => {
      const date = new Date(i.contractDate).getTime();
      return date >= from && date <= to;
    });
  }

  searchDateHistory() {
    const from = new Date(this.regDate).getTime();
    const to = new Date(this.expDate).getTime();
    this.contractChangesCopy = this.contractChanges.filter(i => {
      const date = new Date(i.changeDate).getTime();
      return date >= from && date <= to;
    });
  }

  searchAssignee() {
    if (this.searchTerm.length >= 1) {
      this.subContractsCopy = this.subContracts.filter((obj) => obj.assignee.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    } else {
      this.clearAll();
    }
  }

  searchHistory() {
    if (this.searchTermHistory.length >= 1) {
      this.contractChangesCopy = this.contractChanges.filter((obj) =>
        obj.assignee.name.toLowerCase().includes(this.searchTermHistory.toLowerCase())
        || obj.contractTerms.toLocaleLowerCase().includes(this.searchTermHistory.toLocaleLowerCase())
        || obj.assignor.name.toLocaleLowerCase().includes(this.searchTermHistory.toLocaleLowerCase())
      );
    } else {
      this.clearAll();
    }
  }

  filterByStatus(state) {
    if (state === 'Действует') {
      this.subContractsCopy = this.subContracts.filter(i => i.valid);
    }
    if (state === 'Не действует') {
      this.subContractsCopy = this.subContracts.filter(i => !i.valid);
    }
    if (state === 'Не выбран') {
      this.subContractsCopy = this.subContracts;
    }
  }

  openModal() {
    this.showMobileControl = true;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'hidden');
    bodyStyles.setProperty('height', '100%');
  }

  closeModal() {
    this.showMobileControl = false;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
    bodyStyles.setProperty('height', 'auto');
  }

  clearAll() {
    this.subContractsCopy = this.subContracts;
    this.contractChangesCopy = this.contractChanges;
    this.selectedState = 'Статус';
    this.searchTerm = '';
    this.searchTermHistory = '';
    this.value = [];
  }

  triggerClick() {
    this.inputRef.nativeElement.click();
  }

  toLeadForm(identity) {
    const data = {
      contractID: this.contractID,
      formIdentity: identity,
      selectedCompanyId: this.selectedCompanyId
    };
    this.router.navigate(['/treaty-extension'], {queryParams: {data: JSON.stringify(data)}});
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
