import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PaginatorComponent implements OnChanges {
  @Input() totalCount: number;
  @Input() itemPerPage: number;
  @Input() currentPageNumber: number;
  @Output() changePage = new EventEmitter<number>();

  public pageNumbersList: number[] = [];
  public isNextSet: boolean = false;
  public isPrevSet: boolean = true;
  public pageCount: number;

  private readonly maxPaginationBtns = 7;

  public ngOnChanges(): void {
    this.isNextSet = false;
    this.isPrevSet = false;

    this.pageCount = Math.ceil(this.totalCount / this.itemPerPage);

    let numberBtns = this.maxPaginationBtns;
    if (this.pageCount > this.maxPaginationBtns) {
      if (this.currentPageNumber > 3) {
        this.isPrevSet = true;
        numberBtns -= 1;
      }
      if ((this.pageCount + 1 - this.currentPageNumber) > 4) {
        this.isNextSet = true;
        numberBtns -= 3;
      }
    } else {
      numberBtns = this.pageCount;
    }

    let startPageBtn: number;
    if (!this.isPrevSet) {
      startPageBtn = 1;
    } else if (!this.isNextSet) {
      startPageBtn = this.pageCount + 1 - numberBtns;
    } else {
      startPageBtn = this.currentPageNumber - 1;
    }

    this.pageNumbersList = [];
    for (let i = 0; i < numberBtns; i++) {
      this.pageNumbersList.push(startPageBtn + i);
    }
  }

  public onChangePageNumber(pageNumber: number): void {
    this.changePage.emit(pageNumber);
  }

  public getWidthBtn(): { width: string } {
    if (window.innerWidth < 800) {
      switch (this.pageNumbersList.length) {
        case 5:
          return {width: 'calc((100% - 30px) / 7'};
        default:
          return {width: 'calc((100% - 25px) / 6'};
      }
    }
  }
}
