<div *ngIf="!state.isLoadingPage" class='searches-container'>

  <app-corp-search-history-filters
    *ngIf="!state.isLoadingPage && state.currentTab === 'searches'"
    [state]="state"
    [filterValue]="filterValue"
    [layout]="layout"
    [users]="users"
    (changeFilter)='applyFilters($event)'
    [viewMode]="viewMode"
  >
  </app-corp-search-history-filters>

  <app-corp-search-history-filters
    *ngIf="!state.isLoadingPage && state.currentTab === 'reports'"
    [state]="state"
    [filterValue]="filterValue"
    [layout]="layout"
    [users]="users"
    (changeFilter)='applyFilters($event)'
    [viewMode]="viewMode"
  >
  </app-corp-search-history-filters>

  <app-list
    [dataSource]="state.currentTab === 'searches' ? state.searches : state.reports"
    [isLoading]="state.isLoadingResult"
    [totalCount]="state.currentTab === 'searches' ? state.filteredCountSearchHistory : state.filteredCountReports"
    [currentLayout]="layout"
    [itemsPerPage]="state.currentTab === 'searches' ? state.historyPagination.limit : state.reportsPagination.limit"
    [currentPage]="currentPage"
    [paddingProps]="{'padding': '0'}"
    (changePage)="onChangePage($event)"
  >
    <ng-template #header>
      <div class="header-template grid-template header-searches-history"
           [style.grid-template-columns]="listLayout"
      >
        <div *ngIf="layout !== 'sm'" class="grid-template__item grid-template__item-header">
          Дата поиска, пользователь
        </div>
        <div *ngIf="layout !== 'sm'" class="grid-template__item grid-template__item-header">
          Поисковый запрос
        </div>
        <div *ngIf="layout !== 'sm'" class="grid-template__item grid-template__item-header">
          Вид поиска
        </div>
        <div *ngIf="layout !== 'sm'" class="grid-template__item grid-template__item-header">
          Количество результатов
        </div>
        <div *ngIf="layout !== 'sm'" class="grid-template__item grid-template__item-header">
          Добавлено в отчет
        </div>
      </div>
    </ng-template>
    <ng-template #item let-item>
      <app-history-search-item
        [search]='item'
        [currentLayout]='layout'
        [listLayout]='listLayout'
        [viewMode]='viewMode'
        [downloadingIdList]="downloadingIdList"
        (downloadReport)="onDownloadReport($event)"
        (openReport)="onOpenReport($event)"
      >
      </app-history-search-item>
    </ng-template>
  </app-list>
</div>
