import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CasesRoutingModule } from './cases-routing.module';
import { CasesItemComponent } from './cases-item/cases-item.component';
import { CasesOldComponent } from './cases-old/cases.component';
import { CasesComponent } from './cases.component';
import { CasesListItemComponent } from './cases-list-item/cases-list-item.component';
import { CasesListFiltersComponent } from './cases-list-filters/cases-list-filters.component';
import { ItemEventsComponent } from './cases-item/item-events/item-events.component';
import { ItemInfoComponent } from './cases-item/item-info/item-info.component';
import { TabHasDocsComponent } from './cases-item/tabs-types/tab-has-docs/tab-has-docs.component';
import { ItemTracksComponent } from './cases-item/item-tracks/item-tracks.component';
import { WarningTextComponent } from './cases-item/tabs-types/warnings/warning-text/warning-text.component';
import { WarningPaymentComponent } from './cases-item/tabs-types/warnings/warning-payment/warning-payment.component';
import { TabResultsComponent } from './cases-item/tabs-types/tab-results/tab-results.component';
import { ItemFilesComponent } from './cases-item/item-files/item-files.component';
import { TabWithTableComponent } from './cases-item/tabs-types/tab-with-table/tab-with-table.component';
import { TabCommonInfoComponent } from './cases-item/tabs-types/tab-common-info/tab-common-info.component';
import { WarningWithListComponent } from './cases-item/tabs-types/warnings/warning-with-list/warning-with-list.component';
import { TabWhatWeDidComponent } from './cases-item/tabs-types/tab-what-we-did/tab-what-we-did.component';
import { TabTariffComponent } from './cases-item/tabs-types/tab-tariff/tab-tariff.component';
import { TabWrongComponent } from './cases-item/tabs-types/tab-wrong/tab-wrong.component';
import { TabsTypesComponent } from './cases-item/tabs-types/tabs-types.component';
import { TabAddingDocsComponent } from './cases-item/tabs-types/tab-adding-docs/tab-adding-docs.component';
import { TabComplexFormComponent } from './cases-item/tabs-types/tab-complex-form/tab-complex-form.component';
import { TabDocMixComponent } from './cases-item/tabs-types/tab-doc-mix/tab-doc-mix.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {ExpertModule} from '../layout/expert/expert.mdoule';


@NgModule({
  declarations: [
    CasesItemComponent,
    CasesOldComponent,
    CasesComponent,
    CasesListItemComponent,
    CasesListFiltersComponent,
    CasesOldComponent,
    CasesComponent,
    CasesListItemComponent,
    CasesListFiltersComponent,
    CasesItemComponent,
    ItemEventsComponent,
    ItemInfoComponent,
    ItemFilesComponent,
    TabsTypesComponent,
    TabHasDocsComponent,
    TabAddingDocsComponent,
    TabWithTableComponent,
    TabCommonInfoComponent,
    TabTariffComponent,
    TabResultsComponent,
    TabWrongComponent,
    TabDocMixComponent,
    TabWhatWeDidComponent,
    TabComplexFormComponent,
    WarningWithListComponent,
    WarningPaymentComponent,
    WarningTextComponent,
    ItemTracksComponent,
  ],
  imports: [
    CommonModule,
    CasesRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    MatTooltipModule,
    ClickOutsideModule,
    PerfectScrollbarModule,
    FontAwesomeModule,
    ExpertModule
  ]
})
export class CasesModule { }

