import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CorpSearchService } from '../../services/corp-search.service';
import { CorpSearchByNameService } from '../../services/corp-search-by-name.service';
import { EnvService } from '../../../../../../app-ipid/src/app/shared/services/env.service';
import { ISearchOptions } from '../../../../../../app-search/src/app/models/search.interfaces';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../../../../app-ipid/src/app/shared/services/shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ISearchHistoryPayload } from '../../../../../../app-ipid/src/app/shared/models/search-history.interfaces';
import {Store} from '@ngxs/store';
import {AuthState} from '@web/core/states/auth.state';
import {nullableFilter} from '@web/utils/nullable-filter.util';

@Component({
  selector: 'app-corp-search',
  templateUrl: './corp-search.component.html',
  styleUrls: ['./corp-search.component.scss'],
  providers: []
})
export class CorpSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  rightsSearch$ = this.store.select(AuthState.getSearchRights);
  contragentID;
  private readonly onDestroy = new Subject<void>();

  constructor(
    readonly envService: EnvService,
    readonly corpSearchService: CorpSearchService,
    readonly corpSearchByNameService: CorpSearchByNameService,
    readonly activatedRoute: ActivatedRoute,
    private sharedAPI: SharedService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.setBodyStyles();
    // const currentType = this.activatedRoute.snapshot.paramMap.get('type');
    // this.corpSearchService.changeType(currentType || 'name');

    // TODO: после создания матрицы прав подумать над этим
    // this.getRights();

    this.rightsSearch$.pipe(
      nullableFilter(),
      takeUntil(this.onDestroy)
    ).subscribe(rightsSearch => {
      if (rightsSearch?.length) {
        rightsSearch.forEach((right) => {
          if (right.name === 'по обозначению') {
            this.corpSearchService.searchTypeOptions[0].accept = right.value;
          } else if (right.name === 'по изображению') {
            this.corpSearchService.searchTypeOptions[1].accept = right.value;
          }
        });
        this.corpSearchService.placeholder = this.corpSearchService.searchTypeOptions[1].accept === true ? 'Укажите обозначение или' : 'Укажите обозначение';
      }
    });


    const query = this.activatedRoute.snapshot.queryParamMap;

    if (query.has('searchID') && !query.has('parse')) {
      const searchID = query.get('searchID');
      const searchAction = query.has('show') ? 'show' : 'repeat';

      const params: ISearchHistoryPayload = {
        searchID,
        limit: 50,
        offset: 0,
        entity: 'trademark'
      };

      this.corpSearchService.loadBySearchID(params, searchAction);
    }

    this.activatedRoute.paramMap.subscribe(params => {
      this.corpSearchService.changeType(params.get('type'));
    });
  }

  ngOnDestroy(): void {
    this.corpSearchService.reset();
  }


  onSearchByName(data: ISearchOptions) {
    this.corpSearchByNameService.load(data);
  }

  ngAfterViewInit(): void {
    // this.onSearchByName({ designation: 'yandex', mktuList: [], okvedList: [] });
  }

  // TODO: после создания матрицы прав подумать над этим
  // getRights() {
  //   this.contragentID = JSON.parse(localStorage.getItem('organizations'));
  //   if (this.contragentID) {
  //     const params = {
  //       contragentID: this.contragentID[0].id
  //     };
  //     this.sharedAPI.getRightsAsync(params).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
  //       answer.rights.find(el => {
  //         if (el.title.toLowerCase() === 'права на поиски') {
  //           this.rightsSearch = el.data;
  //         }
  //       });
  //
  //       if (this.rightsSearch?.length) {
  //         this.rightsSearch.forEach((right) => {
  //           if (right.name.toLowerCase() === 'по обозначению') {
  //             this.corpSearchService.searchTypeOptions[0].accept = right.value;
  //           } else if (right.name.toLowerCase() === 'по изображению') {
  //             this.corpSearchService.searchTypeOptions[1].accept = right.value;
  //           }
  //         });
  //         // if (this.rightsSearch.some(el => el.value === 'true' || el.value === true)) {
  //         //   this.corpSearchService.searchTypeOptions[1].accept = this.corpSearchService.searchTypeOptions[2].accept = true;
  //         // }
  //         this.corpSearchService.placeholder = this.corpSearchService.searchTypeOptions[1].accept === true ? 'Укажите обозначение или' : 'Укажите обозначение';
  //       }
  //     });
  //   }
  // }

  setBodyStyles() {
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
    bodyStyles.setProperty('position', 'static');
  }

}
