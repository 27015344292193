import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopularServicesService } from './popular-services.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {
  // Вид объекта
  typeOfObjects = [
    {
      id: 1,
      value: 'Регистрация товарного знака',
      checked: false
    },
    {
      id: 2,
      value: 'Патентование',
      checked: false
    },
    {
      id: 3,
      value: 'Суды и споры',
      checked: false
    },
    {
      id: 4,
      value: 'Договоры',
      checked: false
    },
    {
      id: 5,
      value: 'Другие услуги',
      checked: false
    }
  ];
  // Государственный орган
  objectsSelect = [
    {
      id: 7,
      // value: 'Роспатент (ФИПС)',
      value: 'Роспатент',
      checked: false
    },
    {
      id: 8,
      value: 'Арбитражный суд',
      checked: false
    },
    {
      id: 9,
      value: 'Суд по интеллектуальным правам',
      checked: false
    },
    {
      id: 10,
      value: 'Палата по патентным спорам (ППС)',
      checked: false
    },
    {
      id: 11,
      value: 'Федеральная таможенная служба',
      checked: false
    },
    {
      id: 12,
      value: 'Федеральная антимонопольная служба',
      checked: false
    },
    {
      id: 13,
      value: 'ВОИС',
      checked: false
    },
    {
      id: 14,
      value: 'РАО КОПИРУС',
      checked: false
    },
    {
      id: 15,
      value: 'Роспатент (ФИПС)',
      checked: false
    }
  ];
  $services;
  $servicesCopy;
  sliderCards;
  interval;
  Search: string = null;
  searchTerm;
  stateFilter;
  stateEntity;
  disableArrow = true;
  showNoResult = false;
  showFilters = false;

  private readonly onDestroy = new Subject<void>();
  showMobileControl = false;
  showGovernmentAgency = false;
  showTypeObject = false;

  @ViewChild('slider') slider;

  constructor(
    private popularApi: PopularServicesService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getSliders();
    this.getServices();
    this.getStateFilter();
  }

  getSliders() {
    this.popularApi.getSlider().pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.sliderCards = data.items;
      });
    document.body.style.setProperty('overflow', 'visible');

    if (window.innerWidth <= 793) {
      this.showMobileControl = false;
    } else {
      this.showMobileControl = true;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 793) {
        this.showMobileControl = false;
      } else {
        this.showMobileControl = true;
      }
    });

  }

  makeBodyHidden(): void {
    if (window.innerWidth <= 793) {
      if (this.showMobileControl === true) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.height = '100%';
      } else {
        document.body.style.overflow = 'visible';
        document.body.style.position = '';
        document.body.style.height = 'auto';
      }
    }
  }

  getStateFilter() {
    this.popularApi.getStateFilter().pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.stateFilter = data.items;
      });
  }

  getServices() {
    this.popularApi.getServices().pipe(takeUntil(this.onDestroy))
      .subscribe((data) => {
        this.$services = data.items;
        console.log(this.$services);
        this.$servicesCopy = JSON.parse(JSON.stringify(this.$services));
      });
  }


  getCountCheckedObjects(massive) {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });

    return counter;
  }

  moveLeft() {
    this.slider.prev();
  }

  moveRight() {
    this.slider.next();
    this.disableArrow = false;
  }

  isNextArrowDisabled() {
    if (this.slider) {
      return this.slider.isNextArrowDisabled();
    }
  }

  isPrevArrowDisabled() {
    if (this.slider) {
      return this.slider.isPrevArrowDisabled();
    }
  }

  applyFilters() {
    this.$services = JSON.parse(JSON.stringify(this.$servicesCopy));
    this.filterByType();
    this.filterByText();
    this.filterByStateEntity();
  }

  filterByText() {
    this.$services = JSON.parse(JSON.stringify(this.$servicesCopy));
    if (this.searchTerm) {
      this.$services.forEach(item => {
        item.cards.forEach(el => {
          el.links = el.links.filter(link => link.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
        });
        item.cards = item.cards.filter(card => card.links.length);
      });
      this.$services = this.$services.filter(service => service.cards.length);
      console.log(this.$services.length);
      if (this.$services.length === 0) {
        this.showNoResult = true;
      }
    }
  }

  filterByStateEntity() {
    this.$services = JSON.parse(JSON.stringify(this.$servicesCopy));
    const indexes = [];
    if (this.objectsSelect[0].checked) {
      indexes.push(this.objectsSelect[0].value);
    }
    if (this.objectsSelect[1].checked) {
      indexes.push(this.objectsSelect[1].value);
    }
    if (this.objectsSelect[2].checked) {
      indexes.push(this.objectsSelect[2].value);
    }
    if (this.objectsSelect[3].checked) {
      indexes.push(this.objectsSelect[3].value);
    }
    if (this.objectsSelect[4].checked) {
      indexes.push(this.objectsSelect[4].value);
    }
    if (this.objectsSelect[5].checked) {
      indexes.push(this.objectsSelect[5].value);
    }
    if (this.objectsSelect[6].checked) {
      indexes.push(this.objectsSelect[6].value);
    }
    if (this.objectsSelect[7].checked) {
      indexes.push(this.objectsSelect[7].value);
    }
    if (this.objectsSelect[8].checked) {
      indexes.push(this.objectsSelect[8].value);
    }
    console.log(indexes);
    if (indexes.length) {
      this.$services.forEach(item => {
        item.cards.forEach(el => {
          el.links.forEach(link => {
            link.stateBody = link.stateBody.filter(state => {
              return indexes.includes(state.id);
            });
          });
          el.links = el.links.filter(link => link.stateBody.length);
        });
        item.cards = item.cards.filter(card => card.links.length);
      });
      this.$services = this.$services.filter(service => service.cards.length);
      if (this.$services.length === 0) {
        this.showNoResult = true;
      }
    }

  }

  filterByType() {
    console.log(1);
    this.$services = JSON.parse(JSON.stringify(this.$servicesCopy));
    const indexes = [];
    if (this.typeOfObjects[0].checked) {
      indexes.push('Регистрация товарного знака');
    }
    if (this.typeOfObjects[1].checked) {
      indexes.push('Патентование');
    }
    if (this.typeOfObjects[2].checked) {
      indexes.push('Суды и споры');
    }
    if (this.typeOfObjects[3].checked) {
      indexes.push('Договоры');
    }
    if (this.typeOfObjects[4].checked) {
      indexes.push('Другие услуги ');
    }
    if (indexes.length) {
      this.$services = this.$services.filter(item => indexes.includes(item.title));
      if (this.$services.length === 0) {
        this.showNoResult = true;
      }
    }
  }
  toServiceDetails(id) {
    this.router.navigate(['services/service-item'], {queryParams: {id}});
  }

  clearAll() {
    this.$services = JSON.parse(JSON.stringify(this.$servicesCopy));
    this.searchTerm = '';
    this.typeOfObjects.forEach(item => {
      item.checked = false;
    });
    this.objectsSelect.forEach(item => {
      item.checked = false;
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
