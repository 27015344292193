<section class="section-top-search">
  <div class="search_header">
    <div class="container">
      <div class="search-wrapper">

        <!--        <app-ip-id-search-input-->
        <!--          class="app-ip-id-search-input search&#45;&#45;no-border"-->
        <!--          [designation]="corpSearchByNameService.searchOptions.designation"-->
        <!--          [okvedList]="corpSearchByNameService.searchOptions.okvedList"-->
        <!--          [mktuList]="corpSearchByNameService.searchOptions.mktuList"-->
        <!--          [countriesList]="corpSearchByNameService.searchOptions.countriesList"-->
        <!--          [disableCountry]="disableCountry"-->
        <!--          [disableButton]="disabledButton"-->
        <!--          (search)="corpSearchByNameService.load($event)"-->
        <!--          (searchImage)="onSearch($event)"-->
        <!--        ></app-ip-id-search-input>-->

        <app-ip-id-search-input-corporate
          class="app-ip-id-search-input search--no-border"
          [options]="searchOptions"
          [designation]="corpSearchByImageService.searchOptions.designation"
          [okvedList]="corpSearchByImageService.searchOptions.okvedList"
          [mktuList]="corpSearchByImageService.searchOptions.mktuList"
          [countriesList]="corpSearchByImageService.searchOptions.countriesList"
          [disableCountry]="disableCountry"
          [disableButton]="disabledButton"
          (fileChangeEvent)="fileChangeEvent($event)"
          (searchImage)="onSearch($event)"
          (showPopup)="showPopup($event)"
        >
        </app-ip-id-search-input-corporate>

      </div>
      <div class="cropper_container" [class.visible-border]="visibleBorder">
        <image-cropper
          [imageBase64]="imageChangedEvent"
          [maintainAspectRatio]="false"
          [aspectRatio]="4 / 3"
          [onlyScaleDown]="visibleBorder"
          [hideResizeSquares]="visibleBorder"
          format="png"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
      </div>
      <app-corp-search-loader *ngIf="isLoading"></app-corp-search-loader>
      <span *ngIf="count && !isLoading && innerWidth > 1200" class="counter_num">Найдено</span>

    </div>
    <div class="zz-index" *ngIf="tradeMarks && !isLoading">
      <app-corp-search-trademarks
        [type]="'image'"
        [showFilters]="true"
        [items]="tradeMarks"
        [disableRisk]="true"
        (select)="onSelect('tradeMarks', $event)"
      ></app-corp-search-trademarks>
      <app-corp-search-create-report *ngIf="!corpSearchApiService.objectsLoading"></app-corp-search-create-report>
    </div>
  </div>
</section>
