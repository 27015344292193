import { PaymentTab, PAYMENT_TYPE } from './payment.types';

export const PAYMENT_TABS_WITH_ACT: PaymentTab[] = [
  { type: PAYMENT_TYPE.ONLINE, title: 'Оплата онлайн', id: 'onlineBtn' },
  { type: PAYMENT_TYPE.ACT, title: 'Включить в акт', id: 'actBtn' },
  { type: PAYMENT_TYPE.OFFLINE, title: 'Выставить счет для безналичной оплаты', id: 'btnOfflinePay' },
];

export const DEFAULT_PAYMENT_TABS: PaymentTab[] = [
  { type: PAYMENT_TYPE.ONLINE, title: 'Оплата онлайн', id: 'onlineBtn' },
  { type: PAYMENT_TYPE.OFFLINE, title: 'Выставить счет для безналичной оплаты', id: 'btnOfflinePay' },
];
