import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from '../../shared/services/http.service';
import { MonitoringService } from './monitoring.service';
import risksData from './json-data-risks/json-data-risks';
import { MonitoringAddService } from './shared/monitoring-add.service';
import { UserProfileService } from '../../../../../shared/services/user-profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EnvService } from '../../shared/services/env.service';
import { InterestService } from '../../../../../shared/services/interest.service';
import { Store } from '@ngxs/store';
import { OrganizationState } from '@web/core/states/organization.state';
import { OrganizationInterface } from '@web/core/models/organization.interface';

interface ITypeTab {
  critical: boolean;
  high: boolean;
  possible: boolean;
  recommendation: boolean;
  all: boolean;
}

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
  providers: []
})


export class MonitoringComponent implements OnInit, OnDestroy {
  public kontragents: OrganizationInterface[] = [];

  private readonly onDestroy = new Subject<void>();

  showCompanyList = false;
  selectedCompany;
  risksData = risksData;
  objectsWithoutMonitoring = [];
  objectsWithMonitoring = [];
  risks = {
    critical: [],
    high: [],
    possible: [],
    recommendation: []
  };
  hiddenRisks = {
    critical: [],
    high: [],
    possible: [],
    recommendation: []
  };
  // копия рисков для фильтров
  risksAll = {
    critical: [],
    high: [],
    possible: [],
    recommendation: []
  };
  // массив всех рисков без разбиеная на типы для вкладки "все"
  risksAllForTab = [];

  searchText;

  checkedObjects = [];
  showModalUnconnected = false;
  dataUnconnectedObject;
  showModalRisk = false;
  dataRisk;

  // модальное окно о скрытых рисках
  attention = false;

  // наличие объектов
  noObjects = false;

  // вкладки
  typeTab: ITypeTab = {
    critical: false,
    high: false,
    possible: false,
    recommendation: false,
    all: true,
  };

  // Вид объекта options (статичные)
  typeOfObjects = [
    {
      id: 1,
      value: 'Патенты',
      checked: false,
    },
    {
      id: 2,
      value: 'Товарные знаки',
      checked: false,
    },
    {
      id: 6,
      value: 'Домены',
      checked: false
    },
  ];

  // Объекеты options
  objectsSelect = [];

  // не подключенные объекты Показть еще
  showMore = true;

  // Открыть/скрыть на мобильном
  // Состав рисков
  compositionRisks;
  // Объекты рисков
  objectsRisks;
  // Динамика рисков
  dynamicsRisks;

  showMobileControl = false;
  loadingRisks = true;
  showHiddenRisks = false;
  hiddenRisksCount = 0;
  pages = {
    critical: 1,
    high: 1,
    possible: 1,
    recommendation: 1,
    all: 1,
  };
  loadingUnconnectedObjects = true;
  private trademarkIndexes = ['rutm', 'rutmap', 'wktm', 'wotm', 'nmpt', 'rugp', 'rugpap'];

  objectsLoaded = false;
  risksLoaded = false;
  criticalCount;
  allCount;
  highCount;
  middleCount;
  recommendedCount;
  // Выпадающее меню Вид объекта
  showObjectType = false;
  // Выпадающее меню Объект
  showObject = false;

  corporate: boolean; // флаг корпоративного бренда

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private monitoringService: MonitoringService,
    readonly monitoringAddService: MonitoringAddService,
    private userProfileService: UserProfileService,
    public envService: EnvService,
    private readonly store: Store,
    private interestService: InterestService
  ) {
  }

  ngOnInit(): void {
    this.corporate = environment.corporate;
    this.store.select(OrganizationState.organizations)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(organizations => this.initOrganizations(organizations));
  }

  initComponent(companyId): void {
    this.setWidth();
    this.getObjectsWithoutMonitoring(companyId);
    this.getRisks(companyId);
    this.switchTypeTab('all');
  }

  setWidth(): void {
    if (window.innerWidth <= 793) {
      this.compositionRisks = false;
      this.objectsRisks = false;
      this.dynamicsRisks = false;
    } else {
      this.compositionRisks = true;
      this.objectsRisks = true;
      this.dynamicsRisks = true;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 793) {
        this.compositionRisks = false;
        this.objectsRisks = false;
        this.dynamicsRisks = false;
      } else {
        this.compositionRisks = true;
        this.objectsRisks = true;
        this.dynamicsRisks = true;
      }
    });
  }

  getRisks(companyId?: string): void {
    this.loadingRisks = true;
    this.hiddenRisks = {
      critical: [],
      high: [],
      possible: [],
      recommendation: []
    };
    this.risks = {
      critical: [],
      high: [],
      possible: [],
      recommendation: []
    };
    this.hiddenRisksCount = 0;
    // todo pagination and filters
    this.monitoringService.getObjectsWithRisks(companyId).subscribe((data: any) => {
      data.objectsMonitoring.forEach(item => {
        if (this.selectedCompany.id === companyId) {
          switch (item.risk.level) {
            case 1:
              if (item.risk.hidden) {
                this.hiddenRisks.recommendation.push(item);
              } else {
                this.risks.recommendation.push(item);
              }
              break;
            case 2:
              if (item.risk.hidden) {
                this.hiddenRisks.possible.push(item);
              } else {
                this.risks.possible.push(item);
              }
              break;
            case 3:
              if (item.risk.hidden) {
                this.hiddenRisks.high.push(item);
              } else {
                this.risks.high.push(item);
              }
              break;
            case 4:
              if (item.risk.hidden) {
                this.hiddenRisks.critical.push(item);
              } else {
                this.risks.critical.push(item);
              }
              break;
          }
          if (item.risk.hidden) {
            this.hiddenRisksCount += 1;
          }
        }
      });
      if (this.objectsLoaded && this.loadingRisks) {
        this.uniteObjectsWithRisks();
      }
      this.risksLoaded = true;
    });
  }

  getObjectsWithoutMonitoring(companyId?: string): void {
    let query;
    if (companyId) {
      query = {
        path: 'objects',
        params: {
          organization: companyId
        }
      };
    } else {
      query = {
        path: 'objects',
      };
    }
    this.httpService.get(query).subscribe((data: any) => {
      if (data?.objects?.length) {
        const queryObjects = [];
        data.objects.forEach(item => {
          if (item.isMonitoring === false) {
            if (item.index && (this.trademarkIndexes.includes(item.index[0].id) || item.index[0].id === 'domains')) {
              this.objectsWithoutMonitoring.push(item);
              queryObjects.push({_id: item.id, _index: item.index[0].id});
            }
          } else {
            this.objectsWithMonitoring.push(item);
            this.objectsSelect.push({
              id: this.objectsSelect.length,
              value: item.name,
              checked: false,
              objectID: item.id
            });
          }
        });
        if (this.risksLoaded && this.loadingRisks) {
          this.uniteObjectsWithRisks();
        }
        const queryObj = {
          objects: queryObjects
        };
        this.monitoringService.getRisksByObject(queryObj).subscribe((dataRisks: any[]) => {
          this.objectsWithoutMonitoring.forEach(item => {
            item.risks = dataRisks.find(el => el._id === item.id)?.risk_levels;
          });
          this.loadingUnconnectedObjects = false;
        });
      } else {
        this.loadingUnconnectedObjects = false;
        this.loadingRisks = false;
      }
      this.objectsLoaded = true;
    });
  }

  uniteObjectsWithRisks(): void {
    this.allCount = null;
    this.risks.recommendation.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.hiddenRisks.recommendation.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.risks.possible.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.hiddenRisks.possible.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.risks.high.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.hiddenRisks.high.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.risks.critical.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.hiddenRisks.critical.forEach(item => {
      item.object = this.objectsWithMonitoring.find(el => el.id === item.id);
      item.riskData = this.risksData.find(el => el.type === +item.risk.type);
    });
    this.risksAll = JSON.parse(JSON.stringify(this.risks));
    this.loadingRisks = false;

    this.risksAllForTab = [];
    this.risksAllForTab.push(...this.risks.critical, ...this.risks.high, ...this.risks.possible, ...this.risks.recommendation);
    this.allCount = JSON.parse(JSON.stringify(this.risksAllForTab.length));
    this.highCount = JSON.parse(JSON.stringify(this.risks.high.length));
    this.criticalCount = JSON.parse(JSON.stringify(this.risks.critical.length));
    this.middleCount = JSON.parse(JSON.stringify(this.risks.possible.length));
    this.recommendedCount = JSON.parse(JSON.stringify(this.risks.recommendation.length));
  }

  switchTypeTab(type: string): void {
    this.typeTab = {
      critical: false,
      high: false,
      possible: false,
      recommendation: false,
      all: false,
    };
    switch (type) {
      case 'critical':
        this.typeTab.critical = true;
        break;
      case 'high':
        this.typeTab.high = true;
        break;
      case 'possible':
        this.typeTab.possible = true;
        break;
      case 'recommendation':
        this.typeTab.recommendation = true;
        break;
      case 'all':
        this.typeTab.all = true;
        break;
    }
    this.clearFilters();
  }

  // openModalFirst() {
  //   this.showModal1 = true;
  //   const bodyStyles = document.body.style;
  //   bodyStyles.setProperty('overflow', 'hidden');
  // }
  //
  // openModalSecond() {
  //   this.showModal2 = true;
  //   const bodyStyles = document.body.style;
  //   bodyStyles.setProperty('overflow', 'hidden');
  // }
  //
  // openModalThird() {
  //   this.showModal3 = true;
  //   const bodyStyles = document.body.style;
  //   bodyStyles.setProperty('overflow', 'hidden');
  // }
  //
  // openModalFourth() {
  //   this.showModal4 = true;
  //   const bodyStyles = document.body.style;
  //   bodyStyles.setProperty('overflow', 'hidden');
  // }

  toLeadForm(monitoringObject): void {
    const data = {
      formIdentity: 'Оспорить риск',
      objectId: monitoringObject.object?.id,
      objectIndex: monitoringObject.object?.index[0]?.id,
      riskId: monitoringObject.risk?.riskID
    };
    this.router.navigate(['/treaty-extension'], {queryParams: {data: JSON.stringify(data)}});
  }

  openModalRisk(data, isUnconnected = false): void {
    if (isUnconnected) {
      this.dataUnconnectedObject = data;
      this.showModalUnconnected = true;
    } else {
      this.dataRisk = data;
      this.showModalRisk = true;
    }
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'hidden');
  }

  closeModal(): void {
    // this.showModal1 = false;
    // this.showModal2 = false;
    // this.showModal3 = false;
    // this.showModal4 = false;
    this.showModalRisk = false;
    this.dataRisk = {};
    this.dataUnconnectedObject = {};
    this.showModalUnconnected = false;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  getCountCheckedObjects(massive: any[]): number {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });
    return counter;
  }

  cleanCheckedObj(massive: any[]): void {
    massive.forEach((obj) => {
      obj.checked = false;
    });
  }

  mobileOpenFirstCard(): void {
    if (window.innerWidth <= 793) {
      this.compositionRisks = !this.compositionRisks;
      window.addEventListener('resize', () => {
        if (window.innerWidth <= 793) {
          this.compositionRisks = !this.compositionRisks;
        }
      });
    }
  }

  mobileOpenSecondCard(): void {
    if (window.innerWidth <= 793) {
      this.objectsRisks = !this.objectsRisks;
      window.addEventListener('resize', () => {
        if (window.innerWidth <= 793) {
          this.objectsRisks = !this.objectsRisks;
        }
      });
    }
  }

  mobileOpenThirdCard(): void {
    if (window.innerWidth <= 793) {
      this.dynamicsRisks = !this.dynamicsRisks;
      window.addEventListener('resize', () => {
        if (window.innerWidth <= 793) {
          this.dynamicsRisks = !this.dynamicsRisks;
        }
      });
    }
  }

  makeBodyHidden(): void {
    if (window.innerWidth <= 793) {
      if (this.showMobileControl === true) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.height = '100%';
      } else {
        document.body.style.overflow = 'visible';
        document.body.style.position = '';
        document.body.style.height = 'auto';
      }
    }
  }

  addObject(event, obj): void {
    if (event.target.checked) {
      if (this.checkedObjects.findIndex(item => item.id === obj.id) === -1) {
        this.checkedObjects.push(obj);
        obj.checked = true;
      }
    } else {
      this.checkedObjects.splice(this.checkedObjects.findIndex(item => item.id === obj.id), 1);
      obj.checked = false;
    }
  }

  isChecked(obj): boolean {
    return this.checkedObjects.findIndex(item => item.id === obj.id) !== -1;
  }

  getCountNoMonitoringRisk(): number {
    let sum = 0;
    if (this.objectsWithoutMonitoring.length && this.objectsWithoutMonitoring[0]?.risks && this.objectsWithMonitoring.length) {
      this.objectsWithoutMonitoring.forEach(item => {
        item.risks.forEach(el => {
          if (el.risk !== 0 && el.risk !== 1) {
            sum = sum + el.values;
          }
        });
      });
    }
    return sum;
  }

  getCountTypeObjects(type: string): number {
    if (type === 'trademark') {
      return this.objectsWithMonitoring.filter(item => this.trademarkIndexes.includes(item.index[0].id)).length;
    } else {
      return this.objectsWithMonitoring.filter(item => item.index[0].id === 'domains').length;
    }
  }

  getTypeTab(): string {
    let res = '';
    Object.keys(this.typeTab).forEach(item => {
      if (this.typeTab[item] === true) {
        res = item;
      }
    });
    return res;
  }

  checkRisksByTab(massiveName: 'risks' | 'risksAll'): number {
    const type = this.getTypeTab();
    if (type === 'all') {
      return this[massiveName].critical.length + this[massiveName].high.length + this[massiveName].possible.length + this[massiveName].recommendation.length;
    } else {
      return this[massiveName][type].length;
    }
  }

  test(item) {
    console.log(999999, item);
  }

  applyFilters(): void {
    this.loadingRisks = true;
    this.risks = JSON.parse(JSON.stringify(this.risksAll));
    this.filterByText();
    this.filterByType();
    this.filterByName();
    this.risksAllForTab = [];
    this.risksAllForTab.push(...this.risks.critical, ...this.risks.high, ...this.risks.possible, ...this.risks.recommendation);
  }

  clearFilters(): void {
    this.risks = JSON.parse(JSON.stringify(this.risksAll));
    this.searchText = '';
    this.typeOfObjects.forEach(item => {
      item.checked = false;
    });
    this.objectsSelect.forEach(item => {
      item.checked = false;
    });
  }

  filterByText(): void {
    if (this.searchText) {
      this.risks.critical = this.risks.critical.filter(item => item.risk.description.toLowerCase().includes(this.searchText.toLowerCase()) || item.name.toLowerCase().includes(this.searchText.toLowerCase()));
      this.risks.high = this.risks.high.filter(item => item.risk.description.toLowerCase().includes(this.searchText.toLowerCase()) || item.name.toLowerCase().includes(this.searchText.toLowerCase()));
      this.risks.possible = this.risks.possible.filter(item => item.risk.description.toLowerCase().includes(this.searchText.toLowerCase()) || item.name.toLowerCase().includes(this.searchText.toLowerCase()));
      this.risks.recommendation = this.risks.recommendation.filter(item => item.risk.description.toLowerCase().includes(this.searchText.toLowerCase()) || item.name.toLowerCase().includes(this.searchText.toLowerCase()));
    }
  }

  filterByType(): void {
    const indexes = [];
    if (this.typeOfObjects[0].checked) {
      indexes.push('rupat');
    }
    if (this.typeOfObjects[1].checked) {
      indexes.push(...this.trademarkIndexes);
    }
    if (this.typeOfObjects[2].checked) {
      indexes.push('domains');
    }

    if (indexes.length) {
      this.risks.critical = this.risks.critical.filter(item => indexes.includes(item.object.index[0].id));
      this.risks.high = this.risks.high.filter(item => indexes.includes(item.object.index[0].id));
      this.risks.possible = this.risks.possible.filter(item => indexes.includes(item.object.index[0].id));
      this.risks.recommendation = this.risks.recommendation.filter(item => indexes.includes(item.object.index[0].id));
    }
  }

  filterByName(): void {
    const objects = [];
    this.objectsSelect.forEach(obj => {
      if (obj.checked) {
        objects.push(obj.objectID);
      }
    });
    if (objects.length) {
      this.risks.critical = this.risks.critical.filter(item => objects.includes(item.object.id));
      this.risks.high = this.risks.high.filter(item => objects.includes(item.object.id));
      this.risks.possible = this.risks.possible.filter(item => objects.includes(item.object.id));
      this.risks.recommendation = this.risks.recommendation.filter(item => objects.includes(item.object.id));
    }
    this.loadingRisks = false;
  }

  checkHiddenRisk(risk): boolean {
    if (this.showHiddenRisks) {
      return true;
    } else {
      return !risk.hidden;
    }
  }

  viewHiddenRisks(): void {
    this.clearFilters();
    this.showHiddenRisks = true;
    this.risks.critical.push(...this.hiddenRisks.critical);
    this.risks.high.push(...this.hiddenRisks.high);
    this.risks.possible.push(...this.hiddenRisks.possible);
    this.risks.recommendation.push(...this.hiddenRisks.recommendation);
    this.risksAll = JSON.parse(JSON.stringify(this.risks));
    this.hiddenRisks = {
      critical: [],
      high: [],
      possible: [],
      recommendation: []
    };
    this.hiddenRisksCount = 0;
    this.risksAllForTab = [...this.risks.critical, ...this.risks.high, ...this.risks.possible, ...this.risks.recommendation];
    this.pages = {
      critical: 1,
      high: 1,
      possible: 1,
      recommendation: 1,
      all: 1,
    };
  }

  makeRiskHidden(event): void {
    const query = {
      risks: [{
        id: event.riskId
      }]
    };
    this.monitoringService.setHiddenRisk(query).subscribe(() => {
      this.closeModal();
      this.clearTemplates();
      this.initComponent(this.selectedCompany.id);
      this.attention = true;
    });
  }

  changePage(page: number, type: string): void {
    this.pages[type] = page;
  }

  selectUnconnectedObject(data): void {
    this.addObject({target: {checked: true}}, data);
    this.closeModal();
  }

  getCountRisksByLevel(level: number, object): number {
    const res = object.risks.find(item => item.risk === level);
    if (res && res.values) {
      return res.values;
    } else {
      return 0;
    }
  }

  onAddObjects(): void {
    this.monitoringAddService.createRequest({
      objects: this.checkedObjects.map(obj => ({
        ID: obj.id,
        name: obj.name,
        type: obj.type,
        index: obj.index.map(i => i.id),
      })),
      organization: this.selectedCompany.id,
      interestData: this.interestService.addInterestData()
    });
  }

  scrollTo(className: string): void {
    const elementList = document.querySelectorAll('.' + className);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({behavior: 'smooth'});
  }

  checkAppliedFilters(): boolean {
    return this.searchText || this.getCountCheckedObjects(this.typeOfObjects) || this.getCountCheckedObjects(this.objectsSelect);
  }

  getColorByLevel(level: number): string {
    switch (level) {
      case 1:
        return '--green';
      case 2:
        return '--yellow';
      case 3:
        return '--orange';
      case 4:
        return '';
    }
  }

  onSelect(comp): void {
    this.selectedCompany = comp;
    this.showCompanyList = false;
    this.clearTemplates();
    this.initComponent(this.selectedCompany.id);
  }

  clearTemplates(): void {
    this.allCount = null;
    this.objectsWithoutMonitoring = [];
    this.objectsWithMonitoring = [];
    this.risks = {
      critical: [],
      high: [],
      possible: [],
      recommendation: []
    };
    this.hiddenRisks = {
      critical: [],
      high: [],
      possible: [],
      recommendation: []
    };

    this.objectsLoaded = false;
    this.loadingRisks = true;
    this.risksLoaded = false;
    this.loadingUnconnectedObjects = true;
    this.checkedObjects = [];
    this.risksAllForTab = [];
    this.risksAll = {
      critical: [],
      high: [],
      possible: [],
      recommendation: []
    };
    this.hiddenRisksCount = 0;
  }

  getSrcByIndex(index: string, isRisk?: boolean, imgUrl?: string): string {
    if (index === 'domains') {
      return 'assets/portfolio/domains.svg';
    } else {
      return isRisk ? imgUrl : './assets/images/icon-doc.svg';
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  hideShowCompanyList() {
    if (this.corporate) {
      this.showCompanyList = !this.showCompanyList;
    }
  }

  private initOrganizations(organizations: OrganizationInterface[]): void {
    this.kontragents = [...organizations];
    this.kontragents.unshift({ name: 'Все организации' } as OrganizationInterface);

    // TODO: Перенес из старого варианта, переработать при рефакторинге
    if (organizations.length) {
      this.route.queryParams
        .pipe(takeUntil(this.onDestroy))
        .subscribe((params: Params) => {
          const selectedCompanyId = params?.selectedCompanyId;
          if (selectedCompanyId) {
            this.selectedCompany = this.kontragents.find(k => k.id === selectedCompanyId);
          } else {
            this.selectedCompany = this.kontragents.length === 2 ? this.kontragents[1] : this.kontragents[0];
          }
  
          this.initComponent(this.selectedCompany.id);
        });
    }
  }
}
