import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessorComponent } from '../abstract-value-accessor/abstract-value-accessor.component';
import { IFilterOption } from '@ui/filters/models/filters.interface';

@Component({
  selector: 'app-ui-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectorComponent),
      multi: true,
    }
  ]
})
export class SelectorComponent extends AbstractValueAccessorComponent {

  // по умолчанию имя элемента рандомное
  private arr = new Uint32Array(10);
  @Input() name: string = window.crypto.getRandomValues(this.arr)[1].toString();

  set value(value: unknown[]) {
    if (value && value instanceof Array) {
      this.checked = new Set(value);
    } else {
      this.checked = new Set();
    }
    this.onChange(value);
    this.onTouch();
    this.counter = this.checked.size;
  }

  @Input() placeholder = '';

  @Input() options: IFilterOption[];

  isOptionsShown = false;

  counter = 0;

  checked = new Set<string | number>();

  update(id: number | string) {
    if (!this.checked.has(id)) {
      this.checked.add(id);
    } else {
      this.checked.delete(id);
    }
    this.value = [...this.checked];
  }

  isChecked(id: number | string): boolean {
    return this.checked.has(id);
  }

  uniqueOptionName(option) {
    return 'filter-select__' + this.name + '__' + option.id;
  }
}
