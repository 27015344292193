<header class="header header--centered">
  <a class="header__logo" href="#">
    <img [src]="logoPath" style="max-height: 30px; height: 100%; max-width: none">
  </a>
</header>

<div *ngIf="default" class="page-wrapper">
  <main>
    <section class="form-section">
      <div class="container-sm">
        <h1 class="heading-h1 heading-h1--centered">Восстановить пароль</h1>
        <h3 class="heading-h2 heading-h2--centered h2_new">Введите адрес электронной почты, указанный в вашем
          аккаунте</h3>
        <form [formGroup]="recoveryForm" class="form">
          <div class="form__group">
            <div class="form__input-wrap">
              <input formControlName="email"
                     class="form__input field"
                     type="search"
                     [ngClass]="{ 'error': recoveryForm.get('email').invalid && recoveryForm.get('email').touched }"
                     email
                     required>
              <span class="form__input-label">Адрес электронной почты</span>
            </div>
          </div>
          <div class="form__footer">
            <button *ngIf="!showLoader"
                    (click)="getMail()"
                    class="btn btn__auth btn--full"
                    [disabled]="recoveryForm.invalid || recoveryForm.disabled"
                    type="button">Восстановить
            </button>
            <button *ngIf="showLoader"
                    class="btn btn__auth btn--full"
                    type="button">
              <span class="loaderAuth"></span>
            </button>
          </div>
        </form>
        <p class="form-section__account" [routerLink]="['/login']">
          <a class="bordered-blue">Вернуться к авторизации</a>
        </p>
        <a class="bordered-blue support-link" (click)="openModalSupport()">Обращение в техническую поддержку</a>
      </div>
      <app-support-modal
        *ngIf="showModalSupport"
        [showModal]="showModalSupport"
        (closeModalSupportPopup)="closeModalSupport()">>
      </app-support-modal>
    </section>
  </main>

  <footer class="footer footer--centered">
    <p class="footer__lisence-info">{{footerText}}</p>
  </footer>
</div>

<!--success screen-->
<div *ngIf="success" class="page-wrapper">
  <main>
    <section class="form-section">
      <div class="container-sm">
        <h1 class="heading-h1 heading-h1--centered">Успешно</h1>
        <h3 *ngIf="!restoreSuccess" class="heading-h2 heading-h2--centered h2_new">Инструкция отправлена на вашу
          почту.</h3>
        <h3 *ngIf="restoreSuccess" class="heading-h2 heading-h2--centered h2_new">Восстановление пароля прошло
          успешно.</h3>
      </div>
    </section>
  </main>
  <p class="form-section__account" [routerLink]="['/login']">
    <a class="bordered-blue">Вернуться к авторизации</a>
  </p>

  <footer class="footer footer--centered">
    <p class="footer__lisence-info">{{footerText}}</p>
  </footer>
</div>

<!--error screen-->
<div *ngIf="error" class="page-wrapper">
  <main>
    <section class="form-section">
      <div class="container-sm">
        <h1 class="heading-h1 heading-h1--centered">Email не найден</h1>
        <h3 class="heading-h2 heading-h2--centered h2_new error_label">Пользователь с таким email не найден</h3>
        <div class="form__footer">
          <button
            (click)="retryRecovery()"
            class="btn btn--full"
            type="button">Повторить
          </button>
        </div>
      </div>

    </section>
  </main>

  <footer class="footer footer--centered">
    <p class="footer__lisence-info">{{footerText}}</p>
  </footer>
</div>

<!--new password screen-->
<div *ngIf="passwordSC" class="page-wrapper">
  <main>
    <section class="form-section">
      <div class="container-sm">
        <h1 class="heading-h1 heading-h1--centered">Новый пароль</h1>
        <div class="error_container">
          <div *ngIf="recoverError" class="error_message">{{recoverError}}</div>
        </div>
        <form [formGroup]="recoverPass" class="form">
          <div class="form__group">
            <div class="form__input-wrap">
              <input formControlName="password"
                     class="form__input field"
                     type="password"
                     [ngClass]="{ 'error': recoverPass.get('password').invalid && recoverPass.get('password').touched }"
                     required>
              <span class="form__input-label">Введите новый пароль</span>
            </div>
          </div>
          <div class="form__group">
            <div class="form__input-wrap">
              <input formControlName="confirmPassword"
                     class="form__input form__input--linked field"
                     type="password"
                     (keyup)="comparePasswords()"
                     [ngClass]="{ 'error': recoverPass.get('confirmPassword').invalid && recoverPass.get('confirmPassword').touched && !passwordMatcher }"
                     required>
              <span class="form__input-label">Подтвердите пароль</span>
            </div>
          </div>
          <div *ngIf="!passwordMatch" class="error_container_pass">
            <span class="error_label">Пароли не совпадают.</span>
          </div>
          <div *ngIf="recoverPass.get('password').invalid && recoverPass.get('password').touched"
               class="error_container_pass">
            <span class="error_label">Пароль слишком простой, используйте минимум 6 символов, большие и маленькие буквы, добавьте цифры</span>
          </div>
          <div class="form__footer">
            <button *ngIf="!showLoader"
                    (click)="resetPassword()"
                    class="btn btn--full"
                    type="button">Сохранить
            </button>
            <button *ngIf="showLoader"
                    class="btn btn--full"
                    type="button">
              <span class="loaderAuth"></span>
            </button>
          </div>
        </form>
      </div>
    </section>
  </main>
  <footer class="footer footer--centered">
    <p class="footer__lisence-info">{{footerText}}</p>
  </footer>
</div>
