import { ICorpDataSource, ICorpDataSourceType } from '../interaces/corp-data-source.interface';
import { Injectable } from '@angular/core';
import { CorpSearchApiService } from './corp-search-api.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { IntercomEventsService } from '../../../../../shared/services/intercom-events.service';
import { Store } from '@ngxs/store';
import { CORP_SEARCH_TOKEN } from '@web/features/corp/corp-search/states/search.state';

@Injectable({
  providedIn: 'root'
})
export class CorpSearchSaveReportService {

  searchID: any = null;
  selectedItems: ICorpDataSource = {};
  addReportLoading = false;

  constructor(
    private readonly corpSearchApiService: CorpSearchApiService,
    private readonly toastrService: ToastrService,
    readonly intercomEventsService: IntercomEventsService,
    private store: Store,
  ) {
  }

  setSearchID(searchID: string) {
    this.searchID = searchID;
  }

  onSelect(type: ICorpDataSourceType, items: any[]) {
    this.selectedItems = {
      ...this.selectedItems,
      [type]: items
    };
  }

  addSelectedToReport() {
    if (!this.countSelected) {
      return this.toastrService.error('Необходимо выбрать результаты для сохранения в отчет');
    }

    if (this.addReportLoading) {
      return;
    }

    this.addReportLoading = true;
    this.corpSearchApiService.addSearchItemsToReport(this.searchID, this.allSelectedItems)
      .pipe(
        finalize(() => this.addReportLoading = false)
      )
      .subscribe((resp) => {
        this.toastrService.success('Добавление в отчет прошло успешно');
      });
    this.intercomEventsService.push({event: 'add_item_to_report', item_count: this.countSelected});
  }


  get countSelected() {
    return (this.selectedItems?.legalNames?.length ?? 0) + (this.selectedItems?.domains?.length ?? 0) + (this.selectedItems?.tradeMarks?.length ?? 0);
  }

  private get allSelectedItems() {
    return {
      tradeMarks: (this.selectedItems?.tradeMarks ?? [])
        .map(i => {
          if (this.store.selectSnapshot(CORP_SEARCH_TOKEN).payload.text
            || this.store.selectSnapshot(CORP_SEARCH_TOKEN).payload.searchNumber
            || this.store.selectSnapshot(CORP_SEARCH_TOKEN).payload.searchLegalId) {

            // isLoadingHistory - костыль для добавления в отчет после возвращения из истории поиска

            return this.store.selectSnapshot(CORP_SEARCH_TOKEN).isLoadingHistory
              ? i.__indexNumber + 1
              : i.__indexNumber + 1 + this.store.selectSnapshot(CORP_SEARCH_TOKEN).tradeMarksPagination.from;
          } else {
            return this.store.selectSnapshot(CORP_SEARCH_TOKEN).isLoadingHistory
              ? i.__indexNumber + 1
              : i.__indexNumber + 1 + this.store.selectSnapshot(CORP_SEARCH_TOKEN).payload.from;
          }
        }),
      legalNames: (this.selectedItems?.legalNames ?? [])
        .map(i => this.store.selectSnapshot(CORP_SEARCH_TOKEN).isLoadingHistory
          ? i.__indexNumber + 1
          : i.__indexNumber + 1 + this.store.selectSnapshot(CORP_SEARCH_TOKEN).legalNamesPagination.from),
      domains: (this.selectedItems?.domains ?? [])
        .map(i => this.store.selectSnapshot(CORP_SEARCH_TOKEN).isLoadingHistory
          ? i.__indexNumber + 1
          : i.__indexNumber + 1 + this.store.selectSnapshot(CORP_SEARCH_TOKEN).tradeMarksPagination.from),
    };
  }

  reset() {
    this.selectedItems = {};
    this.searchID = null;
  }

}
