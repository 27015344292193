<div class="personal-data" >
  <h1 class="heading-h1" *ngIf="header">Номер телефона</h1>

  <div class="personal-data__success success" *ngIf="showSuccess">
    <div class="success__title-wrap">
      <h3>Телефон успешно добавлен</h3>
      <button (click)="removeSuccess()" aria-label="Закрыть"></button>
    </div>
    
    <p *ngIf="successText" class="success__desc">
      Благодарим за предоставление вашего контактного номера телефона. Вы можете выбрать тип заявки для подачи на регистрацию ниже.
    </p>
  </div>

  <ng-container *ngIf="!showSuccess && !user?.phone">
    <p class="personal-data__title">
      Для подачи заявки, пожалуйста, укажите свой контактный номер телефона
    </p>

    <form [formGroup]="form" ngxsForm="userPhone.form">
      <div class="personal-data__form" [class.shake]="animationStatus">
        <div class="personal-data__input-wrap">
          <input
            required
            type="tel"
            id="phone"
            formControlName="phone"
            class="field personal-data__input"
            [ngClass]="{'error_validation': codeError}"
          />
          <label class="personal-data__label" for="phone">Телефон</label>
        </div>

        <div class="personal-data__btn-wrap" [class.personal-data__btn-wrap--grow]="codeSended">
          <ng-container *ngIf="!codeSended; else code">
            <button
              type="button"
              [disabled]="!this.form.get('phone').value?.length"
              (click)="sendUserPhoneCode()"
              class="btn personal-data__button"
            >Получить код</button>
          </ng-container>

          <ng-template #code>
            <div class="personal-data__input-wrap personal-data__input-wrap--code">
              <input
                required
                id="code"
                type="text"
                formControlName="code"
                (keyup)="checkUserPhoneCode()"
                [ngClass]="{
                  'successCode': !codeError && form.controls.code.dirty && form.controls.code.value.length > 4,
                  'errorCode': codeError && form.controls.code.dirty
                }"
                class="field personal-data__input personal-data__input--code"
              >
              <label class="personal-data__label" for="code">Ввести код</label>
            </div>
          </ng-template>
        </div>
      </div>

      <div *ngIf="phoneError && !codeSended" class="personal-data__error-wrap">
        <p class="personal-data__error-text">{{phoneError}}</p>
      </div>

      <div class="personal-data__desc-wrap" *ngIf="codeSended">
        <div class="personal-data__desc">
          <div>
            <p *ngIf="count > 1 && !codeError">Код отправлен на указанный телефон. Повторно выслать код можно через {{counter$ | async}} сек.</p>
            <span *ngIf="codeError" class="personal-data__error-text">Код введен неправильно.</span>
            <a *ngIf="count <= 1" (click)="repeatSendUserPhoneCode()">Выслать код повторно</a>
          </div>

          <div>
            <a (click)="resetPhoneForm()" class="">Изменить номер телефона</a>
          </div>
        </div>
      </div>

    </form>
  </ng-container>
</div>
