import { Injectable } from '@angular/core';

/**
 * @class
 * @name TinHelperService
 * @classdesc - Сервис для валидации номеров ИНН и ОГРН в приложении.
 */
@Injectable()
export class TinHelperService {
  /**
   * @property {RegExp} PATTERN_TIN - Регулярное выражение для валидации ИНН и ОГРН.
   */
  public static readonly PATTERN_TIN = /^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$/g;

  /**
   * Метод проверки ИНН или ОГРН на выбор.
   * 
   * @public
   * @param {string} tin - ИНН или ОГРН на выбор.
   * @returns {boolean}
   */
  public checkTIN(tin: string): boolean {
    return TinHelperService.PATTERN_TIN.test(tin) && (this.validateInn(tin) || this.validateOgrn(tin));
  }

  /**
   * Метод валидации ИНН.
   * 
   * @Public
   * @param {string} inn - ИНН для валидации.
   * @returns {boolean}
   */
  public validateInn(inn: string): boolean {
    if (inn.length === 10) {
      return this.check(inn) === Number.parseInt(inn.slice(-1), 10);
    }
    if (inn.length === 12) {
      return (
        this.check(inn.slice(0, 11)) === Number.parseInt(inn.slice(10, -1), 10) &&
        this.check(inn) === Number.parseInt(inn.slice(-1), 10)
      );
    }
    return false;
  }

    /**
   * Метод валидации ОГРН.
   * 
   * @Public
   * @param {string} ogrn - ОГРН для валидации.
   * @returns {boolean}
   */
  public validateOgrn(ogrn: string): boolean {
    if (ogrn) {
      const n13 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 11).toString().slice(-1), 10);
      const n15 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 13).toString().slice(-1), 10);
      return n13 === parseInt(ogrn[12], 10) || n15 === parseInt(ogrn[14], 10);
    }
  }

  private check(num: string): number {
    const n = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0].slice(-num.length);
    let summ: number = 0;
    for (let i = 0; i < String(num).length; i++) {
      summ += Number.parseInt(num[i], 10) * n[i];
    }
    let control = summ % 11;
    if (control > 9) {
      control = control % 10;
    }
    return control;
  }
}
