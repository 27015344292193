import { Directive, Input } from '@angular/core';
import { getProp } from '../../../utils/object-utils';
import { ILvFilter } from '../lv-filter/lv-filter.interface';

@Directive({
  selector: '[lvFilterMultiselect]',
})
export class LvFilterMultiselectDirective implements ILvFilter {
  readonly type = 'multiselect';

  @Input() options: IOption[];
  @Input() value: any[];
  @Input() icon: string;
  @Input() title: string;

  @Input() filterFn: (row: any) => boolean;
  @Input() filterField: string;

  constructor() {
  }

  private defaultFilterRow = (row: any) => {
    if (!this.value?.length) {
      return true;
    }

    const val = getProp(row, this.filterField);
    console.log(val, 'vaaal');
    return this.value.includes(val);
  };

  getFilterFn() {
    if (this.filterFn) {
      return this.filterFn;
    } else if (this.filterField) {
      return this.defaultFilterRow;
    } else {
      throw new Error('filterField or filterFn required in "LvFilterMultiselectDirective"');
    }
  }

}

export interface IOption {
  id: any;
  label: string;
}
