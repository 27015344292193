import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-common-info',
  templateUrl: './tab-common-info.component.html',
  styleUrls: ['./tab-common-info.component.scss']
})
export class TabCommonInfoComponent implements OnInit {
  showServicesList = false;

  constructor() { }

  ngOnInit(): void {
  }


}
