import { TrademarkState } from '../../store/trademark.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import {Component, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {Dispatch} from '@ngxs-labs/dispatch-decorator';
import {PaymentActions} from '@ui/features/payment/store/payment.actions';

@Component({
  selector: 'app-trademark-step',
  templateUrl: './trademark-step.component.html',
  styleUrls: ['./trademark-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkStepComponent implements OnDestroy{
  @ViewSelectSnapshot(TrademarkState.step) step: number;
  @ViewSelectSnapshot(TrademarkState.loading) loading: boolean;

  @Dispatch() clearPaymentState = () => new PaymentActions.ClearPaymentState();

  public ngOnDestroy(): void {
    this.clearPaymentState();
  }
}
