import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RequestContractService} from '../shared/request-contract.service';
import areaSubject from './json-data-territorial-subjects';
import {HttpService} from '../../../shared/services/http.service';
import {FormValidationService} from '../../../shared/services/form-validation.service';
import {ConditionsService} from './conditions.service';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss'],
  providers: [ConditionsService]
})
export class ConditionsComponent implements OnInit {

  @Input() contractType;
  @Output() type = new EventEmitter(); // событие переключения способа оплаты

  subject = false; // выбор кнопки Субъект (Территория действия)
  territorialSubject = areaSubject; // Субъекты

  showOptionsNDS = false; // выбор из списка НДС (Сумма вознаграждения)
  NDS = [
    {
    name: 'Не включая НДС',
    value: '0',
    },
    {
      name: 'С НДС 20%',
      value: '20',
    },
    {
      name: 'С НДС 10%',
      value: '10',
    }]; // НДС

  selectedNDS = this.NDS[0].name; // выбранный НДС

  showOptionsCurrency = false; // выбор из списка валют (Сумма вознаграждения)
  currency = ['RUB', 'USD', 'EUR', 'AUD', 'KRW', 'KZT', 'CAD', 'KGS', 'SGD']; // Валюты
  selectedCurrency = this.currency[0]; // выбранная валюта

  mobileTextarea1100 = true;
  mobileTextarea400 = true;

  files = [];
  fileName;
  fileError = '';
  docBase64;
  fileLinks = [];
  onlyNumbersPattern = /^-?(0|[1-9]\d*)?$/;

  conditionsForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private requestContractService: RequestContractService,
    private httpService: HttpService,
    private validationService: FormValidationService,
    readonly conditionsService: ConditionsService,
  ) {
  }

  ngOnInit(): void {
    this.type.emit(this.contractType);
    this.contractType = this.requestContractService.contractType;
    this.initForm();
    if (!!this.requestContractService.subject) {
      this.setForm();
    }
    this.mobileTextarea1100 = window.innerWidth < 1100;
    this.mobileTextarea400 = window.innerWidth < 400;
    window.addEventListener('resize', () => {
      this.mobileTextarea1100 = window.innerWidth < 1100;
    });
    this.setValue();
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  onSelectNDS(comp): void {
    this.selectedNDS = comp;
    this.showOptionsNDS = false;
    this.setValue();
  }

  onSelectCurrency(comp): void {
    this.selectedCurrency = comp;
    this.showOptionsCurrency = false;
    this.setValue();
  }

  initForm(): void {
    this.conditionsForm = this.fb.group({
      kind: new FormControl(this.contractType),
      exclusivity: new FormControl(''),
      territorialLimits : new FormControl(''),
      territorialSubject: [],
      territorialLimitsString: new FormControl(''),
      periodType: new FormControl(''),
      periodMonths: new FormControl('', Validators.pattern(this.validationService.onlyNumbersPattern)),
      periodYears: new FormControl('', Validators.pattern(this.validationService.onlyNumbersPattern)),
      periodDate: new FormControl(''),
      reward: new FormGroup({
        amountOfCompensation: new FormControl('', Validators.pattern(this.validationService.onlyNumbersPattern)),
        currencyType: new FormControl(this.selectedCurrency),
        VAT: new FormControl(''),
      }),
      mannerOfPayment: new FormControl(''),
      mannerOfPaymentString: new FormControl(''),
      paymentStart: new FormControl(''),
      paymentStartString: new FormControl(''),
      subconcessionRight: new FormControl(''),
      subconcessionRightString: new FormControl(''),
      complex: new FormControl(''),
      goodsAndServices: new FormControl(''),
      goodsAndServicesString: new FormControl(''),
      fieldsOfApplication: new FormGroup({
        goods: new FormControl(false),
        services: new FormControl(false),
        documentation: new FormControl(false),
        advertising: new FormControl(false),
        internet: new FormControl(false),
        textOfApplication: new FormControl(''),
      }),
      misc: new FormControl(''),
      file: new FormControl(''),
    });
  }

  setForm(): void {
    this.conditionsForm = this.fb.group({
      kind: new FormControl(this.contractType),
      exclusivity: new FormControl(this.requestContractService.subject.exclusivity),
      territorialLimits : new FormControl(this.requestContractService.subject.territorialLimits),
      territorialSubject: this.setAreaObjects(),
      territorialLimitsString: new FormControl(this.requestContractService.subject.territorialLimitsString),
      periodType: new FormControl(this.requestContractService.subject.periodType),
      periodMonths: new FormControl(this.requestContractService.subject.periodMonths),
      periodYears: new FormControl(this.requestContractService.subject.periodYears),
      periodDate: new FormControl(this.requestContractService.subject.periodDate),
      reward: new FormGroup({
        amountOfCompensation: new FormControl(this.requestContractService.subject.amountOfCompensation),
        currencyType: new FormControl(this.selectedCurrency),
        VAT: new FormControl(this.conditionsService.setNDS(this.selectedNDS)),
      }),
      mannerOfPayment: new FormControl(this.requestContractService.subject.mannerOfPayment),
      mannerOfPaymentString: new FormControl(this.requestContractService.subject.mannerOfPaymentString),
      paymentStart: new FormControl(this.requestContractService.subject.paymentStart),
      paymentStartString: new FormControl(this.requestContractService.subject.paymentStartString),
      subconcessionRight: new FormControl(this.requestContractService.subject.subconcessionRight),
      subconcessionRightString: new FormControl(this.requestContractService.subject.subconcessionRightString),
      complex: new FormControl(this.requestContractService.subject.complex),
      goodsAndServices: new FormControl(this.requestContractService.subject.goodsAndServices),
      goodsAndServicesString: new FormControl(this.requestContractService.subject.goodsAndServicesString),
      fieldsOfApplication: new FormGroup({
        goods: new FormControl(this.requestContractService.subject.fieldsOfApplication?.goods),
        services: new FormControl(this.requestContractService.subject.fieldsOfApplication?.services),
        documentation: new FormControl(this.requestContractService.subject.fieldsOfApplication?.documentation),
        advertising: new FormControl(this.requestContractService.subject.fieldsOfApplication?.advertising),
        internet: new FormControl(this.requestContractService.subject.fieldsOfApplication?.internet),
        textOfApplication: new FormControl(this.requestContractService.subject.fieldsOfApplication?.textOfApplication),
      }),
      misc: new FormControl(this.requestContractService.subject.misc),
      file: new FormControl(this.setFiles()),
    });
  }

  getCountChecked(massive) {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });

    return counter;
  }

  submit() {
    this.conditionsForm.value.areaSubjects = this.setAreaObjects();
    this.conditionsForm.value.file = this.docBase64;
  }

  subjectText() {
    if (this.getCountChecked(this.territorialSubject) === 0) {
      return 'Выберите субъект';
    } else if (this.getCountChecked(this.territorialSubject) > 1) {
      return this.getCountChecked(this.territorialSubject);
    } else if (this.getCountChecked(this.territorialSubject) === 1) {
      return this.territorialSubject.find(item => item.checked).value;
    }
  }

  setValue(): void {
    this.requestContractService.contractType = this.conditionsForm.value.kind;
    this.requestContractService.subject = {
      exclusivity: this.conditionsForm.value.exclusivity,
      territorialLimits : this.conditionsForm.value.territorialLimits ,
      territorialSubject : this.setAreaObjects(),
      territorialLimitsString: this.conditionsForm.value.territorialLimitsString,
      periodType: this.conditionsForm.value.periodType,
      periodMonths: this.conditionsForm.value.periodMonths,
      periodYears: this.conditionsForm.value.periodYears,
      periodDate: this.conditionsForm.value.periodDate,
      amountOfCompensation: this.conditionsForm.value.reward.amountOfCompensation,
      currencyType: this.selectedCurrency,
      VAT: this.conditionsService.setNDS(this.selectedNDS),
      mannerOfPayment: this.conditionsForm.value.mannerOfPayment,
      mannerOfPaymentString: this.conditionsForm.value.mannerOfPaymentString,
      paymentStart: this.conditionsForm.value.paymentStart,
      paymentStartString: this.conditionsForm.value.paymentStartString,
      subconcessionRight: this.conditionsForm.value.subconcessionRight,
      subconcessionRightString: this.conditionsForm.value.subconcessionRightString,
      complex: this.conditionsForm.value.complex,
      goodsAndServices: this.conditionsForm.value.goodsAndServices,
      goodsAndServicesString: this.conditionsForm.value.goodsAndServicesString,
      fieldsOfApplication: {
        goods: this.conditionsForm.value.fieldsOfApplication.goods,
        services: this.conditionsForm.value.fieldsOfApplication.services,
        documentation: this.conditionsForm.value.fieldsOfApplication.documentation,
        advertising: this.conditionsForm.value.fieldsOfApplication.advertising,
        internet: this.conditionsForm.value.fieldsOfApplication.internet,
        textOfApplication: this.conditionsForm.value.fieldsOfApplication.textOfApplication,
      },
      misc: this.conditionsForm.value.misc,
      files: this.fileLinks,
    };
    this.conditionsForm.get('periodType').valueChanges.subscribe(type => this.setPeriod(type));
    this.requestContractService.validation = false;
  }

  get validation(): boolean {
    return this.requestContractService.validation;
  }

  get isLoading() {
    return this.requestContractService.isLoading;
  }

  get contractAction() {
    return this.requestContractService.contractAction;
  }

  /**
   * Устанавливаем количество субъектов
   */
  setAreaObjects(): Array<string> {
    const subjects = [];
    if (this.conditionsForm.value.territorialLimits === 'russia'
      || this.conditionsForm.value.territorialLimits === 'other' || this.conditionsForm.value.territorialLimits === 'noLimits') {
      this.territorialSubject.map(item => item.checked = false);
      this.subjectText();
      return subjects;
    } else {
      this.territorialSubject.filter(item => {
        if (item.checked) {
          subjects.push(item.value);
        }
      });
      return subjects;
    }
  }

  /**
   * Устанавливаем значение срока действия
   */
  setPeriod(type: string) {
    if (type === 'months') {
      this.conditionsForm.get('periodYears').setValue('');
      this.conditionsForm.get('periodDate').setValue('');
      return this.conditionsForm.value.periodMonths;
    } else if (type === 'years') {
      this.conditionsForm.get('periodMonths').setValue('');
      this.conditionsForm.get('periodDate').setValue('');
      return this.conditionsForm.value.periodMonths;
    } else if (type === 'date') {
      this.conditionsForm.get('periodMonths').setValue('');
      this.conditionsForm.get('periodYears').setValue('');
      return this.conditionsForm.value.periodMonths;
    }
  }

  /**
   * Сохранение загруженных файлов при возвращении со следующего шага
   */
  setFiles() {
    if (this.requestContractService.files.length > 0) {
      this.files = this.requestContractService.files;
      return this.files;
    } else {
      return this.files;
    }
  }
}
