import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-warning-with-list',
  templateUrl: './warning-with-list.component.html',
  styleUrls: ['./warning-with-list.component.scss']
})
export class WarningWithListComponent implements OnInit {
  showServicesList = false;
  @Input() type: string;
  @Input() backgroundWhite: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
