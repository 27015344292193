import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CasesService } from '../../cases/cases.service';
import { LayoutService } from '../../../shared/services/layout.service';

interface ICases {
  draft?: any[];
  active?: any[];
  finished?: any[];
}

@Component({
  selector: 'app-open-cases',
  templateUrl: './open-cases.component.html',
  styleUrls: ['./open-cases.component.scss']
})
export class OpenCasesComponent implements OnInit {
  private readonly onDestroy = new Subject<void>();

  caseTab = 'cases';
  isLoadingCases = false;

  // Все дела полученные от API
  immutableCases: ICases = {
    draft: [],
    active: [],
    finished: []
  };

  // Отображаемые дела (копия immutableCases), данные меняются в зависимости от активных фильтров
  cases: ICases = {};

  casesAll;

  constructor(
    private casesApi: CasesService,
    private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.getCases();
  }

  // Выбор табов
  switchCasesTab(type: string): void {
    if (type === this.caseTab) {
      return;
    }
    this.caseTab = type;
  }

  getCases() {
    this.isLoadingCases = true;
    this.casesApi.getCases().pipe(takeUntil(this.onDestroy))
      .subscribe((casesData) => {
        this.getMappingCases(casesData);
      });
  }

  getMappingCases(casesData) {
    if (casesData?.length) {
      for (const caseItem of casesData) {
        switch (caseItem.caseType) {
          case 0:
            this.immutableCases.draft.push(caseItem);
            break;
          case 1:
            this.immutableCases.active.push(caseItem);
            break;
          case 2:
            this.immutableCases.finished.push(caseItem);
            break;
          default:
            return null;
        }
      }

      this.cases = JSON.parse(JSON.stringify(this.immutableCases));
      this.casesAll = this.cases.active.concat(this.cases.finished);
      this.isLoadingCases = false;

    } else {
      this.isLoadingCases = false;
    }
  }

  openModal() {
    this.layoutService.openCreateRequestModal();
  }
}
