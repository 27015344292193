<div class="domain-names">
  <div class="carousel-title">
    <span class="carousel-title__text carousel-title__text--desktop">Доменные имена</span>
    <span class="carousel-title__text carousel-title__text--mobile">Домены</span>
    <div class="carousel-counter">
      {{ domainNamesData?.hits?.total?.domains ? (domainNamesData?.hits?.total?.domains > 100 ? '100+' : domainNamesData?.hits?.total?.domains) : 0 }}
    </div>
  </div>
  <div *ngIf="!isLoadingDomainNames && allDomainNames.length === 0" class="empty-result">
    <img src="/assets/icons/empty.svg">
    <div class="empty-result__text">Доменные имена по данному запросу не найдены</div>
  </div>
  <div class="domain-names-list__wrap">
    <div *ngIf="allDomainNames.length || !isLoadingDomainNames; else loader" class="domain-names-list"
         [ngStyle]="allDomainNames.length < 4 ? {'justify-content': 'center'} : {}"
         [class.domains-list__notIpiD]="brandsearch || legalsupport">
      <div class="domain-name-item" *ngFor="let domainName of domainNames">
        <div class="domain-name-item__popover"
             *ngIf="getDomain(domainName).length > 18"
        >{{getDomain(domainName)}}</div>
        <div class="domain-name-card" (click)="openModal(domainName)">
          <div class="buttons_risk">
            <button class="box_button_low">
              Нет риска
            </button>
          </div>

          <div
            *ngIf="domainName._source?.fullDomain"
            class="domain-name-card__header_cover"
          >
            <div [innerText]="getShortNameByWords(getDomain(domainName))" class="name"></div>
          </div>

        </div>
      </div>

      <div
        *ngIf="isViewLoadCard()"
        class="domain-name-item brand-search-card" [class]="legalsupport? 'legal-support-card': ''"
        [class.domain-name-item__notIpiD]="brandsearch || legalsupport"
        [class.img__notIpID]="brandsearch || legalsupport">
        <img *ngIf="!legalsupport" src="/assets/icons/free-label.svg">
        <div class="title">{{legalsupport ? 'Красивый домен будет вашим' : 'Скачайте полный отчет'}}</div>
        <div class="text" *ngIf="brandsearch && !legalsupport" [class.padding__notIpID]="brandsearch || legalsupport">
          Индивидуальные рекомендации по регистрации<br>
          5 способов доработки товарного знака от ведущих компаний<br>
          Полный отчет с результатами поиска по всем базам и реестрам
        </div>
        <div class="text" *ngIf="!brandsearch && legalsupport" [class.padding__notIpID]="brandsearch || legalsupport">
          Товарный знак — козырь в вашем рукаве. Зарегистрируйте его и вы сможете<br>
          получить права на сходный домен или запретить его использование.
        </div>

        <button class="btn-download btnForlegalSupport" *ngIf="!brandsearch && legalsupport"
                (click)="eventsService.emitAction('zaregistrirovat-tz')">
          Зарегистрировать товарный знак
        </button>

        <button class="btn-download" *ngIf="brandsearch && !legalsupport" (click)="emitClickDownload()">
          Скачать отчет
        </button>
      </div>

      <div *ngIf="domainNamesOffset && domainNames.length" class="carousel-arrow carousel-arrow__back"
           (click)="changeDomainNameSlider('back')" [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div *ngIf="((domainNamesOffset + 4) < allDomainNames.length) && domainNames.length"
           class="carousel-arrow carousel-arrow__next" (click)="changeDomainNameSlider('next')"
           [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div *ngIf="((domainNamesOffset + 3) < allDomainNames.length) && domainNames.length"
           class="carousel-arrow carousel-arrow__next carousel-arrow__next--tablet"
           (click)="changeDomainNameSlider('next')" [class.legalsupport-carousel-arrow]="legalsupport">
        <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 2L9 11L2 20" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</div>

<div class="zzmodal" *ngIf="showModal && selectedDomain">
  <app-domain-modal
    [data]="selectedDomain"
    (modalEvent)="closeModal()"
  >
  </app-domain-modal>
</div>

<ng-template #loader>
  <div style="display: block; height: 265px">
    <div class="loader"></div>
  </div>
</ng-template>
