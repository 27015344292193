<div class="open-container" *ngIf="!isIndexPage">
  <app-open-top-menu></app-open-top-menu>
</div>
<div (click)="closeDropdowns()" class="page-wrapper">
  <main class="ipid-main">
    <router-outlet></router-outlet>
  </main>
</div>

<app-create-request-modal></app-create-request-modal>
