<section class="section-top-search"
         [class.is-loading]="corpSearchByNameService.isLoading"
         [class.not-loaded]="!corpSearchByNameService.isLoaded"
>
  <div class="search_header">
    <div class="container container--search">
      <div [ngClass]="innerWidth > 1100 ? 'search-wrapper' : ''">

        <app-ip-id-search-input-corporate
          class="app-ip-id-search-input search--no-border"
          [options]="searchOptions"
          [designation]="corpSearchByNameService.searchOptions.designation"
          [okvedList]="corpSearchByNameService.searchOptions.okvedList"
          [mktuList]="corpSearchByNameService.searchOptions.mktuList"
          [countriesList]="corpSearchByNameService.searchOptions.countriesList"
          (search)="corpSearchByNameService.load($event)"
          (showPopup)="showPopup($event)"
          (openCountriesPopup)="showPopupMobile($event)"
          (countriesDropdown)="countriesDropdown($event)"
        ></app-ip-id-search-input-corporate>
      </div>

      <app-corp-search-loader *ngIf="corpSearchByNameService.isLoading"></app-corp-search-loader>

    </div>
  </div>
</section>

<ng-container *ngIf="!corpSearchByNameService.isLoading && corpSearchByNameService.isLoaded">
  <div class="corp-search-tabs">
    <div class="trademarks-btn__wrap">

        <p class="trademarks-btn">
          <span *ngIf="currentTab === 'tradeMarks'">
            Найдено
            {{corpSearchByNameService.total.tradeMarks}}
            товарных
            {{ declOfNum(corpSearchByNameService.total.tradeMarks, ['знак','знака','знаков']) }}
          </span>
          <span *ngIf="currentTab === 'legalNames'">
            Найдено
            {{corpSearchByNameService.total.legalNames}}
            фирменных
            {{ declOfNum(corpSearchByNameService.total.legalNames, ['наименование','наименования','наименований']) }}
            РФ
          </span>
          <span *ngIf="currentTab === 'domains'">
            Найдено
            {{corpSearchByNameService.total.domains}}
            {{ declOfNum(corpSearchByNameService.total.domains, ['домен','домена','доменов']) }}
          </span>
        </p>

        <div class="trademarks-btn__risk {{getClassName()}}">
          <span *ngIf="corpSearchByNameService.maxRisk === 'High'">Высокий риск отказа в регистрации</span>
          <span *ngIf="corpSearchByNameService.maxRisk === 'Medium'">Есть риск отказа в регистрации</span>
          <span *ngIf="corpSearchByNameService.maxRisk === 'None' || corpSearchByNameService.maxRisk === 'Low'">Поздравляем, ваше название уникально</span>
        </div>
      </div>

    <div>
      <button class="corp-search-tabs__btn" (click)="toCheckTrade()"
              *ngIf="currentTab === 'legalNames' || currentTab === 'domains'">
        <span>Товарные знаки</span>
        <sup *ngIf="(!showIndex && currentTab === 'domains' && showIndexMobile) || (currentTab === 'legalNames' && showIndexMobile) || (showIndexMobile && !showIndex)">
          {{corpSearchByNameService.total.tradeMarks}}
        </sup>
      </button>
      <button class="corp-search-tabs__btn" (click)="toCheckName()"
              *ngIf="currentTab === 'tradeMarks' || currentTab === 'domains'">
        <span>Фирменные наименования РФ</span>
        <sup *ngIf="showIndexMobile">
          {{corpSearchByNameService.total.legalNames}}
        </sup>
      </button>
      <button class="corp-search-tabs__btn" (click)="toCheckDomains()"
              *ngIf="currentTab === 'tradeMarks' || currentTab === 'legalNames'">
        <span>Домены</span>
        <sup *ngIf="showIndexMobile">
          {{corpSearchByNameService.total.domains}}
        </sup>
      </button>
    </div>
  </div>

  <app-corp-search-trademarks
    *ngIf="currentTab === 'tradeMarks'"
    [showFilters]="false"
    [items]="corpSearchByNameService.items.tradeMarks"
    (select)="onSelect('tradeMarks', $event)"
    [countriesDropdown]="countriesPopup"
  ></app-corp-search-trademarks>

  <app-corp-search-domains
    *ngIf="currentTab === 'domains'"
    [items]="corpSearchByNameService.items.domains"
    (select)="onSelect('domains', $event)"
  ></app-corp-search-domains>

  <app-corp-search-legalnames
    [countriesDropdown]="countriesPopup"
    *ngIf="currentTab === 'legalNames'"
    [items]="corpSearchByNameService.items.legalNames"
    (select)="onSelect('legalNames', $event)"
  ></app-corp-search-legalnames>

  <app-corp-search-create-report *ngIf="!corpSearchApiService.objectsLoading"></app-corp-search-create-report>

</ng-container>
