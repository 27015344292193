import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupportChatService {

  constructor() {
    if (this.api) {
      this.onJivositeInit();
    } else {
      document.addEventListener('jivosite:load', () => this.onJivositeInit());
    }
  }

  onJivositeInit() {
    const user = this.getUserData();
    if (user === null) {
      return;
    }

    this.api.setContactInfo({...user});
  }

  open(title?: string) {
    const el = this.jivoEl;
    if (!el) {
      console.error('jivosite is not integrated');
      return;
    }

    el.setAttribute('style', 'opacity: 1; visibility: visible;');

    const user = this.getUserData() || {};
    this.api.setContactInfo({
      ...user,
      description: title
    });

    setTimeout(() => {
      this.api.open({});
    });
  }

  hide() {
    const el = this.jivoEl;
    if (!el) {
      console.error('jivosite is not integrated');
      return;
    }
    el.setAttribute('style', '');
  }

  private get api() {
    return (window as any).jivo_api;
  }

  private get jivoEl() {
    return document.querySelector('body>jdiv');
  }


  private getUserData() {
    try {
      const user = JSON.parse(localStorage.getItem('currentUserData'));

      return {
        'name': user.name,
        'email': user.email,
        'phone': user.phone,
      };
    } catch (e) {
      return null;
    }
  }


}
