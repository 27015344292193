import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SsoAuthService } from '../../../../app-ipid/src/app/shared/services/sso-auth.service';
import { environment } from '../../../../app-ipid/src/environments/environment';
import { OauthService } from '../oauth.service';
import { OrganizationActions } from '@web/core/states/organization.actions';
import { AuthActions } from '@web/core/states/auth.actions';
import { Store } from '@ngxs/store';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';
import { StorageService } from '@web/core/services/storage.service';
import { AppActions } from '@web/core/states/app.actions';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import { APP_STATE_TOKEN } from '@web/core/states/app.state';


@Component({
  selector: 'app-external-auth',
  templateUrl: './external-auth.component.html',
  styleUrls: ['./external-auth.component.scss']
})
export class ExternalAuthComponent implements OnInit {
  state$ = this.store.select(AUTH_STATE_TOKEN);
  brand;
  code;

  constructor(
    private route: ActivatedRoute,
    private sso: SsoAuthService,
    private oauth: OauthService,
    private router: Router,
    private store: Store,
    private storage: StorageService,
  ) {
    this.brand = environment.BRAND;

  }

  ngOnInit(): void {
    this.auth();
  }

  auth() {
    this.route.queryParams.subscribe(param => {
      const type = param.type;
      const caseId = param.ID;
      const token = param.token;

      // TODO: Придумать как переиспользовать повтояющиеся куски
      if (type) {
        switch (type) {
          case 'openCaseById':
            this.storage.deleteToken();
            this.storage.setToken(token);
            this.store.dispatch(new AppActions.Init());
            this.store.dispatch(new AuthActions.Init()).subscribe(() => {
              this.store.dispatch(new OrganizationActions.Init()).subscribe(() => {
                this.store.dispatch(new AuthActions.GetRights());
                this.store.selectSnapshot(APP_STATE_TOKEN).brand === 'LEGAL-DEBT'
                  ? this.router.navigate([`/index/item/`], {queryParams: {id: caseId}})
                  : this.router.navigate([`/${APP_ROUTES_NAMES.CASES}/${APP_ROUTES_NAMES.CASES_ITEM}/${caseId}`],
                    {queryParams: {isDraft: false}});
              });
            });
            break;
          case 'openIndexPage':
            this.storage.deleteToken();
            this.storage.setToken(token);
            this.store.dispatch(new AppActions.Init());
            this.store.dispatch(new AuthActions.Init()).subscribe(() => {
              this.store.dispatch(new OrganizationActions.Init()).subscribe(() => {
                this.store.dispatch(new AuthActions.GetRights());
                this.router.navigate([`/index`]);
              });
            });
            break;
        }
      }
    });
  }
}
