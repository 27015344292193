<div class="page-wrap">

  <section class="protection">
    <div class="container">
      <h1 class="heading-h1">Защита интеллектуальной собственности онлайн</h1>
      <div class="protection__advantages">
        <article class="protection__advantages-item">
          <h3 class="protection__title">О сервисе</h3>
          <ul class="protection__list">
            <li class="protection__item">Уникальная платформа охраны интеллектуальной собственности</li>
            <li class="protection__item">Иновационные инструменты поиска нарушений и рисков</li>
            <li class="protection__item">Устранение риска из личного кабинета в 1 клик</li>
          </ul>
          <svg width="80" height="80" viewBox="0 0 80 80">
            <path d="M39.9997 16.6667C39.0785 16.6667 38.333 17.4122 38.333 18.3335V51.6667C38.333 52.588 39.0785 53.3335 39.9997 53.3335C40.921 53.3335 41.6665 52.588 41.6665 51.6667V18.3333C41.6665 17.4122 40.921 16.6667 39.9997 16.6667Z"/>
            <path d="M39.9997 63.3334C40.9202 63.3334 41.6665 62.5872 41.6665 61.6667C41.6665 60.7462 40.9202 60 39.9997 60C39.0792 60 38.333 60.7462 38.333 61.6667C38.333 62.5872 39.0792 63.3334 39.9997 63.3334Z"/>
            <path d="M77.5586 21.8684L58.1316 2.44141C56.5577 0.8675 54.4645 0 52.238 0H27.762C25.5355 0 23.4423 0.8675 21.8684 2.44141L2.44141 21.8684C0.8675 23.4423 0 25.5355 0 27.762V52.238C0 54.4645 0.8675 56.5577 2.44141 58.1316L21.8684 77.5586C23.4423 79.1325 25.5355 80 27.762 80H52.238C54.4645 80 56.5577 79.1325 58.1316 77.5586L77.5586 58.1316C79.1325 56.5577 80 54.4645 80 52.238V27.762C80 25.5355 79.1325 23.4423 77.5586 21.8684ZM76.6667 52.238C76.6667 53.5742 76.1459 54.8291 75.2019 55.7747L55.7747 75.2019C54.8291 76.1459 53.5742 76.6667 52.238 76.6667H27.762C26.4258 76.6667 25.1709 76.1459 24.2253 75.2019L4.79813 55.7747C3.85406 54.8291 3.33328 53.5742 3.33328 52.238V27.762C3.33328 26.4258 3.85406 25.1709 4.79813 24.2253L24.2253 4.79813C25.1709 3.85406 26.4258 3.33328 27.762 3.33328H52.238C53.5742 3.33328 54.8291 3.85406 55.7747 4.79813L75.2017 24.2252C76.1458 25.1708 76.6666 26.4256 76.6666 27.7619V52.238H76.6667Z"/>
          </svg>
        </article>
        <article class="protection__advantages-item">
          <h3 class="protection__title">Ежедневный мониторинг рисков</h3>
          <ul class="protection__list">
            <li class="protection__item">Уникальные эвристические алгоритмы поиска и ранжирования рисков</li>
            <li class="protection__item">Ежедневные обновление баз данных, поиск рисков и нарушений</li>
            <li class="protection__item">Мгновенные нотификации</li>
          </ul>
          <svg width="80" height="80" viewBox="0 0 80 80">
            <g clip-path="url(#clip0)">
              <path d="M0.488281 77.155L24.8298 52.8133C19.7739 47.1997 16.6666 39.7983 16.6666 31.6666C16.6667 14.2058 30.8723 0 48.3333 0C65.7942 0 80 14.2058 80 31.6667C80 49.1277 65.7942 63.3334 48.3333 63.3334C40.2016 63.3334 32.8003 60.2261 27.1866 55.1702L2.845 79.5117C2.51953 79.8372 2.09296 80 1.66656 80C1.24015 80 0.813751 79.8372 0.488121 79.5117C-0.162811 78.8606 -0.162811 77.8059 0.488281 77.155ZM48.3333 60C63.955 60 76.6666 47.29 76.6666 31.6667C76.6666 16.0434 63.955 3.33328 48.3333 3.33328C32.7116 3.33328 20 16.0433 20 31.6667C20 47.2902 32.7116 60 48.3333 60Z"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="80" height="80" fill="white" transform="matrix(-1 0 0 1 80 0)"/>
              </clipPath>
            </defs>
          </svg>
        </article>
        <article class="protection__advantages-item">
          <h3 class="protection__title">Преимущества и выгоды</h3>
          <ul class="protection__list">
            <li class="protection__item">Проактивная защита интеллектуальных активов</li>
            <li class="protection__item">Комплексная оценка вероятности регистрации</li>
            <li class="protection__item">Полностью онлайн 24/7</li>
          </ul>
          <svg width="80" height="80" viewBox="0 0 80 80">
            <path d="M40 0C17.9444 0 0 17.9444 0 40C0 62.0556 17.9444 80 40 80C62.0556 80 80 62.0556 80 40C80 17.9444 62.0556 0 40 0ZM40 76.6667C19.7819 76.6667 3.33328 60.2181 3.33328 40C3.33328 19.7819 19.7819 3.33328 40 3.33328C60.2181 3.33328 76.6667 19.7819 76.6667 40C76.6667 60.2181 60.2181 76.6667 40 76.6667Z"/>
            <path d="M60.4879 23.8217L31.6663 52.6434L16.178 37.1551C15.527 36.504 14.4723 36.504 13.8213 37.1551C13.1702 37.8062 13.1702 38.8609 13.8213 39.5118L30.488 56.1786C30.8135 56.504 31.2401 56.6668 31.6665 56.6668C32.0929 56.6668 32.5193 56.504 32.8449 56.1786L62.8449 26.1786C63.496 25.5275 63.496 24.4728 62.8449 23.8218C62.1937 23.1707 61.139 23.1707 60.4879 23.8217Z"/>
          </svg>
        </article>
      </div>
    </div>
  </section>

  <div class="container" *ngIf="(data && !isLoading); else loader">
    <section class="risk">
      <div class="risk__content">
        <p class="risk-modal__criticality"
           [class.risk-modal__criticality--critical]= "getRiskNameByLevel() === 'Критичный риск'"
           [class.risk-modal__criticality--high]= "getRiskNameByLevel() === 'Высокий риск'"
           [class.risk-modal__criticality--middle]="getRiskNameByLevel() === 'Средний риск'"
           [class.risk-modal__criticality--recommendation]="getRiskNameByLevel() === 'Рекомендация'"
        >{{getRiskNameByLevel()}}</p>
        <h1 class="risk-modal__title">{{data.risk.description}}</h1>
        <div class="risk-modal__desc" *ngIf="riskDescription">
          <p class="risk__important">Важно</p>
          <p>
            {{riskDescription.threat}}
          </p>
        </div>

        <div class="about-risk" *ngIf="data.risk.opposedObject; else aloneObject">
          <div class="about-risk__source-wrap">
          <h3 class="about-risk__title">Источник риска</h3>
          <div class="about-risk__source">
            <div class="about-risk__source-img" *ngIf="opposedImgUrl">
              <img [src]="opposedImgUrl" alt="" class="" width="71" height="30">
            </div>
            <dl>
              <dt class="about-risk__source-title">{{getObjNameByIndex(data.risk.opposedObject._index)}}</dt>
              <dd class="about-risk__source-name">{{data.risk.opposedObject.name}}</dd>
            </dl>
          </div>
          </div>
          <div class="about-risk__mktu" *ngIf="data.risk.object_crossing?.left?.length && data.risk.object_crossing?.right?.length">
          <h3 class="about-risk__caption">{{data.risk.object_crossing.right_source === 'mktu' ? 'Классы МКТУ' : 'ОКВЭД'}}</h3>
          <div class="risk-modal__table-classes classes__list" >
            <div class="classes__item-wrapper" *ngFor="let leftClass of data.risk.object_crossing.right">
              <label class="classes__item" [class.classes__item--active]="!!leftClass.type">{{leftClass.code}}</label>
            </div>
          </div>
          </div>
          <div class="about-risk__date" *ngIf="data.risk.opposedObject.registrationDate || data.risk.opposedObject.applicationDate">
            <h3 class="about-risk__caption">Дата {{data.risk.opposedObject._index !== 'rutmap' ? 'регистрации' : 'подачи заявки'}}</h3>
            <p *ngIf="data.risk.opposedObject.registrationDate">{{data.risk.opposedObject.registrationDate | date:'dd.MM.yyyy'}}</p>
            <p *ngIf="!data.risk.opposedObject.registrationDate && data.risk.opposedObject.applicationDate">
              {{data.risk.opposedObject.applicationDate | date:'dd.MM.yyyy'}}</p>
          </div>
          <div class="about-risk__applicant" *ngIf="data.risk.opposedObjectOwner?.representation">
            <h3 class="about-risk__caption">Заявитель</h3>
            <p>{{data.risk.opposedObjectOwner.representation}}</p>
<!--            <p class="about-risk__applicant-name">Иванов Г. Х.</p>-->
<!--            <p class="about-risk__applicant-spec">Консультирование по вопросам коммерческой деятельности и управления; Деятельность в области права</p>-->
          </div>

          <div class="about-risk__source-wrap about-risk__source-wrap--your">
          <h3 class="about-risk__title">Ваш объект</h3>
          <div class="about-risk__source">
            <div class="about-risk__source-img" *ngIf="imgUrl">
              <img [src]="imgUrl" alt="" class="" width="71" height="30">
            </div>
            <dl>
              <dt class="about-risk__source-title">{{getObjNameByIndex(data.index)}}</dt>
              <dd class="about-risk__source-name">{{data.representation}}</dd>
            </dl>
          </div>
          </div>
          <div class="about-risk__mktu about-risk__mktu--your" *ngIf="data.risk.object_crossing?.left?.length && data.risk.object_crossing?.right?.length">
          <h3 class="about-risk__caption">{{data.risk.object_crossing.left_source === 'mktu' ? 'Классы МКТУ' : 'ОКВЭД'}}</h3>
          <div class="risk-modal__table-classes classes__list">
            <div class="classes__item-wrapper" *ngFor="let ok of data.risk.object_crossing.left">
<!--              <input type="checkbox" id="{{ok.id}}" [(ngModel)]="ok.checked">-->
              <label class="classes__item" [class.classes__item--active]="!!ok.type">{{ok.code}}</label>
            </div>
          </div>
          </div>
          <div class="about-risk__date about-risk__date--your" *ngIf="data.registrationDate">
            <h3 class="about-risk__caption">Дата {{data.index !== 'rutmap' ? 'регистрации' : 'подачи заявки'}}</h3>
            <p>{{data.registrationDate | date:'dd.MM.yyyy'}}</p>
          </div>
        </div>
        <ng-template #aloneObject>
          <div class="risk-modal__about-wrap non-border">
            <div class="img-container-object about-risk__source-img img-alone-object" *ngIf="imgUrl">
              <img [src]="imgUrl" alt="" class="risk-modal__about-img">
            </div>
            <dl class="risk-modal__about">
              <div>
                <dt>{{getObjNameByIndex(data.index)}}</dt>
                <dd class="risk-modal__about-name">{{data.representation}}</dd>
              </div>
              <div *ngIf="data.registrationDate">
                <dt>Дата регистрации</dt>
                <dd>{{data.registrationDate | date:'dd.MM.yyyy'}}</dd>
              </div>
              <div *ngIf="data.objectOwner?.representation">
                <dt>Правообладатель</dt>
                <dd>{{data.objectOwner.representation}}</dd>
              </div>
              <div *ngIf="data.validUntil || data.risk.date">
                <dt>Действует до</dt>
                <dd class="risk-modal__about-expire">{{(data.validUntil || data.risk.date)| date:'dd.MM.yyyy'}}</dd>
              </div>
            </dl>
          </div>
        </ng-template>
        <div class="risk__recommendation-wrap">
          <p class="risk__recommendation">Рекомендации</p>
          <p class="risk__recommendation-desc">{{riskDescription.recommendation}}</p>
        </div>
        <div class="risk__btn-wrap">
          <button class="risk__btn risk__btn--ask" (click)="onClickBtn()">Спросить поверенного</button>
          <button class="risk__btn risk__btn--decide" (click)="toLeadForm()">{{riskDescription.button}}</button>
        </div>
      </div>
    </section>
  </div>

  <ng-template #loader>
    <app-spinner class="spinner-full"></app-spinner>
  </ng-template>

  <app-expert>
  </app-expert>
</div>
