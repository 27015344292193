import { NgxsModule } from '@ngxs/store';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { BrandNamePipe } from './pipes/brand-name.pipe';
import { TrademarkState } from './store/trademark.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { TrademarkService } from './services/trademark.service';
import { TinHelperService } from './services/tin-helper.service';
import { ButtonModule } from '@ui/components/button/button.module';
import { PaymentModule } from '@ui/features/payment/payment.module';
import { TariffesModule } from '@ui/features/tariffes/tariffes.module';
import { DeclOfNumModule } from '@ui/pipes/decl-of-num/decl-of-num.module';
import { PromoCodeModule } from '@ui/components/promo-code/promo-code.module';
import { TrademarkComponent } from './components/trademark/trademark.component';
import { StepperModule } from '../../../../ui/components/stepper/stepper.module';
import { TrademarkMktuModule } from './components/trademark-mktu/trademark-mktu.module';
import { LoadTrademarkRequestGuard } from './routes/guards/load-trademark-request.guard';
import { ToLacaleStringModule } from '@ui/pipes/to-lacale-string/to-lacale-string.module';
import { TrademarkOwnerModule } from './components/trademark-owner/trademark-owner.module';
import { TrademarkStepComponent } from './components/trademark-step/trademark-step.component';
import { TarrifSelectionComponent } from './components/tarrif-selection/tarrif-selection.component';
import { RospatentDutiesComponent } from './components/rospatent-duties/rospatent-duties.component';
import { TrademarkResultComponent } from './components/trademark-result/trademark-result.component';
import { TrademarkPaymentComponent } from './components/trademark-payment/trademark-payment.component';
import {ExpertModule} from '../../../../app-ipid/src/app/pages/layout/expert/expert.mdoule';

@NgModule({
  declarations: [
    BrandNamePipe,
    TrademarkComponent,
    TrademarkStepComponent,
    TarrifSelectionComponent,
    RospatentDutiesComponent,
    TrademarkResultComponent,
    TrademarkPaymentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    PaymentModule,
    MatCardModule,
    StepperModule,
    TariffesModule,
    DeclOfNumModule,
    PromoCodeModule,
    TrademarkMktuModule,
    ReactiveFormsModule,
    TrademarkOwnerModule,
    NgxsFormPluginModule,
    ToLacaleStringModule,
    ExpertModule,
    NgxsModule.forFeature([TrademarkState]),
  ],
  providers: [
    TrademarkService,
    TinHelperService,
    LoadTrademarkRequestGuard,
  ],
  exports: [TrademarkComponent],
})
export class TrademarkModule { }
