import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DemoService {

  indexPage = {
    myDeal: false,
  };

  constructor() {
  }

  updateIndexPage(props: any = {}) {
    this.indexPage = {...this.indexPage, ...props};
  }

}
