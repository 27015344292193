import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { environment } from '../../../../app-ipid/src/environments/environment';
import { SupportChatService } from '../../../../shared/services/support-chat.service';

@Component({
  selector: 'app-ui-ask-expert',
  templateUrl: './ask-expert.component.html',
  styleUrls: ['./ask-expert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AskExpertComponent {
  image = environment.FOOTER_IMAGE_PATH;
  imageMobile = environment.FOOTER_IMAGE_MOBILE_PATH;

  @Input() title = 'Задать вопрос патентному поверенному';

  @Input() buttons: string[] = [
    'Консультация по результатам поиска',
    'Вопрос по текущему делопроизводству',
    'Консультация по услугам',
    'Запрос первичных документов',
    'Другое'
  ];

  constructor(
    private readonly supportChatService: SupportChatService,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  public click(buttonTitle: string): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_ASK_PP });
    this.supportChatService.open(buttonTitle);
  }
}
