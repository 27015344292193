<form [formGroup]="lawFromResident">
  <div class="form__input-wrap">
    <input class="form__input field"
           (ngModelChange)="getLegal(lawFromResident.value.search)"
           (click)="showOrganizationsList()"
           type="text"
           required
           formControlName="search"
           [class.invalid]="validation && !lawFromResident.value.search"
    >
    <span class="form__input-label">Поиск по ИНН / ОГРН или Наименованию</span>
    <div class="loading__request_contract" *ngIf="loadingOwner">
      <div class="loading__inner"></div>
    </div>
    <div class="form__dropdown form-card" *ngIf="dropdown" (click)="setValueOwner()">
      <table class="form-card__table">
        <thead class="dropdown-hover">
        <tr>
          <th style="width: 10%">ОГРН</th>
          <th style="width: 10%">ИНН</th>
          <th>Наименование компании или ИП</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{data._source.PSRN}}</td>
          <td>{{data._source.TIN}}</td>
          <td class="black">{{data._source.fullName}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="form__dropdown form-card" *ngIf="viewOrganizationsList">
      <ng-container>
        <table class="form-card__table">
          <thead class="dropdown-hover">
          <tr>
            <th>ОГРН</th>
            <th>ИНН</th>
            <th>Наименование компании или ИП</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let organization of organizations" class="form-card-hover" (click)="setValueOrganization(organization)">
            <td>{{organization.PSRN}}</td>
            <td>{{organization.TIN}}</td>
            <td class="black">{{organization.shortName || organization.name}}</td>
          </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
  <div class="form-sides__block">
    <div class="form__input-wrap">
      <input class="form__input field" type="text"
             required
             formControlName="INN"
             (ngModelChange)="setValue()"
             [class.invalid]="validation && !lawFromResident.controls.INN.value"
      >
      <span class="form__input-label">ИНН / ОГРН</span>
    </div>
    <div class="form__input-wrap">
      <input class="form__input field" type="text" required formControlName="director" (ngModelChange)="setValue()"
             [class.invalid]="validation && !lawFromResident.controls.director.value">
      <span class="form__input-label">Генеральный директор</span>
    </div>
    <div class="form__input-wrap">
      <input class="form__input field" type="text" required formControlName="address" (ngModelChange)="setValue()"
             [class.invalid]="validation && !lawFromResident.controls.address.value">
      <span class="form__input-label">Юридический адрес</span>
    </div>
    <div class="form__input-wrap">
      <input class="form__input field"
             required
             maxlength="9"
             formControlName="BIK"
             (ngModelChange)="setValue()"
             [class.invalid]="validation && !lawFromResident.controls.BIK.value
             || (lawFromResident.controls.BIK.touched || lawFromResident.controls.BIK.dirty) && lawFromResident.controls.BIK.invalid"
      >
      <span class="form__input-label">БИК Банка</span>
      <span
        *ngIf="(lawFromResident.controls.BIK.touched || lawFromResident.controls.BIK.dirty) && lawFromResident.controls.BIK.invalid"
        class="invalid-field">
          Поле не может быть пустым и должно содержать цифры
      </span>
      <div class="form__dropdown form-card" *ngIf="dropdownBik">
        <table class="form-card__table">
          <thead class="dropdown-hover">
          <tr>
            <th style="width: 10%">БИК</th>
            <th>Наименование банка</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>044525225</td>
            <td class="black">Сбербанк ПАО</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form__input-wrap">
      <input class="form__input field"
             required
             maxlength="20"
             formControlName="account"
             (ngModelChange)="setValue()"
             [class.invalid]="validation && !lawFromResident.controls.account.value
             || (lawFromResident.controls.account.touched || lawFromResident.controls.account.dirty) && lawFromResident.controls.account.invalid"
      >
      <span class="form__input-label">Расчетный счет</span>
      <span *ngIf="(lawFromResident.controls.account.touched || lawFromResident.controls.account.dirty) && lawFromResident.controls.account.invalid"
            class="invalid-field">
        Поле не может быть пустым и должно содержать цифры
      </span>
    </div>
  </div>
</form>
<div *ngIf="viewOrganizationsList" (click)="viewOrganizationsList = false;"
     class="overlay overlay--searchList"
     style="background: none;
                z-index: 4;">
</div>

