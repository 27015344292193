import { Router } from '@angular/router';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';
import {AuthState} from '@web/core/states/auth.state';

@Component({
  selector: 'app-ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  @ViewSelectSnapshot(AuthState.getRightByName('monitoring-viewing')) monitoringAvailable: boolean;

  @Input() title: string = '';
  @Input() titleTooltip: string = '';
  @Input() count: number = 0;
  @Input() isOpen: boolean = false;
  @Input() hideCounter: boolean = false;
  @Input() monitoring: any = {};

  constructor(private readonly router: Router) { }

  public openAccordion(): void {
    if (this.count || this.hideCounter) {
      this.isOpen = !this.isOpen;
    }
  }

  public goToMonitoring(event: MouseEvent): void {
    event.stopPropagation();
    void this.router.navigate(['/', APP_ROUTES_NAMES.MONITORING]);
  }
}
