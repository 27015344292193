<div class="full-size-search">
  <div class="ip-id_search_container">
    <div class="main__form" *ngIf="searchDefault">
      <div class="search_input_container" data-intercom-target="search field" #searchContainerEl>
        <div class="designation">

          <ng-container *ngIf="corporate">
            <div class="search-field-btn" *ngFor="let country of countriesSearchArray.slice(0, 1)"
                 (click)="clearAllCountriesSelected()">
              <span>{{ country?.code }}</span>

              <span *ngIf="countriesSearchArray.length > 1">
              и еще {{ countriesSearchArray.length - 1 }}
            </span>
              <button aria-label="close"></button>
            </div>
          </ng-container>
          <input type="text" class="designation__input"
                 [class.invalid-red]="searchError"
                 (click)="clickOnDesignation()"
                 [(ngModel)]="designation"
                 [ngModelOptions]="{standalone: true}"
                 [disabled]="disableCountry"
                 (keyup.enter)="checkTradeMarks()"
                 (focusout)="sendIntercomData({event: 'printSearchFocusOut', query: designation})"
                 placeholder="{{ placeholder }}">
        </div>

        <div>
          <button *ngIf="isShowCountriesBtn && corporate && !disableCountry" (click)="searchByCountries()"
                  class="plus_button"
                  data-intercom-target="activities">
            <svg class="plus_button--icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.7617 8.98828H8.73828V12.0117H7.26172V8.98828H4.23828V7.51172H7.26172V4.48828H8.73828V7.51172H11.7617V8.98828ZM8 0.761719C7.01562 0.761719 6.05469 0.949219 5.11719 1.32422C4.20312 1.69922 3.39453 2.23828 2.69141 2.94141C1.98828 3.64453 1.44922 4.46484 1.07422 5.40234C0.699219 6.31641 0.511719 7.26562 0.511719 8.25C0.511719 9.23438 0.699219 10.1953 1.07422 11.1328C1.44922 12.0469 1.98828 12.8555 2.69141 13.5586C3.39453 14.2617 4.20312 14.8008 5.11719 15.1758C6.05469 15.5508 7.01562 15.7383 8 15.7383C8.98438 15.7383 9.93359 15.5508 10.8477 15.1758C11.7852 14.8008 12.6055 14.2617 13.3086 13.5586C14.0117 12.8555 14.5508 12.0469 14.9258 11.1328C15.3008 10.1953 15.4883 9.23438 15.4883 8.25C15.4883 7.26562 15.3008 6.31641 14.9258 5.40234C14.5508 4.46484 14.0117 3.64453 13.3086 2.94141C12.6055 2.23828 11.7852 1.69922 10.8477 1.32422C9.93359 0.949219 8.98438 0.761719 8 0.761719Z"
                fill="#00AFEC"/>
            </svg>
            Выбрать территорию действия
          </button>

          <button *ngIf="isShowMktuPlusBtn" (click)="searchByJSON()"
                  class="plus_button"
                  data-intercom-target="activities">
            <svg class="plus_button--icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.7617 8.98828H8.73828V12.0117H7.26172V8.98828H4.23828V7.51172H7.26172V4.48828H8.73828V7.51172H11.7617V8.98828ZM8 0.761719C7.01562 0.761719 6.05469 0.949219 5.11719 1.32422C4.20312 1.69922 3.39453 2.23828 2.69141 2.94141C1.98828 3.64453 1.44922 4.46484 1.07422 5.40234C0.699219 6.31641 0.511719 7.26562 0.511719 8.25C0.511719 9.23438 0.699219 10.1953 1.07422 11.1328C1.44922 12.0469 1.98828 12.8555 2.69141 13.5586C3.39453 14.2617 4.20312 14.8008 5.11719 15.1758C6.05469 15.5508 7.01562 15.7383 8 15.7383C8.98438 15.7383 9.93359 15.5508 10.8477 15.1758C11.7852 14.8008 12.6055 14.2617 13.3086 13.5586C14.0117 12.8555 14.5508 12.0469 14.9258 11.1328C15.3008 10.1953 15.4883 9.23438 15.4883 8.25C15.4883 7.26562 15.3008 6.31641 14.9258 5.40234C14.5508 4.46484 14.0117 3.64453 13.3086 2.94141C12.6055 2.23828 11.7852 1.69922 10.8477 1.32422C9.93359 0.949219 8.98438 0.761719 8 0.761719Z"
                fill="#00AFEC"/>
            </svg>
            Добавить вид деятельности
          </button>
        </div>

        <div *ngIf="okvedSearchArray.length || mktuSearchArray.length" class="added-number-wrapper">
          <div class="added-number" *ngFor="let mktuItem of mktuSearchArray">
            <span class="added-number__delete" (click)="deleteNumberFromSearch('mktu', mktuItem)"></span>
            <div class="added-number__title">МКТУ</div>
            <div class="added-number__number">{{ mktuItem.number }}</div>
            <div class="added-number__popover">
              <div>{{ mktuItem.desc }}</div>
              <div *ngIf="mktuItem.subClasses && mktuItem.subClasses.length">
                <hr style="background: #0be281; margin: 4px 0;" noshade size="1">
                <div *ngFor="let subClass of mktuItem.subClasses" [innerHTML]="'- ' + subClass.desc"></div>
              </div>
            </div>
          </div>

          <div class="added-number added-number_okved" *ngFor="let okvedItem of okvedSearchArray">
            <span class="added-number__delete" (click)="deleteNumberFromSearch('okved', okvedItem)"></span>
            <div class="added-number__title">ОКВЭД</div>
            <div class="added-number__number">{{ okvedItem.code }}</div>
            <div *ngIf="okvedItem.description" class="added-number__popover">
              <div [innerHTML]="okvedItem.description"></div>
            </div>
          </div>
        </div>
      </div>

      <button *ngIf="!disableCountry" data-intercom-target="check button" class="submit_red" [class.disabled]="!designation?.length"
              (click)="isSearchAllowed(designation)">
        Проверить
      </button>
      <button *ngIf="disableCountry" data-intercom-target="check button" class="submit_red" [disabled]="disableButton" [class.disabled]="disableButton"
              (click)="searchImages()">
        Проверить
      </button>

      <div class="main_modal main_modal--countries countries" *ngIf="isShowCountriesPopup && corporate"
           #searchModalCountries>
        <p class="countries__desc">
          <span>Выбраны:</span>
          <button (click)="clearAllCountriesSelected()">
            Очистить все
          </button>
        </p>

        <ul class="countries__selected-list">
          <li class="search-field-btn search-field-btn--popup" *ngFor="let country of countriesSearchArray"
              (click)="deleteNumberFromSearch('countries', country)">
            <span>{{ country?.code }}</span>
            <button aria-label="close"></button>
          </li>
        </ul>

        <form class="countries__form">
          <input type="text" placeholder="Поиск по странам" autocomplete="off"
                 #searchCountries
                 (input)="selectFilter($event)"
                 (change)="selectFilter($event)"
                 aria-describedby="search-text"
          >
        </form>

        <p class="countries__desc countries__desc--all">
          <span>Выбрать все:</span>
          <button (click)="addCountriesSelected('СНГ')">
            СНГ
          </button>
        </p>

        <ul class="countries__checkboxes">
          <li class="countries__checkbox-item"
              *ngFor="let country of !searchCountries.value ? countries : filterCountryList">
            <input type="checkbox" class="countries__checkbox-input" name="{{ country?.code }}"
                   id="1-{{ country?.code }}"
                   (change)="addCountryToSearch(country)"
                   [ngClass]="country?.selected ? 'countries__checkbox-input--checked' : ''"
            >
            <label class="countries__checkbox-label" for="1-{{ country?.code }}">
              <span class="countries__checkbox-code">{{ country?.code }}</span>
              <span class="countries__checkbox-name">{{ country?.name }}</span>
            </label>
          </li>
        </ul>
      </div>

      <div data-intercom-target="activities classifier" *ngIf="isShowSearchPopup"
           (clickOutside)="hideSearchPopupFromOutsideClick($event)" [clickOutsideEnabled]="!isMobile"
           [clickOutsideEvents]="'click,touchstart'" #searchModalEl class="main_modal">
        <div>
          <div class="main_modal__btn-group">
            <!--            <button-->
            <!--              class="all-btn"-->
            <!--              *ngIf="searchPopupViewType !== 'inn-ogrn'"-->
            <!--              [ngClass]="searchPopupViewType === 'all' ? '__selected' : ''"-->
            <!--              (click)="changeSearchPopupView('all')"-->
            <!--            >-->
            <!--              Все-->
            <!--            </button>-->
            <div>
              <button class="mktu-btn" [ngClass]="searchPopupViewType === 'mktu' ? '__selected' : ''"
                      (click)="changeSearchPopupView('mktu')">
                Классы МКТУ
              </button>
              <button class="okved-btn" [ngClass]="searchPopupViewType === 'okved' ? '__selected' : ''"
                      (click)="changeSearchPopupView('okved')">
                ОКВЭД
              </button>
            </div>

            <button class="inn-ogrn-btn" [ngClass]="searchPopupViewType === 'inn-ogrn' ? '__selected' : ''"
                    (click)="changeSearchPopupView('inn-ogrn')">
              Поиск по ИНН/ОГРН
            </button>

          </div>

          <form class="mktu_input_form" *ngIf="['okved', 'mktu'].indexOf(searchPopupViewType) > -1">
            <input
              *ngIf="isShowSearchInput"
              [(ngModel)]="query"
              #queryEl
              type="text" name="mktuQuery"
              placeholder="Например кафе или 63.01 или 42" autocomplete="off" class="ip-id_Input_wide"
              (keyup.enter)="fetchMktu();fetchOkved()"
              (ngModelChange)=fetchMktuAndOkvedByQuery()
              (focusout)="sendIntercomData({event: 'printSearchMktuOkvdFocusOut', query: query})"
            >
          </form>

          <div class="inn-ogrn-search-block" *ngIf="searchPopupViewType === 'inn-ogrn'">
            <div *ngIf="legalName" [ngStyle]="getDynamicMarginForLegalName()" class="legal-name">
              {{ legalName }}
            </div>
            <input type="text" name="innOgrnQuery" #innOgrn="ngModel" [(ngModel)]="innOgrnQuery" maxlength="15"
                   pattern='^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$' placeholder="Подбор ОКВЭД по ИНН\ОГРН"
                   autocomplete="off" class="inn-ogrn-search-input validate-border-color"
                   (click)="changeSearchPopupView('inn-ogrn')" (ngModelChange)=legalChanged()>
            <div *ngIf="innOgrn.invalid && (innOgrn.dirty || innOgrn.touched)" class="error-inn-ogrn">
              Поле не может быть пустым и должно содержать 10, 12, 13 или 15 цифр.
            </div>
          </div>

          <div class="response-result-list" *ngIf="!isLoadingSearchClases; else loader"
               [ngSwitch]="searchPopupViewType">

            <!--            <div *ngSwitchCase="'all'">-->
            <!--              <div *ngIf="mktuMappedResponse?.length || okvedMappedResponse; else classesNotFound">-->
            <!--                <div-->
            <!--                  class="response-result-list__item"-->
            <!--                  *ngFor="let item of okvedMappedResponse"-->
            <!--                  (click)="addOkvedToSearch(item);"-->
            <!--                  [ngClass]="item.selected ? 'response-result-list__item__selected' : ''"-->
            <!--                >-->
            <!--                  <div class="response-result-list__item__name okved-class-code">ОКВЭД {{item.code}}</div>-->
            <!--                  <div class="response-result-list__item__desc" [title]="item.description"-->
            <!--                       [innerHTML]="item.description"></div>-->
            <!--                </div>-->
            <!--                <div-->
            <!--                  class="response-result-list__item"-->
            <!--                  *ngFor="let item of mktuMappedResponse"-->
            <!--                  (click)="addMktuToSearch(item, false)"-->
            <!--                  [ngClass]="item.selected ? 'response-result-list__item__selected' : ''"-->
            <!--                >-->
            <!--                  <div class="response-result-list__item__name mktu-class-code">{{item.name}}</div>-->
            <!--                  <div class="response-result-list__item__desc" [title]="item.desc" [innerHTML]="item.desc"></div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <div *ngSwitchCase="'mktu'">
              <div *ngIf="mktuMappedResponse?.length; else classesNotFound">
                <div class="response-result-list__item" *ngFor="let item of mktuMappedResponse"
                     (click)="addMktuToSearch(item, false)"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div class="response-result-list__item__name mktu-class-code">{{item.name}}</div>
                  <div class="response-result-list__item__desc" [title]="item.desc" [innerHTML]="item.desc"></div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'okved'">
              <div *ngIf="okvedMappedResponse?.length; else classesNotFound">
                <div class="response-result-list__item" *ngFor="let item of okvedMappedResponse"
                     (click)="addOkvedToSearch(item)"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div class="response-result-list__item__name okved-class-code">ОКВЭД {{item.code}}</div>
                  <div class="response-result-list__item__desc" [title]="item.description"
                       [innerHTML]="item.description"></div>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="'inn-ogrn'">
              <div *ngIf="legalMappedResponse?.length; else innOgrnStub">
                <div class="response-result-list__item" *ngFor="let item of legalMappedResponse"
                     (click)="addOkvedToSearch(item)"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div class="response-result-list__item__name okved-class-code">ОКВЭД {{item.code}}</div>
                  <div class="response-result-list__item__desc" [title]="item.description"
                       [innerHTML]="item.description"></div>
                </div>
              </div>
            </div>

            <ng-template #classesNotFound>
              <div class="classesNotFound">
                Ничего не нашлось. Попробуйте изменить формулировку.
              </div>
            </ng-template>

            <ng-template #innOgrnStub>
              <div *ngIf="!isLoadingLegal; else loader" class="innOgrnStub">
                После ввода ИНН или ОГРН организации<br>
                здесь отобразятся ее виды деятельности
              </div>
            </ng-template>

          </div>
        </div>
      </div>


      <ng-template #loader>
        <div style="display: block; height: 265px">
          <div class="loader"></div>
        </div>
      </ng-template>

    </div>
    <span *ngIf="searchError && searchDefault && !designation?.length" class="main__form main__form--error"
          [style.width.px]="1070">
      Задан пустой запрос
    </span>
  </div>
</div>
<div class="mobile-search">
  <div class="mobile-search-container">
    <!--    <span #invisibleText id="invisibleTextID">{{ designation }}</span>-->
    <div class="" *ngIf="searchDefault">
      <div class="mobile-search-block" data-intercom-target="search field" #searchContainerEl>
        <textarea type="text" class="mobile-search-block__textarea"
                  [class.invalid-red]="searchError && !designation?.length" #designationEl
                  (click)="clickOnDesignation()"
                  [disabled]="disableCountry"
                  [(ngModel)]="designation" [ngModelOptions]="{standalone: true}"
                  [maxLength]="this.widthDesignation > 850 ? designation?.length : 100"
                  (keyup.enter)="checkTradeMarks()"
                  placeholder="{{placeholder}}">

        </textarea>

        <span style="display: block; height: 1px; background: rgba(21, 36, 56, 0.15);margin: 0 -5px"></span>

        <a *ngIf="okvedSearchArray.length || mktuSearchArray.length" (click)="clearAddedClasses()"
           class="clear-classes">
          Очистить вид деятельности
        </a>

        <div class="added-number__wrap">
          <div class="added-number added-number__mobile added-number_okved" *ngFor="let okvedItem of okvedSearchArray">
            <span class="added-number__delete" (click)="deleteNumberFromSearch('okved', okvedItem)"></span>
            <div class="added-number__title">ОКВЭД</div>
            <div class="added-number__number">{{ okvedItem.code }}</div>
            <div *ngIf="okvedItem.description" class="added-number__popover">
              <div [innerHTML]="okvedItem.description"></div>
            </div>
          </div>

          <div class="added-number added-number__mobile" *ngFor="let mktuItem of mktuSearchArray">
            <span class="added-number__delete" (click)="deleteNumberFromSearch('mktu', mktuItem)"></span>
            <div class="added-number__title">МКТУ</div>
            <div class="added-number__number">{{ mktuItem.number }}</div>
            <div class="added-number__popover">
              <div>{{ mktuItem.desc }}</div>
              <div *ngIf="mktuItem.subClasses && mktuItem.subClasses.length">
                <hr style="background: #0be281; margin: 4px 0;" noshade size="1">
                <div *ngFor="let subClass of mktuItem.subClasses" [innerHTML]="'- ' + subClass.desc"></div>
              </div>
            </div>
          </div>
        </div>

        <button (click)="searchByCountriesMobile()" *ngIf="corporate && !disableCountry"
                class="plus_button__mobile" data-intercom-target="activities">
          + Терриитория действия
        </button>

        <button (click)="searchByJSON()"
                class="plus_button__mobile" data-intercom-target="activities">
          + Вид деятельности
        </button>

      </div>
      <div *ngIf="searchError && searchDefault && !designation?.length" class="main__form--error mobile-error">
        Задан пустой запрос
      </div>

      <button data-intercom-target="check button" class="submit_red__mobile" [class.disabled]="!designation?.length"
              (click)="isSearchAllowedMobile(designation)">
        Проверить
      </button>

      <div class="main_modal main_modal--countries countries" *ngIf="isShowCountriesPopupMobile && corporate">
        <div class="choice-classes__header">
          <div class="choice-classes__header-wrap">
            <span>Страна действия</span>
            <button class="choice-classes__header-close" (click)="closePopupMobile()"></button>
          </div>
        </div>

        <div #searchModalCountriesMobile>

          <p class="countries__desc">
            <span>Выбраны:</span>
            <button (click)="clearAllCountriesSelected()">
              Очистить все
            </button>
          </p>

          <ul class="countries__selected-list">
            <li class="search-field-btn search-field-btn--popup" *ngFor="let country of countriesSearchArray"
                (click)="deleteNumberFromSearch('countries', country)">
              <span>{{ country?.code }}</span>
              <button aria-label="close"></button>
            </li>
          </ul>

          <form class="countries__form">
            <img src="/assets/icons/search.svg" class="search-icon">
            <input type="text" placeholder="Поиск по странам" autocomplete="off">
          </form>

          <p class="countries__desc countries__desc--all">
            <span>Выбрать все:</span>
            <button (click)="addCountriesSelected('СНГ')">
              СНГ
            </button>
            <!--          <button (click)="addCountriesSelected('Евросоюз')">-->
            <!--            Евросоюз-->
            <!--          </button>,-->
            <!--          <button (click)="addCountriesSelected('БРИКС')">-->
            <!--            БРИКС-->
            <!--          </button>-->
          </p>

          <ul class="countries__checkboxes">
            <li class="countries__checkbox-item" *ngFor="let country of countries">
              <input type="checkbox" class="countries__checkbox-input" name="{{ country?.code }}"
                     id="{{ country?.code }}"
                     (change)="addCountryToSearch(country)"
                     [ngClass]="country?.selected ? 'countries__checkbox-input--checked' : ''"
              >
              <label class="countries__checkbox-label" for="{{ country?.code }}">
                <span class="countries__checkbox-code">{{ country?.code }}</span>
                <span class="countries__checkbox-name">{{ country?.name }}</span>
              </label>
            </li>
          </ul>
        </div>

        <button class="apply-filter__mobile">
          Применить выбор
        </button>
      </div>

      <div *ngIf="isShowSearchPopup">
        <div class="choice-classes__header">
          <div class="choice-classes__header-wrap">
            <span>Вид деятельности</span>
            <button class="choice-classes__header-close" (click)="closePopupMobile()"></button>
          </div>
        </div>

        <div class="choice-classes">

          <div class="choice-classes__filters-and-search">

            <div class="filters-or-search-item">
              <button class="choice-classes__button choice-classes__button--all-type"
                      [ngClass]="['all', 'okved', 'okvedBySearch', 'mktu', 'mktuBySearch'].indexOf(searchPopupViewType) > -1 ? '__selected' : ''"
                      (click)="changeSearchPopupView('all', true); query = ''">
                По МКТУ/ОКВЭД
              </button>
              <button class="choice-classes__button choice-classes__button--inn"
                      [ngClass]="searchPopupViewType === 'inn-ogrn' ? '__selected' : ''"
                      (click)="changeSearchPopupView('inn-ogrn'); query = ''">
                ПО ИНН/ОГРН
              </button>
            </div>

            <div *ngIf="['all', 'okved', 'okvedBySearch', 'mktu', 'mktuBySearch'].indexOf(searchPopupViewType) > -1"
                 class="filters-or-search-item">
              <img *ngIf="!disableCountry" class="search-icon" src="/assets/icons/search.svg">
              <input *ngIf="!disableCountry" [(ngModel)]="query" #queryEl type="text" name="query" placeholder="Кафе, номер класса или ОКВЭД"
                     autocomplete="off" class="ip-id_Input_wide ip-id_Input_wide__mobile"
                     (click)="clickByMobileQueryInput()"
                     (keyup.enter)="fetchMktu();fetchOkved()" (ngModelChange)=fetchMktuAndOkvedByQuery()>
              <input *ngIf="disableCountry" [(ngModel)]="query" #queryEl type="text" name="query"
                     autocomplete="off" class="ip-id_Input_wide ip-id_Input_wide__mobile"
                     (click)="clickByMobileQueryInput()"
                     (keyup.enter)="fetchMktu();fetchOkved()" (ngModelChange)=fetchMktuAndOkvedByQuery()>
            </div>

            <div *ngIf="searchPopupViewType === 'inn-ogrn'" class="inn-ogrn-search-input">
              <div *ngIf="legalName" [ngStyle]="getDynamicMarginForLegalName()" class="legal-name legal-name--mobile">
                {{ legalName }}
              </div>
              <img class="search-icon" style="top: 15px;" src="/assets/icons/search.svg">
              <input type="text" name="innOgrnQuery" #innOgrn="ngModel" [(ngModel)]="innOgrnQuery" maxlength="15"
                     pattern='^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$' placeholder="Подбор ОКВЭД по ИНН\ОГРН"
                     autocomplete="off" class="ip-id_Input_wide ip-id_Input_wide__mobile validate-border-color"
                     (ngModelChange)=legalChanged()>
              <div *ngIf="innOgrn.invalid && (innOgrn.dirty || innOgrn.touched)" class="error-inn-ogrn">
                Поле не может быть пустым и должно содержать 10, 12, 13 или 15 цифр.
              </div>
            </div>

            <div *ngIf="['all', 'okved', 'okvedBySearch', 'mktu', 'mktuBySearch'].indexOf(searchPopupViewType) > -1"
                 class="filters-or-search-item">
              <button class="choice-classes__button choice-classes__button--okved"
                      *ngIf="searchPopupViewType !== 'inn-ogrn' && !options.search || (searchPopupViewType !== 'inn-ogrn' && options.search === 'okved')"
                      [ngClass]="['okved', 'okvedBySearch'].indexOf(searchPopupViewType) > -1 ? '__selected' : ''"
                      (click)="changeSearchPopupView('okved', true)">
                Показать только ОКВЭД
              </button>
              <button class="choice-classes__button choice-classes__button--mktu"
                      *ngIf="searchPopupViewType !== 'inn-ogrn' && !options.search || (searchPopupViewType !== 'inn-ogrn' && options.search === 'mktu')"
                      [ngClass]="['mktu', 'mktuBySearch'].indexOf(searchPopupViewType) > -1 ? '__selected' : ''"
                      (click)="changeSearchPopupView('mktu', true)">
                Показать только МКТУ
              </button>
            </div>

          </div>

          <div class="response-result-list response-result-list__mobile" [ngSwitch]="searchPopupViewType">

            <!--            all-->
            <div *ngSwitchCase="'all'">
              <div *ngIf="mktuMobileTempArray?.length || okvedMobileTempArray; else classesNotFound">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, okvedMobileTempArray, true)"
                     class="choose__btn choose__btn--all">Выбрать все</a>
                  <a (click)="allMobileSelecting(false, okvedMobileTempArray, true)"
                     class="choose__btn choose__btn--clean">Снять выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of okvedMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'okved')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'okved')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description.length > 39" (click)="showMoreDescription(i, 'okved')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
                <div class="response-result-list__item" *ngFor="let item of mktuMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name mktu-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'mktu')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>{{item.name}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'mktu')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.desc"
                       [innerHTML]="item.desc">
                  </div>
                  <img *ngIf="!item.opened && item.desc?.length > 39" (click)="showMoreDescription(i, 'mktu')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            mktu-->
            <div *ngSwitchCase="'mktu'">
              <div *ngIf="mktuMobileTempArray?.length; else classesNotFound">
                <div class="choose">
                  <a (click)="allMobileSelecting( true, mktuMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать
                    все</a>
                  <a (click)="allMobileSelecting(false, mktuMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять
                    выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of mktuMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name mktu-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'mktu')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>{{item.name}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'mktu')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.desc"
                       [innerHTML]="item.desc">
                  </div>
                  <img *ngIf="!item.opened && item.desc?.length > 39" (click)="showMoreDescription(i, 'mktu')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            mktu sub-->
            <div *ngSwitchCase="'mktuBySearch'">
              <div *ngIf="mktuBySearchMobileTempArray?.length; else mobileSearchByClassesStub">
                <div class="choose">
                  <a (click)="allMobileSelecting( true, mktuBySearchMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать все</a>
                  <a (click)="allMobileSelecting( false, mktuBySearchMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of mktuBySearchMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name mktu-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'mktuBySearch', item.number)"
                             [checked]="item.checkedMobile" class="response-result-list__item--checkbox"
                             type="checkbox">
                      <span>{{item.name}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'mktuBySearch')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.desc"
                       [innerHTML]="item.desc">
                  </div>
                  <img *ngIf="!item.opened && item.desc?.length > 39" (click)="showMoreDescription(i, 'mktuBySearch')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            okved-->
            <div *ngSwitchCase="'okved'">
              <div *ngIf="okvedMobileTempArray?.length; else classesNotFound">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, okvedMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать
                    все</a>
                  <a (click)="allMobileSelecting(false, okvedMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять
                    выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of okvedMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'okved')" [checked]="item.checkedMobile"
                             class="response-result-list__item--checkbox" type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'okved')" class="response-result-list__item__desc "
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description?.length > 39" (click)="showMoreDescription(i, 'okved')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            okved sub-->
            <div *ngSwitchCase="'okvedBySearch'">
              <div *ngIf="okvedBySearchMobileTempArray?.length; else mobileSearchByClassesStub">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, okvedBySearchMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать все</a>
                  <a (click)="allMobileSelecting( false, okvedBySearchMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of okvedBySearchMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'okvedBySearch', item.code)"
                             [checked]="item.checkedMobile" class="response-result-list__item--checkbox"
                             type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'okvedBySearch')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description?.length > 39"
                       (click)="showMoreDescription(i, 'okvedBySearch')" class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <!--            inn-ogrn-->
            <div *ngSwitchCase="'inn-ogrn'">
              <div *ngIf="legalMobileTempArray?.length; else innOgrnStub">
                <div class="choose">
                  <a (click)="allMobileSelecting(true, legalMobileTempArray)"
                     class="choose__btn choose__btn--all">Выбрать
                    все</a>
                  <a (click)="allMobileSelecting( false, legalMobileTempArray)"
                     class="choose__btn choose__btn--clean">Снять
                    выделение у всех</a>
                </div>
                <div class="response-result-list__item" *ngFor="let item of legalMobileTempArray; index as i"
                     [ngClass]="item.selected ? 'response-result-list__item__selected' : ''">
                  <div style="display: flex">
                    <label class="response-result-list__item__name okved-class-code-mobile">
                      <input (change)="checkoutClassMobile($event, item, i, 'legal', item.code)"
                             [checked]="item.checkedMobile" class="response-result-list__item--checkbox"
                             type="checkbox">
                      <span>ОКВЭД {{item.code}}</span>
                    </label>
                  </div>
                  <div (click)="showMoreDescription(i, 'legal')" class="response-result-list__item__desc"
                       [ngClass]="{'response-result-list__item__desc-full': item.opened}" [title]="item.description"
                       [innerHTML]="item.description">
                  </div>
                  <img *ngIf="!item.opened && item.description?.length > 39" (click)="showMoreDescription(i, 'legal')"
                       class="plus-icon" src="/assets/icons/plus.svg">
                </div>
              </div>
            </div>

            <ng-template #classesNotFound>
              <div class="classesNotFound">
                Классы не найдены
              </div>
            </ng-template>

            <ng-template #mobileSearchByClassesStub>
              <div *ngIf="!isLoadingSearchClases; else loader" class="innOgrnStub__mobile">
                После ввода, здесь отобразятся<br>
                виды деятельности по вашему запросу
                <span *ngIf="['okvedBySearch', 'mktuBySearch'].indexOf(searchPopupViewType) > -1">
                  <br>или<br>
                  <a (click)="changeSearchPopupView('all')">Вернуться к классам первого уровня</a>
                </span>
              </div>
            </ng-template>

            <ng-template #innOgrnStub>
              <div *ngIf="!isLoadingLegal; else loader" class="innOgrnStub__mobile">
                После ввода ИНН или ОГРН<br>
                организации здесь отобразятся ее<br>
                виды деятельности
              </div>
            </ng-template>

          </div>

          <button (click)="applyMobileFilterByClasses()" class="apply-filter__mobile">
            Применить выбор
          </button>

        </div>
      </div>

      <ng-template #loader>
        <div style="display: block; height: 265px">
          <div class="loader"></div>
        </div>
      </ng-template>

    </div>
  </div>
</div>
