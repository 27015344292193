import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-tariff',
  templateUrl: './tab-tariff.component.html',
  styleUrls: ['./tab-tariff.component.scss']
})
export class TabTariffComponent implements OnInit {

  showServicesList = false;
  constructor() { }

  ngOnInit(): void {
  }

}
