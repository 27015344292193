import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ILvFilterDetail } from '../../../../../../app-ipid/src/app/shared/components/list-view/lv-filter/lv-filter.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchActions } from '@web/features/corp/corp-search/states/search.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-corp-search-domains',
  templateUrl: './corp-search-domains.component.html',
  styleUrls: ['./corp-search-domains.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorpSearchDomainsComponent implements OnInit, OnChanges {

  @Input() items: any[];
  @Input() itemsPerPage = 10;
  @Input() currentPage: number;
  @Input() totalCount: number;
  @Output() changePage = new EventEmitter<any>();

  @Output() select = new EventEmitter<any[]>();

  page = 1;
  selectedDomain = null;

  constructor(
    private _sanitizer: DomSanitizer,
    private store: Store
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.items?.length) {
      this.select.emit(this.items.filter(i => i.checked));
    }
  }

  filters = {

    name: {
      value: '',
      fn: row => {
        const {value} = this.filters.name;
        if (!value) {
          return true;
        }

        return this.getDomain(row).toLowerCase().includes(value.toLowerCase());
      }
    },

    classes: {
      options: [
        {id: 'ebm_soft', label: 'Программы ЭВМ'},
        {id: 'database', label: 'Базы данных'},
        {id: 'domain', label: 'Домены'},
      ],
      value: [],
      fn: rows => true
    },

    riskLevel: {
      options: [
        {id: 'exists', label: 'Занят'},
        {id: 'free', label: 'Свободен'},
      ],
      value: [],
      fn: row => {
        const {value} = this.filters.riskLevel;
        if (!value.length) {
          return true;
        }
        return value.includes(row._source.free ? 'free' : 'exists');
      },
    },

    registration_date: {
      value: []
    },


  };

  onApplyFilters(allFilters: Array<{ filter: ILvFilterDetail<any>, value: any }>) {
    for (const item of allFilters) {
      this.filters[item.filter.name].value = item.value;
    }

    this.page = 1;
  }

  setPage($event) {
    $event.type = 'domainsPagination';
    this.changePage.emit($event);
  }

  selectDomain(item) {
    this.selectedDomain = item;
    document.body.style.overflow = 'hidden';
  }

  onCloseDomainInfoModal() {
    this.selectedDomain = null;
    document.body.style.overflow = 'visible';
  }

  getDomain(row) {
    return row._source.fullDomain.includes('XN--') ? `${row._source.domain}.${row._source.zone}`.toUpperCase() : row._source.fullDomain.toUpperCase();
  }

  getIcon(row: any) {
    if (row._source.faviconData) {
      return this._sanitizer.bypassSecurityTrustResourceUrl(`${row._source.faviconType},${row._source.faviconData}`);
    }
    return '';// default;
  }

  toggleCheck(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.store.dispatch(new SearchActions.CheckObject(item));

    this.select.emit(this.items.filter(i => i.checked));
  }


}
