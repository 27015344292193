import {ExpertComponent} from './expert.component';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SupportModalModule} from '../support-modal/support-modal.module';

@NgModule({
  declarations: [
    ExpertComponent
  ],
  imports: [
    CommonModule,
    SupportModalModule
  ],
  exports: [ExpertComponent],
})
export class ExpertModule { }
