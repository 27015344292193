import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { UserPhoneState } from './store/user-phone.state';
import { UserPhoneService } from './services/user-phone.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserPhoneComponent } from './components/user-phone.component';

@NgModule({
  declarations: [
    UserPhoneComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    NgxsModule.forFeature([UserPhoneState]),
  ],
  providers: [UserPhoneService],
  exports: [UserPhoneComponent],
})
export class UserPhoneModule { }
