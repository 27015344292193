<div *ngIf="!isLoading; else load">
  <section class="section-position section-position--gradient">
    <div class="container control">
      <button class="control__btn"
              (click)="switchTab('from-portfolio'); "
              [class.control__btn--active]="tab === 'from-portfolio'">Договоры из портфеля
      </button>
      <button class="control__btn"
              [disabled]="false"
              (click)="switchTab('from-number')"
              [class.control__btn--active]="tab === 'from-number'">Поиск по номеру договора
      </button>
      <button class="control__btn" (click)="switchTab('user-version')"
              [class.control__btn--active]="tab === 'user-version'"
              [disabled]="false">Указать вручную
      </button>
    </div>
  </section>


  <section class="section-position section-position--white">
     <span class="control__mobile" [class.control__mobile--active]="showMobileFilter"
           *ngIf="tab !== 'user-version'"
           (click)="showMobileFilter = true; makeBodyHidden()">
          <p>Поиск по договорам</p>
     </span>
    <span *ngIf="showMobileFilter && tab !== 'user-version'" class="control__mobile--close" (click)="showMobileFilter=false;makeBodyHidden()"></span>
    <div *ngIf="showMobileFilter && tab !== 'user-version'" (click)="showMobileFilter = false; makeBodyHidden()" class="overlay overlay--search"></div>

    <!--  Договоры из портфеля  -->
    <div *ngIf="tab === 'from-portfolio'">
      <div class="container container__tabs container__bottom" >
        <form class="search"
              [ngClass]="showMobileFilter === true? 'mobile': ''">
          <input class="search__input"
                 [(ngModel)]="searchTerm"
                 (ngModelChange)="searchContracts()"
                 [ngModelOptions]="{standalone: true}"
                 placeholder="Поиск по договорам"/>
          <div class="search__wrapper">
            <div class="dropdown" style="max-width: initial">
              <button class="dropdown__button" (click)="showTypeContract = !showTypeContract" style="outline: none;">
                <span *ngIf="getCountCheckedObjects() === 0" class="dropdown__button-text">{{ getCountCheckedObjects() === 0 ? 'Вид договора:' : 'Вид договора:'}}</span>
                <span *ngIf="getCountCheckedObjects() > 0" class="dropdown__button-text dropdown__button-text--check">
              Вид договора: {{getCountCheckedObjects()}}</span>
                <img
                  class="dropdown__button-img"
                  [ngClass]="showTypeContract ? 'rotate': ''"
                  src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
              </button>
              <div *ngIf="showTypeContract" class="dropdown__menu-wrap">
                <div class="dropdown__menu">
                  <span class="dropdown__menu-title">Поиск по договорам</span>
                  <ng-container *ngFor="let option of typeOfContracts">
                    <input class="visual-hidden dropdown__input"
                           type="checkbox"
                           name="{{option.value}}"
                           [id]="option.id + 'type'"
                           [(ngModel)]="option.checked"
                           (ngModelChange)="filterByTypes()">
                    <label class="dropdown__label" [for]="option.id + 'type'">{{option.value}}</label>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="showTypeContract" (click)="showTypeContract = false;"
               class="overlay overlay--searchList"
               style="background: none;
                      z-index: 4;">
          </div>
          <div class="search__btns-wrapper">
            <button class="btn-blue btn-search" (click)="applyFilters(); showMobileFilter=false;makeBodyHidden()">Найти
            </button>
            <button class="btn-blue-attr btn-clean" (click)="clearFilters()">Очистить</button>
          </div>
        </form>
        <div class="row-white" *ngFor="let contract of contracts.slice(countPage * (page - 1), countPage * page)" [class.row-white--green]="contract.checked" >
          <div class="row-white__img-text">

            <div class="row-white__title-text">
              <p class="row-white__text row-white__text--bold">{{trimString(contract.name, 29)}}</p>
              <span class="row-white__text row-white__text--gray">от {{contract.date | date:'dd.MM.yyyy'}}</span>
            </div>
          </div>

          <div class="row-white__title-text row-white__title-text--date">
            <p class="row-white__text row-white__text--arrow">{{trimString(contract.assignor?.name, 16)}}</p>
            <p class="row-white__text">{{trimString(contract.assignee?.name,20)}}</p>
          </div>

          <div class="row-white__title-text row-white__title-text--total">
            <span class="row-white__total row-white__text--gray">{{createLabelObjects(contract.Objects)}}</span>
          </div>

          <div class="row-white__title-text row-white__title-text--btn">
            <input type="checkbox" [id]="contract.id" [(ngModel)]="contract.checked" (ngModelChange)="checkObject(contract, contract.checked, contracts)">
            <label [for]="contract.id" *ngIf="!contract.checked" class="row-white__btn-choose">Выбрать объект</label>
            <label [for]="contract.id" *ngIf="contract.checked" class="row-white__btn-choose--done">
              <span class="row-white__choose">Выбран</span>
              <span  class="row-white__delete"></span>
            </label>
          </div>

        </div>
      </div>
      <div class="container container__tabs container__tabs--pag" *ngIf="contracts.length > countPage">
        <app-pagination
          [page]="page"
          [currentPageNumber]="page"
          [totalCount]="contracts.length"
          [itemPerPage]="countPage"
          (eventChangePage)="changePage($event)"
        ></app-pagination>
      </div>
    </div>

    <!--  Поиск по номеру договора  -->
    <div *ngIf="tab === 'from-number'">
      <div class="container container__tabs container__bottom">
        <form class="search"
              [ngClass]="showMobileFilter === true? 'mobile': ''">
          <input class="search__input search__input--100"
                 placeholder="Номер договора"
                 [class.invalid-red]="searchError"
                 [(ngModel)]="searchTerm"
                 [ngModelOptions]="{standalone: true}"/>
          <div class="search__btns">
            <button class="search__btn btn-search" (click)="searchContract(); showMobileFilter = false; makeBodyHidden()">Найти</button>
          </div>
        </form>
        <div class="row-white" *ngFor="let one of resultsSearchContracts" [class.row-white--green]="one.checked" >
          <div class="row-white__img-text">

            <div class="row-white__title-text">
              <p class="row-white__text row-white__text--bold">{{trimString(one.contractType, 29) + ' № ' + one.contractNumber}}</p>
              <span class="row-white__text row-white__text--gray">от {{one.contractDate | date:'dd.MM.yyyy'}}</span>
            </div>
          </div>

          <div class="row-white__title-text row-white__title-text--date">
            <p class="row-white__text row-white__text--arrow">{{trimString(one.assignor?.shortLegalName, 16)}}</p>
            <p class="row-white__text">{{trimString(one.assignee?.shortLegalName,20)}}</p>
          </div>

          <div class="row-white__title-text row-white__title-text--total">
            <span class="row-white__total row-white__text--gray">{{createLabelObjects(one.tradeMarks)}}</span>
          </div>

          <div class="row-white__title-text row-white__title-text--btn">
            <input type="checkbox" [id]="one.id" [(ngModel)]="one.checked" (ngModelChange)="checkObject(one, one.checked, resultsSearchContracts)">
            <label [for]="one.id" *ngIf="!one.checked" class="row-white__btn-choose">Выбрать объект</label>
            <label [for]="one.id" *ngIf="one.checked" class="row-white__btn-choose--done">
              <span class="row-white__choose">Выбран</span>
              <span  class="row-white__delete"></span>
            </label>
          </div>

        </div>
      </div>
    </div>

    <!--  Указать вручную  -->
    <div *ngIf="tab === 'user-version'">
      <div class="container container__tabs container__bottom container__tabs--user">
        <form [formGroup]="manuallyForm" class="user-search">
          <input class="user-search__input"
                 formControlName="contractNumber"
                 (ngModelChange)="setValue()"
                 placeholder="Номер договора"/>
          <input class="user-search__input"
                 formControlName="contractDate"
                 (ngModelChange)="setValue()"
                 [placeholder]="'Дата договора'"
                 bsDatepicker
          />
          <div *ngIf="showTypeContract" (click)="showTypeContract = false;"
               class="overlay overlay--searchList"
               style="background: none;
                      z-index: 4;">
          </div>
          <div class="dropdown dropdown-user-version" style="max-width: initial;">
            <button class="dropdown__button" (click)="showTypeContract = !showTypeContract" style="outline: none;">
              <span *ngIf="getTypesName()?.length > 0" class="dropdown__button-text dropdown__button-text--check">{{getTypesName()}}</span>
              <span *ngIf="getTypesName()?.length === 0" class="dropdown__button-text">Тип договора</span>
              <img
                class="dropdown__button-img"
                [ngClass]="showTypeContract ? 'rotate': ''"
                src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
            </button>
            <div *ngIf="showTypeContract" class="dropdown__menu-wrap">
              <div class="dropdown__menu">
                <span class="dropdown__menu-title">Поиск по договорам</span>
                <ng-container *ngFor="let option of options">
                  <input class="search__option-input"
                         type="radio"
                         name="contractType"
                         value="{{option.value}}"
                         formControlName="contractType"
                         id="{{option.id + 'type'}}"
                         [(ngModel)]="option.checked"
                         (ngModelChange)="setValue(option.checked, option)">
                  <label class="search__option-label search__option-label--round" for="{{option.id + 'type'}}">{{option.name}}</label>
                </ng-container>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
<ng-template #load>
  <div class="loader"></div>
</ng-template>
