import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MonitoringComponent } from './monitoring.component';
import { AddObjectComponent } from './add-object/add-object.component';
import { TariffForContestationComponent } from './tariff-for-contestation/tariff-for-contestation.component';
import { MonitoringLandingComponent } from './monitoring-landing/monitoring-landing.component';
import {MonitoringGuard} from './monitoring.guard';


const systemRoutes: Routes = [
  {
    path: '',
    component: MonitoringComponent,
    canActivate: [MonitoringGuard]
  },
  {
    path: 'add/:id',
    component: AddObjectComponent,
  },
  {
    path: 'tariff-for-contestation',
    component: TariffForContestationComponent,
  },
  {
    path: 'landing',
    component: MonitoringLandingComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(systemRoutes)],
  exports: [RouterModule]
})
export class MonitoringRoutingModule {
}
