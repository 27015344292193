import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-claim-page',
  templateUrl: './claim-page.component.html',
  styleUrls: ['./claim-page.component.scss']
})
export class ClaimPageComponent implements OnInit {
  docs = false;
  events = true;
  claimsInfo = false;

  title = 'Входящая претензия от 15.01.2021 № 555435';
  addDoc = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  setResizableWidth(text, titleDiv) {
    titleDiv.focus();
    titleDiv.style.width = text.offsetWidth + 'px';
  }

  getChangedTitle(titleDiv) {
    this.title = titleDiv.innerHTML;
  }


  closeModal() {
    this.addDoc = false;
  }

  openModal() {
    this.addDoc = true;
  }

}
