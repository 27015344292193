import { Duty } from '../../types/trademark.types';
import { TrademarkState } from '../../store/trademark.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Tariff } from '@ui/features/tariffes/models/rates.interfaces';
import { environment } from '../../../../../../app-ipid/src/environments/environment';

@Component({
  selector: 'app-rospatent-duties',
  templateUrl: './rospatent-duties.component.html',
  styleUrls: ['./rospatent-duties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RospatentDutiesComponent {
  @ViewSelectSnapshot(TrademarkState.duty) duty: Duty;
  @ViewSelectSnapshot(TrademarkState.MKTUCodes) MKTUCodes: number[];
  @ViewSelectSnapshot(TrademarkState.activeTariff) tariff: Tariff | null;
  public brand: string = environment.BRAND;
}
