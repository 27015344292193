const colors = {
  $blue_1c: '#00AFEC',
};

export const baseEnvironmentDebt = {
  production: false,
  BASE_URL_API: window.location.host.includes('localhost')
    ? 'http://master.ipid.lkdev.9958258.ru/api'
    : `${window.location.protocol}//${window.location.host}/api`,

  BASE_ELASTIC_URL_API: 'https://test-api.9958258.ru',
  BRAND: 'LEGAL_FREE',
  TITLE: 'LEGAL_FREE',
  LOGO_PATH: '/assets/images/logo-debitorka.svg',
  LOGO2_PATH: '/assets/images/logo-debitorka.svg',
  FOOTER_IMAGE_PATH: '/assets/images/gardium_pro_girl.png',
  FOOTER_IMAGE_MOBILE_PATH: '/assets/images/mobile/footer-image.png',
  FOOTER_TEXT: '© 2022 «Гардиум» ― юристы для бизнеса онлайн',
  FAVICON: '/assets/fav/favicon.ipid.ico',
  STATIC_FILES: '/assets/static',
  // Константы для поиска /search страница
  SEARCH_STYLES: {
    SEARCH_POSIBILITY_TEXT: 'Высокая с IP-ID',
    SEARCH_HEADER_BACKGROUND: '#F2F4F8',
    SEARCH_HEADER_TEXT_COLOR: '#152438',
    SEARCH_SUBMIT_BUTTON_BACKGROUND: '#FF1037',
    SEARCH_SUBMIT_BUTTON_COLOR: '#FFFFFF',
    SEARCH_CARDS_BLOCK_BACKGROUND: '#EBEDF2',
    SEARCH_SPEEDOMETER_BACKGROUND: '#F5F5F6',
    SEARCH_SPEEDOMETER_OUTLINE: 'rgba(21, 36, 56, 0.15)',
  },

  SEARCH_CUSTOM_STYLES: `
    .ip-id-search-risks-submit .request-btn {
      background: ${colors.$blue_1c}!important;
     }
  `,
};
