<div class="docs__blocks">
  <div *ngFor="let file of files" class="docs__block">
    <a class="docs__block-link" (click)="downloadFiles.downloadFile(file)">
      <div class="docs__text-wrapper">
        <span>{{file.fileName}}</span>

      </div>
      <div class="docs__icon-wrapper">
        <span class="docs__icon">
<!--              <img src="/assets/icons/warning.png" class ="docs__icon&#45;&#45;mini">-->
          <svg width="50" height="50" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="25"/>
            <path
              d="M25.9844 22H31.5156L25.9844 16.5156V22ZM19 15.0156H27.0156L33.0156 21.0156V33.0156C33.0156 33.5469 32.8125 34 32.4062 34.375C32.0312 34.7812 31.5625 34.9844 31 34.9844H19C18.4375 34.9844 17.9531 34.7812 17.5469 34.375C17.1719 34 16.9844 33.5469 16.9844 33.0156V16.9844C16.9844 16.4531 17.1719 16 17.5469 15.625C17.9531 15.2188 18.4375 15.0156 19 15.0156ZM24.0156 28V25H22V28H19V30.0156H22V33.0156H24.0156V30.0156H27.0156V28H24.0156Z"
              fill="white"/>
          </svg>
        </span>
      </div>
    </a>
  </div>
</div>
