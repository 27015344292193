import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { SupportChatService } from '../../../../../../shared/services/support-chat.service';
import {document} from 'ngx-bootstrap/utils';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';


@Component({
  selector: 'app-expert',
  templateUrl: './expert.component.html',
  styleUrls: ['./expert.component.scss']
})
export class ExpertComponent implements OnInit {
  footerImage = environment.FOOTER_IMAGE_PATH;
  footerImageMobile = environment.FOOTER_IMAGE_MOBILE_PATH;
  showModal = false;

  btns = [
    'Консультация по результатам поиска',
    'Вопрос по текущему делопроизводству',
    'Консультация по услугам',
    'Запрос первичных документов',
    'Другое',
    'Обращение в техническую поддержку'
  ];

  constructor(
    private readonly supportChatService: SupportChatService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  ngOnInit(): void {
  }

  onClickBtn(btnTitle: string) {
    if (btnTitle === 'Обращение в техническую поддержку') {
      this.showModal = true;
      if (this.showModal) {
        document.getElementsByTagName('header')[0].style.zIndex = 'initial';
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
      }
    } else {
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_ASK_PP });
      this.supportChatService.open(btnTitle);
    }
  }

  closeModal() {
    this.showModal = false;
  }
}
