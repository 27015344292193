<div class="tariffes">
  <div
    *ngFor="let tariff of tariffes; let i = index"
    class="tariff-card"
    [ngStyle]="{'width': (mobile || tariffes.length === 1) ? '100%' : tariffes.length === 2 ? '49%' : '31%'}"
  >
    <app-tariff-card
      [id]="'tariff' + i" 
      [tariff]="tariff"
      [mobile]="mobile"
      [request]="requestType"
      [active]="tariff.id === activeTariff"
      (select)="selectTariff($event)"
    ></app-tariff-card>
  </div>
</div>
