import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-balance',
  templateUrl: './change-balance.component.html',
  styleUrls: ['./change-balance.component.scss']
})
export class ChangeBalanceComponent implements OnInit {
  form: FormGroup;
  online = true;
  cash = false;

  constructor(private fb: FormBuilder,
              private location: Location) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  openOnline(): void {
    this.online = true;
    this.cash = false;
  }

  openCash(): void {
    this.cash = true;
    this.online = false;
  }

  initForm(): void {
    this.form = this.fb.group({
      sum: ['', [Validators.required]],
      INN: ['', [Validators.required]],
      OGRN: ['', [Validators.required]],
      name: ['', [Validators.required]],
      address: ['', [Validators.required]]
    });
  }

  backClicked() {
    this.location.back();
  }
}
