import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../../app-ipid/src/app/shared/services/http.service';
import { SearchService } from '../../../../app-search/src/app/services/search.service';
import {UserProfileService} from '../../../services/user-profile.service';
import {IOrg} from '../../../../app-ipid/src/app/shared/models/main.interfaces';
import {EnvService} from '../../../../app-ipid/src/app/shared/services/env.service';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrls: ['./payer.component.scss']
})
export class PayerComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  @Output() payerSelectEvent = new EventEmitter();
  @Input() isDebit: boolean;
  @Input() owners?: object[];
  @Input() recipients?: object[];
  @Input() organizations?: object[];
  company = []; // список организаций пользователя
  showOptions = false; // показать/скрыть выпадающий список плательщиков
  selectedPayerFromList; // выбранный плательщик из списка компаний

  formPayer: FormGroup; // форма плательщик по инн
  tempPayer; // найденный плательщик по инн
  selectedPayerFromSearch; // выбранный плательщик по инн

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private userProfileService: UserProfileService,
    readonly envService: EnvService
  ) {
  }

  ngOnInit(): void {
    this._initFormPayer();
    if (!this.organizations?.length) {
      this.getOrganizations();
    } else {
      this.company = this.organizations;
    }
    // this.company = this.userProfileService.getOrganizationsData();

    if (this.isDebit) {
      this.selectedPayerFromList = 'another';
      this.showOptions = false;
    } else {
      this.selectedPayerFromList = undefined;
    }
  }

  getOrganizations() {
    this.userProfileService.getOrganizations().subscribe((data: IOrg) => {
      if (data && data.organizations) {
        data.organizations.forEach(el => {
          this.company.push(el);
        });
        if (this.recipients?.length && this.owners?.length) {
          const arr = [...this.recipients, ...this.owners];
          const ids = new Set([
            ...data.organizations.map(org => org.TIN),
          ]);

          arr.forEach((el: any) => {
            if (!Array.from(ids).includes(el.inn || el.TIN)) {
              this.company.push(el);
            }
          });
        }
        if (this.company?.length > 0 && this.isDebit) {
          this.selectedPayerFromSearch = this.company[0];
          this.formPayer.get('payer').setValue(this.selectedPayerFromSearch.TIN);
          this.payerSelectEvent.emit(this.company[0]);
        }
      }
    });
  }

  emptyItemClick() {
    this.selectedPayerFromList = null;
    this.showOptions = false;
    this.payerSelectEvent.emit();
  }

  anotherItemClick() {
    this.selectedPayerFromList = 'another';
    this.showOptions = false;
    this.payerSelectEvent.emit();
  }

  /**
   * инициализация формы плательщика
   */
  private _initFormPayer(): void {
    this.formPayer = this.fb.group({
      payer: [null, [Validators.required]],
    });
  }


  onSelect(comp): void {
    this.selectedPayerFromList = comp;
    this.showOptions = false;
    this.tempPayer = undefined;
    this.selectedPayerFromSearch = undefined;
    this.payerSelectEvent.emit(this.selectedPayerFromList);
  }

  public getCompanyName(comp): string {
    return comp.TIN || comp.inn
      ? `${comp.TIN || comp.inn}<span> / ${comp.name || comp.fullName}</span>`
      : `<span>${comp.name || comp.fullName}</span>`;
  }

  payerChanged(innOgrn: string) {
    if (this.selectedPayerFromSearch?.TIN !== innOgrn) {
      this.tempPayer = undefined;
      if (this.envService.isCorp || (!this.envService.isCorp && !!+innOgrn === true)) {
        this.selectedPayerFromSearch = undefined;
      } else if (!this.envService.isCorp && !!+innOgrn === false) {
        this.selectedPayerFromSearch = innOgrn;
        this.payerSelectEvent.emit(this.selectedPayerFromSearch);
      }
      if (innOgrn.length > 2) {
        this.getFullPayer(innOgrn);
      }
    }
  }

  getFullPayer(id) {
    const query = {
      id,
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits[0]?._source && id === this.formPayer.controls.payer.value) {
        this.tempPayer = data.hits.hits[0]._source;
      }
    });
  }

  checkValidInnOgrn(value) {
    return value && !isNaN(+value) && (value.length === 10 || value.length === 12 || value.length === 13 || value.length === 15);
  }

  payerSelected() {
    this.selectedPayerFromSearch = this.tempPayer;
    this.tempPayer = undefined;
    if (!this.checkValidInnOgrn(this.formPayer.get('payer').value)) {
      this.formPayer.get('payer').setValue(this.selectedPayerFromSearch.TIN);
    }
    this.payerSelectEvent.emit(this.selectedPayerFromSearch);
  }

  ngOnDestroy(): void {
  }

  getDynamicMarginForLegalName() {
    switch (this.formPayer.get('payer').value.length) {
      case 10:
        return {left: '132px'};
      case 12:
        return {left: '150px'};
      case 13:
        return {left: '158px'};
      case 15:
        return {left: '174px'};
    }
  }

  cancelPatentee() {
    this.tempPayer = undefined;
    this.selectedPayerFromSearch = this.formPayer.get('payer').value;
    this.payerSelectEvent.emit(this.selectedPayerFromSearch);
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  getLegalName() {
    return window.innerWidth < 768 ?
      this.trimString(this.selectedPayerFromSearch?.name || this.selectedPayerFromSearch?.fullName, 17) :
      this.trimString(this.selectedPayerFromSearch?.name || this.selectedPayerFromSearch?.fullName, 65);
  }

}
