import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CasesService } from '../cases.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {EnvService} from '../../../shared/services/env.service';
import { REQUEST_TYPES } from '@web/core/models/user.interface';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';

@Component({
  selector: 'app-cases-item',
  templateUrl: './cases-item.component.html',
  styleUrls: ['./cases-item.component.scss']
})
export class CasesItemComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  // вкладки
  activeTab = 'events';
  noInfo = false;
  eventsLength;
  noObjects = false;
  files = [];
  private readonly onDestroy = new Subject<void>();
  logo = environment.LOGO_CASES_PATH;
  caseData;
  $events;
  $tracks;
  caseNumber;
  isDraft = false;

  constructor(
    private route: ActivatedRoute,
    private casesAPI: CasesService,
    private router: Router,
    readonly envService: EnvService,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((data: any) => {
      const id = data.params.id;
      this.route.queryParams.subscribe(params => {
        if (params.isDraft) {
          if (params.isDraft === 'false') {
            this.getCase(id);
          } else {
            this.isDraft = true;
            this.getCase(id, this.isDraft);
          }
        }
      });
    });
  }

  getCase(id, isDraft?) {
    const params: any = {};
    if (isDraft) {
      params.isDraft = isDraft;
    }
    this.casesAPI.getCase(params, id).pipe(takeUntil(this.onDestroy))
      .subscribe((data: any) => {
        // cases
        const info = data?.case?.tasks?.items?.filter(t => t.ID === id);
        if (info?.length) {
          this.caseData = info[0];
        }

        // events
        this.$events = data?.events;
        if (this.$events?.length) {
          // files
          const test = [];
          this.$events.forEach(item => {
            if (item.popupFiles.length) {
              test.push(item.popupFiles[0]);
            }
          });
          // TODO: может стоит и убрать
          this.files = data.files;
        } else {
          this.noObjects = true;
        }
        // tracks
        this.$tracks = data?.tracks.filter(el => el.name !== 'Выбор риска');
      });
  }

  changeTab(tabName) {
    this.activeTab = tabName;
  }

  getStepsText() {
    switch (this.caseData.lastStage) {
      case 0:
      case 1:
        if (this.caseData.type === 'trademark') {
          return `Для завершения оформления заявки вам необходимо <br> Выбрать классы`;
        } else if (this.caseData.type === 'monitoring') {
          return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
        } else {
          return `Для завершения оформления заявки вам необходимо <br> Заполнить информацию`;
        }
      case 2:
        if (this.caseData.type === 'monitoring') {
          return `Для завершения оформления заявки вам необходимо <br> произвести оплату`;
        } else if (this.caseData.type === 'contract') {
          return `Для завершения оформления заявки вам необходимо <br> Заполнить информацию`;
        } else {
        return `Для завершения оформления заявки вам необходимо <br> Выбрать тариф`;
      }
      case 3:
        // TODO: вернуть назад поэтапную оплату
        // if (this.caseData.type === 'monitoring') {
        //   return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
        // } else if (this.caseData.type === 'contract' || (this.caseData.type === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline))) {
        //   return `Для завершения оформления заявки вам необходимо <br> Заполнить информацию`;
        // } else {
        //   return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
        // }
        if (this.caseData.type === 'monitoring') {
          return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
        } else {
          return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
        }
      case 4:
        // TODO: вернуть назад поэтапную оплату
        // if (this.caseData.type === 'trademark' && (this.envService.isGardiumOnline || this.envService.isAlfa)) {
        //   return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
        // } else {
        //   return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
        // }
        return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
      case 5:
        if (this.caseData.type === 'contract') {
          return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
        } else {
          return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
        }
      case 6:
        return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
      case 7:
        return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
      default:
        return null;
    }
  }

  sendDraft(id, type) {
    if (id) {
      if (type === 'monitoring') {
        this.router.navigate(['/monitoring/add/' + id]);
      } else if (type === 'contract') {
        this.router.navigate(['/request-contract'], {queryParams: {id}});
      } else if (type === 'checkingLabels') {
        this.router.navigate(['/request-check-label'], {queryParams: {id}});
      } else {
        if (type === REQUEST_TYPES.TRADEMARK || type === REQUEST_TYPES.TRADEMARK_SEARCH) {
          void this.router.navigate(['/', APP_ROUTES_NAMES.REQUESTS, type, id]);
        } else {
          this.router.navigate(['/request'], {queryParams: {type, id}});
        }
      }
    } else {
      console.log('no draft');
    }
  }

  getStepsData() {
    switch (this.caseData.lastStage) {
      case 0:
      case 1:
        if (this.caseData.type === 'trademark') {
          return `Выбрать классы`;
        } else if (this.caseData.type === 'monitoring') {
          return 'Выбрать тариф';
        } else {
          return 'Заполнить информацию';
        }
      case 2:
        if (this.caseData.type === 'monitoring') {
          return 'Оплатить';
        } else if (this.caseData.type === 'contract') {
          return `Заполнить информацию`;
        } else {
        return `Выбрать тариф`;
      }
      case 3:
        // TODO: вернуть назад поэтапную оплату
        // if (this.caseData.type === 'contract' || (this.caseData.type === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline))) {
        //   return 'Заполнить информацию';
        // } else {
        //   return `Оплатить`;
        // }
      case 4:
        return `Оплатить`;
      case 5:
        if (this.caseData.type === 'contract') {
          return `Выбрать тариф`;
        } else {
          return `Заполнить информацию`;
        }
      case 6:
        if (this.caseData.type === 'contract') {
          return `Оплатить`;
        } else {
          return `Заполнить информацию`;
        }
      default:
        return null;
    }
  }

  deleteTask(id) {
    const params = {
      responseID: id
    };
    this.casesAPI.deleteTask(params).pipe(takeUntil(this.onDestroy))
      .subscribe(deletedData => {
        if (deletedData.answer === 'Успешно') {
          this.router.navigate(['/cases']);
        } else {
          console.log(deletedData.answer, 'err');
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
