<div
  class="ipid-search-header"
  *ngIf="!withResults; else hasResults">
  Введите обозначение для оценки<br> вероятности регистрации
</div>

<ng-template #hasResults>
  <div class="ipid-search-header-with-result">
    <div class="text-title">
      Оценка вероятности регистрации
    </div>
  </div>
</ng-template>

<app-ip-id-search
  [designation]="searchTerm"
  [options]="options"
  (searchHappened)="searchHappened($event)"
>
</app-ip-id-search>

<div class="align-empty-block" *ngIf="!withResults"></div>

<app-expert>
</app-expert>

