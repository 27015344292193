<section class="section-position section-position--bg section-position--main" [class.empty]="!items?.length">
  <div class="container container--bg">
    <!--          <span  class="control__mobile">-->
    <!--              <p>Фильтр по фирменным наименованиям</p>-->
    <!--          </span>-->

    <div *ngIf="!items?.length" class="empty-results">
      К сожалению, ничего не найдено. Попробуйте изменить запрос.
    </div>

    <app-list-view
      *ngIf="items?.length"
      [sourceData]="items"
      [currentPage]='currentPage'
      [totalCount]="totalCount"
      [itemsPerPage]="itemsPerPage"
      (changePage)="setPage($event)"
      [filterType]="'фирменным наименованиям'"
      (applyFilters)="onApplyFilters($event)">

      <!--      <lvFilter lvFilterInput-->
      <!--                name="name"-->
      <!--                className="size&#45;&#45;md"-->
      <!--                placeholder="Поиск по тексту"-->
      <!--                [value]="filters.name.value"-->
      <!--                [filterFn]="filters.name.fn"-->
      <!--      ></lvFilter>-->

      <!--      <lvFilter lvFilterMultiselect-->
      <!--                name="classes"-->
      <!--                title="Классы ОКВЭД"-->
      <!--                [options]="filters.classes.options"-->
      <!--                [value]="filters.classes.value"-->
      <!--                [filterFn]="filters.classes.fn"-->
      <!--      ></lvFilter>-->

      <!--      <lvFilter lvFilterMultiselect-->
      <!--                name="riskLevel"-->
      <!--                title="Степень сходства"-->
      <!--                [options]="filters.riskLevel.options"-->
      <!--                [value]="filters.riskLevel.value"-->
      <!--                [filterFn]="filters.riskLevel.fn"-->
      <!--      ></lvFilter>-->

      <!--      <lvFilter lvFilterDaterange-->
      <!--                name="registration_date"-->
      <!--                placeholder="Дата регистрации"-->
      <!--                [value]="filters.registration_date.value"-->
      <!--                filterField="_source.dateOfPSRN"-->
      <!--      ></lvFilter>-->

      <ng-template let-rows lvData>
        <div class="table-corp">
          <div class="table-corp__header">
            <p class="head-table__first"><span>Краткое наименование</span></p>
            <p class="head-table__second">ИНН</p>
            <p class="head-table__third">ОГРН</p>
            <p class="head-table__fourth">Дата регистрации</p>
            <p class="head-table__fifth">Регион</p>
            <p class="head-table__fifth">ОКВЭД</p>
            <p class="head-table__fifth">Степень сходства</p>
            <p class="head-table__six"><span>Выбрать</span></p>
          </div>
          <div *ngFor="let sourceData of rows"
               class="table-corp__row"
               [class.table-corp__row--active]="sourceData.checked">
            <div class="table-corp__legalname" (click)="selectLegal(sourceData)">
              {{sourceData._index === 'organizations' ? trimString(sourceData._source.name, 30) : trimString(sourceData._source.fullName, 30)}}
              <span
                class="comment-new__tooltip">{{sourceData._index === 'organizations' ? sourceData._source.name : sourceData._source.fullName}}</span>
            </div>
            <div class="table-corp__inn" (click)="selectLegal(sourceData)">
              <span class="table-corp__mobile-txt">ИНН</span>
              {{sourceData._source.TIN}}
            </div>
            <div class="table-corp__ogrn" (click)="selectLegal(sourceData)">
              <span class="table-corp__mobile-txt">ОГРН</span>
              {{sourceData._source.PSRN}}
            </div>
            <div class="table-corp__registerDate" (click)="selectLegal(sourceData)">
              <span class="table-corp__mobile-txt">Дата регистрации</span>
              <p *ngIf="sourceData._source.dateOfPSRN">{{sourceData._source.dateOfPSRN | date:'dd.MM.yyyy'}}</p>
            </div>
            <div class="table-corp__region" (click)="selectLegal(sourceData)">
              <span class="table-corp__mobile-txt">Регион</span>
              {{getRegionName(sourceData)}}
            </div>
            <div class="table-corp__okved">
              <span class="table-corp__mobile-txt">ОКВЭД</span>
              <app-okved-list-view
                [codes]="sourceData._source.OKVEDCodes"
                [countriesDropdown]="countriesDropdown">
              </app-okved-list-view>
            </div>
            <div class="table-corp__state" (click)="selectLegal(sourceData)">
              <span class="table-corp__mobile-txt">Степень сходства</span>
<!--              <app-risk-badge [risk]="sourceData._risk"></app-risk-badge>-->
            </div>
            <div class="table-corp__checkbox">
              <input class="table-corp__checkbox--input" id="{{sourceData.id}}" type="checkbox"
                     [(ngModel)]="sourceData.checked">
              <label class="table-corp__checkbox--label" for="{{sourceData.id}}"
                     (click)="toggleCheck($event, sourceData)"><span class="table-corp__mobile-blue"
                                                                     [class.table-corp__mobile-blue--delete]="sourceData.checked">
        {{sourceData.checked ? 'Убрать выбор' : 'Выбрать для отчета'}}</span></label>
            </div>
          </div>
        </div>
      </ng-template>

    </app-list-view>
  </div>

  <div class="zzmodal" *ngIf="selectedLegal">
    <app-legal-name-modal
      class="app-domain-modal"
      [data]="selectedLegal"
      (modalEvent)="onCloseLegalModal()"
    ></app-legal-name-modal>
  </div>

</section>
