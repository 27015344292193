import { catchError, map } from 'rxjs';
import { Injectable } from '@angular/core';
import { patch } from '@ngxs/store/operators';
import { UserPhoneActions } from './user-phone.actions';
import { NgxsForm } from '@web/core/models/user.interface';
import { AuthActions } from '@web/core/states/auth.actions';
import { UserPhoneService } from '../services/user-phone.service';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

export interface UserPhoneStateModel {
  codeError: string;
  phoneError: string;
  codeSended: boolean;
  brand: string | null;
  showSuccess: boolean;
  form: NgxsForm<{ phone: string, code: string }>;
}

const USER_PHONE_STATE_TOKEN = new StateToken<UserPhoneStateModel>('userPhone');
const USER_PHONE_INITIAL_STATE: UserPhoneStateModel = {
  brand: null,
  codeError: '',
  phoneError: '',
  codeSended: false,
  showSuccess: false,
  form: { model: { phone: '', code: '' } },
};

@State({
  name: USER_PHONE_STATE_TOKEN,
  defaults: USER_PHONE_INITIAL_STATE,
})
@Injectable()
export class UserPhoneState {
  private static readonly SUCCESS = 'Успешно';

  constructor(
    private readonly userPhoneService: UserPhoneService,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  @Selector()
  public static codeSended(state: UserPhoneStateModel) {
    return state.codeSended;
  }

  @Selector()
  public static phoneError(state: UserPhoneStateModel) {
    return state.phoneError;
  }

  @Selector()
  public static showSuccess(state: UserPhoneStateModel) {
    return state.showSuccess;
  }

  @Selector()
  public static codeError(state: UserPhoneStateModel) {
    return state.codeError;
  }

  @Action(UserPhoneActions.SetCurrentBrand)
  public setCurrentBrand(
    { setState }: StateContext<UserPhoneStateModel>,
    { payload }: UserPhoneActions.SetCurrentBrand,
  ) {
    setState(patch<UserPhoneStateModel>({ brand: payload }));
  }

  @Action(UserPhoneActions.SendUserPhoneCode)
  public sendUserPhoneCode(
    { getState, dispatch }: StateContext<UserPhoneStateModel>,
  ) {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SENT_SMS });
    return this.userPhoneService.sendUserPhoneCode(getState().form.model.phone, getState().brand).pipe(
      map(response => dispatch(new UserPhoneActions.SendUserPhoneCodeSuccess(response))),
      catchError(() => dispatch(new UserPhoneActions.SendUserPhoneCodeFail())),
    );
  }

  @Action(UserPhoneActions.SendUserPhoneCodeSuccess)
  public sendUserPhoneCodeSuccess(
    { setState }: StateContext<UserPhoneStateModel>,
    { payload }: UserPhoneActions.SendUserPhoneCodeSuccess,
  ) {
    setState(patch<UserPhoneStateModel>({
      codeSended: payload.answer === UserPhoneState.SUCCESS,
      phoneError: payload.answer !== UserPhoneState.SUCCESS ? payload.answer : '',
    }));
  }

  @Action(UserPhoneActions.RemoveSuccess)
  public removeSuccess(
    { setState }: StateContext<UserPhoneStateModel>,
    { payload }: UserPhoneActions.RemoveSuccess,
  ) {
    setState(patch<UserPhoneStateModel>({ showSuccess: payload }));
  }

  @Action(UserPhoneActions.CheckUserPhoneCode)
  public checkUserPhoneCode(
    { getState, dispatch }: StateContext<UserPhoneStateModel>,
  ) {
    if (getState().form.model.code?.length === 4) {
      const body  = {
        phone: getState().form.model.phone,
        validationCode: getState().form.model.code,
      };
      return this.userPhoneService.checkUserPhoneCode(body, getState().brand).pipe(
        map(respose => dispatch(new UserPhoneActions.CheckUserPhoneCodeSuccess(respose))),
        catchError(() => dispatch(new UserPhoneActions.CheckUserPhoneCodeFail())),
      );
    }
  }

  @Action(UserPhoneActions.CheckUserPhoneCodeSuccess)
  public checkUserPhoneCodeSuccess(
    { setState, dispatch }: StateContext<UserPhoneStateModel>,
    { payload }: UserPhoneActions.CheckUserPhoneCodeSuccess,
  ) {
    if (payload.answer === UserPhoneState.SUCCESS) {
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SMS_CHECKED_OK });
      setState(patch<UserPhoneStateModel>({ showSuccess: true }));
      dispatch(new AuthActions.Init());
    } else {
      setState(patch<UserPhoneStateModel>({ codeError: payload.answer }));
    }
  }

  @Action(UserPhoneActions.ResetUserPhoneForm)
  public resetUserPhoneForm(
    { getState, setState }: StateContext<UserPhoneStateModel>,
  ) {
    setState(patch<UserPhoneStateModel>({
      codeError: '', phoneError: '', codeSended: false, form: {
        model: { ...getState().form.model, code: '' },
      }
    }));
  }
}
