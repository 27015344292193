import { Injectable } from '@angular/core';
import {
  CorpSearchApiService, ICorpSearchApiByOwnerParams,
  ICorpSearchApiByOwnerResult
} from './corp-search-api.service';
import { CorpSearchSaveReportService } from './corp-search-save-report.service';
import { tap } from 'rxjs/operators';
import { ISearchHistoryDetail } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CorpSearchByOwnerService {
  constructor(
    private location: Location,
    private corpSearchApiService: CorpSearchApiService,
    private corpSearchSaveService: CorpSearchSaveReportService,
  ) {
  }

  isLoading = false;

  data: ICorpSearchApiByOwnerResult;

  searchOptions = {
    owner: '',
  };

  loadBySearchHistory(search: ISearchHistoryDetail, isShow: boolean) {
    this.searchOptions = {owner: search.searchParameters.text};

    if (isShow) {
      const tradeMarks = search.results.find(i => i.entity === 'trademark').result;
      const domains = search.results.find(i => i.entity === 'domain').result;

      this.data = {tradeMarks, domains};
    } else {
      this.load(this.searchOptions, false);
    }
  }

  load(params: { owner: string }, saveSearch = true) {
    this.isLoading = true;

    if (params?.owner) {
      this.location.go(`/search/owner?q=${params?.owner}`);
    }

    this.corpSearchApiService
      .searchTrademarksByOwner(params)
      .pipe(
        tap((data) => saveSearch && this.saveSearch(params, data))
      )
      .subscribe((data) => {
        this.isLoading = false;
        this.data = data;
      });
  }

  saveSearch(params: ICorpSearchApiByOwnerParams, result: ICorpSearchApiByOwnerResult) {
    this.corpSearchApiService.objectsLoading = true;
    return this.corpSearchApiService
      .saveSearchTrademarksByOwner(params, result)
      .subscribe(data => {
        if (data && data.searchID) {
          this.corpSearchApiService.objectsLoading = false;
          this.corpSearchSaveService.setSearchID(data.searchID);
        }
      });
  }

  get total() {
    return {
      tradeMarks: this.data.tradeMarks?.hits?.total?.tradeMarks,
      domains: this.data.domains?.hits?.total?.domains,
    };
  }

  get items() {
    return {
      tradeMarks: this.data.tradeMarks?.hits?.hits,
      domains: this.data.domains?.hits?.hits,
    };
  }

  get isLoaded() {
    return this.data !== undefined;
  }

  reset() {
    this.data = undefined;
    this.isLoading = false;
    this.searchOptions = {owner: ''};
  }

}
