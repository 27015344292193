import { Component, OnInit } from '@angular/core';
import { MonitoringAddService } from '../shared/monitoring-add.service';
import { ActivatedRoute } from '@angular/router';
import { IntercomEventsService } from 'projects/shared/services/intercom-events.service';


@Component({
  selector: 'app-add-object-create-request',
  templateUrl: './add-object-create-request.component.html',
  styleUrls: ['./add-object-create-request.component.scss']
})
export class AddObjectCreateRequestComponent implements OnInit {

  readonly CountDefaultShownItems = 20;

  constructor(
    readonly monitoringAddService: MonitoringAddService,
    readonly activatedRoute: ActivatedRoute,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  showAll = false;

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    this.monitoringAddService.loadRequestIfNeeded(id);
  }

  get stage() {
    return this.monitoringAddService.data.stage;
  }

  get hasHiddenObjects() {
    return this.monitoringAddService.allObjects.length > this.CountDefaultShownItems;
  }

  get shownObjects() {
    const all = this.monitoringAddService.allObjects;
    return this.showAll ? all : all.slice(0, this.CountDefaultShownItems);
  }

  onShowAll() {
    this.showAll = true;
  }
}
