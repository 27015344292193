import { Injectable } from '@angular/core';

const MONITORING_RISK_ID = 'integration/monitoring_risk_id';
const MONITORING_RISK_ID_REDIRECTED = 'integration/monitoring_risk_id_redirected';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  // методы для сохранения и получения riskId для /monitoring/landing
  setRiskId(riskId: string) {
    localStorage.setItem(MONITORING_RISK_ID, riskId);
    localStorage.setItem(MONITORING_RISK_ID_REDIRECTED, '');
  }

  getRiskId(): string {
    return localStorage.getItem(MONITORING_RISK_ID);
  }

  setRiskIdRedirected() {
    localStorage.setItem(MONITORING_RISK_ID_REDIRECTED, '1');
  }

  isRiskIdRedirected() {
    return localStorage.getItem(MONITORING_RISK_ID_REDIRECTED) === '1';
  }


}
