<!--<button (click)="before=true; after=false">before</button>-->
<!--<button (click)="before=false; after=true">after</button>-->
<!--<a routerLink="/cases-item">Cases-item</a>-->

<section *ngIf="before">
  <div *ngIf="items && trackItems; else loader" class="main_body_wrapper">

    <div class="section">

      <div class="custom-container_body">
        <div class="row-wrapper">

          <div *ngIf="!mobileSide" class="side" id="side" data-intercom-target="task list">
            <div class="tab-row">
              <ul class="tabs t-2 w-list-unstyled">
                <li *ngIf="openedTasks.length > 0 || activeItem[0].status" class="tab"
                    [ngClass]="toggleOpened ? 'active_draft':'side_tag'" id="opened"
                    (click)="toggleOpenedTasks();selectCase(openedTasks[0].ID)">Активные
                </li>
                <li *ngIf="closedTasks.length > 0" class="tab "
                    [ngClass]="!toggleClosed ? 'side_tag':'active_draft'" id="closed"
                    (click)="toggleClosedTasks()">Завершенные
                </li>
                <li *ngIf="unfinishedTasks.length > 0 || !activeItem[0].status" class="tab"
                    [ngClass]="!toggleUnfinished ? 'side_tag':'active_draft'" id="closed"
                    (click)="toggleUnfinishedTasks();selectCaseFromNav(unfinishedTasks[0].ID, activeItem[0].ID)">Черновики
                </li>
              </ul>
            </div>

<!--            <perfect-scrollbar class="parrent_side">-->
              <!--        main active-->
              <!--        opened-->
              <div class="opened"
                   *ngIf="activeItem[0].opened && toggleOpened"
                   [ngClass]="showAnimation ? '':'zoom_in'">
                <div class="task  warn active opened" [ngClass]="showAnimation ? 'zoom_in':''" id="tab_row" onclick="">
                  <div class="status w-clearfix">
                    <p [ngClass]="showFire ? 'task-header-img': ''" class="paragraph task-header-left">
                      {{activeItem[0].status}}</p>
                    <p class="paragraph task-header-right">Дело {{activeItem[0].number}}</p>
                  </div>
                  <div (click)="toggleMobileMain()" class="details w-clearfix">
                    <div *ngIf="activeItem[0].details.imgSrc && activeItem[0].designationType !== 'W'" class="type link">
                      <img class="logo__cover"
                           src="{{activeItem[0].details.imgSrc | proxyImage}}"
                           alt="ТЗ"/>
                    </div>
                    <div class="title">
                      <h2 class="top_heading">{{activeItem[0].designationValue}}</h2>
                      <div class="description"> {{activeItem[0].details.description}}</div>
                    </div>
                    <div class="donut donut-{{activeItem[0].details.progress}}">
                      <div class="donut-top"></div>
                      <div class="donut-info">{{activeItem[0].details.progress}}%</div>
                      <div class="donut-bottom"></div>
                    </div>
                  </div>
                  <div class="info">
                    <div *ngFor="let infos of info" class="row">
                      <div *ngIf="infos.text">
                        <div class="row-title">{{infos.title}}</div>
                        <div class="row-content">{{infos.text}}</div>
                      </div>
                    </div>
                    <a class="task-button" (click)="openQuestionModal()">Задать вопрос</a>
                  </div>
                </div>

              </div>

              <div *ngIf="toggleOpened">
                <div *ngFor="let opened of openedTasks" (click)="selectCase(opened.ID)"
                     class="task opened"
                     id="tab_row">
                  <div class="status w-clearfix">
                    <p class="paragraph task-header-left task-header-no-img"
                       [ngClass]="opened.status === 'К согласованию' ? 'task-header-img': ''">
                      {{opened.status}}</p>
                    <p class="paragraph task-header-right">Дело {{opened.number}}</p>
                  </div>
                  <div class="details w-clearfix">
                    <div *ngIf="opened.designationType !== 'W'">
                      <div *ngIf="opened.details.imgSrc" class="type link"><img class="logo__cover"
                                                                                src="{{opened.details.imgSrc | proxyImage}}"
                                                                                alt="ТЗ"/>
                      </div>
                    </div>
                    <div class="title">
                      <h2 class="heading"><a>{{opened.designationValue}}</a></h2>
                      <div class="description">{{opened.details.description}}</div>
                    </div>
                    <div class="donut donut-{{opened.details.progress}}">
                      <div class="donut-top"></div>
                      <div class="donut-info">{{opened.details.progress}}%</div>
                      <div class="donut-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!--        closed-->

              <div class="clozed"
                   *ngIf="!activeItem[0].opened && toggleClosed && activeItem[0].status"
                   [ngClass]="showAnimation ? '':'zoom_in'">
                <div class="task  warn active opened" [ngClass]="showAnimation ? 'zoom_in':''" id="tab_row" onclick="">
                  <!--          <div *ngIf="toggleTasks" class="task  warn active opened" id="tab_row" onclick="">-->
                  <div class="status w-clearfix">
                    <p [ngClass]="showFire ? 'task-header-img': ''" class="paragraph task-header-left">
                      {{activeItem[0].status}}</p>
                    <p class="paragraph task-header-right">Дело {{activeItem[0].number}}</p>
                  </div>
                  <div (click)="toggleMobileMain()" class="details w-clearfix">
                    <div *ngIf="activeItem[0].details.imgSrc && activeItem[0].designationType !== 'W'" class="type link">
                      <img class="logo__cover"
                           src="{{activeItem[0].details.imgSrc | proxyImage}}"
                           alt="ТЗ"/>
                    </div>
                    <div class="title">
                      <h2 class="top_heading">{{activeItem[0].designationValue}}</h2>
                      <div class="description"> {{activeItem[0].details.description}}</div>
                    </div>
                    <div class="donut donut-{{activeItem[0].details.progress}}">
                      <div class="donut-top"></div>
                      <div class="donut-info">{{activeItem[0].details.progress}}%</div>
                      <div class="donut-bottom"></div>
                    </div>
                  </div>
                  <div class="info">
                    <div *ngFor="let infos of info" class="row">
                      <div *ngIf="infos.text">
                        <div class="row-title">{{infos.title}}</div>
                        <div class="row-content">{{infos.text}}</div>
                      </div>
                    </div>
                    <a class="task-button" (click)="openQuestionModal()">Задать вопрос</a>
                  </div>
                </div>

              </div>

              <div *ngIf="toggleClosed">
                <div *ngFor="let closed of closedTasks" (click)="selectCase(closed.ID)" class="task closed" id="tab_row">
                  <div class="status w-clearfix">
                    <p class="paragraph task-header-left task-header-no-img "
                       [ngClass]="closed.status === 'К согласованию' ? 'task-header-img': ''">
                      {{closed.status}}</p>
                    <p class="paragraph task-header-right">Дело {{closed.number}}</p>
                  </div>
                  <div class="details w-clearfix">
                    <div *ngIf="closed.designationType !== 'W'">
                      <div *ngIf="closed.details.imgSrc" class="type link"><img class="logo__cover"
                                                                                src="{{closed.details.imgSrc | proxyImage}}"
                                                                                alt="ТЗ"/>
                      </div>
                    </div>
                    <div class="title">
                      <h2 class="heading"><a>{{closed.designationValue}}</a></h2>
                      <div class="description">{{closed.details.description}}</div>
                    </div>
                    <div class="donut donut-{{closed.details.progress}}">
                      <div class="donut-top"></div>
                      <div class="donut-info">{{closed.details.progress}}%</div>
                      <div class="donut-bottom"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!--            unfinished-->

              <div class="unfinished"
                   *ngIf="!activeItem[0].status && toggleUnfinished"
                   [ngClass]="showAnimation ? '':'zoom_in'">
                <div class="task  warn active opened" [ngClass]="showAnimation ? 'zoom_in':''" id="tab_row" onclick="">
                  <div class="status w-clearfix" style="display: flex; justify-content: space-between">
                    <div>
                      <p class="paragraph task-header-left task-header-left_draft">
                        <svg width="18" height="22" viewBox="0 0 18 22">
                          <path d="M17.203 12.8806C16.951 9.59959 15.4234 7.54349 14.0757 5.72909C12.8278 4.04935 11.75 2.59882 11.75 0.459057C11.75 0.287182 11.6538 0.130089 11.5011 0.0513269C11.348 -0.0279075 11.1641 -0.014931 11.0249 0.0862175C9.0009 1.53452 7.31214 3.97553 6.72223 6.30461C6.31269 7.92612 6.25851 9.74903 6.2509 10.953C4.38176 10.5537 3.95835 7.75786 3.95388 7.72739C3.93282 7.58237 3.84422 7.45617 3.71532 7.38725C3.58508 7.31923 3.43198 7.31429 3.29994 7.37965C3.20193 7.42708 0.894117 8.5997 0.75984 13.2816C0.75043 13.4374 0.75 13.5936 0.75 13.7498C0.75 18.2984 4.45111 21.9992 9 21.9992C9.00627 21.9997 9.01298 22.0006 9.01835 21.9992C9.02015 21.9992 9.02191 21.9992 9.02415 21.9992C13.5619 21.9862 17.25 18.2903 17.25 13.7498C17.25 13.5211 17.203 12.8806 17.203 12.8806ZM9 21.0826C7.48355 21.0826 6.25 19.7686 6.25 18.1534C6.25 18.0983 6.24957 18.0428 6.25357 17.9748C6.27191 17.2936 6.40129 16.8286 6.54318 16.5193C6.80907 17.0904 7.28439 17.6154 8.05649 17.6154C8.30984 17.6154 8.51484 17.4104 8.51484 17.1571C8.51484 16.5046 8.52829 15.7518 8.69075 15.0723C8.83534 14.4699 9.18086 13.829 9.61862 13.3152C9.81331 13.9821 10.1929 14.5218 10.5635 15.0486C11.0939 15.8023 11.6422 16.5815 11.7384 17.9103C11.7442 17.9891 11.7501 18.0683 11.7501 18.1534C11.75 19.7686 10.5165 21.0826 9 21.0826Z"/>
                        </svg>
                      </p>
                      <p class="steps_text" style="white-space: nowrap">{{getStepsData()}}</p>
                    </div>
                    <div class="draft-number-color"> <span>Черновик</span> {{+activeItem[0].ID}}</div>
                  </div>
                  <div (click)="toggleMobileMain()" class="details w-clearfix">
                    <div *ngIf="activeItem[0].details.imgSrc && activeItem[0].designationType !== 'W'" class="type link">
                      <img class="logo__cover"
                           src="{{activeItem[0].details.imgSrc | proxyImage}}"
                           alt="ТЗ"/>
                    </div>
                    <div class="title">
                      <h2 class="top_heading_draft">{{activeItem[0].designationValue}}</h2>
                      <div class="description"> {{activeItem[0].details.description}}</div>
                    </div>
                  </div>
                  <div class="info">
                    <div class="row">
                      <div>
                        <div class="row-title">{{info[0].title}}</div>
                        <div class="row-content">{{info[0].text}}</div>
                      </div>
                      <br>
                      <div  *ngIf="activeItem[0].type !== 'rumcc' && activeItem[0].type !== 'monitoring'">
                        <div class="row-title">{{info[2].title.substring(0,9)}}</div>
                        <div *ngIf="activeItem[0].type === 'trademark'" class="row-content">{{info[2].text}}</div>
                        <div *ngIf="activeItem[0].type !== 'trademark'" class="row-content">{{getPatenteeName(activeItem[0].details.patentee[0])}}</div>
                      </div>
                    </div>
                    <div class="draft_controls" *ngIf="activeItem[0].lastStage !== 4 && !(activeItem[0].type === 'monitoring' && activeItem[0].lastStage === 3)">
                      <a class="task-button_draft" (click)="sendDraft(activeItem[0].ID, activeItem[0].type)" >{{getStepsData()}}</a>
                      <div class="button_controls">
                        <!--                      <div class="control_buttons"><fa-icon [icon]="faPen"></fa-icon></div>-->
                        <div class="control_buttons" (click)="deleteTask(activeItem[0].ID)"><fa-icon [icon]="faTrashAlt"></fa-icon></div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div *ngIf="toggleUnfinished">
                <div *ngFor="let unfinished of unfinishedTasks" (click)="selectCaseUnfinished(unfinished.ID, unfinished.opened)"
                     class="task closed other-tasks" id="tab_row">
                  <div class="status_drafts w-clearfix" style="display: flex; justify-content: space-between">
                    <div>
                      <p class="paragraph task-header-left task-header-left_draft">
                        <svg width="18" height="22" viewBox="0 0 18 22">
                          <path d="M17.203 12.8806C16.951 9.59959 15.4234 7.54349 14.0757 5.72909C12.8278 4.04935 11.75 2.59882 11.75 0.459057C11.75 0.287182 11.6538 0.130089 11.5011 0.0513269C11.348 -0.0279075 11.1641 -0.014931 11.0249 0.0862175C9.0009 1.53452 7.31214 3.97553 6.72223 6.30461C6.31269 7.92612 6.25851 9.74903 6.2509 10.953C4.38176 10.5537 3.95835 7.75786 3.95388 7.72739C3.93282 7.58237 3.84422 7.45617 3.71532 7.38725C3.58508 7.31923 3.43198 7.31429 3.29994 7.37965C3.20193 7.42708 0.894117 8.5997 0.75984 13.2816C0.75043 13.4374 0.75 13.5936 0.75 13.7498C0.75 18.2984 4.45111 21.9992 9 21.9992C9.00627 21.9997 9.01298 22.0006 9.01835 21.9992C9.02015 21.9992 9.02191 21.9992 9.02415 21.9992C13.5619 21.9862 17.25 18.2903 17.25 13.7498C17.25 13.5211 17.203 12.8806 17.203 12.8806ZM9 21.0826C7.48355 21.0826 6.25 19.7686 6.25 18.1534C6.25 18.0983 6.24957 18.0428 6.25357 17.9748C6.27191 17.2936 6.40129 16.8286 6.54318 16.5193C6.80907 17.0904 7.28439 17.6154 8.05649 17.6154C8.30984 17.6154 8.51484 17.4104 8.51484 17.1571C8.51484 16.5046 8.52829 15.7518 8.69075 15.0723C8.83534 14.4699 9.18086 13.829 9.61862 13.3152C9.81331 13.9821 10.1929 14.5218 10.5635 15.0486C11.0939 15.8023 11.6422 16.5815 11.7384 17.9103C11.7442 17.9891 11.7501 18.0683 11.7501 18.1534C11.75 19.7686 10.5165 21.0826 9 21.0826Z"/>
                        </svg>
                      </p>
                      <p class="steps_text" style="white-space: nowrap">{{getDraftsData(unfinished.lastStage, unfinished.type)}}</p>
                    </div>
                    <div class="draft-number-color"><span>Черновик</span> {{+unfinished.ID}}</div>
                  </div>
                  <div class="details w-clearfix">
                    <div *ngIf="unfinished.designationType !== 'W'">
                      <div *ngIf="unfinished.details.imgSrc" class="type link"><img class="logo__cover"
                                                                                    src="{{unfinished.details.imgSrc | proxyImage}}"
                                                                                    alt="ТЗ"/>
                      </div>
                    </div>
                    <div class="title">
                      <h2 class="heading_drafts"><a>{{unfinished.designationValue}}</a></h2>
                      <div class="description">{{unfinished.details.description}}</div>
                    </div>
                  </div>
                </div>
              </div>

<!--            </perfect-scrollbar>-->


          </div>
          <!--      side end -->


          <!--      <div class="main" id="main">-->
          <perfect-scrollbar class="main" id="main" *ngIf="!mobileMain" data-intercom-target="task details">
            <div (click)="toggleMobileSide()" class="back_nav" id="back_nav"><img
              [src]="'https://static.9958258.ru/LK/back_icon.svg' | proxyImage">
              <h5>Мои заявки</h5>
            </div>
            <div *ngIf="activeItem[0].status && !toggleUnfinished" class="tab-row right">
              <ul class="tabs t-3 w-list-unstyled">
                <li class="tab  main_tab tab_one" [ngClass]="!hasFile && !agreedStatus ? 'active': ''" id="all"
                    (click)="getEvents()">{{isMobile ? 'ВСЕ' : 'ВСЕ СОБЫТИЯ'}}</li>
                <li class="tab main_tab tab_two" [ngClass]="!hasFile && agreedStatus ? 'active': ''" id="warn"
                    (click)="getStatusEvents()">К согласованию
                </li>
                <li class="tab main_tab tab_three" [ngClass]="hasFile && !agreedStatus ? 'active': ''" id="doc"
                    (click)="getEventsWithFiles()">Документы
                </li>
              </ul>
            </div>

            <div *ngIf="!toggleUnfinished && activeItem[0].status" class="events " [ngClass]="showAnimation ? 'fade-in':''">
              <div [ngClass]="showAnimation ? '':'fade-in'">
                <div class="top__title">
                  <h1 class="task-title">{{activeItem[0].designationValue}}</h1>
                </div>
                <div *ngIf="!showExtended" class="toggle_info" id="toggle_info">
                  <p id="p_info_one" (click)="showExtended = !showExtended"> Подробная информация</p><img id="flip"
                                                                                                          [src]="'https://static.9958258.ru/LK/red_arrow_bottom.svg' | proxyImage">
                </div>
                <div *ngIf="showExtended" class="toggle_info" id="toggle_info">
                  <p id="p_info_one" (click)="showExtended = !showExtended"> Подробная информация</p><img class="flip"
                                                                                                          [src]="'https://static.9958258.ru/LK/red_arrow_bottom.svg' | proxyImage">
                </div>
                <div *ngIf="showExtended" class="main_info" id="main_info">
                  <div class="donut_info">
                    <div class="description"> {{activeItem[0].details.description}}</div>
                    <div class="donut donut-40">
                      <div class="donut-top"></div>
                      <div class="donut-info">{{activeItem[0].details.progress}}%</div>
                      <div class="donut-bottom"></div>
                    </div>
                  </div>
                  <div *ngIf="activeItem[0].details.imgSrc && activeItem[0].designationType !== 'W'" class="main_img">
                    <img src="{{activeItem[0].details.imgSrc | proxyImage}}" alt="ТЗ">
                  </div>
                  <div class="info">
                    <div class="row">
                      <div class="row-title">{{info[1].title}}</div>
                      <div class="row-content">{{info[1].text}}</div>
                    </div>
                    <div class="row">
                      <div class="row-title">{{info[3].title}}</div>
                      <div class="row-content">{{info[3].text}}</div>
                    </div>
                  </div>
                </div>

                <div class="events-info  active">

                  <!--            tracks start -->

                  <div class="tracker">
                    <div class="tracker-points">


                      <div *ngFor="let track of trackData; let i = index" class="milestone"
                           [ngClass]="track.reach ? 'finished': ''">
                        <div *ngIf="i > 0" class="progress-block">
                      <span *ngIf="!track.reach && track.date !== '0 дн.'">
                        {{track.date}}</span>
                        </div>
                        <div class="div-block-7"><img class="image-4" src="../../../assets/images/check-mark.svg" alt="">
                        </div>
                        <div class="text-block-7"><span>{{track.name}}</span></div>
                      </div>

                    </div>

                    <div class="div-block-8">
                      <span *ngFor="let date of trackData">{{date.stagedate}}</span>
                    </div>
                  </div>

                  <!--            tracks end -->

                  <!--            events start -->

                  <div *ngFor="let event of events" class="events-block" (click)="openEventModal()">
                    <a class="event  doc">
                      <div class="event-content" (click)="getModalData(event.imgSrc, event.expertName, event.expertPosition,
                    event.popupTitle, event.popupText, event.popupFooterDate, event.popupFiles, event.Type, event.eventID, event.flagBlue, event.status)">
                        <div class="event-image">
                          <div class="avatar"><img src="{{event.imgSrc | proxyImage}}"/></div>
                        </div>
                        <div class="event-info">
                          <div class="event-header">
                            <div class="div-block-4">
                              <!--                            <div [ngClass]="event.flagBlue ? 'unread':'flag'" class="flag"></div>-->
                              <div class="name">{{event.expertName}}, {{event.expertPosition}}</div>
                            </div>
                            <div class="block-5"></div>
                            <div class="date">{{event.date}}</div>
                            <div *ngIf="!event.status" class=" w-clearfix">
                              <div class="text-block-6">{{event.status}}</div>
                            </div>
                            <div *ngIf="event.status=== 'К согласованию'" class="warn w-clearfix"><img class="image-2"
                                                                                                       src="../../../../../gardium/src/assets/images/flame-white-icon.svg"
                                                                                                       alt="" width="14"/>
                              <div class="text-block-6">{{event.status}}</div>
                            </div>
                            <div *ngIf="event.status=== 'Согласовано'" class="agree w-clearfix"><img class="image-2"
                                                                                                     src="../../../assets/images/check-mark.svg"
                                                                                                     alt="" width="14"/>
                              <div class="text-block-6">{{event.status}}</div>
                            </div>
                          </div>
                          <div [ngClass]="event.flagBlue ? 'event-title':'event-title_read'"
                               class="event-title">{{event.title}}</div>
                          <div *ngIf="event.file" class="event-attach tab-row"><img class="image-3"
                                                                                    src="../../../assets/images/paperclip-ico.svg"
                                                                                    alt=""/>
                            <div class="text-block-5">{{event.file.fileText}}</div>
                          </div>
                        </div>
                      </div>

                      <div class="event_content_mobile" (click)="getModalData(event.imgSrc, event.expertName, event.expertPosition,
              event.popupTitle, event.popupText, event.popupFooterDate, event.popupFiles, event.Type, event.eventID, event.flagBlue, event.status)">
                        <div class="event_head">
                          <div class="head_m">{{event.date}}</div>
                          <div class="event-header">
                            <div *ngIf="!event.status" class=" w-clearfix">
                              <div class="text-block-6">{{event.status}}</div>
                            </div>
                            <div *ngIf="event.status=== 'К согласованию'" class="warn w-clearfix"><img class="image-2"
                                                                                                       src="../../../../../gardium/src/assets/images/flame-white-icon.svg"
                                                                                                       alt="" width="14"/>
                              <div class="text-block-6">{{event.status}}</div>
                            </div>
                            <div *ngIf="event.status=== 'Согласовано'" class="agree w-clearfix"><img class="image-2"
                                                                                                     src="../../../assets/images/check-mark.svg"
                                                                                                     alt="" width="14"/>
                              <div class="text-block-6">{{event.status}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="event_desc">
                          <div class="avatar_m"><img class="icon_avatar"
                                                     [src]="'http://static.intelis.ru/signature/lyuzina.jpg' | proxyImage"/>
                          </div>
                          <div class="desc_tag">
                            <div class="name_m">{{event.expertName}}</div>
                            <div class="name_m">{{event.expertPosition}}</div>
                          </div>
                        </div>
                        <div class="title_main">
                          <div [ngClass]="event.flagBlue ? 'event-title':'event-title_read'">{{event.title}}</div>
                        </div>
                        <div *ngIf="event.file" class="file_events_mobile">
                          <div class="event-attach tab-row">
                            <img class="image-3" src="../../../assets/images/paperclip-ico.svg"
                                 alt=""/>
                            <div class="text-block-5">{{event.file.fileText}}</div>
                          </div>
                        </div>
                      </div>

                    </a>
                  </div>
                  <!--            events end -->
                </div>
              </div>

          </div>
          <!--          unfinished events-->
          <div *ngIf="!activeItem[0].status">
            <div class="events " [ngClass]="showAnimation ? 'fade-in':''">
              <div [ngClass]="showAnimation ? '':'fade-in'">
                <div class="top__title">
                  <h1 *ngIf="!activeItem[0].details.title" class="task-title">IP-ID Online Сервис</h1>
                  <h1 *ngIf="activeItem[0].details.title" class="task-title">{{activeItem[0].details.title}}</h1>
                </div>
                <div class="events-info  active">
                  <!--            tracks start -->
                  <div class="tracker" *ngIf="activeItem[0].type !== 'monitoring'">
                    <div class="tracker-points">
                      <div class="milestone">
                        <div class="div-block-7"><img class="image-4" src="../../../assets/images/check-mark.svg"
                                                      alt="">
                        </div>
                        <div class="text-block-7"><span>Оплата подтверждена</span></div>
                      </div>
                      <div class="milestone">
                        <div class="progress-block">
                        </div>
                        <div class="div-block-7"><img class="image-4" src="../../../assets/images/check-mark.svg"
                                                      alt="">
                        </div>
                        <div class="text-block-7"><span>Проект согласован</span></div>
                      </div>
                      <div class="milestone">
                        <div class="progress-block">
                        </div>
                        <div class="div-block-7"><img class="image-4" src="../../../assets/images/check-mark.svg"
                                                      alt="">
                        </div>
                        <div class="text-block-7"><span>Заявка подана в ФИПС</span></div>
                      </div>
                      <div class="milestone">
                        <div class="progress-block">
                        </div>
                        <div class="div-block-7"><img class="image-4" src="../../../assets/images/check-mark.svg"
                                                      alt="">
                        </div>
                        <div class="text-block-7"><span>Получено решение</span></div>
                      </div>
                      <div class="milestone">
                        <div class="progress-block">
                        </div>
                        <div class="div-block-7"><img class="image-4" src="../../../assets/images/check-mark.svg"
                                                      alt="">
                        </div>
                        <div class="text-block-7"><span>Получено свидетельство</span></div>
                      </div>
                      <!--                      mock data here-->
                    </div>
                  </div>
                  <div class="unfinished_data">
                    <div class="logo_drafts">
                      <img [src]="logo">
                      <div class="draft_text" [innerHTML]="getStepsText()"></div>
                    </div>
                    <button *ngIf="activeItem[0].lastStage !== 4 && !(activeItem[0].type === 'monitoring' && activeItem[0].lastStage === 3)"
                            class="btn draft_dynamic_btn"
                            (click)="sendDraft(activeItem[0].ID, activeItem[0].type)">
                      {{getStepsData()}}
                    </button>
                  </div>
                </div>
              </div>

              </div>

            </div>

          </perfect-scrollbar>
        </div>
      </div>
      <!--  modal start question-->
      <div *ngIf="showQuestion || showEventModal" (click)="closeQuestionModal();closeEventModal()" class="modal-wrapper"
           id="task-modal"></div>

      <div *ngIf="showQuestion" class="modal_question zoom_in_modal">
        <div class="modal-container">
          <div class="modal-head">
            <div class="div-block-10">
              <h1 class="question_heading">Задать вопрос</h1>
            </div>
            <div class="close-btn" (click)="closeQuestionModal()"></div>
          </div>
          <textarea [(ngModel)]="questionTask" class="commentary_input" type="text"
                    placeholder="Введите ваше сообщение"></textarea>
          <button class="submit" (click)="addQuestion();closeQuestionModal()" id="sendBtnTask">ОТПРАВИТЬ</button>
        </div>
      </div>
      <!--  modal end-->
      <!--  modal start events-->

      <div *ngIf="showEventModal" class="modal zoom_in_modal">
        <div class="modal-head">
          <div class="avatar"><img src="{{modalData.imgSrc | proxyImage}}" alt=""></div>
          <div class="div-block-10">
            <div class="username">{{modalData.name}}</div>
            <div class="department">{{modalData.position}}</div>
          </div>
          <div *ngIf="!modalData.flagBlue" class="close-btn" (click)="closeEventModal();eventAgree()"></div>
          <div *ngIf="modalData.flagBlue" class="close-btn" (click)="closeEventModal();closeEvent()"></div>
        </div>
        <div class="modal-body w-clearfix" id="modal-body">
          <h1>{{modalData.title}}</h1>
          <div class="hiden-text" id="readStatus"></div>
          <div class="hiden-text" id="eventID"></div>
          <div class="hiden-text" id="eventType"></div>
          <div id="text" [innerHTML]="modalData.popupText"></div>
          <hr class="divider">
          <div *ngFor="let files of modalData.files" class="modal-detail_text">
            <a id="buttons" class="files_bottom" href="{{files.file.link}}" target="_blank">
              <a class="download-btn"></a>
              <img src="../../../assets/images/paperclip-ico.svg" alt="">
              <span class="bottom_file">
          {{files.file.name}}
        </span>
            </a>

          </div>
          <div class="modal-info">
            <div *ngIf="modalData.status=== 'К согласованию'" (click)="closeEventModal();closeEvent()">
              <button *ngIf="!modalData.flagBlue" (click)="eventAgree();closeEvent()" class="agree__btn">
                Согласовать
              </button>
              <!--            <button *ngIf="modalData.flagBlue" (click)="closeEventModal();sendBothEvents()" class="agree__btn">-->
              <button *ngIf="modalData.flagBlue" (click)="closeEventModal()" class="agree__btn">
                Согласовать
              </button>
            </div>
            <div class="modal-footer_text"></div>
            <span id="footerDate">{{modalData.date}}</span>
          </div>
        </div>
        <div class="modal-body input">
          <input [(ngModel)]="messageModal" class="comment" id="comment" type="text" name="message"
                 placeholder="ваш комментарий">
          <button (click)="addComment();openEventModal()" class="send-btn" id="sendBtn"></button>
        </div>
      </div>
      <!--  modal question end-->

    </div>

  </div>
</section>

<section *ngIf="after">
  <div *ngIf="items && trackItems; else loader" class="main_body_wrapper">

    <section class="section-position section-position__color-bg section-position--padding">
      <div class="container container-top">
        <h1>Дела</h1>
      </div>
    </section>

    <section class="section-position  section-position__color-bg section-position--padding control__wrap">
      <div class="container control">

        <button class="control__btn" (click)="active=true; draft=false; finished=false"
                [class.control__btn--active]="active"
        >Активные<span class="control__count">12<span class="control__division">/ <span class="control__division--red">2</span></span></span>
        </button>
        <button class="control__btn" (click)="active=false; draft=true; finished=false"
                [class.control__btn--active]="draft"
        >Черновики<span class="control__count">5</span>
        </button>
        <button class="control__btn" (click)="active=false; draft=false; finished=true"
                [class.control__btn--active]="finished"
        >Завершенные<span class="control__count">12</span>
        </button>

      </div>
    </section>

    <div *ngIf="active" class="search-style search-style--filter">
      <div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="filter__search-input-wrap">
          <span (click)="showMobileControl = true;makeBodyHidden()">Поиск по делам</span>
          <button class="filter__search-close" *ngIf="showMobileControl === true" (click)="showMobileControl = false;makeBodyHidden()"></button>
        </div>
      </div>
      <div class="container container--search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <form  class="search" [ngClass]="showMobileControl === true? 'mobile': ''">
          <div class="search__input-wrap">
            <svg width="21" height="21" fill="none" viewBox="0 0 21 21">
              <circle cx="9" cy="9" r="8" stroke-width="2"/>
              <path d="M15 15L20 20" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <input class="search__input" placeholder="Поиск по тексту"/>
          </div>

          <div class="search__wrapper">

            <div tabindex="0" class="search__btn-block">
              <button class="search__select-btn" [class.search__select-btn--bold]="getCountCheckedObjects(typeOfObjects)>0">
                <p class="search__label">Статус</p>
                <span>{{ getCountCheckedObjects(typeOfObjects)===0 ? '' : getCountCheckedObjects(typeOfObjects)}}</span>
                <span class="search__select-btn-span"></span>
              </button>
               <div class="search__select">
                <div class="search__hidden-options-wrapper">
                  <div class="search__options-wrapper">

                    <div *ngFor="let option of typeOfObjects" class="search__option">
                      <input class="search__option-input" type="checkbox" name="{{option.value}}" id="{{option.id}}" [(ngModel)]="option.checked">
                      <label class="search__option-label" for="{{option.id}}">{{option.value}}</label>
                    </div>

                  </div>

                  <div class="search__btns-wrapper">
                    <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(typeOfObjects)">Отмена</button>
                    <button type="submit" class="search__btn-submit">Выбрать</button>
                  </div>
                </div>


            </div>
            </div>

            <div tabindex="0" class="search__btn-block">
              <button class="search__select-btn" [class.search__select-btn--bold]="getCountCheckedObjects(objectsSelect)>0">
                <p class="search__label">Тип работ</p>
                <span>{{ getCountCheckedObjects(objectsSelect)===0 ? '' : getCountCheckedObjects(objectsSelect)}}</span>
                <span class="search__select-btn-span"></span>
              </button>
              <div class="search__select">
                  <div class="search__hidden-options-wrapper">
                    <div class="search__options-wrapper">
                      <div *ngFor="let option of objectsSelect" class="search__option">
                        <input class="search__option-input" type="checkbox" name="{{option.value}}" id="{{option.id}}" [(ngModel)]="option.checked">
                        <label class="search__option-label" for="{{option.id}}">{{option.value}}</label>
                      </div>

                    </div>

                    <div class="search__btns-wrapper">
                      <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(objectsSelect)">Отмена</button>
                      <button type="submit" class="search__btn-submit">Выбрать</button>
                    </div>
                  </div>


              </div>
            </div>

          </div>
          <div class="search__btns">
            <button class="search__btn btn-search">Найти</button>
            <button class="search__btn btn-clean">Очистить</button>
          </div>
        </form>

      </div>
      <div class="container container--table">
        <div class="table">

          <div class="table__wrapper">
            <div class="table__head-block">
              <div class="table__head-object">Объект</div>
              <div class="table__head-type">Тип работ</div>
              <div class="table__head-number">Номер дела</div>
              <div class="table__head-state">Статус</div>
            </div>
            <div class="table__big-block">
              <div class="table__row row-white row-white--red">
                <div class="row-white__title-wrap">
                  <div class="row-white__img">
                    <img class="tm_image" src="/assets/images/solar.png">
                  </div>

                  <div class="row-white__title-text row-white__title-text--first">
                    <p class="row-white__text row-white__text--bold">{{testText.length>85 ? testText.slice(0,85)+'...' : testText}}</p>
                  </div>
                </div>

                <div class="row-white__title-text row-white__title-text--second">
                  <p class="row-white__text row-white__text--gray">{{testText2.length>35 ? testText2.slice(0,35)+'...' : testText2}}</p>
                </div>

                <div class="row-white__title-text row-white__title-text--third">
                  <span class="row-white__text row-white__text--desc">Номер дела</span>
                  <p class="row-white__text row-white__text--small row-white__text--bold">3347634</p>
                </div>

                <div class="row-white__title-text row-white__title-text--fourth">
                  <div class="row-white__reg-progress">
                    <div class="row-white__point" [class.row-white__point--yellow]=true></div>
                    <div class="row-white__point" [class.row-white__point--blue]></div>
                    <div class="row-white__point" [class.row-white__point--purple]></div>
                    <div class="row-white__point" [class.row-white__point--dark-green]></div>
                    <div class="row-white__point" [class.row-white__point--green]></div>
                  </div>
                  <p class="row-white__reg-title">Оплата подтверждена</p>

                  <span class="row-white__attention">Требуется согласование</span>
                </div>
              </div>
            </div>
            <div class="table__big-block">
              <div class="table__row row-white ">
                <div class="row-white__title-wrap">
                  <div class="row-white__img">
                    <img class="tm_image" src="/assets/images/solar.png">
                  </div>

                  <div class="row-white__title-text row-white__title-text--first">
                    <p class="row-white__text row-white__text--bold">{{testText.length>85 ? testText.slice(0,85)+'...' : testText}}</p>
                  </div>
                </div>
                <div class="row-white__title-text row-white__title-text--second">
                  <p class="row-white__text row-white__text--gray">{{testText2.length>35 ? testText2.slice(0,35)+'...' : testText2}}</p>
                </div>

                <div class="row-white__title-text row-white__title-text--third">
                  <span class="row-white__text row-white__text--desc">Номер дела</span>
                  <p class="row-white__text row-white__text--small row-white__text--bold">3347634</p>
                </div>

                <div class="row-white__title-text row-white__title-text--fourth">
                  <div class="row-white__reg-progress">
                    <div class="row-white__point" [class.row-white__point--yellow]="true"></div>
                    <div class="row-white__point" [class.row-white__point--blue]="true"></div>
                    <div class="row-white__point" [class.row-white__point--purple]="true"></div>
                    <div class="row-white__point" [class.row-white__point--dark-green]></div>
                    <div class="row-white__point" [class.row-white__point--green]></div>
                  </div>
                  <p class="row-white__reg-title">Заявка подана в ФИПС</p>
<!--                  не убирать div ниже, только span добавлять или удалять по услвию-->
                  <div class="row-white__attention--wrapper">
<!--                    <span class="row-white__attention">Требуется согласование</span>-->
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div *ngIf="draft" class="search-style search-style--filter">
      <div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="filter__search-input-wrap">
          <span (click)="showMobileControl = true;makeBodyHidden()">Поиск по делам</span>
          <button class="filter__search-close" *ngIf="showMobileControl === true" (click)="showMobileControl = false;makeBodyHidden()"></button>
        </div>
      </div>
      <div class="container container--search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <form  class="search" [ngClass]="showMobileControl === true? 'mobile': ''">
          <div class="search__input-wrap">
            <svg width="21" height="21" fill="none" viewBox="0 0 21 21">
              <circle cx="9" cy="9" r="8" stroke-width="2"/>
              <path d="M15 15L20 20" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <input class="search__input" placeholder="Поиск по тексту"/>
          </div>

          <div class="search__wrapper">

            <div tabindex="0" class="search__btn-block">
              <button class="search__select-btn" [class.search__select-btn--bold]="getCountCheckedObjects(typeOfObjects)>0">
                <p class="search__label">Статус</p>
                <span>{{ getCountCheckedObjects(typeOfObjects)===0 ? '' : getCountCheckedObjects(typeOfObjects)}}</span>
                <span class="search__select-btn-span"></span>
              </button>
              <div class="search__select">
                <div class="search__hidden-options-wrapper">
                  <div class="search__options-wrapper">

                    <div *ngFor="let option of typeOfObjects" class="search__option">
                      <input class="search__option-input" type="checkbox" name="{{option.value}}" id="{{option.id}}" [(ngModel)]="option.checked">
                      <label class="search__option-label" for="{{option.id}}">{{option.value}}</label>
                    </div>

                  </div>

                  <div class="search__btns-wrapper">
                    <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(typeOfObjects)">Отмена</button>
                    <button type="submit" class="search__btn-submit">Выбрать</button>
                  </div>
                </div>


              </div>
            </div>

            <div tabindex="0" class="search__btn-block">
              <button class="search__select-btn" [class.search__select-btn--bold]="getCountCheckedObjects(objectsSelect)>0">
                <p class="search__label">Тип работ</p>
                <span>{{ getCountCheckedObjects(objectsSelect)===0 ? '' : getCountCheckedObjects(objectsSelect)}}</span>
                <span class="search__select-btn-span"></span>
              </button>
              <div class="search__select">
                <div class="search__hidden-options-wrapper">
                  <div class="search__options-wrapper">
                    <div *ngFor="let option of objectsSelect" class="search__option">
                      <input class="search__option-input" type="checkbox" name="{{option.value}}" id="{{option.id}}" [(ngModel)]="option.checked">
                      <label class="search__option-label" for="{{option.id}}">{{option.value}}</label>
                    </div>

                  </div>

                  <div class="search__btns-wrapper">
                    <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(objectsSelect)">Отмена</button>
                    <button type="submit" class="search__btn-submit">Выбрать</button>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="search__btns">
            <button class="search__btn btn-search">Найти</button>
            <button class="search__btn btn-clean">Очистить</button>
          </div>
        </form>

      </div>
      <div class="container container--table">
        <div class="table">

          <div class="table__wrapper">
            <div class="table__head-block">
              <div class="table__head-object">Объект</div>
              <div class="table__head-type">Тип работ</div>
              <div class="table__head-number">Номер дела</div>
              <div class="table__head-state">Статус</div>
            </div>
            <div class="table__big-block">
              <div class="table__row row-white row-white--red">
                <div class="row-white__title-wrap">
                  <div class="row-white__img">
                    <img class="tm_image" src="/assets/images/solar.png">
                  </div>

                  <div class="row-white__title-text row-white__title-text--first">
                    <p class="row-white__text row-white__text--bold">{{testText.length>85 ? testText.slice(0,85)+'...' : testText}}</p>
                  </div>
                </div>

                <div class="row-white__title-text row-white__title-text--second">
                  <p class="row-white__text row-white__text--gray">{{testText2.length>35 ? testText2.slice(0,35)+'...' : testText2}}</p>
                </div>

                <div class="row-white__title-text row-white__title-text--third">
                  <span class="row-white__text row-white__text--desc">Номер дела</span>
                  <p class="row-white__text row-white__text--small row-white__text--bold">3347634</p>
                </div>

                <div class="row-white__title-text row-white__title-text--fourth">
                  <div class="row-white__reg-progress">
                    <div class="row-white__point" [class.row-white__point--yellow]=true></div>
                    <div class="row-white__point" [class.row-white__point--blue]></div>
                    <div class="row-white__point" [class.row-white__point--purple]></div>
                    <div class="row-white__point" [class.row-white__point--dark-green]></div>
                    <div class="row-white__point" [class.row-white__point--green]></div>
                  </div>
                  <p class="row-white__reg-title">Оплата подтверждена</p>

                  <span class="row-white__attention">Требуется согласование</span>
                </div>
              </div>
            </div>
            <div class="table__big-block">
              <div class="table__row row-white ">
                <div class="row-white__title-wrap">
                  <div class="row-white__img">
                    <img class="tm_image" src="/assets/images/solar.png">
                  </div>

                  <div class="row-white__title-text row-white__title-text--first">
                    <p class="row-white__text row-white__text--bold">{{testText.length>85 ? testText.slice(0,85)+'...' : testText}}</p>
                  </div>
                </div>
                <div class="row-white__title-text row-white__title-text--second">
                  <p class="row-white__text row-white__text--gray">{{testText2.length>35 ? testText2.slice(0,35)+'...' : testText2}}</p>
                </div>

                <div class="row-white__title-text row-white__title-text--third">
                  <span class="row-white__text row-white__text--desc">Номер дела</span>
                  <p class="row-white__text row-white__text--small row-white__text--bold">3347634</p>
                </div>

                <div class="row-white__title-text row-white__title-text--fourth">
                  <div class="row-white__reg-progress">
                    <div class="row-white__point" [class.row-white__point--yellow]="true"></div>
                    <div class="row-white__point" [class.row-white__point--blue]="true"></div>
                    <div class="row-white__point" [class.row-white__point--purple]="true"></div>
                    <div class="row-white__point" [class.row-white__point--dark-green]></div>
                    <div class="row-white__point" [class.row-white__point--green]></div>
                  </div>
                  <p class="row-white__reg-title">Заявка подана в ФИПС</p>
                  <!--                  не убирать div ниже, только span добавлять или удалять по услвию-->
                  <div class="row-white__attention--wrapper">
                    <!--                    <span class="row-white__attention">Требуется согласование</span>-->
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div *ngIf="finished" class="search-style search-style--filter">
      <div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="filter__search-input-wrap">
          <span (click)="showMobileControl = true;makeBodyHidden()">Поиск по делам</span>
          <button class="filter__search-close" *ngIf="showMobileControl === true" (click)="showMobileControl = false;makeBodyHidden()"></button>
        </div>
      </div>
      <div class="container container--search" [ngClass]="showMobileControl === true? 'mobile': ''">
        <form  class="search" [ngClass]="showMobileControl === true? 'mobile': ''">
          <div class="search__input-wrap">
            <svg width="21" height="21" fill="none" viewBox="0 0 21 21">
              <circle cx="9" cy="9" r="8" stroke-width="2"/>
              <path d="M15 15L20 20" stroke-width="2" stroke-linecap="round"/>
            </svg>
            <input class="search__input" placeholder="Поиск по тексту"/>
          </div>

          <div class="search__wrapper">

            <div tabindex="0" class="search__btn-block">
              <button class="search__select-btn" [class.search__select-btn--bold]="getCountCheckedObjects(typeOfObjects)>0">
                <p class="search__label">Статус</p>
                <span>{{ getCountCheckedObjects(typeOfObjects)===0 ? '' : getCountCheckedObjects(typeOfObjects)}}</span>
                <span class="search__select-btn-span"></span>
              </button>
              <div class="search__select">
                <div class="search__hidden-options-wrapper">
                  <div class="search__options-wrapper">

                    <div *ngFor="let option of typeOfObjects" class="search__option">
                      <input class="search__option-input" type="checkbox" name="{{option.value}}" id="{{option.id}}" [(ngModel)]="option.checked">
                      <label class="search__option-label" for="{{option.id}}">{{option.value}}</label>
                    </div>

                  </div>

                  <div class="search__btns-wrapper">
                    <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(typeOfObjects)">Отмена</button>
                    <button type="submit" class="search__btn-submit">Выбрать</button>
                  </div>
                </div>


              </div>
            </div>

            <div tabindex="0" class="search__btn-block">
              <button class="search__select-btn" [class.search__select-btn--bold]="getCountCheckedObjects(objectsSelect)>0">
                <p class="search__label">Тип работ</p>
                <span>{{ getCountCheckedObjects(objectsSelect)===0 ? '' : getCountCheckedObjects(objectsSelect)}}</span>
                <span class="search__select-btn-span"></span>
              </button>
              <div class="search__select">
                <div class="search__hidden-options-wrapper">
                  <div class="search__options-wrapper">
                    <div *ngFor="let option of objectsSelect" class="search__option">
                      <input class="search__option-input" type="checkbox" name="{{option.value}}" id="{{option.id}}" [(ngModel)]="option.checked">
                      <label class="search__option-label" for="{{option.id}}">{{option.value}}</label>
                    </div>

                  </div>

                  <div class="search__btns-wrapper">
                    <button type="button" class="search__btn-cancel" (click)="cleanCheckedObj(objectsSelect)">Отмена</button>
                    <button type="submit" class="search__btn-submit">Выбрать</button>
                  </div>
                </div>


              </div>
            </div>

          </div>
          <div class="search__btns">
            <button class="search__btn btn-search">Найти</button>
            <button class="search__btn btn-clean">Очистить</button>
          </div>
        </form>

      </div>
      <div class="container container--table">
        <div class="table">

          <div class="table__wrapper">
            <div class="table__head-block">
              <div class="table__head-object">Объект</div>
              <div class="table__head-type">Тип работ</div>
              <div class="table__head-number">Номер дела</div>
              <div class="table__head-state">Статус</div>
            </div>
            <div class="table__big-block">
              <div class="table__row row-white row-white--red">
                <div class="row-white__title-wrap">
                  <div class="row-white__img">
                    <img class="tm_image" src="/assets/images/solar.png">
                  </div>

                  <div class="row-white__title-text row-white__title-text--first">
                    <p class="row-white__text row-white__text--bold">{{testText.length>85 ? testText.slice(0,85)+'...' : testText}}</p>
                  </div>
                </div>

                <div class="row-white__title-text row-white__title-text--second">
                  <p class="row-white__text row-white__text--gray">{{testText2.length>35 ? testText2.slice(0,35)+'...' : testText2}}</p>
                </div>

                <div class="row-white__title-text row-white__title-text--third">
                  <span class="row-white__text row-white__text--desc">Номер дела</span>
                  <p class="row-white__text row-white__text--small row-white__text--bold">3347634</p>
                </div>

                <div class="row-white__title-text row-white__title-text--fourth">
                  <div class="row-white__reg-progress">
                    <div class="row-white__point" [class.row-white__point--yellow]=true></div>
                    <div class="row-white__point" [class.row-white__point--blue]></div>
                    <div class="row-white__point" [class.row-white__point--purple]></div>
                    <div class="row-white__point" [class.row-white__point--dark-green]></div>
                    <div class="row-white__point" [class.row-white__point--green]></div>
                  </div>
                  <p class="row-white__reg-title">Оплата подтверждена</p>

                  <span class="row-white__attention">Требуется согласование</span>
                </div>
              </div>
            </div>
            <div class="table__big-block">
              <div class="table__row row-white ">
                <div class="row-white__title-wrap">
                  <div class="row-white__img">
                    <img class="tm_image" src="/assets/images/solar.png">
                  </div>

                  <div class="row-white__title-text row-white__title-text--first">
                    <p class="row-white__text row-white__text--bold">{{testText.length>85 ? testText.slice(0,85)+'...' : testText}}</p>
                  </div>
                </div>
                <div class="row-white__title-text row-white__title-text--second">
                  <p class="row-white__text row-white__text--gray">{{testText2.length>35 ? testText2.slice(0,35)+'...' : testText2}}</p>
                </div>

                <div class="row-white__title-text row-white__title-text--third">
                  <span class="row-white__text row-white__text--desc">Номер дела</span>
                  <p class="row-white__text row-white__text--small row-white__text--bold">3347634</p>
                </div>

                <div class="row-white__title-text row-white__title-text--fourth">
                  <div class="row-white__reg-progress">
                    <div class="row-white__point" [class.row-white__point--yellow]="true"></div>
                    <div class="row-white__point" [class.row-white__point--blue]="true"></div>
                    <div class="row-white__point" [class.row-white__point--purple]="true"></div>
                    <div class="row-white__point" [class.row-white__point--dark-green]></div>
                    <div class="row-white__point" [class.row-white__point--green]></div>
                  </div>
                  <p class="row-white__reg-title">Заявка подана в ФИПС</p>
                  <!--                  не убирать div ниже, только span добавлять или удалять по услвию-->
                  <div class="row-white__attention--wrapper">
                    <!--                    <span class="row-white__attention">Требуется согласование</span>-->
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>

  <app-expert></app-expert>
</section>

<ng-template #loader>
</ng-template>

