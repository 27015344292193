import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dispute-and-court',
  templateUrl: './dispute-and-court.component.html',
  styleUrls: ['./dispute-and-court.component.scss']
})
export class DisputeAndCourtComponent implements OnInit {
  @Output() modalEvent = new EventEmitter();
  openFirstChart = true;
  openSecondChart = true;
  openThirdChart = true;

  showMobileControl = true;
  mobileCharts = false;

  showCompanyList = false;
  selectedCompany = 'по всем компаниям';

  soon = false;

  // Tabs
  claimsTab = true;
  courtsTab = false;
  disputesTab = false;

  // Charts
  claim = true;
  court = true;
  dispute = true;


  claimSum = '15';
  claimInWork = '3';
  courtSum = '3';
  courtInWork = '1';
  disputeSum = '6';
  disputeInWork = '6';

  showMore = false;

  expression: any;

  constructor() {
  }

  partners = ['Капибарыня', 'Православный'];
  currentTab = 'претензиям';
  openFilter = false;

  ngOnInit(): void {
    document.body.style.overflow = 'visible';
    this.windowControl();

    window.addEventListener('resize', () => {
      this.windowControl();
    });
  }

  windowControl() {
    if (window.innerWidth < 500) {
      this.openFirstChart = false;
      this.openSecondChart = false;
      this.openThirdChart = false;
      this.showMobileControl = false;
      this.mobileCharts = true;
    } else {
      this.openFirstChart = true;
      this.openSecondChart = true;
      this.openThirdChart = true;
      this.showMobileControl = true;
      this.mobileCharts = false;
    }
  }


  makeBodyHidden() {
    if (this.showMobileControl === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }

  toCheckCourts() {
    if (this.courtsTab) {
      this.disputesTab = false;
      this.claimsTab = false;
    }

    this.currentTab = 'судам';
  }

  toCheckClaims() {
    if (this.claimsTab) {
      this.courtsTab = false;
      this.disputesTab = false;
    }

    this.currentTab = 'претензиям';
  }

  toCheckDisputesTab() {
    if (this.disputesTab) {
      this.claimsTab = false;
      this.courtsTab = false;
    }

    this.currentTab = 'спорам';
  }

  onSelect(comp): void {
    this.selectedCompany = comp;
    this.showCompanyList = false;
  }


  getFilterStatus() {
    this.openFilter = !this.openFilter;
  }
}
