export const baseEnvironment = {
  production: false,
  corporate: false,
  BASE_ELASTIC_URL_API: 'https://test-api.9958258.ru',
  BASE_URL_API: window.location.host.includes('localhost') ? 'http://master.ipid.lkdev.9958258.ru/api' : `${window.location.protocol}//${window.location.host}/api`,
  INTERCOM_APP_ID: 'fvwd0dw0',
  BRAND: 'IP-ID',
  TITLE: 'IP-ID',
  LOGO_PATH: '/assets/images/Logo_IP_ID.svg',
  LOGO2_PATH: '/assets/images/logo-2.svg',
  LOGO_CASES_PATH: '/assets/images/Logo_IP_ID.svg',
  FOOTER_IMAGE_PATH: '/assets/images/gardium_pro_girl.png',
  FOOTER_IMAGE_MOBILE_PATH: '/assets/images/gardium_pro_girl.png',
  FOOTER_TEXT: `© ${new Date().getFullYear()} IP-ID. Все права защищены.`,
  FAVICON: '/assets/fav/favicon.ipid.ico',
  STATIC_FILES: '/assets/static/default',
  BASE_URL_IMG: 'http://dev.ipid.lkdev.9958258.ru/storage',

  // GOOGLE_SECRET: '239416622088-ikudvck4krb2uvur98nkie0cvs41bf89.apps.googleusercontent.com',
  // YANDEX_ID: '59ed34590fcf4b99b7e50e18cee7c5d1',
  // FACEBOOK_ID: '359036012444798'

  // TODO: Fix me: That constants for corporate brands, I paste them in to skip the error
  BASE_URL_OPEN: '',
  FRONT_URL: '',
};
