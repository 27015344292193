<div class="attention attention--gray" *ngIf="!attentionStep1">
  <p><b>Важно.</b> Банк может перечислить денежные средства на ваш счет, не уведомляя нас об этом. Если вы
    получили деньги, пожалуйста сообщите нам для завершения делопроизводства. Если деньги не будут
    получены до 27.10.2020, то мы направим заявление в следующий банк.</p>
  <a class="btn-blue-attr" (click)=" attentionStep1=true">
    Получил оплату
  </a>
</div>
<div *ngIf="attentionStep1">
  <div class="hidden__block hidden__block--separating">
    <h4 class="hidden__subtitle">Получил оплату напрямую</h4>
    <form>
      <fieldset>
        <div class="hidden__input-wrapper input-checkbox" >
          <input type="radio" name="agreement" id="3" (change)="showOptions=false">
          <label class="input-checkbox__label" for="3" >Согласиться с решением суда
          </label>
        </div>
        <div class="hidden__input-wrapper input-checkbox">
          <input type="radio" name="agreement" id="4"  (change)="showOptions=true" >
          <label  class="input-checkbox__label" for="4" >Обжаловать решение суда
          </label>
        </div>
        <div class="hidden__input-text-wrapper" *ngIf="showOptions">
          <div class="input-text">
            <input type="number" placeholder="Пожалуйста уточните сумму">
          </div>
          <div class="input-text">
            <input type="number" placeholder="Пожалуйста укажите дату оплаты">
          </div>
        </div>
      </fieldset>
      <div class="hidden__btn-wrapper hidden__btn-wrapper--position">
        <a type="submit" class='btn-blue-attr' (click)="attentionStep1=false">Отменить</a>
        <button type="submit" class='btn-blue'>Отправить</button>
      </div>
    </form>
  </div>
</div>
