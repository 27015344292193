<div class="parrent_modal">

  <div class="modal">
    <ng-container *ngIf="!isLoading && sourceData?._source; else loader">

      <div class="top-hat">
        <a class="ext_link" href="{{getExternalLink(sourceData)}}" target="_blank">
          <div class="top-hat__logo-hat"></div>
          <div class="top-hat__info">
            {{ sourceData.indexType }}
          </div>
        </a>
        <button class="top-hat__btn-close" (click)="closeModal()"></button>
        <!--        <button type="button" class="top-hat__button">Задать вопрос онлайн</button>-->
      </div>

      <div class="trademark-body">
        <div class="trademark-body__buttons_risk" *ngIf="data._risk">
          <button *ngIf="data._risk.toLowerCase() ===  'high'" class="btn-risk box_button__warning">
            Высокий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'medium'" class="btn-risk box_button__middle">
            средний риск
          </button>
          <button *ngIf="data._risk.toLowerCase() === 'low'" class="btn-risk box_button__low">
            Низкий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'none'" class="btn-risk box_button__none">
            Нет риска
          </button>
          <div class="risk-hint">
            <div class="risk-tooltip"
                 *ngIf="(data._risk.toLowerCase() ===  'high') || (data._risk.toLowerCase() ===  'medium')"
            >Этот объект создает значительные риски для вашего товарного знака
            </div>
            <div class="risk-tooltip" *ngIf="data._risk.toLowerCase() === 'low' || data._risk.toLowerCase() === 'none'"
            >Этот объект создает незначительные риски для вашего товарного знака
            </div>
          </div>
        </div>

        <div class="trademark-body__header">
          Заявка на наименование мест происхождения товаров № {{sourceData._source.applicationString}}
        </div>

        <div class="trademark-body__data">
          <div class="left-container">
            <div class="image-container">
              <div class="image-container__name">
                {{trimString(sourceData._index === 'organizations' ? sourceData._source.name : sourceData._source.imageText, 42)}}
              </div>
            </div>
            <div class="feature">
              <div class="title">Указание товара</div>
              <div class="text">
                {{sourceData._source.goodsServices[0].gsDescription}}
              </div>
            </div>
          </div>
          <div class="info-container">
            <div class="info-container__top">
              <div class="feature">
                <div class="title">Заявитель</div>
                <div class="text status-trademark">{{sourceData._source.applicantName}}</div>
                <!--                <div class="go-to-btn">Перейти к карточке правообладателя</div>-->
              </div>
              <div class="feature">
                <div class="title">Адрес для переписки</div>
                <div class="text">{{sourceData._source.correspondenceAddress}}</div>
              </div>
              <div class="feature">
                <div class="title">Дата подачи заявки</div>
                <div class="text">
                  {{sourceData._source.applicationDate | date:'dd.MM.yyyy'}}
                </div>
              </div>
              <div class="feature">
                <div class="title">Статус рассмотрения в Роспатенте</div>
                <div class="text" [innerText]="getStatusTrademark(sourceData)"></div>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="divider"></div>-->
        <!--        <app-accordion-->
        <!--          [title]="'Споры и суды'"-->
        <!--          [count]="0"-->
        <!--        >-->
        <!--        </app-accordion>-->

        <!--        <div class="divider"></div>-->
        <!--        <app-accordion-->
        <!--          [title]="'История изменений'"-->
        <!--          [count]="sourceData._source && sourceData._source.changesHistory && sourceData._source.changesHistory.length"-->
        <!--        >-->
        <!--          <div *ngIf="sourceData._source.changesHistory">-->
        <!--            <app-table-->
        <!--              [title]="[{text: 'Дата регистрации', name:'registrationDate'}, {text: 'Дата публикации', name:'publicationDate'}, {text: 'Тип изменения', name:'changeType'}]"-->
        <!--              [data]="sourceData._source.changesHistory"-->
        <!--            ></app-table>-->
        <!--          </div>-->
        <!--        </app-accordion>-->

        <div class="divider"></div>
        <app-accordion
          [title]="'Другие товарные знаки этого правообладателя'"
          [count]="countOverTradeMarks"
        >
          <div class="trademarks-list__wrap">
            <div class="trademarks-list" *ngIf="overTradeMarks">
              <div class="trade-mark-card" *ngFor="let tradeMark of overTradeMarks; let i = index">
                <div class="image-body" *ngIf="tradeMark && tradeMark._source">
                  <img *ngIf="tradeMark._source.imageUrl && tradeMark._source.markImageFileName"
                       [src]="tradeMark._source.imageUrl" alt="">
                  <div *ngIf="!tradeMark._source.imageUrl || !tradeMark._source.markImageFileName"
                       class="text-instead-image">{{ tradeMark._source.imageText }}</div>
                </div>
                <div class="image-text"
                     *ngIf="tradeMark._source && tradeMark._source.indexType">{{tradeMark._source.indexType}}</div>
                <div *ngIf="tradeMark.typeOfOwner === 'owner'" class="label" [innerText]="'владелец'"></div>
                <div *ngIf="tradeMark.typeOfOwner === 'user'" class="label" [innerText]="'по договору'"></div>
              </div>
            </div>
          </div>
        </app-accordion>

      </div>

    </ng-container>
  </div>

  <div (click)="closeModal()" class="overlay-space"></div>
</div>

<div class="page-overlay" id="modal-overlay"></div>

<ng-template #loader>
  <div class="loader-wrapper">
    <app-ls-emb-loader></app-ls-emb-loader>
  </div>
</ng-template>
