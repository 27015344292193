<div class="form__dropdown form-card" *ngIf="viewSelectContainer && candidate">
  <ng-container *ngIf="!isCheckingCandidate; else loading">
    <table class="form-card__table" (click)="checkCurrentOrganization(candidate.PSRN)">
      <thead class="dropdown-hover">
        <tr>
          <th>ОГРН</th>
          <th>ИНН</th>
          <th>Наименование компании или ИП</th>
        </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{candidate.PSRN}}</td>
        <td>{{candidate.TIN}}</td>
        <td class="black">{{candidate.name || candidate.fullName}}</td>
      </tr>
      </tbody>
    </table>
  </ng-container>

  <ng-template #loading>
    <div class="loader payer-check-loader"></div>
  </ng-template>
</div>
