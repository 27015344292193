<div class="page-wrapper">
  <main>
    <section class="form-section profile">
      <div class="container">
        <a (click)="backClicked()" class="back-link">
            <span>
              <svg width="12" height="8" viewBox="0 0 21 16">
                <g clip-path="url(#clip0)">
                 <path d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                </g>
                <defs>
                  <clipPath id="clip0">
                  <rect width="21" height="16" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </span>
          Назад
        </a>
        <div class="container-sm">
          <h1 class="heading-h1 heading-h1--centered">Изменить контактные данные</h1>
          <form [formGroup]="editCompany">
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="name"
                       class="form__input field form__input--dis"
                       type="text"
                       required [disabled]=true style="pointer-events: none">
                <span class="form__input-label">Наименование компании</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="email"
                       [ngClass]="showEmailError() ? 'invalid' : ''"
                       class="form__input form__input--linked field"
                       type="text"
                       required>
                <span class="form__input-label">Email</span>
              </div>
              <p class="error-invalid-message" *ngIf="showEmailError()">Некорректный email</p>
            </div>
            <div class="form__group">
              <div class="form__input-wrap">
                <input formControlName="phone"
                       class="form__input form__input--linked field"
                       type="tel"
                       (keypress)="numericOnly($event)"
                       required>
                <span class="form__input-label">Телефон</span>
              </div>
            </div>
            <div class="form__footer">
              <div *ngIf="!loading" class="btn-wrapper">
                <button class="btn-blue-attr" type="button" (click)="toPrevPage()">Отменить</button>
                <button class="btn" type="button" [class.disabled-save-btn]="!checkChanges() && !showEmailError()" (click)="updateOrganization()">Сохранить данные</button>
              </div>
              <button *ngIf="loading" class="btn" type="button" style="width: 236px"><span class="loaderAuth"></span></button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</div>
