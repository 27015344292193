import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {

  @Input() title;
  @Input() titleTooltip;
  @Input() count = 0;
  @Input() isOpen = false;
  @Input() hideCounter = false;
  @Input() monitoring: any = {};

  constructor() {
  }

  ngOnInit(): void {
  }

  openAccordion() {
    if (this.count || this.hideCounter) {
      this.isOpen = !this.isOpen;
    }
  }

}
