import { enableProdMode, Predicate } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
//
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// Необходимо для расширения станратных функций JS
declare global {
  interface StringConstructor {
    /**
     * Обозначает пустую строку
     * @type string
     */
    Empty: string;

    /**
     * Проверяет входящую строку на null или пустое значение
     * @param value string
     * @return bool
     */
    IsNullOrEmpty(value: string): boolean;
  }

  interface Array<T> {
    /**
     * Возвращает последний элемент массива
     */
    Last<T>(): T;

    /**
     * Проверяет есть ли элемент в массиве отвечающий заданным критериям
     * @param Predicate function
     */
    Any<T>(predicate: Predicate<T>): boolean;
  }
}

String.Empty = '';

/**
 * Проверяет входящую строку на null или пустое значение
 * @param value string
 * @returns bool
 */
String.IsNullOrEmpty = function (value: string): boolean {
  return value === null || value == String.Empty;
};

/**
 * Возвращает последний элемент из массива
 */
Array.prototype.Last = function <T>(): T {
  let temp: Array<T> = this;
  if (temp.length == 0) {
    return;
  }
  return temp[temp.length - 1];
};

/**
 * Проверяет есть ли элемент в массиве отвечающий заданным критериям
 * @param Predicate function
 */
Array.prototype.Any = function <T>(predicate: Predicate<T>): boolean {
  let temp: Array<T> = this;
  let result: boolean = false;
  temp.forEach((v) => {
    if (predicate(v) == true) {
      result = true;
    }
  });

  return result;
};
