<div *ngIf="proxies" class="container">
  <h2 class="visual-hidden">Доверенности</h2>

  <ng-container *ngIf="proxies.length != 0; else notProxies">
    <div class="filter__search" [ngClass]="showMobileControl === true? 'mobile': ''">
      <div class="filter__search-input-wrap">
        <span (click)="showMobileControl = true;makeBodyHidden()">Поиск по доверенностям </span>
        <button class="filter__search-close" *ngIf="showMobileControl === true"
                (click)="showMobileControl = false;makeBodyHidden()"></button>
      </div>
    </div>
    <div class="filter" [ngClass]="showMobileControl === true? 'mobile': ''">
      <div class="dropdown__wrap" [ngClass]="showMobileControl === true? 'mobile': ''">
        <div class="dropdown">
          <button class="dropdown__button" (click)="showFromWhom = !showFromWhom">
            <span *ngIf="getCountChecked('orgFilterData') === 0" class="dropdown__button-text">От кого</span>
            <span *ngIf="getCountChecked('orgFilterData') >= 1" class="dropdown__button-text dropdown__button-text--check">
              Выбрано: {{ getCountChecked('orgFilterData') }}
            </span>
            <img
              class="dropdown__button-img"
              [ngClass]="showFromWhom ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showFromWhom" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">От кого</span>
              <ng-container *ngFor="let organization of filters.orgFilterData">
                <input type="checkbox"
                       [(ngModel)]="organization.checked"
                       class="visual-hidden dropdown__input"/>
                <label class="dropdown__label" (click)="itemChecking(organization)">
                  <span>{{ organization.name }}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropdown__button" (click)="showToWhom = !showToWhom">
            <span *ngIf="getCountChecked('userFilterData') < 1" class="dropdown__button-text">Кому выдана</span>
            <span *ngIf="getCountChecked('userFilterData') >= 1" class="dropdown__button-text dropdown__button-text--check">
              Выбрано: {{ getCountChecked('userFilterData') }}
            </span>
            <img
              class="dropdown__button-img"
              [ngClass]="showToWhom ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showToWhom" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Кому выдана</span>
              <ng-container *ngFor="let user of filters.userFilterData">
                <input type="checkbox"
                       class="visual-hidden dropdown__input"
                       [(ngModel)]="user.checked"
                />
                <label class="dropdown__label" (click)="itemChecking(user)">
                  <span>{{ user.name }}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="dropdown">
          <button class="dropdown__button" (click)="showStatus = !showStatus">
            <span *ngIf="getCountChecked('checkedListStatus') < 1" class="dropdown__button-text">Статус</span>
            <span *ngIf="getCountChecked('checkedListStatus') >= 1" class="dropdown__button-text dropdown__button-text--check">
              Выбрано: {{ getCountChecked('checkedListStatus') }}
            </span>
            <img
              class="dropdown__button-img"
              [ngClass]="showStatus ? 'rotate': ''"
              src="/assets/icons/icon-dropdown.svg" alt="" width="9" height="5"/>
          </button>
          <div *ngIf="showStatus" class="dropdown__menu-wrap">
            <div class="dropdown__menu">
              <span class="dropdown__menu-title">Статус</span>
              <ng-container *ngFor="let status of filters.checkedListStatus">
                <input type="checkbox" [(ngModel)]="status.checked"
                       class="visual-hidden dropdown__input"/>
                <label class="dropdown__label" (click)="itemChecking(status)">
                  <span>{{ status.name }}</span>
                </label>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="filter__buttons">
        <button class="filter__button filter__button--find" (click)="showMobileControl = false;makeBodyHidden()">Найти</button>
        <button class="filter__button filter__button--clear" (click)="clearFilters()">Очистить</button>
      </div>
    </div>
    <div *ngIf="checkAppliedFilter() && proxies.length" class="filter-text-result">
      <b>Применен фильтр:</b>
      Показано
      <b>{{proxies.length}}</b>
      объектов из
      <b>{{proxyCopy.length}}</b>
      <a class="show-all-link" (click)="clearFilters()">Показать все</a>
    </div>
    <ng-container *ngIf="!proxies.length && proxyCopy.length; else tableAttorney">
      <div style="text-align: center; margin-top: 50px">
        Не найдены объекты, соответствующие запросу.<br>
        Попробуйте изменить поиск<br>
        <a class="show-all-link" (click)="clearFilters()">Показать все</a>
      </div>
    </ng-container>
    <ng-template #tableAttorney>
      <div class="attorney">
        <table
          class="attorney__table user-profile-table"
        >
          <tr>
            <th>Файл</th>
            <th>От кого</th>
            <th>Кому выдана</th>
            <th class="attorney__status">Статус</th>
            <th class="attorney__upload">Дата загрузки</th>
            <th>Действует от</th>
            <th>Действует до</th>
          </tr>
          <tr
            *ngFor="let item of proxies | slice:0:showAttorney"
            [ngClass]="{'invalid': item?.status === 'Не действует'}"
          >
            <td class="attorney__file-wrap">
              <a href="{{item?.file?.path}}" target="_blank" class="attorney__file">{{ item?.file?.name }}</a>
            </td>
            <td class="attorney__from" data-label="от">
              {{ item?.attorney?.organization }}
            </td>
            <td class="attorney__to-wrap" data-label="на">
              <span>{{ item?.attorney?.user }}</span>
            </td>
            <td class="attorney__status-wrap">
              <p class="attorney__status-text"
                 [ngClass]="{
                            'wait': item?.attorney?.status === 'На проверке',
                            'valid': item?.attorney?.status === 'Действует',
                            'invalid': item?.attorney?.status === 'Не действует'}"
              >
                {{ item?.attorney?.status }}
              </p>
            </td>
            <td class="attorney__upload-wrap" data-label="Дата загрузки">
              {{ item?.attorney?.dateLoad | date:'dd.MM.yyyy' }}
            </td>
            <td class="attorney__valid-from-wrap" data-label="Действует от">
              {{ item?.attorney?.dateStart | date:'dd.MM.yyyy'}}
            </td>
            <td class="attorney__valid-to-wrap" data-label="до">
              {{ item?.attorney?.dateEnd | date:'dd.MM.yyyy'}}
            </td>
          </tr>
        </table>
        <button class="user-profile-table__show-all" *ngIf="showAttorney < proxies.length" (click)="attorneyShow()">
          Показать еще
        </button>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #notProxies>
    <section class="no-proxies">
      <img src="/assets/icons/icon-no-org.svg" alt="" class="no-proxies__img" width="120" height="32">
      <p class="no-proxies__title">
        У вас еще нет доверенностей
      </p>
      <!--      <p class="no-proxies__desc">-->
      <!--        У вас еще нет добавленной организации в сервисе. Вы можете добавить организацию самостоятельно, или она будет добавлена автоматически-->
      <!--        после первой оплаты в сервисе. После добавления организации изменения возможны только через службу поддержки.-->
      <!--      </p>-->
      <!--      <button class="no-proxies__btn btn">Добавить организацию</button>-->
    </section>
  </ng-template>
</div>
