<div class="events-steps__hidden">
  <div class="events-steps__hidden-text-wrapper hidden hidden--margin">
    <h3>Скачайте, подпишите и отправьте доверенность</h3>
    <div class="hidden__blocks-wrapper">
      <div class="hidden__block">
        <h4 class="hidden__subtitle">Шаг 1</h4>
        <p class="hidden__text">Мы подготовили доваренность, которая необходима для взаимодействия с должником.
          Скачайте, распечатайте и подпишите доверенность. Прикрепите скан или фото доверенности с вашей
          подписью и печатью на следующем шаге.</p>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Доверенность</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
                          <span class="docs__icon">
                            <img src="/assets/images/red-attention.png" class ="docs__icon--mini">
                            <img src="/assets/icons/orange-empty.svg">
                          </span>
          </div>
        </div>
      </div>
      <div class="hidden__block">
        <h4 class="hidden__subtitle">Шаг 2</h4>
        <p>Прикрепите подписанный скан/фото доверенности или отправьте файл через
          <a class="hidden__chat">
            <svg width="20" height="20" viewBox="0 0 20 20">
              <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"/>
              <path d="M4.41931 9.47082L14.061 5.75332C14.5085 5.59166 14.8993 5.86249 14.7543 6.53916L14.7551 6.53832L13.1135 14.2725C12.9918 14.8208 12.666 14.9542 12.2101 14.6958L9.71015 12.8533L8.50431 14.015C8.37098 14.1483 8.25848 14.2608 8.00015 14.2608L8.17765 11.7167L12.811 7.53082C13.0126 7.35332 12.766 7.25332 12.5001 7.42999L6.77431 11.035L4.30598 10.265C3.77015 10.095 3.75848 9.72916 4.41931 9.47082Z" fill="white"/>
            </svg>
            Chat_Gardium
          </a></p>
        <form class="add-file-form">
          <fieldset>
            <div class="add-file-form__file">
              <p>Doverennost.JPG</p>
              <span></span>
            </div>
            <div class="add-file-form__block add-file">
              <svg width="32" height="33" viewBox="0 0 32 33">
                <path d="M17.5586 14.6914H22.3086V17.8086H17.5586V22.5586H14.4414V17.8086H9.69141V14.6914H14.4414V9.94141H17.5586V14.6914ZM31.8086 5.93359V22.5586L22.3086 32.0586H5.68359C4.19922 32.0586 2.91276 31.5143 1.82422 30.4258C0.735677 29.3372 0.191406 28.0508 0.191406 26.5664V5.93359C0.191406 4.44922 0.735677 3.16276 1.82422 2.07422C2.91276 0.985677 4.19922 0.441406 5.68359 0.441406H26.3164C27.8008 0.441406 29.0872 0.985677 30.1758 2.07422C31.2643 3.16276 31.8086 4.44922 31.8086 5.93359ZM28.6914 6.45312C28.6914 5.66146 28.3945 4.99349 27.8008 4.44922C27.2565 3.85547 26.5885 3.55859 25.7969 3.55859H6.20312C5.41146 3.55859 4.71875 3.85547 4.125 4.44922C3.58073 4.99349 3.30859 5.66146 3.30859 6.45312V26.1953C3.30859 26.987 3.58073 27.6797 4.125 28.2734C4.71875 28.8177 5.41146 29.0898 6.20312 29.0898H20.75V26.7148C20.75 25.181 21.2943 23.8698 22.3828 22.7812C23.4714 21.6927 24.7826 21.1484 26.3164 21.1484H28.6914V6.45312Z"/>
              </svg>
              <span class="prompt prompt__question">?
                              <span class="prompt__hidden">
                                   <p>???</p>
                                </span>
                             </span>
              <input type="file" id="doc">
              <label class="add-file__label" for="doc">Прикрепить скан/фото доверенности</label>
              <span class="add-file__subtitle add-file__subtitle--no-hover">Изображение, не более 2мб</span>
            </div>
          </fieldset>
          <button type="submit" class="btn-blue">Отправить доверенность</button>
        </form>
      </div>
    </div>
  </div>
</div>
