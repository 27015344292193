import {StateContext, StateToken, State, Selector, Action, createSelector} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HistoryActions } from './history.actions';
import { FilterDataUsers } from '@web/features/corp/corp-history/models/filters-data.interface';
import { FiltersValueInterface } from '@web/features/corp/corp-history/models/filters-value.interface';
import { SearchesInterface } from '@web/features/corp/corp-history/models/searches.interface';
import { CorpHistoryApiService } from '@web/features/corp/corp-history/services/corp-history-api.service';
import {finalize} from 'rxjs/operators';
import { downloadFileByUrl, openFileByUrl } from '../../../../../app-ipid/src/app/shared/utils/file-utils';
import {proxyStaticUrl} from '../../../../../app-ipid/src/app/shared/helpers/proxy-static';
import {insertItem, patch, removeItem} from '@ngxs/store/operators';
import {DataService} from '../../../../../app-ipid/src/app/shared/services/api.service';
import {HistoryPaginationInterface} from '@web/features/corp/corp-history/models/history-pagination.interface';
import {PayloadHistoryInterface} from '@web/features/corp/corp-history/models/payload-history.interface';

export const CORP_HISTORY_TOKEN = new StateToken<CorpHistoryStateModel>('history');

@State({
  name: CORP_HISTORY_TOKEN,
  defaults: {
    isLoadingPage: true,
    isLoadingResult: false,
    searches: [],
    reports: [],
    usersSearchHistory: [],
    usersReports: [],
    filtersValueHistory: {
      users: [],
      searchType: [],
      searchQuery: null,
      startDate: null,
      finishDate: null
    },
    filtersValueReports: {
      users: [],
      searchType: [],
      searchQuery: null,
      startDate: null,
      finishDate: null
    },
    formFiltersValueHistory: {
      users: [],
      searchType: [],
      searchQuery: null,
      startDate: null,
      finishDate: null
    },
    formFiltersValueReports: {
      users: [],
      searchType: [],
      searchQuery: null,
      startDate: null,
      finishDate: null
    },
    error: null,
    historyPagination: {
      offset: 0,
      limit: 10
    },
    reportsPagination: {
      offset: 0,
      limit: 10
    },
    totalCountSearchHistory: 0,
    filteredCountSearchHistory: 0,
    totalCountReports: 0,
    filteredCountReports: 0,
    downloadingIDList: [],
    currentTab: 'searches',
    payload: {
      owner: false,
      name: false,
      image: false,
      number: false
    }
  }
})

@Injectable()
export class HistoryState {
  constructor(
    private historyApi: CorpHistoryApiService,
    private _dataService: DataService
  )
  {
  }

  static historySelectorByTab() {
    return createSelector(
      [HistoryState.currentPageHistory, HistoryState.currentPageReports, HistoryState.currentTab],
      (currentPageHistory, currentPageReports, currentTab, filtersValue) => {
        return currentTab === 'searches'
          ? currentPageHistory
          : currentPageReports;
      });
  }

  @Selector([HistoryState])
  static currentPageHistory(state: CorpHistoryStateModel): number {
    return Math.floor(state.historyPagination.offset / state.historyPagination.limit) + 1;
  }

  @Selector([HistoryState])
  static currentPageReports(state: CorpHistoryStateModel): number {
    return Math.floor(state.reportsPagination.offset / state.reportsPagination.limit) + 1;
  }

  @Selector([HistoryState])
  static downloadingIDList(state: CorpHistoryStateModel): string[] {
    return state.downloadingIDList;
  }

  @Selector([HistoryState])
  static currentTab(state: CorpHistoryStateModel): 'searches' | 'reports' {
    return state.currentTab;
  }

  @Action(HistoryActions.Init)
  async init(ctx: Context, { payload, onlyReport }: HistoryActions.Init) {
    const { historyPagination, reportsPagination } = ctx.getState();
    const params = onlyReport
      ? {...reportsPagination, onlyReport}
      : {...historyPagination};
    try {
      const response = await this.historyApi.getSearchHistory( { ...params } );
      ctx.setState(
        patch({
          payload
        }));
      this.updateRights(response, payload);
      onlyReport
        ? ctx.dispatch(new HistoryActions.InitReportsSuccess(response))
        : ctx.dispatch(new HistoryActions.InitHistorySuccess(response));
    } catch (error) {
      ctx.dispatch(new HistoryActions.InitFail(error));
    }
  }

  @Action(HistoryActions.InitReportsSuccess)
  public initReportsSuccess(ctx: Context, { response }: HistoryActions.InitReportsSuccess) {
    ctx.patchState({
      usersReports: response.users,
      totalCountReports: response.totalCount,
      filteredCountReports: response.filteredCount,
      reports: response.searches,
      isLoadingResult: false
    });
  }

  @Action(HistoryActions.InitHistorySuccess)
  public initHistorySuccess(ctx: Context, { response }: HistoryActions.InitHistorySuccess) {
    ctx.patchState({
      usersSearchHistory: response.users,
      totalCountSearchHistory: response.totalCount,
      filteredCountSearchHistory: response.filteredCount,
      searches: response.searches,
      isLoadingPage: false,
      isLoadingResult: false
    });
  }

  @Action(HistoryActions.InitFail)
  public initFail(ctx: Context, error) {
    ctx.patchState({ isLoadingResult: false, error });
  }


  private updateRights(historyResponse, payload): void {
    historyResponse
      .searches.forEach(el => {
      switch (el.searchParameters.searchType) {
        case 'owner':
          el.right = payload.owner;
          break;
        case 'name':
          el.right = payload.name;
          break;
        case 'number':
          el.right = payload.number;
          break;
        case 'image':
          el.right = payload.image;
          break;
      }
    });
  }

  @Action(HistoryActions.GetHistory)
  async getHistory(ctx: Context, { onlyReport }: HistoryActions.GetHistory) {
    ctx.patchState({
      isLoadingResult: true,
      error: null
    });
    const { filtersValueHistory, filtersValueReports, historyPagination, reportsPagination } = ctx.getState();
    const params = onlyReport
      ? {...this.removeEmptyFilters(filtersValueReports), ...reportsPagination, onlyReport}
      : {...this.removeEmptyFilters(filtersValueHistory), ...historyPagination};
    try {
      const response = await this.historyApi.getSearchHistory({ ...params });
      this.updateRights(response, ctx.getState().payload);
      onlyReport
        ? ctx.dispatch(new HistoryActions.InitReportsSuccess(response))
        : ctx.dispatch(new HistoryActions.InitHistorySuccess(response));
    } catch (error) {
      ctx.dispatch(new HistoryActions.InitFail(error));
    }
  }

  @Action(HistoryActions.ChangeFilters)
  async changeFilters(ctx: Context, { filters, onlyReport }: HistoryActions.ChangeFilters) {
    ctx.patchState({
      isLoadingResult: true,
      error: null,
    });
    onlyReport
      ? ctx.patchState({reportsPagination: {limit: 10, offset: 0}, filtersValueReports: filters})
      : ctx.patchState({historyPagination: {limit: 10, offset: 0}, filtersValueHistory: filters});
    ctx.dispatch(new HistoryActions.GetHistory(onlyReport));
  }

  @Action(HistoryActions.ChangePage)
  async changePage(ctx: Context, { page, onlyReport }: HistoryActions.ChangePage) {
    const { historyPagination, reportsPagination } = ctx.getState();
    onlyReport
      ? ctx.patchState({
        isLoadingResult: true,
        error: null,
        reportsPagination: {
          offset: (page - 1) * reportsPagination.limit,
          limit: 10
        }
      })
      : ctx.patchState({
        isLoadingResult: true,
        error: null,
        historyPagination: {
          offset: (page - 1) * historyPagination.limit,
          limit: 10
        }
      });
    ctx.dispatch(new HistoryActions.GetHistory(onlyReport));
  }

  removeEmptyFilters(filters: FiltersValueInterface) {
    const newFilter: any = {};
    if (filters.users?.length) {
      newFilter.users = filters.users;
    }
    if (filters.searchType?.length) {
      newFilter.searchType = filters.searchType;
    }
    if (filters.searchQuery?.length) {
      newFilter.searchQuery = filters.searchQuery;
    }
    if (filters.startDate?.length) {
      newFilter.startDate = filters.startDate;
    }
    if (filters.finishDate?.length) {
      newFilter.finishDate = filters.finishDate;
    }
    return newFilter;
  }

  @Action(HistoryActions.DownloadReport)
  async downloadReport(ctx: Context, { search }: HistoryActions.DownloadReport) {
    ctx.setState(
      patch<CorpHistoryStateModel>({
        downloadingIDList: insertItem<string>(search.searchID)
      })
    );

    this._dataService.getReportFileUrl(search.searchID)
      .pipe(
        finalize(() =>
          ctx.setState(
            patch<CorpHistoryStateModel>({
              downloadingIDList: removeItem<string>(searchID => searchID === search.searchID)
            })
          )
        )
      )
      .subscribe((data) => {
        downloadFileByUrl(proxyStaticUrl(data.file));
      });
  }

  @Action(HistoryActions.OpenReport)
  async openReport(ctx: Context, { searchId }: HistoryActions.OpenReport) {
    ctx.setState(
      patch<CorpHistoryStateModel>({
        downloadingIDList: insertItem<string>(searchId)
      })
    );

    this._dataService.getReportFileUrl(searchId)
      .pipe(
        finalize(() =>
          ctx.setState(
            patch<CorpHistoryStateModel>({
              downloadingIDList: removeItem<string>(searchID => searchID === searchId)
            })
          )
        )
      )
      .subscribe((data) => {
        openFileByUrl(proxyStaticUrl(data.file));
      });
  }

  @Action(HistoryActions.ChangeTab)
  async changeTab(ctx: Context, { tab }: HistoryActions.ChangeTab) {
    ctx.patchState({
      currentTab: tab
    });
  }

  @Action(HistoryActions.GetFormFiltersValue)
  async GetFormFiltersValue(ctx: Context, { filters }: HistoryActions.GetFormFiltersValue) {
    ctx.getState().currentTab === 'searches'
      ? ctx.patchState({formFiltersValueHistory: filters})
      : ctx.patchState({formFiltersValueReports: filters});
  }
}

type Context  = StateContext<CorpHistoryStateModel>;

export interface CorpHistoryStateModel {
  isLoadingPage: boolean;
  isLoadingResult: boolean;
  searches: SearchesInterface[];
  error: string | null;
  usersSearchHistory: FilterDataUsers[];
  usersReports: FilterDataUsers[];
  filtersValueHistory: FiltersValueInterface;
  filtersValueReports: FiltersValueInterface;
  formFiltersValueHistory: FiltersValueInterface | Record<string, any>;
  formFiltersValueReports: FiltersValueInterface | Record<string, any>;
  historyPagination: HistoryPaginationInterface;
  reportsPagination: HistoryPaginationInterface;
  totalCountSearchHistory: number;
  filteredCountSearchHistory: number;
  totalCountReports: number;
  filteredCountReports: number;
  reports: SearchesInterface[];
  downloadingIDList: string[];
  currentTab: 'searches' | 'reports';
  payload: PayloadHistoryInterface;
}
