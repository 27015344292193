import { baseEnvironment } from 'projects/app-ipid/src/environments/base.environment';

const env = (window as any)?.GardiumEnv?.environment;

export let environment = env
  ? env
  : {
      ...baseEnvironment,

      BRAND: 'IP-ID',
      TITLE: 'IP-ID',
      STATIC_FILES: '/assets/static-files/ipid',

      // Константы для поиска /search страница
      SEARCH_STYLES: {
        SEARCH_POSIBILITY_TEXT: 'Высокая с IP-ID',
        SEARCH_SPEEDOMETER_BACKGROUND: '#FFFFFF',
        SEARCH_SPEEDOMETER_OUTLINE: '#E1E5EB',
      },
    };
