import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemLayoutComponent } from '../layout/system-layout/system-layout.component';
import { AuthGuard } from '../../shared/guardes/auth-guard.service';
import { DisputeAndCourtComponent } from './index/dispute-and-court.component';
import { ClaimPageComponent } from './pages/claim-page/claim-page.component';
import { CourtPageComponent } from './pages/court-page/court-page.component';
import { DisputePageComponent } from './pages/dispute-page/dispute-page.component';


const systemRoutes: Routes = [
  {
    path: '',
    component: DisputeAndCourtComponent
  },
  {
    path: 'claim-page',
    component: ClaimPageComponent
  },
  {
    path: 'court-page',
    component: CourtPageComponent
  },
  {
    path: 'dispute-page',
    component: DisputePageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(systemRoutes)],
  exports: [RouterModule]
})
export class CourtRoutingModule {
}
