<div class="open-cases__tabs">
  <button (click)="switchCasesTab('cases')"
          [class.active]="caseTab === 'cases'"
          class="open-cases__tabs-btn"
          [disabled]="!casesAll?.length"
  >
    Мои дела
  </button>
  <button (click)="switchCasesTab('archive')"
          [class.active]="caseTab === 'archive'"
          class="open-cases__tabs-btn"
          [disabled]="!casesAll?.length"
  >
    Архив
  </button>
</div>

<ng-container *ngIf="!isLoadingCases; else loading">

  <ng-container *ngIf="casesAll?.length; else noCases">

    <div *ngIf="caseTab === 'cases'" class="">
      <div class="open-case__wrap">
        <ng-container *ngFor="let caseItem of casesAll">
          <app-open-case-item
            [case]="caseItem"
          ></app-open-case-item>
        </ng-container>

        <div class="open-case__new" (click)="openModal()">
          Новая заявка
        </div>
      </div>
    </div>

    <div *ngIf="caseTab === 'archive'" class="">
      <div class="open-case__wrap">
        <ng-container *ngFor="let caseItem of cases.finished">
          <app-open-case-item
            [case]="caseItem"
          ></app-open-case-item>
        </ng-container>
      </div>
    </div>

  </ng-container>

  <ng-template #noCases>
    <div class="open-no-cases">
      <div class="open-no-cases__img">
        <img src="../../assets/open/images/ico.svg" width="34" height="45">
      </div>
      <p class="open-no-cases__title">У вас пока не заведено ни одного дела</p>
      <p class="open-no-cases__desc">Вы можете подать заявку самостоятельно или создать на основе события из мониторинга рисков.</p>
      <button class="open-no-cases__btn" (click)="openModal()">Новая заявка</button>
    </div>
  </ng-template>
</ng-container>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
