import { Injectable } from '@angular/core';
import { HttpService } from '../../app-ipid/src/app/shared/services/http.service';
import { ILogin } from '../../app-debit/src/app/models/main.interfaces';

const path = 'user';


@Injectable()
export class LoginService {

  constructor(private http: HttpService) {
  }

  login(body, params) {
     return this.http.post<ILogin>({path: `${path}/login`, body: {body}, subjectType: 'login', params});
  }

}
