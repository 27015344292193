import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-okved-list-view',
  templateUrl: './okved-list-view.component.html',
  styleUrls: ['./okved-list-view.component.scss']
})
export class OkvedListViewComponent implements OnInit {

  @Input() codes: Array<{ code: string, description: string }> = [];
  @Input() limit = 5;
  @Input() countriesDropdown?: boolean;

  showAll = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  get showedItems() {
    if (!this.hasMoreThanLimit || this.showAll) {
      return this.codes;
    } else {
      return this.codes.slice(0, this.limit);
    }
  }

  onShowAll() {
    this.showAll = true;
  }

  get hasMoreThanLimit() {
    return this.codes?.length > this.limit;
  }

}
