import { Directive, Input } from '@angular/core';
import { ILvFilter } from '../lv-filter/lv-filter.interface';
import { getProp } from '../../../utils/object-utils';

@Directive({
  selector: '[lvFilterDaterange]'
})
export class LvFilterDaterangeDirective implements ILvFilter {
  readonly type = 'daterange';

  @Input() value: [Date, Date];
  @Input() placeholder: string;

  @Input() filterFn: (row: any) => boolean;
  @Input() filterField: string;

  constructor() {
  }

  private defaultFilterRow = (row: any) => {
    if (!this.value?.length) {
      return true;
    }

    const rowDate = getProp(row, this.filterField);
    if (!rowDate) {
      return false;
    }

    const date = new Date(rowDate).getTime();
    const startTime = this.toStartOfDay(this.value[0]).getTime();
    const endTime = this.toEndOfDay(this.value[1]).getTime();

    return date >= startTime && date <= endTime;
  };

  private toStartOfDay(date: Date) {
    date = new Date(date);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(1);
    return date;
  }

  private toEndOfDay(date: Date) {
    date = new Date(date);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  getFilterFn() {
    if (this.filterFn) {
      return this.filterFn;
    } else if (this.filterField) {
      return this.defaultFilterRow;
    } else {
      throw new Error('filterField or filterFn required in "lvFilterDaterange"');
    }
  }

}
