import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorService {

  constructor(
    private readonly toastr: ToastrService,
  ) {
  }

  httpRequestError(error, operation: string) {
    this.showError(`Operation ${operation} failed with error: ${error?.error?.message || error?.error?.answer || 'undefined error'}`);
  }

  errorSaveSearchHistory = (e) => {
    console.error('error save search-history', e);
    this.showError('Ошибка при сохранении истории поиска');
  };

  showError(message: string, title?: string) {
    // на проде не показываем
    if (environment.production) {
      return;
    }

    this.toastr.error(message, title);
  }

}
