import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../../../shared/services/http.service';
import { interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../../../../../../../app-search/src/app/services/search.service';
import {EnvService} from '../../../../shared/services/env.service';
import { UserProfileService } from '../../../../../../../shared/services/user-profile.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { Store } from '@ngxs/store';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';

declare var window: any;

export interface IYandexResponseToken {
  id: string;
  confirmation: {
    confirmation_token: string
  };
}

const currentHost = `${window.location.protocol}//${window.location.host}`;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit, OnDestroy {

  private readonly onDestroyTimer = new Subject<void>();
  private readonly onDestroy = new Subject<void>();

  @Input() paymentSum; // сумма к оплате
  @Input() promoCode; // промокод
  @Input() requestType; // тип заявки
  @Input() idApplication; // id заявки
  @Input() activeTariff; // id выбранного тарифа
  @Input() duty; // выбранные пошлины
  @Input() owner; // плательщик (только для заявок на ТЗ и поиск и оценку охраноспособности)
  @Input() company = []; // список плательщиков
  @Output() switchPaymentType = new EventEmitter(); // событие переключения способа оплаты
  @Output() nextStepEvent = new EventEmitter(); // событие перехода на следующий шаг
  @Output() backStepEvent = new EventEmitter(); // событие перехода на предыдущий шаг
  showOptions = false; // показать/скрыть выпадающий список плательщиков
  selectedCompany; // выбранный плательщик из списка правообладателей

  payError = {
    status: false,
    text: '',
  };
  production: boolean;
  logo2path = '';
  yaLastPaymentId: string;
  returnAfterPayment;
  yaPaymentStatus: string;
  payWidjetIsRender = false;
  payResponse: any;
  formPay: FormGroup; // форма плательщика с полными данными (инн, огрн, адрес, ...)
  formPayer: FormGroup; // форма плательщик по инн
  paymentTab; // тип оплаты
  timerSubscription: Subscription;
  tempPayer; // найденный плательщик по инн
  selectedPayer; // выбранный плательщик по инн
  selectedPayerString; // выбранный плательщик физ лицо
  viewSelectContainer = true; // отображать выпадающий блок после ввода ИНН/ОГРН
  thirdStepSended = false;
  payerError = false;
  payerCheckLoading = false;
  companyChecked = true;
  showSuccess = true;
  showActTab = false;
  disableButton = false;
  loading = false;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private envService: EnvService,
    private profileApi: UserProfileService,
    private readonly intercomEventsService: IntercomEventsService,
    private store: Store
  ) {
    this.logo2path = environment.LOGO2_PATH;
    this.production = environment.production;
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.company?.length) {
      this.companyChecked = this.company.some(el => {
        return (el?.inn?.length || el?.TIN?.length)
          || el?.name?.TIN?.length
          || (el.name !== 'Указать другого плательщика' && el.name !== 'Выберите плательщика');
      });
      this.selectedCompany = !this.companyChecked ? this.company[1] : this.company[0];
    }
    }, 1000);
    this._initFormPay();

    if (this.store.selectSnapshot(AUTH_STATE_TOKEN).user?.certificate_of_completion && this.envService.isX5) {
      this.showActTab = true;
      this.paymentTab = 'act';
      this.switchPaymentTab('act');
    } else {
      this.paymentTab = 'cash';
    }
    this._initFormPayer();
    this.getUrlParam();
    const payerTempString = localStorage.getItem('selectedPayerString');
    const payerTempObj = localStorage.getItem('selectedPayer');
    if (payerTempString && payerTempString !== 'undefined') {
      this.selectedPayer = payerTempString;
      localStorage.removeItem('selectedPayerString');
    } else if (payerTempObj && payerTempObj !== 'undefined') {
      this.selectedPayer = JSON.parse(payerTempObj);
      localStorage.removeItem('selectedPayer');
    }
    if (this.paymentTab === 'online') {
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_PAYMENT_ONLINE });
    }
  }

  getUrlParam() {
    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.idApplication = params.id;
      }
      this.returnAfterPayment = params.returnAfterPayment;
      const lastPaymentId = params.t;
      if (lastPaymentId) {
        this.yaLastPaymentId = params.t;
      }

      if (this.returnAfterPayment) {
        const source = interval(1000 * 30);
        this.testPayStatus();
        this.timerSubscription = source.pipe(takeUntil(this.onDestroyTimer)).subscribe(val => {
          this.testPayStatus();
        });
      } else {
        setTimeout(() => {
          this.sendTariff();
        }, 1700);
      }
    });
  }

  repeatPayment() {
    this.yaLastPaymentId = null;
    this.payError = {
      status: false,
      text: '',
    };

    // TODO: восстановить url, очистить get params

    this.initYandexCheckout();
  }

  preInitYandexPay(options?) {
    if (this.returnAfterPayment && this.returnAfterPayment === 'true') {
      if (options.reInit) {
        this.initYandexCheckout();
      } else {
        this.testPayStatus();
      }
    } else {
      this.payError.status = false;
      this.payError.text = '';
      this.initYandexCheckout();
    }
  }

  initYandexCheckout() {
    // this.http.get(`http://10.1.33.225:9999/api/yandex_payment/create?sum=${sum}&responseID=${this.idApplication}`).subscribe((data: IYandexResponseToken) => {
    this.httpService.get(
      {
        path: `yandex_payment/create?responseID=${this.idApplication}`,
        subjectType: 'recover password'
      }
    ).subscribe((data: IYandexResponseToken) => {
      if (data?.confirmation?.confirmation_token && data?.id) {
        console.log('payment_id and confirmation_token', data);
        this.yaLastPaymentId = data.id;

        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: data.confirmation.confirmation_token,
          return_url: `${currentHost}/request?type=${this.requestType}&id=${this.idApplication}&returnAfterPayment=true&t=${this.yaLastPaymentId}`,
          error_callback(error) {
            console.error('ya pay err', error);
            // Обработка ошибок инициализации
          }
        });

        // Отображение платежной формы в контейнере
        checkout.render('payment-form');
        this.checkPayRender();

      } else {
        console.error('В ответе нет confirmation_token и/или payment_id');
      }
    });
  }

  getButtonPayText() {
    console.log(333, this.paymentTab);
    if (this.paymentTab === 'cash') {
      return 'Выставить счет';
    } else {
      return 'Включить в акт';
    }
  }

  getStatusButton(): boolean {
    return [this.selectedCompany?.name === 'Выберите плательщика' || this.selectedCompany?.name === 'Указать другого плательщика']
      && !this.selectedPayer && !this.selectedPayerString;
  }

  testPayStatus() {
    this.yaPaymentStatus = null;

    if (this.yaLastPaymentId) {
      // this.http.get(`http://10.1.33.225:9999/api/yandex_payment/check?payment_id=${this.yaLastPaymentId}`).subscribe((data: any) => {
      this.httpService.get(
        {
          path: `yandex_payment/check?payment_id=${this.yaLastPaymentId}&responseID=${this.idApplication}`,
          subjectType: 'yandex_payment/check'
        }
      ).subscribe((data: any) => {
        console.log('getstatus ya pay', data);
        this.payResponse = data;
        if (this.payResponse?.status === 'succeeded') {
          this.sendPayJSON('online', data);
          this.onDestroyTimer.next();
        }
        if (this.payResponse?.status === 'canceled') {
          this.payError.status = true;
          switch (this.payResponse?.cancellation_details?.reason) {
            case '3d_secure_failed':
              this.payError.text = `Не пройдена аутентификация по 3-D Secure. <br class="hide-up-to-md">
            Вам следует повторить платеж, обратиться в банк за уточнениями или использовать другое платежное средство.`;
              break;
            case 'card_expired':
              this.payError.text = `Истек срок действия банковской карты. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'fraud_suspected':
              this.payError.text = `Платеж заблокирован из-за подозрения в мошенничестве. <br class="hide-up-to-md">
            Вам следует использовать другое платежное средство.`;
              break;
            case 'insufficient_funds':
              this.payError.text = `Не хватает денег для оплаты. <br class="hide-up-to-md">
            Вам следует пополнить баланс или использовать другое платежное средство.`;
              break;
            case 'invalid_card_number':
              this.payError.text = `Неправильно указан номер карты. <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'invalid_csc':
              this.payError.text = `Неправильно указан код CVV2 (CVC2, CID). <br class="hide-up-to-md">
            Вам следует повторить платеж и ввести корректные данные.`;
              break;
            case 'payment_method_restricted':
              this.payError.text = `Операции данным платежным средством заблокированы. <br class="hide-up-to-md">
            Вам следует обратиться в организацию, выпустившую платежное средство.`;
              break;
            default:
              this.payError.text = `Произошла ошибка при оплате. <br class="hide-up-to-md">
            Проверьте правильность введенных данных или срок действия карты.`;
          }
          this.onDestroyTimer.next();
        }

        this.yaPaymentStatus = data;
      });
    }
  }

  checkPayRender() {
    setTimeout(() => {
      const el = document.getElementsByTagName('iframe');
      console.log(el[0]);
      if (el[0]) {
        this.payWidjetIsRender = true;
      } else {
        this.payWidjetIsRender = false;
        this.checkPayRender();
      }
    }, 300);
  }

  /**
   * отправка данных для оффайн оплаты
   */
  sendPayJSON(type: string, yandexResponse?: any): void {
    this.loading = true;
    // if (this.requestType === 'trademark') {
    let userID;
    if (localStorage.getItem('currentUserData')) {
      userID = JSON.parse(localStorage.getItem('currentUserData')).userID;
    }

    let reqData;
    if (type === 'cash') {
      reqData = {
        // TODO: вернуть назад поэтапную оплату
        // stage: this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline) ? 5 : 4,
        stage: 4,
        type: 'cash',
        userId: userID,
        responseId: this.idApplication,
        inn: this.formPay.controls.INN.value?.toString(),
        ogrn: this.formPay.controls.OGRN.value?.toString(),
        name: this.formPay.controls.name.value?.toString() || this.selectedPayerString,
        address: this.formPay.controls.address.value?.toString(),
        sum: this.formPay.controls.sum.value.toString(),
        // duty: this.duty
      };
    } else if (type === 'online') {
      if (this.selectedPayer || this.owner) {
        reqData = {
          // TODO: вернуть назад поэтапную оплату
          // stage: this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline) ? 5 : 4,
          stage: 4,
          type: 'online',
          userId: userID,
          responseId: this.idApplication,
          inn: this.selectedPayer?.TIN || this.owner?.inn,
          ogrn: this.selectedPayer?.PSRN || this.owner?.ogrn,
          name: this.selectedPayer?.name || this.selectedPayer?.fullName || this.selectedPayer || this.owner?.name,
          address: this.selectedPayer?.address || this.owner?.address,
          // duty: this.duty,
          yandexResponse,
        };
      } else {
        reqData = {
          // TODO: вернуть назад поэтапную оплату
          // stage: this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline) ? 5 : 4,
          stage: 4,
          type: 'online',
          userId: userID,
          responseId: this.idApplication,
          yandexResponse,
          // duty: this.duty
        };
      }
    } else if (type === 'act') {
      reqData = {
        stage: this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline) ? 5 : 4,
        type: 'act',
        userId: userID,
        responseId: this.idApplication,
        inn: this.formPay.controls.INN.value?.toString(),
        ogrn: this.formPay.controls.OGRN.value?.toString(),
        name: this.formPay.controls.name.value?.toString() || this.selectedPayerString,
        address: this.formPay.controls.address.value?.toString(),
        sum: this.formPay.controls.sum.value.toString(),
        duty: this.duty
      };
      console.log(reqData, 22);
    }

    this.httpService.post({path: 'request/load', body: reqData}).subscribe((data: any) => {
      // this.loading = false;

      this.nextStep();
      localStorage.setItem('selectCaseUnfinished', this.idApplication);

      this.intercomEventsService.push({
        event: type === 'online'
          ? INTERCOM_EVENT_NAME.FORM_PAYMENT_ONLINE_SUCCESS
          : INTERCOM_EVENT_NAME.FORM_PAYMENT_BILL_SUCCESS,
        itemType: this.requestType,
        applyRate: this.activeTariff,
        totalPrice: +this.paymentSum
      });
    });
    // } else {
    //   this.nextStep();
    // }
  }


  /**
   * переключение панели способа оплаты
   * @param type: выбранный тип оплаты
   */
  switchPaymentTab(type: string): void {
    if (type === this.paymentTab) {
      return;
    }
    if (type === 'cash') {
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_PAYMENT_BILL, itemType: this.requestType });
      this.switchPaymentType.emit('cash');
    // } else if (type === 'online') {
    //   this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_PAYMENT_ONLINE, itemType: this.requestType });
    //   this.switchPaymentType.emit('online');
    //   this.preInitYandexPay({reInit: true});
    } else if (type === 'act') {
      this.switchPaymentType.emit('act');
    }
    this.paymentTab = type;
  }

  /**
   * инициализация формы оффлайн оплаты
   */
  private _initFormPay(): void {
    if (this.selectedPayer) {
      this.formPay = this.fb.group({
        INN: [this.selectedPayer.inn || this.selectedPayer.TIN, [Validators.required]],
        OGRN: [this.selectedPayer.ogrn || this.selectedPayer.PSRN, [Validators.required]],
        name: [this.selectedPayer.fullName || this.selectedPayer.name, [Validators.required]],
        address: [this.selectedPayer.address || this.selectedPayer.legalAdress, [Validators.required]],
        sum: [(+this.paymentSum).toLocaleString('ru') + ' ₽', [Validators.required]],
      });
    } else {
      this.formPay = this.fb.group({
        INN: ['', [Validators.required]],
        OGRN: ['', [Validators.required]],
        name: ['', [Validators.required]],
        address: ['', [Validators.required]],
        sum: [(+this.paymentSum).toLocaleString('ru') + ' ₽', [Validators.required]],
      });
    }
  }

  /**
   * инициализация формы плательщика
   */
  private _initFormPayer(): void {
    this.formPayer = this.fb.group({
      payer: [null, [Validators.required]],
    });
  }

  /**
   * отправка выбранного тарифа в юрайт
   */
  sendTariff(): void {
    const tariffBody = {
      responseID: this.idApplication,
      tariffID: this.activeTariff,
      // TODO: вернуть назад поэтапную оплату
      // stage: this.requestType === 'trademark' && (this.envService.isAlfa || this.envService.isGardiumOnline) ? 4 : 3,
      stage: 3,
      promocode: this.promoCode,
      // duty: this.duty
    };
    this.httpService.post({path: 'request/load', body: tariffBody}).subscribe((data: any) => {
      this.thirdStepSended = true;
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_TM_RATE_SENT, typeIP: this.requestType, totalPrice: this.paymentSum });
      if (this.paymentTab === 'online') {
        this.preInitYandexPay();
      }
    });
  }

  nextStep() {
    this.nextStepEvent.emit();
  }

  backStep() {
    this.backStepEvent.emit();
  }

  onSelect(comp): void {
    this.selectedCompany = comp;
    this.showOptions = false;
    this.tempPayer = undefined;
    this.selectedPayer = undefined;
    this.selectedPayerString = undefined;
    if (this.formPay.get('payer')) {
      this.formPay.get('payer').setValue('');
    }
    if (comp.name !== 'Указать другого плательщика' && comp.name !== 'Выберите плательщика') {
      if (typeof comp === 'object') {
        this.selectedPayer = comp;
        console.log(this.selectedPayer);
        localStorage.setItem('selectedPayer', JSON.stringify(this.selectedPayer));
        localStorage.removeItem('selectedPayerString');
        this.setSelectedPayer();
      } else if (typeof comp === 'string') {
        this.selectedPayerString = comp;
        localStorage.setItem('selectedPayerString', this.selectedPayerString);
        localStorage.removeItem('selectedPayer');
      }
    } else {
      localStorage.removeItem('selectedPayer');
      localStorage.removeItem('selectedPayerString');
    }
  }

  getCompanyName(comp) {
    if (typeof comp === 'string' || comp === null) {
      return comp;
    } else {
      return `${comp.TIN}<span> / ${comp.name}</span>`;
    }
  }

  payerChanged(innOgrn: string) {
    if (this.viewSelectContainer) {
      this.tempPayer = undefined;
      if (this.envService.isCorp || (!this.envService.isCorp && this.requestType === 'trademark') || (!this.envService.isCorp && !!+innOgrn === true && this.requestType !== 'trademark')) {
        this.selectedPayer = undefined;
      } else if (!this.envService.isCorp && !!+innOgrn === false && this.requestType !== 'trademark') {
        this.selectedPayer = innOgrn;
      }
      if (this.selectedPayer && !this.envService.isCorp && this.requestType !== 'trademark') {
        this.formPay.get('name').setValue(this.selectedPayer);
      }
      if (innOgrn.length > 2) {
        this.getFullPayer(innOgrn);
      }
    } else {
      this.viewSelectContainer = true;
    }
  }

  getFullPayer(id) {
    const query = {
      id,
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits[0]?._source && id === this.formPayer.controls.payer.value) {
        this.tempPayer = data.hits.hits[0]._source;
      }
    });
  }

  checkValidInnOgrn(value) {
    return value && !isNaN(+value) && (value.length === 10 || value.length === 12 || value.length === 13 || value.length === 15);
  }

  payerSelected() {
    this.selectedPayer = this.tempPayer;
    this.owner = this.tempPayer;
    this.tempPayer = undefined;
    this.setSelectedPayer();
    if (!this.checkValidInnOgrn(this.formPayer.get('payer').value)) {
      this.viewSelectContainer = false;
      this.formPayer.get('payer').setValue(this.selectedPayer.TIN);
    }
    localStorage.setItem('selectedPayer', JSON.stringify(this.selectedPayer));
    localStorage.removeItem('selectedPayerString');
  }

  setSelectedPayer() {
    this.formPay.get('INN').setValue(this.selectedPayer.TIN || this.selectedPayer.inn);
    this.formPay.get('OGRN').setValue(this.selectedPayer.PSRN || this.selectedPayer.ogrn);
    this.formPay.get('name').setValue(this.selectedPayer.fullName || this.selectedPayer.name);
    this.formPay.get('address').setValue(this.selectedPayer.address || this.selectedPayer.legalAdress);
  }

  ngOnDestroy(): void {
  }

  getDynamicMarginForLegalName() {
    switch (this.formPayer.get('payer').value.length) {
      case 10:
        return {left: '132px'};
      case 12:
        return {left: '150px'};
      case 13:
        return {left: '158px'};
      case 15:
        return {left: '174px'};
    }
  }

  cancelRatentee() {
    this.tempPayer = undefined;
    this.selectedPayer = this.formPayer.get('payer').value;
    if (this.selectedPayer && !this.envService.isCorp && this.requestType !== 'trademark') {
      this.formPay.get('name').setValue(this.selectedPayer);
    }
    localStorage.setItem('selectedPayer', this.selectedPayer);
  }

  checkCurrentOrganization(ogrn) {
    this.payerError = false;
    this.payerCheckLoading = true;
    const reqData = {
      PSRN: ogrn
    };
    this.httpService.get({path: 'organizations/check', params: reqData}).subscribe((data: any) => {
      this.payerCheckLoading = false;
      this.payerError = !data.passed;
      if (data.passed) {
        this.payerSelected();
      } else {
        this.tempPayer = undefined;
      }
    });
  }
}
