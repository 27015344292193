import { Component, OnDestroy, OnInit } from '@angular/core';
import { CasesService } from '../cases.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IModalDataT } from '../../../shared/models/main.interfaces';
import { HttpParams } from '@angular/common/http';
import { SharedService } from '../../../shared/services/shared.service';
import { Router } from '@angular/router';
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cases-old',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesOldComponent implements OnInit, OnDestroy {
  // todo: for test
  // before = false;
  // after = true;

  // todo: for release
  after = false;
  before = true;

  // вкладки
  active = true;
  draft = false;
  finished = false;

  testText = 'S OLLER brasil. Nutrimax Extreme Solution Premium, Agi Max Radiance Pluse';
  testText2 = 'Регистрация в иностранном ведомстве';

  tasks;
  items;
  activeItem;
  info;
  openedTasks;
  closedTasks;
  unfinishedTasks;
  trackItems;
  trackData;
  eventData;
  events;
  toggleClosed = true;
  toggleOpened = true;
  toggleUnfinished = false;
  hasFile = false;
  agreedStatus = false;
  statusEvents;
  fileEvents;
  showQuestion = false;
  showEventModal = false;
  modalData = [] as IModalDataT;
  questionTask;
  commentTask;
  messageModal;
  mobileMain = false;
  mobileSide = false;
  isMobile = false;
  displayMode;
  showFire = false;
  showExtended = false;
  showAnimation = false;
  faPen = faPen;
  faTrashAlt = faTrashAlt;
  unfinishedTasksInitial;

  typeOfObjects = [
    {
      id: 1,
      value: 'Патенты',
      checked: false
    },
    {
      id: 2,
      value: 'Товарные знаки',
      checked: false
    },
    {
      id: 3,
      value: 'Авторские права',
      checked: false
    },
    {
      id: 4,
      value: 'Программы ЭВМ',
      checked: false
    },
    {
      id: 5,
      value: 'Базы данных',
      checked: false
    },
    {
      id: 6,
      value: 'Домены',
      checked: false
    },
  ];
  objectsSelect = [
    {
      id: 7,
      value: 'Название объекта',
      checked: false
    },
    {
      id: 8,
      value: 'Demi',
      checked: false
    },
    {
      id: 9,
      value: 'SPORTIK.RU',
      checked: false
    },
    {
      id: 10,
      value: 'Спортивные товары, ООО',
      checked: false
    },
    {
      id: 11,
      value: 'Олимп',
      checked: false
    },
    {
      id: 12,
      value: 'Олимп2',
      checked: false
    },
    {
      id: 12,
      value: 'Олимп3',
      checked: false
    },
  ];

  // Форма поиска на мобилке
  showMobileControl = false;

  dealObjects = [];
  logo = environment.LOGO_CASES_PATH;
  contragentID;
  rights;
  permissions = {
    tradeCase: '',
    softCase: '',
    patentCase: ''
  };


  private readonly onDestroy = new Subject<void>();


  constructor(
    private casesApi: CasesService,
    private sharedApi: SharedService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.contragentID = JSON.parse(localStorage.getItem('organizations'));
    this.mobileMain = false;
    this.mobileSide = false;
    this.getInitialData();
    if (window.innerWidth < 800) {
      this.mobileMain = true;
      this.mobileSide = false;
    }
  }

  getRoleForCheckingRights() {
    this.sharedApi.currentRights.subscribe(data => {
      this.rights = data;
      if (this.rights?.length) {
        this.getTasksDataAlgorithm();
      }
    });
  }

  getRightsAlgorithm() {
    if (this.items) {
      const tradeMarks = this.rights.filter(i => i.title === 'Товарные знаки');
      if (tradeMarks) {
        const synonym = 'trademarks_case-reconciliation';
        tradeMarks[0].data.forEach(item => {
          if (item.nameEng === synonym) {
            if (item.value === 'false' || item.value === 'pending') {
              this.permissions.tradeCase = item.nameEng;
            }
          }
        });
      }
      const patents = this.rights.filter(i => i.title === 'Патенты');
      if (patents) {
        const synonym = 'patents_case-reconciliation';
        patents[0].data.forEach(item => {
          if (item.nameEng === synonym) {
            if (item.value === 'false' || item.value === 'pending') {
              this.permissions.patentCase = item.nameEng;
            }
          }
        });
      }


      const soft = this.rights.filter(i => i.title === 'Программы и БД');
      if (soft) {
        const synonym = 'soft_case-reconciliation';
        soft[0].data.forEach(item => {
          if (item.nameEng === synonym) {
            if (item.value === 'false' || item.value === 'pending') {
              this.permissions.softCase = item.nameEng;
            }
          }
        });
      }
      this.configureRights(this.permissions);
    }
  }

  configureRights(permissions) {
    if (permissions.tradeCase.length > 0 && permissions.softCase.length > 0 && permissions.patentCase.length > 0) {
      this.openedTasks = this.items
        .filter(i => i.opened &&
          i.status &&
          i.details.description !== 'Регистрация патента' &&
          i.details.description !== 'Регистрация в реестре Минкомсвязи' &&
          i.details.description !== 'Регистрация ПО' &&
          i.details.description !== 'Регистрация товарного знака'
        );
      console.log(this.openedTasks, 'filtered', 1);
    } else if (permissions.tradeCase.length === 0 && permissions.softCase.length > 0 && permissions.patentCase.length > 0) {
      this.openedTasks = this.items
        .filter(i => i.opened &&
          i.status &&
          i.details.description !== 'Регистрация патента' &&
          i.details.description !== 'Регистрация в реестре Минкомсвязи' &&
          i.details.description !== 'Регистрация ПО'
        );
      console.log(this.openedTasks, 'filtered', 2);
    } else if (permissions.tradeCase.length === 0 && permissions.softCase.length === 0 && permissions.patentCase.length > 0) {
      this.openedTasks = this.items
        .filter(i => i.opened &&
          i.status &&
          i.details.description !== 'Регистрация патента'
        );
      console.log(this.openedTasks, 'filtered', 3);
    } else if (permissions.tradeCase.length === 0 && permissions.softCase.length === 0 && permissions.patentCase.length === 0) {
      this.openedTasks = this.items.filter(i => i.opened && !i.active && i.status);
      // console.log(this.openedTasks, 'opened');
    }
  }

  getInitialData() {
    const id = localStorage.getItem('selectCase');
    const uId = localStorage.getItem('selectCaseUnfinished');
    if (id) {
      this.selectCase(id);
      console.log('has id');
    } else if (uId) {
      const opened = false;
      this.selectCaseUnfinished(uId, opened);
      console.log('no id');
    } else {
      this.getTasks();
      console.log('no id');
    }
  }

  getTasks() {
    this.casesApi.fetchTasks().pipe(takeUntil(this.onDestroy))
      .subscribe(tasksData => {
        this.tasks = tasksData;
        this.getTasksDataAlgorithm();
        console.log(this.tasks, 'tasks');
        this.displayMode = this.tasks.tasks.displayMode;
      });
  }

  selectCase(id) {
    localStorage.removeItem('selectCaseUnfinished');
    const params = {
      ID: id
    };
    localStorage.setItem('selectCase', (id));
    this.casesApi.fetchTasks(params).pipe(takeUntil(this.onDestroy))
      .subscribe(tasksData => {
        this.tasks = tasksData;
        this.getTasksDataAlgorithm();
        this.showAnimation = !this.showAnimation;
        console.log(this.tasks, 'tasks', 'case');
      });
  }

  selectCaseUnfinished(id, opened?) {
    const params = {
      responseID: id
    };
    localStorage.setItem('selectCaseUnfinished', (id));
    localStorage.removeItem('selectCase');
    this.casesApi.fetchTasks(params).pipe(takeUntil(this.onDestroy))
      .subscribe(tasksData => {
        this.tasks = tasksData;
        this.getTasksDataAlgorithm();
        this.showAnimation = !this.showAnimation;
        console.log(this.tasks, 'tasks', 'draft');
        if (!opened) {
          this.toggleClosed = false;
        }
      });
    window.scrollTo(0, 0);
  }

  selectCaseFromNav(id?, draftId?) {
    if (this.activeItem[0].status) {
      this.selectCase(id);
    } else {
      console.log('draft already active');
    }
  }

  getTasksDataAlgorithm() {
    this.items = this.tasks.tasks.items;
    // if (this.items) {
    //   this.getRightsAlgorithm();
    // }
    this.activeItem = this.items.filter(i => i.active === true);
    if (this.activeItem) {
      this.getEvents();
    }
    this.buttonSetter();
    this.getTracks();
    this.info = this.activeItem[0].info;
    this.openedTasks = this.items.filter(i => i.opened && !i.active && i.status);
    console.log(this.openedTasks, 'opened');
    this.closedTasks = this.items.filter(i => !i.opened && !i.active && i.status);
    this.unfinishedTasksInitial = this.items.filter(i => !i.status && !i.active);
    this.unfinishedTasks = this.unfinishedTasksInitial;
    this.displayMode = this.tasks.tasks.displayMode;
  }

  buttonSetter() {
    if (this.activeItem[0].opened) {
      this.toggleClosed = false;
      this.toggleOpened = true;
      this.toggleUnfinished = false;
    } else if (!this.activeItem[0].opened) {
      this.toggleClosed = false;
      this.toggleOpened = false;
      this.toggleUnfinished = true;
    } else {
      this.toggleClosed = true;
      this.toggleOpened = false;
      this.toggleUnfinished = false;
    }
  }

  getEvents() {
    if (this.activeItem[0].ID && this.activeItem[0].status) {
      const params = {
        ID: this.activeItem[0].ID
      };
      this.casesApi.fetchEvents(params).pipe(takeUntil(this.onDestroy))
        .subscribe(eventData => {
          this.eventData = eventData;
          if (this.eventData) {
            this.events = this.eventData.events.items;
            console.log(this.events, 'events');
          }
        });
    }
  }

  getTracks() {
    const params = {
      ID: this.activeItem[0].ID
    };
    this.casesApi.fetchTracks(params).pipe(takeUntil(this.onDestroy))
      .subscribe(trackData => {
        this.trackItems = trackData;
        this.trackData = this.trackItems.tracks.items;
      });
  }

  toggleClosedTasks() {
    this.toggleClosed = true;
    this.toggleOpened = false;
    this.toggleUnfinished = false;
  }

  toggleOpenedTasks() {
    this.toggleClosed = false;
    this.toggleOpened = true;
    this.toggleUnfinished = false;

  }

  toggleUnfinishedTasks() {
    this.toggleClosed = false;
    this.toggleOpened = false;
    this.toggleUnfinished = true;
  }

  getStatusEvents() {
    this.getEvents();
    this.statusEvents = this.events.filter(i => i.status === 'К согласованию');
    this.events = this.statusEvents;
    this.agreedStatus = true;
    this.hasFile = false;
  }

  getEventsWithFiles() {
    this.getEvents();
    this.fileEvents = this.events.filter(i => i.file);
    this.events = this.fileEvents;
    this.hasFile = true;
    this.agreedStatus = false;
  }

  openQuestionModal() {
    window.scrollTo(0, 0);
    this.showQuestion = true;
    this.commentTask = '';
    this.questionTask = '';
  }

  closeQuestionModal() {
    this.showQuestion = false;
  }


  getModalData(imgSrc, name, position, title, popupText, date, files, type, id, flagBlue, status) {
    this.modalData.imgSrc = imgSrc;
    this.modalData.name = name;
    this.modalData.position = position;
    this.modalData.title = title;
    this.modalData.popupText = popupText;
    this.modalData.date = date;
    this.modalData.files = files;
    this.modalData.type = type;
    this.modalData.id = id;
    this.modalData.flagBlue = flagBlue;
    this.modalData.status = status;
  }

  openEventModal() {
    window.scrollTo(0, 0);
    this.showEventModal = true;
    this.messageModal = '';
  }

  closeEventModal() {
    this.showEventModal = false;
    this.showQuestion = false;
  }

  toggleMobileMain() {
    console.log(window.innerWidth);
    if (window.innerWidth < 800) {
      this.isMobile = true;
      this.mobileMain = false;
      this.mobileSide = true;
    }
  }

  toggleMobileSide() {
    console.log(window.innerWidth);
    if (window.innerWidth < 800) {
      this.isMobile = true;
      this.mobileMain = true;
      this.mobileSide = false;
    }
  }

  addQuestion() {
    const params = new HttpParams()
      .set('ID', this.activeItem[0].ID);

    const body = {
      commentTask: this.commentTask,
      questionTask: this.questionTask
    };
    this.casesApi.addQuestion(params, body)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(questionData => {
        console.log(questionData);
      });
  }

  addComment() {
    const params = new HttpParams()
      .set('eventID', this.activeItem[0].ID)
      .set('eventType', 'in');
    const body = {
      message: this.messageModal
    };
    this.casesApi.addComment(params, body)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(commentData => {
        console.log(commentData);
      });
  }

  closeEvent() {
    const params = new HttpParams()
      .set('eventType', 'in')
      .set('eventID', this.modalData.id);
    this.casesApi.closeAgreement(params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(closeData => {
        console.log(closeData);
      });
  }

  eventAgree() {
    const params = {
      eventID: this.activeItem[0].id,
      eventType: 'in'
    };
    this.casesApi.eventAgree(params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(eventAgree => {
        console.log(eventAgree);
      });
  }

  deleteTask(id) {
    const params = {
      responseID: id
    };
    localStorage.removeItem('selectCaseUnfinished');
    this.casesApi.deleteTask(params).pipe(takeUntil(this.onDestroy))
      .subscribe(deletedData => {
        if (deletedData.answer === 'Успешно') {
          this.getInitialData();
        } else {
          console.log(deletedData.answer, 'err');
        }
        if (this.unfinishedTasks.length <= 0 && this.openedTasks.length <= 0) {
          const show = true;
          this.sharedApi.hideCases.next(show);
          this.router.navigate(['/index']);
        }
      });
  }

  getStepsData() {
    switch (this.activeItem[0].lastStage) {
      case 0:
      case 1:
        if (this.activeItem[0].type === 'trademark') {
          return `Выбрать классы`;
        } else if (this.activeItem[0].type === 'monitoring') {
          return 'Выбрать тариф';
        } else {
          return 'Заполнить информацию';
        }
      case 2:
        if (this.activeItem[0].type === 'monitoring') {
          return 'Оплатить';
        } else {
          return `Выбрать тариф`;
        }
      case 3:
      case 4:
        return `Оплатить`;
      default:
        return null;
    }
  }

  getDraftsData(stage, type) {
    switch (stage) {
      case 0:
      case 1:
        if (type === 'trademark') {
          return `Выбрать классы`;
        } else if (type === 'monitoring') {
          return `Выбрать тариф`;
        } else {
          return 'Заполнить информацию';
        }
      case 2:
        if (type === 'monitoring') {
          return `Оплатить`;
        } else {
          return `Выбрать тариф`;
        }
      case 3:
      case 4:
        return `Оплатить`;
      default:
        return null;
    }
  }

  getStepsText() {
    const user = JSON.parse(localStorage.getItem('currentUserData'));
    switch (this.activeItem[0].lastStage) {
      case 0:
      case 1:
        if (this.activeItem[0].type === 'trademark') {
          return `Для завершения оформления заявки вам необходимо <br> Выбрать классы`;
        } else if (this.activeItem[0].type === 'monitoring') {
          return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
        } else {
          return `Для завершения оформления заявки вам необходимо <br> Заполнить информацию`;
        }
      case 2:
        if (this.activeItem[0].type === 'monitoring') {
          return `Для завершения оформления заявки вам необходимо <br> произвести оплату`;
        } else {
          return `Для завершения оформления заявки вам необходимо <br> Выбрать тариф`;
        }
      case 3:
        if (this.activeItem[0].type === 'monitoring') {
          return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
        } else {
          return `Для завершения оформления заявки вам необходимо <br> выбрать тариф и произвести оплату`;
        }
      case 4:
        return `Как только ваша оплата будет обработана, <br> мы переместим вашу заявку из черновиков в активные дела.`;
      default:
        return null;
    }
  }

  sendDraft(id, type) {
    if (id) {
      console.log(id, 'test click');
      if (type === 'monitoring') {
        this.router.navigate(['/monitoring/add/' + id]);
      } else {
        this.router.navigate(['/request'], {queryParams: {type, id}});
      }
    } else {
      console.log('no draft');
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    localStorage.removeItem('selectCase');
  }

  getCountCheckedObjects(massive) {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });

    return counter;
  }

  cleanCheckedObj(massive) {
    massive.forEach((obj) => {
      obj.checked = false;
    });
  }

  getPatenteeName(name) {
    if (typeof name === 'string') {
      return name;
    } else {
      return name.name;
    }
  }

  // Убрать скрол под модалкой с фильтром
  makeBodyHidden() {
    if (this.showMobileControl === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }
}
