import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiOptions } from '@web/core/models/api-options.interface';
import { environment } from '../../../app-search/src/environments/environment';

const baseUrl = environment.BASE_URL_API;

@Injectable({
  providedIn: 'root'
})

export class SearchApiService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  public static getHeaders() {
    return {
      'Content-Type': 'application/json; charset=utf-8',
    };
  }

  post<T>(
    method: string,
    body: unknown | null,
    options?: ApiOptions
  ): Promise<T> {
    return this.httpClient
      .post<T>(`${baseUrl}/${method}`, body, {headers: SearchApiService.getHeaders()})
      .toPromise();
  }
}
