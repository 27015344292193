<div *ngIf="!isLoading; else load">
  <div class="add-object add-object__content add-object__content--pay">
    <h1 class="add-object__finished">Заявка успешно создана</h1>
    <div class="add-object__thanks-wrapper">
      <img alt ="" [src]="logo2path"/>
      <p class="add-object__thanks">
        Как только ваша оплата будет обработана,<br class="hide-up-to-md">
        мы переместим вашу заявку из черновиков в активные дела.
      </p>
      <a class="bordered-blue" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к заявке</a>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loader"></div>
</ng-template>


