<div class="slider-wrapper">
  <div class="sliders">
    <section class="slide">
      <div class="slide__wrapper">
        <img class="slide__img" src="/assets/images/online.svg" alt="" width="80" height="80"/>
        <a (click)="toRequestPage('clickRTZCarousel')" class="slide__link">
          <h2 class="slide__head">Онлайн-регистрация товарного знака
          </h2>
          <p class="slide__text">Подайте электронную заявку в Роспатент за 5 минут, без визита в офис</p>
        </a>
      </div>
    </section>
    <section class="slide hide">
      <div class="slide__wrapper">
        <img class="slide__img" src="/assets/images/rate.svg" alt="" width="78" height="78"/>
        <a (click)="toRequestPage('clickCheckProbabilityCarousel')" routerLink="/search" class="slide__link">
          <h2 class="slide__head">Оцените вероятность регистрации товарного знака</h2>
          <p class="slide__text">Бесплатная проверка по базам товарных знаков, ЕГРЮЛ и реестру доменов</p>
        </a>
      </div>
    </section>

  </div>
  <div class="slider__dots__wrapper">
            <span class="prev" (click)="minusSlide()">
              <svg width="9" height="16" viewBox="0 0 9 16" fill="none">
                <path d="M8 1L1 8L8 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
    <div class="slider-dots" style="margin: 0 30px">
      <span class="slider-dots_item active" (click)="currentSlide(1)"></span>
      <span class="slider-dots_item" (click)="currentSlide(2)"></span>
      <span class="slider-dots_item" (click)="currentSlide(3)"></span>
    </div>
    <span class="next" (click)="plusSlide()">
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none">
        <path d="M1 1L8 8L1 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
  </div>
</div>
