import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { IApiCountersResponse } from '../models/api/api.model';
import {
  ISearchHistoryDetail,
  ISearchHistorySearchListResult,
  ISearchHistoryAddToReportParams,
  ISearchHistoryPayload
} from '../models/search-history.interfaces';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  constructor(private _httpService: HttpService) {
  }

  public getSearchDetailById(params: ISearchHistoryPayload): Observable<ISearchHistoryDetail> {
    return this._httpService.get<ISearchHistoryDetail>({path: 'searches/detail', params: {...params}});
  }

  public getSearchHistory(params: { topCount?: number } = {}): Observable<ISearchHistorySearchListResult> {
    return this._httpService.get<ISearchHistorySearchListResult>({path: `searches`, subjectType: 'Get search history', params});
  }

  public saveSearch(params: ISearchHistoryDetail) {
    return this._httpService.post<{ searchID: string }>({path: 'searches/load', body: params});
  }

  public getReportFileUrl(searchID: string) {
    return this._httpService.post<{ file: string }>({path: 'searches/report/loadPDF', body: {searchID}});
  }

  public addSearchItemsToReport(params: ISearchHistoryAddToReportParams) {
    return this._httpService.post<{ answer: string }>({path: 'searches/report/load', body: {...params, mark: true}});
  }

  public getCounters(params): Observable<IApiCountersResponse> {
    return this._httpService.get({path: 'objects/count', params});
  }
}
