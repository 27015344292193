import { Component, Input, OnInit } from '@angular/core';
import { IntercomEventsService } from 'projects/shared/services/intercom-events.service';


@Component({
  selector: 'app-service-list-widget',
  templateUrl: './service-list-widget.component.html',
  styleUrls: ['./service-list-widget.component.scss']
})
export class ServiceListWidgetComponent implements OnInit {

  @Input() disabled;

  constructor(
    private readonly intercomEventsService: IntercomEventsService
  ) { }

  ngOnInit(): void {
  }

  sendEvent(synonym: string) {
    this.intercomEventsService.push({event: synonym});
  }
}
