import { Observable } from "rxjs";
import sha1 from 'crypto-js/sha1';
import { Store } from "@ngxs/store";
import Base64 from 'crypto-js/enc-base64';
import { Injectable } from "@angular/core";
import { AUTH_STATE_TOKEN } from "@web/core/states/auth.state";
import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

export const BASE_REQUEST_CONTEXT = new HttpContextToken<boolean>(() => false);
export const ELASTIC_SEARCH_CONTEXT = new HttpContextToken<boolean>(() => false);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(BASE_REQUEST_CONTEXT)) {
      req = req.clone({headers: req.headers.set('Authorization', this.store.selectSnapshot(AUTH_STATE_TOKEN).token), responseType: 'json' })
    }
    if (req.context.get(ELASTIC_SEARCH_CONTEXT)) {
      req = req.clone({ body: { ...req.body, SHA1hash: this.getHash() }, responseType: 'json' })
    }
    return next.handle(req);
  }

  private getHash(): string {
    const user = 'aa';
    const  password = 'Aa123456';

    return Base64.stringify(sha1(user.toLowerCase() + password));
  }
}
