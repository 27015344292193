<section class="section-top-search"
         [class.is-loading]="corpSearchByNumberService.isLoading"
         [class.not-loaded]="!corpSearchByNumberService.isLoaded"
>
  <div class="search_header">
    <div class="container">
      <div class="search-wrapper">
        <app-search-input
          [type]="'number'"
          [value]="corpSearchByNumberService.searchOptions.number"
          placeholder="Поиск по номеру регистрации"
          (search)="onSearch($event)"
        ></app-search-input>
      </div>

      <app-corp-search-loader *ngIf="corpSearchByNumberService.isLoading"></app-corp-search-loader>
    </div>
  </div>
</section>

<div *ngIf="!corpSearchByNumberService.isLoading && corpSearchByNumberService.data"
     [class.empty]="!corpSearchByNumberService.total.tradeMarks"
     class="section-position section-position--bg section-position--main">
  <div class="container container--bg">

    <div *ngIf="!corpSearchByNumberService.total.tradeMarks" class="empty-results">
      К сожалению, ничего не найдено. Попробуйте изменить запрос.
    </div>

    <app-list-view
      *ngIf="corpSearchByNumberService.total.tradeMarks"
      [sourceData]="corpSearchByNumberService.items.tradeMarks"
      [page]="corpSearchByNumberService.page">

      <ng-template let-rows lvData>
        <app-corp-search-trademark-list
          [showColumns]="['owner', 'date', 'classes']"
          [items]="rows"
          (select)="onSelect()"
        ></app-corp-search-trademark-list>
      </ng-template>

    </app-list-view>
  </div>


  <app-corp-search-create-report *ngIf="!corpSearchApiService.objectsLoading"></app-corp-search-create-report>

</div>
