import { AttorneyInterface, OrganizationInterface } from '@web/core/models/organization.interface';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { OrganizationsApiService } from '@web/core/services/organizations-api.service';
import { OrganizationActions } from '@web/core/states/organization.actions';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';
import { patch } from '@ngxs/store/operators';

export const ORGANIZATIONS_STATE_TOKEN = new StateToken<OrganizationStateModel>('organizations');
const organizationsInitialState: OrganizationStateModel = {
  expiringAttorneys: [],
  organizations: [],
  isLoading: false,
  casesExist: false,
  error: null,
};

@State({
  name: ORGANIZATIONS_STATE_TOKEN,
  defaults: organizationsInitialState,
})
@Injectable()
export class OrganizationState {
  constructor(
    private readonly store: Store,
    private readonly organizationsApi: OrganizationsApiService,
  ) {}

  @Selector()
  public static organizations(state: OrganizationStateModel) {
    return state.organizations;
  }

  @Selector()
  public static organizationsForSelect(state: OrganizationStateModel) {
    const kontragents = [...state.organizations];
    kontragents.unshift({ name: 'Все организации' } as OrganizationInterface);
    return kontragents;
  }

  @Action(OrganizationActions.Init)
  public async init(
    { dispatch }: StateContext<OrganizationStateModel>
  ): Promise<void> {
    // TODO: Подумать как улучшить. Проверять на наличие токена для запроса не совсем корректно, т.к если токена нет,
    // значит пользователь не авторизован и должна быть другая логика. Нарушает принцип SRP.
    if (this.store.selectSnapshot(AUTH_STATE_TOKEN).token) {
      dispatch(new OrganizationActions.SetIsLoading());
      try {
        const result = await this.organizationsApi.getOrganizations();
        dispatch(new OrganizationActions.InitSuccess(result));
      } catch (error) {
        dispatch(new OrganizationActions.InitFail(error));;
      }
    }
  }

  @Action(OrganizationActions.InitSuccess)
  public initSuccess(
    { setState }: StateContext<OrganizationStateModel>,
    { payload: { organizations, expiringAttorneys, casesExist } }: OrganizationActions.InitSuccess,
  ): void {
    // TODO remove after moving project to ngxs
    localStorage.setItem('organizations', JSON.stringify(organizations));
    setState(patch<OrganizationStateModel>({ isLoading: true, organizations, expiringAttorneys, casesExist }));
  }

  @Action(OrganizationActions.InitFail)
  public initFail(
    { setState }: StateContext<OrganizationStateModel>,
    { error }: OrganizationActions.InitFail,
  ): void {
    console.error('Error: ', error);
    setState(patch<OrganizationStateModel>({ isLoading: false, error }));
  }

  @Action(OrganizationActions.ClearOrganizations)
  public async clearOrganization(ctx: Context): Promise<void> {
    ctx.patchState({ organizations: [] });
  }

  @Action(OrganizationActions.SetIsLoading)
  public setLoading(
    { setState }: StateContext<OrganizationStateModel>,
  ): void {
    setState(patch<OrganizationStateModel>({ isLoading: true, error: null }));
  }
}

type Context = StateContext<OrganizationStateModel>;

export interface OrganizationStateModel {
  expiringAttorneys: AttorneyInterface[];
  organizations: OrganizationInterface[];
  isLoading: boolean;
  casesExist: boolean;
  error: string | null;
}

export interface LoadOrganizationsResponse {
  casesExist: boolean;
  expiringAttorneys: AttorneyInterface[];
  organizations: OrganizationInterface[];
};
