import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwitchMultiCasePipe } from './switch-multi-case.pipe';

@NgModule({
  declarations: [SwitchMultiCasePipe],
  imports: [CommonModule],
  exports: [SwitchMultiCasePipe],
})
export class SwitchMultiCaseModule { }
