import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent implements OnInit {
  @Input() portfolioObjectsError;
  @Input() successLength;
  @Output() modalEvent = new EventEmitter();

  public showSimpleError: boolean = false;
  nonDuplicateError;

  public ngOnInit(): void {
    if (this.portfolioObjectsError) {
      this.nonDuplicateError = this.portfolioObjectsError
        .filter(e => e.error !== 'Данный объект уже добавлен на данном аккаунте');
      if (this.nonDuplicateError.length) {
        this.showSimpleError = true;
      }
    }
  }

  public closeModal(): void {
    this.modalEvent.emit();
  }

  public getIndexType(index: string): string {
    switch (index) {
      case 'rutm':
        return `Товарный знак`;
      case 'wotm':
        return `Товарный знак международный`;
      case 'rutmap':
        return `Заявка на товарный знак`;
      case 'rupat':
        return `Патент на изобретение`;
      case 'ruum':
        return `Патент на полезную модель`;
      case 'rude':
        return `Патент на промышленный образец`;
      case 'rusw':
        return `Программа`;
      case 'rudb':
        return `База данных`;
      case 'domain':
        return `Домен`;
    }
  }

  public declOfNum(item, words): string {
    return words[(item % 100 > 4 && item % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(item % 10 < 5) ? Math.abs(item) % 10 : 5]];
  }
}
