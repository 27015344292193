import { AfterViewInit, Component, Input } from '@angular/core';
import { IntercomService } from '../../../app-ipid/src/app/shared/services/intercom.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'ipid-search';
  @Input() designation = '';

  constructor(
    private intercomService: IntercomService,
  ) {
  }

  ngAfterViewInit(): void {
    this.intercomService.boot();
  }
}
