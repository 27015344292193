import { Component, Input, OnInit } from '@angular/core';
import { CorpSearchSaveReportService } from '../../services/corp-search-save-report.service';
import { CorpSearchApiService } from '../../services/corp-search-api.service';

@Component({
  selector: 'app-corp-search-create-report',
  templateUrl: './corp-search-create-report.component.html',
  styleUrls: ['./corp-search-create-report.component.scss']
})
export class CorpSearchCreateReportComponent implements OnInit {
  mobile;
  constructor(
    private corpSearchApi: CorpSearchApiService,
    readonly corpSearchSaveService: CorpSearchSaveReportService,
  ) {
  }

  ngOnInit(): void {
    this.mobile = window.innerWidth <= 793;
    window.addEventListener('resize', () => {
      this.mobile = window.innerWidth <= 793;
    });
  }

  onAddToReport() {
    this.corpSearchSaveService.addSelectedToReport();
  }

  checkLoading(): boolean {
    return this.corpSearchApi.objectsLoading;
  }

}
