<div *ngIf="!isLoading; else load">
  <form class="conditions" [formGroup]="conditionsForm" (ngSubmit)="submit()">
    <div class="conditions__row">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Вид договора
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="completeAlienation"
                   value="completeAlienation"
                   formControlName="kind"
                   class="conditions"
                   (ngModelChange)="setValue()">
            <label for="completeAlienation">Полное отчуждение</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="partialAlienation"
                   value="partialAlienation"
                   formControlName="kind"
                   (ngModelChange)="setValue()">
            <label for="partialAlienation">Частичное отчуждение</label>
          </div>
        </div>
      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('kind').value !== ''">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Сумма вознаграждения
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>???</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__fields-wrapper">
          <div class="conditions__complex"
               formGroupName="reward"
               [class.invalid]="validation && !this.conditionsForm.get('reward').value.amountOfCompensation || (conditionsForm.controls.reward.get('amountOfCompensation').touched
                              || conditionsForm.controls.reward.get('amountOfCompensation').dirty) && conditionsForm.controls.reward.get('amountOfCompensation').invalid">
            <input type="text"
                   formControlName="amountOfCompensation"
                   placeholder="0"
                   (ngModelChange)="setValue()">
            <div tabindex="0" class="form__input-wrap form__input-wrap--select money"
                 (focusout)="showOptionsCurrency =false"
                 [class.form__input-wrap--select--after]="showOptionsCurrency ===true">
              <a class="form__link " (click)="showOptionsCurrency =!showOptionsCurrency">{{selectedCurrency}}</a>
              <ul *ngIf="showOptionsCurrency " class="form__select-list">
                <li *ngFor = "let cur of currency" class="form__li company-item"
                    (click)="onSelectCurrency(cur)">
                  <p>{{cur}}</p>
                </li>
              </ul>
            </div>
          </div>
          <div tabindex="0" class="form__input-wrap form__input-wrap--select nds"
               (focusout)="showOptionsNDS=false"
               [class.form__input-wrap--select--after]="showOptionsNDS===true">
            <a class="form__link" (click)="showOptionsNDS=!showOptionsNDS">{{selectedNDS}}</a>
            <ul *ngIf="showOptionsNDS" class="form__select-list">
              <li *ngFor = "let item of NDS" class="form__li company-item"
                  (click)="onSelectNDS(item.name)">
                <p>{{item.name}}</p>
              </li>
            </ul>
          </div>
        </div>
        <span *ngIf="(conditionsForm.controls.reward.get('amountOfCompensation').touched
      || conditionsForm.controls.reward.get('amountOfCompensation').dirty) && conditionsForm.controls.reward.get('amountOfCompensation').invalid"
              class="invalid-field">
        Поле не может быть пустым и должно содержать цифры
      </span>
      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('reward').value.amountOfCompensation !== '' && this.conditionsForm.get('reward').value.amountOfCompensation !== null && conditionsForm.controls.reward.get('amountOfCompensation').valid">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Порядок выплаты
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Периодичность выплат</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div
          class="conditions__radio"
          [class.invalid]="validation && !conditionsForm.controls.mannerOfPayment.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="month"
                   formControlName="mannerOfPayment"
                   value="month"
                   (ngModelChange)="setValue()">
            <label for="month">Ежемесячно</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="quarter"
                   formControlName="mannerOfPayment"
                   value="quarter"
                   (ngModelChange)="setValue()">
            <label for="quarter">Ежеквартально</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="year"
                   formControlName="mannerOfPayment"
                   value="year"
                   (ngModelChange)="setValue()">
            <label for="year">1 раз в год</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="other"
                   formControlName="mannerOfPayment"
                   value="other"
                   (ngModelChange)="setValue()">
            <label for="other">Указать в свободной форме</label>
          </div>
        </div>

        <div *ngIf="this.conditionsForm.get('mannerOfPayment').value==='other'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.mannerOfPayment.value === 'other' && !conditionsForm.controls.mannerOfPaymentString.value">
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMaxRows="20"
          cdkAutosizeMinRows="{{mobileTextarea1100 ? 1.4 : 1}}"
          placeholder="Укажите в свободной форме порядок выплаты вознаграждения"
          formControlName="mannerOfPaymentString"
          (ngModelChange)="setValue()">
        </textarea>
        </div>

      </div>
    </div>
    <div class="conditions__row" *ngIf="this.conditionsForm.get('mannerOfPayment').value === 'month' ||
   this.conditionsForm.get('mannerOfPayment').value === 'year' || this.conditionsForm.get('mannerOfPayment').value === 'quarter' ||
    this.conditionsForm.get('mannerOfPayment').value === 'other' && this.conditionsForm.get('mannerOfPaymentString').value !== '' && this.conditionsForm.get('mannerOfPaymentString').value !== null">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Начало платежей
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Дата первого платежа</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.paymentStart.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="registration"
                   value="registration"
                   formControlName="paymentStart"
                   (ngModelChange)="setValue()">
            <label for="registration">С даты регистрации договора</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="signing"
                   value="signing"
                   formControlName="paymentStart"
                   (ngModelChange)="setValue()">
            <label for="signing">С даты подписания договора</label>
          </div>

          <div class="conditions__radio-option">
            <input type="radio"
                   id="otherDate"
                   value="other"
                   formControlName="paymentStart"
                   (ngModelChange)="setValue()">
            <label for="otherDate">Иное</label>
          </div>
        </div>
        <div *ngIf="this.conditionsForm.get('paymentStart').value==='other'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.paymentStart.value === 'other' && !conditionsForm.controls.paymentStartString.value">
          <input type="text"
                 formControlName="paymentStartString"
                 placeholder="Иное"
                 (ngModelChange)="setValue()">
        </div>
      </div>
    </div>

    <div class="conditions__row"
         *ngIf="this.conditionsForm.get('paymentStart').value === 'registration' ||
          this.conditionsForm.get('paymentStart').value === 'signing' || this.conditionsForm.get('paymentStart').value === 'other'
          && this.conditionsForm.get('paymentStartString').value !== '' && this.conditionsForm.get('paymentStartString').value !== null">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Товары и услуги
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>На какие товары и услуги передаются права на ОИС</p>
         </span>
      </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__radio"
             [class.invalid]="validation && !conditionsForm.controls.goodsAndServices.value">
          <div class="conditions__radio-option">
            <input type="radio"
                   id="withRestrictionsGoods"
                   value="limits"
                   [attr.disabled]="true"
                   formControlName="goodsAndServices"
                   (ngModelChange)="setValue()">
            <label for="withRestrictionsGoods">С ограничениями</label>
          </div>
          <div class="conditions__radio-option">
            <input type="radio"
                   id="withoutRestrictionsGoods"
                   value="yes"
                   [attr.disabled]="true"
                   formControlName="goodsAndServices"
                   (ngModelChange)="setValue()">
            <label for="withoutRestrictionsGoods">Без ограничений</label>
          </div>
          <div class="conditions__radio-option">
            <input type="radio"
                   id="noGoods"
                   value="no"
                   formControlName="goodsAndServices"
                   [attr.disabled]="true"
                   (ngModelChange)="setValue()">
            <label for="noGoods">Нет</label>
          </div>
        </div>
        <div *ngIf="this.conditionsForm.get('goodsAndServices').value==='limits'"
             class="conditions__complex conditions__complex--top"
             [class.invalid]="validation && conditionsForm.controls.goodsAndServices.value === 'limits' && !conditionsForm.controls.goodsAndServicesString.value">
          <input type="text"
                 placeholder="Укажите ограничения"
                 formControlName="goodsAndServicesString"
                 (ngModelChange)="setValue()">
        </div>
      </div>
    </div>

    <div class="conditions__row" *ngIf="this.conditionsForm.get('paymentStart').value === 'registration' ||
          this.conditionsForm.get('paymentStart').value === 'signing' || this.conditionsForm.get('paymentStart').value === 'other'
          && this.conditionsForm.get('paymentStartString').value !== '' && this.conditionsForm.get('paymentStartString').value !== null || this.conditionsForm.get('goodsAndServices').value === 'limits'
          && this.conditionsForm.get('goodsAndServicesString').value !== '' && this.conditionsForm.get('goodsAndServicesString').value !== null">
      <div class="conditions__title-wrapper">
        <div class="conditions__title">Иные условия
          <span class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>Укажите иные условия, которые необходимо внести в договор</p>
         </span>
        </span>
        </div>
      </div>
      <div class="conditions__radio-wrapper">
        <div class="conditions__complex">
        <textarea matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="{{mobileTextarea1100 ? 2 : 1}}"
                  cdkAutosizeMaxRows="20"
                  type="text"
                  placeholder="Опишите иные условия для внесения в договор или прикрепите ваш проект договора"
                  formControlName="misc"
                  (ngModelChange)="setValue()">
        </textarea>
        </div>
        <div class="conditions__complex--top conditions__add-doc conditions__add-doc--flex" >
          <input type="file"
                 id="file"
                 formControlName="file"
                 (change)="this.conditionsService.fileChangeEvent($event, files, fileLinks)"
                 (ngModelChange)="setValue()">
          <label for="file">
            <span class="conditions__text-blue">Прикрепить ваш проект договора в формате PDF или Word</span>
            <span class="conditions__text-gray">(Не обязательно)</span>
          </label>
          <p class="file-error" *ngIf="conditionsService.fileError?.length">{{conditionsService.fileError}}</p>
          <div style="display: flex" *ngFor="let file of files, index as i">
            <span>{{trimString(file.fileName, 30)}}</span>
            <span class="conditions__delete" (click)="this.conditionsService.removeFile(i, files)"></span>
          </div>
        </div>
      </div>

    </div>
    <!--  <button type="submit">console</button>-->
  </form>
</div>

<ng-template #load>
  <div class="loader"></div>
</ng-template>
