import { ModuleWithProviders, NgModule } from '@angular/core';
import { WebModuleConfig } from './core/services/config.service';
import { UraitApiService } from './core/services/urait-api.service';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './core/states/app.state';
import { AuthApiService } from '@web/core/services/auth-api.service';
import { StorageService } from '@web/core/services/storage.service';
import { AuthState } from '@web/core/states/auth.state';
import { OrganizationState } from '@web/core/states/organization.state';
import { OrganizationsApiService } from '@web/core/services/organizations-api.service';
import { RightsApiService } from '@web/core/services/rights-api.service';
import { ServicesState } from '@web/features/services/states/services.state';
import { ServicesApiService } from '@web/features/services/services/services-api.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './utils/auth.interceptor';

@NgModule({
  imports: [
    NgxsModule.forFeature([AppState, AuthState, OrganizationState, ServicesState])
  ],
  providers: [
    UraitApiService,
    AuthApiService,
    StorageService,
    OrganizationsApiService,
    RightsApiService,
    ServicesApiService
  ]
})
export class WebModule {
  static forRoot(config: WebModuleConfig): ModuleWithProviders<WebModule> {
    return {
      ngModule: WebModule,
      providers: [
        {
          provide: WebModuleConfig,
          useFactory: () => {
            return new WebModuleConfig(config);
          },
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
