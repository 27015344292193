import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { DictionaryItem } from '../../../../../shared/models/dictionary/dictionary-item-model';
import { Dictionary } from '../../../../../shared/models/dictionary/dictionary-model';

@Injectable({providedIn: 'root'})

/**
 * Внедряем этот сервис в DI контейнер - получаем возможность добавлять и получать моковые данные в любом месте приложения
 *
 * Пример того как можно добавить данные в moq контейнер
 *
 * @Component({ selector: 'component-selector-name' ...})
 * export class Componentname implements onInit {
 *
 *   - Сначала внедряем зависимость в компонент который планирует использовать moq данные
 *   constructor(private _moqService: MoqService) {
 *
 *   // Пример добавления моковых данных в конейнер
 *      this._moqService.add<string, UserModel>('userPageMoqData', new User({name: 'Avgustin', age: 27}))
 *
 *   // string - ключ, по которому будет происходить поиск в контейнере. Ключ может быть любым, необязательно строкой.
 *   // UserModel - моковые данные. Это может быть число, строка, объект, массив.
 *
 *   // Каждое представление (model / объект) может содержать метод, возвращающий заполненную данными (сущность/представление/бизнес объкт, называйте как хотите.)
 *
 *   // Например new UserModel().getMoqData();
 *   // Метод getMoqData() может выглядеть вот так -
 *      public getMoqData(): TModel //(в данном случае TModel - это UserModel) {
 *        this.name = 'Avgustin';
 *        this.moqData.age = 27;
 *        return this;
 *      }
 *
 *   // Тогда добавление в контейнер моковых данных может выглядеть вот так -
 *      this._moqService.add<string, UserModel>('userPageMoqData', new UserModel().getMoqData());
 *
 *   // Чтобы получить данные из moq контейнера используется метод getMoqValue<TKey, TData>();
 *   // TKey - ключ, по кторому будет происходить поиск в контейнере.
 *   // TData - Тип данный, который был добавлен в контейнер.
 *      ...
 *      public userMoqData = this._moqService.getMoqValue<string, UserModel>('userPageMoqData');
 *      ...
 *   // userMoqData - можно использовать в шаблоне для вывода данных
 * }
 *
 */
export class MoqService {

  /**
   * Контейнер для моковых данных
   * В контейнер можно положить всё что угодно
   */
  private _moqContainer: Dictionary<any, any>;

  constructor() {
    this._moqContainer = new Dictionary<any, any>();
  }

  /**
   * Добавляет моковые данные в контейнер
   * Чтобы добавить данные в контейнер необходимо передать ключ и значение
   * @param key Tkey
   * @param input TData
   */
  public addMoq<TKey, TData>(key: TKey, input: TData): void {
    this._moqContainer.add(new DictionaryItem<TKey, TData>({key: key, value: input}));
  }

  /**
   * Возвращает данные по ключу из контейнера
   * Чтобы получить данные из контейнера необходимо передать ключ
   * @param key TKey
   * @returns TData
   */
  public getMoqValue<TKey, TData>(key: TKey): TData {
    return this._moqContainer.getItem(key);
  }

  /**
   * Получает данные с искусственной задержкой
   * @param key TKey
   * @param delay delay second
   * @returns Observable<TData>
   */
  public getMoqValueWithDelay<TKey, TData>(key: TKey, delay: number): Observable<TData> {
    let result: Observable<TData>;
    if (delay > 0) {
      result = new Observable<TData>((observable) => {
        timer(delay * 1000).subscribe(() => {
          observable.next(this._moqContainer.getItem(key));
        });
      });
    }
    return result;
  }

  /**
   * Удаляет данные по ключу из moq контейнера
   * @param key TKey
   */
  public removeMoq<TKey>(key: TKey): void {
    this._moqContainer.remove(key);
  }

}
