<div class="monitoring-modal risk-modal">
  <div class="monitoring-modal__wrap">
    <div class="risk-modal__content">
      <div *ngIf="successLength > 0" class="success_header">
        <div *ngIf="successLength">
          <h3 id="addedSuccess">{{successLength}} {{declOfNum(successLength, ['объект', 'объекта', 'объектов'])}}
            успешно {{successLength === 1 ? 'добавлен' : 'добавлены'}} в
            портфель</h3>
        </div>
      </div>

      <div *ngIf="portfolioObjectsError">
        <table>
          <caption id="addedError">Ошибки</caption>
          <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Имя</th>
            <th scope="col">Тип Объекта</th>
            <th scope="col">Текст Ошибки</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let obj of portfolioObjectsError; let i = index" [id]="'errorElement' + i">
            <td data-label="ID">{{obj.showId}}</td>
            <td data-label="Имя">{{obj.name}}</td>
            <td data-label="Тип Объекта">{{getIndexType(obj.index)}}</td>
            <td *ngIf="!showSimpleError" data-label="Текст Ошибки">{{obj.error}}</td>
            <td *ngIf="showSimpleError" data-label="Текст Ошибки">Упс что то пошло не так, обратитесь в поддержку</td>
          </tr>
          </tbody>
        </table>
      </div>

      <br>
      <div class="risk-modal__footer">
        <div class="risk-modal__btn-wrapper">
          <button routerLink="/portfolio" class="risk-modal__btn risk-modal__btn--ask" id="gotToPortfolio">
            Перейти в портфель
          </button>
          <button class="risk-modal__btn risk-modal__btn--decide" is="isOkModal" (click)="closeModal()">Закрыть</button>
        </div>
      </div>
      <button class="risk-modal__close" id="closeModal" (click)="closeModal()"></button>
    </div>
  </div>
</div>
