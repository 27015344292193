import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'declOfNum', pure: true })
export class DeclOfNumPipe implements PipeTransform {
  public transform(value: number): string {
    value = Math.abs(value) % 100; const n1 = value % 10;
    if (value > 10 && value < 20) return 'классов';
    if (n1 > 1 && n1 < 5) return 'класса';
    if (n1 === 1)return 'класс';
    return 'классов';
  }
}
