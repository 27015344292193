import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { CorpSearchService } from '../../services/corp-search.service';
import { CorpSearchByNameService } from '../../services/corp-search-by-name.service';
import { ActivatedRoute } from '@angular/router';
import { CorpSearchSaveReportService } from '../../services/corp-search-save-report.service';
import { ICorpDataSourceType } from '../../interaces/corp-data-source.interface';
import { environment } from '../../../../../../app-ipid/src/environments/environment';
import { ISearchHistoryDetail } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import {CorpSearchApiService} from '../../services/corp-search-api.service';

@Component({
  selector: 'app-corp-search-by-name',
  templateUrl: './corp-search-by-name.component.html',
  styleUrls: ['./corp-search-by-name.component.scss'],
})
export class CorpSearchByNameComponent implements OnInit, OnDestroy {
  @Input() initialSearchHistory: ISearchHistoryDetail = null;
  @Input() initialSearchHistoryAction: 'show' | 'repeat' = null;

  currentTab: 'tradeMarks' | 'legalNames' | 'domains' = 'tradeMarks';
  showIndex = true;
  showIndexMobile = true;
  public innerWidth: any;
  countriesPopup = false;

  get searchRisksOptions() {
    return {showProgressPercent: false, ...this.searchOptions};
  }

  searchOptions = {
    style: 'appIpIdSearchPage',
    dynamicStylesForBrand: environment.SEARCH_STYLES,
  };

  constructor(
    readonly corpSearchService: CorpSearchService,
    readonly corpSearchByNameService: CorpSearchByNameService,
    readonly corpSearchSaveService: CorpSearchSaveReportService,
    readonly activatedRoute: ActivatedRoute,
    readonly corpSearchApiService: CorpSearchApiService
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.initialSearchHistory) {
      this.corpSearchByNameService.loadBySearchHistory(this.initialSearchHistory, this.initialSearchHistoryAction === 'show');
    } else {
      this.parseSearchParams();
    }
  }

  private parseSearchParams() {
    const query = this.activatedRoute.snapshot.queryParamMap;

    if (!query.has('q')) {
      return;
    } else {
      const designation = query.get('q');

      this.corpSearchByNameService.load({
        designation: designation || '',
        mktuList: [],
        okvedList: [],
        countriesList: [{name: 'Российская Федерация', code: 'RU', organizations: ['СНГ', 'БРИКС']}]
      });
    }
  }

  ngOnDestroy(): void {
    this.corpSearchByNameService.reset();
  }

  toCheckTrade() {
    this.currentTab = 'tradeMarks';
  }

  toCheckName() {
    this.currentTab = 'legalNames';
  }

  toCheckDomains() {
    this.currentTab = 'domains';
  }

  onSelect(type: ICorpDataSourceType, selected: any[]) {
    this.corpSearchSaveService.onSelect(type, selected);
  }

  showPopup(show) {
    this.showIndex = show;
  }

  showPopupMobile(showMobile) {
    if (innerWidth <= 1100) {
      this.showIndexMobile = !showMobile;
    }
  }

  getClassName() {
    switch (this.corpSearchByNameService.maxRisk) {
      case 'High':
        return 'high';
      case 'Medium':
        return 'medium';
      case 'Low':
        return 'none';
      case 'None':
        return 'none';
    }
  }

  declOfNum(num, words): string {
    return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? Math.abs(num) % 10 : 5]];
  }

  countriesDropdown(event) {
    this.countriesPopup = event;
  }
}
