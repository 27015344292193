import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IRisksParam } from '../search-risks/search-risks.component';

@Component({
  selector: 'app-search-risks-brand',
  templateUrl: './search-risks-brand.component.html',
  styleUrls: ['./search-risks-brand.component.scss']
})
export class SearchRisksBrandComponent implements OnInit {
  risk: string;

  @Input() risks: IRisksParam;

  @Input() totalTradeMarks: number;
  @Input() totalLegalEntities: number;
  @Input() totalDomains: number;
  @Input() designation: any;

  @Input() options: any = {};

  @Output() reportCheckClick = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.riskLevel();
  }

  riskLevel() {
    if (this.percent <= 44) {
      this.risk = 'low';
    } else if (this.percent <= 69) {
      this.risk = 'middle';
    } else {
      this.risk = 'high';
    }
  }

  get percent() {
    return this.risks.custom;
  }

  emitReportCheckClick() {
    this.reportCheckClick.emit();
  }

}
