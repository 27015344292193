<!--trademarks draft or noDraft-->
<div *ngIf="!isDraft || type === 'trademark'" class="info-table">
  <div class="info-table__table">
    <table>
      <tr>
        <td>{{infoItem.info[0].title}}</td>
        <td>{{infoItem.info[0].text}}</td>
      </tr>
      <tr>
        <td>{{infoItem.info[1].title}}</td>
        <td *ngIf="infoItem.type !== 'patent'">{{infoItem.info[1].text}}</td>
        <td *ngIf="infoItem.type === 'patent'">{{getPatenteeName(infoItem.details.patentee[0])}}</td>
      </tr>
      <tr>
        <td>{{infoItem.info[3].title}}</td>
        <td>{{infoItem.info[3].text}}</td>
      </tr>
      <tr>
        <td>Адрес</td>
        <td>{{infoItem.legalAdress}}</td>
      </tr>
      <tr>
        <td>{{infoItem.info[4].title}}</td>
        <td>{{infoItem.info[4].text}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="infoItem.details.imgSrc" class="info-table__img">
    <img src="{{infoItem.details.imgSrc | proxyImage}}">
  </div>
</div>

<!--patents-->
<div *ngIf="isDraft && type === 'patent'" class="info-table">
  <div class="info-table__table">
    <table>
      <tr>
        <td>Дата подачи</td>
        <td>{{infoItem.info[0].text}}</td>
      </tr>
      <tr>
        <td>Тип патента</td>
        <td *ngIf="infoItem.details.patenttype === 'rupat'"> Изобретение </td>
        <td *ngIf="infoItem.details.patenttype === 'ruum'"> Полезная модель </td>
        <td *ngIf="infoItem.details.patenttype === 'rude'"> Промышленный образец </td>
        <td *ngIf="infoItem.details.patenttype === 'unknown' || ''"> Не определен </td>
      </tr>
      <tr>
        <td>Автор</td>
        <td>{{infoItem.details.author}}</td>
      </tr>
      <tr>
        <td>Патентообладатель</td>
        <td>{{getPatenteeName(infoItem.details.patentee[0])}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="infoItem.details.imgSrc" class="info-table__img">
    <img src="{{infoItem.details.imgSrc | proxyImage}}">
  </div>
</div>
<!--data bases-->
<div *ngIf="isDraft && type === 'soft'" class="info-table">
  <div class="info-table__table">
    <table>
      <tr>
        <td>Дата заявки</td>
        <td>{{infoItem.info[0].text}}</td>
      </tr>
      <tr>
        <td>Тип</td>
        <td *ngIf="infoItem.details.patenttype === 'rusw'"> Программа для ЭВМ </td>
        <td *ngIf="infoItem.details.patenttype === 'rudb'" > База данных </td>
        <td *ngIf="infoItem.details.patenttype === 'unknown' || ''" > Не определен </td>
      </tr>
      <tr>
        <td>Автор</td>
        <td>{{infoItem.details.author}}</td>
      </tr>
      <tr>
        <td>Правообладатель</td>
        <td>{{getPatenteeName(infoItem.details.patentee[0])}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="infoItem.details.imgSrc" class="info-table__img">
    <img src="{{infoItem.details.imgSrc  | proxyImage}}">
  </div>
</div>
<!--contract-->
<div *ngIf="isDraft && type === 'contract'" class="info-table">
  <div class="info-table__table">
    <table>
      <tr>
        <td>{{infoItem.info[0].title}}</td>
        <td>{{infoItem.info[0].text}}</td>
      </tr>
      <tr>
        <td>Вид договора</td>
        <td> {{getContractType(infoItem.details.contractType)}} </td>
      </tr>
      <tr>
        <td>Номер</td>
        <td>{{infoItem.number}}</td>
      </tr>
      <tr>
        <td>Действие над договором</td>
        <td>{{getContractAction(infoItem.details.contractAction)}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="infoItem.details.imgSrc" class="info-table__img">
    <img src="{{infoItem.details.imgSrc}}">
  </div>
</div>
<!--monitoring-->
<div *ngIf="isDraft && type === 'monitoring'" class="info-table">
  <div class="info-table__table">
    <table>
      <tr>
        <td>дата подачи</td>
        <td>{{infoItem.info[0].text}}</td>
      </tr>
      <tr>
        <td>Количество выбранных объектов</td>
        <td>{{monitoredObject}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="infoItem.details.imgSrc" class="info-table__img">
    <img src="{{infoItem.details.imgSrc  | proxyImage}}">
  </div>
</div>
<!--rumcc-->
<div *ngIf="isDraft && type === 'rumcc'" class="info-table">
  <div class="info-table__table">
    <table>
      <tr>
        <td>дата подачи</td>
        <td>{{infoItem.info[0].text}}</td>
      </tr>
    </table>
  </div>
  <div *ngIf="infoItem.details.imgSrc" class="info-table__img">
    <img src="{{infoItem.details.imgSrc  | proxyImage}}">
  </div>
</div>
