<div *ngIf="showPage">
  <header class="header header--centered">
    <a class="header__logo" href="#">
      <img [src]="logoPath" style="max-height: 30px; height: 100%; max-width: none">
    </a>
  </header>

  <div *ngIf="newReg" class="page-wrapper" #myInput>
    <main>
      <section class="form-section">
        <div class="container-sm">
          <h1 class="heading-h1 heading-h1--centered" id="registrationHeader">Регистрация</h1>

          <div *ngIf="envService.isShowSocialAuth" class="external-reg">
            <div class="external-reg__links-wrapper">
              <div (click)="yandexCodeGet()" class="external-reg__link">
                <img class="external-reg__icon" alt="" src="../../../assets/ya.svg"/>
                <p class="external-reg__txt">Продолжить с Яндекс</p>
              </div>
              <div (click)="googleRegister()" class="external-reg__link">
                <img class="external-reg__icon" alt="" src="../../../assets/google.svg"/>
                <p class="external-reg__txt">Продолжить с Google</p>
              </div>
<!--              <div (click)="facebookLogin()" class="external-reg__link">-->
<!--                <img class="external-reg__icon" alt="" src="../../../assets/fb.svg"/>-->
<!--                <p class="external-reg__txt">Продолжить с Facebook</p>-->
<!--              </div>-->
            </div>
            <div class="external-reg__alternative-text">
              <p>или войти через email</p>
            </div>
          </div>
          <div class="error_heading">
            <h3 *ngIf="errorRegister" class="error_label">{{errorText}}</h3>
          </div>
          <form class="form" [formGroup]="registrationForm" (keydown)="$event.stopPropagation()">

            <div class="form__group form__group--email">
              <div class="form__input-wrap">
                <input class="form__input field"
                       type="text"
                       formControlName="email"
                       [class.disabled-email]="invitedUser?.email"
                       [ngClass]="{ 'error': registrationForm.get('email').invalid && registrationForm.get('email').touched }"
                       required
                       id="email"
                       email
                >
                <span class="form__input-label">Email</span>
              </div>
            </div>
            <div class="form__group form__group--fields">
              <div class="form__pass">
                <div class="form__input-wrap">
                  <input class="form__input field"
                         type="password"
                         (keyup)="comparePasswords()"
                         formControlName="newPassword"
                         appPassword
                         id="password"
                         [ngClass]="{ 'error': registrationForm.get('newPassword').invalid && registrationForm.get('newPassword').touched}"
                         required
                  >
                  <span class="form__input-label">Пароль</span>
                </div>
              </div>
            </div>

            <div *ngIf="registrationForm.get('newPassword').invalid && registrationForm.get('newPassword').touched"
                 class="error_container_pass">
              <span class="error_label">Пароль слишком простой. Используйте минимум 6 символов, большие и маленькие буквы, добавьте цифры и спецсимволы</span>
            </div>
            <div class="form__footer">
              <button *ngIf="!showLoader"
                      class="btn btn__auth btn--full"
                      type="button"
                      autofocus
                      id="registrationButton"
                      [ngClass]="registrationForm.invalid || registrationForm.disabled ? 'disabled' : ''"
                      (click)="registerUser()">Зарегистрироваться
              </button>
              <button *ngIf="showLoader"
                      class="btn btn__auth btn--full"
                      type="submit"
              >
                <span class="loaderAuth"></span>
              </button>
              <p class="form__lisence-info">Нажимая на кнопку «Зарегистрироваться» вы соглашаетесь <br
                class="hide-up-to-md"> с <a class="bordered-blue" target="_blank" href="{{staticFolder}}/rules.pdf">условиями
                использования</a>
              </p>
            </div>
          </form>
          <p class="form-section__account" [routerLink]="['/login']">
            Уже есть аккаунт? <a class="bordered-blue">Войти</a>
          </p>
          <a class="bordered-blue support-link" (click)="openModalSupport()">Обращение в техническую поддержку</a>
        </div>
        <app-support-modal
          *ngIf="showModalSupport"
          [showModal]="showModalSupport"
          (closeModalSupportPopup)="closeModalSupport()">>
        </app-support-modal>
      </section>
    </main>
    <footer class="footer footer--centered">
      <p class="footer__lisence-info">{{footerText}}</p>
    </footer>
  </div>
</div>

