import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})

export class InviteUserComponent implements OnInit {

  organizations = [];
  selectedOrganizationsId = [];
  form: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private router: Router,
    private location: Location,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    this.organizations = this.userProfileService.getOrganizationsData();
    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      position: ['', [Validators.required]],
      email: ['', [Validators.required]],
      organizations: this.fb.array(this.organizations.map(x => false)),
    });
  }

  send() {
    if (!this.checkValidForm()) {
      this.form.controls.email.markAsTouched();
      this.form.controls.organizations.markAsTouched();
    } else {
      this.loading = true;
      this.selectedOrganizationsId = [];
      this.form.controls.organizations.value.forEach((item, index) => {
        if (item === true) {
          this.selectedOrganizationsId.push(this.organizations[index].id);
        }
      });

      const query = {
        name: this.form.controls.name.value,
        surname: this.form.controls.surname.value,
        email: this.form.controls.email.value,
        position: this.form.controls.position.value,
        organizations: this.selectedOrganizationsId,
      };
      this.userProfileService.inviteUser(query).subscribe(data => {
        if (data) {
          this.loading = false;
          this.router.navigate(['/user/profile']);
          this.toastrService.success('Приглашение отправлено');
        } else {
          this.toastrService.error('Приглашение не отправлено');
        }
      });
    }
  }

  showEmailError(): boolean {
    return (!this.validateEmail(this.form.value.email) &&
      this.form.controls.email.touched);
  }

  showSelectOrgError(): boolean {
    return (!this.validateSelectOrg() &&
      this.form.controls.organizations.touched);
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateSelectOrg(): boolean {
    let res = false;
    this.form.controls.organizations.value.forEach((item, index) => {
      if (item === true) {
       res = true;
      }
    });
    return res;
  }

  checkValidForm(): boolean {
    return this.validateEmail(this.form.value.email) && this.validateSelectOrg();
  }

  backClicked() {
    this.location.back();
  }
}
