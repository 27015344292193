import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-payment-offline',
  templateUrl: './payment-offline.component.html',
  styleUrls: ['./payment-offline.component.scss']
})
export class PaymentOfflineComponent implements OnInit, OnChanges {

  @Input() isRequestCheckLabel: boolean; // форма открыта из подачи заявки на проверку этикеток
  @Input() payer; // данные о плательщике
  @Input() paymentSum; // сумма
  // @Output() payerDataChange = new EventEmitter(); // событие изменения данных о плательщике
  formPay: FormGroup;

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  constructor(
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    console.log(this.payer);
    this._initFormPay();
    if (this.payer) {
      this.prefillForm(this.payer);
    }
  }

  private _initFormPay(): void {
    this.formPay = this.fb.group({
      INN: ['', [Validators.required]],
      OGRN: ['', [Validators.required]],
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      sum: ['', [Validators.required]],
    });
  }

  prefillForm(data) {
    if (data?.ogrn?.length && typeof data?.ogrn === 'number') {
      data.ogrn.toString();
    } else if (data?.ogrn?.length && typeof data?.ogrn === 'string') {
      data.ogrn = data.ogrn.replace(/\s/g, '');
    }
    this.formPay.get('INN').setValue(data.TIN || data?.inn);
    this.formPay.get('OGRN').setValue(data.PSRN || data?.ogrn);
    this.formPay.get('name').setValue(data?.name);
    this.formPay.get('address').setValue(data.legalAdress || data?.address);
    this.formPay.get('sum').setValue((+this.paymentSum).toLocaleString('ru') + ' ₽');
  }

}
