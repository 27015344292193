<div class="events-steps__hidden">
  <app-warning-with-list [type]="'tariff'"></app-warning-with-list>
  <div class="events-steps__hidden--mini-block">
    <h4>Кто должник</h4>
    <p>Не знаю</p>
  </div>
  <div class="events-steps__hidden--mini-block">
    <h4>Что случилось</h4>
    <p>Поставили товары, оказали услуги не качественно</p>
  </div>
  <div class="events-steps__hidden--mini-block">
    <h4>Что вы хотите от должника</h4>
    <p>Иное: Меня обманули при поставке товаров, из 10 вскрытых коробок только в 2х оказался товар надлежащего качества.</p>
  </div>
</div>
