import { AuthState } from '@web/core/states/auth.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { UserInterface } from '@web/core/models/user.interface';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkState } from '@web/features/requests/trademark/store/trademark.state';
import { TrademarkActions } from '@web/features/requests/trademark/store/trademark.actions';

@Component({
  selector: 'app-trademark-owner',
  templateUrl: './trademark-owner.component.html',
  styleUrls: ['./trademark-owner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkOwnerComponent {
  @ViewSelectSnapshot(AuthState.getUser) user: UserInterface | null;
  @ViewSelectSnapshot(TrademarkState.disableNextStep) disableNextStep: boolean;
  @Dispatch() cancelRequest = () => new TrademarkActions.CancelRequest();
  @Dispatch() updateTrademarkDraft = () => new TrademarkActions.UpdateTrademarkDraft();
}
