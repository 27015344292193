import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import mktuData from '../../../../../../../app-search/src/app/pages/ip-id-search/json-data/mktu';


@Component({
  selector: 'app-mktu-mobile',
  templateUrl: './mktu-mobile.component.html',
  styleUrls: ['./mktu-mobile.component.scss']
})
export class MktuMobileComponent implements OnInit, OnDestroy {
  showModalMktu = false;
  constructor(

  ) { }

  mktuData = JSON.parse(JSON.stringify(mktuData)); // массив объектов со всеми классами мкту
  // [{number: 1, name: 'МКТУ 01', desc: 'Продукты химические'},{number: 2, name: 'МКТУ 02', desc: 'Краски, олифы, лаки'}, ...]
  @Input() selectedMktu; // классы выбранные пользователем
  @Output() deleteClass = new EventEmitter();
  @Output() applyCodes = new EventEmitter();

  ngOnInit(): void {
  }

  // добавление нового класса
  addMktu(code) {
    const dublicate = this.selectedMktu.findIndex(item => item === code);
    if (dublicate === -1) {
      this.selectedMktu.push(code);
    }
  }

  // удаление класса
  removeMktu(code) {
    const removeIndex = this.selectedMktu.findIndex(item => item === code);
    this.selectedMktu.splice(removeIndex, 1);
    this.deleteClass.emit({number: code});
  }

  ngOnDestroy(): void {
  }

  closeModalMktu() {
    this.showModalMktu = false;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  closeModalSuccess(event) {
    this.selectedMktu = event;
    this.closeModalMktu();
    const mktu = [];
    this.mktuData.forEach(item => {
      if (this.selectedMktu.includes(item.number)) {
        mktu.push(item);
      }
    });
    this.applyCodes.emit(mktu);
  }

  openModalMktu() {
    this.showModalMktu = true;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'hidden');
  }
}
