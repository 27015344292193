<section class="section-position section-position__exp" data-intercom-target="get support">
  <div class="container section-position__expert">
    <div class="section-position__expert-img">
      <picture>
        <source
          media="(max-width: 768px)"
          [srcset]="imageMobile"
          width="314"/>
        <img [src]="image" alt="" width="314"/>
      </picture>
    </div>
    <div class="expert">
      <h2>Задать вопрос патентному поверенному</h2>
      <div class="expert__btn-wrapper">
        <button
          type="button"
          class="expert__btn"
          *ngFor=" let btn  of buttons"
          (click)="click(btn)"
        >
          {{ btn }}
        </button>
      </div>
    </div>
  </div>
</section>
