<ng-container *ngIf="objectsWithMonitoring; else notMonitoring">
  <ng-container *ngIf="getTotalRisks() != 0; else notRisks">
    <div class="risks-item__title">
      <p class="risks-item__total-wrap">
        <span class="risks-item__total">{{ getTotalRisks() }}</span>
        <span class="risks-item__total-desc">Рисков</span>
      </p>
    </div>
    <table class="chart-corp">
      <tr *ngFor="let risk of risks" class="chart-corp__item"
          [ngClass]="{
                            'fatal': risk.name === 'Критичные',
                            'high': risk.name === 'Высокие',
                            'possible': risk.name === 'Средние',
                            'recommended': risk.name === 'Рекомендации',
                            'disconnected': risk.name === 'Неподключенные объекты'}">
        <td class="chart-corp__line-wrap">
              <span class="chart-corp__line"
                    [style.width.%]="(risk.value / getMaxRisk())*100">
              </span>
        </td>
        <td class="chart-corp__value">{{ risk.value }}</td>
        <td class="chart-corp__name">{{ risk.name }}</td>
      </tr>
    </table>
    <a routerLink="/monitoring" class="risks-item__btn btn" [class.disabled]="X5 || !monitoring">Перейти в мониторинг</a>
  </ng-container>

  <ng-template #notRisks>
    <div class="not-risks">
      <svg width="54" height="54" viewBox="0 0 54 54">
        <path
          d="M27 0.375C22.1667 0.375 17.7083 1.58333 13.625 4C9.54167 6.33333 6.29167 9.54167 3.875 13.625C1.54167 17.7083 0.375 22.1667 0.375 27C0.375 31.8333 1.54167 36.2917 3.875 40.375C6.29167 44.4583 9.54167 47.7083 13.625 50.125C17.7083 52.4583 22.1667 53.625 27 53.625C31.8333 53.625 36.2917 52.4583 40.375 50.125C44.4583 47.7083 47.6667 44.4583 50 40.375C52.4167 36.2917 53.625 31.8333 53.625 27C53.625 22.1667 52.4167 17.7083 50 13.625C47.6667 9.54167 44.4583 6.33333 40.375 4C36.2917 1.58333 31.8333 0.375 27 0.375ZM21.625 40.375L8.375 27L12.125 23.25L21.625 32.75L41.875 12.5L45.625 16.375L21.625 40.375Z"/>
      </svg>
      <p>Нет рисков</p>
      <a routerLink="/monitoring" class="risks-item__btn btn" [class.disabled]="X5 || !monitoring">Перейти в мониторинг</a>
    </div>
  </ng-template>
</ng-container>

<ng-template #notMonitoring>
  <div class="no-monitoring">
    <img src="/assets/images/nothing.svg" alt="nothing"/>
    <p>Нет объектов для мониторинга</p>
    <a routerLink="/monitoring" class="risks-item__btn btn" [class.disabled]="X5">Добавить объект</a>
  </div>
</ng-template>
