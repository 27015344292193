<section *ngIf="userData" class="section" >

  <div class="container">

    <div>
<!--      <div *ngIf="requestType === 'trademark' && (envService.isGardiumOnline || envService.isAlfa)">-->
<!--        <div class="steps">-->
<!--          <div *ngIf="requestType !== 'rumcc'" class="steps__element steps__element&#45;&#45;line" [ngClass]="getStepClass(1)">-->
<!--            <span class="steps__element-text">-->
<!--              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">-->
<!--                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Правообладатель-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="steps__element steps__element&#45;&#45;line" [ngClass]="getStepClass(2)">-->
<!--            <span class="steps__element-text">-->
<!--              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">-->
<!--                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Сведения-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="steps__element steps__element&#45;&#45;line" [ngClass]="getStepClass(3)">-->
<!--            <span class="steps__element-text">-->
<!--              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">-->
<!--                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Тариф-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="steps__element steps__element&#45;&#45;line" [ngClass]="getStepClass(4)">-->
<!--            <span class="steps__element-text">-->
<!--              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">-->
<!--                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Пошлины-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="steps__element steps__element&#45;&#45;line" [ngClass]="getStepClass(5)">-->
<!--            <span class="steps__element-text">-->
<!--              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">-->
<!--                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Оплата-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="steps__element" [ngClass]="getStepClass(6)">-->
<!--            <span class="steps__element-text">-->
<!--              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">-->
<!--                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Заявка сформирована-->
<!--            </span>-->
<!--          </div>-->
<!--          <div *ngIf="requestType !== 'rumcc'" class="steps__mobile">Шаг {{step}} из 6</div>-->
<!--          <div *ngIf="requestType === 'rumcc'" class="steps__mobile">Шаг {{step - 1}} из 5</div>-->
<!--        </div>-->
<!--      </div>-->

      <div>
        <div class="steps" *ngIf="requestType !== 'checkLabel'">
          <div *ngIf="requestType !== 'rumcc'" class="steps__element steps__element--line" [ngClass]="getStepClass(1)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Правообладатель
            </span>
          </div>
          <div class="steps__element steps__element--line" [ngClass]="getStepClass(2)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Сведения
            </span>
          </div>
          <div class="steps__element steps__element--line" [ngClass]="getStepClass(3)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Тариф
            </span>
          </div>
          <div class="steps__element steps__element--line" [ngClass]="getStepClass(4)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Оплата
            </span>
          </div>
          <div class="steps__element" [ngClass]="getStepClass(5)">
            <span class="steps__element-text">
              <svg width="9" height="7" viewBox="0 0 9 7" fill="none">
                <path d="M1 3.14286L3.54545 6L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Заявка сформирована
            </span>
          </div>
          <div *ngIf="requestType !== 'rumcc'" class="steps__mobile">Шаг {{step}} из 6</div>
          <div *ngIf="requestType === 'rumcc'" class="steps__mobile">Шаг {{step - 1}} из 5</div>
        </div>
      </div>

      <div [ngSwitch]="step" class="step-box">
        <div *ngSwitchCase="0">
          <div class="loader"></div>
        </div>
        <div *ngSwitchCase="1">

          <app-user-phone *ngIf="!personalData"
                          (personalDataStatus)="getPersonalDataStatus($event)"
                          [isDebit]="false"
          ></app-user-phone>

          <app-tm-owner
            *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'"
            [isDisabled]="!personalData"
            [requestType]="requestType"
            [startData]="ownerTM"
            [imageText]="imageText"
            [organizations]="organizationsData"
            (nextStepEvent)="TMstep1to2($event)"
            [isShowCompanyInfo]="isShowCompanyInfo"
          ></app-tm-owner>
          <app-owner
            *ngIf="requestType !== 'trademark' && requestType !== 'trademarkSearch'"
            [isDisabled]="!personalData"
            [startData]="owner"
            [requestType]="requestType"
            (nextStepEvent)="step1to2($event)"
          ></app-owner>
        </div>
        <div *ngSwitchCase="2">
          <app-tm-mktu *ngIf="(requestType === 'trademark' && OKVEDCodesShort) || requestType === 'trademarkSearch'"
                       [OKVEDCodes]="OKVEDCodes"
                       [OKVEDCodesShort]="OKVEDCodesShort"
                       [ownerShortName]="ownerShortName"
                       [idApplication]="idApplication"
                       [firstJSON]="firstJSON"
                       [draftData]="tariff?.MKTUCodesShort"
                       [mktuSearch]="mktuSearch"
                       [owner]="owner"
                       [requestType]="requestType"
                       (nextStepEvent)="TMstep2to3($event)"
                       (backStepEvent)="backStep()">
          </app-tm-mktu>
          <app-info *ngIf="requestType !== 'trademark' && requestType !== 'trademarkSearch'"
                    [requestType]="requestType"
                    [selectedType]="owner?.type"
                    [idApplication]="idApplication"
                    [startData]="filesData"
                    (nextStepEvent)="step2to3($event)"
                    (backStepEvent)="backStep()"></app-info>
        </div>
        <div *ngSwitchCase="3">
          <h1 class="form-item__head" id="ratesHeader">Выберите тариф</h1>
          <div class="attention" *ngIf="requestType !== 'trademark' && requestType !== 'trademarkSearch'">
            <h3>Важно</h3>
            <p *ngIf="requestType === 'patent'">После анализа документов, определения вида объекта и составления формулы изобретения мы рассчитаем сумму
              пошлин и выставим вам дополнительный счет. В соответствии с законом патентная пошлина рассчитывается в зависимости
              от итогового количества пунктов формулы изобретения.</p>
            <p *ngIf="requestType === 'soft' || requestType === 'rumcc'">Государственная пошлина за регистрацию
              программы для ЭВМ или базы данных оплачивается  заявителем лично. После анализа документов мы
              рассчитаем сумму пошлины  и направим вам вместе с реквизитами для самостоятельной оплаты. Все
              документы появятся в разделе "Дела".  </p>
          </div>
          <app-rates *ngIf="requestType === 'trademark' || requestType === 'trademarkSearch'"
                     [rates]="tariff?.rates"
                     [activeTariff]="tariff?.activeTariff"
                     [countRates]="tariff?.countRates"
                     [requestType]="requestType"
                     (selectingTariff)="selectTariff($event)"></app-rates>
          <app-rates *ngIf="requestType !== 'trademark' && requestType !== 'trademarkSearch'"
                     [rates]="tariff?.rates"
                     [activeTariff]="tariff?.activeTariff"
                     [countRates]="tariff?.countRates"
                     [requestType]="requestType"
                     (selectingTariff)="selectTariff($event)"></app-rates>

          <div class="duty" *ngIf="requestType === 'trademark'">
            <div>
              <div class="duty__text">Пошлины Роспатента</div>
              <div class="duty__description">Все пошлины за {{tariff?.MKTUCodesShort?.length}} {{declOfNum(tariff?.MKTUCodesShort?.length)}}
                МКТУ, с учетом 30% скидки {{brandLabel}} за электронную подачу заявки
              </div>
            </div>
            <div class="duty__price-wrap">
              <p class="duty__price">
                <span class="duty__old-price">{{(+tariff.duty.sum).toLocaleString('ru')}} ₽</span>
                <span class="duty__discount">-{{tariff.duty.discount}}%</span>
              </p>
              <span class="duty__full">{{(+tariff.duty.sumDiscount).toLocaleString('ru')}} ₽</span>
            </div>
          </div>

          <div class="form-item__btn-block">
            <a class="back-button" (click)="backStep();structureEvent()">
              <svg width="21" height="16" viewBox="0 0 21 16">
                <path d="M0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z"/>
              </svg>
              <div class="text">Назад</div>
            </a>
            <div class="form-item__btn-block--mini sum-block" *ngIf="tariff && tariff.sumBeforeDiscount && tariff.paymentSum">
              <p *ngIf="tariff.sumBeforeDiscount !== tariff.paymentSum">Итого к оплате:<span>{{(+tariff.sumBeforeDiscount).toLocaleString('ru')}} ₽</span> <b>{{(+tariff.paymentSum).toLocaleString('ru')}} ₽</b></p>
              <p *ngIf="tariff.sumBeforeDiscount === tariff.paymentSum">Итого к оплате: <b>{{(+tariff.paymentSum).toLocaleString('ru')}} ₽</b></p>
            </div>
            <div class="form-item__btn-block--mini" >
              <app-promo-code
                style="width: 100%"
                (promoEvent)="applyPromoCode($event)"
              >
              </app-promo-code>
              <button type="button" class="btn-blue" (click)="nextStep()" id="payBtn">Оплатить</button>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="4">
          <!--Пошлины Роспатента -->
<!--          <div *ngIf="requestType === 'trademark' && (envService.isGardiumOnline || envService.isAlfa)">-->
<!--            <app-duties-->
<!--              [duties]="duties"-->
<!--              [activeDuty]="activeDuty"-->
<!--              [paymentSum]="tariff?.paymentSum"-->
<!--              [tariffTitle]="tariffTitle"-->
<!--              (selectingDuty)="selectDuty($event)"-->
<!--            >-->
<!--            </app-duties>-->
<!--            <div class="form-item__btn-block">-->
<!--              <a class="back-button" (click)="backStep()">-->
<!--                <svg width="21" height="16" viewBox="0 0 21 16">-->
<!--                  <path d="M0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z"/>-->
<!--                </svg>-->
<!--                <div class="text">Назад</div>-->
<!--              </a>-->
<!--              <div class="form-item__btn-block&#45;&#45;mini">-->
<!--                <button type="button" class="btn-blue" (click)="nextStep()">Оплатить</button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div>
            <app-payment
              [promoCode]="promoCode"
              [requestType]="requestType"
              [idApplication]="idApplication"
              [company]="company"
              [paymentSum]="tariff?.paymentSum"
              [activeTariff]="tariff?.activeTariff"
              [owner]="firstJSON?.owner"
              (switchPaymentType)="switchPaymentTab($event)"
              (nextStepEvent)="nextStep()"
              (backStepEvent)="backStep()">
            </app-payment>
          </div>
        </div>

        <div *ngSwitchCase="5">
<!--          <div *ngIf="requestType === 'trademark' && (envService.isGardiumOnline || envService.isAlfa)">-->
<!--            <app-payment-->
<!--              [promoCode]="promoCode"-->
<!--              [requestType]="requestType"-->
<!--              [idApplication]="idApplication"-->
<!--              [company]="owner?.company"-->
<!--              [paymentSum]="paymentSumWithDuty"-->
<!--              [activeTariff]="tariff?.activeTariff"-->
<!--              [owner]=" firstJSON?.owner"-->
<!--              [duty]="activeDuty"-->
<!--              (switchPaymentType)="switchPaymentTab($event)"-->
<!--              (nextStepEvent)="nextStep()"-->
<!--              (backStepEvent)="backStep()">-->
<!--            </app-payment>-->
<!--          </div>-->
          <div>
            <div class="info-plate" *ngIf="paymentType === 'online'">
              <div class="info-plate__img"><img [src]="logo2path"></div>
              <p class="info-plate__text">Как только ваша оплата будет обработана,<br class="hide-up-to-md">
                мы переместим вашу заявку из черновиков в активные дела.</p><a class="btn" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к
              заявке</a>
            </div>
            <div class="info-plate" *ngIf="paymentType === 'cash'">
              <div class="info-plate__img"><img [src]="logo2path"></div>
              <p class="info-plate__text" id="infoPlate">Счет сформирован и отправлен вам на почту {{userEmail}}.<br> Рекомендуем оплатить
                счет в течение 5 дней.</p><a class="btn" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к заявке</a>
            </div>
          </div>
          <div class="info-plate" *ngIf="paymentType === 'act'">
            <div class="info-plate__img"><img [src]="logo2path"></div>
            <p class="info-plate__text">Стоимость услуг и пошлины будут включены в следующий акт выполненных работ.</p>
            <a class="btn" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к заявке</a>
          </div>
        </div>
        <div *ngSwitchCase="6">
          <!--        Разметка ошибки-->
          <!--        <div *ngIf="">-->
          <!--          <div class="info-plate info-plate__error" >-->
          <!--            <h1 class="heading-red">Ошибка</h1>-->
          <!--            <div class="info-plate__img"><img [src]="logo2path"></div>-->
          <!--            <p class="info-plate__text">Неправильно указан код CVV2 (CVC2, CID). <br>-->
          <!--              Вам следует повторить платеж и ввести корректные данные.</p>-->
          <!--            <button class="btn-blue">Попробовать еще раз</button>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--        <div *ngIf="requestType !== 'trademark'" class="payment__link&#45;&#45;end" routerLink="/index/item">-->
          <!--          <h1>Заявка успешно создана</h1>-->
          <!--          <img src="/assets/images/logo_gardium.svg" >-->
          <!--          <p>Оплата прошла успешно. Ваша заявка на регистрацию создается.-->
          <!--            Ссылка для отслеживания статуса регистрации будет направлена на почту в течение 15 минут.-->
          <!--          </p>-->
          <!--        </div>-->
          <!--        <div *ngIf="requestType === 'trademark'">-->
          <div class="info-plate" *ngIf="paymentType === 'online'">
            <div class="info-plate__img"><img [src]="logo2path"></div>
            <p class="info-plate__text">Как только ваша оплата будет обработана,<br class="hide-up-to-md">
              мы переместим вашу заявку из черновиков в активные дела.</p><a class="btn" routerLink="/cases">Перейти к
            заявке</a>
          </div>
          <div class="info-plate" *ngIf="paymentType === 'cash'">
            <div class="info-plate__img"><img [src]="logo2path"></div>
            <p class="info-plate__text">Счет сформирован и отправлен вам на почту {{userEmail}}.<br> Рекомендуем оплатить
              счет в течение 5 дней.</p><a class="btn" routerLink="/cases" (click)="sendIntercomEvent()">Перейти к заявке</a>
          </div>
          <!--        </div>-->
        </div>
      </div>
    </div>



  </div>

</section>

<app-expert></app-expert>
