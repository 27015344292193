import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  userPassForm: FormGroup;
  private readonly onDestroy = new Subject<void>();
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%*?&^,.#-])[A-Za-z\d$@!%*?&^,.#-].{5,}$/;
  error = false;
  errorText;


  constructor(
    private fb: FormBuilder,
    private profileApi: UserProfileService,
    private location: Location,
    private readonly toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    const PassPattern = this.passwordPattern;

    this.userPassForm = this.fb.group({
      password: [''],
      newPassword: ['', [Validators.pattern(PassPattern)]],
      confirmPassword: ['', [Validators.pattern(PassPattern)]],
    });
  }

  changePassword() {
    const body = {
      passOld: this.userPassForm.value.password,
      passNew: this.userPassForm.value.newPassword,
      passConfirm: this.userPassForm.value.confirmPassword,
    };
    this.profileApi.changePassword(body).pipe(takeUntil(this.onDestroy)).subscribe(data => {
      if (data.answer === 'Успешно') {
        console.log(data);
        this.userPassForm.reset();
        this.error = false;
        this.toastrService.success('Пароль успешно изменен');
      } else {
        this.error = true;
        this.errorText = data.answer;
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
