import {
  Inject,
  OnInit,
  Component,
  AfterViewInit,
  InjectionToken,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, Subject, switchMap } from 'rxjs';
import { PaymentState } from '../../store/payment.state';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { PaymentActions } from '../../store/payment.actions';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectSnapshot, ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';

const WINDOW = new InjectionToken<string>('window');

@UntilDestroy()
@Component({
  selector: 'app-payment-online',
  templateUrl: './payment-online.component.html',
  styleUrls: ['./payment-online.component.scss'],
  providers: [{ provide: WINDOW, useValue: window }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentOnlineComponent implements OnInit, AfterViewInit {
  @ViewSelectSnapshot(PaymentState.payer) payer: string | null;
  @ViewSelectSnapshot(PaymentState.yaLastPaymentId) yaLastPaymentId: string | null;
  @SelectSnapshot(PaymentState.idApplication) id: string | null;
  @SelectSnapshot(PaymentState.confirmation_token) confirmation_token: string | null;
  @Dispatch() createYandexPayment = () => new PaymentActions.CreateYandexPayment();
  @Dispatch() checkPaymentStatus = (id: string) => new PaymentActions.CheckPaymentStatus(id);

  private readonly onDestroyTimer = new Subject<void>();

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly ref: ChangeDetectorRef,
    @Inject(WINDOW) private readonly window: any,
  ) { }

  public ngOnInit(): void {
    this.store.select(PaymentState.idApplication)
      .pipe(
        filter(idApplication => Boolean(idApplication)),
        switchMap(() => this.route.queryParams),
        untilDestroyed(this),
      )
      .subscribe(queryParams => this.initPayment(queryParams));
  }

  public ngAfterViewInit(): void {
    this.store.select(PaymentState.yaLastPaymentId)
      .pipe(filter(id => Boolean(id)), untilDestroyed(this))
      .subscribe(yaLastPaymentId => this.initYooMoneyWidget(yaLastPaymentId));
  }

  private initPayment(queryParams: Params): void {
    queryParams.returnAfterPayment
      ? this.checkPaymentStatus(queryParams.t)
      : this.createYandexPayment();
  }

  private initYooMoneyWidget(id: string): void {
    const checkout = new this.window.YooMoneyCheckoutWidget({
      confirmation_token: this.confirmation_token,
      return_url: `${window.location.protocol}//${window.location.host}/${this.router.url}?returnAfterPayment=true&t=${id}`,
      error_callback: (error: unknown) => console.error('ya pay err', error),
    });
    checkout.render('payment-form');
    this.ref.detectChanges();
  }
}
