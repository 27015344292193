<section class="section-position section-position__color">
  <div *ngIf="showLoader" class="data-loader">
    <h3> Загрузка информации об услуге</h3>
    <app-spinner
      [color]="'white'"
    ></app-spinner>
  </div>
  <div *ngIf="!showLoader">
    <div class="container container__top">
      <h1 class="services-header">{{$initial.title}}
        <a routerLink="/services"
           class="back-link">
              <span>
                <svg width="12" height="8" viewBox="0 0 21 16">
                  <g clip-path="url(#clip0)">
                   <path
                     d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                    <rect width="21" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
          Назад
        </a>
      </h1>
      <div class="package" *ngIf="!noPackages">
        <p>Пакет услуг:</p>
        <div *ngFor="let btn of $rates" class="package__btns">
          <button class="package__btn" [class.package__btn--active]="$details.id === btn.id"
                  (click)="toSelectPackage(btn.id, btn.name)">{{btn.name}}</button>
        </div>
      </div>
    </div>
    <ul class="container top-card">
      <li *ngFor="let board of $details.boards" class="top-card__card">
        <div class="top-card__card-subtitles">
          <p>{{board.title}}</p>
          <span *ngIf="board.description !== null" class="prompt prompt__question">?
         <span class="prompt__hidden">
           <p>{{board.description}}</p>
         </span>
       </span>
        </div>
        <p class="top-card__numbers">
          {{board.value}}
        </p>
      </li>
    </ul>
  </div>

</section>

<div *ngIf="$details" class="services container">

  <div class="top-btns">
    <button (click)="toLeadForm($initial.title)" class="top-btns__btn">
      <p>Заказать услугу</p>
    </button>
    <button (click)="toLeadForm('Спросить у эксперта')" class="top-btns__btn">
      <p>Спросить у эксперта</p>
      <div class="prompt prompt__question">?
        <div class="prompt__hidden">
          Специалист свяжется с вами для бесплатной консультации
        </div>
      </div>
    </button>
    <button *ngIf="fastRegistration" (click)="toLeadForm('Ускорить регистрацию')" class="top-btns__btn">
      <p>Ускорить регистрацию</p>
      <div class="prompt prompt__question">?
        <div class="prompt__hidden">
          Сократить срок регистрации до 2 месяцев
        </div>
      </div>
    </button>
  </div>

  <div class="services__card-wrapper">

    <section class="services__card" *ngIf="$details?.serviceComposition?.length">
      <div class="services__card-top">
        <p>Состав услуг</p>
      </div>
      <div class="services__card-bottom">
        <div *ngFor="let service of $details?.serviceComposition" class="services__card-bottom--block">
          <h3>{{service.title}}</h3>
          <div class="attention__show">
            <ul class="attention__list">
              <li *ngFor="let serviceItem of service.item">
                {{serviceItem}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="services__card" *ngIf="$details.informationPrice?.title">
      <div class="services__card-top">
        <p>{{$details.informationPrice.title}}</p>
      </div>
      <div class="services__card-bottom services__card-bottom--padding">
        <div class="services__card-bottom--block">
          <p>{{$details.informationPrice.description}}</p>
        </div>
        <div class="table-economy">
          <div class="table-economy__row">
            <span>{{$details.informationPrice.leftTitle}}</span>
            <span>{{$details.informationPrice.rightTitle}}</span>
          </div>
          <div *ngFor="let price of $details.informationPrice.tablePrice">
            <div class="table-economy__row">
              <p>{{price.name}}</p>
            </div>
            <div class="table-economy__row">
              <span>{{price.leftValue}}</span>
              <span>{{price.rightValue}}</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="$details.about?.title">
      <div class="services__card">
        <div class="services__card-top">
          <p>{{$details.about.title}}</p>
        </div>
        <div class="services__card-bottom services__card-bottom--padding">
          <div class="services__card-bottom--block services__card-bottom--text">
            <div *ngFor="let about of $details.about.items">
              <p><b>{{about.name}}</b></p>
              <p>{{about.value}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <section class="attention" *ngIf="$details.recommendation">
    <h3>{{$details.recommendation.title}}</h3>
    <p>{{$details.recommendation.text}}</p>
  </section>

</div>

<app-expert>
</app-expert>
