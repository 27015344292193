import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthPageRoutingModule } from './auth-page-routing.module';
import { AuthComponent } from './auth.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { SystemPageModule } from '../system-page.module';
import { AuthPageGuard } from './auth-page.guard';
import { SsoAuthComponent } from './sso-auth/sso-auth.component';
import {UserPhoneModule} from '../../../../../shared/components/user-phone/user-phone.module';
import {UiSharedModule} from '@ui/shared/ui-shared.module';

@NgModule({
  imports: [
    CommonModule,
    AuthPageRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2TelInputModule,
    SystemPageModule,
    UserPhoneModule,
    UiSharedModule
  ],
  declarations: [
    AuthComponent,
    SsoAuthComponent,
  ],
  providers: [
    AuthPageGuard,
  ]
})
export class AuthPageModule {
}
