import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  logoPath = '';
  logo2Path = '';

  constructor() {
    this.logoPath = environment.LOGO_PATH;
  }

  ngOnInit() {
  }

}
