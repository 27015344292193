<div class="open-case"
     [ngClass]="{
               'blue': progress === 20,
               'yellow': progress === 40,
               'red': progress === 60,
               'green': progress === 80,
               'grey': case?.progress === 100
             }"
>
  <p class="open-case__category">{{case?.description}}</p>
  <p class="open-case__status">{{case?.status}}</p>
  <h3 class="open-case__name">{{case?.name}}</h3>
  <div class="open-case__progress">
    <div class="open-case__progress-bar"
         [ngStyle]="{'width': progress +'%'}">
      <span class="open-case__progress-percent">{{progress}}%</span>
    </div>
  </div>
</div>
