import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PaginationComponent implements OnInit, OnChanges {

  mobile = true;

  private _countPages: number;
  @Input() set countPages(count: number) {
    this._countPages = Math.ceil(count);
    this.pages = [];
    for (let i = 1; i <= this.countPages; i++) {
      this.pages.push(i);
    }
    this.subPages = this.pages.slice(0, 5);
  };

  get countPages() {
    return this._countPages;
  }

  @Input() allItems;

  @Input() page: number = 1;

  @Output() eventChangePage = new EventEmitter<any>();

  pages = [];
  subPages = [];
  @Input() itemPerPage: number;
  @Input() totalCount: number;
  @Input() currentPageNumber: number;
  @Input() monitoring = false;

  @Output() changePageNumber = new EventEmitter<number>();

  pageNumbersList: number[];
  maxPaginationBtns = 7;
  isNextSet = false;
  isPrevSet = true;

  pageCount: number;

  constructor() {
  }

  ngOnInit() {
  }

  changePage(pageNumber) {
    this.eventChangePage.emit(pageNumber);
    this.page = pageNumber;
    if (this.countPages > 5) {
      if (pageNumber < 3) {
        this.subPages = this.pages.slice(0, 5);
      } else if (pageNumber > (this.countPages - 2)) {
        this.subPages = this.pages.slice(this.countPages - 5, this.countPages);
      } else {
        this.subPages = this.pages.slice(pageNumber - 3, pageNumber + 2);
      }
    }
  }

  onChangePageNumber(pageNumber: number) {
    this.changePageNumber.emit(pageNumber);
    this.eventChangePage.emit(pageNumber);

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isNextSet = false;
    this.isPrevSet = false;

    this.pageCount = Math.ceil(this.totalCount / this.itemPerPage);

    let numberBtns = this.maxPaginationBtns;
    if (this.pageCount > this.maxPaginationBtns) {
      if (this.currentPageNumber > 3) {
        this.isPrevSet = true;
        numberBtns -= 1;
      }
      if ((this.pageCount + 1 - this.currentPageNumber) > 4) {
        this.isNextSet = true;
        numberBtns -= 3;
      }
    } else {
      numberBtns = this.pageCount;
    }

    let startPageBtn: number;
    if (!this.isPrevSet) {
      startPageBtn = 1;
    } else if (!this.isNextSet) {
      startPageBtn = this.pageCount + 1 - numberBtns;
    } else {
      startPageBtn = this.currentPageNumber - 1;
    }

    this.pageNumbersList = [];
    for (let i = 0; i < numberBtns; i++) {
      this.pageNumbersList.push(startPageBtn + i);
    }
  }

  getWidthBtn() {
    if (window.innerWidth < 800) {
      switch (this.pageNumbersList.length) {
        case 5:
          return {width: 'calc((100% - 30px) / 7'};
        default:
          return {width: 'calc((100% - 25px) / 6'};
      }
    }
  }
}
