import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  onlyNumbersPattern = /^-?(0|[1-9]\d*)?$/;


  constructor() {
  }

  ValidatePhone(control: AbstractControl): { [key: string]: any } | null {
    if (this.onlyNumbersPattern.test(control.value)) {
      return {phoneNumberInvalid: false};
    }
    return null;
  }
}
