<div class="search__input-wrap">
  <img *ngIf='icon' class='search__input-icon' [src]='icon' alt=''>
  <div *ngIf="legalName"
       [ngStyle]="getDynamicMarginForLegalName()"
       class="legal-name"
  >
    {{trimString(legalName, 68)}}
  </div>
  <input class="search__input"
         id="searchQuery"
         [ngClass]="{'search__input_without-icon': !icon}"
         [ngModel]="val"
         (ngModelChange)="update($event)"
         [disabled]='disabled'
         (keyup.enter)="searchObjects($event)"
         [placeholder]="placeholder"/>
</div>
