import { Directive } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS } from '@angular/forms';

const onlyNumbersPattern = /^-?(0|[1-9]\d*)?$/;

@Directive({
  selector: '[appNumberValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: NumberValidatorDirective,
    multi: true
  }]
})


export class NumberValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (onlyNumbersPattern.test(control.value && control.value.length)) {
      // if (control.value && control.value.length !== 10) {
      return {phoneNumberInvalid: true};
    }
    return null;
  }
}
