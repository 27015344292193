import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DictionaryService } from '../../../../../shared/services/dictionary-service';
import { NavigationPageType } from '../enums/navigation-page-type';

@Injectable({providedIn: 'root'})

export class NavigationService {

  constructor(private _router: Router,
              private _dictionatyService: DictionaryService) {
  }

  public navigate(pageType: NavigationPageType): void {
    const pagePath = this._dictionatyService.navigationDictionary.getItem(pageType);
    if (String.IsNullOrEmpty(pagePath)) {
      return;
    }

    this._router.navigate([`/${pagePath}`]);
  }
}
