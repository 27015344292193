import { Component, Input, OnInit } from '@angular/core';


const SIZE = 590;

@Component({
  selector: 'app-simple-progress-view',
  templateUrl: './simple-progress-view.component.html',
  styleUrls: ['./simple-progress-view.component.scss']
})
export class SimpleProgressViewComponent implements OnInit {

  @Input() progress: number;
  @Input() size: 'small' | 'middle' | 'default' = 'default';

  constructor() {
  }

  ngOnInit(): void {
  }

  get dashoffset() {
    return SIZE + 0 - (SIZE / 100 * this.progress);
  }

  get color() {
    return this.colorByPercent(this.progress);
  }

  colorByPercent(percent) {
    const map = {
      'low': 'red',
      'middle': 'orange',
      'high': 'green',
    };
    return map[this.riskLevelByPercent(percent)];
  }

  private riskLevelByPercent(percent) {
    if (percent < 20) {
      return 'low';
    } else if (percent < 50) {
      return 'middle';
    } else {
      return 'high';
    }
  }
}
