import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-open-index',
  templateUrl: './open-index.component.html',
  styleUrls: ['./open-index.component.scss']
})
export class OpenIndexComponent implements OnInit {

  constructor(private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  toRequestPage(type) {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/request') || currentUrl === 'undefined') {
      window.location.href = `/request?type=${type}`;
    } else {
      this.router.navigate(['/request'], {relativeTo: this.route, queryParams: {type}});
    }
  }

  goToDashboard() {
    this.router.navigate([`/dashboard`]);
  }

  goToMonitoring() {
    this.router.navigate([`/monitoring`]);
  }
}
