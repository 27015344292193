<app-user-phone *ngIf="!user?.phone" [isDebit]="false"></app-user-phone>

<section [class.disabled-section]="!user?.phone">
  <app-trademark-info class="form js-form"></app-trademark-info>
  <app-trademark-form></app-trademark-form>

  <div class="actions">
    <app-ui-button uiType="secondary" [id]="'cancel'" label="Отмена" (onClick)="cancelRequest()"></app-ui-button>
    <app-ui-button label="Продолжить" [id]="'nextStep'" icon="arrow-right" [disabled]="disableNextStep" (onClick)="updateTrademarkDraft()"></app-ui-button>
  </div>
</section>
