<div class="ip-id_header">
  <div class="id-id_top_nav">
    <div class="ip-id_item_1">
      <div class="ip-id_logo"></div>
    </div>
    <div class="ip-id_item_2">
      <div class="item_parent">
        <div class="items">Товарные знаки
          <img src="/assets/images/select.png" alt="">
        </div>
        <div class="items">Названия юрлиц
          <img src="/assets/images/select.png" alt="">
        </div>
        <div class="items">Доменные имена
          <img src="/assets/images/select.png" alt="">
        </div>
        <div class="items">О проекте
          <img src="/assets/images/select.png" alt="">
        </div>
      </div>
    </div>
    <div class="ip-id_item_3">
      <img src="/assets/icons/hamburger.svg" class="hamburger">
    </div>
  </div>
</div>
