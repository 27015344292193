export function downloadFileByBlob(blob: Blob, filename?: string) {
  return downloadFileByUrl(URL.createObjectURL(blob), filename);
}

export function downloadFileByUrl(url: string, filename?: string) {
  if (!filename) {
    filename = url.substr(url.lastIndexOf('/') + 1);
  }

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function openFileByUrl(url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.target = 'blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
