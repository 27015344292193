<div class="monitoring-modal risk-modal">
  <div class="monitoring-modal__wrap">
    <div class="risk-modal__content">
      <p class="risk-modal__criticality"
           [class.risk-modal__criticality--critical]= "status === 'Критичный риск'"
           [class.risk-modal__criticality--high]= "status === 'Высокий риск'"
           [class.risk-modal__criticality--middle]="status === 'Средний риск'"
           [class.risk-modal__criticality--recommendation]="status === 'Рекомендация'"
      >{{status}}</p>
      <h1 class="risk-modal__title">Истекает срок действия патента</h1>
      <div class="risk-modal__desc">
        <p>
          <span class="risk-modal__desc-warning">После 10.10.2020</span> третьи лица получат возможность использовать ваши разработки или запатентовать их на себя
        </p>
      </div>
      <div class="risk-modal__about-wrap">
        <img src="./assets/images/risk-img.png" alt=""width="420" height="296" class="risk-modal__about-img">
        <dl class="risk-modal__about">
          <div>
            <dt>Патент 15122311</dt>
            <dd class="risk-modal__about-name">Технология высокоскоростной передачи данных в сетях 5G</dd>
          </div>
          <div>
            <dt>Авторы</dt>
            <dd>Москвич Роберт, Шторц Йоахим, Ланггер Таня</dd>
          </div>
          <div>
            <dt>Правообладатель</dt>
            <dd>КОЛГЕЙТ-ПАЛМОЛИВ КОМПАНИ</dd>
          </div>
          <div>
            <dt>Истекает</dt>
            <dd class="risk-modal__about-expire">10.10.2020</dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="risk-modal__footer">
      <p class="risk-modal__recommendation">Рекомендации</p>
      <p class="risk-modal__recommendation-desc">Продлите патент чтобы устранить проблему</p>
      <div class="risk-modal__btn-wrap">
        <div class="risk-modal__btn-wrapper">
          <button class="risk-modal__btn risk-modal__btn--decide">Продлить</button>
          <button class="risk-modal__btn risk-modal__btn--ask" (click)="onClickBtn('спросить поверенного')">Спросить поверенного</button>
        </div>
        <div class="risk-modal__take-risk-wrap">
          <button class="risk-modal__take-risk">Принять риск</button>
          <p>Проблема известна с 24.05.2020</p>
        </div>
      </div>
    </div>
    <button (click)="closeModal()" class="risk-modal__close"></button>
  </div>
  <div (click)="closeModal()" class="monitoring-modal__overlay"></div>
</div>
