import { Component, OnDestroy, OnInit } from '@angular/core';
import { CasesService } from './cases.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ICases {
  draft?: any[];
  active?: any[];
  finished?: any[];
}

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent implements OnInit, OnDestroy {

  // текущая активная вкладка
  activeTab;

  // Все дела полученные от API (Ни когда не меняется)
  immutableCases: ICases = {
    draft: [],
    active: [],
    finished: []
  };
  // Отображаемые дела (копия immutableCases), данные меняются в зависимости от активных фильтров
  cases: ICases = {};
  // Индикатор загрузки дел
  isLoadingCases = false;

  // Динамические данные для построения фильтров
  caseRequestTypes = [];
  caseStatuses = [];

  // Форма поиска на мобилке
  showMobileControl = false;

  contragentID;
  rights;
  permissions = {
    tradeCase: '',
    softCase: '',
    patentCase: ''
  };


  private readonly onDestroy = new Subject<void>();


  constructor(
    private casesApi: CasesService
  ) {
  }

  // Получение количества всех дел в зависимости от выбранного таба
  get countAllCases() {
    return this.immutableCases[this.activeTab].length || 0;
  }

  // Получение количества дел с учетом фильтров, в зависимости от выбранного таба
  get countFilteredCases() {
    return this.cases[this.activeTab].length || 0;
  }

  ngOnInit(): void {
    this.contragentID = JSON.parse(localStorage.getItem('organizations'));
    this.getCases();
  }

  getCases() {
    this.isLoadingCases = true;
    this.casesApi.getCases().pipe(takeUntil(this.onDestroy))
      .subscribe((casesData) => {
        this.getMappingCases(casesData);
      });
  }

  getMappingCases(casesData) {
    if (casesData?.length) {
      for (const caseItem of casesData) {
        switch (caseItem.caseType) {
          case 0:
            if (caseItem?.tracks?.length) {
              caseItem.tracks = caseItem.tracks.filter(el => el.name !== 'Выбор риска');
              caseItem.status = this.getLastTrackName(caseItem.tracks);
            }
            if (caseItem.description !== 'Претензионная работа') {
              this.immutableCases.draft.push(caseItem);
            }
            break;
          case 1:
            this.immutableCases.active.push(caseItem);
            break;
          case 2:
            this.immutableCases.finished.push(caseItem);
            break;
          default:
            console.warn(`Тип дела: ${caseItem.caseType} не соответствует значению (0 || 1 || 2) или отсутствует`);
        }
      }
      this.immutableCases.draft = this.immutableCases.draft.sort((a, b) => b.number - a.number);
      this.cases = JSON.parse(JSON.stringify(this.immutableCases));
      this.isLoadingCases = false;
      this.changeTab('active');

    } else {
      this.isLoadingCases = false;
      this.changeTab('active');
    }
  }

  getLastTrackName(tracks) {
    const onlyReachTracks = tracks.filter(track => track.reach);
    if (onlyReachTracks?.length) {
      return onlyReachTracks[onlyReachTracks.length - 1].name;
    } else {
      return 'Нет статуса';
    }
  }

  changeTab(tabName) {
    this.clearFilters();
    this.activeTab = tabName;

    // Подготавливаем данные для фильтров в зависимости от выбранного таба
    this.setDataToFilters(tabName);
  }

  setDataToFilters(tabName) {
    this.caseStatuses = [];
    this.caseRequestTypes = [];
    let tempData;

    switch (tabName) {
      case 'active':
        tempData = this.immutableCases.active;
        break;
      case 'draft':
        tempData = this.immutableCases.draft;
        break;
      case 'finished':
        tempData = this.immutableCases.finished;
        break;
      default:
        console.warn(`Tab: ${tabName} не существует`);
    }

    for (const caseItem of tempData) {
      // Формируем массив для фильтра "Статус"
      if (caseItem.status) {
        if (!this.caseStatuses.length) {
          this.caseStatuses.push({
            id: 1,
            value: caseItem.status,
            checked: false
          });
        } else {
          const foundStatus = this.caseStatuses.find(status => status.value === caseItem.status);
          if (!foundStatus) {
            this.caseStatuses.push({
              id: this.caseStatuses?.length + 1,
              value: caseItem.status,
              checked: false
            });
          }
        }
      }

      // Формируем массив для фильтра "Тип работ"
      if (caseItem.description) {
        if (!this.caseRequestTypes.length) {
          this.caseRequestTypes.push({
            id: 1,
            value: caseItem.description,
            checked: false
          });
        } else {
          const foundRequestType = this.caseRequestTypes.find(status => status.value === caseItem.description);
          if (!foundRequestType) {
            this.caseRequestTypes.push({
              id: this.caseRequestTypes?.length + 1,
              value: caseItem.description,
              checked: false
            });
          }
        }
      }
    }
  }

  applyFilters(event: any) {
    this.cases = JSON.parse(JSON.stringify(this.immutableCases));
    this.filterByText(event.searchText);
    this.filterByStatus(event.caseStatuses);
    this.filterByRequestTypes(event.caseRequestTypes);
  }

  clearFilters() {
    this.cases = JSON.parse(JSON.stringify(this.immutableCases));
    this.caseStatuses.forEach(item => {
      item.checked = false;
    });
    this.caseRequestTypes.forEach(item => {
      item.checked = false;
    });
  }

  filterByText(text) {
    for (const casesByType in this.cases) {
      if (this.cases.hasOwnProperty(casesByType)) {
        this.cases[casesByType] = this.cases[casesByType].filter((caseItem) => {
          return caseItem?.name.toLowerCase().includes(text.toLowerCase());
        });
      }
    }
  }

  filterByStatus(caseStatuses) {
    if (!caseStatuses?.length) {
      return;
    }

    for (const casesByType in this.cases) {
      if (this.cases.hasOwnProperty(casesByType)) {
        const tempFoundCases = [];
        this.cases[casesByType] = this.cases[casesByType].filter((caseItem) => {
          caseStatuses.forEach((caseStatus) => {
            if (caseStatus.value.toLowerCase() === caseItem.status.toLowerCase()) {
              tempFoundCases.push(caseItem);
            }
          });
        });

        this.cases[casesByType] = tempFoundCases;
      }
    }
  }

  filterByRequestTypes(caseRequestTypes) {
    if (!caseRequestTypes?.length) {
      return;
    }

    for (const casesByType in this.cases) {
      if (this.cases.hasOwnProperty(casesByType)) {
        const tempFoundCases = [];
        this.cases[casesByType].forEach((caseItem) => {
          caseRequestTypes.forEach((caseRequestType) => {
            if (caseRequestType.value.toLowerCase() === caseItem.description.toLowerCase()) {
              tempFoundCases.push(caseItem);
            }
          });
        });

        this.cases[casesByType] = tempFoundCases;
      }
    }
  }

  // getRoleForCheckingRights() {
  //   this.sharedApi.currentRights.subscribe(data => {
  //     this.rights = data;
  //     if (this.rights?.length) {
  //       this.getTasksDataAlgorithm();
  //     }
  //   });
  // }

  // getRightsAlgorithm() {
  //   if (this.items) {
  //     const tradeMarks = this.rights.filter(i => i.title === 'Товарные знаки');
  //     if (tradeMarks) {
  //       const synonym = 'trademarks_case-reconciliation';
  //       tradeMarks[0].data.forEach(item => {
  //         if (item.nameEng === synonym) {
  //           if (item.value === 'false' || item.value === 'pending') {
  //             this.permissions.tradeCase = item.nameEng;
  //           }
  //         }
  //       });
  //     }
  //     const patents = this.rights.filter(i => i.title === 'Патенты');
  //     if (patents) {
  //       const synonym = 'patents_case-reconciliation';
  //       patents[0].data.forEach(item => {
  //         if (item.nameEng === synonym) {
  //           if (item.value === 'false' || item.value === 'pending') {
  //             this.permissions.patentCase = item.nameEng;
  //           }
  //         }
  //       });
  //     }
  //
  //
  //     const soft = this.rights.filter(i => i.title === 'Программы и БД');
  //     if (soft) {
  //       const synonym = 'soft_case-reconciliation';
  //       soft[0].data.forEach(item => {
  //         if (item.nameEng === synonym) {
  //           if (item.value === 'false' || item.value === 'pending') {
  //             this.permissions.softCase = item.nameEng;
  //           }
  //         }
  //       });
  //     }
  //     this.configureRights(this.permissions);
  //   }
  // }

  // configureRights(permissions) {
  //   if (permissions.tradeCase.length > 0 && permissions.softCase.length > 0 && permissions.patentCase.length > 0) {
  //     this.openedTasks = this.items
  //       .filter(i => i.opened &&
  //         i.status &&
  //         i.details.description !== 'Регистрация патента' &&
  //         i.details.description !== 'Регистрация в реестре Минкомсвязи' &&
  //         i.details.description !== 'Регистрация ПО' &&
  //         i.details.description !== 'Регистрация товарного знака'
  //       );
  //     console.log(this.openedTasks, 'filtered', 1);
  //   } else if (permissions.tradeCase.length === 0 && permissions.softCase.length > 0 && permissions.patentCase.length > 0) {
  //     this.openedTasks = this.items
  //       .filter(i => i.opened &&
  //         i.status &&
  //         i.details.description !== 'Регистрация патента' &&
  //         i.details.description !== 'Регистрация в реестре Минкомсвязи' &&
  //         i.details.description !== 'Регистрация ПО'
  //       );
  //     console.log(this.openedTasks, 'filtered', 2);
  //   } else if (permissions.tradeCase.length === 0 && permissions.softCase.length === 0 && permissions.patentCase.length > 0) {
  //     this.openedTasks = this.items
  //       .filter(i => i.opened &&
  //         i.status &&
  //         i.details.description !== 'Регистрация патента'
  //       );
  //     console.log(this.openedTasks, 'filtered', 3);
  //   } else if (permissions.tradeCase.length === 0 && permissions.softCase.length === 0 && permissions.patentCase.length === 0) {
  //     this.openedTasks = this.items.filter(i => i.opened && !i.active && i.status);
  //     // console.log(this.openedTasks, 'opened');
  //   }
  // }

  getPatenteeName(name) {
    if (typeof name === 'string') {
      return name;
    } else {
      return name.name;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    localStorage.removeItem('selectCase');
  }
}
