import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() options!: ITabOption[];
  @Input() active: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}

export interface ITabOption {
  title: string;
  value?: any;
  route?: string;
  disabled?: boolean;
}
