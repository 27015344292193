<div class="add-user">
  <div class="add-user__wrap">
    <form [formGroup]="usersForm">
      <div class="add-user__header">
        <h2 class="add-user__header-title">Добавить пользователя</h2>
<!--        <div (click)="loading = !loading">777777</div>-->
        <button class="add-user__header-close" (click)="closeModal()"></button>
      </div>
      <button class="add-user__check-all" (click)="selectAll()">Выбрать всех</button>
      <div class="add-user__item-wrap">
        <div *ngFor="let user of users; let i = index"
             formArrayName="users" class="add-user__item"
             [ngClass]="user?.selected ? 'gray': ''"
        >
          <input type="checkbox" class="add-user__input visual-hidden"
                 [formControlName]="i" id="{{ user.id }}"
                 [value]="user?.id"
                 [(ngModel)]="user.selected"
                 [checked]="user?.selected" (change)="onChanged()"
          >
          <label class="add-user__label" for="{{ user.id }}">
            <img *ngIf="user.image" [src]="user.image" alt="" class="add-user__img">
            <img *ngIf="!user.image" src="/assets/images/avatar.png" alt="" class="add-user__img">
            <span>
              <span class="add-user__name">{{ user.performance || user.name }}</span>
              <span class="add-user__position">{{ user.position }}</span>
            </span>
          </label>
        </div>
      </div>
      <div class="add-user__buttons-wrap">
        <button *ngIf="!selected" class="add-user__button add-user__button--new" (click)="toInvitePage()">Создать нового пользователя</button>
        <button *ngIf="selected && loading" class="add-user__button add-user__button--add" type="button" style="width: 184px"><span class="loaderAuth"></span></button>
        <button *ngIf="selected && !loading" class="add-user__button add-user__button--add" type="submit" (click)="addSelected()">Добавить выбранных</button>
      </div>
    </form>

  </div>
  <div (click)="closeModal()" class="add-user__overlay"></div>
</div>
