import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {RequestCheckLabelService} from '../request-check-label.service';
import { INTERCOM_EVENT_NAME, IntercomEventsService } from '../../../../../../shared/services/intercom-events.service';

@Component({
  selector: 'app-finished-check-label',
  templateUrl: './finished-check-label.component.html',
  styleUrls: ['./finished-check-label.component.scss']
})
export class FinishedCheckLabelComponent implements OnInit {
  logo2path = '';

  constructor(
    readonly requestCheckLabelService: RequestCheckLabelService,
    private readonly intercomEventsService: IntercomEventsService
  ) {
    this.logo2path = environment.LOGO2_PATH;
    console.log(this.logo2path);
  }

  ngOnInit(): void {
  }

  get isLoading() {
    return this.requestCheckLabelService.isLoading;
  }

  public sendIntercomEvent(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.GOTO_ORDER });
  }
}
