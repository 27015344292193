import { Component, ElementRef, HostListener, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MainMenuService } from './main-menu.service';
import { HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../shared/services/shared.service';
import { CasesService } from '../../cases/cases.service';
import { EnvService } from '../../../shared/services/env.service';
import { environment } from '../../../../environments/environment';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { Store } from '@ngxs/store';
import { WebModuleConfig } from '@web/core/services/config.service';
import { ORGANIZATIONS_STATE_TOKEN } from '@web/core/states/organization.state';
import { OrganizationActions } from '@web/core/states/organization.actions';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { AUTH_STATE_TOKEN, AuthState } from '@web/core/states/auth.state';
import { nullableFilter } from '@web/utils/nullable-filter.util';
import {AuthActions} from '@web/core/states/auth.actions';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import { REQUEST_TYPES } from '@web/core/models/user.interface';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AppState} from '@web/core/states/app.state';

// declare var $: any;
declare var window: any;

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  @ViewSelectSnapshot(AuthState.getRightByName('monitoring-viewing')) monitoring: boolean;
  rights$ = this.store.select(AuthState.getRights);
  private readonly onDestroy = new Subject<void>();
  showDropdown = false;
  showDropdownOrg = false;
  modalNotification = false;
  userName: string;
  showModal = false;
  tasks;
  // TODO: revert after fix backend
  disableCases = false;
  initialCases = true;
  showMenu = false;
  overlay = false;
  contragentID;
  userAcc: string;
  administrator;
  environmentBrand;
  rights;
  showSearchList = false;

  searchDropdownList = [
    {
      name: 'Товарные знаки по обозначению',
      access: false
    },
    {
      name: 'Товарные знаки по изображению',
      access: false
    }
  ];

  menu = [
    {
      name: 'Организации и ИП',
      url: '/user/profile',
      tab: 'organization',
      enName: 'organization',
      count: 0
    },
    {
      name: 'Мои права доступа',
      url: '/user/profile',
      tab: 'access',
      enName: 'access',
      count: 0
    },
    {
      name: 'Пользователи и права',
      url: '/user/profile',
      tab: 'users',
      enName: 'users',
      count: 0
    },
    {
      name: 'История платежей',
      url: '/user/profile',
      tab: 'payment',
      enName: 'payment',
      count: 0
    },
    {
      name: 'Доверенности',
      url: '/user/profile',
      tab: 'attorney',
      enName: 'attorney',
      count: 0
    },
    {
      name: 'Редактировать профиль',
      url: '/user/change-profile',
      tab: '',
      enName: 'change-profile',
      count: 0
    },
    {
      name: 'Изменить пароль',
      url: '/user/change-password',
      tab: '',
      enName: 'change-password',
      count: 0
    }
  ];

  // Временно - недостаточно персональных данных для подачи заявки
  personalData = true;
  // Временно - нет телефона (меняется на success, когда добавляем телефон)
  noPhone = true;
  // Временно - выслан ли код
  sendCode = false;
  // Временно - для зеленой обводки у поля "введите код", если код верный
  codeError = false;
  phoneUser;
  brand;
  codePhone;
  userData;
  errorText;
  resetPhone = false;
  params;
  selectedOrg;
  user;
  isClickOutside = false;

  @ViewChild('personalData') personalDataRef: ElementRef;
  @ViewChild('cards') cardsRef: ElementRef;

  constructor(private menuService: MainMenuService,
              readonly envService: EnvService,
              private router: Router,
              private route: ActivatedRoute,
              private shared: SharedService,
              private casesApi: CasesService,
              private userAPI: UserProfileService,
              private store: Store,
              private config: WebModuleConfig,
              private readonly intercomEventsService: IntercomEventsService,
  ) {
    this.environmentBrand = environment.BRAND;

    // TODO: rewrite get env from store data
    // this.store.dispatch(new AppActions.SetBrand()).subscribe(globalState => {
    //   console.log('globalState', globalState.app);
    //   this.brand = globalState.app.brand || '';
    //   console.log('BRAND: ', this.brand);
    // });

    const orgState = this.store.selectSnapshot(ORGANIZATIONS_STATE_TOKEN);
    this.disableCases = !!!orgState.casesExist;

  }

  @HostListener('document:mousedown', ['$event'])
  clickOutside(event): void {
    if (this.cardsRef?.nativeElement?.contains(event.target)) {
      this.isClickOutside = true;
      setTimeout(() => {
        this.isClickOutside = false;
      }, 900);
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUserData'));
    this.contragentID = JSON.parse(localStorage.getItem('organizations'));
    this.rights$.pipe(
      nullableFilter(),
      takeUntil(this.onDestroy)
    ).subscribe(rights => {
      this.rights = rights.rights;
      let rightsSearch = [];
      this.rights?.find(el => {
        if (el?.title?.toLowerCase() === 'права на поиски') {
          rightsSearch = el.data;
        }
        if (rightsSearch?.length) {
          rightsSearch.forEach((right) => {
            if (right.name === 'по обозначению') {
              this.searchDropdownList[0].access = right.value;
            } else if (right.name === 'по изображению') {
              this.searchDropdownList[1].access = right.value;
            }
          });
        }
      });
    });
    // TODO: после создания матрицы прав подумать над этим
    // this.route.queryParams.pipe(takeUntil(this.onDestroy)).subscribe(params => {
    //   if (params?.selectedOrganizationId) {
    //     this.selectedOrg = this.contragentID.find(o => o.id === params?.selectedOrganizationId);
    //   } else {
    //     this.selectedOrg = this.contragentID.length === 2
    //       ? this.contragentID[1]
    //       : this.contragentID[0];
    //   }
    //   this.store.dispatch(new AuthActions.GetRights(this.selectedOrg)).subscribe(item => {
    //     const rights = item.auth.rights.rights;
    //     rights.find(el => {
    //       if (el.title.toLowerCase() === 'права на поиски') {
    //         this.rightsSearch = el.data;
    //       }
    //     });
    //
    //     if (this.rightsSearch?.length) {
    //       this.rightsSearch.forEach((right) => {
    //         if (right.name === 'по обозначению') {
    //           this.searchDropdownList[0].access = right.value;
    //         } else if (right.name === 'по изображению') {
    //           this.searchDropdownList[1].access = right.value;
    //         }
    //       });
    //     }
    //   });
    // });

    if (!localStorage.getItem('currentUserIPID')) {
      this.router.navigate(['/login']);
    }
    if (localStorage.getItem('currentUserData')) {
      this.userName = JSON.parse(localStorage.getItem('currentUserData'))?.userInfo?.name
        || JSON.parse(localStorage.getItem('currentUserData'))?.name;
      this.userAcc = JSON.parse(localStorage.getItem('currentUserData'))?.userInfo?.multiaccount
        || JSON.parse(localStorage.getItem('currentUserData'))?.multiaccount;
      this.administrator = JSON.parse(localStorage.getItem('currentUserData'))?.role === 'Администратор'
        || JSON.parse(localStorage.getItem('currentUserData'))?.userInfo?.role === 'Администратор';
      this.isDisabledUserMenu();
    }

    this.showDropdown = window.innerWidth < 992;
    this.brand = environment.BRAND;

    if (this.store.selectSnapshot(AUTH_STATE_TOKEN).user) {
      this.userData = this.store.selectSnapshot(AUTH_STATE_TOKEN).user;
      this.personalData = this.userData.phone;
      if (this.personalData) {
        this.noPhone = false;
      }
    }



    window.addEventListener('resize', () => {
      this.showDropdown = window.innerWidth < 992;
    });
    this.shared.closeModals.pipe(takeUntil(this.onDestroy)).subscribe(() => {
    });

    this.shared.openModal.subscribe(() => {
      this.openModal();
    });
  }

  public trimString(str, count): string {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  isDisabled(type: string) {
    // return environment.production && type !== 'trademark';
    return false;
  }

  public toRequestPage(type: string): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_IP_TYPE, typeIP: type });
    if (type === REQUEST_TYPES.TRADEMARK || type === REQUEST_TYPES.TRADEMARK_SEARCH) {
      void this.router.navigate(['/', APP_ROUTES_NAMES.REQUESTS, type]);
    } else {
      const currentUrl = this.router.url;
      if (currentUrl.includes('/request') || currentUrl === 'undefined') {
        window.location.href = type !== 'request-contract' && type !== 'request-check-label' ? `/request?type=${type}` : `${type}`;
      } else {
        if (type !== 'request-contract' && type !== 'request-check-label') {
          this.router.navigate(['/request'], {relativeTo: this.route, queryParams: {type}});
        } else if (type === 'request-contract') {
          this.router.navigate(['/request-contract'], {relativeTo: this.route});
        } else if (type === 'request-check-label') {
          this.router.navigate(['/request-check-label'], {relativeTo: this.route});
        }
      }
    }
    this.closeModal();
  }
  // TODO: после создания матрицы прав подумать над этим
  // getRoleForCheckingRights(param?) {
  //   const user = JSON.parse(localStorage.getItem('currentUserData'));
  //   if (this.selectedOrg) {
  //     this.params = {
  //       contragentID: param
  //     };
  //   }
  //   if (user.role === 'Пользователь' || user.multiaccount) {
  //     if (this.params) {
  //       this.shared.getRightsAsync(this.params).subscribe(data => {
  //         if (data) {
  //           this.rights = data.rights;
  //         }
  //       });
  //     }
  //   }
  // }

  getPersonalDataStatus(evt: boolean) {
    return this.personalData = evt;
  }

  getSuccessPhoneConfirm(evt: boolean) {
    return this.noPhone = evt;
  }

  isCardDisabled(synonym): boolean {
    if (this.user.role === 'Пользователь' && this.user.multiaccount) {
      if (this.rights) {
        switch (synonym) {
          case 'contracts_requests':
            return this.checkView(this.rights.filter(i => i.title === 'Договоры'), synonym);
          case 'patents_requests':
            return this.checkView(this.rights.filter(i => i.title === 'Патенты'), synonym);
          case 'soft_requests':
            return this.checkView(this.rights.filter(i => i.title === 'Программы и БД'), synonym);
          case 'trademarks_search-viewing':
            return this.checkView(this.rights.filter(i => i.title === 'Товарные знаки'), synonym);
          case 'trademarks_requests':
            return this.checkView(this.rights.filter(i => i.title === 'Товарные знаки'), synonym);
        }
      }
    } else {
      return false;
    }
  }

  checkView(rights, synonym): boolean {
    return rights[0].data.some(item => {
      if (item.nameEng === synonym) {
        return item.value === 'false' || item.value === 'pending';
      }
    });
  }

  isDisabledUserMenu() {
    const menu = [...this.menu];
    if (!this.userAcc) {
      this.menu = this.menu.filter(i => i.name !== 'Пользователи и права');
      if (!this.envService.isCorp) {
        this.menu = this.menu.filter(i => i.name !== 'Мои права доступа');
      }
    } else if (this.userAcc && !this.administrator) {
      this.menu = this.menu.filter(i => i.name !== 'Пользователи и права' && i.name !== 'История платежей' && i.name !== 'Доверенности');
    } else if (this.userAcc && this.administrator) {
      this.menu = this.menu.filter(i => i.name !== 'Мои права доступа');
      // this.menu = menu;
    }
  }

  public logout(): void {
    const token = localStorage.getItem('currentUserIPID');
    if (token) {
      const params = new HttpParams().set('logout', 'true').set('token', token);
      this.menuService.logOut(params).pipe(takeUntil(this.onDestroy)).subscribe(data => {
        localStorage.removeItem('currentUserIPID');
        localStorage.removeItem('kontragents');
        localStorage.removeItem('currentUserData');
        this.store.dispatch(new OrganizationActions.ClearOrganizations());
        this.router.navigate(['/login']);
        localStorage.removeItem('currentUserIPID');
      });
    } else {
      window.location.reload();
      this.router.navigate(['/login']);
    }
    this.store.dispatch(new AuthActions.Logout());
    localStorage.removeItem('selectCase');
  }

  toggleLogOut() {
    // this.showDropdown = !this.showDropdown;
    this.showSearchList = false;
  }

  closeLogoutDropdown() {
    this.showDropdown = false;
  }

  openModal() {
    document.getElementsByTagName('header')[0].style.zIndex = 'initial';
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_ORDER_APPLY });
    this.showModal = true;
    // TODO: после создания матрицы прав подумать над этим
    // if (this.envService.isGardiumPro || this.envService.isX5) {
    //   this.route.queryParams.subscribe(params => {
    //     if (params.selectedOrganizationId) {
    //       this.selectedOrg = params.selectedOrganizationId;
    //       if (this.selectedOrg) {
    //         this.getRoleForCheckingRights(this.selectedOrg);
    //       }
    //     } else {
    //       this.selectedOrg = this.contragentID[0]?.id;
    //       if (this.selectedOrg) {
    //         this.getRoleForCheckingRights(this.selectedOrg);
    //       }
    //     }
    //   });
    // }
  }

  closeModal() {
    this.showModal = false;
  }

  selectOrg(org) {
    this.contragentID = this.contragentID.filter(i => i.name !== org.name);
    this.contragentID.unshift(org);
    localStorage.setItem('organizations', JSON.stringify(this.contragentID));
  }

  openModalNotification() {
    this.modalNotification = !this.modalNotification;
  }

  closeModalNotification() {
    this.modalNotification = false;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  isShowDropdown() {
    if (window.innerWidth > 992) {
      this.showDropdown = false;
    }
  }

  isDesktopShowDropdown() {
    if (window.innerWidth > 992) {
      this.showDropdown = !this.showDropdown;
    }
  }

  makeBodyHidden(): void {
    if (window.innerWidth <= 793) {
      if (this.showMenu === true) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.height = '100%';
        document.body.style.width = '100%';
      } else {
        document.body.style.overflow = 'visible';
        document.body.style.position = '';
        document.body.style.height = 'auto';
      }
    }
  }

  toSearch() {
    const currentUrl = this.router.url;
    if (!currentUrl.includes('/search/trademarks')) {
      this.router.navigate(['/search/trademarks']);
    } else if (currentUrl.includes('/search/trademarks')) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['/search/trademarks']);
      });
    }
  }

}
