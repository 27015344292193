<div class="parrent_modal">
  <div class="modal">
    <ng-container *ngIf="!isLoading && sourceData?._source; else loader">
      <div class="top-hat">
        <a class="ext_link" href="{{getExternalLink(sourceData)}}" target="_blank">
          <div class="top-hat__logo-hat"></div>
          <div class="top-hat__info">
            {{ sourceData.indexType }}
          </div>
        </a>
        <button class="top-hat__btn-close" (click)="closeModal()"></button>
        <!--        <button type="button" class="top-hat__button">Задать вопрос онлайн</button>-->
      </div>

      <div class="trademark-body">

        <div class="trademark-body__buttons_risk" *ngIf="data._risk">
          <button *ngIf="data._risk.toLowerCase() ===  'high'" class="btn-risk box_button__warning">
            Высокий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'medium'" class="btn-risk box_button__middle">
            средний риск
          </button>
          <button *ngIf="data._risk.toLowerCase() === 'low'" class="btn-risk box_button__low">
            Низкий риск
          </button>
          <button *ngIf="data._risk.toLowerCase() ===  'none'" class="btn-risk box_button__none">
            Нет риска
          </button>
          <div class="risk-hint">
            <div class="risk-tooltip"
                 *ngIf="(data._risk.toLowerCase() ===  'high') || (data._risk.toLowerCase() ===  'medium')"
            >Этот объект создает значительные риски для вашего товарного знака
            </div>
            <div class="risk-tooltip" *ngIf="data._risk.toLowerCase() === 'low' || data._risk.toLowerCase() === 'none'"
            >Этот объект создает незначительные риски для вашего товарного знака
            </div>
          </div>
        </div>

        <div class="trademark-body__header">
          Международный товарный знак <br
          *ngIf="sourceData._source.registrationString.length >= 7">№ {{sourceData._source.registrationString}}
        </div>

        <div class="trademark-body__data">
          <div *ngIf="sourceData._source.markImageFileName; else text" class="image-container">
            <a (click)="showLogoModal()">
              <img class="image-container__image" [src]="data._source.imageUrl">
            </a>
          </div>
          <div [class.open]="isShowLogoModal" class="image-container__modal" (click)="showLogoModal()">
            <img [src]="data._source.imageUrl">
          </div>
          <ng-template #text>
            <div class="text-container">
              <div class="text-container__name">
              <span>
                {{data._source.imageText}}
              </span>
              </div>
            </div>
          </ng-template>

          <div class="info-container">
            <div class="feature">
              <div class="title">Заявитель</div>
              <div class="text status-trademark">{{sourceData._source.applicantName}}</div>
              <!--              <div class="go-to-btn">Перейти к карточке правообладателя</div>-->
            </div>
            <div class="feature">
              <div class="title">Адрес для переписки</div>
              <div class="text">{{sourceData._source.correspondenceAddress}}</div>
            </div>
            <div class="feature" *ngIf="sourceData._source.applicationDate">
              <div class="title">Дата подачи заявки</div>
              <div class="text">
                {{sourceData._source.applicationDate | date:'dd.MM.yyyy'}}
              </div>
            </div>
            <div class="feature" *ngIf="sourceData._source.registrationDate">
              <div class="title">Дата регистрации</div>
              <div class="text">
                {{sourceData._source.registrationDate | date:'dd.MM.yyyy'}}
              </div>
            </div>
            <div class="feature" *ngIf="sourceData._source.expiryDate">
              <div class="title">Дата истечения</div>
              <div class="text">
                {{sourceData._source.expiryDate | date:'dd.MM.yyyy'}}
              </div>
            </div>
            <div class="feature" *ngIf="sourceData._source.disclaimer" style="margin-bottom: 0">
              <div class="title">Неохраняемые элементы</div>
              <div class="text">{{sourceData._source.disclaimer}}</div>
            </div>
            <div class="feature">
              <div class="title">Страны действия</div>
              <app-countries-list-view
                [countries]="getCountries(sourceData)"
              ></app-countries-list-view>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <app-accordion
          [title]="'Классы МКТУ'"
          [count]="sourceData && sourceData._source && sourceData._source.goodsServices && sourceData._source.goodsServices.length"
          [isOpen]="true">
          <ul>
            <li *ngFor="let classes of getMktuClasses()">
              <div class="mktu-class">
                <div class="class-number">{{classes.classNumber}} <span>класс</span></div>
                <div class="class-description"
                     onclick="this.className = (this.className === 'class-description' ? 'class-description-long' : 'class-description')">{{classes.gsDescription}}</div>
              </div>
            </li>
          </ul>
          <button *ngIf="sourceData._source.goodsServices.length > 3 && viewClassesCount === 'short'"
                  (click)="showAllClasses()" class="show-all-btn">Показать
            еще {{sourceData._source.goodsServices.length - 3}}</button>
        </app-accordion>

      </div>
    </ng-container>
  </div>

  <div (click)="closeModal()" class="overlay-space"></div>
</div>

<div class="page-overlay" id="modal-overlay"></div>

<ng-template #loader>
  <div class="loader-wrapper">
    <app-ls-emb-loader></app-ls-emb-loader>
  </div>
</ng-template>
