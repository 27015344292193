<div *ngIf="sliderCards" class="service-item__slider slider">
  <drag-scroll #slider
               style="height: 220px"
               (reachesLeftBound)="leftBoundStat($event)"
               (reachesRightBound)="rightBoundStat($event)"
  >
    <div drag-scroll-item class="slider-item__wrap" *ngFor="let service of sliderCards">
      <div (click)="toServiceDetails(service.id)" class="slider-item" [class.disabled]="X5">
        <p>{{ service.name }}</p>
      </div>
    </div>
  </drag-scroll>
  <div *ngIf="sliderCards.length > 3">
    <button *ngIf="btnPrevShow || !btnPrevShow && !btnNextShow "
            class="service-item__slider-btn service-item__slider-btn--prev"
            (click)="moveLeft()">
      <svg width="19" height="16" viewBox="0 0 19 16">
        <path
          d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM19 7L1 7V9L19 9V7Z"/>
      </svg>
    </button>
  </div>
  <div *ngIf="sliderCards.length > 3">
    <button *ngIf="btnNextShow || !btnPrevShow && !btnNextShow"
            class="service-item__slider-btn service-item__slider-btn--next"
            (click)="moveRight()">
      <svg width="19" height="16" viewBox="0 0 19 16">
        <path
          d="M18.7071 8.70711C19.0976 8.31658 19.0976 7.68342 18.7071 7.29289L12.3431 0.928932C11.9526 0.538408 11.3195 0.538408 10.9289 0.928932C10.5384 1.31946 10.5384 1.95262 10.9289 2.34315L16.5858 8L10.9289 13.6569C10.5384 14.0474 10.5384 14.6805 10.9289 15.0711C11.3195 15.4616 11.9526 15.4616 12.3431 15.0711L18.7071 8.70711ZM0 9H18V7H0V9Z"/>
      </svg>
    </button>
  </div>


</div>
