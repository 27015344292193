import { Injectable } from '@angular/core';
import { CorpSearchApiService } from './corp-search-api.service';
import { AsyncDataLoader } from '../../../../../app-ipid/src/app/shared/helpers/async-data-loader/async-data-loader';

@Injectable({
  providedIn: 'root'
})
export class CorpSearchRepeatService {

  searchParams = new AsyncDataLoader(this.loadSearchParams);

  constructor(
    private readonly corpSearchApiService: CorpSearchApiService,
  ) {
  }

  private searchID: string;

  private loadSearchParams() {
    return this.corpSearchApiService.getSearchDetailById(this.searchID);
  }

  load(searchID: string) {
    this.searchID = searchID;
    this.searchParams.load();
  }

}
