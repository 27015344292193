import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trimString } from '@web/utils/trim-string.util';

@Component({
  selector: 'app-ui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  trimmedTitle = '';
  fullTitle = '';

  @Input()
  set title(value: string) {
    this.fullTitle = value;
    this.trimmedTitle = trimString(value, 30);
  }

  @Input() prompt = '';
}
