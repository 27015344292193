import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { TrademarkState } from '../../store/trademark.state';
import { ViewSelectSnapshot } from '@ngxs-labs/select-snapshot';
import { TrademarkActions } from '../../store/trademark.actions';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TrademarkDraft, TariffResult } from '../../types/trademark.types';
import { OrganizationInterface } from '@web/core/models/organization.interface';

@Component({
  selector: 'app-trademark-payment',
  templateUrl: './trademark-payment.component.html',
  styleUrls: ['./trademark-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrademarkPaymentComponent {
  @ViewSelectSnapshot(TrademarkState.draft) draft: TrademarkDraft | null;
  @ViewSelectSnapshot(TrademarkState.tariffResult) tariffResult: TariffResult;
  @Dispatch() backStep = () => new TrademarkActions.BackStep();
  @Dispatch() paymentCompletion = (payer: OrganizationInterface) => new TrademarkActions.PaymentCompletion(payer);
}
