<section *ngIf="caseData; else loader" class="section-position section-position__color-bg section-position--padding">
  <div class="container container-top">
    <a [routerLink]="['/cases']"
       class="back-link">
              <span>
                <svg width="12" height="8" viewBox="0 0 21 16">
                  <g clip-path="url(#clip0)">
                   <path
                     d="M0.292893 7.2929C-0.097631 7.68343 -0.0976311 8.31659 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8.00001L8.07107 2.34315C8.46159 1.95263 8.46159 1.31947 8.07107 0.92894C7.68054 0.538416 7.04738 0.538416 6.65685 0.92894L0.292893 7.2929ZM21 7.00001H1V9.00001H21V7.00001Z"/>
                  </g>
                  <defs>
                    <clipPath id="clip0">
                    <rect width="21" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </span>
      Назад
    </a>
    <br>
    <div class="container-top__heading">
      <div>
        <span>{{caseData.details.description}}, дело №{{caseData.number}}</span>
        <h1>{{caseData.details.title}}</h1>
      </div>
      <div *ngIf="isDraft"
           (click)="deleteTask(caseData.ID)"
           class="delete_draft">
        Удалить черновик
      </div>
    </div>
    <app-item-tracks
      [$tracks]="$tracks"
      [infoItem]="caseData.status"
    >
    </app-item-tracks>
    <!-- end tracks-->
  </div>
</section>

<section *ngIf="caseData" class="section-position  section-position__color-bg section-position--padding control__wrap">
  <div class="container control">

    <button class="control__btn" (click)="changeTab('events')"
            [class.control__btn--active]="activeTab === 'events'"
    >События<span class="control__count">{{$events?.length ? $events?.length : ''}}</span>
    </button>
    <button class="control__btn" (click)="changeTab('docs')"
            [class.control__btn--active]="activeTab === 'docs'"
            [disabled]="files?.length === 0"
    >Документы<span class="control__count">{{files?.length}}<span *ngIf="!noObjects" class="control__division"><span
      class="control__division--red"></span></span></span>
    </button>
    <button class="control__btn" (click)="changeTab('info')"
            [class.control__btn--active]="activeTab === 'info'"
    >Информация о заявке<span class="control__count"></span>
    </button>

  </div>
</section>

<!--info -->
<section *ngIf="caseData" class="section-position section-position--main">
  <div class="container container--main">

    <!-- События -->
    <div *ngIf="activeTab === 'events' && $events && !noObjects" class="events-steps">
      <app-item-events
        [$events]="$events"
      >
      </app-item-events>
    </div>
    <!-- Если это черновик, то вместо "События", отображается (переход к заявке) -->
    <div
      *ngIf="activeTab === 'events' && !$events?.length"
      class="nothing">
      <img [src]="logo">
      <p [innerHTML]="getStepsText()"></p>
      <button *ngIf="!X5 &&((caseData.lastStage !== 4 && !(caseData.type === 'monitoring' && caseData.lastStage === 3)))
      && ((caseData.lastStage !== 5 && caseData.lastStage !== 7 && caseData.type !== 'trademark') || (caseData.type === 'contract' && caseData.lastStage !== 7) || ((caseData.type === 'trademark' && ((!envService.isAlfa && !envService.isGardiumOnline) || (((envService.isAlfa || envService.isGardiumOnline) && caseData.lastStage !== 5)))) || caseData.type === 'patent'))"
              (click)="sendDraft(caseData.ID, caseData.type)" class="btn-blue">
        {{getStepsData()}}</button>
<!--      <button *ngIf="((caseData.lastStage !== 4 && !(caseData.type === 'monitoring' && caseData.lastStage === 3))-->
<!--      || caseData.lastStage === 4 && caseData.type === 'trademark' && (envService.isAlfa || envService.isGardiumOnline))-->
<!--      && ((caseData.lastStage !== 5 && caseData.lastStage !== 7 && caseData.type !== 'trademark') || (caseData.type === 'contract' && caseData.lastStage !== 7) || ((caseData.type === 'trademark' && ((!envService.isAlfa && !envService.isGardiumOnline) || (((envService.isAlfa || envService.isGardiumOnline) && caseData.lastStage !== 5)))) || caseData.type === 'patent'))"-->
<!--              (click)="sendDraft(caseData.ID, caseData.type)" class="btn-blue">-->
<!--        {{getStepsData()}}</button>-->
    </div>

    <!-- Документы -->
    <div *ngIf="activeTab === 'docs' && files?.length && !noObjects;" class="docs">
      <app-item-files
        [files]="files"
      >
      </app-item-files>
    </div>

    <!-- Информация о заявке -->
    <div *ngIf="activeTab === 'info'" class="info-table">
      <app-item-info
        style="width: 100%;"
        [infoItem]="caseData"
      >
      </app-item-info>
    </div>

  </div>
</section>

<app-expert *ngIf="caseData">
</app-expert>

<ng-template #loader>
  <app-spinner style="height: 300px" class="spinner-full"></app-spinner>
</ng-template>
