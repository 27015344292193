import {Component, Input, OnInit} from '@angular/core';
import { CasesService } from '../../cases.service';
import {DownloadFilesService} from '../../../../../../../shared/services/download-files.service';

@Component({
  selector: 'app-tabs-types',
  templateUrl: './tabs-types.component.html',
  styleUrls: ['./tabs-types.component.scss']
})
export class TabsTypesComponent implements OnInit {

  showMore = false;
  showServicesList = false;
  open = false;

  attention;
  showOptions: boolean;
  attentionStep1: boolean;
  noObjects = false;

  @Input() point;
  isEventAgree;

  constructor(
    private casesApi: CasesService,
    readonly downloadFiles: DownloadFilesService
  ) {
  }

  ngOnInit(): void {
  }

  eventAgree() {
    const params = {
      eventID: this.point.eventID,
      eventType: this.point.Type
    };
    this.casesApi.eventAgree(params)
      .subscribe(eventAgree => {
        this.isEventAgree = true;
        console.log(eventAgree);
      });
  }

}
