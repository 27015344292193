import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { HttpService } from '../../../shared/services/http.service';
import { RequestCheckLabelService } from '../request-check-label.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RequestCheckLabelApiService } from '../request-check-label.api.service';
import { debounceTime, mergeMap, Observable, startWith } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export interface Tag {
  code: string;
  value: string;
}

@Component({
  selector: 'app-label-owner',
  templateUrl: './label-owner.component.html',
  styleUrls: ['./label-owner.component.scss']
})
export class LabelOwnerComponent implements OnInit {

  @Input() organizations;

  fileError = ''; // ошибка загрузки файла
  cardImageBase64: string; // картинка в формате base64
  isUploadingImage = false;
  showOwner = false;
  form: FormGroup;
  tag = '';
  dataTags = [];
  ownerName = '';
  addOnBlur = true;

  // MAT-CHIPS
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  allTags: any[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

  constructor(
    private httpService: HttpService,
    readonly requestCheckLabelService: RequestCheckLabelService,
    private requestCheckLabelApiService: RequestCheckLabelApiService,
    private fb: FormBuilder
  ) {

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(500),
      mergeMap((tag: string | null) => tag ? this._filter(tag) : []));

  }

  ngOnInit(): void {
    if (!this.requestCheckLabelService.labels.length) {
      this.initEmptyForm();
    } else {
      this.setForm();
      this.ownerName = this.requestCheckLabelService.owner.name;
    }

    if (this.organizations?.length) {
      this.organizations.forEach(el => el.checked = false);
    }
  }

  /**
   * Обработчик события прикрипления chip-элемента.
   * @param {MatChipInputEvent} event - Объекст события.
   */
  public add(event: MatChipInputEvent): void {
    const value = this.validateChipInput(event.value);
    if (value) {
      const tagsLength = this.requestCheckLabelService.tags.length;
      this.requestCheckLabelApiService.createTag({ tag: value })
        .pipe(filter(() => this.requestCheckLabelService.tags.length === tagsLength))
        .subscribe((data: any) => this.updateTagsList({ code: data.code, value }));
    }
    event.chipInput.clear();
    this.tagCtrl.setValue(null);
  }

  /**
   * Обработчик события удаления chip-элемента.
   * @param {Tag} item - Удаляемый элемент.
   */
  public remove(item: Tag): void {
    const index = this.requestCheckLabelService.tags.indexOf(item);
    const indexCode = this.requestCheckLabelService.tagsCodes.indexOf(item.code);

    if (index >= 0) {
      this.requestCheckLabelService.tags.splice(index, 1);
      this.requestCheckLabelService.tagsCodes.splice(indexCode, 1);
    }
  }

  /**
   * Обработчик события выбора элемента в автокомплите.
   * @param {MatAutocompleteSelectedEvent} event - Объект события.
   */
  public selected(event: MatAutocompleteSelectedEvent): void {
    this.updateTagsList({ ...event.option.value });
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): Observable<any> {
    const filterValue = value.toLowerCase();
    const params = this.requestCheckLabelService.tagsCodes.length ? {tag: filterValue, except: this.requestCheckLabelService.tagsCodes.join()} : {tag: filterValue};

    return this.requestCheckLabelApiService.getTags(params).pipe(map((data: any) => {
      return data.tags.map((t) => {
        return {
          value: t.name,
          code: t.code
        };
      });
    }));
  }

  /**
   * Событие загрузки этикеток
   * @param fileInput: картинка
   */
  onImageUpload(fileInput: any) {
    if (this.fileError?.length) {
      this.fileError = '';
    }
    if (fileInput.target.files?.length) {
      let files = [...fileInput.target.files];
      files = files.filter(el => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxSize = 12000000;
        if (el.size <= maxSize && _.includes(allowedTypes, el.type)) {
          return el;
        }
        if (el.size > maxSize) {
          this.fileError = 'Максимальный размер файла ' + maxSize / 1000000 + 'Mb';
        }
        if (!_.includes(allowedTypes, el.type)) {
          this.fileError = 'Недопустимый тип файла';
        }
      });
      if (files.length) {
        files.forEach(el => {
          this.isUploadingImage = true;
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = rs => {
              this.cardImageBase64 = e.target.result;
              const imageBody = {
                body: {
                  name: el.name,
                  image: this.cardImageBase64
                }
              };
              this.httpService.post({path: 'request/image/add', body: imageBody})
                .subscribe((data: any) => {
                  if (data) {
                    this.requestCheckLabelService.labels.push(data.path);
                    this.isUploadingImage = false;
                    this.requestCheckLabelService.paymentSum = this.requestCheckLabelService.rate.sumDiscount * this.requestCheckLabelService.labels.length;
                  }
                });
            };
          };
          reader.readAsDataURL(el);
        });
      }
    }
    fileInput.target.value = '';
  }

  /**
   * Удаление изображения
   * @param index: индекс
   * @param array: массив изображений
   */
  removeImage(index, array) {
    array.splice(index, 1);
  }

  getOwnerName(): string  {
    this.organizations.some(obj => {
      if (obj.checked) {
        this.ownerName = obj.name;
      }
    });
    return this.ownerName;
  }

  /**
   * событие добавления класса МКТУ
   * @param selectedClass: объект добавляемого класса
   */
  eventApplyClasses(selectedClass): void {
    if (this.requestCheckLabelService.mktu.every(el => el.number !== selectedClass.number)) {
      this.requestCheckLabelService.mktu.push(selectedClass);
      this.requestCheckLabelService.optionsMKTU.selectedMktu.push(selectedClass.number);
    }
  }

  /**
   * событие удаления класса МКТУ
   * @param deletedClass: объект удаляемого класса
   */
  eventDeletedClass(deletedClass): void {
    // TODO: логика удаления
    let deleteIndex;
    this.requestCheckLabelService.mktu.forEach((item, index) => {
      if (item.number === deletedClass.number) {
        deleteIndex = index;
      }
    });
    if (deleteIndex || deleteIndex === 0) {
      this.requestCheckLabelService.mktu.splice(deleteIndex, 1);
    }
  }

  setValue(checked?: boolean, obj?: any) {
    this.requestCheckLabelService.product = this.form.value.product;
    this.requestCheckLabelService.comment = this.form.value.comment;
    if (checked) {
      this.organizations.forEach(el => {
        if (el.id === obj.id) {
          this.requestCheckLabelService.owner = {
            inn: obj?.TIN,
            ogrn: obj?.PSRN,
            address: obj?.legalAdress,
            name: obj?.name,
            shortName: obj?.shortName || '',
            director: obj?.director,
          };
        }
      });
    }
  }

  /**
   * Инициализация формы для первого шага
   */
  initEmptyForm() {
    this.form = this.fb.group({
      owner: new FormControl(''),
      tag: new FormControl(''),
      product: new FormControl(''),
      comment: new FormControl(''),
    });
  }

  /**
   * Заполнение формы из черновика
   */
  setForm() {
    this.form = this.fb.group({
      owner: new FormControl(this.requestCheckLabelService.owner),
      tag: new FormControl(this.requestCheckLabelService.tags),
      product: new FormControl(this.requestCheckLabelService.product),
      comment: new FormControl(this.requestCheckLabelService.comment),
    });
  }

  getDynamicMarginForComment() {
    const searchHeight = document.getElementsByClassName('main__form')[0];
    if (searchHeight) {
      switch (searchHeight.clientHeight) {
        case 62:
          return {'margin-top': '0'};
        case 112:
          return {'margin-top': '50px'};
        case 162:
          return {'margin-top': '100px'};
      }
    }
  }

  private validateChipInput(value: string): string {
    return (value || '').trim();
  }

  private updateTagsList(data: Tag): void {
    this.requestCheckLabelService.tags.push({ code: data.code, value: data.value });
    this.requestCheckLabelService.tagsCodes.push(data.code);
  }
}
