export namespace UserPhoneActions {
  export class SetCurrentBrand {
    public static readonly type = '[User Phone State] Set Current Brand';
    constructor(public readonly payload: string) { }
  }

  export class SendUserPhoneCode {
    public static readonly type = '[User Phone State] Send User Phone Code';
  }

  export class SendUserPhoneCodeSuccess {
    public static readonly type = '[User Phone State] Send User Phone Code Success';
    constructor(public readonly payload: { answer: string }) { }
  }

  export class SendUserPhoneCodeFail {
    public static readonly type = '[User Phone State] Send User Phone Code Fail';
  }

  export class RemoveSuccess {
    public static readonly type = '[User Phone State] Remove Success';
    constructor(public readonly payload: boolean) { }
  }

  export class CheckUserPhoneCode {
    public static readonly type = '[User Phone State] Check User Phone Code';
  }

  export class CheckUserPhoneCodeSuccess {
    public static readonly type = '[User Phone State] Check User Phone Code Success';
    constructor(public readonly payload: { answer: string }) { }
  }

  export class CheckUserPhoneCodeFail {
    public static readonly type = '[User Phone State] Check User Phone Code Fail';
  }

  export class ResetUserPhoneForm {
    public static readonly type = '[User Phone State] Reset User Phone Form';
  }
}