import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '@ui/filters/services/filter.service';

@Pipe({
  name: 'filter',
  pure: true
})
export class FilterPipe implements PipeTransform {
  constructor(private filterService: FilterService) {}

  transform(value: any[], filter: Record<string, any>, name: string | string[]): any[] {
    if (value) {
      if (typeof name === 'string') {
        return value.filter(item => this.filterService.getFn(name)(item, filter[name]));
      } else {
        return value.filter(item => {
          return name.reduce((result, filterName) => {
            return result && this.filterService.getFn(filterName)(item, filter[filterName]);
          }, true);
        });
      }
    }
    return value;
  }
}
