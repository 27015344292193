<div class="alfa-landing__wrap" *ngIf="risk">
  <section class="alfa-landing container">

    <ng-container *ngIf="risk.risk.opposedObject; else noObject">
      <p class="risk-modal__criticality"
         [class.risk-modal__criticality--critical]="getRiskNameByLevel() === 'Критичный риск'"
         [class.risk-modal__criticality--high]="getRiskNameByLevel() === 'Высокий риск'"
         [class.risk-modal__criticality--middle]="getRiskNameByLevel() === 'Средний риск'"
         [class.risk-modal__criticality--recommendation]="getRiskNameByLevel() === 'Рекомендация'"
      >{{getRiskNameByLevel()}}</p>

      <h1 class="alfa-landing__title">{{risk.risk.description}}</h1>
      <p class="alfa-landing__desc">{{riskDescription.threat}}</p>
      <div class="alfa-landing__risk-wrap">
        <div class="alfa-landing__risk alfa-landing__risk--source">
          <h3>Источник риска</h3>
          <p>
            {{getObjNameByIndex(risk.risk.opposedObject._index)}}
            <span>{{risk.risk.opposedObject.name}}</span>
          </p>
          <div class="alfa-landing__img-wrap" *ngIf="opposedImgUrl">
            <img [src]="opposedImgUrl" alt="" class="" width="80" height="60">
          </div>
        </div>
        <div class="alfa-landing__risk alfa-landing__risk--object">
          <h3>Ваш объект</h3>
          <p>
            {{getObjNameByIndex(risk.index)}}
            <span>{{risk.representation}}</span>
          </p>
          <div class="alfa-landing__img-wrap" *ngIf="imgUrl">
            <img [src]="imgUrl" alt="" class="" width="80" height="60">
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #noObject>
      <p class="risk-modal__criticality"
         [class.risk-modal__criticality--critical]="getRiskNameByLevel() === 'Критичный риск'"
         [class.risk-modal__criticality--high]="getRiskNameByLevel() === 'Высокий риск'"
         [class.risk-modal__criticality--middle]="getRiskNameByLevel() === 'Средний риск'"
         [class.risk-modal__criticality--recommendation]="getRiskNameByLevel() === 'Рекомендация'"
      >{{getRiskNameByLevel()}}</p>

      <h1 class="alfa-landing__title">{{risk.risk.description}}</h1>

      <div class="alfa-landing__attention">
        {{riskDescription.threat}}
      </div>

      <div class="alfa-landing__subtext-wrap">
        <p class="alfa-landing__subtext">{{getObjNameByIndex(risk.index)}}</p>
        <p class="alfa-landing__subtext alfa-landing__subtext--bold">{{risk.representation}}</p>
      </div>
    </ng-template>

      <h2 class="alfa-landing__subtitle">{{riskDescription.recommendation}}</h2>

      <div class="alfa-landing__btn-wrap">
        <button class="btn" (click)="toRegistrationPage()">Зарегистрироваться</button>
        <button class="btn btn--attr" (click)="toLoginPage()">Войти</button>
      </div>

      <p class="alfa-landing__subdesc">Войдите или зарегистрируйтесь, чтобы получить полную информацию о риске, его источнике, а также рекомендации по устранению.</p>

  </section>
</div>

