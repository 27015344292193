import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import mktuData from '../../../../../../../../app-search/src/app/pages/ip-id-search/json-data/mktu';

@Component({
  selector: 'app-mktu-modal',
  templateUrl: './mktu-modal.component.html',
  styleUrls: ['./mktu-modal.component.scss']
})
export class MktuModalComponent implements OnInit {

  constructor() { }

  @Input() selectedMKTU;
  @Output() modalEvent = new EventEmitter();
  @Output() closeModalSuccess = new EventEmitter();

  showMassive = [];
  mktuData = JSON.parse(JSON.stringify(mktuData));

  ngOnInit(): void {
  }

  showDescriptionClick(code) {
    const index = this.showMassive.findIndex(item => item === code);
    if (index === -1) {
      this.showMassive.push(code);
    } else {
      this.showMassive.splice(index, 1);
    }
  }

  checkedClass(code) {
    const index = this.selectedMKTU.findIndex(item => item === code);
    if (index === -1) {
      this.selectedMKTU.push(code);
    } else {
      this.selectedMKTU.splice(index, 1);
    }
  }

  isChecked(code) {
    return this.selectedMKTU.includes(code);
  }

  isShowDescr(code) {
    return this.showMassive.includes(code);
  }

  closeModal() {
    this.modalEvent.emit();
  }

  closeSuccess() {
    this.closeModalSuccess.emit(this.selectedMKTU);
    this.closeModal();
  }

}
