<section class="section-position">
  <div class="section-position__wrap">
    <div class="monitoring-modal__wrapper">

      <ng-container *ngIf="!monitoringAddService.isLoading">
        <div *ngIf="stage === 1">
          <app-add-object-create-request ></app-add-object-create-request>
        </div>
        <div *ngIf="stage === 2">
          <app-add-object-pay ></app-add-object-pay>
        </div>
        <div *ngIf="stage === 3" class="add-object add-object__content add-object__content--pay">
          <div class="info-plate" *ngIf="paymentType === 'online'">
            <div class="info-plate__img"><img [src]="logo" alt="alt"></div>
            <p class="info-plate__text">Как только ваша оплата будет обработана,<br class="hide-up-to-md">
              мы переместим вашу заявку из черновиков в активные дела.</p>
            <a class="btn" [class.disabled]="X5 || !monitoring" routerLink="/monitoring">Перейти в мониторинг</a>
          </div>
          <div class="info-plate" *ngIf="paymentType === 'cash' || paymentType === 'act'">
            <div class="info-plate__img"><img [src]="logo" alt="alt"></div>
            <p class="info-plate__text">Счет сформирован и отправлен вам на почту {{userEmail}}.<br> Рекомендуем оплатить
              счет в течение 5 дней.</p><a class="btn" routerLink="/cases" (click)="goToApplication()">Перейти к заявке</a>
          </div>
        </div>
      </ng-container>


      <div *ngIf="monitoringAddService.isLoading" class="add-object add-object__content add-object__content--loading" >
        <div class="loader"></div>
      </div>
    </div>
  </div>
  <app-expert></app-expert>
</section>
