import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {
  CorpSearchApiService,
  ICorpSearchApiByImageParams,
  ICorpSearchApiByImageResult,
  ICorpSearchApiByNameResult,
} from './corp-search-api.service';
import {CorpSearchSaveReportService} from './corp-search-save-report.service';
import {ISearchOptions} from '../../../../../app-search/src/app/models/search.interfaces';
import { HttpService } from '../../../../../app-ipid/src/app/shared/services/http.service';
import { SearchService } from '../../../../../app-search/src/app/services/search.service';
import {Router} from '@angular/router';

@Injectable()
export class CorpSearchByImageService {
  constructor(
    private location: Location,
    private corpSearchApiService: CorpSearchApiService,
    private corpSearchSaveService: CorpSearchSaveReportService,
    private httpService: HttpService,
    private searchService: SearchService,
    private router: Router,
  ) {
  }

  isLoading = false;

  fileSelected = false;

  isLoadImage = false;

  data: ICorpSearchApiByImageResult;

  searchOptions: ISearchOptions = {
    designation: '',
    mktuList: [],
    okvedList: [],
    countriesList: [],
  };


  public saveSearch(params: ICorpSearchApiByImageParams, result: ICorpSearchApiByNameResult, image: string) {
    this.corpSearchApiService.objectsLoading = true;
    this.corpSearchApiService
      .saveSearchByImage(params, result, image)
      .subscribe((data) => {
      if (data) {
        this.corpSearchApiService.objectsLoading = false;
        this.corpSearchSaveService.setSearchID(data.searchID);
        this.router.navigate(['search/trademarks'], {
          queryParams: {searchID: data.searchID, parse: true},
          queryParamsHandling: 'merge'
        });
      }
    });
  }

  uploadImage(payload, data, image) {
    if (image?.name) {
      const formData: FormData = new FormData();
      formData.append('file', image, image.name);
      this.httpService.post({path: 'request/file/add', body: formData, isFile: true})
        .subscribe((value: any) => {
          if (value) {
            this.saveSearch(payload, data, value.path);
          }
        });
    } else {
      this.saveSearch(payload, data, image);
    }
  }

  pipeTrademarks(data) {
    if (data && data.hits && data.hits.hits) {
      data.hits.hits.forEach((item) => {
        let id = item._source.registrationString;
        // todo: проверять есть ли картинка
        if (item._index === 'rutmap' && item._source.applicationString) {
          id = item._source.applicationString;
        }
        if (id && item._source.markImageFileName) {
          item._source.imageUrl = this.searchService.getImgUrl(
            item._index,
            parseInt(id, 10),
            item._source.markImageFileName,
            id
          );
        }
      });
    }
    return data;
  }
}
