import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { NavigationPageType } from '../enums/navigation-page-type';
import { NavigationService } from '../services/navigation.service';
import { StorateType } from '../enums/storate-type';
import { StrorageService } from '../../../../../shared/services/storage-service';
import { Store } from '@ngxs/store';
import { AuthActions } from '@web/core/states/auth.actions';

@Injectable()
export class HttpIntercept implements HttpInterceptor {
  constructor(
    private _strorageService: StrorageService,
    private _navigationService: NavigationService,
    private store: Store
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          console.log(error, 'from interceptor');
          if (error.status === 401) {
            console.log(
              '%c HTTP ERROR INTERCEPTED',
              'color: coral; font-weight: bold'
            );

            this.store
              .dispatch(new AuthActions.Logout())
              .pipe(take(1))
              .subscribe(() => {
                this._navigationService.navigate(NavigationPageType.Login);
              });
          }
          return throwError(error);
        } else {
          console.log(
            '%c HTTP ERROR INTERCEPTED',
            'color: coral; font-weight: bold'
          );
          return throwError(error);
        }
      })
    );
  }
}
