import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { AuthState } from "@web/core/states/auth.state";

declare var window: any;

@Injectable({ providedIn: 'root' })
export class IntercomEventsService {
  constructor(private readonly store: Store) { }

  public push(event: Partial<IntercomEventPayload>): void {
    if (!window?.dataLayer) {
      console.warn('Intercom dataLayer is undefined');
    }
    // TODO: Временное прешение, пока не переделали запросы;
    let userId = null;
    const user = this.store.selectSnapshot(AuthState.getUser);
    if (user?.userID) userId = user.userID;
    if (user?.userId) userId = user.userId;
    window?.dataLayer?.push?.({
      ...event,
      userID: userId ? userId : null,
    });
  }
}

export interface IntercomEventPayload {
  event: string;
  item_type: string;
  typeIP: string;
  applyType: string;
  applyRate: string;
  service_name: string;
  totalPrice: number;
  service_id: string;
  button_name: string;
  mktu: string;
  tariff: any;
  price: string;
  okved: string[];
  query: string;
  itemType: string;
  item_count: number;
  from: string;
  to: string;
}

export const enum INTERCOM_EVENT_NAME {
  SELECT_INN = 'selectINN',
  PRINT_SEARCH = 'printSearch',
  PRINT_SEARCH_FOCUS_OUT = 'printSearchFocusOut',
  CLICK_ORDER_APPLY = 'clickOrderApply',
  CLICK_CHECK_PROBABILITY_CAROUSEL = 'clickCheckProbabilityCarousel',
  CLICK_CHECK_PROBABILITY_HIT = 'clickCheckProbabilityHit',
  CLICK_RTZ_HIT = 'clickRTZHit',
  CLICK_ASK_PP = 'clickAskPP',
  SELECT_IP_TYPE = 'selectIPtype',
  OPEN_ITEM = 'open_item',
  PRINT_INN_RESULTS = 'printINNresults',
  SELECT_ITEM = 'select_item',
  CLICK_PAYMENT_ONLINE = 'clickPaymentOnline',
  FORM_PAYMENT_ONLINE_SUCCESS = 'formPaymentOnlineSuccess',
  FORM_PAYMENT_BILL_SUCCESS = 'formPaymentBillSuccess',
  CLICK_PAYMENT_BILL = 'clickPaymentBill',
  FORM_TM_RATE_SENT = 'formTmRateSent',
  CLICK_MIN = 'clickMin',
  CLICK_OPT = 'clickOpt',
  CLICK_MAX = 'clickMax',
  SELECT_TARIF = 'selectTariff',
  APP_REGISTER = 'appRegister',
  FORM_TM_APPLY_SENT = 'formTmApplySent',
  FORM_TM_CLASSES_SENT = 'formTmClassesSent',
  FORM_CONTRACT_SIDE_APPLY_SENT = 'formContractSideApplySent',
  FORM_CONTRACT_TOPIC_APPLY_SENT = 'formContractTopicApplySent',
  FORM_CONTRACT_TYPE_APPLY_SENT = 'formContractTypeApplySent',
  FORM_SERVICE_SENT = 'formServiceSent',
  SEARCH_RESULT_NO_UNIQUE = 'searchResultNoUnique',
  SEARCH_RESULT_UNIQUE = 'searchResultUnique',
  OPEN_IP_CARD = 'openIPcard',
  CLICK_SCORE_LOW = 'clickScoreLow',
  CLICK_SCORE_HIGH = 'clickScoreHigh',
  PRINT_SEARCH_INN = 'printSearchInn',
  CLICK_ACTIVITY = 'clickActivity',
  SELECT_MKTU = 'selectMktu',
  SELECT_OKVD = 'selectOkvd',
  CLICK_CHOISE_OKVD = 'clickСhoiceOkvd',
  CLICK_CHOISE_MKTU = 'clickСhoiceMktu',
  CLICK_CHOISE_INN = 'clickСhoiceInn',
  CLICK_CHOISE_MKTU_OKVD_MOB = 'clickChoiceMktuOkvdMob',
  FORM_USER_LOGIN = 'formUserLogin',
  FORM_PASSWORD_RESET = 'formPasswordReset',
  FORM_USER_REGISTERED = 'formUserRegistered',
  SENT_SMS = 'sentSMS',
  SMS_CHECKED_OK = 'SMScheckedOK',
  ADD_ITEM_TO_REPORT = 'add_item_to_report',
  CLICK_SERVICE_CALL2ACTION = 'click_service_call2action',
  GOTO_ORDER = 'goto_order',
  FORM_SVEDENIYA_SENT = 'formSvedeniyaSent',
};
