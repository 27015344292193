<div class="info-modal">
  <div class="info-modal__container">
    <div class="info-modal__block">
      <!--<p class="info-modal__icon">Поиск 232 из 1000 сохранен</p>-->
      <a routerLink="/history">{{mobile ? 'История' : 'Перейти к истории поисков'}}</a>
      <button
        *ngIf="mobile"
        type="button"
        [class.is-active]="corpSearchSaveService.countSelected"
        [class.is-disabled]="!corpSearchSaveService.countSelected || checkLoading()"
        [class.is-loading]="corpSearchSaveService.addReportLoading"
        (click)="onAddToReport()">Добавить в отчет
        <app-spinner color="white" class="sm" *ngIf="corpSearchSaveService.addReportLoading"></app-spinner>
      </button>
    </div>

    <div *ngIf="!mobile" class="info-modal__block" style="margin-right: 28%">
      <p>Вы можете выбрать интересующие вас объекты и сохранить в отчет</p>
      <button
        *ngIf="checkLoading()"
        type="button"
        [class.is-active]="corpSearchSaveService.countSelected"
        [class.is-disabled]="!corpSearchSaveService.countSelected"
        [class.is-loading]="corpSearchSaveService.addReportLoading"
        (click)="onAddToReport()">
        <app-spinner style="top: 2px; right: 5px" color="white" class="sm" *ngIf="checkLoading()"></app-spinner>
      </button>
      <button
        *ngIf="!checkLoading()"
        type="button"
        [class.is-active]="corpSearchSaveService.countSelected"
        [class.is-disabled]="!corpSearchSaveService.countSelected"
        [class.is-loading]="corpSearchSaveService.addReportLoading"
        (click)="onAddToReport()">Добавить в отчет
        <app-spinner color="white" class="sm" *ngIf="corpSearchSaveService.addReportLoading"></app-spinner>
      </button>
    </div>
  </div>
</div>
