import { Injectable } from '@angular/core';
import { StorateType } from '../enums/storate-type';
import { DictionaryService } from './dictionary-service';

@Injectable({providedIn: 'root'})

// работает с localStorage
export class StrorageService {

  constructor(private dictionaryService: DictionaryService) {

  }

  public addStorageValue(storateType: StorateType, value: string): void {
    const dictValue = this.dictionaryService.storageDictionary.getItem(storateType);
    if (dictValue == null) {
      return;
    }

    localStorage.setItem(dictValue, value);
  }

  public removeValue(storateType: StorateType): void {
    const dictValue = this.dictionaryService.storageDictionary.getItem(storateType);
    if (dictValue == null) {
      return;
    }

    localStorage.removeItem(dictValue);
  }

  public getStorageItem(storateType: StorateType): string {
    const dictValue = this.dictionaryService.storageDictionary.getItem(storateType);
    if (dictValue == null) {
      return;
    }

    return localStorage.getItem(dictValue);
  }
}
