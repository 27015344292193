import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-dashboard',
  templateUrl: './open-dashboard.component.html',
  styleUrls: ['./open-dashboard.component.scss']
})
export class OpenDashboardComponent implements OnInit {
  disabled = true;

  constructor() { }

  ngOnInit(): void {
  }




}
