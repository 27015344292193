import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './components/filters/filters.component';
import { FilterDaterangeComponent } from './components/filter-daterange/filter-daterange.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UiSharedModule } from '@ui/shared/ui-shared.module';
import { FiltersContainerComponent } from './containers/filters-container/filters-container.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FilterService } from '@ui/filters/services/filter.service';
import { HideEmptyDirective } from './directives/hide-empty.directive';

@NgModule({
  declarations: [
    FiltersComponent,
    FilterDaterangeComponent,
    FiltersContainerComponent,
    FilterPipe,
    HideEmptyDirective,
  ],
  providers: [FilterService],
  imports: [CommonModule, FormsModule, BsDatepickerModule, UiSharedModule, ReactiveFormsModule],
  exports: [FiltersComponent, FiltersContainerComponent, FilterPipe, HideEmptyDirective]
})
export class FiltersModule {}
