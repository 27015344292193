<div class="notifications">
  <div class="notifications__modal">
    <div class="notifications__head">
      <div class="notifications__head-text">
        <p>Мои организации или ИП</p>
        <p>Настройки</p>
      </div>
      <button type="button" class="btn-blue-small">Выход</button>
    </div>
    <div class="notifications__items">
      <h3>Уведомления</h3>
      <ul class="notifications__list">
        <li>
          <div class="notifications__block notifications__img">
            <img src="" alt="">
          </div>
          <div class="notifications__block notifications__block--width">
            <div class="notifications__subtitle">
              <p><span class="notifications__subtitle--gray">Товарный знак</span> Business Firm</p>
              <span class="notifications__subtitle--gray">21.05.2020</span>
            </div>
            <div class="notifications__text">
              <p>Готов проект лицензионного договора</p>
              <span  class="notifications__text-status notifications__text-status--red">К согласованию</span>
            </div>
          </div>
        </li>
        <li>
          <div class="notifications__block notifications__img">
            <img src="" alt="">
          </div>
          <div class="notifications__block notifications__block--width">
            <div class="notifications__subtitle">
              <p><span class="notifications__subtitle--gray">Товарный знак</span> Business Firm</p>
              <span class="notifications__subtitle--gray">21.05.2020</span>
            </div>
            <div class="notifications__text">
              <p>Заявление о продлении срока действия товарного знака подано</p>
<!--              <span  class="notifications__text-status notifications__text-status&#45;&#45;red">К согласованию</span>-->
            </div>
          </div>
        </li>
        <li>
          <div class="notifications__block notifications__img">
            <img src="" alt="">
          </div>
          <div class="notifications__block notifications__block--width">
            <div class="notifications__subtitle">
              <p><span class="notifications__subtitle--gray">Товарный знак</span> Business Firm</p>
              <span class="notifications__subtitle--gray">21.05.2020</span>
            </div>
            <div class="notifications__text">
              <p>Оплата услуг за регистрацию товарного знака получена</p>
              <!--              <span  class="notifications__text-status notifications__text-status&#45;&#45;red">К согласованию</span>-->
            </div>
          </div>
        </li>
      </ul>

      <span class="notifications__showMore">Все уведомления</span>
    </div>
  </div>
  <div (click)="closeModal()" class="notifications__overlay"></div>
</div>
