import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { proxyStaticUrl } from '../helpers/proxy-static';

@Pipe({
  name: 'proxyImage'
})
export class ImageProxyPipe implements PipeTransform {

  constructor() {
  }

  transform(url: string) {
    return proxyStaticUrl(url);
  }

}
