import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-choice-classes-modal',
  templateUrl: './choice-classes-modal.component.html',
  styleUrls: ['./choice-classes-modal.component.scss']
})
export class ChoiceClassesModalComponent implements OnInit {

  @Output() modalEvent = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalEvent.emit();
  }

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }
}
