import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HttpService } from '../../../../shared/services/http.service';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../../../../app-search/src/app/services/search.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../../../shared/services/shared.service';
import { UserProfileService } from '../../../../../../../shared/services/user-profile.service';
import { InterestService } from '../../../../../../../shared/services/interest.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-tm-owner',
  templateUrl: './tm-owner.component.html',
  styleUrls: ['./tm-owner.component.scss']
})
export class TmOwnerComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();
  @Input() startData;
  @Input() isDisabled;
  @Input() requestType;
  @Input() isShowCompanyInfo;
  @Input() organizations;
  @Output() nextStepEvent = new EventEmitter();

  @Input() imageText; // текстовое обозначение товарного знака
  imageUrl; // url картинки
  imageError: string; // ошибка загрузки картинки
  isImageLoading: boolean; // лоадер загрузки картинки
  cardImageBase64: string; // картинка в формате base64
  viewSelectContainer = false; // отображать выпадающий блок после ввода ИНН/ОГРН
  ownerForm: FormGroup;
  loadingOwner = false; // лоадер при получении владельца
  disabledInput = true; // заблокировать поля ввода владельца
  owner; // владелец по ИНН/ОГРН
  ownerShortName; // короткое наименование организации
  ogrn; // ОГРН владельца
  OKVEDCodes = []; // оквэд коды (массив объектов): [{code: 12, description: "Описание"}, ...]
  OKVEDCodesShort = []; // оквэд кодв (массив чисел): [12, 34, 56, ...]
  idApplication;
  payerError = false;
  payerCheckLoading = false;
  showFullForm = false;
  viewOrganizationsList = false;
  isIP = false;
  isNonResident = false;
  ownerId: string;
  constructor(
    private httpService: HttpService,
    private fb: FormBuilder,
    private searchService: SearchService,
    private router: Router,
    private http: HttpClient,
    private sharedApi: SharedService,
    private route: ActivatedRoute,
    private userProfileService: UserProfileService,
    private interestService: InterestService,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  ngOnInit(): void {
    this._initForm();
    setTimeout(() => {
      if (this.startData) {
        console.log(this.startData);
        this.disabledInput = false;
        this.loadingOwner = false;
        this.imageText = this.startData.imageText;
        this.imageUrl = this.startData.imageUrl;
        this.ownerShortName = this.startData.ownerShortName;
        this.ogrn = this.startData.ogrn;
        this.isNonResident = this.startData.isNonResident;
        this.ownerForm.get('TIN').setValue(this.startData.inn);
        this.ownerForm.get('name').setValue(this.startData.name);
        this.ownerForm.get('head').setValue(this.startData.director);
        this.ownerForm.get('address').setValue(this.startData.address);
      }
      // const intevalId = setInterval(() => {
      //   if (this.userProfileService.getOrganizationsData()) {
      //     clearInterval(intevalId);
      //     this.organizations = this.userProfileService.getOrganizationsData().slice(0).filter(org => !org.pending);
      //   }
      // }, 1000);

    }, 150);
  }

  /**
   * инициализация формы оплыты оффлайн
   */
  private _initForm(): void {
    this.ownerForm = this.fb.group({
      TIN: [null, [Validators.required]],
      name: [null, [Validators.required]],
      head: [null, [Validators.required]],
      address: [null, [Validators.required]]
    });
  }

  /**
   * получение данных о правообладателе по ИНН/ОГРН
   */
  getOwnerData(): void {
    const query = {
      id: this.ownerForm.controls.TIN.value.toString(),
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits[0]?._source) {
        this.isIP = data.hits.hits[0]._index === 'individual_entrepreneurs';
        this.disabledInput = false;
        this.showFullForm = true;
        this.owner = data.hits.hits[0]._source;
        if (this.owner.name) {
          this.ownerShortName = this.owner.name;
        }
        this.viewSelectContainer = true;
        this.ogrn = this.owner.PSRN.toString();
        if (this.owner.OKVEDCodes) {
          this.OKVEDCodes = this.owner.OKVEDCodes;
          this.OKVEDCodesShort = [];
          this.owner.OKVEDCodes.forEach(item => {
            this.OKVEDCodesShort.push(item.code);
          });
        }
      } else {
        this.payerError = true;
        this.ownerForm.controls.name.setValue('');
        this.ownerForm.controls.address.setValue('');
        this.ownerForm.controls.head.setValue('');
      }
      this.loadingOwner = false;
    });

  }

  /**
   * если поле ИНН/ОГРН валидно - получать данные о правообладателе
   */
  checkValidTIN(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.PRINT_INN_RESULTS });
    this.payerError = false;
    this.viewSelectContainer = false;
    const patternTIN = /^(?=[0-9]*$)(?:.{10}|.{12}|.{13}|.{15})$/g;
    if (patternTIN.test(this.ownerForm.controls.TIN.value)) {
      if (this.validateInn() || this.validateOgrn()) {
        this.loadingOwner = true;
        this.getOwnerData();
        this.showFullForm = false;
        this.isShowCompanyInfo = false;
      }
    }
  }

  /**
   * проверка - введен ли в поле ИНН/ОГРН валидный ОГРН
   */
  validateOgrn(): boolean {
    const ogrn = this.ownerForm.controls.TIN.value?.toString();
    if (ogrn) {
      const n13 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 11).toString().slice(-1), 10);
      const n15 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 13).toString().slice(-1), 10);
      return n13 === parseInt(ogrn[12], 10) || n15 === parseInt(ogrn[14], 10);
    }
  }

  /**
   * заполнение данных о правообладателе
   */
  setValueOwner(): void {
    this.ownerForm.controls.name.setValue(this.owner.fullName);
    this.ownerForm.controls.address.setValue(this.owner.address);
    if (this.owner.directors && this.owner.directors[0]) {
      const director = this.owner.directors[0];
      this.ownerForm.controls.head.setValue(director.surname + ' ' + director.name + ' ' + director.patronymic);
    } else {
      if (this.isIP) {
        this.ownerForm.controls.head.setValue(this.owner.fullName);
      }
    }
    this.viewSelectContainer = false;
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SELECT_INN });
  }

  /**
   * проверка валидности формы на 1 шаге
   */
  checkValidForm(): boolean {
    return (((this.validateInn() || this.validateOgrn()) && this.ownerForm.status === 'VALID') || this.isNonResident) && (!!this.imageText || !!this.imageUrl);
  }

  /**
   * проверка - введен ли в поле ИНН/ОГРН валидный ИНН
   */
  validateInn(): boolean {
    const inn = this.ownerForm.controls.TIN.value?.toString();
    const check = (num) => {
      const n = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0].slice(-num.length);
      let summ = 0;
      for (let i = 0; i < num.length; i++) {
        summ += num[i] * n[i];
      }
      let control = summ % 11;
      if (control > 9) {
        control = control % 10;
      }
      return control;
    };
    if (inn?.length === 10) {
      // tslint:disable-next-line:triple-equals
      return check(inn) == inn.slice(-1);
    } else if (inn?.length === 12) {
      return (
        // tslint:disable-next-line:triple-equals
        check(inn.slice(0, 11)) == inn.slice(10, -1) &&
        // tslint:disable-next-line:triple-equals
        check(inn) == inn.slice(-1)
      );
    } else {
      return false;
    }
  }

  /**
   * загрузка картинки
   * @param fileInput: файл
   */
  fileChangeEvent(fileInput: any): any {
    this.isImageLoading = true;
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const maxSize = 2000000;
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (fileInput.target.files[0].size > maxSize) {
        this.imageError = 'Максимальный размер файла ' + maxSize / 1000000 + 'Mb';

        return false;
      }

      if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
        this.imageError = 'Недопустимый тип файла';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          this.cardImageBase64 = e.target.result;
          const imageBody = {
            body: {
              name: fileInput.target.files[0].name,
              image: this.cardImageBase64
            }
          };
          this.httpService.post({path: 'request/image/add', body: imageBody}).subscribe((data: any) => {
            if (data && data.path) {
              this.imageUrl = data.path;
            }
            this.isImageLoading = false;
          });

        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  /**
   * уделание картинки
   */
  removeImage(): void {
    (document.getElementById('file') as HTMLInputElement).value = '';
    this.imageError = null;
    this.cardImageBase64 = null;
    this.imageUrl = null;
  }

  beforeNextStep() {
    if (this.OKVEDCodesShort.length || this.requestType === 'trademarkSearch' || this.isNonResident) {
      this.nextStep();
    } else {
      const query = {
        id: this.ownerForm.controls.TIN.value,
      };
      this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        if (data?.hits?.hits[0]?._source) {
          if (data.hits.hits[0]._source.OKVEDCodes) {
            this.OKVEDCodes = data.hits.hits[0]._source.OKVEDCodes;
            this.OKVEDCodesShort = [];
            data.hits.hits[0]._source.OKVEDCodes.forEach(item => {
              this.OKVEDCodesShort.push(item.code);
            });
            this.nextStep();
          }
        }
      });
    }
  }

  nextStep() {
    this.nextStepEvent.emit({
      idApplication: this.idApplication,
      ownerShortName: this.ownerShortName,
      OKVEDCodes: this.OKVEDCodes,
      OKVEDCodesShort: this.OKVEDCodesShort,
      firstJSON: {
        responseID: this.idApplication ? this.idApplication.toString() : '',
        stage: 1,
        type: this.requestType,
        designation: this.imageText,
        imgURL: this.imageUrl,
        owner: {
          inn: this.validateInn() ? this.ownerForm.controls.TIN.value?.toString() : (this.owner ? this.owner.TIN.toString() : ''),
          ogrn: this.validateOgrn() ? this.ownerForm.controls.TIN.value?.toString() : this.ogrn,
          name: this.ownerForm.controls.name.value,
          shortName: this.ownerShortName,
          director: this.ownerForm.controls.head.value,
          address: this.ownerForm.controls.address.value,
          isNonResident: this.isNonResident,
          id: this.ownerId
        },
        interestData: this.interestService.addInterestData()
  }
    });
  }
  disableCases(show) {
    this.sharedApi.hideCases.next(show);
    console.log(show);
    console.log('cases disable');
  }

  checkCurrentOrganization(ogrn) {
    this.showFullForm = true;
    this.payerError = false;
    this.payerCheckLoading = true;
    const reqData = {
      PSRN: ogrn
    };
    this.httpService.get({path: 'organizations/check', params: reqData}).subscribe((data: any) => {
      this.payerCheckLoading = false;
      this.payerError = !data.passed;
      if (this.payerError === true) {this.ownerForm.reset(); }
      if (data.passed) {
        this.setValueOwner();
      } else {
        this.owner = undefined;
        this.disabledInput = true;
      }
    });
  }
  ngOnDestroy(): void {
  }

  showOrganizationsList() {
    if (this.organizations.length) {
      this.viewOrganizationsList = true;
    }
  }

  setSelectedOwner(owner) {
    this.ownerForm.controls.TIN.setValue(owner.TIN);
    this.ownerId = owner.id;

    console.log('owner', owner);
    if (owner.nonResident) {
      this.isNonResident = true;
      this.ownerForm.controls.head.reset();
      this.ownerForm.controls.TIN.setValue('');
      this.ownerForm.controls.name.setValue(owner.name);
      this.ownerForm.controls.address.setValue(owner.legalAdress);
      this.ownerShortName = '';
      this.ogrn = '';
      if (this.owner) {
        this.owner.TIN = '';
      }
    } else {
      this.isNonResident = false;

      const query = {
        id: this.ownerForm.controls.TIN.value.toString(),
      };
      this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
        if (data?.hits?.hits[0]?._source) {
          this.isIP = data.hits.hits[0]._index === 'individual_entrepreneurs';
          this.disabledInput = false;
          this.showFullForm = true;
          this.owner = data.hits.hits[0]._source;
          if (this.owner.name) {
            this.ownerShortName = this.owner.name;
          }
          this.ogrn = this.owner.PSRN.toString();
          if (this.owner.OKVEDCodes) {
            this.OKVEDCodes = this.owner.OKVEDCodes;
            this.OKVEDCodesShort = [];
            this.owner.OKVEDCodes.forEach(item => {
              this.OKVEDCodesShort.push(item.code);
            });
          }
          this.loadingOwner = false;
          this.setValueOwner();
        }
      });

    }

    this.viewOrganizationsList = false;
    this.showFullForm = true;
    this.disabledInput = false;
  }

}
