import { Injectable } from '@angular/core';

declare var window: any;

@Injectable()

export class InterestService {

  addInterestData() {
    return {
      clientId: window.hasOwnProperty('getClientId') ? window.getClientId() : '',
      utmTerm: window.sbjs?.get.current.trm ? window.sbjs?.get.current.trm : '',
      utmContent: window.sbjs?.get.current.cnt ? window.sbjs?.get.current.cnt : '',
      utmCampaign: window.sbjs?.get.current.cmp ? window.sbjs?.get.current.cmp : '',
      utmSource: window.sbjs?.get.current.src ? window.sbjs?.get.current.src : '',
      utmMedium: window.sbjs?.get.current.mdm ? window.sbjs?.get.current.mdm: '',
      inUrl: window.sbjs?.get.current_add.ep ? window.sbjs?.get.current_add.ep : '',
      fromUrl: window.decodeURI(window.location),
    };
  }
}
