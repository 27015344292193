import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorpHistorySearchesComponent } from './components/corp-history-searches/corp-history-searches.component';
import { CorpHistoryContainerComponent } from './corp-history-container/corp-history-container.component';
import { SharedModule } from '../../../../app-ipid/src/app/shared/shared.module';
import { LayoutModule } from '../../../../app-ipid/src/app/pages/layout/layout.module';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { HistoryState } from '@web/features/corp/corp-history/states/history.state';
import { CorpHistoryApiService } from '@web/features/corp/corp-history/services/corp-history-api.service';
import { CorpSearchHistoryFiltersComponent } from './components/corp-search-history-filters/corp-search-history-filters.component';
import { FiltersModule } from '@ui/filters/filters.module';
import { ListModule } from '@ui/list/list.module';
import { HistorySearchItemComponent } from './components/history-search-item/history-search-item.component';
import { UiSharedModule } from '@ui/shared/ui-shared.module';
import {ExpertModule} from '../../../../app-ipid/src/app/pages/layout/expert/expert.mdoule';


@NgModule({
  declarations: [
    CorpHistoryContainerComponent,
    CorpHistorySearchesComponent,
    CorpSearchHistoryFiltersComponent,
    HistorySearchItemComponent,
  ],
  providers: [
    CorpHistoryApiService
  ],
  imports: [
    NgxsModule.forFeature([HistoryState]),
    CommonModule,
    SharedModule,
    LayoutModule,
    RouterModule,
    FiltersModule,
    ListModule,
    UiSharedModule,
    ExpertModule
  ]
})
export class CorpHistoryModule {
}
