import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContractsRoutingModule } from './contracts-routing.module';
import { ContractsComponent } from './contracts.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { TextFieldModule } from '@angular/cdk/text-field';
import {ExpertModule} from '../layout/expert/expert.mdoule';


@NgModule({
  declarations: [
    ContractsComponent,
  ],
  imports: [
    CommonModule,
    ContractsRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    TextFieldModule,
    ExpertModule
  ]
})
export class ContractsModule { }
