import { Directive, Input } from '@angular/core';
import { getProp } from '../../../utils/object-utils';
import { IOption } from './lv-filter-multiselect.directive';
import { ILvFilter } from '../lv-filter/lv-filter.interface';

@Directive({
  selector: '[lvFilterInput]'
})
export class LvFilterInputDirective implements ILvFilter {
  readonly type = 'input';

  @Input() value: string;
  @Input() placeholder: string;

  @Input() filterFn?: (row: any) => boolean;
  @Input() filterField?: string;

  constructor() {
  }

  private defaultFilterRow = (row: any) => {
    if (!this.value) {
      return true;
    }

    const val = getProp(row, this.filterField) || '';
    return val.includes(this.value.toLowerCase());
  };

  getFilterFn() {
    if (this.filterFn) {
      return this.filterFn;
    } else if (this.filterField) {
      return this.defaultFilterRow;
    } else {
      throw new Error('filterField or filterFn required in "LvFilterInput"');
    }
  }
}
