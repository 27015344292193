import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy, OnInit
} from '@angular/core';
import { FilterService } from '@ui/filters/services/filter.service';
import { asyncScheduler, SubscriptionLike } from 'rxjs';
import { observeOn } from 'rxjs/operators';

@Directive({
  selector: '[appHideEmpty]'
})
export class HideEmptyDirective implements OnDestroy, OnInit {
  @Input('appHideEmpty') childSelector: string;

  private _display = 'block';

  private sub: SubscriptionLike;

  constructor(
    private elRef: ElementRef,
    private filterService: FilterService,
    private cdr: ChangeDetectorRef,
  ) { }

  @HostBinding('style.display') get display() {
    return this._display;
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (!this.sub) {
      this.sub = this.filterService.value$
        .pipe(observeOn(asyncScheduler))
        .subscribe(value => {
          if (!this.elRef.nativeElement.querySelector(this.childSelector)) {
            this._display = 'none';
          } else {
            this._display = 'block';
          }
          this.cdr.markForCheck();
        });
    }
  }
}
