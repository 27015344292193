import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FileComponent } from './pages/file/file.component';

const routes: Routes = [
  // Get files

  // Приложение к оферте
  {path: 'offer', component: FileComponent, data: {file: 'offer.pdf'}},
  {path: 'offer_sogaz', component: FileComponent, data: {file: 'offer_sogaz.pdf'}},

  // for gardium pro
  {path: 'offer_by', component: FileComponent, data: {file: 'by_price.pdf'}},
  {path: 'offer_tinkoff', component: FileComponent, data: {file: 'offer_tinkoff.pdf'}},
  {path: 'offer_santeh', component: FileComponent, data: {file: 'offer_santeh.pdf'}},
  {path: 'offer_askona', component: FileComponent, data: {file: 'offer_askona.pdf'}},
  {path: 'offer_lactalis', component: FileComponent, data: {file: 'offer_lactalis.pdf'}},
  {path: 'offer_divany', component: FileComponent, data: {file: 'offer_divany.pdf'}},
  {path: 'offer_knauf', component: FileComponent, data: {file: 'offer_knauf.pdf'}},
  {path: 'offer_inhom', component: FileComponent, data: {file: 'offer_inhom.pdf'}},
  {path: 'offer_etazhi', component: FileComponent, data: {file: 'offer_etazhi.pdf'}},
  {path: 'offer_ozon', component: FileComponent, data: {file: 'offer_ozon.pdf'}},
  {path: 'offer_bmk', component: FileComponent, data: {file: 'offer_bmk.pdf'}},
  {path: 'offer_mishel', component: FileComponent, data: {file: 'offer_mishel.pdf'}},
  {path: 'offer_valenta', component: FileComponent, data: {file: 'offer_valenta.pdf'}},
  {path: 'offer_lekarstva', component: FileComponent, data: {file: 'offer_lekarstva.pdf'}},
  {path: 'offer_heiniken', component: FileComponent, data: {file: 'offer_heiniken.pdf'}},
  {path: 'offer_petrovaks', component: FileComponent, data: {file: 'offer_petrovaks.pdf'}},
  {path: 'offer_invitro', component: FileComponent, data: {file: 'offer_invitro.pdf'}},
  {path: 'offer_abiproduct', component: FileComponent, data: { file: 'offer_abiproduct.pdf' }},
  {path: 'offer_selectel', component: FileComponent, data: { file: 'offer_selectel.pdf' }},
  {path: 'offer_unilever', component: FileComponent, data: { file: 'offer_unilever.pdf' }},
  {path: 'offer_abinbevefes', component: FileComponent, data: {file: 'offer_abinbevefes.pdf'}},
  {path: 'offer_dkc', component: FileComponent, data: {file: 'offer_dkc.pdf'}},
  {path: 'offer_gulliver', component: FileComponent, data: {file: 'offer_gulliver.pdf'}},
  {path: 'offer_beeline', component: FileComponent, data: {file: 'offer_beeline.pdf'}},

  // Политика конфиденциальности
  { path: 'PP', component: FileComponent, data: { file: 'pp.pdf' } },
  { path: 'pp', component: FileComponent, data: { file: 'pp.pdf' } },
  // Пользовательское соглашение
  { path: 'UA', component: FileComponent, data: { file: 'rules.pdf' } },
  { path: 'ua', component: FileComponent, data: { file: 'rules.pdf' } },
  // Пользовательское соглашение
  { path: 'PDA', component: FileComponent, data: { file: 'pda.pdf' } },
  { path: 'pda', component: FileComponent, data: { file: 'pda.pdf' } },
  // Пользовательское соглашение
  { path: 'SLA', component: FileComponent, data: { file: 'sla.pdf' } },
  { path: 'sla', component: FileComponent, data: { file: 'sla.pdf' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
