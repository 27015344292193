import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpService} from '../../../../shared/services/http.service';
import {MonitoringService} from '../../../monitoring/monitoring.service';
import {MonitoringAddService} from '../../../monitoring/shared/monitoring-add.service';
import {environment} from '../../../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {UserProfileService} from '../../../../../../../shared/services/user-profile.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-open-monitoring-widget',
  templateUrl: './open-monitoring-widget.component.html',
  styleUrls: ['./open-monitoring-widget.component.scss']
})
export class OpenMonitoringWidgetComponent implements OnInit {
  showCompanyList = false;
  kontragents;
  selectedCompany;
  totalMonitoring;
  risksAll = [];
  loadingRisks = true;

  private readonly onDestroy = new Subject<void>();

  constructor( private route: ActivatedRoute,
               private router: Router,
               private httpService: HttpService,
               private monitoringService: MonitoringService,
               readonly monitoringAddService: MonitoringAddService,
               private userProfileService: UserProfileService) { }

  ngOnInit(): void {
    const timer = setInterval(() => {
      if (this.userProfileService.getOrganizationsData()) {
        this.kontragents = this.userProfileService.getOrganizationsData().slice(0);
        this.kontragents.unshift({name: 'Все организации'});

        this.route.queryParams
          .pipe(takeUntil(this.onDestroy))
          .subscribe((params: Params) => {
            const selectedCompanyId = params?.selectedCompanyId;
            if (selectedCompanyId) {
              this.selectedCompany = this.kontragents.find(k => k.id === selectedCompanyId);
            } else {
              this.selectedCompany = this.kontragents.length === 2 ? this.kontragents[1] : this.kontragents[0];
            }

            this.getRisks(this.selectedCompany.id);
            clearInterval(timer);
          });
      }
    }, 1000);
  }

  getRisks(companyId?: string): void {
    this.loadingRisks = true;
    this.monitoringService.getObjectsWithRisks(companyId, 4).subscribe((data: any) => {
      this.totalMonitoring = data.totalCount;
      this.risksAll = data.objectsMonitoring;
      this.loadingRisks = false;
    });
  }

  getSrcByIndex(index: string, isRisk?: boolean, imgUrl?: string): string {
    if (index === 'domains') {
      return 'assets/portfolio/domains.svg';
    } else {
      return isRisk ? imgUrl : './assets/images/icon-doc.svg';
    }
  }

  getColorByLevel(level: number): string {
    switch (level) {
      case 1:
        return 'possible';
      case 2:
        return 'recommended';
      case 3:
        return  'critical';
      default:
        return 'possible';
    }
  }
}
