import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { SocialAuthService } from '../../../services/social-auth.service';
import { Store } from '@ngxs/store';
import { AppActions } from '@web/core/states/app.actions';
import { APP_STATE_TOKEN, AppState } from '@web/core/states/app.state';
import { AuthState } from '@web/core/states/auth.state';
import { AuthActions } from '@web/core/states/auth.actions';
import { OrganizationActions } from '@web/core/states/organization.actions';
import { WebModuleConfig } from '@web/core/services/config.service';
import {document} from 'ngx-bootstrap/utils';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { InterestService } from '../../../services/interest.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  appState$ = this.store.select(APP_STATE_TOKEN);
  isOtkritite$ = this.store.select(AppState.isOtkritie);
  isLoading$ = this.store.select(AuthState.isLoading);
  hasSocialAuth$ = this.store.select(AppState.hasSocialAuth);

  showCodeTemplate = true;

  loginForm: FormGroup;
  incorrectLoginData = false;
  emailPattern = /^\S+@\S+?\.[a-zA-Z]{2,3}$/;
  googleAuthCheck = 'true';
  showModalSupport = false;

  private apiUrl: string;
  brand: string;

  private readonly onDestroy = new Subject<void>();

  constructor(
    private router: Router,
    private socialApi: SocialAuthService,
    private store: Store,
    private config: WebModuleConfig,
    private readonly intercomEventsService: IntercomEventsService,
    private interestService: InterestService
  ) {}

  ngOnInit(): void {
    // if (!this.isDebit && this.envService.isOpen) {
    //   console.log('isOpen: ', this.envService.isOpen);
    //   this.showPage = false;
    //   document.location.href =
    //     'https://rumskapt241.open.ru/sso/oauth2/authorize?service=external&redirect_uri=https%3A%2F%2Fdev.ip-id.ru' +
    //     '%2Fsso&realm=%2Fcustomer&response_type=code&client_id=gardium';
    // }
    //
    // this.showPage = true;
    // this.showLoader = false;
    // this._initForm();
    //
    // if (!this.isDebit) {
    //   this.getExternalAuthToken();
    // }

    // this.subRoute = this.route.data.subscribe(debit => {
    //   this.isDebit = debit;
    //
    //   if (this.isDebit) {
    //     environment = debtEnvironment;
    //   } else {
    //     environment = ipidEnvironment;
    //   }
    //
    //   this.brand = environment.BRAND;
    //   this.footerText = environment.FOOTER_TEXT;
    //   this.logoPath = environment.LOGO_PATH;
    //
    //   console.log('brand: ', this.brand);
    //   console.log('prod: ', environment.production);
    //   console.log('isDebit: ', this.isDebit);
    // });

    this.store.dispatch(new AppActions.SetBrand()).subscribe((globalState) => {
      console.log(globalState.app);
      this.brand = globalState.app.brand || '';
      console.log('BRAND: ', this.brand);
    });

    this.store.dispatch(new AuthActions.AttemptSsoLogin());
    this.store.dispatch(new AuthActions.AttemptOauthLogin());

    this.initForm();
    this.apiUrl = this.config.BASE_URL_API;
    console.log(this.config);
  }

  // getExternalAuthToken() {
  //   this.route.queryParams.subscribe((params) => {
  //     if (params.token) {
  //       this.token = params.token;
  //       localStorage.setItem('currentUserIPID', (this.token));
  //       if (this.envService.isOpen) {
  //         this.ssoLogin(this.token);
  //       } else {
  //         this.oauthLogin();
  //       }
  //     }
  //   });
  // }

  private initForm(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  login() {
    const authData = {
      login: this.loginForm.value.email,
      pass: this.loginForm.value.password,
    };
    this.store
      .dispatch(new AuthActions.Login(authData))
      .subscribe((globalState) => {
        if (!globalState?.auth.error) {
          if (!this.store.selectSnapshot(AppState.isDebit)) {
            this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_USER_LOGIN });
          }
          this.getOrganizations();
        } else {
          console.log('incorrect pass');
          this.incorrectLoginData = true;
        }
      });
  }

  private getOrganizations() {
    this.store.dispatch(new OrganizationActions.Init()).subscribe(() => {
      this.store.dispatch(new AuthActions.GetRights());
      this.router.navigate(['/index']);
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  googleRegister() {
    const interestData = this.interestService.addInterestData();
    const urlParams = new HttpParams()
      .set('client_id', this.socialApi.getGoogleId(this.brand))
      .set(
        'scope',
        'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
      )
      .set('response_type', 'code')
      .set('state', 'brand.' + this.brand + '/' + 'provider.' + 'google' + '/' + 'interestData.' + JSON.stringify(interestData))
      .set('redirect_uri', `${this.apiUrl}/user/oauth`);
    document.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${urlParams.toString()}`;
  }

  yandexCodeGet() {
    const interestData = this.interestService.addInterestData();
    const urlParams = new HttpParams()
      .set('response_type', 'code')
      .set('client_id', this.socialApi.getYandexId(this.brand))
      .set('state', 'brand.' + this.brand + '/' + 'provider.' + 'yandex' + '/' + 'interestData.' + JSON.stringify(interestData))
      .set('redirect_uri', `${this.apiUrl}/user/oauth`);
    document.location.href = `https://oauth.yandex.ru/authorize?${urlParams.toString()}`;
  }

  facebookLogin() {
    const urlParams = new HttpParams()
      .set('client_id', this.socialApi.getFacebookId(this.brand))
      .set('scope', 'email')
      .set('state', 'brand.' + this.brand + '/' + 'provider.' + 'facebook')
      .set('redirect_uri', `${this.apiUrl}/user/oauth`);
    document.location.href = `https://www.facebook.com/dialog/oauth?${urlParams.toString()}`;
  }

  openModalSupport() {
    this.showModalSupport = true;
    if (this.showModalSupport) {
      document.getElementsByTagName('header')[0].style.zIndex = 'initial';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
  }

  closeModalSupport() {
    document.body.style.overflow = 'visible';
    document.body.style.position = '';
    document.body.style.height = 'auto';
    this.showModalSupport = false;
  }
}
