import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { RecoveryService } from '../../../../app-ipid/src/app/shared/services/recovery.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';

import { environment as ipidEnvironment } from '../../../../app-ipid/src/environments/environment';
import { environment as debtEnvironment } from '../../../../app-debit/src/environments/environment';
import {document} from 'ngx-bootstrap/utils';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

let environment;

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit, OnDestroy {

  brand = '';
  logoPath: string;
  footerText: string;

  recoveryForm: FormGroup;
  recoverPass: FormGroup;

  default = true;
  success = false;
  error = false;
  passwordSC = false;
  passwordMatcher = false;
  recoverError;
  passwordMatch: boolean;
  showLoader: boolean;
  ID;
  restoreSuccess = false;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@!%*?&^-])[A-Za-z\d$@!%*?&^-].{6,}$/;
  emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  subRoute: Subscription;
  isDebit;
  showModalSupport = false;

  private readonly onDestroy = new Subject<void>();

  constructor(private recoverApi: RecoveryService,
              private route: ActivatedRoute,
              private intercomEventsService: IntercomEventsService,
  ) {
    this.subRoute = this.route.data.subscribe(data => {
      this.isDebit = data?.isDebit;

      if (this.isDebit) {
        environment = debtEnvironment;
      } else {
        environment = ipidEnvironment;
      }

      this.brand = environment.BRAND;
      this.footerText = environment.FOOTER_TEXT;
      this.logoPath = environment.LOGO_PATH;

      console.log('brand: ', this.brand);
      console.log('prod: ', environment.production);
      console.log('isDebit: ', this.isDebit);
    });
  }

  ngOnInit(): void {
    this.showLoader = false;
    this.initForm();
    this.passwordMatch = true;

    this.getUrlParam();
    if (this.ID) {
      this.passwordSC = true;
      this.default = false;
    }
  }

  initForm() {
    const PassPattern = this.passwordPattern;
    const emailPattern = this.emailPattern;

    this.recoveryForm = new FormGroup({
      email: new FormControl('', Validators.pattern(emailPattern)),
    });

    this.recoverPass = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(PassPattern)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.pattern(PassPattern)]),
    });
  }

  recover() {
    const params = new HttpParams()
      .set('login', this.recoveryForm.value.email.toString())
      .set('brand', this.brand);
    this.recoverApi.recoverAccount(params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data.answer === 'Успешно') {
          if (!this.isDebit) {
            this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_PASSWORD_RESET });
          }
          this.passwordSC = true;
          this.default = false;
        }
        if (data.answer === 'Указанный пользователь не зарегистрирован.') {
        } else {
          this.success = false;
          this.default = false;
          this.error = true;
        }
      });
  }

  @HostListener('document:keyup', ['$event']) // 13=ENTER
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.getMail();
    }
  }

  getMail() {
    this.showLoader = true;
    const params = new HttpParams()
      .set('login', this.recoveryForm.value.email.toString())
      .set('brand', this.brand);
    this.recoverApi.recoverAccountMail(params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(dataRecover => {
        if (dataRecover.answer === 'Успешно') {
          this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_PASSWORD_RESET });
          this.passwordSC = false;
          this.default = false;
          this.success = true;
        } else {
          this.showLoader = false;
          this.success = false;
          this.default = false;
          this.error = true;
        }
      });
  }

  getUrlParam() {
    this.route.queryParams.subscribe(params => {
      console.log(params.id);
      if (params.id) {
        this.ID = params.id;
      }
    });
  }

  resetPassword() {
    this.showLoader = true;
    const body = {
      id: this.ID,
      passNew: this.recoverPass.value.password,
      passConfirm: this.recoverPass.value.confirmPassword,
    };
    const params = new HttpParams()
      .set('brand', this.brand);
    this.recoverApi.resetPassword(body, params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(recoverData => {
        console.log(recoverData);
        if (recoverData.answer === 'Успешно') {
          this.restoreSuccess = true;
          this.success = true;
          this.default = false;
          this.error = false;
          this.passwordSC = false;
        } else {
          this.showLoader = false;
          this.recoverError = recoverData.answer;
          this.error = true;
          this.success = false;
        }
      });
  }

  retryRecovery() {
    this.default = true;
    this.error = false;
    this.success = false;
  }

  openModalSupport() {
    this.showModalSupport = true;
    if (this.showModalSupport) {
      document.getElementsByTagName('header')[0].style.zIndex = 'initial';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
  }

  closeModalSupport() {
    this.showModalSupport = false;
    document.body.style.overflow = 'visible';
    document.body.style.position = '';
    document.body.style.height = 'auto';
  }


  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  comparePasswords() {
    if (this.recoverPass.value.password === this.recoverPass.value.confirmPassword) {
      console.log('no match');
      this.passwordMatch = true;
    } else {
      this.passwordMatch = false;
    }
  }
}
