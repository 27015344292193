import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { RiskIntegrationComponent } from './pages/risk-integration/risk-integration.component';
import { RiskIntegrationAlfaComponent } from './pages/risk-integration-alfa/risk-integration-alfa.component';
import { MonitoringLandingService } from '../monitoring/monitoring-landing/monitoring-landing.service';

const systemRoutes: Routes = [
  {
    path: 'i',
    children: [
      {
        path: 'risk', component: RiskIntegrationComponent
      }
    ]
  },
  {
    path: 'j',
    children: [
      {
        path: 'risk', component: RiskIntegrationAlfaComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(systemRoutes),
  ], exports: [
    RouterModule,
  ],
  providers: [MonitoringLandingService],
})
export class IntegrationRoutingModule {
}
