import { BehaviorSubject, Observable } from 'rxjs';

export class AsyncDataLoader<T> {
  private _data = new BehaviorSubject<T>(null);
  private _isLoadingSubj = new BehaviorSubject<boolean>(false);
  private _error = new BehaviorSubject<boolean>(false);

  constructor(private readonly fn: () => Observable<T>) {
  }

  public load(): void {
    this._error.next(false);
    this._isLoadingSubj.next(true);

    this.fn().subscribe((data) => {
      this._isLoadingSubj.next(false);
      this._data.next(data);
    }, err => {
      this._isLoadingSubj.next(false);
      this._error.next(true);
    });
  }

  public get data$(): Observable<T> {
    return this._data.asObservable();
  }

  public get isLoading$(): Observable<boolean> {
    return this._isLoadingSubj.asObservable();
  }

  public get error$(): Observable<boolean> {
    return this._error.asObservable();
  }

}
