import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchService } from '../../../../../services/search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';


@Component({
  selector: 'app-international-trademark-modal',
  templateUrl: './international-trademark-modal.component.html',
  styleUrls: ['./international-trademark-modal.component.scss']
})

export class InternationalTrademarkModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() modalEvent = new EventEmitter();
  private readonly onDestroy = new Subject<void>();
  sourceData: any = {};
  isLoading = false;
  overTradeMarks;
  viewClassesCount = 'short';
  isShowLogoModal = false;

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  constructor(
    private searchService: SearchService,
    private corp: CorpSearchService,
    private ref: ChangeDetectorRef,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  ngOnInit() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '0';
    }
    this.getDocById();
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.OPEN_ITEM, item_type: 'Международный товарный знак' });
  }

  getCountries(sourceData: any): string {
    if (sourceData?._source?.registrationCountryCode?.length) {
      return sourceData?._source?.registrationCountryCode;
    } else {
      return sourceData?._index.slice(0, 2).toUpperCase().split(',');
    }
  }


  getDocById() {
    const query = {
      _id: this.data._id,
      _index: this.data._index
    };
    this.isLoading = true;
    this.searchService.getDocumentById(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits?.length) {
        this.sourceData = data.hits.hits[0];
        this.sourceData._source.goodsServices.forEach((item, index) => {
          if (item.classNumber === 0) {
            this.sourceData._source.goodsServices.splice(index, 1);
          }
        });
        this.sourceData.indexType = this.searchService.getIndexType(this.sourceData);
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getExternalLink(index) {
    return this.corp.getExternalUrl(index);
  }

  showAllClasses() {
    this.viewClassesCount = 'long';
  }

  getMktuClasses() {
    if (this.viewClassesCount === 'long') {
      return this.sourceData._source.goodsServices;
    } else {
      return this.sourceData._source.goodsServices.slice(0, 3);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  closeModal() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '1';
    }
    this.modalEvent.emit();
  }

  showLogoModal(): void {
    this.isShowLogoModal = !this.isShowLogoModal;
  }
}
