import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-daterange',
  templateUrl: './filter-daterange.component.html',
  styleUrls: ['./filter-daterange.component.scss']
})
export class FilterDaterangeComponent implements OnInit {

  @Input() value: [Date, Date];
  @Input() name: string;
  @Input() placeholder: string;

  @Output() apply = new EventEmitter<[Date, Date]>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onChange(value) {
    if (value?.length) {
      this.apply.emit(value);
    }
  }

}
