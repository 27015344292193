<div *ngIf="showModalMktu">
  <app-mktu-modal
    [selectedMKTU]="selectedMktu"
    (modalEvent)="closeModalMktu()"
    (closeModalSuccess)="closeModalSuccess($event)"
  >
  </app-mktu-modal>
</div>

<section class="mktu">
  <div class="mktu__search-wrapper">
    <div class="mktu__search">
      <p class="mktu__empty" *ngIf="selectedMktu.length === 0">Классы МКТУ</p>
      <div *ngFor="let mktuClass of selectedMktu" class="mktu__card">
        <p class="mktu__subtitle">МКТУ</p>
        <div class="mktu__inner-card">
          <p class="mktu__number">{{mktuClass}}</p>
          <span class="mktu__close-card" (click)="removeMktu(mktuClass)"></span>
        </div>
      </div>
    </div>
    <button class="mktu__btn" (click)="openModalMktu()">Добавить вид деятельности</button>
  </div>
</section>
