import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-trademark-modal',
  templateUrl: './trademark-modal.component.html',
  styleUrls: ['./trademark-modal.component.scss']
})
export class TrademarkModalComponent implements OnInit, OnDestroy {
  @Input() trademark: any;
  @Input() show = false;
  @Output() close = new EventEmitter();

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  closeModal() {
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');

    this.close.emit();
  }

  getTrademarksIndex(trademark: any) {
    return trademark._index !== 'rutm' && trademark._index !== 'rugp' && trademark._index !== 'rugpap'
      && trademark._index !== 'rutmap' && trademark._index !== 'wktm' && trademark._index !== 'wotm';
  }
}
