import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SupportModalComponent} from './support-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AppDragAndDropDirective} from '../../../../../../shared/directives/drag-and-drop.directive';
import {TextareaAutosizeModule} from 'ngx-textarea-autosize';
import {TextFieldModule} from '@angular/cdk/text-field';

@NgModule({
  declarations: [
    SupportModalComponent,
    AppDragAndDropDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    TextareaAutosizeModule,
    TextFieldModule
  ],
  exports: [
    SupportModalComponent
  ],
})
export class SupportModalModule { }
