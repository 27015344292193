import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RequestCheckLabelService} from '../request-check-label.service';

@Component({
  selector: 'app-payment-check-label',
  templateUrl: './payment-check-label.component.html',
  styleUrls: ['./payment-check-label.component.scss']
})
export class PaymentCheckLabelComponent implements OnInit {

  returnAfterPayment = false;
  yaLastPaymentId;
  error;

  @Output() nextStepEvent = new EventEmitter(); // событие перехода на следующий шаг
  @Output() backStepEvent = new EventEmitter(); // событие перехода на предыдущий шаг
  @Input() organizations;

  constructor(
    private activatedRoute: ActivatedRoute,
    readonly requestCheckLabelService: RequestCheckLabelService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.returnAfterPayment = params.returnAfterPayment === 'true';
      this.yaLastPaymentId = params.t;
    });
    const payer = JSON.parse(localStorage.getItem('payer'));
    if (payer) {
      this.selectPayer(payer);
    }
  }

  nextStep() {
    this.nextStepEvent.emit();
  }

  backStep() {
    this.backStepEvent.emit();
  }

  switchPaymentType(type) {
    this.requestCheckLabelService.setPaymentType(type);
  }

  get paymentType() {
    return this.requestCheckLabelService.paymentType;
  }

  get idApplication() {
    return this.requestCheckLabelService.responseID;
  }

  get paymentSum() {
    return this.requestCheckLabelService.paymentSum;
  }

  onlineError(error) {
    this.error = error;
  }

  paymentOnlineSuccessed(data) {
    if (data.status === 'succeeded') {
      this.toSuccessPage(data);
    }
  }

  toSuccessPage(data?) {
    this.requestCheckLabelService.goSuccessStage(data);
  }

  selectPayer(data) {
    if (this.paymentType === 'online') {
      localStorage.setItem('payer', JSON.stringify(data));
    }
    this.requestCheckLabelService.setPayer(data);
  }

  get payer() {
    return this.requestCheckLabelService.payer;
  }
}
