<button
  [id]="id"
  class="ui-button"
  [attr.type]="type"
  mat-stroked-button
  [disabled]="disabled"
  (click)="onClick.emit()"
  [ngClass]="'ui-button--' + uiType"
>
  <app-button-icon *ngIf="icon && iconPos === 'left'" [icon]="icon"></app-button-icon>
  <span>{{label}}</span>
  <app-button-icon *ngIf="icon && iconPos === 'right'" [icon]="icon"></app-button-icon>
</button>