<section *ngIf="rightsInput" class="intellectual container">
  <h3 class="intellectual__caption">Интеллектуальная собственность</h3>

  <div *ngIf="!showAdminPanel" class="intellectual__wrap">
    <article class="intellectual__item" *ngFor="let intellectual of rightsInput">
      <h4 class="intellectual__title">{{ intellectual?.title }}</h4>
      <div class="intellectual__input-wrap" *ngFor="let field of intellectual?.data">

        <label class="switch" [ngClass]="userBlock ? 'switch--block-user': ''">
          <input *ngIf="field.value !== 'pending'" type="checkbox" class="visual-hidden switch__input"
                 [disabled]="userRole !== 'Администратор' || field.value === 'pending' "
                 [checked]="field?.checked"
                 [ngClass]="field?.checked || field.value === 'pending' ? '' : 'checked'"
                 [(ngModel)]="field.checked"
                 (ngModelChange)="changeRights(field.id, field.value, field.checked)"
          >
          <input *ngIf="field.value === 'pending'" type="checkbox" class="visual-hidden switch__input check"
                 [disabled]="userRole !== 'Администратор' || field.value === 'pending' "
                 [checked]="true"
          >
          <input  type="checkbox" class="visual-hidden switch__input"
                  *ngIf="userBlock"
                  [disabled]="userBlock"
          >
          <span class="switch__slider round"></span>
        </label>

        <div class="intellectual__text-wrap">
          <!--                              <span [ngClass]="{'gray': !field?.checked || userBlock}"></span>-->
          <span>
            {{ field?.name }}
          </span>
          <div class="intellectual__tooltip" *ngIf="field?.value === 'pending'">
            <svg class="intellectual__tooltip-img" width="18" height="15" viewBox="0 0 18 15">
              <path d="M9.73828 9.76172H8.26172V6.73828H9.73828V9.76172ZM9.73828 12.75H8.26172V11.2383H9.73828V12.75ZM0.738281 15H17.2617L9 0.761719L0.738281 15Z"/>
            </svg>
            <div class="intellectual__tooltip-desc-wrap">
              <p class="intellectual__tooltip-desc">Права будут доступны после подтверждения полномочий</p>
            </div>
          </div>
        </div>

      </div>
    </article>
  </div>

  <div *ngIf="showAdminPanel" class="intellectual__wrap">
    <article class="intellectual__item" *ngFor="let intellectual of rightsInput">
      <h4 class="intellectual__title">{{ intellectual?.title }}</h4>
      <div class="intellectual__input-wrap" *ngFor="let field of intellectual?.data">

        <label class="switch" [ngClass]="userBlock ? 'switch--block-user': ''">
          <input *ngIf="field.value !== 'pending'" type="checkbox" class="visual-hidden switch__input"
                 [disabled]="true "
                 [checked]="true"
                 [ngClass]="field?.checked || field.value === 'pending' ? '' : 'checked'"
          >
          <input *ngIf="field.value === 'pending'" type="checkbox" class="visual-hidden switch__input check"
                 [disabled]="userRole !== 'Администратор' || field.value === 'pending' "
                 [checked]="true"
          >
          <input  type="checkbox" class="visual-hidden switch__input"
                  *ngIf="userBlock"
                  [disabled]="userBlock"
          >
          <span class="switch__slider round"></span>
        </label>

        <div class="intellectual__text-wrap">
          <span>
            {{ field?.name }}
          </span>
          <div class="intellectual__tooltip" *ngIf="field?.value === 'pending'">
            <svg class="intellectual__tooltip-img" width="18" height="15" viewBox="0 0 18 15">
              <path d="M9.73828 9.76172H8.26172V6.73828H9.73828V9.76172ZM9.73828 12.75H8.26172V11.2383H9.73828V12.75ZM0.738281 15H17.2617L9 0.761719L0.738281 15Z"/>
            </svg>
            <div class="intellectual__tooltip-desc-wrap">
              <p class="intellectual__tooltip-desc">Права будут доступны после подтверждения полномочий</p>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>

  <div *ngIf="!showAdminPanel" class="user-card__button-wrap">
    <button class="user-card__button user-card__button--revoke" [disabled]="showAdminPanel" (click)="deleteAllRights();gotoTop()">Отозвать доступ</button>
    <button class="user-card__button" [disabled]="showAdminPanel" (click)="confirmRightsChange();gotoTop()">Сохранить изменения</button>
  </div>

</section>
