import {Component, OnDestroy, OnInit} from '@angular/core';
import {RequestCheckLabelService} from './request-check-label.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-request-check-label',
  templateUrl: './request-check-label.component.html',
  styleUrls: ['./request-check-label.component.scss'],
  providers: [RequestCheckLabelService]
})
export class RequestCheckLabelComponent implements OnInit, OnDestroy {

  logo2path = '';
  public userEmail: string = JSON.parse(localStorage.getItem('currentUserData')).userInfo
    ? JSON.parse(localStorage.getItem('currentUserData')).userInfo.email
    : JSON.parse(localStorage.getItem('currentUserData')).email;

  constructor(
    readonly requestCheckLabelService: RequestCheckLabelService,
    readonly activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
    this.logo2path = environment.LOGO2_PATH;
  }

  ngOnInit(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.APP_REGISTER });
    const id = this.activatedRoute.snapshot.queryParams.id;
    if (id) {
      this.requestCheckLabelService.loadRequestIfNeeded(id);
    } else {
      this.requestCheckLabelService.createRequest();
    }
  }

  /**
   * Вернуться на шаг назад
   */
  backStep() {
    switch (this.requestCheckLabelService.step) {
      case 1:
        this.router.navigate(['index']);
        break;
      case 2:
        this.requestCheckLabelService.step = 1;
    }
  }

  /**
   * Перейти на второй шаг
   */
  nextStep() {
    if (this.getValidation()) {
      this.requestCheckLabelService.sendFirstStepData();
    } else {
      this.requestCheckLabelService.validation = true;
    }
  }

  /**
   * отметки о пройденных шагах
   * @param step: номер шага
   */
  getStepClass(step): string {
    if (this.requestCheckLabelService.step === step) {
      return 'steps__element--active';
    } else if (this.requestCheckLabelService.step > step) {
      return 'steps__element--finished';
    } else {
      return '';
    }
  }

  /**
   * Валидация первого шага
   */
  getValidation() {
    let result = false;
    if (this.requestCheckLabelService.labels.length && this.requestCheckLabelService.product.length) {
      result = true;
    }
    return result;
  }

  ngOnDestroy() {
    localStorage.removeItem('payer');
  }

  public sendIntercomEvent(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.GOTO_ORDER });
  }
}
