<div class="open-dashboard">

  <section class="open-cases">
    <app-open-cases></app-open-cases>
  </section>

  <section class="open-search">
    <p class="open-search__title">Проверьте возможность регистрации своего товарного знака</p>

    <app-index-search></app-index-search>

    <p class="open-search__desc">Поиск проводится по всем базам и реестрам ФИПС в режиме реального времени</p>
  </section>

  <section class="open-slider">
    <app-index-slider></app-index-slider>
  </section>

  <section class="open-services">

    <app-service-list-widget [disabled]="disabled"></app-service-list-widget>

    <app-open-monitoring-widget></app-open-monitoring-widget>

  </section>

  <section class="open-expert">
    <app-expert></app-expert>
  </section>

</div>
