import {
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { LvFilterMultiselectDirective } from '../directives/lv-filter-multiselect.directive';
import { LvFilterInputDirective } from '../directives/lv-filter-input.directive';
import { LvFilterDaterangeDirective } from '../directives/lv-filter-daterange.directive';

@Component({
  selector: 'lvFilter',
  template: '',
})
export class LvFilterComponent implements OnInit {
  @Input() name: string;
  @Input() className: string;

  constructor(
    @Optional() private multiselectDirective: LvFilterMultiselectDirective,
    @Optional() private inputDirective: LvFilterInputDirective,
    @Optional() private daterangeDirective: LvFilterDaterangeDirective,
  ) {
  }

  ngOnInit(): void {
  }

  getFilterDetail<T extends ILvProps>(): ILvFilterDetail<T> {
    return {
      type: this.directiveRef.type,
      name: this.name,
      className: this.className,
      props: this.directiveRef as T,
      filterFn: this.directiveRef.getFilterFn()
    };
  }

  get type() {
    return this.directiveRef.type;
  }

  get directiveRef() {
    if (this.multiselectDirective) {
      return this.multiselectDirective;
    } else if (this.inputDirective) {
      return this.inputDirective;
    } else if (this.daterangeDirective) {
      return this.daterangeDirective;
    }
  }

}


export interface ILvFilterDetail<T extends ILvProps> {
  type: 'input' | 'multiselect' | 'daterange';
  name: string;
  className?: string;
  props: T;
  filterFn: (row: any) => boolean;
}

export type ILvProps = LvFilterMultiselectDirective | LvFilterInputDirective | LvFilterDaterangeDirective;
