<div class="container inset">
  <div class="attention">
    <h3>Рекомендация</h3>
    <p>Должник не ответил на претензию в течении установленного законом срока. Возможно он произвел оплату или ответил
      вам напрямую, о чем мы не были уведомлены. Если вы не получили оплату напрямую от должника, либо должник не
      ответил
      вам, рекомендуем обратиться в суд.
  </div>
  <div class="obj-info" *ngIf="type==='claim'">
    <div class="obj-info__subs">
      <div class="obj-info__sub">
        <h2>Информация о претензии</h2>
        <table class="obj-info__table">
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Дата подачи претензии</span>
                <p class="big">10.11.2020</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Требования</span>
                <p class="big">1 222 554,32 ₽</p>
                {{trimString('Размер взысканий, которые требует оппонент', 32)}}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Обоснованность претензии</span>
                <p class="big">Высокая</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Высокая</span>
                <p class="big">Онлайн</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Получатель</span>
                <p class="normal">АПС Строительно-Монтажное управление, ООО</p>
                <small>ОГРН 1147746454668</small>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Заявитель</span>
                <p class="normal">Дизайнстройремонт, ООО</p>
                <small>ОГРН 5147746214391</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Объект</span>
                <a>Патент № 4345346, 01.01.2010</a>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <article class="obj-info__obj">
      <h2>{{typeOfClaim === 'in' ? 'Информация о заявителе' : 'Информация о получателе'}}</h2>
      <div class="obj-info__from-section">
        <div class="obj-info__from-block">
          <span>Полное наименование</span>
          <p>Дизайнстройремонт, ООО</p>
          <p>07.08.2003</p>
        </div>
        <div class="obj-info__from-block">
          <span>Юридический адрес</span>
          <p>Москва, ул Народного ополчения, дом 23, корпус 14, офис 322</p>
        </div>
        <div class="obj-info__from-block">
          <span>Руководитель</span>
          <p>Кивелиди Харлампий Иванович, генеральный директор</p>
        </div>
        <div class="obj-info__from-block">
          <span>Интеллектуальная собственность</span>
          <p>1 товарный знак, 5 договоров</p>
        </div>
        <div class="obj-info__from-block">
          <span>Выручка за 2019</span>
          <p>137 000 ₽</p>
        </div>
        <div class="obj-info__from-block">
          <span>Среднесписочная численность</span>
          <p>50 (2019)</p>
        </div>
        <div class="obj-info__from-block">
          <span>Контактные данные</span>
          <a>ivanuska.ru</a>
          <a>07.08.2003</a>
          <p>+7 495 987 65 43</p>
        </div>
      </div>
    </article>
  </div>

  <div class="obj-info" *ngIf="type==='court'">
    <div class="obj-info__subs">
      <div class="obj-info__sub">
        <h2>Информация о претензии</h2>
        <table class="obj-info__table">
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Дата подачи искового заявления</span>
                <p class="big">10.11.2020</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Дата следующего судебного заседания</span>
                <p class="big">12.03.2021</p>
                <small>Вид заседания ― предварительное (первое)</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Требования</span>
                <p class="big">1 222 554,32 ₽</p>
                {{trimString('Размер взысканий, которые требует оппонент', 32)}}
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Вероятность удовлетворения</span>
                <p class="big">Высокая</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Наш процессуальный статус</span>
                <p class="normal">Истец</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Где нарушает</span>
                <p class="normal">Онлайн</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Истцы</span>
                <p class="normal">АПС Строительно-Монтажное управление, ООО</p>
                <small>ОГРН 1147746454668</small>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Ответчики</span>
                <p class="normal">Дизайнстройремонт, ООО</p>
                <small>ОГРН 1147746454668</small>
                <p class="normal">Окнасервис, ООО</p>
                <small>ОГРН 1147746454668</small>
                <p class="normal">Докамонтаж, ООО</p>
                <small>ОГРН 1147746454668</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Объект</span>
                <a>Патент № 4345346, 01.01.2010</a>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="obj-info__sub">
        <button (click)="hasExpertise=!hasExpertise">Есть ли экспертиза</button>
        <h2>Информация об экспертизе</h2>
        <table *ngIf="hasExpertise" class="obj-info__table">
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Кто ходатайствовал</span>
                <p class="normal">АПС Строительно-Монтажное управление, ООО</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Дата удовлетворения</span>
                <p class="normal">10.11.2020</p>

              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Название экспертизы</span>
                <p class="normal">Патентоведческая экспертиза</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Эксперт</span>
                <p class="normal">Дроздов Валерий Олегович, Гардиум</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Дата подготовки</span>
                <p class="normal">12.03.2021</p>
              </div>
            </td>
          </tr>
        </table>
        <p *ngIf="!hasExpertise" class="normal normal--noExp">Экспертиза не была проведена</p>
      </div>
      <div *ngIf="hasExpertise" class="obj-info__sub">
        <h2>Информация о государственной пошлине</h2>
        <table class="obj-info__table">
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Сумма</span>
                <p class="normal">13434.00₽</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Плательщик</span>
                <p class="normal">АПС Строительно-Монтажное управление, ООО</p>
                <small>ОГРН 1147746454668</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Дата оплаты</span>
                <p class="normal">05.02.2021</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Подтверждение оплаты</span>
                <a>Скачать платежное поручение</a>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <article class="obj-info__obj">
      <h2>Информация об оппонентах</h2>
      <div class="obj-info__acc-wrapper">
        <div *ngFor="let comp of accordion;index as i"
             class="obj-info__acc-title"
             [class.obj-info__acc-title--open]="comp.isOpen"
             (click)="comp.isOpen=!comp.isOpen"
        >
          <h3 class="obj-info__name"
              [class.obj-info__name--no-border]="i===0"
          >{{comp.name}}</h3>
          <div *ngIf="comp.isOpen" class="obj-info__from-section">
            <div class="obj-info__from-block">
              <span>Полное наименование</span>
              <p>Дизайнстройремонт, ООО</p>
              <p>07.08.2003</p>
            </div>
            <div class="obj-info__from-block">
              <span>Юридический адрес</span>
              <p>Москва, ул Народного ополчения, дом 23, корпус 14, офис 322</p>
            </div>
            <div class="obj-info__from-block">
              <span>Руководитель</span>
              <p>Кивелиди Харлампий Иванович, генеральный директор</p>
            </div>
            <div class="obj-info__from-block">
              <span>Интеллектуальная собственность</span>
              <p>1 товарный знак, 5 договоров</p>
            </div>
            <div class="obj-info__from-block">
              <span>Выручка за 2019</span>
              <p>137 000 ₽</p>
            </div>
            <div class="obj-info__from-block">
              <span>Среднесписочная численность</span>
              <p>50 (2019)</p>
            </div>
            <div class="obj-info__from-block">
              <span>Контактные данные</span>
              <a>Дизайнстройремонт, ООО</a>
              <a>07.08.2003</a>
              <p>+7 495 987 65 43</p>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>

  <div class="obj-info" *ngIf="type==='dispute'">
    <div class="obj-info__subs">
      <div class="obj-info__sub">
        <h2>Информация о претензии</h2>
        <table class="obj-info__table">
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Дата подачи искового заявления</span>
                <p class="big">10.11.2020</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Дата следующего судебного заседания</span>
                <p class="big">12.03.2021</p>
                <small>Вид заседания ― предварительное (первое)</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Требования</span>
                <p class="big">1 222 554,32 ₽</p>
                {{trimString('Размер взысканий, которые требует оппонент', 32)}}
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Вероятность удовлетворения</span>
                <p class="big">Высокая</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Наш процессуальный статус</span>
                <p class="normal">Истец</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Где нарушает</span>
                <p class="normal">Онлайн</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Истцы</span>
                <p class="normal">АПС Строительно-Монтажное управление, ООО</p>
                <small>ОГРН 1147746454668</small>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Ответчики</span>
                <p class="normal">Дизайнстройремонт, ООО</p>
                <small>ОГРН 1147746454668</small>
                <p class="normal">Окнасервис, ООО</p>
                <small>ОГРН 1147746454668</small>
                <p class="normal">Докамонтаж, ООО</p>
                <small>ОГРН 1147746454668</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Объект</span>
                <a>Патент № 4345346, 01.01.2010</a>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="obj-info__sub">
        <h2>Информация об экспертизе</h2>
        <table class="obj-info__table">
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Кто ходатайствовал</span>
                <p class="normal">АПС Строительно-Монтажное управление, ООО</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Дата удовлетворения</span>
                <p class="normal">10.11.2020</p>

              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Название экспертизы</span>
                <p class="normal">Патентоведческая экспертиза</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Эксперт</span>
                <p class="normal">Дроздов Валерий Олегович, Гардиум</p>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Дата подготовки</span>
                <p class="normal">12.03.2021</p>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="obj-info__sub">
        <h2>Информация о государственной пошлине</h2>
        <table class="obj-info__table">
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Сумма</span>
                <p class="normal">13434.00₽</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Плательщик</span>
                <p class="normal">АПС Строительно-Монтажное управление, ООО</p>
                <small>ОГРН 1147746454668</small>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="obj-info__from-block">
                <span>Дата оплаты</span>
                <p class="normal">05.02.2021</p>
              </div>
            </td>
            <td>
              <div class="obj-info__from-block">
                <span>Подтверждение оплаты</span>
                <a>Скачать платежное поручение</a>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <article class="obj-info__obj">
      <h2>Информация об оппоненте</h2>
      <div class="obj-info__from-section">
        <div class="obj-info__from-block">
          <span>Полное наименование</span>
          <p>Дизайнстройремонт, ООО</p>
          <p>07.08.2003</p>
        </div>
        <div class="obj-info__from-block">
          <span>Юридический адрес</span>
          <p>Москва, ул Народного ополчения, дом 23, корпус 14, офис 322</p>
        </div>
        <div class="obj-info__from-block">
          <span>Руководитель</span>
          <p>Кивелиди Харлампий Иванович, генеральный директор</p>
        </div>
        <div class="obj-info__from-block">
          <span>Интеллектуальная собственность</span>
          <p>1 товарный знак, 5 договоров</p>
        </div>
        <div class="obj-info__from-block">
          <span>Выручка за 2019</span>
          <p>137 000 ₽</p>
        </div>
        <div class="obj-info__from-block">
          <span>Среднесписочная численность</span>
          <p>50 (2019)</p>
        </div>
        <div class="obj-info__from-block">
          <span>Контактные данные</span>
          <a>Дизайнстройремонт, ООО</a>
          <a>07.08.2003</a>
          <p>+7 495 987 65 43</p>
        </div>
      </div>
    </article>
  </div>
</div>
