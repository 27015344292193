export default [
  {
    id: 1,
    value: 'Договор лицензии',
    checked: false
  },
  {
    id: 2,
    value: 'Договор сублицензии',
    checked: false
  },
  {
    id: 3,
    value: 'Договор концессии',
    checked: false
  },
  {
    id: 4,
    value: 'Договор субконцессии',
    checked: false
  },
  {
    id: 5,
    value: 'Договор залога',
    checked: false
  }
];
