import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { LayoutService } from '../../../shared/services/layout.service';

@Component({
  selector: 'app-open-top-menu',
  templateUrl: './open-top-menu.component.html',
  styleUrls: ['./open-top-menu.component.scss']
})
export class OpenTopMenuComponent implements OnInit {

  constructor(private readonly layoutService: LayoutService) {
  }

  ngOnInit(): void {
  }

  openModal() {
    this.layoutService.openCreateRequestModal();
  }
}
