<h2 class="heading-h2">Укажите правообладателя {{type !== 'trademark' ? '(не обязательно)' : ''}}</h2>

<form class="form" [formGroup]="tinForm" ngxsForm="trademark.tinForm">
  <app-ui-input
    type="number"
    id="ownerInput"
    formControlName="TIN"
    [loading]="isLoadingOwner"
    placeholder="ОГРН или ИНН"
    (changeValue)="checkValidTIN($event)"
    data-intercom-target="company id"
    (onClick)="showOrganizationsList()"
  ></app-ui-input>

  <app-selection-container></app-selection-container>
  <app-organization-list></app-organization-list>

  <div *ngIf="payerError.length" class="error-text">
    <span>{{payerError}}</span>
  </div>
</form>

<form class="form" *ngIf="showFullForm" [formGroup]="ownerForm" ngxsForm="trademark.ownerForm">
  <app-ui-input formControlName="name" placeholder="Наименование юрлица" [disabled]="true"></app-ui-input>
  <app-ui-input formControlName="head" placeholder="Руководитель" [disabled]="!organization"></app-ui-input>
  <app-ui-input formControlName="address" placeholder="Юридический адрес" [disabled]="!individual"></app-ui-input>
</form>
