<div class="form-sides" *ngIf="!isLoading; else load">
  <div class="form-sides__big-block">
    <div class="form-sides__form" *ngFor="let one of fieldsWho; index as i">
      <div class="form-sides__switch-wrapper" [ngStyle]="{'width':i>0 ? '100%' : ''}" >
        <h2 *ngIf="i===0" class="form-sides__heading">Кто предоставляет право?</h2>
        <a *ngIf="i>0" class="form-sides__delete" (click)="removeOwner('owner', i, true)">Удалить сторону</a>
        <div class="form-sides__switch">
          <label class="switch"  for="{{one.id}}">
            <input type="checkbox" class="visual-hidden switch__input" id="{{one.id}}" [(ngModel)]="one.notRes" (click)="removeOwner('owner', i)">
            <span class="switch__slider round"></span>
          </label>
          <p>Является нерезидентом</p>
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Лицо, временно проживающее на территории страны, туристы и некоторые категории физических лиц.
              Налоги в стране не уплачивают, в отличие от резидентов, проживающих на постоянной основе.
            </p>
          </span>
          </span>
        </div>
      </div>

      <div *ngIf="!one.notRes">
        <app-form-resident [index]="i" [side]="'owner'" (selectNonResident)='selectNonResident($event)'></app-form-resident>
      </div>

      <div *ngIf="one.notRes">
        <app-form-not-resident [index]="i" [side]="'owner'"></app-form-not-resident>
      </div>

    </div>

    <button type="button" class="btn-blue btn-blue--add" (click)="addWho()">Добавить сторону договора</button>
  </div>
  <div class="form-sides__big-block">
    <div class="form-sides__form" *ngFor="let two of fieldsWhom; index as y">
      <div class="form-sides__switch-wrapper" [ngStyle]="{'width':y>0 ? '100%' : ''}" >
        <h2 *ngIf="y===0" class="form-sides__heading">Кто получает право?</h2>
        <a *ngIf="y>0" class="form-sides__delete" (click)="removeOwner('recipient', y, true)">Удалить сторону</a>
        <div class="form-sides__switch">
          <label class="switch"  for="{{two.id}}">
            <input type="checkbox" class="visual-hidden switch__input" id="{{two.id}}" [(ngModel)]="two.notRes" (click)="removeOwner('recipient', y)">
            <span class="switch__slider round"></span>
          </label>
          <p>Является нерезидентом</p>
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Лицо, временно проживающее на территории страны, туристы и некоторые категории физических лиц.
              Налоги в стране не уплачивают, в отличие от резидентов, проживающих на постоянной основе.
            </p>
            </span>
          </span>
        </div>
      </div>

      <div *ngIf="!two.notRes">
        <app-form-resident [index]="y" [side]="'recipient'" (selectNonResident)='selectNonResident($event)'></app-form-resident>
      </div>

      <div *ngIf="two.notRes">
        <app-form-not-resident [index]="y" [side]="'recipient'"></app-form-not-resident>
      </div>
    </div>

    <button type="button" class="btn-blue btn-blue--add" (click)="addWhom()">Добавить сторону договора</button>
  </div>
</div>
<ng-template #load>
  <div class="loader"></div>
</ng-template>
