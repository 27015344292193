import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IntegrationService } from '../../../shared/services/integration.service';
import { HttpService } from '../../../shared/services/http.service';
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';

@Injectable()
export class MonitoringLandingService {

  private user = 'aa';
  private password = 'Aa123456';

  private bodyForElasticRequest = {
    SHA1hash: this.getHash()
  };

  constructor(
    private readonly integrationService: IntegrationService,
    private readonly router: Router,
    private httpService: HttpService,
  ) {
  }

  isLoading = false;
  loadingError = null;
  data = null;

  load() {
    if (this.isLoading) {
      return;
    }

    const riskId = this.integrationService.getRiskId();

    // если у нас нет riskId то тупо редиректим на главную
    if (!riskId) {
      this.router.navigate(['/']);
      return;
    }

    this.isLoading = true;
    this.fetchRiskById(riskId)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        data => {
          this.data = data;
          // после того как мы один раз открыли страницу - удаляем ид чтобы в следующий раз не открывать
          this.integrationService.setRiskIdRedirected();
        },
        (e) => {
          this.loadingError = e;
        }
      );
  }

  fetchRiskById(riskId: string) {
    console.log('riskId', riskId);
    // здесь делаем http-запрос
    // return of({ id: riskId, testData: 1 });
    return this.httpService.post({path: '_explain_risk', isRequestToElastic: true, body: {id: riskId, ...this.bodyForElasticRequest}});
  }

  getHash() {
    return Base64.stringify(sha1(this.user.toLowerCase() + this.password));
  }

}
