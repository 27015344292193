import { Component, OnInit } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IntegrationService } from '../../../../shared/services/integration.service';

@Component({
  selector: 'app-ref-risk-integration',
  templateUrl: './risk-integration-alfa.component.html',
  styleUrls: ['./risk-integration-alfa.component.scss']
})
export class RiskIntegrationAlfaComponent implements OnInit {

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly integrationService: IntegrationService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap
      .pipe(
        map(i => i.get('id')?.toString()),
        filter(i => !!i),
        take(1)
      )
      .subscribe((riskId) => {
        this.integrationService.setRiskId(riskId);
        localStorage.setItem('integration/alfa_monitoring_risk_id', riskId);
        this.router.navigate(['monitoring/landing-alfa'], {queryParams: {id: riskId}});
      });
  }

}
