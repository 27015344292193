import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { EventsService } from '../../../../services/events.service';


@Component({
  selector: 'app-legal-names',
  templateUrl: './legal-names.component.html',
  styleUrls: ['./legal-names.component.scss']
})
export class LegalNamesComponent implements OnInit {

  @Output() hideSearchPopup = new EventEmitter();

  @Output() clickDownload = new EventEmitter();

  @Input() legalNamesData;
  @Input() legalNames;
  @Input() allLegalNames;
  @Input() isLoadingLegalNames;

  @Input() isShowSearchPopup;
  @Input() brandsearch;
  @Input() legalsupport;
  @Input() designation;

  legalNamesOffset = 0;
  selectedLegalName: any;
  showModal = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeLegalNames();
  }

  constructor(
    readonly eventsService: EventsService,
  ) {
  }

  ngOnInit(): void {
    if (this.brandsearch || this.legalsupport) {
      this.countLegalNamesBrandSearch();
    }
  }

  countLegalNamesBrandSearch() {
    if (document.documentElement.clientWidth > 991) {
      if (this.allLegalNames.length >= 6) {
        this.allLegalNames = this.allLegalNames.slice(0, 6);
      } else {
        if (this.allLegalNames.length <= 4) {
          this.allLegalNames = this.allLegalNames.slice(0, 2);
          this.legalNames = this.allLegalNames.slice(0, 2);
        }
      }
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      if (this.allLegalNames.length >= 4) {
        this.allLegalNames = this.allLegalNames.slice(0, 4);
      } else {
        if (this.allLegalNames.length <= 3) {
          this.allLegalNames = this.allLegalNames.slice(0, 1);
          this.legalNames = this.allLegalNames.slice(0, 1);
        }
      }
    }
  }

  changeLegalNameSlider(direction) {
    switch (direction) {
      case 'next':
        if (document.documentElement.clientWidth > 991) {
          this.legalNamesOffset = this.legalNamesOffset + 4;
          this.legalNames = this.allLegalNames.slice(this.legalNamesOffset, this.legalNamesOffset + 4);
        } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
          this.legalNamesOffset = this.legalNamesOffset + 3;
          this.legalNames = this.allLegalNames.slice(this.legalNamesOffset, this.legalNamesOffset + 3);
        }
        break;
      case 'back':
        if (document.documentElement.clientWidth > 991) {
          this.legalNamesOffset = this.legalNamesOffset - 4;
          this.legalNames = this.allLegalNames.slice(this.legalNamesOffset, this.legalNamesOffset + 4);
        } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
          this.legalNamesOffset = this.legalNamesOffset - 3;
          this.legalNames = this.allLegalNames.slice(this.legalNamesOffset, this.legalNamesOffset + 3);
        }
        break;
    }
  }

  openModalNew(legalName) {
    if (this.isShowSearchPopup) {
      // TODO: quick fix for close search popup
      this.hideSearchPopup.emit();
    }
    this.selectedLegalName = legalName;
    this.showModal = true;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'hidden');
  }

  isOverlongName(legalName): boolean {
    if (legalName._source.name) {
      return legalName._source.name.length > 60 || this.getShortNameByWords(legalName._source.name).changed;
    } else {
      return legalName._source.fullName.length > 60 || this.getShortNameByWords(legalName._source.fullName).changed;
    }
  }

  getShortNameByRows(name) {
    return name.length > 60 ? name.slice(0, 60) : name;
  }

  getShortNameByWords(name) {
    let nameArray = name.split(' ');
    nameArray = nameArray.reverse();
    let nameIndex = 0;
    nameArray.forEach((item, index) => {
      if (item.length > 20) {
        nameIndex = index;
      }
    });
    if (nameIndex) {
      const shortNameArr = nameArray.slice(0, nameIndex + 1);
      shortNameArr[shortNameArr.length - 1] = shortNameArr[shortNameArr.length - 1].substr(0, 17);
      return {
        changed: true,
        name: shortNameArr.join(' ')
      };
    } else {
      return {
        changed: false,
        name
      };
    }
  }

  closeModal() {
    this.showModal = false;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  isViewLoadCard(): boolean {
    if (document.documentElement.clientWidth > 991) {
      return (this.brandsearch || this.legalsupport) && this.legalNamesOffset === 4 * ((this.allLegalNames.length - this.allLegalNames.length % 4) / 4) && this.allLegalNames.length;
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      return (this.brandsearch || this.legalsupport) && this.legalNamesOffset === 3 * ((this.allLegalNames.length - this.allLegalNames.length % 3) / 3) && this.allLegalNames.length;
    } else if (document.documentElement.clientWidth <= 767) {
      return (this.brandsearch || this.legalsupport) && this.allLegalNames.length;
    }
  }

  emitClickDownload() {
    return this.clickDownload.emit();
  }

  resizeLegalNames(): void {
    if (document.documentElement.clientWidth > 991) {
      this.legalNames = this.allLegalNames.slice(0, 4);
      this.legalNamesOffset = 0;
    } else if (document.documentElement.clientWidth <= 991 && document.documentElement.clientWidth > 767) {
      this.legalNames = this.allLegalNames.slice(0, 3);
      this.legalNamesOffset = 0;
    } else if (document.documentElement.clientWidth <= 767 && !this.brandsearch && !this.legalsupport) {
      this.legalNames = this.allLegalNames;
      this.legalNamesOffset = 0;
    } else if (document.documentElement.clientWidth <= 767 && (!this.brandsearch || !this.legalsupport)) {
      this.legalNames = this.allLegalNames.slice(0, 3);
      this.legalNamesOffset = 0;
    }
  }

}
