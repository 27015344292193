import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IDeleteParams, IGetParams, IPostParams, IPutParams } from '../models/rest.interfaces';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
// import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

const baseUrl = environment.BASE_URL_API;

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(
    private http: HttpClient,
    // private toastr: ToastrService
  ) {
  }

  public static getHeaders() {
    return {
      'Content-Type': 'application/json; charset=utf-8',
    };
  }

  get<T>(getParams: IGetParams): Observable<T> {
    const {path, params = {}} = getParams;

    return this.http.get<T>(`${baseUrl}/${path}`, {headers: HttpService.getHeaders(), params})
      .pipe(catchError(this.handleError(`get ${path}`, null)));
  }

  post<T>(postParams: IPostParams): Observable<T> {
    const {path, body} = postParams;

    return this.http.post<T>(`${baseUrl}/${path}`, body, {headers: HttpService.getHeaders()})
      .pipe(
        tap(() => {
          console.log('ok');
          // this.success(`Successfully created ${subjectType}`)
        }),
        catchError(this.handleError(`${path}`, null)));
  }

  put<T>(putParams: IPutParams): Observable<T> {
    const {path, body} = putParams;

    return this.http.put<T>(`${baseUrl}/${path}`, body, {headers: HttpService.getHeaders()})
      .pipe(
        tap(() => {
          // this.success(`Successfully updated ${subjectType}`)
        }),
        catchError(this.handleError(`${path}`, null)),
      );
  }

  delete<T>(deleteParams: IDeleteParams) {
    const {path, id} = deleteParams;

    return this.http
      .delete(`${baseUrl}/${path}/${id}`, {headers: HttpService.getHeaders()})
      .pipe(
        tap(() => {
          // this.success(`Successfully deleted ${subjectType}`)
        }),
        catchError(this.handleError(`delete ${path}`, null)),
      );
  }

  handleError<T>(operation = 'operation', result: T = null): (error) => Observable<T> {
    return (error) => {

      // TODO: send the error to remote logging infrastructure
      console.error(operation, error); // log to console instead
      // tslint:disable-next-line:max-line-length
      this.error(`Operation ${operation} failed with error: ${error && error.error && error.error.message || 'undefined error'}`); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  success(message) {
    // this.toastr.success(message);
  }

  error(message) {
    // this.toastr.error(message, 'Ошибка');
  }
}
