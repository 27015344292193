import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {Store} from '@ngxs/store';
import { AppState } from '@web/core/states/app.state';
import { Filters, FiltersLayout } from '@ui/filters/models/filters.interface';
import { FilterService } from '@ui/filters/services/filter.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-filters-container',
  templateUrl: './filters-container.component.html',
  styleUrls: ['./filters-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersContainerComponent<T extends Filters<any>> {
  private _def: T;
  @Input() set def(value: T) {
    this._def = value;
    for (const input of value) {
      if (input.filterItem) {
        this.filterService.setFn(input.name, input.filterItem);
      }
    }
  }
  get def() {
    return this._def;
  }
  @Input() value: Record<string, any> = {};
  @Input() layout: FiltersLayout;
  @Input() clear$: Observable<void>;
  @Output() apply = new EventEmitter<Record<string, any>>();
  @Input() smButtonTitle = 'Фильтр по услугам';
  @Input() filterValue: Record<string, any>;
  @Input() currentTab: string;

  currentLayout$ = this.store.select(AppState.getLayout);

  constructor(
    private store: Store,
    private filterService: FilterService,
  ) { }

  onApply(value: Record<string, any>) {
    this.apply.emit(value);
    this.filterService.value = value;
  }
}
