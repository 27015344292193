import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { IpIdSearchComponent } from './pages/ip-id-search/ip-id-search.component';

// libraries
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

import { FooterComponent } from './components/footer/footer.component';
import { TrademarkModalComponent } from './pages/ip-id-search/result/trade-marks/trademark-modal/trademark-modal.component';
import { TrademarkModalNewComponent } from './pages/ip-id-search/result/trade-marks/trademark-modal-new/trademark-modal-new.component';
import { LegalNameModalComponent } from './pages/ip-id-search/result/legal-names/legal-name-modal/legal-name-modal.component';
import { DomainModalComponent } from './pages/ip-id-search/result/domain-names/domain-modal/domain-modal.component';
import { LoadingModalComponent } from './pages/ip-id-search/result/loading-modal/loading-modal.component';
import {
  TrademarkApplicationComponent
} from './pages/ip-id-search/result/trade-marks/trademark-application/trademark-application.component';
import { NmptModalComponent } from './pages/ip-id-search/result/trade-marks/nmpt-modal/nmpt-modal.component';
import { RightNmptModalComponent } from './pages/ip-id-search/result/trade-marks/right-nmpt-modal/right-nmpt-modal.component';
import {
  InternationalTrademarkModalComponent
} from './pages/ip-id-search/result/trade-marks/international-trademark-modal/international-trademark-modal.component';
import {
  ApplicationNmptModalComponent
} from './pages/ip-id-search/result/trade-marks/application-nmpt-modal/application-nmpt-modal.component';
import {
  WellKnownTrademarkModalComponent
} from './pages/ip-id-search/result/trade-marks/well-known-trademark-modal/well-known-trademark-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SearchRisksComponent } from './pages/ip-id-search/result/search-risks/search-risks.component';
import { RadioButtonComponent } from './shared/components/radio-button/radio-button.component';
import { AccordionComponent } from './shared/components/accordion/accordion.component';
import { TableComponent } from './shared/components/table/table.component';
import { TradeMarksComponent } from './pages/ip-id-search/result/trade-marks/trade-marks.component';
import { LegalNamesComponent } from './pages/ip-id-search/result/legal-names/legal-names.component';
import { DomainNamesComponent } from './pages/ip-id-search/result/domain-names/domain-names.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SimpleProgressViewComponent } from './shared/components/simple-progress-view/simple-progress-view.component';
import { ProgressViewComponent } from './shared/components/progress-view/progress-view.component';
import { SearchRisksBrandComponent } from './pages/ip-id-search/result/search-risks-brand/search-risks-brand.component';
import { IpIdSearchInputComponent } from './pages/ip-id-search-input/ip-id-search-input.component';
import { ChoiceClassesModalComponent } from './pages/ip-id-search-input/choice-classes-modal/choice-classes-modal.component';
import { SharedModule } from '../../../app-ipid/src/app/shared/shared.module';
import { IntercomService } from '../../../app-ipid/src/app/shared/services/intercom.service';
import { IpIdSearchInputCorporateComponent } from './pages/ip-id-search-input-corporate/ip-id-search-input-corporate.component';
import {
  ForeignTrademarkModalComponent
} from './pages/ip-id-search/result/trade-marks/foreign-trademark-modal/foreign-trademark-modal.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxsModule } from '@ngxs/store';
import { WebModule } from '@web/web.module';
import { environment } from '../../../app-search-ls/src/environments/environment';
import { ErrorService } from '../../../app-ipid/src/app/shared/services/error-service';
import { ToastrModule } from 'ngx-toastr';

const components = [
  AppComponent,
  HeaderComponent,
  IpIdSearchComponent,
  FooterComponent,
  TrademarkModalComponent,
  TrademarkModalNewComponent,
  LegalNameModalComponent,
  DomainModalComponent,
  LoadingModalComponent,
  TrademarkApplicationComponent,
  NmptModalComponent,
  RightNmptModalComponent,
  InternationalTrademarkModalComponent,
  ApplicationNmptModalComponent,
  WellKnownTrademarkModalComponent,
  SearchRisksComponent,
  RadioButtonComponent,
  AccordionComponent,
  TableComponent,
  TradeMarksComponent,
  LegalNamesComponent,
  DomainNamesComponent,
  SimpleProgressViewComponent,
  ProgressViewComponent,
  SearchRisksBrandComponent,
  SimpleProgressViewComponent,
  ProgressViewComponent,
  SearchRisksBrandComponent,
  IpIdSearchInputComponent,
  ChoiceClassesModalComponent
];

@NgModule({
  declarations: [
    ...components,
    ForeignTrademarkModalComponent,
    IpIdSearchInputCorporateComponent
  ],
  exports: [...components, IpIdSearchInputCorporateComponent],
  imports: [
    BrowserModule,
    ClickOutsideModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    // ReactiveFormsModule,
    // MatFormFieldModule,
    // MatSelectModule,
    // MatInputModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
    }),
    MatTooltipModule,
    SharedModule,
    BsDatepickerModule,
    NgxsModule.forRoot(),
    WebModule.forRoot({
      production: environment.production,
      BASE_URL_API: environment.BASE_URL_API,
      BASE_ELASTIC_URL_API: '',
      isDebit: false
    })
  ],
  providers: [
    IntercomService,
    ErrorService
  ],
  entryComponents: [
    IpIdSearchComponent
  ]
  // providers: [],
  // bootstrap: [AppComponent]
})
export class IpIdSearchModule {
  constructor(private injector: Injector) {
  }

  ngDoBootstrap() {
    const el = createCustomElement(IpIdSearchComponent, { injector: this.injector });

    customElements.define('app-ip-id-search', el);
  }
}
