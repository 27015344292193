import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cases-list-filters',
  templateUrl: './cases-list-filters.component.html',
  styleUrls: ['./cases-list-filters.component.scss']
})
export class CasesListFiltersComponent implements OnInit {

  @Input() showMobileControl;
  @Input() caseStatuses;
  @Input() caseRequestTypes;
  @Input() allCases;
  @Input() filteredCases;
  @Input() activeTab;

  @Output() applyFilters = new EventEmitter<any>();
  @Output() clearFilters = new EventEmitter();

  searchText = '';
  // Выпадающее меню Статус
  showStatusType = false;
  // Выпадающее меню Тип работ
  showRequestType = false;

  constructor(
  ) {
  }

  get countAllCases() {
    return this.allCases[this.activeTab].length || 0;
  }

  ngOnInit(): void {
  }

  applyFiltersEmit() {
    this.applyFilters.emit({
      searchText: this.searchText,
      caseStatuses: this.caseStatuses.filter(i => i.checked),
      caseRequestTypes: this.caseRequestTypes.filter(i => i.checked),
    });
  }

  checkAppliedFilters() {
    return this.searchText || this.getCountCheckedObjects(this.caseStatuses) || this.getCountCheckedObjects(this.caseRequestTypes);
  }

  getCasesCount(type) {
    if (type === 'all') {
      return this.allCases[this.activeTab].length;
    } else if (type === 'filtered') {
      return this.filteredCases[this.activeTab].length;
    }
  }

  clearFiltersEmit() {
    this.clearFilters.emit();
    this.searchText = '';
    // this.caseStatuses.forEach(item => {
    //   item.checked = false;
    // });
    // this.caseRequestTypes.forEach(item => {
    //   item.checked = false;
    // });
  }

  getCountCheckedObjects(massive) {
    let counter = 0;
    massive.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });

    return counter;
  }

  // Убрать скрол под модалкой с фильтром
  makeBodyHidden() {
    if (window.innerWidth <= 793) {
      if (this.showMobileControl === true) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.height = '100%';
      } else {
        document.body.style.overflow = 'visible';
        document.body.style.position = '';
        document.body.style.height = 'auto';
      }
    }
  }

}
