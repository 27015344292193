<h1 class="heading-h1" id="mktuHeader">Выбор классов МКТУ
  <span class="prompt prompt__question">?
     <span class="prompt__hidden">
         <p>Международная классификация товаров и услуг, используемая для регистрации товарных знаков</p>
     </span>
   </span>
</h1>
<div *ngIf="owner?.inn">
  <p class="subtitle" *ngIf="lastTrademark?._index === 'rutmap'">Классы подобраны на основании вашей последней заявки на регистрацию товарного знака</p>
  <p class="subtitle" *ngIf="lastTrademark?._index === 'rutm'">Классы подобраны из последнего зарегистрированного товарного знака</p>
  <p class="subtitle" *ngIf="mktuSearch">Классы подобраны из результатов вашего поиска</p>
  <p class="subtitle" *ngIf="!mktuSearch && !lastTrademark">Классы подобраны исходя из видов деятельности правообладателя в ЕГРЮЛ</p>
</div>
<form class="js-form form">
  <div class="request__form" >
    <div class="not-mobile-mktu" >
      <app-ip-id-search *ngIf="showSearchInput"
                        (eventSelected)="eventSelectedClass($event)"
                        (eventDeleted)="eventDeletedClass($event)"
                        [options]="optionsMktu"
                        style="width: 100%;"
      >
      </app-ip-id-search>
    </div>
    <div  class="mobile-mktu">
      <app-mktu-mobile
      [selectedMktu]="MKTUCodesShort"
      (deleteClass)="eventDeletedClass($event)"
      (applyCodes)="eventApplyClasses($event)"
      >
      </app-mktu-mobile>
    </div>
  </div>
  <div class="duty-info" *ngIf="MKTUCodesShort.length">Вы добавили {{MKTUCodesShort.length}} {{declOfNum(MKTUCodesShort.length)}} МКТУ, размер пошлин Роспатента <span class="sum">{{(getDutySum().sum).toLocaleString('ru')}}</span></div>
  <hr class="separator-line">
  <div class="mktu-block" *ngIf="lastTrademark">
    <div class="mktu-block__block">
      <h3>МКТУ из
        <span *ngIf="lastTrademark._index === 'rutm'">товарного знака</span>
        <span *ngIf="lastTrademark._index === 'rutmap'">заявки</span>
        №{{lastTrademark._source.applicationString}} от
        <span *ngIf="lastTrademark._index === 'rutmap'; else tm">{{lastTrademark._source.applicationDate | date:'dd.MM.yyyy'}}</span>
        <ng-template #tm>{{lastTrademark._source.registrationDate | date:'dd.MM.yyyy'}}</ng-template>
      </h3>
    </div>
    <div class="mktu-block__block">
      <div class="mktu-block__img">
        <img  [src]="lastTrademark._source.imageUrl" alt="">
      </div>
      <div class="mktu-block__text">
        <div class="mktu-block__row">
          <p class="mktu-block__text--gray">Классы МКТУ</p>
          <div class="mktu-block__classes">
            <div class="mktu-block__class-item" *ngFor="let item of lastTrademark._source.goodsServices">
              <div>{{item.classNumber}}
                <span class="prompt__hidden"><p>{{trimString (item.gsDescription, 250)}}</p></span>
              </div>
            </div>
            <!--            <div class="mktu-block__class-item">-->
            <!--              <div>2-->
            <!--                <span class="prompt__hidden"><p>Всплывает тултип с описанием данного МКТУВсплывает тултип с описанием данного МКТУ</p></span>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="mktu-block__class-item">-->
            <!--              <div>3-->
            <!--                <span class="prompt__hidden"><p>Всплывает </p></span>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="mktu-block__class-item">-->
            <!--              <div>33-->
            <!--                <span class="prompt__hidden"><p>Всплывает тултип с описанием данного МКТУ</p></span>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="mktu-block__row">
          <p class="mktu-block__text--gray">Правообладатель</p>
          <div>
            <p id="ownerName">{{lastTrademark._source.applicantName}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  <div class="mktu-block__error">-->
  <!--    <p>Кажется, что-то пошло не так. Если ошибка повторяется, попробуйте повторить позже.</p>-->
  <!--  </div>-->

  <div class="okved" *ngIf="OKVEDCodes && OKVEDCodes.length && !lastTrademark && !mktuSearch">
    <div class="okved-title">ОКВЭД из ЕГРЮЛ для {{ownerShortName}}</div>
    <div class="okved-codes">
      <div class="okved-code" *ngFor="let okved of OKVEDCodes">
        {{getShortOkved(okved.code + ' ' + okved.description)}}
        <div class="okved-tooltip" *ngIf="(okved.code + ' ' + okved.description).length > 40">
          {{okved.description}}
        </div>
      </div>
    </div>
  </div>
</form>
<div class="error-message"*ngIf="step2ButtonClicked && !loadindErrorStep2Send">
  Кажется, что-то пошло не так. Если ошибка повторяется, попробуйте повторить позже.
</div>
<div class="btn-wrap" >
  <a class="back-button" (click)="backStep()">
    <svg width="21" height="16" viewBox="0 0 21 16">
      <path d="M0.292893 7.29289C-0.0976314 7.68342 -0.0976315 8.31658 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292893 7.29289ZM21 7L1 7L1 9L21 9L21 7Z"/>
    </svg>
    <div class="text">Назад</div>
  </a>
  <a class="btn step2to3-btn" (click)="step2to3Click()" id="nextStep">
    <div class="btn-body">
              <span *ngIf="!step2ButtonClicked || loadindErrorStep2Send else repeatText">
                Далее
                <div>
            </div>
              </span>
      <ng-template #repeatText>
        <span>Повторить</span>
      </ng-template>
      <div *ngIf="!step2ButtonClicked else error" class="arrow-request-icon">
        <!--              <div *ngIf="false else error" class="arrow-request-icon">-->
        <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"
            fill="white"/>
        </svg>
      </div>
      <ng-template #error>
        <span class="loaderAuth" *ngIf="loadindErrorStep2Send else repeat"></span>
        <ng-template #repeat>
          <div class="repeat">
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.0673162 9.85268C0.606606 5.03829 4.45517 1.17992 9.26955 0.630825C15.5645 -0.089861 20.9231 4.80297 20.9917 10.9362L23.8156 10.9362C23.9725 10.9362 24.0559 11.1323 23.9578 11.2646L19.6876 17.0792C19.6141 17.1772 19.4719 17.1772 19.3984 17.0792L15.1282 11.2646C15.0301 11.1323 15.1184 10.9362 15.2704 10.9362L18.0894 10.9362C18.0207 6.62674 14.3536 3.17529 9.97062 3.46945C6.2005 3.71948 3.14126 6.79343 2.90104 10.5636C2.6461 14.5592 5.49943 17.9567 9.27445 18.5647C9.97552 18.6774 10.4854 19.2902 10.4854 19.9962C10.4854 20.8738 9.70588 21.5602 8.83811 21.4229C3.45993 20.5698 -0.574928 15.6035 0.0673162 9.85268Z"
                fill="white"/>
            </svg>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </a>
</div>
