import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
@Component({
  selector: 'app-system-layout',
  templateUrl: './system-layout.component.html',
  styleUrls: ['./system-layout.component.scss'],
})

export class SystemLayoutComponent implements OnInit {
  constructor(
    private shared: SharedService
    ) {
  }

  ngOnInit() {}

  closeDropdowns() {
    // TODO: подумать над этим
    this.shared.closeModals.next(null);
  }
}
