<div class="paragraph_header">
  <p
    class="promo_text"
    *ngIf="!isShowPromoInput && !showSuccess"
    (click)="showPromoInput()"
  >У меня есть промокод</p>
</div>

<input
  type="text"
  class="promo_input"
  placeholder="Промокод"
  [(ngModel)]="promoCode"
  *ngIf="isShowPromoInput && !showSuccess"
/>
<button
  type="button"
  class="btn-blue_promo"
  (click)="getPromoCode()"
  *ngIf="isShowPromoInput && !showSuccess"
  [class.disabled]="!promoCode.length"
>Применить</button>

<p class="success_paragraph" *ngIf="showSuccess">Применен промокод {{promoCode}}</p>
