import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchService } from '../../../../../services/search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

@Component({
  selector: 'app-trademark-application',
  templateUrl: './trademark-application.component.html',
  styleUrls: ['./trademark-application.component.scss']
})

export class TrademarkApplicationComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() modalEvent = new EventEmitter();
  private readonly onDestroy = new Subject<void>();
  sourceData: any = {};
  isLoading = false;
  viewClassesCount = 'short';
  contractsData = [];
  overTradeMarks;
  countOverTradeMarks;
  isShowLogoModal = false;

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  constructor(
    private searchService: SearchService,
    private corp: CorpSearchService,
    private ref: ChangeDetectorRef,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  ngOnInit() {
    this.getDocById();
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '0';
    }
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.OPEN_ITEM, item_type: 'Заявка на товарный знак' });

  }

  getOverTradeMarks() {
    const query = {
      id: this.sourceData._source.PSRN ? this.sourceData._source.PSRN.toString() : this.sourceData._source.TIN.toString(),
      searchTradeMarks: true
    };
    this.searchService.getOverTradeMarksOrDomains(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data && data.hits && data.hits.hits) {
        data.hits.hits.forEach((item) => {
          let id = item._source.registrationString;
          if (item._index === 'rutmap' && item._source.applicationString) {
            id = item._source.applicationString;
          }
          item._source.imageUrl = this.searchService.getImgUrl(
            item._index,
            parseInt(id, 10),
            item._source.markImageFileName,
            id
          );
          item._source.indexType = this.searchService.getIndexType(item);
        });

        this.overTradeMarks = data.hits.hits;
        this.countOverTradeMarks = data.hits.total?.tradeMarks;

        let deleteIndex;
        this.overTradeMarks.forEach((item, index) => {
          if (item._source.indexType === this.sourceData.indexType) {
            deleteIndex = index;
          }
        });
        if (deleteIndex || (deleteIndex === 0)) {
          this.overTradeMarks.splice(deleteIndex, 1);
        }
        this.ref.detectChanges();
      }
    });
  }

  getExternalLink(index) {
    return this.corp.getExternalUrl(index);
  }

  getDocById() {
    const query = {
      _id: this.data._id,
      _index: this.data._index
    };
    this.isLoading = true;
    this.searchService.getDocumentById(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits?.length) {
        this.sourceData = data.hits.hits[0];
        this.sourceData._source.goodsServices.forEach((item, index) => {
          if (item.classNumber === 0) {
            this.sourceData._source.goodsServices.splice(index, 1);
          }
        });
        this.sourceData.indexType = this.searchService.getIndexType(this.sourceData);
        this.isLoading = false;
        this.ref.detectChanges();
        this.createContractsData();
        if (this.sourceData._source.PSRN || this.sourceData._source.TIN) {
          this.getOverTradeMarks();
        }
      }
    });
  }

  createContractsData() {
    if (this.sourceData._source.contracts) {
      this.sourceData._source.contracts.forEach(item => {
        if (item.contractNumber !== 'Нет договора') {
          this.contractsData.push(item);
        }
        if (item.subContracts && item.subContracts.length > 0) {
          this.contractsData = this.contractsData.concat(item.subContracts);
        }
      });
      this.ref.detectChanges();
    }
  }

  getMktuClasses() {
    if (this.viewClassesCount === 'long') {
      return this.sourceData._source.goodsServices;
    } else {
      return this.sourceData._source.goodsServices.slice(0, 4);
    }
  }

  showAllClasses() {
    this.viewClassesCount = 'long';
  }

  getStatusTrademark(sourceData): string {
    let status = '';
    switch (sourceData._source.statusFIPS) {
      case 10:
        status = 'Пошлина за регистрацию не оплачена';
        break;
      case 14:
        status = 'Формальная экспертиза';
        break;
      case 15:
        status = 'Экспертиза заявленного обозначения';
        break;
      case 16:
        status = 'Заявка отозвана';
        break;
      case 17:
        status = 'Принято решение о регистрации товарного знака';
        break;
      case 18:
        status = 'Принято решение об отказе в регистрации товарного знака';
        break;
      case 19:
        status = 'Регистрация товарного знака';
        break;
      case 20:
        status = 'Принято решение о признании заявки отозванной';
        break;
      case 21:
        status = 'Заявка отозвана по просьбе заявителя';
        break;
    }
    return status;
  }

  getChangeCode(change) {
    let changeCode = '';
    if (change.changeType === 'Исходящая корреспонденция') {
      switch (change.changeCode) {
        case '730':
          changeCode = 'Решение о принятии заявки к рассмотрению';
          break;
        case '950':
          changeCode = 'Запрос формальной экспертизы';
          break;
        case '920':
          changeCode = 'Решение об отказе в принятии заявки к рассмотрению';
          break;
        case '200':
          changeCode = 'Уведомление о необходимости соглашения по заявкам на тождественные знаки';
          break;
        case '700':
          changeCode = 'Уведомление о результатах проверки соответствия';
          break;
        case '002':
          changeCode = 'Уведомление о представлении перевода документов';
          break;
        case '003':
          changeCode = 'Письмо по вопросам делопроизводства';
          break;
        case '100':
          changeCode = 'Запрос экспертизы заявленного обозначения';
          break;
        case '010':
          changeCode = 'Решение о регистрации';
          break;
        case '020':
          changeCode = 'Решение об отказе в регистрации';
          break;
        case '740':
          changeCode = 'Уведомление о невозможности выдачи свидетельства';
          break;
        case '211':
          changeCode = 'Уведомление об удовлетворении ходатайства';
          break;
        case '212':
          changeCode = 'Уведомление об отказе в удовлетворении ходатайства';
          break;
        case '221':
          changeCode = 'Уведомление об удовлетворении ходатайства';
          break;
        case '222':
          changeCode = 'Уведомление об отказе в удовлетворении ходатайства';
          break;
        case '231':
          changeCode = 'Уведомление об удовлетворении ходатайства';
          break;
        case '232':
          changeCode = 'Уведомление об отказе в удовлетворении ходатайства';
          break;
        case '013':
          changeCode = 'Решение об изменении наименования заявителя';
          break;
        case '051':
        case '052':
        case '053':
          changeCode = 'Решение о признании заявки отозванной';
          break;
        case '710':
          changeCode = 'Уведомление о принятии к сведению заявления об отзыве';
          break;
        case '006':
          changeCode = 'Уведомление об удовлетворении просьбы ';
          break;
        case '201':
          changeCode = 'Уведомление об удовлетворении ходатайства';
          break;
        case '202':
          changeCode = 'Уведомление об отказе в удовлетворении ходатайства ';
          break;
        case '281':
          changeCode = 'Уведомление об удовлетворении ходатайства ';
          break;
        case '282':
          changeCode = 'Уведомление об отказе в удовлетворении ходатайства';
          break;
        case '283':
          changeCode = 'Уведомление о представлении документов';
          break;
        case '284':
          changeCode = 'Уведомление о признании ходатайства неподанным';
          break;
        case '009':
          changeCode = 'Письмо о высылке копий';
          break;
        case '190':
          changeCode = 'Письмо об отказе в высылке копий';
          break;
        case '180':
          changeCode = 'Уведомление о представлении документов';
          break;
        case '721':
          changeCode = 'Письмо об учете пошлины';
          break;
        case '722':
          changeCode = 'Письмо о необходимости доплаты пошлины';
          break;
        case '723':
          changeCode = 'Письмо о том, что пошлина не учтена';
          break;
        case '008':
          changeCode = 'Письмо по вопросам делопроизводства';
          break;
        case '290':
          changeCode = 'Письмо о пошлине';
          break;
        case '401':
          changeCode = 'Уведомление об удовлетворении просьбы';
          break;
        case '402':
          changeCode = 'Уведомление об отказе в удовлетворении просьбы';
          break;
        case '403':
          changeCode = 'Уведомление о представлении документов ';
          break;
        case '005':
          changeCode = 'Письмо об уточнении адреса';
          break;
        case '404':
          changeCode = 'Письмо по вопросам делопроизводства';
          break;
        case '001':
          changeCode = 'Письмо произвольной формы';
          break;
        case '280':
          changeCode = 'Напоминание о необходимости уплаты пошлин';
          break;
        case '940':
          changeCode = 'Уведомление о поступлении документов заявки';
          break;
        case '731':
          changeCode = 'Решение о принятии заявки к рассмотрению';
          break;
        case '102':
          changeCode = 'Запрос пошлины за ЭЗО';
          break;
        case '103':
          changeCode = 'Запрос об уточнении перечня товаров и /или услуг';
          break;
        case '004':
          changeCode = 'Письмо о соответствии перечня товаров и/или услуг требованиям Кодекса';
          break;
        case '951':
        case '724':
          changeCode = 'Уведомление о результатах проверки пошлины';
          break;
        case '407':
          changeCode = 'Уведомление об отказе в совершении юр. действия';
          break;
        case '054':
          changeCode = 'Решение о признании заявки отозванной';
          break;
        case '081':
        case '082':
          changeCode = 'Решение об отмене ранее принятого решения';
          break;
      }
    } else {
      switch (change.changeCode) {
        case 'ПЕР':
          changeCode = 'Перевод документов';
          break;
        case 'ДОВ':
          changeCode = 'Доверенность';
          break;
        case 'КОН':
          changeCode = 'Просьба об установлении конвенционного приоритета';
          break;
        case 'КПЗ':
          changeCode = 'Копия первой заявки';
          break;
        case 'ВЫС':
          changeCode = 'Просьба об установлении выставочного приоритета';
          break;
        case 'ДПП':
          changeCode = 'Подтверждение правомерности испрашивания приоритета';
          break;
        case 'ОТД':
          changeCode = 'Досрочное прекращение доверенности';
          break;
        case 'ДПМ':
          changeCode = 'Дополнительные материалы';
          break;
        case 'ДОМ':
          changeCode = 'Дополнительные материалы';
          break;
        case 'ТЗК':
          changeCode = 'Ходатайство о преобразовании заявки';
          break;
        case 'КТЗ':
          changeCode = 'Ходатайство о преобразовании заявки';
          break;
        case 'ТТЗ':
          changeCode = 'Сообщение о соглашении по заявкам на тождественные знаки';
          break;
        case 'ХВЗ':
          changeCode = 'Просьба об установлении приоритета выделенной заявки';
          break;
        case 'ПСР':
          changeCode = 'Ходатайство о продлении установленного срока ';
          break;
        case 'ВСР':
          changeCode = 'Ходатайство о восстановлении пропущенного срока ';
          break;
        case 'ИЗП':
          changeCode = 'Заявление о внесении изменений в документы заявки';
          break;
        case 'ИЗЗ':
          changeCode = 'Заявление об изменении наименования заявителя';
          break;
        case 'ИПО':
          changeCode = 'Заявление об изменении сведений о заявителе в случае передачи или перехода права';
          break;
        case 'ИЗМ':
          changeCode = 'Заявление о внесении исправлений в документы заявки';
          break;
        case 'АДР':
          changeCode = 'Ходатайство о внесении изменений в адрес';
          break;
        case 'ДТП':
          changeCode = 'Заявление о внесении исправлений';
          break;
        case 'ОТЗ':
          changeCode = 'Просьба заявителя об отзыве заявки';
          break;
        case 'ООТ':
          changeCode = 'Отзыв корреспонденции';
          break;
        case 'ПВК':
          changeCode = 'Просьба выслать копии противопоставленных материалов';
          break;
        case 'ТРФ':
          changeCode = 'Просьба об оказании услуги по тарифу';
          break;
        case 'ППД':
        case 'ПВД':
        case 'ПДК':
        case 'ПОШ':
        case 'ППЭ':
          changeCode = 'Платежный документ';
          break;
        case 'ЗАП':
          changeCode = 'Ходатайство о зачете пошлины';
          break;
        case 'ВЗП':
          changeCode = 'Ходатайство о возврате пошлины';
          break;
        case 'ДОТ':
          changeCode = 'Письмо для ответа';
          break;
        case 'СВЕ':
          changeCode = 'Письмо, не требующее ответа';
          break;
        case 'КОП':
          changeCode = 'Письмо для ответа на контроле дирекции';
          break;
        case 'ОБР':
          changeCode = 'Обращение';
          break;
        case 'ФКС':
          changeCode = 'Корреспонденция, поступившая по факсу';
          break;
        case 'ВИК':
          changeCode = 'Возврат почтового отправления';
          break;
        case 'ДТУ':
          changeCode = 'Дополнительные материалы';
          break;
        case 'ЛИК':
          changeCode = 'Ходатайство о ведении переписки через личный кабинет';
          break;
        case 'ЛКФ':
          changeCode = 'Корреспонденция, поступившая через личный кабинет и требующаяпредставления оригинала документа';
          break;
        default:
          changeCode = 'Заявка';
      }
    }
    return changeCode;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  closeModal() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '1';
    }
    this.modalEvent.emit();
  }

  showLogoModal(): void {
    this.isShowLogoModal = !this.isShowLogoModal;
  }
}
