import {Component, forwardRef, Input, Output, EventEmitter} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessorComponent } from '../abstract-value-accessor/abstract-value-accessor.component';

@Component({
  selector: 'app-ui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    }
  ]
})
export class InputComponent extends AbstractValueAccessorComponent {

  disabled = false;

  @Input() val = '';

  @Input() icon = 'assets/icons/icon-search.svg';

  @Input() placeholder: string;

  @Input() legalName: string;

  @Output() setValue = new EventEmitter<string>();

  @Output() setLegal = new EventEmitter<string>();

  @Output() search = new EventEmitter();

  set value(val: string) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch();
      this.setValue.emit(val);
    }
  }

  public update(value: any): void {
    this.value = value;
    this.setLegal.emit(value);
  }

  public searchObjects($event: Event): void {
    this.search.emit($event);
  }

  public getDynamicMarginForLegalName(): { left: string } {
    switch (this.legalName.length) {
      case 10:
        return {left: '112px'};
      case 12:
        return {left: '130px'};
      case 13:
        return {left: '138px'};
      case 15:
        return {left: '154px'};
    }
  }

  public trimString(str, count): string {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
