import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StorateType } from '../enums/storate-type';
import { ApiParams } from '../models/api/api-params';
import { map } from 'rxjs/operators';
import {
  ApiResponse,
  IApiResponse,
} from '../models/api/api-response.interface';
import {StrorageService} from '../../../../../shared/services/storage-service';

const baseUrl =
  environment.BASE_URL_API && environment.BASE_URL_API !== ''
    ? environment.BASE_URL_API
    : `${window.location.protocol}//${window.location.host}`;

const baseElasticUrl = environment.BASE_ELASTIC_URL_API;

@Injectable({providedIn: 'root'})
export class GenericHttpService {
  constructor(
    private httpClient: HttpClient,
    private storageService: StrorageService
  ) {
  }

  /**
   * Отправляет типизированный GET запрос на сервер
   * @param url string - 'path to controller' examle - User/Setting
   * @param body TRequest
   * @param apiParams ApiParams
   * @returns Observable<TResponse>
   */
  public getGeneric<TRequest = any, TResponse = any>(
    url: string,
    body: TRequest = null,
    apiParams: ApiParams = new ApiParams()
  ): Observable<TResponse> {
    const requestUrl = this._getFullRequestUrl(url, apiParams);
    console.warn(requestUrl);

    const response = this.httpClient.get<TResponse>(requestUrl, {
      headers: this.getHeaders(apiParams.isFile, apiParams.isElastic),
      params: this._getParams(apiParams, body),
    });
    return this._handleResponse(response);
  }

  /**
   * Отправляет типизированный POST запрос на сервер
   * @param url string - 'path to controller' examle - User/Setting
   * @param body TRequest
   * @param apiParams ApiParams
   * @returns Observable<TResponse>
   */
  public postGeneric<TRequest = any, TResponse = any>(
    url: string,
    body: TRequest = null,
    apiParams: ApiParams = new ApiParams()
  ): Observable<TResponse> {
    const requestUrl = this._getFullRequestUrl(url, apiParams);
    const response = this.httpClient.post<TResponse>(requestUrl, body, {
      headers: this.getHeaders(apiParams.isFile, apiParams.isElastic),
      params: this._getParams(apiParams),
    });
    return this._handleResponse(response);
  }

  /**
   * Устанавливает заголовки запроса
   * @param isFile bool
   * @param isRequestToElastic bool
   * @returns HttpHeaders
   */
  public getHeaders(isFile = false, isRequestToElastic = false): HttpHeaders {
    const headers = new HttpHeaders();

    if (isFile) {
      headers.append('Content-Type', 'multipart/form-data');
    } else if (isRequestToElastic) {
      headers.append('Content-Type', 'application/json; charset=utf-8');
    } else {
      headers.append('Content-Type', 'text/plain');
    }
    return headers;
  }

  /**
   * Преобразовывает ответ от сервера в ApiResponse model
   * @param response Observable<TResponse>
   * @returns Observable<TResponse>
   */
  private _handleResponse<TResponse>(
    response: Observable<TResponse | ApiResponse<TResponse>>
  ): Observable<TResponse> {
    return response.pipe(
      map((resp) => {
        // Если ответ от сервера имеет модель ApiResponse
        // то создаём объект ответа от апи и возвращаем результат
        if (resp as IApiResponse<TResponse>) {
          return new ApiResponse<TResponse>(resp).data;
        } else {
          return resp as TResponse;
        }
      })
    );
  }

  /**
   * Возвращает полную строку URL
   * @param url string
   * @param apiParams ApiParams
   * @returns string
   */
  private _getFullRequestUrl(url: string, apiParams: ApiParams): string {
    let requestUrl = '';

    // Если строится запрос моковых данных из Postman возвращаем URL в чистом виде
    if (apiParams.isMoqPostman) {
      return url;
    }

    if (!apiParams.isElastic) {
      requestUrl = `${baseUrl}/${url}`;
    } else {
      requestUrl = `${baseElasticUrl}/${url}`;
    }

    return requestUrl;
  }

  /**
   * Усстанавливает параметры запроса
   * @param apiParams ApiParams
   * @returns HttpParams
   */
  private _getParams<TSource>(
    apiParams: ApiParams,
    source: TSource = null
  ): HttpParams {
    let params = new HttpParams();

    if (source != null) {
      Object.keys(source).forEach((key) => {
        const value = source[key];
        if (value instanceof Date) {
          console.warn(
            'В текущей реализации нет возможности отправлять даты в параметрах GET метода'
          );
        } else if (value instanceof Array) {
          console.warn(
            'В текущей реализации нет возможности отправлять массивы в параметрах GET метода'
          );
        } else {
          params = params.append(key, value);
        }
      });
    }

    // TODO: придумать как перенести это в interceptor
    if (!apiParams.isElastic) {
      const token = this.storageService.getStorageItem(StorateType.Token);

      if (token) {
        params = params.append('token', token);
      }
    }

    return params;
  }
}
