import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-badge',
  templateUrl: './risk-badge.component.html',
  styleUrls: ['./risk-badge.component.scss']
})
export class RiskBadgeComponent implements OnInit {

  @Input() risk: 'High' | 'Medium' | 'None' | 'Low';

  constructor() {
  }

  ngOnInit(): void {
  }

  get riskText() {
    if (this.riskLevel === 'none') {
      return 'низкий риск';
    } else if (this.riskLevel === 'low') {
      return 'низкий риск';
    } else if (this.riskLevel === 'medium') {
      return 'средний риск';
    } else {
      return 'высокий риск';
    }
  }

  get riskLevel() {
    return this.risk ? this.risk.toLowerCase() : 'none';
  }
}
