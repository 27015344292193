import { Injectable } from '@angular/core';
import { AttorneyInterface, OrganizationInterface } from '@web/core/models/organization.interface';
import { UraitApiService } from '@web/core/services/urait-api.service';
import { LoadOrganizationsResponse } from '../states/organization.state';

@Injectable()
export class OrganizationsApiService {
  private readonly url = 'organizations';

  constructor(private apiService: UraitApiService) {}

  getOrganizations(): Promise<LoadOrganizationsResponse> {
    return this.apiService.get(this.url);
  }
}
