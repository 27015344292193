import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {RequestContractService} from '../shared/request-contract.service';
import filterContracts from './json-data-filter-contracts';
import {ContractService} from '../contract.service';
import {ISearchObjects} from '../../../shared/models/main.interfaces';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-choice-of-contract',
  templateUrl: './choice-of-contract.component.html',
  styleUrls: ['./choice-of-contract.component.scss']
})
export class ChoiceOfContractComponent implements OnInit, OnDestroy {
  @Input() contractsImmutable;
  tab: 'from-portfolio' | 'from-number' | 'user-version' = 'from-portfolio';
  countPage = 10;
  page = 1;
  valueDate = [];
  types = [];
  manuallyForm: FormGroup;
  typeContractsLabel;
  searchError = false;

  options = [
    {
      name: 'Договор лицензии',
      value: 'license',
      id: 1,
      checked: false
    },
    {
      name: 'Договор сублицензии',
      value: 'sublicense',
      id: 2,
      checked: false
    },
    {
      name: 'Договор концессии',
      value: 'concession',
      id: 3,
      checked: false
    },
    {
      name: 'Договор субконцессии',
      value: 'subconcession',
      id: 4,
      checked: false
    }
  ];
  showMobileFilter = false;
  copyContracts;

  contracts = [];
  resultsSearchContracts = [];
  labelObjects;
  searchTerm;
  typeOfContracts = filterContracts;
  showTypeContract = false;
  private readonly onDestroy = new Subject<void>();

  constructor(
    readonly requestContractService: RequestContractService,
    private contractService: ContractService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.requestContractService.contractDate && this.requestContractService.contractNumber) {
      this.tab = 'user-version';
      this.setForm();
    } else {
      this.initForm();
    }
    if (this.contractsImmutable?.length) {
      this.contracts = [...this.contractsImmutable];
      this.copyContracts = JSON.parse(JSON.stringify(this.contracts));
    }
    if (this.requestContractService.searchesContracts?.length) {
      this.resultsSearchContracts = this.requestContractService.searchesContracts;
    }
  }

  initForm() {
    this.manuallyForm = this.fb.group({
      contractNumber: new FormControl(''),
      contractDate: new FormControl(''),
      contractType: new FormControl('')
    });
  }

  setValue(checked?: boolean, obj?: any) {
    this.requestContractService.contractNumber = this.manuallyForm.value.contractNumber;
    this.requestContractService.contractDate = this.manuallyForm.value.contractDate;
    if (checked) {
      this.options.map(el => {
        if (el.id === obj.id) {
          this.requestContractService.contractType = obj.value;
          this.typeContractsLabel = obj.name;
        }
      });
    }
  }

  setForm() {
    this.manuallyForm = this.fb.group({
      contractNumber: new FormControl(this.requestContractService.contractNumber),
      contractDate: new FormControl(this.requestContractService.contractDate),
      contractType: new FormControl(this.requestContractService.contractType)
    });
    switch (this.requestContractService.contractType) {
      case 'license':
        this.typeContractsLabel = 'Договор лицензии';
        break;
      case 'sublicense':
        this.typeContractsLabel = 'Договор сублицензии';
        break;
      case 'concession':
        this.typeContractsLabel = 'Договор концессии';
        break;
      case 'subconcession':
        this.typeContractsLabel = 'Договор субконцессии';
        break;
    }
  }

  switchTab(type: 'from-portfolio' | 'from-number' | 'user-version' ): void {
    switch (type) {
      case 'from-number':
      case 'from-portfolio':
        if (this.manuallyForm) {
          this.manuallyForm.get('contractNumber').setValue('');
          this.manuallyForm.get('contractDate').setValue('');
        }
        this.requestContractService.contractDate = '';
        this.requestContractService.contractNumber = '';
        break;
      case 'user-version':
        if (this.resultsSearchContracts) {
          this.resultsSearchContracts.forEach(el => el.checked = false);
        }
        if (this.contracts) {
          this.contracts.forEach(el => el.checked = false);
        }
        this.requestContractService.contractId = '';
    }
    this.tab = type;
    this.page = 1;
  }

  trimString(str: string, count: number): string {
    if (!str) {return; }
    if (window.innerWidth < 768){
      return str;
    }
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  makeBodyHidden(): void {
    if (window.innerWidth <= 793) {
      if (this.showMobileFilter === true) {
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.height = '100%';
      } else {
        document.body.style.overflow = 'visible';
        document.body.style.position = '';
        document.body.style.height = 'auto';
      }
    }
  }

  changePage(page: number): void {
    this.page = page;
  }

  checkObject(contract: any, isCheck: boolean, contracts: object[]) {
    if (!this.resultsSearchContracts || !this.contracts) {return; }
    if (isCheck) {
      if (contracts === this.contracts) {
        this.resultsSearchContracts.forEach(el => el.checked = false);
        this.contracts.map(item => {
          item.checked = contract.id === item.id;
        });
      } else {
        this.contracts.forEach(el => el.checked = false);
      }
      this.requestContractService.contractId = contract.id || contract._id;
    } else {
      this.requestContractService.contractId = false;
    }
  }

  createLabelObjects(objects) {
    if (!objects) {return; }
    if (objects?.length === 1) {
      return this.labelObjects = `${objects?.length} объект`;
    } else if ([5, 6, 7, 8, 9, 0].indexOf(+objects?.length.toString().slice(-1)) > -1 || [12, 13, 14].indexOf(objects?.length) > -1) {
      return this.labelObjects = `${objects?.length} объектов`;
    } else if ([2, 3, 4].indexOf(+objects?.length.toString().slice(-1)) > -1) {
      return this.labelObjects = `${objects?.length} объекта`;
    }
  }

  searchContracts() {
    if (this.searchTerm) {
      this.contracts = this.contractsImmutable.filter((obj) => obj.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    } else {
      this.contracts = this.contractsImmutable.filter(() => true);
    }
  }

  removeDuplicateTypes(id) {
    const index = this.types.findIndex(item => item.id === id);
    if (index !== -1) {
      this.types.splice(index, 1);
    }
  }

  filterByTypes() {
    const types = [];
    this.typeOfContracts.forEach(type => {
      if (type.checked) {
        types.push(type.value);
      //   this.contracts = this.contractsImmutable.filter((obj) => {
      //     return obj.type.toLowerCase().includes(type.value.toLowerCase());
      //   });
      // } else if (this.typeOfContracts.every(el => el.checked === false)) {
      //   this.contracts = this.contractsImmutable;
      }
    });
    if (types.length) {
      this.contracts = this.contractsImmutable.filter(obj => types.includes(obj.type));
    } else {
      this.contracts = this.contractsImmutable;
    }
  }

  clearAll() {
    this.contracts = JSON.parse(JSON.stringify(this.copyContracts));
  }

  applyFilters() {
    if (this.searchTerm) {
      this.searchContract();
    }
    this.filterByTypes();
  }


  searchContract() {
    if (this.searchTerm?.length) {
      const body: ISearchObjects = {
        number: this.searchTerm,
      };
      // this.isLoading = true;
      this.contractService.getContracts(body).subscribe(data => {
          if (Object.keys(data).length !== 0) {
            this.resultsSearchContracts.push(data[0]);
            this.requestContractService.searchesContracts = this.resultsSearchContracts;
          }
        }
      );
    } else {
      this.searchError = true;
    }
  }

  get isLoading() {
    return this.requestContractService.isLoading;
  }

  clearFilters() {
    this.searchTerm = '';
    this.typeOfContracts.forEach(el => el.checked = false);
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  getTypesName(): string {
    let typeName = '';
    this.options.some(obj => {
      if (obj.checked) {
        typeName = obj.name;
      }
    });
    return typeName;
  }

  getCountCheckedObjects(): number {
    let counter = 0;
    this.typeOfContracts.forEach((obj) => {
      if (obj.checked) {
        counter++;
      }
    });
    return counter;
  }
}
