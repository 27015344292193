import { CheckpaymentResponse, PAYMENT_TYPE, YandexResponseToken } from '../types/payment.types';

export namespace PaymentActions {
  export class SetApplicationId {
    public static readonly type = '[Payment State] Set Application Id';
    constructor(public readonly payload: string | null) { }
  }

  export class SetCurrentTab {
    public static readonly type = '[Payment State] Set Current Tab';
    constructor(public readonly payload: PAYMENT_TYPE) { }
  }

  export class SetCurrentPayer {
    public static readonly type = '[Payment State] Set Current Payer';
    constructor(public readonly payload: string | null) { }
  }

  export class SetShowOptions {
    public static readonly type = '[Payment State] Set Show Options';
  }

  export class CreateYandexPayment {
    public static readonly type = '[Payment State] Create Yandex Payment';
  }

  export class CreateYandexPaymentSuccess {
    public static readonly type = '[Payment State] Create Yandex Payment Success';
    constructor(public readonly payload: YandexResponseToken) { }
  }

  export class CreateYandexPaymentFail {
    public static readonly type = '[Payment State] Create Yandex Payment Fail';
  }

  export class CheckPaymentStatus {
    public static readonly type = '[Payment State] Check Payment Status';
    constructor(public readonly payload: string) { }
  }

  export class CheckPaymentStatusSuccess {
    public static readonly type = '[Payment State] Check Payment Status Success';
    constructor(public readonly payload: CheckpaymentResponse) { }
  }

  export class CheckPaymentStatusFail {
    public static readonly type = '[Payment State] Check Payment Status Fail';
  }

  export class SetPaymentAmount {
    public static readonly type = '[Payment State] Set Payment Amount';
    constructor(public readonly payload: number) { }
  }

  export class SetSearchedPayer {
    public static readonly type = '[Payment State] Set Searched Payer';
    constructor(public readonly payload: any) { }
  }

  export class ClearPaymentState {
    public static readonly type = '[Payment State] Clear Payment State';
  }
}
