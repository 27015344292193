<form *ngIf="!isLoading; else load">
  <div class="double-block">
    <fieldset>
      <div class="hidden__input-wrapper input-checkbox">
        <input type="radio" name="agreement" id="1" [class.input-checkbox__checked]="contractType === 'license'">
        <label class="input-checkbox__label" for="1"
               [class.input-checkbox__checked]="contractType === 'license'"
               (click)="toSelectType('license')">Лицензионный
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Предоставление прав на использование объекта ИС</p>
            </span>
          </span>
        </label>
      </div>
      <div class="hidden__input-wrapper input-checkbox">
        <input type="radio" name="agreement" id="2" >
        <label class="input-checkbox__label" for="2"
               [class.input-checkbox__checked]="contractType === 'concession'"
               (click)="toSelectType('concession')">Коммерческая концессия
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Договор франшизы, позволяющий передавать комплекс прав на использование объектов ИС</p>
            </span>
          </span>
        </label>
      </div>
      <div class="hidden__input-wrapper input-checkbox">
        <input type="radio" name="agreement" id="3" >
        <label class="input-checkbox__label" for="3"
               [class.input-checkbox__checked]="contractType === 'completeAlienation'"
               (click)="toSelectType('completeAlienation')">Полное отчуждение
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Полная передача прав на использование объекта ИС</p>
            </span>
          </span>
        </label>
      </div>
      <div class="hidden__input-wrapper input-checkbox">
        <input type="radio" name="agreement" id="4" >
        <label class="input-checkbox__label" for="4"
               [class.input-checkbox__checked]="contractType === 'pledge'"
               (click)="toSelectType('pledge')">Залог
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Договор залога, объектом которого выступает ОИС</p>
            </span>
          </span>
        </label>
      </div>
    </fieldset>
    <fieldset>
      <div class="hidden__input-wrapper input-checkbox" >
        <input type="radio" name="agreement" id="5">
        <label class="input-checkbox__label" for="5"
               [class.input-checkbox__checked]="contractType === 'sublicense'"
               (click)="toSelectType('sublicense')">Сублицензионный
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Договор между обладателем исключительного права на использование объекта ИС, полученным от
              правообладателя в рамках лицензионного договора, и третьими лицами. Вторичен относительно лицензионного договора</p>
            </span>
          </span>
        </label>
      </div>
      <div class="hidden__input-wrapper input-checkbox">
        <input type="radio" name="agreement" id="6" >
        <label class="input-checkbox__label" for="6"
               [class.input-checkbox__checked]="contractType === 'subconcession'"
               (click)="toSelectType('subconcession')">Коммерческая субконцессия
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Договор между обладателем исключительного права на использование объекта ИС, полученным от правообладателя
              в рамках договора коммерческой концессии, и третьими лицами. Вторичен относительно договора коммерческой концессии</p>
            </span>
          </span>
        </label>
      </div>
      <div class="hidden__input-wrapper input-checkbox">
        <input type="radio" name="agreement" id="7" >
        <label class="input-checkbox__label" for="7"
               [class.input-checkbox__checked]="contractType === 'partialAlienation'"
               (click)="toSelectType('partialAlienation')">Частичное отчуждение
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Передача прав на использование объекта ИС в отношении части товаров и/или услуг</p>
            </span>
          </span>
        </label>
      </div>
      <div class="hidden__input-wrapper input-checkbox">
        <input type="radio" name="agreement" id="8" >
        <label class="input-checkbox__label" for="8"
               [class.input-checkbox__checked]="contractType === 'repledge'"
               (click)="toSelectType('repledge')">Последующий залог
          <span class="prompt prompt__question">?<span class="prompt__hidden">
            <p>Вторичный договор залога, по которому объект ИС, находящийся в залоге, становится предметом еще одного залога </p>
            </span>
          </span>
        </label>
      </div>
    </fieldset>
  </div>
  <div class="one-block">
    <div class="form-item__input input-checkbox" >
      <input type="radio" name="agreement" value="unknown"
             [class.input-checkbox__checked]="contractType === 'unknown'"
             (click)="toSelectType('unknown')">
      <p>Не знаю</p>
      <span class="form-item__placeholder-wrapper form-item__placeholder-wrapper--no-padding">
             <p class="form-item__placeholder">Вид объекта будет определен патентным поверенным</p>
       </span>
      <h3 class="check">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="#72CA49">
          <circle cx="12" cy="12" r="12"/>
          <path d="M6 12L10 16L18 8" stroke="white" stroke-width="2"/>
        </svg>
      </h3>
    </div>
  </div>
  <div class="one-block">
    <h1 class="heading-h1">Внесение изменений</h1>
    <div class="input-checkbox">
      <input type="radio" name="agreement" id="9" >
      <label class="input-checkbox__label" for="9"
             [class.input-checkbox__checked]="contractType === 'change'"
             (click)="toSelectType('change')">Внесение изменений в ранее зарегистрированный договор
      </label>
    </div>
    <h1 class="heading-h1">Расторжение</h1>
    <div class="input-checkbox">
      <input type="radio" name="agreement" id="10" >
      <label class="input-checkbox__label" for="10"
             [class.input-checkbox__checked]="contractType === 'termination'"
             (click)="toSelectType('termination')">Расторжение ранее зарегистрированного договора
      </label>
    </div>

  </div>
</form>
<ng-template #load>
  <div class="loader"></div>
</ng-template>
