import { NgModule } from '@angular/core';

import { CorpIndexComponent } from './components/corp-index/corp-index.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { LayoutModule } from '../../layout/layout.module';
import { NewsFeedComponent } from './components/news-feed/news-feed.component';
import { PortfolioChartComponent } from './components/portfolio-chart/portfolio-chart.component';
import { RisksChartComponent } from './components/risks-chart/risks-chart.component';
import { ServiceSliderComponent } from './components/service-slider/service-slider.component';
import { TrademarksSearchComponent } from './components/trademarks-search/trademarks-search.component';
import { CorpIndexService } from './services/corp-index.service';
import { FormsModule } from '@angular/forms';
import { DragScrollModule } from 'ngx-drag-scroll';
import { RouterModule } from '@angular/router';
import {ExpertModule} from '../../layout/expert/expert.mdoule';


@NgModule({
  declarations: [
    CorpIndexComponent,
    NewsFeedComponent,
    PortfolioChartComponent,
    RisksChartComponent,
    ServiceSliderComponent,
    TrademarksSearchComponent,
  ],
  providers: [
    CorpIndexService
  ],
  imports: [
    CommonModule,
    SharedModule,
    LayoutModule,
    FormsModule,
    DragScrollModule,
    RouterModule,
    ExpertModule
  ]
})
export class CorpIndexModule {
}
