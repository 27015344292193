<button (click)="claim = !claim;court = !court;dispute = !dispute;soon=false">Изменить наличие объектов</button>
<button routerLink="/claim-page">to claim-page</button>
<button routerLink="/court-page">to court-page</button>
<button routerLink="/dispute-page">to dispute-page</button>

<section class="section-position section-position--mobile">
  <div class="container chart">
    <h1 class="chart__header">Суды и споры
      <span tabindex="0" class="chart__header-blue"
            [class.chart__header-blue--open]="showCompanyList">
            <a class="company-list__link"
               (click)="showCompanyList=!showCompanyList">
              {{selectedCompany}}
            </a>
      </span>
      <div *ngIf="showCompanyList===true" (click)="showCompanyList=false"
           class="overlay overlay--transparent"></div>
      <div class="chart__companies-wrapper company-list" [class.hide]="showCompanyList===false">
        <ul class="company-list__list">
          <li class="company-list__item"
              [class.company-list__item--selected]="company === selectedCompany"
              *ngFor="let company of partners" (click)="onSelect(company)">
            <p>{{company}}</p>
          </li>
        </ul>
      </div>

    </h1>
    <div class="chart__wrapper">
      <div tabindex="0" class="chart__block-wrapper">
        <div>
          <div class="chart__block" *ngIf="!claim" style="position: relative;z-index: 2">
            <p class="chart__name">Претензии</p>
            <span class="chart__status">{{soon ? 'Скоро' : 'Нет данных'}}</span>
          </div>
        </div>
        <div class="chart__block chart__block--main"
             (click)="mobileCharts ? openFirstChart=!openFirstChart : ''">
          <div class="chart__header-wrap">
            <p class="chart__name chart-date chart__name--main"
               [class.chart__name--open]="openFirstChart">Претензии</p>
            <span class="chart__question">?</span>
          </div>
          <div class="chart-date__wrapper" *ngIf="openFirstChart===true">
            <div class="chart-date__numbers">
              <p class="chart-date__big-count chart-date__big-count--yellow">{{claimSum}}</p>
              <p class="chart-date__small-txt">из них</p>
              <p class="chart-date__big-count">{{claimInWork}}</p>
              <p class="chart-date__small-txt">в работе</p>
            </div>
            <div>
              <table class="chart__table">
                <tr>
                  <td>Входящие</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>Исходящие</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td><a class="chart__link">Направить претензию</a></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div tabindex="0" class="chart__block-wrapper">
        <div>
          <div class="chart__block" *ngIf="!court" style="position: relative;z-index: 2">
            <p class="chart__name">Суды</p>
            <span class="chart__status">{{soon ? 'Скоро' : 'Нет данных'}}</span>
          </div>
        </div>
        <div class="chart__block chart__block--main"
             (click)="mobileCharts ? openSecondChart=!openSecondChart : ''">
          <div class="chart__header-wrap">
            <p class="chart__name chart-date chart__name--main"
               [class.chart__name--open]="openSecondChart">Суды</p>
            <span class="chart__question">?</span>
          </div>
          <div class="chart-date__wrapper" *ngIf="openSecondChart===true">
            <div class="chart-date__numbers">
              <p class="chart-date__big-count chart-date__big-count--blue">{{courtSum}}</p>
              <p class="chart-date__small-txt">из них</p>
              <p class="chart-date__big-count">{{courtInWork}}</p>
              <p class="chart-date__small-txt">в работе</p>
            </div>
            <div>
              <table class="chart__table">
                <tr>
                  <td>Истец</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Ответчик</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Третье лицо</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td><a class="chart__link">Подать в суд</a></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div tabindex="0" class="chart__block-wrapper">
        <div>
          <div class="chart__block" *ngIf="!dispute" style="position: relative;z-index: 2">
            <p class="chart__name">Споры</p>
            <span class="chart__status">{{soon ? 'Скоро' : 'Нет данных'}}</span>
          </div>
        </div>
        <div class="chart__block chart__block--main"
             (click)="mobileCharts ? openThirdChart=!openThirdChart : ''">
          <div class="chart__header-wrap">
            <p class="chart__name chart-date chart__name--main"
               [class.chart__name--open]="openThirdChart">Споры</p>
            <span class="chart__question">?</span>
          </div>
          <div class="chart-date__wrapper" *ngIf="openThirdChart===true">
            <div class="chart-date__numbers">
              <p class="chart-date__big-count chart-date__big-count--green">{{disputeSum}}</p>
              <p class="chart-date__small-txt">из них</p>
              <p class="chart-date__big-count">{{disputeInWork}}</p>
              <p class="chart-date__small-txt">в работе</p>
            </div>
            <div>
              <table class="chart__table">
                <tr>
                  <td>ППС</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>ФАС</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>Роскомнадзор</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td><a class="chart__link">Начать спор</a></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</section>
<section class="section-position section-position--court">
  <div *ngIf="showMobileControl===true" (click)="showMobileControl=false;makeBodyHidden()" class="overlay"></div>
  <div tabindex="0" class="container control">
    <button class="control__btn " (click)="claimsTab=!claimsTab; toCheckClaims();"
            [class.control__btn--active]="claimsTab"><p>Претензии</p><span class="control__count"
                                                                           [class.control__count--white]="!claimsTab">14</span>
    </button>
    <button class="control__btn " (click)="courtsTab=!courtsTab;toCheckCourts()"
            [class.control__btn--active]="courtsTab"><p>Суды</p><span class="control__count"
                                                                      [class.control__count--white]="!courtsTab">13</span>
    </button>
    <button class="control__btn " (click)="disputesTab=!disputesTab; toCheckDisputesTab()"
            [class.control__btn--active]="disputesTab"><p>Споры</p><span class="control__count"
                                                                         [class.control__count--white]="!disputesTab">11</span>
    </button>
  </div>
</section>

<section class="section-position section-position__color-bg" style="position: relative">
  <div class="containers-wrapper">
    <div class="container">
       <span class="search__mobile" (click)="openFilter=!openFilter">
          <p>Поиск по {{currentTab}}</p>
       </span>
      <app-claims-index-table *ngIf="claimsTab" [showFilter]="openFilter"
                              (modalEvent)="getFilterStatus()"></app-claims-index-table>
      <app-courts-index-table *ngIf="courtsTab" [showFilter]="openFilter"
                              (modalEvent)="getFilterStatus()"></app-courts-index-table>
      <app-disputs-index-table *ngIf="disputesTab" [showFilter]="openFilter"
                               (modalEvent)="getFilterStatus()"></app-disputs-index-table>
    </div>
  </div>
</section>

<app-expert>
</app-expert>

