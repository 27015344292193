import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { IPayment } from '../../../shared/models/main.interfaces';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})

export class OrganizationComponent implements OnInit, OnDestroy {
  // Временный флаг для переключения администратор/юзер без прав
  administrator = false;

  // Временный флаг Новая организация
  newOrganization = false;
  private readonly onDestroy = new Subject<void>();

  // Сколько показывать дел
  showCases = 2;

  // Сколько показывать в истории платежей
  showPayment = 3;

  // Форма поиска
  form: FormGroup;
  orgData;
  organizationId;
  searchTerm;
  representatives = [];
  overRepresentatives = [];
  archivedCases;
  cases;
  showAllCases = false;
  contragentID;

  // Модальное окно добавления представителя
  showModalAddUser = false;
  disableFinance = false;
  financeReplenishment = false;
  financeActs = false;
  rights;


  userAdmin = {
    name: 'Константинопольский К. А.',
    photo: 'https://via.placeholder.com/100x100',
    position: 'Руководитель',
    email: 'ivanova.i@companyname.ru',
  };

  paymentHistory: IPayment[];

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private profileApi: UserProfileService,
              private router: Router,
              private shared: SharedService
  ) {
  }

  ngOnInit(): void {
    const timer = setInterval(() => {
      if (this.profileApi.getOrganizationsData()) {
        this.initComponent();
        clearInterval(timer);
      }
    }, 1000);
  }

  initComponent() {
    this.administrator = this.profileApi.getUserData().role === 'Администратор' && this.profileApi.getUserData().multiaccount;
    this.contragentID = JSON.parse(localStorage.getItem('organizations'));
    this.initForm();
    this.route.queryParams.subscribe(params => {
      this.organizationId = params.id;
    });
    this.getOrg();
    this.getPaymentsByOrg();
    this.getRoleForCheckingRights();
  }

  getRoleForCheckingRights() {
    const user = JSON.parse(localStorage.getItem('currentUserData'));
    if (user?.role === 'Пользователь' && user?.multiaccount) {
      const params = {
        contragentID: this.contragentID[0].id
      };
      console.log('bbbbb');
      if (params) {
        this.shared.getRightsAsync(params).subscribe(data => {
          this.rights = data.rights;
          if (this.rights?.length) {
            this.getRightsAlgorithm();
          }
        });
      }
    }
  }

  getRightsAlgorithm() {
    const finance = this.rights.filter(i => i.title === 'Финансовая информация');
    this.checkFinanceRights(finance);
    this.checkFinanceRightsAct(finance);
    this.checkFinanceRightsReplenishment(finance);
  }

  checkFinanceRights(finance) {
    const synonym = 'finance_widget-payments';
    finance[0].data.forEach(item => {
      if (item.nameEng === synonym) {
        if (item.value === 'false' || item.value === 'pending') {
          this.disableFinance = true;
        }
      }
    });
  }

  checkFinanceRightsAct(finance) {
    const synonym = 'finance_acts-documents';
    finance[0].data.forEach(item => {
      if (item.nameEng === synonym) {
        if (item.value === 'false' || item.value === 'pending') {
          this.financeActs = true;
        }
      }
    });
  }

  checkFinanceRightsReplenishment(finance) {
    const synonym = 'finance_replenishment-balance';
    finance[0].data.forEach(item => {
      if (item.nameEng === synonym) {
        if (item.value === 'false' || item.value === 'pending') {
          this.financeReplenishment = true;
        }
      }
    });
  }

  getPaymentsByOrg() {
    const params = {
      mode: 'organization',
      id: this.organizationId
    };
    this.profileApi.getPaymentsByOrg(params).pipe(takeUntil(this.onDestroy)).subscribe((payments: any) => {
      if (payments.payments) {
        this.paymentHistory = payments.payments;
      }
    });
  }


  getOrg() {
    this.orgData = this.profileApi.getOrganizationsData().filter(i => i.id === this.organizationId)[0];
    this.representatives = this.orgData.representatives.filter(user => user.status !== 'Нет доступа');
    this.overRepresentatives = this.orgData.representatives.filter(user => user.status === 'Нет доступа' && user.id);
    this.getArchive(this.orgData);
    this.getCases(this.orgData);
  }

  toggleCases() {
    this.showAllCases = true;
    this.getCases(this.orgData);
  }

  toggleArchive() {
    this.showAllCases = true;
    this.getArchive(this.orgData);
  }

  getCases(organization) {
    if (this.showAllCases) {
      this.cases = organization.cases.filter(c => c.opened);
    } else {
      this.cases = organization.cases.filter(c => c.opened).slice(0, 3);
    }
  }

  getArchive(organization) {
    if (this.showAllCases) {
      this.archivedCases = organization.cases.filter(c => !c.opened);
    } else {
      this.archivedCases = organization.cases.filter(c => !c.opened).slice(0, 3);
    }
  }

  // Показ всех дел
  casesShow(): void {
    this.showCases += 10;
  }

  // Показ всей истории платежей
  paymentShow(): void {
    this.showPayment += 10;
  }

  initForm(): void {
    this.form = this.fb.group({
      search: ['', [Validators.required]],
    });
  }

  // Открыть модальное окно добавления представителя
  openModalAddUser(): void {
    this.showModalAddUser = true;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'hidden');
  }

  // Закрыть модальное окно добавления представителя
  closeModal(): void {
    this.showModalAddUser = false;
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  searchReps(term) {
    if (term?.length >= 3) {
      this.representatives = this.representatives.filter((obj) => obj.name.toLowerCase().includes(term.toLowerCase())
        || obj.surname.toLowerCase().includes(term.toLowerCase())
      );
    } else {
      this.representatives = this.orgData?.representatives.filter(user => user.status !== 'Нет доступа');
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  getTitleByPSRNCount(PSRN) {
    if (PSRN.toString().replace(/\s+/g, '').length === 15) {
      return 'ОГРНИП';
    } else {
      return 'ОГРН';
    }
  }

  getStringWithoutSpace(str) {
    return str.replace(/\s+/g, '');
  }

  toLeadForm(identity) {
    const data = {
      formIdentity: identity,
      contragentID: this.organizationId
    };
    this.router.navigate(['/treaty-extension'], {queryParams: {data: JSON.stringify(data)}});
  }

  toChangePage() {
    this.router.navigate(['user/change-organization'], {queryParams: {id: this.orgData.id}});
  }

  toDeletePage() {
    this.router.navigate(['user/organization-delete'], {queryParams: {id: this.orgData.id}});
  }

  editRights(id) {
    if (this.administrator) {
      this.router.navigate(['user/profile'], {queryParams: {contID: this.organizationId, userID: id}});
    }
  }
}
