import {
  MKTU,
  TmForm,
  TINForm,
  OKVEDCode,
  OwnerForm,
  OptionsMKTU,
  RequestType,
  TariffPayload,
  TariffResponse,
  TrademarkDraft,
  UpdateTrademarkDraft,
  LastRequestTrademark,
  TrademarkDraftDetails,
  TrademarkDraftPromocode,
  SearchLegalResponseSourse,
  LastRequestTrademarkSource,
} from '../types/trademark.types';
import { catchError, map } from 'rxjs';
import { Router } from '@angular/router';
import { ImgHelper } from '../services/img-helper';
import { Injectable, NgZone } from '@angular/core';
import { TrademarkActions } from './trademark.actions';
import { TrademarkService } from '../services/trademark.service';
import { TinHelperService } from '../services/tin-helper.service';
import { DEFAULT_REQUEST_STEPS } from '../types/trademark.defaults';
import { APP_ROUTES_NAMES } from '@web/core/models/app-routes-names';
import { OrganizationState } from '@web/core/states/organization.state';
import { PaymentState } from '@ui/features/payment/store/payment.state';
import { NgxsForm, REQUEST_TYPES } from '@web/core/models/user.interface';
import { InterestService } from 'projects/shared/services/interest.service';
import { append, insertItem, patch, removeItem } from '@ngxs/store/operators';
import { OrganizationInterface } from '@web/core/models/organization.interface';
import {INTERCOM_EVENT_NAME, IntercomEventsService} from 'projects/shared/services/intercom-events.service';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

export interface TrademarkStateModel {
  mktu: MKTU[];
  step: number;
  steps: string[];
  loading: boolean;
  imageError: string;
  payerError: string;
  individual: boolean;
  showFullForm: boolean;
  ownerId: string | null;
  imageUrl: string | null;
  OKVEDCodes: OKVEDCode[];
  isLoadingOwner: boolean;
  type: RequestType | null;
  showSearchInput: boolean;
  tmForm: NgxsForm<TmForm>;
  optionsMktu: OptionsMKTU;
  tinForm: NgxsForm<TINForm>;
  viewSelectContainer: boolean;
  isCheckingCandidate: boolean;
  draft: TrademarkDraft | null;
  ownerForm: NgxsForm<OwnerForm>;
  viewOrganizationsList: boolean;
  tariffResponse: TariffResponse | null,
  owner: SearchLegalResponseSourse | null;
  lastTrademark: LastRequestTrademark | null;
  organization: OrganizationInterface | null;
  candidate: SearchLegalResponseSourse | null;
}
export const TRADEMARK_STATE_TOKEN = new StateToken<TrademarkStateModel>('trademark');
export const TRADEMARK_INITIAL_STATE: TrademarkStateModel = {
  step: 0,
  mktu: [],
  draft: null,
  owner: null,
  ownerId: null,
  imageUrl: null,
  imageError: '',
  loading: false,
  OKVEDCodes: [],
  payerError: '',
  candidate: null,
  individual: false,
  organization: null,
  lastTrademark: null,
  showFullForm: false,
  tariffResponse: null,
  isLoadingOwner: false,
  showSearchInput: false,
  isCheckingCandidate: false,
  viewSelectContainer: false,
  viewOrganizationsList: false,
  steps: DEFAULT_REQUEST_STEPS,
  tinForm: { model: { TIN: '' } },
  tmForm: { model: { imageText: '' }},
  type: null,
  ownerForm: {  model: { name: '', head: '', address: '' } },
  optionsMktu: {
    onlyClassSelect: true, search: 'mktu', selectedMktu: [],
  },
};

@State({
  name: TRADEMARK_STATE_TOKEN,
  defaults: TRADEMARK_INITIAL_STATE,
})
@Injectable()
export class TrademarkState {
  private static readonly MAX_SIZE = 2000000;

  constructor(
    private readonly store: Store,
    private readonly zone: NgZone,
    private readonly router: Router,
    private readonly interestService: InterestService,
    private readonly tinHelperService: TinHelperService,
    private readonly trademarkService: TrademarkService,
    private readonly intercomEventsService: IntercomEventsService,
  ) { }

  public static ownerEmail() {
    return createSelector(
      [OrganizationState.organizations, TrademarkState.owner],
      (organizations: OrganizationInterface[], owner: SearchLegalResponseSourse | null) => {
        const user = organizations.find(item => item.id === owner.id);
        return (user && user.email) ? user.email : '';
    });
  }

  @Selector()
  public static loading(state: TrademarkStateModel) {
    return state.loading;
  }

  @Selector()
  public static individual(state: TrademarkStateModel) {
    return state.individual;
  }

  @Selector()
  public static type(state: TrademarkStateModel) {
    return state.type;
  }

  @Selector()
  public static step(state: TrademarkStateModel) {
    return state.step;
  }

  @Selector()
  public static steps(state: TrademarkStateModel) {
    return state.steps;
  }

  @Selector()
  public static disableNextStep(state: TrademarkStateModel) {
    return state.type === REQUEST_TYPES.TRADEMARK
      ? !((state.tmForm.model.imageText?.length || state.imageUrl) && (state.owner || state.organization))
      : false;
  }

  @Selector()
  public static imageUrl(state: TrademarkStateModel) {
    return state.imageUrl;
  }

  @Selector()
  public static imageError(state: TrademarkStateModel) {
    return state.imageError;
  }

  @Selector()
  public static showFullForm(state: TrademarkStateModel) {
    return state.showFullForm;
  }

  @Selector()
  public static viewOrganizationsList(state: TrademarkStateModel) {
    return state.viewOrganizationsList;
  }

  @Selector()
  public static payerError(state: TrademarkStateModel) {
    return state.payerError;
  }

  @Selector()
  public static owner(state: TrademarkStateModel) {
    return state.owner;
  }

  @Selector()
  public static isLoadingOwner(state: TrademarkStateModel) {
    return state.isLoadingOwner;
  }

  @Selector()
  public static candidate(state: TrademarkStateModel) {
    return state.candidate;
  }

  @Selector()
  public static isCheckingCandidate(state: TrademarkStateModel) {
    return state.isCheckingCandidate;
  }

  @Selector()
  public static viewSelectContainer(state: TrademarkStateModel) {
    return state.viewSelectContainer;
  }

  @Selector()
  public static draft(state: TrademarkStateModel) {
    return state.draft;
  }

  @Selector()
  public static showSearchInput(state: TrademarkStateModel) {
    return state.showSearchInput;
  }

  @Selector()
  public static optionsMktu(state: TrademarkStateModel) {
    return state.optionsMktu;
  }

  @Selector()
  public static lastTrademark(state: TrademarkStateModel) {
    return state.lastTrademark;
  }

  @Selector()
  public static OKVEDCodes(state: TrademarkStateModel) {
    return state.OKVEDCodes;
  }

  @Selector()
  public static tariffes(state: TrademarkStateModel) {
    return state.tariffResponse?.tariff;
  }

  @Selector()
  public static proocode(state: TrademarkStateModel) {
    return state.draft.promocode.id.length ? state.draft.promocode : null;
  }

  @Selector()
  public static ownerShortName(state: TrademarkStateModel) {
    return state.draft.info.find(item => item.title === 'Заявитель сокращенно').text ?? '';
  }

  @Selector()
  public static activeTariffId(state: TrademarkStateModel) {
    return state.draft.tariff;
  }

  @Selector()
  public static activeTariff(state: TrademarkStateModel) {
    return state.tariffResponse?.tariff.find(item => item.id === state.draft.tariff) ?? null;
  }

  @Selector()
  public static duty(state: TrademarkStateModel) {
    return state.tariffResponse?.duty;
  }

  @Selector()
  public static organization(state: TrademarkStateModel) {
    return state.organization;
  }

  @Selector()
  public static MKTUCodes(state: TrademarkStateModel) {
    return state.draft ? state.draft?.info.find(item => item.title === 'Классы МКТУ').text.split(', ').map(item => +item) : [];
  }

  @Selector()
  public static tariffResult(state: TrademarkStateModel) {
    const activeTariff = state.tariffResponse?.tariff.find(item => item.id === state.draft.tariff) ?? null;
    return {
      paymentSum: activeTariff ? +activeTariff.sumDiscount + +state.tariffResponse.duty.sumDiscount : 0,
      sumBeforeDiscount: activeTariff ? +activeTariff.sum + +state.tariffResponse.duty.sum : 0,
    }
  }

  @Action(TrademarkActions.SetLoading)
  public setLoading(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.SetLoading,
  ) {
    setState(patch<TrademarkStateModel>({ loading: payload }));
  }

  @Action(TrademarkActions.SetRequestType)
  public setRequestType(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.SetRequestType,
  ) {
    setState(patch<TrademarkStateModel>({ type: payload }));
  }

  @Action(TrademarkActions.ClearTrademarkState)
  public clearTrademarkSearchState(
    { setState }: StateContext<TrademarkStateModel>,
  ) {
    setState(patch<TrademarkStateModel>(TRADEMARK_INITIAL_STATE));
  }

  @Action(TrademarkActions.LoadTrademarkDraft)
  public loadTrademarkDraftRequest(
    { dispatch }: StateContext<TrademarkStateModel>,
    { id }: TrademarkActions.LoadTrademarkDraft,
  ) {
    dispatch(new TrademarkActions.SetLoading(true));
    return this.trademarkService.loadDraftRequest(id).pipe(
      map(response => dispatch(new TrademarkActions.LoadTrademarkDraftSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.LoadTrademarkDraftFail())),
    );
  }

  @Action(TrademarkActions.LoadTrademarkDraftSuccess)
  public loadTrademarkDraftRequestSuccess(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload: { items } }: TrademarkActions.LoadTrademarkDraftSuccess,
  ) {
    setState(patch<TrademarkStateModel>({ step: items[0].lastStage, draft: items[0], showSearchInput: true, type: items[0].type as RequestType }));
    dispatch([new TrademarkActions.SetLoading(false), new TrademarkActions.ActionByCurrentStep()]);
  }

  @Action(TrademarkActions.UploadTrademarkImage)
  public uploadTrademarkImage(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.UploadTrademarkImage,
  ) {
    if (payload.size > TrademarkState.MAX_SIZE) {
      return setState(patch<TrademarkStateModel>({ imageError: 'Максимальный размер файла 2 Mb' }));
    }
    if (!['image/png', 'image/jpeg', 'image/jpg'].includes(payload.type)) {
      return setState(patch<TrademarkStateModel>({ imageError: 'Недопустимый тип файла' }));
    }
    const reader = new FileReader();
    reader.onload = ({ target }: ProgressEvent<FileReader>) => {
      const image = new Image();
      image.src = target.result as string;
      image.onload = () =>
        dispatch(new TrademarkActions.UploadImage({
          body: { name: payload.name, image: target.result as string },
        }));
    }
    reader.readAsDataURL(payload);
  }

  @Action(TrademarkActions.UploadImage)
  public uploadImage(
    { dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.UploadImage,
  ) {
    return this.trademarkService.uploadImage(payload).pipe(
      map(response => dispatch(new TrademarkActions.UploadImageSuccess(response))),
      catchError(error => dispatch(new TrademarkActions.UploadImageFail(error))),
    );
  }

  @Action(TrademarkActions.UploadImageSuccess)
  public uploadImageSuccess(
    { getState, setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.UploadImageSuccess,
  ) {
    setState(patch<TrademarkStateModel>({ imageUrl: payload.path, imageError: '' }));
    if (getState().draft && getState().draft.details) {
      setState(patch<TrademarkStateModel>({ draft: patch<TrademarkDraft>({
        details: patch<TrademarkDraftDetails>({ imgSrc: payload.path }),
      })}));
    }
  }

  @Action(TrademarkActions.RemoveTrademarkImage)
  public removeTrademarkImage(
    { getState, setState }: StateContext<TrademarkStateModel>,
  ) {
    setState(patch<TrademarkStateModel>({ imageUrl: '', imageError: '' }));
    if (getState().draft && getState().draft.details) {
      setState(patch<TrademarkStateModel>({ draft: patch<TrademarkDraft>({
        details: patch<TrademarkDraftDetails>({ imgSrc: null }),
      })}));
    }
  }

  @Action(TrademarkActions.ShowOrganizationsList)
  public showOrganizationsList(
    { getState, setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.ShowOrganizationsList,
  ) {
    const organizations = this.store.selectSnapshot(OrganizationState.organizations);
    if (organizations.length && !getState().candidate) {
      setState(patch<TrademarkStateModel>({ viewOrganizationsList: payload }));
    }
  }

  @Action(TrademarkActions.SetOrganizationFromList)
  public setOrganizationFromList(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.SetOrganizationFromList,
  ) {
    setState(patch<TrademarkStateModel>({
      individual: payload.individual,
      tinForm: { model: { TIN: payload?.TIN ? payload?.TIN : '' } },
      ownerId: payload.id, organization: payload, payerError: '', owner: null,
      viewOrganizationsList: false, showFullForm: payload.nonResident, ownerForm: {
        model: { name: payload.name, address: payload.legalAdress, head: '' },
      },
    }));
    !payload.nonResident && dispatch(new TrademarkActions.LoadOwnerInfo({ id: payload.TIN }));
  }

  @Action(TrademarkActions.LoadOwnerInfo)
  public loadOwnerInfo(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadOwnerInfo,
  ) {
    setState(patch<TrademarkStateModel>({ isLoadingOwner: true }));
    return this.trademarkService.searchLegal(payload).pipe(
      map(response => dispatch(new TrademarkActions.LoadOwnerInfoSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.LoadOwnerInfoFail())),
    );
  }

  @Action(TrademarkActions.LoadOwnerInfoSuccess)
  public loadOwnerInfoSuccess(
    { getState, setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadOwnerInfoSuccess,
  ) {
    const owner = payload.hits.hits[0]?._source;
    const organization = getState().organization;
    const isIP = payload.hits.hits[0]?._index === 'individual_entrepreneurs';
    setState(patch<TrademarkStateModel>({
      isLoadingOwner: false,
      owner: owner ? owner : null,
      showFullForm: Boolean(owner),
      viewOrganizationsList: false,
      organization: !owner ? organization : null,
      ownerId: owner ? owner.id : organization.id,
      tinForm: { model: { TIN: owner ? owner.TIN : organization.TIN } },
      payerError: !owner ? 'Организация не найдена, введите ИНН другой организации' : '',
      ownerForm: { model: {
        name: owner ? owner.fullName : organization.name,
        address: owner ? owner.address : organization.legalAdress,
        head: owner ? this.getHeadOrganization(owner, isIP) : organization.director,
      } },
    }));
  }

  @Action(TrademarkActions.CheckValidTIN)
  public checkValidTIN(
    { dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.CheckValidTIN,
  ) {
    if (this.tinHelperService.checkTIN(payload)) {
      dispatch(new TrademarkActions.LoadOwnerCandidate(payload));
    }
  }

  @Action(TrademarkActions.LoadOwnerCandidate)
  public loadOwnerCandidate(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadOwnerCandidate,
  ) {
    setState(patch<TrademarkStateModel>({ payerError: '', isLoadingOwner: true }));
    return this.trademarkService.searchLegal({ id: payload }).pipe(
      map(response => dispatch(new TrademarkActions.LoadOwnerCandidateSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.LoadOwnerCandidateFail())),
    );
  }

  @Action(TrademarkActions.LoadOwnerCandidateSuccess)
  public loadOwnerCandidateSuccess(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadOwnerCandidateSuccess,
  ) {
    const organizations = this.store.selectSnapshot(OrganizationState.organizations);
    const candidate = payload.hits.hits[0]?._source;
    const isOrg = candidate ? organizations.find(org => org.TIN === candidate.TIN || org.id === candidate.id || +org.PSRN === candidate.PSRN) : null;
    setState(patch<TrademarkStateModel>({
      isLoadingOwner: false,
      viewOrganizationsList: false,
      showFullForm: candidate ? true : false,
      viewSelectContainer: Boolean(candidate),
      candidate: candidate ? candidate : null,
      ownerId: candidate ? candidate.id : null,
      individual: isOrg ? isOrg.individual : payload.hits.hits[0]?._index === 'individual_entrepreneurs',
      payerError: !candidate ? 'Организация не найдена, введите ИНН другой организации' : '',
    }));
  }

  @Action(TrademarkActions.CheckOrganization)
  public checkOrganization(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.CheckOrganization,
  ) {
    setState(patch<TrademarkStateModel>({ isCheckingCandidate: true }));
    return this.trademarkService.checkOrganization(payload).pipe(
      map(response => dispatch(new TrademarkActions.CheckOrganizationSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.CheckOrganizationFail())),
    );
  }

  @Action(TrademarkActions.CheckOrganizationSuccess)
  public checkOrganizationSuccess(
    { getState, setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.CheckOrganizationSuccess,
  ) {
    const candidate = getState().candidate;
    setState(patch<TrademarkStateModel>({
      candidate: null,
      organization: null,
      isCheckingCandidate: false,
      viewSelectContainer: false,
      showFullForm: payload.passed,
      ownerId: candidate ? candidate.id : null,
      owner: payload.passed ? candidate : null,
      ownerForm: { model: {
        name: payload.passed ? candidate.fullName: '',
        address: payload.passed ? candidate.address : '',
        head: payload.passed ? this.getHeadOrganization(candidate, true) : '',
      } },
      tinForm: { model: { TIN: payload.passed ? candidate.TIN : getState().tinForm.model.TIN } },
      payerError: !payload.passed ? 'Выбранная организация уже зарегистрирована, укажите другого плательщика' : '',
    }));
  }

  @Action(TrademarkActions.UpdateTrademarkDraft)
  public updateTrademarkDraft(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    dispatch(new TrademarkActions.SetLoading(true));
    const payload: UpdateTrademarkDraft = {
      stage: 1,
      type: getState().type,
      imgURL: getState().imageUrl ? getState().imageUrl : null,
      designation: getState().tmForm.model.imageText,
      interestData: this.interestService.addInterestData(),
      owner: {
        inn: this.tinHelperService.validateInn(getState().tinForm.model.TIN?.toString())
          ? getState().tinForm.model.TIN?.toString() : '',
        ogrn: this.tinHelperService.validateOgrn(getState().tinForm.model.TIN?.toString())
          ? getState().tinForm.model.TIN?.toString() : '',
        isNonResident: getState().owner ? true : false,
        name: getState().ownerForm.model.name,
        address: getState().ownerForm.model.address,
        director: getState().ownerForm.model.head,
        shortName: getState().owner ? getState().owner.name : '',
      },
    };
    if (getState().ownerId) {
      payload.owner.id = getState().ownerId;
    }
    if(getState().draft && getState().draft.ID) {
      payload.responseID = getState().draft.ID;
    }
    return this.trademarkService.createDraftRequest(payload).pipe(
      map(response => dispatch(new TrademarkActions.UpdateTrademarkDraftSuccess(response.responseID))),
      catchError(() => dispatch(new TrademarkActions.UpdateTrademarkDraftFail())),
    );
  }

  @Action(TrademarkActions.UpdateTrademarkDraftSuccess)
  public updateTrademarkDraftSuccess(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
    { responseID }: TrademarkActions.UpdateTrademarkDraftSuccess,
  ) {
    if (!getState().draft) {
      this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_TM_APPLY_SENT, typeIP: getState().type});
      return void this.zone.run(() => void this.router.navigate(['/', APP_ROUTES_NAMES.REQUESTS, getState().type, responseID]));
    }
    dispatch(new TrademarkActions.ClearTrademarkState());
    dispatch(new TrademarkActions.LoadTrademarkDraft(responseID));
  }

  @Action(TrademarkActions.ActionByCurrentStep)
  public actionByCurrentStep(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    switch(getState().step) {
      case 0: return dispatch(new TrademarkActions.FillFromDraft());
      case 1: return dispatch(new TrademarkActions.LoadOwnerByInn(getState().draft.inn));
      case 2: return dispatch(new TrademarkActions.LoadTariffes());
      case 3: return dispatch(new TrademarkActions.LoadTariffes());
      case 4: return dispatch(new TrademarkActions.LoadOwnerInfo({ id: getState().draft.inn }));
    }
  }

  @Action(TrademarkActions.BackStep)
  public backStep(
    { getState, setState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    getState().step === 0
      ? void this.zone.run(() => this.router.navigate(['/']))
      : setState(patch<TrademarkStateModel>({ step: getState().step - 1 }))
          && dispatch(new TrademarkActions.ActionByCurrentStep());
  }

  @Action(TrademarkActions.FillFromDraft)
  public fillFromDraft(
    { getState, setState, dispatch }: StateContext<TrademarkStateModel>
  ) {
    const draft = getState().draft;
    const organizations = this.store.selectSnapshot(OrganizationState.organizations);
    const organization = organizations.find(org => org.legalAdress === draft.legalAdress);

    setState(patch<TrademarkStateModel>({
      showFullForm: true,
      organization: organization,
      tmForm: { model: { imageText: draft.details.title && draft.details.title !== 'Изобразительный знак' ? draft.details.title : '' } },
      imageUrl: (draft && draft.details && draft.details.imgSrc) ? draft.details.imgSrc : null,
    }));
    if (draft.inn) {
      dispatch(new TrademarkActions.LoadOwnerInfo({ id: draft.inn }));
    } else {
      const name = getState().draft.info.find(item => item.title === 'Заявитель')
      setState(patch<TrademarkStateModel>({
        ownerForm: { model: {
          head: draft.director ? draft.director : '',
          name: name ? name.text : '',
          address: draft.legalAdress ? draft.legalAdress : '',
        }}
      }));
    }
  }

  @Action(TrademarkActions.LoadOwnerByInn)
  public loadOwnerByInn(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadOwnerByInn,
  ) {
    setState(patch<TrademarkStateModel>({ showSearchInput: false, loading: true }));
    return !payload
      ? setState(patch<TrademarkStateModel>({ owner: null, ownerId: null, showSearchInput: true, loading: false }))
      : this.trademarkService.searchLegal({ id: payload }).pipe(
          map(response => dispatch(new TrademarkActions.LoadOwnerByInnSuccess(response))),
          catchError(() => dispatch(new TrademarkActions.LoadOwnerByInnFail())),
        );
  }

  @Action(TrademarkActions.LoadOwnerByInnSuccess)
  public loadOwnerByInnSuccess(
    { getState, setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadOwnerByInnSuccess,
  ) {
    const organizations = this.store.selectSnapshot(OrganizationState.organizations);
    const isOrg = organizations.find(org => org.TIN === payload.hits.hits[0]?._source.TIN || org.id === payload.hits.hits[0]?._source.id || +org.PSRN === payload.hits.hits[0]?._source.PSRN)
    setState(patch<TrademarkStateModel>({
      loading: false,
      owner: payload.hits.hits[0]?._source,
      OKVEDCodes: payload.hits.hits[0]?._source.OKVEDCodes,
      individual: isOrg ? isOrg.individual : payload.hits.hits[0]?._index === 'individual_entrepreneurs',
    }));
    const info = getState().draft.info.find(item => item.title === 'Классы МКТУ');
    const draftMKTU = info.text.length ? info.text.split(', ').map(item => +item) : [];
    draftMKTU.length
      ? dispatch(new TrademarkActions.SetMKTUListByDraft(draftMKTU))
      : dispatch(new TrademarkActions.LoadMKTUByLastRequest());
  }

  @Action(TrademarkActions.SetMKTUListByDraft)
  public setMKTUListByDraft(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.SetMKTUListByDraft,
  ) {
    setState(patch<TrademarkStateModel>({
      loading: false,
      showSearchInput: true,
      optionsMktu: patch<OptionsMKTU>({ selectedMktu: append([...new Set(payload)]) }),
    }));
  }

  @Action(TrademarkActions.LoadMKTUByLastRequest)
  public loadMKTUByLastRequest(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    return this.trademarkService.loadLastRequestTrademark({ TIN: getState().owner.TIN, PSRN: getState().owner.PSRN.toString() }).pipe(
      map(response => dispatch(new TrademarkActions.LoadMKTUByLastRequestSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.LoadMKTUByLastRequestFail())),
    );
  }

  @Action(TrademarkActions.LoadMKTUByLastRequestSuccess)
  public loadMKTUByLastRequestSuccess(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadMKTUByLastRequestSuccess,
  ) {
    if (payload && payload._source && payload._source.goodsServices && payload._source.goodsServices.length) {
      payload._source.imageUrl = ImgHelper.getImgUrl(payload);
      setState(patch<TrademarkStateModel>({
        loading: false,
        showSearchInput: true,
        lastTrademark: payload,
        optionsMktu: patch<OptionsMKTU>({ selectedMktu: [ ...new Set(payload._source.goodsServices.map(item => item.classNumber)) ] }),
      }));
      payload._id && dispatch(new TrademarkActions.LoadDocumentById());
    } else {
      dispatch(new TrademarkActions.LoadMKTUByOKVED());
    }
  }

  @Action(TrademarkActions.LoadDocumentById)
  public loadDocumentById(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    const payload = { _id: getState().lastTrademark._id, _index: getState().lastTrademark._index };
    return this.trademarkService.loadDocumentById(payload).pipe(
      map(response => dispatch(new TrademarkActions.LoadDocumentByIdSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.LoadDocumentByIdFail())),
    );
  }

  @Action(TrademarkActions.LoadDocumentByIdSuccess)
  public loadDocumentByIdSuccess(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadDocumentByIdSuccess,
  ) {
    if (payload.hits.length) {
      setState(patch<TrademarkStateModel>({
        loading: false,
        lastTrademark: patch<LastRequestTrademark>({
          _source: patch<LastRequestTrademarkSource>({
            goodsServices: payload.hits[0]._source.goodsServices,
          }),
        }),
      }));
    }
  }

  @Action(TrademarkActions.LoadMKTUByOKVED)
  public loadMKTUByOKVED(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    return this.trademarkService.loadMktuByOkved({ eaNumber: getState().OKVEDCodes.map(item => item.code) }).pipe(
      map(response => dispatch(new TrademarkActions.LoadMKTUByOKVEDSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.LoadMKTUByOKVEDFail())),
    );
  }

  @Action(TrademarkActions.LoadMKTUByOKVEDSuccess)
  public loadMKTUByOKVEDSuccess(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadMKTUByOKVEDSuccess,
  ) {
    const mktu = payload.hits.map(item => item._source && item._source.codeMKTU);
    setState(patch<TrademarkStateModel>({
      showSearchInput: true,
      optionsMktu: patch<OptionsMKTU>({ selectedMktu: append(mktu) }),
    }));
  }

  @Action(TrademarkActions.UpdateMKTUClasses)
  public updateMKTUClasses(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.UpdateMKTUClasses,
  ) {
    setState(patch<TrademarkStateModel>({
      mktu: [...payload],
      optionsMktu: patch<OptionsMKTU>({ selectedMktu: [...payload.map(mktu => mktu.number)] }),
    }));
  }

  @Action(TrademarkActions.AddMKTUClass)
  public addMKTUClass(
    { getState, setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.AddMKTUClass,
  ) {
    const MKTUNumbersSet = new Set([...getState().optionsMktu.selectedMktu, payload.number]);
    setState(patch<TrademarkStateModel>({ optionsMktu: patch<OptionsMKTU>({ selectedMktu: [...MKTUNumbersSet] }) }));
    !getState().mktu.some(item => item.number === payload.number)
      && setState(patch<TrademarkStateModel>({ mktu: insertItem(payload) }));
  }

  @Action(TrademarkActions.RemoveMKTUClass)
  public removeMKTUClass(
    { setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.RemoveMKTUClass,
  ) {
    setState(patch<TrademarkStateModel>({
      mktu: removeItem(item => item.number === payload),
      optionsMktu: patch<OptionsMKTU>({ selectedMktu: removeItem(item => item === payload) }),
    }));
  }

  @Action(TrademarkActions.SaveMKTUForRequest)
  public saveMKTUForRequest(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    dispatch(new TrademarkActions.SetLoading(true));
    const payload: UpdateTrademarkDraft = {
      stage: 2,
      mktu: getState().mktu,
      okved: getState().OKVEDCodes,
      responseID: getState().draft.ID,
    }
    return this.trademarkService.createDraftRequest(payload).pipe(
      map(() => dispatch(new TrademarkActions.SaveMKTUForRequestSuccess())),
      catchError(() => dispatch(new TrademarkActions.SaveMKTUForRequestFail()))
    );
  }

  @Action(TrademarkActions.SaveMKTUForRequestSuccess)
  public saveMKTUForRequestSuccess(
    { getState, setState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    setState(patch<TrademarkStateModel>({ step: getState().step + 1 }));
    dispatch(new TrademarkActions.ActionByCurrentStep());
  }

  @Action(TrademarkActions.LoadTariffes)
  public loadTariffes(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    const payload: TariffPayload = { responseID: getState().draft.ID };
    if (getState().draft.promocode.id.length) {
      payload.promocode = getState().draft.promocode.id;
    }
    return this.trademarkService.loadTariffes(payload).pipe(
      map(resppnse => dispatch(new TrademarkActions.LoadTariffesSuccess(resppnse))),
      catchError(() => dispatch(new TrademarkActions.LoadTariffesFail())),
    );
  }

  @Action(TrademarkActions.LoadTariffesSuccess)
  public loadTariffesSuccess(
    { getState, setState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.LoadTariffesSuccess,
  ) {
    setState(patch<TrademarkStateModel>({
      loading: false,
      tariffResponse: payload,
      draft: patch<TrademarkDraft>({ tariff: getState().draft.tariff ? getState().draft.tariff : payload.tariff.find(item => item.default).id }),
    }));
  }

  @Action(TrademarkActions.SelectTariff)
  public selectTariff(
    { setState, getState }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.SelectTariff,
  ) {
    getState().tariffResponse.tariff.forEach(tariff => {
      if (tariff.id === payload) {
        this.intercomEventsService.push({
          event: INTERCOM_EVENT_NAME.SELECT_TARIF,
          typeIP: getState().type,
          tariff
        });
      }
    });
    setState(patch<TrademarkStateModel>({ draft: patch<TrademarkDraft>({ tariff: payload }) }));
  }

  @Action(TrademarkActions.ApplyPromoCode)
  public applyPromoCode(
    { setState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.ApplyPromoCode,
  ) {
    setState(patch<TrademarkStateModel>({
      draft: patch<TrademarkDraft>({ promocode: patch<TrademarkDraftPromocode>({ ...payload }) }),
    }));
    dispatch(new TrademarkActions.LoadTariffes());
  }

  @Action(TrademarkActions.ToPaymentStage)
  public toPaymentStage(
    { getState, dispatch}: StateContext<TrademarkStateModel>,
  ) {
    const payload: UpdateTrademarkDraft = {
      responseID: getState().draft.ID,
      stage: 3,
      tariffID: getState().draft.tariff,
      promocode: getState().draft.promocode.id,
    }
    return this.trademarkService.createDraftRequest(payload).pipe(
      map(() => dispatch(new TrademarkActions.ToPaymentStageSuccess())),
      catchError(() => dispatch(new TrademarkActions.ToPaymentStageFail())),
    );
  }

  @Action(TrademarkActions.ToPaymentStageSuccess)
  public toPaymentStageSuccess(
    { getState, setState, dispatch }: StateContext<TrademarkStateModel>,
  ) {
    getState().tariffResponse.tariff.forEach(tariff => {
      if (tariff.id === getState().draft.tariff) {
        this.intercomEventsService.push({
          event: INTERCOM_EVENT_NAME.SELECT_TARIF,
          typeIP: getState().type,
          tariff,
          totalPrice: tariff ? +tariff.sumDiscount + +getState().tariffResponse.duty.sumDiscount : 0,
        });
      }
    });
    setState(patch<TrademarkStateModel>({ step: getState().step + 1 }));
    dispatch(new TrademarkActions.ActionByCurrentStep());
  }

  @Action(TrademarkActions.PaymentCompletion)
  public paymentCompletion(
    { getState, dispatch }: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.PaymentCompletion
  ) {
    const data: UpdateTrademarkDraft = {
      ...payload,
      stage: 4,
      responseID: getState().draft.ID,
      type: this.store.selectSnapshot(PaymentState.currentTab),
      sum: this.store.selectSnapshot(TrademarkState.tariffResult).paymentSum.toString(),
    };
    return this.trademarkService.createDraftRequest(data).pipe(
      map(() => dispatch(new TrademarkActions.PaymentCompletionSuccess())),
      catchError(() => dispatch(new TrademarkActions.PaymentCompletionFail())),
    );
  }

  @Action(TrademarkActions.PaymentCompletionSuccess)
  public paymentCompletionSuccess(
    { getState, setState }: StateContext<TrademarkStateModel>,
  ) {
    setState(patch<TrademarkStateModel>({ step: getState().step + 1 }));
  }

  @Action(TrademarkActions.CancelRequest)
  public cancelRequest(
    _: StateContext<TrademarkStateModel>,
  ) { void this.zone.run(() => void this.router.navigate(['/'])); }

  @Action(TrademarkActions.ReloadTrademarkDraft)
  public reloadTrademarkDraft(
    { dispatch }: StateContext<TrademarkStateModel>,
    { id }: TrademarkActions.ReloadTrademarkDraft,
  ) {
    dispatch(new TrademarkActions.SetLoading(true));
    return this.trademarkService.loadDraftRequest(id).pipe(
      map(response => dispatch(new TrademarkActions.ReloadTrademarkDraftSuccess(response))),
      catchError(() => dispatch(new TrademarkActions.ReloadTrademarkDraftFail())),
    );
  }

  @Action(TrademarkActions.ReloadTrademarkDraftSuccess)
  public reloadTrademarkDraftSuccess(
    { setState }: StateContext<TrademarkStateModel>,
    { payload: { items } }: TrademarkActions.LoadTrademarkDraftSuccess,
  ) {
    setState(patch<TrademarkStateModel>({ draft: items[0], loading: false }));
  }

  @Action(TrademarkActions.SendIntercomEvent)
  public sendIntercomEvent(
    _: StateContext<TrademarkStateModel>,
    { payload }: TrademarkActions.SendIntercomEvent,
  ) { this.intercomEventsService.push({ event: payload }); }

  private getHeadOrganization(owner: SearchLegalResponseSourse, isIP: boolean): string {
    let head = '';
    if (owner.directors && owner.directors[0]) {
      const director = owner.directors[0];
      head = `${director.surname} ${director.name} ${director.patronymic}`;
    } else {
      head = isIP ? owner.fullName : '';
    }
    return head;
  }
}
