<div class="personal-data" >
  <h1 class="heading-h1" *ngIf="header">Номер телефона</h1>
  <ng-container *ngIf="noPhone; else success">
    <p class="personal-data__title">Для подачи заявки, пожалуйста, укажите свой контактный номер
      телефона</p>
<!--  <ng-container *ngIf="noPhone || isEditPhone; else success">-->
<!--    <div *ngIf="!phoneIsUpdated; else success"></div>-->
<!--    <p *ngIf="isEditPhone; else elseStartMessage" class="personal-data__title">-->
<!--      Для изменения номера, пожалуйста, укажите свой новый контактный номер телефона-->
<!--    </p>-->
<!--    <ng-template #elseStartMessage>-->
<!--      <p class="success__desc">Для подачи заявки, пожалуйста, укажите свой контактный номер телефона</p>-->
<!--    </ng-template>-->
    <form>
      <div class="personal-data__form" [class.shake]="animationStatus">
        <div class="personal-data__input-wrap" >
          <input type="tel"
                 [(ngModel)]="phoneUser"
                 [ngModelOptions]="{standalone: true}"
                 [ngClass]="codeError ? 'error_validation' : ''"
                 class="field personal-data__input"
                 id="phone"
                 required>
          <label class="personal-data__label" for="phone">Телефон</label>
        </div>
        <div class="personal-data__btn-wrap" [class.personal-data__btn-wrap--grow]="sendCode">
          <ng-container *ngIf="!sendCode; else code">
            <button class="btn personal-data__button" type="button"
                    [disabled]="!phoneUser"
                    (click)="codeGetter()">Получить код
            </button>
          </ng-container>
          <ng-template #code>
            <div class="personal-data__input-wrap personal-data__input-wrap--code">
              <input type="text"
                     #code="ngModel"
                     [class.successCode]="!codeError && code.dirty && code.value.length > 4"
                     [class.errorCode]="codeError && code.dirty"
                     [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="codePhone"
                     (keyup)="checkCode($event, 4)"
                     class="field personal-data__input personal-data__input--code"
                     id="code" required
              >
              <label class="personal-data__label" for="code">Ввести код</label>
            </div>
          </ng-template>
        </div>
      </div>
      <div *ngIf="phoneError && !sendCode" class="personal-data__error-wrap">
        <p class="personal-data__error-text">{{errorText}}</p>
      </div>
      <div class="personal-data__desc-wrap" *ngIf="sendCode">
        <div class="personal-data__desc">
          <div>
            <p *ngIf="showTimer && count > 1 && !codeError">Код отправлен на указанный телефон. Повторно выслать код можно через {{counter$ | async}} сек.</p>
            <span *ngIf="codeError" class="personal-data__error-text">Код введен неправильно.</span>
            <a *ngIf="showRepeat && count <= 1" (click)="fetchCodeRepeat()">Выслать код повторно</a>
          </div>
          <div>
            <a (click)="resetPhoneForm()" class="">Изменить номер телефона</a>
          </div>
        </div>
      </div>

    </form>
  </ng-container>
  <ng-template #success>
    <div class="personal-data__success success">
      <div class="success__title-wrap">
        <h3>Телефон успешно добавлен</h3>
        <button (click)="removeSuccess()" aria-label="Закрыть"></button>
      </div>
      <p *ngIf="successText" class="success__desc">Благодарим за предоставление вашего контактного номера телефона. Вы
        можете выбрать тип заявки
        для подачи на регистрацию ниже.</p>
<!--      <div *ngIf="successText">-->
<!--        <p *ngIf="isEditPhone; else elseSuccessMessage" class="success__desc">Телефон успешно сохранен</p>-->
<!--        <ng-template #elseSuccessMessage>-->
<!--          <p class="success__desc">Благодарим за предоставление вашего контактного номера телефона. Вы-->
<!--            можете выбрать тип заявки-->
<!--            для подачи на регистрацию ниже.</p>-->
<!--        </ng-template>-->
<!--      </div>-->
    </div>
  </ng-template>
</div>
