import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { IntegrationService } from '../services/integration.service';
import { Store } from '@ngxs/store';
import { AuthState } from '@web/core/states/auth.state';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  private previousBlockedUrl: string;  // (0) Стремненькая, но понятная реализация

  constructor(
    private router: Router,
    private integrationService: IntegrationService,
    private store: Store,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const monitoringLandingPage = '/monitoring/landing-alfa';
    const hasNonRedirectedRiskId = this.integrationService.getRiskId() && !this.integrationService.isRiskIdRedirected();

    if (hasNonRedirectedRiskId && state.url.includes(monitoringLandingPage)) {
      this.previousBlockedUrl = state.url.replace('-alfa', '');
      return true;
    } else {
      return this._canActivate(route, state);
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this._canActivate(route, state);
  }

  _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuth = this.store.selectSnapshot(AuthState.isAuth);
    if (!isAuth) {
      this.previousBlockedUrl = state.url; // (1) Если заблочили ссылку то сохраняем ее
      this.router.navigate(['/login']);
      return false;
    }

    // (2) Если мы авторизовались
    if (this.previousBlockedUrl) {     // (3) И у нас была заблоченая ссылка
      this.router.navigateByUrl(this.previousBlockedUrl);  // (4) Переходим по ней
      this.previousBlockedUrl = null;  // (5) Сбрасываем ее
      return false;                     // (6) И блокируем текущий роут (НЕ запомненный)
    }

    return true;
  }

  setPreviousBlockedUrl(url) {
    this.previousBlockedUrl = url;
  }
}
