import { Injectable } from '@angular/core';
import { DictionaryItem } from '../models/dictionary/dictionary-item-model';
import { Dictionary } from '../models/dictionary/dictionary-model';
import { NavigationPageType } from '../../app-ipid/src/app/shared/enums/navigation-page-type';
import { StorateType } from '../../app-ipid/src/app/shared/enums/storate-type';

@Injectable({providedIn: 'root'})

export class DictionaryService {

  public storageDictionary: Dictionary<StorateType, string>;
  public navigationDictionary: Dictionary<NavigationPageType, string>;

  constructor() {
    this.storageDictionary = new Dictionary<StorateType, string>();
    this.navigationDictionary = new Dictionary<NavigationPageType, string>();

    this._init();
  }

  private _init(): void {
    this._storageDictionary();
    this._navigationDictionary();
  }

  private _storageDictionary(): void {
    this.storageDictionary.add(new DictionaryItem<StorateType, string>({key: StorateType.Token, value: 'currentUserIPID'}));
    this.storageDictionary.add(new DictionaryItem<StorateType, string>({key: StorateType.CurrentUser, value: 'currentUserData'}));
  }

  private _navigationDictionary(): void {
    this.navigationDictionary.add(new DictionaryItem<NavigationPageType, string>({key: NavigationPageType.Login, value: 'login'}));
  }
}
