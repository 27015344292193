import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { SupportChatService } from '../../../../../../shared/services/support-chat.service';

@Component({
  selector: 'app-risk-contract-modal',
  templateUrl: './risk-contract-modal.component.html',
  styleUrls: ['./risk-contract-modal.component.scss']
})
export class RiskContractModalComponent implements OnInit {

  @Output() modalEvent = new EventEmitter();
  status = 'Критичный риск';

  constructor(private supportChatService: SupportChatService
  ) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.modalEvent.emit();
  }

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  onClickBtn(btnTitle: string) {
    this.supportChatService.open(btnTitle);
  }
}
