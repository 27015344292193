<div [class.white-bg]="options?.style === 'appIpIdSearchPage'" [class.legalsupport]="legalsupport">
  <div
    data-intercom-target="probability"
    [class.help-enabled]="helpEnabled"
    [class.help-disabled]="!helpEnabled"
    class="ip-id-search-risks risk-{{colorByPercent(risk)}}" [style.max-width]="legalsupport ? '100%': '1150px'">
    <div class="ip-id-search-risks-header">
      <h1>Вероятность регистрации</h1>
      <div class="risk-level" [class.highEnabled]="helpDisabledRiskName === 'Высокая'">
        <div class="risk-level__toggle-wrap risk-level__toggle-wrap--low">
          <div
            (click)="intercomEventsService.push({'event': 'clickScoreLow'}); toggleHelpEnabledMobile('low');sendDesignationLow()"
            #low
            class="risk-level__toggle risk-level__toggle--low"
            [class.active]="!helpEnabled"
          >
            <ng-container *ngIf="helpDisabledRiskName === 'Высокая'; else lowEnabled">
              <span class="with-risk-text">{{helpDisabledRiskName}}</span>
            </ng-container>
            <ng-template #lowEnabled>
              <span class="with-risk-text">{{helpDisabledRiskName}} <span>с рисками</span></span>
            </ng-template>
          </div>
        </div>
        <app-radio-button
          [checked]="helpEnabled"
          (toggle)="toggleHelpEnabled()"
          [color]="helpEnabledColor"
          [color2]="helpDisabledColor"
        ></app-radio-button>
        <div class="risk-level__toggle-wrap risk-level__toggle-wrap--high">
          <div
            (click)="intercomEventsService.push({'event': 'clickScoreHigh'});toggleHelpEnabledMobile('high');sendDesignationHigh()"
            class="risk-level__toggle risk-level__toggle--high"
            [class.active]="helpEnabled"
          >
          <span
            [innerText]="options?.style === 'appIpIdSearchPage' ? options?.dynamicStylesForBrand?.SEARCH_POSIBILITY_TEXT : legalsupport ? 'Высокая с Гардиумом':'Высокая с IP-ID'"></span>
          </div>
        </div>
      </div>

    </div>
    <div class="ip-id-search-risks-content">
      <div class="risks-notes left" *ngIf="probability === 'low' || probability === 'both'">
        <div>
          <i>{{totalTradeMarks ? (totalTradeMarks > 100 ? '100+' : totalTradeMarks) : '0'}}</i>
          <span>Товарные знаки</span>
        </div>
        <div>
          <i>{{totalLegalEntities ? (totalLegalEntities > 100 ? '100+' : totalLegalEntities) : '0'}}</i> <span>Фирменные наименования</span>
        </div>
        <div>
          <i>{{totalDomains ? (totalDomains > 100 ? '100+' : totalDomains) : '0'}}</i> <span>Доменные имена</span>
        </div>
      </div>
      <div class="risk-percent center" style="max-width: 370px">
        <!--белый круг в спидометре-->
        <div class="circle-wrap">
          <div class="circle"
               [class.circle-95]="helpDisabledRisk===95"
               [class.circle-96]="helpDisabledRisk===96"
               [class.circle-97]="helpDisabledRisk===97"
               [class.circle-98]="helpDisabledRisk===98"
               [class.circle-99]="helpDisabledRisk===99"
          ></div>
        </div>
        <div class="circle-wrap">
          <div class="circle2"
               [ngStyle]="helpEnabled ? getRoundGradient(helpEnabledRisk) :
              getRoundGradient(helpDisabledRisk)"></div>
        </div>
        <div class="risk-progress">

          <svg xmlns="http://www.w3.org/2000/svg" height="350" width="350" viewBox="-35 -5 270 270"
               style="overflow: visible">
            <defs>
              <linearGradient id="lgrad2" x1="0%" y1="100%" x2="100%" y2="100%">
                <stop offset="0%" style="stop-color:#BDDF32;stop-opacity:1"/>
                <stop class="stop-color" offset="100%" style="stop-opacity:1"/>
              </linearGradient>
            </defs>

            <!--левая дуга цветная-->
            <path
              class="meter custom-risk stroke-{{helpDisabledColor}}"
              d="M41 149.5a77 77 0 1 1 117.93 0"
              fill="none"
              [attr.stroke-dasharray]="helpDisabledParams.array + ' 1000'"
              [attr.stroke-dashoffset]="helpDisabledParams.offset"
            />

            <!--правая дуга цветная-->
            <path
              class="meter help-enabled-risk stroke2-{{helpEnabledColor}}"
              [attr.stroke]="'url(' + baseHref + '#lgrad2)'"
              d="M41 149.5a77 77 0 1 1 117.93 0"
              fill="none"
              [attr.stroke-dasharray]="helpEnabledParams.array + ' 1000'"
              [attr.stroke-dashoffset]="helpEnabledParams.offset"
            />

            <!--правая дуга серая-->
            <path
              class="meter unused-risk"
              [ngStyle]="{'stroke': legalsupport ? '#E1E5EB' : options?.dynamicStylesForBrand?.SEARCH_SPEEDOMETER_OUTLINE}"
              d="M41 149.5a77 77 0 1 1 117.93 0"
              fill="none"
              [attr.stroke-dasharray]="unusedProgressParams.array + ' 1000'"
              [attr.stroke-dashoffset]="unusedProgressParams.offset"
            />

            <!--красный хвостик-->
            <line
              class="stroke-{{helpDisabledColor}}"
              [attr.x1]="helpDisabledParams.x1"
              [attr.y1]="helpDisabledParams.y1"
              [attr.x2]="helpDisabledParams.x2"
              [attr.y2]="helpDisabledParams.y2"/>

            <!--текст левой дуги-->
            <text
              [attr.x]="helpDisabledParams.textLeft - 5"
              [attr.y]="helpDisabledParams.textTop - 10"
              class="fill-{{helpDisabledColor}} text"
            >{{helpDisabledRiskName}}
            </text>

            <!--зеленый хвостик-->
            <line
              *ngIf="helpEnabled"
              class="help-enabled-line stroke-{{helpEnabledColor}}"
              [attr.x1]="helpEnabledParams.x1"
              [attr.y1]="helpEnabledParams.y1"
              [attr.x2]="helpEnabledParams.x2"
              [attr.y2]="helpEnabledParams.y2"/>

            <!--белый разделитель-->
            <line
              [ngStyle]="{'stroke': legalsupport ? '#FFFFFF' : options?.dynamicStylesForBrand?.SEARCH_SPEEDOMETER_BACKGROUND}"
              [style.stroke-width.px]="getStrokeWidth(helpDisabledRisk)"
              [attr.x1]="helpDisabledParams.x1 + (helpDisabledRisk===5 ? 8 : helpDisabledRisk===45||46||47 ? 14 : 16)"
              [attr.y1]="helpDisabledParams.y1 + getY1(helpDisabledRisk)"
              [attr.x2]="101"
              [attr.y2]="100"/>
            <!--заготовка для конусного белого разделителя-->
            <!--              <polygon points="50 15, 58 100, 42 100"></polygon>-->

            <!--текст правой дуги-->
            <text
              *ngIf="helpEnabled"
              [attr.x]="helpEnabledParams.textLeft - 7"
              [attr.y]="helpEnabledParams.textTop - 13"
              class="fill-{{helpEnabledColor}} text"
            >{{helpEnabledRiskName}}
            </text>

            <!--серый круг-->
            <circle cx="100" cy="100" [attr.r]="isScale ? 20 : 24"
                    [ngStyle]="{'fill': legalsupport ? '#E1E5EB' : options?.dynamicStylesForBrand?.SEARCH_SPEEDOMETER_OUTLINE}"
            ></circle>

          </svg>

          <!--          <div class="main-risk-level-text" *ngIf="isProgressPercentShow">{{risk}}%</div>-->
        </div>

      </div>
      <div class="risks-notes right" *ngIf="probability === 'high' || probability === 'both'">
        <div>
          <i>+{{risks.sign_reworking}}%</i> <span>Доработка до уникальности</span>
        </div>
        <div>
          <i>+{{risks.mktu_completion}}%</i> <span>Работа с классами МКТУ</span>
        </div>
        <div>
          <i>+{{risks.legal_strategies}}%</i> <span>Юридические стратегии</span>
        </div>
      </div>
    </div>

    <div class="ip-id-search-risks-submit">
      <button (click)="sendDesignation()"
              class="request-btn">{{legalsupport ? 'Зарегистрировать' : 'Подать заявку'}}</button>
    </div>

  </div>
</div>
