export namespace ServicesActions {
  export class Init {
    static readonly type = '[services] Init';
  }

  export class LoadPopular {
    static readonly type = '[services] LoadPopular';
  }

  export class LoadDetails {
    static readonly type = '[services] LoadDetails';

    constructor(public id: string) {}
  }
}
