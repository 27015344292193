<div class="pay">
  <div class="pay__wrap">
    <div class="pay__top">
      <button (click)="closeModal()" class="pay__close" aria-label="Закрыть"></button>

      <img [src]="pay?.deposit ? '/assets/icons/icon-pay-plus.svg': '/assets/icons/icon-pay-minus.svg'" width="60"
           height="60">

      <p class="pay__total" [ngClass]="pay?.deposit ? 'green': ''">
        <span>{{ pay?.deposit ? '+' : '-' }}&nbsp;</span>
        <span>{{ pay?.sum?.toLocaleString('ru') }}</span>
        ₽
      </p>
    </div>
    <div class="pay__info">
      <dl class="pay__list">
        <div class="pay__item">
          <dt>Дата платежа</dt>
          <dd>{{ pay?.date | date:'dd.MM.yyyy' }}</dd>
        </div>
        <div class="pay__item">
          <!-- Оплачено = сумма - бонусы -->
          <dt>Оплачено</dt>
          <dd>{{ pay?.sum?.toLocaleString('ru') }} ₽</dd>
        </div>
        <div class="pay__item" *ngIf="pay?.bonus != null">
          <dt>Stub Использовано бонусов</dt>
          <dd>{{ pay?.bonus }}</dd>
        </div>
        <div class="pay__item">
          <dt>Способ оплаты</dt>
          <dd>Stub MasterCard **** 4535</dd>
        </div>
        <div class="pay__item">
          <dt>Организация</dt>
          <dd>{{ pay?.client }}</dd>
        </div>
        <div class="pay__item">
          <dt>Услуга</dt>
          <dd>{{ pay?.name }}</dd>
        </div>
      </dl>
      <!--ссылка Сохранить - загрузить PDF-файл-->
      <a class="pay__save">Сохранить</a>
    </div>
  </div>
  <div (click)="closeModal()" class="pay__overlay"></div>
</div>
