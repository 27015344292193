import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SupportChatService } from '../../../../../../shared/services/support-chat.service';

const hostUrl = `${window.location.protocol}//${window.location.host}`;
const HOST = (hostUrl.includes('localhost') || hostUrl.includes('127.0.0.1') || hostUrl.includes('10.1.33.225'))
  ? `${environment.BASE_URL_IMG}/tm`
  : `${hostUrl}/storage/tm`;

const RUTM_PATH = `${HOST}/RUTM`;
const WKTM_PATH = `${HOST}/WKTM`;
const WOTM_PATH = `${HOST}/WOTM`;
const RUTMAP_PATH = `${HOST}/RUTMAP`;
const KZTM_PATH = `${HOST}/KZTM`;
const UZTM_PATH = `${HOST}/UZTM`;
const BUTM_PATH = `${HOST}/BUTM`;


@Component({
  selector: 'app-risk-trademark-modal',
  templateUrl: './risk-trademark-modal.component.html',
  styleUrls: ['./risk-trademark-modal.component.scss']
})
export class RiskTrademarkModalComponent implements OnInit {

  OKVED = [
    {
      id: 100,
      number: '63.4.1',
      checked: ''
    },
    {
      id: 102,
      number: '63.2',
      checked: ''
    },
    {
      id: 103,
      number: '63.7.1',
      checked: ''
    },
    {
      id: 104,
      number: '63.1',
      checked: ''
    }
  ];

  MKTU = [
    {
      id: 1,
      checked: ''
    },
    {
      id: 2,
      checked: ''
    },
    {
      id: 26,
      checked: ''
    },
    {
      id: 34,
      checked: ''
    },
    {
      id: 5,
      checked: ''
    },
    {
      id: 9,
      checked: ''
    },
    {
      id: 76,
      checked: ''
    },
    {
      id: 46,
      checked: ''
    },
    {
      id: 10,
      checked: ''
    },
    {
      id: 11,
      checked: ''
    },
    {
      id: 12,
      checked: ''
    },
    {
      id: 13,
      checked: ''
    },
    {
      id: 77,
      checked: ''
    },
    {
      id: 62,
      checked: ''
    }
  ];

  imgUrl;

  @Input() data;
  @Output() modalEvent = new EventEmitter();
  @Output() hideRiskEvent = new EventEmitter();

  constructor(
    private router: Router,
    private supportChatService: SupportChatService
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.risk.opposedObject && (this.data.risk.opposedObject._index === 'rutm' || this.data.risk.opposedObject._index === 'rutmap')) {
      let id = this.data.risk.opposedObject.applicationString;
      if (this.data.risk.opposedObject._index === 'rutm') {
        id = this.data.risk.opposedObject.registrationString;
      }
      if (this.data.risk.opposedObject._index && id && this.data.risk.opposedObject.markImageFileName) {
        this.imgUrl = this.getImgUrl(this.data.risk.opposedObject._index, id, this.data.risk.opposedObject.markImageFileName);
      }
    }
    if (this.data.type === 'домен') {
      this.data.object.imageURL = 'assets/portfolio/domains.svg';
    }
  }

  closeModal() {
    this.modalEvent.emit();
  }

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  getRiskByLevel(level) {
    switch (level) {
      case 1:
        return 'Рекомендация';
      case 2:
        return 'Средний риск';
      case 3:
        return 'Высокий риск';
      case 4:
        return 'Критичный риск';
    }
  }

  getObjNameByIndex(index) {
    switch (index) {
      case 'rutm':
        return 'Товарный знак';
      case 'wotm':
        return 'Международный товарный знак';
      case 'wktm':
        return 'Общеизвестный товарный знак';
      case 'rugp':
        return 'Наименование мест происхождения товара';
      case 'rugpap':
        return 'Заявка на наименование мест происхождения товара';
      case 'rutmap':
        return 'Заявка на товарный знак';
      case 'domains':
        return 'Домен';
      case 'organizations':
        return 'Организация';
      case 'individual_entrepreneurs':
        return 'Индивидуальный предприниматель';
    }
  }

  getImgUrl(
    type: 'rutm' | 'wktm' | 'wotm' | 'rutmap' | 'kztm' | 'uztm' | 'butm',
    id: number,
    markImageFileName: string,
    fullId?: string
  ): string {
    if (type === 'rutm') {
      return `${RUTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wktm') {
      return `${WKTM_PATH}/${this.generatePath(id)}/${(fullId || id.toString()).replace('/', 'A')}/${markImageFileName}`;
    } else if (type === 'wotm') {
      return `${WOTM_PATH}/${this.generatePath(id)}/${markImageFileName}`;
    } else if (type === 'rutmap') {
      const year = id.toString().slice(0, 4);
      const realId = parseInt(id.toString().slice(4), 10);
      return `${RUTMAP_PATH}/NEW${year}/${this.generatePath(
        realId
      )}/${id}/${markImageFileName}`;
    } else if (type === 'kztm') {
      return `${KZTM_PATH}/${markImageFileName}`;
    } else if (type === 'uztm') {
      return `${UZTM_PATH}/${markImageFileName}`;
    } else if (type === 'butm') {
      return `${BUTM_PATH}/${markImageFileName}`;
    }
  }

  generatePath(num) {
    if (num < 1000) {
      return `0`;
    }
    const paths: string[] = [];
    const n = num.toString();
    for (let i = 1; n.length - i >= 3; i++) {
      paths.push(n.slice(0, i) + '0'.repeat(n.length - i));
    }
    return paths.join('/');
  }

  hideRisk() {
    this.hideRiskEvent.emit({
      objId: this.data.object.id,
      objIndex: this.data.object.index[0].id,
      riskId: this.data.risk.id
    });
  }

  toLeadForm(monitoringObject) {
    console.log('monitoringObject', monitoringObject);
    const data = {
      formIdentity: monitoringObject.riskData.button === 'Продлить' ? 'Продлить срок действия' : 'Оспорить риск',
      objectId: monitoringObject.object?.id,
      objectIndex: monitoringObject.object?.index[0]?.id,
      riskId: monitoringObject.risk?.riskID
    };
    this.router.navigate(['/treaty-extension'], {queryParams: {data: JSON.stringify(data)}});
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  getShowIdById(id: string): number {
    return +id.split('-')[0];
  }

  onClickBtn(btnTitle: string) {
    this.supportChatService.open(btnTitle);
  }
}
