import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SystemPageRoutesService } from './system-page-routes.service';


@NgModule({
  imports: [
    RouterModule.forChild(SystemPageRoutesService.buildRoutes()),
  ], exports: [
    RouterModule,
  ],
})
export class SystemPageRoutingModule {
}
