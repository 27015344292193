<div *ngIf="corpSearchService.isLoadingSearchHistory" class="search-history-main-loader">
  <div>
    Загрузка информации о поиске
  </div>
  <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)" stroke-width="2">
        <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
        <path d="M36 18c0-9.94-8.06-18-18-18" stroke="#152438">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"/>
        </path>
      </g>
    </g>
  </svg>
</div>

<ng-container *ngIf="!corpSearchService.isLoadingSearchHistory">
  <div class="corp-search">
    <section class="section-top-search">
      <div class="search_header">
        <div class="container">
          <div class="search-wrapper">
            <app-title-dropdown
              *ngIf="corpSearchService.searchType !== 'trademarks'"
              class="app-title-dropdown"
              title="Поиск"
              [value]="corpSearchService.searchType"
              [options]="corpSearchService.searchTypeOptions"
              (changeEvent)="corpSearchService.changeSearchType($event)"
            ></app-title-dropdown>
            <app-title-dropdown
              *ngIf="corpSearchService.searchType === 'trademarks'"
              class="app-title-dropdown"
              title="Поиск по товарным знакам"
            ></app-title-dropdown>
          </div>
        </div>
      </div>
    </section>

    <app-corp-search-tm
      *ngIf="corpSearchService.searchType === 'trademarks'"
      [initialSearchHistory]="corpSearchService.searchHistoryItem"
      [initialSearchHistoryAction]="corpSearchService.searchHistoryAction"
    ></app-corp-search-tm>

    <app-corp-search-by-name
      *ngIf="corpSearchService.searchType === 'name'"
      [initialSearchHistory]="corpSearchService.searchHistoryItem"
      [initialSearchHistoryAction]="corpSearchService.searchHistoryAction"
    ></app-corp-search-by-name>

    <app-corp-search-by-number
      *ngIf="corpSearchService.searchType === 'number'"
      [initialSearchHistory]="corpSearchService.searchHistoryItem"
      [initialSearchHistoryAction]="corpSearchService.searchHistoryAction"
    ></app-corp-search-by-number>

    <app-corp-search-by-owner
      *ngIf="corpSearchService.searchType === 'owner'"
      [initialSearchHistory]="corpSearchService.searchHistoryItem"
      [initialSearchHistoryAction]="corpSearchService.searchHistoryAction"
    ></app-corp-search-by-owner>

    <app-corp-search-by-image
      *ngIf="corpSearchService.searchType === 'image'"
      [initialSearchHistory]="corpSearchService.searchHistoryItem"
      [initialSearchHistoryAction]="corpSearchService.searchHistoryAction"
    ></app-corp-search-by-image>
  </div>

  <div class="corp-search__expert">
    <app-expert>
    </app-expert>
  </div>
</ng-container>
