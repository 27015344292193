import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiOptions } from '../models/api-options.interface';
import { Store } from '@ngxs/store';
import { AUTH_STATE_TOKEN } from '@web/core/states/auth.state';

export abstract class ApiService {
  constructor(
    protected readonly httpClient: HttpClient,
    protected readonly apiUrl: string,
    protected readonly store: Store,
  ) {}

  get<T>(method: string, options?: ApiOptions): Promise<T> {
    return this.httpClient
      .get<T>(`${this.apiUrl}/${method}`, this.modifyOptions(options))
      .toPromise();
  }

  post<T>(
    method: string,
    body: unknown | null,
    options?: ApiOptions
  ): Promise<T> {
    return this.httpClient
      .post<T>(`${this.apiUrl}/${method}`, body, this.modifyOptions(options))
      .toPromise();
  }

  put<T>(
    method: string,
    body: unknown | null,
    options?: ApiOptions
  ): Promise<T> {
    return this.httpClient
      .put<T>(`${this.apiUrl}/${method}`, body, this.modifyOptions(options))
      .toPromise();
  }

  patch<T>(
    method: string,
    body: unknown | null,
    options?: ApiOptions
  ): Promise<T> {
    return this.httpClient
      .patch<T>(`${this.apiUrl}/${method}`, body, this.modifyOptions(options))
      .toPromise();
  }

  delete<T>(method: string, options?: ApiOptions): Promise<T> {
    return this.httpClient
      .delete<T>(`${this.apiUrl}/${method}`, this.modifyOptions(options))
      .toPromise();
  }

  private modifyOptions(options?: ApiOptions): ApiOptions {
    let headers: HttpHeaders = this.getHeaders();
    if (options && (options.headers || options.isFile)) {
      if (options.headers instanceof HttpHeaders && !options.isFile) {
        for (const key of options.headers.keys()) {
          headers = headers.append(key, options.headers[key]);
        }
      } else if (!options.isFile) {
        for (const key of Object.keys(options.headers)) {
          headers = headers.append(key, options.headers[key]);
        }
      } else if (options.isFile) {
        headers = headers.delete('Content-Type');
        headers = headers.append('Content-Type', 'multipart/form-data');
      }
    }
    let params: HttpParams = this.getParams();
    if (options && (options.params || options.isFile)) {
      if (options.params instanceof HttpParams && !options.isFile) {
        for (const key of options.params.keys()) {
          params = params.append(key, options.params[key]);
        }
      } else if (!options.isFile) {
        for (const key of Object.keys(options.params)) {
          params = params.append(key, options.params[key]);
        }
      } else if (options.isFile) {
        params = params.delete('token');
      }
    }
    return {
      ...options,
      headers,
      params,
    };
  }

  protected getParams(): HttpParams {
    let params = new HttpParams();
    const token = this.store.selectSnapshot(AUTH_STATE_TOKEN).token;
    if (token) {
      params = params.append('token', token);
    }
    return params;
  }

  protected abstract getHeaders(): HttpHeaders;
}
