import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SearchService } from '../../../../../services/search.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CorpSearchService } from '@web/features/corp/corp-search/services/corp-search.service';

@Component({
  selector: 'app-nmpt-modal',
  templateUrl: './nmpt-modal.component.html',
  styleUrls: ['./nmpt-modal.component.scss']
})

export class NmptModalComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() modalEvent = new EventEmitter();
  private readonly onDestroy = new Subject<void>();
  sourceData: any = {};
  isLoading = false;

  @HostListener('document:keyup', ['$event']) // 27=esc
  keyup(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  constructor(
    private searchService: SearchService,
    private corp: CorpSearchService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '0';
    }
    this.getDocById();
  }

  getDocById() {
    const query = {
      _id: this.data._id,
      _index: this.data._index
    };
    this.isLoading = true;
    this.searchService.getDocumentById(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits?.length) {
        this.sourceData = data.hits.hits[0];
        this.sourceData.indexType = `RU ${this.sourceData._source.applicationString}`;
        this.isLoading = false;
        this.ref.detectChanges();
      }
    });
  }

  getExternalLink(index) {
    return this.corp.getExternalUrl(index);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  closeModal() {
    if (document.getElementsByTagName('header')[0]) {
      document.getElementsByTagName('header')[0].style.zIndex = '1';
    }
    this.modalEvent.emit();
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }
}
