import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cases-list-item',
  templateUrl: './cases-list-item.component.html',
  styleUrls: ['./cases-list-item.component.scss']
})
export class CasesListItemComponent implements OnInit {

  @Input() case;
  @Input() draft;

  trackColors = ['yellow', 'blue', 'purple', 'dark-green', 'green', 'green', 'green'];

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  toTask(id, type) {
    if (type === 0) {
      this.router.navigate([`/cases/cases-item/${id}`], {queryParams: {isDraft: true}});
    } else {
      this.router.navigate([`/cases/cases-item/${id}`]);
    }
  }

  getShortName(name) {
    return name.length > 8 ? name.substr(0, 6) + '...' : name;
  }

}
