import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { CasesService } from '../../cases/cases.service';
import { HttpService } from '../../../shared/services/http.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { DemoService } from '../../../shared/services/demo.service';
import { EnvService } from '../../../shared/services/env.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';

declare var window: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [],
})
export class IndexComponent implements OnInit, OnDestroy {

  private readonly onDestroy = new Subject<void>();

  push = false;
  submit = false;
  tasks;
  case;
  active;
  trackData;
  trackItems;
  trackProgress;
  lastGoal;
  casesTotal;
  designation;
  isLoadingCases = false;
  showRecommendation = false;

  // Временно; не заполнены личные данные
  personalData = false;

  constructor(
    readonly demoService: DemoService,
    readonly envService: EnvService,
    private casesApi: CasesService,
    private httpService: HttpService,
    private sharedApi: SharedService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  @ViewChild('attention') attentionRef: ElementRef;

  ngOnInit() {
    this.getTasks();
    this.checkUserData();
  }

  checkUserData(){
    const user: any = JSON.parse(localStorage.getItem('currentUserData'));
    if (!user.name && !user.surname && !user.position){
      this.showRecommendation = true;
    }
  }
  toggleDemoHasDeal() {
    this.demoService.updateIndexPage({myDeal: !this.demoService.indexPage.myDeal});
  }

  getTasks() {
    this.casesApi.fetchTasks().pipe(takeUntil(this.onDestroy))
      .pipe(takeUntil(this.onDestroy))
      .subscribe(tasksData => {
        this.tasks = tasksData;
        if (this.tasks) {
          this.active = this.tasks.tasks.items.filter(i => i.active === true && i.status);
          this.case = this.tasks.tasks.items.filter(i => i.status);
        }
        if (this.case?.length) {
          this.getTracks();
          this.getCasesLength();
        }
        this.isLoadingCases = true;
      });
    console.log(this.tasks, 'new test');
  }

  getCasesLength() {
    if (this.case.length === 0) {
      this.casesTotal = '';
    } else if (this.case.length === 1) {
      this.casesTotal = '1 ДЕЛO';
    } else {
      this.casesTotal = `ЕЩЕ ${this.case.length - 1} ДЕЛ`;
    }
  }

  getTracks() {
    const params = {
      ID: this.case[0].ID
    };
    this.casesApi.fetchTracks(params).pipe(takeUntil(this.onDestroy))
      .subscribe(trackData => {
        this.trackItems = trackData;
        this.trackData = this.trackItems.tracks.items;
        this.trackProgress = this.trackData.filter(i => i.reach);
        if (this.trackProgress) {
          this.lastGoal = this.trackProgress[this.trackProgress.length - 1];
        }
      });
  }

  setActiveCase() {
    localStorage.setItem('selectCase', (this.case[0].ID));
  }

  setActiveOther() {
    if (this.case[1]) {
      localStorage.setItem('selectCase', (this.case[1].ID));
    } else {
      this.setActiveCase();
    }
  }

  openModal() {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.CLICK_ORDER_APPLY });
    window.scrollTo( 0, 0);
    // TODO: подумать над этим
    this.sharedApi.openModal.next(null);
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  onSubmit() {
  }

  removeAttention(): void {
    this.attentionRef.nativeElement.remove();
  }
}
