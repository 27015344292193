import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CasesService } from '../cases/cases.service';
import * as contractModel from './shared/contract.model';
import { SearchService } from '../../../../../app-search/src/app/services/search.service';
import {IContractsParrent, IResponse, ISearchObjects} from '../../shared/models/main.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private user = 'aa';
  private password = 'Aa123456';
  private bodyForElasticRequest = {
    SHA1hash: this.getHash()
  };

  constructor(
    private httpService: HttpService,
    private casesService: CasesService,
    private searchService: SearchService,
  ) {
  }

  getHash() {
    return Base64.stringify(sha1(this.user.toLowerCase() + this.password));
  }

  /**
   * Запрос на восстановление заявки из черновика
   * @param responseID: id заявка
   * @param params: дополнительные параметры
   */
  requestContractGetDetail(params, responseID: string) {
    return this.casesService.getCase(params, responseID)
      .pipe(
        map((data: any) => {
          const task = data?.case?.tasks?.items[0];
          return {
            responseID,
            task
          };
        })
      );
  }

  /**
   * Запрос на отправку Вида договора
   * @param data: модель для отправки Вида договора
   */
  requestContractStage1(data: contractModel.IRequestContractStage1): Observable<contractModel.IRequestContractResponse> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 1, type: 'contract'} });
  }

  /**
   * Запрос на отправку Сторон договора
   * @param data: модель для отправки Сторон договора
   */
  requestContractStage2(data: contractModel.IRequestContractStage2): Observable<contractModel.IRequestContractResponse> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 2, type: 'contract' } });
  }

  /**
   * Запрос на отправку Объектов договора
   * @param data: модель для отправки Объектов договора
   */
  requestContractStage3(data: contractModel.IRequestContractStage3): Observable<contractModel.IRequestContractResponseRisk> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 3, type: 'contract' } });
  }

  // getRisksByObject(body: any) {
  //   return this.httpService.post({path: '_qualifyrisksbyid', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  // }

  /**
   * Запрос на отправку Предмета договора
   * @param data: модель для отправки Предмета договора
   */
  requestContractStage4(data: contractModel.IRequestContractStage4): Observable<contractModel.IRequestContractResponse> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 5} });
  }

  /**
   * Запрос на отправку Тарифа
   * @param data: модель для отправки Тарифа
   */
  requestContractStage5(data: contractModel.IRequestContractStage5): Observable<contractModel.IRequestContractResponse> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 6} });
  }

  getLegalByName(name: string): Observable<any> {
    return this.searchService.getLegal({id: name});
  }

  requestContractStage6(data: contractModel.IRequestContractStage6): Observable<contractModel.IRequestContractResponse> {
    return this.httpService.post({ path: 'request/load', body: { ...data, stage: 7 }  });
  }

  getContractsNew(params) {
    return this.httpService.get<IContractsParrent>({path: `objects/contracts`, subjectType: 'Get contracts', params});
  }

  getContracts(body: ISearchObjects) {
    return this.httpService.post({path: '_contractinfo', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }

  deleteTask(params) {
    return this.httpService.get<IResponse>({path: `tasks/delete`, subjectType: 'delete task', params});
  }

}
