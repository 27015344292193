import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '../../../shared/services/layout.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-request-modal',
  templateUrl: './create-request-modal.component.html',
  styleUrls: ['./create-request-modal.component.scss']
})
export class CreateRequestModalComponent implements OnInit, OnDestroy {
  @Input() disabledTypes: string[] = [];

  private readonly onDestroy = new Subject();
  showModal = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly layoutService: LayoutService
  ) {
  }

  ngOnInit(): void {
    this.layoutService.createRequestModal.open
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.showModal = true;
      });
    this.layoutService.createRequestModal.close
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.showModal = false;
      });
  }

  isDisabled(type: string) {
    return this.disabledTypes.includes(type);
  }

  ngOnDestroy() {
    // TODO: подумать над этим
    this.onDestroy.next(null);
  }

  toRequestPage(type) {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/request') || currentUrl === 'undefined') {
      window.location.href = `/request?type=${type}`;
    } else {
      this.router.navigate(['/request'], { relativeTo: this.route, queryParams: { type } });
    }
    this.closeModal();
  }

  closeModal() {
    this.layoutService.closeCreateRequestModal();
  }

}
