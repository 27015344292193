import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractsComponent } from './contracts.component';
import { ContractTariffComponent } from './contract-tariff/contract-tariff.component';

const systemRoutes: Routes = [
  {
    path: '',
    component: ContractsComponent,
  },
  {
    path: 'tariff',
    component: ContractTariffComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(systemRoutes)],
  exports: [RouterModule]
})
export class ContractsRoutingModule {
}
