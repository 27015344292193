<div class="parrent_modal">

  <div class="modal">
    <ng-container *ngIf="!isLoading && sourceData?._source; else loader">

      <div class="top-hat">
        <div
          [ngClass]=" sourceData._source.domainRegistrator === 'RUCENTER-RF' || sourceData._source.domainRegistrator === 'RU-CENTER-RU'
          ? 'top-hat__logo-nic': 'top-hat__logo-reg'"></div>
        <div class="top-hat__info" [innerText]="getDomain(sourceData)">
        </div>
        <button class="top-hat__btn-close" (click)="closeModal()"></button>
        <!--        <button type="button" class="top-hat__button">Задать вопрос онлайн</button>-->
      </div>

      <div class="domain-body">
        <div class="domain-body__buttons_risk">
          <button class="btn-risk box_button_warning" [innerText]="sourceData._source.free ? 'Свободен' : 'Занят'">
          </button>
          <button class="btn-risk box_button_want">Интересен этот домен
          </button>
          <div class="risk-hint">
            <div class="risk-tooltip"
                 *ngIf="(data._risk.toLowerCase() ===  'high') || (data._risk.toLowerCase() ===  'medium')"
            >Этот объект создает значительные риски для вашего товарного знака
            </div>
            <div class="risk-tooltip" *ngIf="data._risk.toLowerCase() === 'low' || data._risk.toLowerCase() === 'none'"
            >Этот объект создает незначительные риски для вашего товарного знака
            </div>
          </div>
        </div>

        <div class="domain-body__header">
          Доменное имя
          <span [innerText]="getDomain(sourceData)">
          </span>
        </div>

        <div class="domain-body__data">
          <div class="image-container">
            <div class="image-container__name" [innerText]="getShortNameByWords(getDomain(sourceData))">
            </div>
            <div class="domain-popover">{{getDomain(sourceData).toLowerCase()}}</div>
          </div>
          <div class="info-container">
            <div class="info-container__top">
              <div class="feature">
                <div class="title">Администратор домена</div>
                <div class="text status-domain"
                     [innerText]="sourceData._source.applicantStatus === 1 ? 'Организация' : 'private person'"></div>
              </div>
              <div class="feature" *ngIf="owner">
                <div class="title">Владелец</div>
                <div class="text">
                  {{owner}}
                </div>
              </div>
            </div>
            <div class="info-container__bottom">
              <div class="first-column">
                <div class="feature">
                  <div class="title">Дата регистрации</div>
                  <div class="text">
                    {{sourceData._source.registrationDate | date:'dd.MM.yyyy'}}
                  </div>
                </div>
                <div class="feature">
                  <div class="title">Дата освобождения</div>
                  <div class="text">
                    {{sourceData._source.freeDate | date:'dd.MM.yyyy'}}
                  </div>
                </div>
              </div>
              <div class="second-column">
                <div class="feature">
                  <div class="title">Статус</div>
                  <div class="text" [innerText]="sourceData._source.free ? 'Свободен' : 'Занят'"></div>
                </div>
                <div class="feature">
                  <div class="title">Срок действия</div>
                  <div class="text">{{sourceData._source.payDate | date:'dd.MM.yyyy'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="divider"></div>
        <app-accordion
          [title]="'Другие домены этого владельца'"
          [count]="overDomains && overDomains.length"
        >
          <div *ngIf="overDomains && overDomains.length">
            <ul>
              <li *ngFor="let domain of overDomains">
                <span class="domain" [innerText]="getDomain(domain).toLowerCase()"></span>
              </li>
            </ul>
          </div>
        </app-accordion>
      </div>

    </ng-container>
  </div>

  <div (click)="closeModal()" class="overlay-space"></div>
</div>

<div class="page-overlay" id="modal-overlay"></div>

<ng-template #loader>
  <div class="loader-wrapper">
    <app-ls-emb-loader></app-ls-emb-loader>
  </div>
</ng-template>
