<div *ngIf="!(isOtkritite$ | async)">
  <header class="header header--centered">
    <a class="header__logo" href="#">
      <img [src]="(appState$ | async).logoPath" style="max-height: 30px; height: 100%; max-width: none">
    </a>
  </header>

  <div class="page-wrapper">
    <main>
      <section class="form-section">
        <div class="container-sm">
          <h1 class="heading-h1 heading-h1--centered" id="authorization">Авторизация</h1>

          <div *ngIf="hasSocialAuth$ | async" class="external-reg">
            <div class="external-reg__links-wrapper">
              <div (click)="yandexCodeGet()" class="external-reg__link">
                <img class="external-reg__icon" alt="" src="assets/ya.svg"/>
                <p class="external-reg__txt">Продолжить с Яндекс</p>
              </div>
              <div (click)="googleRegister()" class="external-reg__link">
                <img class="external-reg__icon" alt="" src="assets/google.svg"/>
                <p class="external-reg__txt">Продолжить с Google</p>
              </div>
<!--              <div (click)="facebookLogin()" class="external-reg__link">-->
<!--                <img class="external-reg__icon" alt="" src="../../../assets/fb.svg"/>-->
<!--                <p class="external-reg__txt">Продолжить с Facebook</p>-->
<!--              </div>-->
            </div>
            <div class="external-reg__alternative-text">
              <p>или войти через email</p>
            </div>
          </div>

          <form [formGroup]="loginForm" class="form">
            <div *ngIf="incorrectLoginData" class="errors not-mobile">
            <span class="login_error">
                Неверный логин или пароль.
              </span>
            </div>
            <div class="form__group form__group--email">
              <div class="form__input-wrap">
                <input formControlName="email"
                       class="form__input field"
                       type="text"
                       id="userEmail"
                       [ngClass]="{ 'error': loginForm.get('email').invalid && loginForm.get('email').touched || incorrectLoginData }"
                       email
                       required>
                <span class="form__input-label">Адрес электронной почты</span>
              </div>
            </div>
            <div class="form__group">
              <div class="form__input-wrap form__pass">
                <input formControlName="password"
                       class="form__input form__input--linked field"
                       type="password"
                       id="userPassword"
                       [ngClass]="{ 'error': loginForm.get('password').invalid && loginForm.get('password').touched || incorrectLoginData }"
                       required>
                <a *ngIf="brand !== 'LEGAL-DEBT'" class="bordered-gray form__input-link not-mobile" [routerLink]="['/recovery']" id="forgetPassword">Забыли
                  пароль?</a><span class="form__input-label" id="password">Пароль</span>
              </div>
              <div *ngIf="incorrectLoginData" class="errors mobile">
            <span class="login_error">
                Неверный логин или пароль.
              </span>
              </div>
            </div>
            <div class="form__footer">
              <button
                (click)="login()"
                class="btn btn__auth btn--full"
                id="submitLogin"
                [disabled]="loginForm.invalid || loginForm.disabled || (isLoading$ | async)"
                type="button"
              >
                <span *ngIf="isLoading$ | async" class="loaderAuth" id="login"></span>
                {{ (isLoading$ | async) ? '' : 'Войти'}}
              </button>
            </div>
          </form>
          <a class="bordered-gray form__input-link mobile mobile--text" [routerLink]="['/recovery']">Забыли пароль?</a>
          <p class="form-section__account" id="registration" [routerLink]="['/registration']">
            Нет аккаунта? <a class="bordered-blue" id="registrationHeader">Зарегистрироваться</a>
          </p>
          <a class="bordered-blue support-link" (click)="openModalSupport()">Обращение в техническую поддержку</a>
        </div>
        <app-support-modal
          *ngIf="showModalSupport"
          [showModal]="showModalSupport"
          (closeModalSupportPopup)="closeModalSupport()">>
        </app-support-modal>
      </section>
    </main>
    <footer class="footer footer--centered">
      <p class="footer__lisence-info">{{(appState$ | async).footerText}}</p>
    </footer>
  </div>
</div>
