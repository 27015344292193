export interface ISourceModel {
  _index: ISourceIndex;
  _source: any;
}

export type ISourceIndex = 'rutm' | 'wotm' | 'rutmap' | 'rupat' | 'ruum' | 'rude' | 'rusw'
  | 'rudb' | 'domain' | 'organizations' | 'individual_entrepreneurs' | 'rugp' | 'rugpap';

export enum SourceIndexGroup {
  Trademark = 'trademark',
  TrademarkInternational = 'trademark-international',
  TrademarkRequest = 'trademark-request',

  PatentInvention = 'patent-invention',
  PatentUtilityModel = 'patent-utility-model',
  PatentIndustrialModel = 'patent-industrial-model',

  Program = 'program',
  Database = 'database',
  Domain = 'domain',
  LegalName = 'legalname',
  Nmpt = 'nmpt',
  NmptRequest = 'nmpt-request',
}

const indexTypes: { [key: string]: [SourceIndexGroup, string] } = {
  'rutm': [SourceIndexGroup.Trademark, 'Товарный знак'],
  'wotm': [SourceIndexGroup.TrademarkInternational, 'Товарный знак международный'],
  'rutmap': [SourceIndexGroup.TrademarkRequest, 'Заявка на товарный знак'],

  'rupat': [SourceIndexGroup.PatentInvention, 'Патент на изобретение'],
  'ruum': [SourceIndexGroup.PatentUtilityModel, 'Патент на полезную модель'],
  'rude': [SourceIndexGroup.PatentIndustrialModel, 'Патент на промышленный образец'],

  'rusw': [SourceIndexGroup.Program, 'Программа'],
  'rudb': [SourceIndexGroup.Database, 'База данных'],
  'domain': [SourceIndexGroup.Domain, 'Домен'],
  'rugp': [SourceIndexGroup.Nmpt, 'НМПТ'],
  'rugpap': [SourceIndexGroup.NmptRequest, 'Заявка на НМПТ']
};

export const getSourceIndexInfo = (source: string | ISourceModel) => {
  const [group, title] = indexTypes[typeof source === 'string' ? source : source._index];
  return {group, title};
};

export const getAllIndexTypes = () => Object.keys(indexTypes);

export const getSourceGroups = () => {
};
