import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AppActions } from './app.actions';
import { environment as ipidEnvironment } from '../../../app-ipid/src/environments/environment';
import { environment as debitEnvironment } from '../../../app-debit/src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Brand } from '@web/core/models/brand.enum';
import { fromEvent, SubscriptionLike } from 'rxjs';
import { ScriptLoaderService } from '../../../shared/services/script-loader.service';
import { promisify } from '@web/utils/promisify.util';
import { WebModuleConfig } from '@web/core/services/config.service';

export const APP_STATE_TOKEN = new StateToken<AppStateModel>('app');
declare var window: any;

@State({
  name: APP_STATE_TOKEN,
  defaults: {
    windowWidth: null,
    windowHeight: null,
    brand: null,
    isDebit: null,
    footerText: null,
    logoPath: null,
  },
})
@Injectable()
export class AppState {
  private resizeSub: SubscriptionLike;

  constructor(
    private route: ActivatedRoute,
    private scriptLoaderService: ScriptLoaderService,
    private config: WebModuleConfig
  ) {}

  @Selector()
  static hasSocialAuth(state: AppStateModel) {
    return (
      state.brand === Brand['GARDIUM-ONLINE'] || state.brand === Brand.ALFA
    );
  }

  @Selector()
  static isDebit(state: AppStateModel) {
    return state.isDebit;
  }

  @Selector()
  static isLoadCustomScripts(state: AppStateModel) {
    return AppState._isLoadCustomScripts(state.brand);
  }

  static _isLoadCustomScripts(brand: string) {
    return (
      brand === Brand['GARDIUM-ONLINE'] ||
      brand === Brand.ALFA ||
      brand === Brand['1C-START'] ||
      brand === Brand['MTS-KASSA'] ||
      brand === Brand['IPID-PRO'] ||
      brand === Brand.YOOKASSA ||
      brand === Brand.TOCHKA
    );
  }

  @Selector()
  static isOtkritie(state: AppStateModel) {
    return AppState.isBrandOpen(state.brand);
  }

  @Selector()
  static X5(state: AppStateModel) {
    return state.brand === Brand.X5;
  }

  static isBrandOpen(brand: string) {
    return brand === Brand.OPEN;
  }

  @Selector()
  static isMonitoringEnabled(state: AppStateModel) {
    return (
      state.brand === Brand['IP-ID'] ||
      state.brand === Brand['GARDIUM-ONLINE'] ||
      state.brand === Brand.ALFA ||
      state.brand === Brand.SBER
    );
  }

  @Selector()
  static isMobile(state: AppStateModel) {
    return state.windowWidth <= 793 ?? false;
  }

  @Selector()
  static getWindowWidth(state: AppStateModel) {
    return state.windowWidth;
  }

  @Selector()
  static getLayout(state: AppStateModel): 'sm' | 'md' | 'lg' {
    if (state.windowWidth <= 793) {
      return 'sm';
    } else if (state.windowWidth <= 1100) {
      return 'md';
    } else {
      return 'lg';
    }
  }

  @Selector()
  static getBrand(state: AppStateModel): string {
    return state.brand;
  }

  @Action(AppActions.Init)
  async init(ctx: Context) {
    ctx.dispatch(new AppActions.ListenWindowWidth());
    await ctx.dispatch(new AppActions.SetBrand());
  }

  @Action(AppActions.ListenWindowWidth)
  listenWindowWidth(ctx: Context) {
    ctx.patchState({ windowWidth: window.innerWidth });

    if (!this.resizeSub) {
      this.resizeSub = fromEvent(window, 'resize').subscribe(() => {
        ctx.patchState({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        });
      });
    }
  }

  @Action(AppActions.SetBrand)
  async setBrand(ctx: Context) {
    let environment;
    const { isDebit } = this.config;

    if (isDebit) {
      environment = debitEnvironment;
    } else {
      environment = ipidEnvironment;
    }

    // if (AppState._isLoadCustomScripts(environment.BRAND)) {
    //   this.scriptLoaderService
    //     // .loadScript(`https://services.wiseadvice.ru/tracker/js/v1/wa.min.js`)
    //     .loadScript(`/assets/js/wa.min.js`)
    //     .subscribe((data) =>
    //       console.log(
    //         `console.log('%c Custom scripts is Loaded! ', 'background: #222; color: #bada55');`,
    //       )
    //     );
    // }
    ctx.patchState({
      brand: environment.BRAND,
      footerText: environment.FOOTER_TEXT,
      logoPath: environment.LOGO_PATH,
    });
  }
}

type Context = StateContext<AppStateModel>;

export interface AppStateModel {
  windowWidth: number | null;
  windowHeight: number | null;
  isDebit: boolean | null;
  brand: string | null;
  logoPath: string | null;
  footerText: string | null;
}
