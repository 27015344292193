export default [
  {
    number: 1,
    code: '1',
    name: 'ОКВЭД 1',
    description: 'Растениеводство и животноводство, охота и предоставление соответствующих услуг в этих областях'
  },
  {
    number: 2,
    code: '2',
    name: 'ОКВЭД 2',
    description: 'Лесоводство и лесозаготовки'
  },
  {
    number: 3,
    code: '3',
    name: 'ОКВЭД 3',
    description: 'Рыболовство и рыбоводство'
  },
  {
    number: 5,
    code: '5',
    name: 'ОКВЭД 5',
    description: 'Добыча угля'
  },
  {
    number: 6,
    code: '6',
    name: 'ОКВЭД 6',
    description: 'Добыча сырой нефти и природного газа'
  },
  {
    number: 7,
    code: '7',
    name: 'ОКВЭД 7',
    description: 'Добыча металлических руд'
  },
  {
    number: 8,
    code: '8',
    name: 'ОКВЭД 8',
    description: 'Добыча прочих полезных ископаемых'
  },
  {
    number: 8,
    code: '9',
    name: 'ОКВЭД 9',
    description: 'Предоставление услуг в области добычи полезных ископаемых'
  },
  {
    number: 10,
    code: '10',
    name: 'ОКВЭД 10',
    description: 'Производство пищевых продуктов'
  },
  {
    number: 11,
    code: '11',
    name: 'ОКВЭД 11',
    description: 'Производство напитков'
  },
  {
    number: 12,
    code: '12',
    name: 'ОКВЭД 12',
    description: 'Производство табачных изделий'
  },
  {
    number: 13,
    code: '13',
    name: 'ОКВЭД 13',
    description: 'Производство текстильных изделий'
  },
  {
    number: 14,
    code: '14',
    name: 'ОКВЭД 14',
    description: 'Производство одежды'
  },
  {
    number: 15,
    code: '15',
    name: 'ОКВЭД 15',
    description: 'Производство кожи и изделий из кожи'
  },
  {
    number: 16,
    code: '16',
    name: 'ОКВЭД 16',
    description: 'Обработка древесины и производство изделий из дерева и пробки, кроме мебели, производство изделий из соломки и материалов для плетения'
  },
  {
    number: 17,
    code: '17',
    name: 'ОКВЭД 17',
    description: 'Производство бумаги и бумажных изделий'
  },
  {
    number: 18,
    code: '18',
    name: 'ОКВЭД 18',
    description: 'Деятельность полиграфическая и копирование носителей информации'
  },
  {
    number: 19,
    code: '19',
    name: 'ОКВЭД 19',
    description: 'Производство кокса и нефтепродуктов'
  },
  {
    number: 20,
    code: '20',
    name: 'ОКВЭД 20',
    description: 'Производство химических веществ и химических продуктов'
  },
  {
    number: 21,
    code: '21',
    name: 'ОКВЭД 21',
    description: 'Производство лекарственных средств и материалов, применяемых в медицинских целях'
  },
  {
    number: 22,
    code: '22',
    name: 'ОКВЭД 22',
    description: 'Производство резиновых и пластмассовых изделий'
  },
  {
    number: 23,
    code: '23',
    name: 'ОКВЭД 23',
    description: 'Производство прочей неметаллической минеральной продукции'
  },
  {
    number: 24,
    code: '24',
    name: 'ОКВЭД 24',
    description: 'Производство металлургическое'
  },
  {
    number: 25,
    code: '25',
    name: 'ОКВЭД 25',
    description: 'Производство готовых металлических изделий, кроме машин и оборудования'
  },
  {
    number: 26,
    code: '26',
    name: 'ОКВЭД 26',
    description: 'Производство компьютеров, электронных и оптических изделий'
  },
  {
    number: 27,
    code: '27',
    name: 'ОКВЭД 27',
    description: 'Производство электрического оборудования'
  },
  {
    number: 28,
    code: '28',
    name: 'ОКВЭД 28',
    description: 'Производство машин и оборудования, не включенных в другие группировки'
  },
  {
    number: 29,
    code: '29',
    name: 'ОКВЭД 29',
    description: 'Производство автотранспортных средств, прицепов и полуприцепов'
  },
  {
    number: 30,
    code: '30',
    name: 'ОКВЭД 30',
    description: 'Производство прочих транспортных средств и оборудования'
  },
  {
    number: 31,
    code: '31',
    name: 'ОКВЭД 31',
    description: 'Производство мебели'
  },
  {
    number: 32,
    code: '32',
    name: 'ОКВЭД 32',
    description: 'Производство прочих готовых изделий'
  },
  {
    number: 33,
    code: '33',
    name: 'ОКВЭД 33',
    description: 'Ремонт и монтаж машин и оборудования'
  },
  {
    number: 35,
    code: '35',
    name: 'ОКВЭД 35',
    description: 'Обеспечение электрической энергией, газом и паром; кондиционирование воздуха'
  },
  {
    number: 36,
    code: '36',
    name: 'ОКВЭД 36',
    description: 'Забор, очистка и распределение воды'
  },
  {
    number: 37,
    code: '37',
    name: 'ОКВЭД 37',
    description: 'Сбор и обработка сточных вод'
  },
  {
    number: 38,
    code: '38',
    name: 'ОКВЭД 38',
    description: 'Сбор, обработка и утилизация отходов; обработка вторичного сырья'
  },
  {
    number: 39,
    code: '39',
    name: 'ОКВЭД 39',
    description: 'Предоставление услуг в области ликвидации последствий загрязнений и прочих услуг, связанных с удалением отходов'
  },
  {
    number: 41,
    code: '41',
    name: 'ОКВЭД 41',
    description: 'Строительство зданий'
  },
  {
    number: 42,
    code: '42',
    name: 'ОКВЭД 42',
    description: 'Строительство инженерных сооружений'
  },
  {
    number: 43,
    code: '43',
    name: 'ОКВЭД 42',
    description: 'Работы строительные специализированные'
  },
  {
    number: 45,
    code: '45',
    name: 'ОКВЭД 45',
    description: 'Торговля оптовая и розничная автотранспортными средствами и мотоциклами и их ремонт'
  },
  {
    number: 46,
    code: '46',
    name: 'ОКВЭД 46',
    description: 'Торговля оптовая, кроме оптовой торговли автотранспортными средствами и мотоциклами'
  },
  {
    number: 47,
    code: '47',
    name: 'ОКВЭД 47',
    description: 'Торговля розничная, кроме торговли автотранспортными средствами и мотоциклами'
  },
  {
    number: 49,
    code: '49',
    name: 'ОКВЭД 49',
    description: 'Деятельность сухопутного и трубопроводного транспорта'
  },
  {
    number: 50,
    code: '50',
    name: 'ОКВЭД 50',
    description: 'Деятельность водного транспорта'
  },
  {
    number: 51,
    code: '51',
    name: 'ОКВЭД 51',
    description: 'Деятельность воздушного и космического транспорта'
  },
  {
    number: 52,
    code: '52',
    name: 'ОКВЭД 52',
    description: 'Складское хозяйство и вспомогательная транспортная деятельность'
  },
  {
    number: 53,
    code: '53',
    name: 'ОКВЭД 53',
    description: 'Деятельность почтовой связи и курьерская деятельность'
  },
  {
    number: 55,
    code: '55',
    name: 'ОКВЭД 55',
    description: 'Деятельность по предоставлению мест для временного проживания'
  },
  {
    number: 56,
    code: '56',
    name: 'ОКВЭД 56',
    description: 'Деятельность по предоставлению продуктов питания и напитков'
  },
  {
    number: 58,
    code: '58',
    name: 'ОКВЭД 58',
    description: 'Деятельность издательская'
  },
  {
    number: 59,
    code: '59',
    name: 'ОКВЭД 59',
    description: 'Производство кинофильмов, видеофильмов и телевизионных программ, издание звукозаписей и нот'
  },
  {
    number: 60,
    code: '60',
    name: 'ОКВЭД 60',
    description: 'Деятельность в области телевизионного и радиовещания'
  },
  {
    number: 61,
    code: '61',
    name: 'ОКВЭД 61',
    description: 'Деятельность в сфере телекоммуникаций'
  },
  {
    number: 62,
    code: '62',
    name: 'ОКВЭД 62',
    description: 'Разработка компьютерного программного обеспечения, консультационные услуги в данной области и другие сопутствующие услуги'
  },
  {
    number: 63,
    code: '63',
    name: 'ОКВЭД 63',
    description: 'Деятельность в области информационных технологий'
  },
  {
    number: 64,
    code: '64',
    name: 'ОКВЭД 64',
    description: 'Деятельность по предоставлению финансовых услуг, кроме услуг по страхованию и пенсионному обеспечению'
  },
  {
    number: 65,
    code: '65',
    name: 'ОКВЭД 65',
    description: 'Страхование, перестрахование, деятельность негосударственных пенсионных фондов, кроме обязательного социального обеспечения'
  },
  {
    number: 66,
    code: '66',
    name: 'ОКВЭД 66',
    description: 'Деятельность вспомогательная в сфере финансовых услуг и страхования'
  },
  {
    number: 68,
    code: '68',
    name: 'ОКВЭД 68',
    description: 'Операции с недвижимым имуществом'
  },
  {
    number: 69,
    code: '69',
    name: 'ОКВЭД 69',
    description: 'Деятельность в области права и бухгалтерского учета'
  },
  {
    number: 70,
    code: '70',
    name: 'ОКВЭД 70',
    description: 'Деятельность головных офисов; консультирование по вопросам управления'
  },
  {
    number: 71,
    code: '71',
    name: 'ОКВЭД 71',
    description: 'Деятельность в области архитектуры и инженерно-технического проектирования; технических испытаний, исследований и анализа'
  },
  {
    number: 72,
    code: '72',
    name: 'ОКВЭД 72',
    description: 'Научные исследования и разработки'
  },
  {
    number: 73,
    code: '73',
    name: 'ОКВЭД 73',
    description: 'Деятельность рекламная и исследование конъюнктуры рынка'
  },
  {
    number: 74,
    code: '74',
    name: 'ОКВЭД 74',
    description: 'Деятельность профессиональная научная и техническая прочая'
  },
  {
    number: 75,
    code: '75',
    name: 'ОКВЭД 75',
    description: 'Деятельность ветеринарная'
  },
  {
    number: 77,
    code: '77',
    name: 'ОКВЭД 77',
    description: 'Аренда и лизинг'
  },
  {
    number: 78,
    code: '78',
    name: 'ОКВЭД 78',
    description: 'Деятельность по трудоустройству и подбору персонала'
  },
  {
    number: 79,
    code: '79',
    name: 'ОКВЭД 79',
    description: 'Деятельность туристических агентств и прочих организаций, предоставляющих услуги в сфере туризма'
  },
  {
    number: 80,
    code: '80',
    name: 'ОКВЭД 80',
    description: 'Деятельность по обеспечению безопасности и проведению расследований'
  },
  {
    number: 81,
    code: '81',
    name: 'ОКВЭД 81',
    description: 'Деятельность по обслуживанию зданий и территорий'
  },
  {
    number: 82,
    code: '82',
    name: 'ОКВЭД 82',
    description: 'Деятельность административно-хозяйственная, вспомогательная деятельность по обеспечению функционирования организации, деятельность по предоставлению прочих вспомогательных услуг для бизнеса'
  },
  {
    number: 84,
    code: '84',
    name: 'ОКВЭД 84',
    description: 'Деятельность органов государственного управления по обеспечению военной безопасности, обязательному социальному обеспечению'
  },
  {
    number: 85,
    code: '85',
    name: 'ОКВЭД 85',
    description: 'Образование'
  },
  {
    number: 86,
    code: '86',
    name: 'ОКВЭД 86',
    description: 'Деятельность в области здравоохранения'
  },
  {
    number: 87,
    code: '87',
    name: 'ОКВЭД 87',
    description: 'Деятельность по уходу с обеспечением проживания'
  },
  {
    number: 88,
    code: '88',
    name: 'ОКВЭД 88',
    description: 'Предоставление социальных услуг без обеспечения проживания'
  },
  {
    number: 90,
    code: '90',
    name: 'ОКВЭД 90',
    description: 'Деятельность творческая, деятельность в области искусства и организации развлечений'
  },
  {
    number: 91,
    code: '91',
    name: 'ОКВЭД 91',
    description: 'Деятельность библиотек, архивов, музеев и прочих объектов культуры'
  },
  {
    number: 92,
    code: '92',
    name: 'ОКВЭД 92',
    description: 'Деятельность по организации и проведению азартных игр и заключению пари, по организации и проведению лотерей'
  },
  {
    number: 93,
    code: '93',
    name: 'ОКВЭД 93',
    description: 'Деятельность в области спорта, отдыха и развлечений'
  },
  {
    number: 94,
    code: '94',
    name: 'ОКВЭД 94',
    description: 'Деятельность общественных организаций'
  },
  {
    number: 95,
    code: '95',
    name: 'ОКВЭД 95',
    description: 'Ремонт компьютеров, предметов личного потребления и хозяйственно-бытового назначения'
  },
  {
    number: 96,
    code: '96',
    name: 'ОКВЭД 96',
    description: 'Деятельность по предоставлению прочих персональных услуг'
  },
  {
    number: 97,
    code: '97',
    name: 'ОКВЭД 97',
    description: 'Деятельность домашних хозяйств с наемными работниками'
  },
  {
    number: 98,
    code: '98',
    name: 'ОКВЭД 98',
    description: 'Деятельность недифференцированная частных домашних хозяйств по производству товаров и предоставлению услуг для собственного потребления'
  },
  {
    number: 99,
    code: '99',
    name: 'ОКВЭД 99',
    description: 'Деятельность экстерриториальных организаций и органов'
  }
];
