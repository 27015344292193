<div class="mktu-list">
  <div class="mktu-list__item prompt prompt__active"
       [style.position]="countriesDropdown ? 'initial' : ''"
       [class.mktu-list__item--critical]="isMatching(i.classNumber)"
       *ngFor="let i of showedItems">
    {{i.classNumber}}
    <span class="prompt__hidden">
     <p>{{getDescription(i.classNumber)}}</p>
    </span>
  </div>
  <div class="mktu-list__item mktu-list__item--red" *ngIf="hasMoreThanLimit && !showAll" (click)="onShowAll()">
    + {{codes.length - limit}}</div>
</div>
