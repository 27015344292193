import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-court-page',
  templateUrl: './court-page.component.html',
  styleUrls: ['./court-page.component.scss']
})
export class CourtPageComponent implements OnInit {
  docs = false;
  events = true;
  claimsInfo = false;

  title = 'СИП-51/2021 Суд по интеллектуальным правам';
  addDoc = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  trimString(str, count) {
    return str?.length > count ? str.slice(0, count) + '...' : str;
  }

  setResizableWidth(text, titleDiv) {
    titleDiv.focus();
    titleDiv.style.width = text.offsetWidth + 'px';
  }

  getChangedTitle(titleDiv) {
    this.title = titleDiv.innerHTML;
  }

  closeModal() {
    this.addDoc = false;
  }

  openModal() {
    this.addDoc = true;
  }

}
