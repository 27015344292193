<div class="container inset inset--docs">
  <div *ngIf="type==='claim'" class="docs">
    <div class="docs__main-wrapper arr">
      <h4>Документы по претензии</h4>
      <div class="docs__blocks">
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/gray-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/gray-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/gray-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/gray-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/gray-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="type==='court' || type==='dispute'" class="docs">
    <div class="docs__main-wrapper">
      <h4>Документы по претензии</h4>
      <div class="docs__blocks">
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/pink-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/pink-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/pink-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/pink-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
              <img src="/assets/icons/success.png" class="docs__icon--mini">
              <img src="/assets/icons/pink-empty.svg">
              <span class="prompt__hidden">
                <p>Согласован</p>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="docs__main-wrapper">
      <h4>Доказательства для {{type === 'court' ? 'судебного дела' : ' спора'}}</h4>
      <div class="docs__blocks">
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/yellow-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/yellow-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="docs__main-wrapper">
      <h4>Процессуальные документы {{type === 'court' ? 'истца' : 'заявителя'}}</h4>
      <div class="docs__blocks">
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
              <img src="/assets/icons/warning.png" class="docs__icon--mini">
              <img src="/assets/icons/green-empty.svg">
              <span class="prompt__hidden">
                <p>Требуется согласование</p>
                <a>Перейти к согласованию</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/green-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/green-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Договор</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
              <img src="/assets/icons/success.png" class="docs__icon--mini">
              <img src="/assets/icons/green-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Согласован</p>
              </span>
            </span>
          </div>
        </div>

      </div>
    </div>
    <div class="docs__main-wrapper">
      <h4>Процессуальные документы ответчика</h4>
      <div class="docs__blocks">
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Отзыв на иск</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/purple-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Апелляционная жалоба</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/purple-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен пользователем</p>
                <a>Константин К.</a>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="docs__main-wrapper">
      <h4>{{type === 'court' ? 'Судебные акты' : 'Административные акты'}}</h4>
      <div class="docs__blocks">
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Решение первой инстанции</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/pink-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Определение о назначении экспертизы</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/pink-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Определение о принятии иска</span>
            <a href="" download>nazvanie_dogovora.pdf</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/pink-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="docs__main-wrapper">
      <h4>Копия материалов дела</h4>
      <div class="docs__blocks">
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Архив материалов дела</span>
            <a href="" download>СИП-51/2021_от_10-02-2020.zip</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/blue-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Аудиозапись судебного заседания</span>
            <a href="" download>СИП-51/2021_от_10-02-2020.zip</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/blue-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
        <div class="docs__block">
          <div class="docs__text-wrapper">
            <span>Аудиозапись судебного заседания</span>
            <a href="" download>СИП-51/2021_от_17-03-2020.wav</a>
          </div>
          <div class="docs__icon-wrapper">
            <span class="docs__icon">
<!--              <img src="/assets/icons/green-key.png" class ="docs__icon&#45;&#45;mini">-->
              <img src="/assets/icons/blue-gardium-empty.svg">
              <span class="prompt__hidden">
                <p>Загружен Гардиум</p>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="docs__btn-wrapper">
      <a href="" download>Скачать все документы</a>
    </div>
  </div>
</div>
