import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../../../shared/services/api.service';
import { AsyncDataLoader } from '../../../../shared/helpers/async-data-loader/async-data-loader';
import { ISearchHistorySearchListResult } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { IApiCountersResponse } from 'projects/app-ipid/src/app/shared/models/api/api.model';

@Injectable()
export class CorpIndexService {
  id;

  constructor(private _dataService: DataService) {
  }

  public loadSearchHistory = (): Observable<ISearchHistorySearchListResult> => {
    return this._dataService.getSearchHistory({topCount: 2});
  };

  public loadPortfolio = (): Observable<IApiCountersResponse> => {
    let params = {};
    if (this.id) {
      params = {organization: this.id};
    }
    return this._dataService.getCounters(params);
  };

  readonly searchHistory = new AsyncDataLoader(this.loadSearchHistory);
  readonly counters = new AsyncDataLoader(this.loadPortfolio);

  public load(id?): void {
    this.id = id;
    this.searchHistory.load();
    this.counters.load();
  }
}
