import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskIntegrationComponent } from './pages/risk-integration/risk-integration.component';
import { IntegrationRoutingModule } from './integration-routing.module';
import { RiskIntegrationAlfaComponent } from './pages/risk-integration-alfa/risk-integration-alfa.component';



// Интеграция с банками по мониторингу рисков
@NgModule({
  declarations: [RiskIntegrationComponent, RiskIntegrationAlfaComponent],
  imports: [
    CommonModule,
    IntegrationRoutingModule,
  ]
})
export class IntegrationModule { }
