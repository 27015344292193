<section class="ui-input" (click)="onClickHandler()">
  <input
    [type]="type"
    [value]="textValue"
    [required]="required"
    [disabled]="disabled"
    class="ui-input__field"
    [placeholder]="placeholder"
    (input)="update($event.target)"
  />
  <span class="ui-input__label">{{placeholder}}</span>

  <div class="loading" *ngIf="loading">
    <div class="loading__inner"></div>
  </div>
</section>
