import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {SharedService} from '../../../../../app-ipid/src/app/shared/services/shared.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@web/core/states/auth.state';
import {nullableFilter} from '@web/utils/nullable-filter.util';
import { HistoryActions } from '@web/features/corp/corp-history/states/history.actions';
import { CORP_HISTORY_TOKEN, HistoryState } from '@web/features/corp/corp-history/states/history.state';
import { FiltersValueInterface } from '@web/features/corp/corp-history/models/filters-value.interface';
import { AppState } from '@web/core/states/app.state';
import {SearchesInterface} from '@web/features/corp/corp-history/models/searches.interface';


@Component({
  selector: 'app-corp-history-container',
  templateUrl: './corp-history-container.component.html',
  styleUrls: ['./corp-history-container.component.scss'],
  providers: []
})
export class CorpHistoryContainerComponent implements OnInit {
  state$ = this.store.select(CORP_HISTORY_TOKEN);
  layout$ = this.store.select(AppState.getLayout);
  rightsSearch$ = this.store.select(AuthState.getSearchRights);


  hidePageMobile = false;
  showMobileControl = false;
  rights = {
    name: false,
    image: false,
    owner: false,
    number: false
  };
  currentPage: string;
  private readonly onDestroy = new Subject<void>();

  constructor(
    readonly changeDetectorRef: ChangeDetectorRef,
    private sharedAPI: SharedService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.showMobileControl = window.innerWidth > 1050;

    window.addEventListener('resize', () => {
      this.showMobileControl = window.innerWidth > 1050;
    });

    this.store.select(HistoryState.historySelectorByTab()).pipe(
      takeUntil(this.onDestroy)
    ).subscribe(next => {
      this.currentPage = next;
    });

    // TODO: после создания матрицы прав подумать над этим
    // this.getRights();

    this.rightsSearch$.pipe(
      nullableFilter(),
      takeUntil(this.onDestroy)
    ).subscribe(rightsSearch => {
      if (rightsSearch?.length) {
        if (rightsSearch.every(item => item.value === true || item.value === 'true')) {
          this.rights = {
            name: true,
            image: true,
            owner: true,
            number: true
          };
        } else if (rightsSearch.some(item => item.value === 'true' || item.value === true)) {
          let rightName = '';
          rightsSearch.forEach(item => {
            if (item.value === true || item.value === 'true') {
              rightName = item.name.toLowerCase();
            }
          });
          switch (rightName) {
            case 'по изображению':
              this.rights = {
                name: false,
                image: true,
                number: true,
                owner: true
              };
              break;
            case 'по обозначению':
              this.rights = {
                name: true,
                image: false,
                number: true,
                owner: true
              };
              break;
          }
        }
      }
      this.store.dispatch(new HistoryActions.Init(this.rights, true));
      this.store.dispatch(new HistoryActions.Init(this.rights));
    });
  }

  changeTab(tab: 'searches' | 'reports') {
    this.store.dispatch(new HistoryActions.ChangeTab(tab));
    this.changeDetectorRef.detectChanges();
  }

  // TODO: после создания матрицы прав подумать над этим
  // getRights() {
  //   this.contragentID = JSON.parse(localStorage.getItem('organizations'));
  //   if (this.contragentID) {
  //     const params = {
  //       contragentID: this.contragentID[0].id
  //     };
  //     this.sharedAPI.getRightsAsync(params).pipe(takeUntil(this.onDestroy)).subscribe(answer => {
  //       answer.rights.find(el => {
  //         if (el.title === 'Права на поиски') {
  //           this.rightsSearch = el.data;
  //         }
  //       });
  //       if (this.rightsSearch?.length) {
  //         if (this.rightsSearch.every(el => el.value === true || el.value === 'true')) {
  //           this.rights = {
  //             owner: true,
  //             name: true,
  //             number: true,
  //             image: true,
  //           };
  //         } else if (this.rightsSearch.some(el => el.value === 'true' || el.value === true)) {
  //           let rightName = '';
  //           this.rightsSearch.forEach(el => {
  //             if (el.value === true || el.value === 'true') {
  //               rightName = el.name.toLowerCase();
  //             }
  //           });
  //           switch (rightName) {
  //             case 'по изображению':
  //               this.rights = {
  //                 owner: true,
  //                 name: false,
  //                 number: true,
  //                 image: true,
  //               };
  //               break;
  //             case 'по обозначению':
  //               this.rights = {
  //                 owner: true,
  //                 name: true,
  //                 number: true,
  //                 image: false,
  //               };
  //               break;
  //           }
  //         }
  //       }
  //       this.service.load(this.rights);
  //     });
  //   }
  // }

  makeBodyHidden() {
    if (window.innerWidth <= 793) {
      if (this.showMobileControl === true) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    }
    document.body.style.overflow = 'visible';
  }

  onDownloadReport(search: SearchesInterface) {
    this.store.dispatch(new HistoryActions.DownloadReport(search));
  }

  onOpenReport(searchId: string) {
    this.store.dispatch(new HistoryActions.OpenReport(searchId));
  }

  hideMobileWindow(event) {
    this.hidePageMobile = event;
  }

  changePage($event) {
    this.store.selectSnapshot(CORP_HISTORY_TOKEN).currentTab === 'searches'
      ? this.store.dispatch(new HistoryActions.ChangePage($event))
      : this.store.dispatch(new HistoryActions.ChangePage($event, true));
  }

  applyFilters(filter: FiltersValueInterface) {
    this.store.selectSnapshot(CORP_HISTORY_TOKEN).currentTab === 'searches'
      ? this.store.dispatch(new HistoryActions.ChangeFilters(filter))
      : this.store.dispatch(new HistoryActions.ChangeFilters(filter, true));
  }
}
