import { Injectable } from '@angular/core';
import { HttpService } from '../../shared/services/http.service';
import sha1 from 'crypto-js/sha1';
import Base64 from 'crypto-js/enc-base64';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IMonitoringAddObjectsDetail,
  IMonitoringAddObjectsResponse,
  IMonitoringAddObjectsStage1, IMonitoringAddObjectsStage2, IMonitoringAddObjectsStage3, IMonitoringObject
} from './shared/monitoring.model';
import { map } from 'rxjs/operators';
import { CasesService } from '../cases/cases.service';

interface IObjectsLoad {
  id: string;
  isRequest?: boolean;
  index?: string;
  monitoringDate?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  private user = 'aa';
  private password = 'Aa123456';

  private bodyForElasticRequest = {
    SHA1hash: this.getHash()
  };

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private casesService: CasesService,
  ) {
  }

  getHash() {
    return Base64.stringify(sha1(this.user.toLowerCase() + this.password));
  }

  getToken() {
    return localStorage.getItem('currentUserIPID');
  }

  setObjectMonitoring(body: IObjectsLoad[], organization?: string) {
    const query = {
      objectsMonitoring: body,
      organization
    };
    return this.httpService.post({path: 'objects/monitoring/load', body: query});
  }

  removeObjectMonitoring(body: IObjectsLoad[]) {
    const query = {
      objectsMonitoring: body,
    };
    return this.httpService.post({path: 'objects/monitoring/delete', body: query});
  }

  getObjectsWithRisksById(id: string) {
    return this.httpService.get({path: 'objects/monitoring/risks', params: {objectID: id}});
  }

  getObjectsWithRisks(id?, count?) {
    let params = {};
    if (id) {
      params = {
        organization: id,
      };
    }

    if (count) {
      params = {
        limit: count,
        showEmptyObjects: 1
      };
    }

    if (count && id) {
      params = {
        organization: id,
        limit: count,
        showEmptyObjects: 1
      };
    }

    return this.httpService.get({path: 'objects/monitoring/risks', params});
  }

  getObjectsWithoutRisksById(id: string) {
    return this.httpService.get({path: 'objects/monitoring', params: {objectID: id}});
  }

  getObjectsWithoutRisks() {
    return this.httpService.get({path: 'objects/monitoring'});
  }

  addObjectsToMonitoringGetDetail(params, responseID: string): Observable<IMonitoringAddObjectsDetail> {
    return this.casesService.getCase(params, responseID)
      .pipe(
        map((data: any) => {
          const task = data?.case?.tasks?.items[0];
          console.log('task', task);

          const objects: IMonitoringObject[] = [];
          const excludedObjects: IMonitoringObject[] = [];

          for (const item of task.details.objects) {
            const obj: IMonitoringObject = {
              ID: item.ID,
              name: item.name ?? '',
              type: item.type ?? '',
              index: item.index
            };

            if (item.include) {
              objects.push(obj);
            } else {
              excludedObjects.push(obj);
            }
          }

          return {
            responseID,
            objects,
            excludedObjects,
            stage: task.lastStage,
            tariffID: task.tariff,
            organizationId: task.details.organization
          };
        })
      );
  }

  // начало добавление мониторинга
  addObjectsToMonitoringStage1(data: IMonitoringAddObjectsStage1): Observable<IMonitoringAddObjectsResponse> {
    return this.httpService.post({ path: 'objects/monitoring/request/load', body: { ...data, stage: 1 } });
  }

  addObjectsToMonitoringStage2(data: IMonitoringAddObjectsStage2): Observable<IMonitoringAddObjectsResponse> {
    return this.httpService.post({ path: 'objects/monitoring/request/load', body: { ...data, stage: 2 }  });
  }

  addObjectsToMonitoringStage3(data: IMonitoringAddObjectsStage3): Observable<IMonitoringAddObjectsResponse> {
    return this.httpService.post({ path: 'objects/monitoring/request/load', body: { ...data, stage: 3 }  });
  }

  setHiddenRisk(body) {
    return this.httpService.post({path: 'objects/risks/hide', body});
  }

  getRisksByObject(body: any) {
    // body.PSRN = '1127711000031';
    return this.httpService.post({path: '_qualifyrisksbyid', isRequestToElastic: true, body: {...body, ...this.bodyForElasticRequest}});
  }
}
