import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '../../shared/services/url.service';
import { UserProfileService } from '../../../../../shared/services/user-profile.service';
import {Subject} from 'rxjs';
import { InterestService } from '../../../../../shared/services/interest.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { AuthState } from '@web/core/states/auth.state';
import { Store } from '@ngxs/store';

declare var window: any;

@Component({
  selector: 'app-treaty-extension',
  templateUrl: './treaty-extension.component.html',
  styleUrls: ['./treaty-extension.component.scss']
})
export class TreatyExtensionComponent implements OnInit {
  rows = 5;
  firstStep = true;
  secondStep = false;
  showPersonalData = false;
  isLoading = false;

  personalData = false;
  comment = '';
  formIdentity = '';
  leadFormData;
  requestError = false;
  userData;
  isService = false;
  serviceID;

  private readonly onDestroy = new Subject<void>();
  // previousUrl: Observable<string> = this.urlService.previousUrl$;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private urlService: UrlService,
    private userProfileService: UserProfileService,
    private profileApi: UserProfileService,
    private interestService: InterestService,
    private readonly intercomEventsService: IntercomEventsService,
    private store: Store
  ) {
  }

  // TODO: Вынести все formIdentity из шаблонов в ENUM

  ngOnInit(): void {
    this.init();

    if (window.innerWidth < 500) {
      this.rows = 10;
    } else {
      this.rows = 5;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 500) {
        this.rows = 10;
      } else {
        this.rows = 5;
      }
    });
    const bodyStyles = document.body.style;
    bodyStyles.setProperty('overflow', 'visible');
  }

  init() {
    this.isLoading = !!this.formIdentity.length;
    this.userProfileService.getOrganizations().subscribe(organizationsData => {
      this.leadFormData = {
        interestData: this.interestService.addInterestData(),
        fromUrl: ''
      };
      const organizations = organizationsData.organizations;
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      this.urlService.previousUrl$.subscribe((previousUrl: string) => {
        this.leadFormData.fromUrl = previousUrl ? `${baseUrl}${previousUrl.split('?')[0]}` : baseUrl;
      });

      this.route.queryParams.subscribe(param => {
        const data = JSON.parse(param.data);
        this.formIdentity = data.formIdentity;

        // Собираем основную инфу
        this.leadFormData = {
          ...this.leadFormData,
          formIdentity: data.formIdentity,
          contragentID: data.contragentID || organizations[0]?.id, // id компании если мы пришли из user/profile или выбранная компания
          comment: this.comment
        };

        // Портфель - договоры
        if (data.contractID) {
          this.leadFormData = {
            ...this.leadFormData,
            contractID: data.contractID,
            contragentID: data.selectedCompanyId
          };
        }

        // Мониторинг - риски
        if (data.objectId && data.objectIndex && data.riskId) {
          this.leadFormData = {
            ...this.leadFormData,
            objectId: data.objectId,
            objectIndex: data.objectIndex,
            riskId: data.riskId,
          };
        }

        if (data.responseID) {
          this.leadFormData = {
            ...this.leadFormData,
            responseID: data.responseID,
            formIdentity: data.formIdentity,
            contragentID: data.contragentID || organizations[0]?.id, // id компании если мы пришли из user/profile или выбранная компания
            comment: this.comment
          };
        }

        // Услуги
        if (data.serviceId) {
          this.isService = true;
          this.serviceID = data.serviceId;
          this.leadFormData.fromUrl = baseUrl + '/services/service-item?id=' + data.serviceId;
        }
      });
    });

    // this.leadFormData.previousUrl = this.routerExtService.getPreviousUrl();

    // if (localStorage.getItem('currentUserData') !== 'undefined') {
    //   const user = JSON.parse(localStorage.getItem('currentUserData'));
    //   this.leadFormData.user = `${user.name} ${user.surname}`;
    // }
    if (this.store.selectSnapshot(AuthState.getUser)) {
      this.userData = this.store.selectSnapshot(AuthState.getUser);
      this.personalData = this.userData.phone;
    }  }

  sendRequest() {
    this.requestError = false;
    this.leadFormData.comment = this.comment;

    this.isLoading = true;
    console.log(this.leadFormData);

    this.userProfileService.sendLeadForm(this.leadFormData).subscribe((data: any) => {
      this.isLoading = false;
      if (data?.error) {
        console.error(data.error);
        this.requestError = true;
        return;
      }

      if (data?.answer) {
        this.firstStep = false;
        this.secondStep = true;
      }
    });
    if (this.isService) {
      this.intercomEventsService.push({
        event: INTERCOM_EVENT_NAME.FORM_SERVICE_SENT,
        button_name: this.formIdentity,
        service_name: JSON.parse(this.route.snapshot.queryParams.data).serviceName,
        price: JSON.parse(this.route.snapshot.queryParams.data).price,
        tariff: JSON.parse(this.route.snapshot.queryParams.data).tariff,
        service_id: this.serviceID,
      });
    }
  }

  toBackLocation() {
    if (this.leadFormData.responseID) {
      this.router.navigate(['/request-contract']);
    } else {
      this.location.back();
    }
  }

  goNext() {
    !this.personalData ? this.personalDataForm() : this.sendRequest();
  }

  personalDataForm() {
    this.showPersonalData = true;
  }

  getPersonalDataStatus(evt: boolean): void {
    this.personalData = evt;
    if (this.personalData === true) {
      this.showPersonalData = false;
    }
  }

}
