import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab-has-docs',
  templateUrl: './tab-has-docs.component.html',
  styleUrls: ['./tab-has-docs.component.scss']
})
export class TabHasDocsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
