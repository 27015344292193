<app-add-doc *ngIf="addDoc" (modalEvent)="closeModal()"></app-add-doc>

<section class="section-position section-position__color-bg section-position__color-bg--claim">
  <div class="container header-court">
    <div class="header-court__block header-court__block--head">
      <div>
        <p class="header-court__link-back" routerLink="/court">Претензия № 555435 от 15.01.2021, обоснованность
          высокая</p>
        <button (click)="openModal()">Прикрепить документы к делу</button>
      </div>
      <div>
        <div class="resizable"
             #titleDiv
             contenteditable
             (input)="getChangedTitle(titleDiv)">{{title}}
        </div>
        <span class="edit" (click)="setResizableWidth(title,titleDiv)"></span>
      </div>
      <p>Объект:<span>Патент № 4345346, 01.01.2010</span></p>
    </div>
    <div class="header-court__block header-court__block--row">
      <div class="header-court__card  header-court__card--claim">
        <p class="header-court__card-bottom">Статус</p>
        <h2 class="header-court__card-header-main header-court__card-header-main--claim">Отправлен ответ</h2>
        <p class="header-court__card-bottom-txt">Альтернативное предложение</p>
      </div>
      <div class="header-court__card ">
        <p class="header-court__card-bottom ">Отправитель претензии</p>
        <h2 class="header-court__card-header-main header-court__card-header-main--claim">
          {{trimString('Дизайнстройремонт, ООО', 38)}}
          <span class="prompt__hidden">
            <p>Дизайнстройремонт, ООО</p>
          </span>
        </h2>
        <small>{{trimString('ОГРН 234246457', 32)}}</small>
      </div>
      <div class="header-court__card ">
        <p class="header-court__card-bottom">Получатель претензии</p>
        <h2 class="header-court__card-header-main header-court__card-header-main--claim">
          {{trimString('АПС Строительно- Монтажное управление Монтажное управление', 38)}}
          <span class="prompt__hidden">
            <p>АПС Строительно- Монтажное управление Монтажное управление</p>
          </span>
        </h2>
        <small>{{trimString('ОГРН 234246457', 32)}}</small>
      </div>
      <div class="header-court__card ">
        <div class="header-court__card-bottom">Требования
          <span class="prompt prompt__question">?
              <span class="prompt__hidden">
                {{trimString('Размер взысканий, которые требует оппонент', 32)}}
              </span>
            </span>
        </div>
        <h2 class="header-court__card-header-main header-court__card-header-main--claim">1 222 554,32 ₽</h2>
        <small>{{trimString('Размер взысканий, которые требует оппонент', 32)}}</small>
      </div>

    </div>
  </div>
</section>

<section
  class="section-position section-position__color-bg section-position__color-bg--claim section-position--padding">
  <div class="container control">
    <button class="control__btn" (click)="events=true; docs=false; claimsInfo=false"
            [class.control__btn--active]="events">События<span class="control__count">8</span>
    </button>
    <button class="control__btn" (click)="docs=true; events=false; claimsInfo=false"
            [class.control__btn--active]="docs"
    >Документы<span class="control__count">8</span>
    </button>
    <button class="control__btn" (click)="docs=false; events=false; claimsInfo=true"
            [class.control__btn--active]="claimsInfo"
    >Информация о претензии<span class="control__count">8</span>
    </button>
  </div>
</section>

<section class="section-position section-position--white">

  <app-accordion-tabs *ngIf="events"></app-accordion-tabs>

  <app-docs-tab *ngIf="docs" [type]="'claim'"></app-docs-tab>

  <app-info-tab *ngIf="claimsInfo" [type]="'claim'" [typeOfClaim]="'in'"></app-info-tab>

</section>
<app-expert></app-expert>

