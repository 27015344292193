import { Component, OnInit, OnDestroy } from '@angular/core';
import { MonitoringAddService } from '../shared/monitoring-add.service';
import { ActivatedRoute } from '@angular/router';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import {ViewSelectSnapshot} from '@ngxs-labs/select-snapshot';
import {AuthState} from '@web/core/states/auth.state';
import {AppState} from '@web/core/states/app.state';

@Component({
  selector: 'app-add-object',
  templateUrl: './add-object.component.html',
  styleUrls: ['./add-object.component.scss']
})
export class AddObjectComponent implements OnInit, OnDestroy {
  @ViewSelectSnapshot(AppState.X5) X5: boolean;
  @ViewSelectSnapshot(AuthState.getRightByName('monitoring-viewing')) monitoring: boolean;

  public userEmail: string = JSON.parse(localStorage.getItem('currentUserData')).userInfo
    ? JSON.parse(localStorage.getItem('currentUserData')).userInfo.email
    : JSON.parse(localStorage.getItem('currentUserData')).email;

  logo2path = '';
  constructor(
    readonly monitoringAddService: MonitoringAddService,
    readonly activatedRoute: ActivatedRoute,
    readonly userProfileService: UserProfileService,
    private readonly intercomEventsService: IntercomEventsService,
  ) {
  }

  ngOnInit(): void {
    this.monitoringAddService.loadRequestIfNeeded(this.activatedRoute.snapshot.paramMap.get('id'));
  }

  ngOnDestroy() {
    this.monitoringAddService.reset();
  }

  get stage() {
    return this.monitoringAddService.data.stage;
  }

  get paymentType() {
    return this.monitoringAddService.paymentType;
  }

  get logo() {
    return this.monitoringAddService.logo;
  }

  public goToApplication(): void {
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.GOTO_ORDER });
  }
}
