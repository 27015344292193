import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  IRequestRecipient,
  IRequestContractObject,
  IRequestContractResponseRisk,
  TypeContract,
  IRequestContractSubject,
  ActionContract,
  IRequestContractStage6
} from './contract.model';
import {finalize} from 'rxjs/operators';
import { ContractService } from '../contract.service';
import {HttpService} from '../../../shared/services/http.service';
import {ISearchObjects} from '../../../shared/models/main.interfaces';
import { InterestService } from '../../../../../../shared/services/interest.service';
import {INTERCOM_EVENT_NAME, IntercomEventsService} from '../../../../../../shared/services/intercom-events.service';

@Injectable()
export class RequestContractService {
  isLoading = false;
  rates = {min: null, opty: null, max: null}; // тарифы
  countRates; // количество тарифов
  activeTariff: string; // id выбранного тарифа
  duty: any; // пошлины
  sumBeforeDiscount = 0; // общая сумма (тариф + пошлины) до скидки
  paymentSum; // сумма к оплате с учетом скидки
  files = []; // прикрепленные файлы
  paymentType: 'online' | 'cash' | 'act'; // тип оплаты
  stage = 0; // номер шага
  responseID: string; // id заявки
  owners: IRequestRecipient[] = []; // стороны предоставляющие права
  recipients: IRequestRecipient[] = []; // получатели права
  objects: IRequestContractObject[] = []; // объекты договора
  riskData = {
    risk: false,
    usrle: {},
    rospatent: {},
    result: null
  }; // риски объектов
  contractType: TypeContract; // вид договора
  contractAction: ActionContract; // действие с договором
  contractNumber: string; // номер договора из Вкладки "Указать вручную"
  contractDate: string; // дата договора из Вкладки "Указать вручную"
  validation = false; // валидация форм
  subject: IRequestContractSubject; // предмет договора
  payer; // плательщик
  contractId; // выбранный договор
  draft; // черновик
  contract; // контракт
  changeContract = false;
  searchResults; // результаты поиска по реестру объектов
  queryForSearch; // параметры для поиска
  showRiskToggle = true;
  searchesContracts = [];

  constructor(
    private httpService: HttpService,
    private readonly router: Router,
    private contractService: ContractService,
    private activatedRoute: ActivatedRoute,
    private interestService: InterestService,
    private readonly intercomEventsService: IntercomEventsService
  ) {
  }

  /**
   * Запрос на восстановление заявки по id
   * @param responseID: id заявки
   */
  loadRequestIfNeeded(responseID: string): void {
    if (!this.responseID) {
      this.loadRequest(responseID);
    }
  }
  /**
   * Запрос на восстановление заявки по id
   * @param responseID: id заявки
   */
  loadRequest(responseID: string) {
    const params = {
      isDraft: true
    };
    this.isLoading = true;
    this.contractService.requestContractGetDetail(params, responseID)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(data => {
        this.draft = data.task;
        this.responseID = data.responseID;
        if (this.draft) {
          this.parseDraft();
        }
      });
  }

  parseDraft() {
    this.contractAction = this.draft.details.contractAction;
    if (this.contractAction === 'change' || this.contractAction === 'termination') {
      this.contractType = null;
    } else {
      this.contractType = this.draft.details.contractType;
    }
    this.contractDate = this.draft.details.contractDate;
    this.contractNumber = this.draft.details.contractNumber;
    this.draft.details.owners.forEach(el => this.owners.push(el));
    this.draft.details.recipients.forEach(el => this.recipients.push(el));
    this.draft.details.objects.forEach(el => this.objects.push(el));
    if (Object.keys(this.draft.details.subject).length > 0) {
      this.subject = this.draft.details.subject;
      this.draft.details.subject.files?.forEach(item => {
        this.files.push({
          fileName: item.substring(item.lastIndexOf('/') + 1),
          file: undefined,
          path: item,
          size: 0
        });
      });
    }
    this.showRiskToggle = this.draft.risk;
    switch (this.draft.lastStage) {
      case 1:
        if (this.draft.details.contractAction === 'change' || this.draft.details.contractAction === 'termination') {
          this.stage = this.draft.lastStage;
          this.changeContract = true;
        } else if (this.draft.details.contractType === 'unknown' || this.draft.details.contractType === 'pledge' || this.draft.details.contractType === 'repledge') {
          this.stage = this.draft.lastStage;
        } else {
          this.stage = this.draft.lastStage + 1;
        }
        break;
      case 2:
      case 3:
      case 4:
        this.stage = this.draft.lastStage + 1;
        break;
      case 5:
        this.getRates();
        this.stage = this.draft.lastStage;
        break;
      case 6:
        this.getRates();
        this.stage = this.draft.lastStage;
        break;
      case 7:
        this.stage = this.draft.lastStage;
    }
  }

  /**
   * Отправка Вида договора
   */
  sendTypeContract() {
    const params = {
      responseID: this.responseID,
      contractType: this.contractType,
      contractAction: this.contractAction,
      contractNumber: this.contractNumber,
      contractDate: this.contractDate,
      interestData: this.interestService.addInterestData()
    };
    this.isLoading = true;
    if (this.contractType !== null && this.contractAction !== 'termination' && this.contractType !== 'pledge' && this.contractType !== 'repledge' && this.contractType !== 'unknown'
      || (this.contractNumber?.length && this.contractDate?.length)) {
      this.stage = 2;
      this.changeContract = false;
    } else if ((this.contractType === 'pledge' || this.contractType === 'repledge') && this.contractAction === 'change') {
      this.changeContract = false;
      this.toLeadForm();
    }
    return this.contractService.requestContractStage1(params)
      .subscribe(data => {
        this.isLoading = false;
        this.responseID = data.responseID;
        if (!this.activatedRoute.snapshot.queryParams.id) {
          this.router.navigate(['/request-contract'], {queryParams: {id: this.responseID}});
        }
        if (this.contractType !== null && this.contractAction === 'termination') {
          this.getRates();
        }
      });
  }

  /**
   * Отправка Сторон договора
   */
  sendPartiesContract() {
    const params = {
      responseID: this.responseID,
      owners: this.owners,
      recipients: this.recipients,
    };
    this.isLoading = true;
    this.stage = 3;
    return this.contractService.requestContractStage2(params)
      .subscribe(data => {
        this.isLoading = false;
        this.responseID = data.responseID;
        this.owners = params.owners;
        this.recipients = params.recipients;
      });
  }

  /**
   * Отправка Объектов договора
   */
  sendObjectsContract() {
    const params = {
      responseID: this.responseID,
      Objects: this.objects
    };
    this.isLoading = true;
    return this.contractService.requestContractStage3(params)
      .subscribe((data: IRequestContractResponseRisk) => {
        this.isLoading = false;
        if (data.risk) {
          this.riskData.risk = data.risk;
          this.riskData.usrle = data.usrle;
          this.riskData.rospatent = data.rospatent;
        } else {
          this.showRiskToggle = false;
          this.stage = 4;
        }
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_CONTRACT_TOPIC_APPLY_SENT });
      });
  }

  /**
   * Отправка Предмета договора
   */
  sendSubjectContract() {
    const params = {
      responseID: this.responseID,
      contractType: this.contractType,
      subject: this.subject
    };
    this.isLoading = true;
    this.stage = 5;
    return this.contractService.requestContractStage4(params)
      .pipe(finalize(() => {
        this.isLoading = false;
      }))
      .subscribe(data => {
        this.responseID = data.responseID;
        this.contractType = params.contractType;
        this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.FORM_CONTRACT_TOPIC_APPLY_SENT });
        this.getRates();
      });
  }

  /**
   * Получение тарифов и пошлин
   */
  getRates(promoCode?: string): void {
    const param = {
      responseID: this.responseID,
      promocode: promoCode
    };
    if (!promoCode) {
      delete param.promocode;
    }
    this.isLoading = true;
    this.httpService.get({path: 'request/tariff', params: param}).subscribe((data: any) => {
      this.isLoading = false;
      if (this.contractAction === 'termination') {
        this.stage = 5;
      }
      if (data?.tariff && data.duty) {
        this.countRates = data.tariff?.length;
        this.duty = data.duty;
        data.tariff.forEach((item, index) => {
          if (index === 0) {
            this.rates.min = item;
          }
          if (index === 1) {
            this.rates.opty = item;
          }
          if (index === 2) {
            this.rates.max = item;
          }
          if (item.default) {
            this.activeTariff = item.id;
            this.paymentSum = +item.sumDiscount + +this.duty?.sumDiscount;
            this.sumBeforeDiscount = +item.sum;
          }
        });
        // this.paymentSum = +this.rates?.max?.sumDiscount + +this.duty?.sumDiscount;
        switch (this.draft?.tariff) {
          case '000000001':
          case 'Минимальный':
            this.paymentSum = +this.rates.min.sumDiscount + +this.duty.sumDiscount;
            this.activeTariff = this.rates.min.id;
            this.sumBeforeDiscount = +this.rates.min.sum + +this.duty.sum;
            break;
          case '000000002':
          case 'Оптимальный':
            this.activeTariff = this.rates.opty.id;
            this.paymentSum = +this.rates.opty.sumDiscount + +this.duty.sumDiscount;
            this.sumBeforeDiscount = +this.rates.opty.sum + +this.duty.sum;
            break;
          case '000000003':
          case 'Максимальный':
            this.activeTariff = this.rates.max.id;
            this.paymentSum = +this.rates.max.sumDiscount + +this.duty.sumDiscount;
            this.sumBeforeDiscount = +this.rates.max.sum + +this.duty.sum;
            break;
        }
        if (!this.draft) {
          this.stage = 5;
        }
      }
    });
  }

  /**
   * Отправка Тарифа
   */
  sendTariff(code?) {
    const params = {
      responseID: this.responseID,
      tariffID: this.activeTariff,
      promocode: code
    };
    if (!code) {
      delete params.promocode;
    }
    this.isLoading = true;
    return this.contractService.requestContractStage5(params)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(data => {
        for (const rate in this.rates) {
          if (this.rates[rate]?.id === this.activeTariff) {
            this.intercomEventsService.push({
              event: INTERCOM_EVENT_NAME.FORM_TM_RATE_SENT,
              typeIP: 'contract',
              tariff: this.rates[rate],
              totalPrice: this.paymentSum
            });
          }
        }
        this.stage = 6;
        this.responseID = data.responseID;
        }
      );
  }

  /**
   * Выбор типа оплаты
   * @param type: тип оплаты
   */
  setPaymentType(type: 'online' | 'cash' | 'act') {
    this.paymentType = type;
  }

  /**
   * Выбор плательщика
   * @param payerData: плательщик
   */
  setPayer(payerData) {
    this.payer = payerData;
    if (this.payer?.ogrn?.length && typeof this.payer?.ogrn === 'number') {
      this.payer.ogrn.toString();
    } else if (this.payer?.ogrn?.length && typeof this.payer?.ogrn === 'string') {
      this.payer.ogrn = this.payer.ogrn.replace(/\s/g, '');
    }
  }

  /**
   * Получение выбранного контракта
   */
  getContracts() {
    const body: ISearchObjects = {
      number: this.contractId,
    };
    this.isLoading = true;
    this.changeContract = false;
    this.contractService.getContracts(body).subscribe(answer => {
      this.isLoading = false;
      this.contract = answer[0];
      switch (this.contract.contractType) {
        case 'Договор лицензии':
          this.contractType = 'license';
          break;
        case 'Договор сублицензии':
          this.contractType = 'sublicense';
          break;
        case 'Договор концессии':
          this.contractType = 'concession';
          break;
        case 'Договор субконцессии':
          this.contractType = 'subconcession';
          break;
        case 'Договор залога':
          this.contractType = 'pledge';
          break;
      }
      this.sendTypeContract();
      if (this.contractAction === 'change') {
        this.owners = [];
        this.owners[0] = {
          inn: this.contract.assignor.TIN,
          address: this.contract.assignor.address,
          notResident: false,
          name: '',
          bik: '',
          account: '',
          director: ''
        };
        this.recipients = [];
        this.recipients[0] = {
          inn: this.contract.assignee.TIN,
          address: this.contract.assignee.address,
          notResident: false,
          name: '',
          bik: '',
          account: '',
          director: ''
        };
        this.objects = [];
        this.subject = undefined;
        this.contract.tradeMarks.forEach(el => {
          this.objects.push({
            id: el.id,
            index: el.index,
            elasticObject: false
          });
        });
      }
    });
  }

  goSuccessStage(dataYa) {
    this.isLoading = true;

    const query: IRequestContractStage6 = {
      responseID: this.responseID,
      inn: this.payer?.TIN || this.payer?.inn,
      ogrn: this.payer?.PSRN || this.payer?.ogrn,
      name: this.payer?.name || this.payer?.fullName || this.payer,
      address: this.payer?.address || this.payer?.legalAdress,
      sum: this.paymentSum,
      type: this.paymentType,
      yandexResponse: dataYa
    };
    return this.contractService.requestContractStage6(query)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe((data) => {
        this.stage = 7;
        this.responseID = data.responseID;
      });
  }

  deleteTask(id) {
    const params = {
      responseID: id
    };
    this.contractService.deleteTask(params)
      .subscribe(deletedData => {
        if (deletedData.answer === 'Успешно') {
          console.log('Успешно');
        } else {
          console.log(deletedData.answer, 'err');
        }
      });
  }

  selectTariff(event) {
    this.activeTariff = event.id;
    if (this.duty) {
      this.paymentSum = +event.sumDiscount + +this.duty.sumDiscount;
      this.sumBeforeDiscount = +event.sum + +this.duty.sum;
    } else {
      this.paymentSum = +event.sumDiscount;
      this.sumBeforeDiscount = +event.sum;
    }
  }

  /**
   * Лид форма
   */
  toLeadForm(): void {
    const data = {
      formIdentity: 'Подача заявки на регистрацию договора',
      responseID: this.responseID
    };
    this.router.navigate(['/treaty-extension'], {queryParams: {data: JSON.stringify(data)}});
  }
}
