import { Injectable } from '@angular/core';
import { IOrgDefault, IRights } from '../../../shared/models/main.interfaces';
import { HttpService } from '../../../shared/services/http.service';

const path = 'rights';


@Injectable({
  providedIn: 'root'
})
export class UserRightsService {

  constructor(private http: HttpService) { }

  getRights(params) {
    return this.http.get<IRights>({path: `${path}`, subjectType: 'tasks', params});
  }

  addRights(body) {
    return this.http.post<IOrgDefault>({path: `${path}/add`, subjectType: 'organizations', body});
  }

  deleteRights(body) {
    return this.http.post<IOrgDefault>({path: `${path}/delete`, subjectType: 'users', body});
  }

  deleteAllRights(body) {
    return this.http.post<IOrgDefault>({path: `${path}/delete-all`, subjectType: 'delete all', body});
  }
}
