import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subject, timer} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import { environment as ipidEnvironment } from '../../../../app-ipid/src/environments/environment';
import {RegistrationService} from '../../../services/registration.service';
import {UserProfileService} from '../../../services/user-profile.service';
import { environment as debtEnvironment } from '../../../../app-debit/src/environments/environment';
import { IntercomEventsService, INTERCOM_EVENT_NAME } from 'projects/shared/services/intercom-events.service';
import { Store } from '@ngxs/store';
import { AuthState } from '@web/core/states/auth.state';

let environment;

@Component({
  selector: 'app-user-phone',
  templateUrl: './user-phone.component.html',
  styleUrls: ['./user-phone.component.scss']
})
export class UserPhoneComponent implements OnInit {
  sendCode = false;
  codeError = false;
  phoneUser;
  codePhone;
  userData;
  errorText;
  resetPhone = false;
  isClickOutside = false;
  counter$: Observable<number>;
  count = 60;
  showTimer = false;
  showRepeat = false;
  phoneError = false;
  personalData = true;
  noPhone = true;
  // @Input() isEditPhone = false;
  // phoneIsUpdated = false;
  brand;

  @Output() personalDataStatus = new EventEmitter<boolean>();
  @Output() noPhoneStatus = new EventEmitter<boolean>();

  @Input() header = true;
  @Input() successText = false;
  @Input() animationStatus;
  @Input() isDebit: boolean;

  private readonly onDestroy = new Subject<void>();

  constructor( private registerApi: RegistrationService,
               private profileApi: UserProfileService,
               private readonly intercomEventsService: IntercomEventsService,
               private store: Store
  ) {}

  ngOnInit(): void {
    if (this.isDebit) {
      environment = debtEnvironment;
    } else {
      environment = ipidEnvironment;
    }

    this.brand = environment.BRAND;

    console.log('brand: ', this.brand);
    console.log('prod: ', environment.production);
    console.log('isDebit: ', this.isDebit);

    // if (this.isDebit) {
    //   this.brand = 'LEGAL-DEBT';
    // } else {
    //   this.brand = environment.BRAND;
    // }
    if (this.store.selectSnapshot(AuthState.getUser)) {
      this.userData = this.store.selectSnapshot(AuthState.getUser);
      this.personalData = this.userData.phone;
      if (this.personalData) {
        // this.phoneUser = this.userData.phone;
        this.noPhone = false;
      }
    }
  }

  fetchCodeRepeat() {
    this.count = 60;
    this.showRepeat = false;
    this.showTimer = true;
    this.codeGetter();
  }

  setTimer() {
    this.showTimer = true;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  codeGetter() {
    this.setTimer();
    const params = new HttpParams()
      .set('phone', this.phoneUser.toString())
      .set('brand', this.brand);
    this.registerApi.getCode(params)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data.answer === 'Успешно') {
          this.sendCode = true;
          console.log(data);
        } else {
          this.phoneError = true;
          this.errorText = data.answer;
          console.log(data);
        }
      });
    this.showRepeat = true;
    this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SENT_SMS });
  }

  checkCode(event, maxLength) {
    const body = {
      phone: this.phoneUser,
      validationCode: this.codePhone
    };
    this.codeError = false;
    if (this.codePhone.length === 4) {
      const params = new HttpParams()
        .set('brand', this.brand);
      this.registerApi.checkCode(body, params)
        .pipe(takeUntil(this.onDestroy))
        .subscribe(data => {
          if (data.answer === 'Успешно') {
            this.intercomEventsService.push({ event: INTERCOM_EVENT_NAME.SMS_CHECKED_OK });
            console.log(data.answer, 'success');
            this.noPhone = false;
            this.noPhoneStatus.emit(false);
            this.personalDataStatus.emit(true);
          } else {
            this.codeError = true;
            this.errorText = data.answer;
            console.log(data);
          }
        });
    }
  }

  // editUsers(phone?, email?, code?) {
  //   const params = {
  //     token: localStorage.getItem('currentUserIPID')
  //   };
  //   const body = {
  //     email,
  //     phone,
  //     phoneSkipCheck: true
  //   };
  //   this.profileApi.updateUser(params, body).pipe(takeUntil(this.onDestroy)).subscribe(userData => {
  //     if (userData) {
  //       this.profileApi.getUser().pipe(takeUntil(this.onDestroy)).subscribe(data => {
  //         localStorage.setItem('currentUserData', (JSON.stringify(data.userInfo)));
  //       });
  //     }
  //   });
  // }

  resetPhoneForm() {
    this.sendCode = false;
    this.resetPhone = true;
    this.phoneUser = '';
  }

  removeSuccess(): void {
    this.personalData = true;
    this.showTimer = false;
    this.codeError = false;
    this.phoneError = false;
    this.personalDataStatus.emit(this.personalData);
  }

}
