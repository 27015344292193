import { Injectable } from '@angular/core';
import {
  CorpSearchApiService,
  ICorpSearchApiByNumberParams,
  ICorpSearchApiByNumberResult
} from './corp-search-api.service';
import { CorpSearchSaveReportService } from './corp-search-save-report.service';
import { tap } from 'rxjs/operators';
import { ISearchHistoryDetail } from 'projects/app-ipid/src/app/shared/models/search-history.interfaces';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CorpSearchByNumberService {
  constructor(
    private location: Location,
    private corpSearchApiService: CorpSearchApiService,
    private corpSearchSaveService: CorpSearchSaveReportService,
  ) {
  }

  isLoading = false;

  searchOptions = {number: ''};

  data: ICorpSearchApiByNumberResult;

  page = 1;

  changePage(page) {
    this.page = page;
  }

  loadBySearchHistory(search: ISearchHistoryDetail, isShow: boolean) {
    this.searchOptions = {number: search.searchParameters.text};

    if (isShow) {
      this.data = search.results.find(i => i.entity === 'trademark').result;
    } else {
      this.load(this.searchOptions, false);
    }
  }

  load(searchParams: { number: string }, saveSearch = true) {
    this.isLoading = true;

    if (searchParams?.number) {
      this.location.go(`/search/number?q=${searchParams.number}`);
    }

    this.corpSearchApiService.searchTrademarksByNumber(searchParams)
      .pipe(
        tap((data) => saveSearch && this.saveSearch(searchParams, data))
      )
      .subscribe((data) => {
        this.isLoading = false;
        this.data = data;
      });
  }

  saveSearch(params: ICorpSearchApiByNumberParams, result: ICorpSearchApiByNumberResult) {
    this.corpSearchApiService.objectsLoading = true;
    return this.corpSearchApiService
      .saveSearchTrademarksByNumber(params, result)
      .subscribe(data => {
        if (data && data.searchID) {
          this.corpSearchApiService.objectsLoading = false;
          this.corpSearchSaveService.setSearchID(data.searchID);
        }
      });
  }

  get total() {
    return {
      tradeMarks: this.data?.hits?.hits?.length
    };
  }

  get items() {
    return {
      tradeMarks: this.data?.hits?.hits,
    };
  }


  reset() {
    this.data = undefined;
    this.page = 1;
    this.searchOptions = {number: ''};
  }

  get isLoaded() {
    return this.data !== undefined;
  }

}
