import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-card-settings',
  templateUrl: './user-card-settings.component.html',
  styleUrls: ['./user-card-settings.component.scss']
})
export class UserCardSettingsComponent implements OnInit {
  //Временные данные
  organizations = [
    {
      id: '1',
      name: 'ИП Константинопольский Константин Андреевич',
      ogrnip: 'ОГРНИП 317774600010541',
      inn: 'ИНН 501209738853',
      city: 'Москва',
      balance: '17 325,93',
      bonus: '3 900,00',
      checked: false,
      cases: [
        {
          name: 'Регистрация товарного знака #55467',
          response: 'Ждем ответ от роспатента',
        },
        {
          name: 'Ведение дела в арбитражном суде #44345',
          response: 'Взыскание',
        },
        {
          name: 'Претензионная работа #53578',
          response: 'Претензия, ожидание ответа',
        }
      ],
      user: [
        {
          name: 'Гудреева Алена'
        },
        {
          name: 'Пчелкин Валентин',
        },
        {
          name: 'Козлов Владимир',
        },
        {
          name: 'Гудреева Алена',
        },
        {
          name: 'Пчелкин Валентин',
        }
      ]
    },
    {
      id: '2',
      name: 'ООО Ромашка',
      ogrnip: 'ОГРНИП 317774600010541',
      inn: 'ИНН 501209738853',
      city: 'Москва',
      balance: '17 325,93',
      bonus: '3 900,00',
      checked: false,
      cases: [],
      user: [
        {
          name: 'Гудреева Алена'
        },
        {
          name: 'Пчелкин Валентин',
        }
      ]
    }
  ];

  //Кнопки в секции с экспертом
  btns = ['Консультация по претензии или иску', 'Вопрос по текущему делопроизводству',
    'Консультация по услугам', 'Запрос первичных документов', 'Другое'];

  //Выпадающее меню с выбором организации
  showDropdown = false;
  selected;

  //Пользователь заблокирован
  userBlock = false;

  constructor() { }

  ngOnInit(): void {
    this.selected = this.organizations[0].name;
  }
}
