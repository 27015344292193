<section class="open-monitoring-widget">
  <div class="open-monitoring-widget__header">
    <h2 class="open-monitoring-widget__title">Мониторинг</h2>
    <a *ngIf="totalMonitoring && totalMonitoring > 4" href="" class="open-monitoring-widget__link" routerLink="/monitoring">Все (+{{totalMonitoring - 4}})</a>
  </div>
  <div *ngIf="!loadingRisks && risksAll.length !== 0" class="open-monitoring-widget__table-wrapper">
    <div *ngFor="let risk of risksAll" class="open-monitoring-widget__row">
      <div class="open-monitoring-widget__logo-wrapper">
        <img *ngIf="risk.imageURL" [src]="getSrcByIndex(risk.index[0].id, true, risk.imageURL)" alt="" class="open-monitoring-widget__logo-img">
        <span *ngIf="!risk.imageURL">Logo</span>
      </div>
      <div class="open-monitoring-widget__main-info-wrapper">
        <span class="open-monitoring-widget__risk"
              [ngClass]="getColorByLevel(risk.risk.level)">
          <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.642 6.512L6.128 6.116L6.546 3.41L6.128 0.682L3.642 0.307999L1.156 0.682L0.716 3.41L1.156 6.116L3.642 6.512Z" fill="currentColor"/>
          </svg>
        </span>
        <div class="open-monitoring-widget__text-wrapper">
          <h4 class="open-monitoring-widget__text">{{risk.type}} {{risk.showId || risk.id}}</h4>
          <p  class="open-monitoring-widget__subtext">{{risk.name}}</p>
        </div>
      </div>
      <div class="open-monitoring-widget__state-wrapper">
        <p class="open-monitoring-widget__state">{{risk.risk.description}}</p>
        <span class="open-monitoring-widget__date" [class.critical]="risk.risk.level === 3">{{risk.risk.date | date:'dd.MM.yyyy' }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!loadingRisks && risksAll.length === 0" class="open-monitoring-widget__table-wrapper open-monitoring-widget__table-wrapper--empty">
    <img src="/assets/images/nothing.svg" alt="nothing">
    <h3>Нет объектов для мониторинга</h3>
    <p>Вы можете подключить объекты в разделе мониторинга.</p>
    <a routerLink="/monitoring">Перейти в мониторинг</a>
  </div>

  <app-spinner  *ngIf="loadingRisks" style="height: 300px" class="spinner-full"></app-spinner>

</section>

