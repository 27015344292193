import { Injectable } from '@angular/core';
import { UraitApiService } from '@web/core/services/urait-api.service';
import { RightsResponse } from '@web/core/models/rights.interface';

@Injectable()
export class RightsApiService {
  private readonly url = 'rights';

  constructor(private apiService: UraitApiService) {}

  getRights(params): Promise<RightsResponse> {
    return this.apiService.get(this.url, params);
  }
}
