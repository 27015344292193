import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '../../../../../../shared/services/user-profile.service';
import { takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../../../../app-search/src/app/services/search.service';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-organization-delete',
  templateUrl: './organization-delete.component.html',
  styleUrls: ['./organization-delete.component.scss']
})

export class OrganizationDeleteComponent implements OnInit {

  deleteCompany: FormGroup;
  phoneVerified = false;
  errorText;
  showCodeInput = false;
  codeError = false;
  loading = false;
  organization;
  fullOrganizationData;
  private readonly onDestroy = new Subject<void>();


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private userProfileService: UserProfileService,
    private router: Router,
    private searchService: SearchService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getUrlParam();
  }

  initForm(): void {
    this.deleteCompany = this.fb.group({
      name: ['', [Validators.required]],
      director: ['', [Validators.required]],
      OGRN: ['', [Validators.required]],
      INN: ['', [Validators.required]],
      date: ['', [Validators.required]],
      region: ['', [Validators.required]],
    });
  }

  getUrlParam() {
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.getOrganizationById(params.id);
      }
    });
  }

  getOrganizationById(id) {
    this.organization = this.userProfileService.getOrganizationsData().find(el => el.id === id);
    this.getFullOrganizationData(this.organization.TIN);
  }

  getFullOrganizationData(inn) {
    const query = {
      id: inn,
    };
    this.searchService.getLegal(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.hits?.hits.length) {
        this.fullOrganizationData = data.hits.hits[0]._source;
        this.setDataToForm();
      }
    });
  }

  setDataToForm() {
    this.deleteCompany.controls.name.setValue(this.fullOrganizationData.name || this.fullOrganizationData.fullName);
    if (this.fullOrganizationData.directors) {
      const directorName = this.fullOrganizationData.directors[0].surname + ' ' + this.fullOrganizationData.directors[0].name + ' ' + this.fullOrganizationData.directors[0].patronymic;
      this.deleteCompany.controls.director.setValue(directorName);
    }
    this.deleteCompany.controls.OGRN.setValue(this.fullOrganizationData.PSRN);
    this.deleteCompany.controls.INN.setValue(this.fullOrganizationData.TIN);
    const date = new Date(this.fullOrganizationData.dateOfPSRN);
    const dateResult = (('0' + date.getDate()).slice(-2)) + '.' + (('0' + (date.getMonth() + 1)).slice(-2)) + '.' + date.getFullYear();
    this.deleteCompany.controls.date.setValue(dateResult);
    const region = this.fullOrganizationData.addressHistory[0].regionName + ' ' + this.fullOrganizationData.addressHistory[0].regionType;
    this.deleteCompany.controls.region.setValue(region);
  }

  toPrevPage() {
    this.router.navigate(['user/organization'], {queryParams: {id: this.organization.id}});
  }

  deleteOrganization() {
    this.loading = true;
    const query = {
      organizations: [{
        id: this.organization.id
      }]
    };
    this.userProfileService.deleteOrganizations(query).pipe(takeUntil(this.onDestroy)).subscribe((data: any) => {
      if (data?.error?.length) {
        console.log('ERROR', data.error);
      } else {
        this.userProfileService.loadOrganizationIfNeeded();
        this.router.navigate(['user/profile']);
      }
      this.loading = false;
    });
  }

  backClicked() {
    this.location.back();
  }
}
