import { Injectable } from '@angular/core';
import { IPostParams } from 'projects/app-search/src/app/models/rest.interfaces';
import { HttpService } from './http.service';
import { UserLoginInterface } from '@web/core/models/user.interface';

const path = 'user';

@Injectable({
  providedIn: 'root',
})
export class SsoAuthService {
  constructor(private http: HttpService) {}

  ssoLogin(body, params) {
    return this.http.post<UserLoginInterface>({
      path: `${path}/ssoLogin`,
      body: { body },
      subjectType: 'sso',
      params,
    });
  }
}
