<div class="container">
  <h2 class="visual-hidden">Организации и ИП</h2>

  <div class="attention" *ngIf="administrator && expiringAttorneys?.length >= 1">
    <p class="attention__title">
      Важно!
    </p>
    <div *ngFor="let exp of expAttorneys">
      <p class="attention__desc">
        У пользователя <span class="attention__desc-bold">{{exp.user}}</span>
        в компании «<span class="attention__desc-bold">{{exp.shortName}}</span>» заканчивается доверенность
        <b>{{exp.date}}</b>.
        Пожалуйста, прикрепите новую доверенность.
      </p>
    </div>
    <a (click)="openAttorneys()" class="attention__link">Посмотреть</a>
  </div>
  <div>
    <section class="user-organization"
             *ngFor="let organization of orgData; index as i"
             [ngClass]="organization.pending ? 'waiting-conf': ''"
    >
      <div class="user-organization__wrap">
        <div class="user-organization__info">
          <a (click)="toChild(organization.id)" class="user-organization__name">{{ organization?.name }}</a>
          <ul class="user__info-list user-organization__list">
            <li class="user__info-item">{{getTitleByPSRNCount(organization?.PSRN)}} {{ getStringWithoutSpace(organization?.PSRN) }}</li>
            <li class="user__info-item">ИНН {{ organization?.TIN }}</li>
          </ul>
        </div>
      </div>
      <ul class="user-organization__about" *ngIf="!organization.pending">
        <li class="user-organization__about-item" *ngIf="administrator">
          <p class="user-organization__about-value">
            <span *ngIf="administrator && newOrganization === false">0&nbsp;</span>
            <span *ngIf="administrator && newOrganization === true">0&nbsp;</span>
            <span class="user-organization__about-rub">₽</span>
          </p>
          <span>Остаток на счете</span>
        </li>
        <li class="user-organization__about-item">
          <ng-container *ngIf="organization.cases.length; else zero">
            <a class="user-organization__about-value" routerLink="/cases">{{ organization.cases.length }}</a>
          </ng-container>
          <ng-template #zero>
            <span class="user-organization__about-value">0</span>
          </ng-template>
          <span>Активных дел</span>
        </li>
        <li class="user-organization__about-item">
          <ng-container *ngIf="getRepresentativesCount(organization?.representatives) != 0; else zero">
            <a class="user-organization__about-value" (click)="toChild(organization.id)">{{ getRepresentativesCount(organization?.representatives) }}</a>
          </ng-container>
          <ng-template #zero>
            <span class="user-organization__about-value">0</span>
          </ng-template>
          <span>Пользователей</span>
        </li>
      </ul>
      <div *ngIf="organization.pending" class="user-organization__waiting-account">
        <img src="/assets/icons/icon-waiting-account.svg" alt="" class="waiting-account__img" width="120" height="32">
        <p class="waiting-account__title">
          Ожидает подтверждение администратора организации
        </p>
        <p class="waiting-account__desc">
          Данная компания уже зарегистрирована в сервисе. Вы сможете использовать сервис для
          получения услуг от имени указанной организации, после подтверждения ваших прав администратором.
          Если вы считаете что должны являться администратором этой организации, то
          обратитесь в <a (click)="openModalSupport()" class="waiting-account__link">службу поддержки</a>.
        </p>
      </div>
    </section>

    <section class="user-organization__waiting-account user-organization__waiting-account--empty" *ngIf="!orgData.length">
      <img src="/assets/icons/icon-no-org.svg" alt="" class="waiting-account__img" width="120" height="32">
      <p class="waiting-account__title">
        У вас еще нет добавленной организации
      </p>
      <p class="waiting-account__desc">
        У вас еще нет добавленной организации в сервисе. Вы можете добавить организацию самостоятельно, или она будет добавлена автоматически
        после первой оплаты в сервисе. После добавления организации изменения возможны только через службу поддержки.
      </p>
      <button (click)="addOrg()" class="user-organization__waiting-account-btn btn">Добавить организацию</button>
    </section>
  </div>

  <button *ngIf="administrator && !monoAccount && orgData.length" (click)="addOrg()" class="user-organization__add">
    <svg viewBox="0 0 60 60" class="user-organization__add-img">
      <circle cx="30" cy="30" r="30"/>
      <rect x="29" y="20" width="2" height="20" fill="white"/>
      <rect x="40" y="29" width="2" height="20" transform="rotate(90 40 29)" fill="white"/>
    </svg>
    <span class="user-organization__add-text">Добавить организацию</span>
  </button>
</div>

<app-support-modal
  *ngIf="showModalSupport"
  [showModal]="showModalSupport"
  (closeModalSupportPopup)="closeModalSupport()">>
</app-support-modal>
